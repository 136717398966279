define(["dijit/Dialog", "mojo/user", "dojo/text!../widgets/campaigns/templates/CampaignPauseDialog.html", "dojo/text!../widgets/campaigns/templates/CampaignCancelDialog.html"], function (Dialog, user, pauseDialogTemplate, cancelDialogTemplate) {
  return {
    pause: function (postcardId, status) {
      var dialog = new Dialog({
        "title": "Would you like to pause your postcard?",
        "content": pauseDialogTemplate,
        onHide: function () {
          dialog.destroyRecursive();
        }
      });
      dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
        window.location.href = "/postcards/pause?id=" + postcardId;
      });
      dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
        dialog.hide();
      });
      if (status === "scheduled") {
        var modalContent = dialog.domNode.querySelector("#modalContent");
        modalContent.innerText += "Note that pausing to edit a postcard may change your scheduled date.";
      }
      dialog.show();
    },
    cancel: function (postcardId, campaignName) {
      var dialog = new Dialog({
        "title": "Cancel this campaign?",
        "content": cancelDialogTemplate,
        onHide: function () {
          dialog.destroyRecursive();
        }
      });
      var form = dialog.domNode.querySelector("form");
      form.setAttribute("action", "/postcards/cancel?id=" + postcardId);
      var campaignNameSpan = dialog.domNode.querySelector("[data-dojo-attach-point='campaignNameSpan']");
      campaignNameSpan.innerHTML = campaignName;
      var csrf = dialog.domNode.querySelector("[data-dojo-attach-point='csrf']");
      csrf.setAttribute("value", user.__csrfToken);
      dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
        dialog.hide();
      });
      dialog.show();
    },
    replicate: function (postcardId) {
      var form = document.createElement("form");
      form.action = "/postcards/replicate";
      form.method = "POST";
      var id = document.createElement("input");
      id.type = "hidden";
      id.name = "id";
      id.value = postcardId;
      form.appendChild(id);
      var csrf = document.createElement("input");
      csrf.type = "hidden";
      csrf.name = "__csrf_token";
      csrf.value = user.getCsrfToken();
      form.appendChild(csrf);
      var submit = document.createElement("button");
      submit.type = "submit";
      form.appendChild(submit);
      document.body.appendChild(form);
      // We use submit.click() here in lieu of form.submit()
      // in order to fire the "submit" event.
      submit.click();
    }
  };
});