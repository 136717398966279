/**
 * Widget that displays 2 states that are relevant for content comparison.
 *
 * 1) Overall click-rate for combinations that uses a given content.
 * 2) Specific click-rate for a particular url in a given content for each combination.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/contentLinkStats.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo/number", "dojox/html/entities"], function (declare, _WidgetBase, _TemplatedMixin, tpl, lang, array, domConstruct, html, number, htmlEntities) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    rowTpl: "<li>" + '<p class="!margin--lv0 selfclear padding--lv3 !padding-top-bottom--lv0 !padding-right--lv0">' + '<span class="float-left">Combination {combo}</span>' + '<span class="float-right"><span class="fwb">{clicks}%</span> Clicks</span>' + '</p>' + "</li>",
    rowMetaTpl: '<p class="small-meta !margin--lv0 padding--lv3 !padding-top-bottom--lv0 !padding-right--lv0"><span class="fwb">{label}:</span> {value}</p>',
    // See view.js or controller for more info.
    detailedStats: null,
    // See view.js or controller for more info.
    contentLinkStats: null,
    // Look-up map that keys campaign ID to the combination
    campaignIdToCombo: null,
    testingSubjects: false,
    testingFromNames: false,
    testingSchedules: false,
    postMixInProperties: function () {
      this.campaignIdToCombo = {};
      array.forEach(this.detailedStats.combinationStats, lang.hitch(this, function (combo) {
        this.campaignIdToCombo[combo.campaignId] = combo;
      }));
    },
    /**
     * Show the details for an individual link.
     *
     * @param {number} contentIndex
     * @param {number} linkIndex
     */
    showLinkDetails: function (contentIndex, linkIndex) {
      domConstruct.empty(this.comboList);
      var contentStats = this.contentLinkStats[contentIndex];
      var linkDetails = contentStats && contentStats.links[linkIndex];
      if (linkDetails) {
        this._setHeader("Link details for Content " + (contentIndex + 1));
        array.forEach(linkDetails.campaigns, lang.hitch(this, function (campaignDetails) {
          this._addLinkDetail(campaignDetails);
        }));
        var rate = this._formatRate(linkDetails.overall_clicks, linkDetails.overall_delivered) + "%";
        html.set(this.leftDetails, linkDetails.url);
        html.set(this.rightDetails, rate);
      }
    },
    /**
     * Shows the content stats for the given index. Pulls up the click-rate for all the campaigns
     * that used the given content.
     *
     * @param {number} contentIndex
     */
    showContentDetails: function (contentIndex) {
      domConstruct.empty(this.comboList);
      var contentStats = this.contentLinkStats[contentIndex];
      if (contentStats) {
        this._setHeader("Details");
        array.forEach(contentStats.campaigns, lang.hitch(this, "_addOverallDetail"));
        html.set(this.leftDetails, "Content " + (contentIndex + 1));
        var variateStat = this.detailedStats.variateStats.contents[contentIndex];
        if (variateStat) {
          html.set(this.rightDetails, number.format(variateStat.avgClickRate, {
            places: 1
          }) + "%");
        }
      }
    },
    _addLinkDetail: function (campaignDetails) {
      var clickRate = this._formatRate(campaignDetails.unique_clicks, campaignDetails.delivered);
      this._addRow(campaignDetails.campaign_id, clickRate);
    },
    _formatRate: function (click, delivered) {
      var rate = 0;
      if (delivered) {
        rate = click / delivered * 100;
      }
      if (rate > 0) {
        rate = number.format(rate, {
          places: 1
        });
      }
      return rate;
    },
    /**
     * Given a campaign_id, it will insert a row that contains the given campaign's click rate.
     * @param {number} campaignId
     * @private
     */
    _addOverallDetail: function (campaignId) {
      var combo = this.campaignIdToCombo[campaignId];
      this._addRow(campaignId, number.format(combo.clickRate, {
        places: 1
      }));
    },
    _addRow: function (campaignId, clickRate) {
      var combo = this.campaignIdToCombo[campaignId];
      var row = domConstruct.place(lang.replace(this.rowTpl, {
        "combo": combo.combination,
        "clicks": clickRate
      }), this.comboList, "append");
      if (this.testingSubjects) {
        domConstruct.place(lang.replace(this.rowMetaTpl, {
          "label": "Subject line",
          "value": htmlEntities.encode(combo.subject)
        }), row);
      }
      if (this.testingFromNames) {
        domConstruct.place(lang.replace(this.rowMetaTpl, {
          "label": "From",
          "value": htmlEntities.encode(combo.fromName) + "&lt;" + combo.fromEmail + "&gt;"
        }), row);
      }
      if (this.testingSchedules) {
        domConstruct.place(lang.replace(this.rowMetaTpl, {
          "label": "Scheduled",
          "value": combo.scheduledSendTime
        }), row);
      }
    },
    /**
     * Helper for change the header
     */
    _setHeader: function (msg) {
      this.header.innerHTML = msg;
    }
  });
});