/**
 * @class mojo.app.lists.import.Destination
 *
 * Represent a location that an import column's data can be written into.
 * Generally, these are merge fields, advanced fields, merge field components, and interest groups.
 *
 * @property {String} id Identifier for this destination, as known by importer backend, eg. "3", "5.addr1", "My Group Name"
 * @property {String} name Human-readable name, eg. "First Name", "Home Address", "Home Address - City"
 * @property {String} type Where this destination hails from, eg. "merge", "advanced" (see `Destination.TYPE_*`)
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var Destination = declare(null, {
    constructor: function (id, name, type) {
      this.id = id;
      this.name = name;
      this.type = type;
    },
    getName: function () {
      return this.name;
    },
    getTypeLabel: function () {
      if (this.type === Destination.TYPE_MERGE_FIELD_COMPONENT) {
        return "address field";
      }
      return this.type + " field";
    }
  });
  Destination.TYPE_MERGE_FIELD = "merge";
  Destination.TYPE_ADVANCED_FIELD = "advanced";
  Destination.TYPE_MERGE_FIELD_COMPONENT = "merge_component";
  Destination.ID_TAGS = "TAGS";
  return Destination;
});