define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors",
// Mixins
"mojo/widgets/pattern-library/ButtonGroupDemo"], function (declare, topic, mColors, ButtonGroupDemo) {
  var style = document.documentElement.style;
  var colors = mColors.brandColors;
  return declare([ButtonGroupDemo], {
    type: "bordered",
    options: ["Deprecated colors", "New colors"],
    allProps: ["--vis-meter", "--vis-meter-bg", "--vis-neutralMeter-bg", "--vis-spectrum"],
    /**
     * Do some action on change. Careful, will likely fire on first load.
     *
     * @param {String} newValue - The changed value.
     * @private
     */
    _handleTogglerChange: function (newValue) {
      this._resetProps();
      topic.publish("colorToggle/change", newValue);
      if (newValue === "new colors") {
        this._setNew();
      }
    },
    _setNew: function () {
      var spectrumGradient = "linear-gradient(to right, " + colors.pumpkin.hex + ", " + colors.apricot.hex + " 33% 66%, " + colors.apple.hex + ")";
      style.setProperty("--vis-meter-bg", colors.parsnip.hex);
      style.setProperty("--vis-meter", colors.apple.hex);
      style.setProperty("--vis-spectrum", spectrumGradient);
      style.setProperty("--vis-neutralMeter-bg", colors.plum.hex);
    },
    _resetProps: function () {
      this.allProps.forEach(function (i) {
        document.documentElement.style.removeProperty(i);
      });
    }
  });
});