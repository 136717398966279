define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/signup-forms/PopupSignupForm"], function (declare, base, PopupSignupForm) {
  return declare([base], {
    config: null,
    subscribeUrl: "",
    onReady: function () {
      var form = new PopupSignupForm({
        config: this.config,
        ignoreCookie: true,
        subscribeUrl: "",
        isPreview: true
      });
      form.addBackgroundForMobilePreview();
      form.placeAt(document.body);
      form.startup();
      this.form = form;
    }
  });
});