/**
 * @class mojo.app.lists.Tag
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var Tag = declare(null, {
    /**
     * @type {Number}
     */
    tagId: null,
    /**
     * @type {String}
     */
    name: null,
    constructor: function (tagId, name) {
      this.tagId = tagId;
      this.name = name;
    }
  });

  /**
   * @param {*} view Data from backend
   * @return {*} A Tag instance
   */
  Tag.fromView = function (view) {
    return new Tag(view.tag_id, view.tag_name);
  };
  return Tag;
});