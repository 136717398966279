define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "mojo/lib/logger", "dojo/debounce", "mojo/context", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, base, on, query, xhr, mUrl, utils, logger, debounce, context, EcsEvents, EcsTracking) {
  return declare([base], {
    onReady: function () {
      this._incorrectCodeMsg = "Hmm, it looks the code you entered is incorrect.";
      this._maxAttemptCodeMsg = "Too many attempts done on the security code. Please get a new code.";
      this._nonNumericCodeMsg = "Only numbers allowed.";
      this._invalidUsernameMsg = "Invalid username";
      this._apiUnavailableMsg = "Shoot! We can't complete your request at this time. Please try again later.";
      this._apiErrorMsg = "Shoot! We failed on our end. Please try again";
      this._attachListenerToCodeTextBox();
      if (context.flagIsOn("identity_bridge.ecs_event_tracking_in_signup_and_onboarding")) {
        this._attachECSListeners();
      }
    },
    _attachECSListeners: function () {
      var openOutlookLink = this.$el.openOutlookLink;
      var openGmailLink = this.$el.openGmailLink;
      var resendEmailLink = this.$el.resendEmailLink;
      var windowUrl = new URL(window.location.href);
      var entry_point = windowUrl.searchParams.get("entrypoint") || "";
      var qb_realm_id = windowUrl.searchParams.get("qb_realm_id") || "";
      var qb_pseudonym_id = windowUrl.searchParams.get("qb_pseudonym_id") || "";
      on(openGmailLink, "click", function () {
        EcsTracking.track(EcsEvents.EmailConfirmationGmailEvent, {
          entry_point: entry_point,
          qb_realm_id: qb_realm_id,
          qb_pseudonym_id: qb_pseudonym_id
        });
      });
      on(openOutlookLink, "click", function () {
        EcsTracking.track(EcsEvents.EmailConfirmationOutlookEvent, {
          entry_point: entry_point,
          qb_realm_id: qb_realm_id,
          qb_pseudonym_id: qb_pseudonym_id
        });
      });
      on(resendEmailLink, "click", function () {
        EcsTracking.track(EcsEvents.EmailConfirmationRetryEvent, {
          entry_point: entry_point,
          qb_realm_id: qb_realm_id,
          qb_pseudonym_id: qb_pseudonym_id
        });
      });
    },
    _sendGoogleAnalyticsEvent: function (category, action, label, customParams) {
      if (!customParams) {
        // initializing custome params to empty object
        customParams = {};
      }
      var $client_id_field = document.getElementById("client_id");
      if ($client_id_field && $client_id_field.value) {
        // check if the client id exists, pass it as custom param.
        customParams["dimension63"] = $client_id_field.value;
      }
      logger.googleAnalytics.trackEvent(category, action, label, customParams);
    },
    /**
     * Make a GET Request to a backend endpoint.
     *
     * @param {string} endpoint - backend path.
     * @param {Object} formData - Payload to attach to the GET request.
     *
     * @returns {*|Promise} Promise object representing the HTTP GET response.
     *
     * @private
     */
    _getData: function (endpoint, formData) {
      return xhr.get(mUrl.toUrl(endpoint, formData), {
        "handleAs": "json"
      });
    },
    /**
     * Clear code error message and red border around text boxes.
     *
     * @returns {void}
     *
     * @private
     */
    _clearCodeErrors: function () {
      var msgElem = query(".verification-code-wrapper .invalid-error");
      if (!msgElem || !msgElem.length) {
        return;
      }
      msgElem[0].innerText = "";
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem) {
        return;
      }
      txtBoxElem.removeClass("invalid");
    },
    /**
     * Add code error message and red border around text boxes.
     *
     * @param {string} msg - error message.
     *
     * @returns {void}
     *
     * @private
     */
    _showCodeErrors: function (msg) {
      this._clearCodeErrors();
      var msgElem = query(".verification-code-wrapper .invalid-error");
      if (!msgElem || !msgElem.length) {
        return;
      }
      msgElem[0].innerText = msg;
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem) {
        return;
      }
      txtBoxElem.addClass("invalid");
    },
    /**
     * Get combined code from all 6 text boxes.
     *
     * @returns {string} code.
     *
     * @private
     */
    _getCode: function () {
      var elem = this.$el.verificationCode;
      if (!elem || !elem.value) {
        return "";
      }
      return elem.value.replace(/\s/g, "");
    },
    /**
     * Check if code is valid.
     *
     * @param {string} code - error message.
     *
     * @returns {boolean} - if code is valid.
     *
     * @private
     */
    _isCodeValid: function (code) {
      code = String(code);
      return code && code.length === 6 && utils.isPositiveInteger(code);
    },
    /**
     * Shows/hides loading indicator.
     *
     * @param {boolean} show - to display/hide.
     *
     * @returns {void}
     *
     * @private
     */
    _showLoadingStatus: function (show) {
      var loadingModal = query("#verify_loading_modal");
      if (!loadingModal || !loadingModal.length) {
        return;
      }
      var content = query(".signup-wrap .content");
      if (!content || !content.length) {
        return;
      }
      if (show) {
        loadingModal.addClass("loading");
        content.addClass("hide");
      } else {
        loadingModal.removeClass("loading");
        content.removeClass("hide");
      }
    },
    /**
     * Disable/enable code text box.
     *
     * @param {boolean} disable - to disable/enable.
     *
     * @returns {void}
     *
     * @private
     */
    _disableCodeTextBox: function (disable) {
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem || !txtBoxElem.length) {
        return;
      }
      txtBoxElem[0].disabled = disable === true;
      if (disable) {
        txtBoxElem.addClass("opacity-70");
      } else {
        txtBoxElem.removeClass("opacity-70");
      }
    },
    /**
     * Put focus on code text box.
     *
     * @returns {void}
     *
     * @private
     */
    _focusCodeTextBox: function () {
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem || !txtBoxElem.length) {
        return;
      }
      txtBoxElem[0].focus();
    },
    /**
     * Make an API call to verify code.
     */
    _verifyEmailByCodeAPI: function () {
      var self = this,
        gaLabel;
      var username = self.$el.username ? self.$el.username.value : "";
      var userid = self.$el.userid ? self.$el.userid.value : "";
      var client_id = self.$el.client_id ? self.$el.client_id.value : "";
      var response_type = self.$el.response_type ? self.$el.response_type.value : "";
      var state = self.$el.state ? self.$el.state.value : "";
      var redirect_uri = self.$el.redirect_uri ? self.$el.redirect_uri.value : "";
      var signup_source = self.$el.signup_source ? self.$el.signup_source.value : "";
      if (!username || !userid) {
        // Should not happen
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._invalidUsernameMsg);
        self._showCodeErrors(self._incorrectCodeMsg);
        return;
      }
      var code = self._getCode();
      if (!self._isCodeValid(code)) {
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._incorrectCodeMsg);
        self._showCodeErrors(self._incorrectCodeMsg);
        return;
      }
      self._disableCodeTextBox(true);
      self._showLoadingStatus(true);

      // Make an API call to verify code
      self._getData("/signup/verify-email-by-code", {
        username: username,
        userid: userid,
        code: code,
        signup_source: signup_source
      }).then(function (result) {
        if (result && result.success && result.data.params.redirect_url) {
          self._sendGoogleAnalyticsEvent("sign up", "step complete", "code successful");
          if (client_id && client_id !== "") {
            var query_string = new URLSearchParams();
            query_string.append("client_id", client_id);
            query_string.append("state", state);
            query_string.append("redirect_uri", redirect_uri);
            query_string.append("response_type", response_type);
            window.location.href = result.data.params.redirect_url + "&" + query_string.toString();
          } else {
            window.location.href = result.data.params.redirect_url;
          }
          return;
        }
        self._disableCodeTextBox(false);
        self._showLoadingStatus(false);

        // Log error messages to GA
        gaLabel = result.data.error_message + " (SigSci Code: " + result.data.sigsci_code + ").";
        self._sendGoogleAnalyticsEvent("sign up", "code error", gaLabel);

        // If error is of type validation, show it to user
        if (result.data.sigsci_code === "5") {
          if (result.data.error_message === "Max attempts") {
            self._showCodeErrors(self._maxAttemptCodeMsg);
          } else {
            self._showCodeErrors(self._incorrectCodeMsg);
          }
        } else {
          self._showCodeErrors(self._apiUnavailableMsg);
        }
        self._focusCodeTextBox();
      }, function (error) {
        self._disableCodeTextBox(false);
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._apiErrorMsg);
        self._showCodeErrors(self._apiErrorMsg);
        console.error(self._apiErrorMsg, error);
        self._focusCodeTextBox();
      });
    },
    /**
     * Do necessary checks when text box input is changed.
     *
     * @returns {void}
     *
     * @private
     */
    _attachListenerToCodeTextBox: function () {
      var self = this;
      var textBoxElem = self.$el.verificationCode;
      if (!textBoxElem) {
        return;
      }
      on(textBoxElem, "input", debounce(function () {
        self._clearCodeErrors();
        var code = self._getCode();
        if (!code) {
          return;
        }
        if (textBoxElem.value.indexOf(" ") >= 0) {
          textBoxElem.value = code;
        }
        if (code.length > 6) {
          code = code.substring(0, 6);
          textBoxElem.value = code;
        }
        if (!utils.isPositiveInteger(code)) {
          self._showCodeErrors(self._nonNumericCodeMsg);
          return;
        }
        if (code.length > 6) {
          self._showCodeErrors(self._incorrectCodeMsg);
          return;
        }
        if (code.length === 6) {
          self._verifyEmailByCodeAPI();
        }
      }, 200));
    }
  });
});