define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "d3/d3"], function (declare, _Widget, d3) {
  return declare([_Widget], {
    setTime: function (time) {
      this.time = time;
      d3.selectAll(".clock").remove();
      this.render();
    },
    render: function () {
      var timeSplit = this.time.split(":");
      var data = [{
        'unit': 'minutes',
        'value': timeSplit[1]
      }, {
        'unit': 'hours',
        'value': timeSplit[0]
      }];
      var w = this.width || 36,
        h = this.height || 36,
        strokeWidth = this.strokewidth || 2,
        clockFillColor = this.cfill || "none",
        clockBorderColor = this.cborder || "#FEBE12",
        clockHandColor = this.chand || "#FEBE12",
        clockCenterColor = this.ccolor || "#FEBE12",
        transitionEnabled = this.transition,
        radius = w / 2,
        vis,
        clock,
        hourPosition,
        minutePosition,
        minutePositionFinal,
        hourPositionFinal,
        clockhand,
        hourPositionOffset;

      // Map 60 minutes onto a radial 360 degree range.
      var scaleMins = d3.scale.linear().domain([0, 59 + 59 / 60]).range([0, 2 * Math.PI]);

      // Map 12 hours onto a radial 360 degree range.
      var scaleHours = d3.scale.linear().domain([0, 11 + 59 / 60]).range([0, 2 * Math.PI]);

      // The hour hand moves 30 degrees every hour.
      // The minute hand moves 360 degrees every hour.
      // Map the linear movement of minute hand onto a 30 degree radial range
      // to obtain the angular offset to add to the hour hand position (in scaleHours above)
      // to get the final, accurate hour hand position.
      var scaleBetweenHours = d3.scale.linear().domain([0, 59 + 59 / 60]).range([0, Math.PI / 6]);

      // The SVG
      vis = d3.select(this.domNode).append("svg:svg").attr("class", "clock").attr("width", w).attr("height", h);
      clock = vis.append("svg:g").attr("transform", "translate(" + radius + "," + radius + ")");

      // Clock face
      clock.append("svg:circle").attr("class", "clockface").attr("r", radius - strokeWidth).attr("fill", clockFillColor).attr("stroke", clockBorderColor).attr("stroke-width", strokeWidth);

      // Minute hand before transition
      minutePosition = d3.svg.arc().innerRadius(0).outerRadius(2 / 3 * radius).startAngle(0).endAngle(0);
      minutePositionFinal = d3.svg.arc().innerRadius(0).outerRadius(2 / 3 * radius).startAngle(function (d) {
        return scaleMins(+d.value);
      }).endAngle(function (d) {
        return scaleMins(+d.value);
      });

      // Hour hand before transition
      hourPosition = d3.svg.arc().innerRadius(0).outerRadius(1 / 2 * radius).startAngle(0).endAngle(0);
      hourPositionFinal = d3.svg.arc().innerRadius(0).outerRadius(1 / 2 * radius).startAngle(function (d) {
        return scaleHours(+d.value % 12) + scaleBetweenHours(hourPositionOffset);
      }).endAngle(function (d) {
        return scaleHours(+d.value % 12) + scaleBetweenHours(hourPositionOffset);
      });

      // Add clockhands to the clockface
      clockhand = clock.selectAll(".clockhand").data(data).enter().append("svg:path").attr("class", "clockhand").attr("stroke", clockHandColor).attr("stroke-width", strokeWidth).attr("stroke-linecap", "round").attr("stroke-linejoin", "round").attr("fill", "none");
      if (transitionEnabled) {
        clockhand.attr("d", function (d) {
          if (d.unit === "minutes") {
            hourPositionOffset = +d.value;
            return minutePosition();
          } else if (d.unit === "hours") {
            return hourPosition();
          }
        }).transition().delay(333).duration(666).attrTween("transform", tween);
      } else {
        clockhand.attr("d", function (d) {
          if (d.unit === "minutes") {
            hourPositionOffset = +d.value;
            return minutePositionFinal(d);
          } else if (d.unit === "hours") {
            return hourPositionFinal(d);
          }
        });
      }
      function tween(d, i, a) {
        if (d.unit === "minutes") {
          return d3.interpolate("rotate(0)", "rotate(" + scaleMins(+d.value) * (180 / Math.PI) + ")");
        } else if (d.unit === "hours") {
          return d3.interpolate("rotate(0)", "rotate(" + (scaleHours(+d.value % 12) + scaleBetweenHours(hourPositionOffset)) * (180 / Math.PI) + ")");
        }
      }

      // Center dial circle
      return clock.append("svg:circle").attr("class", "centerdot").attr("r", 1.5).attr("fill", "#fff").attr("stroke", clockCenterColor).attr("stroke-width", strokeWidth);
    },
    postCreate: function () {
      this.render();
    }
  });
});