define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request/notify", "mojo/widgets/InlineToast"], function (declare, lang, notify, InlineToast) {
  return declare([InlineToast], {
    message: "Saved",
    variant: "dark",
    postCreate: function () {
      this.inherited(arguments);
      notify("done", lang.hitch(this, function (response) {
        // Only continue on successful saves
        if (response.status !== 200) {
          return;
        }

        // Checks if response was a save response
        var possibleSaveUrls = ["new-block", "update-block", "update-all-blocks", "remove-block"];
        var wasLikelySaveResponse = possibleSaveUrls.some(function (url) {
          return response.url.includes(url);
        });

        // Run the animation
        if (wasLikelySaveResponse) {
          this.showToast();
        }
      }));
    }
  });
});