define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"./ValidationTextBox", "./ComboBoxMixin"], function (declare, ValidationTextBox, ComboBoxMixin) {
  // module:
  //		dijit/form/ComboBox

  return declare("dijit.form.ComboBox", [ValidationTextBox, ComboBoxMixin], {
    // summary:
    //		Auto-completing text box
    //
    // description:
    //		The drop down box's values are populated from an class called
    //		a data provider, which returns a list of values based on the characters
    //		that the user has typed into the input box.
    //		If OPTION tags are used as the data provider via markup,
    //		then the OPTION tag's child text node is used as the widget value
    //		when selected.  The OPTION tag's value attribute is ignored.
    //		To set the default value when using OPTION tags, specify the selected
    //		attribute on 1 of the child OPTION tags.
    //
    //		Some of the options to the ComboBox are actually arguments to the data
    //		provider.
  });
});