define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/DisplayToggle", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user"], function (declare, DisplayToggle, domClass, user) {
  return declare([DisplayToggle], {
    init: function () {
      this.inherited(arguments);
    },
    /**
     * switchToGrid will toggle the grid/list view of a List
     * @param {Boolean} showGrid - true for grid, false for list
     */
    switchGrid: function (showGrid) {
      // target is pass in as a string, and when fileList is not ready, this.target remains a string.
      // first pass of opening will only set the displayGrid if the target doesn't exist.
      if (typeof this.target !== "string") {
        user.saveSetting("filemanager_show_grid", showGrid);
        var parent = this.target.domNode.parentNode;
        this._toggleGridClasses(showGrid, parent);
      }
      this.displayGrid = showGrid;
    },
    _showGrid: function (showGrid) {
      this.inherited(arguments);
      var targetParentNode = this.target.domNode.parentNode;
      this._toggleGridClasses(showGrid, targetParentNode);
    },
    /**
     *
     * @param {Boolean} showGrid decided to show grid(true) or list(false)
     * @param {Element} targetParentNode is the parent domElement to add/remove grid-active class
     */
    _toggleGridClasses: function (showGrid, targetParentNode) {
      // Remove over-opinionated classname (Can be removed after grid-slat-module dies)
      domClass.remove(targetParentNode, "grid-active");

      // Toggle top-level classes necessary based on whether or not to show the grid.
      domClass.toggle(targetParentNode, "v-gridView--active", showGrid);
      domClass.toggle(targetParentNode, "v-listView--active", !showGrid);
    }
  });
});