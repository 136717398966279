define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo/text!./templates/select.html", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dijit/form/Select"], function (declare, _Part, _Templated, tpl, array, sync, Select) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    label: "",
    alwaysVisible: false,
    init: function () {
      var options = [];
      array.forEach(this.values, function (item) {
        options.push({
          label: item[1],
          value: item[0]
        });
      });
      this.select = new Select({
        options: options,
        onChange: this.onChange
      }, this.select);
      this.select.startup();
      this.own(this.select);
      if (!this.syncAttr) {
        throw new Error("syncAttr must be specified");
      } else {
        this.own(sync(this.target, this.syncAttr, this.select, "value"));
      }
    },
    canDisplay: function () {
      return this.alwaysVisible || this.inherited(arguments);
    },
    onChange: function (value) {}
  });
});