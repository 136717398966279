/**
 * @class mojo.app.lists.Import
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Tag", "./Group"], function (declare, Tag, Group) {
  var Import = declare(null, {
    /**
     * @type Number
     */
    importId: null,
    /**
     * @type mojo.app.lists.Tag[]
     */
    additionalTags: null,
    /**
     * @type mojo.app.lists.Group[]
     */
    additionalGroups: null,
    /**
     * @type Boolean
     */
    isReady: null,
    /**
     * @param {Number} importId Identifier for import
     * @param {mojo.app.lists.Tag[]} additionalTags Any additional tags that will be added to imported contacts
     * @param {mojo.app.lists.Group[]} additionalGroups Any additional groups that will be added to imported contacts
     * @param {Boolean} isReady True if the import can be completed, false if there are errors that should block the user
     */
    constructor: function (importId, additionalTags, additionalGroups, isReady) {
      this.importId = importId;
      this.additionalTags = additionalTags;
      this.additionalGroups = additionalGroups;
      this.isReady = isReady;
    }
  });
  Import.fromView = function (view) {
    var importId = view.import_id;
    var additionalTags = view.additional_tags.map(Tag.fromView);
    var additionalGroups = view.additional_groups.map(Group.fromView);
    var isReady = view.is_ready;
    return new Import(importId, additionalTags, additionalGroups, isReady);
  };
  return Import;
});