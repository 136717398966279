define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/widgets/ecommerce/ProductPicker", "./_Block", "./_ButtonMixin", "./_ImageMixin"], function (declare, lang, mUrl, ProductPicker, _Block, _ButtonMixin, _ImageMixin) {
  var MAX_PRODUCTS = 4;
  var Product = declare(null, {
    id: null,
    buttonLinkType: "product",
    title: "",
    imageUrl: "",
    addToCartUrl: "",
    productUrl: "",
    buttonEnabled: true,
    buttonText: "View",
    descriptionEnabled: false,
    description: "",
    constructor: function (params) {
      lang.mixin(this, params);
      if (this.imageUrl) {
        this.imageUrl = mUrl.addProxy(this.imageUrl);
      }
      if (!this.hasValidUrl()) {
        this.buttonEnabled = false;
      }
    },
    getButtonLinkUrl: function () {
      var url;
      if (this.buttonLinkType && this.buttonLinkType === "cart") {
        url = this.addToCartUrl;
      }
      if (!url) {
        url = this.productUrl;
      }
      return url;
    },
    hasValidCartUrl: function () {
      return this.addToCartUrl && this.addToCartUrl !== this.productUrl;
    },
    hasValidUrl: function () {
      return this.productUrl || this.addToCartUrl;
    }
  });
  return declare([_Block, _ButtonMixin, _ImageMixin], {
    type: "product",
    _name: "Product",
    _ecommOnly: true,
    _editingTypes: ["campaign", "page"],
    constructor: function (model) {
      this.styles = [{
        "name": "Product title",
        "selector": ".mcnProductTitle",
        "props": {
          "color": {
            "value": "#202020"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "16px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": "100%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }, {
        "name": "Product price",
        "selector": ".mcnProductPrice",
        "props": {
          "color": {
            "value": ""
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "14px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "normal"
          },
          "line-height": {
            "value": "100%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }].concat(this._getButtonStyles(model._campaignRenderingType));
      this.styles = this.styles.concat(this._getImageStyles());

      // vertical || horizontal
      this.layout = "vertical";
      this.products = [];

      // global settings for all buttons.
      this.buttonWidth = "100%";
      this.buttonAlign = "center";
    },
    /**
     * Override the base behavior to convert the list of products into
     * the model defined at the top of this module.
     */
    postscript: function () {
      this.inherited(arguments);
      this.products = this.products.map(function (meta) {
        return new Product(meta);
      });
    },
    addProduct: function () {
      var self = this;
      return ProductPicker.chooseProduct().then(function (products) {
        products.forEach(function (product) {
          self.products.push(self._createNewProduct(product));
          // set here is used to fire change events
          self.updateProducts();
        });
        self.save();
      });
    },
    addProductFromStore: function (store) {
      var self = this;
      return ProductPicker.chooseProductFromStore(store).then(function (products) {
        products.forEach(function (product) {
          self.products.push(self._createNewProduct(product));
          // set here is used to fire change events
          self.updateProducts();
        });
        self.save();
      });
    },
    /**
     * Replace the product in the given index. It resets all the attributes with the exception of
     * description and the button text.
     *
     * @param {number} index - the index of the product we want to change
     * @returns {dojo/Promise} - a promise that is successful when the user chosen a product
     */
    replaceProduct: function (index) {
      var self = this;
      return ProductPicker.chooseProduct().then(function (products) {
        // Replacement is always one product
        var meta = products[0];
        if (meta) {
          var oldProduct = self.products[index];
          var newProduct = self._createNewProduct(meta);
          if (oldProduct) {
            newProduct.description = oldProduct.description;
            newProduct.buttonText = oldProduct.buttonText;
          }
          self.products[index] = newProduct;
          self.updateProducts();
          self.save();
        }
      });
    },
    addTopSellersFromStore: function (store) {
      var self = this;
      var storeId = store || this.storeId;
      return ProductPicker.chooseTopSellersFromStore(storeId).then(function (products) {
        var top4 = products.slice(0, 4);
        var top4Products = top4.map(function (meta) {
          return self._createNewProduct(meta);
        });
        self.set("products", top4Products);
        self.save();
      });
    },
    removeProductByIndex: function (index) {
      if (this.products[index]) {
        this.products.splice(index, 1);
        this.updateProducts();
        this.save();
      }
    },
    hasMaxProducts: function () {
      return this.products.length >= MAX_PRODUCTS;
    },
    /**
     * Forces a refresh of products with itself to fire the onChange events
     */
    updateProducts: function () {
      this.set("products", this.products);
    },
    /**
     * Takes the product meta from the ProductPicker and correctly instantiate a Product defined in this module
     *
     * @param {Object} productMeta - a product meta that is returned by ProductPicker
     * @returns {Product} a new instance of Product
     * @private
     */
    _createNewProduct: function (productMeta) {
      // put up a warning that there is no link to the product because of Square, and remove the button, add a way to link the product somewhere.
      return new Product({
        "id": productMeta.product_id,
        "title": productMeta.title,
        "price": productMeta.price,
        "imageUrl": productMeta.image_url,
        "productUrl": productMeta.product_url,
        "addToCartUrl": productMeta.add_to_cart_url
      });
    }
  });
});