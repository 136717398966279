define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/clipboard", "vendor/lodash-amd/template", "mojo/utils/date", "mojo/widgets/campaigns/Slat", "mojo/app/campaigns", "mojo/app/surveys", "mojo/utils", "mojo/utils/I18nTranslation", "mojo/user", "mojo/lib/flags", "mojo/context"], function (declare, clipboard, template, dateUtils, Slat, campaigns, surveys, utils, I18nTranslation, user, flags, context) {
  return declare([Slat], {
    channelIcon: "c-channelIcon--survey",
    statusContent: {
      "draft": {
        label: "Draft"
      },
      "on": {
        label: "Published",
        badge: "success",
        description: "<% if (publishedAt) { %>Published at <strong>${publishedAt}</strong><% } %>"
      },
      "off": {
        label: "Unpublished"
      }
    },
    typeContent: {
      "survey": {
        label: "Survey"
      }
    },
    statsOrder: [],
    statsContent: {},
    actions: {
      "view": {
        label: "View"
      },
      "edit": {
        label: "Edit"
      },
      "replicate": {
        label: "Replicate",
        onClick: function () {
          surveys.replicate(this.model.surveyId).then(function (data) {
            window.location.href = "/lists/surveys/edit?survey_id=" + data.replicaSurveyId;
          });
        }
      },
      "copy_url": {
        label: "Copy URL",
        onClick: function () {
          this.copyUrl();
        }
      },
      "report": {
        label: "View Report"
      }
    },
    statusContentTranslated: {},
    typeContentTranslated: {},
    statsContentTranslated: {},
    actionsTranslated: {},
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "draft": {
          label: translationObject.campaigns_draft
        },
        "on": {
          label: translationObject.campaigns_published,
          badge: "success",
          description: "<% if (publishedAt) { %>" + I18nTranslation.translate("campaigns_survey_published_at", ["{publishedAt}"]) + "<% } %>"
        },
        "off": {
          label: translationObject.campaigns_unpublished
        }
      };
      this.typeContentTranslated = {
        "survey": {
          label: translationObject.campaigns_survey
        }
      };
      this.actionsTranslated = {
        "view": {
          label: translationObject.campaigns_actions_view
        },
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate,
          onClick: function () {
            surveys.replicate(this.model.surveyId).then(function (data) {
              window.location.href = "/lists/surveys/edit?survey_id=" + data.replicaSurveyId;
            });
          }
        },
        "copy_url": {
          label: translationObject.campaigns_actions_copy_url,
          onClick: function () {
            this.copyUrl();
          }
        },
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action) {
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Surveys");
            }
          }
        }
      };
    },
    buildAdditionalMetaData: function () {
      var status = this.model.status;
      var content = this.statusContent[status];
      if (!content) {
        return;
      }
      if (content.description) {
        this.node_additionalMetaData.innerHTML = template(content.description)({
          publishedAt: this.model.publishedAt ? dateUtils.getDateString(this.model.publishedAt) : null
        });
      }
    },
    buildAdditionalMetaDataTranslated: function () {
      var status = this.model.status;
      var content = this.statusContentTranslated[status];
      if (!content || !content.description) {
        return;
      }
      var publishedAt = this.model.publishedAt;
      if (publishedAt) {
        publishedAt = context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(publishedAt, "full", true) : dateUtils.getDateString(publishedAt);
      }
      this.node_additionalMetaData.innerHTML = template(content.description)({
        publishedAt: publishedAt || null
      });
    },
    /**
     * Copy the url for this page to the clipboard
     */
    copyUrl: function () {
      surveys.getPublicUrl(this.model.surveyId).then(function (data) {
        clipboard.copy(data.url);
        utils.toast('The "' + this.model.title + '" URL was was copied to your clipboard. Go forth and share!');
      }.bind(this));
    }
  });
});