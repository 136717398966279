define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/focus", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/segment-overlay.html",
// Widgets
"mojo/analytics/parts/_Part", "./SegmentOverlayLineItem", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, lang, domAttr, domClass, domConstruct, on, focusUtil, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, overlayTplStr, _Part, SegmentOverlayLineItem, I18nTranslation, flags, context, ready) {
  return declare([_Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: overlayTplStr,
    target: null,
    listId: null,
    // Segments with name matching search text
    searchResults: [],
    // Master Store of all segments associated with this list
    segmentStore: null,
    segments: [],
    selectSegment: null,
    viewSegmentsUrl: null,
    viewAllSegments: "View All Segments",
    findSegment: "Find Segment",
    noSegmentsFound: "No segments found",
    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize();
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          this.viewAllSegments = I18nTranslation.translate("audience_management_view_all_segments");
          this.findSegment = I18nTranslation.translate("audience_management_find_segment");
          this.noSegmentsFound = I18nTranslation.translate("audience_management_no_segments_found");
        }
      }
    },
    init: function () {
      this.setupSegmentData();
      this.populateSegmentLineItems();
      this.setupSearch();
      // NOTE: Navigates to segment url via the click handler
      // below so no need to set view segment URL. Tracking
      // kept getting wiped out and custom dimensions were
      // causing issues inline.
      // this.setViewSegmentsUrl();

      on(this.manageSegmentsButton, "click", lang.hitch(this, function () {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'navigation',
          eventAction: 'cta',
          eventLabel: 'inline',
          dimension53: this.manageSegmentsButton.innerText,
          dimension54: mUrl.toUrl("/lists/segments?id=" + this.listId),
          dimension55: 'view segment submenu',
          dimension56: 'navigate to list of all segments'
        });
        window.top.location = mUrl.toUrl("/audience/segments?id=" + this.listId);
      }));
    },
    /**
     * Observe the segments Store and initialize fields
     */
    setupSegmentData: function () {
      this.queryAndSortStore();
      var segmentsQuery = this.segmentStore.query();
      segmentsQuery.observe(lang.hitch(this, function () {
        this.queryAndSortStore();
        this.populateSegmentLineItems();
      }), true);
    },
    queryAndSortStore: function () {
      this.segments = this.segmentStore.query({}, {
        sort: [{
          attribute: "name"
        }]
      });
      this.searchResults = this.segments;
    },
    /**
     * Create segment line item UIs and attach relevant event handlers
     */
    populateSegmentLineItems: function () {
      domConstruct.empty(this.segmentListContainer);
      if (this.segments.length < 1) {
        domClass.remove(this.noSegmentsContainer, "hide");
        domClass.add(this.segmentNavContainer, "hide");
        return;
      } else {
        domClass.add(this.noSegmentsContainer, "hide");
        domClass.remove(this.segmentNavContainer, "hide");
      }
      if (this.searchResults.length > 0) {
        domClass.add(this.noSearchResults, "hide");
        var self = this;
        this.searchResults.forEach(function (segment) {
          var segmentLineItem = new SegmentOverlayLineItem({
            "segment": segment,
            "segmentCreatedDate": segment.createdDate
          });
          segmentLineItem.placeAt(self.segmentListContainer, "last");
          segmentLineItem.on("selectSegment", lang.hitch(self, function () {
            self.onSelectSegment(segment);
          }));
          segmentLineItem.on("editSegment", lang.hitch(self, function () {
            self.onEditSegment(segment);
          }));
        });
      } else {
        domClass.remove(this.noSearchResults, "hide");
      }
    },
    /**
     * Set up event handlers to support segment search
     */
    setupSearch: function () {
      on(this.segmentSearchInput, "keyup", lang.hitch(this, "searchSegmentNames"));
    },
    /**
     * Perform case-insensitive local search for the user-entered text in segment names
     */
    searchSegmentNames: function () {
      var searchText = this.segmentSearchInput.value;
      if (this.segments && searchText) {
        this.searchResults = this.segments.filter(function (segment) {
          return segment.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        });
      } else if (!searchText) {
        this.searchResults = this.segments;
      }
      this.populateSegmentLineItems();
    },
    /**
     * Construct the manage segments URL and insert into DOM
     */
    setViewSegmentsUrl: function () {
      var viewSegmentsUrl = mUrl.toUrl("/lists/segments?id=" + this.listId);
      domAttr.set(this.manageSegmentsButton, "href", viewSegmentsUrl);
    },
    /**
     * Reset the state of the overlay
     */
    refresh: function () {
      this.segmentSearchInput.value = "";
      this.segmentListContainer.scrollTop = 0;
      this.searchResults = this.segments;
      this.populateSegmentLineItems();
      setTimeout(lang.hitch(this, function () {
        focusUtil.focus(this.segmentSearchInput);
      }), 100);
    },
    /**
     * Adjust visible segment and session on line item click
     * @param {object} segment to select
     */
    onSelectSegment: function (segment) {
      if (segment.status !== "finished") {
        this.target.setSegment(segment);
      } else {
        this.selectSegment(segment);
      }
    },
    /**
     * Show the UI for editing selected segment
     */
    onEditSegment: function () {},
    /**
     * Override _Part canDisplay to show when there are no target results
     * @returns {boolean} canDisplay value
     */
    canDisplay: function () {
      return true;
    },
    /**
     * Handle for open event
     * setTimeout is needed here because focus is immediately being shifted to the clicked button
     */
    onOpen: function () {
      this.refresh();
    },
    /**
     * Override to prevent overlay from closing on click of line items
     */
    onExecute: function () {}
  });
});