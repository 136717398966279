define(["dijit/registry", "mojo/url", "mojo/SinglePageApplicationUtils", "mojo/context"], function (registry, url, SinglePageAppUtils, context) {
  var revokeAccess = {
    clientId: null,
    agencyId: null,
    loginId: null,
    /**
     * Shows remove account dialog and saves client id for later
     * @param {number} pClientId - Unique id of client account from backend
     */
    showRemoveAccountConfirmation: function (pClientId) {
      this.clientId = pClientId;
      registry.byId("remove-account-dialog").show();
    },
    /**
     * Sends to remove client account
     */
    doRemoveAccount: function () {
      if (window.self !== window.top && context.flagIsOn("xp.fix_chunk_errors_account")) {
        window.top.location = url.toUrl("/account/clients/remove-account", {
          "client_id": this.clientId
        });
      } else {
        window.location = url.toUrl("/account/clients/remove-account", {
          "client_id": this.clientId
        });
      }
    },
    /**
     * Shows remove user dialog and saves client id and login id for later
     * @param {number} pClientId - Unique id of client account from backend
     * @param {number} pLoginId - Unique id of user to be deleted from backend
     */
    showRemoveUserConfirmation: function (pClientId, pLoginId) {
      this.clientId = pClientId;
      this.loginId = pLoginId;
      registry.byId("remove-user-dialog").show();
    },
    /**
     * Sends to remove user from client account
     */
    doRemoveUser: function () {
      if (window.self !== window.top && context.flagIsOn("xp.fix_chunk_errors_account")) {
        window.top.location = url.toUrl("/account/clients/revoke-user", {
          "client_id": this.clientId,
          "login_id": this.loginId
        });
      } else {
        window.location = url.toUrl("/account/clients/revoke-user", {
          "client_id": this.clientId,
          "login_id": this.loginId
        });
      }
    },
    /**
     * Shows remove account dialog and saves agency id for later
     * @param {number} pAgencyId - Unique id of agency account from backend
     */
    showRemoveAgencyDialog: function (pAgencyId) {
      this.agencyId = pAgencyId;
      registry.byId("remove-agency-account-dialog").show();
    },
    /**
     * Sends to remove agency account
     */
    doRemoveAgencyAccount: function () {
      var path = SinglePageAppUtils.prefixPath("/account/users/remove-account");
      if (window.self !== window.top && context.flagIsOn("xp.fix_chunk_errors_account")) {
        window.top.location = url.toUrl(path, {
          "agency_id": this.agencyId
        });
      } else {
        window.location = url.toUrl(path, {
          "agency_id": this.agencyId
        });
      }
    },
    /**
     * Shows remove user dialog and saves agency id and login id for later
     * @param {number} pAgencyId - Unique id of agency account from backend
     * @param {number} pLoginId - Unique id of user to be deleted from backend
     */
    showRemoveAgencyUserDialog: function (pAgencyId, pLoginId) {
      this.agencyId = pAgencyId;
      this.loginId = pLoginId;
      registry.byId("remove-agency-user-dialog").show();
    },
    /**
     * Sends to remove agency user from account
     */
    doRemoveAgencyUser: function () {
      var path = SinglePageAppUtils.prefixPath("/account/users/revoke-user");
      if (window.self !== window.top && context.flagIsOn("xp.fix_chunk_errors_account")) {
        window.top.location = url.toUrl(path, {
          "agency_id": this.agencyId,
          "login_id": this.loginId
        });
      } else {
        window.location = url.toUrl(path, {
          "agency_id": this.agencyId,
          "login_id": this.loginId
        });
      }
    }
  };
  return revokeAccess;
});