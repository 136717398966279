/**
 * Custom slider for MVT winner test size selection.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/SliderWithLabel", "dijit/form/HorizontalRule", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, SliderWithLabel, HorizontalRule, domConstruct, domStyle, array, lang, on) {
  return declare([SliderWithLabel], {
    tickMarks: null,
    markContainer: null,
    markContainerTpl: '<div class="dijitRuleContainer dijitRuleContainerH"></div>',
    markTpl: '<div class="dijitRuleMark dijitRuleMarkH" style="left:{interval}%;"></div>',
    // This is the minimum for the slider, but we won't go below 10%
    minimum: 0,
    maximum: 100,
    discreteValues: 101,
    intermediateChanges: true,
    showButtons: false,
    // The lowest we're allowed to test.
    minTestable: 10,
    // The number of combinations currently selected for MVT
    numCombinations: 0,
    postCreate: function () {
      this.inherited(arguments);
      this.tickMarks = [];
      this.watch("numCombinations", lang.hitch(this, "_updateTicks"));
      this.own(on(this, "change", lang.hitch(this, "_updateTicks")));
      this._updateTicks();
    },
    /**
     * Override the default implementation to stop value from going to the lowest which is minTestable.
     */
    _setValueAttr: function (val) {
      this.inherited(arguments, [val >= this.minTestable ? val : this.minTestable]);
    },
    _updateTicks: function () {
      var tickIntevals = [];
      if (this.numCombinations > 1) {
        var step = this.value / this.numCombinations;
        for (var i = 1; i < this.numCombinations; i++) {
          tickIntevals.push(step * i);
        }
      }
      this._showTicks(tickIntevals);
    },
    /**
     * Allow adding arbitrary rule marks to the slider.
     *
     * @param {array} intervals - an array of integers signifying the % we want to drop a rule mark.
     */
    _showTicks: function (intervals) {
      // Only create container if needed.
      if (!this.markContainer) {
        this.markContainer = domConstruct.place(this.markContainerTpl, this.bottomDecoration);
      }

      // Hide excess tick-marks.
      if (intervals.length < this.tickMarks.length) {
        for (var start = intervals.length - 1; start < this.tickMarks.length; start++) {
          if (this.tickMarks[start]) {
            domStyle.set(this.tickMarks[start], "display", "none");
          }
        }
      }
      array.forEach(intervals, lang.hitch(this, function (interval, index) {
        if (this.tickMarks[index]) {
          domStyle.set(this.tickMarks[index], {
            "left": interval + "%",
            "display": ""
          });
        } else {
          var tickHTML = lang.replace(this.markTpl, {
            interval: interval
          });
          this.tickMarks[index] = domConstruct.place(tickHTML, this.markContainer);
        }
      }));
    }
  });
});