/**
 * Base class for mc:edit editors. Mostly code to handle keeping two models in-sync.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "../_EditorMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Widget, _Templated, _EditorMixin, lang, array, mvcSync) {
  return declare([_Widget, _Templated, _EditorMixin], {
    widgetsInTemplate: true,
    constructor: function () {
      this._watches = [];
      this._syncs = [];
      this._skipSave = false;
    },
    /**
     * Remove any sync calls & watch calls. Part of Dijit's lifecycle
     */
    uninitialize: function () {
      array.forEach(this._watches, function (w) {
        w.unwatch();
      });
      array.forEach(this._syncs, function (s) {
        s.remove();
      });
      this._watches = [];
      this._syncs = [];
      this.inherited(arguments);
    },
    /**
     * Watch for a particular attribute on the model to change. Uses the watch function in dojo.Stateful.
     * Editors need to call this instead of directly watching the model to avoid some really really bad behavior from
     * not cleaning up after editors are deleted.
     *
     * @param attr
     * @param f function on what to do with watches
     */
    watch: function (attr, f) {
      var watch = attr ? this.mcedit.watch(attr, f) : this.mcedit.watch(f);
      this._watches.push(watch);
    },
    /**
     * Syncs a particular attr in the block model and keeps track of the sync handler to remove when the editor is destroyed.
     * Similar reasoning as this.watch
     *
     * See dojox/mvc/sync
     */
    sync: function (attr, obj, objAttr) {
      var sync = mvcSync(this.mcedit, attr, obj, objAttr);
      this._syncs.push(sync);
    },
    saveAndHide: function () {
      this.done();
    },
    cancelAndHide: function () {
      this._skipSave = true;
      this.done();
    },
    save: function (sync) {
      return this.mcedit.save(sync);
    },
    /**
     * function that gets called when the editor is closed and no longer in the user's view. This can happen when
     * a user clicks on another block or when the hide function is invoked.
     */
    onClose: function () {
      if (!this._skipSave) {
        this.save().then(lang.hitch(this, 'destroy'));
      } else {
        this.destroy();
      }
    },
    onDataUpdate: function (data) {},
    onShow: function () {}
  });
});