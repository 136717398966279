define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo/text!./templates/ApiEditor/editor.html", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'codemirror4/lib/codemirror', "mojo/neapolitan/widgets/EditorContainer", 'codemirror4/mode/htmlmixed/htmlmixed'], function (declare, _Base, tmpl, domConstruct, lang, CodeMirror) {
  return declare([_Base], {
    type: 'api',
    templateString: tmpl,
    postCreate: function () {
      this.inherited(arguments);
      this.codeEditor = CodeMirror(this.editorContainer, {
        value: this.block.html,
        mode: "htmlmixed",
        autofocus: true,
        tabMode: "indent",
        indentUnit: 2,
        smartIndent: false,
        autoClearEmptyLines: true,
        lineWrapping: true,
        lineNumbers: true
      });

      // Set readOnly attribute based on model value
      this.codeEditor.options.readOnly = !this.block.editable ? 'nocursor' : false;
      this.codeEditor.on("change", lang.hitch(this, function () {
        this.block.set("html", this.codeEditor.getValue());
      }));
    },
    onShow: function () {
      this.codeEditor.refresh();
      this.codeEditor.focus();
    }
  });
});