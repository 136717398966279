define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dom, query, xhr) {
  var chatter = {
    _page: 1,
    _type: 'all',
    /**
     * Validate the chimp chatter preferences
     * @param {mojo.widgets.LiveDialog} chatter preferences dialog box to update from
     * @param {String} chatter_post_url the url to post the updates to
     */
    validatePrefs: function (dialog, chatter_post_url) {
      var prefs = dialog.getValues();
      var i = 0;
      for (var type in prefs) {
        if (prefs[type].length) {
          i++;
        }
      }

      // If there are less than 1 selected
      if (i < 1) {
        //Show error message
        query('#no-prefs-error').removeClass('hide');
      } else {
        //If there was more than one selected, actually update the preferences and hide the modal
        mojo.chatter.updatePrefs(prefs, chatter_post_url);
        dijit.byId('chatter-prefs').hide();
      }
    },
    /**
     * Update the chimp chatter preferences
     * @param prefs delivered from validatePrefs
     * @param {String} chatter_post_url the url to post the updates to
     */
    updatePrefs: function (prefs, chatter_post_url) {
      var update_prefs = {};
      for (var type in prefs) {
        if (prefs[type].length) {
          update_prefs[type] = 1;
        }
      }

      // Submit via xhr
      xhr.post(chatter_post_url, {
        'data': update_prefs,
        'handleAs': 'text'
      }).then(function (response) {
        dom.byId('chatter-block').innerHTML = response;
      });
    },
    /**
     * Navigate the chimp chatter page to the given major type
     * @param {String} type major type name to filter on (campaigns, lists, etc.)
     */
    navigate: function (type) {
      dom.byId('chatter-container').innerHTML = 'loading...';
      query('#chatter-nav .current').removeClass('current');
      query('#chatter-nav .chatter-' + type).addClass('current');
      query('#chatter-feed-url').attr('href', web_root + 'chatter/feed-' + type + '?u=' + user_private_id);
      xhr.get(web_root + 'chatter/', {
        'query': {
          'type': type
        },
        'handleAs': 'text'
      }).then(function (html) {
        dom.byId('chatter-list').innerHTML = html;
        chatter._page = 1;
        chatter._type = type;
        if (/nothing to chatter/.test(html)) {
          dom.byId('chatter-more-button').style.display = 'none';
        } else {
          dom.byId('chatter-more-button').style.display = '';
        }
      });
    },
    showMore: function () {
      xhr.get(web_root + 'chatter/', {
        'query': {
          'type': chatter._type,
          'page': ++chatter._page
        },
        'handleAs': 'text'
      }).then(function (html) {
        if (/nothing to chatter/.test(html)) {
          dom.byId('chatter-more-button').style.display = 'none';
        } else {
          dom.byId('chatter-list').innerHTML += html;
        }
      });
    },
    clearImage: function (img) {
      img.onerror = function () {};
      var parent_el = img.parentNode;
      while (parent_el && parent_el.nodeName.toLowerCase() != 'li') {
        parent_el = parent_el.parentNode;
      }
      if (!parent_el) return;
      parent_el.parentNode.removeChild(parent_el);
    }
  };
  return chatter;
});