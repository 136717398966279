/**
 * Widget built to handle the restarting of paused automations due to billing
 * or if a user downgraded from Premium / Standard to Free / Essential
 *
 * Documentation --> https://confluence.mailchimp.com/pages/viewpage.action?spaceKey=AT&title=Documentation%3A+Restart+Paused+Automations+Widget
 * @module mojo/widgets/automation/RestartAutomation
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dijit/registry", "mojo/url", "mojo/utils", "mojo/lib/flags", "mojo/user", "mojo/authz", "./RestartAutomationDialog", "mojo/widgets/automation/PostUpgradeDialog", "mojo/widgets/UpgradeActionBlock", "mojo/widgets/Renderable", "dojo/text!./templates/RestartAutomation.html", "dojo/text!./templates/PostUpgradeReviewDialog.html"], function (declare, request, domClass, domConstruct, Dialog, registry, mUrl, mUtils, flags, user, Authz, RestartDialogContent, PostUpgradeDialog, UpgradeBlock, Renderable, tplStr) {
  // Pause Reason "Constants"
  var PAUSE_REASON_DOWNGRADE = "downgrade";
  var PAUSE_REASON_BILLING = "billing";

  // Purpose of Notification to be passed to backend
  var NOTIFICATION_PURPOSE_DOWNGRADE_CAMPAIGN_PAGE = "downgrade_campaign_page_cta";
  var NOTIFICATION_PURPOSE_RESTART_DOWNGRADE_AUTOMATIONS = "downgrade_automation_restart";
  var NOTIFICATION_PURPOSE_RESTART_BILLING_AUTOMATIONS = "billing_automation_restart";
  return declare([Renderable, Authz], {
    templateString: tplStr,
    linkLocation: null,
    postEndpoint: "/automations/confirm-restart-post",
    campaignData: {},
    pauseReason: null,
    notificationPurpose: null,
    // Keep under 270 characters.
    upgradeBody: {
      title: "Your active automated email series have been paused",
      copy: "If you want to continue sending your automated email series, get the Standard or Premium plan."
    },
    // Copy for feedback block title, copy, cta
    feedbackBody: {
      title: "Some campaigns are paused",
      copy: "Review your paused campaigns",
      cta: "Review",
      dismissText: "No Thanks"
    },
    // Boolean checks to see if user has any downgraded or billing paused automations. Default: false
    hasDowngradedPausedSeriesAutomations: false,
    hasBillingPausedAutomations: false,
    // Boolean to determine whether to show upgrade block or not based off user access.
    // Passed Down From Controller - Default: false
    showUpgradeBlock: false,
    postMixInProperties: function () {
      // Set the reason why automations were paused
      this.pauseReason = this._getPauseReason();
      this.notificationPurpose = this._getNotificationPurpose();
      this.notificationEndpoint = this.pauseReason === PAUSE_REASON_DOWNGRADE ? "bulk-downgrade-paused-displayed" : "billing-paused-notifications-displayed";
      if (this.pauseReason === PAUSE_REASON_DOWNGRADE) {
        this.feedbackBody.title = "Restart your automated email series";
        this.feedbackBody.copy = "Now that you've upgraded, you can review and resume your automated email series that were previously paused";
      }
    },
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      // Call out to the backend to mark the notification as displayed
      request.post(mUrl.toUrl("automations/" + this.notificationEndpoint, {
        "notification_purpose": this.notificationPurpose
      }), {
        handleAs: "json"
      }).then(this._handleNotificationDisplayPostResponse.bind(this));

      // If a user is on the free or essential plan with paused series automations, show thu upgrade block
      if (this.showUpgradeBlock && !this.hasDowngradedPausedSeriesAutomations) {
        this._initializeUpgradeBlock();
      } else {
        domClass.remove(this.feedbackBlockContainer, "hide");
      }
    },
    /**
     * Create the upgrade block widget and place it within the DOM. Show the paywall
     * when a user clicks the CTA
     *
     * @private
     */
    _initializeUpgradeBlock: function () {
      this.upgradeBlock = new UpgradeBlock({
        body: this.upgradeBody,
        isDismissable: true,
        dismissConfig: {
          innerText: "No thanks",
          onDismissPressCallback: function () {
            this._handleNotificationDismissal(this.notificationPurpose);
          }.bind(this)
        },
        featureName: "automation",
        gaTrackingCategory: "upsell_moment",
        gaTrackingAction: "button_click",
        gaTrackingLabelProps: {
          upgrade_reason: "restart_automations",
          button_text: "upgrade_now"
        },
        onComplete: function () {
          this._hasSeriesAutomationsAccess().then(function (authzResponse) {
            // If the user upgraded to the correct package
            // and has access to series automations
            if (authzResponse === true) {
              this.upgradeBlock.destroyRecursive();
              this._launchPostUpgradeModal();
            }
          }.bind(this));
        }.bind(this),
        onCtaPressCallback: true
      });
      this.upgradeBlock.placeAt(this.upgradeBlockContainer);
    },
    /**
     * Call back to handle dismissing notifications
     *
     * @private
     */
    _handleNotificationDismissal: function () {
      request.post(mUrl.toUrl("automations/dismiss-notifications", {
        "notification_purpose": this.notificationPurpose
      }), {
        handleAs: "json"
      }).then(this._handleNotificationDismissalPostResponse.bind(this));
    },
    /**
     * Do a quick check on the users access to series / step automations
     *
     * @returns {Promise} Are they on the right package for access?
     * @private
     */
    _hasSeriesAutomationsAccess: function () {
      return this.userMay("automation_step", "create", {
        "step_count": 1
      });
    },
    /**
     * Get the reason why the automations were paused
     *
     * @returns {string} The pause reason "billing, downgrade, or all"
     * @private
     */
    _getPauseReason: function () {
      if (this.hasDowngradedPausedSeriesAutomations && this.hasBillingPausedAutomations) {
        return "all";
      }
      return this.hasDowngradedPausedSeriesAutomations || this.showUpgradeBlock ? PAUSE_REASON_DOWNGRADE : PAUSE_REASON_BILLING;
    },
    /**
     * Checked the passed down parameters to set the notification purpose
     *
     * @private
     */
    _getNotificationPurpose: function () {
      if (this.showUpgradeBlock && !this.hasDowngradedPausedSeriesAutomations) {
        return NOTIFICATION_PURPOSE_DOWNGRADE_CAMPAIGN_PAGE;
      }
      return this.hasBillingPausedAutomations ? NOTIFICATION_PURPOSE_RESTART_BILLING_AUTOMATIONS : NOTIFICATION_PURPOSE_RESTART_DOWNGRADE_AUTOMATIONS;
    },
    /**
     * Reach out to the backend to get the available paused automations and then handle the request
     *
     * @private
     */
    _getPausedAutomations: function () {
      var req = request.get(mUrl.toUrl("automations/confirm-restart", {
        "pause_reason": this.pauseReason
      }), {
        handleAs: "json"
      });
      req.then(this._handlePausedAutomationRequest.bind(this), function (error) {
        // Remove the loading indicator of any buttons if an error occurs
        if (this.postUpgradeModal) {
          this.postUpgradeModal.content.toggleCtaLoadingState();
        }
        domClass.remove(this.reviewAutomationsButton, "button-loading");
        throw new Error("An error occurred trying to retrieve the paused automations. Error: " + error);
      }.bind(this));
    },
    /**
     * Handle the successful request for the data
     *
     * @param {object} responseData - data passed from the backend with each automation info
     * @private
     */
    _handlePausedAutomationRequest: function (responseData) {
      this.campaignData = responseData;

      // If the user hits review from post upgrade modal. Destroy the widget
      if (this.postUpgradeModal) {
        this.postUpgradeModal.destroyRecursive();
      }

      // After retrieving the data launch the modal and pass the content to it.
      this._launchRestartModal();
    },
    /**
     * When user chooses to dismiss the feedback block treat it as if they are
     * restarting automations, but send an empty array to be handled in the backend along
     * with the pauseReason
     *
     * @private
     */
    _handleFeedbackBlockDismiss: function () {
      this._handleNotificationDismissal();
      this._postForm({
        "campaign_ids[]": [],
        "pause_reason": this.pauseReason
      });
    },
    /**
     * Method to handle launching the restart modal. If the performance user flag
     * is not on then just launch the modal else, make a request to get the data.
     *
     * @param {Event} e - Mouse Event on click of review automations CTA
     * @private
     */
    _handleRestartModalLaunch: function (e) {
      e.preventDefault();

      // Add a loading indicator while retrieving the data
      domClass.add(this.reviewAutomationsButton, "button-loading");

      // Make a request to get the campaign data
      this._getPausedAutomations();
    },
    /**
     * Method to launch the restart automation dialog. Creates a new Restart Dialog Widget
     * which contains all the content for the dialog and handles selecting the automations.
     *
     * @private
     */
    _launchRestartModal: function () {
      this.restartDialog = new Dialog({
        title: "Restart automations",
        content: new RestartDialogContent({
          campaigns: this.campaignData,
          onCancel: function () {
            this.restartDialog.onHide();
          }.bind(this),
          onRestart: function (campaignIds) {
            this._postForm({
              "campaign_ids[]": campaignIds,
              "pause_reason": this.pauseReason
            });
          }.bind(this)
        }),
        onHide: function () {
          this.destroyRecursive();
        },
        onError: function () {
          // Call the post error method within the Restart Dialog widget
          this.content.onPostError();
        }
      });

      // Remove the loading indicator
      domClass.remove(this.reviewAutomationsButton, "button-loading");
      this.restartDialog.show();
      setTimeout(function () {
        this.restartDialog.focus();
      }.bind(this), 50);
    },
    /**
     * Method to launch the post upgrade modal. Creates a new feature dialog
     * which gives the user the option yo review the paused automations or revisit later
     *
     * @private
     */
    _launchPostUpgradeModal: function () {
      this.postUpgradeModal = new Dialog({
        "class": "feature-dialog",
        content: new PostUpgradeDialog({
          ctaText: "Review",
          onCtaButtonPress: this._handlePostUpgradeModalCtaBtnPress.bind(this),
          onDismissBtnPress: this._handlePostUpgradeModalDismissBtnPress.bind(this)
        })
      });

      // Grab the image from the feature dialog
      var image = this.postUpgradeModal.content.imageNode;

      // Make sure the image has loaded to center in viewport
      mUtils.ensureContentRendered(image).then(function () {
        this.postUpgradeModal.show();
      }.bind(this));
    },
    /**
     * Respond to the click of the Review CTA. Get the paused automations
     *
     * @private
     */
    _handlePostUpgradeModalCtaBtnPress: function () {
      // Show a loading indicator while retrieving the paused automations
      this.postUpgradeModal.content.toggleCtaLoadingState();
      this._getPausedAutomations();
    },
    /**
     * Respond to the click the dismissal of post upgrade modal
     *
     * @private
     */
    _handlePostUpgradeModalDismissBtnPress: function () {
      this.postUpgradeModal.destroyRecursive();
    },
    /**
     * Post out to the backend an array of the selected automation ids that
     * the user wants to restart
     *
     * @param {object} formData - Object containing an array of the selected campaign ids and pause reason
     * @private
     */
    _postForm: function (formData) {
      var action = request.post(mUrl.toUrl(this.postEndpoint), {
        "handleAs": "json",
        "data": formData
      });
      action.then(this._handleAutomationRestartResponse.bind(this), function (error) {
        this.restartDialog.onError(); /* See RestartAutomationDialog.js */
        throw new Error("An error occurred trying to send the paused automation(s). Error: " + error);
      }.bind(this));
    },
    /**
     * Call back for handling successful post of the campaign ids and handles re-rendering the list
     * and notifying the user of started automations
     *
     * @param {object} response - object holding the restarted campaign ids and their status of started/error
     * @private
     */
    _handleAutomationRestartResponse: function (response) {
      var campaignList = registry.byId("campaigns-list");
      var campaignStatuses = Object.values(response);

      // Check to see if there any restarted campaigns from the response
      if (campaignStatuses.length > 0) {
        // Loop through the response and filter the campaigns with a status of started
        var restartedCount = campaignStatuses.filter(function (automation) {
          return automation.status === "started";
        }).length;
        this.restartDialog.onHide();
        mUtils.toast(restartedCount + " automation(s) successfully restarted");

        // If user is on the campaign index page re render the page
        if (this.linkLocation === "campaign index") {
          campaignList.render();
        }
      }

      // Destroy the entire widget to hide it from the UI
      this.destroyRecursive();
    },
    /**
     * Track in Google Analytics that the user has seen the feedback / upgrade block
     *
     * @param {object} response - Object containing the error response
     * @private
     */
    _handleNotificationDisplayPostResponse: function (response) {
      var eventLabelString;
      var eventActionString;
      switch (this.notificationPurpose) {
        case NOTIFICATION_PURPOSE_DOWNGRADE_CAMPAIGN_PAGE:
          eventActionString = "upgrade block";
          eventLabelString = "after downgrading";
          break;
        case NOTIFICATION_PURPOSE_RESTART_DOWNGRADE_AUTOMATIONS:
          eventActionString = "downgrade restart feedback block";
          eventLabelString = "after upgrading";
          break;
        case NOTIFICATION_PURPOSE_RESTART_BILLING_AUTOMATIONS:
          eventActionString = "billing failure feedback block";
          eventLabelString = "after fixing billing";
          break;
        default:
          throw new Error("Unknown notification purpose: " + this.notificationPurpose);
      }
      if (response.error) {
        throw new Error("An error occurred marking this notification as displayed: " + response.error);
      } else {
        window.ga("send", "event", window.location.pathname, "seen " + eventActionString, eventLabelString);
      }
    },
    /**
     * Track in Google Analytics that the user has dismissed the feedback / upgrade block
     *
     * @param {object} response - Object containing the error response
     * @private
     */
    _handleNotificationDismissalPostResponse: function (response) {
      var eventLabelString;
      var eventActionString;
      switch (this.notificationPurpose) {
        case NOTIFICATION_PURPOSE_DOWNGRADE_CAMPAIGN_PAGE:
          eventActionString = "upgrade block";
          eventLabelString = "after downgrading";
          break;
        case NOTIFICATION_PURPOSE_RESTART_DOWNGRADE_AUTOMATIONS:
          eventActionString = "feedback block";
          eventLabelString = "to bulk restart downgrade paused automations";
          break;
        case NOTIFICATION_PURPOSE_RESTART_BILLING_AUTOMATIONS:
          eventActionString = "feedback block";
          eventLabelString = "to bulk restart billing paused automations";
          break;
        default:
          throw new Error("Unknown notification purpose: " + this.notificationPurpose);
      }
      if (response.error) {
        throw new Error("An error occurred dismissing this notification: " + response.error);
      } else {
        window.ga("send", "event", window.location.pathname, "user dismissed " + eventActionString, eventLabelString);
      }
    }
  });
});