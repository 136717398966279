define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "dijit/Dialog", "mojo/widgets/automation/ArchiveAutomationDialog", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/app/campaigns", "dijit", "mojo/context", "mojo/lib/flags"], function (declare, base, query, on, topic, domClass, domAttr, mUrl, mUtils, Dialog, ArchiveDialogContent, request, registry, campaigns, dijit, context, flags) {
  return declare([base], {
    onReady: function () {},
    showReplicationModal: function (automation_id) {
      var replicateAutomationDialog = new Dialog({
        title: "Replicate Automation",
        href: mUrl.toUrl("/automations/replicate-automation-modal", {
          "id": automation_id
        }),
        'onDownloadEnd': function () {
          query('.cancel', replicateAutomationDialog.domNode).on('click', function (event) {
            event.stopPropagation();
            event.preventDefault();
            replicateAutomationDialog.hide();
          });
        },
        'onHide': function () {
          replicateAutomationDialog.destroyRecursive();
        }
      });
      replicateAutomationDialog.show();
    },
    /**
     * Launch the modal to confirm archiving an automation
     * @param {object} automationId - Object containing the selected campaign id
     * @private
    */
    _confirmArchiving: function (automationId) {
      var dialog = new Dialog({
        "title": "Are you sure?",
        "onHide": function () {
          dialog.destroyRecursive();
        },
        "onDownloadEnd": function () {
          query(".cancel-button", dialog.domNode).on("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            dialog.hide();
          });
          query("form", dialog.domNode).on("submit", function (e) {
            dijit.byId("confirm-archiving-button").set("disabled", true);
            e.stopPropagation();
            e.preventDefault();
            var formData = {
              "automationId": automationId
            };
            request.post(domAttr.get(this, "action"), {
              "data": formData
            }).then(function () {
              mUtils.toast("Your automation has been successfully archived.");
              campaigns.reRenderCampaigns(true, "archived");
              dialog.hide();
            }, function () {
              dialog.hide();
              mUtils.toast("There was a problem archiving your automation. Please try again.");
            });
          });
        }
      });
      dialog.setHref(mUrl.toUrl("automations/confirm-archive", {
        "automationId": automationId
      }));
      dialog.show();
    }
  });
});