/* eslint-disable */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking", "mojo/context", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dijit/registry'], function (declare, base, on, EcsEvents, EcsTracking, context, flags, query, registry) {
  return declare([base], {
    onReady: function () {
      // ECS page viewed: event line 48

      // Depending on whether the previous URL was a Dojo or react page can be found in different places
      var previousUrlFull = window.top.history.state ? window.top.history.state.referrer : window.top.document.referrer;
      var previousPageUrl = previousUrlFull ? new URL(previousUrlFull).pathname : "";
      EcsTracking.track(EcsEvents.AudienceOrganizationViewArchiveAllContactsPageEvent, {
        previous_page_url: previousPageUrl
      });
      var archiveAllBtn = query('#archive-all-btn')[0];
      var validationWidget = registry.byId('form-validation');
      on(archiveAllBtn, 'click', function () {
        // Needs to be improved, ideally should get data directly from controller or dojo
        var contactCount = 0;
        var archivedContactCountElements = query("[title='Your contacts']");
        if (archivedContactCountElements.length) {
          contactCount = archivedContactCountElements[0].innerText;
        }
        var hasContactArchiveError = !validationWidget.validate();
        EcsTracking.track(EcsEvents.AudienceOrganizationClickArchiveAllContactsEvent, {
          contact_count: !isNaN(contactCount) ? Number(contactCount) : 0,
          has_contact_archive_error: hasContactArchiveError
        });
      });
    }
  });
});