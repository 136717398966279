define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "./_EditViewMixin", "./FieldConfig", "./NestedConditionRow"], function (declare, _WidgetBase, _TemplatedMixin, _EditViewMixin, FieldConfig, NestedConditionRow) {
  var MAX_NESTED_CONDITIONS = 5;
  var RemoveButton = declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div class='remove-row'><a href='javascript:;' data-dojo-attach-point='removeButton' data-dojo-attach-event='click: remove' title='Remove row'><span class='freddicon remove-circle'></span></a></div>",
    isVisible: true,
    postCreate: function () {
      this.watch("isVisible", this.render.bind(this));
    },
    render: function () {
      this.domNode.classList.toggle("hide", this.isVisible !== true);
    },
    remove: function () {
      this.onRemove();
    }
  });

  /**
   * @package mojo/segmentation
   * @class NestedConditionGroup
   *
   * Acts as a proxy over multiple nested condition rows of the same field, transparently passing methods (like
   * editMode() and viewMode(), while re-packaging methods like getAST() and fromAST().
   *
   * The value of this additional layer of indirection, is a sensible foothold to place UI elements relating to the
   * additional and removal of nested conditions of the same field.
   */
  return declare([_WidgetBase, _TemplatedMixin, _EditViewMixin], {
    changeStateOnFocus: false,
    templateString: "<div>" + "<div data-dojo-attach-point='nestedConditionsContainer'></div>" + "<div class='margin-bottom--lv2 font-weight--bold' data-dojo-attach-point='addButton'>" + "<a href='javascript:;' data-dojo-attach-event='click: add'>" + "<span class='freddicon add-circle'></span> Add ${label}" + "</a>" + "</div>" + "</div>",
    /**
     * @type {string} Either `null`, `"edit"`, or `"view"`
     */
    mode: null,
    /**
     * Editor configuration, including what fields, operators, and ranges of values are available.
     * Nothing about the user's selections or segment itself.
     *
     * @type {object}
     */
    configs: null,
    /**
     * Particular field that this group of nested conditions belongs to.
     *
     * @type {mojo/segmentation/FieldConfig}
     *
     * @private
     */
    parentFieldConfig: null,
    /**
     * @type {mojo/segmentation/FieldConfig}
     *
     * @private
     */
    field: null,
    /**
     * @type {RemoveButton[]}
     *
     * @private
     */
    removalButtons: null,
    /**
     * @type {mojo/segmentation/NestedConditionRow[]}
     *
     * @private
     */
    children: null,
    /**
     * @type {DomNode} Populated by an attach point, contains the NestedConditionRow instances
     */
    nestedConditionsContainer: null,
    /**
     * @type {DomNode} Populated by an attach point, contains the "(+) Add" element
     */
    addButton: null,
    label: null,
    /**
     * Plain javascript object representation of this group. A good variable to watch, for keeping aware of this
     * group's state.
     */
    ast: null,
    constructor: function (configs, parentFieldConfig, nestedField) {
      this.configs = configs;
      this.parentFieldConfig = parentFieldConfig;
      this.field = nestedField;
      this.label = nestedField.label.toLowerCase();
      this.children = [];
      this.removalButtons = [];
    },
    postCreate: function () {
      // boot one initially, fromAST will build more
      this._bootAndPlace();
      this._toggleButtons();
    },
    _bootAndPlace: function () {
      var widget = new NestedConditionRow({
        configs: this.configs,
        fieldConfig: this.field
      });
      widget.startup();
      widget.editMode();
      var removeButton = new RemoveButton({
        onRemove: function () {
          this.remove(widget);
        }.bind(this)
      });
      var nestedConditionContainer = document.createElement("div");
      nestedConditionContainer.style.position = "relative";
      nestedConditionContainer.appendChild(removeButton.domNode);
      nestedConditionContainer.appendChild(widget.domNode);
      this.nestedConditionsContainer.appendChild(nestedConditionContainer);
      this.removalButtons.push(removeButton);
      this.children.push(widget);
      widget.opSelect.watch("value", function (name, old, newValue) {
        this.bumpAST();
      }.bind(this));
      widget.watch("ast", function () {
        this.bumpAST();
      }.bind(this));
      return widget;
    },
    bumpAST: function () {
      this.set("ast", this.getAST());
    },
    add: function () {
      if (this.children.length >= MAX_NESTED_CONDITIONS) {
        return;
      }
      this._bootAndPlace();
      this._toggleButtons();
    },
    remove: function (child) {
      var position = this.children.indexOf(child);
      if (position === -1) {
        throw new Error("Failed to remove nested field, as it was not found in `this.children`");
      }
      this.children.splice(position, 1);
      this.removalButtons.splice(position, 1);
      this.nestedConditionsContainer.removeChild(this.nestedConditionsContainer.childNodes[position]);
      this._toggleButtons();
    },
    _toggleButtons: function () {
      // "(+) Add whatever" button
      var supportsMultipleNestedConditions = this.parentFieldConfig.supportsMultipleNestedConditions(this.field.field);
      var hasFewerThanLimit = this.children.length < MAX_NESTED_CONDITIONS;
      var isInEditMode = this.mode === "edit";
      var isVisible = supportsMultipleNestedConditions && hasFewerThanLimit && isInEditMode;
      this.addButton.classList.toggle("hide", isVisible === false);

      // "(-)" buttons
      var removeButtonIsVisible = this.parentFieldConfig.supportsMultipleNestedConditions(this.field.field) && this.children.length > 1;
      this.removalButtons.map(function (button) {
        button.set("isVisible", removeButtonIsVisible);
      });
    },
    viewMode: function () {
      this.mode = "view";
      this.children.forEach(function (child) {
        child.viewMode();
      });
      this._toggleButtons();
    },
    editMode: function () {
      this.mode = "edit";
      this.children.forEach(function (child) {
        child.editMode();
      });
      this._toggleButtons();
    },
    getAST: function () {
      return this.children.map(function (child) {
        return child.getAST();
      });
    },
    fromASTs: function (nestedConditionASTs) {
      nestedConditionASTs.map(function (ast, i) {
        var child;
        if (i < this.children.length) {
          // update a child in-place
          child = this.children[i];
        } else {
          // make a new child and place
          child = this._bootAndPlace();
        }
        child.fromAST(ast);
      }.bind(this));
      this._toggleButtons();
    }
  });
});