/**
 * Base module that all neapolitan editors share.
 *
 * To create a new editor, just extend this and provide the following:
 * - type
 * - name
 * - icon
 *
 * You can provide defaults by setting defaultData on the constructor.
 *
 * To provide default styles for the design panel, you can provide a defaultStyles on the constructor.
 * It's assumed to be an array of objects that contain the following attributes:
 *
 * name:  The name of the section. It adds a title bar when set. (Optional)
 * props:  An array of css properties OR for more advanced use cases, you can use an object where
 *         the attributes are css properties and the value contains another object to set "value" and "selector".
 *         ["color"] OR { "color": { "value": "red", "selector: ".override-selector" } }
 * selector:  Used to apply the properties on the output markup
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "../_EditorMixin", "../BlockStyleEditor", "dojox/mvc/sync", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, _Widget, _Templated, lang, array, domStyle, on, domConstruct, context, flags, _EditorMixin, StyleEditor, mvcSync, nodeListDom, EcsTracking, EcsEvents) {
  return declare([_Widget, _Templated, _EditorMixin], {
    /**
     * unique type for this block editor. It's used to find which editor to use when a block needs to be edited.
     */
    type: null,
    widgetsInTemplate: true,
    hasDynamicContent: false,
    isDynamicContentEnabled: false,
    CAMPAIGN_RENDERING_TYPES: {
      PAGE: "page"
    },
    isPageTemplate: false,
    constructor: function (model) {
      this._watches = [];
      this._syncs = [];
    },
    postMixInProperties: function () {
      this.inherited(arguments);
      this.storeMap = {};
      this.isDynamicContentEnabled = null;
      this.isDynamicContentEnabled = this.block._campaignRenderingType !== this.CAMPAIGN_RENDERING_TYPES.PAGE;
      this.hasDynamicContent = this.block.hasDynamicContent;
    },
    postCreate: function () {
      // specific to landing pages, for displaying, creating, or removing messaging for email templates.
      this.isPageTemplate = this.block._campaignRenderingType === this.CAMPAIGN_RENDERING_TYPES.PAGE;
      if (this.styleEditorContainer) {
        var styleEditor = this.getStyleEditor();
        // Editors may override getStyleEditor() to prevent mounting the style editor
        // See SignUpFormEditor for example (dependent on flag value)
        if (!styleEditor) {
          return;
        }
        styleEditor.startup();
        domConstruct.place(styleEditor.domNode, this.styleEditorContainer, "only");
        this.watch("styles", lang.hitch(this, function () {
          styleEditor.updateInputs(this.block.styles);
        }));
      }
    },
    /**
     * Remove any sync calls & watch calls. Part of Dijit's lifecycle
     */
    uninitialize: function () {
      array.forEach(this._watches, function (w) {
        w.unwatch();
      });
      array.forEach(this._syncs, function (s) {
        s.remove();
      });
      this._watches = [];
      this._syncs = [];
      this.inherited(arguments);
    },
    /**
     * Watch for a particular attribute on the model to change. Uses the watch function in dojo.Stateful.
     * Editors need to call this instead of directly watching the model to avoid some really really bad behavior from
     * not cleaning up after editors are deleted.
     *
     * @param attr
     * @param f function on what to do with watches
     */
    watch: function (attr, f) {
      var watch = attr ? this.block.watch(attr, f) : this.block.watch(f);
      this._watches.push(watch);
    },
    /**
     * Syncs a particular attr in the block model and keeps track of the sync handler to remove when the editor is destroyed.
     * Similar reasoning as this.watch
     *
     * See dojox/mvc/sync
     */
    sync: function (attr, obj, objAttr) {
      var sync = mvcSync(this.block, attr, obj, objAttr);
      this._syncs.push(sync);
    },
    getStyleEditor: function () {
      if (!this.styleEditor || this.styleEditor._destroyed) {
        var styles = lang.clone(this.block.styles);
        this.styleEditor = new StyleEditor({
          'styles': styles,
          'type': this.block._name,
          'kbLink': this.block._kbLink
        });
        this.connect(this.styleEditor, "onChange", function (styles) {
          this.updateStyles(styles);
          if (this.applyAll) {
            this._updateAllBlockStyles();
          }
        });
        this.connect(this.styleEditor, "onCancel", function (styles) {
          this.updateStyles(styles);
          this._done();
          if (this.applyAll) {
            this._revertAllBlockStyles();
          }
          this.applyAll = false;
        });
        this.connect(this.styleEditor, "onRevert", function (styles) {
          this.updateStyles(styles);
          if (this.applyAll) {
            this._updateAllBlockStyles();
          }
        });
        this.connect(this.styleEditor, "onApplyToAll", function (applyToAll) {
          this.applyAll = applyToAll;
          if (this.applyAll) {
            this._updateAllBlockStyles();
          } else {
            this._revertAllBlockStyles();
          }
        });
      }
      return this.styleEditor;
    },
    /**
     * Quick utility to setup the preserved content dialog logic. Makes the assumption that this
     * editor is using a CKEditor through the mixin.
     */
    _setupPreservedContent: function () {
      if (this.switchContentBtn) {
        var self = this;
        if (window.app.hasPreservedContent()) {
          domStyle.set(this.switchContentBtn, 'display', '');
          on(this.switchContentBtn, 'click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            window.app.openPreservedContentDialog().then(function (preservedContent) {
              self.block._endpoint.getPreservedContent(preservedContent['name'], preservedContent['type']).then(function (content) {
                self.editor.setData(content);
                self.onCKChange();
              });
            });
          });
        }
      }
    },
    _updateAllBlockStyles: function () {
      if (!this._blocksByType) this._blocksByType = window.app.byType(this.type);
      array.forEach(this._blocksByType, lang.hitch(this, function (b) {
        if (b !== this.block) {
          b.applyStyles(this.block.styles);
        }
      }));
    },
    _revertAllBlockStyles: function () {
      if (this._blocksByType) {
        array.forEach(this._blocksByType, lang.hitch(this, function (b) {
          if (b !== this.block) {
            b.revertStyles();
          }
        }));
      }
    },
    _saveAllBlockStyles: function () {
      if (this._blocksByType) {
        window.app.updateAll(this._blocksByType, {
          'notify': true
        });
      }
    },
    /**
     * Updates the styles for the editor, but they are not persisted. By default it fires a content change
     *
     * @param styles -- See StyleEditor for how this looks like.
     */
    updateStyles: function (styles) {
      if (this.currentStyles == null) this.currentStyles = lang.clone(this.block.styles);
      this.block.set("styles", styles);
    },
    /**
     * Revert styles to whatever was last saved.
     */
    revertStyles: function () {
      if (this.currentStyles) {
        this.block.set("styles", this.currentStyles);
        this.currentStyles = null;
      }
    },
    saveAndHide: function () {
      this.publishDone();
      if (this.editorContainerWidget) {
        // Track ECS event for click on "Save & Close" button in edit drawer
        EcsTracking.track(EcsEvents.SaveAndCloseNeapolitanEvent, {
          block_type: this.editorContainerWidget.header
        });
      }
    },
    cancelAndHide: function () {
      this._skipSave = true;
      this.publishDone();
    },
    _done: function () {
      if (this.styleEditor && !this.styleEditor._destroyed) {
        if (this.applyAll) {
          this.applyAll = false;
          this._saveAllBlockStyles();
        }
        this.styleEditor.done();
        this.styleEditor.destroy();
      }
    },
    save: function (sync) {
      return this.block.save(sync);
    },
    onDataUpdate: function (data) {},
    /**
     * function that gets called when the editor is closed and no longer in the user's view. This can happen when
     * a user clicks on another block or when the hide function is invoked.
     */
    onClose: function () {
      this._done();
      if (!this._skipSave) this.save().then(lang.hitch(this, 'destroy'));
    }
  });
});