define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/ChoiceCard", "dojo/text!./templates/ChoiceCardGroup.html"], function (declare, lang, dom, domConstruct, query, registry, _WidgetBase, _TemplatedMixin, ChoiceCard, tpl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    // The type can be 'checkbox' or 'radio', but we'll default to checkbox.
    type: "checkbox",
    options: [],
    // reference our child cards for later retrieval
    choiceCards: {},
    postCreate: function () {
      this.options.forEach(lang.hitch(this, function (option) {
        this.addOption(option);
      }));
    },
    startup: function () {
      // If the user decides to declaratively add ChoiceCard widgets instead of setting options programmatically,
      // we need to make sure that those widgets are connected to our click event listener. So let's find all of
      // the widgets, making sure they match the parent's type, and connect the listener.
      registry.findWidgets(this.containerNode).forEach(lang.hitch(this, function (widget) {
        if (widget.type) {
          // if we're mixing option types, offer a warning instead.
          if (widget.type !== this.type) {
            console.warn("The ChoiceCardGroup " + this.id + " is of type '" + this.type + "' but widget '" + widget.id + "' is of type '" + widget.type + "'");

            // no click listener? now you have one.
          } else if (!widget.onClick) {
            widget.onClick = lang.hitch(this, this._onChoiceCardClicked);

            // if the child widget already has a listener for some reason,
            // let's call it first, and then call ours.
          } else if (widget.onClick !== this._onChoiceCardClicked) {
            var widgetOnClick = widget.onClick;
            var parentOnClick = lang.hitch(this, this._onChoiceCardClicked);
            widget.onClick = function (event) {
              widgetOnClick(event);
              parentOnClick(event);
            };
          }
          this.choiceCards[widget._id] = widget;
        }
      }));
    },
    addOption: function (option) {
      var choiceCard = new ChoiceCard({
        type: this.type,
        _id: option.id,
        // we underscore here because ${id} is a reserved template variable.
        name: option.name || option.id,
        // if a name is not provided, default to the id.
        value: option.value || option.name || option.id,
        // provide a default for value as well
        title: option.title,
        description: option.description,
        content: option.content,
        badge: option.badge,
        disabled: option.disabled,
        disabledReason: option.disabledReason,
        onClick: lang.hitch(this, this._onChoiceCardClicked)
      });
      this.choiceCards[option.id] = choiceCard;
      choiceCard.placeAt(this.containerNode);
    },
    setOptions: function (options) {
      this.options = options;
      domConstruct.empty(this.containerNode);
      this.options.forEach(lang.hitch(this, function (option) {
        // create a new Choice Card for each option
        this.addOption(option);
      }));
    },
    _onChoiceCardClicked: function (event) {
      var id = event.target.id;

      // If we're treating the choice cards as radio buttons, we need to deselect all but the currently-selected.
      if (this.type === "radio") {
        query("input:checked", this.containerNode).forEach(function (node) {
          node.checked = node.id === id;
        });
      }
      var checked = this.getCheckedOptions();
      this.onOptionsChanged(checked);
    },
    getChoiceCard: function (id) {
      return this.choiceCards[id];
    },
    getCheckedOptions: function () {
      var options = [];
      query("input:checked", this.containerNode).forEach(function (node) {
        options.push({
          id: node.id,
          name: node.name,
          value: node.value
        });
      });
      return options;
    },
    // Implement as you please.
    onOptionsChanged: function (checkedOptions) {}
  });
});