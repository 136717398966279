define(["dojo/uacss"], function () {

  // module:
  //		dijit/_base/sniff

  /*=====
  return {
  	// summary:
  	//		Deprecated, back compatibility module, new code should require dojo/uacss directly instead of this module.
  };
  =====*/
});