define(["dojo/request", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (request, lang, mUrl) {
  return {
    getCampaignGenderData: function (campaignId) {
      return this._getGenderData({
        "campaign_id": campaignId
      });
    },
    getListGenderData: function (listId) {
      return this._getGenderData({
        "list_id": listId
      });
    },
    getCampaignGenderByAgeRangeData: function (campaignId) {
      return this._getGenderByAgeRangeData({
        "campaign_id": campaignId
      });
    },
    getListGenderByAgeRangeData: function (listId) {
      return this._getGenderByAgeRangeData({
        "list_id": listId
      });
    },
    _getGenderData: function (params) {
      return request.get(mUrl.toUrl("/predicted-demographics/get-gender-data"), {
        "handleAs": "json",
        "query": params
      });
    },
    _getGenderByAgeRangeData: function (params) {
      return request.get(mUrl.toUrl("/predicted-demographics/get-gender-by-age-range-data"), {
        "handleAs": "json",
        "query": params
      });
    }
  };
});