define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/utils", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, mUrl, utils, xhr, dom, on, domAttr, domClass, Dialog, query) {
  var handleCancelBtn = function (dialog) {
    //Close dialog on cancel button
    query(".cancel-button", dialog.domNode).on("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      dialog.hide();
    });
  };
  return declare([base], {
    onReady: function () {
      var self = this;
      self.initAcknowledgementCheckMark();
      self.initDismissReconfirmModal();

      // Handle emojis
      utils.parseEmoji(dom.byId("issue-detail"));
    },
    initAcknowledgementCheckMark: function () {
      var self = this;
      var acknowledgeIssueCheckmark = this.$el.acknowledgeCheckmark;
      var acknowledgeIssueButton = this.$el.ignoreWarning;
      if (acknowledgeIssueCheckmark) {
        on(acknowledgeIssueCheckmark, "change", function () {
          domClass.toggle(acknowledgeIssueButton, "disabled");
        });
      }
    },
    initDismissReconfirmModal: function () {
      var self = this;
      var dismissReconfirmModal = this.$el.confirmDismissReconfirm;
      if (dismissReconfirmModal) {
        on(dismissReconfirmModal, "click", function () {
          self._dismissReconfirmModal(dismissReconfirmModal);
        });
      }
    },
    _dismissReconfirmModal: function (listItem) {
      var dialog = new Dialog({
        "title": "Are you sure?",
        "onHide": function () {
          dialog.destroyRecursive();
        },
        "onDownloadEnd": function () {
          handleCancelBtn(dialog);
        }
      });
      dialog.setHref(mUrl.toUrl("account/status/dismiss-reconfirm-modal", {
        "id": domAttr.get(listItem, "data-value")
      }));
      dialog.show();
    }
  });
});