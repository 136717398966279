define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dijit/registry"], function (declare, lang, base, domAttr, p2Utils, on, query, FilterMenu, FilterMessage, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var filterSettings = {
        "target": "list",
        "filters": [{
          "label": "Name",
          "key": "name",
          "type": "text"
        }, {
          "label": "Channel",
          "key": "channel",
          "type": "select",
          "options": {
            "email": "Email",
            "chat": "Chat"
          }
        }, {
          "label": "Tag",
          "key": "tag",
          "type": "text"
        }, {
          "label": "Group",
          "key": "group",
          "type": "text"
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.listFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.listFiltersMessage.appendChild(filterMessage.domNode);
    },
    openDeleteDialog: function (route_name, route_id) {
      var name = query("[data-element='deleteRouteName']")[0].innerHTML = route_name;
      var id = query("[data-element='deleteRouteId']")[0].value = route_id;
      var deleteRouteModal = registry.byId("deleteRouteModal");
      deleteRouteModal.show();
    },
    openClearDialog: function (route_name, route_id) {
      var name = query("[data-element='clearRouteName']")[0].innerHTML = route_name;
      var id = query("[data-element='clearRouteId']")[0].value = route_id;
      var clearRouteModal = registry.byId("clearRouteModal");
      clearRouteModal.show();
    },
    closeModal: function (modal_id) {
      var modal = registry.byId(modal_id);
      modal.hide();
    }
  });
});