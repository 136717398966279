/**
 * @module mojo/widgets/PathnamesComboBox
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/ComboBox", "dojo/store/Memory", "mojo/utils", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _TemplatedMixin, _WidgetBase, base, on, dom, ComboBox, Memory, utils, domConstruct) {
  var recentPathnamesStorageKey = "recentlyUsedPathnames";
  function getRecentPathnames() {
    return JSON.parse(utils.getCookie(recentPathnamesStorageKey)) || [];
  }
  function updatePathnamesInCookie(pathnamesArray) {
    var domain = document.location.host.indexOf("admin") > -1 ? "admin.mailchimp.com" : "dev.mailchimp.com";
    var mostRecent15Pathnames = pathnamesArray.slice(-15);
    document.cookie = recentPathnamesStorageKey + "=" + JSON.stringify(mostRecent15Pathnames) + "; domain=" + domain + "; path=/; secure; max-age=31536000; samesite=strict;";
  }
  function validatePathname(pathname) {
    var isValid = pathname.length > 0;

    // TODO: add other validations here
    // Validate that it is an actual pathname if possible. I know GlobalLinks.php has a lot of valid pathnames but not all.

    return isValid;
  }

  // Adding slash to front of path names visually so user doesn't have to type for it to match up with dropdown options
  domConstruct.place("<span id='slash' style='margin-right: -16px; margin-left: 10px; z-index: 1; line-height: 34px;'>/</span>", "login-as-pathname");
  var recentPathnames = getRecentPathnames();
  var stateStore = new Memory({
    data: recentPathnames.map(function (pathname) {
      return {
        name: pathname,
        // displaying the pathnames in the dropdown with a slash, but still want the input value to search against "name" value
        label: "/" + pathname
      };
    }),
    idProperty: "name"
  });
  var pathnamesComboBox = new ComboBox({
    id: "pathnamesComboBox",
    store: stateStore,
    value: "",
    placeholder: "URL login destination (eg: /account)",
    labelAttr: "label",
    style: {
      paddingLeft: "16px"
    }
  });
  pathnamesComboBox.placeAt(dom.byId("login-as-pathname"));
  return declare([base, _TemplatedMixin, _WidgetBase], {
    userId: "",
    loginId: "",
    baseUrl: "/peaches2/users/review-tools/login-as?user_id=",
    postCreate: function () {
      var loginAsButton = dom.byId("login-as-btn");
      var url = this.baseUrl + this.userId + "&login_id=" + this.loginId;
      on(loginAsButton, "click", function () {
        var inputValue = dom.byId("pathnamesComboBox").value.toLowerCase();
        var isValidPathname = validatePathname(inputValue);
        var updatedUrl = url;
        // If the pathname entered is valid then, then it will be appended to URL and the recentPathnames cookie will be updated.
        if (isValidPathname) {
          if (inputValue) {
            inputValue = inputValue.startsWith("/") ? inputValue.substring(1) : inputValue;
          }
          updatedUrl += "&redirect=/" + inputValue;
          var inputValueIndex = recentPathnames.indexOf(inputValue);

          // Update recent pathnames
          // First, updated local store
          stateStore.put({
            name: inputValue,
            label: "/" + inputValue
          });

          // Then, update cookie
          // If the pathname is already in the array, remove it so it can be added to front of the array
          if (inputValueIndex !== -1) {
            recentPathnames.splice(inputValueIndex, 1);
          }
          updatePathnamesInCookie([inputValue].concat(recentPathnames));
          dom.byId("pathnamesComboBox").value = "";
        }
        window.open(updatedUrl, "_blank");
      });
    }
  });
});