define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "mojo/utils", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "mojo/app/account/connected-sites"], function (declare, _base, domAttr, query, on, html, utils, lang, velocity, connectedSites) {
  return declare([_base], {
    onReady: function () {
      var self = this;

      // Setup up listener on list selection
      if (this.$el.locationSelect) {
        on(this.$el.locationSelect, "change", function () {
          //TODO:  This section would only be needed if we chose to support creating location which Square doesn't support
          // If Create a new list is selected, then show input form
          // if (self.$el.locationSelect.value === "0") {
          //     velocity(self.$el.locationSelectContainer, "transition.slideUpIn", { duration: 100 });
          // } else {
          //     velocity(self.$el.locationSelectContainer, "transition.slideDownOut", { duration: 100 });
          // }

          // If no list selected, disable next button
          if (!self.$el.locationSelect.value) {
            connectedSites.disableNext();
          } else {
            // Enable next button
            connectedSites.enableNext();
          }
        });
      }

      // Setup next button
      on(connectedSites.nextButton, "click", function (e) {

        //TODO:  This section would only be needed if we chose to support creating location which Square doesn't support
        // Check for list name
        // var locationName = query("#location-name", self.$el.locationSelectContainer)[0];
        //
        // // Prevent click if url is not valid
        // if (self.$el.listSelect.value === "0" && !locationName.value) {
        //     e.stopPropagation();
        //     e.preventDefault();
        //
        //     // Prompt the user to enter a list name
        //     utils.addError(listName, "Please enter a name for your new location.");
        // }
      });
    }
  });
});