define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "./StyleEditor", "dojo/text!./templates/globalElementStyleEditor.html"], function (declare, domClass, utils, StyleEditor, template) {
  return declare([StyleEditor], {
    templateString: template,
    type: "",
    kbLink: null,
    isOptional: true,
    widgetsInTemplate: true,
    postCreate: function () {
      this.inherited(arguments);
    },
    onChange: function () {
      utils.globalPublish("neapolitan/blocks/style/changed");
    }
  });
});