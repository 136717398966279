define(["dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (has, kernel, lang, dom) {
  // module:
  //		dojo/dnd/common

  var exports = lang.getObject("dojo.dnd", true);
  /*=====
  // TODO: for 2.0, replace line above with this code.
  var exports = {
  	// summary:
  	//		TODOC
  };
  =====*/

  exports.getCopyKeyState = function (evt) {
    return evt[has("mac") ? "metaKey" : "ctrlKey"];
  };
  exports._uniqueId = 0;
  exports.getUniqueId = function () {
    // summary:
    //		returns a unique string for use with any DOM element
    var id;
    do {
      id = kernel._scopeName + "Unique" + ++exports._uniqueId;
    } while (dom.byId(id));
    return id;
  };
  exports._empty = {};
  exports.isFormElement = function ( /*Event*/e) {
    // summary:
    //		returns true if user clicked on a form element
    var t = e.target;
    if (t.nodeType == 3 /*TEXT_NODE*/) {
      t = t.parentNode;
    }
    return " a button textarea input select option ".indexOf(" " + t.tagName.toLowerCase() + " ") >= 0; // Boolean
  };
  return exports;
});