define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/context", "mojo/lib/flags", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/HelpLinks.html"], function (declare, domConstruct, domClass, xhr, mUrl, context, flags, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    helpLinksNode: null,
    supportOptionsNode: null,
    // from constructor
    linkObjects: null,
    supportOptions: null,
    postCreate: function () {
      this._renderListItems();
    },
    _renderItem: function (item) {
      var listItemElement = domConstruct.create("li");
      var paragraphElement = domConstruct.create("p", {
        "class": "margin-bottom--lv0"
      });
      var itemLink = domConstruct.create("a", {
        href: item.url,
        target: "_blank"
      });
      itemLink.innerHTML = item.title;
      domConstruct.place(itemLink, paragraphElement);
      domConstruct.place(paragraphElement, listItemElement);
      domConstruct.place(listItemElement, this.helpLinksNode);
    },
    _renderListItems: function () {
      this.linkObjects.map(this._renderItem.bind(this));
    }
  });
});