define(["mojo/colors", "mojo/context", "mojo/lib/flags"], function (mColors, context, flags) {
  var exports = {};
  exports.ACCOUNT_DOMAINS_PATH = "/account/domains";
  exports.ENTRI_CONFIG_URI = "/account/domains/entri/get-config";
  exports.ENTRI_SCRIPT_URI = "https://cdn.goentri.com/entri.js";
  exports.ENTRI_CONFIG_DNS_RECORDS = {
    domain: [{
      type: "CNAME",
      host: "k2._domainkey.{DOMAIN}",
      value: "dkim2.mcsv.net",
      ttl: 300
    }, {
      type: "CNAME",
      host: "k3._domainkey.{DOMAIN}",
      value: "dkim3.mcsv.net",
      ttl: 300
    }, {
      type: "TXT",
      host: "_dmarc",
      value: "v=DMARC1; p=none;",
      ttl: 300
    }],
    subDomain: [{
      type: "CNAME",
      host: "k2._domainkey.{SUBDOMAIN}.{DOMAIN}",
      value: "dkim2.mcsv.net",
      ttl: 300
    }, {
      type: "CNAME",
      host: "k3._domainkey.{SUBDOMAIN}.{DOMAIN}",
      value: "dkim3.mcsv.net",
      ttl: 300
    }, {
      type: "TXT",
      host: "_dmarc",
      value: "v=DMARC1; p=none;",
      ttl: 300
    }]
  };
  exports.ENTRI_CONFIG_WHITE_LABEL = {
    // When true, hides all mentions of “Powered by Entri”
    hideEntriLogo: true,
    // Hides Mailchimp logo from initial screen (not the multi-domain initial screen)
    hideCompanyLogo: true,
    // Forces displaying Entri ToS
    showEntriToS: true,
    theme: {
      fg: mColors.brandColors.coconut.hex,
      bg: mColors.brandColors.kale.hex,
      // This enables the animation with Mailchimp's colors
      internalCustomAnimation: "Mailchimp",
      buttons: {
        border: {
          radius: "38px"
        }
      },
      googleFont: {
        family: "Open Sans",
        variant: "400",
        // This sets up the default weight for bold texts
        boldWeight: "600"
      },
      // Global modal's width
      width: "446px",
      titles: {
        // Global title's size
        fontSize: "1.26rem",
        providerLogin: {
          // Override the global title's site for this specific screen
          fontSize: "1rem"
        }
      },
      secondaryLinks: {
        // Override the global normal size for secondary cta's
        fontSize: "0.8rem"
      }
    },
    customCopy: {
      initialScreen: {
        title: {
          en: "<strong>Mailchimp</strong> uses <strong>Entri</strong> to easily authenticate your domain"
        }
      },
      congratulations: {
        title: {
          en: "Records have been created for {DOMAIN}!"
        },
        description: {
          en: "It may take up to 48 hours for changes to publish. We'll notify you by email once domain authentication is complete."
        }
      },
      providerLogin: {
        message: {
          en: "Your {PROVIDER} credentials will be used to authenticate your domain, but we won't store your username or password."
        }
      },
      existingRecords: {
        title: {
          en: "You already have existing records at {DOMAIN}"
        }
      }
    },
    // Overrides the default Entri app icons
    icons: {
      initialStep: {
        secureIcon: "MailchimpPasswordDark",
        easyIcon: "MailchimpCheckmark"
      },
      domainAnalysis: {
        stepInitial: "MailchimpStep",
        stepInactive: "MailchimpStepInactive",
        stepFinished: "MailchimpStepFilled"
      },
      providerLogin: {
        user: "MailchimpUser",
        password: "MailchimpPassword",
        passwordEyeVisible: "MailchimpEyeVisible",
        passwordEyeHidden: "MailchimpEyeHidden"
      }
    },
    customProperties: {
      initialScreen: {
        //Enables the go-to manual link in the initial screen
        showManualInstructionsCTA: true
      }
    }
  };
  exports.ENTRI_PROVIDER_UNKNOWN = "Unknown";
  exports.ENTRI_SETUP_TYPE_MANUAL = "Manual";
  exports.ENTRI_EVENT_CLOSE = "onEntriClose";
  exports.ENTRI_EVENT_STEP_CHANGE = "onEntriStepChange";

  /**
   * @typedef {'INITIAL'|'ENTER_DOMAIN'|'DOMAIN_ANALYSIS'|'DOMAIN_SETUP'|'IN_PROGRESS'|'EXISTING_RECORDS'|'LOGIN'|'MANUAL_CONFIGURATION'|'PROVIDER_MANUAL_SELECTION'|'EXIT_WITH_ERROR'|'DKIM_SETUP'|'FINISHED_SUCCESSFULLY'|'FINISHED_SUCCESSFULLY_MANUAL'} EntriStep
   */

  /** Initial screen for Entri Connect, Secure and Power. */
  exports.ENTRI_STEP_INITIAL = "INITIAL";

  /** Enter your domain screen in case there’s no prefilledDomain */
  exports.ENTRI_STEP_ENTER_DOMAIN = "ENTER_DOMAIN";

  /** The domain is under the DNS analysis */
  exports.ENTRI_STEP_DOMAIN_ANALYSIS = "DOMAIN_ANALYSIS";

  /** The domain is on the DNS configuration step */
  exports.ENTRI_STEP_DOMAIN_SETUP = "DOMAIN_SETUP";

  /** The user exited the Entri modal while the DNS setup was in progress. */
  exports.ENTRI_STEP_IN_PROGRESS = "IN_PROGRESS";

  /** The user exited the Entri modal when prompted if they want to override an existing set of DNS records. */
  exports.ENTRI_STEP_EXISTING_RECORDS = "EXISTING_RECORDS";

  /** The user exited the Entri modal flow during the login process (when prompted for their login credentials and/or 2FA verification) */
  exports.ENTRI_STEP_LOGIN = "LOGIN";

  /** The user exited the Entri modal after manual DNS configuration instructions were presented. */
  exports.ENTRI_STEP_MANUAL_CONFIGURATION = "MANUAL_CONFIGURATION";

  /** The user entered the providers’ list for manual selection */
  exports.ENTRI_STEP_PROVIDER_MANUAL_SELECTION = "PROVIDER_MANUAL_SELECTION";

  /** The user exited the Entri modal after an error occurred. */
  exports.ENTRI_STEP_EXIT_WITH_ERROR = "EXIT_WITH_ERROR";

  /** The user exited the Entri modal after prompting */
  exports.ENTRI_STEP_DKIM_SETUP = "DKIM_SETUP";

  /** The user has successfully configured their domain using the automatic flow. */
  exports.ENTRI_STEP_FINISHED_SUCCESSFULLY = "FINISHED_SUCCESSFULLY";

  /** The user has successfully configured their domain using the manual flow. */
  exports.ENTRI_STEP_FINISHED_SUCCESSFULLY_MANUAL = "FINISHED_SUCCESSFULLY_MANUAL";
  return exports;
});