define([], function () {
  return {
    description: "This is my amazing fake t-shirt. It has sleeves and some wicked graphics.",
    id: 1,
    image: {
      src: "https://square-production.s3.amazonaws.com/files/3…2249b978fe1c19c5a7ce47818ecc29f17fb/original.jpeg"
    },
    price: "$1.05",
    shipping: "0.00",
    tax: "0.0",
    title: "Fake T-Shirt",
    buttonText: "Buy Now!"
  };
});