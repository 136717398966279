define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/find", "mojo/page/settings", "mojo/widgets/Renderable", "dojo/text!./templates/tracking-options.html", "mojo/utils", "mojo/api/page"], function (declare, lang, find, pageSettings, Renderable, tpl, Utils, pageApi) {
  var TrackingOptions = declare([Renderable], {
    templateString: tpl,
    state: {
      googleAnalyticsTrackingIdError: "",
      facebookPixelIdError: ""
    },
    constructor: function (props) {
      if (typeof props.state === "undefined") {
        throw new TypeError("state property is required");
      }
    },
    handleAcceptTerms: function () {
      this.setState({
        isAcceptingTerms: true
      });
      pageApi.acceptTerms().then(lang.hitch(this, function (response) {
        var newStateProps = {
          isAcceptingTerms: false
        };
        if (response.status === "success") {
          newStateProps.acceptedTerms = true;
        }
        this.setState(newStateProps);
      }));
    },
    toggleGoogleAnalytics: function (isChecked) {
      this.setState({
        googleAnalyticsTrackingEnabled: isChecked
      });
    },
    setGoogleAnalyticsTrackingId: function (e) {
      this.state.googleAnalyticsTrackingId = e.target.value;
      this.preserveFocus(e);
      this.setState({
        googleAnalyticsTrackingIdError: this.getGoogleAnalyticsTrackingIdError()
      });
    },
    toggleMailchimpTracking: function (isChecked) {
      this.setState({
        mailchimpTrackingEnabled: isChecked
      });
    },
    togglePixelTracking: function (isChecked) {
      this.setState({
        facebookPixelTrackingEnabled: isChecked
      });
    },
    setFacebookPixelId: function (e) {
      this.state.facebookPixelId = e.target.value;
      this.preserveFocus(e);
      this.setState({
        facebookPixelIdError: this.getFacebookPixelIdError()
      });
    },
    toggleFacebookPixelDisclosure: function (e) {
      this.state.isFacebookPixelDisclosureOpen = !this.state.isFacebookPixelDisclosureOpen;
      Utils.disclosureElement("disclose-pixel-id-input", e.currentTarget, this.state.isFacebookPixelDisclosureOpen);
    },
    getGoogleAnalyticsTrackingIdError: function () {
      if (!this.state.googleAnalyticsTrackingEnabled) {
        return "";
      }
      if (!this.state.googleAnalyticsTrackingId || !this.state.googleAnalyticsTrackingId.trim()) {
        return "Please enter a value";
      }
      return "";
    },
    getFacebookPixelIdError: function () {
      if (!this.state.facebookPixelTrackingEnabled) {
        return "";
      }
      if (!this.state.hasFacebookAdsAccountPixel && (!this.state.facebookPixelId || !this.state.facebookPixelId.trim())) {
        return "Please enter a value";
      }
      return "";
    },
    validateBeforeSave: function () {
      var newStateProps = {
        facebookPixelIdError: this.getFacebookPixelIdError(),
        googleAnalyticsTrackingIdError: this.getGoogleAnalyticsTrackingIdError()
      };
      if (newStateProps.facebookPixelIdError) {
        newStateProps.isFacebookPixelDisclosureOpen = true;
      }
      this.setState(newStateProps);
    },
    hasValidationErrors: function () {
      return this.state.googleAnalyticsTrackingIdError || this.state.facebookPixelIdError;
    },
    getData: function () {
      var trackingState = this.state;
      var settingTypes = pageSettings.TYPES;
      var settings = [this.buildSettingDataByType(settingTypes.facebookPixel, trackingState), this.buildSettingDataByType(settingTypes.googleAnalytics, trackingState), this.buildSettingDataByType(settingTypes.mailchimp, trackingState)];
      return {
        tracking_settings: settings
      };
    },
    buildSettingDataByType: function (type, state) {
      return {
        setting_type: type,
        enabled: this.getEnabledPropertyByType(type, state),
        data: this.getDataPropertyByType(type, state)
      };
    },
    getEnabledPropertyByType: function (type, state) {
      var typeToPropertyMap = {};
      typeToPropertyMap[pageSettings.TYPES.googleAnalytics] = "googleAnalyticsTrackingEnabled";
      typeToPropertyMap[pageSettings.TYPES.mailchimp] = "mailchimpTrackingEnabled";
      typeToPropertyMap[pageSettings.TYPES.facebookPixel] = "facebookPixelTrackingEnabled";
      return state[typeToPropertyMap[type]];
    },
    getDataPropertyByType: function (type, state) {
      var typeToPropertyMap = {};
      if (state.googleAnalyticsTrackingId !== undefined) {
        var gaMapping = typeToPropertyMap[pageSettings.TYPES.googleAnalytics] = {};
        gaMapping[pageSettings.DATA_NAMES.googleAnalyticsTrackingId] = state.googleAnalyticsTrackingId;
      }
      if (state.facebookPixelId !== undefined) {
        var fbMapping = typeToPropertyMap[pageSettings.TYPES.facebookPixel] = {};
        fbMapping[pageSettings.DATA_NAMES.facebookPixel] = state.facebookPixelId;
      }
      return typeToPropertyMap[type];
    }
  });
  return TrackingOptions;
});