define(["dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domAttr.attr
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// lang.mixin
"../main",
// export symbols to dijit
"../hccss" // not using this module directly, but loading it sets CSS flag on <html>
], function (domAttr, lang, dijit) {
  // module:
  //		dijit/_base/wai

  var exports = {
    // summary:
    //		Deprecated methods for setting/getting wai roles and states.
    //		New code should call setAttribute()/getAttribute() directly.
    //
    //		Also loads hccss to apply dj_a11y class to root node if machine is in high-contrast mode.

    hasWaiRole: function ( /*Element*/elem, /*String?*/role) {
      // summary:
      //		Determines if an element has a particular role.
      // returns:
      //		True if elem has the specific role attribute and false if not.
      //		For backwards compatibility if role parameter not provided,
      //		returns true if has a role
      var waiRole = this.getWaiRole(elem);
      return role ? waiRole.indexOf(role) > -1 : waiRole.length > 0;
    },
    getWaiRole: function ( /*Element*/elem) {
      // summary:
      //		Gets the role for an element (which should be a wai role).
      // returns:
      //		The role of elem or an empty string if elem
      //		does not have a role.
      return lang.trim((domAttr.get(elem, "role") || "").replace("wairole:", ""));
    },
    setWaiRole: function ( /*Element*/elem, /*String*/role) {
      // summary:
      //		Sets the role on an element.
      // description:
      //		Replace existing role attribute with new role.

      domAttr.set(elem, "role", role);
    },
    removeWaiRole: function ( /*Element*/elem, /*String*/role) {
      // summary:
      //		Removes the specified role from an element.
      //		Removes role attribute if no specific role provided (for backwards compat.)

      var roleValue = domAttr.get(elem, "role");
      if (!roleValue) {
        return;
      }
      if (role) {
        var t = lang.trim((" " + roleValue + " ").replace(" " + role + " ", " "));
        domAttr.set(elem, "role", t);
      } else {
        elem.removeAttribute("role");
      }
    },
    hasWaiState: function ( /*Element*/elem, /*String*/state) {
      // summary:
      //		Determines if an element has a given state.
      // description:
      //		Checks for an attribute called "aria-"+state.
      // returns:
      //		true if elem has a value for the given state and
      //		false if it does not.

      return elem.hasAttribute ? elem.hasAttribute("aria-" + state) : !!elem.getAttribute("aria-" + state);
    },
    getWaiState: function ( /*Element*/elem, /*String*/state) {
      // summary:
      //		Gets the value of a state on an element.
      // description:
      //		Checks for an attribute called "aria-"+state.
      // returns:
      //		The value of the requested state on elem
      //		or an empty string if elem has no value for state.

      return elem.getAttribute("aria-" + state) || "";
    },
    setWaiState: function ( /*Element*/elem, /*String*/state, /*String*/value) {
      // summary:
      //		Sets a state on an element.
      // description:
      //		Sets an attribute called "aria-"+state.

      elem.setAttribute("aria-" + state, value);
    },
    removeWaiState: function ( /*Element*/elem, /*String*/state) {
      // summary:
      //		Removes a state from an element.
      // description:
      //		Sets an attribute called "aria-"+state.

      elem.removeAttribute("aria-" + state);
    }
  };
  lang.mixin(dijit, exports);

  /*===== return exports; =====*/
  return dijit; // for back compat :-(
});