define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/utils/string", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "codemirror4/lib/codemirror", "codemirror4/mode/htmlmixed/htmlmixed", "codemirror4/addon/display/placeholder"], function (declare, base, stringUtils, on, dom, domClass, CodeMirror) {
  return declare([base], {
    onReady: function () {
      var self = this;

      // Toggle upload elements based on selection
      on(this.$widget.uploadSegment, "change", function () {
        self._toggleSegmentOptions();
      });

      // CodeMirror config
      this.editor = CodeMirror.fromTextArea(this.$el.pasteArea, {
        mode: "text",
        tabMode: "indent",
        indentUnit: 2,
        smartIndent: false,
        theme: "default",
        autoClearEmptyLines: true,
        lineWrapping: false,
        lineNumbers: true
      });
      this._toggleSegmentOptions();
    },
    _toggleSegmentOptions: function () {
      var uploadChecked = this.$widget.uploadSegment.checked;
      var uploadVisible = uploadChecked === true;
      domClass.toggle(this.$el.uploadSegmentContainer, "hide", !uploadVisible);
      domClass.toggle(this.$el.pasteSegmentContainer, "hide", uploadVisible);

      // Refresh/focus editor then Paste Segment is selected
      if (!uploadVisible) {
        this.editor.refresh();
        this.editor.focus();
      }
    },
    /**
     * Strips emojis from a provided input
     * @param {object} input the input to be stripped
     */
    stripEmojisFromInput: function (input) {
      input.value = stringUtils.stripEmojis(input.value);
    }
  });
});