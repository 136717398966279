define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/FeedbackBlock.html"], function (declare, domClass, domConstruct, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    type: "info",
    title: "",
    message: "",
    containerClass: "",
    postCreate: function () {
      var icons = {
        success: "tick-circle",
        warning: "warn-circle",
        error: "not-allowed",
        info: "info-circle"
      };
      domClass.add(this.domNode, this.type);
      domClass.add(this.domNode, this.containerClass);
      domClass.add(this.icon, icons[this.type]);
      if (this.title || this.message) {
        this.containerNode.innerHTML = "";
        if (this.title) {
          this.containerNode.appendChild(domConstruct.toDom("<h4>" + this.title + "</h4>"));
        }
        if (this.message) {
          this.containerNode.appendChild(domConstruct.toDom("<p>" + this.message + "</p>"));
        }
      }
    },
    /**
     * Diplay feeback block for set amount of time and then hide.
     */
    flash: function (visibileDuration) {
      var fadeArgs = {
        node: this.id,
        duration: 1500
      };
      var closeArgs = {
        node: this.id,
        properties: {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0
        }
      };
      setTimeout(function () {
        dojo.fx.combine([dojo.fadeOut(fadeArgs), dojo.animateProperty(closeArgs)]).play();
      }.bind(this), visibileDuration);
    }
  });
});