define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/request-helpers", "mojo/lib/Poller", "mojo/utils/date"], function (declare, lang, xhr, all, Deferred, mUrl, requestHelpers, Poller, dateUtils) {
  var Postcard;
  var PostcardList = declare(null, {
    constructor: function (properties) {
      this.listId = properties.list_id;
      this.name = properties.name;
      this.disabled = properties.is_disabled;
    }
  });
  var PostcardAutomationTriggerEcommerceStore = declare(null, {
    constructor: function (properties) {
      this.storeId = properties.store_id;
      this.storeName = properties.store_name;
    }
  });
  var PostcardSegment = declare(null, {
    constructor: function (properties) {
      this.id = properties.id;
      this.type = properties.type;
      this.name = properties.segment_name;
    }
  });
  var PostcardContent = declare(null, {
    constructor: function (properties) {
      this.frontFileId = properties.front_file_id;
      this.backTitle = properties.back_title;
      this.backMessage = properties.back_message;
      this.backFileId = properties.back_file_id;
      this.frontFileUrl = properties.front_file_url;
      this.backFileUrl = properties.back_file_url;
      this.promoCodeId = properties.promo_code_id;
      this.promoRuleId = properties.promo_rule_id;
      this.promoStoreId = properties.promo_store_id;
    }
  });
  PostcardContent.getPatchFromData = function (data) {
    if (data === null) {
      return {};
    }
    var patch = {};
    if ("frontFileId" in data) {
      patch.front_file_id = data.frontFileId;
    }
    if ("frontFileUrl" in data) {
      patch.front_file_url = data.frontFileUrl;
    }
    if ("backTitle" in data) {
      patch.back_title = data.backTitle;
    }
    if ("backMessage" in data) {
      patch.back_message = data.backMessage;
    }
    if ("backFileId" in data) {
      patch.back_file_id = data.backFileId;
    }
    if ("backFileUrl" in data) {
      patch.back_file_url = data.backFileUrl;
    }
    if ("promoStoreId" in data) {
      patch.promo_store_id = data.promoStoreId;
    }
    if ("promoCodeId" in data) {
      patch.promo_code_id = data.promoCodeId;
    }
    if ("promoRuleId" in data) {
      patch.promo_rule_id = data.promoRuleId;
    }
    return patch;
  };
  var LOCATION_TYPE_COMMUNITY = "community";
  var LOCATION_TYPE_DISTRICT = "district";
  var PostcardLocation = declare(null, {
    constructor: function (properties) {
      this.location_id = properties.location_id;
      this.ui_name = properties.ui_name;
      this.radius_miles = properties.radius_miles;
      this.location_type = properties.location_type;
    }
  });
  var PostcardLocationSuggestion = declare(null, {
    constructor: function (properties) {
      this.id = properties.location_id;
      this.type = properties.location_type;
      this.name = properties.display_name || properties.ui_name;
    },
    mustHaveRadius: function () {
      return this.type === LOCATION_TYPE_COMMUNITY || this.type === LOCATION_TYPE_DISTRICT;
    }
  });
  var PostcardContentPreview = declare(null, {
    constructor: function (properties) {
      this.frontHtml = properties.front_html;
      this.backHtml = properties.back_html;
    }
  });
  var PostcardPotentialRecipients = declare(null, {
    constructor: function (properties) {
      this.optimisticCount = properties.optimistic_count;
    }
  });

  /**
   * A trigger is the combination of an automation strategy and its associated settings.
   *
   * @property null|string strategy String identifier for strategy, or null to indicate no automated sending
   * @property array|null settings Nullable plain object of string keys to primitive or rich values, vary by strategy
   */
  var PostcardAutomationTrigger = declare(null, {
    constructor: function (properties) {
      this.strategy = properties.strategy;
      var settings = properties.settings;
      if (settings === null) {
        this.settings = null;
      } else {
        this.settings = {
          interval: settings.interval,
          storeName: settings.store_name,
          latency: settings.latency
        };
      }
    }
  });
  var StoreDropdownOption = declare(null, {
    constructor: function (properties) {
      this.storeId = properties.store_id;
      this.storeName = properties.store_name;
      this.postcard = properties.postcard_id && {
        postcardId: properties.postcard_id,
        postcardUniqueId: properties.postcard_unique_id,
        postcardStatus: properties.postcard_status,
        postcardCanEdit: properties.postcard_can_edit
      };
    }
  });

  /**
   * Converts bag of automation trigger data (usually from a checklist item) into properties compatible with patch
   * endpoint contract.
   *
   * @param {Object} data Trigger definition data clump
   * @return {{strategy, settings}} Patch-compatible data clump
   */
  PostcardAutomationTrigger.getPatchFromData = function (data) {
    var patch = {
      strategy: data.strategy,
      settings: {
        interval: data.settings.interval
      }
    };
    return patch;
  };
  var PostcardAudienceBuildingStatus = declare(null, {
    constructor: function (properties) {
      // this is a string for now
      this.state = properties;
    },
    isFetchingAddresses: function () {
      return this.state === PostcardAudienceBuildingStatus.STATE_BUILDING;
    },
    isReady: function () {
      return this.state === PostcardAudienceBuildingStatus.STATE_READY;
    }
  });
  PostcardAudienceBuildingStatus.STATE_UNSTARTED = null;
  PostcardAudienceBuildingStatus.STATE_BUILDING = "building";
  PostcardAudienceBuildingStatus.STATE_READY = "ready";
  var api = {
    /**
     * Exported API Classes
     */
    PostcardLocation: PostcardLocation,
    PostcardLocationSuggestion: PostcardLocationSuggestion,
    PostcardSegment: PostcardSegment,
    /**
     * Audience strategy constants
     */
    AUDIENCE_STRATEGY_CONTACTS: "contacts",
    AUDIENCE_STRATEGY_LOOKALIKE: "lookalike",
    AUDIENCE_STRATEGY_UPLOAD: "upload",
    /**
     * Automation strategy constants
     */
    AUTOMATION_STRATEGY_RECURRING: "recurring",
    AUTOMATION_STRATEGY_ABANDONED_CART: "abandoned_cart",
    /**
     * Status constants
     */
    POSTCARD_STATUS_DRAFT: "draft",
    POSTCARD_STATUS_PROCESSING: "processing",
    POSTCARD_STATUS_SENT: "sent",
    POSTCARD_STATUS_ACTIVE: "active",
    POSTCARD_STATUS_PAUSED: "paused",
    POSTCARD_STATUS_CANCELED: "canceled",
    POSTCARD_STATUS_REASON_COMPLIANCE: "compliance",
    POSTCARD_STATUS_REASON_NO_RECIPIENTS: "no_recipients",
    POSTCARD_STATUS_REASON_SOURCE_DELETED: "source_deleted",
    /**
     * Instantiate a new Postcard from the given properties
     *
     * @param {Object} postcardProperties - Properties to build the Postcard instance from
     * @return {Postcard} A full instance
     */
    createFromProperties: function (postcardProperties) {
      return new Postcard(postcardProperties);
    },
    /**
     * Temporary mocked, in-memory postcard data
     */
    mockedPostcard: null,
    /**
     * Apply a patch of properties to the mocked, in-memory postcard
     *
     * @param {Object} patch - Properties to update
     * @return {Promise.<Postcard>} A Promise resolving to a new updated postcard instance
     */
    patchMocked: function (patch) {
      this.mockedPostcard = lang.mixin({}, this.mockedPostcard, patch);
      if (patch.list_id) {
        this.mockedPostcard.list = {
          list_id: patch.list_id,
          name: "Mocked List Name"
        };
      }
      return all().then(this.createFromProperties.bind(this, this.mockedPostcard));
    },
    /**
     * Apply a patch of properties to the postcard, and get a full, updated, postcard back
     *
     * @param {string} postcardId - Postcard id to update
     * @param {Object} patch - Properties to update
     * @return {Promise.<Postcard>} A Promise resolving to a new updated postcard instance
     */
    patchById: function (postcardId, patch) {
      return xhr.post(mUrl.toUrl("/postcards/patch", {
        "id": postcardId
      }), {
        "handleAs": "json",
        "data": {
          "patch": JSON.stringify(patch) // dojo doesn't serialize json objects
        }
      }).then(this.createFromProperties.bind(this));
    },
    /**
     * Get location suggestions from a text search.
     *
     * @param {String} textSearch - User's search string
     * @return {Promise.<PostcardLocationSuggestion[]>} A promise resolving to an array of search suggestions
     */
    fetchLocationSuggestions: function (textSearch) {
      if (textSearch && textSearch.length >= 3 && textSearch.length <= 100) {
        return xhr.get(mUrl.toUrl("/location-autocomplete/search", {
          "q": textSearch
        }), {
          "handleAs": "json"
        }).then(function (suggestions) {
          return suggestions.map(function (suggestion) {
            return new PostcardLocationSuggestion(suggestion);
          });
        });
      }
      return all().then(function () {
        return [];
      });
    },
    /**
     * Preview what some postcard content would look like.
     *
     * @param {Object} postcardContent Content which would be patched
     *
     * @return {Promise.<PostcardContentPreview>} A promise resolving to the content preview
     */
    getContentPreview: function (postcardContent) {
      return xhr.post(mUrl.toUrl("/postcards/content/get-preview"), {
        handleAs: "json",
        data: PostcardContent.getPatchFromData(postcardContent)
      }).then(function (preview) {
        return new PostcardContentPreview(preview);
      }, requestHelpers.throwError);
    },
    /**
     * Get the HTML used to populate the billing sidebar
     *
     * @param {string} postcardId Postcard id to fetch sidebar HTML for
     *
     * @return {Promise.<string>} A promise resolving to the HTML needed to populate sidebar
     */
    getBillingSidebarHTML: function (postcardId) {
      return xhr.post(mUrl.toUrl("postcards/billing-summary"), {
        "data": {
          id: postcardId
        },
        "handleAs": "text"
      });
    },
    /**
     * Get an estimate of the potential recipients of a postcard campaign.
     *
     * @param {Number} maxBudgetInUserCurrency the user's maximum budget
     * @param {Number} listSegmentCount the size of the list/segment used to seed recipients
     * @param {PostcardLocationSuggestion} [location] an optional location whose postage should be used in calculation
     *
     * @return {Promise.<PostcardPotentialRecipients>} A promise resolving to a PostcardPotentialRecipients object
     */
    getPotentialRecipients: function (maxBudgetInUserCurrency, listSegmentCount, location) {
      return xhr.post(mUrl.toUrl("/postcards/potential-recipients"), {
        "data": {
          "max_budget_in_user_currency": maxBudgetInUserCurrency,
          "list_segment_count": listSegmentCount,
          "location_id": location ? location.id : null
        },
        "handleAs": "json"
      }).then(function (potentialRecipients) {
        return new PostcardPotentialRecipients(potentialRecipients);
      });
    },
    /**
     * Get user's active stores with their attached abandoned cart postcards
     *
     * @return {Promise.<StoreDropdownOption[]>} A promise resolving to an array of StoreDropdownOptions
     */
    getActiveStoresWithAbandonedCartPostcards: function () {
      return xhr.post(mUrl.toUrl("/postcards/get-active-stores-with-abandoned-postcards"), {
        "data": {},
        "handleAs": "json"
      }).then(function (dropdownOptions) {
        return dropdownOptions.map(function (option) {
          return new StoreDropdownOption(option);
        }, this);
      });
    },
    /**
     * Poll the server until campaign addresses have been found
     *
     * @param {string} postcardId the ID of the postcard
     *
     * @param {function} onUpdate the number of times address fetching has executed
     *
     * @return {Promise} A promise that resolves when addresses have been found
     */
    pollForFetchedAddresses: function (postcardId, onUpdate) {
      var poller = new Poller(function () {
        return api.patchById(postcardId).then(function (postcardSentinel) {
          if (postcardSentinel.audienceBuildingStatus.isFetchingAddresses()) {
            onUpdate(poller._previousAttempts);
            return Poller.RETRY;
          }

          // resolve promise with null, we shouldn't rely on the fact that we have a finished postcard here
          return null;
        });
      });
      poller.begin();
      return poller.getPromise();
    },
    checkForFetchedAddresses: function (postcardId, onUpdate) {
      if (!this._addressRequest) {
        this._addressRequest = this.pollForFetchedAddresses(postcardId, onUpdate).then(this._clearAddressRequest.bind(this));
      }
      return this._addressRequest;
    },
    cancelAddressRequest: function () {
      if (this._addressRequest && !this._addressRequest.isFulfilled()) {
        this._addressRequest.cancel();
      }
      this._clearAddressRequest();
    },
    isCheckingForFetchedAddresses: function () {
      return !!this._addressRequest;
    },
    _clearAddressRequest: function () {
      this._addressRequest = null;
    }
  };
  Postcard = declare(null, {
    /**
     * Takes a properties bundle, and unpacks it to front-end-friendly structure, delegating
     * to sub-models along the way
     *
     * @param {Object} properties - Properties from postcard endpoints
     */
    constructor: function (properties) {
      this._timestamp = Date.now();
      this.name = properties.title;
      this.postcardId = properties.postcard_id;
      // dateUtils.dateTransform handles the old date format as a string and the new format as an object without the need for a flag
      this.updatedAt = properties.updated_at ? dateUtils.dateTransform(properties.updated_at) : null;
      this.status = properties.status || null;
      this.statusReason = properties.status_reason || null;
      this.uniqueID = properties.unique_id || null;
      this.audienceStrategy = properties.audience_strategy || null;
      this.list = properties.list ? new PostcardList(properties.list) : null;
      this.segment = properties.segment ? new PostcardSegment(properties.segment) : null;
      this.wasSourceDeleted = properties.was_source_deleted || false;
      this.maxBudgetInUserCurrency = properties.max_budget_in_user_currency ? Math.floor(properties.max_budget_in_user_currency) : null;
      this.creditRechargeAmountInUserCurrency = properties.credit_recharge_amount_in_user_currency ? Math.floor(properties.credit_recharge_amount_in_user_currency) : null;
      this.maxBudgetInUSD = properties.max_budget_in_usd || null;
      // The back end is set up to return multiple location filters, but the FE is only set up for one.
      // Let's just take the first element until we're ready to support multiple location filters.
      this.location = properties.location_filters && properties.location_filters.length > 0 ? new PostcardLocation(properties.location_filters[0]) : null;
      this.audienceSize = properties.audience_size;
      this.numberOfAddressesFoundByAddressFinder = properties.number_of_addresses_found_by_address_finder;
      this.isTryingAddressFinder = properties.is_trying_address_finder;
      this.remainingQuantity = properties.number_of_postcards_available;
      this.content = new PostcardContent(properties.content);
      this.shouldShowSendErrorMessage = properties.should_show_send_error_message;
      var automationTrigger = properties.automation_trigger;
      this.automationTrigger = automationTrigger ? new PostcardAutomationTrigger(automationTrigger) : null;
      this.automationTriggerStore = properties.automation_trigger_store ? new PostcardAutomationTriggerEcommerceStore(properties.automation_trigger_store) : null;
      this.audienceBuildingStatus = new PostcardAudienceBuildingStatus(properties.audience_building_status);
      this.isAutomated = this.automationTrigger.strategy !== null;
      this.nextOrderDate = properties.next_order_date;
      this.scheduledSendTime = properties.scheduled_send_time;
    },
    patch: function (data) {
      data = data || {};
      var patch = {};
      if (data.listId) {
        patch = lang.mixin(patch, {
          "list_id": data.listId
        });
      }
      if (data.name) {
        patch = lang.mixin(patch, {
          "title": data.name
        });
      }
      if (data.segment !== undefined) {
        patch = lang.mixin(patch, {
          "segment": data.segment
        });
      }
      if (data.audienceStrategy !== undefined) {
        patch = lang.mixin(patch, {
          "audience_strategy": data.audienceStrategy
        });
      }
      if (data.location !== undefined) {
        patch = lang.mixin(patch, {
          "location_filters": data.location ? [data.location] : null
        });
      }
      if (data.content !== undefined) {
        patch = lang.mixin(patch, {
          "content": PostcardContent.getPatchFromData(data.content)
        });
      }
      if (data.maxBudgetInUserCurrency !== undefined) {
        patch = lang.mixin(patch, {
          "max_budget_in_user_currency": data.maxBudgetInUserCurrency
        });
      }
      if (data.creditRechargeAmountInUserCurrency !== undefined) {
        patch = lang.mixin(patch, {
          "credit_recharge_amount_in_user_currency": data.creditRechargeAmountInUserCurrency
        });
      }
      if (data.automationTrigger !== undefined) {
        patch = lang.mixin(patch, {
          "automation_trigger": PostcardAutomationTrigger.getPatchFromData(data.automationTrigger)
        });
      }
      if (data.pastedAddresses !== undefined) {
        patch = lang.mixin(patch, {
          "pasted_addresses": data.pastedAddresses
        });
      }
      if (data.isTryingAddressFinder !== undefined) {
        patch = lang.mixin(patch, {
          "is_trying_address_finder": data.isTryingAddressFinder
        });
      }
      return api.mockedPostcard ? api.patchMocked(patch) : api.patchById(this.postcardId, patch);
    },
    getSupportedAudienceStrategies: function () {
      if (this.isAutomated) {
        // Can't use similar people finder with automations yet
        return [api.AUDIENCE_STRATEGY_CONTACTS];
      }
      // This array determines the order of the default postcard audience.
      return [api.AUDIENCE_STRATEGY_CONTACTS, api.AUDIENCE_STRATEGY_LOOKALIKE, api.AUDIENCE_STRATEGY_UPLOAD];
    }
  });
  return api;
});