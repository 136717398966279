define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/date",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!../templates/activity-feed/activity-item-ecommerce-signup.html"], function (declare, dateUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    campaignSendTime: null,
    postMixInProperties: function () {
      var sentDate = new Date(this.data.formatted_user_time);
      // be explicit about needing the timezone in the time stamp string
      // add timezone to activity feed time stamp
      this.campaignSendTime = dateUtils.getTimeString(sentDate, true);
    }
  });
});