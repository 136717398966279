define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/tag-action-button.html"], function (declare, lang, domAttr, domClass, on, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, buttonTplString) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: buttonTplString,
    tagID: null,
    tagName: null,
    tagState: null,
    contacts: null,
    mixedIcon: "https://cdn-images.mailchimp.com/product/tags/tag-mixed-icon.svg",
    allIcon: "https://cdn-images.mailchimp.com/product/tags/tag-selected-icon.svg",
    /** Available states for the tag action button
     * NONE implies that the tag should have no highlighting
     * MIXED implies the light blue highlight with the dash
     * ALL implies the darker highlight with a check mark
     **/
    states: {
      "ALL": "0",
      "MIXED": "1",
      "NONE": "2"
    },
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      this.setTagState(this.tagState);
      this.setupState();
      on(this.tagActionButton, "click", lang.hitch(this, "onTagClick"));
    },
    /**
     * Set the state of the current tag
     * Expects value of ALL, MIXED, or NONE
     * @param {string} state to assign
     */
    setTagState: function (state) {
      if (state || state === 0) {
        this.tagState = this.states[state];
        this.setupState();
      }
    },
    /**
     * Hide all template options
     */
    resetState: function () {
      domClass.add(this.noIconContainer, "hide");
      domClass.add(this.hasIconContainer, "hide");
    },
    /**
     * Set up the UI based on passed-in state
     * The template defaults to this.state.NONE
     */
    setupState: function () {
      this.resetState();
      switch (this.tagState) {
        case this.states["MIXED"]:
          this.setupMixed();
          break;
        case this.states["ALL"]:
          this.setupAll();
          break;
        case this.states["NONE"]:
        default:
          this.setupNone();
          break;
      }
    },
    /**
     * Set up the UI when no contacts have this tag
     */
    setupNone: function () {
      domClass.add(this.tagActionButton, "none");
      domClass.remove(this.noIconContainer, "hide");
    },
    /**
     * Set up the UI when some contacts have this tag
     */
    setupMixed: function () {
      domClass.add(this.tagActionButton, "mixed");
      domAttr.set(this.tagStateIcon, "src", this.mixedIcon);
      domClass.remove(this.hasIconContainer, "hide");
    },
    /**
     * Set up the UI when all contacts have this tag
     */
    setupAll: function () {
      domClass.add(this.tagActionButton, "all");
      domAttr.set(this.tagStateIcon, "src", this.allIcon);
      domClass.remove(this.hasIconContainer, "hide");
    },
    /**
     * Generic click event
     */
    onTagClick: function () {}
  });
});