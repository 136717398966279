define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/lists/members", "mojo/analytics/VisTableListMembers", "mojo/analytics/VisQuery"], function (declare, base, mUrl, on, dom, members, TableListMembers, Query) {
  return declare([base], {
    listId: null,
    queryLabel: null,
    querySelect: null,
    memberTable: null,
    onReady: function () {
      var query = {
        'select': this.querySelect,
        'select_count': 'count(id)',
        'from': 'recipients',
        'label': this.queryLabel
      };
      var queryUrl = mUrl.toUrl('/campaigns/segments/popup-data', {
        'lid': this.listId,
        'id': 'reconfirm-external',
        'iter': true
      });
      this.memberTable = new TableListMembers({
        query: new Query(queryUrl, query)
      }, this.$el.memberGrid);
      if (this.$el.reconfirmExternalButton) {
        var self = this;
        on(this.$el.reconfirmExternalButton, "click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          //Must use some flags because the members.js grid of list members is hardwired to work with a different page than this prune workflow
          members.confirmBulkActionWithParameters('reconfirm-external', self.listId, false, true, function () {
            dojo.style(self.$el.reconfirmExternalButton, 'display', 'none');
            window.location = mUrl.toUrl("lists/members/", {
              id: self.listId
            });
          });
        });
      }
    }
  });
});