define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./TextBlock", "dojo/text!../editors/blocks/templates/RssItemsEditor/excerptsContent.html", "dojo/text!../editors/blocks/templates/RssItemsEditor/titlesContent.html", "dojo/text!../editors/blocks/templates/RssItemsEditor/fullContent.html"], function (declare, TextBlock, excerpts, titles, full) {
  return declare("mojo.neapolitan.renderers.RssItemsBlock", [TextBlock], {
    presets: {
      excerpts: excerpts,
      titles: titles,
      full: full
    },
    getOutputModel: function (meta) {
      var textBlockMeta = {
        'numberOfColumns': 1,
        'splitOption': [100],
        'content': meta.selectedPreset == 'custom' ? meta.customContent : this.presets[meta.selectedPreset]
      };
      return this.inherited(arguments, [textBlockMeta]);
    }
  });
});