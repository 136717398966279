/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ /
 *
 * ~~~ ANIMATIONS ~~~
 *
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (lang, Deferred, domClass, domStyle, velocity) {
  var DEFAULT_DURATION = 150;
  return {
    /**
     * Fade an element into view.
     * @param {Object} element - Element to be faded in.
     * @param {int} duration - How long the animation will take.
     * @param {int} delay - How much to delay the start of the animation.
     *
     * @return {Obj} promise - Callback when animation is completed.
     */
    fadeIn: function (element, duration, delay) {
      var promise = new Deferred();
      if (duration === undefined) {
        duration = DEFAULT_DURATION;
      }
      if (delay === undefined) {
        delay = 100;
      }
      domClass.remove(element, "display--none");
      domStyle.set(element, "opacity", 0);
      velocity(element, {
        "opacity": 1
      }, {
        "delay": delay,
        "duration": duration,
        "complete": lang.hitch(this, function () {
          promise.resolve();
        })
      });
      return promise;
    },
    /**
     * Fade an element out of view.
     * @param {Object} element - Element to be faded out.
     * @param {int} duration - How long the animation will take.
     * @param {int} delay - How much to delay the start of the animation.
     *
     * @return {Obj} promise - Callback when animation is completed.
     */
    fadeOut: function (element, duration, delay) {
      var promise = new Deferred();
      if (duration === undefined) {
        duration = DEFAULT_DURATION;
      }
      if (delay === undefined) {
        delay = 0;
      }
      velocity(element, {
        "opacity": 0
      }, {
        "delay": delay,
        "duration": duration,
        "complete": lang.hitch(this, function () {
          promise.resolve();
        })
      });
      return promise;
    }
  };
});