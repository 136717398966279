define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/EventbriteEmailTemplates.html"], function (declare, lang, on, domGeometry, domConstruct, domClass, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // set by controller
    listItems: null,
    showAll: false,
    _setShowAllAttr: function (value) {
      if (!!value) {
        this._renderAllItems();
        this.toggleMoreNode.innerHTML = "Less<span class='freddicon menu-up'></span>";
      } else {
        this._renderFirstItem();
        this.toggleMoreNode.innerHTML = "More<span class='freddicon menu-down'></span>";
      }
      this._set("showAll", !!value);
    },
    postCreate: function () {
      this._renderFirstItem();

      // if there is more than one bullet point
      //   - display show more toggle button
      //   - add event listener
      if (this.listItems.length > 1) {
        domClass.remove(this.toggleMoreNode, "display--none");
        this.own(on(this.toggleMoreNode, "click", lang.hitch(this, function () {
          this.set("showAll", !this.showAll);
        })));
      }
    },
    _renderSingleItem: function (listItem) {
      var listElement = domConstruct.create("li");
      listElement.innerHTML = listItem;
      domConstruct.place(listElement, this.stepsListNode);
    },
    _renderFirstItem: function () {
      if (this.listItems.length > 0) {
        domConstruct.empty(this.stepsListNode);
        this._renderSingleItem(this.listItems[0]);
      }
    },
    _renderAllItems: function () {
      domConstruct.empty(this.stepsListNode);
      this.listItems.map(this._renderSingleItem.bind(this));
    }
  });
});