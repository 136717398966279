define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Widget, dom, on) {
  return declare([_Widget], {
    postCreate: function () {
      var el = this.domNode;
      el.style.overflow = "hidden";
      el.style.minHeight = el.offsetHeight + "px";
      var offset = el.offsetHeight - el.clientHeight;
      on(el, "keyup", function () {
        el.style.height = "auto";
        el.style.height = parseInt(el.scrollHeight + offset, 10) + "px";
      });
    }
  });
});