define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, base, mUrl, on, registry) {
  return declare([base], {
    user_id: null,
    onReady: function () {
      var self = this;
      on(this.$el.createSeedlistButton, "click", function () {
        self.$widget.createSeedlistDialog.show();
      });
    },
    /**
     * sets this.user_id and opens delete dialog
     * @param {string} id - id of the user that we are deleting the seedlist from
     */
    deleteSeedList: function (id, lid) {
      this.$el.userIdInput.value = id;
      this.$el.listIdInput.value = lid;
      this.$widget.deleteDialog.show();
    }
  });
});