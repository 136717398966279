define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "mojo/app/peaches2/utils"], function (declare, base, mUrl, tabContainerUtil, query, FilterMenu, FilterMessage, p2Utils) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
    },
    deleteStore: function (id) {
      this.deletedStoreId = id;
      this.$widget.deleteStoreDialog.show();
    },
    doStoreDeletion: function () {
      var note = this.$el.deleteStoreNote.value;
      var id = this.deletedStoreId;
      this.deletedStore = null;
      if (!note) {
        this.$widget.deleteStoreDialog.hide();
        p2Utils.utils.setFlash("You have to provide a note", "error");
        return;
      }
      window.location = mUrl.toUrl("/peaches2/users/ecommerce/store-delete", {
        user_id: this.userAttrs.userId,
        note: note,
        id: id,
        _p: this.activeModule
      });
    },
    loadFilter: function () {
      var settings = {
        "target": "ecommerce-stores-list",
        "filters": [{
          "label": "Deletion Status",
          "key": "deletion_status",
          "type": "select",
          "options": {
            "deleted": "Deleted",
            "not_deleted": "Not Deleted"
          }
        }]
      };
      var menu = new FilterMenu(settings);
      this.$el.storeFilter.appendChild(menu.domNode);
      var message = new FilterMessage(settings);
      this.$el.storeFilterMessage.appendChild(message.domNode);
    }
  });
});