define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // dom.setSelectable
"dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domAttr.set
"dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domClass.toggle
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // kernel.deprecated
"dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // has("ie")
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// lang.hitch
"./_Widget", "./_TemplatedMixin", "./_Contained", "./_CssStateMixin", "dojo/text!./templates/MenuItem.html"], function (declare, dom, domAttr, domClass, kernel, has, lang, _Widget, _TemplatedMixin, _Contained, _CssStateMixin, template) {
  // module:
  //		dijit/MenuItem

  var MenuItem = declare("dijit.MenuItem" + (0 ? "_NoBidi" : ""), [_Widget, _TemplatedMixin, _Contained, _CssStateMixin], {
    // summary:
    //		A line item in a Menu Widget

    // Make 3 columns
    // icon, label, and expand arrow (BiDi-dependent) indicating sub-menu
    templateString: template,
    baseClass: "dijitMenuItem",
    // label: String
    //		Menu text as HTML
    label: "",
    _setLabelAttr: function (val) {
      this._set("label", val);
      var shortcutKey = "";
      var text;
      var ndx = val.search(/{\S}/);
      if (ndx >= 0) {
        shortcutKey = val.charAt(ndx + 1);
        var prefix = val.substr(0, ndx);
        var suffix = val.substr(ndx + 3);
        text = prefix + shortcutKey + suffix;
        val = prefix + '<span class="dijitMenuItemShortcutKey">' + shortcutKey + '</span>' + suffix;
      } else {
        text = val;
      }
      this.domNode.setAttribute("aria-label", text + " " + this.accelKey);
      this.containerNode.innerHTML = val;
      this._set('shortcutKey', shortcutKey);
    },
    /*=====
    // shortcutKey: [readonly] String
    //		Single character (underlined when the parent Menu is focused) used to navigate directly to this widget,
    //		also known as [a mnemonic](http://en.wikipedia.org/wiki/Mnemonics_(keyboard%29).
    //		This is denoted in the label by surrounding the single character with {}.
    //		For example, if label="{F}ile", then shortcutKey="F".
    shortcutKey: "",
    =====*/

    // iconClass: String
    //		Class to apply to DOMNode to make it display an icon.
    iconClass: "dijitNoIcon",
    _setIconClassAttr: {
      node: "iconNode",
      type: "class"
    },
    // accelKey: String
    //		Text for the accelerator (shortcut) key combination, a control, alt, etc. modified keystroke meant to
    //		execute the menu item regardless of where the focus is on the page.
    //
    //		Note that although Menu can display accelerator keys, there is no infrastructure to actually catch and
    //		execute those accelerators.
    accelKey: "",
    // disabled: Boolean
    //		If true, the menu item is disabled.
    //		If false, the menu item is enabled.
    disabled: false,
    _fillContent: function ( /*DomNode*/source) {
      // If button label is specified as srcNodeRef.innerHTML rather than
      // this.params.label, handle it here.
      if (source && !("label" in this.params)) {
        this._set('label', source.innerHTML);
      }
    },
    buildRendering: function () {
      this.inherited(arguments);
      var label = this.id + "_text";
      domAttr.set(this.containerNode, "id", label); // only needed for backward compat
      if (this.accelKeyNode) {
        domAttr.set(this.accelKeyNode, "id", this.id + "_accel"); // only needed for backward compat
      }
      dom.setSelectable(this.domNode, false);
    },
    onClick: function /*Event*/
    () {
      // summary:
      //		User defined function to handle clicks
      // tags:
      //		callback
    },
    focus: function () {
      // summary:
      //		Focus on this MenuItem
      try {
        if (has("ie") == 8) {
          // needed for IE8 which won't scroll TR tags into view on focus yet calling scrollIntoView creates flicker (#10275)
          this.containerNode.focus();
        }
        this.focusNode.focus();
      } catch (e) {
        // this throws on IE (at least) in some scenarios
      }
    },
    _setSelected: function (selected) {
      // summary:
      //		Indicate that this node is the currently selected one
      // tags:
      //		private

      domClass.toggle(this.domNode, "dijitMenuItemSelected", selected);
    },
    setLabel: function ( /*String*/content) {
      // summary:
      //		Deprecated.   Use set('label', ...) instead.
      // tags:
      //		deprecated
      kernel.deprecated("dijit.MenuItem.setLabel() is deprecated.  Use set('label', ...) instead.", "", "2.0");
      this.set("label", content);
    },
    setDisabled: function ( /*Boolean*/disabled) {
      // summary:
      //		Deprecated.   Use set('disabled', bool) instead.
      // tags:
      //		deprecated
      kernel.deprecated("dijit.Menu.setDisabled() is deprecated.  Use set('disabled', bool) instead.", "", "2.0");
      this.set('disabled', disabled);
    },
    _setDisabledAttr: function ( /*Boolean*/value) {
      // summary:
      //		Hook for attr('disabled', ...) to work.
      //		Enable or disable this menu item.

      this.focusNode.setAttribute('aria-disabled', value ? 'true' : 'false');
      this._set("disabled", value);
    },
    _setAccelKeyAttr: function ( /*String*/value) {
      // summary:
      //		Hook for attr('accelKey', ...) to work.
      //		Set accelKey on this menu item.

      if (this.accelKeyNode) {
        this.accelKeyNode.style.display = value ? "" : "none";
        this.accelKeyNode.innerHTML = value;
        //have to use colSpan to make it work in IE
        domAttr.set(this.containerNode, 'colSpan', value ? "1" : "2");
      }
      this._set("accelKey", value);
    }
  });
  if (0) {
    MenuItem = declare("dijit.MenuItem", MenuItem, {
      _setLabelAttr: function (val) {
        this.inherited(arguments);
        if (this.textDir === "auto") {
          this.applyTextDir(this.textDirNode);
        }
      }
    });
  }
  return MenuItem;
});