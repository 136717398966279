define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// kernel.deprecated
"./Button", "./_ToggleButtonMixin"], function (declare, kernel, Button, _ToggleButtonMixin) {
  // module:
  //		dijit/form/ToggleButton

  return declare("dijit.form.ToggleButton", [Button, _ToggleButtonMixin], {
    // summary:
    //		A templated button widget that can be in two states (checked or not).
    //		Can be base class for things like tabs or checkbox or radio buttons.

    baseClass: "dijitToggleButton",
    setChecked: function ( /*Boolean*/checked) {
      // summary:
      //		Deprecated.  Use set('checked', true/false) instead.
      kernel.deprecated("setChecked(" + checked + ") is deprecated. Use set('checked'," + checked + ") instead.", "", "2.0");
      this.set('checked', checked);
    }
  });
});