define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/negate", "vendor/lodash-amd/isNil", "mojo/widgets/Renderable", "mojo/widgets/FeedbackBlock", "mojo/report/Stat", "mojo/utils", "mojo/utils/date", "mojo/context", "mojo/lib/flags", "mojo/report/widgets/BigStats", "mojo/report/widgets/StatsTable", "mojo/report/widgets/StatsTableEntries", "mojo/social-post/widgets/ImpressionsEngagementChart"], function (declare, negate, isNil, Renderable, FeedbackBlock, Stat, utils, dateUtils, context, flags, BigStats, StatsTable, StatsTableEntries, ImpressionsEngagementChart) {
  return declare([Renderable], {
    templateString: '<div class="line sub-section" data-dojo-attach-point="subsectionsContainer"></div>',
    platformNames: {
      "instagram": "Instagram",
      "facebook": "Facebook",
      "twitter": "Twitter"
    },
    engagementSubStatNamesByNetwork: {
      "facebook": {
        "reactions": "Reactions",
        "comments": "Comments",
        "shares": "Shares"
      },
      "instagram": {
        "likes": "Likes",
        "comments": "Comments",
        "saves": "Saves"
      }
    },
    constructor: function (props) {
      this.socialPost = props.socialPost;
      this.report = props.report;
      this.hasTwitter = this.report.platforms.indexOf("twitter") > -1;
      this.multipleNetworks = this.report.platforms.length > 1;
      this.twitterOnly = this.hasTwitter && !this.multipleNetworks;
      if (this.hasTwitter && !this.multipleNetworks) {
        this.impressionsGraph = this.impressionsGraphTwitter();
        this.children = [this.metaInfo(), this.impressionsGraph];
      } else {
        this.impressionsGraph = this.impressionsGraph();
        this.children = [this.metaInfo(), this.cumulativeStats(), this.impressionsGraph, this.impressionsBreakdownFor("facebook"), this.impressionsBreakdownFor("instagram")];
      }
    },
    metaInfo: function () {
      // should the social post be shown in the user's browser timezone or their user.default's tz.
      // getDateString is automatically using the user.defaults tz.
      var publishedAtDate;
      var lastUpdatedDate;

      // dates are now objects - but still have to be transformed in jsDates for formatting
      // dateTransform is used to get a JS date object from a
      // date string (date_str) and the timezone offset (tz_float) of that date
      var jsPublishedDate = this.socialPost.postedAt ? dateUtils.dateTransform(this.socialPost.postedAt) : null;
      if (jsPublishedDate instanceof Object && jsPublishedDate.hasOwnProperty("jsDate")) {
        publishedAtDate = dateUtils.formatDateTime(jsPublishedDate.jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
      } else {
        publishedAtDate = jsPublishedDate ? dateUtils.getDateString(jsPublishedDate) : "";
      }
      var jsLastUpdatedDate = lastUpdatedDate ? dateUtils.dateTransform(this.report.lastUpdatedDate) : null;
      if (jsLastUpdatedDate instanceof Object && jsLastUpdatedDate.hasOwnProperty("jsDate")) {
        lastUpdatedDate = dateUtils.formatDateTime(jsLastUpdatedDate.jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
      } else {
        lastUpdatedDate = jsLastUpdatedDate ? dateUtils.getDateString(jsLastUpdatedDate) : "Never";
      }
      var metaInfo = new StatsTableEntries({
        stats: [new Stat("Status", "Published on " + publishedAtDate), new Stat("Channels", this.report.statsByPlatform.map(function (platform) {
          var linkTemplate = "<a href='{value}' target='_blank'>{label}</a>";
          var platformName = this.platformNames[platform.platform];
          var postUrl = platform.post_url;
          var link = utils.compileTemplate(linkTemplate, {
            label: platformName,
            value: postUrl
          });
          return postUrl ? link.outerHTML : platformName;
        }, this).join(", ")), new Stat("Last Updated", lastUpdatedDate, this.twitterOnly ? " " : "We'll update these stats periodically so they may not reflect real time activity.")],
        format: StatsTableEntries.FORMAT_FACTOID
      });
      return new StatsTable({
        children: [metaInfo]
      });
    },
    cumulativeStats: function () {
      var bigStats = [new Stat("Total Reach", utils.numberFormat(this.report.cumulativeStats.reach, 0, ".", ","), "&#128587; The total number of unique people who see your post."), new Stat("Total Impressions", utils.numberFormat(this.report.cumulativeStats.impressions, 0, ".", ","), "&#128200; The number of times your post is displayed, whether the post is clicked or not."), new Stat("Engagement", utils.numberFormat(this.report.cumulativeStats.engagements, 0, ".", ","), "&#128483; The total number of likes, shares, and comments by people involving your post.")];
      return new BigStats({
        stats: bigStats
      });
    },
    impressionsGraph: function () {
      var instagramMetrics = this.report.statsByPlatform.filter(function (platformStats) {
        return platformStats.platform === "instagram";
      })[0];
      var facebookMetrics = this.report.statsByPlatform.filter(function (platformStats) {
        return platformStats.platform === "facebook";
      })[0];
      var engagementStats = [];
      var impressionsStats = [];
      if (instagramMetrics) {
        engagementStats.push(new Stat("Instagram", String(instagramMetrics.stats.engagements)));
        impressionsStats.push(new Stat("Instagram", String(instagramMetrics.stats.impressions)));
      }
      if (facebookMetrics) {
        engagementStats.push(new Stat("Facebook", String(facebookMetrics.stats.engagements)));
        impressionsStats.push(new Stat("Facebook", String(facebookMetrics.stats.impressions)));
      }
      return new ImpressionsEngagementChart({
        engagement: engagementStats,
        impressions: impressionsStats
      });
    },
    impressionsGraphTwitter: function () {
      var engagementStats = [];
      var impressionsStats = [];
      var postedTweet = "";
      this.report.statsByPlatform.forEach(function (network) {
        if (network.platform === "twitter") {
          postedTweet = network.post_url;
        }
      });
      engagementStats.push(new Stat("Facebook", String(20)));
      impressionsStats.push(new Stat("Facebook", String(0)));
      engagementStats.push(new Stat("Instagram", String(0)));
      impressionsStats.push(new Stat("Instagram", String(0)));
      engagementStats.push(new Stat("Twitter", String(0)));
      impressionsStats.push(new Stat("Twitter", String(0)));
      return new ImpressionsEngagementChart({
        engagement: engagementStats,
        impressions: impressionsStats,
        showMask: true,
        tweetUrl: postedTweet
      });
    },
    impressionsBreakdownFor: function (networkName) {
      var matchingNetwork = this.report.statsByPlatform.filter(function (platformStats) {
        return platformStats.platform === networkName;
      })[0];
      if (!matchingNetwork) {
        return null;
      }
      var stats = matchingNetwork.stats;
      var impressionsAndReachTable = new StatsTableEntries({
        stats: [new Stat("Impressions", utils.numberFormat(stats.impressions || 0, 0, ".", ",")), new Stat("Reach", utils.numberFormat(stats.reach || 0, 0, ".", ","))],
        format: StatsTableEntries.FORMAT_BREAKDOWN
      });
      var engagementStat = new Stat("Engagement", utils.numberFormat(stats.engagements, 0, ".", ","));
      var engagementSubStatNames = this.engagementSubStatNamesByNetwork[networkName];
      var engagementBreakdownStats = [];
      Object.keys(engagementSubStatNames).forEach(function (statKey) {
        if (stats[statKey] !== undefined) {
          engagementBreakdownStats.push(new Stat(engagementSubStatNames[statKey], utils.numberFormat(stats[statKey] || 0, 0, ".", ",")));
        }
      });
      var engagementTable = new StatsTableEntries({
        stats: [engagementStat, engagementBreakdownStats],
        format: StatsTableEntries.FORMAT_BREAKDOWN
      });
      return new StatsTable({
        children: [impressionsAndReachTable, engagementTable],
        title: this.platformNames[networkName]
      });
    },
    render: function () {
      this.inherited(arguments);
      this.children.filter(negate(isNil)).map(function (widget) {
        widget.placeAt(this.subsectionsContainer);
      }.bind(this));

      /**
       * Adding this feedback block is for a one off scenario.  We did not want to break the existing
       * patterns for the report page components. If notifications are needed in between the section class
       * we may want to figure out a better way to handle feedback blocks in this instance.
       */

      var showFeedbackBlock = this.hasTwitter && this.multipleNetworks;
      if (showFeedbackBlock) {
        var postedTweet = "";
        this.report.statsByPlatform.forEach(function (network) {
          if (network.platform === "twitter") {
            postedTweet = network.post_url;
          }
        });
        var twitterLink = "<a href='" + postedTweet + "' target='_blank'>here</a>";
        var twitterSupport = new FeedbackBlock({
          title: "Tweet activity is not available",
          message: "Mailchimp does not support Twitter reporting. To view your tweet activity, click " + twitterLink + ".",
          type: "info"
        });
        twitterSupport.placeAt(this.children[0].domNode.querySelector(".sub-section"));
      }
    },
    startup: function () {
      this.impressionsGraph.createImpressionsEngagementChart();
    }
  });
});