define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags"], function (declare, user, flags) {
  function getNestedConditions(ast, field) {
    return (ast.nested_conditions || []).filter(function (nestedCondition) {
      return nestedCondition.field === field;
    });
  }

  /**
   * @package mojo/segmentation
   * @class FieldConfig
   */
  var FieldConfig = declare(null, {
    /**
     * @var string
     *
     * @private
     */
    field: null,
    /**
     * @var ?object[]
     *
     * @private
     */
    nested_fields: null,
    /**
     * @var array<{{label: string, value: string}}>
     *
     * @private
     */
    ops: null,
    constructor: function (rawConfig) {
      this.field = rawConfig.field;
      this.label = rawConfig.label;
      this.category = rawConfig.category;
      this.ops = rawConfig.ops;
      this.nested_fields = rawConfig.nested_fields;
    },
    /**
     * When rendering this field and particular operator, should the UI display the name before the operator?
     *
     * @public
     * @param {string} operator - Particular operator
     * @returns {boolean} True if name should display before operator
     */
    displaysNameBeforeOperator: function (operator) {
      if (this.field === "contact_event_property") {
        if (operator === "is") {
          return true;
        }
      }
      return false;
    },
    supportsMultipleNestedConditions: function (nestedField) {
      if (this.field === "contact_event") {
        if (nestedField === "contact_event_property") {
          return true;
        }
      }
      return false;
    },
    /**
     * Given an AST, return a field config which reflects the inter-condition restrictions imposed by the AST's
     * field and value selections.
     *
     * @param {object} ast Plain javascript object AST
     *
     * @return {object[]} Nested field definitions
     */
    nestedFieldsFromAST: function (ast) {
      var nestedFields = this.nested_fields || [];

      // first: make a copy of the nestedFields, so that its safe to mutate them later (without impacting `this`)
      nestedFields = nestedFields.map(function (nestedField) {
        return JSON.parse(JSON.stringify(nestedField));
      });

      // second: take only the nested fields that the AST permits
      // eg. `email_campaign_activity` field only shows the `click_url` nested fields when the operator is `click` or `not_click`
      nestedFields = nestedFields.filter(function (nestedField) {
        return this._shouldShowNestedFieldGivenAST(ast, nestedField);
      }.bind(this));

      // finally: of the remaining nested fields, mutate the nested field configuration, per the AST's values
      // eg. `contact_event` field's `contact_event_property` nested field's dropdown options reflect the AST's
      // selected value of the `contact_event_name` nested field.
      nestedFields.forEach(function (nestedField) {
        if (this.field === "contact_event" || this.field === "website_engagement") {
          if (nestedField.field === "contact_event_property") {
            var contactEventName = getNestedConditions(ast, "contact_event_name")[0];
            if (!contactEventName) {
              return;
            }
            var name = contactEventName.value;
            nestedField.ops.forEach(function (op) {
              if (op.value === "is") {
                op.input.options = "contact_event_property_name_options_for_event_" + name;
              }
            });
          }
          if (nestedField.field === "contact_event_property0") {
            var contactEventName = getNestedConditions(ast, "contact_event_name")[0];
            if (!contactEventName) {
              return;
            }
            var name = contactEventName.value;
            nestedField.ops.forEach(function (op) {
              if (op.value === "is") {
                op.input.options = "web_engagement_property_options_for_event_" + name;
              }
            });
          }
        } else {
          if (this.field === "contact_event") {
            if (nestedField.field === "contact_event_property") {
              var contactEventName = getNestedConditions(ast, "contact_event_name")[0];
              if (!contactEventName) {
                return;
              }
              var name = contactEventName.value;
              nestedField.ops.forEach(function (op) {
                if (op.value === "is") {
                  op.input.options = "contact_event_property_name_options_for_event_" + name;
                }
              });
            }
          }
        }
      }.bind(this));
      return nestedFields;
    },
    /**
     * Determine whether a nestedField should be shown, given an AST. This is where we limit which fields can or
     * cannot be used with other parent/sibling fields/operators.
     *
     * @param {object} ast - AST that the user has configured their segment as
     * @param {mojo/segmentation/FieldConfig} nestedField - Field to show/hide
     *
     * @returns {boolean} Whether this `nestedField` should be shown or not
     *
     * @private
     */
    _shouldShowNestedFieldGivenAST: function (ast, nestedField) {
      if (this.field === "email_campaign_activity") {
        if (nestedField.field === "timeframe") {
          // show the timeframe condition only when the nested condition `campaign`'s operator is any/all last
          var campaignField = getNestedConditions(ast, "campaign")[0];
          return campaignField && campaignField.op.match(/(any|all)last\d/) === null;
        }
        if (nestedField.field === "click_url") {
          // show the click_url condition only when the email_campaign_activity operator is `"click"` or `"not_click"`
          return ast.op === "click" || ast.op === "not_click";
        }
      }

      // unless specified otherwise, show all nested fields by default
      return true;
    },
    clone: function () {
      return new FieldConfig({
        field: this.field,
        label: this.label,
        category: this.category,
        ops: this.ops,
        nested_fields: this.nested_fields
      });
    }
  });
  return FieldConfig;
});