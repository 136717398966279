/**
 * Input With Label
 * @module mojo/widgets/ads/facebook/edit-content/InputWithLabel.js
 * @see mojo/widgets/ads/facebook/edit-content/CarouselItem.js
 *
 * Widget responsible for normalizing behavior across all inputs
 * in a carousel item.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/CountRemainingCharacters", "velocity/velocity",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/InputWithLabel.html",
// Doesn't require a var
"velocity/velocity.ui"], function (declare, lang, domConstruct, on, topic, CharCount, Velocity, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    animationDuration: 250,
    // Matches field-help pattern.

    type: "",
    // Text, select, textarea
    label: "",
    name: "",
    required: true,
    lengthLimit: null,
    content: {},
    // helper text, placeholder, initial value

    postCreate: function () {
      this._initInput();
      this._initHelpText();
      this._initCharCount();
    },
    /**
     * Gather all of the necessary data and create the node.
     *
     * @private
     */
    _initInput: function () {
      var nodeType = null;
      var typeSpecificAttrs = null;
      switch (this.type) {
        case "textarea":
          // TODO: Integrate with ResizeTextarea Widget
          nodeType = "textarea";
          typeSpecificAttrs = {
            placeholder: this.content.placeholder ? this.content.placeholder : "",
            innerHTML: this.content.value ? this.content.value : "",
            cols: "30",
            rows: "2",
            className: "resize--none"
          };
          break;
        default:
          nodeType = "input";
          typeSpecificAttrs = {
            type: this.type,
            value: this.content.value ? this.content.value : "",
            placeholder: this.content.placeholder ? this.content.placeholder : ""
          };
      }
      var attrs = lang.mixin({
        id: this.id + "_input",
        name: this.name,
        maxlength: this.lengthLimit ? this.lengthLimit : ""
      }, typeSpecificAttrs);
      this.input = domConstruct.create(nodeType, attrs, this.inputContainer);

      // Show/hide character count and/or required label.
      on(this.input, "focus", lang.hitch(this, "_handleInputFocus"));
      on(this.input, "blur", lang.hitch(this, "_handleInputBlur"));
    },
    /**
     * Simple function to setup help text if valid. It needs to be in the same input container so that it animates
     * based on input focus.
     *
     * @private
     */
    _initHelpText: function () {
      // Don't bother unless we are passed helptext content.
      if (!this.content.helpText) {
        return;
      }

      // Add the node to the right place.
      domConstruct.create("div", {
        className: "field-help",
        innerHTML: this.content.helpText
      }, this.inputContainer);
    },
    /**
     * Startup the char count widget. It's placed in a hidden node for easier animation later.
     *
     * @private
     */
    _initCharCount: function () {
      // Don't bother unless they actually want to have a max limit
      if (!this.lengthLimit) {
        return;
      }
      this.charCount = new CharCount({
        inputField: this.id + "_input",
        characters: this.lengthLimit,
        className: "small-meta"
      });
      this.charCount.placeAt(this.charCountContainer);
      this.charCount.startup();
    },
    /**
     * On focus of the input, if there's a character count, animate that node in.
     *
     * @private
     */
    _handleInputFocus: function () {
      if (!this.lengthLimit) {
        return;
      }
      if (this.required) {
        this._animateInWithRequired();
        return;
      }

      // If there isn't a required label, animate the character count widget anyway
      Velocity(this.charCountContainer, "transition.slideUpIn", {
        duration: this.animationDuration
      });
    },
    /**
     * On blur of the inputs, hide the character count and compare values
     *
     * @private
     */
    _handleInputBlur: function () {
      this._compareValues();
      if (!this.lengthLimit) {
        return;
      }
      if (this.required) {
        this._animateOutWithRequired();
        return;
      }

      // If there isn't a required label, animate the character count widget anyway
      Velocity(this.charCountContainer, "transition.slideDownOut", {
        duration: this.animationDuration
      });
    },
    /**
     * Animate in the character count widget and hide the required field. Make it look cool.
     *
     * @private
     */
    _animateInWithRequired: function () {
      Velocity(this.requiredIndicator, "transition.slideUpOut", {
        duration: this.animationDuration / 2,
        // Account for the fact this'll take twice as long as help text
        complete: lang.hitch(this, function () {
          Velocity(this.charCountContainer, "transition.slideUpIn", {
            duration: this.animationDuration / 2
          });
        })
      });
    },
    /**
     * Do the exact opposite of animating in.
     *
     * @private
     */
    _animateOutWithRequired: function () {
      Velocity(this.charCountContainer, "transition.slideDownOut", {
        duration: this.animationDuration / 2,
        complete: lang.hitch(this, function () {
          Velocity(this.requiredIndicator, "transition.slideDownIn", {
            duration: this.animationDuration / 2
          });
        })
      });
    },
    /**
     * Public method used to standardize value retrieval across all CarouselItem inputs.
     *
     * TODO: Make adjustments for TextArea once ResizeTextarea widget is wired.
     *
     * @returns {*|string} - The value of this single input.
     */
    getValue: function () {
      return this.input.value;
    },
    /**
     * Public method used to get the passed in value from the backend on load/save
     * of the CarouselItem
     *
     * @returns {*|string} - The value of this single input on initial load or save.
     */
    getPassedInValue: function () {
      return this.content.value;
    },
    /**
     * Check to see if the value in the form matches the value from the data
     *
     * @private
     */
    _compareValues: function () {
      if (this.getValue() !== this.getPassedInValue()) {
        topic.publish("content/carouselItem/inputChanged", {
          hasChanged: true
        });
      }
    }
  });
});