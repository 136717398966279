/**
 * Module that contains all the logic used to handle the element used to pick a winner manually
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_Templated", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/variateWinner.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _Templated, _WidgetsInTemplateMixin, tpl, on, parser, lang, array, html, domClass, domStyle) {
  return declare([_WidgetBase, _Templated, _WidgetsInTemplateMixin], {
    templateString: tpl,
    winner: null,
    postCreate: function () {
      this.showSelection();
      this.own(on(this.sendBtn, "click", lang.hitch(this, "onSelect")));
    },
    /**
     * Show the mode in which we show how many combinations are currently selected.
     */
    showSelection: function () {
      domClass.remove(this.selectionContainer, "hide");
      domClass.add(this.winnerContainer, "hide");
    },
    /**
     * Show the currently selected winner based on what's already picked by the user.
     */
    showWinner: function (combination) {
      if (combination) {
        domClass.add(this.selectionContainer, "hide");
        domClass.remove(this.winnerContainer, "hide");
        html.set(this.subject, combination.subject);
        var fromNameLabel = "From " + combination.fromName + " &lt;" + combination.fromEmail + "&gt;";
        html.set(this.fromName, fromNameLabel);
        if (combination.contentDescription) {
          var contentLabel = "&ldquo;" + combination.contentDescription + "&rdquo;";
          html.set(this.contentDescription, contentLabel);
        }
        this._updateScreenshot(combination.screenshotUrl);
        this.winner = combination;
      }
    },
    _updateScreenshot: function (url) {
      domStyle.set(this.screenshot, "background", "url(" + url + ") no-repeat center top / cover #f2f2f2");
    },
    // For custom callbacks
    onSelect: function () {}
  });
});