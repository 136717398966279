define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./sorter"], function (lang, kernel, sorter) {
  // module:
  //		dojo/data/util/simpleFetch
  // summary:
  //		The simpleFetch mixin is designed to serve as a set of function(s) that can
  //		be mixed into other datastore implementations to accelerate their development.

  var simpleFetch = {};
  lang.setObject("dojo.data.util.simpleFetch", simpleFetch);
  simpleFetch.errorHandler = function ( /*Object*/errorData, /*Object*/requestObject) {
    // summary:
    //		The error handler when there is an error fetching items.  This function should not be called
    //		directly and is used by simpleFetch.fetch().
    if (requestObject.onError) {
      var scope = requestObject.scope || kernel.global;
      requestObject.onError.call(scope, errorData, requestObject);
    }
  };
  simpleFetch.fetchHandler = function ( /*Array*/items, /*Object*/requestObject) {
    // summary:
    //		The handler when items are successfully fetched.  This function should not be called directly
    //		and is used by simpleFetch.fetch().
    var oldAbortFunction = requestObject.abort || null,
      aborted = false,
      startIndex = requestObject.start ? requestObject.start : 0,
      endIndex = requestObject.count && requestObject.count !== Infinity ? startIndex + requestObject.count : items.length;
    requestObject.abort = function () {
      aborted = true;
      if (oldAbortFunction) {
        oldAbortFunction.call(requestObject);
      }
    };
    var scope = requestObject.scope || kernel.global;
    if (!requestObject.store) {
      requestObject.store = this;
    }
    if (requestObject.onBegin) {
      requestObject.onBegin.call(scope, items.length, requestObject);
    }
    if (requestObject.sort) {
      items.sort(sorter.createSortFunction(requestObject.sort, this));
    }
    if (requestObject.onItem) {
      for (var i = startIndex; i < items.length && i < endIndex; ++i) {
        var item = items[i];
        if (!aborted) {
          requestObject.onItem.call(scope, item, requestObject);
        }
      }
    }
    if (requestObject.onComplete && !aborted) {
      var subset = null;
      if (!requestObject.onItem) {
        subset = items.slice(startIndex, endIndex);
      }
      requestObject.onComplete.call(scope, subset, requestObject);
    }
  };
  simpleFetch.fetch = function ( /* Object? */request) {
    // summary:
    //		The simpleFetch mixin is designed to serve as a set of function(s) that can
    //		be mixed into other datastore implementations to accelerate their development.
    // description:
    //		The simpleFetch mixin should work well for any datastore that can respond to a _fetchItems()
    //		call by returning an array of all the found items that matched the query.  The simpleFetch mixin
    //		is not designed to work for datastores that respond to a fetch() call by incrementally
    //		loading items, or sequentially loading partial batches of the result
    //		set.  For datastores that mixin simpleFetch, simpleFetch
    //		implements a fetch method that automatically handles eight of the fetch()
    //		arguments -- onBegin, onItem, onComplete, onError, start, count, sort and scope
    //		The class mixing in simpleFetch should not implement fetch(),
    //		but should instead implement a _fetchItems() method.  The _fetchItems()
    //		method takes three arguments, the keywordArgs object that was passed
    //		to fetch(), a callback function to be called when the result array is
    //		available, and an error callback to be called if something goes wrong.
    //		The _fetchItems() method should ignore any keywordArgs parameters for
    //		start, count, onBegin, onItem, onComplete, onError, sort, and scope.
    //		The _fetchItems() method needs to correctly handle any other keywordArgs
    //		parameters, including the query parameter and any optional parameters
    //		(such as includeChildren).  The _fetchItems() method should create an array of
    //		result items and pass it to the fetchHandler along with the original request object --
    //		or, the _fetchItems() method may, if it wants to, create an new request object
    //		with other specifics about the request that are specific to the datastore and pass
    //		that as the request object to the handler.
    //
    //		For more information on this specific function, see dojo/data/api/Read.fetch()
    //
    // request:
    //		The keywordArgs parameter may either be an instance of
    //		conforming to dojo/data/api/Request or may be a simple anonymous object
    //		that may contain any of the following:
    // |	{
    // |		query: query-object or query-string,
    // |		queryOptions: object,
    // |		onBegin: Function,
    // |		onItem: Function,
    // |		onComplete: Function,
    // |		onError: Function,
    // |		scope: object,
    // |		start: int
    // |		count: int
    // |		sort: array
    // |	}
    //		All implementations should accept keywordArgs objects with any of
    //		the 9 standard properties: query, onBegin, onItem, onComplete, onError
    //		scope, sort, start, and count.  Some implementations may accept additional
    //		properties in the keywordArgs object as valid parameters, such as
    //		{includeOutliers:true}.
    //
    //		####The *query* parameter
    //
    //		The query may be optional in some data store implementations.
    //		The dojo/data/api/Read API does not specify the syntax or semantics
    //		of the query itself -- each different data store implementation
    //		may have its own notion of what a query should look like.
    //		However, as of dojo 0.9, 1.0, and 1.1, all the provided datastores in dojo.data
    //		and dojox.data support an object structure query, where the object is a set of
    //		name/value parameters such as { attrFoo: valueBar, attrFoo1: valueBar1}.  Most of the
    //		dijit widgets, such as ComboBox assume this to be the case when working with a datastore
    //		when they dynamically update the query.  Therefore, for maximum compatibility with dijit
    //		widgets the recommended query parameter is a key/value object.  That does not mean that the
    //		the datastore may not take alternative query forms, such as a simple string, a Date, a number,
    //		or a mix of such.  Ultimately, The dojo/data/api/Read API is agnostic about what the query
    //		format.
    //
    //		Further note:  In general for query objects that accept strings as attribute
    //		value matches, the store should also support basic filtering capability, such as *
    //		(match any character) and ? (match single character).  An example query that is a query object
    //		would be like: { attrFoo: "value*"}.  Which generally means match all items where they have
    //		an attribute named attrFoo, with a value that starts with 'value'.
    //
    //		####The *queryOptions* parameter
    //
    //		The queryOptions parameter is an optional parameter used to specify options that may modify
    //		the query in some fashion, such as doing a case insensitive search, or doing a deep search
    //		where all items in a hierarchical representation of data are scanned instead of just the root
    //		items.  It currently defines two options that all datastores should attempt to honor if possible:
    // |	{
    // |		ignoreCase: boolean, // Whether or not the query should match case sensitively or not.  Default behaviour is false.
    // |		deep: boolean	// Whether or not a fetch should do a deep search of items and all child
    // |						// items instead of just root-level items in a datastore.  Default is false.
    // |	}
    //
    //		####The *onBegin* parameter.
    //
    //		function(size, request);
    //		If an onBegin callback function is provided, the callback function
    //		will be called just once, before the first onItem callback is called.
    //		The onBegin callback function will be passed two arguments, the
    //		the total number of items identified and the Request object.  If the total number is
    //		unknown, then size will be -1.  Note that size is not necessarily the size of the
    //		collection of items returned from the query, as the request may have specified to return only a
    //		subset of the total set of items through the use of the start and count parameters.
    //
    //		####The *onItem* parameter.
    //
    //		function(item, request);
    //
    //		If an onItem callback function is provided, the callback function
    //		will be called as each item in the result is received. The callback
    //		function will be passed two arguments: the item itself, and the
    //		Request object.
    //
    //		####The *onComplete* parameter.
    //
    //		function(items, request);
    //
    //		If an onComplete callback function is provided, the callback function
    //		will be called just once, after the last onItem callback is called.
    //		Note that if the onItem callback is not present, then onComplete will be passed
    //		an array containing all items which matched the query and the request object.
    //		If the onItem callback is present, then onComplete is called as:
    //		onComplete(null, request).
    //
    //		####The *onError* parameter.
    //
    //		function(errorData, request);
    //
    //		If an onError callback function is provided, the callback function
    //		will be called if there is any sort of error while attempting to
    //		execute the query.
    //		The onError callback function will be passed two arguments:
    //		an Error object and the Request object.
    //
    //		####The *scope* parameter.
    //
    //		If a scope object is provided, all of the callback functions (onItem,
    //		onComplete, onError, etc) will be invoked in the context of the scope
    //		object.  In the body of the callback function, the value of the "this"
    //		keyword will be the scope object.   If no scope object is provided,
    //		the callback functions will be called in the context of dojo.global().
    //		For example, onItem.call(scope, item, request) vs.
    //		onItem.call(dojo.global(), item, request)
    //
    //		####The *start* parameter.
    //
    //		If a start parameter is specified, this is a indication to the datastore to
    //		only start returning items once the start number of items have been located and
    //		skipped.  When this parameter is paired with 'count', the store should be able
    //		to page across queries with millions of hits by only returning subsets of the
    //		hits for each query
    //
    //		####The *count* parameter.
    //
    //		If a count parameter is specified, this is a indication to the datastore to
    //		only return up to that many items.  This allows a fetch call that may have
    //		millions of item matches to be paired down to something reasonable.
    //
    //		####The *sort* parameter.
    //
    //		If a sort parameter is specified, this is a indication to the datastore to
    //		sort the items in some manner before returning the items.  The array is an array of
    //		javascript objects that must conform to the following format to be applied to the
    //		fetching of items:
    // |	{
    // |		attribute: attribute || attribute-name-string,
    // |		descending: true|false;   // Optional.  Default is false.
    // |	}
    //		Note that when comparing attributes, if an item contains no value for the attribute
    //		(undefined), then it the default ascending sort logic should push it to the bottom
    //		of the list.  In the descending order case, it such items should appear at the top of the list.

    request = request || {};
    if (!request.store) {
      request.store = this;
    }
    this._fetchItems(request, lang.hitch(this, "fetchHandler"), lang.hitch(this, "errorHandler"));
    return request; // Object
  };
  return simpleFetch;
});