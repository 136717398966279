define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/widgets/Mask", "mccharts/mccharts", "mojo/colors", "mojo/url", "dojo/text!./templates/ImpressionsEngagementChart.html"], function (declare, Renderable, Mask, mccharts, mColors, mUrl, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.engagementStats = props.engagement;
      this.impressionsStats = props.impressions;
      this.showMask = props.showMask;
      this.tweetUrl = props.tweetUrl;
    },
    createImpressionsEngagementChart: function () {
      var impressionsEngagementChart = mccharts.models.stackBarChart();
      if (this.showMask) {
        var newContainer = new Mask({
          title: "Apologies!",
          message: "Mailchimp does not support Twitter Reporting.",
          imageUrl: "https://cdn-images.mailchimp.com/product/twitter-reporting-apology.png",
          buttonUrl: this.tweetUrl,
          buttonText: "View Tweet"
        });
        newContainer.placeAt(this.maskContainer);
      }

      // the value gets changed in social-post/widgets/ReportPage.js to a string when we pass in the props.
      // we have to make sure it is a number. if it is not a number, make it 0
      var impressionsData = this.impressionsStats.map(function (row) {
        return {
          platform: row.label,
          val: Number(row.value) || 0
        };
      });
      var engagementData = this.engagementStats.map(function (row) {
        return {
          platform: row.label,
          val: Number(row.value) || 0
        };
      });
      var data = {
        "impressions": impressionsData,
        "engagement": engagementData
      };
      impressionsEngagementChart.aspectRatio(2, 1);
      impressionsEngagementChart.xAxis.tickFormat(mccharts.u.stringTick);
      impressionsEngagementChart.xAxis.keyValue("platform");
      impressionsEngagementChart.yAxis.keyValue("val");
      impressionsEngagementChart.yAxis.maxTicks(6);
      impressionsEngagementChart.colors([mColors.dataViz.combo5.color3, mColors.dataViz.combo5.color1]);
      impressionsEngagementChart.noDataMessage("We're not seeing anything yet - check again later for updated stats.");
      var impressionsEngagementLegend = impressionsEngagementChart.legend();
      impressionsEngagementLegend.seriesLabels(["Impressions", "Engagement"]);
      var toolTip = impressionsEngagementChart.tooltip();
      toolTip.enabled(true);
      toolTip.xOffset(10);
      toolTip.dataFormatter(function (d) {
        var str = impressionsEngagementLegend.seriesLabels()[d.seriesIndex] + ": " + d.y;
        return str;
      });
      impressionsEngagementLegend.horizontalAlign("right");
      impressionsEngagementChart.loadChart("#impressionsEngagementContainer", data);
    }
  });
});