define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/NoDataBlock", "mojo/user", "mojo/lib/flags", "dojo/text!./templates/FacebookNotConnected.html"], function (declare, lang, domClass, topic, _WidgetBase, _TemplatedMixin, noDataBlock, user, flags, facebookNotConnectedTpl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: facebookNotConnectedTpl,
    translationTerms: {},
    constructor: function (props) {
      this.props = props;
    },
    postCreate: function () {
      return this._initializeNewFacebookConnect();
    },
    // This is not being used, see PR: https://git.rsglab.com/product/mailchimp/pull/46055/files
    _initializeControl: function () {
      var errorContent = {
        title: this.props.translationTerms.content_studio_facebook_connect_error_title,
        message: this.props.translationTerms.content_studio_facebook_connect_error_message,
        buttonText: this.props.translationTerms.content_studio_facebook_connect_error_button_text,
        buttonLink: "/facebook/login?push=true",
        buttonNewWindow: true,
        handleButtonClick: lang.hitch(this, function () {
          this._handleFacebookAuthClick();
        })
      };
      errorContent.class = "noborder";
      var noData = new noDataBlock(errorContent);
      noData.placeAt(this.facebookConnectMsg);
      noData.startup();
      domClass.remove(this.facebookConnectMsg, "hide");
    },
    _initializeNewFacebookConnect: function () {
      var errorContent = {
        title: this.props.translationTerms.content_studio_new_facebook_connect_error_button_text,
        message: this.props.translationTerms.content_studio_new_facebook_connect_error_message,
        buttonText: this.props.translationTerms.content_studio_new_facebook_connect_error_button_text,
        buttonLink: "/facebook/login?push=true",
        buttonNewWindow: true,
        handleButtonClick: lang.hitch(this, function () {
          this._handleFacebookAuthClick();
        })
      };
      errorContent.class = "noborder";
      errorContent.icon = "empty-facebook-ron";
      var noData = new noDataBlock(errorContent);
      noData.placeAt(this.facebookConnectMsg);
      noData.startup();
      domClass.remove(this.facebookConnectMsg, "hide");
    },
    _handleFacebookAuthClick: function () {
      topic.publish("mojo/content-manager/api/auth-channel", "click");
    }
  });
});