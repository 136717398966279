define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./ImageCard"], function (declare, lang, Deferred, ImageCard) {
  return declare([ImageCard], {
    type: "webVideo",
    _name: "Video",
    _kbLink: "https://mailchimp.com/help/use-video-content-blocks/",
    _campaignType: "page",
    _renderMergeTags: "true",
    mergeTag: "WEB_VIDEO",
    _imageUploaderType: "video",
    captionEnabled: false,
    constructor: function () {
      this.caption = {
        text: this._defaultCaption,
        image: null
      };
      this.captionPosition = "bottom"; // [top,left,right,bottom]
      this.captionWidth = "two-third"; // [half, one-third, two-third, three-quarter]

      this.styles = [{
        "name": "Block",
        "selector": ".mcnImageCardRightContentInner, .mcnImageCardLeftContentInner, .mcnImageCardBottomContent, .mcnImageCardTopContent",
        "props": {
          "width": {
            "editorInputType": "percentage",
            "label": "Width",
            "selector": ".mcnVideoContainer",
            "value": "100%"
          },
          "border": {
            "value": ""
          }
        }
      }, {
        "name": "Caption style",
        "selector": ".mcnTextContent, .mcnTextContent p",
        "props": {
          "background-color": {
            "selector": ".mcnImageCardRightContentInner, .mcnImageCardLeftContentInner, .mcnImageCardBottomContent, .mcnImageCardTopContent",
            "value": "#404040"
          },
          "color": {
            "value": "#F2F2F2"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "14px"
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": "normal"
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": "center"
          }
        }
      }];
      this.video = {
        url: "",
        image: null,
        service: null,
        mergeTag: this.mergeTag,
        mergeString: ""
      };
    },
    postscript: function (params, blockOverride) {
      // clean up an old "flex" style and convert to "width"
      var flexValue;
      if (params.styles && params.styles[0] && params.styles[0].props && params.styles[0].props.flex) {
        flexValue = params.styles[0].props.flex.value.substring(4);
        delete params.styles[0].props.flex;
      }

      // Clean up an old background color style for block
      var bgValue;
      if (params.styles && params.styles[0] && params.styles[0].props && params.styles[0].props["background-color"]) {
        bgValue = params.styles[0].props["background-color"].value;
        delete params.styles[0].props["background-color"];
      }

      // run default method
      this.inherited(arguments);

      // pass old flexValue to width if it exists
      if (flexValue) {
        this.styles[0].props.width.value = flexValue;
      }

      // Pass old background color value, if it exists
      if (bgValue) {
        this.styles[1].props["background-color"].value = bgValue;
      }
    },
    /**
     * Toggle caption in web video block
     * @param {Boolean} value - pass the checkbox value as boolean
     */
    toggleCaption: function (value) {
      this.captionEnabled = value;
      this.save();
    }
  });
});