define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/campaigns/CampaignArchiveUrl", "mojo/user", "mojo/widgets/Renderable", "mojo/url", "dojo/text!./templates/reconfirm-dialog.html"], function (declare, CampaignArchiveUrl, user, Renderable, mUrl, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.state = {
        user: {
          isLimitedTrial: user.isLimitedTrial
        },
        reconfirmUrl: mUrl.toUrl("/campaigns/reconfirm-egp", {
          id: props.model.campaignId
        }),
        errorState: props.model.errorState
      };
    }
  });
});