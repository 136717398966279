define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/context", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, context, on) {
  return declare([base], {
    onReady: function () {
      window.ga("send", "event", "sign up", "step complete", "email confirmed", {
        "dimension64": "email confirmed",
        "dimension65": "2"
      });
      if (window.dataLayer) {
        if (context.flagIsOn("app.google_analytics_4_signup")) {
          window.dataLayer.push({
            event: "e_signUpStep",
            stepName: "email confirmed",
            stepNumber: "2",
            selectedPlan: this.selectedPlan,
            selectedContacts: this.selectedContacts,
            // [GA4]
            process_name: "signup",
            process_type: "account",
            process_step: 2,
            process_step_name: "email_confirmed",
            process_status: null
          });
        } else {
          window.dataLayer.push({
            event: "e_signUpStep",
            stepName: "email confirmed",
            stepNumber: "2",
            selectedPlan: this.selectedPlan,
            selectedContacts: this.selectedContacts
          });
        }
      }
      if (this.$el.confirmBtn) {
        on(this.$el.confirmBtn, "click", function () {
          window.ga("send", "event", "sign up", "step complete", "confirm human", {
            "dimension64": "confirm human",
            "dimension65": "3"
          });
          if (window.dataLayer) {
            if (context.flagIsOn("app.google_analytics_4_signup")) {
              window.dataLayer.push({
                event: "e_signUpStep",
                stepName: "confirm human",
                stepNumber: "3",
                selectedPlan: this.selectedPlan,
                selectedContacts: this.selectedContacts,
                // [GA4]
                process_name: "signup",
                process_type: "account",
                process_step: 3,
                process_step_name: "confirm_human",
                process_status: null
              });
            } else {
              window.dataLayer.push({
                event: "e_signUpStep",
                stepName: "confirm human",
                stepNumber: "3",
                selectedPlan: this.selectedPlan,
                selectedContacts: this.selectedContacts
              });
            }
          }
        });

        /* category: mandrill sign up, action: step complete, label: confirm human CDs:, 64: confirm human, 65:3, CMs: none, Notes: none */
        if (this.mandrillEntry) {
          on(this.$el.confirmBtn, "click", function () {
            window.ga("send", "event", "mandrill sign up", "step complete", "confirm human", {
              "dimension64": "confirm human",
              "dimension65": "3"
            });
          });
        }
      }

      /* category: mandrill sign up, action: step complete, label: activation screen CDs:, 64: activation screen, 65: 2, CMs: none,
          Notes: fire when page loads (fire along with the pageview hit) */
      if (this.mandrillEntry) {
        window.ga("send", "event", "mandrill sign up", "step complete", "activation screen", {
          "dimension64": "activation screen",
          "dimension65": "2"
        });
      }
    }
  });
});