define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/recipientCountBar.html", "dojo/text!./templates/fixedRecipientCountBar.html", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/throttle", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/campaigns/recipients", "mojo/app/pro/segment", "dojox/html/entities", "mojo/widgets/SubscriberTableOverlay", "dojo/number", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, fixedBarTpl, domClass, domConstruct, on, lang, dom, throttle, domGeom, recipients, proSegment, entities, SubscriberTableOverlay, number, flags, context, I18nTranslation) {
  var SEGMENT_STATES = {
    EXISTING: "existing",
    NEW: "new"
  };
  var SEGMENT_TYPES = {
    SAVED: "saved",
    ADVANCED: "advanced",
    PASTED: "pasted",
    REGULAR: "regular"
  };

  // Since all of this is done inside a wizard, we have to use the container id
  var bodyId = "av-content";
  var FixedBar = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: fixedBarTpl,
    currentBar: null,
    postCreate: function () {
      this.own(on(this.updateBtn, "click", lang.hitch(this, "_updateCount")), on(dom.byId(bodyId), "scroll", throttle(lang.hitch(this, "_handleScroll"), 10)), on(this.domNode, "[data-mc-action=preview]:click", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this._previewRecipients();
      })));
    },
    /**
     * on scroll event handler that hide/show the fixed bar
     *
     * @private
     */
    _handleScroll: function () {
      // get DOM positions
      var countBarPos = domGeom.position(this.currentBar.domNode, true);
      var container = domGeom.position(bodyId, true);

      // amount the in-list recipient bar is pushed down by it's older sibling's bottom-margin
      var fixedBarPaddingTop = 20;
      if (countBarPos.y - fixedBarPaddingTop <= container.y) {
        domClass.remove(this.domNode, "invisible");
      } else {
        domClass.add(this.domNode, "invisible");
      }
    },
    /**
     * on click handler for previewing recipients that calls the current bar to preview
     *
     * @private
     */
    _previewRecipients: function () {
      if (this.currentBar) {
        this.currentBar.previewRecipients();
      }
    },
    /**
     * on click handler for the button that calls the current bar to update its own count
     *
     * @private
     */
    _updateCount: function () {
      if (this.currentBar) {
        this.currentBar.reloadCount();
      }
    },
    /**
     * Method that other bars should call when they are updated so the fixed bar can mirror their state
     *
     * @param {mojo/app/campaigns/RecipientCountBar} bar - the bar we want to currently display
     */
    update: function (bar) {
      this.currentBar = bar;

      // Update content
      this.countNode.innerHTML = bar.countNode.innerHTML;
      this.copyNode.innerHTML = bar.copyNode.innerHTML;

      // Update visibility
      domClass.toggle(this.countNode, "hide", domClass.contains(bar.countNode, "hide"));
      domClass.toggle(this.copyNode, "hide", domClass.contains(bar.copyNode, "hide"));
      domClass.toggle(this.loadingNode, "hide", domClass.contains(bar.loadingNode, "hide"));
      domClass.toggle(this.updateBtn, "hide", domClass.contains(bar.updateBtn, "hide"));
    }
  });
  var fixedBarWidget;
  function updateFixedBar(bar) {
    if (!fixedBarWidget) {
      fixedBarWidget = new FixedBar();
      fixedBarWidget.startup();
      domConstruct.place(fixedBarWidget.domNode, dom.byId(bodyId), "last");
    }
    fixedBarWidget.update(bar);
  }
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    listId: null,
    listSize: 0,
    campaignId: null,
    selectedSegmentState: null,
    selectedSegmentName: null,
    selectedSegmentType: null,
    selectedAdvancedEditor: null,
    translatedTerms: {},
    postCreate: function () {
      I18nTranslation.initialize().then(function (value) {
        this.translatedTerms = value;
        this.own(on(this.updateBtn, "click", lang.hitch(this, "reloadCount")), on(this.domNode, "[data-mc-action=preview]:click", lang.hitch(this, function (e) {
          e.stopPropagation();
          e.preventDefault();
          this.previewRecipients();
        })));
        updateFixedBar(this);
      }.bind(this));
    },
    /**
     * if there's a segment selected, this will open up the subscriber overlay
     */
    previewRecipients: function () {
      if (this.selectedSegmentType) {
        switch (this.selectedSegmentType) {
          case SEGMENT_TYPES.ADVANCED:
            var ast = this.selectedAdvancedEditor.getAST();
            SubscriberTableOverlay.main().load(this.listId, ast, {
              showPagination: false,
              persistTableSettings: false
            }, "campaign");
            break;
          default:
            SubscriberTableOverlay.main().loadRegular(this.listId, this.campaignId, {
              showPagination: true,
              persistTableSettings: false
            });
        }
      }
    },
    /**
     * reloads the count for the recipients page.
     */
    reloadCount: function () {
      if (this.selectedSegmentType) {
        this._showLoadingState();
        switch (this.selectedSegmentType) {
          case SEGMENT_TYPES.ADVANCED:
            this._countAdvancedSegment();
            break;
          default:
            this._countSegment();
        }
      }
    },
    /**
     * relies on the page's segment form to figure out how to get the count,
     * so it relies on refreshSegmentCount to fetch it.
     *
     * @private
     */
    _countSegment: function () {
      var onSuccess = lang.hitch(this, function (result) {
        this._showLoadedState(result.count);
      });
      var onFail = lang.hitch(this, function () {
        this._showErrorState(this.translatedTerms.trouble_calculating_segmet);
      });
      recipients.refreshSegmentCount(this.listId, this.campaignId).then(onSuccess, onFail);
    },
    /**
     * Gets the AST config from the advanced segment editor and fetches the count
     *
     * @private
     */
    _countAdvancedSegment: function () {
      var onSuccess = lang.hitch(this, function (count) {
        if (count.match(/^[0-9,]+$/)) {
          this._showLoadedState(count);
        } else if (count === -2) {
          this._showErrorState(this.translatedTerms.segment_too_large_to_count);
        } else {
          this._showErrorState(this.translatedTerms.segment_incomplete);
        }
      });
      var onFail = lang.hitch(this, function () {
        this._showErrorState(this.translatedTerms.trouble_calculating_segment);
      });
      var ast = this.selectedAdvancedEditor.getAST();
      proSegment.refreshSegmentCount(this.listId, ast, "campaign").then(onSuccess, onFail);
    },
    /**
     * Updates the bar to reflect that a user chose to send to the entire audience.
     */
    selectEntireList: function () {
      this._clearSegment();
      this._updateCopy(this.translatedTerms.recipients_everyone_in_your_audience);
      this._updateCount(this.listSize);
      this._hideButton();
      this._showCount();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Update the bar when a user selects a saved segment
     *
     * @param {string} segmentName - the display name
     */
    selectSavedSegment: function (segmentName) {
      this._clearSegment();
      this.selectedSegmentState = SEGMENT_STATES.EXISTING;
      this.selectedSegmentName = entities.encode(segmentName);
      this.selectedSegmentType = SEGMENT_TYPES.SAVED;
      if (segmentName) {
        this._updateCopy(this.translatedTerms.selected_segment + "<strong>" + this.selectedSegmentName + "</strong>");
        this._showButton();
      } else {
        this._updateCopy(this.translatedTerms.select_recipients);
        this._hideButton();
      }
      this._hideCount();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Update the bar when a user chooses to use a pasted segment
     */
    selectPastedSegment: function () {
      this._clearSegment();
      this.selectedSegmentState = SEGMENT_STATES.NEW;
      this.selectedSegmentType = SEGMENT_TYPES.PASTED;
      this._updateCopy(this.translatedTerms.build_segment);
      this._hideCount();
      this._hideButton();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Update the bar when a user selects a new segment
     */
    selectNewSegment: function () {
      this._clearSegment();
      this.selectedSegmentState = SEGMENT_STATES.NEW;
      this.selectedSegmentType = SEGMENT_TYPES.REGULAR;
      this._updateCopy(this.translatedTerms.build_segment);
      this._hideCount();
      this._showButton();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Update the bar when a user selects a new advanced segment
     * @param {mojo} editor - a reference to the editor used for editing the new segment
     *
     */
    selectNewAdvancedSegment: function (editor) {
      this._clearSegment();
      this.selectedSegmentState = SEGMENT_STATES.NEW;
      this.selectedSegmentType = SEGMENT_TYPES.ADVANCED;
      this.selectedAdvancedEditor = editor;
      this._updateCopy(this.translatedTerms.build_advanced_segment);
      this._hideCount();
      this._showButton();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Clear all the attributes related to the selected segment
     *
     * @private
     */
    _clearSegment: function () {
      this.selectedSegmentState = null;
      this.selectedSegmentName = null;
      this.selectedSegmentType = null;
      this.selectedAdvancedEditor = null;
    },
    /**
     * Set the bar to a loading state. Used when a count is being calculated.
     *
     * @private
     */
    _showLoadingState: function () {
      var copy;
      switch (this.selectedSegmentState) {
        case SEGMENT_STATES.EXISTING:
          copy = this.translatedTerms.calculating_recipients_in_selected_segment + "<strong>" + this.selectedSegmentName + "</strong>.";
          break;
        case SEGMENT_STATES.NEW:
          copy = this.translatedTerms.calculating_recipients;
          break;
        default:
          throw new Error("Unhandled selectedSegmentState");
      }
      this._updateCopy(copy);
      this._showLoadingIndicator();
      this._hideCount();
      this._hideButton();
      updateFixedBar(this);
    },
    /**
     * Update the state of the bar when a count is queried and successfully returned.
     *
     * @param {number} count - the number that matches the selected segment
     * @private
     */
    _showLoadedState: function (count) {
      var copy;
      switch (this.selectedSegmentState) {
        case SEGMENT_STATES.EXISTING:
          copy = this.translatedTerms.recipients_in_the_segment + "<strong>" + this.selectedSegmentName + "</strong>.";
          this._hideButton();
          break;
        case SEGMENT_STATES.NEW:
          copy = this.translatedTerms.recipients_match;
          this._showButton();
          break;
        default:
          throw new Error("Unhandled selectedSegmentState");
      }
      this._updateCopy(copy);
      this._updateCount(count);
      this._showCount();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    /**
     * Update the state of the bar when a count is queried and returned with an error
     *
     * @private
     */
    _showErrorState: function (message) {
      this._updateCopy(message);
      this._hideCount();
      this._showButton();
      this._hideLoadingIndicator();
      updateFixedBar(this);
    },
    _showLoadingIndicator: function () {
      domClass.remove(this.loadingNode.domNode, "hide");
    },
    _hideLoadingIndicator: function () {
      domClass.add(this.loadingNode.domNode, "hide");
    },
    /**
     * Update the count of the bar and formats it.
     *
     * @param {number} count - the number of recipients
     * @private
     */
    _updateCount: function (count) {
      if (typeof count === "string") {
        count = count.replace(/[^0-9]/g, "");
      }
      var shouldAddLink = count > 0 && this.selectedSegmentType;
      if (shouldAddLink) {
        this.countNode.innerHTML = "<a href=\"#\" data-mc-action=\"preview\">" + number.format(count) + "</a>";
      } else {
        this.countNode.innerHTML = number.format(count);
      }
    },
    /**
     * Updates the copy of the bar
     * @param {string} copy - the text to insert into the DOM
     * @private
     */
    _updateCopy: function (copy) {
      this.copyNode.innerHTML = copy;
    },
    /**
     * Hide the count node
     * @private
     */
    _hideCount: function () {
      domClass.add(this.countNode, "hide");
    },
    /**
     * Show the count node
     * @private
     */
    _showCount: function () {
      domClass.remove(this.countNode, "hide");
    },
    /**
     * Helper method to hide the update button
     * @private
     */
    _hideButton: function () {
      domClass.add(this.updateBtn, "hide");
    },
    /**
     * Helper method to show the update button
     * @private
     */
    _showButton: function () {
      this.updateBtn.innerHTML = this.translatedTerms.update_recipient_count;
      domClass.remove(this.updateBtn, "hide");
    }
  });
});