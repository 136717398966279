/**
 * New Segment Button
 *
 * @module /mojo/widgets/contact-table/NewSegmentButton.js
 *
 * This file represents a variable button for the Contact Profile TopLevelSegmentBar. Based on the user type we'll
 * provide customers either a simple button (If they only have access to a single segmentation editor) or a dropdown
 * if they are eligible for both regular and advanced segmentation.
 * */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/lib/logger",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/new-segment-button.html", "mojo/utils/I18nTranslation"], function (declare, user, context, flags, logger, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, I18nTranslation) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    customProperties: {
      org: "sbseg",
      purpose: "prod",
      scope: "mc",
      initiative_name: "audience_organization",
      scope_area: "contacts",
      screen: "lists/members",
      object: "segment",
      object_detail: "create_segment",
      action: "started",
      ui_object: "button",
      ui_object_detail: "create_segment",
      ui_action: "clicked",
      ui_access_point: "table_header"
    },
    newSegmentLabel: "New Segment",
    advancedSegmentLabel: "Advanced Segment",
    advancedSegmentSubtext: "Powerful targeting for your contacts. Recommended for large audiences.",
    regularSegmentLabel: "Regular Segment",
    regularSegmentSubtext: "Simple, straight-forward targeting.",
    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          I18nTranslation.initialize();
          this.newSegmentLabel = I18nTranslation.translate("audience_management_new_segment_label");
          this.advancedSegmentLabel = I18nTranslation.translate("audience_management_advanced_segment_label");
          this.advancedSegmentSubtext = I18nTranslation.translate("audience_management_advanced_segment_subtext");
          this.regularSegmentLabel = I18nTranslation.translate("audience_management_regular_segment_label");
          this.regularSegmentSubtext = I18nTranslation.translate("audience_management_regular_segment_subtext");
        }
      }
    },
    /**
     * Customize button experience based on user info
     */
    postCreate: function () {
      var hasMultipleEditors = user.hasAdvancedSegmentation;

      // Give folks a dropdown instead of a dropdown
      if (hasMultipleEditors) {
        this.simpleButton.classList.add("hide");
        this.dropdown.domNode.classList.remove("hide");
      }
    },
    /**
     * Handle the expected interaction via the parent widget
     */
    onRegularSegmentClick: function () {
      // If widget is instiated properly, this will never throw
      throw new Error("onRegularSegmentClick is required to be implemented by parent widget");
    },
    /**
     * Handle the expected interaction via the parent widget
     */
    onAdvancedSegmentClick: function () {
      // If widget is instiated properly, this will never throw
      throw new Error("onAdvancedSegmentClick is required to be implemented by parent widget");
    },
    /**
     * Click handler for regular segments MenuItem or button.
     *
     * @private
     */
    _handleClickRegular: function () {
      var properties = this.customProperties;
      properties["segmentation_method"] = "regular";
      properties["has_elc_release_1_sms_cohort_ff"] = context.flagIsOn(flags.ELC_RELEASE_1_SMS_COHORT);
      logger.eventsPipeline.publish("segment:started", properties);
      this.onRegularSegmentClick();
    },
    /**
     * Click handler for advanced segments MenuItem.
     * @private
     */
    _handleClickAdvanced: function () {
      var properties = this.customProperties;
      properties["segmentation_method"] = "advanced";
      properties["has_elc_release_1_sms_cohort_ff"] = context.flagIsOn(flags.ELC_RELEASE_1_SMS_COHORT);
      logger.eventsPipeline.publish("segment:started", properties);
      this.onAdvancedSegmentClick();
    }
  });
});