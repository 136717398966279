define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/lib/flags", "mojo/context", "vendor/lodash-amd/reject", "vendor/lodash-amd/toNumber"], function (lang, debounce, Deferred, dom, domClass, domStyle, domGeom, on, topic, mojoUtils, flags, context, reject, toNumber) {
  window.mobileUtilsLoaded = false;
  var resizeListener = null;
  var orientationChangeListener = null;
  var mobileTopicHandlers = {
    keyboardDown: null,
    screenState: null,
    backgroundActivate: null,
    backgroundUpdate: null,
    nativeToolbarGlobal: null,
    nativeToolbarHide: null,
    mobileZoomFit: null
  };
  var utils = {
    previewMode: "desktop",
    stateQueue: [],
    nativeAppVersion: null,
    previewStyleOptions: [],
    genericMessageHandlers: {},
    versionDeferred: null,
    /**
     * nativeFeatureConfig will be a look-up for appVersion Numbers from native apps and compare with >= for displaying features.
     * this will be updatable as appVersions phase out with user updates.
     * list features as CONSTANT style names.
     */
    nativeFeatureConfig: {
      // BLOCK_ACTIVE is the blocks in a template have an active highlighted state
      "BLOCK_ACTIVE": {
        iOS: {
          version: 1
        },
        Android: {
          version: 1
        }
      },
      "CONTAINER_BACKGROUND_STYLE_CONTROLS": {
        iOS: {
          version: 1
        },
        Android: {
          version: 1
        }
      },
      // native toolbar for blocks, container styles, global
      "TOOLBAR": {
        iOS: {
          version: 1
        },
        Android: {
          version: 1
        }
      },
      // for native color picker where web app needs hex value
      "COLOR_PICKER": {
        iOS: {
          version: 2
        },
        Android: {
          version: 1
        }
      },
      // for native color picker where web app needs hex value
      "CONTENT_STUDIO_TOOLBAR": {
        iOS: {
          version: 3
        },
        Android: {
          version: 3
        }
      },
      // Android uses a native file browser for Content Studio
      "FILE_BROWSER": {
        iOS: false,
        Android: true
      }
    },
    /**
     * Event to fire when Preview has loaded
     * @param {Object} nativeEditorOptions will include addBlock, editBlock, preview, and background options
     */
    previewDidLoad: function (nativeEditorOptions) {
      var versionDeferred = utils.getNativeVersion();
      if (this.previewStyleOptions.length > 0) {
        nativeEditorOptions.backgroundOptions = this.previewStyleOptions;
      }
      // broadcast for Block Editor it needs to Save
      var nativeMessageHandler = this.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            window.webkit.messageHandlers.webContentDidLoad.postMessage(nativeEditorOptions);
            // call native global toolbar after webContentDidLoad for initial toolbar state
            utils.triggerNativeGlobalState();
          } else {
            window.webkit.messageHandlers.webContentDidLoad.postMessage({});
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            var stringifiedArgs = JSON.stringify(nativeEditorOptions);
            // if there is a version, running
            window.Android.webContentDidLoad(stringifiedArgs);
            // call native global toolbar after webContentDidLoad for initial toolbar state
            utils.triggerNativeGlobalState();
          } else {
            // if there is no version, running
            window.Android.webContentDidLoad();
          }
        });
      }
    },
    /**
     * getNativeVersion
     * set local version variable
     * @return {String} version as a string returned from native injected JS method
     * @return {Boolean} version as false if version doesn't exist, as in the case of the 3.11 mobile release
     */
    getNativeVersion: function () {
      // call native method for getVersion if exist, and resolve is globaNativeMessageHandler
      // else reject
      var nativeMessageHandler = this.getNativeObject();
      if (utils.versionDeferred === null) {
        var versionCallResponseObj = {
          id: "versionNumber"
        };
        utils.versionDeferred = new Deferred();
        utils.genericMessageHandlers.versionNumber = function (data) {
          // TODO: add toolbarHeight
          utils.versionDeferred.resolve(data);
        };
        if (nativeMessageHandler === "iOS") {
          if (window.webkit.messageHandlers.getAppVersion && window.webkit.messageHandlers.getAppVersion.postMessage) {
            window.webkit.messageHandlers.getAppVersion.postMessage(versionCallResponseObj);
          } else {
            utils.versionDeferred.resolve(false);
          }
        }
        if (nativeMessageHandler === "Android") {
          if (window.Android.getAppVersion) {
            var stringifiedArgs = JSON.stringify(versionCallResponseObj);
            window.Android.getAppVersion(stringifiedArgs);
          } else {
            utils.versionDeferred.resolve(false);
          }
        }
        if (!nativeMessageHandler) {
          utils.versionDeferred.resolve(false);
        }
      }
      return utils.versionDeferred.promise;
    },
    /**
     * resetBackActionQueue will zero out the queue of actions based on a global screen state or a new starting point for block or background,
     * or other screen state
     */
    resetStateQueue: function () {
      if (utils.stateQueue && utils.stateQueue.length > 0) {
        utils.stateQueue = [];
      }
    },
    /**
     * removeStateQueue will pop items out of state queue if the length is > 0
     * @param {Object} rejectObj is an object with only a state property for filtering
     * @property {String} state is the key to search for filtering the existing state objects
     */
    removeStateQueue: function (rejectObj) {
      // check with the state that is being removed
      if (utils.stateQueue && utils.stateQueue.length > 0) {
        // for specific instances where a state is not a screen, like in ckEditorFocus
        // remove only the rejected state
        if (rejectObj) {
          utils.stateQueue = reject(utils.stateQueue, rejectObj);
          return;
        }
        utils.stateQueue.pop();
      }
    },
    /**
     * setScreenState will handle hard states of block editors and background editors.
     * if there are items in the softState queue, trigging hard state changes will be handles with exception.
     * native will trigger actions for hard states and soft states, and those actions will dictate the queue.
     * @param {Object} obj will have a key for 'action` for callback action to add to the queue and pop the queue.
     * @property {String} state will be the type of item opened "modal", "dropdown", "other", mainly for logging what opened, this might be removed
     * @property {Function} backAction will be the callback function for closing, when the state is called from the queue, on back.
     *
     */
    setScreenState: function (obj) {
      // deal with state changes
      var curState;
      if (utils.stateQueue.length > 0) {
        curState = utils.stateQueue[utils.stateQueue.length - 1].state;
      }
      if (!curState || obj.state !== curState) {
        utils.stateQueue.push(obj);
      }
    },
    triggerNativeGlobalState: function (obj) {
      var globalToolbarState = {};
      if (utils.previewMode) {
        globalToolbarState.previewState = utils.previewMode;
      }
      // need this for every new call beyond mobile version 4.1
      var versionDeferred = utils.getNativeVersion();
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            window.webkit.messageHandlers.globalToolbar.postMessage(globalToolbarState);
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedObj = JSON.stringify(globalToolbarState);
            window.Android.globalToolbar(stringifiedObj);
          }
        });
      }
    },
    triggerNativeHideToolbar: function (obj) {
      // need this for every new call beyond mobile version 4.1
      var versionDeferred = utils.getNativeVersion();
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            window.webkit.messageHandlers.hideToolbar.postMessage({});
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedObj = JSON.stringify({});
            window.Android.hideToolbar(stringifiedObj);
          }
        });
      }
    },
    editorBackgroundActiveEvent: function (obj) {
      // deactivate block if active;
      // flag with don't send globalToolbar event
      this.nativeDoneBlock(true);
      // set state for back button

      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        window.webkit.messageHandlers.backgroundEdit.postMessage(obj);
      }
      if (nativeMessageHandler === "Android") {
        var stringifiedArgs = JSON.stringify(obj);
        window.Android.backgroundEdit(stringifiedArgs);
      }
    },
    editorBackgroundChangeEvent: function (obj) {
      // same as edit call, but spliting the functions now for any future differences
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        window.webkit.messageHandlers.backgroundEdit.postMessage(obj);
      }
      if (nativeMessageHandler === "Android") {
        var stringifiedArgs = JSON.stringify(obj);
        window.Android.backgroundEdit(stringifiedArgs);
      }
    },
    /**
     * campaignBlockSelected calls native object method to activate block option toolbar
     */
    campaignBlockSelected: function () {
      var versionDeferred = utils.getNativeVersion();

      // flag with don't send globalToolbar event
      utils.nativeDoneBackground(true);

      // setting state here, as opposed to Neapolitan is this active state for a block can be set from closing an editor, not just clicking a block in the preview
      // this is a centralized method to statify needs only used on mobile.
      window.top.app.nativeStateHandler.setScreenState({
        state: "blockActive",
        backAction: utils.nativeDoneBlock
      });

      // broadcast for Block Editor it needs to Save
      var nativeMessageHandler = this.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            window.webkit.messageHandlers.blockEdit.postMessage({});
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedArgs = JSON.stringify({});
            window.Android.blockEdit(stringifiedArgs);
          }
        });
      }
    },
    /**
     * Event to fire when Native Mobile user hits back or slides back to navigate away from WebView
     * This function will teardown popup screens: block editing, Content Studio, and any future screens
     */
    mobileUserTriggersBack: function () {
      // pop the queue, and wait for the state to change
      if (utils.stateQueue.length > 0) {
        var curStateObj = utils.stateQueue[utils.stateQueue.length - 1];
        if (curStateObj.backAction) {
          curStateObj.backAction();
        }
      } else {
        // tell mobile app to exit
        utils.mobileSaveAndExit();
      }
    },
    /**
    * Event to fire when Mobile user clicks the Save and Exit button
    */
    mobileSaveAndExit: function () {
      // broadcast for Block Editor it needs to Save
      mojoUtils.globalPublish("current/block/editor/save/for/mobile");
      // call event added my native environment
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        window.webkit.messageHandlers.mobileSaveAndExit.postMessage({});
      }
      if (nativeMessageHandler === "Android") {
        window.Android.mobileSaveAndExit();
      }
    },
    /**
    * Event to fire when Native Mobile user selects an image from the content manager
    * @param {Object} contentID the id of the piece of content selected
    */
    didSelectContent: function (contentID) {
      var versionDeferred = utils.getNativeVersion();
      // broadcast for Block Editor it needs to Save
      var nativeMessageHandler = this.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            window.webkit.messageHandlers.didSelectContent.postMessage({
              "contentID": contentID
            });
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedArgs = JSON.stringify({
              "contentID": contentID
            });
            window.Android.didSelectContent(stringifiedArgs);
          }
        });
      }
    },
    /**
    * Event to fire when Native Mobile user selects a new source in the content studio
    * @param {Object} source the source the user chose
    */
    didChangeContentSource: function (source, canFileInFolders) {
      var versionDeferred = utils.getNativeVersion();
      canFileInFolders = canFileInFolders === undefined ? true : canFileInFolders;
      // broadcast for Block Editor it needs to Save
      var nativeMessageHandler = this.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            window.webkit.messageHandlers.didChangeContentSource.postMessage({
              "source": source,
              "show_folder_option": canFileInFolders
            });
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedArgs = JSON.stringify({
              "source": source,
              "show_folder_option": canFileInFolders
            });
            window.Android.didChangeContentSource(stringifiedArgs);
          }
        });
      }
    },
    /**
    * Event to fire when Native Mobile user dismiss the content studio modals
    */
    didDismissContentSourceSheet: function () {
      var versionDeferred = utils.getNativeVersion();
      // broadcast for Block Editor it needs to Save
      var nativeMessageHandler = this.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            window.webkit.messageHandlers.didDismissContentSourceSheet.postMessage({});
          }
        });
      }
      if (nativeMessageHandler === "Android") {
        versionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            // if there is a version, running
            var stringifiedArgs = JSON.stringify({});
            window.Android.didDismissContentSourceSheet(stringifiedArgs);
          }
        });
      }
    },
    /**
    * Event to fire when Android user clicks file "Upload" from Content Studio to trigger native file upload
    */
    androidFileBrowse: function () {
      // call event added my native environment
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "Android") {
        window.Android.openFileBrowse();
      }
    },
    // TODO: remove versions for using native colorPicker - when specific app versions are fully adopted
    /**
     * nativeColorPicker is for Andriod appVersion 1, iOS appVersion 2
     * @param {String} colorHex is a HEX string value sent to the native picker
     */
    nativeColorPicker: function (colorHex) {
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        window.webkit.messageHandlers.openColorPicker.postMessage({
          color: colorHex
        });
      }
      if (nativeMessageHandler === "Android") {
        window.Android.openColorPicker(colorHex);
      }
    },
    /**
     * checkForNativeFeature will
     * @param {String} feature will be the a string to use for feature lookup
     * @param {String} versionNumber is the number (as a string) passed from native request for its version
     * @return {Boolean} featureConfig or featureConfig.version compared to current native version will be returned for a feature
     */
    checkForNativeFeature: function (feature, versionNumber) {
      var nativeOS = this.getNativeObject();
      var featureConfig = utils.nativeFeatureConfig[feature][nativeOS];
      if (featureConfig && featureConfig.version) {
        return toNumber(versionNumber) >= featureConfig.version;
      }
      return featureConfig;
    },
    /**
    * Event to fire when Mobile user needs to reauth
    */
    mobileReauthenticate: function () {
      // call event added my native environment
      var nativeMessageHandler = utils.getNativeObject();
      if (nativeMessageHandler === "iOS") {
        window.webkit.messageHandlers.mobileReauth.postMessage({});
      }
      if (nativeMessageHandler === "Android") {
        window.Android.mobileReauth();
      }
    },
    /**
    * @returns {Object} - Get the native message object to send messages to whichever native wrapper exists.
    */
    getNativeObject: function () {
      // if iOS, return object iOS injected
      if (window.webkit && window.webkit.messageHandlers) {
        return "iOS";
      }
      // if Android, return object Android injected
      if (window.Android) {
        return "Android";
      }
      // user needs to inject the mobile objects with a Browser snippet.
      return false;
    },
    /**
    * Represents functions for Native webView wrappers to communicate with mobile neapolitan.
    * @returns {Object} - Methods to call when native events occur: "nativeSlideAnimationComplete", "webViewIsDestroyed"
    */
    setupNativeListeners: function () {
      var nativeListeners = {
        "messageResponse": this.onNativeMessageResponse,
        "keyboardUp": this.onNativeKeyboardUp,
        "keyboardDown": this.onNativeKeyboardDown,
        "entryAnimationComplete": this.onEntryAnimationComplete,
        "saveAndExitWebApp": this.mobileUserTriggersBack,
        "backNavigate": this.mobileUserTriggersBack,
        "fileUploadCancel": this.nativeFileUploadCancel,
        "fileUploadSuccess": this.nativeFileUploadSuccess,
        "fileUploadError": this.nativeFileUploadError,
        "colorPickSuccess": this.nativeColorPickerSuccess,
        "colorPickClose": this.nativeColorPickerClose,
        "addBlock": this.nativeAddBlock,
        "editBlock": this.nativeEditBlock,
        "duplicateBlock": this.nativeCloneBlock,
        "deleteBlock": this.nativeDeleteBlock,
        "moveBlock": this.nativeSortBlock,
        "doneWithBlockOptions": this.nativeDoneBlock,
        "backgroundColor": this.nativeBackgroundColor,
        "backgroundImage": this.nativeBackgroundImage,
        "backgroundImageClose": this.nativeBackgroundImageClose,
        "backgroundImageFit": this.nativeBackgroundImageFit,
        "backgroundImageClear": this.nativeBackgroundImageClear,
        "doneWithBackgroundOptions": this.nativeDoneBackground,
        "setGlobalToolbarHeight": this.nativeSetGlobalToolbarHeight,
        "togglePreview": utils.changePreviewMode,
        "openContentSource": this.openContentSource,
        "openContentFolder": this.openContentFolder
      };
      return nativeListeners;
    },
    /**
    * Represents utils functions for global window methods to use with JavaScript
    * @returns {Object} - Methods to call when JS needs to know about native: "getNativeObject"
    */
    setupNativeUtils: function () {
      var nativeUtils = {
        "getNativeObject": this.getNativeObject,
        "getNativeVersion": this.getNativeVersion,
        "nativeColorPicker": this.nativeColorPicker,
        "checkForNativeFeature": this.checkForNativeFeature
      };
      return nativeUtils;
    },
    /**
    * Represents utils functions for global window methods to use with JavaScript
    * @returns {Object} - Methods to call when JS needs to know about native: "setScreenState", "removeStateQueue", "resetStateQueue"
    * native needs states to handle using a back button.  these global methods will be triggered from the app, and iFrames in the app.
    */
    setupNativeStateHandler: function () {
      var nativeStateHandler = {
        "removeStateQueue": utils.removeStateQueue,
        // for removing actions from queue, when the web changes state, not native back fn
        "setScreenState": utils.setScreenState,
        // for setting the current hard state (block editing, background editing)
        "resetStateQueue": utils.resetStateQueue // zero out the array of state
      };
      // set initial state
      return nativeStateHandler;
    },
    /**
    * When the Native WebView cancels file browse, trigger Content Studio onCancel event
    */
    nativeFileUploadCancel: function () {
      // obj will be a JSON object from the API image upload success response
      mojoUtils.globalPublish("content/manager/native/upload/cancel", {});
    },
    /**
    * When the Native WebView finishes file upload with success, trigger Content Studio onUpload event success
    * @param {Object} successObj - response obj from FileManager post request with ContentItem object
    */
    nativeFileUploadSuccess: function (successObj) {
      // obj will be a JSON object from the API image upload success response
      mojoUtils.globalPublish("content/manager/native/upload/success", successObj);
    },
    /**
    * When the Native WebView finishes file upload with error, trigger Content Studio onUpload event error
    * @param {Object} errorObj - error response obj from FileManager post request with error object
    */
    nativeFileUploadError: function (errorObj) {
      // obj will be a JSON object from the API image upload error response
      mojoUtils.globalPublish("content/manager/native/upload/error", errorObj);
    },
    nativeColorPickerSuccess: function (colorHex) {
      mojoUtils.globalPublish("native/mobile/colorpicker/update", colorHex);
    },
    nativeColorPickerClose: function () {
      mojoUtils.globalPublish("native/mobile/colorpicker/close");
    },
    /**
     * Native Wrapper adds a block to the Preview
     * @param {Object} blockObj properties to add block from native
     * @property {String} type will be the block type
     * @property {String} container will be the container name where to add the block, default value will be "body"
     */
    nativeAddBlock: function (blockObj) {
      //Check the DOM for an upper body section that is a special case for 1:2:1 template
      var sectionLabelContent = document.getElementsByClassName("sectionLabel")[2];
      var container = "";
      if (sectionLabelContent.innerHTML === "upper body ") {
        container = blockObj.container || "upper body";
      } else {
        container = blockObj.container || "body";
      }
      // call globalPublish to add block with details for type and container
      var block = blockObj.blockType;
      mojoUtils.globalPublish("native/mobile/add/" + container + "/newBlock", blockObj);
    },
    /**
     * Native Wrapper open editor for selected block
     */
    nativeEditBlock: function () {
      mojoUtils.globalPublish("native/mobile/block/action/edit");
    },
    /**
     * Native Wrapper clone selected block
     */
    nativeCloneBlock: function () {
      mojoUtils.globalPublish("native/mobile/block/action/clone");
    },
    /**
     * Native Wrapper delete selected block
    */
    nativeDeleteBlock: function () {
      mojoUtils.globalPublish("native/mobile/block/action/delete");
    },
    /**
     * Native Wrapper move selected block
     * @param {String} sortDirection: "up" or "down"
    */
    nativeSortBlock: function (sortDirection) {
      mojoUtils.globalPublish("native/mobile/block/action/sort", {
        moveDirection: sortDirection
      });
    },
    /**
     * Native Wrapper deselect active block
     */
    nativeDoneBlock: function (bool) {
      var publishObj = {};
      if (bool) {
        publishObj.shouldNotFireGlobalToolbar = bool;
      } else {
        publishObj.shouldNotFireGlobalToolbar = false;
      }
      mojoUtils.globalPublish("native/mobile/block/action/done", publishObj);
    },
    /**
     * Native Wrapper trigger background color
     */
    nativeBackgroundColor: function () {
      mojoUtils.globalPublish("native/mobile/background/action/color");
    },
    /**
     * nativeBackgroundImage triggers Content Studio to open
     */
    nativeBackgroundImage: function () {
      mojoUtils.globalPublish("native/mobile/background/action/image");
    },
    /**
     * Native Wrapper close background Content Studio
     */
    nativeBackgroundImageClose: function () {
      mojoUtils.globalPublish("native/mobile/background/action/image/close");
    },
    /**
     * nativeBackgroundImageFit will set the background image fill type
     * @param {String} fillOption will be "fit", "fill", "tile"
     */
    nativeBackgroundImageFit: function (fillOption) {
      mojoUtils.globalPublish("native/mobile/background/action/image/fill", {
        fillType: fillOption
      });
    },
    /**
     * nativeDoneBackground
     */
    nativeDoneBackground: function (bool) {
      mojoUtils.globalPublish("native/mobile/background/action/deactivate", {
        shouldNotFireGlobalToolbar: bool
      });
    },
    /**
     * nativeBackgroundImageClear will remove the image from the background
     */
    nativeBackgroundImageClear: function () {
      mojoUtils.globalPublish("native/mobile/background/action/image/clear");
    },
    /**
     * nativeBackgroundDeselect will deactivate the container accepting style changes
     */
    nativeBackgroundDeselect: function () {
      mojoUtils.globalPublish("native/mobile/background/action/deactivate");
    },
    /**
     * onNativeMessageResponse will be a generic message handler for call and response methods.
     * @param {Object} obj will have the handler fn key
     * @property {String} id will be the handler key
     * @property {Obj} data will be the data to pass to the callback function
     */
    onNativeMessageResponse: function (obj) {
      // check id and fire globalPublish with id
      if (obj && obj.id && utils.genericMessageHandlers.hasOwnProperty(obj.id)) {
        if (obj.data) {
          utils.genericMessageHandlers[obj.id](obj.data);
        } else {
          utils.genericMessageHandlers[obj.id]();
        }
      }
    },
    /**
     * onNativeKeyboardUp will handle any global event for when the keyboard is up
     * @param {object} obj will contain any data needed, specifically keyboard height
     * @property {Number} keyboardHeight
     */
    onNativeKeyboardUp: function (obj) {
      mojoUtils.globalPublish("native/mobile/keyboard/up", obj);
    },
    /**
     * onNativeKeyboardDown will handle any global event for when the keyboard is down
     * @param {object} obj will contain any data needed
     */
    onNativeKeyboardDown: function (obj) {
      mojoUtils.globalPublish("native/mobile/keyboard/down", obj);
    },
    /**
     * for native Keyboard down, treat it as a blur event for CKEditor
     */
    nativeKeyboardDownFired: function () {
      // for now keyboard down should only fire blur for current ckEditor
      // blur will remove the specific state object for back
      mojoUtils.globalPublish("current/blur/ckEditor/for/mobile");
    },
    /**
     * When the Native WebView finishes slide-up animation, trigger transition for blue outlines
     */
    onEntryAnimationComplete: function () {
      mojoUtils.globalPublish("native/preview/nativeAnimationComplete");
    },
    /**
     * toggle viewport zoom settings for focus and blur events in CKEditor
     * @argument {boolean} bool will determine whether to turn the zooming on or off
     */
    toggleViewportZooming: function (bool) {
      var viewport = document.querySelector("meta[name=viewport]");
      if (bool) {
        viewport.setAttribute("content", "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no");
      } else {
        viewport.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=5.0");
      }
    },
    /**
     * Whenever a resize happens, we need to adjust our preview to fit.
     */
    zoomOnResize: function () {
      if (!resizeListener) {
        resizeListener = on(window, "resize", debounce(utils.zoomPreviewToFit, 50));
      }
      if (!orientationChangeListener) {
        orientationChangeListener = on(window, "orientationchange", debounce(utils.zoomPreviewToFit, 500));
      }
    },
    changePreviewMode: function (value) {
      utils.previewMode = value;
      utils.zoomPreviewToFit({
        scrollToTop: true
      });
      utils.triggerNativeGlobalState();
    },
    setDefaultMobileType: function (value) {
      utils.previewMode = value;
    },
    /**
     * Zooms the preview content to fit the current viewport.
     * @param {Object} obj will have options for zoom preview
     * @property {Boolean} scrollToTop will fire scrollTo = 0
     */
    zoomPreviewToFit: function (obj) {
      utils.toggleViewportZooming();
      var frame = dom.byId("preview-template");

      // get the specific element that is scrolling, so the scrollTop will out the template at the top
      var colbodyHasList = document.body.querySelector("#previewcol > .colbody");
      if (colbodyHasList && obj && obj.hasOwnProperty("scrollToTop") && obj.scrollToTop) {
        colbodyHasList.scrollTop = 0;
      }
      if (frame) {
        var previewDoc = frame.contentWindow.document;
        var previewRoot = previewDoc.documentElement;
        // if previewMode is desktop - remove class "mobile-preview"
        if (utils.previewMode === "desktop") {
          // Clear out any existing transforms.
          domStyle.set(frame, {
            "transformOrigin": "0 0",
            "transform": "scale(1)"
          });
          domClass.remove(frame, "mobile-preview");
          var docWidth = domGeom.position(previewRoot).w;
          var viewportWidth = document.documentElement.clientWidth;
          if (viewportWidth < docWidth) {
            var horizontalPadding = 0;
            var zoomRate = viewportWidth / (docWidth + horizontalPadding);

            // add bottom-padding for the content before scaling the preview
            var previewBottomPaddingForActionPane = 60 / zoomRate;
            domStyle.set(previewRoot, {
              "padding-bottom": previewBottomPaddingForActionPane + "px"
            });
            domStyle.set(frame, {
              "transform": "scale(" + zoomRate.toFixed(3) + ") translate(0, 0)"
            });
            mojoUtils.globalPublish("container/controls/scale/preview", {
              scale: zoomRate.toFixed(3)
            });
          }
        } else {
          domStyle.set(frame, {
            "transform": "scale(1) translate(-50%, 0)"
          });
          domClass.add(frame, "mobile-preview");
          mojoUtils.globalPublish("container/controls/scale/preview", {
            scale: 1
          });
        }
      }
    },
    /* Content Studio native */
    /**
     * openContentSource displays the menu for the content studio source options
     */

    openContentSource: function () {
      mojoUtils.globalPublish("native/content/opensource");
    },
    /**
     * openContentFolder displays the menu for the content studio folder options
     */

    openContentFolder: function () {
      mojoUtils.globalPublish("native/content/openfolder");
    }
  };

  // TODO: rework Mobile Utils to create a singleton and setupMobileHandlers part of init()
  var setupMobileHandlers = function () {
    for (var handler in mobileTopicHandlers) {
      if (mobileTopicHandlers.hasOwnProperty(handler)) {
        if (mobileTopicHandlers[handler]) {
          mobileTopicHandlers[handler].remove();
        }
      }
    }
    mobileTopicHandlers.keyboardDown = topic.subscribe("native/mobile/keyboard/down", lang.hitch(this, utils.nativeKeyboardDownFired));
    mobileTopicHandlers.backgroundActivate = topic.subscribe("native/background/option/activate", lang.hitch(utils, utils.editorBackgroundActiveEvent));
    mobileTopicHandlers.backgroundUpdate = topic.subscribe("native/background/option/update", lang.hitch(utils, utils.editorBackgroundChangeEvent));
    mobileTopicHandlers.nativeToolbarGlobal = topic.subscribe("native/toolbar/state/global", lang.hitch(this, utils.triggerNativeGlobalState));
    mobileTopicHandlers.nativeToolbarHide = topic.subscribe("native/toolbar/state/hide", lang.hitch(this, utils.triggerNativeHideToolbar));
    mobileTopicHandlers.mobileZoomFit = topic.subscribe("mobile/zoom/to/fit", lang.hitch(this, utils.zoomPreviewToFit));
    window.mobileUtilsLoaded = true;
  };
  setupMobileHandlers();
  return utils;
});