define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // array.forEach
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domAttr.set
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// lang.hitch
"dojo/query!css2",
// query
"../registry" // registry.getEnclosingWidget
], function (array, declare, domAttr, lang, query, registry) {
  // module:
  //		dijit/form/_RadioButtonMixin

  return declare("dijit.form._RadioButtonMixin", null, {
    // summary:
    //		Mixin to provide widget functionality for an HTML radio button

    // type: [private] String
    //		type attribute on `<input>` node.
    //		Users should not change this value.
    type: "radio",
    _getRelatedWidgets: function () {
      // Private function needed to help iterate over all radio buttons in a group.
      var ary = [];
      query("input[type=radio]", this.focusNode.form || this.ownerDocument).forEach(
      // can't use name= since query doesn't support [] in the name
      lang.hitch(this, function (inputNode) {
        if (inputNode.name == this.name && inputNode.form == this.focusNode.form) {
          var widget = registry.getEnclosingWidget(inputNode);
          if (widget) {
            ary.push(widget);
          }
        }
      }));
      return ary;
    },
    _setCheckedAttr: function ( /*Boolean*/value) {
      // If I am being checked then have to deselect currently checked radio button
      this.inherited(arguments);
      if (!this._created) {
        return;
      }
      if (value) {
        array.forEach(this._getRelatedWidgets(), lang.hitch(this, function (widget) {
          if (widget != this && widget.checked) {
            widget.set('checked', false);
          }
        }));
      }
    },
    _getSubmitValue: function ( /*String*/value) {
      return value == null ? "on" : value;
    },
    _onClick: function ( /*Event*/e) {
      if (this.checked || this.disabled) {
        // nothing to do
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
      if (this.readOnly) {
        // ignored by some browsers so we have to resync the DOM elements with widget values
        e.stopPropagation();
        e.preventDefault();
        array.forEach(this._getRelatedWidgets(), lang.hitch(this, function (widget) {
          domAttr.set(this.focusNode || this.domNode, 'checked', widget.checked);
        }));
        return false;
      }
      return this.inherited(arguments);
    }
  });
});