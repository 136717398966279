define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/confirmInput.html", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation"], function (declare, _Widget, _Templated, on, query, domAttr, template, context, flags, I18nTranslation) {
  return declare([_Widget, _Templated], {
    templateString: template,
    confirm: "CONFIRM",
    helptip: "",
    error: "Please enter the text exactly as it is displayed to confirm.",
    active: true,
    type: "Type ",
    confirmText: " to confirm",
    constructor: function () {
      I18nTranslation.initialize().then(function (translatedValues) {
        this.type = translatedValues["delete_confirmation_type"];
        this.error = translatedValues["delete_confirmation_error"];
        this.confirmText = translatedValues["delete_confirmation_to_confirm"];
        this.confirm = translatedValues["confirm"];
      }.bind(this));
    },
    postCreate: function () {
      var parentForm = query(this.domNode).closest("form");
      var self = this;
      if (parentForm) {
        on(parentForm, "submit", function (e) {
          if (self.active && !self.validate()) {
            e.stopImmediatePropagation();
            e.preventDefault();
          }
        });
      }
    },
    validate: function () {
      if (domAttr.get(this.confirmInput, "value") !== this.confirm) {
        query(this.confirmError).style("display", "").html(this.error);
        query(this.confirmInput).addClass("invalid");
        return false;
      }
      query(this.confirmError).style("display", "none");
      query(this.confirmInput).removeClass("invalid");
      window.scrollTo(0, 0);
      return true;
    },
    clearInputField: function () {
      this.confirmInput.value = "";
    }
  });
});