define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/_send-now.html", "dijit/registry", "mojo/url", "mojo/events-pipeline-beacon/bulk-email-campaign-event"], function (declare, lang, dom, domStyle, Renderable, tpl, registry, mUrl, trackEvent) {
  return declare([Renderable], {
    templateString: tpl,
    campaignId: null,
    constructor: function (props) {
      this.state = {
        model: props.model
      };
      this.campaignId = props.model.campaignId;
    },
    showSendNowDialog: function () {
      var sendNowModal = registry.byId(this.sendNowModal.id);
      sendNowModal.show();
    },
    sendConfirmed: function () {
      registry.byId(this.sendNowModal.id).set("title", "Sending...");
      registry.byId(this.sendNowModal.id).set("closable", false);
      domStyle.set(this.confirmSendPre, "display", "none");
      domStyle.set(this.confirmSendAction, "display", "none");
      domStyle.set(this.confirmSendPost, "display", "block");
      window.ga("send", "event", "campaign_checklist", "click", "send: send_now");
      window.top.location.href = mUrl.toUrl("/campaigns/wizard/send", {
        "id": this.campaignId
      });
      trackEvent({
        screen: "finished-sent",
        object: "bulk_campaign",
        object_detail: "send_bulk_campaign",
        action: "engaged",
        ui_object: "button",
        ui_object_detail: "send",
        ui_action: "clicked",
        ui_access_point: "floating_action_button",
        campaign_id: this.campaignId
      });
    }
  });
});