define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, lang, base, FilterMenu, FilterMessage) {
  return declare([base], {
    onReady: function () {
      var filterSettings1 = {
        "target": "agent-all-profiles-list",
        "filters": [{
          "label": "First Name",
          "key": "fname",
          "type": "text"
        }, {
          "label": "Last Name",
          "key": "lname",
          "type": "text"
        }, {
          "label": "Email",
          "key": "email",
          "type": "text"
        }, {
          "label": "Agent ID",
          "key": "agent_id",
          "type": "text"
        }, {
          "label": "Show Inactive Users",
          "key": "inactive",
          "type": "checkbox"
        }]
      };
      var filter1 = new FilterMenu(filterSettings1);
      this.$el.list1Filters.appendChild(filter1.domNode);
      var filterMessage1 = new FilterMessage(filterSettings1);
      this.$el.list1FiltersMessage.appendChild(filterMessage1.domNode);
      var filterSettings2 = {
        "target": "agent-special-profiles-list",
        "filters": [{
          "label": "First Name",
          "key": "fname",
          "type": "text"
        }, {
          "label": "Last Name",
          "key": "lname",
          "type": "text"
        }, {
          "label": "Email",
          "key": "email",
          "type": "text"
        }, {
          "label": "Agent ID",
          "key": "agent_id",
          "type": "text"
        }, {
          "label": "Show Inactive Users",
          "key": "inactive",
          "type": "checkbox"
        }]
      };
      var filter2 = new FilterMenu(filterSettings2);
      this.$el.list2Filters.appendChild(filter2.domNode);
      var filterMessage2 = new FilterMessage(filterSettings2);
      this.$el.list2FiltersMessage.appendChild(filterMessage2.domNode);
      var filterSettings3 = {
        "target": "agent-group-list",
        "filters": [{
          "label": "First Name",
          "key": "fname",
          "type": "text"
        }, {
          "label": "Last Name",
          "key": "lname",
          "type": "text"
        }, {
          "label": "Email",
          "key": "email",
          "type": "text"
        }, {
          "label": "Agent ID",
          "key": "agent_id",
          "type": "text"
        }, {
          "label": "Show Inactive Users",
          "key": "inactive",
          "type": "checkbox"
        }]
      };
      var filter3 = new FilterMenu(filterSettings3);
      this.$el.list3Filters.appendChild(filter3.domNode);
      var filterMessage3 = new FilterMessage(filterSettings3);
      this.$el.list3FiltersMessage.appendChild(filterMessage3.domNode);
    },
    /**
     * Opens delete user Modal
     * @param {number} id - the id of the mcadmin user that is being deleted
     */
    deleteUser: function (id) {
      this.$el.userIdInput.value = id;
      this.$widget.deleteDialog.show();
    }
  });
});