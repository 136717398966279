define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/mouse!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dijit/_WidgetBase', 'dijit/_Templated', 'dojo/text!./templates/linkCheckerPane.html', 'dojo/text!./templates/linkCheckerPaneItem.html'], function (declare, array, lang, domConstruct, query, domClass, domAttr, on, mouse, _WidgetBase, _Templated, tpl, itemTpl) {
  return declare([_WidgetBase, _Templated], {
    templateString: tpl,
    linkTpl: itemTpl,
    linkStore: null,
    postCreate: function () {
      var self = this;
      this._renderLinks();
      this.own(on(this.linkList, 'li:click', function () {
        self.onSelect(domAttr.get(this, 'data-mc-link-id'));
      }), on(this.linkList, on.selector('li', mouse.enter), function () {
        self.onHover(domAttr.get(this, 'data-mc-link-id'));
      }));
      this.watch("linkStore", lang.hitch(this, 'refresh'));
    },
    refresh: function () {
      this._renderLinks();
    },
    onHover: function (linkId) {},
    onSelect: function (linkId) {},
    markAsViewed: function (linkId) {
      this._numChecked++;
      this.numChecked.innerHTML = this._numChecked;
      query('[data-mc-link-id="' + linkId + '"]', this.domNode).removeClass('unread');
    },
    _renderLinks: function () {
      domConstruct.empty(this.linkList);
      this._numChecked = 0;
      array.forEach(this.linkStore.order, lang.hitch(this, function (linkId) {
        var linkData = this.linkStore.links[linkId];
        var tplModel = lang.mixin({
          'linkId': linkId
        }, linkData);
        if (!tplModel.href) {
          tplModel.href = 'Link to Nowhere...';
        }
        var row = domConstruct.place(domConstruct.toDom(lang.replace(this.linkTpl, tplModel)), this.linkList);
        if (tplModel.iconSrc) {
          query('.media-image', row).append('<img src="' + tplModel.iconSrc + '"/>');
          domClass.add(query('.media-image', row)[0], 'icon-available');
        }
        if (!tplModel.checked) {
          domClass.add(row, 'unread');
        } else {
          this._numChecked++;
        }
      }));
      this.numLinks.innerHTML = this.linkStore.order.length;
      this.numChecked.innerHTML = this._numChecked;
    }
  });
});