define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// inherit from existing image editor
"./Text", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/textMobile.html", "mojo/neapolitan/widgets/ColorPicker"], function (declare, Text, _MobileBlockMixin, tmpl, ColorPicker) {
  return declare([Text, _MobileBlockMixin], {
    type: "text-mobile",
    templateString: tmpl,
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["sectionHeader", "columnSelector", "recoverContentWarning"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});