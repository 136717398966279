define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "mojo/url", "mojo/utils", "mojo/user", "mojo/lib/flags", "mojo/neapolitan/models/PageNavigation"], function (declare, lang, xhr, Deferred, Stateful, mUrl, utils, user, flags, PageNavigation) {
  var NAVIGATION_SAVE_URL = "/landing-pages/save-navigation-settings";

  // set default state of Navigation
  var Navigation = declare([Stateful], {
    enabled: false,
    links: [],
    options: [],
    styles: [],
    pageId: null
  });
  var api = {
    /**
     * Save config settings for Navigation
     * @param {Object} model - Properties to update
     * @param {boolean} fromEditor - whether this save request was from the navigation editor
     * @return {Promise} promise from save request
     */
    save: function (model, fromEditor) {
      var url = mUrl.toUrl(NAVIGATION_SAVE_URL, {
        id: model.pageId
      });
      var linkMap = model.get("linkMap");
      var formattedLinks = model.get("links").map(function (link) {
        if (link.type === "pageLink") {
          return {
            "text": link.text,
            "pageId": +link.pageId,
            "link": link.link,
            "type": link.type,
            "isActive": linkMap[link.pageId].isPublished
          };
        }
        return link;
      });
      var setting = {
        enabled: model.get("enabled"),
        links: formattedLinks,
        useBrand: false,
        styles: model.get("styles"),
        html: model.getMarkup(),
        fromEditor: fromEditor
      };
      window.ga("send", "event", "Website Builder", "Edit Website Design-Content-Design-Navigation", "Clicked / Save");
      return xhr.post(url, {
        "handleAs": "json",
        "data": {
          "setting": JSON.stringify(setting) // dojo doesn't serialize json object
        }
      });
    },
    /**
     * Instantiate a new Campaign from the given properties
     * @param {Object} navigationConfig - Properties to build the NavigationSettings instance from
     * @return {Campaign} A full instance
     */
    createFromProperties: function (navigationConfig) {
      return new PageNavigation(navigationConfig);
    }
  };
  return api;
});