/**
 * Contains all the functions that are required for Mobile Block Editors
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/fx/Toggler", "mojo/utils", "mojo/neapolitan/mobile/utils"], function (declare, lang, topic, domClass, domStyle, Toggler, utils, MobileUtils) {
  return declare(null, {
    mobileSaveHandler: null,
    toggleCKEditModeHandler: null,
    // empty arrays for each block to add their elements
    // to the list of domElements to hide on CKEditor focus
    domElementHiddenOnFocus: [],
    togglerElements: [],
    postCreate: function () {
      this.inherited(arguments);
      // run the toggler setup
      this._setupTogglerObjectsForCKFocus();
      MobileUtils.toggleViewportZooming(true);
      // trigger event for opening block
      // for mobile native app, set the screen state
      if (window.top && window.top.app && window.top.app.nativeStateHandler) {
        window.top.app.nativeStateHandler.setScreenState({
          state: "blockEditor",
          backAction: lang.hitch(this, this.saveAndHide)
        });
      }
    },
    destroy: function () {
      this.inherited(arguments);
      MobileUtils.toggleViewportZooming(false);
    },
    _setupTogglerObjectsForCKFocus: function () {
      var that = this;
      this.togglerElements = this.domElementHiddenOnFocus.map(lang.hitch(this, function (item) {
        return new Toggler({
          node: this[item]
        });
      }));
    },
    _setupNativeMobileHandlers: function () {
      // add global listeners for mobile
      this.mobileSaveHandler = topic.subscribe("current/block/editor/save/for/mobile", lang.hitch(this, this.saveAndHide));
      this.toggleCKEditModeHandler = topic.subscribe("current/block/editor/toggle/ckeditor/mode", lang.hitch(this, this._toggleCKEditMode));
    },
    _teardownNativeMobileHandlers: function () {
      this.mobileSaveHandler.remove();
      this.toggleCKEditModeHandler.remove();
      // trigger event for closing block
      // on teardown pop mobile state out of queue
      if (window.top && window.top.app && window.top.app.nativeStateHandler) {
        window.top.app.nativeStateHandler.removeStateQueue();
      }
      // fire event to tell native to show edit toolbar

      // campaign block selected is only for newer version
      // mobile utils only work from window.top
      var mobileVersionDeferred = window.top.app && window.top.app.nativeUtils && window.top.app.nativeUtils.getNativeVersion();
      // get based on mobile Version.
      var self = this;
      if (mobileVersionDeferred) {
        mobileVersionDeferred.then(function (versionReturned) {
          if (versionReturned && versionReturned.hasOwnProperty("versionNumber")) {
            MobileUtils.campaignBlockSelected();
          }
        });
      }
    },
    _toggleDomElementsOnFocus: function (bool) {
      var that = this;
      // show/hide each item for focus/blur with CKEditor
      this.togglerElements.forEach(lang.hitch(this, function (togglerEl) {
        if (bool) {
          this.domElementHiddenOnFocus.forEach(function (node) {
            domStyle.set(that[node], "display", "");
          });
          togglerEl.show();
        } else {
          togglerEl.hide();
          this.domElementHiddenOnFocus.forEach(function (node) {
            domStyle.set(that[node], "display", "none");
          });
        }
      }));
    },
    _toggleCKEditMode: function (obj) {
      if (this.ckeditorContainer) {
        if (obj.isEditMode) {
          // hide all elements in domElementHiddenOnFocus array
          this._toggleDomElementsOnFocus(false);
        } else {
          // show all elements in domElementHiddenOnFocus array
          this._toggleDomElementsOnFocus(true);
        }
      }
    }
  });
});