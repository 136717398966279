/**
 * Event Adapter Mixin
 * @module /mojo/widgets/ads/mobile-checklist/_EventAdapterMixin.js
 * @see /mojo/widgets/ads/mobile-checklist/_EventRouter.js
 *
 * Each individual widget needs to communicate to the event router with a common nomenclature.
 * Base methods are created with the adapter to be handled by child instances
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase"], function (declare, _WidgetBase) {
  return declare([_WidgetBase], {
    /**
     * Required method implemented by the child widgets to handle canceling of slat
     *
     * @throws if not implemented by child instance
     */
    __cancel: function () {
      throw new Error("cancel has not been implemented by the " + this.slatName + " widget");
    },
    /**
     * Required method to be implemented by child widgets that handle saving of slat
     *
     * @throws if not implemented by child instance
     */
    __save: function () {
      throw new Error("save has not been implemented by the " + this.slatName + " widget");
    },
    /**
     * Required method to be implemented by child widgets that handle the press of the back button.
     *
     * @throws if not implemented by child instance
     */
    __back: function () {
      throw new Error("back has not been implemented by the " + this.slatName + " widget");
    },
    /**
     * Public methods to notify the Event Router
     *
     */
    onLoaded: function () {},
    onDone: function (payload) {},
    onDoneWithError: function () {}
  });
});