define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domClass.add domClass.remove
"dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domStyle.get
"dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// keys.DOWN_ARROW keys.PAGE_DOWN keys.PAGE_UP keys.UP_ARROW
"../_WidgetBase", "../_TemplatedMixin", "./_ComboBoxMenuMixin", "./_ListMouseMixin"], function (declare, domClass, domStyle, keys, _WidgetBase, _TemplatedMixin, _ComboBoxMenuMixin, _ListMouseMixin) {
  // module:
  //		dijit/form/_ComboBoxMenu

  return declare("dijit.form._ComboBoxMenu", [_WidgetBase, _TemplatedMixin, _ListMouseMixin, _ComboBoxMenuMixin], {
    // summary:
    //		Focus-less menu for internal use in `dijit/form/ComboBox`
    //		Abstract methods that must be defined externally:
    //
    //		- onChange: item was explicitly chosen (mousedown somewhere on the menu and mouseup somewhere on the menu)
    //		- onPage: next(1) or previous(-1) button pressed
    // tags:
    //		private

    // TODO for 2.0 or earlier: stop putting stuff inside this.containerNode.   Switch to using this.domNode
    // or a different attach point.    See _TemplatedMixin::searchContainerNode.
    templateString: "<div class='dijitReset dijitMenu' data-dojo-attach-point='containerNode' style='overflow: auto; overflow-x: hidden;' role='listbox'>" + "<div class='dijitMenuItem dijitMenuPreviousButton' data-dojo-attach-point='previousButton' role='option'></div>" + "<div class='dijitMenuItem dijitMenuNextButton' data-dojo-attach-point='nextButton' role='option'></div>" + "</div>",
    baseClass: "dijitComboBoxMenu",
    postCreate: function () {
      this.inherited(arguments);
      if (!this.isLeftToRight()) {
        domClass.add(this.previousButton, "dijitMenuItemRtl");
        domClass.add(this.nextButton, "dijitMenuItemRtl");
      }
      this.containerNode.setAttribute("role", "listbox");
    },
    _createMenuItem: function () {
      // note: not using domConstruct.create() because need to specify document
      var item = this.ownerDocument.createElement("div");
      item.className = "dijitReset dijitMenuItem small-meta fwb cursor-default" + (this.isLeftToRight() ? "" : " dijitMenuItemRtl");
      item.setAttribute("role", "option");
      return item;
    },
    onHover: function ( /*DomNode*/node) {
      // summary:
      //		Add hover CSS
      domClass.add(node, "dijitMenuItemHover");
    },
    onUnhover: function ( /*DomNode*/node) {
      // summary:
      //		Remove hover CSS
      domClass.remove(node, "dijitMenuItemHover");
    },
    onSelect: function ( /*DomNode*/node) {
      // summary:
      //		Add selected CSS
      domClass.add(node, "dijitMenuItemSelected");
    },
    onDeselect: function ( /*DomNode*/node) {
      // summary:
      //		Remove selected CSS
      domClass.remove(node, "dijitMenuItemSelected");
    },
    _page: function ( /*Boolean*/up) {
      // summary:
      //		Handles page-up and page-down keypresses

      var scrollamount = 0;
      var oldscroll = this.domNode.scrollTop;
      var height = domStyle.get(this.domNode, "height");
      // if no item is highlighted, highlight the first option
      if (!this.getHighlightedOption()) {
        this.selectNextNode();
      }
      while (scrollamount < height) {
        var highlighted_option = this.getHighlightedOption();
        if (up) {
          // stop at option 1
          if (!highlighted_option.previousSibling || highlighted_option.previousSibling.style.display == "none") {
            break;
          }
          this.selectPreviousNode();
        } else {
          // stop at last option
          if (!highlighted_option.nextSibling || highlighted_option.nextSibling.style.display == "none") {
            break;
          }
          this.selectNextNode();
        }
        // going backwards
        var newscroll = this.domNode.scrollTop;
        scrollamount += (newscroll - oldscroll) * (up ? -1 : 1);
        oldscroll = newscroll;
      }
    },
    handleKey: function (evt) {
      // summary:
      //		Handle keystroke event forwarded from ComboBox, returning false if it's
      //		a keystroke I recognize and process, true otherwise.
      switch (evt.keyCode) {
        case keys.DOWN_ARROW:
          this.selectNextNode();
          return false;
        case keys.PAGE_DOWN:
          this._page(false);
          return false;
        case keys.UP_ARROW:
          this.selectPreviousNode();
          return false;
        case keys.PAGE_UP:
          this._page(true);
          return false;
        default:
          return true;
      }
    }
  });
});