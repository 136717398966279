define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate"], function (declare, lang, query, domConstruct, on, domStyle, domClass) {
  return declare([], {
    label: "Unknown",
    skipContainer: false,
    isOptional: false,
    resetLink: null,
    labelNode: null,
    inputContainerNode: null,
    subLabel: false,
    subLabelClasses: "c-paneLabel--small fwn padding--lv0",
    postCreate: function () {
      this.inherited(arguments);
      if (!this.skipContainer) {
        var containerClass = "style-" + this.label.toLowerCase().replace(" ", "");
        var container = "<div class='style-field selfclear " + containerClass + "'></div>";
        query(this.domNode).wrap(container);
        this.inputContainerNode = this.domNode.parentNode;
        var label = "<label>" + this.label + "</label>";
        this.labelNode = domConstruct.place(label, this.inputContainerNode, "first");
        if (this.subLabel) {
          domClass.add(this.labelNode, this.subLabelClasses);
        }
        if (this.isOptional) {
          var resetHtml = "<a href='#' class='small-meta margin--lv2'>Remove</a>";
          this.resetLink = domConstruct.place(resetHtml, query("label", this.inputContainerNode)[0]);
          this.own(on(this.resetLink, "click", lang.hitch(this, "_resetHandler")));
        }
      }
    },
    _resetHandler: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.onReset();
      this.toggleResetLink(false);
    },
    toggleResetLink: function (show) {
      if (this.resetLink) {
        domStyle.set(this.resetLink, "display", show ? "" : "none");
      }
    },
    onReset: function () {}
  });
});