define(["dijit", "dijit/form/Select", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "mojo/lib/flags", "mojo/user", "mojo/context", "mojo/neapolitan/Neapolitan", "mojo/views/_base", "mojo/widgets/KbSearch", "mojo/widgets/ActionableDialog"], function (dijit, Select, declare, dom, domAttr, has, on, query, xhr, mUrl, mPusher, flags, user, context, Neapolitan, base, KbSearch, ActionableDialog) {
  var acceptSiteTos = function (siteId, trackingData) {
    return xhr.post(mUrl.toUrl('/website/settings/save-tracking-settings?id=' + siteId), {
      "data": JSON.stringify(trackingData),
      "handleAs": "json"
    });
  };
  return declare([base], {
    onReady: function () {
      var pusherChannel = this.pusher_channel;
      var lastUpdated = this.$el.lastUpdatedNode;
      var $dropdownContainer = document.getElementById("pageDropdownContainer");
      if ($dropdownContainer !== null) {
        var pageOptions = window.app.websitePageNavOptions;
        var selectOptions = pageOptions.map(function (option) {
          return {
            label: option.pageName,
            value: option.pageId
          };
        });
        this.pageDropdown = new Select({
          name: "pageSelect2",
          options: selectOptions,
          "class": "margin-left--lv2 !margin-bottom--lv0"
        });
        this.pageDropdown.placeAt($dropdownContainer).startup();
        this.pageDropdown.set("value", window.app.campaignId);

        // Page dropdown tracking
        on(this.pageDropdown, "click", function () {
          window.ga("send", "event", "draft_editor", "click", "page_dropdown");
        });
        on(this.pageDropdown, "change", function (pageId) {
          if (+window.app.campaignId !== pageId) {
            window.ga("send", "event", "draft_editor", "click", "page_dropdown:" + this.domNode.innerText);
            window.location = "/landing-pages/design?id=" + pageId;
          }
        });
      }
      if (pusherChannel) {
        mPusher.load().then(function (pusher) {
          pusher.subscribe(pusherChannel).bind("page-update", function (data) {
            if (lastUpdated) {
              lastUpdated.innerText = data.updatedAt;
            }
          });
        });
      }
      window.app.setDesignStyles(dom.byId("design-editor"));

      // This is to get around the fact that you can't scroll iframes in IOS
      if (has("ios")) {
        query(".iframe-wrapper").addClass("ios-iframe-scroll");
      }
      query("[data-kb-help]").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        KbSearch.main().show();
      });
      if (typeof window.usabilla_live === "function") {
        // hide default feedback form
        window.usabilla_live("hide");
        // trigger form when CTA is clicked
        query("[data-give-feedback]").style("display", "");
        query("[data-give-feedback]").on("click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          window.usabilla_live("click");
        });
      }
      query("[data-save-in-place]").forEach(function (el) {
        on(el, "click", function (e) {
          e.preventDefault();
          e.target.innerText = "Saving...";
          window.app.saveAll().then(function () {
            e.target.innerText = "Save";
          });
        });
      });
      query("[data-save-on-click]").forEach(function (el) {
        on(el, "click", function (e) {
          e.preventDefault();
          var href = el.href;

          // ensure publishing prevention only happens if this is a website
          // and the user has yet to accept terms and conditions
          if (el.dataset.isPublishButton && !window.app.trackingSettings.didAcceptTerms) {
            return;
          }
          // change text on button
          if (window.app.isWebsitePage) {
            e.target.innerText = "Saving...";
          } else if (window.app.isPagePublished && user.hasRole('manager')) {
            e.target.innerText = "Publishing...";
          } else {
            e.target.innerText = "Saving...";
          }
          window.ga("send", "event", "draft_editor", "click", "save & close");
          window.app.saveAll().then(function () {
            window.location = href;
          });
        });
      });

      // Site Preview Click Tracking
      var previewLink = query("[data-event-category='Website Neapolitan - Preview']")[0];
      if (previewLink) {
        on(previewLink, "click", function () {
          window.ga("send", "event", "draft_editor", "click", "preview");
        });
      }

      // progress bar link tracking
      var progressBarLinks = query(".c-wizardLink--bottom");
      progressBarLinks.forEach(function (el) {
        on(el, "click", function (e) {
          window.ga("send", "event", "draft_editor", "click", "preview" + this.innerText);
        }.bind(el));
      });

      // "Back" button tracking
      var backButton = query("[title='Go back to Style']")[0];
      if (backButton) {
        on(backButton, "click", function () {
          window.ga("send", "event", "draft_editor", "click", "back");
        });
      }
      var publishButton = query("[data-is-publish-button]")[0];
      if (publishButton) {
        on(publishButton, "click", function (e) {
          // only get on top of the publishing click
          // if the page is on a website
          if (window.app.websiteId && !window.app.trackingSettings.didAcceptTerms) {
            e.preventDefault();
            var trackingSettingsClone = JSON.parse(JSON.stringify(window.app.trackingSettings));
            var siteId = window.app.websiteId;
            trackingSettingsClone.didAcceptTerms = true;
            new ActionableDialog({
              title: "Review Website Terms",
              contentMessage: "Almost There!\b " + "To continue and publish your website, please accept these <a href=\"https://mailchimp.com/legal/additional-terms/\" target=\"_blank\" rel=\"noopener noreferrer\">additional terms and conditions</a>.",
              primaryActionLabel: "Accept",
              onClickPrimaryAction: function (evt, dialog) {
                var primaryActionButton = document.getElementById("primaryActionButton_label");
                primaryActionButton.innerText = "Saving...";
                acceptSiteTos(siteId, trackingSettingsClone).then(function () {
                  // Set the inner text as such because if they're here, they've yet to publish because they
                  // haven't accepted terms.
                  publishButton.innerText = "Publishing...";
                  dialog.hide();
                  primaryActionButton.innerText = "Accept";
                  window.onbeforeunload = null;
                  window.location = publishButton.getAttribute("href");
                });
              },
              secondaryActionLabel: "Cancel",
              onClickSecondaryAction: function (evt, dialog) {
                dialog.hide();
              }
            }).show();
          }
        });
      }
      var freddieWizardLogo = query('[data-mc-el="wizardFreddieLogo"]')[0];
      on(freddieWizardLogo, "click", function (e) {
        if (!window.app.isPagePublished || !window.app.didMakeChanges) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        window.ga("send", "event", "draft_editor", "click", "mailchimp logo");
        new ActionableDialog({
          title: "Are you sure?",
          contentMessage: "You’ve made changes that have not been published. " + "If you exit now, we’ll save your work so you can come back later, " + "but visitors to your landing page will not see these changes.",
          primaryActionLabel: "Exit without publishing",
          onClickPrimaryAction: function (evt, dialog) {
            window.onbeforeunload = null;
            window.location = freddieWizardLogo.getAttribute("href");
          },
          secondaryActionLabel: "Keep editing",
          onClickSecondaryAction: function (evt, dialog) {
            dialog.hide();
          }
        }).show();
      });
    }
  });
});