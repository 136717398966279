define(["dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/hccss", "dojo-proxy-loader?name=dojo/domReady!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (domClass, has, domReady, win) {
  // module:
  //		dijit/hccss

  /*=====
  return function(){
  	// summary:
  	//		Test if computer is in high contrast mode, and sets `dijit_a11y` flag on `<body>` if it is.
  	//		Deprecated, use ``dojo/hccss`` instead.
  };
  =====*/

  domReady(function () {
    if (has("highcontrast")) {
      domClass.add(win.body(), "dijit_a11y");
    }
  });
  return has;
});