define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, dom, query, on, domAttr, domStyle, domClass) {
  return declare([base], {
    onReady: function () {
      this.attachEventHandlers();
    },
    validateRequiredFields: function () {
      // Verify required fields have been selected
      if (query("[name=whyRemovePro]:checked").length > 0) {
        domClass.remove(this.$el.removeButton, "disabled");
        domClass.add(this.$el.requiredStatus, "hide");
      }
    },
    attachEventHandlers: function () {
      var self = this;
      on(query("[name=whyRemovePro]"), 'change', function () {
        if (this.value == "Other") {
          domClass.remove(self.$el.otherReasonInput, "hide");
        } else {
          domClass.add(self.$el.otherReasonInput, "hide");
        }
        if (this.value == "Too hard to figure out") {
          domClass.remove(self.$el.featuresTroubleInput, "hide");
        } else {
          domClass.add(self.$el.featuresTroubleInput, "hide");
        }
        if (this.value == "Doesn't meet my needs") {
          domClass.remove(self.$el.featuresShortInput, "hide");
        } else {
          domClass.add(self.$el.featuresShortInput, "hide");
        }
        self.validateRequiredFields();
      });
    }
  });
});