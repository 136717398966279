define(["dojo/request", "dojo/hash", "mojo/url", "mojo/utils/date"], function (request, hash, mUrl, dateUtils) {
  return {
    baseUrl: "/lists/members/",
    getProfileOverviewUrl: "get-subscriber-profile",
    getVIPUrl: "get-VIP-status",
    addVIPUrl: "add-gmonkey",
    removeVIPUrl: "rem-gmonkey",
    getStarRatingUrl: "get-star-rating",
    unsubscribeMemberUrl: "unsubscribe",
    subscribeMemberUrl: "subscribe",
    resendMemberUrl: "resubscribe",
    unarchiveMemberUrl: "unarchive",
    archiveMemberUrl: "archive",
    deleteMemberUrl: "delete",
    saveNoteUrl: "add-note",
    deleteNoteUrl: "delete-note",
    getBasicInfoUrl: "contact-basic-info",
    getCustomInfoUrl: "contact-merge-info",
    getAllInfoUrl: "contact-all-merge-info",
    getGroupsInfoUrl: "get-groups",
    getGdprInfoUrl: "gdpr-info",
    getMetaInfoUrl: "contact-meta-info",
    saveLanguageUrl: "set-language",
    saveFormatUrl: "edit-optin-post",
    getActivityFeedUrl: "feed",
    overrideEmailClientUrl: "override-email-client",
    overridePredictedDemographicsUrl: "override-predicted-demographics",
    overrideSocialProfileUrl: "override-social-profile",
    overrideLocationUrl: "override-location",
    pendingAppointmentsUrl: "pending-appointments",
    /**
     * Construct the request URL from the baseUrl and passed parameters
     * @param {string} url Url to append
     * @param {string} memberID List member ID
     * @param {object} queryParams Query params to append
     * @returns {string} mUrl Constructed Url
     */
    constructUrl: function (url, memberID, queryParams) {
      return mUrl.toUrl(this.baseUrl + url + this.constructQueryString(memberID, queryParams));
    },
    /**
     * Create a string of query parameters for the request
     * @param {string} memberID List member ID
     * @param {object} queryParams Params to append
     * @returns {string} queryString Constructed query string
     */
    constructQueryString: function (memberID, queryParams) {
      var queryString = "";
      if (memberID) {
        queryString += "?id=" + memberID;
      }
      if (queryParams) {
        //loop through each param to be appended
        for (var key in queryParams) {
          if (queryParams.hasOwnProperty(key)) {
            queryString += "&" + key + "=" + queryParams[key];
          }
        }
      }
      return queryString;
    },
    /**
     * Perform the GET request as indicated in the parameters
     * @param {string} requestUrl Url to hit
     * @param {function} callback Callback function
     * @param {function} errorCallback Error case callback function
     * @param {string} handleAs specify request type (text, json, etc.)
     * @returns {string} request Request response
     */
    getRequest: function (requestUrl, callback, errorCallback, handleAs) {
      return request.get(requestUrl, {
        handleAs: handleAs ? handleAs : "text"
      }).then(function (response) {
        if (callback) {
          callback(response);
        }
      }, function (error) {
        if (errorCallback) {
          errorCallback(error);
        }
      });
    },
    /**
     * Perform the POST request as indicated in the parameters
     * @param {string} requestUrl Url to hit
     * @param {object} postObject JSON object to post to the server
     * @param {function} callback Callback function
     * @param {function} errorCallback Error case callback function
     * @returns {string} request Request response
     */
    postRequest: function (requestUrl, postObject, callback, errorCallback) {
      return request.post(requestUrl, {
        handleAs: "json",
        data: postObject
      }).then(function (response) {
        if (callback) {
          callback(response);
        }
      }, function (error) {
        if (errorCallback) {
          errorCallback(error);
        }
      });
    },
    /**
     * Get the profile overview data of the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getProfileOverview: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getProfileOverviewUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the current VIP status of the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getVIPStatus: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getVIPUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Add the list member to the list VIP
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    addVIP: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.addVIPUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Remove the list member from the list VIP
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    removeVIP: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.removeVIPUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Get the star count for the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getStarRating: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getStarRatingUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Unsubscribe the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    unsubscribeMember: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.unsubscribeMemberUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Subscribe the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    subscribeMember: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.subscribeMemberUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Resend subscription confirmation to the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    resendMember: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.resendMemberUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Unarchive the list member
     * @param {string} listID for the member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    unarchiveMember: function (listID, memberID, callback) {
      var requestUrl = this.constructUrl(this.unarchiveMemberUrl, memberID, {
        "list_id": listID,
        "list_member_ids": JSON.stringify([memberID])
      });
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Archive the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    archiveMember: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.archiveMemberUrl, memberID);
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Hard delete the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    deleteMember: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.deleteMemberUrl, memberID, {
        "redirect": false
      });
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Get 
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    pendingAppointments: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.pendingAppointmentsUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Create a note about the list member
     * @param {string} memberID List member ID
     * @param {string} note Note text
     * @param {function} callback Callback function
     */
    addNote: function (memberID, note, callback) {
      // Make sure to encode the note text
      var encodedNoteText = encodeURIComponent(note);
      var requestUrl = this.constructUrl(this.saveNoteUrl, memberID, {
        "note": encodedNoteText
      });
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Update note about the list member
     * @param {string} memberID List member ID
     * @param {string} note Note text
     * @param {string} noteID ID of existing note
     * @param {function} callback Callback function
     */
    editNote: function (memberID, note, noteID, callback) {
      // Make sure to encode the note text
      var encodedNoteText = encodeURIComponent(note);
      var requestUrl = this.constructUrl(this.saveNoteUrl, memberID, {
        "note": encodedNoteText,
        "note-id": noteID
      });
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Delete the specified user note about the list member
     * @param {string} memberID List member ID
     * @param {string} noteID ID of existing note
     * @param {function} callback Callback function
     */
    deleteNote: function (memberID, noteID, callback) {
      var requestUrl = this.constructUrl(this.deleteNoteUrl, memberID, {
        "note-id": noteID
      });
      this.getRequest(requestUrl, callback, null, "text");
    },
    /**
     * Get the basic info block data about the list member
     * @param {string} memberID List member ID
     * @param {string} type Type of list to fetch
     * @param {function} callback Callback function
     */
    getInfoList: function (memberID, type, callback) {
      switch (type) {
        case "basic":
          this.getBasicInfo(memberID, callback);
          break;
        case "custom":
          this.getCustomInfo(memberID, callback);
          break;
        case "all":
          this.getAllInfo(memberID, callback);
          break;
        case "groups":
          this.getGroupsInfo(memberID, callback);
          break;
        case "gdpr":
          this.getGdprInfo(memberID, callback);
          break;
        case "meta":
          this.getMetaInfo(memberID, callback);
          break;
        default:
          break;
      }
    },
    /**
     * Get the basic info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getBasicInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getBasicInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getAllInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getAllInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the custom info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getCustomInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getCustomInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the groups info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getGroupsInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getGroupsInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the GDPR info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getGdprInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getGdprInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Get the meta/other info block data about the list member
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getMetaInfo: function (memberID, callback) {
      var requestUrl = this.constructUrl(this.getMetaInfoUrl, memberID);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Submit updates to meta/other info block data about the list member
     * @param {string} memberID List member ID
     * @param {string} language Selected language
     * @param {string} emailFormat Selected email format
     */
    submitMetaInfo: function (memberID, language, emailFormat) {
      var self = this;
      var languageRequestUrl = this.constructUrl(this.saveLanguageUrl, memberID, {
        "lang": language
      });
      this.getRequest(languageRequestUrl, function () {
        var formatRequestUrl = self.constructUrl(self.saveFormatUrl, memberID, {
          "email_type": emailFormat
        });
        self.getRequest(formatRequestUrl, function () {
          hash("");
          location.reload();
        }, null, "text");
      }, null, "text");
    },
    /**
     * Overrides the list member's locations
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    overrideLocation: function (memberID, callback) {
      var overrideLocationUrl = this.constructUrl(this.overrideLocationUrl, memberID, {});
      this.getRequest(overrideLocationUrl, callback, null, "text");
    },
    /**
     * Overrides the list member favorite email client
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    overrideEmailClient: function (memberID, callback) {
      var overrideEmailClientUrl = this.constructUrl(this.overrideEmailClientUrl, memberID, {});
      this.getRequest(overrideEmailClientUrl, callback, null, "text");
    },
    /**
     * Overrides the list member's predicted demographics (age, gender, etc)
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    overridePredictedDemographics: function (memberID, callback) {
      var overridePredictedDemographicsUrl = this.constructUrl(this.overridePredictedDemographicsUrl, memberID, {});
      this.getRequest(overridePredictedDemographicsUrl, callback, null, "text");
    },
    /**
     * Overrides the list member's social profile (Twitter account, gender, etc)
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    overrideSocialProfile: function (memberID, callback) {
      var overrideSocialProfileUrl = this.constructUrl(this.overrideSocialProfileUrl, memberID, {});
      this.getRequest(overrideSocialProfileUrl, callback, null, "text");
    },
    /**
     * Get the consolidated activity feed about the list member
     * @param {string} memberID List member ID
     * @param {string} typeFilter activity type to filter the feed down to
     * @param {int} slice Pagination index
     * @param {function} callback Callback function
     */
    getActivityFeed: function (memberID, typeFilter, slice, slice_size, callback) {
      var queryParams = {
        "slice_size": slice_size,
        "slice_start": slice
      };
      if (typeFilter && typeFilter !== "all") {
        queryParams["types"] = typeFilter;
      }
      var requestUrl = this.constructUrl(this.getActivityFeedUrl, memberID, queryParams);
      this.getRequest(requestUrl, callback, null, "json");
    },
    /**
     * Organize the raw activity feed into an array of dates
     * with the associated array of feed items for the day
     * @param {array} rawFeedArray Feed returned from feedAction
     * @returns {array} structuredFeed Restructured feed organized by date
     */
    structureActivityFeed: function (rawFeedArray) {
      var structuredFeed = [];
      var currentDate = "";
      var currentDateFeed = [];
      rawFeedArray.forEach(function (element) {
        if (element.creation_time.date && element.creation_time.date.length > 0) {
          //Get display date representation
          var dateObj = new Date(element.formatted_user_time);
          var dateStr = dateUtils.getDateOnlyString(dateObj, false);

          //If the date doesn't match, we've moved on to another date in the feed
          if (dateStr !== currentDate) {
            currentDate = dateStr;
            currentDateFeed = [];
            var dayObject = {
              "date": currentDate,
              "feed": currentDateFeed
            };
            structuredFeed.push(dayObject);
          }
          // add timezone to activity feed time stamp
          element.displayTime = dateUtils.getTimeString(dateObj, true);
          currentDateFeed.push(element);
        }
      });
      return structuredFeed;
    }
  };
});