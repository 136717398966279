define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "../email-renderers/_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "mojo/lib/flags", "mojo/user", "dojo/text!../editors/blocks/templates/PaymentEditor/outputWeb.html", "dojo/text!../editors/blocks/templates/PaymentEditor/previewDemo.html", "../editors/blocks/templates/PaymentEditor/previewDemoProductData"], function (declare, lang, htmlEntities, _Renderer, ButtonRenderer, Link, flags, user, tpl, demoTpl, demoData) {
  return declare([_Renderer], {
    buttonRenderer: null,
    outputTplStr: window.top.Previewer ? demoTpl : tpl,
    constructor: function () {
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * Use Button block renderer to render the button itself.
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @returns {string} html content of how a button should look
     * @private
     */
    _buttonMarkup: function (meta, product) {
      var buttonMeta = {
        buttonText: product.buttonText,
        buttonWidth: meta.buttonWidth,
        align: meta.buttonAlign,
        buttonId: "payment_button_" + product.id,
        link: {
          "href": "#0",
          "attrs": {
            "target": "_self"
          }
        }
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    /**
     * Return the markup of the content of each product. This includes name, desc, price and button
     *
     * @param {Object} meta - ref to the output model's meta.
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @return {Array} Data needed to render the rows in the template
     *
     * @private
     */
    _contentMarkup: function (meta, product) {
      var rows = [];
      if (product.title) {
        rows.push({
          html: product.title + "<br />",
          className: "mcnProductTitle",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.price) {
        var mergeTag = "*|PAYMENT_PRICE:[$productID=" + product.id + "]|*" + product.price + "*|END:PAYMENT_PRICE|*";
        rows.push({
          html: mergeTag + "<br />",
          className: "mcnProductPrice",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.descriptionEnabled && product.description) {
        rows.push({
          html: product.description + "<br/>",
          className: "mcnProductDescription mcnTextContent",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px; word-break: break-word;"
        });
      }
      rows.push({
        html: this._buttonMarkup(meta, product),
        className: "mcnProductButton",
        styles: "padding-top:18px"
      });
      return rows;
    },
    _getContainerStyles: function (meta) {
      return "flex: 1 1 50%;padding-top: 9px; padding-bottom: 9px; display: flex; flex-direction: column;";
    },
    _getGalleryImageStyles: function (meta) {
      var numberOfImages = meta.galleryImages ? meta.galleryImages.length : 0;
      var DIMENSIONS = {
        2: {
          height: 120,
          width: 60
        },
        3: {
          height: 105,
          width: 75
        },
        4: {
          height: 90,
          width: 100
        },
        5: {
          height: 80,
          width: 100
        }
      };
      var widthAmount = DIMENSIONS[numberOfImages] ? DIMENSIONS[numberOfImages].width : 100;
      var heightAmount = DIMENSIONS[numberOfImages] ? DIMENSIONS[numberOfImages].height : 80;
      var minWidthAmount = DIMENSIONS[numberOfImages] ? DIMENSIONS[numberOfImages].height : 80;
      var maxWidthAmount = DIMENSIONS[numberOfImages] ? DIMENSIONS[numberOfImages].height + 20 : 100;
      return {
        show: meta.galleryImagesEnabled && numberOfImages > 1 ? "flex" : "none",
        outerContainer: "padding:18px 0px;",
        innerContainer: "padding-bottom:9px; display:flex; margin-bottom:18px; align-items:center; justify-items:center; min-width:320px; max-width:480px;" + "width:" + widthAmount + "%",
        image: "background-position: 50% 50%; background-size:cover; cursor:pointer; flex:1 0 0; margin-right:9px; width:auto; min-width:" + minWidthAmount + "px; " + "max-width:" + maxWidthAmount + "px; " + "height:" + heightAmount + "px;"
      };
    },
    getOutputModel: function (meta) {
      var self = this;
      var BUTTON_POSITION = {
        right: "flex-end",
        left: "flex-start",
        center: "center"
      };
      var demoProduct = demoData;
      var productsClone = lang.clone(meta.products);
      if (window.top.Previewer) {
        productsClone.push(demoProduct);
      }
      var productsData = productsClone.map(function (product) {
        return {
          product: product,
          body: self._contentMarkup(meta, product),
          image: product.image
        };
      });

      // vars that will drive the column orientation
      var hasImage = productsData && productsData[0] && productsData[0].image && productsData[0].image.src;
      // product object (id (for merge tag), title, price, taxes?, desc)
      // productImages ({main: {path, alt, title}, alt: [{path, alt, title}]})
      // blockSettings: {}
      var demoButtonMarkup = window.top.Previewer ? productsData[0].body[2].html : "";
      var demoStyles = window.top.Previewer ? {
        title: productsData[0].body[0].styles,
        price: productsData[0].body[1].styles,
        button: productsData[0].body[2].styles
      } : "";
      return {
        productsData: window.top.Previewer ? productsData[0] : productsData,
        flexDirection: meta.layout === "vertical" ? "row" : "column",
        containerStyles: this._getContainerStyles(meta),
        galleryImages: meta.galleryImagesEnabled && meta.galleryImages.length > 1 ? meta.galleryImages : [],
        galleryImageStyles: this._getGalleryImageStyles(meta),
        demoButtonMarkup: demoButtonMarkup,
        demoStyles: demoStyles
      };
    }
  });
});