/**
 * Keeps the user's session alive while they have the app opened
 */
define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "mojo/context", "mojo/SinglePageApplicationUtils", "mojo/lib/flags"], function (request, mUrl, utils, context, singlePageAppUtils, flags) {
  // If we're on the login domain, don't execute any of this.
  if (window.location.origin.indexOf("login") > -1) {
    return {};
  }

  // If we're "inside" the single-page app, the top frame will hande
  // polling for reauth, dispatching tokens, etc.
  if (singlePageAppUtils.isInFallbackIframe()) {
    return {};
  }
  if (context.flagIsOn(flags.CSRF_KEEP_ALIVE_POLLER_DELAY)) {
    // Setting the delay time to 10 minutes
    var delayMs = 1000 * 60 * 10;
    setInterval(function checkSession() {
      if (window.document.location.href.includes("/login/reauthenticate")) {
        return false;
      }
      request.get("/account/callback/ping").then(function (json) {
        // Despite a non-deterministic response shape, there's
        // no need to try/catch here–if this callsite fails, we'll
        // just fall to the `otherwise` block below.
        json = JSON.parse(json);
        if (json.hasOwnProperty("status") && json.status === "OK") {
          // Fire off an event on the current window. There's an
          // upstream "message" event handler that will globally
          // patch XMLHttpRequest's open method to inline the token.
          window.postMessage({
            channel: "reauth/complete",
            payload: json.csrf
          }, window.location.origin);

          // Some parts of the app (e.g. neapolitan), rely on this
          // global event in order to patch the CSRF token.
          utils.globalPublish("mojo/reauth/complete", json.token);
        }
      }).otherwise(function () {
        // There's nothing actionable to do in this case. Chances are
        // the request failed because of a stale session. If that's
        // the case, then the user will see the ReauthOverlay.
      });
    }, delayMs);
  } else {
    // 15 mins
    var REFRESH_TIME = 900000;
    setInterval(function () {
      request.get(mUrl.toUrl("account/callback/ping")).otherwise(function () {
        // user's session timed out, nothing to do but spam bugsnag (so don't)
      });
    }, REFRESH_TIME);
  }
  return {};
});