/**
 * Widget that is used to display editors. It handles animation and starting/tearing down editors.
 * It uses dojo Topics to decide when to show and hide.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/connect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (declare, _Widget, _Templated, query, lang, connect, domStyle, domClass, domConstruct, Deferred, velocity) {
  return declare([_Widget, _Templated], {
    paneId: null,
    templateString: "<div><div data-dojo-attach-point='container'></div></div>",
    editor: null,
    postCreate: function () {
      var self = this;
      this._defaultContainerStyles();
      domStyle.set(this.domNode, 'display', 'none');
      connect.subscribe("mojo/editor/setup", this, function (editor) {
        if (self._isValidEditor(editor)) {
          self.setup(editor);
        }
      });
      connect.subscribe("mojo/editor/ready", this, function (editor) {
        if (self._isValidEditor(editor)) {
          self.display(editor);
        }
      });
      connect.subscribe("mojo/editor/done", this, function (editor) {
        if (self._isValidEditor(editor)) {
          if (self.editor == editor) {
            self.closeEditor();
          } else {
            editor.onClose();
          }
        }
      });
    },
    _defaultContainerStyles: function () {
      // set the styles and hide the pane.
      domStyle.set(this.container, {
        "width": "100%",
        "height": "100%",
        "position": "absolute",
        "left": 0,
        "top": 0,
        "bottom": 0,
        "overflow": "hidden",
        "background": "#ffffff"
      });
    },
    _isValidEditor: function (editor) {
      var idsSpecified = editor.paneId || this.paneId;
      return idsSpecified ? editor.paneId === this.paneId : true;
    },
    setup: function (editor) {
      var deferred = new Deferred();
      if (this.editor === null || this.editor && this.editor !== editor) {
        var oldEditor = this.editor;
        this.editor = editor;
        var callback = lang.hitch(this, function () {
          if (oldEditor) oldEditor.onClose();
          domConstruct.place(this.editor.domNode, this.container, "only");
          this.editor.startup();
          deferred.resolve();
        });
        this.editor ? this._hide(true).then(callback) : callback();
      } else {
        deferred.resolve();
      }
      return deferred;
    },
    display: function (editor) {
      this.setup(editor).then(lang.hitch(this, function () {
        if (domStyle.get(this.domNode, 'display') == 'none') {
          this._show(true).then(lang.hitch(editor, editor.onShow));
        }
      }));
    },
    saveEditor: function () {
      if (this.editor && this.editor.save) {
        // Do a synchronous save.
        this.editor.save(true);
      }
    },
    closeEditor: function () {
      // Get the first word up to the first space
      var firstWord = /^([\S]+)/;
      if (this.editor && !this._closing) {
        var editorName = firstWord.exec(this.editor.domNode.innerText) ? firstWord.exec(this.editor.domNode.innerText)[0] : this.editor.type;
        this._closing = true;
        this._hide(true).then(lang.hitch(this, function () {
          this.editor.onClose();
          this.editor = null;
          this._closing = false;
        }));
        window.ga("send", "event", "draft_editor", "click", editorName + "_editor:Cancel");
      }
    },
    _hide: function (animate) {
      var deferred = new Deferred();
      /* For Tablets: Only hide the drawer when hiding the editor if we're on the content tab. */
      if (query("#content-link.active").length) {
        /* Also, don't hide the drawer if we're in the link checker or any other .slider-editor pane. */
        query("body:not(.slider-editor)").removeClass("open-drawer");
      }
      if (animate) {
        domClass.add(this.domNode, "editorPaneHiding");
        this._hideAnimation().then(lang.hitch(this, function () {
          this._hide(false);
          domClass.remove(this.domNode, "editorPaneHiding");
          deferred.resolve();
        }));
      } else {
        domStyle.set(this.domNode, "display", "none");
        deferred.resolve();
      }
      return deferred;
    },
    /**
     * Performs the hide animation and returns a promise when complete
     *
     * @returns {dojo/Promise} a promise that completes when the animation finishes.
     * @private
     */
    _hideAnimation: function () {
      var deferred = new Deferred();
      var w = this.domNode.offsetWidth;

      // For every 250 pixel, take 100 millis.
      var duration = Math.round((w / 250 + 1) * 100);
      velocity(this.container, {
        "left": w
      }, {
        "duration": duration,
        "easing": "easeOutExpo",
        "complete": lang.hitch(this, function () {
          deferred.resolve();
        })
      });
      return deferred.promise;
    },
    _show: function (animate) {
      var deferred = new Deferred();
      /* For Tablets: Always show the drawer when an editor is opened. */
      query("body").addClass("open-drawer");
      if (animate) {
        this._showAnimation().then(function () {
          deferred.resolve();
        });
      } else {
        domStyle.set(this.domNode, "display", "block");
        deferred.resolve();
      }
      return deferred;
    },
    /**
     * Performs the show animation and returns a promise when complete
     *
     * @returns {dojo/Promise} a promise that completes when the animation finishes.
     * @private
     */
    _showAnimation: function () {
      var deferred = new Deferred();
      domStyle.set(this.domNode, {
        "display": "block"
      });
      var w = this.domNode.offsetWidth;
      domStyle.set(this.container, {
        "left": w + "px"
      });

      // For every 250 pixel, take 100 millis.
      var duration = Math.round((w / 250 + 1) * 100);
      velocity(this.container, {
        "left": "0px"
      }, {
        "duration": duration,
        "easing": "easeOutExpo",
        "complete": function () {
          deferred.resolve();
        }
      });
      return deferred.promise;
    }
  });
});