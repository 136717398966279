define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/blocks/ProductEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/ProductEditor/editorMobile.html", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, ProductEditor, _MobileBlockMixin, tmpl, _DynamicContentMixin) {
  return declare([ProductEditor, _MobileBlockMixin, _DynamicContentMixin], {
    type: "product-mobile",
    templateString: tmpl,
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["sectionHeader", "storeSelectContainer", "productDetailsImagesAndTitle", "productDetailsTitlePrice", "productDetailsCustomDescButton", "productDetailsButtonDetails", "productDetailsLinkText"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      document.getElementById("editor-pane").classList.add("embedded");
      this._setupNativeMobileHandlers();
    }
  });
});