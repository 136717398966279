/**
 * @class mojo.app.lists.Grouping
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var Grouping = declare(null, {
    /**
     * @type {String}
     */
    name: null,
    constructor: function (name) {
      this.name = name;
    }
  });

  /**
   * @param {*} view Data from backend
   * @return {*} A Grouping instance
   */
  Grouping.fromView = function (view) {
    return new Grouping(view.name);
  };
  return Grouping;
});