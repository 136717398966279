define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/templates", "mojo/context", "mojo/lib/flags"], function (declare, base, templates, context, flags) {
  return declare([base], {
    campaignEventData: {},
    // Passed from controller

    onReady: function () {
      if (context.flagIsOn(flags.SHST_SHUTTERSTOCK_NEA_MIGRATION)) {
        templates.setCampaignEventData(this.campaignEventData);
      }
    }
  });
});