define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dom, domConstruct, html, dojoWindow) {
  var clipboard = {
    /**
     * Copy the given string to the clipboard by placing an element in the <body> temporarily
     *
     * @param {string} text - The text to copy to the clipboard
     * @throws Error if failed to copy text (usually caused by calling outside of an event handler)
     */
    copy: function (text) {
      var fakeInput = domConstruct.toDom("<input>");
      fakeInput.value = text;
      domConstruct.place(fakeInput, dojoWindow.body());
      fakeInput.select();
      var successful = document.execCommand("copy");
      domConstruct.destroy(fakeInput);
      if (successful === false) {
        throw new Error("Failed to copy text (verify caller was in the context of an event handler)");
      }
    }
  };
  return clipboard;
});