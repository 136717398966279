/**
 * DnD Source that handles all the DnD features for Neapolitan's block selection.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/dnd/Manager", "dojo/dnd/Source", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/neapolitan/blocks", "mojo/user", "vendor/lodash-amd/isEqual", "dojo/text!./templates/blockTemplate.html"], function (declare, win, Manager, Source, domClass, domConstruct, stringUtil, topic, context, flags, blocks, user, isEqual, tpl) {
  Manager.manager().OFFSET_Y = 10;
  Manager.manager().OFFSET_X = 10;
  return declare([Source], {
    copyOnly: true,
    selfAccept: false,
    campaignType: null,
    ecommStores: [],
    blockList: [],
    // incredmentally add landing pages supported block as needed;
    // code blocks removed because users are trying to use iframes and that is not available
    pageBlockMap: {
      "text": true,
      "boxedText": true,
      "divider": true,
      "code": false,
      "button": true,
      "image": true,
      "imageCard": true,
      "imageGroup": true,
      "imageCaption": true,
      "socialFollow": true,
      "product": true,
      "payment": false,
      // SUNSET Payment blocks was rolled out
      "promoCode": true,
      "signUpForm": true,
      "socialShare": true,
      "webVideo": true
    },
    constructor: function () {
      this.singular = true;
      this.creator = this._avatarCreator;
      this.currentBlockList = blocks.getBlocks();
      this.insertBlockIcons(this.currentBlockList);
    },
    _avatarCreator: function (data, hint) {
      var svgFilename = data.editorType;
      if (data.editorType === "socialShare") {
        svgFilename = "socialShareRevised";
        data.name = "Share";
      }
      if (data.editorType === "socialFollow") {
        svgFilename = "socialFollowRevised";
      }
      var node = domConstruct.toDom(stringUtil.substitute(tpl, {
        name: data.name,
        description: data.description,
        editorType: data.editorType,
        svgFilename: svgFilename
      }));
      return {
        node: node,
        data: data,
        type: ["new-mc-block"]
      };
    },
    updateBlockIcons: function (blocksToHide) {
      var newBlocks = blocks.getFilteredBlocks(blocksToHide);
      var bool = isEqual(this.currentBlockList, newBlocks);
      // remove all and re-insert blocks, only if block set is different
      if (!bool) {
        this.node.innerHTML = "";
        this.currentBlockList = newBlocks;
        this.insertBlockIcons(this.currentBlockList);
      }
    },
    insertBlockIcons: function (editorsByType) {
      for (var type in editorsByType) {
        if (editorsByType.hasOwnProperty(type)) {
          var editor = editorsByType[type];
          var editorVisible = this._editorVisible(editor);
          if (editorVisible) {
            this.insertNodes(false, [{
              name: editor.prototype._label || editor.prototype._name,
              description: editor.prototype._description,
              editorType: type
            }]);
          }
        }
      }
    },
    _validPlatformCheck: function () {
      // Loop through map to find an allowed platform, if found return true
      var platformFound = false;
      this.ecommStores.forEach(function (ecommStore) {
        if (ecommStore.show_promo_code_block === true) {
          platformFound = true;
        }
      });
      return platformFound;
    },
    /**
     * Determine whether a given block ("editor") should be visible to this user in this context.
     *
     * @param {object} editor - the block to evaluate
     * @returns {boolean} - should this block be visible?
     *
     * Note: this function is eerily similar to filterBlockByCampaignType() in /Volumes/CaseSensitive/mailchimp/web/js/mojo/neapolitan/blocks.js
     **/

    _editorVisible: function (editor) {
      var block = editor.prototype;
      var validVisible = block._visible;
      var validType = block._campaignType ? block._campaignType === this.campaignType : true;
      var validEditingType = block._editingTypes.indexOf(this.editingType) >= 0;
      var validEcomm = block._ecommOnly ? user.isAnyEcomm : true;
      var validPaid = block._paidOnly ? block._paidOnly && user.hasPaid : true;
      var validFlag = block._featureFlag ? user.flagIsOn(block._featureFlag) : true;
      var validPage = this.campaignType === "page" ? this.pageBlockMap[block.type] : true;
      var validBlock = validVisible && validType && validEditingType && validEcomm && validPaid && validFlag && validPage;
      return validBlock;
    },
    onDndStart: function (source) {
      // This hides the list of blocks on smaller screens.
      if (this === source) {
        domClass.remove(win.body(), "open-drawer");
      }
      this.inherited(arguments);
    }
  });
});