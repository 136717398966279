/**
 * Color Swatches
 * @module mojo/widgets/pattern-library/DataVizSwatches.js
 *
 * Widget used to represent UI for Pattern Library color swatches in Data Visualization section.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "mojo/widgets/pattern-library/Swatch",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/DataVizSwatches.html"], function (declare, domConstruct, mColors, Swatch, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  var brandColors = mColors.brandColors;

  /**
   * Base widget responsible for loading all other widgets.
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    colorFormat: "hex",
    // Control what colors show as part of what grouping.
    groupData: [brandColors.apple, brandColors.plum, brandColors.apricot, brandColors.pumpkin, brandColors.dragonfruit, brandColors.parsnip],
    postCreate: function () {
      this._initSwatches();
    },
    /**
     * Create the rows for the swatches based on whats configured earlier.
     *
     * @private
     */
    _initSwatches: function () {
      this._resetSwatches();
      this.groupData.forEach(function (colorObj) {
        var swatch = new Swatch({
          color: colorObj,
          colorFormat: this.colorFormat
        });
        swatch.placeAt(this.groupDataContainer);
        swatch.startup();
      }.bind(this));
    },
    /**
     * Respond to changes in the toggler. Save the users setting and regenerate all of the swatches.
     *
     * @param {string} newFormat - New format to display. hex/rgb/less
     * @private
     */
    _handleTogglerChange: function (newFormat) {
      this.colorFormat = newFormat;
      this._initSwatches();
    },
    /**
     * Clear out the domNodes so we don't end up with duplicate swatches. Ideally this would destroy each
     * of the widgets. If performance becomes a challenge, this would be a necessary upgrade.
     *
     * @private
     */
    _resetSwatches: function () {
      domConstruct.empty(this.groupDataContainer);
    }
  });
});