define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mccharts/mccharts", "mojo/colors", "d3/d3", "dijit/registry", "mojo/url", "mojo/charts", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/logger"], function (declare, _base, mccharts, mColors, d3, registry, mUrl, charts, dom) {
  return declare([_base], {
    onReady: function () {
      var self = this;
      if (dom.byId("pages-performance")) {
        var url = mUrl.toUrl("/reports/landing-pages/history", {
          id: this.pageId
        });
        var pagesChart = new charts.PageReportsTimeseriesChart("pages-performance", url);
      }
      var ordersList = dom.byId("orders-list");
      var storeSelect = dom.byId("ecommerce-stores");
      storeSelect.on("change", function () {
        var data = this.get("value").split("-");
        ordersList.set("url", mUrl.toUrl("/reports/ecommerce/order-list", {
          id: self.pageUniqueId,
          store_id: data[0],
          version: data[1]
        }));
      });
    }
  });
});