define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/peaches", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, mUrl, tabContainerUtil, peaches, dom) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "list-details") {
        var listIdFromUrl = new URL(window.location.href).searchParams.get("list_id");
        if (listIdFromUrl) {
          this.viewListDetails(listIdFromUrl);
        }
      }
    },
    /**
     * Opens the view list details tab
     * @param listId
     */

    viewListDetails: function (listId) {
      var self = this;
      var newPaneObj = {
        title: "List Details: " + listId,
        href: mUrl.toUrl("/peaches2/users/lists?user_id=" + self.userAttrs.userId + "&_s=list-details&list_id=" + listId),
        stub: "list-details",
        closable: true,
        onClose: function () {
          self.tabContainer.listDetailsSection = null;
          return true;
        },
        onDownloadEnd: function () {
          self.listDetailsInit(listId, this);
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     * adds event handlers and initializes the charts
     * @param {number} lid - the list id
     * @param {object} viewDetailsPane - the content pane object
     */
    listDetailsInit: function (lid, viewDetailsPane) {
      var self = this;
      this.parse();
      this._loadCharts(this.userAttrs.userId, lid);
      var select = this.$widget.detailsGrowthSelect;
      select.set("onChange", function () {
        var listId = select.attr("value");
        var url = mUrl.toUrl("/peaches2/users/lists", {
          _s: "list-details",
          user_id: self.userAttrs.userId,
          list_id: listId
        });
        viewDetailsPane.set("href", url).then(function () {
          self._loadCharts(self.userAttrs.userId, listId);
        });
        viewDetailsPane.set("title", "Login Details: " + listId);
      });
    },
    /**
     * used to initialize the charts
     * @param {number} uid - the user id
     * @param {number} lid - the list id
     */
    _loadCharts: function (uid, lid) {
      var self = this;
      if (dom.byId("campaign-history")) {
        new peaches.PerformanceOverviewChart("campaign-history", mUrl.toUrl("/peaches2/users/lists/campaign-history-json-data", {
          user_id: uid,
          list_id: lid
        }));
      }
      if (dom.byId("campaign-abuse-history")) {
        new peaches.AbusePerformanceOverviewChart("campaign-abuse-history", mUrl.toUrl("/peaches2/users/lists/campaign-abuse-history-json-data", {
          user_id: uid,
          list_id: lid
        }));
      }
    }
  });
});