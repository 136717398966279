define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "mojo/peaches", "mojo/url", "mccharts/mccharts", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/ButtonGroup", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on, FilterMenu, FilterMessage, peaches, mUrl, mcCharts, xhr, domConstruct, ButtonGroup, domClass) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      this.itemTpl = "<li class='c-buttonGroupItem'>" + "<button data-mc-value='{value}' title='{title}' class='c-buttonGroupButton v-buttonGroupUnderlined'>{label}</button>" + "</li>";
      this.activeClass = " v-buttonGroupUnderlined--active";
      var filterSettings = {
        "target": "unsubscribe-reasons-list",
        "filters": [{
          "label": "List",
          "key": "list_id",
          "type": "text"
        }, {
          "label": "Age",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day",
            "2": "Two days",
            "7": "One week",
            "30": "One month",
            "90": "Three months"
          }
        }, {
          "label": "Reason",
          "key": "reason",
          "type": "select",
          "options": {
            "NORMAL": "NORMAL",
            "NOSIGNUP": "NOSIGNUP",
            "OTHER": "OTHER",
            "SPAM": "SPAM"
          }
        }, {
          "label": "Type",
          "key": "type",
          "type": "select",
          "options": {
            "S": "Abuse Complaint",
            "A": "Admin",
            "C": "Campaign",
            "W": "Not a Campaign"
          }
        }, {
          "label": "Comments Only",
          "key": "comments",
          "type": "checkbox"
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.unsubscribeFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.unsubscribeFiltersMessage.appendChild(filterMessage.domNode);
      filterSettings = {
        "target": "reasons-by-age-list",
        "filters": [{
          "label": "Age",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day",
            "2": "Two days",
            "7": "One week",
            "30": "One month",
            "90": "Three months"
          }
        }]
      };
      filter = new FilterMenu(filterSettings);
      this.$el.unsubscribeReasonsFilters.appendChild(filter.domNode);
      filterMessage = new FilterMessage(filterSettings);
      this.$el.unsubscribeReasonsFiltersMessage.appendChild(filterMessage.domNode);
      if (this.$el.unsubscribeButtonGroup) {
        var unsubscribeTimeFrame = [{
          title: "All Time",
          value: "All Time",
          label: "All Time"
        }, {
          title: "90 days",
          value: "90",
          label: "90 days"
        }, {
          title: "30 days",
          value: "30",
          label: "30 days"
        }];
        this.unsubscribeTabs = new ButtonGroup({
          options: unsubscribeTimeFrame,
          itemTpl: self.itemTpl,
          activeClass: self.activeClass
        });
        this.unsubscribeTabs.placeAt(this.$el.unsubscribeButtonGroup);
        this.unsubscribeTabs.startup();
        this.unsubscribeTabs.setSelected(unsubscribeTimeFrame[0].value);
      }
      this.showOverallGraph();
      this.showListMostUnsubs();
    },
    showOverallGraph: function (legendId) {
      var self = this;
      var doughnutChart = mcCharts.models.doughnutChart();
      xhr(mUrl.toUrl("/peaches2/users/lists/get-account-unsubs", {
        user_id: this.pageJs.userAttrs.userId
      }), {
        handleAs: "json"
      }).then(function (data) {
        // Do something with the handled data
        var myObj = {};
        Object.keys(data).forEach(function (d) {
          var chartData = [];
          if (data[d]) {}
          for (var prop in data[d]) {
            if (data[d][prop]) {
              var dataVal = data[d][prop];
              switch (prop) {
                case "":
                  prop = "Unspecified";
                  break;
                case "NORMAL":
                  prop = "No longer interested";
                  break;
                case "NOSIGNUP":
                  prop = "Did not sign up";
                  break;
                case "SPAM":
                  prop = "Spammy Content";
                  break;
                case "OTHER":
                  prop = "Other";
                  break;
                case "INAPPROPRIATE":
                  prop = "Inappropriate";
                  break;
                case "BYADMIN":
                  prop = "Unsub by Admin";
                  break;
                default:
                  prop = "Unknown Source";
              }
              var obj = {
                "type": prop,
                "value": dataVal
              };
              chartData.push(obj);
            }
          }
          myObj[d] = chartData;
        });
        return myObj;
      }).then(function (data) {
        //these should probably live in mojo/colors
        var graphsColors = ["#cfbecd", "#b79db5", "#9f7d9c", "#fbd4b4", "#f69343", "#ffeebf", "#fede88"];
        doughnutChart.colors(graphsColors);
        doughnutChart.strokeWidth(30);
        doughnutChart.percentageOnly(true);
        doughnutChart.loadChart("#overall-unsubscribe-reasons", data.all_time).then(function (res) {
          doughnutChart.legendContainer = self.$el.overallUnsubLegend;
          self._createLegendNodes(res.data.length);
          doughnutChart._createLegend();
        });
        self.unsubscribeTabs.on("change", function () {
          if (this.value === "90") {
            doughnutChart.updateData(data.last_90_days).then(function (res) {
              self._showNoDataState(res.data);
              self._createLegendNodes(res.data.length);
              doughnutChart._createLegend();
            });
          }
          if (this.value === "30") {
            doughnutChart.updateData(data.last_30_days).then(function (res) {
              self._showNoDataState(res.data);
              self._createLegendNodes(res.data.length);
              doughnutChart._createLegend();
            });
          }
          if (this.value === "All Time") {
            doughnutChart.updateData(data.all_time).then(function (res) {
              self._showNoDataState(res.data);
              self._createLegendNodes(res.data.length);
              doughnutChart._createLegend();
            });
          }
        });
      });
    },
    _createLegendNodes: function (numberOfNodes) {
      var self = this;
      for (var i = 0; i < numberOfNodes; i++) {
        var legendNode = "<div class=\"flex small-meta\">" + "<div class=\"\"></div>" + "<div class=\"flex-fourth small-meta\"></div>" + "</div>";
        domConstruct.place(legendNode, self.$el.overallUnsubLegend);
      }
    },
    _showNoDataState: function (data) {
      if (data.length === 0) {
        domClass.remove(this.$el.noUnsubData, "hide");
      } else {
        domClass.add(this.$el.noUnsubData, "hide");
      }
    },
    showListMostUnsubs: function () {
      var self = this;
      var listUrl = mUrl.toUrl("/peaches2/users/lists/", {
        _s: "list-details",
        user_id: this.pageJs.userAttrs.userId,
        list_id: null
      });
      dojo.xhrGet({
        'url': mUrl.toUrl("/peaches2/users/lists/get-list-most-unsubs"),
        'content': {
          'user_id': this.pageJs.userAttrs.userId
        },
        'handleAs': 'json'
      }).then(function (data) {
        data.most_unsubs.forEach(function (entry, i) {
          var chartData = [];
          var chartObj = {};
          chartObj['list_id'] = entry.list_id;
          if (entry.reasons === null) {
            return;
          }
          for (var key in entry.reasons) {
            var dataVal = entry.reasons[key];
            if (key === "") {
              key = "UNSPECIFIED";
            }
            chartObj[key] = dataVal;
          }
          chartData.push(chartObj);
          var listDetailsURL = listUrl + "&list_id=" + entry.list_id;
          var unsubChart = '<div class="flex">' + '<div class="fwb padding--lv4 !padding-bottom--lv0 !padding-left-right--lv0 list-id-link"><a href="' + listDetailsURL + '">' + entry.list_id + '</a></div>' + '<div class="stacked-bar-graph"  id="most-unsubs-' + entry.list_id + '"></div>' + '<div class="padding--lv4 !padding-bottom--lv0 !padding-left-right--lv0 small-meta"><span class="margin--lv1 !margin-top-bottom--lv0 !margin-left--lv0 fwb ">' + entry.unsubs + ' Unsubs </span><span>Last Import: ' + entry.last_import + '</span></div>' + '</div>';
          if (data.most_unsubs === null) {
            var noData = '<div class="no-data alignc">' + '<img role="presentation" src="/css/icons/no-data/goose-egg.svg" class="icon">' + '<h2>Goose egg. There\'s no information available.</h2>' + '</div>';
            dojo.place(noData, 'most-unsubs', 'only');
          } else {
            dojo.place(unsubChart, 'most-unsubs', 'last');
            self._renderChart(chartData, 'most-unsubs-' + entry.list_id);
          }
        });
      });
    },
    _renderChart: function (chartData, chartLocation) {
      var chart = AmCharts.makeChart(chartLocation, {
        "type": "serial",
        "theme": "light",
        "dataProvider": chartData,
        "colors": ['#cfbecd', '#b79db5', '#9f7d9c', '#fbd4b4', '#f69343', '#ffeebf', '#fede88'],
        "valueAxes": [{
          "stackType": "100%",
          "axisAlpha": 0,
          "gridAlpha": 0,
          "labelsEnabled": false
        }],
        "graphs": [{
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Unspecified",
          "type": "column",
          "color": "#000000",
          "valueField": "UNSPECIFIED"
        }, {
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Unsub by Admin",
          "type": "column",
          "color": "#000000",
          "valueField": "BYADMIN"
        }, {
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "No longer interested",
          "type": "column",
          "color": "#000000",
          "valueField": "NORMAL"
        }, {
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Did not sign up",
          "type": "column",
          "color": "#000000",
          "valueField": "NOSIGNUP"
        }, {
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Spammy Content",
          "type": "column",
          "color": "#000000",
          "valueField": "SPAM"
        }, {
          "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Other",
          "type": "column",
          "color": "#000000",
          "valueField": "OTHER"
        }, {
          "balloonText": '<span class="fwb  ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
          "fillAlphas": 0.8,
          "lineAlpha": 0.3,
          "title": "Inappropriate",
          "type": "column",
          "color": "#000000",
          "valueField": "INAPPROPRIATE"
        }],
        "balloonText": '<span class="fwb ">LID [[category]]: [[title]] </span> <span class="fwb">[[percents]]%</span>',
        "balloon": {
          "borderThickness": 0,
          "animationDuration": 0.25,
          "fadeOutDuration": 0.25,
          "shadowColor": "transparent",
          "horizontalPadding": 12,
          "verticalPadding": 6,
          "color": "#fff",
          "fillColor": "#373737",
          "cornerRadius": 3,
          "fillAlpha": 1,
          "textAlign": "left",
          "startDuration": 0,
          "sequencedAnimation": true,
          "startAlpha": "100%"
        },
        "rotate": true,
        "categoryField": "list_id",
        "categoryAxis": {
          "gridPosition": "start",
          "axisAlpha": 0,
          "gridAlpha": 0,
          "position": "left",
          "labelsEnabled": false
        }
      });
    }
  });
});