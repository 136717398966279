define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.hitch
"dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/MenuItem"], function (lang, array, declare, MenuItem) {
  return declare([MenuItem], {
    badgeTpl: '<span class="badge {badgeType} margin--lv2 !margin-top-bottom--lv0 !margin-right--lv0">{badge}</span>',
    metaTpl: '{label}<div class="fwn textcolor--secondary">{meta}</div>',
    mediaTpl: '<div class="selfclear"><div class="float-left u-square--lv1 margin--lv2 !margin-top-bottom--lv0 !margin-left--lv0"><img src="{imageSrc}" height="36"></div><div class="float-left"><div class="line-height--lv3">{label}</div><div class="fwn textcolor--secondary line-height--lv3">{meta}</div></div></div>',
    _setLabelAttr: function (val) {
      var badge = this.option.badge;
      var badgeType = this.option.badgeType;
      var imageSrc = this.option.imageSrc;
      var meta = this.option.meta || "";
      if (badge) {
        val += lang.replace(this.badgeTpl, {
          "badge": badge,
          "badgeType": badgeType
        });
      }
      if (imageSrc) {
        // Apply the mediaTpl, which resembles the media block
        val = lang.replace(this.mediaTpl, {
          "imageSrc": imageSrc,
          "label": val,
          "meta": meta
        });
      } else if (meta) {
        // No need for a media block, use metaTpl
        val = lang.replace(this.metaTpl, {
          "label": val,
          "meta": meta
        });
      }
      this.inherited(arguments, [val]);
    }
  });
});