/**
 * Events that are created inside of @mc/mc-ecs-storage can be accessed from this object
 */
define([], function () {
  /**
   * Grab the events from the global scope
   */
  var EcsEvents = window.__MC_ECS_EVENT;

  /**
   * Remove the global object
   */
  delete window.__MC_ECS_EVENT;
  for (var event in EcsEvents) {
    if (EcsEvents.hasOwnProperty(event)) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/seal
      // Prevents new properties from being added to the object and also prevents old properties from being removed
      Object.seal(EcsEvents[event]);
    }
  }

  /**
   * Users should not attempt to add new properties to this object
   */
  Object.seal(EcsEvents);
  return EcsEvents;
});