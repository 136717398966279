define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils"], function (declare, base, lang, dom, on, request, domForm, ioQuery, mUrl, utils) {
  return declare([base], {
    onReady: function () {
      this._setupFieldSelector();
      this._setupSaveFields();
      this._setupSearchForm();
    },
    _setupFieldSelector: function () {
      on(this.$el.selectField, "input", function () {
        if (dom.byId(this.value)) {
          var selectedField = this.value;
          var targetElement = dom.byId(selectedField);
          targetElement.parentElement.parentElement.parentElement.style.display = "block";
          targetElement.scrollIntoView();
          targetElement.checked = true;
        }
      });
    },
    _setupSaveFields: function () {
      var self = this;
      var onSuccess = function (data) {
        utils.toast("Preferences Saved");
      };
      var onError = function (error) {
        utils.toast("There was an error saving data. Please visit the Preferences page to continue", "error");
      };
      on(this.$el.saveFieldLink, "click", function (e) {
        request.post(mUrl.toUrl("/peaches2/tools/user-search/save-settings/"), {
          data: domForm.toObject(self.$el.defaultFieldsForm),
          handleAs: "text"
        }).then(onSuccess, onError);
      });
    },
    _setupSearchForm: function () {
      on(this.$el.searchForm, "submit", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this._performSearch();
      }));
      on(this.$el.applyFilterBtn, "click", lang.hitch(this, function (e) {
        this._performSearch();
      }));
      on(this.$el.clearFilterBtn, "click", lang.hitch(this, "_clearFilters"));
    },
    _performSearch: function () {
      // Combine filters & search query. Have to do this as the filter popup is outside the form.
      var searchParams = domForm.toObject(this.$el.searchForm);
      this.$group.fieldInput.forEach(function (input) {
        if (input.value) {
          searchParams[input.name] = input.value;
        }
      });
      document.location.href = this.$el.searchForm.action + "?" + ioQuery.objectToQuery(searchParams);
    },
    _clearFilters: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.$group.fieldInput.forEach(function (input) {
        input.value = "";
      });
    }
  });
});