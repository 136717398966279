define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/ImportLoader.html", "mojo/widgets/ProgressCircle", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/Poll", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, template, ProgressCircle, domStyle, html, lang, query, Stateful, mUrl, xhr, Poll, I18nTranslation, flags, context) {
  return declare("ImportLoader", [_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Stateful], {
    templateString: template,
    widgetsInTemplate: true,
    status: "",
    messages: {},
    percent: false,
    allowedStatuses: ["working", "complete", "reverting", "reverted"],
    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (translations) {
          var reloadPageHtml = "<a onclick='window.location.reload()' href=''>";
          var endAnchor = "</a>";
          var countDownRefresh = "<span data-mc-el='countdownToRefresh'>";
          var endSpan = "</span>";
          this.messages = {
            "working": {
              "primary": translations.audience_management_working_primary,
              "secondary": translations.audience_management_working_secondary
            },
            "complete": {
              "primary": translations.audience_management_complete_primary,
              "secondary": I18nTranslation.getTranslationString(translations.audience_management_complete_reverted_secondary, [reloadPageHtml, endAnchor]),
              "auto_secondary": I18nTranslation.getTranslationString(translations.audience_management_complete_auto_secondary, [reloadPageHtml, endAnchor, countDownRefresh, endSpan])
            },
            "reverting": {
              "primary": translations.audience_management_reverting_primary,
              "secondary": translations.audience_management_reverting_secondary
            },
            "reverted": {
              "primary": translations.audience_management_reverted_primary,
              "secondary": I18nTranslation.getTranslationString(translations.audience_management_complete_reverted_secondary, [reloadPageHtml, endAnchor])
            }
          };
        }.bind(this));
      } else {
        this.messages = {
          "working": {
            "primary": "We're importing your list!",
            "secondary": "You can navigate away from this page - importing won't be affected."
          },
          "complete": {
            "primary": "Your import is complete!",
            "secondary": "<a onclick='window.location.reload()' href=''>Refresh this page</a> to update the table below."
          },
          "reverting": {
            "primary": "We're reverting your list!",
            "secondary": "You can navigate away from this page - reverting won't be affected."
          },
          "reverted": {
            "primary": "Your import has been undone!",
            "secondary": "<a onclick='window.location.reload()' href=''>Refresh this page</a> to update the table below."
          }
        };
      }
    },
    postCreate: function () {
      this.inherited(arguments);
      this.updateStatus();
      this.poll = new Poll(lang.hitch(this, this.updateStatus), 5000);
      this.poll.start();
      this.progressMeter = new ProgressCircle();
      this.progressMeter.placeAt(this.progressMeterContainer);
      this.watch("status", function (name, oldValue, newValue) {
        if (this.allowedStatuses.indexOf(newValue) === -1) {
          return;
        }
        this.updateMessaging(newValue);

        // switch the newValues to align with the states in ProgressCircle.js
        if (this.percent || this.isComplete()) {
          switch (newValue) {
            case "working":
            case "reverting":
              this.progressMeter.set("status", "progress");
              break;
            case "complete":
            case "reverted":
              this.progressMeter.set("status", "success");
              this.poll.stop();
              break;
            default:
              break;
          }
        } else {
          this.progressMeter.set("status", "loading");
        }
        if (this.isComplete()) {
          this.replaceCompleteMessaging();
          this.initCountdownElement();
        }
      });
    },
    replaceCompleteMessaging: function () {
      var completeMessaging = {};
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        completeMessaging = {
          "primary": this.messages.complete.primary,
          "secondary": this.messages.complete.auto_secondary
        };
      } else {
        completeMessaging = {
          "primary": "Your import is complete!",
          "secondary": "We’ll automatically <a onclick='window.location.reload()' href=''>refresh this page</a> in <span data-mc-el='countdownToRefresh'>5 seconds</span> to show your new audience."
        };
      }
      html.set(this.statusMessagePrimary, completeMessaging.primary);
      domStyle.set(this.statusMessagePrimary, "display", "block");
      html.set(this.statusMessageSecondary, completeMessaging.secondary);
      domStyle.set(this.statusMessageSecondary, "display", "block");
    },
    initCountdownElement: function () {
      var secondsToReload = 5;
      var intervalId = null;
      var countDownElement = query("[data-mc-el='countdownToRefresh']")[0];
      var countDown = function () {
        if (!countDownElement) {
          clearInterval(intervalId);
          window.location.reload();
        } else if (secondsToReload > 0) {
          var units = secondsToReload === 1 ? "second" : "seconds";
          countDownElement.innerHTML = secondsToReload + " " + units;
          secondsToReload--;
        } else if (secondsToReload === 0) {
          clearInterval(intervalId);
          window.location.reload();
        }
      };
      intervalId = window.setInterval(countDown, 1000);
    },
    updateStatus: function () {
      xhr.get(mUrl.toUrl("lists/members/import-loader"), {
        handleAs: "json",
        query: {
          import_id: this.import_id
        }
      }).then(lang.hitch(this, function (response) {
        if (response.success && response.data) {
          this.updateData(response.data);
        } else {
          this.poll.stop();
        }
      }));
    },
    updateData: function (data) {
      domStyle.set(this.domNode, "display", data ? "block" : "none");
      this.percent = data.percent !== -1;
      this.set("status", data.status);
      if (this.percent && (data.status === "working" || data.status === "reverting")) {
        this.setPercentComplete(data.percent);
      }
    },
    updateMessaging: function (status) {
      if (this.allowedStatuses.indexOf(status) === -1) {
        return;
      }
      if (this.messages[status]["primary"]) {
        html.set(this.statusMessagePrimary, this.messages[status]["primary"]);
        domStyle.set(this.statusMessagePrimary, "display", "block");
      } else {
        domStyle.set(this.statusMessagePrimary, "display", "none");
      }
      if (this.messages[status]["secondary"]) {
        html.set(this.statusMessageSecondary, this.messages[status]["secondary"]);
        domStyle.set(this.statusMessageSecondary, "display", "block");
      } else {
        domStyle.set(this.statusMessageSecondary, "display", "none");
      }
    },
    isComplete: function () {
      return this.status === "complete" || this.status === "reverted";
    },
    setPercentComplete: function (num) {
      if (typeof num !== "undefined") {
        this.progressMeter.setPercent(num);
      }
    }
  });
});