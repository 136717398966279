define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/StatusTimeline.html", "dojo/text!./templates/StatusTimelineItem.html"], function (declare, lang, domConstruct, domClass, Renderable, tpl, itemTpl) {
  return declare([Renderable], {
    templateString: tpl,
    nodeTemplateString: itemTpl,
    edgeTemplateString: '<div class="c-statusTimelineEdge"></div>',
    STATUS_TITLES: {
      placed: "Order placed",
      sent: "Sent to printer",
      printed: "Printing complete",
      shipping: "Mailed",
      delivered: "Delivered"
    },
    constructor: function (props) {
      this.state = {
        disclaimer: props.disclaimer
      };
    },
    render: function () {
      this.inherited(arguments);
      this.renderStatusNodes();
    },
    renderStatusNodes: function () {
      var currentStatusIndex = this.statuses.indexOf(this.currentStatus);
      this.statuses.forEach(function (status, index) {
        var node = domConstruct.toDom(lang.replace(this.nodeTemplateString, {
          ordinal: index + 1,
          title: this.STATUS_TITLES[status],
          classes: index <= currentStatusIndex ? " c-statusTimelineNode--checked" : ""
        }));
        if (index > 0) {
          var edge = domConstruct.toDom(this.edgeTemplateString);
          domClass.toggle(edge, "c-statusTimelineEdge--checked", index <= currentStatusIndex);
          this.timelineContainer.appendChild(edge);
        }
        this.timelineContainer.appendChild(node);
      }, this);
    }
  });
});