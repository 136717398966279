define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, lang, base, domAttr, on, topic, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    automationListRendered: null,
    onReady: function () {
      var self = this;
      topic.subscribe("mojo/analytics/List/automations-list/render", function (list) {
        var campaignIndex = self.pageJs;
        self.pageJs.tabContainer.automationSection.set("title", "Automation (" + list.total + ")");
        self.parse(self.elId);
        if (self.$group.automationEmailTool) {
          self.$group.automationEmailTool.forEach(function (el) {
            registry.byId(el.id).set("onChange", function () {
              campaignIndex.openEmailTool(this);
            });
          });
        }
        if (self.$group.manageAutomationLinksButtons) {
          self.$group.manageAutomationLinksButtons.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.manageLinks(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.manageAutomationLinksButtons) {
          self.$group.manageAutomationLinksButtons.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.manageBounces(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.deleteAutomationButtons) {
          self.$group.deleteAutomationButtons.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.deleteCampaign(domAttr.get(el, "data-campaign-id"), domAttr.get(el, "data-campaign-type"), "automation");
            });
          });
        }
        if (self.$group.viewAutomationDetails) {
          self.$group.viewAutomationDetails.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.viewParentDetails) {
          self.$group.viewParentDetails.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.viewDetailsTitleButtons) {
          self.$group.viewDetailsTitleButtons.forEach(function (el) {
            on(el, "click", function () {
              campaignIndex.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (!self.automationListRendered) {
          self.automationListRendered = true;
          campaignIndex.loadFilter("automation");
        }
      });
    }
  });
});