/**
 * A group of icons as buttons that allows only one button to be toggled.
 * This pattern is used primarily in mobile neapoliton for desktop/mobile preview toggle
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/ButtonGroup", "dojo/text!./templates/IconButtonGroupItem.html", "dojo/text!./templates/IconButtonGroupItemWithImage.html"], function (declare, array, lang, domConstruct, ButtonGroup, FreddiconItemTpl, ImageItemTpl) {
  return declare([ButtonGroup], {
    templateString: "<ul class=\"flex align-items--center\"></ul>",
    // using the option key "icon"" will create a Freddicon icon
    iconTpl: FreddiconItemTpl,
    // using the option key "path"" will create a an image element
    imageTpl: ImageItemTpl,
    activeClass: "active",
    postCreate: function () {
      this.inherited(arguments);
      this.setSelected(this.params.value);
    },
    _addOptions: function () {
      array.forEach(this.options, lang.hitch(this, function (option) {
        //overwrote the addOptions method to add "icon" to the template options, and remove title.
        var tplOpts = lang.mixin({
          "value": "",
          "label": "",
          "icon": ""
        }, option);
        if (option.icon) {
          domConstruct.place(lang.replace(this.iconTpl, tplOpts), this.domNode);
        } else {
          domConstruct.place(lang.replace(this.imageTpl, tplOpts), this.domNode);
        }
      }));
    }
  });
});