define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/peaches", "dijit/registry"], function (declare, base, domAttr, on, mUrl, peaches, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.editIssueButtons) {
        this.$group.editIssueButtons.forEach(function (el) {
          on(el, "click", function () {
            self.updateIssue(domAttr.get(el, "data-issue-id"), domAttr.get(el, "data-source"), domAttr.get(el, "data-target"), domAttr.get(el, "data-sent"), domAttr.get(el, "data-occurred-at"), domAttr.get(el, "data-detection-source"));
          });
        });
      }
    },
    updateIssue: function (issueId, sourceApp, targetedBrand, emailsSent, occurredAt, detectionSource) {
      this.$el.issueId.value = issueId;
      var sourceAppSelector = registry.byId("source-select");
      sourceAppSelector.set("value", sourceApp);
      this.$el.targetedBrand.value = targetedBrand;
      this.$el.emailsSent.value = emailsSent;
      this.$el.occurredAt.value = occurredAt;
      var detectionSourceSelector = registry.byId("detection-select");
      detectionSourceSelector.set("value", detectionSource);
      this.$widget.editIssueDialog.show();
    }
  });
});