define(["dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/domReady!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (geometry, lang, domReady, has, baseWindow) {
  // module:
  //		dojo/uacss

  /*=====
  return {
  	// summary:
  	//		Applies pre-set CSS classes to the top-level HTML node, based on:
  	//
  	//		- browser (ex: dj_ie)
  	//		- browser version (ex: dj_ie6)
  	//		- box model (ex: dj_contentBox)
  	//		- text direction (ex: dijitRtl)
  	//
  	//		In addition, browser, browser version, and box model are
  	//		combined with an RTL flag when browser text is RTL. ex: dj_ie-rtl.
  	//
  	//		Returns the has() method.
  };
  =====*/

  var html = baseWindow.doc.documentElement,
    ie = has("ie"),
    opera = has("opera"),
    maj = Math.floor,
    ff = has("ff"),
    trident = has("trident"),
    boxModel = geometry.boxModel.replace(/-/, ''),
    classes = {
      "dj_quirks": has("quirks"),
      // NOTE: Opera not supported by dijit
      "dj_opera": opera,
      "dj_khtml": has("khtml"),
      "dj_webkit": has("webkit"),
      "dj_safari": has("safari"),
      "dj_chrome": has("chrome"),
      "dj_gecko": has("mozilla"),
      "dj_ios": has("ios"),
      "dj_android": has("android")
    }; // no dojo unsupported browsers

  if (ie) {
    classes["dj_ie"] = true;
    classes["dj_ie" + maj(ie)] = true;
    classes["dj_iequirks"] = has("quirks");
  }
  if (ff) {
    classes["dj_ff" + maj(ff)] = true;
  }

  // IE 11 uses Trident 7 rendering engine
  // http://dojo-toolkit.33424.n3.nabble.com/Dojo-1-9-2-now-available-tp4000355.html
  if (maj(trident) === 7) {
    classes["dj_ie"] = true;
    classes["dj_ie11"] = true;
  }
  classes["dj_" + boxModel] = true;

  // apply browser, browser version, and box model class names
  var classStr = "";
  for (var clz in classes) {
    if (classes[clz]) {
      classStr += clz + " ";
    }
  }
  html.className = lang.trim(html.className + " " + classStr);

  // If RTL mode, then add dj_rtl flag plus repeat existing classes with -rtl extension.
  // We can't run the code below until the <body> tag has loaded (so we can check for dir=rtl).
  domReady(function () {
    if (!geometry.isBodyLtr()) {
      var rtlClassStr = "dj_rtl dijitRtl " + classStr.replace(/ /g, "-rtl ");
      html.className = lang.trim(html.className + " " + rtlClassStr + "dj_rtl dijitRtl " + classStr.replace(/ /g, "-rtl "));
    }
  });
  return has;
});