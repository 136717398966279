define(["dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (dojoWindow, domConstruct, domStyle, velocity) {
  return function () {
    var frame = domConstruct.toDom("<iframe src='/brand-styles' style='display:none; position:absolute; width:100%; height:100%; z-index:100; transform: translateY(400px);'></iframe>");
    domConstruct.place(frame, dojoWindow.body());
    window.addEventListener("message", receiveMessage);
    function receiveMessage(event) {
      var data = JSON.parse(event.data);
      if (data.event === "brand_styles:show") {
        _animSlideIn(frame);
        return;
      }
      if (data.event === "brand_styles:close") {
        _animSlideOut(frame);
        return;
      }
    }

    /* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ /
     *
     * ~~~ ANIMATIONS ~~~
     *
     * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

    /**
     * Slides an element into view.
     * @param {Object} element - Element to slide in.
     */
    function _animSlideIn(element) {
      domStyle.set(element, "display", "block");
      domStyle.set(element, "opacity", 0);
      velocity(element, {
        "opacity": [1, "easeOutCubic", 0],
        "translateY": ["0", "easeOutQuint", "100%"]
      }, {
        "delay": 100,
        "duration": 550
      });
    }

    /**
     * Slides an element out of view.
     * @param {Object} element - Element to slide out.
     */
    function _animSlideOut(element) {
      velocity(element, {
        "translateY": ["100%", "easeOutQuint", "0"]
      }, {
        "delay": 50,
        "duration": 450,
        "complete": function () {
          domStyle.set(element, "display", "none");
        }
      });
    }
  };
});