define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dojox/mvc/sync", "dojo/text!./templates/listMemberPagingTotal.html"], function (declare, _Part, _Templated, lang, number, sync, tpl) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    init: function () {
      sync(this.target, "total", this, "total");
      sync(this.target, "type", this, "type");
      this._updateTotal();
      this.watch("total", lang.hitch(this, "_updateTotal"));
    },
    _updateTotal: function () {
      this.totalCount.innerHTML = number.format(this.total, {
        'pattern': '#,###'
      });
    }
  });
});