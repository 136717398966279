define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/ChatButton.html", "dojo/text!./templates/ChatLink.html", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/user", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/support/chat", "mojo/utils/string", "mojo/context", "dojo/request", "mojo/widgets/ada/Ada"], function (declare, _WidgetBase, _TemplatedMixin, tplStrBtn, tplStrLink, xhr, mUrl, user, flags, lang, on, domStyle, domClass, array, Chat, stringUtils, context, request, Ada) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStrBtn,
    params: {},
    service: "zendesk",
    support_key: "",
    tag: "none",
    referral: encodeURIComponent(window.location.pathname),
    department: "none",
    tracking: {},
    buttonClass: "",
    postMixInProperties: function () {
      //display a link instead of a chat button
      if (this.params && this.params.cta_style === 'link') {
        this.templateString = tplStrLink;
      }
      //attempt to append tracking info
      if (this.params.tracking && this.params.tracking != "") {
        try {
          this.tracking = this.params.tracking.event_label ? this.params.tracking : JSON.parse(this.params.tracking);
        } catch (e) {
          this.tracking = null;
          console.warn("Expected tracking to be passed in as a json parsable string");
        }
      }
    },
    postCreate: function () {
      this.translateChatButton();

      //set service and button visibility based on user info
      xhr.get(mUrl.toUrl("kb/get-support-settings"), {
        handleAs: "json"
      }).then(lang.hitch(this, function (response) {
        this.setService(response.userData);
        this.setVisibility(response.userData);
      }));
      this.setParams();
      this.handleButtonClick();
    },
    //translates the copy pulled in from this widget's template using content from `data/localization/content.json`
    translateChatButton: function () {
      request.get(mUrl.toUrl("/kb-api/localized-content"), {
        "handleAs": "json"
      }).then(lang.hitch(this, function (response) {
        var locale = response.locale;
        var content = response.content;
        this.chatFormButton.innerHTML = content.chat_with_us[locale];
      }));
    },
    /**
     * Send tracking info to google analytics.
     */
    sendTrackingInfo: function () {
      // default tracking event label to "help:key:chat"
      var event_label = this.tracking.event_label || "help:" + this.referral;
      window.ga("send", "event", this.tracking.tracking_category, "click", event_label);
    },
    /**
     * Opens a new chat window with selected service (currently ada or zendesk)
     */
    openChat: function (endpoint, params) {
      window.open(mUrl.toUrl(endpoint, params), "_blank", "height=700,width=500");
    },
    showChatButton: function () {
      domClass.remove(this.chatFormButton, "js-hide");
    },
    hideChatButton: function () {
      domClass.add(this.chatFormButton, "js-hide");
    },
    /**
     * Return page slug if referrer is https://mailchimp.com/help/
     * @returns {string} KB page slug (mailchimp.com/help/getting-started/ -> getting-started)
     */
    getKbReferrer: function () {
      var kb_referrer = "";
      if (document.referrer.startsWith("https://mailchimp.com/help/")) {
        kb_referrer = document.referrer.replace("https://mailchimp.com/help/", "");
        kb_referrer = kb_referrer.replace(/\/$/, "");
      }
      return kb_referrer;
    },
    /**
     * Sets the service activated by this chat button (currently ada or zendesk)
     *
     * @param userData a property returned from `KbController::getSupportSettingsAction()`
     */
    setService: function (userData) {
      var chat = new Chat();
      this.service = "zendesk";
      return;
    },
    /**
     * Sets the visibility of the chat button
     *
     * @param userData a property returned from `KbController::getSupportSettingsAction()`
     */
    setVisibility: function (userData) {
      var chat = new Chat();
      switch (userData.user_level) {
        case "pfree":
        case "paid":
        case "pro":
          this.showChatButton();
          break;
        case "free":
        case "trial":
          chat.getChatDeptStatus(lang.hitch(this, function (online) {
            if (online) {
              this.showChatButton();
            } else {
              this.hideChatButton();
            }
          }));
          break;
        default:
          this.hideChatButton();
      }
    },
    // add params used for chat service (ada or zendesk)
    setParams: function () {
      this.params.kb_slg = this.getKbReferrer();
      this.params.tg = this.tag;
      this.params.pt = this.department;
      this.params.ref = this.referral;
      this.params.support_key = this.support_key;
    },
    handleButtonClick: function () {
      on(this.chatFormButton, "click", lang.hitch(this, function () {
        if (this.service === "ada") {
          this.openChat("/support/chat-ada", this.params);
        } else {
          this.openChat("/support/chat-form", this.params);
        }
        if (this.tracking) {
          this.sendTrackingInfo();
        }
      }));
    }
  });
});