define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, dom, on, domStyle, domAttr, mUrl, query) {
  return declare([base], {
    monthVal: null,
    yearVal: null,
    onReady: function () {
      var getCurrentMonth = new Date().getMonth() + 1;
      domAttr.set(query("option[value=" + ("0" + getCurrentMonth).slice(-2) + "]")[0], "selected", true);
      this.monthVal = this.$el.monthSelect.value;
      this.yearVal = this.$el.yearSelect.value;
      on(this.$el.monthSelect, "change", lang.hitch(this, function () {
        this.monthVal = this.$el.monthSelect.value;
      }));
      on(this.$el.yearSelect, "change", lang.hitch(this, function () {
        this.yearVal = this.$el.yearSelect.value;
      }));
    },
    /**
     *  emails a discount overview to mcadmin user
     */
    exportOverviewReport: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/export-discounts-overview", {
        discount_month: this.monthVal + "/" + this.yearVal
      });
    },
    /**
     * emails monthly orders to mcadmin user
     */
    exportMonthlyOrdersReport: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/monthly-orders-post", {
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails monthly orders to mcadmin user
     */
    exportMonthlyOrdersReportBigQuery: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/monthly-orders-post", {
        month: this.monthVal,
        year: this.yearVal,
        use_bigquery: true
      });
    },
    /**
     * emails paygo ledger report to mcadmin user
     */
    exportPayGoLedger: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/paygo-ledger", {
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails monthly orders to mcadmin user
     */
    exportPrepayLedger: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/prepay-ledger", {
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails prepay credits to mcadmin user
     */
    exportPrepayCredit: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/prepay-credit", {
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails monthly refunds to mcadmin user
     */
    exportMonthlyRefundsReport: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/monthly-orders-post", {
        refunds_only: "Y",
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails facebook orders to mcadmin user
     */
    exportFBReports: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/fb-export", {
        month: this.monthVal,
        year: this.yearVal
      });
    },
    /**
     * emails monthly unbilled receivables report to mcadmin user
     */
    exportMonthlyUnbilledReceivablesReport: function () {
      document.location = mUrl.toUrl("/peaches2/financial/exports/export-unbilled-receivables", {
        month: this.monthVal,
        year: this.yearVal
      });
    }
  });
});