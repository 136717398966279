define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// kernel.deprecated
"./ContentPane"], function (declare, kernel, ContentPane) {
  // module:
  //		dijit/layout/AccordionPane

  return declare("dijit.layout.AccordionPane", ContentPane, {
    // summary:
    //		Deprecated widget.   Use `dijit/layout/ContentPane` instead.
    // tags:
    //		deprecated

    constructor: function () {
      kernel.deprecated("dijit.layout.AccordionPane deprecated, use ContentPane instead", "", "2.0");
    },
    onSelected: function () {
      // summary:
      //		called when this pane is selected
    }
  });
});