define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (lang) {
  // module:
  //		dojo/data/util/filter
  // summary:
  //		TODOC

  var filter = {};
  lang.setObject("dojo.data.util.filter", filter);
  filter.patternToRegExp = function ( /*String*/pattern, /*boolean?*/ignoreCase) {
    // summary:
    //		Helper function to convert a simple pattern to a regular expression for matching.
    // description:
    //		Returns a regular expression object that conforms to the defined conversion rules.
    //		For example:
    //
    //		- ca*   -> /^ca.*$/
    //		- *ca*  -> /^.*ca.*$/
    //		- *c\*a*  -> /^.*c\*a.*$/
    //		- *c\*a?*  -> /^.*c\*a..*$/
    //
    //		and so on.
    // pattern: string
    //		A simple matching pattern to convert that follows basic rules:
    //
    //		- * Means match anything, so ca* means match anything starting with ca
    //		- ? Means match single character.  So, b?b will match to bob and bab, and so on.
    //		- \ is an escape character.  So for example, \* means do not treat * as a match, but literal character *.
    //
    //		To use a \ as a character in the string, it must be escaped.  So in the pattern it should be
    //		represented by \\ to be treated as an ordinary \ character instead of an escape.
    // ignoreCase:
    //		An optional flag to indicate if the pattern matching should be treated as case-sensitive or not when comparing
    //		By default, it is assumed case sensitive.

    var rxp = "^";
    var c = null;
    for (var i = 0; i < pattern.length; i++) {
      c = pattern.charAt(i);
      switch (c) {
        case '\\':
          rxp += c;
          i++;
          rxp += pattern.charAt(i);
          break;
        case '*':
          rxp += ".*";
          break;
        case '?':
          rxp += ".";
          break;
        case '$':
        case '^':
        case '/':
        case '+':
        case '.':
        case '|':
        case '(':
        case ')':
        case '{':
        case '}':
        case '[':
        case ']':
          rxp += "\\";
        //fallthrough
        default:
          rxp += c;
      }
    }
    rxp += "$";
    if (ignoreCase) {
      return new RegExp(rxp, "mi"); //RegExp
    } else {
      return new RegExp(rxp, "m"); //RegExp
    }
  };
  return filter;
});