define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "dijit/registry", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/paging.html", "mojo/utils/I18nTranslation", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Part, _Templated, lang, number, on, sync, domStyle, domClass, Select, registry, topic, query, tpl, I18nTranslation, domAttr) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    // The minimum amount of rows before we display pagination
    minTotalSize: 10,
    // if set, the node with this id will scroll to the top
    // when changing pages
    container: null,
    ofText: "of",
    postMixInProperties: function () {
      this.of = this.ofText;
      I18nTranslation.initialize("I18N_REPORTING_TRANSLATIONS").then(function (translations) {
        this.of = translations.reporting_of;
      }.bind(this));
    },
    init: function () {
      sync(this.target, "total", this, "total");
      sync(this.target, "page", this, "page");
      sync(this.target, "pageSize", this, "pageSize");
      this.updateAll();
      var self = this;
      on(this.prevButton, "click", function () {
        if (!self.target.refreshing) {
          var page = parseInt(self.get("page"), 10) - 1;
          if (page <= 0) {
            page = 1;
          }
          self.set("page", page);
          self.scrollToTop();
        }
      });
      on(this.nextButton, "click", function () {
        if (!self.target.refreshing) {
          var page = parseInt(self.get("page"), 10) + 1;
          self.set("page", page);
          self.scrollToTop();
        }
      });
      this.watch("page", lang.hitch(this, "_updatePages"));
      this.watch("pageSize", lang.hitch(this, "_updatePages"));
      this.watch("total", lang.hitch(this, "updateAll"));
    },
    scrollToTop: function () {
      if (this.container) {
        var div = query("#" + this.container)[0];
        if (div) {
          div.scrollTop = 0;
        }
      }
    },
    updateAll: function () {
      this._updatePages();
      this._updateTotalPages();
    },
    _updatePages: function () {
      var pageSize = parseInt(this.pageSize);
      var start = parseInt((this.page - 1) * pageSize, 10) + 1;
      var end = start + pageSize - 1;
      this.startLabel.innerHTML = start;
      this.endLabel.innerHTML = end < this.total ? end : this.total;
      domStyle.set(this.prevButton, "display", this.page > 1 ? "inline" : "none");
      domStyle.set(this.nextButton, "display", end < this.total ? "inline" : "none");
    },
    _updateTotalPages: function () {
      this.totalPagesLabel.innerHTML = number.format(this.total, {
        "pattern": "#,###"
      });
    },
    canDisplay: function () {
      return this.inherited(arguments) && this.target.total > this.minTotalSize;
    }
  });
});