define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block", "mojo/context", "mojo/lib/flags"], function (declare, _Block, context, flags) {
  return declare([_Block], {
    type: "productRec",
    _name: "Product Rec",
    _ecommOnly: true,
    _editingTypes: ["campaign", "page"],
    _paidOnly: false,
    _renderMergeTags: true,
    constructor: function () {
      this.styles = [{
        "name": "Product title",
        "selector": ".mcnProductTitle",
        "props": {
          "color": {
            "value": "#202020"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "16px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": "100%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }, {
        "name": "Product price",
        "selector": ".mcnProductPrice",
        "props": {
          "color": {
            "value": ""
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "14px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "normal"
          },
          "line-height": {
            "value": "100%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }, {
        "name": "Button style",
        "selector": ".mcnButtonContentContainer",
        "props": {
          "border": {
            "value": ""
          },
          "border-radius": {
            "value": "3px"
          },
          "background-color": {
            "value": "#2BAADF"
          }
        }
      }, {
        "name": "Button text style",
        "selector": ".mcnButtonContent",
        "props": {
          "color": {
            "value": "#FFFFFF",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "16px"
          },
          "font-weight": {
            "value": "bold",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "letter-spacing": {
            "value": "normal",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "padding": {
            "value": "15px"
          }
        }
      }];

      // vertical || horizontal
      this.layout = "vertical";
      this.numProducts = 2;
      this.startIndex = 0;
      this.storeId = null;
      this.recommendationStrategy = null;

      // Button attributes.
      this.buttonText = "View";
      this.buttonWidth = "100%";
      this.buttonAlign = "center";
      this.buttonLinkType = "product";
      this.titleEnabled = true;
      this.priceEnabled = true;
      this.buttonEnabled = true;
    }
  });
});