/**
 * Top level widget for the product selection/editing UI
 * @module /mojo/widgets/account/connected-sites/ProductsList
 * @see /mojo/widgets/account/connected-sites/ProductsListItem
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/productsList.html", "mojo/widgets/NoDataBlock", "velocity/velocity", "./ProductsListItem"], function (declare, lang, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, Deferred, templateStr, NoDataBlock, Velocity, ProductsListItem) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    sectionContainerNode: null,
    noResultsContainerNode: null,
    noDataBlockNode: null,
    // check to skip initial animation
    // hasRenderedOnce: false,

    /** @type {Platform[]} set by StoreSelectionManager */
    products: null,
    productAction: null,
    _setProductsAttr: function (productsArray) {
      this._renderProductList(productsArray);

      // TODO check if old code is useful
      // if (this.hasRenderedOnce) {
      //     if (isEqual(productsArray, this.products)) {
      //         // platforms list has not changed - no updates to be made
      //         return;
      //     }

      //     // standard update with animation

      // } else {
      //     // initial render - do not animate
      //     this._renderPlatformSections(
      //         productsArray,
      //         this.sectionContainerNode
      //     );
      // }

      // this.set("hasRenderedOnce", true);
      this._set("products", productsArray);
    },
    postCreate: function () {

      //TODO: should no block show up, or go directly to add product
      // new NoDataBlock({
      //     type: "default",
      //     title: "No results",
      //     message: "We couldn't find any integrations matching your search. " +
      //         "<br />" +
      //         "Try <a href='#other'>connecting a custom website</a>, or see our <a target='_blank' href='https://connect.mailchimp.com/'>list of available integrations</a>."
      // }).placeAt(this.noDataBlockNode);
    },
    // TODO need animation for seeing the list of products?
    _fadeIn: function (element) {
      var deferred = new Deferred();
      Velocity(element, "transition.slideUpIn", {
        duration: 100,
        complete: deferred.resolve
      });
      return deferred;
    },
    // TODO need animation for seeing the list of products?
    _fadeOut: function (element) {
      var deferred = new Deferred();
      Velocity(element, "transition.slideDownOut", {
        duration: 100,
        complete: function () {
          return deferred.resolve();
        }
      });
      return deferred;
    },
    /**
     * Iterates this.products to render each product item
     * @param {object[]} products array product data object
     */
    _renderProductList: function (products) {
      // hide current results
      // create products from template and add to the domNode

      // flow from ecomm platform-listing for animating options

      // fade In and Out the List Items?
      // this._fadeOut(this.sectionContainerNode).then(
      //     function () {
      if (products.length === 0) {
        // show no results (empty state)
        this.sectionContainerNode.innerHTML = "<p class=\"font-style--italic small-meta margin-top--lvmargin--lv2 alignc\">No products found.</p>"; // this.noResultsContainerNode.innerHTML;
      } else {
        // render new results into sectionContainerNode
        this.sectionContainerNode.innerHTML = "";
        products.map(function (productItem) {
          var productSelectionObject = lang.clone(productItem);
          var productListItemDetails = {
            onItemAction: this.handleProductAction.bind(this, productSelectionObject),
            productAction: this.productAction,
            productDetails: productItem
          };
          var productItemNode = new ProductsListItem(productListItemDetails);
          productItemNode.placeAt(this.sectionContainerNode);
        }.bind(this));
      }
      // show new results
      // this._fadeIn(this.sectionContainerNode);
      // }.bind(this)
      // );
    },
    /**
     * handleProductAction
     */
    handleProductAction: function () {}
  });
});