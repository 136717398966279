define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'mojo/KeyShortcuts', 'velocity/velocity', 'dojo/NodeList-traverse'], function (array, event, dom, domClass, domStyle, domAttr, on, query, Shortcuts, Velocity) {
  // Global vars
  var doc = document.documentElement;
  var re = /\w*-overlay/;
  var divOverlay;
  var baseNav = {
    // PT 61427158: Disable pointer-events on scroll
    trapEvents: function () {
      var root = document.documentElement,
        timer;
      on(window, "scroll", function () {
        clearTimeout(timer);
        if (!root.style.pointerEvents) {
          root.style.pointerEvents = "none";
        }
        timer = setTimeout(function () {
          root.style.pointerEvents = "";
        }, 125);
      });
    },
    init: function () {
      baseNav.showOverlay();
      baseNav.setupMobileNav();

      // Close Overlay
      query(".close-overlay").on("click", function () {
        divOverlay = re.exec(doc.getAttribute("class"))[0];
        baseNav.hideOverlay(divOverlay);
      });

      // Close overlay on ESC
      Shortcuts.onEsc(function (e) {
        e.stopPropagation();
        divOverlay = re.exec(doc.getAttribute("class"))[0];
        baseNav.hideOverlay(divOverlay);
      });
    },
    setupMobileNav: function () {
      query(".mobile-top-nav").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        domClass.toggle(this, "active");
      });
    },
    showOverlay: function () {
      query(".menu-button").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        baseNav.velocityShowOverlay(query('.phone-overlay-container')[0], 'mobile-overlay');
        baseNav.velocityShowOverlay(query('.tablet-overlay-container')[0], 'mobile-overlay');
        baseNav.setAriaRules("mobile");
      });
    },
    velocityShowOverlay: function (container, overlay) {
      Velocity(container, "stop");
      Velocity(container, {
        opacity: [1, [0.7, 0, 0.175, 1], 0.4],
        zIndex: [100, "easeInOut", -5]
      }, {
        duration: 225,
        visibility: "visible",
        complete: function () {
          domClass.add(doc, overlay + " overflow-hidden");
          if (overlay === "search-overlay") {
            dom.byId("global-search").focus();
          }
        }
      });
    },
    hideOverlay: function (divOverlay) {
      // Hide overlay
      if (divOverlay === "mobile-overlay") {
        baseNav.velocityHideOverlay(query(".phone-overlay-container")[0]);
        baseNav.velocityHideOverlay(query(".tablet-overlay-container")[0]);
      } else {
        baseNav.velocityHideOverlay(query("." + divOverlay + "-container")[0]);
      }

      // Set ARIA hidden rules to true
      baseNav.setAriaRules();
    },
    velocityHideOverlay: function (container) {
      Velocity(container, "stop");
      Velocity(container, {
        opacity: [0.4, [0.7, 0, 0.175, -0.3], 1],
        zIndex: [-5, "easeInOut", 100]
      }, {
        duration: 225,
        visibility: "hidden",
        complete: function () {
          domClass.replace(doc, "", divOverlay + " overflow-hidden");
        }
      });
    },
    setAriaRules: function (flag) {
      switch (flag) {
        case "mobile":
          domAttr.set(query(".phone-overlay-container")[0], "aria-hidden", "false");
          domAttr.set(query(".tablet-overlay-container")[0], "aria-hidden", "false");
          domAttr.set(query(".main-navigation")[0], "aria-hidden", "true");
          break;
        default:
          domAttr.set(query(".phone-overlay-container")[0], "aria-hidden", "true");
          domAttr.set(query(".tablet-overlay-container")[0], "aria-hidden", "true");
          domAttr.set(query(".main-navigation")[0], "aria-hidden", "false");
          break;
      }
    }
  }; // NAV OBJECT

  return baseNav;
});