define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/AbandonedBrowseEditor/editor.html", "mojo/neapolitan/editors/_DynamicContentMixin", "mojo/neapolitan/widgets/EditorContainer"], function (declare, _Base, query, domClass, domAttr, domConstruct, lang, array, on, tpl, _DynamicContentMixin) {
  // Default values of the button texts and we swap them if a user didn't touch them.
  var VIEW_PRODUCT_BTN_TEXT = "View";
  var CART_BTN_TEXT = "Buy now";
  return declare([_Base, _DynamicContentMixin], {
    type: "abandonedBrowse",
    templateString: tpl,
    postCreate: function () {
      this.inherited(arguments);
      this.sync("buttonText", this.buttonTextInput, "value");
      this.sync("buttonAlign", this.buttonAlignInput, "value");
      this.sync("buttonWidth", this.buttonWidthInput, "value");
      this.sync("priceEnabled", this.priceToggle, "checked");
      this.sync("buttonEnabled", this.buttonToggle, "checked");
      this.watch("buttonEnabled", lang.hitch(this, "_toggleButtonSettings"));
      this._toggleButtonSettings();
      this.sync("buttonLinkType", this.buttonLinkTypeSelectInput, "value");
      this.watch("buttonLinkType", lang.hitch(this, "_updateDefaultButtonText"));
    },
    _toggleButtonSettings: function () {
      domClass.toggle(this.buttonSettingsContainer, "hide", !this.block.buttonEnabled);
    },
    _updateDefaultButtonText: function () {
      var currentText = this.block.buttonText || "";
      var buttonLinkType = this.block.buttonLinkType;
      if (buttonLinkType === "cart" && (!currentText || currentText.trim() === VIEW_PRODUCT_BTN_TEXT)) {
        this.buttonTextInput.set("value", CART_BTN_TEXT);
      } else if (buttonLinkType === "product" && (!currentText || currentText.trim() === CART_BTN_TEXT)) {
        this.buttonTextInput.set("value", VIEW_PRODUCT_BTN_TEXT);
      }
    }
  });
});