define([], function () {
  return {
    TYPES: {
      mailchimp: 0,
      facebookPixel: 1,
      googleAnalytics: 2,
      ccpa: 3,
      fbccpa: 4
    },
    DATA_NAMES: {
      facebookPixel: "fb_pixel",
      googleAnalyticsTrackingId: "ga_tracking_id",
      hasAdsAccountPixel: "has_ads_account_pixel"
    }
  };
});