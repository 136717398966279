define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo, lang) {
  // module:
  //		dojo/cldr/monetary

  var monetary = {
    // summary:
    //		TODOC
  };
  lang.setObject("dojo.cldr.monetary", monetary);
  monetary.getData = function ( /*String*/code) {
    // summary:
    //		A mapping of currency code to currency-specific formatting information. Returns a unique object with properties: places, round.
    // code:
    //		an [ISO 4217](http://en.wikipedia.org/wiki/ISO_4217) currency code

    // from http://www.unicode.org/cldr/data/common/supplemental/supplementalData.xml:supplementalData/currencyData/fractions

    var placesData = {
      ADP: 0,
      AFN: 0,
      ALL: 0,
      AMD: 0,
      BHD: 3,
      BIF: 0,
      BYR: 0,
      CLF: 0,
      CLP: 0,
      COP: 0,
      CRC: 0,
      DJF: 0,
      ESP: 0,
      GNF: 0,
      GYD: 0,
      HUF: 0,
      IDR: 0,
      IQD: 0,
      IRR: 3,
      ISK: 0,
      ITL: 0,
      JOD: 3,
      JPY: 0,
      KMF: 0,
      KPW: 0,
      KRW: 0,
      KWD: 3,
      LAK: 0,
      LBP: 0,
      LUF: 0,
      LYD: 3,
      MGA: 0,
      MGF: 0,
      MMK: 0,
      MNT: 0,
      MRO: 0,
      MUR: 0,
      OMR: 3,
      PKR: 0,
      PYG: 0,
      RSD: 0,
      RWF: 0,
      SLL: 0,
      SOS: 0,
      STD: 0,
      SYP: 0,
      TMM: 0,
      TND: 3,
      TRL: 0,
      TZS: 0,
      UGX: 0,
      UZS: 0,
      VND: 0,
      VUV: 0,
      XAF: 0,
      XOF: 0,
      XPF: 0,
      YER: 0,
      ZMK: 0,
      ZWD: 0
    };
    var roundingData = {};
    var places = placesData[code],
      round = roundingData[code];
    if (typeof places == "undefined") {
      places = 2;
    }
    if (typeof round == "undefined") {
      round = 0;
    }
    return {
      places: places,
      round: round
    }; // Object
  };
  return monetary;
});