/**
 * Static Facebook Ad Checklist Module
 * @module mojo/app/placards/checklist/_ChecklistMixin.js
 *
 * This module is loaded on the various ad checklists and should be the home of common methods.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/utils",
// Mixins
"mojo/views/_base",
// Variable not required
"dojo/NodeList-traverse"], function (declare, domForm, query, request, utils, _base) {
  return declare([_base], {
    SLAT_ID: {
      CONNECT: "connect",
      AUDIENCE: "audience",
      BUDGET: "budget",
      CONTENT: "content"
    },
    SLAT_MODE: {
      VIEW: "view",
      EDIT: "edit"
    },
    saveForm: function (form, callback, errorCallback) {
      // we should reset the form error indicators
      query("input", form).forEach(function (input) {
        utils.removeErrors(input);
      });
      request.post(form.action, {
        data: domForm.toObject(form),
        handleAs: "json"
      }).then(function (data) {
        switch (data.status) {
          case "success":
            if (callback) {
              callback();
            }
            break;
          case "error":
            // if the user had errors on their form, show them and do not switch
            if (data.errors) {
              utils.showFormErrors(form, data.errors);
            }
            if (errorCallback) {
              errorCallback();
            }
            break;
          default:
            throw new Error("Unknown state " + data.status);
        }
      });
    },
    /**
     * Handles removing url wrapper from string and potential quotation marks.
     *
     * @param {String} url - Image URL to be cleaned
     * @return {String} url - Image URL post-cleaning - w/o url("")
     */
    cleanImageURL: function (url) {
      // Some browsers (Safari/Edge) format url's as url("https://...") and some format as url(https://)
      // Removing all double quotes makes the strings the same in all browsers
      url = url.replace(/['"]+/g, "");

      // Remove the url() that wraps around the string
      url = url.slice(4, -1);
      return url;
    }
  });
});