/**
 * A group of buttons that allows only one button to be toggled.
 * This pattern is used primarily in wizards
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/availableFieldInput.html", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, lang, domConstruct, on, _TemplatedMixin, _WidgetBase, _WidgetsInTemplateMixin, tpl, flags, context, I18nTranslation) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    isSelected: false,
    isDisabled: false,
    isDefault: false,
    hasRequiredToggle: false,
    smsAudience: false,
    numSelectedChannels: 0,
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        this.emailOrSmsNotOptional = I18nTranslation.translate("landing_pages_email_or_sms_not_optional");
      }
    },
    postCreate: function () {
      var self = this;
      if (!this.hasRequiredToggle || !this.isSelected) {
        // remove unused dndHandle and require switch
        domConstruct.destroy(this.requiredSwitchContainer);
      }
      if (!this.isSelected) {
        domConstruct.destroy(this.dndHandle);
      }
      if (!this.isGroup) {
        domConstruct.destroy(this.groupLabel);
      }
      if (this.isSelected && this.isDisabled) {
        if (!window.elcIsEnabled) {
          domConstruct.destroy(this.dndHandle);
        }
        if (this.numSelectedChannels < 2) {
          this.selectCheckbox.set("disabled", true);
        }
      }
      this.selectCheckbox.onChange = lang.hitch(this, this.onLocalSelectedChange);
      this.requiredSwitch.onChange = lang.hitch(this, this.onLocalRequiredChange);
      if (window.elcIsEnabled) {
        if (this.isDisabled && this.smsAudience) {
          // eslint-disable-next-line
          dojo.setStyle(this.emailOrSmsNotOptional, "display", "block");
        }
      }
    },
    onLocalRequiredChange: function (checked) {
      this.onRequiredChange(this.params.model._postKeyId, checked);
    },
    onLocalSelectedChange: function (checked) {
      this.onChange(this.model, checked);
    },
    // to be passed as params
    onChange: function (model, selectedValue) {},
    onRequiredChange: function (model) {}
  });
});