define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "./_EditViewMixin", "./_ConditionRowMixin", "./FieldConfig", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/nestedConditionRow.html", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, _EditViewMixin, _ConditionRowMixin, FieldConfig, domClass, domConstruct, array, lang, on, tpl, flags, context) {
  /**
   * @module mojo/segmentation
   * @class NestedConditionRow
   *
   * mojo/segmentation/ConditionRow imposes additional lifecycle methods on this widget. Specifically:
   * - this class is constructed with a `fieldConfig`, which defines the kind of field this UI element will embody
   * - but, for the details of what particular `operator`, `value`, and `extra` are given to this widget via `fromAST`
   * - there's also two commands this widget must implement: viewMode() and editMode()
   */
  return declare([_WidgetBase, _TemplatedMixin, _EditViewMixin, _ConditionRowMixin], {
    changeStateOnFocus: false,
    templateString: tpl,
    valueInput: null,
    configs: null,
    fieldConfig: null,
    // Look-up map
    opsMap: null,
    ast: null,
    postMixInProperties: function () {
      if (!this.configs) {
        throw new Error("configs must be set for a condition row.");
      }
      if (!this.fieldConfig) {
        throw new Error("Cannot create a nested condition without a fieldConfig attribute");
      }

      // Build the lookup map for each op value.
      this.opsMap = {};
      array.forEach(this.fieldConfig.ops, lang.hitch(this, function (op) {
        this.opsMap[op.value] = op;
      }));
    },
    postCreate: function () {
      this.renderOpInput();
      this.renderValueInput();
      this.renderExtraInput();
      if (this.valueInput) {
        this.valueInput.watch("value", function () {
          this.set("ast", this.getAST());
        }.bind(this));
      }
    },
    /**
     * @returns {mojo/segmentation/FieldConfig} Field configuration for the row being displayed
     */
    getFieldConfig: function () {
      return new FieldConfig(this.fieldConfig);
    },
    getOpConfig: function () {
      return this.opsMap[this.op];
    },
    onViewMode: function () {
      this._updateLabels();
      domClass.remove(this.viewContainer, "hide");
      domClass.add(this.editContainer, "hide");
    },
    onEditMode: function () {
      domClass.add(this.viewContainer, "hide");
      domClass.remove(this.editContainer, "hide");
    },
    _updateLabels: function () {
      this.opLabel.innerHTML = this.getOpHTML();
      this.valueLabel.innerHTML = this.getValueHTML();
      this.extraLabel.innerHTML = this.getExtraHTML();
      domClass.toggle(this.valueLabelContainer, "hide", !this.valueInput);
      var position = this.getFieldConfig().displaysNameBeforeOperator(this.op) ? "before" : "after";
      domConstruct.place(this.valueLabelContainer, this.opLabelContainer, position);
    },
    fromAST: function (ast) {
      this.opSelect.set("value", ast.op);
      if (this.valueInput) {
        if (context.flagIsOn("segmentation.cub_custom_engagement_text_input") && ast.field === "contact_event_timeframe" && ast.op === "date_within") {
          var newValue;
          switch (ast.value) {
            case "last1day":
              newValue = 1;
              break;
            case "last7day":
              newValue = 7;
              break;
            case "last1month":
              newValue = 30;
              break;
            default:
              newValue = ast.value;
              break;
          }
          this.valueInput.numberInput.constraints.max = 180;
          this.valueInput.numberInput.constraints.min = 1;
          this.valueInput.setValue(newValue);
        } else {
          this.valueInput.setValue(ast.value);
        }
        this.valueInput.watch("value", function () {
          this.set("ast", this.getAST());
        }.bind(this));
        if (ast.value_op) {
          this.valueInput.setValueOp(ast.value_op);
        }
      }
      if (ast.extra) {
        if (this.extraInput) {
          this.extraInput.setValue(ast.extra);
        } else if (this.valueInput) {
          this.valueInput.setExtra(ast.extra);
        }
      }
    }
  });
});