define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags"], function (declare, user, flags) {
  var CampaignRenderingTypes = {
    PAGE: "page"
  };
  return declare(null, {
    _getButtonStyles: function (campaignRenderingType) {
      if (campaignRenderingType === CampaignRenderingTypes.PAGE) {
        // Specific styling for the Button outputWeb template for Landing Pages
        return [{
          "name": "Button style",
          "selector": ".mcnButtonContentContainer",
          "props": {
            "border": {
              "value": ""
            },
            "border-radius": {
              "value": "4px",
              "unit": "px",
              "minimum": 0,
              "maximum": 50,
              "wholeNumbersOnly": true
            },
            "background-color": {
              "value": "#2BAADF"
            },
            "box-shadow": {
              "value": "none"
            },
            "padding-top": {
              "value": "18px"
            },
            "padding-left": {
              "value": "18px"
            },
            "padding-bottom": {
              "value": "18px"
            },
            "padding-right": {
              "value": "18px"
            }
          }
        }, {
          "name": "Button text style",
          "selector": ".mcnButton",
          "props": {
            "color": {
              "value": "#FFFFFF",
              "selector": ".mcnButton"
            },
            "font-family": {
              "value": "Arial"
            },
            "font-size": {
              "value": "16px"
            },
            "font-weight": {
              "value": "bold"
            },
            "letter-spacing": {
              "value": "normal"
            }
          }
        }];
      }
      return [{
        "name": "Button style",
        "selector": ".mcnButtonContentContainer",
        "props": {
          "border": {
            "value": ""
          },
          "border-radius": {
            "value": "4px",
            "unit": "px",
            "minimum": 0,
            "maximum": 50,
            "wholeNumbersOnly": true
          },
          "background-color": {
            "value": "#2BAADF"
          }
        }
      }, {
        "name": "Button text style",
        "selector": ".mcnButtonContent",
        "props": {
          "color": {
            "value": "#FFFFFF",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "font-family": {
            "value": "Arial"
          },
          "font-size": {
            "value": "16px"
          },
          "font-weight": {
            "value": "bold",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "letter-spacing": {
            "value": "normal",
            "selector": ".mcnButtonContent .mcnButton"
          },
          "padding": {
            "value": "18px"
          }
        }
      }];
    }
  });
});