/**
 * Module that helps create and encapsulate a prompt to the AI assistant. A prompt contains all the metadata
 * for the ask and the response associated with said ask.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/genai/api"], function (declare, Deferred, genaiAPI) {
  var createType = "create";
  var refineType = "refine";
  var commandTypes = {
    freeform: "freeform",
    shorten: "shorten",
    lengthen: "lengthen",
    changeTone: "changeTone",
    fixSpelling: "fixSpelling"
  };
  var responseStatuses = {
    success: "success",
    failure: "failure"
  };

  // List of known errors
  var responseErrors = {
    unsupportedLanguage: "The input text was determined to not be in a supported language",
    generic: "There was a problem with your request. Please try again."
  };

  /**
   * Basic class through dojo that will store all the metadata and also parse out a response status and content
   * from the API requests.
   *
   * Will not be directly exposed through this module and instead will be created through the helper functions.
   */
  var Prompt = declare(null, {
    // create/refine
    type: null,
    // See commandTypes for valid values
    command: null,
    // Object of params specific for a command type
    commandParams: {},
    // Source text to use for refinement
    sourceText: null,
    // tone used for creation
    tone: null,
    // Response status. Gets set after exec() is called
    responseStatus: null,
    // Response content of the AI Assist. Gets set after exec() is called
    responseContent: null,
    // Response error message that can be used to identify the type of error. Not for user display
    responseError: null,
    // Keeps a reference to the deferred used for exec() to run only once.
    _execDeferred: null,
    constructor: function (args) {
      declare.safeMixin(this, args);
    },
    exec: function () {
      if (!this._execDeferred) {
        this._execDeferred = new Deferred();
        var promptText = this.getPromptText();
        switch (this.type) {
          case createType:
            genaiAPI.generateParagraph(promptText, this.tone).then(this._handleAPIResponse.bind(this), this._handleApiErrorResponse.bind(this));
            break;
          case refineType:
            genaiAPI.refineParagraph(this.sourceText, promptText).then(this._handleAPIResponse.bind(this), this._handleApiErrorResponse.bind(this));
            break;
          default:
            throw new Error("Unknown type of prompt: " + this.type);
        }
      }
      return this._execDeferred.promise;
    },
    /**
     * Handle API responses when it's a non-200 response.
     *
     * @param {Object} errorContext error context object that gets returned by failure xhr request from dojo
     *
     * @private
     */
    _handleApiErrorResponse: function (errorContext) {
      this.responseStatus = responseStatuses.failure;
      if (errorContext.response && errorContext.response.data) {
        this.responseError = errorContext.response.data.msg;
      }
      this._execDeferred.resolve(this);
    },
    /**
     * Handle API response when it's a 200 response.
     *
     * @param {Object} response - json response from the API
     *
     * @private
     */
    _handleAPIResponse: function (response) {
      if (response.status === "error") {
        this.responseError = response.msg;
        this.responseStatus = responseStatuses.failure;
      } else {
        this.responseContent = response.content;
        this.responseStatus = responseStatuses.success;
        this.intuit_tid = response.intuit_tid;
      }
      this._execDeferred.resolve(this);
    },
    getPromptText: function () {
      switch (this.command) {
        case commandTypes.freeform:
          return this.commandParams.text;
        case commandTypes.shorten:
          return "Condense the text to convey the same meaning using fewer words.";
        case commandTypes.lengthen:
          return "Elaborate on the text without compromising its accuracy - keep the output length at a reasonable and concise level. Output at an 8th grade reading level.";
        case commandTypes.changeTone:
          return "Change tone to " + this.commandParams.tone;
        case commandTypes.fixSpelling:
          return "Correct the spelling and grammar of this text but do not include this instruction in the output.";
        default:
          throw new Error("Unknown prompt command type: " + this.command);
      }
    },
    getTone: function () {
      if (this.type === createType) {
        return this.tone;
      } else if (this.command === commandTypes.changeTone) {
        return this.commandParams.tone;
      }
      return "";
    }
  });
  return {
    responseStatuses: responseStatuses,
    responseErrors: responseErrors,
    commandTypes: commandTypes,
    create: function (createText) {
      return {
        withTone: function (tone) {
          return new Prompt({
            type: createType,
            tone: tone,
            command: commandTypes.freeform,
            commandParams: {
              "text": createText
            }
          });
        }
      };
    },
    refine: function (sourceText) {
      return {
        changeTone: function (tone) {
          return new Prompt({
            type: refineType,
            sourceText: sourceText,
            command: commandTypes.changeTone,
            commandParams: {
              "tone": tone
            }
          });
        },
        withFreeformCommand: function (text) {
          return new Prompt({
            type: refineType,
            sourceText: sourceText,
            command: commandTypes.freeform,
            commandParams: {
              "text": text
            }
          });
        },
        shorten: function () {
          return new Prompt({
            type: refineType,
            sourceText: sourceText,
            command: commandTypes.shorten
          });
        },
        lengthen: function () {
          return new Prompt({
            type: refineType,
            sourceText: sourceText,
            command: commandTypes.lengthen
          });
        },
        fixSpelling: function () {
          return new Prompt({
            type: refineType,
            sourceText: sourceText,
            command: commandTypes.fixSpelling
          });
        }
      };
    },
    /**
     * Take an existing Prompt instance and clones it to be re-used again
     *
     * @param {Object} prompt an instance of the Prompt object in this module
     * @returns {Object} a new prompt instance
     */
    clone: function (prompt) {
      return new Prompt({
        type: prompt.type,
        sourceText: prompt.sourceText,
        command: prompt.command,
        commandParams: prompt.commandParams
      });
    }
  };
});