define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/DropDownMenu", "dijit/MenuItem", "dijit/form/ComboButton", "mojo/widgets/Clock", "mojo/analytics/parts/SelectCheckBox", "mojo/url", "mojo/utils", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/api/outreach", "mojo/app/campaigns", "dojo/text!./templates/Slat.html", "dojo/text!./templates/SlatCondensed.html", "mojo/utils/I18nTranslation", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, dom, domClass, domAttr, on, lang, locale, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, DropDownMenu, MenuItem, ComboButton, Clock, SelectCheckBox, mUrl, utils, user, context, flags, outreachApi, campaigns, campaignSlatTpl, campaignSlatCondensedTpl, I18nTranslation, EcsTracking, EcsEvents) {
  var CAMPAIGN_LIST_LOCATION_REPORTS = "reports";
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    /*
        Universal Slat Template
    */
    templateString: campaignSlatTpl,
    /*
        Data Attributes Returned from API
    */
    id: 0,
    title: "",
    //name of data item
    status: "",
    //status of data item, mapped to statusContent in child class
    listName: "",
    //name of list or store used by data item
    type: "",
    //type of data item, mapped to typeContent in child class
    channelIcon: "",
    //icon class applied to channel icon
    stats: {
      //"attribute": {value: 0, percent: 0}
    },
    /*
        Config Attributes That Should Be Overridden in Child Classes
    */
    actions: {
      //"action": {
      //  label: "Human-readable action name"
      //  href: "Optional. Template for link href"
      //  onClick: Optional function to run when click occurs, bound to slat instance.
      //}
    },
    statusContent: {
      //"status": {
      //  label: "Human-readable name for status"
      //}
    },
    typeContent: {
      //"type": {
      //  label: "Human-readable name for type"
      //}
    },
    statsOrder: [
      //"subscribers", "opens", "clicks"
    ],
    statsContent: {
      //"status": {
      //  label: "Human-readable stat name"
      //}
    },
    translationObject: {},
    constructor: function (properties) {
      this.model = properties.model;
      this.title = this.model.title;
      this.mode = properties.mode;
      this.campaignListLocation = this.model.campaignListLocation;
    },
    _getSlatPosition: function (dataSelector, href) {
      var selector = "[data-target='" + dataSelector + "']";
      var position = null;
      var slats = document.querySelectorAll(selector);
      for (var i = 0; i < slats.length; i++) {
        if (slats[i].href === href) {
          position = i + 1;
          i = slats.length; //if we find a match exit loop
        }
      }
      return position;
    },
    _buildActionDropDown: function () {
      var availableActions = this._isReportsPage() ? this.model.availableReportActions : this.model.availableActions;
      if (availableActions && availableActions.length > 0) {
        var dropDown;
        if (availableActions.length > 1) {
          dropDown = new DropDownMenu({
            "class": "mc-dropdown"
          });
          availableActions.forEach(lang.hitch(this, function (action) {
            var key = action.label;
            if (action && !action.is_primary) {
              var params = this._generateButtonParams(action, this.actions, this.model.type, dropDown, this.model.availableReportActions);
              var item = new MenuItem(params);
              dropDown.addChild(item);
            }
          }));
        }
        var primaryAction = this._getPrimaryAction(availableActions);
        if (primaryAction) {
          var params = this._generateButtonParams(primaryAction, this.actions, this.model.type, dropDown, this.model.availableReportActions);
          var button = new ComboButton(params);
          domClass.add(button.domNode, "c-slatButton button-small");
          domClass.toggle(button.domNode, "c-campaignManager_slat_actions_single", !dropDown);
          button.placeAt(this.node_actions, "replace");
        }
      }
    },
    _buildActionDropDownTranslated: function () {
      var availableActions = this._isReportsPage() ? this.model.availableReportActions : this.model.availableActions;
      if (availableActions && availableActions.length > 0) {
        var dropDown;
        if (availableActions.length > 1) {
          dropDown = new DropDownMenu({
            "class": "mc-dropdown"
          });
          availableActions.forEach(lang.hitch(this, function (action) {
            var key = action.label;
            if (action && !action.is_primary) {
              var params = this._generateButtonParams(action, this.actionsTranslated, this.model.type, dropDown, this.model.availableReportActions);
              var item = new MenuItem(params);
              dropDown.addChild(item);
            }
          }));
        }
        var primaryAction = this._getPrimaryAction(availableActions);
        if (primaryAction) {
          var params = this._generateButtonParams(primaryAction, this.actionsTranslated, this.model.type, dropDown, this.model.availableReportActions);
          var button = new ComboButton(params);
          domClass.add(button.domNode, "c-slatButton button-small");
          domClass.toggle(button.domNode, "c-campaignManager_slat_actions_single", !dropDown);
          button.placeAt(this.node_actions, "replace");
        }
      }
    },
    // Difference between action and actionsList[key]:
    // Action receives the label used as a key for accessing the actionList entry, the is_primary boolean and the href.
    // actionList[key] receives the action with the rendered action label as well as the actual onclick function.
    // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.

    _generateButtonParams: function (action, actionList, campaignType, dropDownChildren, modelActions) {
      var key = action.label;
      var params = {
        label: this._generateLabelHTML(action, actionList[key])
      };
      if (actionList[key].onMouseUp) {
        var handleMouseUp = function (event) {
          if (event.target.className.includes("dijitArrowButtonInner")) {
            // This handles GA calls for clicking the dropdown menu open arrow.
            actionList[key].onMouseUp("dropdown menu open", "reports menu", {
              dimension56: "dropdown menu open"
            });
          } else if (action.is_primary) {
            // This handles GA calls for clicking the primary action on the reports side button.
            actionList[key].onMouseUp("view report", "campaigns", {
              dimension53: actionList[key].label,
              dimension54: action.href,
              dimension55: this._getSlatPosition("view-report-button", action.href),
              dimension56: actionList[key].label + " for a " + campaignType + " campaign"
            });
          } else {
            // This handles GA calls for clicking one of the dropdown menu options
            actionList[key].onMouseUp("dropdown menu select", actionList[key].label, {
              dimension53: actionList[key].label,
              dimension54: action.href ? action.href : null,
              dimension55: modelActions.indexOf(action),
              dimension56: "dropdown menu select"
            });
          }
        };
        params.onMouseUp = handleMouseUp.bind(this);
      }
      if (!action.href && actionList[key].onClick && !action.is_disabled) {
        params.onClick = actionList[key].onClick.bind(this);
      }
      if (action.is_primary) {
        params.dropDown = dropDownChildren;
      }
      if (action.is_disabled) {
        params.class = "dijitMenuItemDisabledCursor";
      }
      return params;
    },
    _generateTitleAnalytics: function (node, onMouseUp, label, href, campaignType) {
      var handleMouseUp = function () {
        onMouseUp("view report", "campaigns", {
          dimension53: label,
          dimension54: href,
          dimension55: this._getSlatPosition("campaign-link", href),
          dimension56: "View report for a " + campaignType + " campaign"
        });
      };
      on(node, "mouseup", handleMouseUp.bind(this));
    },
    _buildTypeName: function () {
      var type = this.model.type;
      this.node_typeName.innerHTML = this.typeContent[type] ? this.typeContent[type].label : "";
    },
    _buildTypeNameTranslated: function () {
      var type = this.model.type;
      this.node_typeName.innerHTML = this.typeContentTranslated[type] ? this.typeContentTranslated[type].label : "";
    },
    _buildEditorType: function () {
      // Display an editor type badge for users
      if (this.model.editorType && this.node_badge_editorType) {
        this.node_badge_editorType.innerHTML = this.model.editorType;
        domClass.remove(this.node_badge_editorType, "display--none");
      }
    },
    _buildStatusBadge: function () {
      var status = this.statusContent[this.model.status];
      if (status) {
        this.node_badge.innerHTML = status.label;
        domClass.add(this.node_badge, status.badge);
      }
    },
    _buildStatusBadgeTranslated: function () {
      var status = this.statusContentTranslated[this.model.status];
      if (status) {
        this.node_badge.innerHTML = status.label;
        domClass.add(this.node_badge, status.badge);
      }
    },
    _buildStats: function () {
      var stats = this.model.stats;
      if (stats) {
        this.statsOrder.forEach(lang.hitch(this, function (stat) {
          var statValues = stats[stat];
          var valueTypes = statValues ? Object.keys(statValues) : [];
          var statName = this.statsContent[stat] ? this.statsContent[stat].label : "";
          var statWrapper = utils.compileTemplate(this.template_statWrapper, {});
          valueTypes.forEach(lang.hitch(this, function (valueType) {
            var isPercent = valueType === "percent";
            var value = statValues[valueType];
            var automationId = this.model.automationId;
            var statDetails = utils.compileTemplate(!!automationId && value > 0 ? this.template_statDetailsWithLink : this.template_statDetails, {
              type: valueTypes.length > 1 ? "c-campaignManager_slat_stat_" + (isPercent ? "primary" : "secondary") : "",
              value: (Number(value) ? utils.numberFormat(value, isPercent ? 1 : 0, ".", ",") : value) + (isPercent && value !== "N/A" ? "%" : ""),
              name: statName,
              href: mUrl.toUrl("/reports/summary?id=" + automationId)
            });
            statWrapper.appendChild(statDetails);
          }));
          this.node_stats.appendChild(statWrapper);
        }));
      }
    },
    _buildStatsTranslated: function () {
      var stats = this.model.stats;
      if (stats) {
        this.statsOrder.forEach(lang.hitch(this, function (stat) {
          var statValues = stats[stat];
          var valueTypes = statValues ? Object.keys(statValues) : [];
          var statName = this.statsContentTranslated[stat] ? this.statsContentTranslated[stat].label : "";
          var statWrapper = utils.compileTemplate(this.template_statWrapper, {});
          valueTypes.forEach(lang.hitch(this, function (valueType) {
            var isPercent = valueType === "percent";
            var value = statValues[valueType];
            var automationId = this.model.automationId;
            var statDetails = utils.compileTemplate(!!automationId && value > 0 ? this.template_statDetailsWithLink : this.template_statDetails, {
              type: valueTypes.length > 1 ? "c-campaignManager_slat_stat_" + (isPercent ? "primary" : "secondary") : "",
              value: (Number(value) ? utils.numberFormat(value, isPercent ? 1 : 0, ".", ",") : value) + (isPercent && value !== "N/A" ? "%" : ""),
              name: statName,
              href: mUrl.toUrl("/reports/summary?id=" + automationId)
            });
            statWrapper.appendChild(statDetails);
          }));
          this.node_stats.appendChild(statWrapper);
        }));
      }
    },
    _buildCheckbox: function () {
      var type = this.model.type;
      switch (type) {
        case "social":
          type = "Social posts";
          break;
        case "page":
          type = "Landing pages";
          break;
        case "postcard":
          type = "Postcards";
          break;
        case "automation":
          type = "Automations";
          break;
        case "facebook":
          type = "Ads";
          break;
        case "google":
          type = "Ads";
          break;
        case "survey":
          type = "surveys";
          break;
        default:
          type = "Email";
          break;
      }
      var checkbox = new SelectCheckBox({
        value: this.model.uniqueId
      });
      checkbox.placeAt(this.node_selectCheckBox, "replace");
      on(checkbox, "click", lang.hitch(this, function () {
        EcsTracking.track(EcsEvents.AllCampaignsBulkActionCheckBoxEvent, {
          touchpoint_type: type
        });
      }));
    },
    /*
        Make the title of each slat work like a link that does the same thing as the first item in the list of
        available actions, unless the titleLink property is available from the model.
     */

    _buildTitleAction: function () {
      var link = this.node_titleLink;
      var defaultAction = this._getPrimaryAction(this.model.availableActions);
      var key = defaultAction.label;
      var hrefTemplate = this._isReportsPage() ? defaultAction.href : this.model.titleLink || defaultAction.href;
      if (hrefTemplate) {
        domAttr.set(link, "href", hrefTemplate);
      } else {
        on(link, "click", this.actions[key].onClick.bind(this));
      }
      if (this.actions[key].onMouseUp) {
        this._generateTitleAnalytics(link, this.actions[key].onMouseUp, link.innerHTML, hrefTemplate, this.model.type);
      }
    },
    _buildTitleActionTranslated: function () {
      var link = this.node_titleLink;
      var defaultAction = this._getPrimaryAction(this.model.availableActions);
      var key = defaultAction.label;
      var hrefTemplate = this._isReportsPage() ? defaultAction.href : this.model.titleLink || defaultAction.href;
      var ECSTrackTitleClick = function () {
        this._ECSTrackingEvents();
      };
      if (hrefTemplate) {
        domAttr.set(link, "href", hrefTemplate);
      } else {
        on(link, "click", this.actionsTranslated[key].onClick.bind(this));
      }
      if (this.actionsTranslated[key].onMouseUp) {
        this._generateTitleAnalytics(link, this.actionsTranslated[key].onMouseUp, link.innerHTML, hrefTemplate, this.model.type);
      }
      on(link, "mousedown", ECSTrackTitleClick.bind(this));
    },
    /*
     * Displays a compliance message in the slat if the campaign has an issue
     */
    _buildComplianceMessage: function () {
      if (this.model.shouldShowComplianceMessage) {
        this.node_complianceMessage.appendChild(utils.compileTemplate(this.template_complianceMessage, {
          message: "There is a compliance issue with this campaign."
        }));
      } else if (this.model.shouldShowTermsMessage) {
        this.node_complianceMessage.appendChild(utils.compileTemplate(this.template_complianceMessage, {
          message: "Terms of Service haven't been accepted."
        }));
      } else if (this.model.shouldShowDisconnectMessage) {
        this.node_complianceMessage.appendChild(utils.compileTemplate(this.template_complianceMessage, {
          message: "This site has been disconnected."
        }));
      }
    },
    /*
     * Displays a general error message in the slat if the campaign didn't send
     */
    _buildSendingErrorMessage: function () {
      if (this.model.shouldShowSendErrorMessage) {
        this.node_sendErrorMessage.appendChild(utils.compileTemplate(this.template_sendErrorMessage, {
          message: "We were unable to send this campaign."
        }));
      }
    },
    _ECSTrackingEvents: function () {
      var modelType = this.model.type;
      var touchpointType = null;
      switch (modelType) {
        case "automation":
          touchpointType = "Automations";
          break;
        case "page":
          touchpointType = "Landing pages";
          break;
        case "google":
        case "facebook":
          touchpointType = "Ads";
          break;
        case "postcard":
          touchpointType = "Postcards";
          break;
        case "social":
          touchpointType = "Social posts";
          break;
        case "survey":
          touchpointType = "surveys";
          break;
        default:
          touchpointType = "Email";
          break;
      }
      campaigns.trackSlat("touchpoint_link_click", true, touchpointType);
    },
    _showNdMessagingIfNeeded: function () {
      // only show for ND users
      if (!this.model.hasNdStore) {
        return;
      }
      var ndMessage = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
        templateString: "<div>\
                        <span class='freddicon warn-circle warn-circle-color padding-right--lv1'></span>\
                        <span data-dojo-attach-point='messageNode'></span>\
                        <a data-dojo-type='mojo/widgets/account/connected-sites/nd/NdResourceLink'></a>\
                    </div>",
        message: "",
        _setMessageAttr: {
          node: "messageNode",
          type: "innerText"
        }
      });
      var errorMessage = null;
      if (this.model.type === "automation") {
        if (user.flagIsOn(flags.ND_CIRCUIT_BREAKER_DISCONNECT) && this.model.status === "sending") {
          errorMessage = "Automations triggered from Shopify will be paused, and revenue data is no longer available.";
        } else if (user.flagIsOn(flags.ND_CIRCUIT_BREAKER_DISCONNECT)) {
          errorMessage = "Automations triggered from Shopify have been paused, and revenue data is no longer available.";
        }
      } else if (this.model.status === "sent") {
        // only show for sent campaigns
        if (user.flagIsOn(flags.ND_CIRCUIT_BREAKER_DISCONNECT)) {
          errorMessage = "Revenue data from Shopify is no longer available.";
        }
      }
      if (errorMessage) {
        new ndMessage({
          message: errorMessage
        }).placeAt(this.node_ndMessage);
      }
    },
    /*
        Additional Population Functions That Should Be Overridden By Child Classes If Different From Default Implementations
    */
    buildAdditionalMetaData: function () {},
    buildAdditionalMetaDataTranslated: function () {},
    buildTranslations: function () {},
    buildChannelIcon: function () {
      domClass.add(this.node_channelIcon, this.channelIcon);
    },
    buildListName: function () {},
    /*
        General Utility Functions
    */
    _generateLinkHTML: function (linkText, hrefTemplate, action) {
      var labelLink = dom.create("a", {
        innerHTML: linkText
      });
      if (hrefTemplate) {
        domAttr.set(labelLink, "href", hrefTemplate);

        // For the print action we want to open up a new page
        if (linkText === "Print") {
          domAttr.set(labelLink, "target", "blank");
        }
        if (context.flagIsOn(flags.REPORTING_DOWNLOAD_LINK_ATTRIBUTE)) {
          // For the download action we want to download instead of navigate
          if (linkText === "Download") {
            domAttr.set(labelLink, "download", "true");
          }
        }
      }
      if (action === undefined) {
        domAttr.set(labelLink, "data-target", "view-report-button");
      }

      // This sets the onMouseUp analytics calls for the dropdown menu items.
      if (action && action.onMouseUp) {
        on(labelLink, "mouseup", action.onMouseUp.bind(this));
      }
      return labelLink.outerHTML;
    },
    _generateLabelHTML: function (action, actionListItem) {
      var html = "";
      if (!action.is_primary) {
        if (action.is_disabled) {
          var disabledLabel = dom.create("span", {
            innerHTML: actionListItem.label
          });
          domClass.add(disabledLabel, "dijitMenuItemDisabledColor");
          html = disabledLabel.outerHTML;
        } else {
          html = this._generateLinkHTML(actionListItem.label, action.href, actionListItem);
        }
      } else {
        html = this._generateLinkHTML(actionListItem.label, action.href);
      }
      if (action.description) {
        var desc = dom.create("span", {
          innerHTML: action.description
        });
        domClass.add(desc, "dijitMenuItemDescription");
        html += "<br/>" + desc.outerHTML;
      }
      return html;
    },
    _getPrimaryAction: function (actions) {
      return actions.filter(function (action) {
        return action.is_primary;
      })[0];
    },
    postMixInProperties: function () {
      if (this.mode === "condensed") {
        this.templateString = campaignSlatCondensedTpl;
      }
    },
    /*
        Post Create Function That Runs Build Functions
        (Override with this.inherited in child class to add additional behavior)
    */
    postCreate: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        if (this.mode === "condensed") {
          this.buildTranslations(translations);
          this._buildTitleActionTranslated();
          this._buildTypeNameTranslated();
          this._buildStatsTranslated();
          this.buildAdditionalMetaDataTranslated();
          this.buildListName();
        } else {
          this.buildTranslations(translations);
          this._buildCheckbox();
          this._buildStatusBadgeTranslated();
          this._buildEditorType();
          this._buildActionDropDownTranslated();
          this._buildTitleActionTranslated();
          this._buildTypeNameTranslated();
          this._buildStatsTranslated();
          this._buildComplianceMessage();
          this._buildSendingErrorMessage();
          this._showNdMessagingIfNeeded();
          this.buildChannelIcon();
          this.buildAdditionalMetaDataTranslated();
          this.buildListName();
        }
      }.bind(this));

      // For the reports page [UX-118] we are hiding the status pills
      if (this._isReportsPage()) {
        domClass.add(this.node_checkBox, "c-campaignManager_slat_checkbox--reports padding-right--lv4");
        domClass.remove(this.node_checkBox, "padding-right--lv2");

        // If the user is a viewer remove the option to select campaigns and remove the checkbox
        if (user.role === "viewer") {
          dom.destroy(this.node_checkBox);
        }
      }
    },
    /**
     * Checks to see if the user is on the reports page
     *
     * @returns {boolean} Whether or not the user is on the reports page
     */
    _isReportsPage: function () {
      return this.campaignListLocation === CAMPAIGN_LIST_LOCATION_REPORTS;
    }
  });
});