define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "dojox"], function (dojo, dijit, dojox) {
  dojo.provide('mojo.lists');

  /**
   * Rename an interest group
   * @param {Integer} bit the bit of the interest group to rename
   * @param {String} name the new name to update
   * @param {String} url the url to post the rename information
   */
  mojo.lists.renameInterest = function (bit, name, url) {
    dojo.xhrPost({
      'url': url,
      'content': {
        'bit': bit,
        'name': name
      }
    });
  };

  /**
   * Remove an interest group from the database and the DOM (this will re-bit the interest groups)
   * @param {Integer} bit the bit of the interest group to delete
   * @param {String} url the url to post the delete information
   * @param {String} rename_url we need to rebuild the interest group list, so the rename_url is needed for callbacks
   */
  mojo.lists.deleteInterest = function (bit, url, rename_url) {
    dojo.xhrPost({
      'url': url,
      'content': {
        'bit': bit
      },
      'handleAs': 'json',
      'sync': true,
      'load': function (response, ioargs) {
        //Clear out the group list and re-build it
        dojo.byId('interest-groups').innerHTML = '';
        dojo.forEach(response.groups, function (group) {
          if (dijit.byId('interest-' + group.bit)) {
            dijit.byId('interest-' + group.bit).destroyRecursive();
          }
          var new_item = document.createElement('li');
          dojo.addClass(new_item, 'dojoDndItem');
          new_item.id = 'interestrow-' + group.bit;
          var li_content = '<span class="sort-handle">&nbsp;</span> ';
          li_content += ' <a href="#" onclick="confirmDelete(' + group.bit + ');" class="delete">&nbsp;</a> ';
          li_content += ' <span id="interest-' + group.bit + '" class="interest-edit">' + group.name + '</span>';
          new_item.innerHTML = li_content;
          dojo.byId('interest-groups').appendChild(new_item);
          var params = {
            'id': 'interest-' + group.bit,
            'title': 'click to rename this interest group',
            'value': group.name,
            'onChange': function (value) {
              mojo.lists.renameInterest(group.bit, value, rename_url);
            }
          };
          var in_place = new dijit.InlineEditBox(params, dojo.byId('interest-' + group.bit));
        });
        dojo.byId('add-interest').style.display = '';
        setTimeout(function () {
          interest_group_list.clearItems();
          interest_group_list.startup();
        }, 200);
      }
    });
  };

  /**
   * Toggle a resort of the interest groups for a list
   * @param {Array} bit_order an array of the interest group bits, in sort order
   * @param {String} url the url to post the reorder request
   */
  mojo.lists.reorderInterests = function (bit_order, url) {
    dojo.xhrPost({
      'url': url,
      'content': {
        'bit_order[]': bit_order
      }
    });
  };

  /**
   * All grids of list members share some common fields - merge columns, email address, and view/delete links
   * @param {Integer} list_id the id of the list to pull dataset fields for
   * @param {String} get_fields_url the URL to ask for the dataset fields
   * @param {String} view_member_url the URL to use in the view member action
   * @param {String} delete_member_url the URL to use in the delete member action
   */
  mojo.lists.memberGridFields = function (list_id, get_fields_url, view_member_url, delete_member_url) {
    dojo.xhrPost({
      'url': get_fields_url,
      'sync': true,
      'handleAs': 'json',
      'content': {
        'id': list_id
      },
      'load': function (data) {
        fields = data.fields;
      }
    });
    var cells = [];
    cells.push({
      'name': 'view',
      'field': 'list_email_id',
      'get': dojo.partial(mojo.lists.memberViewLink, view_member_url)
    });
    dojo.forEach(fields, function (field) {
      cells.push({
        'name': field.name,
        'field': field['var'],
        'classes': 'column-merge',
        'width': '180px'
      });
    });
    cells.push({
      'name': 'delete',
      'field': 'list_email_id',
      'get': dojo.partial(mojo.lists.memberDeleteLink, delete_member_url)
    });
    return cells;
  };

  /**
   * Dojo grid renderer for the "view" link on the members grid
   * @param {String} url
   * @param {Integer} row
   */
  mojo.lists.memberViewLink = function (url, row) {
    var item = this.grid.model.getRow(row);
    if (!item) return '...';
    return '<a href="' + url + '?id=' + item.list_email_id + '" class="view-member button-small">view</a>';
  };

  /**
   * Dojo grid renderer for the "delete" link on the members grid
   * @param {String} url
   * @param {Integer} row
   */
  mojo.lists.memberDeleteLink = function (url, row) {
    var item = this.grid.model.getRow(row);
    if (!item) return '...';
    return '<a href="' + url + '?id=' + item.list_email_id + '" class="delete-member button-small p0">delete</a>';
  };
});