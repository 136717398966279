/**
 * Applied to "from email" inputs to watch and warn about for DMARC issues
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "mojo/url", "dijit/Dialog", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _widgetBase, mUrl, Dialog, lang, on, query, dom, domConstruct, domAttr, domClass, array) {
  return declare([_widgetBase], {
    // different instances of the DMARC warning require different margin
    warningClass: "!margin-bottom--lv3",
    postCreate: function () {
      var warning = '<div class="field-help always-show ' + this.warningClass + ' hide" data-mc-el="dmarc-warning">To <a href="https://mailchimp.com/help/limitations-of-free-email-addresses/" class="nowrap" target="_blank">ensure delivery</a>, we\'ll change your from address to <span data-mc-el="addyChange">contact.yahoo.com@send.mailchimpapp.com</span></div>';
      this.errorMessage = domConstruct.place(warning, this.domNode, "after");
      domAttr.set(this.domNode, "data-mc-el", "dmarc-input");
      this.checkDomain();
      this.own(on(this.domNode, "blur", lang.hitch(this, function () {
        this.checkDomain();
      })));
    },
    checkDomain: function () {
      var self = this;
      var e = this.domNode.value;
      var warnDomains = ["@aol", "@yahoo", "@ymail", "@rocketmail", "@mail.ru", "@kb.ru", "@list.ru", "@inbox.ru"];

      // Checks if input value matches an item in warnDomains array
      if (array.some(warnDomains, function (domain) {
        return e.indexOf(domain) >= 0;
      })) {
        //Hide any previous errors (i.e., 'Please enter a value' error)
        query(self.errorMessage).next(".invalid-error").forEach(function (node) {
          domClass.add(node, "hide");
        });
        // Place re-written email address into field-help msg
        query('[data-mc-el="addyChange"]', self.errorMessage)[0].innerHTML = e.split("@").join(".") + "@send.mailchimpapp.com";
        domClass.remove(self.errorMessage, "hide");
        domClass.remove(this.domNode, "invalid");
      } else {
        domClass.add(self.errorMessage, "hide");
      }
    }
  });
});