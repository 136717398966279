define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/url", "mojo/utils", "mojo/context", "dijit/form/Select",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/user", "mojo/lib/flags", "mojo/SinglePageApplicationUtils", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/actions.html",
//API
"./Api",
// Translations
"mojo/utils/I18nTranslation"], function (declare, lang, on, query, domConstruct, topic, domClass, registry, mUrl, utils, context, Select, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, user, flags, SinglePageAppUtils, ContactProfileEvent, actionsString, api, I18nTranslation) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    ARCHIVE_ACTION: "archive",
    DELETE_ACTION: "delete",
    templateString: actionsString,
    memberID: null,
    contactID: null,
    listID: null,
    listName: null,
    isSubscribed: null,
    isAdminUnsubscribed: null,
    isSubscriberUnsubscribed: null,
    isOptedOutOfOneToOneMessaging: null,
    isPending: null,
    isTransactional: null,
    isArchived: null,
    isHardDeleted: false,
    selectedRemovalType: null,
    translations: [],
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (values) {
          this.translations = values;
        }.bind(this));
      }
      this.inherited(arguments);
      this.setupSelect();
      this.selectedRemovalType = this.ARCHIVE_ACTION;
    },
    /**
     * Append click handlers to each of the buttons
     */
    setupSelect: function () {
      domConstruct.empty(this.actionsSelectContainer);

      // If we've already hard-deleted, select should not be available
      if (this.isHardDeleted) {
        return;
      }
      var optionsArray = [];
      optionsArray.push({
        "label": "Actions",
        "value": "actions"
      });
      this.ActionSelect = new Select({
        name: "actionSelect"
      });
      this.ActionSelect.on("click", lang.hitch(this, "handleClick"));
      if (!this.isOptedOutOfOneToOneMessaging) {
        optionsArray.push({
          "label": this.translations["all_contacts_send_message"] || "Send Message",
          "value": "send_message"
        });
      }

      // Unarchive and delete are the only actions available for archived contacts
      if (this.isArchived) {
        optionsArray.push({
          "label": this.translations["all_contacts_unarchive_option"] || "Unarchive",
          "value": "unarchive"
        });
        optionsArray.push({
          "label": this.translations["all_contacts_delete_option"] || "Delete",
          "value": "hard_delete"
        });
        this.ActionSelect.options = optionsArray;
        this.ActionSelect.placeAt(this.actionsSelectContainer);
        this.ActionSelect.startup();
        this.ActionSelect.on("change", lang.hitch(this, "handleSelect"));
        return;
      }
      if (this.isSubscribed) {
        optionsArray.push({
          "label": this.translations["all_contacts_unsubscribe_option"] || "Unsubscribe",
          "value": "unsubscribe"
        });
      }
      if (this.isAdminUnsubscribed) {
        optionsArray.push({
          "label": this.translations["all_contacts_subscribe_option"] || "Subscribe",
          "value": "subscribe"
        });
      }
      if (this.isSubscriberUnsubscribed && !this.isPending) {
        optionsArray.push({
          "label": this.translations["all_contacts_resent_label"] || "Resend Confirmation",
          "value": "resend_confirmation"
        });
      }
      if (user.hasRole("admin")) {
        optionsArray.push({
          "label": this.translations["all_contacts_export_label"] || "Export",
          "value": "export"
        });
      }
      if (this.isVIP && !this.isTransactional) {
        optionsArray.push({
          "label": this.translations["all_contacts_remove_vip"] || "Remove VIP",
          "value": "vip_remove"
        });
      } else if (this.canAddVIP && !this.isTransactional) {
        optionsArray.push({
          "label": this.translations["all_contacts_add_vip"] || "Add VIP",
          "value": "vip_add"
        });
      }
      optionsArray.push({
        "label": "Remove contact",
        "value": "remove"
      });
      // Toggle visibility of the warnings associated with deleting contacts
      query("[name='remove_contact']").on("change", function (e) {
        var permanentDeleteWarning = query(".permanentDeleteWarning")[0];
        if (e.target.dataset.selectType === "delete") {
          domClass.remove(permanentDeleteWarning, "hide");
          this.selectedRemovalType = this.DELETE_ACTION;
        } else {
          domClass.add(permanentDeleteWarning, "hide");
          this.selectedRemovalType = this.ARCHIVE_ACTION;
        }
      }.bind(this));
      if (optionsArray.length > 1) {
        this.ActionSelect.options = optionsArray;
        this.ActionSelect.placeAt(this.actionsSelectContainer);
        this.ActionSelect.startup();
        this.ActionSelect.on("change", lang.hitch(this, "handleSelect"));
      }
    },
    handleClick: function () {
      var event = new ContactProfileEvent("audience:engaged", {
        action: "engaged",
        ui_object: "dropdown",
        ui_object_detail: "actions",
        ui_action: "clicked",
        ui_access_point: "action_list",
        event_origin: "dojo"
      });
      event.publishEvent();
    },
    /**
     * Handle the onchange event for the action select dropdown
     * @param {string} event Selected action from the dropdown
     */
    handleSelect: function (event) {
      switch (event) {
        case "send_message":
          window.location.href = "/audience/inbox?id=" + this.listID + "&contact_id=" + this.contactID;
          break;
        case "unsubscribe":
          this.unsubscribeModal.show();
          break;
        case "subscribe":
          this.subscribeModal.show();
          break;
        case "resend_confirmation":
          this.resendModal.show();
          break;
        case "export":
          this.exportContact();
          break;
        case "unarchive":
          this.unarchiveMember();
          break;
        case "remove":
          this.showRemoveModal(this.removeModal);
          break;
        case "hard_delete":
          if (this.isArchived) {
            this.selectedRemovalType = this.DELETE_ACTION;
            this.showRemoveModal(this.permanentDeleteModal);
          }
          break;
        case "vip_add":
          this.addVIP();
          break;
        case "vip_remove":
          this.removeVIP();
          break;
        default:
          break;
      }
      this.ActionSelect.attr("value", "actions");
      var actionSelectedEvent = new ContactProfileEvent("audience:selected", {
        action: "selected",
        ui_object: "dropdown_item",
        ui_object_detail: "",
        ui_action: "clicked",
        ui_access_point: "action_list",
        action_selected: event,
        event_origin: "dojo"
      });
      actionSelectedEvent.publishEvent();
    },
    /**
     * Hide all action confirmation modals
     */
    dismissModals: function () {
      this.unsubscribeModal.hide();
      this.subscribeModal.hide();
      this.resendModal.hide();
      this.removeModal.hide();
      this.permanentDeleteModal.hide();
    },
    /**
     * Api request to unsubscribe list member
     */
    unsubscribeMember: function () {
      var self = this;
      api.unsubscribeMember(this.memberID, function () {
        self.toastCallback(this.translations["all_contacts_successfully_unsubscribed"] || "Contact successfully unsubscribed.");
      });
    },
    /**
     * Api request to subscribe list member
     */
    subscribeMember: function () {
      var self = this;
      api.subscribeMember(this.memberID, function () {
        self.toastCallback(this.translations["all_contacts_successfully_subscribed"] || "Contact successfully subscribed.");
      });
    },
    /**
     * Api request to resend confirmation email to list member
     */
    resendMember: function () {
      utils.toggleButtonLoadingState(this.resendButton.domNode, "Sending...");
      var self = this;
      api.resendMember(this.memberID, function () {
        self.toastCallback(this.translations["all_contacts_resent_to_contact"] || "Confirmation email resent to contact.");
      });
    },
    /**
     * Api request to unarchive a list member
     */
    unarchiveMember: function () {
      api.unarchiveMember(this.listID, this.memberID, lang.hitch(this, function () {
        location.reload(true);
        this.toastCallback(this.translations["all_contacts_been_unarchived"] || "Contact has been unarchived.");
      }));
    },
    /**
     * Call the appropriate removal function (archive or hard delete)
     * based on radio selection in confirmation modal
     */
    removeMember: function () {
      if (this.selectedRemovalType === this.ARCHIVE_ACTION) {
        this.archiveMember();
      } else if (this.selectedRemovalType === this.DELETE_ACTION) {
        this.hardDeleteMember();
      }
    },
    /**
     * Api request to archive a list member
     */
    archiveMember: function () {
      utils.toggleButtonLoadingState(this.removeButton.domNode, "Archiving...");
      api.archiveMember(this.memberID, this.removeMemberCallback.bind(this));
    },
    /**
     * Api request to hard delete a list member
     */
    hardDeleteMember: function () {
      var button = this.isArchived ? this.deleteButton : this.removeButton;
      utils.toggleButtonLoadingState(button.domNode, "Deleting...");
      var confirmationInput = this.isArchived ? this.deleteConfirm : this.removeConfirm;
      var hasConfirmed = confirmationInput.validate();
      if (hasConfirmed) {
        api.deleteMember(this.memberID, this.removeMemberCallback.bind(this));
      } else {
        utils.toggleButtonLoadingState(button.domNode, "Delete");
      }
    },
    /**
     * Callback for successful removal of list member via archive or delete
     * Navigate back to the list members view
     */
    removeMemberCallback: function () {
      var path = mUrl.toUrl("/lists/members/?id=" + this.listID);
      window.location.replace(SinglePageAppUtils.prefixPath(path));
    },
    /**
     * Api request to add list member to VIP
     */
    addVIP: function () {
      api.addVIP(this.memberID, lang.hitch(this, function () {
        this.toastCallback(this.translations["all_contacts_added_vip"] || "Contact added to VIP.");
      }));
      var trackParams = {
        "eventCategory": "Contact VIP",
        "eventAction": "Add VIP",
        "eventLabel": "Contact profile"
      };
      utils.trackEvent(trackParams);
    },
    /**
     * Api request to remove list member from VIP
     */
    removeVIP: function () {
      api.removeVIP(this.memberID, lang.hitch(this, function () {
        this.toastCallback(this.translations["all_contacts_removed_vip"] || "Contact removed from VIP.");
      }));
      var trackParams = {
        "eventCategory": "Contact VIP",
        "eventAction": "Remove VIP",
        "eventLabel": "Contact profile"
      };
      utils.trackEvent(trackParams);
    },
    /**
     * Navigate to view to export this contact
     */
    exportContact: function () {
      window.location.replace(mUrl.toUrl("/lists/members/build-export?id=" + this.memberID));
    },
    showRemoveModal: function (modal) {
      modal.show();
      api.pendingAppointments(this.memberID, function (data) {
        if (data.hasPendingAppointments) {
          // Disable radio if modal has one
          var radio = modal._descendants.filter(function (node) {
            return node["data-select-type"] === "delete";
          });
          if (radio.length > 0) {
            radio[0].disabled = true;
          }

          // Set/show special feedback message/link
          modal.domNode.querySelector(".pendingAppointmentsWarning").classList.remove("hide");
          modal.domNode.querySelector(".permanentDeleteWarning").classList.add("hide");

          // Hide submit button if no radio buttons
          if (radio.length === 0) {
            modal.domNode.querySelector(".button.p0").classList.add("hide");
          }
        }
        modal.domNode.querySelector(".dijitDialogPaneContent").classList.add("max-height-inherit");
        modal.domNode.querySelector(".dijitDialogPaneContentArea").classList.remove("invisible");
      });
    },
    /**
     * Show a toast message and alert the profile overview that it needs to re-fresh
     * @param {string} toastMessage Message to display
     */
    toastCallback: function (toastMessage) {
      this.dismissModals();
      if (toastMessage) {
        utils.toast(toastMessage);
      }
      topic.publish("contact-profile/action");
    }
  });
});