/**
 * Color Swatch
 * @module mojo/widgets/pattern-library/Swatch.js
 *
 * Widget that represents the idea of a single swatch. Handles all markup and interactions
 * associated with an individual swatch.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/clipboard", "mojo/utils",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/Swatch.html"], function (declare, keys, clipboard, utils, Renderable, tplStr) {
  /**
   * TODO: Replace Renderable with standard _WidgetBase and _TemplatedMixin. It creates an extra div node that's problematic.
   */
  return declare([Renderable], {
    templateString: tplStr,
    baseClass: "colorSwatchNode",
    color: {},
    colorFormat: "hex",
    /**
     * Copy the selected colorFormat to the clipboard. Publish to the global topic to create a notification.
     *
     * @private
     */
    _copyColor: function () {
      clipboard.copy(this.color[this.colorFormat]);
      utils.globalPublish("show-toast", [{
        "message": this.color[this.colorFormat] + " is copied—paste when you're ready.",
        "type": "success"
      }]);
    },
    /**
     * Respond to click on a swatch node.
     *
     * @param {Event} event - click
     * @private
     */
    _onClick: function (event) {
      event.preventDefault();
      this._copyColor();
    },
    /**
     * Intercept keypress and potentially call method to copy color to clipboard.
     *
     * @param {Event} event - onkeydown
     * @private
     */
    _onKeyDown: function (event) {
      if (event.keyCode === keys.ENTER) {
        this._copyColor();
      }
    }
  });
});