define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, base, domAttr, on, FilterMenu, FilterMessage) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var filterSettings = {
        "target": "emails-left-list",
        "filters": [{
          "label": "Since",
          "key": "from",
          "type": "text"
        }, {
          "label": "Up to",
          "key": "to",
          "type": "text"
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.emailsLeftListFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.emailsLeftListFiltersMessage.appendChild(filterMessage.domNode);
    }
  });
});