define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo/store/Cache", "dojo/store/util/QueryResults", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/lib/flags", "mojo/url", "mojo/user", "mojo/widgets/input/TagAutocomplete", "mojo/widgets/input/TagList"], function (declare, lang, dom, domClass, request, query, Memory, Cache, QueryResults, topic, registry, flags, mUrl, user, TagAutocomplete, TagList) {
  var createLocationId = function (location) {
    var name = location.name.split(", ").join(";");
    name.split(" ").join(";");
    return [location.type, location.country_code, location.region_id, location.city_id, location.zip_id, name].map(function (val) {
      if (val === "NULL") {
        return "";
      }
      return val;
    }).join("_");
  };
  var allLocations = {};
  var uniqueLocations;
  var conflicts;

  // startsWith Polyfill
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
    };
  }

  // piggy-back of dojo's in-memory store and override
  // the query method to fetch from our own endpoint
  var LocationsStore = declare([Memory], {
    // normally the ID property is id, and while we do have an id, we will need to compare the users input
    // to items in the cached store, so its best to create our own normalized id and key on that.
    idProperty: "nameKey",
    query: function (storeQuery) {
      var country = null;
      if (dom.byId("lookalike").checked) {
        country = registry.byId("audience-country").getValue();
      }

      // queries could either be a string or a hash of name-value pairs to match.
      // in our case, looks like the widget using this store sends an object and tries to match "name".
      // there's some weirdness there too: storeQuery.name is actually a "fake" string object with a toString() method.
      storeQuery = lang.isString(storeQuery) ? storeQuery : storeQuery.name.toString();
      var url = mUrl.toUrl("/ads/facebook/find-locations", {
        q: storeQuery,
        limit: 100,
        cc: country
      });
      var locations = request(url, {
        handleAs: "json"
      }).then(function (results) {
        results.forEach(function (result) {
          result.nameKey = result.name.toLowerCase(); // normalize our key. just lowering will work.
          result.id = createLocationId(result); // we'll need to store the name as well, so include it

          allLocations[result.id] = result;
        });
        return results;
      });

      // store query results should also attach a total value
      // (or, as in this case, promise) to the results.
      locations.total = locations.then(function (results) {
        return results.length;
      });
      return QueryResults(locations);
    }
  });
  var locationCache = new Memory({
    idProperty: "nameKey"
  });
  var LocationsTagModel = declare([TagAutocomplete], {
    store: new Cache(new LocationsStore(), locationCache),
    autoComplete: false,
    // when the user hits enter, the autocomplete field's value may not have been updated yet, so we need to force
    // the tag to update its value if the user's current input is valid.
    onEnter: function () {
      var input = this.getCurrentInput().toLowerCase();
      var item = locationCache.get(input);
      if (item) {
        this.setValue(item);
        this.setLabel(item.name);
      }
      return true; // we return true to signify that we handled the event ourselves.
    }
  });
  var TOPIC_GEOTARGETING = "ads/facebook-location-taglist";
  return declare([TagList], {
    TagModel: LocationsTagModel,
    locations: [],
    postCreate: function () {
      var self = this;
      this.inherited(arguments); // this changes context, so we can no longer use it.

      var locations = self.locations || [];
      locations.forEach(lang.hitch(function (location) {
        self._addTag(createLocationId(location), location.name);
      }));
    },
    // whenever we add a tag, we actually want to populate a single hidden input
    // with all of the tags we have so far, separated by commas.
    _addTag: function (value, label) {
      var tag = this.inherited(arguments);
      this.removeDuplicateTag();
      this.findGroupedTags();
      this.highlightGroupedTags();
      this.toggleConflictMessage();
      this.updateField();
      return tag;
    },
    _removeTag: function (tag) {
      this.inherited(arguments);
      this.findGroupedTags();
      this.highlightGroupedTags();
      this.toggleConflictMessage();
      this.updateField();
    },
    updateField: function () {
      // get the values, but get rid of duplicate tags
      var unique = {};
      var values = this.getValues().filter(function (_value) {
        var v = _value.trim();
        if (!unique[v]) {
          unique[v] = true;
          return true;
        }
        return false;
      });
      dom.byId("audience-locations").value = values.join();
    },
    removeDuplicateTag: function () {
      var flags = {};

      // Remove duplicate tags immediately on entry
      this._getTags().forEach(function (tag) {
        if (flags[tag.value]) {
          tag.destroy();
        } else {
          flags[tag.value] = true;
        }
      });
    },
    findGroupedTags: function () {
      function doesConflict(strA, strB) {
        return strA !== strB && (
        // any direct matches will be caught already
        strA.startsWith(strB) || strB.startsWith(strA));
      }

      // does a nested array (arrs) contain arrays that conflict
      function containsConflicts(arr, i, arrs) {
        return arrs.some(doesConflict.bind(null, arr));
      }
      function findConflicts(arrs) {
        return arrs.filter(containsConflicts);
      }

      // Extract tag values, which represent the Country Code, Region ID, City ID, Zip ID
      var places = this._getTags().map(function (tag) {
        // Strip out type and underscores for correct string matching
        var tag_type = tag.value.split("_");
        tag_type.shift();
        tag_type.pop();
        return tag_type.join("");
      }).filter(function (str) {
        // Ignore the last blank tag in the array
        return str !== "";
      });
      conflicts = findConflicts(places);
    },
    highlightGroupedTags: function () {
      if (conflicts.length > 0) {
        topic.publish(TOPIC_GEOTARGETING, {
          conflicts: true
        });
        // disables save when there are location errors
        // domAttr.set(query("[data-mc-el=saveAudience]")[0], "disabled");

        this._getTags().map(function (tag) {
          var tag_type = tag.value.split("_");
          // shift off the 'type' for string comparison
          tag_type.shift();
          // shift off the 'name' for string comparison
          tag_type.pop();
          var location = tag_type.join("");
          if (conflicts.indexOf(location) !== -1) {
            domClass.add(tag.domNode, "c-tagList_item--highlight");
          } else {
            domClass.remove(tag.domNode, "c-tagList_item--highlight");
          }
        });
      } else {
        // enables save when errors are clear
        topic.publish(TOPIC_GEOTARGETING, "{conflicts: false}");
        // domAttr.remove(query("[data-mc-el=saveAudience]")[0], "disabled");

        this._getTags().map(function (tag) {
          domClass.remove(tag.domNode, "c-tagList_item--highlight");
        });
      }
    },
    toggleConflictMessage: function () {
      domClass.toggle(query("[data-mc-el='conflictMessage']")[0], "hide", conflicts.length <= 0);
    }
  });
});