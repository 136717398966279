define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dijit/registry", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/surveys", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _base, registry, query, surveys, topic) {
  return declare([_base], {
    onReady: function () {
      var list = registry.byId("surveys-list");

      // SWMCAPPS Survey
      if (typeof window.usabilla_live === "function") {
        window.usabilla_live('trigger', 'swmcapps_1_1_survey_1');
      }
      if (this.$el.deleteBtn) {
        query(this.$el.deleteBtn).on("click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          var selection = list.getSelection();
          surveys.showDeletionPrompt(selection).then(function () {
            list.render();
          });
        });
      }
      var createSurveyButton = this.$el.createBtn;
      topic.subscribe("mojo/analytics/surveys-list/ready", function () {
        if (list.total === 0) {
          createSurveyButton.style.display = "none";
        }
      });
    }
  });
});