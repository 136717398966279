define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, lang, base) {
  return declare([base], {
    setupMobile: function () {
      var nativeMobileObj = {
        mobileSaveAndExit: {
          postMessage: function (obj) {
            console.dir(obj);
            console.log("iOS: Save and Exit Mobile");
          }
        },
        webContentDidLoad: {
          postMessage: function (obj) {
            console.dir(obj);
            console.log("iOS: webContentDidLoad");
            window.app.nativeEvents.entryAnimationComplete();
          }
        },
        mobileReauth: {
          postMessage: function (obj) {
            console.dir(obj);
            console.log("iOS: Reauth");
          }
        },
        getAppVersion: {
          postMessage: function (obj) {
            // call method to resolve promise
            console.log("iOS: getAppVersion");
            window.app.nativeEvents.messageResponse({
              id: obj.id,
              data: {
                versionNumber: "2"
              }
            });
          }
        },
        openColorPicker: {
          postMessage: function (colorPickerObj) {
            console.log("iOS: Open ColorPicker with Color: " + colorPickerObj.color);
          }
        },
        blockEdit: {
          postMessage: function () {
            console.log("Active Block Edit: iOS");
          }
        },
        backgroundEdit: {
          postMessage: function (obj) {
            console.dir(obj);
            console.log("iOS: Background Edit");
          }
        },
        globalToolbar: {
          postMessage: function () {
            console.log("Globa Toolbar: iOS");
          }
        },
        hideToolbar: {
          postMessage: function (obj) {
            // var parsedObj = JSON.parse(obj);
            console.log(obj);
            console.log("Hide toolbar");
          }
        }
      };
      var nativeMobileObjAndroid = {
        mobileSaveAndExit: function (obj) {
          console.dir(obj);
          console.log("Android: Save and Exit Mobile");
        },
        webContentDidLoad: function (obj) {
          console.dir(obj);
          console.log("Android: webContentDidLoad");
          window.app.nativeEvents.entryAnimationComplete();
        },
        mobileReauth: function (obj) {
          console.dir(obj);
          console.log("Android: Reauth");
        },
        openFileBrowse: function (obj) {
          console.log("Android: Open File Browse");
        },
        openColorPicker: function (hexValue) {
          console.log("Android: Open ColorPicker with Color: " + hexValue);
        },
        getAppVersion: function (obj) {
          var parsedObj = JSON.parse(obj);
          console.log("Android: getAppVersion");
          window.app.nativeEvents.messageResponse({
            id: parsedObj.id,
            data: {
              versionNumber: "1"
            }
          });
        },
        backgroundEdit: function (obj) {
          console.dir(obj);
          console.log("Android: Background Edit");
        },
        blockEdit: function (obj) {
          console.dir(obj);
        },
        globalToolbar: function () {
          console.log("Global Toolbar: Android");
        },
        hideToolbar: function (obj) {
          var parsedObj = JSON.parse(obj);
          console.log(parsedObj);
          console.log("Hide toolbar");
        }
      };
      window.webkit = {
        messageHandlers: nativeMobileObj
      };
      // window.Android = nativeMobileObjAndroid;
    },
    onReady: function () {
      if (this.forDesktopTesting()) {
        this.setupMobile();
      }
    },
    forDesktopTesting: function () {
      return window.location.search.indexOf("simulator=true") !== -1;
    }
  });
});