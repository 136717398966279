/**
 * @module mojo.utils.array
 *
 * Array utility methods.
 *
 * All methods' first argument should be an array.
 * Method names follow a prefix pattern which indicates their behavior:
 *  - toSomething: takes an array and formats it in a certain way, eg. toEnglishEnumeration
 */
define([], function () {
  var exports = {
    /**
     * Generates a comma separated string based on the array passed in. Automatically handle the Oxford
     * comma and if the list has 1, or 2 items.
     *
     * @param {string[]} wordList -- formatted in `[ "something1", "something2" ]`
     * @returns {string} the list of values in a human readable format, eg `"something1 and something2"`
     */
    toEnglishEnumeration: function (wordList) {
      switch (wordList.length) {
        case 0:
          return "";
        case 1:
          return wordList[0];
        case 2:
          return wordList.join(" and ");
        default:
          return wordList.slice(0, -2).join(", ") + (wordList.slice(0, -2).length ? ", " : "") + wordList.slice(-2).join(", and ");
      }
    }
  };
  return exports;
});