/**
 * @class mojo.report.Stat
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  /**
   * Defines a stat with label and value.
   *
   * This class isn't responsible for formatting values as strings yet, so the caller must
   * handle converting ints to strings for their particular use case, eg currency or shortened strings.
   */
  return declare([], {
    /**
     * @type string
     * @private
     */
    label: "",
    /**
     * @type string
     * @private
     */
    value: "",
    /**
     * @type string
     * @private
     */
    description: "",
    /**
     * @param {string} label Human-readable short label
     * @param {string} value Human-readable and formatted value
     * @param {string} description Human-readable description of value
     */
    constructor: function (label, value, description) {
      var typeofLabel = typeof label;
      if (typeofLabel !== "string") {
        throw new Error("`label` argument must be a string, but found `" + typeofLabel + "`");
      }
      this.label = label;
      var typeofValue = typeof value;
      if (typeofValue !== "string") {
        throw new Error("`value` argument must be a string, but found `" + typeofValue + "`");
      }
      this.value = value;
      var typeofDescription = typeof description;
      if (description && typeofDescription !== "string") {
        throw new Error("`description` argument must be a string, but found `" + typeofDescription + "`");
      }
      this.description = description;
    },
    /**
     * @return {string} human-readable and formatted value
     */
    getValue: function () {
      return this.value;
    },
    /**
     * @return {string} human-readable short label
     */
    getLabel: function () {
      return this.label;
    },
    /**
     * @return {string} human-readable description
     */
    getDescription: function () {
      return this.description;
    }
  });
});