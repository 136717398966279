define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/SignUpFormEditor/_form-fields-content.html", "dojo/text!../editors/blocks/templates/SignUpFormEditor/outputWeb.html", "dojo/text!../editors/blocks/templates/SignUpFormEditor/previewDemo.html", "dojox/dtl", "dojox/dtl/Context", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "mojo/user", "mojo/lib/flags", "vendor/lodash-amd/find", "vendor/lodash-amd/map", "../email-renderers/_Renderer"], function (declare, fieldsTpl, webOutputTpl, demoTpl, dtl, dtlContext, _TemplatedMixin, _WidgetBase, user, flags, _find, _map, _Renderer) {
  return declare([_Renderer], {
    // Basic template
    outputTplStr: webOutputTpl,
    // Template that puts all the field attributes in formFields
    formFieldsTemplate: null,
    constructor: function () {
      this.inherited(arguments);

      // Load the form fields template
      this.formFieldsTemplate = new window.dojox.dtl.Template(fieldsTpl);
    },
    createBlockContainerStyle: function (blockAlignment) {
      switch (blockAlignment) {
        case "left":
          return "justify-content:flex-start;";
        case "right":
          return "justify-content:flex-end;";
        case "center":
          return "justify-content:center;";
        default:
          return "justify-content:center;";
      }
    },
    createFormContainerStyle: function (blockWidth) {
      return "align-items:center; justify-content:center;";
    },
    getOutputModel: function (meta) {
      // get fonts from style model in labels
      // look up styles for label
      var labelStyle = _find(meta.styles, {
        "selector": ".webContent__content label"
      });
      var formContainerStyle = this.createFormContainerStyle(meta.blockWidth);
      var blockContainerStyle = this.createBlockContainerStyle(meta.blockAlignment);
      var signUpFormBody = this.formFieldsTemplate.render(new dtlContext({
        "labelFonts": labelStyle ? labelStyle.props["font-family"].value : "",
        "formFields": meta.mergeTag
      }));
      if (window.top.Previewer) {
        formContainerStyle += " width: 100%;";
        blockContainerStyle += " width: 100%;";
      }
      return {
        signUpFormBody: window.top.Previewer && !window.top.Previewer.signUpFormConfig ? demoTpl : signUpFormBody,
        formContainerStyle: formContainerStyle,
        blockContainerStyle: blockContainerStyle
      };
    }
  });
});