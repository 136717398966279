define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/fx", "dojo-proxy-loader?name=dojo/_base/fx!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/contact-profile/activity-feed/EventProperty", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/activity-feed/activity-item-event.html"], function (declare, domClass, domConstruct, domStyle, fx, baseFx, EventProperty, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl) {
  var EventFeedItem = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    data: null,
    isArrowUp: false,
    postMixInProperties: function () {
      this.data.fineGrainedDisplayTime = new Date(this.data.created_at_timestamp * 1000).toLocaleString();
    },
    postCreate: function () {
      this.formatProperties();
    },
    /**
     * Show properties section
     */
    formatProperties: function () {
      var properties = this.data.event_properties;
      if (properties && Object.keys(properties).length > 0) {
        domClass.remove(this.showDetails, "hide");
        var propertiesContainer = domConstruct.create("div", null, this.propertiesContainer);
        Object.keys(properties).sort().forEach(function (key) {
          var value = properties[key];
          var formattedKey = key.replace(/_/g, " ");
          formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
          var property = new EventProperty({
            "data": {
              "label": formattedKey,
              "value": value
            }
          });
          property.placeAt(propertiesContainer, "last");
        });
      }
    },
    /**
     * Show/Hide properties section
     */
    toggleDetails: function () {
      domClass.remove(this.propertiesContainer, "hide");
      if (this.isArrowUp) {
        domClass.add(this.propertiesArrow, "arrow-down-animation");
        domClass.remove(this.propertiesArrow, "arrow-up-animation");
        fx.combine([baseFx.fadeOut({
          node: this.propertiesContainer,
          duration: 800
        }), fx.wipeOut({
          node: this.propertiesContainer
        })]).play();
      } else {
        domClass.remove(this.propertiesArrow, "arrow-down-animation");
        domClass.add(this.propertiesArrow, "arrow-up-animation");
        domStyle.set(this.propertiesContainer, "opacity", "0");
        fx.combine([baseFx.fadeIn({
          node: this.propertiesContainer,
          duration: 800
        }), fx.wipeIn({
          node: this.propertiesContainer
        })]).play();
      }
      this.isArrowUp = !this.isArrowUp;
    }
  });
  return EventFeedItem;
});