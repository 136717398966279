define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "mojo/lib/logger", "dojo/debounce"], function (declare, base, on, query, xhr, mUrl, utils, logger, debounce) {
  return declare([base], {
    onReady: function () {
      this._incorrectCodeMsg = "Hmm, it looks the code you entered is incorrect.";
      this._maxAttemptCodeMsg = "Too many attempts done on the security code. Please get a new code.";
      this._nonNumericCodeMsg = "Only numbers allowed.";
      this._invalidUsernameMsg = "Invalid username";
      this._apiUnavailableMsg = "Shoot! We can't complete your request at this time. Please try again later.";
      this._apiErrorMsg = "Shoot! We failed on our end. Please try again";
      this._attachListenerToCodeTextBox();
      this._attachListenerToResendText();
      this._attachListenerFeedbackDismiss();
      this._attachListenerInputCells();
    },
    _sendGoogleAnalyticsEvent: function (category, action, label, customParams) {
      if (!customParams) {
        // initializing custome params to empty object
        customParams = {};
      }
      var $client_id_field = document.getElementById("client_id");
      if ($client_id_field && $client_id_field.value) {
        // check if the client id exists, pass it as custom param.
        customParams["dimension63"] = $client_id_field.value;
      }
      logger.googleAnalytics.trackEvent(category, action, label, customParams);
    },
    /**
     * Make a GET Request to a backend endpoint.
     *
     * @param {string} endpoint - backend path.
     * @param {Object} formData - Payload to attach to the GET request.
     *
     * @returns {*|Promise} Promise object representing the HTTP GET response.
     *
     * @private
     */
    _getData: function (endpoint, formData) {
      return xhr.get(mUrl.toUrl(endpoint, formData), {
        "handleAs": "json"
      });
    },
    /**
     * Clear code error message and red border around text boxes.
     *
     * @returns {void}
     *
     * @private
     */
    _clearCodeErrors: function () {
      var msgElem = query(".verification-code-wrapper .invalid-error");
      if (!msgElem || !msgElem.length) {
        return;
      }
      msgElem[0].innerText = "";
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem) {
        return;
      }
      txtBoxElem.removeClass("invalid");
    },
    /**
     * Add code error message and red border around text boxes.
     *
     * @param {string} msg - error message.
     *
     * @returns {void}
     *
     * @private
     */
    _showCodeErrors: function (msg) {
      this._clearCodeErrors();
      var msgElem = query(".verification-code-wrapper .invalid-error");
      if (!msgElem || !msgElem.length) {
        return;
      }
      msgElem[0].innerText = msg;
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem) {
        return;
      }
      txtBoxElem.addClass("invalid");
    },
    /**
     * Get combined code from all 6 text boxes.
     *
     * @returns {string} code.
     *
     * @private
     */
    _getCode: function () {
      var elem = this.$el.verificationCode;
      if (!elem || !elem.value) {
        return "";
      }
      return elem.value.replace(/\s/g, "");
    },
    /**
     * Check if code is valid.
     *
     * @param {string} code - error message.
     *
     * @returns {boolean} - if code is valid.
     *
     * @private
     */
    _isCodeValid: function (code) {
      code = String(code);
      return code && code.length === 6 && utils.isPositiveInteger(code);
    },
    /**
     * Shows/hides loading indicator.
     *
     * @param {boolean} show - to display/hide.
     *
     * @returns {void}
     *
     * @private
     */
    _showLoadingStatus: function (show) {
      var loadingEle = query("#verify-loading");
      if (!loadingEle || !loadingEle.length) {
        return;
      }
      var content = query("#verify-content");
      if (!content || !content.length) {
        return;
      }
      if (show) {
        content.addClass("hide");
        loadingEle.removeClass("hide");
      } else {
        loadingEle.addClass("hide");
        content.removeClass("hide");
      }
    },
    /**
     * Disable/enable code text box.
     *
     * @param {boolean} disable - to disable/enable.
     *
     * @returns {void}
     *
     * @private
     */
    _disableCodeTextBox: function (disable) {
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem || !txtBoxElem.length) {
        return;
      }
      txtBoxElem[0].disabled = disable === true;
      if (disable) {
        txtBoxElem.addClass("opacity-70");
      } else {
        txtBoxElem.removeClass("opacity-70");
      }
    },
    /**
     * Put focus on code text box.
     *
     * @returns {void}
     *
     * @private
     */
    _focusCodeTextBox: function () {
      var txtBoxElem = query(".verification-code-wrapper #verification-code");
      if (!txtBoxElem || !txtBoxElem.length) {
        return;
      }
      txtBoxElem[0].focus();
    },
    /**
     * Make an API call to verify code.
     */
    _verifyEmailByCodeAPI: function () {
      var self = this,
        gaLabel;
      var username = self.$el.username ? self.$el.username.value : "";
      var userid = self.$el.user_id ? self.$el.user_id.value : "";
      var client_id = self.$el.client_id ? self.$el.client_id.value : "";
      var response_type = self.$el.response_type ? self.$el.response_type.value : "";
      var state = self.$el.state ? self.$el.state.value : "";
      var redirect_uri = self.$el.redirect_uri ? self.$el.redirect_uri.value : "";
      var signup_source = self.$el.signup_source ? self.$el.signup_source.value : "";
      var paramsMissing = self._isAnyValueMissing([client_id, response_type, redirect_uri, signup_source]);
      if (paramsMissing) {
        self._showCodeErrors(self._apiErrorMsg);
        return;
      }
      var code = self._getCode();
      if (!username || !userid) {
        // Should not happen
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._invalidUsernameMsg);
        self._showCodeErrors(self._incorrectCodeMsg);
        return;
      }
      if (!self._isCodeValid(code)) {
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._incorrectCodeMsg);
        self._showCodeErrors(self._incorrectCodeMsg);
        return;
      }
      self._disableCodeTextBox(true);
      self._showLoadingStatus(true);

      // Make an API call to verify code
      self._getData("/signup/verify-email-by-code", {
        username: username,
        userid: userid,
        code: code,
        signup_source: signup_source,
        client_id: client_id
      }).then(function (result) {
        if (result && result.success && result.data.params.redirect_url) {
          self._sendGoogleAnalyticsEvent("sign up", "step complete", "code successful");
          var query_string = new URLSearchParams();
          query_string.append("client_id", client_id);
          query_string.append("state", state);
          query_string.append("redirect_uri", redirect_uri);
          query_string.append("response_type", response_type);
          query_string.append("signup_source", signup_source);
          window.location.href = result.data.params.redirect_url + "&" + query_string.toString();
          return;
        }
        self._disableCodeTextBox(false);
        self._showLoadingStatus(false);

        // Log error messages to GA
        gaLabel = result.data.error_message + " (SigSci Code: " + result.data.sigsci_code + ").";
        self._sendGoogleAnalyticsEvent("sign up", "code error", gaLabel);

        // If error is of type validation, show it to user
        if (result.data.sigsci_code === "5") {
          if (result.data.error_message === "Max attempts") {
            self._showCodeErrors(self._maxAttemptCodeMsg);
          } else {
            self._showCodeErrors(self._incorrectCodeMsg);
          }
        } else {
          self._showCodeErrors(self._apiUnavailableMsg);
        }
        self._focusCodeTextBox();
      }, function (error) {
        self._disableCodeTextBox(false);
        self._sendGoogleAnalyticsEvent("sign up", "code error", self._apiErrorMsg);
        self._showCodeErrors(self._apiErrorMsg);
        self._focusCodeTextBox();
      });
    },
    _isAnyValueMissing: function (values) {
      return values.some(function (e) {
        return !e || e === "";
      });
    },
    /**
     * Separating validation from focus behavior.
     *
     * @returns {void}
     *
     * @private
     */
    _validationTest: function () {
      var self = this;
      var textBoxElem = self.$el.verificationCode;
      if (!textBoxElem) {
        return;
      }
      self._clearCodeErrors();
      var code = self._getCode();
      if (!code) {
        return;
      }
      if (textBoxElem.value.indexOf(" ") >= 0) {
        textBoxElem.value = code;
      }
      if (code.length > 6) {
        code = code.substring(0, 6);
        textBoxElem.value = code;
      }
      if (!utils.isPositiveInteger(code)) {
        self._showCodeErrors(self._nonNumericCodeMsg);
        return;
      }
      if (code.length > 6) {
        self._showCodeErrors(self._incorrectCodeMsg);
        return;
      }
      if (code.length === 6) {
        self._verifyEmailByCodeAPI();
      }
    },
    /**
     * Do necessary checks when text box input is changed.
     *
     * @returns {void}
     *
     * @private
     */
    _attachListenerToCodeTextBox: function () {
      var self = this;
      var textBoxElem = self.$el.verificationCode;
      if (!textBoxElem) {
        return;
      }
      on(textBoxElem, "input", debounce(function () {
        self._validationTest();
      }, 200));
    },
    /**
     * Handle requesting pin resend email
     *
     * @returns {void}
     *
     * @private
     */
    _attachListenerToResendText: function () {
      var self = this;
      on(self.$el.resendPinButton, "click", debounce(function () {
        var button = query(self.$el.resendPinButton)[0];
        if (!button) {
          return;
        }
        var currentText = button.innerText;
        button.innerText = "Resending PIN...";
        var username = self.$el.username ? self.$el.username.value : "";
        var user_id = self.$el.user_id ? self.$el.user_id.value : "";
        var client_id = self.$el.client_id ? self.$el.client_id.value : "";
        self._getData("/signup/resend-oneclick-confirmation", {
          user_id: user_id,
          username: username,
          client_id: client_id
        }).then(function (result) {
          button.innerText = currentText;
          if (result.success) {
            self._displayResentPINFeedback();
          }
        });
      }));
    },
    /**
     * Displays the `green` feedback box informing the user their ping has been resent.
     *
     * @returns {void}
     *
     * @private
     */
    _displayResentPINFeedback: function () {
      var self = this;
      var feedbackBlock = query(self.$el.successFeedback);
      if (!feedbackBlock) {
        return;
      }
      feedbackBlock.removeClass("hide");
    },
    /**
     * Closses feedback box when user click cross
     *
     * @returns {void}
     *
     * @private
     */
    _attachListenerFeedbackDismiss: function () {
      var self = this;
      on(self.$el.dismissButton, "click", debounce(function () {
        var feedbackBlock = query(self.$el.successFeedback);
        if (!feedbackBlock) {
          return;
        }
        feedbackBlock.addClass("hide");
      }));
    },
    /**
     * Makes listeners for interactions with code input cells on the code based verification page.
     *
     * @returns {void}
     *
     * @private
     */
    _attachListenerInputCells: function () {
      var self = this;
      var verificationCells = Array.from(document.getElementsByClassName("verification-cell"));
      verificationCells.forEach(function (el) {
        el.addEventListener("click", function (event) {
          event.target.select();
        });
        el.addEventListener("keyup", function (event) {
          var verificationArray = [];
          var mainValidationInput = document.getElementById("verification-code");
          query(".verification-cell").forEach(function (cell, index) {
            verificationArray[index] = cell.value;
          });
          if (verificationArray.indexOf("") === -1 && event.key !== "Tab" && event.key !== "Shift") {
            mainValidationInput.value = verificationArray.join("");
            self._validationTest();
          }
        });
        el.addEventListener("keydown", function (event) {
          var nextCell = event.target.nextElementSibling;
          if (!isNaN(event.key) && event.key !== "Tab" && event.key !== "Shift") {
            event.preventDefault();
            event.target.value = event.key;
          }
          if (nextCell && !isNaN(event.key)) {
            nextCell.focus();
          }
          //     // If we have six valid characters, then attempt the submit.
        });
        el.addEventListener("paste", function (event) {
          event.preventDefault();
          var pastedText = undefined;
          var mainValidationInput = document.getElementById("verification-code");
          var pastedTextArray = [];
          if (window.clipboardData && window.clipboardData.getData) {
            // IE
            pastedText = window.clipboardData.getData("Text");
          } else if (event.clipboardData && event.clipboardData.getData) {
            pastedText = event.clipboardData.getData("text/plain");
          }
          // Strip non-numerical values and trim to first six characters.
          pastedText = pastedText.replace(/\D/g, "").substring(0, 6);
          if (pastedText.length === 6) {
            pastedTextArray = pastedText.split("");
            query(".verification-cell").forEach(function (cell, index) {
              cell.value = pastedTextArray[index];
            });
            mainValidationInput.value = pastedText;
            mainValidationInput.focus();
            self._validationTest();
          }
        });
      });
    }
  });
});