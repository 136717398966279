/**
 * AMD module to use Pusher.
 * @module mojo/Pusher
 *
 * Loads Pusher when needed and keeps a single reference of an instance.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request/script", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/url"], function (declare, script, Deferred, context, mUrl) {
  var libUrl = "https://js.pusher.com/2.2/pusher.min.js";

  // Global promise
  var deferred;
  // Contains a single reference to a pusher instance
  var pusherInstance;

  /**
   * @exports mojo/Pusher
   */
  return {
    /**
     * Returns a promise of a pusher instance. Dynamically loads the pusher script once if Pusher is not
     * present in the global scope.
     *
     * @returns {dojo/promise/Promise}
     */
    load: function () {
      if (!deferred || pusherInstance && pusherInstance.connection && pusherInstance.connection.state == "disconnected") {
        deferred = new Deferred();
        function onPusherSuccess() {
          window.Pusher.channel_auth_endpoint = mUrl.toUrl("/pusher/auth");
          pusherInstance = new window.Pusher(context.pusherKey);
          deferred.resolve(pusherInstance);
        }
        function onFailure() {
          deferred.reject({
            "error": "timeout"
          });
        }
        if (!window.Pusher) {
          script.get(libUrl).then(onPusherSuccess, onFailure);
        } else {
          onPusherSuccess();
        }
      }
      return deferred.promise;
    },
    /**
     * Disconnect the only instance of pusher. It will cause load() to re-create a new one next time it's called.
     */
    disconnect: function () {
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    },
    /**
     * A socket ID is used to uniquely identify the current user in this browser.
     * It's passed to the server to allow Pusher to filter out events that are generated by a user.
     *
     * @returns {String}
     */
    getSocketId: function () {
      var socketId;
      if (pusherInstance && pusherInstance.connection) {
        socketId = pusherInstance.connection.socket_id;
      }
      return socketId;
    }
  };
});