define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/context", "mojo/lib/flags", "mojo/url", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, context, flags, mUrl, domAttr, on, topic) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      topic.subscribe("mojo/analytics/List/orders-list/render", function (list) {
        self.parse(self.elId);
        if (self.$group.viewOrderDetailsLink) {
          self.$group.viewOrderDetailsLink.forEach(function (el) {
            on(el, "click", function () {
              self.viewOrderDetails(domAttr.get(el, "data-order-id"));
            });
          });
        }
      });
    },
    /**
     * adds the order Details content pane
     * @param {number} id - order id
     */
    viewOrderDetails: function (id) {
      var self = this;
      var newPaneObj = {
        title: "Order Details: " + id,
        href: mUrl.toUrl("/peaches2/users/billing", {
          _s: "order-details",
          user_id: self.pageJs.userAttrs.userId,
          order_id: id
        }),
        stub: "order-details",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.orderDetailsSection = null;
          return true;
        }
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true, id);
    },
    /**
     *Convert an amount to USD and round it to cents
     * @param {number} amount - the amount that should be refunded
     * @param {number} exchangeRate - exchange rate from USD to native. So to get the exchange rate _to_ USD, divide.
     */
    getAmountInUSD: function (amount, exchangeRate) {
      return (amount / exchangeRate).toFixed(2);
    },
    /**
     *Update the UI with the new US refund amount on-the-fly when refund amount is edited
     *
     */
    processItemRefundChange: function () {
      var amount = this.$el.refundItemAmount.value;
      amount = amount === "" ? 0 : parseFloat(amount);
      if (typeof amount !== "number" || isNaN(amount)) {
        this.$el.refundItemAmount.classList.add("invalid");
      } else {
        this.$el.refundItemAmount.classList.remove("invalid");
      }
      if (this.exchange_rate_from_usd && this.exchange_rate_from_usd !== "") {
        amount = this.getAmountInUSD(amount, this.exchange_rate_from_usd);
      }
      this.$el.normalizedItemRefundAmount.innerText = amount;
    },
    /**
     * Get the refund amount in the native currency.
     * @param {number} amount - the amount for the refund
     * @param {number} exchangeRate - exchange rate from USD to native
     * @returns {string} the amount in native currency
     */
    getAmountInNativeCurrency: function (amount, exchangeRate) {
      return (amount * exchangeRate).toFixed(2);
    },
    /**
     * Refund a users's order
     * @param {number} id - the id of an order
     * @param {string} amount - the account that should be refunded
     * @param {string} card - the card that was used for order
     * @param {string} datetime - the data of the refund
     * @param {string} lastFour - last four digits of card used
     * @param {string} check_refund_rules - checks for refund rules
     * @param {string} currency_symbol - currency of the user's order
     * @param {number} exchange_rate_from_usd - exchange rate for international localized orders
     * @param {boolean} lrc_order_paid_in_native_currency - true if the the international order was paid in native currency
     */
    refundOrder: function (id, amount, card, datetime, lastFour, check_refund_rules, currency_symbol, exchange_rate_from_usd, lrc_order_paid_in_native_currency) {
      exchange_rate_from_usd = exchange_rate_from_usd || 1;
      var refundAmount = this.getAmountInNativeCurrency(amount, exchange_rate_from_usd);
      var normalizedUSDAmount = amount;

      // For international orders paid in local currency, the `amount` is in the local currency
      // so exchange it to USD and reset the refund amount.
      if (lrc_order_paid_in_native_currency) {
        normalizedUSDAmount = this.getAmountInUSD(amount, exchange_rate_from_usd);
        refundAmount = amount;
      }
      this.$el.normalizedOrderRefundAmount.innerText = normalizedUSDAmount;
      this.$el.currencySymbol.innerText = currency_symbol || "$";
      this.$el.refundOrderId.value = id;
      this.$el.refundAmount.value = refundAmount;
      this.$el.checkRefundRules.value = check_refund_rules;

      // Show refund area
      this.$widget.refundWarningArea.show();
    },
    resendReceipt: function (orderId) {
      this.$el.resendReceiptOrderIdInput.value = orderId;
      this.$widget.resendReceiptWarningArea.show();
    },
    /**
     *Refund a users' order
     * @param {number} id - the id of an item
     * @param {string} amount - the account that should be refunded
     * @param {string} card - the card that was used for order
     * @param {string} datetime - the data of the refund
     * @param {string} currency_symbol - currency of the user's order
     * @param {number} exchange_rate_from_usd - exchange rate for international localized orders
     */
    refundOrderItem: function (id, amount, card, datetime, currency_symbol, exchange_rate_from_usd) {
      // Populate the form
      exchange_rate_from_usd = exchange_rate_from_usd || 1;
      var normalizedUSDAmount = this.getAmountInUSD(amount, exchange_rate_from_usd);
      this.$el.normalizedItemRefundAmount.innerText = normalizedUSDAmount;
      this.$el.itemCurrencySymbol.innerText = currency_symbol || "$";

      // Create event handler for refund amount change
      this.exchange_rate_from_usd = exchange_rate_from_usd;
      this.$el.refundItemAmount.addEventListener("keyup", this.processItemRefundChange.bind(this));
      this.$el.refundItemAmount.addEventListener("change", this.processItemRefundChange.bind(this));
      // Populate the form
      this.$el.refundOrderItemId.value = id;
      this.$el.refundItemAmount.value = amount;

      // Create warning block
      this.$widget.refundItemWarningArea.show();
    },
    /**
     *Refund a users' PAYG order item
     * @param {number} id - the id of an item
     * @param {string} amount - the money amount that should be refunded
     * @param {string} initial_credit_amount - the amount of PAYG credits that the credit originally contained
     * @param {string} credit_amount - the amount of PAYG credits that the credit currently contains
     * @param {string} card - the card that was used for order
     * @param {string} datetime - the data of the refund
     * @param {number} last_four - the last 4 digits of the card to receive the refund
     * @param {string} currency_symbol - currency of the user's order
     * @param {number} exchange_rate_from_usd - exchange rate for international localized orders
     */
    refundOrderItemDeductCredits: function (id, amount, initial_credit_amount, credit_amount, card, datetime, last_four, currency_symbol, exchange_rate_from_usd) {
      // Populate the form
      exchange_rate_from_usd = exchange_rate_from_usd || 1;
      this.$el.normalizedItemDeductCreditsRefundAmount.innerText = this.getAmountInUSD(amount, exchange_rate_from_usd);

      // Populate the form
      this.$el.refundOrderItemDeductCreditsId.value = id;
      this.$el.refundItemDeductCreditsAmount.innerHTML = (currency_symbol || "$") + amount;
      this.$el.refundItemDeductCreditsAmountHelpText.innerHTML = (currency_symbol || "$") + amount;
      this.$el.refundItemHelperCreditsCount.innerHTML = credit_amount + " left of " + initial_credit_amount;
      this.$el.refundItemDeductCreditsCount.innerHTML = credit_amount;
      if (Number(amount) <= 0 || Number(amount) <= 0.0) {
        this.$el.refundItemDeductCreditsSubmitButton.disabled = true;
      }

      // Create warning block
      this.$widget.refundItemDeductCreditsWarningArea.show();
    },
    /**
     * Retry failed order
     * @param {string} id - order id of the failed order
     */
    retryOrder: function (id) {
      this.$el.retryOrderId.value = id;
      this.$el.retryWarningText.innerHTML = "Are you sure you want to retry order " + id + "?";
      this.$widget.retryWarningArea.show();
    },
    /**
     * Retry payment for failed capture
     * @param {string} id - order id of the failed order
     */
    retryPayment: function (id) {
      this.$el.retryPaymentOrderId.value = id;
      this.$el.retryPaymentWarningText.innerHTML = "Are you sure you want to retry order " + id + "?";
      this.$widget.retryPaymentWarningArea.show();
    },
    /**
     * opens a chargeback for the order id passed
     * @param {number} order_id - the order id that should be charged back
     */
    openChargeback: function (order_id) {
      this.$el.openChargebackId.value = order_id;
      this.$el.openChargebackWarningText.innerHTML = "Are you sure you want to open a chargeback on " + "<strong>order " + order_id + "</strong>? <br/>It's sure to disable sending until the user pays it back.";
      this.$widget.openChargebackWarningArea.show();
    },
    /**
     * re-requests a transaction from fonoa for the order id passed
     * @param {number} order_id - the order id that should be sent to fonoa
     */
    resendInvoice: function (order_id) {
      this.$el.resendInvoiceId.value = order_id;
      this.$el.resendInvoiceWarningText.innerHTML = "Are you sure you want to resend the transaction to Fonoa on " + "<strong>order " + order_id + "</strong>?";
      this.$widget.resendInvoiceWarningArea.show();
    },
    /**
     * void a chargeBack
     * @param {number} order_id - the order id
     */
    voidChargeback: function (order_id) {
      this.$el.voidChargebackId.value = order_id;
      this.$el.voidChargebackWarningText.innerHTML = "Are you sure you want to void the chargeback on " + "<strong>order " + order_id + "</strong>?";
      this.$widget.voidChargebackWarningArea.show();
    },
    /**
     * cancel retries
     * @param {number} order_id - the order id
     */
    cancelRetries: function (order_id) {
      this.$el.cancelRetriesId.value = order_id;
      this.$el.cancelRetriesWarningText.innerHTML = "Are you sure you want to cancel retries on <strong>order " + order_id + "</strong>?";
      this.$widget.cancelRetriesWarningArea.show();
    }
  });
});