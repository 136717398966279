define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/InfoBlock", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, /** @var mojo/widgets/InfoBlock */InfoBlock, I18nTranslation, flags, context) {
  var EventsCriteriaInfoBlock = declare([InfoBlock], {
    settingId: "eventsCriteriaInfoBlockAcknowledgement",
    imageSrc: "https://cdn-images.mailchimp.com/product/brand_assets/illos/art-other-choice.png",
    headerText: "Now you can generate personalized messages based on user behavior",
    infoText: "Tailor campaigns based on how users interact with your app.",
    linkText: "Learn more about events in our API guide",
    linkHref: "https://mailchimp.com/developer/guides/create-custom-events/",
    constructor: function () {
      I18nTranslation.initialize("I18N_AUDIENCE_MANAGEMENT_V2").then(function () {
        this.headerText = I18nTranslation.translate("audience_management_segment_edit_title_text");
        this.infoText = I18nTranslation.translate("audience_management_segment_edit_info_text");
        this.linkText = I18nTranslation.translate("audience_management_segment_edit_link_text");
      }.bind(this));
    }
  });
  return EventsCriteriaInfoBlock;
});