define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/ImageWithCaptionBlock", "dojox/dtl", "dojox/dtl/Context", "dojo/text!../editors/blocks/templates/ImageWithCaptionEditor/outputWeb.html"], function (declare, ImageWithCaptionBlock, dtl, dtlContext, tpl) {
  var justifyContentMap = {
    "center": "center",
    "left": "flex-start",
    "right": "flex-end"
  };
  return declare("mojo.neapolitan.web-renderers.ImageWithCaptionBlock", [ImageWithCaptionBlock], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      var captions = [];
      var calculatedWidths = this._getWidths(meta);
      var numOfCaptions = Number(meta.numberOfCaptions);
      for (var i = 0; i < numOfCaptions; i++) {
        var caption = meta.captions[i];
        if (!caption) {
          caption = {
            image: null,
            text: ""
          };
        }
        if (caption.image) {
          if (caption.image && caption.image.width > calculatedWidths.image) {
            caption.image.calculatedWidth = calculatedWidths.image;
          } else {
            caption.image.calculatedWidth = caption.image.width;
          }
        }
        caption.columnWidth = calculatedWidths.columnWidth;
        captions.push(caption);
      }
      return {
        widths: calculatedWidths,
        align: meta.align,
        position: meta.captionPosition,
        captions: captions,
        styleFlexDirection: this._getFlexDirection(meta.captionPosition),
        imageContentClassNames: this._getClassName(meta.captionPosition),
        totalImagePadding: this._getImagePadding(meta.captionPosition, meta.edgeToEdge),
        captionImageContentStyle: this._getCaptionImageContentStyle(this._getImagePadding(meta.captionPosition, meta.edgeToEdge), meta.captionWidth, meta.captionPosition),
        captionContentStyle: this._getCaptionContentStyle(meta.captionWidth, meta.captionPosition),
        captionAlign: this._getCardImageAlign(meta.align),
        containerClasses: this._getContainerClasses(meta),
        innerClass: this._getInnerFlexClass(meta)
      };
    },
    // Styles
    _getContentFlex: function (captionWidth, captionPosition) {
      var captionFlex;
      var imageFlex;
      if (captionPosition === "top" || captionPosition === "bottom") {
        captionFlex = "0 0 auto";
        imageFlex = "0 0 auto";
      } else {
        switch (captionWidth) {
          case "one-third":
            captionFlex = "0 0 30%";
            imageFlex = "0 0 70%";
            break;
          case "two-third":
            imageFlex = "0 0 30%";
            captionFlex = "0 0 70%";
            break;
          case "three-quarter":
            captionFlex = "0 0 75%";
            imageFlex = "0 0 25%";
            break;
          default:
            captionFlex = "0 0 50%";
            imageFlex = "0 0 50%";
            break;
        }
      }
      return {
        caption: captionFlex,
        image: imageFlex
      };
    },
    _getImagePadding: function (position, isEdge2Edge) {
      var totalImagePadding = "padding: 0px;";
      if (!isEdge2Edge) {
        switch (position) {
          case "top":
            totalImagePadding = "padding:9px 9px 0 9px;";
            break;
          case "bottom":
            totalImagePadding = "padding:0 9px 9px 9px;";
            break;
          case "left":
            totalImagePadding = "padding:0 9px;";
            break;
          case "right":
            totalImagePadding = "padding:0 9px;";
            break;
          default:
            throw new Error("Unknown captionPosition");
        }
      }
      return totalImagePadding;
    },
    // Rounded up styles
    _getCaptionImageContentStyle: function (totalImagePadding, captionWidth, captionPosition) {
      return "display:flex; flex:" + this._getContentFlex(captionWidth, captionPosition).image + ";";
    },
    _getCardImageAlign: function (align) {
      var justifyContent = justifyContentMap[align];
      return justifyContent;
    },
    _getCaptionContentStyle: function (captionWidth, captionPosition) {
      return "display:flex; flex:" + this._getContentFlex(captionWidth, captionPosition).caption + ";";
    },
    //TODO: refactor classNames and use flex in those classes.
    _getClassName: function (position) {
      var className = "mcnCaptionBottomImageContent";
      switch (position) {
        case "top":
          className = "mcnCaptionTopImageContent";
          break;
        case "bottom":
          className = "mcnCaptionBottomImageContent";
          break;
        case "left":
          className = "mcnCaptionLeftContentInner";
          break;
        case "right":
          className = "mcnCaptionRightContentInner";
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return className;
    },
    _getContainerClasses: function (meta) {
      var classes = "mcnCaptionBlock";
      var numOfCaptions = Number(meta.numberOfCaptions);
      if ((meta.captionPosition === "left" || meta.captionPosition === "right") && numOfCaptions === 2) {
        classes += " mcnCaptionColumn";
      }
      return classes;
    },
    _getInnerFlexClass: function (meta) {
      if (meta.captionPosition === "left" || meta.captionPosition === "right") {
        return "mcnCaptionColumn";
      }
      return "";
    },
    _getFlexDirection: function (position) {
      var flexDirectionStyle = "column";
      switch (position) {
        case "top":
          flexDirectionStyle = "column-reverse";
          break;
        case "bottom":
          flexDirectionStyle = "column";
          break;
        case "left":
          flexDirectionStyle = "row-reverse";
          break;
        case "right":
          flexDirectionStyle = "row";
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return flexDirectionStyle;
    },
    _getWidths: function (meta) {
      var wholeWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2);
      var imageWidth;
      var captionWidth;
      switch (meta.captionPosition) {
        case "top":
        case "bottom":
          imageWidth = wholeWidth;
          break;
        case "left":
        case "right":
          switch (meta.captionWidth) {
            case "half":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 4) / 2);
              break;
            case "one-third":
              imageWidth = wholeWidth - this.innerPadding * 4;
              break;
            case "two-third":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 3);
              break;
            case "three-quarter":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 4);
              break;
            default:
              throw new Error("Unknown captionWidth");
          }
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return {
        image: imageWidth
      };
    }
  });
});