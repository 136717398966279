define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/Stateful", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/Autocomplete.html"], function (declare, utils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Stateful, lang, dom, domClass, entities, keys, templateString) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Stateful], {
    templateString: templateString,
    /**
     * Default member properties
     */
    wait: 250,
    searchTopic: null,
    placeholderText: "",
    shouldShowSearchDivider: true,
    /*
        Constructor. Maps suggestions into an object for easier retrieval later.
    */
    constructor: function (params) {
      this.suggestionsMap = {};
      this.suggestionOrder = [];

      // If a static list of suggestions is provided, use it to generate the initial suggestion map
      if (params.suggestions) {
        this._generateSuggestionMap(params.suggestions);
      }
    },
    /**
     * Method to map a list of suggestions into an object for easier retrieval later.
     *
     * @param {Object[]} suggestions list of suggestions
     *
     */
    _generateSuggestionMap: function (suggestions) {
      this.suggestionsMap = suggestions.reduce(lang.hitch(function (acc, suggestion) {
        acc[suggestion.value] = suggestion;
        return acc;
      }), {
        search: {
          value: "search",
          label: "{search}",
          icon: "search",
          shouldShowPrompt: true
        }
      });
      this.suggestionOrder = suggestions.map(function (suggestion) {
        return suggestion.value;
      });
    },
    /**
     * Custom stateful setter attribute for placeholderText
     *
     * @param {string} placeholderText new placeholder text
     *
     */
    _setPlaceholderTextAttr: function (placeholderText) {
      if (placeholderText) {
        this.placeholderText = placeholderText;
        this.SearchBox.filteredInput.setAttribute("placeholder", this.placeholderText);
      }
    },
    /*
        List of currently visible suggestion values based on search text.
        When updated, the suggestions menu re-renders.
    */
    visibleSuggestions: [],
    _setVisibleSuggestionsAttr: function (visibleSuggestions) {
      this.visibleSuggestions = visibleSuggestions;
      var searchLinks = document.createDocumentFragment();
      var hasShownDivider = false;
      this.visibleSuggestions.forEach(lang.hitch(this, function (suggestionKey, i) {
        var matchingSuggestion = this.suggestionsMap[suggestionKey];
        matchingSuggestion.domNode = utils.compileTemplate(this.template_result, lang.mixin(lang.clone(matchingSuggestion), {
          label: lang.replace(matchingSuggestion.label, {
            search: entities.encode(this.searchText)
          }),
          icon: matchingSuggestion.icon || "hide",
          promptClass: matchingSuggestion.shouldShowPrompt ? "" : "hide",
          detailsClass: matchingSuggestion.details ? "" : "hide"
        }));
        if (matchingSuggestion.iconImage) {
          dom.place(utils.compileTemplate(this.template_iconImage, {
            iconImage: matchingSuggestion.iconImage
          }), matchingSuggestion.domNode, "first");
        }
        matchingSuggestion.domNode.addEventListener("mousedown", this._selectSuggestion.bind(this, suggestionKey));
        if (i <= 1 && !this.isSearch(suggestionKey) && !hasShownDivider && this.shouldShowSearchDivider) {
          hasShownDivider = true;
          searchLinks.appendChild(utils.compileTemplate(this.template_divider, {}));
        }
        searchLinks.appendChild(matchingSuggestion.domNode);
      }));
      dom.place(searchLinks, this.node_autoCompleteResults, "only");
      this.set("highlightedSuggestionKey", "");
    },
    /*
        The current search text.
        When updated, the visible suggestions are filtered.
    */
    searchText: null,
    _setSearchTextAttr: function (searchText) {
      if (searchText !== this.searchText) {
        if (searchText && searchText.charAt(0) !== "%") {
          this.searchText = decodeURI(searchText || "");
          this.onTextSearch(this.searchText);
        } else {
          this.searchText = searchText || "";
        }
        this.SearchBox.setValue(this.searchText);
        this._setVisibleSuggestionsBasedOnSearchText();
        this.set("shouldShowSuggestions", this.isFocused && (!this.shouldOnlySuggestOnFocus || !this.searchText));
      }
    },
    /*
        The suggestion currently highlighted in the menu.
        When updated, the corresponding DOM element is highlighted.
    */
    highlightedSuggestionKey: "",
    _setHighlightedSuggestionKeyAttr: function (highlightedSuggestionKey) {
      this.highlightedSuggestionKey = highlightedSuggestionKey;
      this.visibleSuggestions.forEach(lang.hitch(this, function (suggestionKey, i) {
        domClass.toggle(this.suggestionsMap[suggestionKey].domNode, "c-autocomplete_suggestion--highlighted", suggestionKey === this.highlightedSuggestionKey);
      }));
    },
    /*
        The suggestion being applied to the search box.
        When updated, the corresponding search text is updated with the suggestion's corresponding label.
    */
    selectedSuggestion: "",
    _setSelectedSuggestionAttr: function (selectedSuggestion) {
      this.selectedSuggestion = selectedSuggestion;
      if (this.suggestionsMap[selectedSuggestion]) {
        this.set("searchText", this.suggestionsMap[selectedSuggestion].label);
      } else if (!selectedSuggestion) {
        this.set("searchText", "");
      }
    },
    /*
        Should the suggestions be shown?
        When updated, the suggestion list is shown or hidden.
    */
    shouldShowSuggestions: false,
    _setShouldShowSuggestionsAttr: function (shouldShowSuggestions) {
      this.shouldShowSuggestions = shouldShowSuggestions;
      domClass.toggle(this.node_autoCompleteResults, "hide", !shouldShowSuggestions);
    },
    /**
     * Helper function that sets list of visible suggestions based on search text.
     */
    _setVisibleSuggestionsBasedOnSearchText: function () {
      if (this.suggestionFetchingFunction) {
        this.suggestionFetchingFunction(this.searchText).then(function (suggestions) {
          this._generateSuggestionMap(suggestions);
          this.set("visibleSuggestions", this.suggestionOrder);
          this.onSuggestionsChanged(suggestions.length, this.searchText);
        }.bind(this));
      } else {
        this.set("visibleSuggestions", (this.searchText ? ["search"] : []).concat(this.suggestionOrder).filter(lang.hitch(this, function (suggestionKey) {
          var matchingSuggestion = this.suggestionsMap[suggestionKey];
          return !this.searchText && this.shouldSuggestInitially(matchingSuggestion) || this.isSearch(suggestionKey) || this.searchText && this.shouldSuggestInSearches(matchingSuggestion) && matchingSuggestion.label.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        })));
      }
    },
    /*
        When a suggestion is chosen, hide suggestions and call appropriate callback
    */
    _selectSuggestion: function (suggestion, event) {
      event.preventDefault();
      this.SearchBox.filteredInput.blur();
      this.set("shouldShowSuggestions", false);
      var selectedSuggestion = suggestion || this.highlightedSuggestionKey;
      if (!selectedSuggestion || this.isSearch(selectedSuggestion)) {
        this.onTextSearch(this.SearchBox.filteredInput.value);
      } else {
        this.onSuggestionSelected(this.suggestionsMap[selectedSuggestion]);
      }
    },
    /*
        Keyboard navigation. When keypress happens, highlight next or previous suggestion.
    */
    _onKeyDown: function (event) {
      var keyCode = event.charCode || event.keyCode;
      if (keyCode === keys.DOWN_ARROW || keyCode === keys.UP_ARROW) {
        event.preventDefault();
        var direction = keyCode === keys.DOWN_ARROW ? 1 : -1;
        this.set("highlightedSuggestionKey", this.visibleSuggestions[this.visibleSuggestions.indexOf(this.highlightedSuggestionKey) + direction] || this.highlightedSuggestionKey);
      } else if (this.highlightedSuggestionKey && keyCode !== keys.ENTER) {
        //Un-highlight suggestion while typing
        this.set("highlightedSuggestionKey", "");
      }
    },
    /*
        Is a "search" being suggested?
    */
    isSearch: function (suggestionName) {
      return suggestionName === "search";
    },
    /*
        Method to manually clear internal search box
    */
    clear: function () {
      this.SearchBox._clearSearch();
    },
    /*
        Method to be called after search box is cleared
    */
    onClear: function () {},
    /*
        Method to be called when a suggestion is selected
    */
    onSuggestionSelected: function () {},
    /**
     * Test whether or not a suggestion should be shown initially.
     *
     * @param {Object} suggestion suggestion object being tested
     *
     * @returns {boolean} whether or not the suggestion should show on initial suggestion list
     */
    shouldSuggestInitially: function (suggestion) {
      return true;
    },
    /**
     * Test whether or not a suggestion should be shown in search results.
     *
     * @param {Object} suggestion suggestion object being tested
     *
     * @returns {boolean} whether or not the suggestion should show in searches.
     */
    shouldSuggestInSearches: function (suggestion) {
      return true;
    },
    /*
        Method to be called when a text search is initiated
    */
    onTextSearch: function () {},
    /**
     * Method to be called when search box is focused
     *
     * @param  {Element} focusedElement dom element that was focused
     */
    onFocus: function (focusedElement) {},
    /*
        Post Create Method. Bind events, set initial search text.
    */
    /**
     * Method to be called when search box is focused
     *
     * @param  {Number} suggestionsLength suggestion array length
     * @param  {String} searchText input field value
     */
    onSuggestionsChanged: function (suggestionsLength, searchText) {},
    setNewSuggestions: function (suggestions) {
      this._generateSuggestionMap(suggestions);
      this.set("visibleSuggestions", this.suggestionOrder);
    },
    postCreate: function () {
      this.inherited(arguments);
      this.set("searchText", "");

      //Bind change/clear events
      this.SearchBox.onChange = this.set.bind(this, "searchText");
      this.SearchBox.onClear = lang.hitch(this, function () {
        this.set("searchText", "");
        this.onClear();
      });

      //Bind focus/blur/keydown events
      this.SearchBox.onBlur = lang.hitch(this, function () {
        this.set("isFocused", false);
        this.set("shouldShowSuggestions", false);
      });
      this.SearchBox.onFocus = lang.hitch(this, function () {
        this.set("isFocused", true);
        var shouldShowSuggestions = this.visibleSuggestions.length > 0 && (!this.shouldOnlySuggestOnFocus || !this.searchText);
        if (shouldShowSuggestions) {
          //Refresh search suggestions before showing
          this._setVisibleSuggestionsBasedOnSearchText();
        }
        this.set("shouldShowSuggestions", shouldShowSuggestions);
        this.onFocus(this.SearchBox.filteredInput);
      });
      this.SearchBox.onKeyDown = this._onKeyDown.bind(this);

      //Bind This Component's Submit Method
      this.domNode.addEventListener("submit", this._selectSuggestion.bind(this, null));
    }
  });
});