define(["/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js", // require, require.toUrl
"dojo-proxy-loader?name=dojo/_base/config!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // config.blankGif
"dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domClass.add
"dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domStyle.getComputedStyle
"dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/domReady!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module" // win.body
], function (dojoRequire, config, domClass, domStyle, has, domReady, win) {
  // module:
  //		dojo/hccss

  /*=====
  return function(){
  	// summary:
  	//		Test if computer is in high contrast mode (i.e. if browser is not displaying background images).
  	//		Defines `has("highcontrast")` and sets `dj_a11y` CSS class on `<body>` if machine is in high contrast mode.
  	//		Returns `has()` method;
  };
  =====*/

  // Has() test for when background images aren't displayed.  Don't call has("highcontrast") before dojo/domReady!.
  has.add("highcontrast", function () {
    // note: if multiple documents, doesn't matter which one we use
    var div = win.doc.createElement("div");
    div.style.cssText = "border: 1px solid; border-color:red green; position: absolute; height: 5px; top: -999px;" + "background-image: url(\"" + (config.blankGif || dojoRequire.toUrl("dojo/resources/blank.gif")) + "\");";
    win.body().appendChild(div);
    var cs = domStyle.getComputedStyle(div),
      bkImg = cs.backgroundImage,
      hc = cs.borderTopColor == cs.borderRightColor || bkImg && (bkImg == "none" || bkImg == "url(invalid-url:)");
    if (has("ie") <= 8) {
      div.outerHTML = ""; // prevent mixed-content warning, see http://support.microsoft.com/kb/925014
    } else {
      win.body().removeChild(div);
    }
    return hc;
  });
  domReady(function () {
    if (has("highcontrast")) {
      domClass.add(win.body(), "dj_a11y");
    }
  });
  return has;
});