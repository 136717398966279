/*
    [SMU-105] This function is created in order to separate the logic to check for any role-based addresses in the local-part of an email.
    It was originally implemented inside "validation/email.js" but decided to separate it because "web/js/mojo/widgets/checklist-editor/items/From.js" allows role-based addresses.
    "web/js/mojoviews/signup/index.js" combines this logic with "validation/email.js" functions to prevent users from typing invalid email during the signup process.
*/

define(["mojo/utils/I18nTranslation"], function (I18nTranslation) {
  return function validateLocalPart(str) {
    var split = str.split("@");
    var username = split[0];

    // Source: app/lib/MC/Validation.php
    var ROLE_ADDRESSES = ["abuse", "admin", "billing", "compliance", "devnull", "dns", "ftp", "hostmaster", "inoc", "ispfeedback", "ispsupport", "list-request", "list", "maildaemon", "noc", "no-reply", "noreply", "null", "phish", "phishing", "postmaster", "privacy", "registrar", "root", "security", "spam", "support", "sysadmin", "tech", "undisclosed-recipients", "unsubscribe", "usenet", "uucp", "webmaster", "www"];
    var isRoleBasedAddress = false;
    ROLE_ADDRESSES.forEach(function (role) {
      if (username.toLowerCase() === role) {
        isRoleBasedAddress = true;
      }
    });
    if (isRoleBasedAddress) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("non_role_based_email", [username]));
      }
      return new Error("Sorry, role-based addresses like " + username + "@ are not allowed. Please use a different email address.");
    }
    return null;
  };
});