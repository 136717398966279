define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "vendor/lodash-amd/find", "mojo/widgets/Renderable", "mojo/widgets/SegmentDropdown", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/checklist-editor/SendNow", "mojo/widgets/checklist-editor/items/ReconfirmDialog", "mojo/widgets/checklist-editor/items/BounceWarningDialog", "mojo/widgets/checklist-editor/items/AcceptTermsDialog", "mojo/widgets/checklist-editor/CampaignChecklistItemRec", "mojo/views/campaigns/wizard/confirm", "dijit/Dialog", "mojo/api/member-list", "mojo/api/campaign", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils", "mojo/url", "dojo/text!./templates/recipients.html"], function (declare, lang, domConstruct, string, on, topic, registry, find, Renderable, SegmentDropdown, ItemButtons, SendNow, ReconfirmDialog, BounceWarningDialog, AcceptTermsDialog, CampaignChecklistItemRec, Confirm, Dialog, memberListApi, campaignApi, user, flags, logger, utils, mUrl, tpl) {
  var TOPIC_RECS_CHECKLIST_EDITOR = "recs/checklist_editor";
  var Recipients = declare([Renderable], {
    _cachedListSegmentPairs: {},
    templateString: tpl,
    state: {
      SegmentDropdown: SegmentDropdown,
      user: user,
      listOptions: [],
      listId: null,
      listName: "",
      segment: null,
      count: null,
      isPersonalizedToInfoDisplayed: false,
      personalizedToTagIsSupportedByDropdown: false,
      personalizedToOptions: null,
      isEmpty: false,
      isImportingList: false,
      displayedRecs: {},
      previouslyActivatedRecipients: false,
      recommendationSuccess: false,
      hasSeenRecipientsRec: false,
      hasSeenRecipientsSuccess: false,
      activeSegmentSelector: '',
      selectedPrebuiltSegment: ''
    },
    defaultAudienceId: null,
    constructor: function (props) {
      this.defaultAudienceId = props.defaultAudienceId;
      if (this.defaultAudienceId && props.model.recipients) {
        props.model.recipients.listId = this.defaultAudienceId;
        // EMG-1269 Update campaign's list id with default audience id
        props.model.patch({
          recipients: {
            listId: this.defaultAudienceId
          }
        });
      }
      var campaign = props.model;
      this.campaign = campaign;
      this.initializeRecommendationTopic();

      // we want to show the empty state when a user account has less than 2 contacts in their audience
      // but only if they haven't already set recipients for this campaign with a list that has at least 1 contact.
      if (!props.userAgeExempt && (props.audienceSize < 2 && typeof campaign.recipients.listId === "undefined" || props.audienceSize < 1)) {
        this.state.listErrorMessage = null;
        this.state.isEmpty = true;
      } else if (campaign.errorState && campaign.errorState.scope === "list") {
        this.state.listErrorMessage = campaign.errorState;
      } else {
        this.state.listErrorMessage = null;
      }
      this.state.isImportingList = this.isImportingList(props);
      if (campaign.recipients.listId) {
        this.state.listId = campaign.recipients.listId;
        this.state.listName = campaign.recipients.listName;
        this.state.count = campaign.recipients.count;
        this.state.isValid = !!this.state.listId;
      }
      this.state.segment = campaign.recipients.segment;
      this.state.activeSegmentSelector = this.getActiveSegmentSelector(campaign.recipients.segment);
      this.state.selectedPrebuiltSegment = this.getSelectedPrebuiltSegment(campaign.recipients.segment);
      this.state.mergeFields = campaign.list ? campaign.list.mergeFields : [];
      this.state.settings = {
        personalizeToTag: campaign.recipients.personalizeToTag,
        personalizeTo: campaign.recipients.personalizeToTag !== ""
      };
      this.state.getSegmentOptionForSegment = Recipients.getSegmentDropdownOptionValueFromSegment;

      // Reconfirm Dialog
      this.reconfirmDialogContent = new ReconfirmDialog({
        model: props.model,
        onSave: props.onSave,
        openDialog: this.openReconfirmDialog.bind(this),
        closeDialog: this.closeReconfirmDialog.bind(this),
        reconfirmURL: mUrl.toUrl("/campaigns/reconfirm-egp", {
          id: props.model.campaignId
        })
      });
      this.reconfirmDialog = new Dialog({
        title: "Reconfirm Your New Addresses",
        content: this.reconfirmDialogContent
      });

      // Bounce Warning dialog (shown before Accept Terms dialog)
      this.bounceWarningDialogContent = new BounceWarningDialog({
        model: props.model,
        onSave: props.onSave,
        openDialog: this.openBounceWarningDialog.bind(this),
        openAcceptTermsDialog: this.openAcceptTermsDialog.bind(this),
        closeDialog: this.closeBounceWarningDialog.bind(this)
      });
      this.bounceWarningDialog = new Dialog({
        title: "Bounce and List Deletion Warning",
        content: this.bounceWarningDialogContent
      });

      // Accept Terms dialog (accessible as step 2 of Bounce Warning Dialog)
      this.acceptTermsDialogContent = new AcceptTermsDialog({
        model: props.model,
        onSave: this.saveAcceptTermsDialog.bind(this),
        openDialog: this.openAcceptTermsDialog.bind(this),
        closeDialog: this.closeAcceptTermsDialog.bind(this)
      });
      this.acceptTermsDialog = new Dialog({
        title: "Accept Terms",
        content: this.acceptTermsDialogContent
      });
      this.addPersistentExtraProps = props.addPersistentExtraProps;
      if (props.previouslyActivatedRecipients) {
        this.state.previouslyActivatedRecipients = props.previouslyActivatedRecipients;
        this.state.hasSeenRecipientsRec = props.hasSeenRecipientsRec;
        this.state.hasSeenRecipientsSuccess = props.hasSeenRecipientsSuccess;
      }
      if (props.previouslyActivatedRecipients && this.state.settings.personalizeToTag !== "") {
        this.state.recommendationSuccess = true;
      } else if (this.state.settings.personalizeToTag === "") {
        this.state.recommendationSuccess = false;
      }
    },
    openReconfirmDialog: function () {
      this.reconfirmDialog.show();
    },
    closeReconfirmDialog: function () {
      this.reconfirmDialog.hide();
    },
    openBounceWarningDialog: function () {
      this.bounceWarningDialog.show();
    },
    closeBounceWarningDialog: function () {
      this.bounceWarningDialog.hide();
    },
    openAcceptTermsDialog: function () {
      this.acceptTermsDialog.show();
    },
    closeAcceptTermsDialog: function () {
      this.acceptTermsDialog.hide();
      this.closeBounceWarningDialog();
    },
    saveAcceptTermsDialog: function () {
      this.closeAcceptTermsDialog();
      var confirm = new Confirm();
      confirm.acceptEgpTerms(this.state.model.campaignId, this.openAcceptTermsDialog.bind(this));
    },
    openPaywallWithSendTimeLimitGATracking: function () {
      window.ga("send", "event", "upsell_moment", "button_click", JSON.stringify({
        location: "campaigns",
        upgrade_reason: "send_limit",
        button_text: "resolve"
      }));
      window.top.location.href = "/checkout/plan?redirect=/campaigns/edit?id=" + this.model.campaignId;
    },
    willUpdate: function (prevState, nextState) {
      if (this.isActive) {
        campaignApi.CampaignRecipients.cancelRecipientCount();
      } else if (this.model.recipients.needsRecipientCount()) {
        campaignApi.CampaignRecipients.requestRecipientCount(this.model).then(lang.hitch(this, function () {
          this.onSave();
        }));
      }
    },
    postCreate: function () {
      // If we end up keeping the prebuilt segments experiment in production, we can move the props-heavy widgets in here (i.e, the cards)
      this.inherited(arguments);
      memberListApi.cachedList().then(this.setListOptions.bind(this));
    },
    _getCustomData: function () {
      var customSegmentContainer = registry.byNode(this.customSegmentContainer);
      return customSegmentContainer.getData();
    },
    getData: function () {
      var personalizedToData = registry.byNode(this.personalizedToContainer).getData();
      var data = {
        recipients: {
          listId: this.state.listId,
          segment: this.state.segment,
          personalizeToTag: personalizedToData
        }
      };
      if (this.isCustomSegment()) {
        data.recipients.segment = this._getCustomData();
      }
      return data;
    },
    getOptionMeta: function (option) {
      var meta = option.totalSubscribers.toLocaleString() + " subscribed contacts";
      if (option.hasEcommerceStore) {
        meta += " &middot; Store connected";
      }
      return meta;
    },
    setListOptions: function (response) {
      this.setState({
        listOptions: response
      });
    },
    getActiveSegmentSelector: function (segment) {
      // segment can be 'null' if 'Entire Audience' has been selected.
      var segmentOption = 'entireAudience';
      if (segment && segment.type) {
        switch (segment.type) {
          case 'prebuilt':
            segmentOption = 'prebuiltFilter';
            break;
          default:
            segmentOption = 'otherFilter';
        }
      }
      return segmentOption;
    },
    getSelectedPrebuiltSegment: function (segment) {
      if (segment && segment.type && segment.type === 'prebuilt') {
        return segment.id;
      }
      return '';
    },
    onListChange: function (listId) {
      var nextState = {
        listId: Number(listId),
        isValid: !!listId
      };
      nextState.settings = {
        personalizeToTag: "",
        personalizeTo: this.personalizedToContainer ? registry.byNode(this.personalizedToContainer).state.settings.personalizeTo : this.state.settings.personalizeTo
      };
      for (var i = 0; i < this.state.listOptions.length; i++) {
        if (Number(listId) === Number(this.state.listOptions[i].listId)) {
          nextState.mergeFields = this.state.listOptions[i].mergeFields;
        }
      }
      if (this.state.listId) {
        this._cachedListSegmentPairs[this.state.listId] = {
          segment: this.isCustomSegment() ? this._getCustomData() : this.state.segment
        };
      }
      if (this._cachedListSegmentPairs[nextState.listId]) {
        var cached = this._cachedListSegmentPairs[nextState.listId];
        nextState.segment = cached.segment;
      } else {
        nextState.segment = null;
      }
      window.ga("send", "event", "campaign_checklist", "click", "choose_audience");
      this.setState(nextState);
    },
    setActiveSegmentSelector: function (e, activeSegmentSelectorName) {
      var nextState = {};
      nextState.activeSegmentSelector = activeSegmentSelectorName;
      if (activeSegmentSelectorName !== 'prebuiltFilter') {
        nextState.selectedPrebuiltSegment = '';
      }
      this.setState(nextState);
    },
    setSelectedPrebuiltSegment: function (prebuiltSegmentName) {
      var nextState = {};
      nextState.selectedPrebuiltSegment = prebuiltSegmentName;
      this.setState(nextState);
    },
    setSegmentOption: function (segmentOption) {
      window.ga("send", "event", "segments", "change", "choose_segment-" + segmentOption);
      var nextState = {};
      if (segmentOption === SegmentDropdown.OPTION_ENTIRE_LIST) {
        nextState = {
          segment: null
        };
      } else if (segmentOption === SegmentDropdown.OPTION_CUSTOM_SEGMENT) {
        var properties = {
          org: "sbseg",
          purpose: "prod",
          scope: "mc",
          initiative_name: "audience_organization",
          scope_area: "bulk_campaigns",
          screen: "campaigns/edit",
          object: "segment",
          object_detail: "create_segment",
          action: "started",
          ui_object: "button",
          ui_object_detail: "create_segment",
          ui_action: "clicked",
          ui_access_point: "segments_dropdown",
          campaign_id: this.campaign.campaignId ? this.campaign.campaignId : null,
          segmentation_method: "regular"
        };

        // Overwrite default segmentation method for pro users
        if (user.pro) {
          properties.segmentation_method = "advanced";
        }
        // Publish to Events Pipeline
        logger.eventsPipeline.publish("segment:started", properties);
        nextState = {
          segment: {
            type: "custom",
            value: {
              conditions: [],
              match: "any"
            }
          }
        };
      } else {
        var segmentOptionParts = segmentOption.split(":");
        var type = segmentOptionParts[0];
        var id = segmentOptionParts[1];
        nextState = {
          segment: {
            type: type,
            id: id
          }
        };
      }
      var personalizedToStateSource = this.personalizedToContainer ? registry.byNode(this.personalizedToContainer).state.settings : this.state.settings;
      nextState.settings = {
        personalizeToTag: personalizedToStateSource.personalizeToTag,
        personalizeTo: personalizedToStateSource.personalizeTo
      };
      this.setState(nextState);
    },
    openRecipientsPopup: function (event) {
      event.preventDefault();
      var url = mUrl.toUrl("campaigns/wizard/recipient-popup?id=" + this.model.campaignId);
      setTimeout(function () {
        window.ga("send", "event", "campaign_checklist", "click", "view:add_recipients");
        var win = window.open(url, "_blank", "toolbar=0,scrollbars=1,location=0,status=1,menubar=0,resizable=1,width=600,height=400");
        utils.checkPopupWin(win);
      }, 0);
      return false;
    },
    isCustomSegment: function () {
      var segment = this.state.segment;
      return segment && (segment.type === "pasted" || segment.type === "custom" || segment.type === "custom_pro");
    },
    /**
     * Performs rendering instructions, analytics and other event handling for recommendations
     * in the checklist editor DOM tree via Dojo pubsub
     */
    initializeRecommendationTopic: function () {
      // Decide when to show the recommended for you flag on the personalized to
      // checkbox & display the rec message if it isn't followed
      // Static recs are being handled by campaignController:getRecommendations
      var self = this;
      var publish = function (message) {
        topic.publish(TOPIC_RECS_CHECKLIST_EDITOR, message);
      };
      var ownRecCodes = [];
      var handleCodeResponseMessage = function (recCodes) {
        ownRecCodes = recCodes;
        publish({
          type: "ready"
        }); // expect to receive instructions on which recs to display
      };
      var handleRenderMessage = function (renderableRecCodes) {
        if (!ownRecCodes.length) return;
        var displayedRecs = {};
        ownRecCodes.forEach(function (recCode) {
          if (!renderableRecCodes[recCode]) return;
          displayedRecs[recCode] = renderableRecCodes[recCode];
        });
        self.setState({
          displayedRecs: displayedRecs
        });
      };
      topic.subscribe(TOPIC_RECS_CHECKLIST_EDITOR, function (message) {
        switch (message.type) {
          case "render":
            return handleRenderMessage(message.payload);
          case "recipients_rec_codes":
            return handleCodeResponseMessage(message.payload);
          default:
            return null;
        }
      });
      publish({
        type: "request_codes",
        payload: "recipients"
      });
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.itemButtons = new ItemButtons({
          label: "Recipients",
          getData: this.getData.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          activate: this.activate,
          isActive: this.isActive,
          isValid: this.state.listId ? true : this.state.isValid
        });
        this.itemButtons.placeAt(this.itemButtonsContainer);
      }
    },
    isImportingList: function (props) {
      var model = props.model;
      var listId = model.list ? model.list.listId : null;
      var importedListId = props.importedListId;
      var importStatus = props.importStatus;
      var shouldShowLoading = listId && listId === importedListId && importStatus === "working";
      return shouldShowLoading === true;
    }
  });
  Recipients.getSegmentDropdownOptionValueFromSegment = function (segment) {
    var segmentOption = SegmentDropdown.OPTION_ENTIRE_LIST;
    if (segment !== null) {
      var type = segment.type;
      switch (type) {
        case "saved":
          segmentOption = "saved:" + segment.id;
          break;
        case "prebuilt":
          segmentOption = "prebuilt:" + segment.id;
          break;
        case "saved_pro":
          segmentOption = "saved_pro:" + segment.id;
          break;
        case "static":
          segmentOption = "static:" + segment.id;
          break;
        case "pasted":
          segmentOption = "pasted:" + segment.id;
          break;
        case "custom":
        case "custom_pro":
        case "saved_custom":
          segmentOption = SegmentDropdown.OPTION_CUSTOM_SEGMENT;
          break;
        default:
          throw new Error("Cannot construct recipients item for unknown segment type");
      }
    }
    return segmentOption;
  };

  // Static methods used by the checklist item
  Recipients.isComplete = function (model) {
    if (this.extraProps.defaultAudienceId) {
      return true;
    }
    return !!model.recipients.listId;
  };
  Recipients.isValid = function (model) {
    var hasListId = !!model.recipients.listId;
    var hasRecipients = model.recipients.count > 0;
    var recipientsAreValid = hasListId && hasRecipients;
    var listHasComplianceIssues = model.list && model.list.complianceStatus.hasBlockingIssues();
    var campaignHasComplianceIssues = false;
    if (user.canSendFree) {
      campaignHasComplianceIssues = model.complianceStatus.hasBlockingIssues();
    }
    var hasAnyComplianceIssues = listHasComplianceIssues || campaignHasComplianceIssues;
    return recipientsAreValid && hasAnyComplianceIssues === false && model.list.isDeleted === false;
  };
  Recipients.isPending = function (model) {
    var listId = model.list ? model.list.listId : null;
    var importedListId = this.extraProps ? this.extraProps.importedListId : null;
    var importStatus = this.extraProps ? this.extraProps.importStatus : null;
    var shouldShowLoading = listId && listId === importedListId && importStatus === "working";
    return shouldShowLoading === true || model.recipients.needsRecipientCount();
  };
  Recipients.activate = function () {
    this.addPersistentExtraProps({
      previouslyActivatedRecipients: true
    });
    this.setState({
      isActive: true
    });
    this.onActivate();
  };
  Recipients.cancel = function (e) {
    if (e) {
      e.preventDefault();
    }
    this.createNewContentWidget();
    this.deactivate();
    this.setState({
      errors: {}
    });
  };
  return Recipients;
});