define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/Pusher", "mojo/url", "mojo/app/wizard"], function (declare, base, dom, query, lang, xhr, mPusher, mUrl, wizard) {
  return declare([base], {
    // inserted into the controller with jsAttrs
    shopifyChannel: null,
    siteId: null,
    onReady: function () {
      // Initialize Pusher
      this._initializePusher();
      window.setTimeout(lang.hitch(this, function () {
        xhr(mUrl.toUrl("account/connected-sites/wizard/store-sync-status?id=" + this.siteId), {
          "handleAs": "json"
        }).then(lang.hitch(this, function (response) {
          if (response.complete) {
            this.goToNextStep();
          }
        }));
      }), 3000);
    },
    goToNextStep: function () {
      var button = query(".goToNextStepContainer a")[0];
      wizard.goToNextStep(button);
    },
    _initializePusher: function () {
      var self = this;
      mPusher.load().then(function (pusher) {
        self.channel = pusher.subscribe(self.shopifyChannel);
        self.channel.bind("update", this.goToNextStep, this);
      });
    }
  });
});