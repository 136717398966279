define([], function () {
  var FACEBOOK = {
    networkName: "Facebook",
    OAuthUrl: "/facebook/login?redir=social-posts-edit|{socialPostId}&source_of_request=facebook_integration_link",
    noProfilesUrl: "https://mailchimp.com/help/connect-or-disconnect-the-facebook-integration/",
    profileName: "page",
    networkIcon: "https://cdn-images.mailchimp.com/product/facebook_organic_social.png"
  };
  var INSTAGRAM = {
    networkName: "Instagram",
    OAuthUrl: "/facebook/login?redir=social-posts-edit|{socialPostId}&source_of_request=facebook_integration_link",
    noProfilesUrl: "https://mailchimp.com/help/add-instagram-facebook-page/",
    profileName: "profile",
    networkIcon: "https://cdn-images.mailchimp.com/product/instagram_organic_social.png"
  };
  var TWITTER = {
    networkName: "Twitter",
    OAuthUrl: "/account/integrations/twitter-oauth-authorize?redir=social-posts-edit|{socialPostId}",
    profileName: "profile",
    networkIcon: "https://cdn-images.mailchimp.com/product/twitter_organic_social.png"
  };
  var NETWORKS = {
    facebook: FACEBOOK,
    instagram: INSTAGRAM,
    twitter: TWITTER
  };
  return NETWORKS;
});