define(["dojo/Stateful", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "mojo/user", "mojo/lib/flags", "mojo/utils/number",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Widgets
"dijit/form/InlineDropDownButton", "dijit/DropDownMenu", "dijit/MenuItem",
// Templates
"dojo/text!./inline-audience-switcher.html"], function (Stateful, declare, lang, domAttr, domClass, domConstruct, on, request, mUrl, mUser, flags, numberUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, InlineDropDownButton, DropDownMenu, MenuItem, tplString) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplString,
    allAudiencesKey: "all",
    allAudiencesLabel: "All audiences",
    audienceList: [],
    contactCount: null,
    currentAudienceId: null,
    currentAudienceName: null,
    elcEnabled: false,
    isDisabled: false,
    hasLinkToViewAudiences: false,
    isAllAudiencesEnabled: false,
    isAllSelected: false,
    onStartupCallback: null,
    onAudienceOpenCallback: null,
    onSwitchAudienceCallback: null,
    location: "",
    // Passed in through widget declaration
    mc_data: [],
    /**
     * Perform appropriate setup actions
     */
    postCreate: function () {
      this._fetchAndHydrate();
    },
    /**
     * Enable / disable audience selection.
     * @param {boolean} isDisabled - whether the audience selction should be disabled
     */
    setDisabled: function (isDisabled) {
      this.isDisabled = isDisabled;
      if (this.dropdownButton) {
        this.dropdownButton.setDisabled(isDisabled);
        this.dropdownButton.setLabel(isDisabled ? this.allAudiencesLabel : this.currentAudienceName);
      }
    },
    /**
     * Handle open / close of the dropdown menu
     * @private
     */
    _onMenuToggleCallback: function () {
      domClass.toggle(this.switcherContainer, "dropdown-menu-open");
    },
    /**
     * Fetch the data pertaining to this widget and re-hydrate the widget
     * @private
     */
    _fetchAndHydrate: function () {
      var requestUrl = "/lists/switcher/get-switcher-data";
      var dataRequest = request.get(requestUrl, {
        handleAs: "json"
      });
      dataRequest.then(lang.hitch(this, function (response) {
        this.isAllSelected = response.isAllSelected;
        this.audienceList = response.audienceList;
        this.elcEnabled = response.isElcEligible;

        // Set up the UI for the case where all audiences is persisted
        if (this.isAllAudiencesEnabled && this.isAllSelected) {
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", this.allAudiencesKey);
          this.currentAudienceName = this.allAudiencesLabel;
        } else {
          this.contactCount = numberUtils.toFormat(response.contactCount, null, null, ",");
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", response.currentAudienceId);
          this.currentAudienceName = response.currentAudienceName;
        }

        // Call the passed-in startup callback function
        if (this.onStartupCallback) {
          this.onStartupCallback(this.currentAudienceId);
        }

        // Set up the UI for the case where all audiences is persisted
        if (this.isAllAudiencesEnabled && this.isAllSelected) {
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", this.allAudiencesKey);
          this.currentAudienceName = this.allAudiencesLabel;
        } else {
          this.contactCount = numberUtils.toFormat(response.contactCount, null, null, ",");
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", response.currentAudienceId);
          this.currentAudienceName = response.currentAudienceName;
          this.subscriberCount = numberUtils.toFormat(response.subscriberCount, null, null, ",");
          if (mUser.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
            this.translatedContactTerm = this.contactCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_contact"] : this.translatedTerms["audience_managment_switcher_info_sentence_contacts"];
          }
        }

        // Setup all UI
        if (this.audienceList.length > 1) {
          this._injectData();
          this._setupSwitcher();
        } else if (this.audienceList.length === 1) {
          this._injectData();
          domClass.add(this.container, "single-audience");
          this.singleAudienceName.innerText = this.currentAudienceName;
          domClass.remove(this.singleAudienceName, "hide");
        }
      }));
    },
    /**
     * Inject response data into the template
     * @private
     */
    _injectData: function () {
      // We do not have data to inject if the user is viewing "All audiences"
      if (!(this.isAllAudiencesEnabled && this.isAllSelected) && !this.isDisabled) {
        //Links to the contact table are not active if the user is not a manager or higher
        if (mUser.hasRole("manager")) {
          //Setup link to view all contacts (via the contact table)
          this.contactsLink.innerText = this.contactCount;
          var path = this.elcEnabled ? "audience/contacts?clear_segment=true&id=" + this.currentAudienceId : "lists/members/clear-and-view?id=" + this.currentAudienceId;
          domAttr.set(this.contactsLink, "href", path);

          //Setup link to view all subscribers (via setting the session segment)
          this.subscribersLink.innerText = this.subscriberCount;
          on(this.subscribersLink, "click", lang.hitch(this, function () {
            this._onSubscribersClick();
          }));
          domClass.remove(this.audienceData, "hide");
        } else {
          this.contactsDisplay.innerText = this.contactCount;
          this.subscribersDisplay.innerText = this.subscriberCount;
          domClass.remove(this.audienceDataReadOnly, "hide");
        }
      } else {
        // Hide data containers when viewing "All audiences"
        domClass.add(this.audienceData, "hide");
        domClass.add(this.audienceDataReadOnly, "hide");
      }
    },
    /**
     * Set up the state where 2+ audiences exist
     * A dropdown menu is shown with the current audience as the selected option
     * @private
     */
    _setupSwitcher: function () {
      domConstruct.empty(this.switcher);
      var onMenuToggle = this._onMenuToggleCallback.bind(this);
      var self = this;
      var dropdown = new DropDownMenu({
        autoFocus: true,
        style: "display: none;",
        onOpen: function () {
          onMenuToggle();
          self.onAudienceOpenCallback();
        },
        onClose: onMenuToggle
      });

      /**
       * Add each audience to the dropdown
       */
      this.audienceList.forEach(lang.hitch(this, function (audience) {
        if (audience.id !== this.currentAudienceId) {
          dropdown.addChild(new MenuItem({
            label: "<p class='margin-bottom--lv0'>" + audience.name + "</p>",
            onClick: lang.hitch(this, function () {
              this._onAudienceClick(audience);
            })
          }));
        }
      }));

      /**
       * Render the "All audiences" button if enabled
       */
      if (this.isAllAudiencesEnabled && !this.isAllSelected && this.audienceList.length > 1) {
        dropdown.addChild(new MenuItem({
          label: "<p class='font-weight--bold margin-bottom--lv0'>All audiences</p>",
          onClick: lang.hitch(this, function () {
            this._onAudienceClick("all");
          })
        }));
      }

      /**
       * Render the "View audiences" link if enabled
       */
      if (this.hasLinkToViewAudiences) {
        var href = "/audience/manage/";
        dropdown.addChild(new MenuItem({
          label: "<a class='view-audiences-link' href='" + href + "'>View audiences</a>"
        }));
      }
      var dropdownButton = new InlineDropDownButton({
        label: this.isDisabled ? this.allAudiencesLabel : this.currentAudienceName,
        dropDown: dropdown,
        dropDownPosition: ["below"],
        disabled: this.isDisabled,
        _aroundNode: this.switcherContainer
      });
      this.dropdownButton = dropdownButton;
      dropdownButton.placeAt(this.switcher, "only");
      domClass.remove(this.switcherContainer, "hide");
      domClass.add(this.singleAudienceName, "hide");
    },
    /**
     * Handle the click event from the switcher dropdown menu
     * @param {object} audience to switch to
     * @private
     */
    _onAudienceClick: function (audience) {
      // Tracking the event here instead of in a caller-provided callback
      // also lets us track "All audiences" selection
      var campaignsL0IsOn = mUser.flagIsOn(flags.WEF_LEFT_NAV_V2) && this.mc_data && this.mc_data["left_nav_v2_experiment_active"];
      if (this.location === "campaigns" && campaignsL0IsOn) {
        var audienceName = audience === this.allAudiencesKey ? "All audiences" : audience.name;
        window.ga("send", "event", "campaigns", "audience drop down", "selected " + audienceName);
      }
      if (audience === this.allAudiencesKey) {
        this._onAllAudiencesClick();
      } else {
        // Only overwrite the "All audiences" value if we're coming from a context that supports "All audiences"
        var switchAudienceUrl = "/lists/switcher/set-selected-audience?id=" + audience.id;
        switchAudienceUrl += "&overwrite_all=" + this.isAllAudiencesEnabled;
        var switchAudienceRequest = request.get(switchAudienceUrl, {
          handleAs: "json"
        });
        var result = switchAudienceRequest.then(lang.hitch(this, function () {
          //Call the passed-in callback to perform actions as a result of the list switch
          this.onSwitchAudienceCallback(audience.id);
          this._fetchAndHydrate();
        }));
      }
    },
    /**
     * When the user selects "All" from the dropdown, we need to update the dropdown Label and hide irrelevant UI
     * @private
     */
    _onAllAudiencesClick: function () {
      var switchAudienceRequest = request.get("/lists/switcher/set-select-all-audiences", {
        handleAs: "json"
      });
      var result = switchAudienceRequest.then(lang.hitch(this, function () {
        this.onSwitchAudienceCallback(this.allAudiencesKey);
        this._fetchAndHydrate();
      }));
    },
    /**
     * Set the session segment to view the subscribers only
     * Navigate to /lists/members?id={currentAudienceId}
     * @private
     */
    _onSubscribersClick: function () {
      var formData = new FormData();
      var segmentCondition = {
        match: "all",
        conditions: [{
          field: "contact_status",
          op: "status_is",
          value: ["Y"]
        }]
      };
      formData.append("segment", JSON.stringify(segmentCondition));
      var setSessionRequest = request.post("/lists/segments/members/storeJson?list_id=" + this.currentAudienceId, {
        handleAs: "json",
        data: formData
      });
      var result = setSessionRequest.then(lang.hitch(this, function () {
        window.top.location.href = "/lists/members?id=" + this.currentAudienceId + "&previewName=Subscribers";
      }));
    },
    /**
     * Public function to allow programmatic setting of "All audiences"
     * from contexts outside of the switcher itself
     */
    setIsAllAudiences: function () {
      this._onAllAudiencesClick();
    }
  });
});