define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/mcedit/Text", "mojo/neapolitan/editors/mcedit/TextMobile", "./_Base"], function (declare, lang, Deferred, domAttr, domClass, domConstruct, on, query, TextEditor, TextEditorMobile, _Base) {
  return declare([_Base], {
    postCreate: function () {
      var editContentDom = domConstruct.place('<div class="edit-content"><div class="tpl-content"></div></div>', this.domNode);
      // use the main window's mobileUtil to get the native version.
      var mobileVersionDeferred = window.top && window.top.app && window.top.app.nativeUtils && window.top.app.nativeUtils.getNativeVersion && window.top.app.nativeUtils.getNativeVersion();
      if (this.savedContent) {
        query('.tpl-content', this.domNode)[0].innerHTML = this.savedContent;
      }
      if (!this.duplicate) {
        var self = this;
        // Adds the edit link on hover on desktop
        // Container is click to edit on mobile, doesn't have label
        if (!this.isMobile) {
          this.editableLinks = domConstruct.place('<span class="edit-content-links">' + '<span><a class="edit-content-link tpl-link" href="#" mc:action="edit">Edit</a></span>' + "</span>", editContentDom);
          on(this.editableLinks, "a:click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            var action = domAttr.get(this, "mc:action");
            switch (action) {
              case "edit":
                self.openEditor();
                break;
              default:
                throw new Error("mcedit text does not handle " + action);
            }
          });
          on(this.domNode, ".tpl-content:click", lang.hitch(self, function (e) {
            e.stopPropagation();
            e.preventDefault();
            self.openEditor();
          }));
        }

        // This is the container that has the actual HTML content for this mc:edit
        this.contentDom = query(".tpl-content", this.domNode)[0];
        if (this.isMobile) {
          if (mobileVersionDeferred) {
            mobileVersionDeferred.then(function (versionReturned) {
              if (versionReturned) {
                // setup click action for activate block
                on(self.domNode, "click", lang.hitch(self, self.setBlockActive));
              } else {
                on(self.domNode, "click", lang.hitch(self, function (e) {
                  e.stopPropagation();
                  e.preventDefault();
                  self.openEditor();
                }));
              }
            });
          } else {
            on(self.domNode, "click", lang.hitch(self, function (e) {
              e.stopPropagation();
              e.preventDefault();
              self.openEditor();
            }));
          }
        } else {
          on(self.domNode, "click", lang.hitch(self, function (e) {
            e.stopPropagation();
            e.preventDefault();
            self.openEditor();
          }));
        }
        this.content = this.savedContent;
        // Keep another copy for knowing if content was changed.
        this._savedContent = this.content;
        this.watch("content", lang.hitch(this, function () {
          this.contentDom.innerHTML = this.content;
        }));
      } else {
        query(".edit-content", this.domNode).prepend('<div class="editedOverlay blockError"><strong>Editing disabled:</strong> Duplicate mc:edit ID</div>');
      }
    },
    /**
     * Crude logic based on naming convention.
     */
    isFooter: function () {
      return this.editId.match('footer') != null;
    },
    save: function () {
      if (this._savedContent != this.content) {
        this._savedContent = this.content;
        return this.endpoint.mcedit.saveText(this.editId, this.content);
      } else {
        var promise = new Deferred();
        promise.resolve();
        return promise;
      }
    },
    update: function () {
      // Grab the latest text from the server.
      this.endpoint.mcedit.getText(this.editId).then(lang.hitch(this, function (contentMeta) {
        this.set("content", contentMeta);
      }));
    },
    recoverContent: function (preservedContent) {
      return this.endpoint.mcedit.recoverContent(this.editId, preservedContent['name'], preservedContent['type']);
    },
    getEditor: function () {
      var Editor = this.getEditorByType(this.isMobile);
      return Editor({
        mcedit: this,
        customCkStylesPath: this.stylesPath,
        restrictions: this.restrictions
      });
    },
    getEditorByType: function (isMobile) {
      if (isMobile) {
        return TextEditorMobile;
      }
      return TextEditor;
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var contentWrap = domConstruct.create('div');
      var content = domConstruct.toDom(this.content);
      domConstruct.place(content, contentWrap);
      var link = query('a', contentWrap)[index];
      if (link && domAttr.get(link, 'href') == oldUrl) {
        domAttr.set(link, 'href', newUrl);
        this.set("content", contentWrap.innerHTML);
        domConstruct.destroy(contentWrap);
        return this.save();
      } else {
        domConstruct.destroy(contentWrap);
        return this.inherited(arguments);
      }
    }
  });
});