/**
 * Top level widget for the product selection UI
 * @module /mojo/widgets/ecommerce/ProductsListItem
 * @see /mojo/widgets/ecommerce/ProductsList
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dojo/text!./templates/productsListItem.html"], function (declare, on, domClass, _Widget, _TemplatedMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin], {
    templateString: templateStr,
    constructor: function (params) {
      this.productInfo = this.createProductInfo(params.price, params.sku);
    },
    postCreate: function () {
      this.own(on(this.productAction, "click", this.onItemAction), on(this.productAction, "focus", this._showActions.bind(this)), on(this.productAction, "blur", this._hideActions.bind(this)), on(this.productAction, "mouseover", this._showActions.bind(this)), on(this.productAction, "mouseout", this._hideActions.bind(this)));
    },
    createProductInfo: function (price, sku) {
      if (sku) {
        return price + " &middot; SKU " + sku;
      }
      return price;
    },
    _showActions: function () {
      domClass.toggle(this.actionsNode, "hide", false);
    },
    _hideActions: function () {
      domClass.toggle(this.actionsNode, "hide", true);
    },
    /**
     * onItemAction will select the product assign to the ProductListItem
     * @override
     */
    onItemAction: function () {}
  });
});