define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/url", "mojo/context", "mojo/lib/flags", "mojo/page/settings", "vendor/lodash-amd/find", "dojo/text!./templates/page-settings.html"], function (declare, lang, xhr, Renderable, mUrl, context, flags, pageSettings, find, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      var isWebsite = props.model.type === "website";
      if (isWebsite) {
        props.model = props.model.childPage;
      }
      this.state = {
        listId: props.model.list.id,
        captchaEnabled: props.model.list.captchaEnabled,
        captchaUrl: mUrl.toUrl("/lists/settings/defaults", {
          id: props.model.list.id
        }),
        isActive: false,
        isSaving: false,
        isValid: props.isValid,
        isWebsite: isWebsite
      };
      if (props.model.tracking_settings) {
        var tracking_settings = props.model.tracking_settings;
        var items = tracking_settings.items;
        var settingTypes = pageSettings.TYPES;
        this.state.tracking = {
          mailchimpTrackingEnabled: this.checkEnabledByType(items, settingTypes.mailchimp),
          googleRestrictedDataProcessing: this.checkEnabledByType(items, settingTypes.ccpa),
          googleAnalyticsTrackingEnabled: this.checkEnabledByType(items, settingTypes.googleAnalytics),
          facebookPixelTrackingEnabled: this.checkEnabledByType(items, settingTypes.facebookPixel),
          googleAnalyticsTrackingId: this.getPropertyBySettingTypeAndDataName(items, settingTypes.googleAnalytics, pageSettings.DATA_NAMES.googleAnalyticsTrackingId),
          facebookPixelId: this.getPropertyBySettingTypeAndDataName(items, settingTypes.facebookPixel, pageSettings.DATA_NAMES.facebookPixel),
          hasFacebookAdsAccountPixel: this.getPropertyBySettingTypeAndDataName(items, settingTypes.facebookPixel, pageSettings.DATA_NAMES.hasAdsAccountPixel),
          anySettingTypeEnabled: this.checkAnySettingTypeEnabled(items),
          acceptedTerms: tracking_settings.did_accept_terms
        };
      }
    },
    checkAnySettingTypeEnabled: function (settings) {
      var res = find(settings, {
        enabled: true
      });
      return res !== undefined;
    },
    activate: function () {
      this.setState({
        isActive: true
      });
      this.onActivate();
    },
    deactivate: function () {
      this.setState({
        isActive: false
      });
      this.onDeactivate();
    },
    checkEnabledByType: function (settings, type) {
      var res = find(settings, {
        setting_type: type,
        enabled: true
      });
      return res !== undefined;
    },
    getPropertyBySettingTypeAndDataName: function (settings, type, name) {
      var setting = find(settings, {
        setting_type: type
      });
      if (setting === undefined || setting.data === undefined) {
        return undefined;
      }
      return setting.data[name];
    }
  });
});