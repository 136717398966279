define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Moveable" /*=====, "./Mover" =====*/], function (declare, Moveable /*=====, Mover =====*/) {
  // module:
  //		dojo/dnd/TimedMoveable

  /*=====
  var __TimedMoveableArgs = declare([Moveable.__MoveableArgs], {
  	// timeout: Number
  	//		delay move by this number of ms,
  	//		accumulating position changes during the timeout
  	timeout: 0
  });
  =====*/

  // precalculate long expressions
  var oldOnMove = Moveable.prototype.onMove;
  return declare("dojo.dnd.TimedMoveable", Moveable, {
    // summary:
    //		A specialized version of Moveable to support an FPS throttling.
    //		This class puts an upper restriction on FPS, which may reduce
    //		the CPU load. The additional parameter "timeout" regulates
    //		the delay before actually moving the moveable object.

    // object attributes (for markup)
    timeout: 40,
    // in ms, 40ms corresponds to 25 fps

    constructor: function (node, params) {
      // summary:
      //		an object that makes a node moveable with a timer
      // node: Node||String
      //		a node (or node's id) to be moved
      // params: __TimedMoveableArgs
      //		object with additional parameters.

      // sanitize parameters
      if (!params) {
        params = {};
      }
      if (params.timeout && typeof params.timeout == "number" && params.timeout >= 0) {
        this.timeout = params.timeout;
      }
    },
    onMoveStop: function ( /*Mover*/mover) {
      if (mover._timer) {
        // stop timer
        clearTimeout(mover._timer);
        // reflect the last received position
        oldOnMove.call(this, mover, mover._leftTop);
      }
      Moveable.prototype.onMoveStop.apply(this, arguments);
    },
    onMove: function ( /*Mover*/mover, /*Object*/leftTop) {
      mover._leftTop = leftTop;
      if (!mover._timer) {
        var _t = this; // to avoid using dojo.hitch()
        mover._timer = setTimeout(function () {
          // we don't have any pending requests
          mover._timer = null;
          // reflect the last received position
          oldOnMove.call(_t, mover, mover._leftTop);
        }, this.timeout);
      }
    }
  });
});