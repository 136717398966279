define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo/text!../editors/blocks/templates/AbandonedCartEditor/output.html"], function (declare, _Renderer, ButtonRenderer, Link, tpl) {
  return declare([_Renderer], {
    outputTplStr: tpl,
    constructor: function () {
      this.inherited(arguments);
      this.buttonRenderer = new ButtonRenderer();
    },
    _getMergeTagOpen: function (meta) {
      var tagAttributes = [];
      if (meta.numProducts) {
        tagAttributes.push("$total=" + meta.numProducts);
      }
      var tagAttrStr = "";
      if (tagAttributes.length) {
        tagAttrStr = "[" + tagAttributes.join(",") + "]";
      }
      return "*|ABANDONED_CART:" + tagAttrStr + "|*";
    },
    getOutputModel: function (meta) {
      var titleOnly = !meta.priceEnabled;
      return {
        "titleOnly": titleOnly,
        "mergeTagOpen": this._getMergeTagOpen(meta),
        "mergeTagClose": "*|END:ABANDONED_CART|*"
      };
    }
  });
});