define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/NdDisconnectModalContent.html"], function (declare, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, NdDisconnectModalContent) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: NdDisconnectModalContent,
    displayError: function () {
      domClass.remove(this.errorNode, "hide");
      domClass.add(this.inputNode, "invalid");
    },
    clearError: function () {
      domClass.add(this.errorNode, "hide");
      domClass.remove(this.inputNode, "invalid");
    },
    getValue: function () {
      return this.inputNode.value;
    }
  });
});