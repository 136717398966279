define(["dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/logger", "/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js"], function (has, Deferred, context, logger, dojoRequire) {
  function injectExperimentData() {
    // We're using `@mc/experiments` somewhere in here, which requires
    // the existence of a script tag w/a JSON paylaod:
    if (document.head.querySelector("#js-experiment-data")) {
      return;
    }
    var experimentData = document.createElement("script");
    experimentData.id = "js-experiment-data";
    experimentData.type = "application/json";
    experimentData.textContent = "[]";
    document.head.appendChild(experimentData);
  }
  function injectConfigData() {
    // We may be using `@mc/config` somewhere in here,
    // which requires the existence of a script tag
    // w/a JSON object
    if (document.head.querySelector("#js-app-config")) {
      return;
    }
    var jsConfigElement = document.createElement("script");
    jsConfigElement.id = "js-app-config";
    jsConfigElement.type = "application/json";
    jsConfigElement.textContent = "{}";
    document.head.appendChild(jsConfigElement);
  }
  function injectTranslationData() {
    if (document.head.querySelector("#js-translation-data")) {
      return;
    }
    var jsTranslationElement = document.createElement("script");
    jsTranslationElement.id = "js-translation-data";
    jsTranslationElement.type = "application/json";
    jsTranslationElement.textContent = "{}";
    document.head.appendChild(jsTranslationElement);
  }
  function loadStylesheet(href) {
    // If this css file already exists then we can skip appending it.
    if (document.head.querySelector("link[href='" + href + "']")) {
      return;
    }
    var styles = document.createElement("link");
    styles.rel = "stylesheet";
    styles.href = href;
    document.head.appendChild(styles);
  }
  return function bridge(bundle) {
    if (context.flagIsOn("xp.bridge_inject_app_config")) {
      injectConfigData();
      injectTranslationData();
    }
    injectExperimentData();
    var notLoginHostname = window.location.hostname.indexOf("login.mailchimp.com") === -1;
    if (notLoginHostname) {
      logger.info("js_assets", "[bridges] React injection into dojo (classic)", {
        bridgeName: bundle,
        pathName: window.location.pathname
      });
    }
    if (window.__bridges) {
      var bridgeScriptTag = document.querySelector("[data-bridge-css]");
      if (bridgeScriptTag !== null) {
        loadStylesheet(bridgeScriptTag.dataset.bridgeCss);
      }
      if (!Object.prototype.hasOwnProperty.call(window.__bridges, bundle)) {
        throw new Error("Missing bridge:" + bundle);
      }
      return window.__bridges[bundle]().then(function (mod) {
        return mod.default;
      });
    }
    var deferred = new Deferred();
    // This conditional will strip the block from production bundles, which will keep these errors from needlessly leaking into production
    if (0) {
      if (!context.bridgeBundles) {
        throw new Error("No bridge bundles were found. This likely means that webpack isn't running or isn't producing the bridge manifest. Please run 'script/fp dev' to start the bundles. ");
      }
      if (context.bridgeBundles.__seed) {
        window.location = context.bridgeBundles.__seed;
      }
      if (!context.bridgeBundles[bundle + ".js"]) {
        if (context.bridgeUrl) {
          dojoRequire([context.bridgeUrl + "umd.vendor.js", context.bridgeUrl + bundle + ".js"], function (_, mod) {
            deferred.resolve(mod.default);
          });
          return deferred.promise;
        }
        throw new Error("The bridge bundle requested is not found. Please ensure it is defined in the webpack config. Reach out to the Front-end Platform team (#front-end-help in Slack) for more information or help!");
      }
    }

    // If we find a stylehseet matching our bundle we should inject it to the page
    // This is expected to happen only in production
    if (context.bridgeBundles[bundle + ".css"]) {
      loadStylesheet(context.bridgeBundles[bundle + ".css"]);
    }
    if (context.bridgeBundles["umd.vendor.css"]) {
      loadStylesheet(context.bridgeBundles["umd.vendor.css"]);
    }
    dojoRequire([context.bridgeBundles["umd.vendor.js"], context.bridgeBundles[bundle + ".js"]], function (_, mod) {
      deferred.resolve(mod.default);
    });
    return deferred.promise;
  };
});