/**
 * Specialized select input box that can create another input based on its config. The created input will be used to
 * return the "Extra" attribute
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "./_SegmentInputMixin", "./Select", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js"], function (declare, _WidgetBase, _SegmentInputMixin, Select, domConstruct, domAttr, lang, flags, array, dojoRequire) {
  return declare([_WidgetBase, _SegmentInputMixin], {
    ops: null,
    opsMap: null,
    configs: null,
    valueInput: null,
    postMixInProperties: function () {
      this.opsMap = {};
      this.options = [];

      // Add the options that the Select parent understands
      array.forEach(this.ops, lang.hitch(this, function (op) {
        this.opsMap[op.value] = op;
        var option = {
          "label": op.label,
          "value": op.value
        };
        this.options.push(option);
      }));
    },
    buildRendering: function () {
      this.domNode = domConstruct.create("div", {
        "class": "segment-extra-select"
      });
    },
    postCreate: function () {
      this.selectInput = new Select({
        "class": "condition-value",
        "options": this.options
      });
      this.selectInput.placeAt(this.domNode);
      this.selectInput.on("change", lang.hitch(this, "renderInput"));
      this.renderInput();
    },
    renderInput: function () {
      if (this.valueInput) {
        this.valueInput.destroy();
        this.valueInput = null;
      }
      var opConfig = this.opsMap[this.getValue()];
      var inputConfig = opConfig.input;
      var self = this;
      if (inputConfig) {
        // Avoids circular dependency.
        require(["mojo/segmentation/input/Creator"], function (InputCreator) {
          self.valueInput = InputCreator.create(self.configs, inputConfig);
          domConstruct.place(self.valueInput.domNode, self.domNode);
        });
      }
    },
    getValueHTML: function () {
      return this.valueInput ? this.valueInput.getDisplayHTML() : "";
    },
    getDisplayHTML: function () {
      return this.selectInput.getDisplayHTML() + ": " + this.getValueHTML();
    },
    getValue: function () {
      return this.selectInput.getValue();
    },
    setValue: function (value) {
      this.selectInput.setValue(value);
      this.renderInput();
    },
    setExtra: function (extraValue) {
      if (this.valueInput) {
        this.valueInput.setValue(extraValue);
      }
    },
    getExtra: function () {
      var extra = null;
      if (this.valueInput) {
        extra = this.valueInput.getValue();

        // original_signup_source_options is a special case where the value must be a number
        if (this.inputConfig.options === "original_signup_source_options") {
          if (!isNaN(parseInt(extra, 10))) {
            extra = parseInt(extra, 10);
          }
        }
      }
      return extra;
    },
    destroy: function () {
      if (this.selectInput) {
        this.selectInput.destroy();
      }
      if (this.valueInput) {
        this.valueInput.destroy();
      }
      this.inherited(arguments);
    }
  });
});