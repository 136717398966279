define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/lib/flags", "mojo/url", "mojo/user", "mojo/widgets/campaigns/PlainTextDialog", "mojo/widgets/Previewer", "mojo/views/_base"], function (declare, lang, on, domAttr, query, topic, Dialog, flags, mUrl, user, PlainTextDialog, Previewer, base) {
  return declare([base], {
    parentId: null,
    parentTitle: null,
    childId: null,
    previewer: null,
    onReady: function () {
      var self = this;

      // Refresh the status bar whenever a checklist slat state is changed
      query("[data-checklist-slat]").forEach(function (e) {
        if (domAttr.get(e, "data-checklist-slat") !== "status-bar") {
          self.$widget.checklist.addOnSlatRenderedListener(domAttr.get(e, "data-checklist-slat"), function () {
            self.$widget.statusBar.getView("status-bar");
          });
        }
      });

      // when plain-text is saved, update content slat
      topic.subscribe(PlainTextDialog.successTopic(this.childId), function () {
        self.$widget.checklist.getView("content");
      });
      window.dataLayer.push({
        event: "e_pageLoad",
        destination: "campaign_detail",
        outreach_id: this.outreachId,
        outreach_type: this.type,
        login_id: this.loginId ? this.loginId : null,
        user_id: this.userId ? this.userId : null
      });

      // On click of the Remove Subscribers button, open the Automation Overlay with the queue removal settings
      on(this.$el.removeSubscribersBtn, "click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.$widget.automationOverlay.openView(this.parentId, "queueRemove", {
          "emailId": this.parentId
        });
      }.bind(this));
    },
    openDomainVerification: function (dom) {
      var email = domAttr.get(dom, "data-email");
      var url = mUrl.toUrl("/account/verify-domain/", {
        "step": "add-new",
        "email": email,
        "cid": this.childId
      });
      if (!this.verificationDialog) {
        this.verificationDialog = new Dialog({
          "title": "Verify Domain",
          'style': 'width:620px;',
          'refreshOnShow': 'true',
          'id': 'mc-module-dialog',
          'onHide': lang.hitch(this, function () {
            self.$widget.checklist.getView("from");
          })
        });
      }
      this.verificationDialog.setHref(url);
      this.verificationDialog.show();
    },
    previewContent: function () {
      if (!this.previewer) {
        this.previewer = new Previewer({
          'previewId': this.childId,
          'previewType': 'campaign',
          'titleText': this.parentTitle,
          'showMergeInfo': true
        });
      }
      this.previewer.show();
    },
    editPlainText: function () {
      PlainTextDialog.show(this.childId);
    }
  });
});