define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "mojo/user", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/Quickbooks.html", "dijit/Dialog"], function (declare, lang, xhr, domClass, domStyle, on, mUrl, mPusher, user, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    _setAppInstallUrlAttr: {
      node: "integrationButtonNode",
      type: "attribute",
      attribute: "href"
    },
    pusherChannel: null,
    pusherCallback: null,
    postMixInProperties: function () {
      this.csrfToken = user.getCsrfToken();
    },
    postCreate: function () {
      if (this.integrationInstallStatus === true) {
        this._setupDisconnectButton();
        domClass.add(this.connectFormContainer, "hide");
      }
      this.own(on(this.disconnectButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._showDisconnectDialog();
      })));
      this.own(on(this.testButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._testConnection();
      })));
      this._loadData();
      this._initListSelect();
      // Remove the sandbox checkbox for non-internal accounts
      if (user.type !== "internal") {
        domClass.add(this.sandboxContainer, "hide");
      }
    },
    _testConnection: function () {
      var test_url = "/account/integrations/quickbooks/test?id=" + this.listId;
      var self = this;
      self._toggleSyncSuccess(false);
      self.testResults.innerHTML = "";
      domClass.add(self.testButton, "disabled");
      domStyle.set(self.integrationLoading, "display", "");
      xhr.post(mUrl.toUrl(test_url), {
        "data": {},
        "handleAs": "json"
      }).then(function (data) {
        var txtbox = self.testResults;
        domClass.remove(self.testButton, "disabled");
        domStyle.set(self.integrationLoading, "display", "none");
        domClass.remove(txtbox, "hide");
        txtbox.innerHTML = data.result.msg;
      });
    },
    _loadData: function () {
      var self = this;
      xhr.get(mUrl.toUrl("/account/integrations/quickbooks/get-connection-info"), {
        "handleAs": "json"
      }).then(function (data) {
        if (data.connected) {
          self.listId = data.listId;
          domClass.add(self.connectionErrorContainer, "hide");
          self._setConnectionNodeValue(self.listToSync, data.listName, "Audience to sync to: ");
          self._setConnectionNodeValue(self.connectedCompany, data.connectedCompany, "Company to sync: ");
          self._setConnectionNodeValue(self.statusToSyncAs, data.contactStatus, "Syncing contacts as: ");
          domClass.remove(self.connectionResults, "hide");
          domClass.toggle(self.connectionErrorContainer, "hide", !data.connectionError);
          if (data.pusherChannel) {
            self._connectPusher(data.pusherChannel);
          }
          if (data.syncing) {
            self._toggleSyncProgress(true);
          }
        }
      }, function () {
        domClass.remove(self.connectionErrorContainer, "hide");
      });
    },
    _initListSelect: function () {
      var self = this;
      xhr.get(mUrl.toUrl("/account/integrations/quickbooks/get-lists-for-connection"), {
        "handleAs": "json"
      }).then(function (data) {
        var options = data.lists.map(function (list) {
          return {
            value: list.id,
            label: list.name
          };
        });
        self.listSelect.addOption(options);
        domClass.remove(self.connectForm, "hide");
      });
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _setupDisconnectButton: function () {
      domClass.remove(this.integrationButtonNode, "p1 disabled button-loading");
      domClass.add(this.integrationButtonNode, "hide");
      this.integrationButtonNode.innerText = "Disconnect";
      this.own(on.once(this.integrationButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._showDisconnectDialog();
      })));
    },
    _showDisconnectDialog: function () {
      this.disconnectDialog.show();
    },
    _hideDisconnectDialog: function () {
      this.disconnectDialog.hide();
    },
    _trackConnectClick: function () {
      window.ga("send", "event", "connected sites", "platform connect", "button", {
        "dimension53": "Connect To QuickBooks",
        "dimension54": "/account/integrations/quickbooks/post",
        "dimension62": "QuickBooks"
      });
    },
    _confirmDisconnectIntegration: function () {
      this._hideDisconnectDialog();
      this._displayWaiting();
      if (this.listId) {
        xhr.post(mUrl.toUrl("/account/integrations/quickbooks/disconnect"), {
          "data": {
            id: this.listId
          },
          "handleAs": "json"
        }).then(function (data) {
          if (data && data.status === "success") {
            // Hard refresh
            window.location.reload();
          }
        });
      }
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    _setupConnectButton: function () {
      domClass.remove(this.integrationButtonNode, "disabled button-loading");
      domClass.add(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Connect";
    },
    _connectPusher: function (channelName) {
      mPusher.load().then(lang.hitch(this, function (pusher) {
        this.pusherChannel = pusher.subscribe(channelName);
        this.pusherCallback = lang.hitch(this, "_handleSyncUpdates");
        this.pusherChannel.bind("full-sync-events", this.pusherCallback);
      }));
    },
    _disconnectPusher: function () {
      var channel = this.pusherChannel;
      if (channel) {
        channel.unbind(this.pusherCallback);
        mPusher.load().then(function (pusher) {
          pusher.unsubscribe(channel);
        });
      }
    },
    destroy: function () {
      this._disconnectPusher();
      this.inherited(arguments);
    },
    _toggleSyncProgress: function (show) {
      domClass.toggle(this.syncProgressContainer, "hide", !show);
      domClass.toggle(this.actionsContainer, "hide", show);
    },
    _toggleSyncSuccess: function (show) {
      this.viewContactsLink.setAttribute("href", "/lists/members?id=" + this.listId);
      domClass.toggle(this.syncSuccessContainer, "hide", !show);
    },
    _handleSyncUpdates: function (data) {
      switch (data.status) {
        case "running":
          var value = Math.floor(data.processed / data.total * 100) + "%";
          this.progressBarLabel.innerText = value;
          domStyle.set(this.progressBar, "width", value);
          this._toggleSyncProgress(true);
          break;
        case "completed":
          this._toggleSyncProgress(false);
          this._toggleSyncSuccess(true);
          break;
        default:
          throw new Error("Unhandled status type");
      }
    }
  });
});