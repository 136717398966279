define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/fx/easing"], function (lang, kernel, easing) {
  kernel.deprecated("dojox.fx.easing", "Upgraded to Core, use dojo.fx.easing instead", "2.0");
  var fxExt = lang.getObject("dojox.fx", true);
  fxExt.easing = easing;
  /*=====
  	return {
  		// summary:
  		//		An Alias to `dojo.fx.easing`. Moved to Core in Dojo 1.2.
  	};
  =====*/
  return easing;
});