define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_AttachMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, _WidgetBase, _AttachMixin, _WidgetsInTemplateMixin, dom, on, xhr, ready, mUrl) {
  return declare([_WidgetBase, _AttachMixin, _WidgetsInTemplateMixin], {
    startup: function () {
      on(this.select, "change", this._onChange.bind(this));
    },
    /**
     * Event handler for internal select widget on change event
     *
     * @param {Event} e - Event to handle
     */
    _onChange: function (e) {
      var copyFrom = this.select.value;
      if (copyFrom === "0") {
        return;
      }
      this._getContactFormData(copyFrom).then(this._setFormData.bind(this));
    },
    /**
     * Make a request to get contact data information for another entity in this account
     *
     * @param {string} copyDataFrom - Name of the other entity from which to get data
     * @return {Promise<Object>} Map of string key html element ids, to string values
     */
    _getContactFormData: function (copyDataFrom) {
      return xhr.post(mUrl.toUrl("account/copy-from"), {
        "data": {
          "from": copyDataFrom
        },
        "handleAs": "json"
      });
    },
    /**
     * Set data in the page by mapping over elements and setting values
     *
     * @param {Object<String, String>} data - Map of html element ids, to values
     */
    _setFormData: function (data) {
      for (var key in data) {
        if (dom.byId(key)) {
          var value = data[key];
          dom.byId(key).value = value;
          //The event won't emit if we just change the value? yay js
          on.emit(dom.byId(key), "change", {
            bubbles: true,
            cancelable: true
          });
        }
      }
    }
  });
});