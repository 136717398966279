define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/NumberTextBox", "./_SegmentInputMixin", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/numberDays.html", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, domConstruct, NumberTextBox, _SegmentInputMixin, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, I18nTranslation, flags, context) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _SegmentInputMixin], {
    lastText: "the last",
    day: "day",
    daysText: "days",
    blank: "blank",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        this.lastText = I18nTranslation.translate("audience_management_segment_builder_last");
        this.day = I18nTranslation.translate("audience_management_segment_builder_day");
        this.daysText = I18nTranslation.translate("audience_management_segment_builder_days");
        this.blank = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    numberInput: null,
    templateString: tpl,
    getDisplayHTML: function () {
      var value = this.getValue();
      if (value) {
        if (value === 1) {
          this.daysText = this.day;
        }
        return "<span class=\"dim6\">" + this.lastText + " " + value + " " + this.daysText + "</span>";
      }
      return "<span class=\"dim6\">(" + this.blank + ")</span>";
    },
    getValue: function () {
      return this.numberInput.value;
    },
    setValue: function (value) {
      this.numberInput.set("value", value);
    }
  });
});