define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, on, lang, domClass, array) {
  return declare([base], {
    onReady: function () {
      on(this.$group.uid_options, "click", lang.hitch(this, function () {
        this.toggleVisibility(this.$group.uid_options);
      }));
      on(this.$el.badges_checkbox, "click", lang.hitch(this, function () {
        if (this.$el.badges_checkbox.checked === true) {
          domClass.remove(this.$el.badge, "hide");
          this.enableOptions(this.$group.badges);
        } else {
          domClass.add(this.$el.badge, "hide");
          this.disableOptions(this.$group.badges);
        }
      }));
    },
    toggleVisibility: function (elements) {
      elements.forEach(lang.hitch(this, function (el) {
        var targetInput = el.value;
        if (this.$el[targetInput]) {
          if (el.checked) {
            domClass.remove(this.$el[targetInput], "hide");
          } else {
            domClass.add(this.$el[targetInput], "hide");
          }
        }
      }));
    },
    disableOptions: function (elements) {
      elements.forEach(function (el) {
        el.disabled = true;
      });
    },
    enableOptions: function (elements) {
      elements.forEach(function (el) {
        el.disabled = false;
      });
    }
  });
});