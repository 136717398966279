define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on) {
  var productionPayoneer = 1;
  return declare([base], {
    /**
     * Adds a warning message when "PRODUCTION" is selected as the Payoneer Environment
     */
    onReady: function () {
      var self = this;
      this.$group.warn.forEach(function (el) {
        on(el, "change", function (event) {
          if (el.value == productionPayoneer) {
            self.$widget.changeDialog.show();
          }
        });
      });
    }
  });
});