/**
 * AMD module to use Aviary.
 * @module mojo/ImageEditor
 *
 * Loads Aviary when needed and keeps a single reference of an instance.
 */
define(["dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/widgets/ImageEditor"], function (Deferred, domConstruct, on, url, ImageEditorV2) {
  var singleton = {
    /**
     * Open image editor to given src with specified tools
     *
     * @param {string} src - Image to edit
     * @param {(null|string[])} [tools=all] - List of tools to limit editing to (if an array, the first is the initialized tool)
     * @param {(null|object)} options - Other parameters to pass into the editor ("cropPresets": string like "16:9")
     *
     * @returns {dojo/promise/Promise} Resolved with the url of the edited image
     */
    open: function (src, tools, options) {
      return new ImageEditorV2(src, tools, options);
    },
    /**
     * Calculates the dimension for an image.
     *
     * @param {string} src - Image to get information for
     *
     * @returns {dojo/promise/Promise} Resolved with a plain ol' object with keys "url", "width", "height"
     */
    getImageInfo: function (src) {
      var promise = new Deferred();
      var img = domConstruct.create("img", {
        "src": src
      });
      function _resolve() {
        promise.resolve({
          url: url.addProxy(src),
          width: img.naturalWidth,
          height: img.naturalHeight,
          originalUrl: img.src
        });
        domConstruct.destroy(img);
      }

      // some browsers set this correctly
      var loaded = !img.complete;

      // some incorrectly sets the complete check
      if (loaded) {
        loaded = !(typeof img.naturalWidth !== "undefined" && img.naturalWidth === 0);
      }
      if (loaded) {
        _resolve();
      } else {
        on.once(img, "load", _resolve);
      }
      return promise;
    }
  };
  return singleton;
});