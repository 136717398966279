define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dijit/Dialog", "mojo/context", "mojo/utils", "mojo/utils/date", "mojo/app/campaigns", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils/I18nTranslation", "./Slat", "dojo/text!./templates/CampaignPauseDialog.html", "dojo/text!./templates/CampaignCancelDialog.html"], function (declare, domClass, on, lang, entities, Dialog, context, utils, dateUtils, campaigns, user, flags, logger, I18nTranslation, Slat, pauseDialogTemplate, cancelDialogTemplate) {
  var statusContent = {
    "draft": {
      label: "Draft",
      badge: "",
      description: "Edited <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
    },
    "sent": {
      label: "Sent",
      badge: "success",
      description: "Sent <strong>{sendDate}</strong> to {sends} recipients"
    },
    "processing": {
      label: "Processing",
      badge: "info",
      description: ""
    },
    "compliance": {
      label: "Rejected",
      badge: "error",
      description: ""
    },
    "active": {
      label: "Sending",
      badge: "info",
      description: "Next order is scheduled for <strong>{nextOrderDate}</strong>"
    },
    "paused": {
      label: "Paused",
      badge: "warning",
      description: "Edited <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
    },
    "scheduled": {
      label: "Scheduled",
      badge: "warning",
      description: "Scheduled for <strong>{scheduledSendTime}</strong>"
    },
    "canceled": {
      label: "Canceled",
      badge: "error",
      description: "Canceled <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
    }
  };
  var PostcardSlat = declare([Slat], {
    channelIcon: "c-channelIcon--postcard",
    statusContent: statusContent,
    statsOrder: ["recipients"],
    statsContent: {
      "recipients": {
        label: "Recipients"
      }
    },
    typeContent: {
      "postcard": {
        label: "Postcard"
      },
      "recurring": {
        label: "Recurring Postcard"
      },
      "abandoned_cart": {
        label: "Abandoned Cart Postcard"
      }
    },
    actions: {
      "edit": {
        label: "Edit"
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
        }
      },
      "view": {
        label: "View Postcard"
      },
      "pause": {
        label: "Pause Postcard",
        onClick: function () {
          var dialog = new Dialog({
            "title": "Would you like to pause your postcard?",
            "content": pauseDialogTemplate,
            onHide: function () {
              dialog.destroyRecursive();
            }
          });
          dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
            window.location.href = "/postcards/pause?id=" + this.model.postcardId;
          }.bind(this));
          dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
            dialog.hide();
          });
          if (this.model.status === "scheduled") {
            var modalContent = dialog.domNode.querySelector("#modalContent");
            modalContent.innerText += "Note that pausing to edit a postcard may change your scheduled date.";
          }
          dialog.show();
        }
      },
      "replicate": {
        label: "Replicate",
        onClick: function () {
          var form = document.createElement("form");
          form.action = "/postcards/replicate";
          form.method = "POST";
          var id = document.createElement("input");
          id.type = "hidden";
          id.name = "id";
          id.value = this.model.postcardId;
          form.appendChild(id);
          var csrf = document.createElement("input");
          csrf.type = "hidden";
          csrf.name = "__csrf_token";
          csrf.value = user.getCsrfToken();
          form.appendChild(csrf);
          var submit = document.createElement("button");
          submit.type = "submit";
          form.appendChild(submit);
          document.body.appendChild(form);
          // We use submit.click() here in lieu of form.submit()
          // in order to fire the "submit" event.
          submit.click();
        }
      },
      "cancel": {
        label: "Cancel",
        onClick: function () {
          var postcardId = this.model.postcardId;
          var campaignName = this.model.title;
          var dialog = new Dialog({
            "title": "Cancel this campaign?",
            "content": cancelDialogTemplate,
            onHide: function () {
              dialog.destroyRecursive();
            }
          });
          var form = dialog.domNode.querySelector("form");
          form.setAttribute("action", "/postcards/cancel?id=" + postcardId);
          var campaignNameSpan = dialog.domNode.querySelector("[data-dojo-attach-point='campaignNameSpan']");
          campaignNameSpan.innerHTML = campaignName;
          var csrf = dialog.domNode.querySelector("[data-dojo-attach-point='csrf']");
          csrf.setAttribute("value", user.__csrfToken);
          dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
            dialog.hide();
          });
          dialog.show();
        }
      }
    },
    statusContentTranslated: {},
    statsContentTranslated: {},
    typeContentTranslated: {},
    actionsTranslated: {},
    /**
     * override
     *
     * @param {object} translationObject i18n object
     */
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "draft": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_edited_by_you", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "sent": {
          label: translationObject.campaigns_sent,
          badge: "success",
          description: I18nTranslation.translate("campaigns_sent_description_postcard", ["{sendDate}", "{sends}"])
        },
        "processing": {
          label: translationObject.campaigns_processing,
          badge: "info",
          description: ""
        },
        "compliance": {
          label: translationObject.campaigns_rejected,
          badge: "error",
          description: ""
        },
        "active": {
          label: translationObject.campaigns_sending,
          badge: "info",
          description: I18nTranslation.translate("campaigns_sending_description_postcard", ["{nextOrderDate}"])
        },
        "paused": {
          label: translationObject.campaigns_paused,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_edited_by_you", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "scheduled": {
          label: translationObject.campaigns_scheduled,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_scheduled_description_postcard", ["{scheduledSendTime}"])
        },
        "canceled": {
          label: translationObject.campaigns_canceled,
          badge: "error",
          description: I18nTranslation.translate("campaigns_canceled_description_postcard", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        }
      };
      this.statsContentTranslated = {
        "recipients": {
          label: translationObject.campaigns_recipients
        }
      };
      this.typeContentTranslated = {
        "postcard": {
          label: translationObject.campaigns_postcard
        },
        "recurring": {
          label: translationObject.campaigns_recurring_postcard
        },
        "abandoned_cart": {
          label: translationObject.campaigns_abandoned_card_postcard
        }
      };
      this.actionsTranslated = {
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Postcards");
            }
          }
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
          }
        },
        "view": {
          label: translationObject.campaigns_actions_view_postcard
        },
        "pause": {
          label: translationObject.campaigns_actions_pause_postcard,
          onClick: function () {
            var dialog = new Dialog({
              "title": translationObject.campaigns_actions_pause_postcard_title,
              "content": pauseDialogTemplate,
              onHide: function () {
                dialog.destroyRecursive();
              }
            });
            dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
              window.location.href = "/postcards/pause?id=" + this.model.postcardId;
            }.bind(this));
            dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
              dialog.hide();
            });
            if (this.model.status === "scheduled") {
              var modalContent = dialog.domNode.querySelector("#modalContent");
              modalContent.innerText += translationObject.campaigns_actions_pause_postcard_note;
            }
            dialog.show();
          }
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate,
          onClick: function () {
            var form = document.createElement("form");
            form.action = "/postcards/replicate";
            form.method = "POST";
            var id = document.createElement("input");
            id.type = "hidden";
            id.name = "id";
            id.value = this.model.postcardId;
            form.appendChild(id);
            var csrf = document.createElement("input");
            csrf.type = "hidden";
            csrf.name = "__csrf_token";
            csrf.value = user.getCsrfToken();
            form.appendChild(csrf);
            var submit = document.createElement("button");
            submit.type = "submit";
            form.appendChild(submit);
            document.body.appendChild(form);
            // We use submit.click() here in lieu of form.submit()
            // in order to fire the "submit" event.
            submit.click();
          }
        },
        "cancel": {
          label: translationObject.campaigns_actions_cancel,
          onClick: function () {
            var postcardId = this.model.postcardId;
            var campaignName = this.model.title;
            var dialog = new Dialog({
              "title": translationObject.campaigns_actions_cancel_title,
              "content": cancelDialogTemplate,
              onHide: function () {
                dialog.destroyRecursive();
              }
            });
            var form = dialog.domNode.querySelector("form");
            form.setAttribute("action", "/postcards/cancel?id=" + postcardId);
            var campaignNameSpan = dialog.domNode.querySelector("[data-dojo-attach-point='campaignNameSpan']");
            campaignNameSpan.innerHTML = campaignName;
            var csrf = dialog.domNode.querySelector("[data-dojo-attach-point='csrf']");
            csrf.setAttribute("value", user.__csrfToken);
            dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
              dialog.hide();
            });
            dialog.show();
          }
        }
      };
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaData: function () {
      if (this.model.status === "active" && this.model.postcardAutomationStrategy === "abandoned_cart") {
        return;
      }
      if (this.statusContent[this.model.status]) {
        var template = this.statusContent[this.model.status].description;
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "sendDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                return dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            case "nextOrderDate":
              if (!this.model.nextOrderDate) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                return dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR);
              }
              return dateUtils.getDateOnlyString(this.model[key]);
            case "scheduledSendTime":
              if (!this.model.scheduledSendTime) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                return dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR);
              }
              return dateUtils.getDateOnlyString(this.model[key]);
            default:
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.model.status === "active" && this.model.postcardAutomationStrategy === "abandoned_cart") {
        return;
      }
      if (this.statusContentTranslated[this.model.status]) {
        var template = this.statusContentTranslated[this.model.status].description;
        var jsDate;
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "sendDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            case "nextOrderDate":
              if (!this.model.nextOrderDate) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "short") : dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR);
              }
              return dateUtils.getDateOnlyString(this.model[key]);
            case "scheduledSendTime":
              if (!this.model.scheduledSendTime) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "short") : dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR);
              }
              return dateUtils.getDateOnlyString(this.model[key]);
            default:
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildListName: function () {
      var listName = this.model.storeName || this.model.listName;
      if (this.model.wasSourceDeleted) {
        listName = "Deleted list or segment";
      }
      if (this.model.audienceStrategy === "upload") {
        listName = "Imported file";
      }
      this.node_listName.appendChild(utils.compileTemplate(listName ? this.template_listName : "", {
        listName: listName,
        segmentName: this.model.hasSegment && this.model.segmentDisplay ? this.model.segmentDisplay : "",
        snapshotDate: "",
        snapshotDateClass: "hide"
      }));
    },
    /**
     * override
     */
    _buildStatusBadge: function () {
      var status = this.statusContent[this.model.statusReason] || this.statusContent[this.model.status];
      if (status) {
        this.node_badge.innerHTML = status.label;
        domClass.add(this.node_badge, status.badge);
      }
    },
    /**
     * override
     */
    _buildStatusBadgeTranslated: function () {
      var status = this.statusContentTranslated[this.model.statusReason] || this.statusContentTranslated[this.model.status];
      if (status) {
        this.node_badge.innerHTML = status.label;
        domClass.add(this.node_badge, status.badge);
      }
    },
    /**
     * override
     */
    _buildTypeName: function () {
      if (this.model.postcardAutomationStrategy && this.typeContent[this.model.postcardAutomationStrategy]) {
        var recurringTypeName = this.typeContent[this.model.postcardAutomationStrategy].label;
        this.node_typeName.innerHTML = this.template_typeIcon.innerHTML + recurringTypeName;
        return;
      }
      this.node_typeName.innerHTML = this.typeContent[this.model.type].label;
    },
    /**
     * override
     */
    _buildTypeNameTranslated: function () {
      if (this.model.postcardAutomationStrategy && this.typeContentTranslated[this.model.postcardAutomationStrategy]) {
        var recurringTypeName = this.typeContentTranslated[this.model.postcardAutomationStrategy].label;
        this.node_typeName.innerHTML = this.template_typeIcon.innerHTML + recurringTypeName;
        return;
      }
      this.node_typeName.innerHTML = this.typeContentTranslated[this.model.type].label;
    }
  });
  PostcardSlat.STATUS_CONTENT = statusContent;
  return PostcardSlat;
});