/**
 * @class mojo.widgets.tag.InlineTaggingWithBadges
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl/filter/misc", "dojox/html/entities", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/form/DropDownButton", "mojo/utils", "mojo/lib/logger", "mojo/user", "mojo/lib/flags",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/inline-tagging-with-badges.html",
//Widgets
"mojo/widgets/tags/TagBadge", "mojo/widgets/tags/TagOverlay",
//API
"../tags/Api"], function (declare, lang, filter, htmlEntities, keys, on, domConstruct, domClass, query, domAttr, registry, DropDownButton, utils, logger, user, flags, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tagsTplString, TagBadge, TagOverlay, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tagsTplString,
    /**
     * Populated by data-dojo-args or constructor args.
     *
     * @var number
     */
    listID: null,
    /**
     * Populated by data-dojo-args or constructor args.
     *
     * @var ?boolean
     */
    uiOptionHideHeader: null,
    /**
     * Populated by attach point.
     *
     * @var DOMNode
     */
    header: null,
    /**
     * Populated by attach point.
     *
     * @var DOMNode
     */
    taggingButtonContainer: null,
    /**
     * Populated by attach point.
     *
     * @var DOMNode
     */
    currentSelectedTagsListContainer: null,
    currentSelectedTags: [],
    taggingButton: null,
    tagOverlay: null,
    tagBadgeHandles: [],
    /**
     * Populated by contructor args. Keeps state synced with the tag widget
     * and is bound to the scope it's passed from.
     *
     * @param {array} required Used to update a landing page's signup tags.
     * @return {array} Returns the array parameter to landing page's state.
     *
     */
    updateSignupTags: null,
    analyticsCategory: null,
    analyticsStatus: null,
    tagLimit: null,
    tags: null,
    addTags: null,
    tagLimitMessage1: null,
    tagLimitMessage2: null,
    tagSelectionCTAText: null,
    noSelectedTagsText: null,
    hideManageTags: null,
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      if (!this.listID) {
        throw new Error("requires valid listID parameter");
      }
      this.tagOverlay = new TagOverlay({
        "listId": this.listID,
        "tagCreationAllowed": true,
        "analyticsData": "Import",
        "analyticsCategory": this.analyticsCategory,
        "analyticsStatus": this.analyticsStatus,
        "tagSelectionCTAText": this.tagSelectionCTAText,
        "hideManageTags": this.hideManageTags
      });

      //Set up the Assign Tags button as a DropDownButton
      this.taggingButton = new DropDownButton({
        label: "<span class='freddicon plus padding-left--lv0'></span>",
        dropDown: this.tagOverlay,
        dropDownPosition: ["below"]
      }, this.taggingButton);
      this.taggingButton.startup();
      if (this.uiOptionHideHeader === false) {
        // hide the header
        domClass.add(this.header, "hide");
      } else {
        // add some spacing to the element beneath the header
        domClass.add(this.currentSelectedTagsListContainer, "padding-top--lv3");
      }
      this.setupListeners();
      this.populateCurrentTags();
      this.updateTagOverlay();
      if (this.noSelectedTagsText) {
        this.noSelectedTags.innerHTML = this.noSelectedTagsText;
      }
    },
    /**
     * Show the selected tags
     */
    populateCurrentTags: function () {
      var self = this;
      this.destroyCurrentTags();
      if (this.currentSelectedTags.length < 1) {
        domConstruct.empty(this.currentSelectedTagsListContainer);
        domClass.remove(this.noSelectedTags, "hide");
      } else {
        domClass.add(this.noSelectedTags, "hide");
        this.toggleNote();
        this.currentSelectedTags.forEach(function (tag) {
          tag.tag_name = htmlEntities.decode(tag.tag_name);
          var tagBadge = new TagBadge({
            "tag": tag,
            "tagName": tag.tag_name,
            "readOnly": false
          });
          tagBadge.placeAt(self.currentSelectedTagsListContainer, "last");
          var tagHandle = on(tagBadge, "remove", lang.hitch(self, "removeTagFromCurrentSelection"));
          self.tagBadgeHandles.push(tagHandle);
        });
      }
      this.updateTagOverlay();
    },
    /**
     * Toggle the tagging note based on number of selected tags
     */
    toggleNote: function () {
      if (this.currentSelectedTags.length === this.tagLimit) {
        this.toggleAddTagButton("disable");
        domClass.remove(this.taggingNote, "hide");
      } else {
        this.toggleAddTagButton("enable");
        domClass.add(this.taggingNote, "hide");
      }
    },
    /**
     * Clear out all tag badge DOM nodes and event handles
     */
    destroyCurrentTags: function () {
      domConstruct.empty(this.currentSelectedTagsListContainer);
      this.tagBadgeHandles.forEach(function (handle) {
        handle.remove();
      });
      this.tagBadgeHandles = [];
    },
    /**
     * Set up all event listeners and subscriptions
     */
    setupListeners: function () {
      on(this.tagOverlay, "applyTag", lang.hitch(this, "addTagToCurrentSelection"));
      on(this.tagOverlay, "removeTag", lang.hitch(this, "removeTagFromCurrentSelection"));
      on(this.tagOverlay, "createTag", lang.hitch(this, "createTag"));
    },
    /**
     * update the tag overlay with currently selected tags
     */
    updateTagOverlay: function () {
      var selectedTagIDs = [];
      this.currentSelectedTags.forEach(function (selectedTag) {
        selectedTagIDs.push(parseInt(selectedTag.tag_id, 10));
      });
      this.tagOverlay.setSelection({
        "all": selectedTagIDs,
        "mixed": []
      });
    },
    /**
     * notify tag overlay of change and re-render tags
     */
    onTagChange: function () {
      if (this.updateSignupTags) {
        this.updateSignupTags(this.currentSelectedTags);
      }
      this.populateCurrentTags();
      this.updateInputValue();
    },
    /**
     * update this widget input value to be use if inset in form
     */
    updateInputValue: function () {
      var joinedIDs = this.currentSelectedTags.map(function (tag) {
        return tag.tag_id;
      }).join(",");
      this.valueInput.value = joinedIDs;
    },
    /**
     * Add a tag to the current selection
     * @param {object} tag Tag to apply
     * @private
     */
    addTagToCurrentSelection: function (tag) {
      this.set("currentSelectedTags", this.currentSelectedTags.concat([tag]));
      this.taggingButton.closeDropDown();
      this.onTagChange();
      if (this.analyticsCategory && this.analyticsStatus) {
        var analyticsCategory = this.analyticsCategory + " - " + this.analyticsStatus;
        logger.googleAnalytics.trackEvent(analyticsCategory, "Clicked", "Add Tag");
      }
    },
    /**
     * Remove tag from the selected list
     * @param {object} removalTag Tag to remove
     * @private
     */
    removeTagFromCurrentSelection: function (removalTag) {
      this.taggingButton.closeDropDown();
      this.set("currentSelectedTags", this.currentSelectedTags.filter(function (tag) {
        return removalTag.tag_id !== tag.tag_id;
      }));
      this.onTagChange();
      if (this.analyticsCategory && this.analyticsStatus) {
        var analyticsCategory = this.analyticsCategory + " - " + this.analyticsStatus;
        logger.googleAnalytics.trackEvent(analyticsCategory, "Clicked", "Remove Tag");
      }
    },
    /**
     * Make API call to create new tag and apply it to current selection
     * @private
     */
    createTag: function () {
      this.createTagWithName(this.tagOverlay.getInputValue());
    },
    /**
     * Make API call to create new tag with a certain name.
     * @param {String} name Name of the tag to add.
     */
    createTagWithName: function (name) {
      api.createTagApplyToContacts(this.listID, name, [], lang.hitch(this, "successCallback"));
    },
    /**
     * Generic success callback for updates to tags
     * Show a toast message and re-hydrate the widget
     * @param {object} response from API response
     */
    successCallback: function (response) {
      this.set("currentSelectedTags", this.currentSelectedTags.concat([response.created_tag]));
      this.taggingButton.closeDropDown();
      this.onTagChange();
      if (this.analyticsCategory && this.analyticsStatus) {
        var analyticsCategory = this.analyticsCategory + " - " + this.analyticsStatus;
        logger.googleAnalytics.trackEvent(analyticsCategory, "Submit", "Create and Add Tag");
      }
    },
    /**
     * Toggle the add button based on the optional
     * tag limit being reached or not
     * @param {String} action to apply
     */
    toggleAddTagButton: function (action) {
      if (action === "disable") {
        domAttr.set(this.taggingButtonContainer, "disabled", "disabled");
        domClass.remove(this.taggingNote, "hide");
      } else if (action === "enable") {
        domAttr.remove(this.taggingButtonContainer, "disabled");
        domClass.add(this.taggingNote, "hide");
      }
    }
  });
});