define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// keys
"../focus",
// focus.focus()
"./DropDownButton", "dojo/text!./templates/ComboButton.html", "../a11yclick" // template uses ondijitclick
], function (declare, keys, focus, DropDownButton, template) {
  // module:
  //		dijit/form/ComboButton

  return declare("dijit.form.ComboButton", DropDownButton, {
    // summary:
    //		A combination button and drop-down button.
    //		Users can click one side to "press" the button, or click an arrow
    //		icon to display the drop down.
    //
    // example:
    // |	<button data-dojo-type="dijit/form/ComboButton" onClick="...">
    // |		<span>Hello world</span>
    // |		<div data-dojo-type="dijit/Menu">...</div>
    // |	</button>
    //
    // example:
    // |	var button1 = new ComboButton({label: "hello world", onClick: foo, dropDown: "myMenu"});
    // |	dojo.body().appendChild(button1.domNode);
    //

    templateString: template,
    // Map widget attributes to DOMNode attributes.
    _setIdAttr: "",
    // override _FormWidgetMixin which puts id on the focusNode
    _setTabIndexAttr: ["focusNode", "titleNode"],
    _setTitleAttr: "titleNode",
    // optionsTitle: String
    //		Text that describes the options menu (accessibility)
    optionsTitle: "",
    baseClass: "dijitComboButton",
    // Set classes like dijitButtonContentsHover or dijitArrowButtonActive depending on
    // mouse action over specified node
    cssStateNodes: {
      "buttonNode": "dijitButtonNode",
      "titleNode": "dijitButtonContents",
      "_popupStateNode": "dijitDownArrowButton"
    },
    _focusedNode: null,
    _onButtonKeyDown: function ( /*Event*/evt) {
      // summary:
      //		Handler for right arrow key when focus is on left part of button
      if (evt.keyCode == keys[this.isLeftToRight() ? "RIGHT_ARROW" : "LEFT_ARROW"]) {
        focus.focus(this._popupStateNode);
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    _onArrowKeyDown: function ( /*Event*/evt) {
      // summary:
      //		Handler for left arrow key when focus is on right part of button
      if (evt.keyCode == keys[this.isLeftToRight() ? "LEFT_ARROW" : "RIGHT_ARROW"]) {
        focus.focus(this.titleNode);
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    focus: function ( /*String*/position) {
      // summary:
      //		Focuses this widget to according to position, if specified,
      //		otherwise on arrow node
      // position:
      //		"start" or "end"
      if (!this.disabled) {
        focus.focus(position == "start" ? this.titleNode : this._popupStateNode);
      }
    }
  });
});