define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/form/CheckedMultiSelect", "./_SegmentInputMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, CheckedMultiSelect, _SegmentInputMixin, array, lang, I18nTranslation, flags, context) {
  return declare([CheckedMultiSelect, _SegmentInputMixin], {
    "class": "condition-value",
    multiple: true,
    dropDown: true,
    nounLabel: "item",
    postMixInProperties: function () {
      this.inherited(arguments);
      this.labelText = this._getSelectLabel();
    },
    startup: function () {
      this.inherited(arguments);

      /**
       * Fixes an issue where clicking drop-down doesn't open the menu
       */
      this.dropDownButton.on("click", lang.hitch(this, function () {
        this.dropDownButton.openDropDown();
      }));
    },
    getDisplayHTML: function () {
      if (this.value && this.value.length) {
        var labels = [];
        array.forEach(this.options, function (option) {
          if (option.selected) {
            labels.push(option.label);
          }
        });
        return labels.join("<span class=\"dim6\">,</span> ");
      }
      return lang.replace("<span class=\"dim6\">(No {nounLabel})</span>", {
        nounLabel: this.nounLabel
      });
    },
    _getNumSelected: function () {
      var count = 0;
      array.forEach(this.options, function (option) {
        if (option.selected) {
          count++;
        }
      });
      return count;
    },
    _updateSelection: function () {
      this.inherited(arguments);
      this._updateSelectLabel();
    },
    _updateSelectLabel: function () {
      if (this.dropDown && this.dropDownButton) {
        this.dropDownButton.set("label", this._getSelectLabel());
      }
    },
    _getSelectLabel: function () {
      var count = this._getNumSelected();
      var selected = "selected";
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        selected = I18nTranslation.translate("audience_management_segment_builder_selected");
      }
      var tpl = "{num} {nounLabel}" + (count !== 1 ? "s" : "") + " " + selected;
      return lang.replace(tpl, {
        "num": this._getNumSelected(),
        "nounLabel": this.nounLabel
      });
    },
    getValue: function () {
      return this.value;
    },
    setValue: function (value) {
      this.set("value", value);
    }
  });
});