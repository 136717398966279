define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "mojo/user", "mojo/utils", "mojo/widgets/ActionableDialog", "mojo/utils/I18nTranslation", "dojo/text!mojo/widgets/campaigns/templates/CampaignsRenameDialog.html"], function (declare, lang, dom, domClass, domConstruct, on, query, xhr, entities, _TemplatedMixin, _WidgetBase, user, utils, ActionableDialog, I18nTranslation, templateString) {
  /**
   * This widget reimplements the Campaigns page "Rename Campaign" modal
   * to accept event handlers that are called when a user clicks the primary
   * action button ("Save"), the secondary action button ("Cancel"), and
   * the close ("X") button in the modal.
   *
   * Callers can use the default behavior that submits the rename form to
   * /campaigns/rename or supply custom behavior in the handlers and override
   * the default with `event.preventDefault()`.
   */
  return declare([_WidgetBase], {
    campaignUniqueId: "",
    campaignTitle: "",
    _decodedCampaignTitle: "",
    constructor: function () {
      var args = arguments[0] || {};

      // Adapted from web/js/mojo/app/campaigns.js
      // https://git.rsglab.com/product/mailchimp/blob/aecdc738804d51ea9930fcdc2f095c855f050029/web/js/mojo/app/campaigns.js#L266-L269
      // create a decoding map that supports single quotes and uses dojox's html, latin entity defaults
      var customMap = [["'", "#039"]];
      var maps = customMap.concat(entities.html, entities.latin);
      this._decodedCampaignTitle = entities.decode(args.campaignTitle, maps);

      // Use an internal widget to set attach point attributes:
      var Template = declare([_WidgetBase, _TemplatedMixin], {
        templateString: templateString,
        newTitle: this._decodedCampaignTitle,
        _setNewTitleAttr: {
          node: "newTitleNode",
          type: "attribute",
          attribute: "value"
        },
        oldTitle: this._decodedCampaignTitle,
        _setOldTitleAttr: {
          node: "oldTitleNode",
          type: "attribute",
          attribute: "value"
        },
        id: args.campaignUniqueId,
        _setIdAttr: {
          node: "idNode",
          type: "attribute",
          attribute: "value"
        },
        token: user.getCsrfToken(),
        _setTokenAttr: {
          node: "tokenNode",
          type: "attribute",
          attribute: "value"
        },
        newTitleLabel: I18nTranslation.translate("campaigns_rename_field_label"),
        _setNewTitleLabelAttr: {
          node: "newTitleLabelNode",
          type: "innerHTML"
        },
        newTitleHelp: I18nTranslation.translate("campaigns_rename_field_help"),
        _setNewTitleHelpAttr: {
          node: "newTitleHelpNode",
          type: "innerHTML"
        }
      });
      this.dialog = new ActionableDialog(lang.mixin({
        title: I18nTranslation.translate("campaigns_rename_title"),
        primaryActionLabel: I18nTranslation.translate("campaigns_rename_save"),
        secondaryActionLabel: I18nTranslation.translate("campaigns_rename_cancel"),
        _setUpContentMessage: function () {
          // "this" refers to the ActionableDialog instance:
          new Template().placeAt(this.contentArea);
        }
      }, args, {
        onCancel: lang.hitch(this, this._onCancel),
        onClickPrimaryAction: lang.hitch(this, this._onClickPrimaryAction),
        onClickSecondaryAction: lang.hitch(this, this._onClickSecondaryAction)
      }));
    },
    show: function () {
      this.dialog.show();
    },
    _onCancel: function () {
      if (typeof this.onCancel === "function") {
        this.onCancel();
      }
    },
    _onClickPrimaryAction: function (event) {
      if (typeof this.onClickPrimaryAction === "function") {
        this.onClickPrimaryAction(event, this.dialog);

        // Give callers the option to cancel the default behavior:
        if (event.defaultPrevented) {
          return;
        }
      }
      event.preventDefault();
      var primaryActionButton = query("#primaryActionButton", this.dialog.domNode)[0];

      // Adapted from web/js/mojo/views/campaigns/index.js
      // https://git.rsglab.com/product/mailchimp/blob/aecdc738804d51ea9930fcdc2f095c855f050029/web/js/mojo/views/campaigns/index.js#L460-L491
      utils.toggleButtonLoadingState(primaryActionButton);
      var renameField = query("#renameField", this.dialog.domNode)[0];
      var val = renameField.value;
      var old = this._decodedCampaignTitle;
      if (val && val !== old) {
        var form = query("form", this.dialog.domNode)[0];
        form.submit();
      } else {
        var renameError = query("#renameError", this.dialog.domNode)[0];
        domClass.add(renameField, "invalid");
        domClass.remove(renameError, "hide");
        if (val === old) {
          renameError.innerHTML = I18nTranslation.translate("campaigns_rename_same_name");
        } else {
          renameError.innerHTML = I18nTranslation.translate("campaigns_rename_no_name");
        }
        utils.toggleButtonLoadingState(primaryActionButton);
      }
    },
    _onClickSecondaryAction: function (event) {
      if (typeof this.onClickSecondaryAction === "function") {
        this.onClickSecondaryAction(event, this.dialog);

        // Give callers the option to cancel the default behavior:
        if (event.defaultPrevented) {
          return;
        }
      }
      event.preventDefault();
      this.dialog.hide();
    }
  });
});