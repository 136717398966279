/**
 * Image Block
 * @module mojo/widgets/ads/facebook/edit-content/ImageBlock.js
 * @see mojo/widgets/ads/facebook/edit-content/CarouselItem.js
 *
 * Widget to handle the various interactions associated with the Ad Editor image section. Not to be confused with
 * mojo/neapolitan/widgets/ImageRow.js
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ImageEditor", "mojo/url", "mojo/utils", "mojo/widgets/ads/facebook/edit-content/Api", "mojo/widgets/FileManager", "mojo/user", "mojo/lib/flags", "mojo/lib/logger",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/ImageBlock.html"], function (declare, lang, topic, ImageEditor, mUrl, utils, ContentApi, FileManager, user, flags, logger, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    // Ad Unique Identifier. Used for publishing to backend.
    adId: null,
    // Which item in the carousel is this under? Necessary for updating backend.
    index: null,
    imageUrl: "",
    // Is the media item editable (gifs and videos can't be edited)
    mediaIsEditable: null,
    // List of non-editable file extensions to check against
    nonEditableFiles: [],
    socialAdMessage: "",
    hasNewSocialAdMessage: false,
    constructor: function (props) {
      this.props = props;
    },
    /**
     * Before we show the Image block, make sure we have all of the necessary data.
     */
    postMixInProperties: function () {
      this._validateRequiredProps();

      // Check to see an image/media item is available and check if its editable
      if (this.imageUrl) {
        this.mediaIsEditable = this.isMediaEditable(this.imageUrl);
      }
    },
    postCreate: function () {
      logger.googleAnalytics.trackEvent("social ads", "autodesigner shown", "use creative assistant designs", {
        'dimension53': 'Creative Assistant',
        'dimension54': '/ads/edit?id=' + this.adId,
        'dimension56': 'button displayed'
      });
      logger.info("creative-assistant", "Autodesigner - AD btn shown to user", {
        location: "image block",
        url: window.location.href
      });
    },
    _handleInputChange: function (inputField) {
      if (inputField.hasChanged) {
        this.socialAdMessage = inputField.message;
        this.hasNewSocialAdMessage = inputField.hasChanged;
      }
    },
    /**
     * Respond to CA button click
     */
    showAutoDesigner: function () {
      logger.googleAnalytics.trackEvent("social ads", "autodesigner selected", "select creative assistant design", {
        'dimension53': 'Creative Assistant',
        'dimension54': '/ads/edit?id=' + this.adId,
        'dimension56': 'Facebook ad: use autodesigner'
      });
      logger.info("creative-assistant", "Autodesigner - social-ads-creative-assistant-button-clicked");
      if (FileManager.isShown()) {
        return;
      }
      var isCarousel = this.props.imageClass === 'v-fbAttachment--carousel';
      var blockSize = isCarousel ? {
        width: 540,
        height: 540
      } : {
        width: 600,
        height: 314
      };
      FileManager.show({
        actions: "select,edit,delete",
        allowedFileTypes: ["image"],
        shouldChangeSource: true,
        newSource: {
          id: 'content-engine'
        },
        autodesignerChannel: 'ads',
        allowAutoDesigner: true,
        blockSize: blockSize,
        triggerType: 'ads',
        relatedAssetsRequired: false,
        onSelect: this._onImageSelection.bind(this)
      });
    },
    /**
     * Respond to add/replace button click. Event handler is within the template.
     *
     * @private
     */
    _handleAdd: function () {
      this._initFileManager();
    },
    /**
     * Respond to remove button click. Event handler is within the template.
     *
     * @private
     */
    _handleRemove: function () {
      var formData = {
        id: this.adId,
        index: this.index
      };
      ContentApi.removeImage(formData).then(this._onRemoveImageResponse.bind(this));
    },
    /**
     * Respond to edit button click. Event handler is within the template.
     *
     * @private
     */
    _handleEdit: function () {
      this._initImageEditor();
    },
    /**
     * Start up the image editor and wait for them to finish editing.
     *
     * @private
     */
    _initImageEditor: function () {
      var imageUrl = mUrl.removeProxy(this.imageUrl);
      ImageEditor.open(imageUrl).then(this._onImageEdited.bind(this), this._onImageEditError.bind(this));
    },
    /**
     * The user is done editing the image. Call out to the backend to save it and assign it to this carousel item.
     *
     * @param {string} imageData - Image data returned from the image editor
     * @private
     */
    _onImageEdited: function (imageData) {
      var formData = {
        id: this.adId,
        index: this.index,
        data: imageData
      };
      ContentApi.editImage(formData).then(this._onSaveImageResponse.bind(this));
    },
    /**
     * Just in case things with the Image Editor go south
     *
     * @param {string} error - Contents of the error
     * @private
     */
    _onImageEditError: function (error) {
      // don't throw an error; it's ok if the image editor is canceled
    },
    /**
     * Show the File Manager AKA Content Studio. This is where folks'll select the image they want to use.
     *
     * @private
     */
    _initFileManager: function () {
      logger.googleAnalytics.trackEvent("social ads", "my files selected", "select file", {
        'dimension53': 'Browse',
        'dimension54': '/ads/edit?id=' + this.adId,
        'dimension56': 'Facebook ad: use my files'
      });
      // Don't wanna try to load multiple.
      if (FileManager.isShown()) {
        return;
      }
      var isCarousel = this.props.imageClass === 'v-fbAttachment--carousel';
      var blockSize = isCarousel ? {
        width: 540,
        height: 540
      } : {
        width: 600,
        height: 314
      };
      // File Manager operates as a singleton so no need to actually instantiate.
      FileManager.show({
        actions: "select,edit,delete",
        allowedFileTypes: ["image"],
        shouldChangeSource: true,
        newSource: {
          id: 'file'
        },
        autodesignerChannel: 'ads',
        allowAutoDesigner: true,
        blockSize: blockSize,
        relatedAssetsRequired: false,
        triggerType: 'ads',
        onSelect: this._onImageSelection.bind(this)
      });
    },
    /**
     * Handle selection from Content Studio. Take that info and tell the backend that the user selected an image
     * for this attachment/CarouselItem
     *
     * @param {String} url - URL to the image. Unused in this context.
     * @param {String} name - File name or whatever name is within Content Studio. Unused.
     * @param {Number} id - File ID of the item within Content Studio / MC Backend.
     * @private
     */
    _onImageSelection: function (url, name, id) {
      var formData = {
        file_id: id,
        id: this.adId,
        index: this.index
      };
      ContentApi.addImage(formData).then(this._onSaveImageResponse.bind(this));
      FileManager.hide();
    },
    /**
     * Handle adding and editing Image API request. Both adding and editing have the same response behavior.
     * If all things go well, notify the parent widget and re-render the view.
     *
     * @param {promise} response - What we get back from the server. Either success or error with what the errors are.
     * @private
     */
    _onSaveImageResponse: function (response) {
      switch (response.status) {
        case "success":
          this.set("imageUrl", response.url);
          this.set("mediaIsEditable", !response.isGif);
          this.render();
          break;
        case "error":
          utils.showFormErrors(this.domNode, response.errors);
          break;
        default:
          throw Error("Unknown status " + response.status);
      }
    },
    /**
     * Handle Remove Image API request. If all things go well, notify the parent widget and re-render the view.
     *
     * @param {promise} response - What we get back from the server. Either success or error with what the errors are.
     * @private
     */
    _onRemoveImageResponse: function (response) {
      switch (response.status) {
        case "success":
          this.set("imageUrl", "");
          this.render();
          break;
        case "error":
          utils.showFormErrors(this.domNode, response.errors);
          break;
        default:
          throw Error("Unknown status " + response.status);
      }
    },
    /**
     * Checks the selected media item to see if its editable
     *
     * @param {string} mediaUrl - The url of the selected media
     * @returns {boolean} Is the media item editable?
     * @private
     */
    isMediaEditable: function (mediaUrl) {
      var fileExtension = mediaUrl.split(".").pop();
      this.nonEditableFiles = ["gif"];
      return this.nonEditableFiles.indexOf(fileExtension) === -1;
    },
    /**
     * Throw some errors if we are missing crucial data.
     *
     * @private
     */
    _validateRequiredProps: function () {
      if (this.adId === null) {
        throw new Error("The ad Unique ID is not configured. It is required.");
      }
      if (this.index === null) {
        throw new Error("An index is required for the Image Block.");
      }
      if (typeof this.index !== "number") {
        throw new Error("Invalid item carousel index format.");
      }
    },
    /**
     * We are treating this widget as something of an Input. This method allows us to simply get Image URL like
     * we do for other inputs in the interface.
     *
     * @returns {String} - The ImageURL
     */
    getValue: function () {
      return this.imageUrl;
    }
  });
});