define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/ViewAnalytics.html", "mojo/url", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation", "dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, on, tpl, murl, flags, context, I18nTranslation, _WidgetBase, _TemplatedMixin) {
  return declare([_WidgetBase, _TemplatedMixin], {
    buttonLabel: "View analytics",
    buttonClass: "button-wink button-wink-secondary add-btn",
    templateString: tpl,
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (translations) {
          this.buttonLabel = translations.campaigns_actions_view_analytics;
        }
      }.bind(this));
    },
    postCreate: function () {
      on(this.domNode, "click", function () {
        window.location.href = murl.toUrl("/analytics/email-dashboard");
      });
    }
  });
});