define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/registry", "dojo/text!./templates/promptDisplay.html", "mojo/neapolitan/genai/aiassistant/prompt-creator", "mojo/neapolitan/genai/aiassistant/PromptPane/FeedbackDialog", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, on, domClass, domAttr, lang, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, registry, tpl, promptCreator, FeedbackDialog, EcsTracking, EcsEvents) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    // Text that is being modified by the AI
    sourceText: "",
    // The type of request, i.e. shorten/lengthen etc
    requestMessage: "",
    // Text used to render as the response in the widget. Set to the appropriate value in postMixInProperties based on the prompt
    responseText: "",
    // A reference to a prompt created through prompt-creator
    prompt: null,
    // data-dojo-attach-point in the template
    quickActions: null,
    // data-dojo-attach-point in the template
    insertButton: null,
    // data-dojo-attach-point in the template
    applyButton: null,
    // data-dojo-attach-point in the template
    retryButton: null,
    // data-dojo-attach-point in the template
    shortenButton: null,
    // data-dojo-attach-point in the template
    lengthenButton: null,
    // data-dojo-attach-point in the template
    feedbackButton: null,
    // data-dojo-attach-point in the template
    toneSelect: null,
    // data-dojo-attach-point in the template
    customPromptButton: null,
    toneOptions: null,
    // Whether all actions are disabled on the prompt
    disabled: false,
    // reference to the prompt pane
    promptPane: null,
    constructor: function () {
      this.toneOptions = [];
      this.promptPane = registry.byId("aiPrompt");
    },
    postMixInProperties: function () {
      if (!this.prompt) {
        return;
      }
      if (this.prompt.responseStatus === promptCreator.responseStatuses.success) {
        this.requestMessage = this._generateRequestMessage();
        this.responseText = this.prompt.responseContent;
        this.sourceText = this.prompt.sourceText;
      } else {
        switch (this.prompt.responseError) {
          case promptCreator.responseErrors.unsupportedLanguage:
            this.responseText = "Sorry, we do not support requests in that language.";
            break;
          default:
            this.responseText = "Oops! We had issues handling your request.";
        }
      }
    },
    postCreate: function () {
      this.own(on(this.applyButton, "click", lang.hitch(this, "onPromptInsert")), on(this.insertButton, "click", lang.hitch(this, "onPromptInsert")), on(this.retryButton, "click", lang.hitch(this, "onPromptRetry")), on(this.shortenButton, "click", lang.hitch(this, "onPromptShorten")), on(this.lengthenButton, "click", lang.hitch(this, "onPromptLengthen")), on(this.toneSelect, "toneChange", lang.hitch(this, "onPromptToneChange")), on(this.feedbackButton, "click", lang.hitch(this, "_showFeedbackDialog")), on(this.customPromptButton, "click", lang.hitch(this, "onCustomPrompt")));
    },
    _showFeedbackDialog: function () {
      var event = EcsEvents.ShareFeedbackEvent;
      event.campaign_id = window.app.campaignId;
      event.campaign_type = window.app.campaignType;
      event.ai_shortcut_selected_h1 = "Share Feedback";
      EcsTracking.track(event);
      if (!this.dialog) {
        this.dialog = new FeedbackDialog();
        this.dialog.on("FeedbackSubmitted", lang.hitch(this, "onPromptFeedbackShared"));
      }
      this.dialog.show();
    },
    setDisabled: function (disabled) {
      this.disabled = disabled;
      this._toggleActions();
    },
    _toggleActions: function () {
      this._toggleButtonState(this.disabled);
      domClass.toggle(this.quickActions, "hide", this.disabled);
      domClass.toggle(this.applyButton, "hide", this.disabled);
    },
    /**
     * Toggles the disabled state of various buttons and the toneSelect widget.
     *
     * @param {boolean} isDisabled - If true, sets the disabled attribute on the buttons and toneSelect widget. If false, removes the disabled attribute.
     * @private
     */
    _toggleButtonState: function (isDisabled) {
      var buttons = [this.applyButton, this.insertButton, this.retryButton, this.shortenButton, this.lengthenButton, this.feedbackButton, this.customPromptButton];
      buttons.forEach(function (button) {
        // Stop processing this iteration if no button is present
        if (!button) {
          return;
        }
        if (isDisabled) {
          domAttr.set(button, "disabled", "disabled");
        } else {
          domAttr.remove(button, "disabled");
        }
      });
      this.toneSelect.setDisabled(isDisabled);
    },
    /**
     * Handle generation of the request message
     *
     * @private
     * @returns {string} requestMessage
     */
    _generateRequestMessage: function () {
      var requestMessage = "You asked to ";
      switch (this.prompt.command) {
        case promptCreator.commandTypes.shorten:
          requestMessage += "shorten the content";
          break;
        case promptCreator.commandTypes.lengthen:
          requestMessage += "lengthen the content";
          break;
        case promptCreator.commandTypes.changeTone:
          requestMessage += "change tone to " + this.prompt.commandParams.tone.toLowerCase();
          break;
        case promptCreator.commandTypes.fixSpelling:
          requestMessage += "correct spelling & grammar";
          break;
        default:
          requestMessage += '"' + this.prompt.getPromptText() + '"';
      }
      return requestMessage;
    },
    /**
     * Dojo widget event for when we want to insert a prompt
     */
    onPromptInsert: function () {},
    /**
     * Dojo widget event for when we want to shorten a prompt
     */
    onPromptRetry: function () {},
    /**
     * Dojo widget event for when we want to shorten a prompt
     */
    onPromptShorten: function () {},
    /**
     * Dojo widget event for when we want to lengthen a prompt
     */
    onPromptLengthen: function () {},
    /**
     * Dojo widget event for when we want to lengthen a prompt
     *
     * @param {string} tone the tone value
     */
    onPromptToneChange: function (tone) {},
    /**
     * Dojo widget event for when a user submits a feedback
     *
     * @param {string} feedback the feedback shared by the customer
     */
    onPromptFeedbackShared: function (feedback) {},
    /**
     * Dojo widget event for when a user wants to input their custom prompt
     */
    onCustomPrompt: function () {},
    destroy: function () {
      this.inherited(arguments);
      if (this.dialog) {
        this.dialog.destroy();
      }
    }
  });
});