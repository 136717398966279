define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/negate", "vendor/lodash-amd/isNil", "mojo/lib/format", "mojo/utils/string", "mojo/lib/flags", "mojo/widgets/Renderable", "mojo/report/Stat", "mojo/api/postcard", "mojo/user", "mojo/report/widgets/StatusTimeline", "mojo/report/widgets/BigStats", "mojo/report/widgets/StatsTable", "mojo/report/widgets/StatsTableEntries", "mojo/report/widgets/LinksEntry", "mojo/postcard/widgets/FrontAndBackPreview", "mojo/postcard/widgets/RecipientsMap"], function (declare, lang, negate, isNil, format, stringUtils, flags, Renderable, Stat, postcardApi, user, StatusTimeline, BigStats, StatsTable, StatsTableEntries, LinksEntry, FrontAndBackPreview, RecipientsMap) {
  return declare([Renderable], {
    templateString: '<div class="line sub-section" data-dojo-attach-point="subsectionsContainer"></div>',
    constructor: function (props) {
      this.postcard = props.postcard;
      this.report = props.report;
      this.automationStrategy = this.postcard.automationTrigger.strategy;
      this.children = [this.getStatusTimeline(), this.getStatsTable(), this.getBigStats(), this.getPreview(), this.getRecipientsMap()];
    },
    render: function () {
      this.inherited(arguments);
      this.children.filter(negate(isNil)).map(function (widget) {
        widget.placeAt(this.subsectionsContainer);
      }.bind(this));
    },
    getStatusTimeline: function () {
      var orderStatus = this.report.orderStatus;
      return orderStatus ? new StatusTimeline({
        statuses: orderStatus.validKeys,
        currentStatus: orderStatus.key,
        disclaimer: this.report.sendCounts.international > 0 ? "Delivery tracking is currently limited to <strong>U.S. recipients</strong>." : null
      }) : null;
    },
    getStatsTable: function () {
      var children = [];
      var facts = [];
      if (this.automationStrategy === "abandoned_cart" && this.report.statusDescription === "active") {
        facts.push(new Stat("Status", "Sending"));
      } else if (this.postcard.status === "scheduled") {
        facts.push(new Stat("Status", "Scheduled"));
      } else {
        facts.push(new Stat("Status", stringUtils.capitalize(this.report.statusDescription)));
      }
      if (this.postcard.audienceStrategy === "upload") {
        facts.push(new Stat("Recipients", this.report.audienceDescription));
      } else {
        facts.push(new Stat("Audience", this.report.audienceDescription));
      }
      var properties = new StatsTableEntries({
        stats: facts,
        format: StatsTableEntries.FORMAT_FACTOID
      });
      children.push(properties);
      children.push(new StatsTableEntries({
        stats: [new Stat("Total spent", user.formatCurrency(this.report.spend.total, this.report.spend.currencyCode))],
        format: StatsTableEntries.FORMAT_FACTOID
      }));
      var sends;
      if (this.report.sendCounts.international > 0) {
        sends = new StatsTableEntries({
          stats: [new Stat("Total postcards", this.report.sendCounts.total.toString()), new Stat("U.S. postcards", this.report.sendCounts.domestic.toString()), new Stat("International postcards", this.report.sendCounts.international.toString())],
          format: StatsTableEntries.FORMAT_BREAKDOWN
        });
      } else {
        sends = new StatsTableEntries({
          stats: [new Stat("Total postcards", this.report.sendCounts.total.toString())],
          format: StatsTableEntries.FORMAT_FACTOID
        });
      }
      var linksEntry = new LinksEntry({
        links: [{
          url: "/postcards/edit?id=" + this.postcard.postcardId,
          text: "View postcard"
        }]
      });
      children.push(sends, linksEntry);
      return new StatsTable({
        children: children
      });
    },
    getBigStats: function () {
      if (this.postcard.content.promoCodeId === null) {
        return null;
      }
      var bigStats = [new Stat("Promo code uses", this.report.attribution.promoCodeUsageCount.toString()), new Stat("Revenue from promo codes", this.report.attribution.promoCodeUsageRevenue)];
      if (this.report.attribution.promoCodeUsageNewCustomers) {
        bigStats.splice(1, 0, new Stat("New customers", this.report.attribution.promoCodeUsageNewCustomers.toString()));
      }
      return new BigStats({
        stats: bigStats
      });
    },
    getPreview: function () {
      return new FrontAndBackPreview({
        postcard: this.postcard
      });
    },
    getRecipientsMap: function () {
      var recipientCounts = Object.keys(this.report.recipientLocationToCountMap).map(function (countryCode) {
        // Refactor: turn this context-specific recipient count into a more general representation
        return {
          countryCode: countryCode,
          recipientCount: this.report.recipientLocationToCountMap[countryCode]
        };
      }, this);
      return new RecipientsMap({
        recipientCounts: recipientCounts
      });
    }
  });
});