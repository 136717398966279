define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/widgets/Dialog", "mojo/widgets/account/connected-sites/nd/NdDisconnectModal", "mojo/lib/logger", "dojo/text!./templates/DisconnectWarning.html"], function (declare, on, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Dialog, NdDisconnectModal, logger, DisconnectWarningTemplate) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: DisconnectWarningTemplate,
    dialog: null,
    siteId: null,
    storeName: "",
    siteUrl: "",
    isEcommerce: false,
    storePlatform: "",
    displayNd: false,
    ndContent: new NdDisconnectModal(),
    postCreate: function () {
      this.siteIdField.value = this.siteId;
      this.dialog = new Dialog({
        "closable": false,
        "class": "dijitDialog--fullScreen@phone",
        "title": "Are You Sure?",
        "content": this
      });
      this.form.addEventListener("submit", function (e) {
        logger.googleAnalytics.trackEvent("connected sites", "platform disconnect", "button", {
          dimension53: "Disconnect",
          dimension54: "/account/connected-sites/delete-connected-site",
          dimension55: "disconnect site",
          dimension62: this.storePlatform,
          dimension63: this.storePlatform,
          metric6: 1
        });
      }.bind(this));
    },
    show: function () {
      this.dialog.show();
      this._showWarning();
    },
    hide: function () {
      this.dialog.hide();
    },
    _showWarning: function () {
      if (this.isEcommerce && this.displayNd) {
        this.ndContent.placeAt(this.ndContainer);
        domClass.add(this.defaultWarning, "hide");
      } else if (this.isEcommerce) {
        domClass.remove(this.ecomWarning, "hide");
        domClass.add(this.defaultWarning, "hide");
      } else {
        domClass.add(this.ecomWarning, "hide");
        domClass.remove(this.defaultWarning, "hide");
      }
      domClass.remove(this.warning, "hide");
    }
  });
});