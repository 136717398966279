/**
 * Facebook Ad Content API
 *
 * @module mojo/widgets/ads/facebook/edit-content/Api.js
 * @see mojo/widgets/ads/facebook/edit-content/Carousel.js
 *
 * There are a few endpoints and actions we need to keep track of around the content section.
 * This widget is intended as a centralized place to manage get and post requests for the content section.
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (lang, xhr, mUrl) {
  return {
    /**
     * Tell the backend to update the contents of a carousel item. The backend requires knowledge of
     * the Ad unique ID, the index of the carousel item, and the actual content.
     *
     * @param {obj} formData - subjective form data.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     *
     * @private
     */
    updateCarouselItem: function (formData) {
      return this._postForm("/ads/facebook/post-content/", formData);
    },
    /**
     * Tell the backend to remove a carousel item.
     *
     * @param {obj} formData - Unique Ad id and the carousel index.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    deleteCarouselItem: function (formData) {
      return this._postForm("/ads/facebook/remove-attachment", formData);
    },
    /**
     * Tell the backend to empty all of the content and start fresh.
     *
     * @param {obj} formData - Unique Ad id.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    resetCarousel: function (formData) {
      return this._postForm("/ads/facebook/reset-content", formData);
    },
    /**
     * Tell the backend to assign an image for a particular carousel item / attachment.
     *
     * @param {obj} formData - usually file ID, unique ad ID, and attachment index
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    addImage: function (formData) {
      return this._postForm("/ads/facebook/add-image", formData);
    },
    /**
     * Tell the backend to remove an image from a particular carousel item / attachment.
     *
     * @param {obj} formData - just the unique ad ID, and attachment index
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    removeImage: function (formData) {
      return this._postForm("/ads/facebook/remove-image", formData);
    },
    /**
     * Tell the backend to update an image for an attachment. This'll involve saving the image to the Content Studio
     * and validating
     *
     * @param {obj} formData - usually image url, unique ad ID, and attachment index
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    editImage: function (formData) {
      return this._postForm("/ads/facebook/edit-image", formData);
    },
    /**
     * Post the form to the backend. Should be flexible enough for most circumstances
     *
     * @param {string} endpoint - backend path. Such as "/ads/facebook/post-content"
     * @param {obj} formData - subjective form data.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     *
     * @private
     */
    _postForm: function (endpoint, formData) {
      return xhr.post(mUrl.toUrl(endpoint), {
        "handleAs": "json",
        "data": formData
      });
    }
  };
});