define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/lib/logger", "dojo/text!./templates/item.html"], function (declare, lang, on, domClass, Renderable, logger, tpl) {
  return declare([Renderable], {
    saveLabel: "Save",
    cancelLabel: "Cancel",
    errorLabel: "Resolve",
    badge: null,
    tagPreview: null,
    formatTagPreview: null,
    templateString: tpl,
    classNames: ["c-slatMeta", "c-checklistSlat", "flex", "padding--lv3"],
    state: {
      isActive: false,
      isDisabled: false,
      isEmpty: true,
      isSaving: false,
      isValid: false
    },
    canToggleAvailability: true,
    persistentExtraProps: {},
    addPersistentExtraProps: function (context) {
      this.persistentExtraProps = Object.assign(this.persistentExtraProps, context);
    },
    constructor: function (props) {
      this.isComplete = props.isComplete || props.contentWidget.isComplete;
      this.isValid = props.isValid || props.contentWidget.isValid;
      this.state.isActive = props.isActive || false;
      this.state.isDisabled = props.isDisabled || false;
      this.state.isReadOnly = !!props.isReadOnly;
      this.extraProps = props.extraProps || {};
      this.userCanIncludeImportedContacts = props.userCanIncludeImportedContacts;
      if (!this.actionLabel) {
        this.actionLabel = "Add " + props.label;
      }
      if (!this.editLabel) {
        this.editLabel = "Edit " + props.label;
      }
      if (!this.isComplete) {
        throw new RangeError("content editor item must have isComplete defined as a function");
      }
      if (!this.isValid) {
        throw new RangeError("content editor item must have isValid defined as a function");
      }
      this.canToggleAvailability = typeof props.canToggleAvailability === typeof true ? props.canToggleAvailability : this.canToggleAvailability;
    },
    render: function () {
      this.inherited(arguments);
      this.createNewContentWidget();
      this.content.placeAt(this.itemContent);
    },
    createNewContentWidget: function () {
      this.unmount();
      this.content = new this.contentWidget(lang.mixin({
        actionLabel: this.actionLabel,
        tagPreview: this.tagPreview,
        formatTagPreview: this.formatTagPreview,
        activate: this.activate.bind(this),
        cancel: this.cancel.bind(this),
        description: this.description,
        isActive: this.state.isActive,
        isComplete: this.isComplete(this.model),
        isPending: this.isPending && this.isPending(this.model),
        isReadOnly: this.state.isReadOnly,
        isSaving: this.state.isSaving,
        getCurrentlyRenderedItem: this.getCurrentlyRenderedItem,
        isValid: this.isValid(this.model),
        model: this.model,
        onSave: this.onSave.bind(this),
        userCanIncludeImportedContacts: this.userCanIncludeImportedContacts,
        addPersistentExtraProps: this.addPersistentExtraProps.bind(this)
      }, Object.assign(this.extraProps, this.persistentExtraProps)));
    },
    cancel: function (e) {
      if (e) {
        e.preventDefault();
      }
      this.createNewContentWidget();
      this.deactivate();
      this.setState({
        errors: {}
      });
    },
    activate: function () {
      this.setState({
        isActive: true
      });
      this.onActivate();
      if (this.label === "Social Share Preview") {
        //Ga track - category, action, label
        logger.googleAnalytics.trackEvent("landing pages editor", "social share", "edit preview");
      }
    },
    deactivate: function () {
      this.setState({
        isActive: false
      });
      this.onDeactivate();
    },
    setState: function () {
      var newState = arguments[0];
      if (typeof newState.isDisabled === typeof true && !this.canToggleAvailability) {
        newState = lang.clone(newState);
        delete newState.isDisabled;
      }
      this.inherited(arguments, [newState]);
    },
    unmount: function () {
      if (this.content && this.content.unmount) {
        this.content.unmount();
      }
    }
  });
});