define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/_pause-automation.html", "dijit/registry", "mojo/url"], function (declare, lang, dom, domStyle, Renderable, tpl, registry, mUrl) {
  return declare([Renderable], {
    templateString: tpl,
    campaignId: null,
    automationType: null,
    constructor: function (props) {
      this.state = {
        model: props.model
      };
      this.campaignId = props.model.campaignId;
      this.automationType = props.automationType;
    },
    showPauseAutomationDialog: function () {
      var pauseAutomationModal = registry.byId(this.pauseAutomationModal.id);
      pauseAutomationModal.show();
    },
    startConfirmed: function () {
      registry.byId(this.pauseAutomationModal.id).set("title", "Pausing...");
      registry.byId(this.pauseAutomationModal.id).set("closable", false);
      if (this.automationType === "singleWelcome") {
        document.location.href = mUrl.toUrl("/automations/single-email-welcome/pause", {
          "id": this.campaignId
        });
      } else {
        document.location.href = mUrl.toUrl("automations/finished-automation", {
          "id": this.campaignId
        });
      }
    }
  });
});