define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/user"], function (declare, utils, user) {
  var api = {
    DEFAULT_LOGO_URL: "https://cdn-images.mailchimp.com/template_images/gallery/logo@1x.png",
    TOPIC_CHANGES: "mojo/api/brand-assets/topic",
    MERGE_TAG: "*|BRAND:LOGO|*",
    getBrandUrl: function () {
      var bootstrappedUser = user.getBootstrappedInstance();
      var url = bootstrappedUser.brandLogoUrl;
      return url;
    },
    hasCustomBrandUrl: function () {
      return api.getBrandUrl() !== api.DEFAULT_LOGO_URL;
    },
    _setBrandUrl: function (url) {
      var bootstrappedUser = user.getBootstrappedInstance();
      bootstrappedUser.brandLogoUrl = url;
    },
    setBrandItem: function (brandItem) {
      api._setBrandUrl(brandItem ? brandItem.getDisplayUrl() : api.DEFAULT_LOGO_URL);
      utils.globalPublish(api.TOPIC_CHANGES, {
        value: brandItem
      });
    }
  };
  return api;
});