define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, lang, base, FilterMenu, FilterMessage) {
  return declare([base], {
    onReady: function () {
      var filterSettings = {
        target: "orders-list",
        filters: [{
          label: "Order Type",
          key: "type",
          type: "select",
          options: {
            internal: "Internal Orders",
            regular: "Regular Orders"
          }
        }, {
          label: "Status",
          key: "status",
          type: "select",
          options: {
            paid: "Paid",
            refunded: "Refunded",
            failed: "Failed"
          }
        }, {
          label: "Order ID",
          key: "order_id",
          type: "text"
        }, {
          label: "User ID",
          key: "user_id",
          type: "text"
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.ordersFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.ordersFiltersMessage.appendChild(filterMessage.domNode);
    }
  });
});