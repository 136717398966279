define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!../templates/activity-feed/activity-item-sub-unsub.html"], function (declare, lang, domConstruct, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    /**
     * Append items to DOM accounting for cases of:
     * 1. Admin unsub
     * 2. No corresponding campaign link
     * 3. Corresponding campaign link
     */
    postCreate: function () {
      var container = this.unsubContentContainer;
      var reason_text = "<span> with the reason: " + this.data.unsubscribe_reason + "</span>";
      if (this.data.is_admin_unsubscribed) {
        domConstruct.place("<span>admin action.</span>", container);
      }
      if (!this.data.is_admin_unsubscribed && !this.data.campaign_id) {
        domConstruct.place("<span>an unsubscribe form</span>", container);
        domConstruct.place(reason_text, container);
      }
      if (this.data.campaign_id) {
        var campaignURL = mUrl.toUrl("/campaigns/show?id=" + this.data.campaign_id);
        var campaign_link = "<a class='text-decoration--underline' href='" + campaignURL + "' target='_blank'>" + this.data.campaign_title + "</a>";
        domConstruct.place(campaign_link, container);
        domConstruct.place(reason_text, container);
      }
    }
  });
});