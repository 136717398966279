define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/InstallDifficulty.html"], function (declare, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    levelNode: null,
    descriptionNode: null,
    // from constructor
    level: null,
    _setLevelAttr: {
      node: "levelNode",
      type: "innerText"
    },
    description: null,
    _setDescriptionAttr: {
      node: "descriptionNode",
      type: "innerText"
    }
  });
});