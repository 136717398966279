define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/lib/logger", "mojo/widgets/Renderable", "mojo/widgets/Previewer", "mojo/widgets/campaigns/PlainTextDialog", "mojo/widgets/account/paywall/PaywallUpgradeButton", "mojo/neapolitan/ShareSettings", "./_SendTest", "mojo/user", "mojo/lib/flags", "mojo/context", "mojo/authz", "mojo/api/campaign/PresendCheck", "dojo/text!./templates/content.html"], function (declare, lang, on, dom, domClass, registry, topic, htmlEntities, logger, Renderable, Previewer, PlainTextDialog, PaywallUpgradeButton, ShareSettings, SendTest, user, flags, context, Authz, PresendCheck, tpl) {
  var Content = declare([Renderable, Authz], {
    templateString: tpl,
    constructor: function (props) {
      this.state = {
        PresendCheck: PresendCheck,
        promotion: props.model.promotion.promotion || "",
        promotionIsEnabled: props.model.promotion.promotionIsEnabled || "",
        promotionIsComplete: props.model.promotion.promotionIsComplete || "",
        hasFeatureRemoveMailchimpBranding: this.userHasFeature("remove_mailchimp_branding"),
        hasFeatureSendCreativeAssistantEmail: this.userHasFeature("creative_assistant_for_email"),
        hasSentTestEmail: props.model.testsSent >= 1,
        promoteFreeTrialsVariant: context.flagIsOn(flags.PX_PROMOTE_FREE_TRIALS_VARIANT)
      };
    },
    render: function () {
      this.inherited(arguments);
    },
    getData: function () {
      return {
        content: {}
      };
    },
    _trackCreativeAssistatntUpgradeAction: function (planDisplayName) {
      logger.googleAnalytics.trackEvent("creative assistant for email", "cta", "inline", {
        dimension53: "upgrade",
        dimension54: window.location.pathname,
        dimension56: "upgrade moment",
        dimension96: "creative assistant for email",
        dimension97: planDisplayName,
        dimension98: "access creative assistant emails"
      });
      logger.googleAnalytics.trackEvent("checkout ca email", "start", "checklist inline", {
        dimension54: window.location.pathname,
        dimension97: planDisplayName
      });
    },
    postCreate: function () {
      if (this.creativeAssistantUpgradeButtonContainer) {
        this.creativeAssistantUpgradeButton = new PaywallUpgradeButton({
          buttonText: "Upgrade",
          disableRefreshForEditorUpgrade: false,
          fixedGATrackingCallback: this._trackCreativeAssistatntUpgradeAction,
          redirect: "/campaigns/edit?id=" + this.model.campaignId,
          featureName: "creative_assistant_for_email"
        });
        this.creativeAssistantUpgradeButton.placeAt(this.creativeAssistantUpgradeButtonContainer);
        this.creativeAssistantUpgradeButton.startup();
      }
    },
    previewContent: function () {
      if (!this.previewer) {
        this.previewer = new Previewer({
          "previewId": this.model.campaignId,
          "previewType": "campaign",
          "titleText": htmlEntities.encode(this.model.title),
          "showMergeInfo": true
        });
      }
      this.previewer.show();
      // this function is used in the campaign checklist builder and in the automations checklist builder. updated the properties of model to include check for single welcome
      if (this.model.isSingleWelcome) {
        window.ga("send", "event", "one_click_builder", "click", "preview");
      } else {
        window.ga("send", "event", "campaign_checklist", "click", "preview");
      }
    },
    editPlainText: function () {
      PlainTextDialog.show(this.model.campaignId, lang.hitch(this, function () {
        this.onSave();
        if (this.model.isSingleWelcome) {
          window.ga("send", "event", "one_click_builder", "click", "plain_text:save");
        } else {
          window.ga("send", "event", "campaign_checklist", "click", "plain_text:save");
        }
      }));
      if (this.model.isSingleWelcome) {
        window.ga("send", "event", "one_click_builder", "click", "plain_text:edit");
      } else {
        window.ga("send", "event", "campaign_checklist", "click", "plain_text:edit");
      }
    },
    showShareSettings: function () {
      if (this.shareSettings) {
        this.shareSettings.destroy();
      }
      this.shareSettings = new ShareSettings({
        campaignId: this.model.campaignId,
        titleText: htmlEntities.encode(this.model.title),
        onSave: lang.hitch(this, function (promotion) {
          this.onSave();
          if (this.model.isSingleWelcome) {
            window.ga("send", "event", "one_click_builder", "click", "enable_social_cards:save_and_close");
          } else {
            window.ga("send", "event", "campaign_checklist", "click", "enable_social_cards:save_and_close");
          }
        })
      });
      this.shareSettings.show();
      if (this.model.isSingleWelcome) {
        window.ga("send", "event", "one_click_bulder", "click", "enable_social_cards");
      } else {
        window.ga("send", "event", "campaign_checklist", "click", "enable_social_cards");
      }
    },
    openModal: function () {
      if (this.sendTestModal) {
        this.sendTestModal.destroy();
      }
      user.getUserLogins().then(this._openModal.bind(this));
      if (this.model.isSingleWelcome) {
        window.ga("send", "event", "one_click_builder", "click", "send_a_test_email");
      } else {
        window.ga("send", "event", "campaign_builder", "click", "send_a_test_email");
      }
    },
    _openModal: function (userLogins) {
      this.sendTestModal = new SendTest({
        model: this.model,
        userLogins: userLogins
      });
      this.sendTestModal.show();
    },
    showPaywall: function () {
      logger.googleAnalytics.trackEvent("email editor", "cta", "inline", {
        dimension53: "upgrade your account",
        dimension54: window.location.pathname,
        dimension56: "upgrade moment",
        dimension96: "email editor",
        dimension97: user.planDisplayName.toLowerCase(),
        dimension98: "remove mc branding"
      });
      window.top.location = "/account/paywall?redirect=/campaigns/edit?id=" + this.model.campaignId + "&feature_name=remove_mailchimp_branding";
    }
  });

  // Static methods used by the checklist item
  Content.isComplete = function (campaign) {
    return campaign.renderedHtml;
  };
  Content.isValid = function (campaign) {
    return campaign.isContentReady;
  };
  return Content;
});