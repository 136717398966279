define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/from.html", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/Renderable", "mojo/user", "mojo/utils", "mojo/context", "mojo/lib/flags", "mojo/validation/email", "mojo/api/verified-domains", "./_VerifyDomain"], function (declare, lang, on, domClass, domConstruct, array, tpl, ItemButtons, Renderable, user, utils, context, flags, emailValidation, verifiedDomainsApi, verifyDomain) {
  var From = declare([Renderable], {
    templateString: tpl,
    userRole: user.role,
    FROM_NAME_MAX_LENGTH: 100,
    VERIFY_EMAIL_ERROR: 1,
    DMARC_POLICY_ERROR: 2,
    constructor: function (props) {
      var campaign = props.model;
      var defaults = campaign.list ? campaign.list.defaults : {};
      var displayedFromEmail = campaign.from.fromEmail || defaults.fromEmail || "";
      this.isVerifiedEmail = defaults.fromEmail !== "" && displayedFromEmail === defaults.fromEmail ? defaults.fromEmailIsVerified : campaign.from.isVerifiedEmail;
      this.verifiedDomain = [""];
      this.emailNotVerified = false;
      this.DMARCPolicyNoDKIM = false;
      this.state = {
        model: campaign,
        fromName: campaign.from.fromName || defaults.fromName || "",
        fromEmail: displayedFromEmail,
        errors: props.errors || {},
        campaignId: campaign.campaignId,
        emailDomain: campaign.from.fromEmail ? campaign.from.fromEmail.split("@")[1] : "",
        domainAuthError: false
      };
      this.domainNotAuthenticated = false;
      this.sendingEmail = null;
      if (context.flagIsOn(flags.EEE3_NO_AUTH_NO_ENTRY_GLOBAL_MUNGING_MESSAGES)) {
        this.domainNotAuthenticated = !campaign.from.isAuthenticatedDomain;
        this.sendingEmail = campaign.from.sendingEmail;
        if (this.domainNotAuthenticated && this.state.fromEmail !== this.sendingEmail) {
          this.state.domainAuthError = "To meet industry authentication requirements, we’ll change your From address to " + campaign.from.sendingEmail + ". Want to use your original From address?";
        }
      }
      this.state.isValid = !!this.state.fromEmail && !!this.state.fromName && !this.domainNotAuthenticated;
    },
    postCreate: function () {
      verifiedDomainsApi.list().then(function (data) {
        this.verifiedDomain = data.map(function (item) {
          return item.domain;
        });
      }.bind(this));
    },
    getData: function () {
      return {
        from: {
          fromName: this.state.fromName,
          fromEmail: this.state.fromEmail
        }
      };
    },
    onSaveError: function (error) {
      if (error.errors.from_email.code === this.VERIFY_EMAIL_ERROR) {
        this.emailNotVerified = true;
      }
      if (error.errors.from_email.code === this.DMARC_POLICY_ERROR) {
        this.DMARCPolicyNoDKIM = true;
      }
      this.setState({
        errors: error.errors
      });
    },
    /**
     * Sets an error in the designated field in the From module
     * using the target ID and target name (manually assigned in
     * these instances as strings)
     *
     * @param {string} targetId in reference to the field
     * @param {string} targetName in reference to the field name
    */
    setFieldError: function (targetId, targetName) {
      var message = "Please enter a value";
      var errors = lang.clone(this.state.errors);
      if (message) {
        errors[targetId] = message ? {
          message: message
        } : null;
      } else {
        delete errors[targetId];
      }
      var nextState = {
        errors: errors
      };
      nextState[targetName] = "";
      nextState.isValid = Object.keys(errors).length === 0;
      this.setState(nextState);
    },
    /**
     * Performs a pre-check for the save button on the content in the fromName and fromEmail fields.
     * If the fromName or fromEmail fields are empty, it applies error message to the field and
     * returns false. Otherwise, returns true.
     *
     * @return {bool} true if fromName is blank, false if not
    */
    onBeforeSave: function () {
      this.resetErrorFlags();
      if (!this.state.fromName) {
        this.setFieldError("from_name", "fromName");
        return false;
      }
      if (!this.state.fromEmail) {
        this.setFieldError("from_email", "fromEmail");
        return false;
      }
      return true;
    },
    resetErrorFlags: function () {
      this.emailNotVerified = false;
      this.DMARCPolicyNoDKIM = false;
    },
    validate: function (field, value) {
      var error = "";
      if (field === "from_name" && value === "") {
        error = "Please enter a value";
      }
      if (field === "from_email") {
        var validate = emailValidation(value);
        if (value === "") {
          error = "Please enter a value";
        }
        if (validate) {
          error = validate.message;
        }
      }
      if (error) {
        return error;
      }
      return null;
    },
    isVerifiedDomain: function () {
      var emailAddress = this.state.fromEmail;
      var domain = emailAddress.split("@")[1];
      if (this.validEmail() && this.verifiedDomain.indexOf(domain) >= 0) {
        return true;
      }
      return false;
    },
    validEmail: function () {
      var email = this.state.fromEmail;
      var validate = emailValidation(email);
      if (email === "") {
        return false;
      }
      if (validate) {
        return false;
      }
      return true;
    },
    setValue: function (event) {
      var message = this.validate(event.target.id, event.target.value);
      var errors = lang.clone(this.state.errors);
      if (message) {
        errors[event.target.id] = message ? {
          message: message
        } : null;
      } else {
        delete errors[event.target.id];
      }
      var nextState = {
        errors: errors
      };
      nextState[event.target.name] = event.target.value;
      nextState.isValid = Object.keys(errors).length === 0;
      this.preserveFocus(event);
      this.setState(nextState);
    },
    isNANEFlagOn: function () {
      return context.flagIsOn(flags.EEE3_NO_AUTH_NO_ENTRY_GLOBAL_MUNGING_MESSAGES);
    },
    // These are the domains we provide in-app messaging to notify users of from addresses being munged
    isDMARC: function () {
      if (context.flagIsOn(flags.EEE3_NO_AUTH_NO_ENTRY_GLOBAL_MUNGING_MESSAGES) && this.sendingEmail !== null && this.sendingEmail !== this.state.fromEmail) {
        this.state.DMARCEmail = this.sendingEmail;
        return true;
      }
      var emailAddress = this.state.fromEmail;
      var warnDomains = ["@aol", "@yahoo", "@ymail", "@rocketmail", "@verizon.net", "@mail.ru", "@bk.ru", "@list.ru", "@inbox.ru", "@mac.com", "@btinternet.com", "@btopenworld.com", "@talk21.com", "@icloud.com", "@me.com", "@ntlworld.com", "@blueyonder.co.uk", "@ziggo.nl", "@home.nl", "@virginmedia.com", "@upcmail.nl", "@chello.nl", "@casema.nl", "@virgin.net", "@quicknet.nl", "@multiweb.nl", "@hahah.nl", "@zeggis.nl", "@telekabel.nl", "@zinders.nl", "@zeggis.com", "@razcall.com", "@cable.a2000.nl", "@razcall.nl", "@direct.a2000.nl", "@vodafone.nl", "@ziggo.com", "@vodafoneziggo.nl", "@telstra.com", "@telstra.com.au", "@bigpond.com", "@bigpond.com.au", "@bigpond.net", "@bigpond.net.au"];
      var username = emailAddress.split("@")[0];

      // Checks if input value matches an item in warnDomains array
      if (array.some(warnDomains, function (domain) {
        return emailAddress.indexOf(domain) >= 0;
      }) && username.length !== 0) {
        this.state.DMARCEmail = emailAddress.split("@").join(".") + "@send.mailchimpapp.com";
        return true;
      }
      return false;
    },
    showVerifyModal: function () {
      if (!this.VerifyDomain) {
        this.VerifyDomain = new verifyDomain({
          model: this.state
        });
      }
      this.VerifyDomain.openVerifyModal();
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.itemButtons = new ItemButtons({
          label: "From",
          getData: this.getData.bind(this),
          onBeforeSave: this.onBeforeSave.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          activate: this.activate,
          deactivate: this.deactivate,
          isActive: this.isActive,
          isValid: this.state.isValid,
          onSaveError: this.onSaveError.bind(this)
        });
        this.itemButtons.placeAt(this.fromItemButtonsContainer);
      }
    }
  });

  // Static methods used by the checklist item
  From.isComplete = function (campaign) {
    return campaign.from.fromName !== null && campaign.from.fromEmail !== null;
  };
  From.isValid = function (campaign) {
    return campaign.from.fromName !== null && utils.getSymbolLength(campaign.from.fromName.trim()) > 0 && campaign.from.fromEmail !== null && campaign.from.isVerifiedEmail;
  };
  return From;
});