/**
 * Favicon Editor
 * @module mojo/widgets/pages/FaviconEditor.js
 * @see mojo/widgets/checklist-editor/items/PageTitle.js
 *
 * The widget that's responsible for adding a favicon/site icon for Landing Pages. Uses Content Studio
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/ContentManagerPane", "mojo/lib/logger",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/favicon-editor.html", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags"], function (declare, ContentManagerPane, logger, Renderable, tplStr, I18nTranslation, context, flags) {
  var MAXIMUM_IMAGE_SIZE = 512;
  var MINIMUM_IMAGE_SIZE = 16;

  /**
   * Base widget responsible for loading all other widgets.
   */
  return declare([Renderable], {
    templateString: tplStr,
    chooseACustom: "Choose a custom site icon for your landing page",
    uploadAPng: "Upload a PNG, JPG, or ICO image. Files must be square images that are 512 x 512 pixels or smaller.",
    uploadButton: "Upload",
    replaceButton: "Replace",
    updateButton: "Update",
    image: "Image",
    makeSure: "Make sure your image meets the requirements",
    favicons: "Favicons must be square images between 16 x 16 to 512 x 512 pixels. Update then select this image to edit in Content Studio",
    constructor: function (props) {
      this.state = {
        currentFaviconUrl: props.faviconUrl,
        customFaviconImage: null,
        isFaviconImageInvalid: false,
        uploadButtonText: this.uploadButton
      };
      this.initialFaviconIcon = props.faviconUrl;
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.chooseACustom = I18nTranslation.translate("landing_pages_choose_a_custom");
        this.uploadAPng = I18nTranslation.translate("landing_pages_upload_a_png");
        this.uploadButton = I18nTranslation.translate("landing_pages_upload_button");
        this.replaceButton = I18nTranslation.translate("landing_pages_replace_button");
        this.updateButton = I18nTranslation.translate("landing_pages_update_button");
        this.makeSure = I18nTranslation.translate("landing_pages_make_sure");
        this.image = I18nTranslation.translate("landing_pages_image");
        this.favicons = I18nTranslation.translate("landing_pages_favicons");
      }
    },
    postCreate: function () {
      // Reconstruct the saved image to match the Content Studio image model - Similar to EditCustomFavicon.js
      if (this.initialFaviconIcon) {
        this.setState({
          customFaviconImage: {
            url: this.initialFaviconIcon,
            name: this.initialFaviconIcon.split("/").pop().toLowerCase(),
            fileExtension: this.initialFaviconIcon.split(".").pop().toLowerCase()
          },
          uploadButtonText: this.replaceButton
        });
      }
    },
    /**
     * Method to update the page title in the browser preview. Called from the parent PageTitle.js
     * widget using the state from the Page Title input field
     *
     * @param {string} title - Value pulled from the Page Title input field onChange
     */
    setPageTitle: function (title) {
      this.setState({
        pageTitle: title === "" ? "Page Title Will Go Here" : title
      });
    },
    /**
     * Checks the file extension, width and dimensions to make sure the image is a square and not
     * greater or less than the maximum and minimum size
     *
     * @param {obj} selectedImage - The selected inserted image from Content Studio and data assigned to it
     * @returns {boolean} - Whether or not the image is invalid
     */
    validateFaviconImage: function (selectedImage) {
      var width = selectedImage.width;
      var height = selectedImage.height;
      var fileExtension = selectedImage.fileExtension;
      return fileExtension !== "ico" && (width > MAXIMUM_IMAGE_SIZE || height > MAXIMUM_IMAGE_SIZE || width < MINIMUM_IMAGE_SIZE || height < MINIMUM_IMAGE_SIZE || width !== height);
    },
    /**
     * Opens the content studio when the Upload Button is clicked. Attached using
     * data-dojo-attach-event in the template
     *
     * @param {obj} selectedImage -
     */
    validateAndUpdatePreview: function (selectedImage) {
      var isImageInvalid = this.validateFaviconImage(selectedImage);
      var imageData = {
        dimensions: selectedImage.dimensions,
        name: selectedImage.name,
        fileExtension: selectedImage.fileExtension,
        width: selectedImage.width,
        height: selectedImage.height
      };
      this.setState({
        currentFaviconUrl: selectedImage.url,
        customFaviconImage: imageData,
        isFaviconImageInvalid: isImageInvalid,
        uploadButtonText: isImageInvalid ? this.updateButton : this.replaceButton
      });
    },
    /**
     * Opens the content studio when the Upload Button is clicked. Attached using
     * data-dojo-attach-event in the template
     *
     * @param { event } e - Pointer Event
     */
    onImageUploadPress: function (e) {
      ContentManagerPane.show({
        actions: ["select", "edit"],
        allowedFileTypes: ["png", "jpg", "jpeg", "ico"],
        onSelectContentItem: this.onImageSelection
      });

      // Track when user clicks on the upload button to load content studio
      logger.googleAnalytics.trackEvent("landing pages editor", "page title and site icon", "upload", {
        dimension58: "cta type",
        dimension59: "inline"
      });
    }
  });
});