define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, query, domConstruct, domAttr, array, _Block) {
  return declare([_Block], {
    type: "text",
    _name: "Text",
    _kbLink: "https://mailchimp.com/help/use-text-content-blocks/",
    _defaultText: "This is a text block. You can use it to add text to your template.",
    constructor: function () {
      this.content = [this._defaultText];
      this.numberOfColumns = 1;
      this.splitOption = [100];
      this.selectedColumn = 0;
      this.styles = [{
        "name": "Text style",
        "selector": ".mcnTextContent, .mcnTextContentLeft, .mcnTextContentRight, .mcnTextContent p, .mcnTextContentLeft p, .mcnTextContentRight p",
        "props": {
          "color": {
            "value": ""
          },
          "font-family": {
            "value": ""
          },
          "font-size": {
            "value": ""
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": ""
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": ""
          }
        }
      }];
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var content = this.getContent();
      var link = query('a', content)[index];
      if (link && domAttr.get(link, 'href') == oldUrl) {
        var containers = query('.mcnTextContentContainer', content);
        var parentColumn = query(link).parents('.mcnTextContentContainer')[0];
        var columnNum = array.indexOf(containers, parentColumn);

        // Couldn't find column w/ link, so error out.
        if (columnNum < 0) {
          domConstruct.destroy(content);
          return this.inherited(arguments);
        } else {
          // Grab the link in the context of the column
          var linkIndex = array.indexOf(query('a', parentColumn), link);
          var columnWrap = domConstruct.create('div');
          var columnDom = domConstruct.toDom(this.content[columnNum]);
          domConstruct.place(columnDom, columnWrap);

          // set the href on the link that we found.
          domAttr.set(query('a', columnWrap)[linkIndex], 'href', newUrl);
          this.content[columnNum] = query(columnWrap).html();
          domConstruct.destroy(columnWrap);
          domConstruct.destroy(content);
          return this.save();
        }
      } else {
        domConstruct.destroy(content);
        // Didn't find a suitable link to change, so error out.
        return this.inherited(arguments);
      }
    }
  });
});