define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo/store/Observable", "dojo/store/DataStore", "dojox/form/CheckedMultiSelect", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation"], function (declare, lang, array, on, dom, Memory, Observable, DataStore, CheckedMultiSelect, ready, context, flags, I18nTranslation) {
  return declare([CheckedMultiSelect], {
    dropDown: "true",
    multiple: "true",
    selectAll: "false",
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      // Add default label if not provided
      if (!this.labelText) {
        var select_days = "Select days";
        if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
          I18nTranslation.initialize().then(function (translations) {
            self.labelText = translations.reporting_comparative_report_select_days || select_days;
          });
        } else {
          self.labelText = select_days;
        }
      }
      var monday = "Monday";
      var tuesday = "Tuesday";
      var wednesday = "Wednesday";
      var thursday = "Thursday";
      var friday = "Friday";
      var saturday = "Saturday";
      var sunday = "Sunday";
      var monday_short = "Mon";
      var tuesday_short = "Tue";
      var wednesday_short = "Wed";
      var thursday_short = "Thu";
      var friday_short = "Fri";
      var saturday_short = "Sat";
      var sunday_short = "Sun";
      var data_var = [];
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
        I18nTranslation.initialize().then(function (translations) {
          data_var = [{
            label: translations.reporting_comparative_report_monday || monday,
            value: "monday",
            shortLabel: translations.reporting_comparative_report_mon || monday_short,
            dayOfWeek: "1"
          }, {
            label: translations.reporting_comparative_report_tuesday || tuesday,
            value: "tuesday",
            shortLabel: translations.reporting_comparative_report_tue || tuesday_short,
            dayOfWeek: "2"
          }, {
            label: translations.reporting_comparative_report_wednesday || wednesday,
            value: "wednesday",
            shortLabel: translations.reporting_comparative_report_wed || wednesday_short,
            dayOfWeek: "3"
          }, {
            label: translations.reporting_comparative_report_thursday || thursday,
            value: "thursday",
            shortLabel: translations.reporting_comparative_report_thu || thursday_short,
            dayOfWeek: "4"
          }, {
            label: translations.reporting_comparative_report_friday || friday,
            value: "friday",
            shortLabel: translations.reporting_comparative_report_fri || friday_short,
            dayOfWeek: "5"
          }, {
            label: translations.reporting_comparative_report_saturday || saturday,
            value: "saturday",
            shortLabel: translations.reporting_comparative_report_sat || saturday_short,
            dayOfWeek: "6"
          }, {
            label: translations.reporting_comparative_report_sunday || sunday,
            value: "sunday",
            shortLabel: translations.reporting_comparative_report_sun || sunday_short,
            dayOfWeek: "0"
          }];
        });
      } else {
        data_var = [{
          label: monday || monday,
          value: "monday",
          shortLabel: monday_short,
          dayOfWeek: "1"
        }, {
          label: tuesday || tuesday,
          value: "tuesday",
          shortLabel: tuesday_short,
          dayOfWeek: "2"
        }, {
          label: wednesday || wednesday,
          value: "wednesday",
          shortLabel: wednesday_short,
          dayOfWeek: "3"
        }, {
          label: thursday || thursday,
          value: "thursday",
          shortLabel: thursday_short,
          dayOfWeek: "4"
        }, {
          label: friday || friday,
          value: "friday",
          shortLabel: friday_short,
          dayOfWeek: "5"
        }, {
          label: saturday || saturday,
          value: "saturday",
          shortLabel: saturday_short,
          dayOfWeek: "6"
        }, {
          label: sunday || sunday,
          value: "sunday",
          shortLabel: sunday_short,
          dayOfWeek: "0"
        }];
      }
      var memoryStore = new Memory({
        idProperty: "value",
        data: data_var
      });
      var storeQuery = memoryStore.query();
      storeQuery.forEach(function (day) {
        // Select all options if selectAll attr set
        if (self.selectAll === "true") {
          day.selected = "true";
        }
        // Add days as options
        self.addOption(day);
      });

      // Attach on change event
      on(this, "change", function () {
        // Get Selected options
        var selectedOptions = array.filter(this.options, function (option) {
          return option.selected;
        });

        // Find out which days of week are selected and change label
        if (this.dropDown && this.dropDownButton && selectedOptions) {
          var isMonSelected = array.indexOf(this.get("value"), "monday") !== -1;
          var isTueSelected = array.indexOf(this.get("value"), "tuesday") !== -1;
          var isWedSelected = array.indexOf(this.get("value"), "wednesday") !== -1;
          var isThuSelected = array.indexOf(this.get("value"), "thursday") !== -1;
          var isFriSelected = array.indexOf(this.get("value"), "friday") !== -1;
          var isSatSelected = array.indexOf(this.get("value"), "saturday") !== -1;
          var isSunSelected = array.indexOf(this.get("value"), "sunday") !== -1;
          if (isMonSelected && isTueSelected && isWedSelected && isThuSelected && isFriSelected && isSatSelected && isSunSelected) {
            // var every_day = "START Every day";
            var every_day = "Every day";
            if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
              I18nTranslation.initialize().then(function (translations) {
                self.dropDownButton.set("label", translations.reporting_comparative_report_every_day || every_day);
              });
            } else {
              self.dropDownButton.set("label", every_day);
            }
          } else if (isMonSelected && isTueSelected && isWedSelected && isThuSelected && isFriSelected && !isSatSelected && !isSunSelected) {
            var weekdays = "Weekdays";
            if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
              I18nTranslation.initialize().then(function (translations) {
                self.dropDownButton.set("label", translations.reporting_comparative_report_weekdays || weekdays);
              });
            } else {
              self.dropDownButton.set("label", weekdays);
            }
          } else if (!isMonSelected && !isTueSelected && !isWedSelected && !isThuSelected && !isFriSelected && isSatSelected && isSunSelected) {
            var weekends = "Weekends";
            if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
              I18nTranslation.initialize().then(function (translations) {
                self.dropDownButton.set("label", translations.reporting_comparative_report_weekends || weekends);
              });
            } else {
              self.dropDownButton.set("label", weekends);
            }
          } else {
            var label = "";
            array.forEach(selectedOptions, function (option) {
              label += (label.length ? ", " : "") + option.shortLabel;
            });
            this.dropDownButton.set("label", label);
          }
        }

        // Default label text when no options selected
        if (!selectedOptions.length) {
          this.dropDownButton.set("label", this.labelText);
        }
      });

      // Open DropDown when clicked
      on(this, "click", function () {
        this.dropDownButton.openDropDown();
      });
    },
    startup: function () {
      this.inherited(arguments);
    }
  });
});