define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/user", "mojo/lib/flags", "mojo/widgets/FileManager", "mojo/widgets/content-manager/ContentManager", "mojo/neapolitan/mobile/utils"], function (declare, query, domClass, base, user, flags, FileManager, ContentManager, mobileUtils) {
  return declare([base], {
    onReady: function () {
      window.app = {};
      window.app.nativeEvents = mobileUtils.setupNativeListeners();
      window.app.nativeUtils = mobileUtils.setupNativeUtils();
      window.app.nativeStateHandler = mobileUtils.setupNativeStateHandler();
      var CM;
      CM = new ContentManager({
        actions: "copy, preview, edit, rename, delete, create",
        initialSource: 'autodesigner',
        isKioskMode: false,
        inPageDisplay: true
      });
      CM.startup();
      CM.placeAt(this.$el.container);
    }
  });
});