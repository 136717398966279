/**
 * Contact Profile Module
 * @module /mojo/views/members/contact-profile
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/user", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/router", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/widgets/contact-profile/Api", "mojo/widgets/contact-profile/ProfileOverview", "mojo/widgets/contact-profile/ContactFields", "mojo/widgets/contact-profile/FieldsList", "mojo/widgets/contact-profile/FieldsListItem", "mojo/widgets/contact-profile/AddNote", "mojo/widgets/contact-profile/ActivityFeed", "mojo/widgets/contact-profile/Tags", "mojo/appfabric/performance"], function (declare, base, user, flags, on, router, domClass, registry, api, ProfileOverview, ContactFields, FieldsList, FieldsListItem, AddNote, ActivityFeed, Tags, performance) {
  return declare([base], {
    memberID: null,
    listID: null,
    listName: null,
    openRate: null,
    clickRate: null,
    hasEcomm: null,
    orderStatsByCurrency: null,
    orderCount: null,
    formattedZeroAmount: null,
    selectedLanguage: null,
    media: null,
    gdprEnabled: false,
    isHardDeleted: false,
    isArchived: false,
    onReady: function () {
      this.initProfileOverviewWidget();
      this.initContactFields();
      this.setupMetaInfo();
      this.setupSocialInfo();
      this.initTags();
      this.initAddNoteWidget();
      this.initActivityFeedWidget();
      this.setupModalRouter();
      window.dataLayer.push({
        event: "e_pageLoad",
        destination: "list_detail",
        list_id: this.listId,
        login_id: this.loginId,
        user_id: this.userId
      });

      // SWMCAPPS Survey
      if (typeof window.usabilla_live === "function") {
        window.usabilla_live('trigger', 'swmcapps_1_1_survey_1');
      }
      performance.endMarkedMeasure();
    },
    /**
     * Create, place, and startup consolidated Profile Overview
     *
     * @param {string} fieldName name of field to be inserted into warning text
     *
     * @returns {string} warning text
     */
    getWarningText: function (fieldName) {
      return "You’re about to delete " + fieldName + " for this contact. This action can’t be undone and you’ll no longer have access to any " + fieldName + " information for this person on any of your lists.";
    },
    /**
     * Create, place, and startup consolidated Profile Overview
     */
    initProfileOverviewWidget: function () {
      var ProfileOverviewWidget = new ProfileOverview({
        "memberID": this.memberID,
        "contactID": this.contactID,
        "listID": this.listID,
        "listName": this.listName,
        "isArchived": this.isArchived,
        "isHardDeleted": this.isHardDeleted
      });
      var self = this;
      on(ProfileOverviewWidget.clearDemographicsButton, "click", function () {
        registry.byId("edit-predicted-demographics-dialog").show();
      });
      on(this.$el.predictedDemographicsOverride, "click", function () {
        self.$el.overridePredictedFieldWarningText.innerText = self.getWarningText("predicted demographics");
        registry.byId("edit-predicted-demographics-dialog").hide().then(function () {
          registry.byId("override-predicted-fields-dialog").show();
          on(self.$el.confirmOverrideButton, "click", function () {
            self.overridePredictedDemographics(ProfileOverviewWidget);
          });
        });
      });
      ProfileOverviewWidget.placeAt(this.$el.profileOverviewContainer);
      ProfileOverviewWidget.startup();
    },
    /**
     * Create, place, and startup contact fields widget
     */
    initContactFields: function () {
      var ContactFieldsWidgets = this.getInfoWidgets();
      ContactFieldsWidgets.placeAt(this.$el.contactFieldsContainer);
      ContactFieldsWidgets.startup();
    },
    /**
     *  Create widget that has all contact fields
     *  @returns {object} ContactFieldsWidgets Container widget for all contact fields
     */
    getInfoWidgets: function () {
      var ContactFieldsWidgets = new ContactFields({
        "memberID": this.memberID,
        "listID": this.listID,
        "openRate": this.openRate,
        "clickRate": this.clickRate,
        "hasEcomm": this.hasEcomm,
        "orderStatsByCurrency": this.orderStatsByCurrency,
        "orderCount": this.orderCount,
        "formattedZeroAmount": this.formattedZeroAmount,
        "gdprEnabled": this.gdprEnabled,
        "isArchived": this.isArchived
      });
      return ContactFieldsWidgets;
    },
    /**
     * Initialize tags widget
     */
    initTags: function () {
      var TagsWidget = new Tags({
        "memberID": this.memberID,
        "listID": this.listID,
        "isArchived": this.isArchived
      });
      TagsWidget.placeAt(this.$el.tagsContainer);
      TagsWidget.startup();
    },
    /**
     *  Create, place, and startup Add Note widget
     */
    initAddNoteWidget: function () {
      var AddNoteWidget = new AddNote({
        "memberID": this.memberID,
        "isArchived": this.isArchived
      });
      AddNoteWidget.placeAt(this.$el.addNoteContainer);
      AddNoteWidget.startup();
    },
    /**
     *  Create, place, and startup Activity Feed
     */
    initActivityFeedWidget: function () {
      var ActivityFeedWidget = new ActivityFeed({
        "memberID": this.memberID,
        "isArchived": this.isArchived
      });
      ActivityFeedWidget.placeAt(this.$el.activityFeedContainer);
      ActivityFeedWidget.startup();
    },
    /**
     *  Set up meta info (language and email format) event handlers
     */
    setupMetaInfo: function () {
      var self = this;
      on(this.$el.languageSelect, "change", function (e) {
        self.selectedLanguage = e.target.value;
      });
      on(this.$el.submitMetaDialog, "click", function () {
        self.submitMetaInfo();
      });
      if (this.$el.emailClientOverride) {
        on(this.$el.emailClientOverride, "click", function () {
          self.$el.overridePredictedFieldWarningText.innerText = self.getWarningText("favorite email client");
          var closePromise = registry.byId("edit-meta-dialog").hide();
          self.confirmOverride(self.overrideEmailClient, closePromise);
        });
      }
      if (this.$el.locationOverride) {
        on(this.$el.locationOverride, "click", function () {
          self.$el.overridePredictedFieldWarningText.innerText = self.getWarningText("predicted location");
          var closePromise = registry.byId("edit-meta-dialog").hide();
          self.confirmOverride(self.overrideLocation, closePromise);
        });
      }
    },
    /**
     *  Set up social profile event handlers
     */
    setupSocialInfo: function () {
      var self = this;
      if (this.$el.socialOverride) {
        on(this.$el.socialOverride, "click", function () {
          self.$el.overridePredictedFieldWarningText.innerText = self.getWarningText("social profile");
          var closePromise = registry.byId("edit-social-dialog").hide();
          self.confirmOverride(self.overrideSocialProfile, closePromise);
        });
      }
    },
    /**
     *  Api requests to save meta info (language and email format)
     */
    submitMetaInfo: function () {
      var selectedEmailFormat = document.querySelector('#format-radio input[type="radio"]:checked').value;
      api.submitMetaInfo(this.memberID, this.selectedLanguage, selectedEmailFormat);
    },
    /**
     *  Api requests to override a the contact's locations
     *  @param {string} memberID list_member ID to override
     *  @param {function} callback Callback function
     */
    overrideLocation: function (memberID, callback) {
      api.overrideLocation(memberID, callback);
    },
    /**
     *  Api requests to override a the contact's predicted favorite email client
     *  @param {string} memberID list_member ID to override
     *  @param {function} callback Callback function
     */
    overrideEmailClient: function (memberID, callback) {
      api.overrideEmailClient(memberID, callback);
    },
    /**
     * API call to override the contact's predicted demographics fields
     * @param {ProfileOverview} profileOverviewWidget widget to modify profile overview
     */
    overridePredictedDemographics: function (profileOverviewWidget) {
      api.overridePredictedDemographics(this.memberID, function () {
        profileOverviewWidget.getProfileOverview();
        registry.byId("override-predicted-fields-dialog").hide();
      });
    },
    /**
     * API call to override the contact's social profile fields
     * @param {string} memberID list_member ID to override
     * @param {function} callback Callback function
     */
    overrideSocialProfile: function (memberID, callback) {
      api.overrideSocialProfile(memberID, callback);
    },
    /**
     * Prompts user with dialogue to confirm predicted field override
     * @param {function} callback API override function to run on confirmation (takes memberId and callback)
     * @param {Promise} closePromise promise for closing the earlier dialogue
     */
    confirmOverride: function (callback, closePromise) {
      if (closePromise) {
        closePromise.then(function () {
          registry.byId("override-predicted-fields-dialog").show();
          var self = this;
          on(this.$el.confirmOverrideButton, "click", function () {
            callback(self.memberID, function () {
              registry.byId("override-predicted-fields-dialog").hide();
              window.location.reload(false);
            });
          });
        }.bind(this));
      }
    },
    /**
     *  Sets up temporary router to handle persistence of edit modals on reload
     */
    setupModalRouter: function () {
      var self = this;
      router.register(":id", function (evt) {
        self.interpretHash(evt.params.id);
      });
      router.startup();

      //This handles the case of the editPostAction() which changes the URL on validation failure
      if (window.location.href.indexOf("edit-post") > -1) {
        this.interpretHash("edit-profile-dialog");
      }
    },
    /**
     * Interpret the modalID from the URL hash and show the correct modal
     * @param {string} modalID DOM id of the modal to show
     */
    interpretHash: function (modalID) {
      if (modalID) {
        registry.byId(modalID).show();
      }
    }
  });
});