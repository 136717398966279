define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, on) {
  return declare([base], {
    onReady: function () {
      if (this.$el.tryTxDemo) {
        /* category: mandrill sign up, action: cta, label: button CDs:, 53: try our demo, 54:<click URL>, 56: navigate, CMs: none, Notes: none */
        on(this.$el.tryMandrillDemo, "click", function () {
          window.ga("send", "event", "mandrill sign up", "cta", "button", {
            'dimension53': "try our demo",
            'dimension54': "mailchimp.com/transactional",
            'dimension56': "navigate"
          });
        });
      }
      if (this.$el.accountBlocks) {
        /* category: mandrill sign up, action: cta, label: button CDs:, 53: pick a plan, 54:<click URL>, 56: navigate, CMs: none, Notes: none */
        on(this.$el.accountBlocks, "click", function () {
          window.ga("send", "event", "mandrill sign up", "cta", "button", {
            'dimension53': "pick a plan",
            'dimension54': "mailchimp.com/account/blocks",
            'dimension56': "navigate"
          });
        });
      }
    }
  });
});