define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags", "dojo/text!./templates/favicon-preview.html"], function (declare, domClass, Renderable, I18nTranslation, context, flags, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    DEFAULT_FAVICON: "https://s3.amazonaws.com/cdn-images.mailchimp.com/websites/favicons/default/android-chrome-512x512.png",
    custom: "Custom",
    defaultText: "Default",
    constructor: function (props) {
      this.state = {
        faviconUrl: props.faviconUrl
      };
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.custom = I18nTranslation.translate("landing_pages_custom");
        this.defaultText = I18nTranslation.translate("landing_pages_default_text");
      }
    }
  });
});