define(["./_WidgetBase", "./_TemplatedMixin", "./_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // array.forEach
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.extend lang.isArray
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module" // kernel.deprecated
], function (_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, array, declare, lang, kernel) {
  // module:
  //		dijit/_Templated

  // These arguments can be specified for widgets which are used in templates.
  // Since any widget can be specified as sub widgets in template, mix it
  // into the base widget class.  (This is a hack, but it's effective.)
  // Remove for 2.0.   Also, hide from API doc parser.
  lang.extend(_WidgetBase, /*===== {} || =====*/{
    waiRole: "",
    waiState: ""
  });
  return declare("dijit._Templated", [_TemplatedMixin, _WidgetsInTemplateMixin], {
    // summary:
    //		Deprecated mixin for widgets that are instantiated from a template.
    //		Widgets should use _TemplatedMixin plus if necessary _WidgetsInTemplateMixin instead.

    // widgetsInTemplate: [protected] Boolean
    //		Should we parse the template to find widgets that might be
    //		declared in markup inside it?  False by default.
    widgetsInTemplate: false,
    constructor: function () {
      kernel.deprecated(this.declaredClass + ": dijit._Templated deprecated, use dijit._TemplatedMixin and if necessary dijit._WidgetsInTemplateMixin", "", "2.0");
    },
    _processNode: function (baseNode, getAttrFunc) {
      var ret = this.inherited(arguments);

      // Do deprecated waiRole and waiState
      var role = getAttrFunc(baseNode, "waiRole");
      if (role) {
        baseNode.setAttribute("role", role);
      }
      var values = getAttrFunc(baseNode, "waiState");
      if (values) {
        array.forEach(values.split(/\s*,\s*/), function (stateValue) {
          if (stateValue.indexOf('-') != -1) {
            var pair = stateValue.split('-');
            baseNode.setAttribute("aria-" + pair[0], pair[1]);
          }
        });
      }
      return ret;
    }
  });
});