/**
 * Upgrade Action Block
 * @module mojo/widgets/UpgradeActionBlock.js
 *
 * See http://ux.mailchimp.com/product/patterns/feedback#upgrade-block for additional documentation
 *
 * This component uses the Renderable mixin as a quick mechanism to access Lodash's templating engine instead of access.
 * to the traditional stateful features. This is intentional and an effort to separate template display
 * logic from the functional logic found in UpgradeActionBlock.js. This also allows for the explicit escaping of
 * potential HTML.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/utils/array", "mojo/widgets/account/paywall/PaywallUpgradeButton", "mojo/widgets/PlanSelectorCTA",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/UpgradeActionBlock.html", "velocity/velocity", "velocity/velocity.ui"], function (declare, topic, context, arrayUtils, PaywallUpgradeButton, PlanSelectorCTA, Renderable, tplStr, Velocity) {
  return declare([Renderable], {
    templateString: tplStr,
    // An image is required
    img: {
      path: "https://cdn-images.mailchimp.com/product/brand_assets/illos/art-other-first-purchase.png",
      alt: "Illustration of caped woman leaping over mountain"
    },
    // Keep under 270 characters.
    body: {
      title: "Upgrade your account to unlock new features",
      copy: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    // A CTA is required
    cta: {
      innerText: "Upgrade Now",
      // Highly recommended default
      className: "p0" // Button priority. See http://ux.mailchimp.com/patterns/buttons
    },
    isDismissable: false,
    dismissConfig: {
      onDismissPressCallback: null,
      innerText: "Dismiss"
    },
    disableRefreshForEditorUpgrade: false,
    // Override with String if you'd like to use topic/subscribe to listen for button press.
    topicName: null,
    // Callback you should override if you are not using topic/subscribe (Preferred)
    onCtaPressCallback: null,
    // Optional ability to add a variant classname. Currently only c-upgradeActionBlock--small is used.
    variantClass: null,
    gaTrackingCategory: null,
    gaTrackingAction: null,
    gaTrackingLabelProps: null,
    // Optional callback to execute after completing checkout
    onComplete: undefined,
    useOldPlanSelector: false,
    // TODO: this can be removed when all suggester vs. selector experiments are completed:
    experiment: undefined,
    /**
     * Part of Dojo's lifecyle. Call out to a method that will make sure we're passing in appropriate props.
     */
    postMixInProperties: function () {
      this._validateProps();
    },
    postCreate: function () {
      if (this.martaID && !useOldPlanSelector) {
        this.button = new PlanSelectorCTA({
          martaID: this.martaID,
          redirectUrl: this.redirect ? this.redirect : "",
          featureName: this.featureName,
          onUpgradeComplete: this.onComplete,
          buttonText: this.cta.innerText,
          containerClass: "button p0"
        });
        this.button.placeAt(this.upgradeButtonContainer);
        this.button.startup();
      } else {
        this.button = new PaywallUpgradeButton({
          martaID: this.martaID,
          buttonText: this.cta.innerText,
          disableRefreshForEditorUpgrade: this.disableRefreshForEditorUpgrade,
          gaTracking: {
            action: this.gaTrackingAction,
            category: this.gaTrackingCategory,
            label: this.gaTrackingLabelProps
          },
          fixedGATrackingCallback: this.fixedGATrackingCallback,
          redirect: this.redirect,
          featureName: this.featureName,
          featureContext: this.featureContext,
          onComplete: this.onComplete,
          experiment: this.experiment
        });
        this.button.placeAt(this.upgradeButtonContainer);
        this.button.startup();
      }
    },
    /**
     * Public method to toggle disabled state of the CTA
     */
    toggleCtaLoadingState: function () {
      if (this._isButtonDisabled()) {
        this.node_button.classList.remove("button-loading");
        this.node_button.removeAttribute("disabled");
      } else {
        this.node_button.classList.add("button-loading");
        this.node_button.setAttribute("disabled", "disabled");
      }
    },
    /**
     * Do not override this method.
     * This responds to the button press and passes the details on to the appropriate callback.
     *
     * @private
     */
    _onButtonPress: function () {
      // Set loading indication
      this.toggleCtaLoadingState();

      // Add GA tracking for button press
      if (this.gaTrackingCategory && this.gaTrackingAction && this.gaTrackingLabelProps) {
        window.ga("send", "event", this.gaTrackingCategory, this.gaTrackingAction, JSON.stringify(this.gaTrackingLabelProps));
      }

      // Notify listener if it's a callback
      if (this.onCtaPressCallback && typeof this.onCtaPressCallback === "function") {
        this.onCtaPressCallback();
      }

      // Notify listener if it's using dojo/topic
      if (this.topicName) {
        topic.publish(this.topicName, {
          pressed: true
        });
      }
    },
    /**
     * Do not override this method.
     * This responds to the dismiss button press and passes the details on to the appropriate callback.
     *
     * @param {Event} e - Mouse Event on click
     * @private
     */
    _onDismissPress: function (e) {
      e.preventDefault();
      this._animateAndHide();
    },
    /**
     * Do not override this method.
     * Fade out the action block. On complete of animation fire the callback if one is defined.
     *
     * @private
     */
    _animateAndHide: function () {
      Velocity(this.domNode, "fadeOut", {
        "easing": "easeInOut",
        "duration": 200,
        "complete": function () {
          if (this.dismissConfig.onDismissPressCallback) {
            this.dismissConfig.onDismissPressCallback();
          }
        }.bind(this)
      });
    },
    /**
     * Do a quick check on the status of the button.
     *
     * @returns {boolean} Is the button currently "disabled"
     * @private
     */
    _isButtonDisabled: function () {
      return this.node_button.classList.contains("button-loading") && this.node_button.getAttribute("disabled") === "disabled";
    },
    /**
     * Do some simple checks on data being passed into the widget.
     * Don't you wish Dojo had PropTypes?
     *
     * @private
     */
    _validateProps: function () {
      this._validateImgProps();
      this._validateBodyProps();
      this._validateCtaProps();
      this._validateCallback();
      this._validateDismiss();
    },
    /**
     * Verify we're being passed in the correct data for images.
     * @private
     */
    _validateImgProps: function () {
      if (!this.img) {
        throw new Error("this.img is invalid. UpgradeActionBlock requires an img Object.");
      }

      // Validate necessary object keys are configured.
      if ("path" in this.img === false) {
        throw new Error("this.img is invalid. The UpgradeActionBlock img Object requires a path key to be set.");
      }
      if ("alt" in this.img === false) {
        throw new Error("this.img is invalid. The UpgradeActionBlock img Object requires an alt key to be set.");
      }

      // Validate data types.
      if (typeof this.img.path !== "string") {
        throw new Error("this.img.path is invalid. UpgradeActionBlock was expecting a String.");
      }
      if (typeof this.img.alt !== "string") {
        throw new Error("this.img.alt is invalid. UpgradeActionBlock was expecting a String.");
      }

      // Validate string length
      if (this.img.path.length < 1) {
        throw new Error("this.img.path is invalid. UpgradeActionBlock was expecting a relative or fully qualified URL");
      }
      if (this.img.alt.length < 1) {
        throw new Error("this.img.alt is invalid. UpgradeActionBlock was expecting a descriptive alt text for the image.");
      }
    },
    /**
     * Verify we're being passed in correct data for the body.
     *
     * @private
     */
    _validateBodyProps: function () {
      if (!this.body) {
        throw new Error("this.body is invalid. UpgradeActionBlock requires a body with the type of a string.");
      }

      // Validate necessary object keys are configured.
      if ("title" in this.body === false) {
        throw new Error("this.body is invalid. The UpgradeActionBlock body Object requires a title key to be set.");
      }
      if ("copy" in this.body === false) {
        throw new Error("this.body is invalid. The UpgradeActionBlock body Object requires an copy key to be set.");
      }

      // Validate data types.
      if (typeof this.body.title !== "string") {
        throw new Error("this.body.title is invalid. UpgradeActionBlock was expecting a String.");
      }
      if (typeof this.body.copy !== "string") {
        throw new Error("this.body.copy is invalid. UpgradeActionBlock was expecting a String.");
      }

      // Validate string length
      if (this.body.copy.length > 270 && context.avestaEnvironment && context.avestaEnvironment === "dev") {
        console.warn("Warning: UpgradeActionBlock this.body.copy exceeds recommended string length of 270 characters");
      }
    },
    /**
     * Verify we're being passed in correct data for the CTA.
     *
     * @private
     */
    _validateCtaProps: function () {
      // These are the only button types we want to use in this pattern.
      var validClassNames = ["",
      // Default parsnip button style
      "p0",
      // Peppercorn button style
      "p1" // Kale button style
      ];
      if (!this.cta) {
        throw new Error("this.cta is invalid. UpgradeActionBlock requires an cta Object.");
      }

      // Validate necessary object keys are configured.
      if ("innerText" in this.cta === false) {
        throw new Error("this.cta is invalid. The UpgradeActionBlock cta Object requires a innerText key to be set.");
      }
      if ("className" in this.cta === false) {
        throw new Error("this.cta is invalid. The UpgradeActionBlock cta Object requires an className key to be set.");
      }

      // Validate data types.
      if (typeof this.cta.innerText !== "string") {
        throw new Error("this.cta.innerText is invalid. UpgradeActionBlock was expecting a String.");
      }
      if (typeof this.cta.className !== "string") {
        throw new Error("this.cta.className is invalid. UpgradeActionBlock was expecting a String.");
      }

      // Validate string length
      if (this.cta.innerText.length < 1) {
        throw new Error("this.cta.innerText is invalid. UpgradeActionBlock was expecting descriptive text");
      }

      // Validate contents of this.cta.className
      if (validClassNames.indexOf(this.cta.className) === -1) {
        var readableClassnames = arrayUtils.toEnglishEnumeration(validClassNames.map(function (i) {
          return i === "" ? "An empty string" : i;
        }));
        throw new Error("this.cta.className is invalid. UpgradeActionBlock was expecting one of the following: " + readableClassnames + ".");
      }
    },
    /**
     * Callbacks for this component are tricky. Give some helpful error messages
     *
     * @private
     */
    _validateCallback: function () {
      if (!this.topicName && !this.onCtaPressCallback) {
        throw new Error("No callback interface is configured. UpgradeActionBlock expects either a topic OR callback");
      }
      if (this.topicName && this.onCtaPressCallback) {
        throw new Error("Duplicate callback interfaces are configured. UpgradeActionBlock expects either a topic OR callback");
      }
      if (this.topicName) {
        if (typeof this.topicName !== "string" || this.topicName.length < 1) {
          throw new Error("this.topicName is invalid. UpgradeActionBlock was expecting a valid String.");
        }
      }
    },
    /**
     * Verify we're being passed a boolean in order to show / hide the dismiss CTA
     *
     * @private
     */
    _validateDismiss: function () {
      if (typeof this.isDismissable !== "boolean") {
        throw new Error("this.isDismissable is invalid. UpgradeActionBlock was expecting a Boolean.");
      }
    }
  });
});