define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domAttr.set
"dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // keys.ESCAPE
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_FormWidgetMixin"], function (declare, domAttr, keys, lang, on, _FormWidgetMixin) {
  // module:
  //		dijit/form/_FormValueMixin

  return declare("dijit.form._FormValueMixin", _FormWidgetMixin, {
    // summary:
    //		Mixin for widgets corresponding to native HTML elements such as `<input>` or `<select>`
    //		that have user changeable values.
    // description:
    //		Each _FormValueMixin represents a single input value, and has a (possibly hidden) `<input>` element,
    //		to which it serializes it's input value, so that form submission (either normal submission or via FormBind?)
    //		works as expected.

    // readOnly: Boolean
    //		Should this widget respond to user input?
    //		In markup, this is specified as "readOnly".
    //		Similar to disabled except readOnly form values are submitted.
    readOnly: false,
    _setReadOnlyAttr: function ( /*Boolean*/value) {
      domAttr.set(this.focusNode, 'readOnly', value);
      this._set("readOnly", value);
    },
    postCreate: function () {
      this.inherited(arguments);

      // Update our reset value if it hasn't yet been set (because this.set()
      // is only called when there *is* a value)
      if (this._resetValue === undefined) {
        this._lastValueReported = this._resetValue = this.value;
      }
    },
    _setValueAttr: function ( /*anything*/newValue, /*Boolean?*/priorityChange) {
      // summary:
      //		Hook so set('value', value) works.
      // description:
      //		Sets the value of the widget.
      //		If the value has changed, then fire onChange event, unless priorityChange
      //		is specified as null (or false?)
      this._handleOnChange(newValue, priorityChange);
    },
    _handleOnChange: function ( /*anything*/newValue, /*Boolean?*/priorityChange) {
      // summary:
      //		Called when the value of the widget has changed.  Saves the new value in this.value,
      //		and calls onChange() if appropriate.   See _FormWidget._handleOnChange() for details.
      this._set("value", newValue);
      this.inherited(arguments);
    },
    undo: function () {
      // summary:
      //		Restore the value to the last value passed to onChange
      this._setValueAttr(this._lastValueReported, false);
    },
    reset: function () {
      // summary:
      //		Reset the widget's value to what it was at initialization time
      this._hasBeenBlurred = false;
      this._setValueAttr(this._resetValue, true);
    }
  });
});