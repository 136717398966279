define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "dojo/text!../editors/blocks/templates/ImageEditor/output.html"], function (declare, _Renderer, lang, user, flags, tpl) {
  return declare("mojo.neapolitan.renderers.ImageBlock", [_Renderer], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      var model = {
        innerPadding: this.innerPadding,
        outerPadding: this.outerPadding
      };
      if (meta.edgeToEdge) {
        model.innerPadding = 0;
        model.outerPadding = 0;
      }
      meta.containerWidth = meta.containerWidth - model.innerPadding * 2 - model.outerPadding * 2;
      if (meta.image) {
        var imageData = lang.mixin(model, meta.image);
        var imageClass = "";
        var width;

        // Only limit the calculated width of an image if it's wider than it's container
        // and apply the mcnRetinaImage or mcnImage class
        if (meta.retina) {
          width = imageData.width / 2;
          imageClass = "mcnRetinaImage";
        } else {
          width = imageData.width;
          imageClass = "mcnImage";
        }
        width = width < meta.containerWidth ? width : meta.containerWidth;
        if (meta.customSize) {
          var customSize = parseInt(meta.customSize, 10);
          width = width * (customSize / 100);
        }
        imageData.calculatedWidth = width;
        imageData.align = meta.align;
        imageData.imageClass = imageClass;
        return imageData;
      }
      return lang.mixin(model, {
        src: null,
        calculatedWidth: meta.containerWidth
      });
    }
  });
});