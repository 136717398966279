define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Preview Toggle Widget
"./view-content/PreviewSwitcher",
// Preview Widgets
"./view-content/FacebookPreviewDesktop", "./view-content/FacebookPreviewMobile", "./view-content/InstagramPreview",
// Templates
"dojo/text!./view-content/templates/ViewContentSlat.html"], function (declare, lang, domClass, domStyle, domConstruct, domAttr, query, on, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, togglePreview, FbPreviewDesktop, FbPreviewMobile, InstagramPreview, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    /**
     * Setup the data for the preview widget.
     *
     * @param {obj} data - an object with keys for ad_id, ad content, and page info .
     */
    setData: function (data) {
      // Assign vars
      this.data = data;
      this.content = data.content;
      this.page = data.page;
      this.hasContent = data.hasContent;
      this.hasAttachments = data.hasAttachments;
      this.numberOfAttachments = data.numberOfAttachments;
      this.message = data.message;
      this.seeMoreLink = data.seeMoreLink;
      this._setPageDefaults();
      this._initPreviews();
      this._initPreviewToggle();
      this._toggleLoadingIndicator();
    },
    /**
     * Pass the attachment(s) data onto the different Preview widgets and create them
     */
    _initPreviews: function () {
      // Boolean variables to check if placement is enabled or set up
      this.hasAdPlacements = this.data.hasAdPlacements;
      this.hasFbPlacements = this.data.hasFacebookPlacements;
      this.hasIgPlacements = this.data.hasInstagramPlacements;
      var showFacebook = this.hasFbPlacements || !this.hasAdPlacements;
      var showInstagram = this.hasIgPlacements || !this.hasAdPlacements;
      var previewData = this._getPreviewData();

      // Instantiate the preview widgets
      this.instagramPreview = new InstagramPreview(previewData);
      this.fbDesktopPreview = new FbPreviewDesktop(previewData);
      this.fbMobilePreview = new FbPreviewMobile(previewData);
      if (showFacebook) {
        this.fbMobilePreview.placeAt(this.fbPreviewContainer, "first");
        this.fbMobilePreview.startup();
        this.fbDesktopPreview.placeAt(this.fbPreviewContainer);
        this.fbDesktopPreview.startup();
      } else {
        domConstruct.destroy(this.fbPreviewContainer);
      }
      if (showInstagram) {
        this.instagramPreview.placeAt(this.igPreviewContainer);
      } else {
        domConstruct.destroy(this.igPreviewContainer);
      }
    },
    _initPreviewToggle: function () {
      this.previewToggle = new togglePreview({
        templateString: "<div><div data-dojo-attach-point=\"buttons\" class=\"flex justify-content--center\"></div><div data-dojo-attach-point=\"childButtons\" class=\"flex justify-content--center hide-phone\"></div></div>"
      });
      this.previewToggle.placeAt(this.previewContainer, "first");
      this.previewToggle.startup();
    },
    /**
     * Show loading indicator while previews are being created then hide when completed
     *
     * @private
     */
    _toggleLoadingIndicator: function () {
      domClass.toggle(this.loadingIndicator, "hide");
      domClass.toggle(this.previewContainer, "hide");
    },
    /**
     * Take the content data, format links and buttons and also provide
     * defaults for null values. Pushes to new array to avoid conflicts
     *
     * @returns {array} - Copy of content data for preview
     */
    _getFormattedContentData: function () {
      var self = this;
      var formattedData = [];
      this.content.forEach(function (content, index) {
        // Create a copy of the content data
        var data = lang.mixin({}, {
          "adIndex": index,
          "imageUrl": content.imageUrl,
          "linkUrl": content.linkUrl ? content.linkUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0] : "Page Link Goes Here",
          "title": content.name ? content.name : "Title Goes Here",
          "description": content.description ? content.description : "",
          "callToActionButton": content.callToAction ? self._handleCTABookTravel(content.callToAction) : ""
        });
        formattedData.push(data);
      });
      return formattedData;
    },
    /**
     * Method to get the data needed to generate and populate the previews
     *
     * @returns {object} - Preview data used for the previews
     */
    _getPreviewData: function () {
      return lang.mixin({}, {
        hasContent: this.hasContent,
        hasAttachments: this.hasAttachments,
        numberOfAttachments: this.numberOfAttachments,
        page: this.page,
        seeMoreLink: this.seeMoreLink,
        message: this.message ? this.message : "",
        content: this._getFormattedContentData() // Replace content with modified content
      });
    },
    /**
     * Checks to see if call to action value is "BOOK_TRAVEL" if so change
     * it to "Book Now" else keep the same and convert to lowercase and remove underscore.
     *
     * @param {string} cta - Call to action string coming from Controller
     * @returns {string} - Button value string
     */
    _handleCTABookTravel: function (cta) {
      return cta === "BOOK_TRAVEL" ? "Book Now" : cta.toLowerCase().replace("_", " ");
    },
    /**
     * Checks to see if a user has a connected account. If they don't we use a default
     * name and avatar for their preview
     *
     */
    _setPageDefaults: function () {
      var pageName = "Your Page Name Goes Here";
      var pageAvatar = "https://cdn-images.mailchimp.com/product/ads/default-profile-avatar.jpg";
      if (!this.page) {
        this.page = {
          "name": pageName,
          "iconUrl": pageAvatar,
          "instagramIconUrl": pageAvatar,
          "instagramHandle": pageName.toLowerCase().split(" ").join("_")
        };
      }
    }
  });
});