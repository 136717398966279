define(["dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // array.forEach
"dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // keys.ENTER keys.SPACE
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// has("dom-addeventlistener")
"./a11yclick"], function (on, array, keys, declare, has, a11yclick) {
  // module:
  //		dijit/_OnDijitClickMixin

  var ret = declare("dijit._OnDijitClickMixin", null, {
    // summary:
    //		Deprecated.   New code should access the dijit/a11yclick event directly, ex:
    //		|	this.own(on(node, a11yclick, function(){ ... }));
    //
    //		Mixing in this class will make _WidgetBase.connect(node, "ondijitclick", ...) work.
    //		It also used to be necessary to make templates with ondijitclick work, but now you can just require
    //		dijit/a11yclick.

    connect: function (obj, event, method) {
      // override _WidgetBase.connect() to make this.connect(node, "ondijitclick", ...) work
      return this.inherited(arguments, [obj, event == "ondijitclick" ? a11yclick : event, method]);
    }
  });
  ret.a11yclick = a11yclick; // back compat

  return ret;
});