/**
 * A widget that represents a product-oriented filterable select input for our segmentation UI. For users with a lot
 * of ecommerce products, we'll reach out to the backend
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo/store/util/QueryResults", "mojo/segmentation/api",
// Mixins
"dijit/form/FilteringSelect", "./_SegmentInputMixin", "dojox/html/entities", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, lang, Deferred, Memory, QueryResults, api, _FilteringSelect, _SegmentInputMixin, htmlEntities, flags, I18nTranslation, context) {
  // The default maximum number of products we'll include as part of the page load.
  // This should stay in-sync with MC_SegmentEditor_Fields_Ecommerce::MAX_OPTIONS
  var MAX_OPTIONS = 1000;

  /**
   * Restructure a content-dense array of product objects into a simple collection based on the product name.
   * Products come unsorted. Example response:
   *
   * [{
   *      product_id: 6718049,
   *      title: "Smile Unglazed Low Pot",
   *      price: "$9.95",
   *      image_url: "https://cdn11.bigcommerce.com/s-bxfcn2owdj/products/93/images/384/pexels-photo-993626__22528.1556492332.386.513.jpg?c=2",
   *      sku: "SLLPJ",
   *      description: 'When translated Le Parfait means "the perfect one" - and that\'s just what this air-tight jar is. Designed for canning, these jars will ensure your harvest does not spoil, but is kept well-preserved for those cold winter months that lie ahead. Also can be used to store grains, beans and spices. Lid easily removes for a thorough cleaning. May be frozen - just be sure to leave enough room for expansion.\n\n1 L/34 fl oz',
   *      product_url: "https://pottedplanter.mybigcommerce.com/smile-unglazed-low-pot",
   *      add_to_cart_url: "https://pottedplanter.mybigcommerce.com/smile-unglazed-low-pot"
   *  }]
   *
   * @param {Object[]} products Collection of product objects. We only currently use the product.title.
   * @returns {Object[]} Sorted and formatted products
   */
  var formatProducts = function (products) {
    return products
    // Structure the data to what the combo box expects
    .map(function (p) {
      return {
        // Encode the potentially unsanitized data
        label: htmlEntities.encode(p.title),
        value: p.title,
        group: null
      };
    })
    // Alphabatize it
    .sort(function (a, b) {
      var labelA = a.label.toUpperCase();
      var labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };

  /**
   * Extend the functionality of the Memory module to query the backend when appropriate
   */
  var ProductsStore = declare([Memory], {
    // Threaded through input configs. Required for network requests.
    listId: null,
    /**
     * Override default querying behavior and progressively enhance results with values from the backend.
     *
     * This method is already effectively debounced and protected from extra calls from modifier keystrokes.
     *
     * @param {Object} query The query to use for retrieving objects from the store.
     * @param {Object} options The optional arguments to apply to the resultset.
     * @returns {Promise|Object} QueryResults or a promise
     */
    query: function (query, options) {
      // The default experience. If anything goes wrong, look up from the data set added at instantiation
      var localQuery = QueryResults(this.queryEngine(query, options)(this.data));

      // query.value is by default a RegExp obj when the searchAttr is set to "value". Naively convert the
      // foo* value to a regular string and strip the '*';
      var term = query.value ? query.value.toString().slice(0, -1) : "";
      if (!this.listId || !term) {
        return localQuery;
      }

      // Perform network request for query term. Swallow any errors by returning
      // default local experience.
      var deferred = new Deferred();
      api.getProductListFromTerm(this.listId, term).then(function (products) {
        if (products.length > 0) {
          deferred.resolve(QueryResults(formatProducts(products)));
        } else {
          deferred.resolve(localQuery);
        }
      }, function () {
        // Something went wrong, return the default experience
        deferred.resolve(localQuery);
      });

      // _SearchMixin assumes a synchronous response but accepts a promise because it's built
      // around Dojo/when. As a promise we avoid a race condition and can easily handle the async
      // request
      return deferred.promise;
    }
  });
  var invalidText = "Invalid value";
  return declare([_FilteringSelect, _SegmentInputMixin], {
    value: null,
    options: null,
    constructor: function () {
      this.set("searchAttr", "value");
      this.set("labelAttr", "label");
      this.set("labelType", "html");
    },
    /**
     * On startup, inject the list of options into the store
     */
    startup: function () {
      var isDynamicSearchEnabled = this.options.length >= MAX_OPTIONS;

      // Both Memory and ProductStore share the same API.
      // If there aren't a ton of products, hydrate the default local datastore mechanism
      // used throughout most of Dojo.
      var DataResource = isDynamicSearchEnabled ? ProductsStore : Memory;
      var store = new DataResource({
        listId: this.configs.listId,
        data: this.options,
        idProperty: "value"
      });
      this.set("store", store);
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        invalidText = I18nTranslation.translate("audience_management_segment_builder_invalid_value");
      }
    },
    /**
     * Get display label to show when no longer editing this condition
     * @return {string} value to display when the select is not in use
     */
    getDisplayHTML: function () {
      if (this.displayedValue) {
        // Input value may have unsanitized data in it. Before presenting it back to the user
        // as raw HTML, encode it to address XSS potential.
        return htmlEntities.encode(this.displayedValue);
      }
      return "(" + invalidText + ")";
    },
    /**
     * Get selected value
     * @return {Object} selected value
     */
    getValue: function () {
      return this.value;
    },
    /**
     * Set selected value
     * @param {Object} value selected
     */
    setValue: function (value) {
      this.set("value", value);
    }
  });
});