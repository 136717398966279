define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"./a11y" // _getTabNavigable
], function (declare, a11y) {
  // module:
  //		dijit/_DialogMixin

  return declare("dijit._DialogMixin", null, {
    // summary:
    //		This provides functions useful to Dialog and TooltipDialog

    // actionBarTemplate: String
    //		HTML snippet to show the action bar (gray bar with OK/cancel buttons).
    //		Blank by default, but used by ConfirmDialog/ConfirmTooltipDialog subclasses.
    actionBarTemplate: "",
    execute: function /*Object*/ /*===== formContents =====*/
    () {
      // summary:
      //		Callback when the user hits the submit button.
      //		Override this method to handle Dialog execution.
      // description:
      //		After the user has pressed the submit button, the Dialog
      //		first calls onExecute() to notify the container to hide the
      //		dialog and restore focus to wherever it used to be.
      //
      //		*Then* this method is called.
      // type:
      //		callback
    },
    onCancel: function () {
      // summary:
      //		Called when user has pressed the Dialog's cancel button, to notify container.
      // description:
      //		Developer shouldn't override or connect to this method;
      //		it's a private communication device between the TooltipDialog
      //		and the thing that opened it (ex: `dijit/form/DropDownButton`)
      // type:
      //		protected
    },
    onExecute: function () {
      // summary:
      //		Called when user has pressed the dialog's OK button, to notify container.
      // description:
      //		Developer shouldn't override or connect to this method;
      //		it's a private communication device between the TooltipDialog
      //		and the thing that opened it (ex: `dijit/form/DropDownButton`)
      // type:
      //		protected
    },
    _onSubmit: function () {
      // summary:
      //		Callback when user hits submit button
      // type:
      //		protected
      this.onExecute(); // notify container that we are about to execute
      this.execute(this.get('value'));
    },
    _getFocusItems: function () {
      // summary:
      //		Finds focusable items in dialog,
      //		and sets this._firstFocusItem and this._lastFocusItem
      // tags:
      //		protected

      var elems = a11y._getTabNavigable(this.domNode);
      this._firstFocusItem = elems.lowest || elems.first || this.closeButtonNode || this.domNode;
      this._lastFocusItem = elems.last || elems.highest || this._firstFocusItem;
    }
  });
});