/**
 * Another quasi-neapolitan editor. It uses the same behavior to open as an editor, but it's not really a content block editor.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/_Templated", "dijit/_Widget", "dijit/form/RadioButton", "mojo/url", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/authz", "mojo/lib/logger", "mojo/widgets/pages/PrivacyBadge", "mojo/widgets/UpgradeActionBlock", "./_EditorMixin", "dojo/text!./templates/privacyBadgeEditor.html", "mojo/utils/I18nTranslation"], function (declare, lang, domAttr, domClass, domConstruct, on, Stateful, _Templated, _Widget, RadioButton, mUrl, user, context, flags, Authz, logger, PrivacyBadge, UpgradeActionBlock, _EditorMixin, template, I18nTranslation) {
  return declare([_Widget, _Templated, _EditorMixin, Authz], {
    templateString: template,
    widgetsInTemplate: true,
    model: null,
    upgradeActionBlock: null,
    canTogglePrivacyBadge: false,
    badgeDesign: "Mailchimp Badge Design",
    enableBadge: "Enable Mailchimp Badge",
    save: "Save",
    cancel: "Cancel",
    style: "Style",
    upgradeBlockTitle: "Add your own policy and terms",
    upgradeBlockCopy: "Remove our Mailchimp badge to add terms tailored to your brand.",
    privacyWarning: "These links help visitors understand their use of your website and are required in some regions. If you remove our links from your site, you must provide links to your own privacy statement and terms of use.",
    disableWarning: "To disable the Mailchimp badge on your page, <a href='/account/billing/plans' target='_blank' title='Upgrade to a paid account'>upgrade to a paid account</a>.",
    constructor: function () {
      this.inherited(arguments);
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.badgeDesign = I18nTranslation.translate("nea_badge_design");
        this.enableBadge = I18nTranslation.translate("nea_enable_badge");
        this.save = I18nTranslation.translate("nea_save");
        this.cancel = I18nTranslation.translate("nea_cancel");
        this.style = I18nTranslation.translate("nea_style");
        this.upgradeBlockTitle = I18nTranslation.translate("nea_upgrade_block_title");
        this.upgradeBlockCopy = I18nTranslation.translate("nea_upgrade_block_copy");
        this.privacyWarning = I18nTranslation.translate("nea_privacy_warning");
      }
    },
    postCreate: function (params) {
      I18nTranslation.initialize("I18N_LANDING_PAGES").then(function () {
        this.disableWarningMessage.innerHTML = I18nTranslation.translate("nea_disable_warning", ["<a href='/account/billing/plans' target='_blank' title='Upgrade to a paid account'>", "</a>"]);
      }.bind(this));
      this.inherited(arguments);
      this.canTogglePrivacyBadge = this.userHasFeature("remove_mailchimp_branding");
      if (!this.canTogglePrivacyBadge) {
        this._createUpdateNotification();
      }
      this._toggleElements(this.model.get("enabled"));
      var themes = Object.keys(PrivacyBadge.themeStyles);
      themes.forEach(function (theme) {
        var option = new RadioButton({
          value: theme,
          checked: this.model.get("theme") === theme,
          name: "privacyBadgeTheme"
        });
        option.startup();
        on(option, "change", this.onThemeChange.bind(this, theme));
        var preview = new PrivacyBadge({
          isLive: false,
          model: new Stateful({
            theme: theme,
            enabled: true,
            baseBadgeStyles: lang.mixin(PrivacyBadge.baseContainerStyles, {
              position: "static",
              bottom: "inherit",
              left: "inherit",
              right: "inherit",
              display: "block",
              margin: "0"
            }),
            onlyShowReportLink: this.model.get("onlyShowReportLink")
          }),
          onClick: this.onThemeChange.bind(this, theme)
        });
        preview.startup();
        var label = domConstruct.create("label", {
          class: "radio",
          style: "display: flex; align-items: center; text-indent: 0px; margin-left: -36px;"
        });
        domConstruct.place(option.domNode, label);
        domConstruct.place(preview.domNode, label);
        domConstruct.place(label, this.themeStyleSection);
      }.bind(this));
    },
    _toggleElements: function (isSelected) {
      if (!this.canTogglePrivacyBadge) {
        isSelected = true;
        this.privacyBadgeEnabledSwitch.set("disabled", true);
        // remove from dom don't want duplicated messages rendered and hidden
        domConstruct.destroy(this.freeUserVisibilityToggleMsg);
      } else {
        this.privacyBadgeEnabledSwitch.set("disabled", false);
      }
      this.privacyBadgeEnabledSwitch.set("value", isSelected);
      if (isSelected) {
        if (domClass.contains(this.themeStyleSection, "hide")) {
          domClass.remove(this.themeStyleSection, "hide");
        }
        if (!domClass.contains(this.privacyWarning, "hide")) {
          domClass.add(this.privacyWarning, "hide");
        }
      } else {
        if (domClass.contains(this.privacyWarning, "hide")) {
          domClass.remove(this.privacyWarning, "hide");
        }
        if (!domClass.contains(this.themeStyleSection, "hide")) {
          domClass.add(this.themeStyleSection, "hide");
        }
      }
    },
    _createUpdateNotification: function () {
      var gaTrackingLabelProps = {
        "location": "landing_page_editor",
        "upgrade_reason": "monkey_rewards_landing_page",
        "button_text": "upgrade_now"
      };
      this.upgradeActionBlock = new UpgradeActionBlock({
        body: {
          title: this.upgradeBlockTitle,
          copy: this.upgradeBlockCopy
        },
        featureName: "remove_mailchimp_branding",
        gaTrackingCategory: "upsell_moment",
        gaTrackingAction: "button_click",
        gaTrackingLabelProps: gaTrackingLabelProps,
        onComplete: function () {
          // get the userHasFeature
          this.canTogglePrivacyBadge = this.userHasFeature("remove_mailchimp_branding");
          if (this.canTogglePrivacyBadge && this.upgradeActionBlock) {
            this.upgradeActionBlock.destroy();
          }
          this._toggleElements(this.model.get("enabled"));
        }.bind(this),
        onCtaPressCallback: true
      });
      this.upgradeActionBlock.placeAt(this.upgradeNotification, "only");
    },
    onTogglePrivacyEnabled: function (enabled) {
      this.model.set("enabled", enabled);
      this._toggleElements(this.model.get("enabled"));
    },
    onThemeChange: function (theme, isSelected) {
      if (isSelected) {
        this.model.set("theme", theme);
      }
    },
    onSave: function () {},
    _onDone: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.onSave(this.model);
      this.done();
    },
    _onCancel: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.done();
    },
    onClose: function () {
      this.destroy();
    }
  });
});