define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/callout.html"], function (declare, domClass, domConstruct, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    icon: "light-bulb-color",
    iconPath: null,
    columns: 1,
    // fill containerNode with content when instantiated via JS
    content: null,
    postCreate: function () {
      if (this.content) {
        domConstruct.place(this.content, this.containerNode);
      }

      // Assign column width classes
      switch (this.columns) {
        case "1 centered":
          domClass.remove(this.tipContainerNode, "c-mediaBody--centered");
          domClass.add(this.domNode, "flex-auto justify-content--center");
          break;
        case 2:
          domClass.add(this.domNode, "flex-half");
          break;
        case 3:
          domClass.add(this.domNode, "flex-third");
          break;
        default:
          domClass.add(this.domNode, "flex-auto readable-max-width");
          break;
      }

      // Assign icon
      if (this.iconPath) {
        // Path to an arbitrary image file has been specified
        domClass.remove(this.imageContainer, "hide");
      } else {
        domClass.remove(this.freddiconContainer, "hide");
      }
    },
    show: function () {
      domClass.remove(this.domNode, "hide");
    },
    hide: function () {
      domClass.add(this.domNode, "hide");
    }
  });
});