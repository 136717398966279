module.exports = {
  "scientificFormat": "#E0",
  "currencySpacing-afterCurrency-currencyMatch": "[:^S:]",
  "infinity": "∞",
  "superscriptingExponent": "×",
  "list": ";",
  "percentSign": "%",
  "minusSign": "-",
  "currencySpacing-beforeCurrency-surroundingMatch": "[:digit:]",
  "decimalFormat-short": "000T",
  "currencySpacing-afterCurrency-insertBetween": " ",
  "nan": "NaN",
  "plusSign": "+",
  "currencySpacing-afterCurrency-surroundingMatch": "[:digit:]",
  "currencySpacing-beforeCurrency-currencyMatch": "[:^S:]",
  "currencyFormat": "¤ #,##0.00",
  "perMille": "‰",
  "group": ",",
  "percentFormat": "#,##0%",
  "decimalFormat-long": "000T",
  "decimalFormat": "#,##0.###",
  "decimal": ".",
  "currencySpacing-beforeCurrency-insertBetween": " ",
  "exponential": "E"
};