define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils"], function (declare, base, domAttr, on, domStyle, domClass, p2Utils) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      this.$group.submitDateForm.forEach(function (el) {
        on(el, "click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          var form = this.parentElement;
          p2Utils.utils.sendFormViaAjax(form);
        });
      });
      if (this.$el.firstPaymentInputEdit) {
        on(this.$el.firstPaymentInputEdit, "click", function () {
          domClass.remove(self.$el.firstPaymentInput, "hide");
          domClass.add(self.$el.firstPaymentInputEdit, "hide");
        });
      }
      if (this.$el.lastPaymentInputEdit) {
        on(this.$el.lastPaymentInputEdit, "click", function () {
          domClass.remove(self.$el.lastPaymentInput, "hide");
          domClass.add(self.$el.lastPaymentInputEdit, "hide");
        });
      }
      if (this.$el.monthlyStartDateInputEdit) {
        on(this.$el.monthlyStartDateInputEdit, "click", function () {
          domClass.remove(self.$el.monthlyStartDateInput, "hide");
          domClass.add(self.$el.monthlyStartDateInputEdit, "hide");
        });
      }
      if (this.$el.discountExpirationInputEdit) {
        on(this.$el.discountExpirationInputEdit, "click", function () {
          domClass.remove(self.$el.discountExpirationInput, "hide");
          domClass.add(self.$el.discountExpirationInputEdit, "hide");
        });
      }
      if (this.$el.monthlyPaymentFailedInputEdit) {
        on(this.$el.monthlyPaymentFailedInputEdit, "click", function () {
          domClass.remove(self.$el.monthlyPaymentFailedInput, "hide");
          domClass.add(self.$el.monthlyPaymentFailedInputEdit, "hide");
        });
      }
      if (this.$el.monthlyPlanRadio.checked) {
        domClass.add(this.$el.paygoPlanSelect, "hide");
      }
      if (this.$el.paygoPlanRadio.checked) {
        domClass.add(this.$el.monthlyPlanSelect, "hide");
      }
      on(this.$el.monthlyPlanRadio, "change", function () {
        if (self.$el.monthlyPlanRadio.checked) {
          domClass.toggle(self.$el.monthlyPlanSelect, "hide");
          domClass.toggle(self.$el.paygoPlanSelect, "hide");
        }
      });
      on(this.$el.paygoPlanRadio, "change", function () {
        if (self.$el.paygoPlanRadio.checked) {
          domClass.toggle(self.$el.monthlyPlanSelect, "hide");
          domClass.toggle(self.$el.paygoPlanSelect, "hide");
        }
      });
    },
    cancelEdit: function (elId, editId) {
      domClass.add(this.$el[elId], "hide");
      domClass.remove(this.$el[editId], "hide");
    }
  });
});