define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/app/peaches2/webhooks", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, mUrl, tabContainerUtil, webhooks, query) {
  return declare([base, webhooks], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        if (tab.stub === "webhooks") {
          tab.href = mUrl.toUrl("/peaches2/admin/webhooks/get-list", {
            users: self.userAttrs.userId
          });
        }
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
    },
    peachesUrl: function (endpoint, args) {
      return mUrl.toUrl("/peaches2/users" + endpoint, args);
    },
    exportCalls: function () {
      var exportDate = query("[name=api-stats-date]")[0].value;
      var dateArr = exportDate.split("-");
      var url = this.peachesUrl("/integrations/export-api-calls", {
        user_id: this.userAttrs.userId,
        d: dateArr[2],
        m: dateArr[1],
        y: dateArr[0],
        date: exportDate
      });
      document.location.href = url;
    }
  });
});