/**
 * Encapsulates mc:template_section which gives user the option to hide this element.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (declare, domConstruct, domClass, domStyle, velocity) {
  return declare(null, {
    sectionId: null,
    appRef: null,
    // Quick way to identify this object
    type: "template_section",
    isHidden: false,
    // hash of containers or container names. for use with setting hidden containers for Page.
    sectionContainers: {},
    constructor: function (appRef, sectionId, elDom) {
      this.appRef = appRef;
      this.sectionId = sectionId;
      this.dom = elDom;
      this.endpoint = appRef.getEndpoint();
      this.listeners = [];
      this.isHidden = this.checkHiddenClassNames(elDom);
      this.sectionOverlay = domConstruct.place("<div class='tpl-section-wrapper hide'>" + "<div class=\"tpl-section-wrapper-headline-container\"><h5 class=\"tpl-section-wrapper-headline\">" + sectionId + "</h5></div>" + "</div>", this.dom, "first");
    },
    toggleSectionVisible: function () {
      this.isHidden = this.checkHiddenClassNames(this.dom);
      if (this.isHidden) {
        // setup styles with classes for animating-in
        domClass.toggle(this.dom, "hidden-opacity", true);
        domClass.toggle(this.dom, "tpl-section-hidden", false);
        // multiple step animation: opacity, slideUp (opacity, height)
        velocity.animate(this.dom, "slideDown", {
          duration: 400,
          complete: function (elements) {
            domClass.toggle(this.dom, "tpl-section-hidden-height", false);
            // remove display:block from this.dom
            domStyle.set(this.dom, "display", "");
            velocity(this.dom, {
              opacity: 1
            }, {
              delay: 1000,
              duration: 400,
              complete: function () {
                domClass.toggle(this.dom, "hidden-opacity", false);
                domStyle.set(this.dom, "opacity", "");
                // preview - resize
                window.top.resizePreview();
              }.bind(this)
            });
          }.bind(this)
        });
      } else {
        // setup styles with classes for animating-out
        // hide dom with opacity
        velocity(this.dom, {
          opacity: 0
        }, {
          duration: 400,
          complete: function () {
            // slide up to end with height of 1
            domClass.toggle(this.dom, "tpl-section-hidden-opacity", true);
            velocity.animate(this.dom, "slideUp", {
              duration: 400,
              complete: function (elements) {
                domClass.toggle(this.dom, "tpl-section-hidden", true);
                this.appRef.hideTemplateSection(this);
                // preview - resize
                window.top.resizePreview();
              }.bind(this)
            });
          }.bind(this)
        });
      }
    },
    toggleTemplateSectionRequest: function () {
      return this.endpoint.hideable.toggle(this.sectionId).then(function () {
        this.toggleSectionVisible();
      }.bind(this));
    },
    checkHiddenClassNames: function (el) {
      return el.classList.contains("tpl-section-hidden");
    },
    mouseEnterControls: function () {
      // show the overlay
      domClass.toggle(this.sectionOverlay, "hide", false);
      velocity.animate(this.sectionOverlay, "fadeIn", {
        duration: 300,
        complete: function (elements) {
          domClass.toggle(this.dom, "tpl-section-wrapper-overlay", true);
        }.bind(this)
      });
      // dom get class for overlay
    },
    mouseLeaveControls: function () {
      // dom remove class for overlay
      velocity.animate(this.sectionOverlay, "fadeOut", {
        duration: 0,
        complete: function (elements) {
          domClass.toggle(this.dom, "tpl-section-wrapper-overlay", false);
          domClass.toggle(this.sectionOverlay, "hide", true);
        }.bind(this)
      });
    }
  });
});