define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin",
// Templates
"dojo/text!./templates/CampaignManagerCalendarBanner.html",
// Mojo
"mojo/url", "mojo/user", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, CampaignManagerCalendarBannerTemplateString, mUrl, mUser, flags, context, I18nTranslation) {
  /**
   * @module mojo/widgets
   * @class StaticInlineRec
   */
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: null,
    bannerType: null,
    settingId: null,
    buildRendering: function () {
      if (this.bannerType === "calendar") {
        this.templateString = CampaignManagerCalendarBannerTemplateString;
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      // Hide banner until translations resolve
      this.bannerContainer.classList.add("hide");
      I18nTranslation.initialize().then(function () {
        this.prepareText();
        // show banner after text is loaded
        this.bannerContainer.classList.remove("hide");
      }.bind(this));

      // Handle close icon
      this.connect(this.closeButton, "click", function () {
        mUser.saveSetting(this.settingId, "true");
        this.destroy();
      });
    },
    prepareText: function () {
      var titleText,
        descriptionText = "";
      var ctaText = "Discover Campaign Manager";
      var dismissText = "Dismiss";
      if (this.bannerType === "calendar") {
        if (context.flagIsOn(flags.ACP_USE_I18N_TRANSLATIONS) && !I18nTranslation.isTranslationObjectEmpty()) {
          titleText = I18nTranslation.translate("all_campaigns_campaign_manager_calendar_title");
          descriptionText = I18nTranslation.translate("all_campaigns_campaign_manager_calendar_content");
          ctaText = I18nTranslation.translate("all_campaigns_campaign_manager_discover");
          dismissText = I18nTranslation.translate("all_campaigns_campaign_manager_dismiss");
        } else {
          titleText = "A new way to organize campaigns";
          descriptionText = "See how your emails, SMS webhooks, tasks, and real life events come together to support your marketing goals.";
        }
        this.closeButton.innerText = dismissText;
      }
      this.bannerTitle.innerText = titleText;
      this.bannerDescription.innerText = descriptionText;
      this.bannerCta.innerText = ctaText;
    }
  });
});