define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block", "./_ButtonMixin"], function (declare, _Block, _ButtonMixin) {
  var CampaignRenderingTypes = {
    PAGE: "page"
  };
  return declare([_Block, _ButtonMixin], {
    type: "button",
    _name: "Button",
    _kbLink: "https://mailchimp.com/help/use-button-content-blocks/",
    constructor: function (model) {
      this.buttonText = "Buy Now";
      this.align = "center";
      this.buttonWidth = "";
      this.styles = this._getButtonStyles(model._campaignRenderingType);
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      if (this.link && this.link.href === oldUrl) {
        this.link.href = newUrl;
        return this.save();
      }
      return this.inherited(arguments);
    }
  });
});