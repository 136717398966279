/* eslint-disable */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking", "mojo/context", "mojo/lib/flags", "mojo/analytics/SubscriberTable", "mojo/url", "mojo/utils", "mojo/utils/string", "mojo/app/lists/members"], function (declare, base, lang, dom, domClass, query, on, when, EcsEvents, EcsTracking, context, flags, SubscriberTable, mUrl, utils, stringUtils, listMembers) {
  return declare([base], {
    listId: null,
    includesEmaillessContacts: null,
    // Reference to the VisTableListMembers used
    contactTable: null,
    onReady: function () {
      // ECS page viewed: 51
      var previousUrlFull = window.top.history.state ? window.top.history.state.referrer : window.top.document.referrer;
      var referrer = previousUrlFull ? new URL(previousUrlFull).pathname : "";
      EcsTracking.track(EcsEvents.AudienceOrganizationViewArchivedContactsPageEvent, {
        previous_page_url: referrer
      });
      this._setupMemberTable();
      on(this.$el.unarchiveBtn, "click", lang.hitch(this, "_unarchiveSelected"));
    },
    _setupMemberTable: function () {
      var dataUrl = mUrl.toUrl("/lists/segments/members/archived", {
        "list_id": this.listId
      });
      this.contactTable = new SubscriberTable({
        dataUrl: dataUrl,
        floatingHeaderEnabled: false,
        includesEmaillessContacts: this.includesEmaillessContacts,
        options: {
          wait: true,
          noDataDom: this.$el.noDataBlock
        }
      }, dom.byId('member-grid'));

      // An enabled export button is only displayed for admin and above roles
      // so, if one isn't present we can skip hidding it
      if (this.$el.exportBtn != null) {
        var contactTable = this.contactTable;
        // ECS event role based help : 45
        on(this.$el.exportBtn, "click", function (e) {
          // ECS page viewed: 52
          var totalCount = contactTable.total || 0;
          EcsTracking.track(EcsEvents.AudienceOrganizationClickArchivedContactsExportEvent, {
            contact_count: totalCount
          });
        });

        // Hide export button if table has no data to export
        this.contactTable.watch("total", lang.hitch(this, function () {
          domClass.toggle(this.$el.exportBtn, 'hide', !this.contactTable.total);
        }));
      }
      var resultsPerPageDropdown = dom.byId('dijit_form_Select_0');
      var selectedIds = this.contactTable.getSelection();
      var selectedCount = selectedIds.length;
      var selectedOptions = document.body.getElementsByClassName('dijitMenuItem');

      // ECS viewable dropdown selected: 54
      if (resultsPerPageDropdown) {
        on(resultsPerPageDropdown, "click", function () {
          for (var i = 0; i < selectedOptions.length; i++) {
            selectedOptions[i].addEventListener('click', function (event) {
              EcsTracking.track(EcsEvents.AudienceOrganizationClickArchivedContactsChangeViewableEvent, {
                contact_count: selectedCount,
                num_of_records_shown: this.innerText
              });
            });
          }
        });
      }
      ;

      // Explicitly turn off the select-all link as that's not yet supported.
      this.contactTable.set("canSelectAll", false);
      this.contactTable.set("page", 1);
      this.contactTable.requery();
    },
    _unarchiveSelected: function () {
      var selectedIds = this.contactTable.getSelection();
      var selectedCount = selectedIds.length;

      // ECS page viewed: 53
      EcsTracking.track(EcsEvents.AudienceOrganizationClickArchivedContactsUnarchiveEvent, {
        contact_count: selectedCount
      });
      if (selectedCount) {
        if (this.includesEmaillessContacts === true) {
          listMembers.unarchiveEmaillessContacts(this.listId, selectedIds).then(lang.hitch(this, "_successfullyArchived", selectedCount), lang.hitch(this, "_failedToArchive"));
        } else {
          listMembers.unarchiveContacts(this.listId, selectedIds).then(lang.hitch(this, "_successfullyArchived", selectedCount), lang.hitch(this, "_failedToArchive"));
        }
      }
    },
    _successfullyArchived: function (selectedCount) {
      utils.toast("Successfully unarchived " + selectedCount + " " + stringUtils.toPlural("contact", selectedCount));

      // If we archive all of the last page, load the previous page
      when(this.contactTable.refresh(true), lang.hitch(this, function () {
        var notFirstPage = this.contactTable.page > 1;
        if (notFirstPage && this.contactTable.total === 0) {
          this.contactTable.set("page", this.contactTable.page - 1);
        }
      }));
    },
    _failedToArchive: function () {
      utils.toast("Could not unarchive the selected contacts", "error");
    }
  });
});