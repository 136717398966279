define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  return declare(null, {
    essentialCategory: "essential",
    performanceCategory: "performance",
    analyticsCategory: "analytics",
    advertisingCategory: "advertising",
    activeGroups: [],
    constructor: function () {
      if (typeof window.top.OneTrust !== "undefined") {
        this.OneTrust = window.top.OneTrust;
      }
      if (typeof window.top.OnetrustActiveGroups !== "undefined") {
        this.activeGroups = window.top.OnetrustActiveGroups;
      }
      this.cookieCategories = {
        "1": this.essentialCategory,
        "2": this.performanceCategory,
        "3": this.analyticsCategory,
        "4": this.advertisingCategory
      };
      this.parsePreferences();
    },
    parsePreferences: function () {
      var self = this;
      this.allowedCategories = [];
      this.allowedCategoryIDs = this.activeGroups.split(",");
      this.allowedCategoryIDs.shift();
      this.allowedCategoryIDs.pop();
      this.allowedCategoryIDs.forEach(function (item) {
        self.allowedCategories.push(self.cookieCategories[item]);
      });
      return this.allowedCategories;
    },
    setCookie: function (category, key, value, expires, path, domain) {
      if (this.isCategoryAllowed(category)) {
        var cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);
        if (typeof path === 'string') {
          cookie += ";path=" + path;
        }
        if (expires !== undefined && typeof expires.getMonth === 'function') {
          cookie += ";expires=" + expires;
        }
        if (typeof domain === 'string') {
          cookie += ";domain=" + domain;
        }
        document.cookie = cookie;
        return true;
      }
      return false;
    },
    isCategoryAllowed: function (category) {
      return this.allowedCategories.includes(category);
    }
  }); // eo declare
}); // eo define