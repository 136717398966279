define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo/text!../editors/blocks/templates/TextEditor/output.html"], function (declare, _Renderer, tpl) {
  return declare("mojo.neapolitan.email-renderers.TextBlock", [_Renderer], {
    outputTplStr: tpl,
    getSplitWidth: function (totalWidth, split) {
      return Math.floor(totalWidth * (split / 100));
    },
    getOutputModel: function (meta) {
      var columns = [];
      var totalWidth = meta.containerWidth;
      // Accepts an array of cont0ent or just a string for content
      var contents = typeof meta.content == "string" ? [meta.content] : meta.content;
      for (var i = 0; i < meta.numberOfColumns; i++) {
        var split = meta.splitOption[i];
        var content = contents[i] ? contents[i] : "";
        columns.push({
          content: content,
          width: this.getSplitWidth(totalWidth, split)
        });
      }
      return {
        columns: columns,
        columnCount: meta.numberOfColumns,
        containerWidth: meta.containerWidth
      };
    }
  });
});