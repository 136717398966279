/**
 * Used to handle async task responses. Wrap it around an xhr request that returns such responses
 * and this will handle the callbacks.
 *
 * Usages:
 * async(xhr.get('url'), { onComplete: ... });
 *
 * @param xhrRequest - an xhr request from dojo
 * @param callbacks - object that contain any of the following callbacks.
 *  onComplete, onError, onFail
 */
define(["mojo/url", "dojo/request"], function (url, request) {
  var INTERVAL = 3000;

  /**
   * Pings the endpoint to get the status of the async task
   * @param {string} id
   * @param {function} callbacks
   */
  var call = function (id, callbacks) {
    request.post(url.toUrl('/async'), {
      'handleAs': 'json',
      'data': {
        'id': id
      }
    }).then(function (response) {
      handleStatus(id, response, callbacks);
    });
  };

  /**
   * @param {string} id
   * @param {object} response
   * @param {object} callbacks
   */
  var handleStatus = function (id, response, callbacks) {
    var status = response.status;
    switch (status) {
      case 'queued':
      case 'started':
        setTimeout(function () {
          call(id, callbacks);
        }, INTERVAL);
        break;
      case 'error':
        if (callbacks && callbacks.onError) {
          callbacks.onError(response);
        }
        break;
      case 'failed':
        if (callbacks && callbacks.onFailed) {
          callbacks.onFailed(response);
        }
        break;
      case 'completed':
        if (callbacks && callbacks.onComplete) {
          callbacks.onComplete(response);
        }
        break;
    }
  };

  /**
   *
   * @param xhrRequest
   * @param {function} callbacks
   */
  var async = function (xhrRequest, callbacks) {
    callbacks = callbacks || {};
    xhrRequest.then(function (response) {
      var id = response['_async_id'];
      handleStatus(id, response, callbacks);
    });
  };
  return async;
});