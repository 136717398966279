define([], function () {
  return {
    // Freddicons 20
    "edit": {
      code: "e000",
      "class": "edit"
    },
    "duplicate": {
      code: "e001",
      "class": "duplicate"
    },
    "trash": {
      code: "e002",
      "class": "trash"
    },
    "addCircle": {
      code: "e003",
      "class": "add-circle"
    },
    "addFill": {
      code: "e004",
      "class": "add-fill"
    },
    "removeCircle": {
      code: "e005",
      "class": "remove-circle"
    },
    "removeFill": {
      code: "e006",
      "class": "remove-fill"
    },
    "tickCircle": {
      code: "e007",
      "class": "tick-circle"
    },
    "tickCircleColor": {
      code: "e007",
      "class": "tick-circle-color"
    },
    "tickFill": {
      code: "e008",
      "class": "tick-fill"
    },
    "tickFillColor": {
      code: "e008",
      "class": "tick-fill-color"
    },
    "crossCircle": {
      code: "e009",
      "class": "cross-circle"
    },
    "crossCircleColor": {
      code: "e009",
      "class": "cross-circle-color"
    },
    "crossFill": {
      code: "e010",
      "class": "cross-fill"
    },
    "infoCircle": {
      code: "e011",
      "class": "info-circle"
    },
    "infoCircleColor": {
      code: "e011",
      "class": "info-circle-color"
    },
    "infoFill": {
      code: "e012",
      "class": "info-fill"
    },
    "timeCircle": {
      code: "e013",
      "class": "time-circle"
    },
    "timeFill": {
      code: "e014",
      "class": "time-fill"
    },
    "warnCircle": {
      code: "e015",
      "class": "warn-circle"
    },
    "warnCircleColor": {
      code: "e015",
      "class": "warn-circle-color"
    },
    "warnFill": {
      code: "e016",
      "class": "warn-fill"
    },
    "playCircle": {
      code: "e017",
      "class": "play-circle"
    },
    "playFill": {
      code: "e018",
      "class": "play-fill"
    },
    "pauseCircle": {
      code: "e019",
      "class": "pause-circle"
    },
    "pauseFill": {
      code: "e020",
      "class": "pause-fill"
    },
    "menuLeft": {
      code: "e021",
      "class": "menu-left"
    },
    "menuUp": {
      code: "e022",
      "class": "menu-up"
    },
    "menuRight": {
      code: "e023",
      "class": "menu-right"
    },
    "menuDown": {
      code: "e024",
      "class": "menu-down"
    },
    "play": {
      code: "e025",
      "class": "play"
    },
    "arrowLeft": {
      code: "e026",
      "class": "arrow-left"
    },
    "arrowRight": {
      code: "e027",
      "class": "arrow-right"
    },
    "arrowLeftRight": {
      code: "e028",
      "class": "arrow-left-right"
    },
    "arrowUp": {
      code: "e029",
      "class": "arrow-up"
    },
    "arrowDown": {
      code: "e030",
      "class": "arrow-down"
    },
    "download": {
      code: "e031",
      "class": "download"
    },
    "share": {
      code: "e032",
      "class": "share"
    },
    "refresh": {
      code: "e033",
      "class": "refresh"
    },
    "undo": {
      code: "e034",
      "class": "undo"
    },
    "plus": {
      code: "e035",
      "class": "plus"
    },
    "tick": {
      code: "e036",
      "class": "tick"
    },
    "cross": {
      code: "e037",
      "class": "cross"
    },
    // { code:"e038", class:"menu-right-large" },
    "pause": {
      code: "e039",
      "class": "pause"
    },
    "code": {
      code: "e040",
      "class": "code"
    },
    "print": {
      code: "e041",
      "class": "print"
    },
    "noComment": {
      code: "e042",
      "class": "no-comment"
    },
    "comment": {
      code: "e043",
      "class": "comment"
    },
    "pic": {
      code: "e044",
      "class": "pic"
    },
    "analytics": {
      code: "e045",
      "class": "analytics"
    },
    "calendar": {
      code: "e046",
      "class": "calendar"
    },
    "month": {
      code: "e047",
      "class": "month"
    },
    "week": {
      code: "e048",
      "class": "week"
    },
    "day": {
      code: "e049",
      "class": "day"
    },
    "maximize": {
      code: "e050",
      "class": "maximize"
    },
    "minimize": {
      code: "e051",
      "class": "minimize"
    },
    "camera": {
      code: "e052",
      "class": "camera"
    },
    "conversation": {
      code: "e053",
      "class": "conversation"
    },
    "eye": {
      code: "e054",
      "class": "eye"
    },
    "link": {
      code: "e055",
      "class": "link"
    },
    "settings": {
      code: "e056",
      "class": "settings"
    },
    "search": {
      code: "e057",
      "class": "search"
    },
    "phone": {
      code: "e058",
      "class": "phone"
    },
    "conversationFill": {
      code: "e059",
      "class": "conversation-fill"
    },
    "location": {
      code: "e060",
      "class": "location"
    },
    "gridView": {
      code: "e061",
      "class": "grid-view"
    },
    "listView": {
      code: "e062",
      "class": "list-view"
    },
    "hamburger": {
      code: "e063",
      "class": "hamburger"
    },
    "moveArrows": {
      code: "e064",
      "class": "move-arrows"
    },
    "vellip": {
      code: "e065",
      "class": "vellip"
    },
    "addSubscriber": {
      code: "e066",
      "class": "add-subscriber"
    },
    "starFill": {
      code: "e067",
      "class": "star-fill"
    },
    "grayStarFill": {
      code: "e067",
      "class": "graystar-fill"
    },
    "starHalf": {
      code: "e068",
      "class": "star-half"
    },
    "star": {
      code: "e069",
      "class": "star"
    },
    "heartFill": {
      code: "e070",
      "class": "heart-fill"
    },
    "heart": {
      code: "e071",
      "class": "heart"
    },
    "desktop": {
      code: "e072",
      "class": "desktop"
    },
    "phoneDuplicate": {
      code: "e073",
      "class": "phone"
    },
    //Same as e058 as far as I know. Either needs to be removed or renamed to something more meaningful. Will check with Alex and update later.
    "offline": {
      code: "e074",
      "class": "offline"
    },
    "textBold": {
      code: "e075",
      "class": "text-bold"
    },
    "textItalics": {
      code: "e076",
      "class": "text-italics"
    },
    "textUnderline": {
      code: "e077",
      "class": "text-underline"
    },
    "textColor": {
      code: "e078",
      "class": "text-color"
    },
    "fillColor": {
      code: "e079",
      "class": "fill-color"
    },
    "bulletList": {
      code: "e080",
      "class": "bullet-list"
    },
    "leftAlign": {
      code: "e081",
      "class": "left-align"
    },
    "centerAlign": {
      code: "e082",
      "class": "center-align"
    },
    "rightAlign": {
      code: "e083",
      "class": "right-align"
    },
    "justify": {
      code: "e084",
      "class": "justify"
    },
    "anchorLink": {
      code: "e085",
      "class": "anchor-link"
    },
    "editorPic": {
      code: "e086",
      "class": "editor-pic"
    },
    "editorCode": {
      code: "e087",
      "class": "editor-code"
    },
    "copy": {
      code: "e088",
      "class": "copy"
    },
    "copyWord": {
      code: "e089",
      "class": "copy-word"
    },
    "editorLink": {
      code: "e090",
      "class": "editor-link"
    },
    "editorUnlink": {
      code: "e091",
      "class": "editor-unlink"
    },
    "clearStyle": {
      code: "e092",
      "class": "clear-style"
    },
    "numberList": {
      code: "e093",
      "class": "number-list"
    },
    "editorUndo": {
      code: "e094",
      "class": "editor-undo"
    },
    "editorRedo": {
      code: "e095",
      "class": "editor-redo"
    },
    "spellcheck": {
      code: "e096",
      "class": "spellcheck"
    },
    "horizontalRule": {
      code: "e097",
      "class": "horizontal-rule"
    },
    "paragraph": {
      code: "e098",
      "class": "paragraph"
    },
    "paragraphRTL": {
      code: "e099",
      "class": "paragraph-rtl"
    },
    "crop": {
      code: "e100",
      "class": "crop"
    },
    "fullscreen": {
      code: "e101",
      "class": "fullscreen"
    },
    "redirect": {
      code: "e102",
      "class": "redirect"
    },
    "refreshPro": {
      code: "e103",
      "class": "refresh-pro"
    },
    "plusSmall": {
      code: "e104",
      "class": "plus-small"
    },
    "minusSmall": {
      code: "e105",
      "class": "minus-small"
    },
    "notAllowed": {
      code: "e106",
      "class": "not-allowed"
    },
    "notAllowedColor": {
      code: "e106",
      "class": "not-allowed-color"
    },
    "eyeHide": {
      code: "e107",
      "class": "eye-hide"
    },
    "lightBulb": {
      code: "e108",
      "class": "light-bulb"
    },
    "lightBulbColor": {
      code: "e108",
      "class": "light-bulb-color"
    },
    "textStrikethrough": {
      code: "e109",
      "class": "text-strikethrough"
    },
    "campaignList": {
      code: "e110",
      "class": "campaign-list"
    },
    "abTest": {
      code: "e111",
      "class": "ab-test"
    },
    "emailCampaign": {
      code: "e112",
      "class": "email-campaign"
    },
    "rssFeed": {
      code: "e113",
      "class": "rss-feed"
    },
    "campaignOngoing": {
      code: "e114",
      "class": "campaign-ongoing"
    },
    "adCampaign": {
      code: "e115",
      "class": "ad-campaign"
    },
    "landingPage": {
      code: "e116",
      "class": "landing-page"
    },
    "socialPost": {
      code: "e117",
      "class": "social-post"
    },
    "editorIndent": {
      code: "e118",
      "class": "editor-indent"
    },
    "editorOutdent": {
      code: "e120",
      "class": "editor-outdent"
    },
    "archive": {
      code: "e122",
      "class": "archive"
    },
    "editorBlockquote": {
      code: "e123",
      "class": "editor-blockquote"
    },
    "dragHandle": {
      code: "e124",
      "class": "drag-handle"
    },
    "blocks": {
      code: "e125",
      "class": "blocks"
    },
    "content": {
      code: "e126",
      "class": "content"
    },
    "style": {
      code: "e127",
      "class": "style"
    },
    // Freddicons 36
    "editCircleLarge": {
      code: "f000",
      "class": "edit-circle-large"
    },
    "editCircleLargeColor": {
      code: "f000",
      "class": "edit-circle-large-color"
    },
    "editFillLarge": {
      code: "f001",
      "class": "edit-fill-large"
    },
    "duplicateCircleLarge": {
      code: "f002",
      "class": "duplicate-circle-large"
    },
    "duplicateFillLarge": {
      code: "f003",
      "class": "duplicate-fill-large"
    },
    "trashCircleLarge": {
      code: "f004",
      "class": "trash-circle-large"
    },
    "trashFillLarge": {
      code: "f005",
      "class": "trash-fill-large"
    },
    "addCircleLarge": {
      code: "f006",
      "class": "add-circle-large"
    },
    "addFillLarge": {
      code: "f007",
      "class": "add-fill-large"
    },
    "removeCircleLarge": {
      code: "f008",
      "class": "remove-circle-large"
    },
    "removeFillLarge": {
      code: "f009",
      "class": "remove-fill-large"
    },
    "tickCircleLarge": {
      code: "f010",
      "class": "tick-circle-large"
    },
    "tickCircleLargeColor": {
      code: "f010",
      "class": "tick-circle-large-color"
    },
    "tickFillLarge": {
      code: "f011",
      "class": "tick-fill-large"
    },
    "crossCircleLarge": {
      code: "f012",
      "class": "cross-circle-large"
    },
    "crossCircleLargeColor": {
      code: "f012",
      "class": "cross-circle-large-color"
    },
    "crossFillLarge": {
      code: "f013",
      "class": "cross-fill-large"
    },
    "infoCircleLarge": {
      code: "f014",
      "class": "info-circle-large"
    },
    "infoCircleLargeColor": {
      code: "f014",
      "class": "info-circle-large-color"
    },
    "infoFillLarge": {
      code: "f015",
      "class": "info-fill-large"
    },
    "scheduleCircleLarge": {
      code: "f016",
      "class": "schedule-circle-large"
    },
    "scheduleCircleLargeColor": {
      code: "f016",
      "class": "schedule-circle-large-color"
    },
    "scheduleFillLarge": {
      code: "f017",
      "class": "schedule-fill-large"
    },
    "warnCircleLarge": {
      code: "f018",
      "class": "warn-circle-large"
    },
    "warnCircleLargeColor": {
      code: "f018",
      "class": "warn-circle-large-color"
    },
    "warnFillLarge": {
      code: "f019",
      "class": "warn-fill-large"
    },
    "pauseCircleLarge": {
      code: "f020",
      "class": "pause-circle-large"
    },
    "pauseCircleLargeColor": {
      code: "f020",
      "class": "pause-circle-large-color"
    },
    "pauseFillLarge": {
      code: "f021",
      "class": "pause-fill-large"
    },
    "codeCircleLarge": {
      code: "f022",
      "class": "code-circle-large"
    },
    "codeFillLarge": {
      code: "f023",
      "class": "code-fill-large"
    },
    "conversationCircleLarge": {
      code: "f024",
      "class": "conversation-circle-large"
    },
    "conversationCircleLargeColor": {
      code: "f024",
      "class": "conversation-circle-large-color"
    },
    "conversationFillLarge": {
      code: "f025",
      "class": "conversation-fill-large"
    },
    "eyeCircleLarge": {
      code: "f026",
      "class": "eye-circle-large"
    },
    "eyeFillLarge": {
      code: "f027",
      "class": "eye-fill-large"
    },
    "linkCircleLarge": {
      code: "f028",
      "class": "link-circle-large"
    },
    "linkFillLarge": {
      code: "f029",
      "class": "link-fill-large"
    },
    "searchCircleLarge": {
      code: "f030",
      "class": "search-circle-large"
    },
    "searchFillLarge": {
      code: "f031",
      "class": "search-fill-large"
    },
    "refreshCircleLarge": {
      code: "f032",
      "class": "refresh-circle-large"
    },
    "notAllowedLarge": {
      code: "f033",
      "class": "not-allowed-large"
    },
    "notAllowedFillLarge": {
      code: "f034",
      "class": "not-allowed-fill-large"
    },
    "undoLarge": {
      code: "f035",
      "class": "undo-large"
    },
    "archiveCircleLarge": {
      code: "f036",
      "class": "archive-circle-large"
    },
    "archiveFillLarge": {
      code: "f037",
      "class": "archive-fill-large"
    }
  };
});