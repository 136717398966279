/**
 * Interfaces with Mailchimp's VIS endpoints so separate all that logic from just rendering a mojo/analytics/VisTable
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags"], function (declare, lang, array, Deferred, user, flags) {
  return declare([], {
    constructor: function (url, query, urlParams) {
      this.url = url;
      this.query = lang.mixin({}, query);
      this.urlParams = lang.mixin({}, urlParams);
    },
    setSort: function (orderByCol, ascending) {
      this.query.orderby = orderByCol;
      this.query.ascending = ascending;
      return this;
    },
    resetSort: function () {
      this.query.orderby = null;
      this.query.ascending = null;
      return this;
    },
    /**
     * Fetch the count for this particular query.
     * @return {dojo.Deferred} a promise that will contain an integer for the count.
     */
    getCount: function () {
      var promise = new Deferred();
      var query_str = "select " + this.query.select_count;
      if (this.query.from) {
        query_str += " from " + this.query.from;
      }
      if (this.query.where) {
        query_str += " where (" + this.query.where + ")";
        if (this.query.filter) {
          query_str += " and (" + this.query.filter + ")";
        }
      } else if (this.query.filter) {
        query_str += " where " + this.query.filter;
      }
      if (this.query.groupby) {
        query_str += " group by " + this.query.groupby;
      }
      if (this.query.pivot) {
        query_str += " pivot " + this.query.pivot;
      }
      if (user.flagIsOn(flags.REPORTING_COMBINE_CLICKS)) {
        this._send(query_str).then(lang.hitch(this, function (res) {
          if (res.status === "success") {
            var rowCount;
            if (this.query.groupby) {
              rowCount = res.data.length;
            } else {
              rowCount = parseInt(res.data[0][0].v, 10);
            }
            promise.resolve(rowCount);
          } else {
            promise.reject("Couldn't get count");
          }
        }));
      } else {
        this._send(query_str).then(function (res) {
          if (res.status === "success") {
            var rowCount = parseInt(res.data[0][0].v, 10);
            promise.resolve(rowCount);
          } else {
            promise.reject("Couldn't get count");
          }
        });
      }
      return promise;
    },
    /**
     * Fetch the rows for this particular query.
     *
     * @param page the page of data to query for (starts at 1 and defaults to 1)
     * @param pageSize the pageSize of this query (defaults to 25)
     *
     * @return {dojo.Deferred} a promise that will contain an array of Objects of the following format:
     *  [{header_id: row_value, ...}]
     */
    getData: function (page, pageSize) {
      var promise = new Deferred();
      if (!page) {
        page = 1;
      }
      if (!pageSize) {
        pageSize = 25;
      }
      this._send(this._buildQuery(this.query, page, pageSize)).then(function (data) {
        var result = {};
        result.headers = [];
        array.forEach(data.meta, function (header) {
          var headerData = {
            id: header.id,
            label: header.label
          };
          result.headers.push(headerData);
        });
        result.rows = [];
        array.forEach(data.data, function (row) {
          var rowData = {};
          array.forEach(row, function (val, idx) {
            rowData[result.headers[idx].id] = val.f ? val.f : val.v;
          });
          result.rows.push(rowData);
        });
        promise.resolve(result);
      });
      return promise;
    },
    _send: function (queryString) {
      var url = "";
      var time = new Date().getTime();
      if (this.url.indexOf("?") === -1) {
        url = this.url + "?_cbt=" + time;
      } else {
        url = this.url + "&_cbt=" + time;
      }
      return dojo.xhrPost({
        "url": url,
        "content": lang.mixin(this.urlParams, {
          q: queryString
        }),
        "handleAs": "json"
      });
    },
    _buildQuery: function (query, page, pageSize) {
      var query_str = "";
      if (query.select) {
        query_str += "select " + query.select;
      }
      if (query.from) {
        query_str += " from " + query.from;
      }
      if (query.where) {
        query_str += " where (" + query.where + ")";
        if (query.filter) {
          query_str += " and (" + query.filter + ")";
        }
      } else if (query.filter) {
        query_str += " where " + query.filter;
      }
      if (query.groupby) {
        query_str += " group by " + query.groupby;
      }
      if (query.pivot) {
        query_str += " pivot " + query.pivot;
      }
      if (query.orderby) {
        query_str += " order by ";
        if (query.filter_orderby) {
          query_str += query.filter_orderby + ", ";
        }

        // Remove desc/asc
        query.orderby = query.orderby.toLowerCase();
        if (query.orderby.match("desc")) {
          query.orderby = lang.trim(query.orderby.replace("desc", ""));
          query.ascending = false;
        } else if (query.orderby.toLowerCase().match("asc")) {
          query.orderby = lang.trim(query.orderby.replace("asc", ""));
          query.ascending = true;
        }
        query_str += query.orderby;
        if (!query.ascending) {
          query_str += " desc";
        }
      } else if (query.filter_orderby) {
        query_str += "order by " + query.filter_orderby;
      }
      query_str += " limit " + pageSize + " offset " + (page - 1) * pageSize;
      if (query.label) {
        query_str += " label " + query.label;
      }
      if (query.format) {
        query_str += " format " + query.format;
      }
      return query_str;
    }
  });
});