define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dijit/_WidgetBase', 'dijit/_Templated', 'dijit/layout/ContentPane', "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dojo/text!./templates/preservedContentDialog.html', "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog"], function (declare, _WidgetBase, _Templated, ContentPane, on, ioQuery, Deferred, tplStr, domConstruct, domClass, lang, Dialog) {
  return declare([_WidgetBase, _Templated], {
    templateString: tplStr,
    widgetsInTemplate: true,
    postMixInProperties: function () {
      if (!this.campaignId) {
        throw new Error("Please specify the template or campaign Id");
      }
      if (!this.rootUrl) {
        throw new Error("Please specify the root url for this editor");
      }
      if (!this.preservedContentBlocks) {
        throw new Error("You must specify preserved content map");
      }
    },
    postCreate: function () {
      var self = this;
      this.index = null;
      this.watch("index", lang.hitch(this, function (name, oldValue, value) {
        domClass.toggle(this.prevBtn, "disabled", value <= 0);
        domClass.toggle(this.nextBtn, "disabled", value >= this.preservedContentBlocks.length - 1);
        this._loadContent();
      }));
      this.own(on(this.prevBtn, 'click', lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (!domClass.contains(this.prevBtn, "disabled")) {
          this.set("index", this.get("index") - 1);
        }
      })), on(this.nextBtn, 'click', lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (!domClass.contains(this.nextBtn, "disabled")) {
          this.set("index", this.get("index") + 1);
        }
      })), on(this.useBtn, 'click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        // the content is structured like the following { 'name': 'block_name', 'type': 'html'}
        self.hide().then(function () {
          self.promise.resolve(self.preservedContentBlocks[self.index]);
        });
      }), on(this.cancelBtn, 'click', lang.hitch(this, 'hide')));
    },
    _loadContent: function () {
      if (!this.contentPane) {
        this.contentPane = new ContentPane({}, this.contentPaneContainer);
      }
      var paramsStr = ioQuery.objectToQuery({
        id: this.campaignId,
        block: this.preservedContentBlocks[this.index]['name'],
        type: this.preservedContentBlocks[this.index]['type']
      });
      this.contentPane.setHref(this.rootUrl + '/preserved-block-preview?' + paramsStr);
    },
    /**
     * Shows the dialog and return a promise. This promise will will return a successful pick or fail when user cancels.
     */
    show: function () {
      this.promise = new Deferred();
      if (!this.dialog) {
        this.dialog = new Dialog({
          'title': "Recovered Content",
          'content': this.domNode
        });
      }

      // Reset it back to 0 & loads the content
      this.set("index", 0);
      this.dialog.show();
      return this.promise;
    },
    hide: function () {
      if (this.dialog) return this.dialog.hide();
      var promise = new Deferred();
      promise.resolve();
      return promise;
    }
  });
});