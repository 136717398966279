define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/layout/TabController", "mojo/widgets/Renderable", "mojo/intent-overlay/BlueprintCard", "mojo/lib/logger", "dojo/text!./templates/AutomationConfigurationView.html"], function (declare, lang, topic, registry, domConstruct, domClass, TabController, Renderable, BlueprintCard, logger, tpl) {
  var AutomationConfigurationView = declare([Renderable], {
    state: {
      isCustom: false
    },
    createButtonLocation: null,
    templateString: tpl,
    postMixInProperties: function () {
      // Bootup Listeners only if Automation Tab is selected
      if (this.isSelected) {
        AutomationConfigurationView._bootupListeners();
      }
      this.TabController = TabController;
      this.groups = this.option.groupedAutomations;
    },
    toggleCustom: function () {
      this.setState({
        isCustom: !this.state.isCustom
      });
      logger.googleAnalytics.trackEvent("pageview", "/campaign-create/explore/custom", "click on tab");
    },
    render: function () {
      var formerTabContainer = registry.byId("automationTabContainer");
      if (formerTabContainer) {
        formerTabContainer.destroy();
      }
      this.inherited(arguments);
      if (!this.state.isCustom) {
        Object.keys(this.groups).forEach(function (key) {
          var group = this.groups[key];
          var createBlueprintCards = group.blueprints.map(function (blueprint) {
            var card = new BlueprintCard({
              blueprint: blueprint,
              cardClass: "c-blueprintCard_simple"
            });
            domConstruct.place(card.domNode, this["contentPane-" + key], "last");
          }, this);
        }, this);
        domClass.toggle(this.domNode.querySelector("#automationTabContainer_tablist"), "dijitTabControllerAutomations");
      }
    },
    destroy: function () {
      this.inherited(arguments);
      AutomationConfigurationView.destroyTopicListeners();
    }
  });
  AutomationConfigurationView._startupListener = null;
  AutomationConfigurationView._selectListener = null;
  AutomationConfigurationView._bootupListeners = function () {
    AutomationConfigurationView._startupListener = topic.subscribe("automationTabContainer-startup", function (startupMessage) {
      var selectedPane = startupMessage.selected;
      AutomationConfigurationView._logSubtabView(selectedPane);
    });
    AutomationConfigurationView._selectListener = topic.subscribe("automationTabContainer-selectChild", AutomationConfigurationView._logSubtabView);
  };
  AutomationConfigurationView._logSubtabView = function (selectedPane) {
    logger.info("campaigns-front-door", "User view", {
      view: "automation",
      subtab: selectedPane.name
    });
    logger.googleAnalytics.trackEvent("pageview", "/campaign-create/explore/" + selectedPane.name, "click on tab");
  };
  AutomationConfigurationView.destroyTopicListeners = function () {
    var startupListener = AutomationConfigurationView._startupListener;
    if (startupListener) {
      startupListener.remove();
    }
    var selectListener = AutomationConfigurationView._selectListener;
    if (selectListener) {
      selectListener.remove();
    }
  };
  return AutomationConfigurationView;
});