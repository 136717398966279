define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/ViewContentSlat.html"], function (declare, lang, domClass, domConstruct, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    /**
     * Workhorse of the widget. See https://dojotoolkit.org/reference-guide/1.10/dijit/_WidgetBase.html for method
     * lifecyle
     */
    postCreate: function () {
      this.toggleCarouselControls();
      this._handleCarousel();
    },
    /**
     * Hide the next and previous buttons for carousel Ads
     *
     */
    toggleCarouselControls: function () {
      domClass.toggle(this.carouselActions, "hide", !this.hasAttachments);
    },
    /**
     * Toggle class to hide non-active ad items in carousel
     * @param  {Object} attachmentItem - individual carousel ad item
     * @param  {Object} attachmentIndex - index of carousel ad item
     * @param  {String} className - Optional class name to toggle
     *
     */
    toggleActiveClass: function (attachmentItem, attachmentIndex, className) {
      // If a class argument is defined use that class, if not use default hide class
      className = className || "hide";
      attachmentItem.forEach(function (value, index) {
        domClass.toggle(value, className, index !== +attachmentIndex);
      });
    },
    /**
     * Method for carousel ads to cycle between ad / attachments
     * To be implemented by child widgets
     */
    _handleCarousel: function () {}
  });
});