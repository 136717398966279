define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/SpecialAdCategorySelector.html"], function (declare, _Widget, _TemplatedMixin, _WidgetsInTemplateMixin, tpl) {
  return declare([_Widget, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    postCreate: function () {
      this.hasSpecialAdCategory.on("change", function (isChecked) {
        this.specialAdCategory.set("disabled", !isChecked);
        // if the user unchecks the "has special ad category" box, revert special ad category to null
        if (!this.hasSpecialAdCategory.isChecked) {
          this.specialAdCategory.value = null;
        }
        this.onChange();
      }.bind(this), true);
      this.specialAdCategory.on("change", function (value) {
        this.onChange();
      }.bind(this), true);
    },
    isValid: function () {
      if (this.hasSpecialAdCategory.checked) {
        return this.specialAdCategory.value;
      }
      return !this.hasSpecialAdCategory.checked;
    }
  });
});