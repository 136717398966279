define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/string", "mojo/url", "mojo/utils/string", "mojo/signup-forms/PagesSignupFormContainer", "mojo/api/brand-assets", "mojo/user"], function (array, declare, lang, request, string, mUrl, stringUtils, PagesSignupFormContainer, brandAssetsApi, user) {
  var MAX_NUM_PREC = 10;
  var MAX_NUM_CART = 3;
  var RAW_TYPE = "raw";
  var DEFAULT_TYPE = "default";
  var tokenRegex = /(\*\|\w+(?:\:[^\|]*)?\|\*)/;
  var blockTypes = {
    "PRODUCT_RECOMMENDATION": "PRODUCT_RECOMMENDATION",
    "ABANDONED_CART": "ABANDONED_CART",
    "ABANDONED_BROWSE": "ABANDONED_BROWSE",
    "PROMO_CODE": "PROMO_CODE",
    "PAGE": "PAGE",
    "PAYMENT_PRICE": "PAYMENT_PRICE",
    "WEB_VIDEO": "WEB_VIDEO"
  };
  var Token = declare(null, {
    constructor: function (type, data) {
      this.type = type;
      this.data = data;
      this.children = [];
      this.parent = null;
      this.params = {};
      if (type !== RAW_TYPE) {
        this.parseParams();
      }
    },
    parseParams: function () {
      var data = this.data.trim();
      // For merge tags that have a [] inside, we treat those as parameters
      if (data.substr(-1) !== "]" || data.indexOf("[") === -1) {
        return false;
      }

      // Look for the first [ as the bracket for parameters and put the rest away as the data.
      var bracketStartPos = data.indexOf("[");
      this.data = data.substr(0, bracketStartPos);

      // Remove first and last element which are the brackets.
      var params = data.substr(bracketStartPos + 1);
      params = params.substr(0, params.length - 1);
      array.forEach(params.split(","), lang.hitch(this, function (param) {
        var parts = param.split("=");
        var key = parts[0].trim();
        var val = parts.splice(-1).join("=").trim();
        // Only things that start with $ are valid params
        if (key[0] === "$") {
          this.params[key.substr(1)] = val;
        }
      }));
    },
    getData: function () {
      var params = [];
      for (var key in this.params) {
        if (this.params.hasOwnProperty(key)) {
          params.push("$" + key + "=" + this.params[key]);
        }
      }
      return this.data + (params.length ? " [" + params.join(",") + "]" : "");
    },
    append: function (token) {
      this.children.push(token);
      token.parent = this;
    }
  });
  var MergeTemplate = declare(null, {
    constructor: function () {
      this.current = null;
      this.blockStack = [];
      this.tokens = [];
      this.formConfig = null;
    },
    setFormConfig: function (config) {
      this.formConfig = config;
    },
    tokenize: function (html) {
      this.tokens = [];
      this.blockStack = [];
      this.current = null;
      array.forEach(html.split(tokenRegex), lang.hitch(this, function (token) {
        if (token.substr(0, 2) !== "*|" || token.substr(-2) !== "|*") {
          this.addToken(RAW_TYPE, token);
        } else if (token.indexOf(":") === -1) {
          this.addToken(DEFAULT_TYPE, token.substr(2, token.length - 4));
        } else {
          var parts = token.substr(2, token.length - 4).split(":");
          var type = parts[0];
          var data = parts.splice(-1).join(":");

          // convert blockType to Boolean for conditional check
          // this is fixing a linting issue of loose ineqaulity ("!=")
          var isBlockType = Boolean(blockTypes[type]);
          if (isBlockType) {
            this.pushBlock(type, data);
          } else if (type === "END") {
            if (blockTypes[data] !== null) {
              this.popBlock(type);
            } else {
              this.addToken(type, data);
            }
          } else {
            this.addToken(type, data);
          }
        }
      }));
    },
    addToken: function (type, data) {
      var token = new Token(type, data);
      if (this.current === null) {
        this.tokens.push(token);
      } else {
        this.current.append(token);
      }
      return token;
    },
    pushBlock: function (type, data) {
      var token = this.addToken(type, data);
      this.current = token;
      this.blockStack.push(token);
    },
    popBlock: function (type) {
      while (this.blockStack.length) {
        var popped = this.blockStack.pop();
        if (popped.type === type) {
          break;
        }
      }
      if (this.blockStack.length) {
        this.current = this.blockStack[this.blockStack.length - 1];
      } else {
        this.current = null;
      }
    },
    /**
     * Take the given HTML and tokenize merge tags and renders them according to functions
     * defined in this module.
     *
     * @param {string} html
     *
     * @returns {string}
     */
    render: function (html) {
      try {
        this.tokenize(html);
        return this.renderTokens(this.tokens);
      } catch (e) {
        console.error(e);
        return html;
      }
    },
    renderTokens: function (tokens, attributes) {
      var rendered = "";
      var self = this;
      array.forEach(tokens, function (token) {
        var value = "";
        var handleFunction = "handle" + stringUtils.capitalize(token.type.toLowerCase());
        if (typeof self[handleFunction] === "function") {
          value = self[handleFunction](token, attributes);
        } else {
          value = self.handleMissing(token);
        }
        rendered += value;
      });
      return rendered;
    },
    /**
     * Token that are not special handled will go through this method.
     *
     * @param {Token} token
     * @returns {string}
     */
    handleMissing: function (token) {
      return "*|" + token.type + ":" + token.getData() + "|*";
    },
    /**
     * Tokens that look like *|THIS|* are consider default tokens.
     *
     * @param {Token} token
     * @returns {string}
     */
    handleDefault: function (token) {
      return "*|" + token.data + "|*";
    },
    /**
     * These are the non-tokens tokens. The regular HTML pieces between merge tags
     *
     * @param {Token} token
     * @returns {string}
     */
    handleRaw: function (token) {
      return token.data;
    },
    /**
     *
     * @param {Token} token
     * @returns {string}
     */
    handleProduct_recommendation: function (token) {
      var total = +token.params.total;
      var startIndex = token.params.start_index ? +token.params.start_index : 0;
      if (!total) {
        total = MAX_NUM_PREC;
      }
      var endIndex = total + startIndex - 1;
      if (endIndex > MAX_NUM_PREC - 1) {
        endIndex = MAX_NUM_PREC - 1;
      }
      var value = "";
      for (var i = startIndex; i <= endIndex; i++) {
        var fileName = "placeholder-" + string.pad(i + 1, 2, "0") + ".png";
        value += this.renderTokens(token.children, {
          "image": "https://cdn-images.mailchimp.com/ecomm/products/" + fileName
        });
      }
      return value;
    },
    /**
     * @param {Token} token
     * @returns {string}
     */
    handleAbandoned_cart: function (token) {
      var total = +token.params.total;
      if (!total || total > MAX_NUM_CART) {
        total = MAX_NUM_CART;
      }
      var value = "";
      for (var i = 0; i < total; i++) {
        var fileName = "placeholder-" + string.pad(i + 1, 2, "0") + ".png";
        value += this.renderTokens(token.children, {
          "image": "https://cdn-images.mailchimp.com/ecomm/products/" + fileName
        });
      }
      return value;
    },
    /**
     * @param {Token} token
     * @returns {string}
     */
    handleAbandoned_browse: function (token) {
      // possibly allow more than 1 in the future...
      var total = 1;
      var value = "";
      for (var i = 0; i < total; ++i) {
        var fileName = "placeholder-" + string.pad(i + 1, 2, "0") + ".png";
        value += this.renderTokens(token.children, {
          "image": "https://cdn-images.mailchimp.com/ecomm/products/" + fileName
        });
      }
      return value;
    },
    /**
     * @param token
     * @param attributes
     * @returns {*}
     */
    handleProduct: function (token, attributes) {
      if (!attributes || token.parent === null) {
        return this.handleMissing(token);
      }
      if (token.data === "IMAGE_URL") {
        return attributes.image;
      }
      return "*|" + token.type + ":" + token.data + "|*";
    },
    /**
     * @param {Object} token for merge tag
     * @returns {String} value of promo code to render
     */
    handlePromo_code: function (token) {
      var value = "";

      // Check for promo code
      if (token.params.promo_code) {
        value = token.params.promo_code;
      }
      value += this.renderTokens(token.children);
      return value;
    },
    /**
     * @param {Object} token for merge tag
     * @returns {String} value of promo code to render
     */
    handlePayment_price: function (token) {
      // only return the children
      return this.renderTokens(token.children);
    },
    /**
     * @param {Object} token for merge tag
     * @returns {String} HTML for rendering form
     */
    handlePage: function (token) {
      var formConfig = this.formConfig || window.top && window.top.app && window.top.app.formConfig;
      // render form with style changes.
      // this will rerender for each save
      var pagesSignupFormContainer = new PagesSignupFormContainer({
        config: formConfig
      });
      return pagesSignupFormContainer.signupForm.toHTML();
    },
    /**
     * @param {Token} token - Token instance to render
     * @return {string} the brand logo merge tag value
     */
    handleBrand: function (token) {
      return brandAssetsApi.getBrandUrl();
    },
    /**
     * @return {string} the website logo merge tag value
     */
    handleWebsite: function () {
      var bootstrappedUser = user.getBootstrappedInstance();
      return bootstrappedUser.websiteLogoUrl;
    },
    /**
     * handleWeb_video will insert a video iframe for youtube or vimeo or an image (while in the editor)
     * @param {Object} token will have the parameters for
     * @returns {String} HTML as a string for an img element or iframe element
     */
    handleWeb_video: function (token) {
      var videoSrc;
      var imgAlt = token.params.imgAlt || "";
      var imgPath = token.params.imgPath || "";
      var imgProtocol = token.params.imgProtocol || "http";
      var imgWidth = token.params.imgWidth || "";
      switch (token.params.service) {
        case "youtube":
          videoSrc = "https://www.youtube.com/embed/" + token.params.id;
          break;
        case "vimeo":
          videoSrc = "https://player.vimeo.com/video/" + token.params.id;
          break;
        default:
          break;
      }
      if (imgPath) {
        return "<img alt=\"" + imgAlt + "\" src=\"" + imgProtocol + "://" + imgPath + "\" width=\"100%\" style=\"align-self: center; max-width:100%;\" class=\"mcnImage\"/>";
      }
      // return the video if for some reason an image path doesn't exist.
      if (videoSrc) {
        return "<iframe src=\"" + videoSrc + "\" webkitallowfullscreen mozallowfullscreen allowfullscreen style=\"position: absolute; top:0; left: 0; width: 100%; height: 100%;\" frameborder=\"0\"></iframe>";
      }
      return "";
    }
  });
  var instance = new MergeTemplate();
  return {
    setFormConfig: function (config) {
      instance.setFormConfig(config);
    },
    /**
     * @param {string} html - the HTML we want to parse for merge tokens
     * @returns {string}
     */
    render: function (html) {
      return instance.render(html);
    }
  };
});