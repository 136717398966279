define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/app/campaigns", "mojo/url", "mojo/context", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "dojo/text!./templates/SlatGroup.html"], function (declare, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, campaigns, murl, context, flags, dom, domClass, lang, on, I18nTranslation, templateString) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateString,
    name: "",
    loadButtonText: "Load More",
    isRendered: false,
    shouldHideGroupHeader: false,
    items: null,
    // A collection of Slat instances

    completedDownloadLink: murl.toUrl("/reports/export"),
    addItem: function (newItem) {
      if (this.items.filter(function (item) {
        return item.id === newItem.id;
      }).length === 0) {
        this.items.push(newItem);
        this.isRendered = false;
      }
    },
    toggleLoadButton: function (shouldShowButton) {
      domClass.toggle(this.node_loadButton, "hide", !shouldShowButton);
    },
    toggleDownloadLink: function (shouldShowLink) {
      domClass.toggle(this.node_downloadLink, "hide", !shouldShowLink);
    },
    setCount: function (count) {
      this.node_count.innerHTML = "(" + count + ")";
      domClass.toggle(this.node_count, "hide", !count);
    },
    renderItems: function () {
      var rows = document.createDocumentFragment();
      this.items.forEach(lang.hitch(this, function (item, i) {
        if (!item.isRendered) {
          rows.appendChild(item.domNode);
          item.isRendered = true;
        }
      }));
      dom.place(rows, this.node_list);
      this.node_count.innerHTML = this.items.length;
    },
    postCreate: function () {
      //Create new array for each instance
      this.items = [];
      //Bind load button
      on(this.node_loadButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this.onLoadMore(this);
      }));
      //Bind archive link and show/hide base on attribute
      domClass.toggle(this.node_archiveLink, "hide", !this.shouldShowArchiveLink);
      on(this.node_archiveLink, "click", campaigns.foldersArchive);

      // Hide the header container if the user on the reports page
      domClass.toggle(this.node_header, "hide", this.shouldHideGroupHeader);
    },
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.loadButtonText = translations.campaigns_load_more;
      }.bind(this));
    }
  });
});