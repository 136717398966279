define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dijit/_Widget", "dijit/_TemplatedMixin", "dojo/text!./templates/TextInput.html"], function (declare, domClass, on, utils, _Widget, _TemplatedMixin, tpl) {
  return declare([_Widget, _TemplatedMixin], {
    label: null,
    name: null,
    value: null,
    charLimit: 255,
    // based on findings db allows for 255 chars for majority of frontDoor text inputs
    length: null,
    templateString: tpl,
    constructor: function (properties) {
      this.label = properties.label;
      this.value = properties.value || "";
      this.name = properties.name;
      if (properties.charLimit < 255) {
        this.length = properties.charLimit;
      }
    },
    postCreate: function () {
      if (this.length) {
        this.showCharacterCount();
      }
    },
    showCharacterCount: function () {
      domClass.remove(this.charCounter, "display--none");
      on(this.input, "keyup", function () {
        utils.checkInputLength(this.input, this.charDisplay, this.length);
      }.bind(this));
    },
    focus: function () {
      this.input.focus();
    }
  });
});