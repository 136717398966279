define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/lib/logger", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, domClass, xhr, on, _base, mUrl, logger, EcsEvents, EcsTracking) {
  return declare([_base], {
    constructor: function () {
      this.inherited(arguments);
      this.acknowledgeMistier = this._acknowledgeMistier.bind(this);
    },
    onReady: function () {
      if (this.needs_retier === false) {
        window.location.href = mUrl.toUrl("/");
      } else {
        domClass.remove(this.$el.dvMainContent, "hide");
      }
      EcsTracking.track(EcsEvents.AccountAdjustPlanEventViewed);
      if (this.$el.dismissMistierButton) {
        on(this.$el.dismissMistierButton, "click", this.dismissMistierButton.bind(this));
      }
      if (this.$el.adjustPlanButton) {
        on(this.$el.adjustPlanButton, "click", this.adjustPlanButtonClick.bind(this));
      }
    },
    dismissMistierButton: function () {
      EcsTracking.track(EcsEvents.AccountKeepYourPlanClickEvent);
      xhr(mUrl.toUrl("/account/adjust-plan/set-has-acknowledged-overage", {
        location: location.pathname
      }), {
        handleAs: "json"
      });

      //Google Analytics
      var label = {
        location: "post_login",
        reason: "adjust_to_save",
        button_text: "keep_contact_tier"
      };
      logger.googleAnalytics.trackEvent("adjust_tier", "button_click", JSON.stringify(label));
      window.location.href = mUrl.toUrl("/");
    },
    _acknowledgeMistier: function () {
      xhr(mUrl.toUrl("/account/adjust-plan/set-has-acknowledged-overage", {
        old_plan_id: this.current_plan_id,
        new_plan_id: this.suggested_plan_id,
        location: location.pathname
      }), {
        handleAs: "json"
      });

      //Google Analytics
      var label = {
        location: "post_login",
        reason: "adjust_to_save",
        button_text: "adjust_contact_tier"
      };
      logger.googleAnalytics.trackEvent("adjust_tier", "button_click", JSON.stringify(label));
      window.location.href = mUrl.toUrl("/");
    },
    adjustPlanButtonClick: function () {
      EcsTracking.track(EcsEvents.AccountAdjustPlanClickEvent);
    }
  });
});