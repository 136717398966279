/**
 * Notification Bar Editor: (AKA Page Banner)
 * Another quasi-neapolitan editor. It uses the same behavior to open as an editor, but it's not really a content block editor.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Templated", "dijit/_Widget", "mojo/context", "mojo/lib/flags", "./_EditorMixin", "./_CKMixin", "./GlobalElementStyleEditor", "dojo/text!./templates/pageBannerEditor.html"], function (declare, lang, domClass, domConstruct, _Templated, _Widget, context, flags, _EditorMixin, _CKMixin, StyleEditor, template) {
  return declare([_Widget, _Templated, _EditorMixin, _CKMixin], {
    templateString: template,
    widgetsInTemplate: true,
    isPageTemplate: true,
    model: null,
    // maps to query selector in mojo/widgets/pages/OptOutLinkHandler.js
    optOutAttr: "data-page-banner-opt-out",
    optOutLink: " <a data-page-banner-opt-out href='#page-banner-opt-out'>Opt out of Mailchimp tracking on this site.</a>",
    postCreate: function (params) {
      this.inherited(arguments);
      this._toggleElements(this.model.get("enabled"));
      this._setupInputs();
      this._setupStyleEditor();
    },
    startup: function () {
      this.showCKEditor(this.ckeditorContainer, null, this.model.get("content"), lang.hitch(this, "publishReady"));
    },
    _toggleElements: function (isSelected) {
      if (isSelected) {
        domClass.remove(this.legalCopy, "hide");
        if (this.model.get("isMCTrackingEnabled")) {
          domClass.remove(this.optOutContainer, "hide");
        }
        if (domClass.contains(this.styleEditorContainer, "hide")) {
          domClass.remove(this.styleEditorContainer, "hide");
        }
        if (domClass.contains(this.contentSection, "hide")) {
          domClass.remove(this.contentSection, "hide");
        }
        if (!domClass.contains(this.pageBannerWarning, "hide")) {
          domClass.add(this.pageBannerWarning, "hide");
        }
      } else {
        domClass.add(this.legalCopy, "hide");
        domClass.add(this.optOutContainer, "hide");
        domClass.add(this.pageBannerWarningCopy, "hide");
        domClass.remove(this.pageBannerWarningNewCopy, "hide");
        if (domClass.contains(this.pageBannerWarning, "hide")) {
          domClass.remove(this.pageBannerWarning, "hide");
        }
        if (!domClass.contains(this.styleEditorContainer, "hide")) {
          domClass.add(this.styleEditorContainer, "hide");
        }
        if (!domClass.contains(this.contentSection, "hide")) {
          domClass.add(this.contentSection, "hide");
        }
      }
    },
    handleOptOutChange: function (checked) {
      if (checked) {
        this.addOptOutLink();
      } else {
        this.removeOptOutLink();
      }
    },
    addOptOutLink: function () {
      var contentDom = domConstruct.toDom(this.editor.getData());
      if (!this.hasOptOutLinks(contentDom)) {
        if (contentDom.lastElementChild && contentDom.lastElementChild.nodeName === "P") {
          // place opt-out link inline with last element
          contentDom.lastElementChild.innerHTML += this.optOutLink;
          this.editor.setData(this.getFragmentHtml(contentDom));
        } else {
          this.editor.setData(this.editor.getData() + this.optOutLink);
        }
      }
    },
    removeOptOutLink: function () {
      var contentDom = domConstruct.toDom(this.editor.getData());
      var linkNodes = this.getOptOutLinks(contentDom);
      if (linkNodes) {
        if ("innerHTML" in contentDom) {
          this.editor.setData("");
        } else {
          linkNodes.forEach(function (node) {
            node.parentNode.removeChild(node);
          });
          this.editor.setData(this.getFragmentHtml(contentDom));
        }
      }
    },
    getOptOutLinks: function (contentDom) {
      if (contentDom.hasAttribute && contentDom.hasAttribute(this.optOutAttr)) {
        // parent element is opt-out link
        var temp = document.createElement("div");
        temp.append(contentDom);
        // return node list
        return temp.childNodes;
      } else if (contentDom.querySelectorAll) {
        // query for element
        return contentDom.querySelectorAll("[" + this.optOutAttr + "]");
      }
      return null;
    },
    hasOptOutLinks: function (contentDom) {
      var links = this.getOptOutLinks(contentDom);
      return links && links.length > 0;
    },
    // innerHTML is unsupported on document fragments
    getFragmentHtml: function (documentFragment) {
      var temp = document.createElement("div");
      temp.append(documentFragment);
      return temp.innerHTML;
    },
    onToggleEnabled: function (enabled) {
      this.model.set("enabled", enabled);
      this._toggleElements(this.model.get("enabled"));
    },
    onCKChange: function () {
      var contentDom = domConstruct.toDom(this.editor.getData());
      this.optOutCheckbox.setChecked(this.hasOptOutLinks(contentDom));
      this.model.set("content", this.editor.getData());
    },
    _setupInputs: function () {
      this.pageBannerEnabledSwitch.set("value", this.model.get("enabled"));
    },
    save: function () {
      return this.onSave(this.model);
    },
    onSave: function () {},
    _onDone: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.onSave(this.model);
      this.done();
    },
    _onCancel: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.done();
    },
    onClose: function () {
      this.destroy();
    },
    _setupStyleEditor: function () {
      if (this.styleEditorContainer) {
        var styleEditor = this._getStyleEditor();
        styleEditor.startup();
        domConstruct.place(styleEditor.domNode, this.styleEditorContainer);
        this.watch("styles", lang.hitch(this, function () {
          styleEditor.updateInputs(this.block.styles);
        }));
      }
    },
    _getStyleEditor: function () {
      if (!this.styleEditor || this.styleEditor._destroyed) {
        var styles = lang.clone(this.model.styles);
        this.styleEditor = new StyleEditor({
          "styles": styles,
          "type": "Styles"
        });
        this.connect(this.styleEditor, "onChange", function (newStyle) {
          this.model.set("styles", newStyle);
        });
        this.connect(this.styleEditor, "onCancel", function (newStyle) {
          this.model.set("styles", newStyle);
          this._done();
        });
      }
      return this.styleEditor;
    }
  });
});