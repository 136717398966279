/**
 * Widget built to show a checklist's status and associated actions, specific to ads.
 * @module mojo/widgets/ads/checklist/status-bar/AdStatusBar
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/StatusBar", "mojo/widgets/ads/checklist/status-bar/AdStatusBarActions"], function (declare, _StatusBar, AdStatusBarActions) {
  // Constants match statuses set in AdInterface
  var STATUS_DRAFT = "draft";
  var STATUS_PENDING = "pending";
  var STATUS_SCHEDULED = "scheduled";
  var STATUS_ACTIVE = "active";
  var STATUS_PAUSED = "paused";
  var STATUS_REJECTED = "rejected";
  var STATUS_COMPLETED = "completed";
  var STATUS_REASON_BILLING = "billing";
  var STATUS_REASON_COMPLIANCE = "compliance";
  var STATUS_REASON_CONNECTED_SITE = "no_connected_site";

  // Custom statuses used here from _getStatusName()
  var STATUS_PARTIAL_REJECTED = "partialRejected";
  var STATUS_LAPSED_BILLING = "lapsedBilling";
  var STATUS_NO_CONNECTED_SITE = "noConnectedSite";
  var STATUS_COMPLIANCE = "compliance";
  var STATUS_CANCELED = "canceled";
  var STATUS_CANCELED_BY_FACEBOOK = "canceledByFacebook";
  var STATUS_HAS_MISSING_FACEBOOK_PERMISSIONS = "hasMissingFacebookPermissions";

  /**
   * Widget that is responsible for creating and updating the ads checklist status bar.
   */
  var AdStatusBar = declare([_StatusBar], {
    // Passed in through widget declaration
    ad: null,
    // Global vars for status bar template
    icon: {
      classes: "c-channelIcon c-channelIcon--ad"
    },
    /**
     * Create objects here so we can use our constants as keys. Thanks ES5... :/
     */
    _createBadgeOptions: function () {
      this.inherited(arguments);
      this.badgeOptions[STATUS_PARTIAL_REJECTED] = {
        type: "warning",
        label: "Some rejected"
      };
      this.badgeOptions[STATUS_LAPSED_BILLING] = {
        type: "error",
        label: "Lapsed billing"
      };
      this.badgeOptions[STATUS_NO_CONNECTED_SITE] = {
        type: "error",
        label: "No connected site"
      };
      this.badgeOptions[STATUS_COMPLIANCE] = {
        type: "error",
        label: "Compliance"
      };
      this.badgeOptions[STATUS_CANCELED] = {
        type: "error",
        label: "Canceled"
      };
      this.badgeOptions[STATUS_CANCELED_BY_FACEBOOK] = {
        type: "error",
        label: "Canceled"
      };
      this.badgeOptions[STATUS_HAS_MISSING_FACEBOOK_PERMISSIONS] = {
        type: "warning",
        label: "Missing Permissions"
      };
    },
    /**
     * Create options for which actions are shown for each status.
     */
    _createActionOptions: function () {
      this.actionOptions[STATUS_DRAFT] = [this.actions.finishLater, this.actions.submitAd];
      this.actionOptions[STATUS_PENDING] = [this.actions.endAd];
      this.actionOptions[STATUS_SCHEDULED] = [this.actions.endAd];
      this.actionOptions[STATUS_ACTIVE] = [this.actions.combo, this.actions.viewReport];
      this.actionOptions[STATUS_PAUSED] = [this.actions.resumeAd];
      this.actionOptions[STATUS_REJECTED] = [this.actions.endAd, this.actions.submitAd];
      this.actionOptions[STATUS_COMPLETED] = [this.actions.viewReport];
      this.actionOptions[STATUS_PARTIAL_REJECTED] = [this.actions.finishLater, this.actions.submitAd];
      this.actionOptions[STATUS_LAPSED_BILLING] = [this.actions.viewReport];
      this.actionOptions[STATUS_COMPLIANCE] = [this.actions.viewReport];
      this.actionOptions[STATUS_NO_CONNECTED_SITE] = [];
      this.actionOptions[STATUS_CANCELED] = [this.actions.replicateAd, this.actions.viewReport];
      this.actionOptions[STATUS_CANCELED_BY_FACEBOOK] = [this.actions.replicateAd, this.actions.viewReport];
      this.actionOptions[STATUS_HAS_MISSING_FACEBOOK_PERMISSIONS] = [this.actions.finishLater];
    },
    /**
     * Instantiate this status bar's custom action widget.
     */
    _createActions: function () {
      this.actions = new AdStatusBarActions({
        status: this.status,
        ad: this.ad
      });
    },
    /**
     * Return a clearer ad status so we can avoid using if/else's in a bunch
     * of different places. Statuses defined at the top of this widget.
     *
     * @returns {string} The true status of the ad.
     */
    _getStatusName: function () {
      switch (this.status.name) {
        case STATUS_ACTIVE:
          // Partial rejection, some ads running
          if (this.ad.needsAttention) {
            return STATUS_PARTIAL_REJECTED;
          }
          // All approved and running
          return this.status.name;
        case STATUS_PAUSED:
          // Paused until user fixes billing information
          if (this.status.reason === STATUS_REASON_BILLING) {
            return STATUS_LAPSED_BILLING;
          }
          if (this.status.reason === STATUS_REASON_CONNECTED_SITE) {
            return STATUS_NO_CONNECTED_SITE;
          }
          // Paused for no clear reason
          return this.status.name;
        default:
          // Was canceled by Facebook (only can be true for Facebook ads)
          if (this.ad.hasBeenCanceledByFacebook) {
            return STATUS_CANCELED_BY_FACEBOOK;

            // Was otherwise canceled
          } else if (this.ad.hasBeenCanceled) {
            return STATUS_CANCELED;
            // User has missing facebook permissions
          } else if (this.ad.hasMissingFacebookPermissions) {
            return STATUS_HAS_MISSING_FACEBOOK_PERMISSIONS;
          }

          // Status returned from back-end is enough
          return this.status.name;
      }
    }
  });
  var adStatusBar = null;
  var module = {
    /**
     * If there's already an existing status bar, destroy it
     * so the template can be refreshed.
     *
     * @param {Object} params - Any data required to setup the status bar.
     * @returns {Object} - This status bar widget.
     */
    _getAdStatusBar: function (params) {
      if (adStatusBar) {
        adStatusBar.destroyRecursive();
      }
      adStatusBar = new AdStatusBar(params);
      adStatusBar.startup();
      return adStatusBar;
    },
    /**
     * If there's already an existing status bar, destroy it
     * so the template can be refreshed.
     *
     * @param {Object} params - Any data required to setup the status bar.
     * @param {Node} domNode - Where the widget will be placed.
     *
     * @returns {Object} - This status bar widget.
     */
    render: function (params, domNode) {
      return this._getAdStatusBar(params).render(params, domNode);
    }
  };
  return module;
});