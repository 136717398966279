define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "mojo/widgets/OptGroupLabel", "mojo/widgets/input/_WithLabelMixin"], function (declare, array, lang, domConstruct, domClass, Select, OptGroupLabel, _WithLabelMixin) {
  var SAFEFONTS = [{
    label: "Arial",
    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif"
  }, {
    label: "Comic Sans",
    value: "'Comic Sans MS', 'Marker Felt-Thin', Arial, sans-serif"
  }, {
    label: "Courier New",
    value: "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace"
  }, {
    label: "Georgia",
    value: "Georgia, Times, 'Times New Roman', serif"
  }, {
    label: "Helvetica",
    value: "'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif"
  }, {
    label: "Lucida",
    value: "'Lucida Sans Unicode', 'Lucida Grande', sans-serif"
  }, {
    label: "Tahoma",
    value: "Tahoma, Verdana, Segoe, sans-serif"
  }, {
    label: "Times New Roman",
    value: "'Times New Roman', Times, Baskerville, Georgia, serif"
  }, {
    label: "Trebuchet MS",
    value: "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif"
  }, {
    label: "Verdana",
    value: "Verdana, Geneva, sans-serif"
  }];
  var WEBFONTS = [{
    label: "Arvo",
    value: "'Arvo', 'Courier', Georgia, serif"
  }, {
    label: "Lato",
    value: "'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Lora",
    value: "'Lora', Georgia, 'Times New Roman', serif"
  }, {
    label: "Merriweather",
    value: "'Merriweather', Georgia, 'Times New Roman', serif"
  }, {
    label: "Merriweather Sans",
    value: "'Merriweather Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Noticia Text",
    value: "'Noticia Text', Georgia, 'Times New Roman', serif"
  }, {
    label: "Open Sans",
    value: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Playfair Display",
    value: "'Playfair Display', Georgia, 'Times New Roman', serif"
  }, {
    label: "Roboto",
    value: "'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Source Sans Pro",
    value: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }];
  var EXTENDED_WEBFONTS = [{
    label: "Archivo Narrow",
    value: "'Archivo Narrow', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Caveat",
    value: "'Caveat', Arial, sans-serif"
  }, {
    label: "Dancing Script",
    value: "'Dancing Script', Arial, sans-serif"
  }, {
    label: "Josefin Slab",
    value: "'Josefin Slab', Georgia, Times New Roman, serif"
  }, {
    label: "Karla",
    value: "'Karla', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Montserrat",
    value: "'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Nunito",
    value: "'Nunito', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "PT Serif",
    value: "'PT Serif', Courier, Georgia, serif"
  }, {
    label: "Raleway",
    value: "'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif"
  }, {
    label: "Space Mono",
    value: "'Space Mono', Courier, Georgia, serif"
  }];
  return declare([Select, _WithLabelMixin], {
    label: "Font Family",
    webfont_enable: false,
    maxHeight: -1,
    "class": "!margin-bottom--lv0",
    constructor: function (props) {
      // Clone all options since we're mutating them
      this.options = lang.clone(SAFEFONTS);

      // Add webfonts and inject option group labels if necessary
      if (props.webfont_enable) {
        if (props.useExtendedWebFonts) {
          var webFonts = lang.clone(WEBFONTS).concat(lang.clone(EXTENDED_WEBFONTS));
          webFonts.sort(function (a, b) {
            return a.label < b.label ? -1 : 1;
          });
          this.options = this.options.concat(webFonts);
        } else {
          this.options = this.options.concat(lang.clone(WEBFONTS));
        }
        this.options.unshift({
          'optGroup': true,
          'optGroupLabel': 'Standard web fonts',
          'label': '',
          value: ''
        });
        this.options.splice(SAFEFONTS.length + 1, 0, {
          'optGroup': true,
          'optGroupLabel': 'Custom web fonts',
          'label': '',
          value: ''
        });
      }

      // Add necessary styling to preview the fonts within the select
      array.forEach(this.options, function (option) {
        if (!option.optGroup) {
          option.label = '<span class="mojoFontFamilyInputLabel" style="font-family: ' + option.value + '" >' + option.label + '</span>';
        }
      });
      if (props.isOptional) {
        this.options.unshift({
          "label": "Not specified",
          value: ""
        });
      }
    },
    startup: function () {
      this.inherited(arguments);
      this._toggleWebFontWarning();
    },
    _setValueAttr: function (value) {
      var realValue = value;
      if (this.options[realValue] == null) {
        array.some(this.options, function (opts) {
          // If it matches the label, let's use that stack
          if (opts.label.indexOf(realValue) >= 0) {
            realValue = opts.value;
            return true;
          }
          if (opts.value.replace('"', "").indexOf(realValue) == 0) {
            realValue = opts.value;
            return true;
          } else {
            return false;
          }
        });
      }
      this.inherited(arguments, [realValue]);
      this._toggleWebFontWarning();
    },
    _fillContent: function () {
      this.inherited(arguments);

      // Drop a class on the dropdown widget so that option items indent correctly
      domClass.toggle(this.dropDown.domNode, 'dijitSelectWithOptionGroup', this.webfont_enable);
    },
    _getMenuItemForOption: function (option) {
      // Load a different template for option group labels
      if (option.optGroup) {
        return new OptGroupLabel({
          label: option.optGroupLabel
        });
      }
      return this.inherited(arguments);
    },
    _toggleWebFontWarning: function () {
      // We should only do this when the widget is fully set up.
      // check Neapolitan to see if the app is a landing page "isPageTemplate"
      // only show the warning for email templates
      if (this._created && this.webfont_enable && !window.app.isPageTemplate) {
        var value = this.value;
        var isWebFont = WEBFONTS.some(function (webfont) {
          return webfont.value === value;
        });
        if (!this.webfontWarning) {
          var msg = "<p class='font-weight--bold small-meta dim-el'>If an email client <a href='https://mailchimp.com/help/limitations-of-html-email/' target='_blank'>doesn't support web fonts</a>, we'll use a similar standard font instead.</p>";
          this.webfontWarning = domConstruct.place(msg, this.domNode, "after");
        }
        domClass.toggle(this.webfontWarning, "hide", !isWebFont);
      }
    }
  });
});