/**
 * A widget that represents a Zip Code input for our segmentation UI.
 * Adds formatting to the text, constraining it to only 5 numerical digits.
 * Uses a native text input box, but adds additional functions to make it easier to use for segmentation.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_SegmentInputMixin", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/maps", "mojo/widgets/contact-profile/GeolocationHelpTip", "dijit/Dialog", "dijit/focus", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, _WidgetBase, domConstruct, domAttr, lang, array, _SegmentInputMixin, user, flags, logger, maps, GeolocationHelpTip, Dialog, focusUtil, I18nTranslation, context) {
  var blankText = "blank";
  var validateLocationText = "Validate Location";
  var aboutGeoLocation = "about geolocation data";
  var selectLocation = "Select a location:";
  var useLocation = "Use This Location";
  return declare([_WidgetBase, _SegmentInputMixin], {
    value: "",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        validateLocationText = I18nTranslation.translate("audience_management_segment_builder_predicted_location_validateButtonText");
        selectLocation = I18nTranslation.translate("audience_management_segment_builder_choose_location_text");
        useLocation = I18nTranslation.translate("audience_management_segment_builder_use_this_location_button_text");
        aboutGeoLocation = I18nTranslation.translate("audience_management_segment_builder_about_geolocation_text");
        blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    buildRendering: function () {
      maps.loadCode();
      var value = this.value;
      this.domNode = domConstruct.create("div", {
        className: "ipgeo-distance-select-container condition-value"
      });
      this.inputNode = domConstruct.create("input", {
        type: "text",
        id: "segment-" + this.configs.listId + "-" + this.id + "-address"
      }, this.domNode);
      domConstruct.create("a", {
        className: "geo-validate-location button-small",
        textContent: validateLocationText,
        onclick: function () {
          maps.addressSearch("segment-" + this.configs.listId + "-" + this.id, null);
        }.bind(this)
      }, this.domNode);
      if (this.helpTip) {
        this.helpTip.destroy();
        this.helpTip = null;
      }
      this.helpTip = new GeolocationHelpTip({
        label: aboutGeoLocation
      });
      this.helpTip.startup();
      this.helpTip.placeAt(this.domNode);
      if (this.mapModal) {
        this.mapModal.destroy();
        this.mapModal = null;
      }
      this.mapModal = new Dialog({
        title: selectLocation,
        id: "segment-" + this.configs.listId + "-" + this.id + "-mapdialog",
        className: "geo-map-modal",
        content: domConstruct.create("div", {
          className: "dijitDialogPaneContentArea",
          innerHTML: "<div id=\"segment-" + this.configs.listId + "-" + this.id + "-map\" style=\"width:500px;height:300px;\"></div>" + "<div class=\"dijitDialogPaneActionBar\">" + "<a onclick=\"dijit.byId('segment-" + this.configs.listId + "-" + this.id + "-mapdialog').hide();\" class=\"button p0\">" + useLocation + "</a>" + "</div>"
        }),
        onHide: function () {
          this.onValueChange();
          focusUtil.focus(this.inputNode);
        }.bind(this)
      });
      this.hiddenInputs = {};
      ["lat", "lng", "city", "region", "cc"].forEach(function (type) {
        this.hiddenInputs[type] = domConstruct.create("input", {
          type: "hidden",
          id: "segment-" + this.configs.listId + "-" + this.id + "-" + type
        }, this.domNode);
      }.bind(this));
      if (value) {
        this.inputNode.value = value;
      }
      this.set("value", this.inputNode.value);
      this.on("change", lang.hitch(this, "onValueChange"));
    },
    getDisplayHTML: function () {
      return this.value ? this.value : "<span class=\"dim6\">(" + blankText + ")</span>";
    },
    getValue: function () {
      return this.value;
    },
    getHiddenValues: function () {
      var hiddenValues = {};
      Object.entries(this.hiddenInputs).forEach(function (hiddenInput) {
        if (["lat", "lng"].includes(hiddenInput[0])) {
          hiddenValues[hiddenInput[0]] = parseFloat(hiddenInput[1].value);
        } else {
          hiddenValues[hiddenInput[0]] = hiddenInput[1].value;
        }
      });
      return hiddenValues;
    },
    setHiddenValues: function (ast) {
      Object.entries(this.hiddenInputs).forEach(function (hiddenInput) {
        if (ast[hiddenInput[0]]) {
          hiddenInput[1].value = ast[hiddenInput[0]];
        }
      });
    },
    setValue: function (value) {
      this.inputNode.value = value;
      this.set("value", value);
    },
    // Publish a Segment Engaged event to the Events Pipeline
    _logToEventsPipeline: function () {
      var sharedEventProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        object: "segment",
        object_detail: "create_segment",
        action: "engaged",
        ui_object: "drop_down",
        ui_object_detail: "segment_conditions",
        ui_action: "clicked",
        ui_access_point: "form_segment",
        segmentation_method: "advanced",
        condition_field: null
      };
      var contactTableProperties = Object.assign({
        scope_area: "contacts",
        screen: "lists/members"
      }, sharedEventProperties);
      var emailChecklistProperties = Object.assign({
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit"
      }, sharedEventProperties);

      // If event is from the Email Checklist page
      if (window.location.pathname.startsWith("/i/campaigns/edit")) {
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        var campaign_id = parseInt(new URLSearchParams(window.location.search).get("id"), 10);
        // Add campaign_id to email checklist properties
        emailChecklistProperties.campaign_id = campaign_id;
        logger.eventsPipeline.publish("segment:engaged", emailChecklistProperties);
        // If event is from the Contact Table page
      } else if (window.location.pathname.startsWith("/i/lists/members")) {
        logger.eventsPipeline.publish("segment:engaged", contactTableProperties);
      }
    },
    onValueChange: function () {
      this._updateValue();
      this._logToEventsPipeline();
    },
    _updateValue: function () {
      this.set("value", this.inputNode.value);
    },
    destroy: function () {
      this.destroyDescendants();
      this.inherited(arguments);
    }
  });
});