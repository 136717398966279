define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./Extension.html", "dojo/text!./DisconnectDialogBody.html", "dojo/text!./DisconnectDialogActionBar.html"], function (declare, lang, domAttr, domClass, on, query, xhr, Dialog, _Widget, _TemplatedMixin, _WidgetsInTemplateMixin, templateStr, disconnectDialogBodyTemplateStr, disconnectDialogActionBarTemplateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: templateStr,
    // attach points
    connectionButtonNode: null,
    connectionStatusTextNode: null,
    termsNode: null,
    viewCampaignsButtonNode: null,
    // constructor
    isInstalled: null,
    installUrl: null,
    uninstallUrl: null,
    inProgressNode: null,
    platformIdentifier: null,
    installedAt: null,
    // a date string in the user's timezone
    disconnectWarning: null,
    // dialog optionally created during post-create
    disconnectDialog: null,
    postCreate: function () {
      if (this.isInstalled === true) {
        this._displayConnectionStatusWithText("Connected on: " + this.installedAt);
        this._hideTerms();
        this._displayViewCampaignsButton();
        this._prepareDisconnectButton();
      } else {
        this._displayTerms();
        this._prepareConnectButton();
      }
    },
    _prepareConnectButton: function () {
      this._displayConnectionButtonWithText("Connect", true);
      this.own(on.once(this.connectionButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._displayWaitingState("Waiting for connection");
        this._hideConnectionStatus();
        this._handlePopupAuth();
      })));
    },
    /**
     * Opens a new window for the user to go through the extension auth process.
     * This function adds a handler to receive messages in the format defined by
     * /extensions/install-complete in order to know when the auth process has
     * succeeded or failed. This function also begins polling for when the window
     * is closed so that we can identify abandoned auth flows.
     */
    _handlePopupAuth: function () {
      var win = window.open(this.installUrl, this.platformIdentifier);
      win.focus();
      var handleMessage = function (e) {
        if (e.origin !== window.location.origin) {
          return;
        }
        if (typeof e.data === "string") {
          return;
        }
        if (e.data.source !== "extensions-auth") {
          return;
        }
        if (e.data.payload.success) {
          //if a redirect url is passed redirect to it
          var urlParams = new URLSearchParams(window.location.search);
          var redirectUrl = urlParams.get("redirect");
          if (redirectUrl) {
            window.location = redirectUrl;
            return;
          }
          // if not reload the current page
          window.location.reload();
        }
      };
      window.addEventListener("message", handleMessage);
      var timerId = setInterval(lang.hitch(this, function handleAuthWindowClose() {
        if (win.closed) {
          clearInterval(timerId);
          window.removeEventListener("message", handleMessage);
          this._hideInProgressNode();
          this._prepareConnectButton();
          this._displayConnectionStatusWithText("Connection was not completed. Try again?", true);
        }
      }), 5000);

      // Destruct handlers when the user navigates away from the Extension's instructions page
      window.addEventListener("hashchange", function destructHandlers() {
        clearInterval(timerId);
        window.removeEventListener("message", handleMessage);
      }, {
        once: true
      });
    },
    _prepareDisconnectButton: function () {
      this._displayConnectionButtonWithText("Disconnect", false);
      if (this.disconnectWarning && this.disconnectWarning.header && this.disconnectWarning.body) {
        this.disconnectDialog = new Dialog({
          title: this.disconnectWarning.header,
          content: disconnectDialogBodyTemplateStr,
          actionBarTemplate: disconnectDialogActionBarTemplateStr,
          onHide: lang.hitch(this, function () {
            this._hideWaitingState("Disconnect");
          })
        });

        // populate dialog content
        query(".disconnect-warning-content-area", this.disconnectDialog.domNode).forEach(lang.hitch(this, function (node) {
          node.innerText = this.disconnectWarning.body;
        }));

        // wire up the dialog disconnect button
        query(".confirm-disconnect", this.disconnectDialog.domNode).on("click", lang.hitch(this, function (e) {
          e.preventDefault();
          query(".confirm-disconnect").forEach(lang.hitch(this, function (node) {
            this._setButtonNodeToWaiting(node);
          }));
          xhr.get(this.uninstallUrl, {
            "handleAs": "json"
          }).then(function (data) {
            if (data && data.success === true) {
              window.location.reload();
            }
          });
        }));

        // wire up the dialog cancel button
        query(".cancel", this.disconnectDialog.domNode).on("click", lang.hitch(this, function (e) {
          e.preventDefault();
          this._hideWaitingState("Disconnect");
          this.disconnectDialog.hide();
        }));

        // wire up the non-dialog disconnect button (to open the disconnect dialog)
        on(this.connectionButtonNode, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          this._displayWaitingState("Disconnecting");
          this.disconnectDialog.show();
        }));
      } else {
        this.own(on.once(this.connectionButtonNode, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          this._displayWaitingState("Disconnecting");
          xhr.get(this.uninstallUrl, {
            "handleAs": "json"
          }).then(function (data) {
            if (data && data.success === true) {
              window.location.reload();
            }
          });
        })));
      }
    },
    _displayConnectionButtonWithText: function (buttonText, isPrimary) {
      domClass.remove(this.connectionButtonNode);
      domClass.add(this.connectionButtonNode, "button");
      if (isPrimary === true) {
        domClass.add(this.connectionButtonNode, "p1");
      }
      this.connectionButtonNode.innerText = buttonText;
    },
    _displayWaitingState: function (buttonText) {
      domClass.remove(this.inProgressNode, "display--none");
      this._setButtonNodeToWaiting(this.connectionButtonNode);
      domClass.remove(this.connectionButtonNode, "p1");
      this.connectionButtonNode.innerText = buttonText;
    },
    _hideWaitingState: function (buttonText) {
      this._hideInProgressNode();
      this._removeWaitingStateFromButtonNode(this.connectionButtonNode);
      domClass.add(this.connectionButtonNode, "p1");
      this.connectionButtonNode.innerText = buttonText;
    },
    _hideInProgressNode: function () {
      domClass.add(this.inProgressNode, "display--none");
    },
    _displayConnectionStatusWithText: function (statusText, isError) {
      if (isError === true) {
        domClass.add(this.connectionStatusTextNode, "textcolor--error");
      }
      domClass.remove(this.connectionStatusTextNode, "hide");
      this.connectionStatusTextNode.innerText = statusText;
    },
    _hideConnectionStatus: function () {
      domClass.add(this.connectionStatusTextNode, "hide");
    },
    _displayTerms: function () {
      domClass.remove(this.termsNode, "hide");
    },
    _hideTerms: function () {
      domClass.add(this.termsNode, "hide");
    },
    _displayViewCampaignsButton: function () {
      domClass.remove(this.viewCampaignsButtonNode, "hide");
    },
    _setButtonNodeToWaiting: function (buttonNode) {
      domClass.add(buttonNode, "button-loading disabled");
    },
    _removeWaitingStateFromButtonNode: function (buttonNode) {
      domClass.remove(buttonNode, "button-loading disabled");
    }
  });
});