/**
 * variate setup is different than the rest of the campaign setup, but share similar options.
 * This extends the original, but run its own sub-set of init callbacks.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./setup", "mojo/app/wizard", "dijit/registry", "mojo/utils", "dojo/NodeList-traverse"], function (declare, query, domClass, array, lang, on, setup, wizard, registry, utils) {
  function isUniqueArray(arrayname) {
    return arrayname.every(function (item, pos, me) {
      return me.indexOf(item) === pos || me.indexOf(item) < 0;
    });
  }
  return declare([setup], {
    onReady: function () {
      this.initPersonalizeTo();
      this.initGoogleAnalytics();
      this.initClickTale();
      this.initConversationOptions();
      wizard.repositionFlashBlock();
      this.checkSimilarItems();

      // Check on load
      this._checkSubjectDupe();
      this._checkFromDupe();
      this._checkSendTimeDupe();
    },
    checkSimilarItems: function () {
      var self = this;

      // SUBJECT LINE
      ///////////////
      if (this.$group.subjectLine) {
        var subjectGroup = this.$group.subjectLine;
        array.forEach(subjectGroup, function (subjectField) {
          on(subjectField, "keyup", lang.hitch(self, "_checkSubjectDupe"));
        });
      }

      // FROM NAME
      ////////////
      if (this.$group.fromName) {
        var fromGroup = this.$group.fromName;
        array.forEach(fromGroup, function (fromField) {
          on(fromField, "change", lang.hitch(self, "_checkFromDupe"));
        });
      }

      // SEND TIME
      ////////////
      if (this.$group.sendTime) {
        var sendGroup = this.$group.sendTime;
        array.forEach(sendGroup, function (sendField) {
          var wdgt = registry.byNode(sendField);
          on(wdgt, "change", lang.hitch(self, "_checkSendTimeDupe"));
        });
      }
    },
    _checkSubjectDupe: function () {
      var subjectGroup = this.$group.subjectLine;
      if (subjectGroup) {
        var subjects = array.map(subjectGroup, function (item) {
          return item["value"];
        });
        domClass.toggle(this.$el.subjectMessage, "hide", this._isNonEmpty(subjects));
      }
    },
    _checkFromDupe: function () {
      var fromGroup = this.$group.fromName;
      if (fromGroup) {
        var names = array.map(fromGroup, function (item) {
          return item["value"];
        });
        domClass.toggle(this.$el.fromMessage, "hide", this._isNonEmpty(names));
      }
    },
    _checkSendTimeDupe: function () {
      var sendGroup = this.$group.sendTime;
      if (sendGroup) {
        var sends = array.map(sendGroup, function (item) {
          return registry.byNode(item).value;
        });
        domClass.toggle(this.$el.sendMessage, "hide", this._isNonEmpty(sends));
      }
    },
    _isNonEmpty: function (values) {
      var nonEmpty = array.filter(values, function (item) {
        return item !== null && item !== "";
      });
      return isUniqueArray(nonEmpty);
    }
  });
});