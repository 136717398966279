define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/bridge", "mojo/user", "dojo/text!./templates/PaywallUpgradeButton.html"], function (declare, _WidgetBase, _TemplateMixin, bridge, user, template) {
  return declare([_WidgetBase, _TemplateMixin], {
    templateString: template,
    buttonText: "",
    buttonType: "",
    clearMargin: false,
    containerClass: "",
    disableRefreshForEditorUpgrade: false,
    redirect: "",
    featureName: "",
    featureContext: "",
    fullWidth: false,
    gaTracking: undefined,
    onComplete: undefined,
    onCancel: undefined,
    // TODO: Once the MARTA project is rolled out, and this is no longer used
    // we'll want to remove this prop from this file
    // and from /web/js/src/Main/Checkout/UpgradeMoment/Plan/index.js
    fixedGATrackingCallback: undefined,
    // NOTE: this should only be used if the PlanSelector needs to be opened
    // from a button that is outside of the React App (when the Left Navigation is not present)
    // If using MARTA for tracking, the PlanSelectorCTA Dojo component should be used
    martaID: null,
    suggestedPlan: undefined,
    suggestedPlanContent: undefined,
    location: undefined,
    postCreate: function () {
      this._renderReactUpgradeMomentButton();
    },
    _renderReactUpgradeMomentButton: function () {
      bridge("paywall").then(function (modules) {
        var props = {
          allowDowngrades: this.allowDowngrades,
          buttonType: this.buttonType || "important",
          children: this.buttonText || "Upgrade Now",
          clearMargin: this.clearMargin,
          disabled: !user.hasRole("manager"),
          disableRefreshForEditorUpgrade: this.disableRefreshForEditorUpgrade,
          fullWidth: this.fullWidth,
          gaTracking: this.gaTracking,
          fixedGATrackingCallback: this.fixedGATrackingCallback,
          martaID: this.martaID,
          location: this.location,
          modalProps: {
            featureName: this.featureName,
            featureContext: this.featureContext,
            redirectUrl: this.redirect,
            onComplete: this.onComplete,
            onCancel: this.onCancel,
            shouldSkipPlanSelector: !!this.skipToCheckout,
            pricingPlanId: this.pricingPlanId,
            suggestedPlan: this.suggestedPlan,
            suggestedPlanContent: this.suggestedPlanContent
          }
        };
        this.unmountUpgradeMomentButton = modules.mountUpgradeMomentButton(this.buttonContainer, props);
      }.bind(this), function () {
        //TODO: Log error
        console.error("Failed to load Bridge");
        this._unmountReactBridges();
      }.bind(this));
    },
    _unmountReactBridges: function () {
      if (this.unmountUpgradeMomentButton) {
        this.unmountUpgradeMomentButton = null;
      }
    }
  });
});