define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/some", "vendor/lodash-amd/find", "./PresendCheck"], function (declare, some, find, PresendCheck) {
  var PresendChecksCollection = declare(null, {
    /**
     * @param {PresendCheck[]} listOfPresendChecks An array of PresendCheck instances
     */
    constructor: function (listOfPresendChecks) {
      this.presendChecks = listOfPresendChecks.map(function (jsonPresendCheck) {
        return new PresendCheck(jsonPresendCheck);
      });
    },
    hasCheckWithCode: function (code) {
      return some(this.presendChecks, {
        code: code
      });
    },
    getCheckWithCode: function (code) {
      return find(this.presendChecks, {
        code: code
      });
    }
  });
  return PresendChecksCollection;
});