define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/Previewer", "dojo/text!./templates/PreviewTemplateButton.html"], function (declare, domConstruct, _WidgetBase, _TemplatedMixin, Previewer, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    buttonText: "Click to Preview",
    buttonClass: "c-choiceCard_revealed opacity-0 button p1 full-width margin--lv2",
    containerClass: "flex position--absolute justify-content--center align-items--center width--100 min-height--100",
    previewId: null,
    previewType: null,
    previewTitle: null,
    showUpgradeMoment: null,
    showPreview: function () {
      if (!this.previewer) {
        this.previewer = new Previewer({
          "previewId": this.previewId,
          "previewType": this.previewType,
          "titleText": this.previewTitle,
          "showUpgradeMoment": this.showUpgradeMoment,
          "inboxPreviewEnabled": false
        });
      } else {
        this.previewer.previewId = this.previewId;
        this.previewer.previewType = this.previewType;
        this.previewer.titleText = this.previewTitle;
        this.previewer.showUpgradeMoment = this.showUpgradeMoment;
      }
      this.previewer.show();
    }
  });
});