define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo/text!./templates/ImageGroupEditor/editor.html", "dojo/text!./templates/ImageGroupEditor/groupIcons.html", "dojox/dtl", "dojox/dtl/Context", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "mojo/neapolitan/MaxWidthRestriction", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, lang, _Base, tmpl, iconTmpl, dtl, dtlContext, query, on, array, domAttr, domConstruct, user, flags, MaxWidthRestriction, _DynamicContentMixin) {
  return declare([_Base, _DynamicContentMixin], {
    type: "imageGroup",
    templateString: tmpl,
    outerPadding: 9,
    groupLayouts: [[[1]], [[2], [1, 1]], [[1, 2], [2, 1], [1, 1, 1]], [[2, 2], [1, 2, 1]], [[1, 2, 2], [2, 1, 2], [2, 2, 1]]],
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      this._imagesTotal = this.block.images.length;
      this.sync("images", this.imageList, "images");
      this.own(on(this.layoutSelector, ".mojoImageGroupLayout:click", function () {
        self._selectLayout(this);
      }));
      this.connect(this.imageList, "onChange", "updateImages");
      this.watch("images", lang.hitch(this, function () {
        if (this._imagesTotal !== this.block.images.length) {
          this.block.set("selectedLayout", this.groupLayouts[this.block.images.length - 1][0]);
          this._renderLayoutIcons();
          this._imagesTotal = this.block.images.length;
        }
      }));
      this.watch("selectedLayout", lang.hitch(this, function () {
        this._renderLayoutIcons();
      }));
      this.watch("_uploading", lang.hitch(this, function () {
        this.imageList.toggleUploadMessage(this.block._uploading);
      }));
      this._renderLayoutIcons();
      this._addWidthChecks();
      this._addAspectRatioChecks();
    },
    _renderLayoutIcons: function () {
      var iconLayouts = [];
      /**
       * creates an array of arrays with empty content since I can't have a numeric loop in django templates ;(
       * ex. [1,2] => [[''],['','']]
       */
      array.forEach(this.getLayoutGroup(), lang.hitch(this, function (layout) {
        var layoutIcons = [];

        // Find the currently selected layout. If the group layout is not available anymore, it won't be an issue.
        var selected = this.block.selectedLayout.length == layout.length;
        array.forEach(layout, lang.hitch(this, function (numPerRow, index) {
          if (selected && this.block.selectedLayout[index] !== numPerRow) {
            selected = false;
          }
          var rowLayout = [];
          for (var i = 0; i < numPerRow; i++) {
            rowLayout.push("");
          }
          layoutIcons.push(rowLayout);
        }));
        iconLayouts.push({
          layout: layoutIcons,
          selected: selected
        });
      }));
      var iconHtml = this.getIconTemplate().render(new dtlContext({
        layouts: iconLayouts
      }));
      domConstruct.place(iconHtml, this.layoutSelector, "only");
    },
    getLayoutGroup: function () {
      if (this.block.images.length > 1) {
        return this.groupLayouts[this.block.images.length - 1];
      }
      return this.groupLayouts[0];
    },
    _selectLayout: function (layoutIcon) {
      var layoutIndex = domAttr.get(layoutIcon, "data-layout");
      this.block.set("selectedLayout", this.groupLayouts[this.block.images.length - 1][layoutIndex]);
      this._addAspectRatioChecks();
      this._addWidthChecks();
      this.block.save();
    },
    _getCalculatedContainerWidth: function (numPerRow) {
      if (numPerRow == 2) {
        return Math.floor(this.block.getContainerWidth() / 2 - this.outerPadding * 4);
      }
      return Math.floor(this.block.getContainerWidth() - this.outerPadding * 4);
    },
    /**
     * Add a minimum and maximum width restrictions to warn the user of images that are
     * way too small and images that are too big for email.
     *
     * @private
     */
    _addWidthChecks: function () {
      var imageIndex = 0;
      array.forEach(this.block.selectedLayout, lang.hitch(this, function (numPerRow) {
        var imageContainerWidth = this._getCalculatedContainerWidth(numPerRow);
        var rows = this.imageList.imageRows.slice(imageIndex, imageIndex + numPerRow);
        var isMobile = Boolean(window.app && window.app.nativeUtils && window.app.nativeUtils.getNativeObject());
        array.forEach(rows, function (row) {
          row.addMinWidthRestriction(imageContainerWidth, "Oops, this image is too small for this space. Please upload a larger version that is at least 600px wide.");
          MaxWidthRestriction.addTo(row);
        });
        imageIndex += numPerRow;
      }));
    },
    /**
     * Go through all the image rows and update the aspect ratios based on the current image group layout
     *
     * @private
     */
    _addAspectRatioChecks: function () {
      var imageIndex = 0;
      array.forEach(this.block.selectedLayout, lang.hitch(this, function (numPerRow) {
        var imageContainerWidth = this._getCalculatedContainerWidth(numPerRow);
        var rowImages = this.block.images.slice(imageIndex, imageIndex + numPerRow);
        var minHeight = this._getShortestHeight(imageContainerWidth, rowImages);
        var rows = this.imageList.imageRows.slice(imageIndex, imageIndex + numPerRow);
        array.forEach(rows, function (row) {
          row.restrictAspectRatio(imageContainerWidth, minHeight);
        });
        imageIndex += numPerRow;
      }));
    },
    _getShortestHeight: function (calculatedWidth, images) {
      var minHeight = null;
      array.forEach(images, function (image) {
        if (image && image.width >= calculatedWidth) {
          var zoomRatio = image.width / calculatedWidth;
          var currentHeight = Math.round(image.height / zoomRatio);
          if (minHeight === null || currentHeight < minHeight) {
            minHeight = currentHeight;
          }
        }
      });
      return minHeight;
    },
    getIconTemplate: function () {
      if (!this.iconTemplate) {
        this.iconTemplate = new dojox.dtl.Template(iconTmpl);
      }
      return this.iconTemplate;
    },
    onUploading: function (isUploading) {
      this.imageList.toggleUploadMessage(isUploading);
    },
    updateImages: function (changedAttr) {
      var imageCount = this.block.images.length;
      this.block.set("images", lang.clone(this.imageList.images));
      if (imageCount !== this.block.images.length) {
        this.block.set("selectedLayout", this.groupLayouts[this.block.images.length - 1][0]);
        this._renderLayoutIcons();
      }

      // fall-through here is on purpose.
      switch (changedAttr) {
        case "image":
          this._addWidthChecks();
          break;
        case "reorder":
          this._addAspectRatioChecks();
          break;
        default:
          break;
      }
      this.block.save();
    }
  });
});