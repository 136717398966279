define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/page-content.html", "mojo/widgets/Previewer", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation"], function (declare, lang, Renderable, tpl, Previewer, context, flags, I18nTranslation) {
  var Content = declare([Renderable], {
    templateString: tpl,
    afterSignup: "After signup form is submitted, visitors will see a confirmation message.",
    afterSignup2: "After signup form is submitted, visitors will be sent to",
    constructor: function (props) {
      this.state = {};
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.afterSignup = I18nTranslation.translate("landing_pages_after_signup");
        this.afterSignup2 = I18nTranslation.translate("landing_pages_after_signup_2");
      }
    },
    getData: function () {
      return {
        content: {}
      };
    },
    previewContent: function () {
      // TODO:figure out why the previewLoader'
      this.previewer = this.model.type === "website" ? new Previewer({
        "previewId": this.model.childPage.pageId,
        "previewType": "page",
        "titleText": this.model.childPage.name,
        "showMergeInfo": false
      }) : new Previewer({
        "previewId": this.model.pageId,
        "previewType": "page",
        "titleText": this.model.name,
        "showMergeInfo": false
      });
      this.previewer.show();
    }
  });

  // Static methods used by the checklist item
  Content.isComplete = function (model) {
    return model.renderedHtml && model.hasTemplate;
  };
  Content.isValid = function (model) {
    return model.isContentReady;
  };
  return Content;
});