define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/utils", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (dom, Deferred, lang, declare, on, query, context, utils, base, xhr, mUrl) {
  return declare([base], {
    onReady: function () {
      var self = this;
      on(dom.byId("create-account-link"), "click", function (event) {
        event.preventDefault();
        self._sendGoogleAnalyticsEvent("oauth2", "signup", "clicked_create_account");
        self._launchCreateAccountAction(self);
      });
    },
    _attachUserWithOneclickExperiment: function (variant) {
      this._sendGoogleAnalyticsEvent("experiment", "signup", "activate_oneclick_experiment", {
        dimension126: "nua-269-oneclick-0622",
        dimension127: variant
      });
    },
    _sendGoogleAnalyticsEvent: function (category, action, label, customParams) {
      if (!customParams) {
        // initializing custom params to empty object
        customParams = {};
      }
      var $client_id = this.client_id;
      if ($client_id) {
        // check if the client id exists, pass it as custom param.
        customParams["dimension63"] = $client_id;
      }
      window.ga("send", "event", category, action, label, customParams);
    },
    _launchCreateAccountAction: function (globalThisRef) {
      var signup_url = this.signup_url;
      var target = this.target;
      var client_id = this.client_id;
      var pid = this.pid;
      var state = this.state;
      var response_type = this.response_type;
      var redirect_uri = this.redirect_uri;
      xhr.post(mUrl.toUrl("/oauth2/create-account-link", {
        client_id: client_id,
        default_signup_url: signup_url,
        default_target: target,
        pid: pid,
        state: state,
        response_type: response_type,
        redirect_uri: redirect_uri
      }), {
        handleAs: "json"
      }).then(function (result) {
        if (result.success) {
          globalThisRef._attachUserWithOneclickExperiment(result.bucket);
          window.location.href = result.signup_url;
        } else if (result.success === false && result.bucket === "control") {
          globalThisRef._attachUserWithOneclickExperiment(result.bucket);
          window.open(result.signup_url, "_blank");
        } else {
          //Take user to the default signup
          window.open(signup_url, "_blank");
        }
      }, function (error) {
        if (error.response.status === 200 && error.response.getHeader("content-type") !== "application/json") {
          //Do nothing. This is likely html content being returned from login page if the request has timed out
        } else {
          console.error("Error determining which Create An Account flow to route to.", error);
        }
        return false;
      });
    }
  });
});