/**
 * Performance Overview Chart
 * @module mojo/widgets/sandbox/PerformanceOverviewChart.js
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "vendor/lodash-amd/merge", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/Renderable"], function (declare, array, mColors, merge, dom, on, domClass, topic, Renderable) {
  // Included on necessary pages as a separate <script src="">
  var AmCharts = window.AmCharts;
  var dataViz = mColors.dataViz;
  // Implementation of old style
  var deprecatedStyle = {
    color1: "#9bcfc2",
    color2: "#2c9ab7",
    color3: "#E85C41",
    fontSize: 13,
    fontFamily: "'Open Sans','Helvetica Neue',Arial,Helvetica,Verdana,sans-serif",
    xAxisColor: "#ccc",
    yAxisLabelColor: "#555",
    cursorColor: "#333"
  };

  // Implementation of new style
  var newStyle = {
    color1: dataViz.combo1.color1,
    color2: dataViz.combo1.color2,
    color3: dataViz.combo1.color3,
    fontSize: 14,
    fontFamily: "'Graphik Web',' Helvetica Neue', Arial, Helvetica, Verdana, sans-serif",
    xAxisColor: mColors.brandColors.peppercorn.hex,
    yAxisLabelColor: mColors.brandColors.peppercorn.hex,
    cursorColor: mColors.dataViz.flyover.flagFill
  };
  var mockData = [{
    "openUrl": "/reports/summary?id=754937",
    "openRate": "57.36",
    "clickUrl": "/reports/summary?id=754937",
    "clickRate": "8.59",
    "bubbleText": "[Snap] Free shipping is back!\n16 Jun,2016 4:37 pm [Thu]",
    "campaignId": 754937,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=757681",
    "openRate": "39.63",
    "clickUrl": "/reports/summary?id=757681",
    "clickRate": "2.54",
    "bubbleText": "Issue #11: Fulfillment\n21 Jun,2016 2:00 pm [Tue]",
    "campaignId": 757681,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=763925",
    "openRate": "55.31",
    "clickUrl": "/reports/summary?id=763925",
    "clickRate": "8.91",
    "bubbleText": "Freddie and Co. July 4th Promo\n28 Jun,2016 1:00 pm [Tue]",
    "campaignId": 763925,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=770605",
    "openRate": "36.84",
    "clickUrl": "/reports/summary?id=770605",
    "clickRate": "1.37",
    "bubbleText": "Issue #12: Launch Recap\n28 Jun,2016 2:00 pm [Tue]",
    "campaignId": 770605,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=742449",
    "openRate": "58.08",
    "clickUrl": "/reports/summary?id=742449",
    "clickRate": "5.31",
    "bubbleText": "Freddie and Co. + Lost-n-Found Youth\n30 Jun,2016 10:53 am [Thu]",
    "campaignId": 742449,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=775757",
    "openRate": "93.62",
    "clickUrl": "/reports/summary?id=775757",
    "clickRate": "32.83",
    "bubbleText": "1st Employee Freddie Billboard\n30 Jun,2016 1:43 pm [Thu]",
    "campaignId": 775757,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=777477",
    "openRate": "34.87",
    "clickUrl": "/reports/summary?id=777477",
    "clickRate": "0.75",
    "bubbleText": "Issue #13: Bank Account and Business License\n5 Jul,2016 2:00 pm [Tue]",
    "campaignId": 777477,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=780197",
    "openRate": "55.92",
    "clickUrl": "/reports/summary?id=780197",
    "clickRate": "18.93",
    "bubbleText": "Freddie and Co. Pintrill Announcement\n12 Jul,2016 11:11 am [Tue]",
    "campaignId": 780197,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=794177",
    "openRate": "33.33",
    "clickUrl": "/reports/summary?id=794177",
    "clickRate": "1.25",
    "bubbleText": "Issue #13.5: Tips from an Accounting Expert!\n12 Jul,2016 2:11 pm [Tue]",
    "campaignId": 794177,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=791357",
    "openRate": "34.54",
    "clickUrl": "/reports/summary?id=791357",
    "clickRate": "1.48",
    "bubbleText": "Issue #14: Introducing our 2nd Collection\n19 Jul,2016 2:00 pm [Tue]",
    "campaignId": 791357,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=805769",
    "openRate": "54.64",
    "clickUrl": "/reports/summary?id=805769",
    "clickRate": "10.87",
    "bubbleText": "Freddie and Co. + Pintrill\n21 Jul,2016 11:00 am [Thu]",
    "campaignId": 805769,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=809345",
    "openRate": "32.54",
    "clickUrl": "/reports/summary?id=809345",
    "clickRate": "2.29",
    "bubbleText": "Issue #15: Product Photography\n26 Jul,2016 2:00 pm [Tue]",
    "campaignId": 809345,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=811273",
    "openRate": "31.37",
    "clickUrl": "/reports/summary?id=811273",
    "clickRate": "0.95",
    "bubbleText": "Issue #16: Three Stories\n2 Aug,2016 2:08 pm [Tue]",
    "campaignId": 811273,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=826685",
    "openRate": "54.39",
    "clickUrl": "/reports/summary?id=826685",
    "clickRate": "4.63",
    "bubbleText": "Introducing re:imagine/ATL\n4 Aug,2016 1:00 pm [Thu]",
    "campaignId": 826685,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=828861",
    "openRate": "30.82",
    "clickUrl": "/reports/summary?id=828861",
    "clickRate": "1.45",
    "bubbleText": "Issue #16.5: Tips from a Social Media Expert\n9 Aug,2016 2:00 pm [Tue]",
    "campaignId": 828861,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=840669",
    "openRate": "29.30",
    "clickUrl": "/reports/summary?id=840669",
    "clickRate": "1.87",
    "bubbleText": "Issue #17: Developing a Design Aesthetic\n16 Aug,2016 2:15 pm [Tue]",
    "campaignId": 840669,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=849709",
    "openRate": "49.38",
    "clickUrl": "/reports/summary?id=849709",
    "clickRate": "2.91",
    "bubbleText": "PINTRILL - Catch Em All (new hands)\n18 Aug,2016 10:06 am [Thu]",
    "campaignId": 849709,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=852185",
    "openRate": "28.17",
    "clickUrl": "/reports/summary?id=852185",
    "clickRate": "1.38",
    "bubbleText": "Issue #18: Freddie and Co.&#039;s MailChimp Account\n23 Aug,2016 2:00 pm [Tue]",
    "campaignId": 852185,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=845565",
    "openRate": "59.01",
    "clickUrl": "/reports/summary?id=845565",
    "clickRate": "12.72",
    "bubbleText": "Freddie and Co. at The Decatur Book Festival\n25 Aug,2016 10:00 am [Thu]",
    "campaignId": 845565,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=857969",
    "openRate": "26.90",
    "clickUrl": "/reports/summary?id=857969",
    "clickRate": "1.19",
    "bubbleText": "Issue #19: Brand Voice\n30 Aug,2016 4:00 pm [Tue]",
    "campaignId": 857969,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=860409",
    "openRate": "26.14",
    "clickUrl": "/reports/summary?id=860409",
    "clickRate": "0.47",
    "bubbleText": "Issue #20: Product Testing\n7 Sep,2016 4:10 pm [Wed]",
    "campaignId": 860409,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=859725",
    "openRate": "25.30",
    "clickUrl": "/reports/summary?id=859725",
    "clickRate": "0.52",
    "bubbleText": "Issue #21: Holiday Prep\n14 Sep,2016 2:26 pm [Wed]",
    "campaignId": 859725,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=885629",
    "openRate": "55.77",
    "clickUrl": "/reports/summary?id=885629",
    "clickRate": "16.17",
    "bubbleText": "Collection #3 Launch\n15 Sep,2016 1:22 pm [Thu]",
    "campaignId": 885629,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=892905",
    "openRate": "24.55",
    "clickUrl": "/reports/summary?id=892905",
    "clickRate": "0.72",
    "bubbleText": "Issue #22: Selling in Person (Plus, our third collection launch!)\n21 Sep,2016 2:00 pm [Wed]",
    "campaignId": 892905,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=903117",
    "openRate": "23.83",
    "clickUrl": "/reports/summary?id=903117",
    "clickRate": "1.00",
    "bubbleText": "Issue #23: Packaging\n28 Sep,2016 2:45 pm [Wed]",
    "campaignId": 903117,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=909805",
    "openRate": "43.66",
    "clickUrl": "/reports/summary?id=909805",
    "clickRate": "5.36",
    "bubbleText": "Baron Book Bundle\n30 Sep,2016 1:45 pm [Fri]",
    "campaignId": 909805,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=911093",
    "openRate": "25.31",
    "clickUrl": "/reports/summary?id=911093",
    "clickRate": "0.57",
    "bubbleText": "Issue #24: Baron Fig&#039;s Story\n4 Oct,2016 2:46 pm [Tue]",
    "campaignId": 911093,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=924625",
    "openRate": "24.32",
    "clickUrl": "/reports/summary?id=924625",
    "clickRate": "0.53",
    "bubbleText": "Issue #25: The Book Blunder\n12 Oct,2016 3:03 pm [Wed]",
    "campaignId": 924625,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=937477",
    "openRate": "24.97",
    "clickUrl": "/reports/summary?id=937477",
    "clickRate": "0.44",
    "bubbleText": "Issue #26: Strategic Merchandising\n18 Oct,2016 3:41 pm [Tue]",
    "campaignId": 937477,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=950165",
    "openRate": "14.39",
    "clickUrl": "/reports/summary?id=950165",
    "clickRate": "2.92",
    "bubbleText": "BAGGUxPoketo Collection\n27 Oct,2016 3:40 pm [Thu]",
    "campaignId": 950165,
    "industryAvg": "29.56"
  }];
  var getBalloonConfig = function () {
    return {
      showBullet: false,
      textShadowColor: "none",
      horizontalPadding: 15,
      verticalPadding: 5,
      borderThickness: 0,
      shadowAlpha: 0,
      color: "#FFFFFF",
      fillAlpha: 1,
      adjustBorderColor: false
    };
  };
  var getCategoryAxis = function (color) {
    return {
      autoGridCount: false,
      gridPosition: "start",
      dashLength: 0,
      axisColor: color,
      axisThickness: 2,
      gridAlpha: 0,
      labelsEnabled: false
    };
  };
  var initIndustryGraph = function (color) {
    return merge(new AmCharts.AmGraph(), {
      title: "Industry Average",
      valueField: "industryAvg",
      type: "line",
      lineColor: color,
      dashLength: 3,
      balloonText: "[[value]]% Industry Avg",
      lineThickness: 2,
      bullet: "round",
      bulletSize: 0,
      fillAlphas: 0.0
    });
  };
  var initClicksGraph = function (color) {
    return merge(new AmCharts.AmGraph(), {
      title: "Click Rate",
      valueField: "clickRate",
      type: "smoothedLine",
      lineColor: color,
      balloonText: "[[value]]% Click Rate",
      lineThickness: 3,
      bullet: "round",
      bulletColor: "#fff",
      bulletBorderColor: color,
      bulletBorderThickness: 2,
      bulletBorderAlpha: 1,
      bulletSize: 6,
      fillAlphas: 0.3
    });
  };
  var initOpensGraph = function (color) {
    return merge(new AmCharts.AmGraph(), {
      title: "Open Rate",
      valueField: "openRate",
      type: "smoothedLine",
      lineColor: color,
      balloonText: "[[value]]% Open Rate",
      lineThickness: 2,
      bullet: "round",
      bulletColor: "#fff",
      bulletBorderColor: color,
      bulletBorderThickness: 2,
      bulletBorderAlpha: 1,
      bulletSize: 6,
      fillAlphas: 0.0
    });
  };
  var initChartCursor = function (color) {
    return merge(new AmCharts.ChartCursor(), {
      cursorColor: color,
      cursorAlpha: 0.2,
      cursorPosition: "middle",
      bulletsEnabled: true,
      bulletSize: 6
    });
  };
  var initValueAccess = function () {
    return merge(new AmCharts.ValueAxis(), {
      dashLength: 0,
      axisAlpha: 0,
      unit: "%",
      axisThickness: 0
    });
  };
  return declare([Renderable], {
    // Seemingly arbitrary height is required for rendering. 🤷‍♀️
    templateString: "<div id='chartContainer' style='height: 400px'></div>",
    elementId: "chartContainer",
    customColors: null,
    currentColors: deprecatedStyle,
    hasIndustry: true,
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      this._initEventListener();
      this._renderChart();
    },
    _renderChart: function () {
      this.chart = merge(new AmCharts.AmSerialChart(), {
        pathToImages: "http://dev.mailchimp.com/charts/amcharts/images/",
        dataProvider: mockData,
        categoryField: "bubbleText",
        startDuration: 0,
        autoMargins: false,
        marginTop: 10,
        marginRight: 0,
        marginBottom: 100,
        marginLeft: 50,
        color: this.currentColors.yAxisLabelColor,
        fontFamily: this.currentColors.fontFamily,
        fontSize: this.currentColors.fontSize,
        balloon: getBalloonConfig(),
        categoryAxis: getCategoryAxis(this.currentColors.xAxisColor)
      });
      this.chart.addGraph(initClicksGraph(this.currentColors.color2));
      this.chart.addGraph(initOpensGraph(this.currentColors.color1));
      if (this.hasIndustry) {
        this.chart.addGraph(initIndustryGraph(this.currentColors.color3));
      }
      this.chart.addValueAxis(initValueAccess());
      this.chart.addChartCursor(initChartCursor(this.currentColors.cursorColor));
      this.chart.write(this.elementId);
    },
    _initEventListener: function () {
      topic.subscribe("colorToggle/change", this._handleColorChange.bind(this));
    },
    _handleColorChange: function (selectedColor) {
      var useNewColor = selectedColor === "new colors";
      var legend = document.querySelector("[data-swatch-legend='performanceLegend']");
      this.currentColors = useNewColor ? newStyle : deprecatedStyle;
      domClass.toggle(legend, "dataViz--combo1", useNewColor);
      this._renderChart();
    }
  });
});