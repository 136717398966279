/**
 * A simple list that supports any of the components under mojo/analytics/parts
 *
 * By default, it will try to get the list from {currentURL}/list?{params}, but you can specify the url by passing in a url attribute.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "./_SelectableMixin", "./_FilterableMixin", "./_FilterableMixinWithLocalStorage", "./_PersistedMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dojo/parser", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "dijit/registry", "mojo/url", "dojo/Stateful"], function (declare, _Widget, _SelectableMixin, _FilterableMixin, _FilterableMixinWithLocalStorage, _PersistedMixin, lang, array, query, domConstruct, domStyle, sync, parser, topic, xhr, user, registry, mUrl, Stateful) {
  return declare([_Widget, _PersistedMixin, _SelectableMixin, _FilterableMixinWithLocalStorage], {
    page: 1,
    pageSize: user.settings.pageSize,
    asc: true,
    lazy: false,
    options: null,
    _params: {},
    constructor: function () {
      this._syncs = [];
      this._published = false;
      this._renderIsQueued = false;
    },
    postMixInProperties: function () {
      this.inherited(arguments);
      // /campaigns hash overrides the front door hash when refreshing the page
      // with the front door open. Setting persisted to false will stop
      // PersistedMixin from removing "create-campaign" from the url
      if (mUrl.inUrl('/#/create-campaign')) {
        this._persisted = false;
      } else {
        this.createHashParams();
      }
    },
    postCreate: function () {
      var self = this;
      var page = this.getHashParam('p');
      if (page && this.shouldPersistPage()) {
        this.set("page", page);
      }
      if (!this.url) {
        this.url = document.location.pathname;
        if (this.url[this.url.length - 1] != '/') this.url += '/';
        this.url += 'list' + document.location.search;
      }

      // FP-661: interop for loading this in the single-page app's iframe
      if (this.url.substr(0, 3) === "/i/") {
        this.url = this.url.substr(2);
      }
      this.watch("page", lang.hitch(this, function () {
        this.render();
        if (this.shouldPersistPage()) {
          if (this.page === 1) {
            this.unsetHashParam("p");
          } else {
            this.setHashParam("p", this.page);
          }
        }
      }));
      var pageSize = this.getHashParam('s');
      if (pageSize && this.shouldPersistPage()) {
        this.set("pageSize", pageSize);
      }
      this.watch("pageSize", lang.hitch(this, function () {
        if (this.page == 1) {
          this.render();
        } else {
          this.set("page", 1);
        }
        user.saveSetting('vis_grid_page_size', this.pageSize);
        if (this.shouldPersistPage()) {
          this.setHashParam("s", this.pageSize);
        }
      }));
      this.watch("url", lang.hitch(this, "render"));
      this.watch("sort", lang.hitch(this, "render"));
      this.watch("asc", lang.hitch(this, "render"));
      this.watch("search", lang.hitch(this, "render"));
      this._filters.watch(lang.hitch(this, "render"));
      if (!this.lazy) this.render();
    },
    render: function () {
      if (this._renderIsQueued === false) {
        this._renderIsQueued = true;
        setTimeout(lang.hitch(this, function () {
          this._renderIsQueued = false;
          this._render();
        }), 0);
      }
    },
    _render: function () {
      this.initialized = true;
      var delayedLoader = setTimeout(lang.hitch(this, "renderLoading"), 500);
      var params = lang.mixin({
        page: this.page,
        per_page: this.pageSize,
        sort: this.sort,
        asc: this.asc
      }, this.getFilterQuery(), this._getOptions(), this._getParams());
      this.getData(params).then(lang.hitch(this, function (data) {
        this._removeState();
        clearTimeout(delayedLoader);
        this.renderData(data, params);
        if (data.params) {
          this._params = data.params;
        }
        var total = "pagination" in data ? data.pagination.total_count : data.count;
        this.set("total", total);

        // Accounts for when data has been deleted between refreshes.
        if (!this._published || this._published && data.count === 0) {
          topic.publish("mojo/analytics/" + this.id + "/ready");
          this._published = true;
        }
        query(".selectCheckBox", this.domNode).forEach(lang.hitch(this, function (box) {
          var dijitBox = registry.byNode(box);
          this.selected[dijitBox.value] = this.selected[dijitBox.value] || false;
          this._syncs.push(sync(this.selected, dijitBox.value, dijitBox, "checked"));
        }));
        topic.publish(this.getTopic(), this);
      }));
    },
    getData: function (params) {
      return xhr.get(this.getUrl(), {
        handleAs: "json",
        query: params
      });
    },
    getUrl: function () {
      return this.url;
    },
    renderLoading: function () {
      this.renderContent({
        content: "<div class='list-loading'></div>"
      });
    },
    renderData: function (data, params) {
      if (data.count || data.items && data.items.length > 0) {
        domStyle.set(this.domNode, "display", "");
        if (this.emptyNode) {
          domStyle.set(this.emptyNode, "display", "none");
        }
        this.renderContent(data, params);
        parser.parse(this.domNode);
      } else {
        domStyle.set(this.domNode, "display", "none");
        if (!this.emptyNode) {
          this.emptyNode = domConstruct.place("<div></div>", this.domNode, "after");
        }
        domStyle.set(this.emptyNode, "display", "");
        this.renderEmpty(data, params);
        parser.parse(this.emptyNode);
      }
    },
    renderContent: function (data, params) {
      var content = lang.trim(data.content);
      domConstruct.place(content, this.domNode, "only");
    },
    renderEmpty: function (data, params) {
      var content = lang.trim(data.content);
      domConstruct.place(content, this.emptyNode, "only");
    },
    setOptions: function (options) {
      this.options = options;
      this.render();
    },
    _getOptions: function () {
      if (!this.options) return {};
      var optionMap = {};
      for (var key in this.options) {
        optionMap['options[' + key + ']'] = this.options[key];
      }
      return optionMap;
    },
    _getParams: function () {
      if (!this._params) return {};
      var paramsMap = {};
      for (var key in this._params) {
        paramsMap['params[' + key + ']'] = this._params[key];
      }
      return paramsMap;
    },
    getTopic: function () {
      return "mojo/analytics/List/" + this.id + "/render";
    },
    shouldPersistPage: function () {
      return true;
    },
    /**
     * Clean up any syncs we have and reset the selection model
     */
    _removeState: function () {
      array.forEach(this._syncs, function (s) {
        s.remove();
      });
      this._syncs = [];
      this.clearSelectionModel();
      array.forEach(registry.findWidgets(this.domNode), function (w) {
        w.destroyRecursive();
      });
    }
  });
});