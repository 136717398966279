define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/ButtonGroup", "dojo/text!./templates/PreviewSwitcher.html", "dojo/text!./templates/ButtonItemBordered.html", "dojo/text!./templates/ButtonItemUnderlined.html"], function (declare, array, lang, domClass, domConstruct, query, _WidgetBase, _TemplatedMixin, ButtonGroup, tplStr, borderedBtnTplStr, underlinedBtnTplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    // NodeList of top-level previews
    previews: null,
    // NodeList of child previews of currently selected preview
    childPreviews: null,
    // ButtonGroup responsible for toggling visibility of top-level previews
    previewButtons: null,
    // If the selected preview has child preview, this is the associated button group
    childPreviewButtons: null,
    /**
     * Workhorse of the widget. See https://dojotoolkit.org/reference-guide/1.10/dijit/_WidgetBase.html for method
     * lifecyle
     */
    postCreate: function () {
      this.parsePreviews();
      this.hideAll();
      this.buildPreviewButtons();
    },
    /**
     * Parse the containerNode and setup this.previews as a nodeList.
     */
    parsePreviews: function () {
      this.previews = query("[data-preview-label]", this.containerNode);
    },
    /**
     * Parse the containerNode and setup this.childPreviews as a nodeList.
     *
     * @param {object} queryScope Passed as a currently visible node.
     */
    parseChildPreviews: function (queryScope) {
      this.childPreviews = query("[data-preview-style]", queryScope);
    },
    /**
     * Gather all of the info necessary and build the top-level preview button group.
     */
    buildPreviewButtons: function () {
      var buttons = this.getPreviewButtons();
      this.previewButtons = new ButtonGroup({
        options: buttons,
        itemTpl: borderedBtnTplStr,
        activeClass: "v-buttonGroupBordered--active",
        "class": "margin--lv2 margin-left-right--lv0 margin-bottom--lv4"
      });

      // Hide buttons if there's only one preview. Keep functionality in place.
      domClass.toggle(this.buttons, "hide", this.previews.length === 1);

      // Place button group
      this.previewButtons.placeAt(this.buttons);
      this.previewButtons.startup();

      // By default use the first one and attach handler.
      this.previewButtons.on("change", lang.hitch(this, "handlePreviewChange"));
      this.previewButtons.setSelected(buttons[0].value);
    },
    /**
     * Builds the top-level preview button group.
     */
    buildChildPreviewTab: function () {
      var childButtons = this.getChildPreviewButtons();
      this.childPreviewButtons = new ButtonGroup({
        options: childButtons,
        itemTpl: underlinedBtnTplStr,
        activeClass: "v-buttonGroupUnderlined--active",
        "class": "margin-bottom--lv2"
      });

      // Place button group
      this.childPreviewButtons.placeAt(this.childButtons);
      this.childPreviewButtons.startup();

      // By default use the first one and attach handler.
      this.childPreviewButtons.on("change", lang.hitch(this, "handleChildPreviewChange"));
      this.childPreviewButtons.setSelected(childButtons[0].value);
    },
    /**
     * Show appropriate top-level preview
     */
    handlePreviewChange: function () {
      this.hideAll();
      this.resetChildPreviews();
      this.previews.forEach(lang.hitch(this, function (node) {
        if (this.previewButtons.value === node.dataset.previewLabel.toLowerCase()) {
          this.parseChildPreviews(node);
          domClass.remove(node, "hide");

          // Create child button group if necessary.
          if (this.childPreviews && this.childPreviews.length > 1) {
            this.buildChildPreviewTab();
          }
        }
      }));
    },
    /**
     * Show appropriate child preview
     */
    handleChildPreviewChange: function () {
      this.childPreviews.forEach(lang.hitch(this, function (node) {
        domClass.toggle(node, "hide", this.childPreviewButtons.value !== node.dataset.previewStyle.toLowerCase());
      }));
    },
    /**
     * Hide all of the top-level previews
     */
    hideAll: function () {
      this.previews.addClass("hide");
    },
    /**
     * Utility function to consistently reset the child preview button group
     */
    resetChildPreviews: function () {
      this.childPreviews = null;
      domConstruct.empty(this.childButtons);
    },
    /**
     * @returns {Object} Shortcut to get the correctly formatted button items with only unique values.
     */
    getPreviewButtons: function () {
      return this.getButtonItemsFromArray(this.getUniqueValuesFromNodes(this.previews, "previewLabel"));
    },
    /**
     * @returns {Object} Based on the selected preview, return button group items associated with the child
     * preview.
     */
    getChildPreviewButtons: function () {
      return this.getButtonItemsFromArray(this.getUniqueValuesFromNodes(this.childPreviews, "previewStyle"));
    },
    /**
     * @param {array} list of button group items. that can be passed from a query.
     * *
     * @returns {object} Formatted necessary for Button Group widget based on the array you send it.
     */
    getButtonItemsFromArray: function (list) {
      return array.map(list, lang.hitch(this, function (item) {
        return {
          value: item.toLowerCase(),
          label: item
        };
      }));
    },
    /**
     *
     * @param {array} nodeList that can be passed from a query.
     * @param {string} dataAttr The data-* we are pulling value from. Format in thisStyleNotation.
     * *
     * @returns {array} of unique values items gathered from nodeList.
     */
    getUniqueValuesFromNodes: function (nodeList, dataAttr) {
      var uniqueItems = [];
      nodeList.forEach(lang.hitch(this, function (node) {
        var value = node.dataset[dataAttr];
        if (array.indexOf(uniqueItems, value) === -1) {
          uniqueItems.push(value);
        }
      }));
      return uniqueItems;
    }
  });
});