define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo/text!../editors/blocks/templates/PromoCodeEditor/outputWeb.html"], function (declare, _Renderer, ButtonRenderer, Link, tpl) {
  return declare([_Renderer], {
    // Basic template
    outputTplStr: tpl,
    // Keeps a reference to a button renderer which is the same renderer that handles a button block
    buttonRenderer: null,
    constructor: function () {
      this.inherited(arguments);

      // Initialize button renderer
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * Return the markup of the content of each promo code. This includes description, code, expiration and button
     *
     * @param {Object} meta - ref to the output model's meta.
     *
     * @return {Array} the rows for the inner content of the block.
     *
     * @private
     */
    _contentRows: function (meta) {
      var rows = [];
      var align = meta.align || "center";
      var padding = "padding-bottom:18px;padding-left:18px;padding-right:18px";
      if (meta.descriptionEnabled && meta.description) {
        rows.push({
          html: meta.description,
          className: "mcnPromoDescription",
          styles: padding + ";text-align:" + align
        });
      }
      if (!meta.promoCode) {
        meta.promoCode = "Promo Code";
      }
      rows.push({
        html: meta.promoCode,
        className: "mcnPromoCode",
        styles: padding + ";word-break:break-all;text-align:" + align
      });
      if (meta.expirationEnabled) {
        var expiration = null;

        // Check for expiration date
        if (!meta.expirationDate) {
          expiration = "No expiration date";
        } else {
          expiration = "Expiration date: " + meta.expirationDate;
        }
        rows.push({
          html: expiration,
          className: "mcnExpirationDate",
          styles: padding + ";text-align:" + align
        });
      }
      if (meta.buttonEnabled) {
        rows.push({
          html: this._buttonMarkup(meta),
          className: "mcnButtonContent"
        });
      }
      return rows;
    },
    /**
     * Use Button block renderer to render the button.
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     *
     * @returns {string} html content of how a button should look
     * @private
     */
    _buttonMarkup: function (meta) {
      var buttonMeta = {
        buttonText: meta.buttonText,
        buttonWidth: meta.buttonWidth,
        link: new Link(meta.link, meta.title)
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    getOutputModel: function (meta) {
      return {
        rows: this._contentRows(meta)
      };
    }
  });
});