define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./DeleteDialog", "dojo/text!./templates/BrandDeleteDialog.html", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, DeleteDialog, tpl, query, I18nTranslation, flags, context) {
  /**
   * A confirmation dialog for deleting a brand.
   */
  return declare([DeleteDialog], {
    dialogTitle: "Are you sure you want to delete your logo?",
    confirmDeleteText: "Your image will revert to a placeholder in draft, ongoing, and archived campaigns. This includes automated emails and published landing pages.",
    deleteButtonText: "Delete",
    cancelButtonText: "Cancel",
    templateString: tpl,
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function () {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.dialogTitle = I18nTranslation.translate("content_studio_brand_dialog_title");
        this.confirmDeleteText = I18nTranslation.translate("content_studio_brand_delete_text");
        this.deleteButtonText = I18nTranslation.translate("content_studio_brand_delete_button");
        this.cancelButtonText = I18nTranslation.translate("content_studio_brand_cancel_button");
      }.bind(this));
    }
  });
});