define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "mojo/url", "mojo/user", "mojo/lib/flags"], function (declare, lang, xhr, Deferred, Stateful, mUrl, user, flags) {
  var PAGE_BANNER_SAVE_URL = "/landing-pages/save-page-banner-settings";
  var _defaultText = "<p style=\"text-align: center;\"><em>Write your own or use one of our examples:</em></p>" + "<p style=\"text-align: center;\">We use cookies to improve your experience and help us understand how you use our site. By using this site, you accept our [use of cookies].</p>" + "<p style=\"text-align: center;\">Act fast! This offer ends March 31.</p>";
  // set default state of Page Banner Settings
  var PageBannerSettings = declare([Stateful], {
    styles: [{
      "name": "Style",
      "selector": ".mcnPageBannerContainer",
      "props": {
        "background-color": {
          "value": "#BFECFA",
          "isOptional": false
        }
      }
    }],
    pageId: null,
    content: _defaultText,
    enabled: false,
    _kbLink: "https://mailchimp.com/help/use-notification-bars-on-landing-pages/",
    _webKbLink: "https://mailchimp.com/help/about-website-beta/",
    constructor: function (params) {
      this.set("_kbLink", "https://mailchimp.com/help/use-notification-bar-website-drag-drop/");
    }
  });
  var api = {
    /**
     * Save config settings for Page Banner
     * @param {Object} model - Properties to update
     * @return {Promise} promise from save request
     */
    save: function (model) {
      var url = mUrl.toUrl(PAGE_BANNER_SAVE_URL, {
        id: model.pageId
      });
      var setting = {
        styles: model.get("styles"),
        content: model.get("content"),
        enabled: model.get("enabled")
      };
      if (window.app.isWebsitePage === true) {
        window.ga("send", "event", "Website Builder", "Edit Website Design-Content-Design-Nofication-Bar", "Clicked / Save");
      }
      return xhr.post(url, {
        "handleAs": "json",
        "data": {
          "setting": JSON.stringify(setting) // dojo doesn't serialize json objects
        }
      });
    },
    /**
     * Instantiate a new Campaign from the given properties
     * @param {Object} pageBannerConfig - Properties to build the PageBannerSettings instance from
     * @return {Campaign} A full instance
     */
    createFromProperties: function (pageBannerConfig) {
      return new PageBannerSettings(pageBannerConfig);
    }
  };
  return api;
});