define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    /**
     * The id of this section's tab
     */
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.addFeatureButtons) {
        this.$group.addFeatureButtons.forEach(function (el) {
          on(el, "click", function () {
            self.addFeature(domAttr.get(el, "data-feature-id"), domAttr.get(el, "data-feature-name"));
          });
        });
      }
      if (this.$group.removeFeatureButtons) {
        this.$group.removeFeatureButtons.forEach(function (el) {
          on(el, "click", function () {
            self.removeFeature(domAttr.get(el, "data-feature-id"), domAttr.get(el, "data-feature-name"));
          });
        });
      }
    },
    /**
     * opens a modal to enable a feature flag in Mailchimp
     * @param {string} feature_id - the id of the feature flag
     * @param {string}feature_name - name of the feature flag
     */

    addFeature: function (feature_id, feature_name) {
      this.$el.featureId.value = feature_id;
      this.$el.featureStartTime.value = "";
      this.$el.featureEndTime.value = "";
      this.$el.addFeatureText.innerHTML = "Do you want to add <strong>" + feature_name + "</strong> to this user?";
      this.$widget.addFeatureDialog.show();
    },
    /**
     * opens a modal to disable a feature flag in Mailchimp
     * @param {string} user_feature_id - id of the feature flag
     * @param {string} feature_name - name of the feature flag
     */
    removeFeature: function (user_feature_id, feature_name) {
      this.$el.featureRemoveId.value = user_feature_id;
      this.$el.removeFeatureText.innerHTML = "Are you sure you want to remove <strong>" + feature_name + "</strong> from this user?";
      this.$widget.removeFeatureDialog.show();
    }
  });
});