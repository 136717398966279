define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo/text!./templates/ImageEditor/editor.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/neapolitan/MaxWidthRestriction", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, _Base, tmpl, lang, domConstruct, user, context, flags, MaxWidthRestriction, domClass, _DynamicContentMixin) {
  return declare([_Base, _DynamicContentMixin], {
    type: "image",
    retina: false,
    templateString: tmpl,
    postCreate: function () {
      this.isMobile = window.top.app && window.top.app.nativeUtils && window.top.app.nativeUtils.getNativeObject();
      this.inherited(arguments);
      this.sync("edgeToEdge", this.edgeToEdgeToggle, "checked");
      this.sync("retina", this.retinaToggle, "checked");
      this.sync("align", this.alignInput, "value");
      this.sync("image", this.imageRow, "image");
      if (!this.isMobile) {
        this.sync("customSize", this.contentSizeSlider, "value");
      }
      this._toggleImageSlider();
      this.watch("_uploading", lang.hitch(this, function () {
        this.imageRow.toggleUploadMessage(this.block._uploading);
      }));
      this.connect(this.imageRow, "onChange", "updateImage");
      MaxWidthRestriction.addTo(this.imageRow);
    },
    onUploading: function (isUploading) {
      this.imageRow.toggleUploadMessage(isUploading);
    },
    updateImage: function () {
      this.block.set("image", this.imageRow.image);
      this._toggleImageSlider();
      this.save();
    },
    // Unhide the slider if an image exists to prevent users from seeing empty blocks with the slider.
    _toggleImageSlider: function () {
      if (!this.isMobile && this.imageRow.image) {
        domClass.toggle(this.contentSizeSliderContainer, "hide", false);
      }
    }
  });
});