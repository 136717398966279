/**
 * @module mojo/widgets/VariateSelector
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_Templated", "dojo/text!./templates/variateSelector.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _Templated, tpl, on, parser, lang, dom, domClass, query) {
  return declare([_WidgetBase, _Templated], {
    templateString: tpl,
    potential: 0,
    minimum: 2,
    maximum: 8,
    value: 0,
    label: "",
    name: "",
    postMixInProperties: function () {
      this.potential = this.maximum;
    },
    _selectVariate: function () {
      if (this.potential >= this.minimum) {
        this.set("value", this.minimum);
        this.onChange();
      }
    },
    _increaseVariate: function () {
      if (this.value < this.potential) {
        this.set("value", this.value + 1);
        this.onChange();
      }
    },
    _decreaseVariate: function () {
      if (this.value > this.minimum) {
        this.set("value", this.value - 1);
        this.onChange();
      } else if (this.value !== 0) {
        this.set("value", 0);
        this.onChange();
      }
    },
    _displayControls: function () {
      if (this.value === 0) {
        this.variateControls.style.display = "";
        domClass.remove(query(this.domNode).parent()[0], "mv-active");
      } else {
        this.variateControls.style.display = "block";
        domClass.add(query(this.domNode).parent()[0], "mv-active");
        this.variateValue.innerHTML = this.value;
      }
    },
    _onValueChange: function () {
      this.variateName.value = this.value;
      this._displayControls();
      domClass.toggle(this.variatePlus, "disabled", this.value >= this.maximum);
    },
    _onPotentialChange: function () {
      domClass.toggle(query(this.domNode).parent()[0], "mv-disabled", this.potential < this.minimum);
    },
    postCreate: function () {
      var self = this;
      this.own(on(self.variateSelect, "click", lang.hitch(this, "_selectVariate")), on(self.variatePlus, "click", lang.hitch(this, "_increaseVariate")), on(self.variateMinus, "click", lang.hitch(this, "_decreaseVariate")));
      this.watch("value", lang.hitch(this, "_onValueChange"));
      this.watch("potential", lang.hitch(this, "_onPotentialChange"));
      this.watch("total", lang.hitch(this, function () {
        domClass.toggle(this.variatePlus, "disabled", this.total >= this.maximum || this.potential === this.minimum && this.total >= 6);
      }));
      this._onValueChange();
      this._onPotentialChange();
    },
    onChange: function () {}
  });
});