/**
 * Add country calling code options to a select menu.
 * Useful when needed on pages where PHP is not rendered
 * (eg: contact-support-form.html)
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "dijit/form/Select"], function (lang, declare, request, mUrl, Select) {
  return declare([Select], {
    postCreate: function () {
      this.inherited(arguments);
      this.fetchCallingCodes().then(lang.hitch(this, function (response) {
        if (typeof response === "object") {
          this.setCallingCodes(response);
        }
      }));
    },
    fetchCallingCodes: function () {
      return request.get(mUrl.toUrl("/support/country-calling-codes"), {
        "handleAs": "json"
      });
    },
    setCallingCodes: function (options) {
      Object.keys(options).forEach(lang.hitch(this, function (key) {
        this.options.push({
          "value": options[key].value,
          "label": options[key].label
        });
      }));
    }
  });
});