define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/views/automations/index", "mojo/app/campaigns", "mojo/utils", "mojo/utils/date", "mojo/url", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils/I18nTranslation", "./Slat", "mojo/user"], function (declare, lang, domConstruct, on, entities, AutomationActions, campaigns, utils, dateUtils, mUrl, context, flags, logger, I18nTranslation, Slat, user) {
  return declare([Slat], {
    channelIcon: "c-channelIcon--email",
    statusContent: {
      "sending": {
        label: "Sending",
        badge: "info",
        description: "Sending since <strong>{startDate}</strong>"
      },
      "somepaused": {
        label: "Some Paused",
        badge: "warning",
        description: "{totalEmailsCount} emails, {pausedEmailsCount} paused on <strong>{lastPausedDate}</strong>"
      },
      "save": {
        label: "Draft",
        badge: "",
        description: "Edited on <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      },
      "draft": {
        label: "Draft",
        badge: "",
        description: "Edited on <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      },
      "active": {
        label: "Active",
        badge: "success",
        description: "Active since <strong>{startDate}</strong>"
      },
      "paused": {
        label: "Paused",
        badge: "warning",
        description: "Paused on <strong>{lastPausedDate}</strong>"
      },
      "archived": {
        label: "Archived",
        badge: "",
        description: "Archived on <strong>{archivedDate}</strong>"
      }
    },
    typeContent: {
      "automation": {
        label: "Automation"
      }
    },
    statsOrder: ["opens", "clicks", "revenue"],
    statsContent: {
      "opens": {
        label: "Opens"
      },
      "clicks": {
        label: "Clicks"
      },
      "revenue": {
        label: "Revenue"
      }
    },
    actions: {
      "replicate": {
        label: "Replicate",
        onClick: function () {
          var actions = new AutomationActions();
          actions.showReplicationModal(this.model.automationId);
        }
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "edit": {
        label: "Edit"
      },
      "pause": {
        label: "Pause"
      },
      "view": {
        label: "View"
      },
      "unpause": {
        label: "Unpause"
      },
      "replicatetoautomation": {
        label: "Replicate to Automation"
      },
      "resolve": {
        label: "Resolve"
      },
      "accept": {
        label: "Accept Now"
      },
      "archive": {
        label: "Archive",
        onClick: function () {
          var actions = new AutomationActions();
          actions._confirmArchiving(this.model.automationId);
        }
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
        }
      }
    },
    statusContentTranslated: {},
    statsContentTranslated: {},
    typeContentTranslated: {},
    actionsTranslated: {},
    /**
     * override
     *
     * @param {object} translationObject i18n object
     */
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "sending": {
          label: translationObject.campaigns_sending,
          badge: "info",
          description: I18nTranslation.translate("campaigns_automation_sending_description", ["{startDate}"])
        },
        "somepaused": {
          label: translationObject.campaigns_some_paused,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_some_paused_description", ["{totalEmailsCount}", "{pausedEmailsCount}", "{lastPausedDate}"])
        },
        "save": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_draft_description", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "draft": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_draft_description", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "active": {
          label: translationObject.campaigns_active,
          badge: "success",
          description: I18nTranslation.translate("campaigns_automations_active_description", ["{startDate}"])
        },
        "paused": {
          label: translationObject.campaigns_paused,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_automations_paused_description", ["{lastPausedDate}"])
        },
        "archived": {
          label: translationObject.campaigns_archived,
          badge: "",
          description: I18nTranslation.translate("campaigns_automations_archived_description", ["{archivedDate}"])
        }
      };
      this.statsContentTranslated = {
        "opens": {
          label: translationObject.campaigns_opens
        },
        "clicks": {
          label: translationObject.campaigns_clicks
        },
        "revenue": {
          label: translationObject.campaigns_revenue
        }
      };
      this.typeContentTranslated = {
        "automation": {
          label: translationObject.campaigns_automation
        }
      };
      this.actionsTranslated = {
        "replicate": {
          label: translationObject.campaigns_actions_replicate,
          onClick: function () {
            var actions = new AutomationActions();
            actions.showReplicationModal(this.model.automationId);
          }
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Automations");
            }
          }
        },
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        "pause": {
          label: translationObject.campaigns_actions_pause
        },
        "view": {
          label: translationObject.campaigns_actions_view
        },
        "unpause": {
          label: translationObject.campaigns_actions_unpause
        },
        "replicatetoautomation": {
          label: translationObject.campaigns_actions_replicate_to_automation
        },
        "resolve": {
          label: translationObject.campaigns_actions_resolve
        },
        "accept": {
          label: translationObject.campaigns_actions_accept_now
        },
        "archive": {
          label: translationObject.campaigns_actions_archive,
          onClick: function () {
            var actions = new AutomationActions();
            actions._confirmArchiving(this.model.automationId);
          }
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
          }
        }
      };
    },
    /**
     * @override Slat
     */
    _buildTypeName: function () {
      if (this.typeContent[this.model.type] && !this.model.autoresponderId) {
        var templateIcon = this.template_typeIcon.innerHTML;
        this.node_typeName.innerHTML = templateIcon + this.model.workflowType || this.typeContent[this.model.type].label;
      }
    },
    /**
     * @override Slat
     */
    _buildTypeNameTranslated: function () {
      if (this.typeContentTranslated[this.model.type] && !this.model.autoresponderId) {
        var templateIcon = this.template_typeIcon.innerHTML;
        this.node_typeName.innerHTML = templateIcon + this.model.workflowType || this.typeContentTranslated[this.model.type].label;
      }
    },
    /**
     * @override Slat
     */
    buildListName: function () {
      if (!this.model.autoresponderId) {
        this.node_listName.appendChild(utils.compileTemplate(this.model.listName ? this.template_listName : "", {
          listName: this.model.listName,
          segmentName: this.model.hasSegment && this.model.segmentDisplay ? this.model.segmentDisplay : "",
          snapshotDate: "",
          snapshotDateClass: "hide"
        }));
      }
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaData: function () {
      if (this.statusContent[this.model.status]) {
        var template = this.statusContent[this.model.status].description;
        var templateIcon = this.template_typeIcon.innerHTML;
        var isDraft = this.model.status === "draft" || this.model.status === "save";
        if (this.model.autoresponderId) {
          template = isDraft ? templateIcon + "This automation is incomplete." : templateIcon + "{autoresponderTriggerString}";
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "startDate":
            case "lastPausedDate":
            case "archivedDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                return dateUtils.formatDateTime(this.model[key].jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] || "";
          }
        }));

        //Add helper link for autoresponders
        if (this.model.autoresponderId) {
          var helperLink;
          if (isDraft) {
            helperLink = domConstruct.toDom("<a href='" + mUrl.toUrl("/campaigns/show?id=" + this.model.autoresponderId) + "'>Finish setup</a>");
          } else {
            helperLink = domConstruct.toDom("<a href='#0'>Who will get this next?</a>");
            on(helperLink, "click", lang.hitch(this, function (e) {
              e.preventDefault();
              var modalUrl = mUrl.toUrl("/autoresponders/queue-popup?id=" + this.model.autoresponderId);
              var win = window.open(modalUrl, "_blank", "toolbar=0,scrollbars=1,location=0,status=1,menubar=0,resizable=1,width=600,height=400");
              utils.checkPopupWin(win);
            }));
          }
          domConstruct.place(domConstruct.toDom("<br />"), this.node_additionalMetaData);
          domConstruct.place(helperLink, this.node_additionalMetaData);
        }
      }
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.statusContentTranslated[this.model.status]) {
        var template = this.statusContentTranslated[this.model.status].description;
        var templateIcon = this.template_typeIcon.innerHTML;
        var isDraft = this.model.status === "draft" || this.model.status === "save";
        if (this.model.autoresponderId) {
          template = isDraft ? templateIcon + "This automation is incomplete." : templateIcon + "{autoresponderTriggerString}";
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "startDate":
            case "lastPausedDate":
            case "archivedDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(this.model[key], "full", true) : dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] || "";
          }
        }));

        //Add helper link for autoresponders
        if (this.model.autoresponderId) {
          var helperLink;
          if (isDraft) {
            helperLink = domConstruct.toDom("<a href='" + mUrl.toUrl("/campaigns/show?id=" + this.model.autoresponderId) + "'>Finish setup</a>");
          } else {
            helperLink = domConstruct.toDom("<a href='#0'>Who will get this next?</a>");
            on(helperLink, "click", lang.hitch(this, function (e) {
              e.preventDefault();
              var modalUrl = mUrl.toUrl("/autoresponders/queue-popup?id=" + this.model.autoresponderId);
              var win = window.open(modalUrl, "_blank", "toolbar=0,scrollbars=1,location=0,status=1,menubar=0,resizable=1,width=600,height=400");
              utils.checkPopupWin(win);
            }));
          }
          domConstruct.place(domConstruct.toDom("<br />"), this.node_additionalMetaData);
          domConstruct.place(helperLink, this.node_additionalMetaData);
        }
      }
    }
  });
});