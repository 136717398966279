define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/experiments/ExperimentLog", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents", "dojo/text!./templates/editorWriteButton.html", "./PromptPane", "./tracking-helper", "mojo/context", "mojo/lib/flags", "mojo/neapolitan/utils"], function (declare, domClass, _WidgetBase, _TemplatedMixin, ExperimentLog, EcsTracking, EcsEvents, tplStr, PromptPane, TrackingHelper, context, flags, neapUtils) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    // data-dojo-attach-point in template
    button: null,
    // data-dojo-attach-point in template
    genaiWriteCopy: null,
    // Reference to the existing AI Prompt Pane
    promptPane: null,
    // Required dom node to place the prompt panel into. Appends it to the end.
    editorContainerDomNode: null,
    // Used in ECS tracking across the GenAI feature
    blockType: "",
    // The id for current block we're editing
    blockId: null,
    startingText: null,
    // If starting text is set, update the button label
    setStartingText: function (startingText) {
      this.startingText = neapUtils.blockHasDefaultText(this.blockId, startingText) ? null : startingText;
      this.genaiWriteCopy.innerText = this.startingText && this.startingText.length ? "Edit with AI" : "Write with AI";
    },
    /**
     * Tracks the "Write/Rewrite with AI" button click
     */
    _trackShowPromptClick: function () {
      if (this.startingText && this.startingText.length) {
        EcsTracking.track(EcsEvents.RewriteWithAIEvent, TrackingHelper.getEventParams());
        ExperimentLog.logOptimizely("nea-gen-ai-click-edit-with-ai");
        return;
      }
      EcsTracking.track(EcsEvents.NeaWriteWithAiEvent, TrackingHelper.getEventParams());
      ExperimentLog.logOptimizely("nea-gen-ai-click-write-with-ai");
    },
    /**
     * Shows the prompt panel. Click event of the write button
     *
     * @private
     */
    _showPromptPanel: function () {
      // Check for existing text selection
      // If no text is selected, check and see if editor contains text.
      // If it does, select all the text as that is the subject that will be used in genAI updates
      var text = window.editor.getSelection() ? window.editor.getSelection().getSelectedText() : null;
      if (!text) {
        text = neapUtils.stripHTML(window.editor.getData() || "");
        if (text) {
          window.editor.execCommand("selectAll");
        }
      }
      this._trackShowPromptClick();
      if (this.editorContainerDomNode === null) {
        console.error("editorContainerDomNode must be set");
        return;
      }

      // Get a new prompt pane everytime it's opened.
      if (this.promptPane) {
        this.promptPane.destroy();
      }
      this.promptPane = new PromptPane({
        blockId: this.blockId,
        isInRefineMode: !neapUtils.blockHasDefaultText(this.blockId) && text
      });
      this.promptPane.setStartingText(this.startingText);
      this.promptPane.placeAt(this.editorContainerDomNode, "last");
      this.promptPane.startup();
      this.promptPane.on("insert", function (textContent) {
        this.onInsert(textContent);
        if (context.flagIsOn(flags.EEE_NEA_GENAI_WRITE_EDIT_BUTTON_BUGFIX)) {
          this.setStartingText(textContent);
        } else {
          this.setStartingText(null);
        }
        this.promptPane.hide();
      }.bind(this));
      this.promptPane.show();
    },
    postCreate: function () {
      TrackingHelper.init({
        block_type: this.blockType,
        campaign_id: window.app.campaignId,
        campaign_type: window.app.campaignType
      });

      // Make sure we have the appropriate starting text on load
      // This helps determine what copy to use on the button
      var startingText;
      if (!this.startingText && !neapUtils.blockHasDefaultText(this.blockId)) {
        startingText = neapUtils.stripHTML(window.editor.getData() || "");
        this.setStartingText(startingText);
      } else if (!this.startingText) {
        startingText = neapUtils.stripHTML(window.editor.getData() || "");
        this.setStartingText(startingText);
      }
    },
    /**
     * dojo widget event that fires when a user selects some content to insert
     *
     * @param {string} textContent the text content that user chose from the prompt pane
     */
    onInsert: function (textContent) {},
    /**
     * Dijit lifecycle as part of destroying this widget.
     */
    destroy: function () {
      if (this.promptPane) {
        this.promptPane.destroy();
      }
      this.inherited(arguments);
    }
  });
});