/**
 * Color Format Toggle
 * @module mojo/widgets/pattern-library/ColorFormatToggle.js
 *
 * Widget dedicated to rendering and handling selection of color format
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/ButtonGroup",
// Templates
"dojo/text!./templates/ButtonItemBordered.html"], function (declare, ButtonGroup, borderedBtnTplStr) {
  return declare([ButtonGroup], {
    itemTpl: borderedBtnTplStr,
    defaultValue: "hex",
    options: [{
      "value": "hex",
      "label": "Hex"
    }, {
      "value": "rgb",
      "label": "RGB"
    }, {
      "value": "cmyk",
      "label": "CMYK"
    }, {
      "value": "less",
      "label": "Less"
    }],
    activeClass: "v-buttonGroupBordered--active",
    postCreate: function () {
      this.inherited(arguments);
      this.setSelected(this.defaultValue); // Smart default
    }
  });
});