/**
 * Create Template Base
 * @module mojo/widgets/create-template/CreateTemplateTabs
 *
 * Widget for the create template view
 */

define(["dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/app/templates", "mojo/lib/flags", "mojo/context",
// Mixins
"mojo/widgets/Renderable", "dojo/text!../templates/create-template/create-template-tabs.html"], function (aspect, declare, domConstruct, user, ready, dom, on, keys, domClass, query, registry, templateUtils, flags, context, Renderable, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    state: {},
    constructor: function (props) {
      this.inherited(arguments);
      var templateCategories = props.templatesByCategory ? Object.keys(props.templatesByCategory).map(function (templateCategoryKey) {
        return [templateCategoryKey, props.templatesByCategory[templateCategoryKey]];
      }) : [];
      this.state = {
        selectedTab: props.selectedTab,
        shouldShowPaywall: props.shouldShowPaywall === "true",
        templateCategories: templateCategories,
        hasUserCustomTemplateFeature: props.hasUserCustomTemplateFeature === "true",
        flagMartaCreateTemplate: context.flagIsOn(flags.UPG_MARTA_CREATE_TEMPLATE_UPGRADES),
        promoteFreeTrialsVariant: context.flagIsOn(flags.PX_PROMOTE_FREE_TRIALS_VARIANT)
      };
    },
    submitSearchTemplate: function () {
      var campaignId = null;
      var searchTerm = this.themeSearch ? this.themeSearch.value : "";
      var searchCategory = this.templateCategory ? this.templateCategory.value : "";
      var excludeIntegrations = true;
      var contentPaneId = "themes-list";
      templateUtils.searchForTemplate(campaignId, searchTerm, searchCategory, excludeIntegrations, contentPaneId);
    },
    onRestoreFocus: function () {
      var submitSearchTemplate = this.submitSearchTemplate.bind(this);
      if (this.themeSearch && this.templateCategory && this.searchButton) {
        on(this.themeSearch, "keypress", function (e) {
          var charOrCode = e.charCode || e.keyCode;
          if (charOrCode === keys.ENTER) {
            e.preventDefault();
            submitSearchTemplate();
          }
          if (charOrCode === keys.ESCAPE) {
            e.preventDefault();
            this.value = "";
            submitSearchTemplate();
          }
        });
        on(this.templateCategory, "change", submitSearchTemplate);
        on(this.searchButton, "click", submitSearchTemplate);
      }
      var tabContainer = registry.byId("template-container");
      var selectedTab = this.state.selectedTab && registry.byId(this.state.selectedTab);
      if (selectedTab) {
        tabContainer.selectChild(selectedTab);
      }
      aspect.after(tabContainer, "selectChild", function (tab) {
        user.saveSetting("my_templates_tab", tab.id);
      }, true);
    }
  });
});