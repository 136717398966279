/**
 * MobileContentSlat
 * @module mojo/widgets/ads/facebook/MobileContentSlat.js
 * @see mojo/widgets/ads/mobile-checklist/FacebookSlat.js
 *
 * The mobile version of the content editor. This is used as a loader
 * for both the view and edit modes of the Facebook content slat.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "dijit/Dialog", "mojo/widgets/ads/facebook/EditContentSlatMobile", "mojo/widgets/ads/facebook/ViewContentSlat", "velocity/velocity",
// Mixins
"mojo/widgets/Renderable", "../mobile-checklist/_EventAdapterMixin",
// Templates
"dojo/text!./mobile-content/templates/MobileContentSlat.html", "dojo/text!./mobile-content/templates/UnsavedChangesDialog.html",
// Doesn't require a var
"velocity/velocity.ui"], function (declare, lang, domClass, domAttr, request, topic, mUrl, utils, Dialog, EditMode, ViewMode, Velocity, Renderable, _EventAdapterMixin, tplStr, dialogTplStr) {
  var EDIT_MODE = "edit";
  var VIEW_MODE = "view";
  return declare([Renderable, _EventAdapterMixin], {
    templateString: tplStr,
    // Simple string to differentiate between edit and view
    contentMode: null,
    // Boolean to see if changes have been made to edit form
    editFormHasChanges: false,
    // Passed in boolean to check if user is in the simulator
    useSimulator: null,
    postCreate: function () {
      this._initViewOrEditMode();

      // Listen for change of input field in carousel and fire event
      topic.subscribe("content/carouselItem/inputChanged", this._handleInputChange.bind(this));

      // Listen for change of imageURL for carousel item to trigger save
      topic.subscribe("content/carouselItem/imageSaved", this._animateToasterInOut.bind(this));
    },
    /**
     * Call out to the checklist endpoint to grab all of the necessary data to load the widgets.
     *
     * @returns {*|Promise} Promise object representing the HTTP get response.
     * @private
     */
    _getData: function () {
      var pathAndParams = mUrl.toUrl("/ads/checklist", {
        "id": this.id,
        "itemId": this.slatName
      });
      return request.get(pathAndParams, {
        handleAs: "json"
      });
    },
    /**
     * After grabbing the data go ahead and create the View or Edit mode on
     * successful retrieval of the data.
     *
     * @private
     */
    _initViewOrEditMode: function () {
      this._getData().then(this._handleSuccessResponse.bind(this), this._handleErrorResponse.bind(this));
    },
    _handleSuccessResponse: function (response) {
      this.contentData = response.data;
      this.hasContent = response.data.hasContent;
      this.canEdit = response.data.canEdit;
      this.incompleteAttachmentIndex = response.data.incompleteAttachmentIndex;

      // If the ad has content to preview or the ad is in a non-editing state we
      // want to show the View Mode
      var showViewMode = this.hasContent || !this.canEdit;

      // If this ad already has content initialize and show the preview or view mode
      if (showViewMode) {
        this._initViewMode();
      } else {
        this._initEditMode();
      }

      // Set the content mode to show and hide certain elements
      this.set("contentMode", showViewMode ? VIEW_MODE : EDIT_MODE);

      // If the ad status is not in a Draft or Rejected state, don't let the user edit
      if (!this.canEdit) {
        domClass.add(this.editActionButton, "hide");
      }

      // Show the action bar after view or edit widget has been created
      domClass.remove(this.editActionBar, "hide");

      // Tell the router the slat is loaded
      // Ideally this would wait until we are certain both view/edit mode are done.
      this.onLoaded();
    },
    /**
     * On recreation of the preview we want to grab the latest/saved content from
     * the backend and generate a new preview
     *
     * @param {Object} response - Content data back from /ads/checklist
     * @private
     */
    _handleViewSuccessResponse: function (response) {
      this.contentData = response.data;
      this.hasContent = response.data.hasContent;
      this.incompleteAttachmentIndex = response.data.incompleteAttachmentIndex;
      this.set("contentMode", VIEW_MODE);

      // Hide the loading indicator and show preview
      this._toggleLoadingIndicator();

      // Re-enable the exit button when preview is created and shown
      domAttr.remove(this.exitBtn, "disabled", "disabled");
      this._initViewMode();
    },
    _handleErrorResponse: function () {
      throw new Error("An error occurred retrieving data for the content slat");
    },
    /**
     * Method to show the save and exit toolbar if a user makes changes in
     * the input fields. Only run if an input field has changed and if the edit form
     * doesn't already have changes
     *
     * @param {Object} inputField - Holds boolean if input has changes
     * @private
     */
    _handleInputChange: function (inputField) {
      if (inputField.hasChanged && !this.editFormHasChanges) {
        this._animateSaveExitBarIn();
        this.editFormHasChanges = true;
      }
    },
    /**
     * We have a custom Facebook previewer that allows us simulate what the ads will look like on Facebook
     * and Instagram. Load that widget only if there is content
     *
     * @private
     */
    _initViewMode: function () {
      this.viewWidget = new ViewMode();
      this.viewWidget.placeAt(this.viewModeContainer, "first");
      this.viewWidget.startup();
      this.viewWidget.setData(this.contentData);
    },
    _initEditMode: function () {
      this.editWidget = new EditMode({
        onDone: lang.hitch(this, "_handleEditModeSaveSuccess"),
        onDoneWithError: lang.hitch(this, "_handleEditModeSaveFailure"),
        onExit: lang.hitch(this, "_handleEditModeExit"),
        _handleSlatReset: lang.hitch(this, "onDone")
      });
      this.editWidget.placeAt(this.editContentContainer);
      this.editWidget.startup();
      this.editWidget.setData(this.contentData);
    },
    /**
     * When a user is viewing their ad we should allow them to edit their content
     * This event hides the preview and shows the content editor
     *
     * @private
     */
    _handleEditButtonPress: function () {
      this._initEditMode();
      this.set("contentMode", EDIT_MODE);
    },
    /**
     * When a user clicks on the exit button we want to either send them
     * back to the preview or back to the ad detail
     *
     * @private
     */
    _handleExitButtonPress: function () {
      // Check to see if they have content
      if (this.contentMode === VIEW_MODE) {
        this.onDone({});
        return;
      }
      this.editWidget.__cancel();
    },
    /**
     * On click of exit when a user makes changes fire the Unsaved Changes Confirmation Dialog
     *
     * @private
     */
    _handleExitConfirmButtonPress: function () {
      this.unsavedChangesDialog = new Dialog({
        title: "Exit and lose changes?",
        content: "<div class=\"dijitDialogPaneContentArea dijitDialogPaneContentArea--mobile\"><p>If you exit now, you'll lose all the changes you've made in this section. This cannot be undone.</p></div>",
        actionBarTemplate: dialogTplStr,
        draggable: false,
        closable: false,
        _handleConfirmExitButtonPress: lang.hitch(this, "_handleExitConfirmation")
      });
      this.unsavedChangesDialog.startup();
      this.unsavedChangesDialog.show();
    },
    /**
     * Handle when a user confirms exiting and losing all their changes from the dialog
     *
     * @private
     */
    _handleExitConfirmation: function () {
      // Destroy the dialog
      this.unsavedChangesDialog.destroy();
      this._animateSaveExitBarOut();

      // If the user came in on a blank slate and don't make any changes then shoot
      // them back out to the ad detail screen
      this._handleEditModeExit();
    },
    _handleSaveButtonPress: function () {
      domClass.add(this.saveBtn, "button-loading");
      domAttr.set(this.saveBtn, "disabled", "disabled");
      this.editWidget.__save();
    },
    /**
     * On successful save show the original action bar so user can continue or exit edit mode
     *
     * @private
     */
    _handleEditModeSaveSuccess: function () {
      domClass.remove(this.saveBtn, "button-loading");
      domAttr.remove(this.saveBtn, "disabled", "disabled");
      domClass.add(this.incompleteAdErrorMessage, "hide");
      this._animateToasterInOut();
      this._animateSaveExitBarOut();
    },
    /**
     * On unsuccessful save we want to remove loading indicator and re-enable the button
     *
     * @private
     */
    _handleEditModeSaveFailure: function () {
      domClass.remove(this.saveBtn, "button-loading");
      domAttr.remove(this.saveBtn, "disabled", "disabled");
    },
    /**
     * Method to reset the view widget and create a new preview with updated content
     *
     * @private
     */
    _resetViewMode: function () {
      if (this.viewWidget) {
        this.viewWidget.destroyRecursive(false);
      }

      // Disable the exit button while generating the preview
      domAttr.set(this.exitBtn, "disabled", "disabled");

      // Show loading indicator while preview is being generated
      this._toggleLoadingIndicator();
      this._getData().then(this._handleViewSuccessResponse.bind(this), this._handleErrorResponse.bind(this));
    },
    _gotoViewModeFromEdit: function () {
      // Destroy the edit widget
      this.editWidget.destroyRecursive(false);

      // Re-create the preview with updated content
      this._resetViewMode();
    },
    /**
     * Animate the toaster message
     *
     * @private
     */
    _animateToasterInOut: function () {
      Velocity(this.toastContainer, "transition.slideUpIn", {
        duration: 250,
        complete: lang.hitch(this, function () {
          Velocity(this.toastContainer, "transition.slideDownOut", {
            delay: 2000,
            duration: 250
          });
        })
      });
    },
    _animateSaveExitBarIn: function () {
      this.editFormHasChanges = true;
      Velocity(this.actionToolbarExit, "transition.slideDownOut", {
        duration: 250,
        complete: lang.hitch(this, function () {
          Velocity(this.actionToolbarSaveExit, "transition.slideUpIn", {
            duration: 250
          });
        })
      });
    },
    _animateSaveExitBarOut: function () {
      this.editFormHasChanges = false;
      Velocity(this.actionToolbarSaveExit, "transition.slideDownOut", {
        duration: 250,
        complete: lang.hitch(this, function () {
          Velocity(this.actionToolbarExit, "transition.slideUpIn", {
            duration: 250
          });
        })
      });
    },
    /**
     * Custom setter for which view to show for content slat Called by this.set("contentMode", "");
     *
     * @param {string} mode - What view mode to show
     * @private
     */
    _setContentModeAttr: function (mode) {
      this._set("contentMode", mode);

      // Toggle the different view modes based on the content mode attribute
      domClass.toggle(this.editActionButton, "hide", mode === EDIT_MODE);
      domClass.toggle(this.viewModeContainer, "hide", mode === EDIT_MODE);

      // If there is an incomplete carousel item in the user's ad we want to surface an error message, but only if they
      // can still edit the ad.
      domClass.toggle(this.incompleteAdErrorMessage, "hide", !this.incompleteAttachmentIndex > 0 || !this.canEdit);

      // Change the button text if the one the carousel items are incomplete
      this.editActionButton.innerText = !this.incompleteAttachmentIndex > 0 ? "Edit Content" : "Resolve";
    },
    /**
     * Show loading indicator while previews are being created then hide when completed
     *
     * @private
     */
    _toggleLoadingIndicator: function () {
      domClass.toggle(this.loadingIndicator, "hide");
      domClass.toggle(this.editViewContainer, "hide");
    },
    /**
     * When a user exits the edit mode we want to send them back
     * to the preview screen if they have content
     *
     * @private
     */
    _handleEditModeExit: function () {
      // Check to see if they have valid content
      if (!this.editWidget.hasContent()) {
        this.onDone({});
        return;
      }

      // Clean up the widget
      this.editWidget.destroyRecursive(false);

      // Re-create the preview with updated content
      this._resetViewMode();
    }
  });
});