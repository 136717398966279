define(["dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils"], function (on, query, mUtils) {
  var PROMOTION_BUTTON_SELECTOR = "[data-promotion-loading-button]";
  var exports = {
    _canClickPromotion: true,
    setupButtonEvents: function () {
      // when promotions are clicked,
      // server takes some time to create new ads/automtion
      // the result will be a page reload on error or a redirect
      // so we can disable any button trying create from a promotion
      query(PROMOTION_BUTTON_SELECTOR).forEach(function (el) {
        on(el, "click", function (e) {
          var shouldCreateFromPromotion = this._canClickPromotion;
          if (this._canClickPromotion) {
            mUtils.toggleButtonLoadingState(el);
            this._canClickPromotion = false;
          } else {
            e.preventDefault();
            e.stopPropagation();
          }
          return shouldCreateFromPromotion;
        }.bind(this));
      }.bind(this));
    }
  };
  return exports;
});