define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dojo/text!./templates/ProgressCircle.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "d3/d3", "dojo/NodeList-traverse", "dojo/NodeList-manipulate"], function (declare, _Widget, _TemplatedMixin, template, on, query, domAttr, lang, Stateful, d3) {
  var _tau = 2 * Math.PI;
  var spin = function (selection, duration) {
    selection.transition().ease("linear").delay(0).duration(duration).attrTween("transform", function () {
      return d3.interpolateString("rotate(0)", "rotate(360)");
    });
  };
  var styleTween = function (transition, name, color) {
    transition.styleTween(name, function () {
      return d3.interpolate(this.style[name], color);
    });
  };
  return declare("ProgressCircle", [_Widget, _TemplatedMixin, Stateful], {
    templateString: template,
    percentComplete: 0,
    circleSize: 72,
    // px

    pathWidth: 2,
    // px

    pathBackgroundColor: "#e0e0e0",
    progressColor: "#52bad5",
    successColor: "#72c1b0",
    errorColor: "#EE836E",
    endValue: 100,
    canceled: false,
    cancelComplete: false,
    loading: true,
    loaderDuration: 1000,
    status: '',
    allowedStatuses: ['loading', 'progress', 'success', 'canceling', 'canceled'],
    buildRendering: function () {
      this.inherited(arguments);
      this.svg = d3.select(this.progressCircle).append("svg").attr("width", this.circleSize).attr("height", this.circleSize).append("g").attr("transform", "translate(" + this.circleSize / 2 + "," + this.circleSize / 2 + ")");
      this.arc = d3.svg.arc().innerRadius(this.circleSize / 2 - this.pathWidth).outerRadius(this.circleSize / 2).startAngle(0);
      this.bg = this.svg.append("path").datum({
        endAngle: _tau
      }).style("fill", this.pathBackgroundColor).attr("d", this.arc);
    },
    postCreate: function () {
      this.render();
      this.watch('status', function (name, oldValue, newValue) {
        if (this.allowedStatuses.indexOf(newValue) == -1) return;
        switch (newValue) {
          case 'loading':
            this.toggleForeground('off');
            this.toggleTextValue('off');
            this.toggleLoader('on');
            break;
          case 'progress':
            this.toggleLoader('off');
            this.toggleForeground('on');
            this.toggleTextValue('on');
            break;
          case 'success':
            this.toggleLoader('off');
            this.toggleForeground('on');
            this.toggleTextValue('off');
            this.set('percentComplete', 100); // Place last so foreground animates
            break;
          case 'canceling':
            this.set('canceled', true); // Place first so foreground initializes with correct status color

            this.toggleForeground('off');
            this.toggleTextValue('off');
            this.toggleLoader('on');
            break;
          case 'canceled':
            this.set('canceled', true); // Place first so foreground initializes with correct status color
            this.set('cancelComplete', true);
            this.toggleLoader('off');
            this.toggleForeground('on');
            this.toggleTextValue('off');
            this.set('percentComplete', 100); // Place last so foreground animates
            break;
        }
        this.render();
      });
    },
    toggleLoader: function (value) {
      if (value === 'on' && typeof this.loader === 'undefined') {
        this.loader = this.svg.append("path").datum({
          endAngle: 20 / 100 * _tau
        }).style("fill", this._getStatusColor()).attr("d", this.arc);
        var _self = this;
        spin(_self.loader, _self.loaderDuration);
        this.loaderIntervalId = setInterval(function () {
          spin(_self.loader, _self.loaderDuration);
        }, _self.loaderDuration);
      } else if (value === 'off' && this.loader) {
        this.loader.transition().delay(0).call(styleTween, "fill", this.pathBackgroundColor);
        this.loader.remove();
        delete this.loader;
        clearInterval(this.loaderIntervalId);
      }
    },
    toggleForeground: function (value) {
      if (value === 'on' && typeof this.foreground === 'undefined') {
        this.foreground = this.svg.append("path").datum({
          endAngle: this.percentComplete / 100 * _tau
        }).style("fill", this._getStatusColor()).attr("d", this.arc);
      } else if (value === 'off' && this.foreground) {
        this.foreground.remove();
        delete this.foreground;
      }
    },
    toggleTextValue: function (value) {
      if (value === 'on' && typeof this.textValue === 'undefined') {
        this.textValue = this.svg.append("text").attr("x", 0).attr("y", 0).attr("text-anchor", "middle").attr("dominant-baseline", "central").attr("font-size", "17px").attr("font-weight", "600").text(this.percentComplete + "%");
      } else if (value === 'off' && this.textValue) {
        this.textValue.remove();
        delete this.textValue;
      }
    },
    render: function () {
      var _self = this;
      var arcTween = function (transition, angle, endValue) {
        transition.attrTween("d", function (d) {
          var interp = d3.interpolate(d.endAngle, angle);
          return function (t) {
            d.endAngle = interp(t);
            // update text as well but in whole numbers only
            if (_self.textValue) _self.textValue.text(parseInt(d.endAngle * 100 / _tau, 10) + "%");
            return _self.arc(d);
          };
        });
      };
      if (this.foreground) {
        this.foreground.transition().delay(222).duration(666).call(arcTween, _self.percentComplete / 100 * _tau, _self.percentComplete).call(styleTween, "fill", _self._getStatusColor());
      }
      if (this.textValue) {
        this.textValue.transition().delay(222).duration(333).styleTween("fill", function (d, i, a) {
          if (_self.isCanceled()) {
            return d3.interpolate(a, _self._getStatusColor());
          }
        }).styleTween("opacity", function (d, i, a) {
          if (_self.isComplete()) {
            return d3.interpolate(a, 0);
          }
        });
      }
      d3.select(this.successImage).transition().delay(1333).duration(333).styleTween("opacity", function (d, i, a) {
        if (_self.status === 'success') {
          return d3.interpolate(a, 1);
        } else {
          return d3.interpolate(a, 0);
        }
      }).styleTween("transform", function (d, i, a) {
        return d3.interpolate(a, 'scale(1)');
      });
      d3.select(this.cancelImage).transition().delay(1333).duration(333).styleTween("opacity", function (d, i, a) {
        if (_self.status === 'canceled') {
          return d3.interpolate(a, 1);
        } else {
          return d3.interpolate(a, 0);
        }
      }).styleTween("transform", function (d, i, a) {
        return d3.interpolate(a, 'scale(1)');
      });
    },
    /**
     *  Set the percent complete.
     */
    setPercent: function (num) {
      if (typeof num != 'undefined') {
        this.set('percentComplete', num);
        this.render();
      }
    },
    isLoading: function () {
      return typeof this.loader !== 'undefined';
    },
    isCanceled: function () {
      return this.canceled;
    },
    isSuccess: function () {
      return this.isComplete() && !this.isCanceled();
    },
    /**
     *  Progress is finished, either canceled or success
     */
    isComplete: function () {
      return this.percentComplete === 100;
    },
    /**
     *  Get the color representing the current status
     */
    _getStatusColor: function () {
      if (this.isCanceled()) {
        return this.errorColor;
      } else if (this.isSuccess()) {
        return this.successColor;
      } else {
        return this.progressColor;
      }
    }
  });
});