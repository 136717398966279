define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/textAlignInput.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_WithResetLinkMixin"], function (declare, _WidgetBase, _TemplatedMixin, tpl, on, query, domAttr, _WithResetLinkMixin) {
  return declare([_WidgetBase, _TemplatedMixin, _WithResetLinkMixin], {
    templateString: tpl,
    initialized: false,
    buildRendering: function () {
      // If name is passed in, use that as radio's name, but otherwise create a unique one based on id
      if (!this.name) {
        this.name = this.id + "_textAlign";
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      var self = this;

      // Keep this original value to reset.
      this.originalValue = this.value;
      self.inherited(arguments);
      self.initialized = true;
      self._toggleActive();
      this.own(on(this.domNode, "[type=radio]:change", function () {
        self.set("value", this.value);
      }));
    },
    _setValueAttr: function (value) {
      this._set("value", value);
      if (this.initialized) {
        this._toggleActive();
      }
      this.onChange(value);
    },
    _toggleActive: function () {
      var value = this.get("value");
      if (value) {
        var radio = query("[type=radio][value=" + value + "]", this.domNode)[0];
        if (radio) {
          radio.checked = true;
        }
      } else {
        var checkedRadio = query("[type=radio]:checked", this.domNode)[0];
        if (checkedRadio) {
          checkedRadio.checked = false;
        }
      }
      this.toggleResetLink(value !== "");
    },
    onReset: function () {
      this.set("value", "");
    },
    reset: function () {
      this.set("value", this.originalValue);
    },
    onChange: function (value) {}
  });
});