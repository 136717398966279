define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable",
// Mixin
"./_ContentPreviewMixin",
// Templates
"dojo/text!./templates/InstagramPreview.html"], function (declare, lang, domConstruct, domStyle, domAttr, on, query, Renderable, _PreviewMixin, igTplStr) {
  return declare([Renderable, _PreviewMixin], {
    templateString: igTplStr,
    postMixInProperties: function () {
      this._truncateMessage();
    },
    postCreate: function () {
      this.inherited(arguments);

      // For carousel ads set the width of the container to handle the transitions
      if (this.numberOfAttachments > 1) {
        domStyle.set(this.contentImages, "width", this.numberOfAttachments * 100 + "%");
      }
    },
    /**
     * Checks the message length against the max character count limit
     * and truncates the message and adds "...more" to end
     *
     * @returns {string} - New message value
     */
    _truncateMessage: function () {
      var maxCharCount = this.hasAttachments ? 145 : 130;
      if (this.message.length > maxCharCount) {
        this.message = this.message.substr(0, maxCharCount) + " ... more";
      }
      return this.message;
    },
    _handleCarousel: function () {
      var self = this;
      var button = "[data-button-action]";
      var selectedAdIndex = 0;
      var transitionAmount;
      on(this.carouselActions, on.selector(button, "click"), function (e) {
        var buttonAction = domAttr.get(e.target, "data-button-action");
        var numberOfAttachments = self.content.length;
        var buttonNodes = query("[data-button-node]");
        var titleNodes = query("[data-title-node]");
        var bulletNodes = query("[data-bullet-node]");
        switch (buttonAction) {
          case "next":
            selectedAdIndex++;

            // Set the current selected ad index to itself, but when user reaches the end
            // set the selected index to 0 to start over cycle
            selectedAdIndex = selectedAdIndex <= numberOfAttachments - 1 ? selectedAdIndex : selectedAdIndex = 0;
            break;
          case "previous":
            selectedAdIndex--;

            // Set the current selected ad index to itself, but when user reaches the end
            // set the selected index to the last ad item in carousel
            selectedAdIndex = selectedAdIndex < 0 ? selectedAdIndex = numberOfAttachments - 1 : selectedAdIndex;
            break;
          default:
            throw Error("This button action " + buttonAction + " is not available. Must be next or previous");
        }
        transitionAmount = selectedAdIndex / numberOfAttachments * 100;
        domStyle.set(self.contentImages, "transform", "translateX(-" + transitionAmount + "%)");

        // Hide the non-active call to action button and title for attachments
        self.toggleActiveClass(buttonNodes, selectedAdIndex);
        self.toggleActiveClass(titleNodes, selectedAdIndex);

        // Add deselected class for bullets in the carousel
        self.toggleActiveClass(bulletNodes, selectedAdIndex, "c-igAdCarousel_bullet--deselected");
      });
    }
  });
});