define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/throttle", "dojox/html/entities", "dijit/focus", "dijit/registry", "mojo/lib/logger", "mojo/utils", "mojo/url", "mojo/user", "mojo/lib/flags", "mojo/utils/string",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/_FocusMixin",
// Templates
"dojo/text!./templates/tag-overlay.html",
//Widgets
"mojo/widgets/tags/TagActionButton", "mojo/widgets/tags/Api",
//Translations
"mojo/context", "mojo/utils/I18nTranslation"], function (declare, lang, domAttr, keys, on, domConstruct, domClass, query, request, throttle, htmlEntities, focusUtil, registry, logger, utils, mUrl, user, flags, stringUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _FocusMixin, overlayTplString, TagActionButton, TagApi, context, I18nTranslation) {
  var MAX_LENGTH = 100;
  var MAX_RENDER_TAGS = 500;
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _FocusMixin], {
    templateString: overlayTplString,
    tags: null,
    bulkUntagTarget: null,
    tagActionClass: null,
    listId: null,
    tagCreationAllowed: false,
    exactMatch: false,
    activeNode: null,
    isLoading: false,
    analyticsData: "",
    analyticsCategory: null,
    analyticsStatus: null,
    // Whether or not we should show a confirmation dialog and the dialog contents
    confirmationRequired: false,
    confirmationTitle: "",
    confirmationHelpText: "",
    // There are multiple confirmation dialogs and each has a specific id
    // This helps us target the correct one
    confirmationWidgetId: null,
    // Fields to track the state of marked tags
    all: [],
    mixed: [],
    tagSelectionCTAText: null,
    hideManageTags: null,
    translatedTerms: {},
    /**
     * Initialize data
     */
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedTerms = value;
        }.bind(this));
      }
      this.inherited(arguments);
      this.setupTagInput();
      domAttr.set(this.manageTagsButton, "href", mUrl.toUrl("/audience/tags", {
        id: this.listId
      }));
      on(this.manageTagsButton, "click", lang.hitch(this, function () {
        var label = this.tags && this.tags.length > 0 ? "Has Tags" : "No Tags";
        logger.googleAnalytics.trackEvent("Manage Tags", this.analyticsData, label);
      }));
      on(this.tagInput, "blur, focus", lang.hitch(this, function () {
        this.toggleFocus();
      }));

      /**
       * Overrides default template's
       * CTA with optional text
       */
      if (this.tagSelectionCTAText) {
        this.tagSelectionCTA.innerHTML = this.tagSelectionCTAText;
      }
      if (this.hideManageTags) {
        domClass.add(this.manageTagsButton, "hide");
      }
      TagApi.watchTags(lang.hitch(this, function (tags) {
        this.tags = tags;
        this.tags.sort(this.sortTagsByName);
        this.refresh();
      }), this.listId);
    },
    /**
     * Re-draw the tag list based on provided states
     * Expects an object with keys "all" and "mixed"
     * @param {object} tagStates to parse
     */
    setSelection: function (tagStates) {
      if (typeof tagStates !== "object") {
        return;
      }
      this.all = tagStates.all || [];
      this.mixed = tagStates.mixed || [];
      this.populateTagsList(this.tags);
    },
    /**
     * Set up UI and listeners for tag input
     */
    setupTagInput: function () {
      this.setPlaceholderAndManageText();
      if (!this.tagCreationAllowed) {
        this.hideCreation();
      }
      on(this.overlayContainer, "keydown", lang.hitch(this, "handleKeydown"));
      // Throttle the custom enterKeydown event to prevent multiple API calls in quick succession
      on(this.overlayContainer, "enterKeydown", throttle(lang.hitch(this, "handleEnterKeydown"), 500));
      on(this.tagInput, "input", lang.hitch(this, "handleInput"));
      on(this.newTagContainer, "click", lang.hitch(this, "handleCreateTag"));
    },
    /**
     * Set the input placeholder text based
     * on creation ability and tag count
     */
    setPlaceholderAndManageText: function () {
      if (this.tagCreationAllowed) {
        if (this.tags && this.tags.length > 0) {
          domAttr.set(this.tagInput, "placeholder", this.translatedTerms["tag_manager_create_or_find_a_tag"] || "Create or find a tag");
          domClass.remove(this.manageTagsContainer, "hide");
        } else {
          domAttr.set(this.tagInput, "placeholder", this.translatedTerms["tag_manager_create_right_here"] || "Create your first tag right here");
          domClass.add(this.manageTagsContainer, "hide");
        }
      } else {
        domAttr.set(this.tagInput, "placeholder", this.translatedTerms["tag_manager_find_a_tag"] || "Find a tag");
        domClass.remove(this.manageTagsContainer, "hide");
      }
    },
    /**
     * Get the user-entered tag input value
     * @returns {string} current input value
     */
    getInputValue: function () {
      return this.tagInput.value;
    },
    /**
     * Set the value of the tag input
     * @param {string} inputValue Value to set
     */
    setInputValue: function (inputValue) {
      this.tagInput.value = inputValue;
    },
    /**
     * Set the loading state
     * @param {boolean} isLoading Value to set
     */
    setLoadingState: function (isLoading) {
      if (isLoading) {
        domClass.remove(this.loadingContainer, "hide");
        domClass.add(this.taggingContainer, "hide");
      } else {
        domClass.add(this.loadingContainer, "hide");
        domClass.remove(this.taggingContainer, "hide");
        this.focusInput();
      }
      this.isLoading = isLoading;
    },
    /**
     * Populate the available tags list with the provided list
     * @param {array} tagList List of tags to show
     */
    populateTagsList: function (tagList) {
      //Translations
      this.noTagsYetLabel.innerText = this.translatedTerms["tag_manager_no_tags_yet"] || "No tags yet";
      this.createNewTagLabel.innerText = this.translatedTerms["tag_manager_create_new_tag"] || "Create a New Tag:";
      this.noTagsFoundLabel.innerText = this.translatedTerms["tag_manager_no_tags_found"] || "No tags found";
      this.manageTagsLabel.innerText = this.translatedTerms["tag_manager_manage_tags"] || "Manage Tags";
      this.cancelButton.innerText = this.translatedTerms["tag_manager_cancel"] || "Cancel";
      this.tagAllWarning.innerText = this.translatedTerms["tag_manager_tag_all_contacts"] || "You are about to tag all contacts.";
      this.confirmButton.innerText = this.translatedTerms["tag_manager_confirmation_button_text"] || "Confirm";
      this.tagConfirmationDialog.title = this.translatedTerms["all_contacts_bulk_action_header"] || "Are you sure?";
      this.chooseATagLabel.innerText = this.translatedTerms["tag_manager_choose_a_tag"] ? this.translatedTerms["tag_manager_choose_a_tag"] + ":" : "Choose a Tag:";
      var inputValue = this.getInputValue();
      if (this.tags && this.tags.length > 0) {
        tagList.map(function (tag) {
          tag.tag_name = htmlEntities.decode(tag.tag_name);
        });
        this.showSearchResults(tagList);
        domClass.add(this.noTagsContainer, "hide");
      } else {
        domClass.remove(this.noTagsContainer, "hide");
      }
      if (inputValue && inputValue.length > 0) {
        //No tags to display but the user has typed some input
        if (this.tagCreationAllowed && !this.exactMatch && inputValue.trim().length > 0) {
          this.showCreation();
        } else {
          this.hideCreation();
        }
      } else {
        this.hideCreation();
      }
    },
    /**
     * Handle typing into the search for/create tags text input
     */
    handleInput: function () {
      this.stripEmojisFromInput();
      var inputValue = this.getInputValue();

      //if 1+ characters are entered, perform a search for matching tags
      if (inputValue && inputValue.length > 0) {
        var results = this.searchTags(inputValue);
        this.populateTagsList(results);
        this.newTagContainer.innerText = inputValue;
      } else {
        this.populateTagsList(this.tags);
      }
      utils.checkInputLength(this.tagInput, this.newTagCharsRemainingCount, MAX_LENGTH, true);
    },
    /**
     * Strips emojis from a provided input
     * @param {object} input the input to be stripped
     */
    stripEmojisFromInput: function () {
      this.setInputValue(stringUtils.stripEmojis(this.getInputValue()));
    },
    /**
     * Handle special character input
     * @param {object} event Keydown event
     */
    handleKeydown: function (event) {
      if (event.keyCode === keys.ENTER) {
        //if user presses ENTER emit event for active node
        event.preventDefault();
        // Emit a custom event which we can throttle to prevent multiple API calls in quick succession
        on.emit(this.overlayContainer, "enterKeydown", event);
      } else if (event.keyCode === keys.DOWN_ARROW || event.keyCode === keys.UP_ARROW) {
        //if user presses UP or DOWN
        this.navigate(event);
      } else if (!domClass.contains(this.createNewContainer, "hide")) {
        //no special keys were pressed
        domClass.remove(this.newTagContainer, "active");
      }
    },
    /**
     * Handles the enter keydown event and defines what actions to take give the active node
     */
    handleEnterKeydown: function () {
      if (this.activeNode === this.tagInput) {
        this.handleCreateTag();
      } else {
        on.emit(this.getActiveNode(), "click", {
          bubbles: false,
          cancelable: true
        });
      }
    },
    /**
     * Toggle visibility of character count on focus and blur state changes
     */
    toggleFocus: function () {
      domClass.toggle(this.newTagCharsRemainingContainer, "hide");
    },
    /**
     * Takes in an event and uses it to navigate up and down the tags overlay
     *
     * @param {object} event Keyboard event
     */
    navigate: function (event) {
      event.preventDefault();
      var direction = null;
      if (event.keyCode === keys.DOWN_ARROW) {
        direction = "down";
      } else if (event.keyCode === keys.UP_ARROW) {
        direction = "up";
      }
      var keyableNodes = this.getkeyableNodes();
      var activeNode = this.getActiveNode();
      var nextKeyableNode = this.getNextKeyableNode(activeNode, keyableNodes, direction);
      this.setActiveNode(nextKeyableNode);
    },
    /**
     * Gets a list of nodes with a "keyable" class
     *
     * @returns {array} a list of nodes
     */
    getkeyableNodes: function () {
      return query(".keyable", this.overlayContainer);
    },
    /**
     * Gets the active node
     *
     * @returns {object} node
     */
    getActiveNode: function () {
      return this.activeNode;
    },
    /**
     * Gets the next available keyable node to be focused
     *
     * @param {object} currentNode the active node
     * @param {array} nodeList list of keyable nodes
     * @param {string} direction the direction we are moving
     *
     * @returns {node} the next available keyable node
     */
    getNextKeyableNode: function (currentNode, nodeList, direction) {
      var currentIndex = nodeList.indexOf(currentNode);
      var nextNodeIndex = 0;
      if (direction === "down") {
        nextNodeIndex = currentIndex + 1;
      } else if (direction === "up") {
        nextNodeIndex = currentIndex - 1;
      }

      // if the next node is not keyable
      // iterate to find next keyable node
      if (!this.isKeyable(nodeList[nextNodeIndex])) {
        // if the direction we are keying is down
        // we need to increment to find the next keyable node
        // if we don't find one we are at the end of the list
        // go to the top
        if (direction === "down") {
          for (var dwnIndex = nextNodeIndex; dwnIndex <= nodeList.length; dwnIndex++) {
            if (this.isKeyable(nodeList[dwnIndex])) {
              return nodeList[dwnIndex];
            }
          }
          return nodeList[0];
        }
        // if the direction we are keying is up
        // we need to decrement to find the next keyable node
        // if we don't find one we are at the top of the list
        // go to the bottom
        if (direction === "up") {
          for (var upIndex = nextNodeIndex; upIndex >= 0; upIndex--) {
            if (this.isKeyable(nodeList[upIndex])) {
              return nodeList[upIndex];
            }
          }
          return nodeList[nodeList.length - 1];
        }
      }
      return nodeList[nextNodeIndex];
    },
    /**
     * Check if a node is currently keyable
     *
     * @param {object} node DOM node
     *
     * @returns {bool} true if a node is currently keyable
     */
    isKeyable: function (node) {
      // handle undefine case
      if (!node) {
        return false;
      }
      // if a node is hidden,
      // its parent is hidden,
      // or it doesn't have "keyable" class
      // its not keyable
      return !domClass.contains(node, "hide") && !domClass.contains(node.parentNode, "hide") && domClass.contains(node, "keyable");
    },
    /**
     * Moves the active class and from the current node to a new node
     *
     * @param {object} newNode DOM node
     */
    setActiveNode: function (newNode) {
      this.activeNode = newNode;
      newNode.focus();
    },
    /**
     * Create TagActionButtons for each tag in the provided list
     * Sort the list of buttons by state and append to the DOM
     * @param {array} tagList list of tags to render
     */
    setupTagButtons: function (tagList) {
      var self = this;
      var tagActionButtons = [];
      tagList.some(function (tag, index) {
        var tagActionButton = new TagActionButton({
          "tagID": tag.tag_id,
          "tagName": tag.tag_name
        });
        self.all.forEach(function (allTag) {
          if (allTag === tag.tag_id) {
            tagActionButton.setTagState("ALL");
          }
        });
        if (!tagActionButton.tagState) {
          self.mixed.forEach(function (mixedTag) {
            if (mixedTag === tag.tag_id) {
              tagActionButton.setTagState("MIXED");
            }
          });
        }
        if (!tagActionButton.tagState) {
          tagActionButton.setTagState("NONE");
        }
        tagActionButton.on("tagClick", function () {
          self.handleTagClick(tag);
        });
        tagActionButtons.push(tagActionButton);
        return index >= MAX_RENDER_TAGS;
      });
      tagActionButtons.sort(this.sortTagsByState);
      tagActionButtons.forEach(lang.hitch(this, function (button) {
        button.placeAt(this.searchResultsList, "last");
      }));
    },
    /**
     * Handle click events on TagActionButtons
     * @param {object} tag Tag to apply or remove
     */
    handleTagClick: function (tag) {
      if (this.confirmationRequired) {
        this.tagConfirmationDialog.show();
        on.once(this.tagConfirmationCancel, "click", lang.hitch(this, function () {
          this.tagConfirmationDialog.hide();
        }));
        if (this.bulkUntagTarget) {
          this.confirmationWarningTextContainer.innerHTML = "You're about to remove the tag <b>" + tag.tag_name + "</b> from your selected contacts.";
          domClass.remove(this.confirmationWarningInputContainer, "hide");
          on(this.tagConfirmationConfirm, "click", lang.hitch(this, function () {
            this.performUntagAllAction(this.bulkUntagTarget, tag);
          }));
          return;
        }
        on.once(this.tagConfirmationConfirm, "click", lang.hitch(this, function () {
          this.performTagAction(tag);
          this.tagConfirmationDialog.hide();
        }));
      } else {
        this.performTagAction(tag);
      }
    },
    /**
     * Create and apply the user-entered tag
     */
    handleCreateTag: function () {
      var inputValue = this.getInputValue();
      if (this.confirmationRequired) {
        this.tagConfirmationDialog.show();
        on.once(this.tagConfirmationCancel, "click", lang.hitch(this, function () {
          this.tagConfirmationDialog.hide();
        }));
        on.once(this.tagConfirmationConfirm, "click", lang.hitch(this, function () {
          // The confirmation modal opening makes the overlay close and therefore reset the input value
          // so we need to re-inject the value here for external event handlers
          this.tagInput.value = inputValue;
          this.onCreateTag();
          this.tagConfirmationDialog.hide();
        }));
      } else {
        this.onCreateTag();
      }
    },
    /**
     * Apply and remove the selected tag as appropriate
     * @param {Object} tag Tag to apply or remove
     */
    performTagAction: function (tag) {
      var shouldRemove;
      this.all.forEach(lang.hitch(this, function (allTag) {
        if (allTag === tag.tag_id) {
          shouldRemove = true;
        }
      }));
      if (shouldRemove) {
        this.onRemoveTag(tag);
      } else {
        this.onApplyTag(tag);
      }
    },
    /**
     * Removes tag from entire audience or segment
     * @param {String} bulkUntagTarget Target (audience or segment) to untag
     * @param {Object} tag Tag to untag from target
     */
    performUntagAllAction: function (bulkUntagTarget, tag) {
      event.stopPropagation();
      event.preventDefault();
      var validatorInput, isValid;
      var untagAudience = false;
      if (bulkUntagTarget === "audience") {
        validatorInput = registry.byId("confirmUntagAudienceInput");
        untagAudience = true;
      } else if (bulkUntagTarget === "segment") {
        validatorInput = registry.byId("confirmUntagSegmentInput");
      }
      isValid = validatorInput ? validatorInput.validate() : false;
      if (isValid) {
        this.onRemoveTag(untagAudience, tag);
        this.tagConfirmationDialog.hide();
      }
    },
    /**
     * Sort the tag list based on tag name
     * @param {object} tag1 First tag to compare
     * @param {object} tag2 Second tag to compare
     * @returns {number} comparison Comparison result
     */
    sortTagsByName: function (tag1, tag2) {
      var comparison = 0;
      if (tag1.tag_name > tag2.tag_name) {
        comparison = 1;
      } else if (tag1.tag_name < tag2.tag_name) {
        comparison = -1;
      }
      return comparison;
    },
    /**
     * Sort the tag button list based on tag selection state
     * @param {object} tag1 First tag to compare
     * @param {object} tag2 Second tag to compare
     * @returns {number} comparison Comparison result
     */
    sortTagsByState: function (tag1, tag2) {
      var comparison = 0;
      if (tag1.tagState > tag2.tagState) {
        comparison = 1;
      } else if (tag1.tagState < tag2.tagState) {
        comparison = -1;
      }
      return comparison;
    },
    /**
     * Perform a case-insensitive search against the list of available tags
     * @param {string} searchText User-entered search term
     * @returns {Array} results Search results
     */
    searchTags: function (searchText) {
      this.exactMatch = false;
      var self = this;
      var results = [];
      searchText = searchText.trim();
      if (this.tags) {
        this.tags.forEach(function (tag) {
          tag.tag_name = String(tag.tag_name);
          if (tag.tag_name.toLowerCase() === searchText.toLowerCase()) {
            self.exactMatch = true;
            results.unshift(tag);
          } else if (tag.tag_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            results.push(tag);
          }
        });
      }
      return results;
    },
    /**
     * Show search results or empty list state
     * @param {array} tagList List of tags to show
     */
    showSearchResults: function (tagList) {
      domConstruct.empty(this.searchResultsList);
      if (tagList.length < 1) {
        domClass.add(this.searchResultsContainer, "hide");
        if (!this.tagCreationAllowed) {
          domClass.remove(this.noSearchResultsContainer, "hide");
        }
      } else {
        this.setupTagButtons(tagList);
        domClass.remove(this.searchResultsContainer, "hide");
        domClass.add(this.noSearchResultsContainer, "hide");
      }
    },
    /**
     * Hide tag creation UI
     */
    hideCreation: function () {
      domClass.add(this.createNewContainer, "hide");
    },
    /**
     * Show tag creation UI
     */
    showCreation: function () {
      domClass.remove(this.createNewContainer, "hide");
    },
    /**
     * Refresh the state of the widget
     */
    refresh: function () {
      this.setPlaceholderAndManageText();
      this.setInputValue(null);
      this.hideCreation();
      this.populateTagsList(this.tags);
      this.setLoadingState(false);
      this.scrollContainer.scrollTop = 0;
    },
    /**
     * Handle for open event
     * setTimeout is needed here because focus is immediately being shifted to the clicked button
     */
    onOpen: function () {
      this.focusInput();
      this.refresh();
      if (this.analyticsCategory && this.analyticsStatus) {
        var analyticsCategory = this.analyticsCategory + " - " + this.analyticsStatus;
        logger.googleAnalytics.trackEvent(analyticsCategory, "Clicked", "Open Tags");
      }
    },
    /**
     * Focus the the search input
     */
    focusInput: function () {
      setTimeout(lang.hitch(this, function () {
        focusUtil.focus(this.tagInput);
        this.activeNode = this.tagInput;
      }), 100);
    },
    /**
     * Handle for apply tag event
     * @param {object} tag Tag to apply
     */
    onApplyTag: function (tag) {},
    /**
     * Handle for remove tag event
     * @param {object} tag Tag to remove
     */
    onRemoveTag: function (tag) {},
    /**
     * Handle for create tag event
     */
    onCreateTag: function () {},
    /**
     * Handle for close event
     */
    onClose: function () {
      this.setInputValue(null);
    },
    /**
     * Override to prevent overlay from closing on click of line items
     */
    onExecute: function () {}
  });
});