define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "dojo/text!./templates/campaign-status.html", "mojo/widgets/Renderable", "mojo/user", "mojo/url", "mojo/utils", "mojo/utils/date", "mojo/lib/logger", "../SendNow", "./_SendLater", "../NotifySenders"], function (lang, declare, locale, tpl, Renderable, user, mUrl, utils, dateUtils, logger, SendNow, SendLater, NotifySenders) {
  return declare([Renderable], {
    templateString: tpl,
    /**
     * Constant url for Finish later link
     */
    finishLaterUrl: mUrl.toUrl("/campaigns"),
    enableButtonWhenUserIsPaused: null,
    state: {
      isItemActive: false
    },
    constructor: function (props) {
      this.enableButtonWhenUserIsPaused = user.isPaused;
      this.timezoneLabel = user.timezoneLabel !== null ? user.timezoneLabel : user.timezoneId;
      this.state.model = props.model;
      this.state.completeItems = props.completeItems;
      this.state.remainingItems = props.remainingItems;

      // this field is true when all checklist slats are complete and valid
      this.state.isReady = props.isReady;
      this.state.hasPro = user.pro;
      this.state.isManager = user.hasRole("manager");
      this.state.heading = "Create your email campaign!";
      this.state.subHeading = "Draft email";
      this.state.disabledClass = "";
      this.state.redirectUrl = window.location.href;
      this.state.showUpgradeButton = false;
      if (this.state.model.list && this.state.model.list.isDisabled) {
        this.state.disabledClass = " disabled";
      }
      var getSendButtons = function () {
        if (user && !user.canSendFree) {
          this.state.showUpgradeButton = true;
          return [{
            classes: "c-slatButton button p1",
            value: "Send",
            enabledAction: "openSendNowModal",
            otherAttrs: this.enableButtonWhenUserIsPaused ? "data-dojo-attach-event='click:openSendNowModal'" : "disabled='disabled'"
          }];
        }
        this.state.showUpgradeButton = false;
        return [{
          classes: "c-slatButton button" + this.state.disabledClass,
          otherAttrs: this.enableButtonWhenUserIsPaused ? "data-dojo-attach-event='click:openSendLaterModal'" : "disabled='disabled'",
          value: "Schedule",
          enabledAction: "openSendLaterModal"
        }, {
          classes: "c-slatButton button p1" + this.state.disabledClass,
          otherAttrs: this.enableButtonWhenUserIsPaused ? "data-dojo-attach-event='click:openSendNowModal'" : "disabled='disabled'",
          value: "Send",
          enabledAction: "openSendNowModal"
        }];
      }.bind(this);
      var notifyButtons = [{
        classes: "c-slatButton button",
        otherAttrs: this.enableButtonWhenUserIsPaused ? "data-dojo-attach-event='click:openNotifySendersModal'" : "disabled='disabled'",
        value: "I'm finished",
        enabledAction: "openNotifySendersModal"
      }];
      this.state.buttons = this.state.isManager ? getSendButtons() : notifyButtons;
      this.state.shouldShowFinishLaterLink = true;
      this.state.icon = {
        classes: "c-channelIcon c-channelIcon--email"
      };

      // this field should be true when the campaign is ready to send
      // that is, it has passed validation from front-end and back-end
      var isReadyToSend = this.state.isReady;
      this.campaignStatusBadge = {
        ad: {
          "scheduled": {
            label: "Scheduled",
            badge: "warning"
          },
          "paused": {
            label: "Paused",
            badge: "warning"
          },
          "pending": {
            label: "In Review",
            badge: "warning"
          },
          "active": {
            label: "Published",
            badge: "info"
          },
          "rejected": {
            label: "Rejected",
            badge: "error"
          },
          "completed": {
            label: "Completed",
            badge: "success"
          },
          "canceled": {
            label: "Canceled",
            badge: "error"
          },
          "draft": {
            label: "Draft",
            badge: ""
          },
          "finalized": {
            label: "Finalized",
            badge: ""
          }
        },
        automation: {
          "sending": {
            label: "Sending",
            badge: "info"
          },
          "somepaused": {
            label: "Some Paused",
            badge: "warning"
          },
          "save": {
            label: "Draft",
            badge: ""
          },
          "draft": {
            label: "Draft",
            badge: ""
          },
          "active": {
            label: "Active",
            badge: "info"
          },
          "paused": {
            label: "Paused",
            badge: "warning"
          }
        },
        email: {
          "sent": {
            label: "Sent",
            badge: "success"
          },
          "canceled": {
            label: "Canceled",
            badge: "error"
          },
          "save": {
            label: "Draft",
            badge: ""
          },
          "sending": {
            label: "Sending",
            badge: "info"
          },
          "active": {
            label: "Sending",
            badge: "info"
          },
          "schedule": {
            label: "Scheduled",
            badge: "warning"
          },
          "paused": {
            label: "Paused",
            badge: "warning"
          },
          "disconnected": {
            label: "Not connected",
            badge: "warning"
          }
        }
      };
      this.campaignType = "email";
      this.pendoGuideClosed = false;
      if (props.model.errorState && props.model.errorState.scope === "account") {
        this.state.heading = props.model.errorState.title;
        this.state.subHeading = props.model.errorState.descriptionShort;
        this.state.buttons[0].classes = "c-slatButton button p2" + props.model.errorState.resolution.canResolve ? "" : " disabled";
        if (props.model.errorState.resolution.action && props.model.errorState.resolution.action.attr) {
          this.state.buttons[0].value = props.model.errorState.resolution.action.title;
          this.state.buttons[0].otherAttrs = props.model.errorState.resolution.action.attr;
        }
      } else if (this.state.model.isCanceled && this.state.hasPro) {
        this.state.heading = "Your email campaign was canceled.";
        this.state.subHeading = "Canceled on " + this._getDateString(this.state.model.canceledAt);
        this.state.buttons = [{
          classes: "c-slatButton button p0",
          value: "View Results"
        }];
        this.state.shouldShowFinishLaterLink = false;
      } else if (this.state.model.status === "sending" && this.state.hasPro) {
        this.state.heading = "Your email campaign is sending!";
        this.state.subHeading = this.state.model.deliveryPercentage + "% sent";
        this.state.buttons = [{
          classes: "c-slatButton button p2",
          value: "Stop Delivery"
        }];
        this.state.shouldShowFinishLaterLink = false;
      } else if (this.state.model.status === "canceling" && this.state.hasPro) {
        this.state.heading = "We're canceling the delivery.";
        this.state.subHeading = "This could take a few minutes.";
        this.state.buttons = []; // no button
        this.state.shouldShowFinishLaterLink = false;
      } else if (this.state.model.status === "sent") {
        this.state.heading = "Your email campaign was delivered!";
        this.state.subHeading = "Sent on " + this._getDateString(this.state.model.sendTime);
        this.state.buttons = [{
          classes: "c-slatButton button p0",
          value: "View Results"
        }];
        this.state.shouldShowFinishLaterLink = false;
      } else if (this.state.model.status === "paused") {
        this.state.heading = "Your email campaign is paused.";
        this.state.subHeading = "Paused";
        this.state.buttons = [{
          classes: "c-slatButton button",
          value: "Resume Campaign",
          otherAttrs: "data-dojo-attach-event='click: openSendLaterModal'"
        }, {
          classes: "c-slatButton button p1",
          otherAttrs: "data-dojo-attach-event='click: openSendNowModal'",
          value: "Send Now"
        }];
      } else if (this.state.model.status === "schedule") {
        this.state.heading = "Your email campaign is scheduled.";
        this.state.subHeading = "Scheduled to send on " + this._getDateString(this.state.model.sendTime);
        this.state.buttons = [{
          classes: "c-slatButton button",
          value: "Pause Campaign"
        }];
        this.state.shouldShowFinishLaterLink = false;
      } else if (isReadyToSend) {
        this.state.heading = "Your email campaign is ready to send!";
        this.state.buttons = this.state.buttons.map(function (button) {
          return lang.mixin({}, button, {
            otherAttrs: "data-dojo-attach-event='click: " + button.enabledAction + "'"
          });
        });
      } else if (this.state.remainingItems >= 4) {
        this.state.heading = "Let's get started!";
      } else if (this.state.remainingItems >= 2) {
        this.state.heading = "Keep it up!";
      } else if (this.state.remainingItems === 1) {
        this.state.heading = "You're almost finished!";
      } else if (this.state.remainingItems === 0) {
        // this case isn't relevant if some validation comes from back-end, isn't captured by a checklist item,
        // but we still want to disable the checklist.
        this.state.heading = "Error";
        this.state.subHeading = "We've made a huge mistake.";
        throw new Error("Checklist thinks it is complete, but is still not in `isReady` state");
      }
      var showTimewarpGuide = function () {
        try {
          window.pendo.initialize({
            visitor: {
              id: user.userId
            },
            events: {
              guidesLoaded: function () {
                var guides = window.pendo.getActiveGuides();
                try {
                  guides.forEach(function (guide) {
                    if (guide.name === "Timewarp" && guide.canShow() && !guide.hasBeenSeen()) {
                      window.pendo.showGuideByName("Timewarp");
                      logger.info("recs-timewarp", "Guide initialized");
                    }
                  });
                } catch (e) {
                  logger.info("recs-timewarp", "Guide did not initialize", {
                    log_classification: "sensitive"
                  });
                }
              }
            }
          });
        } catch (e) {
          logger.info("recs-timewarp", "Pendo did not initialize");
        }
      };
      if (this.state.isReady) {
        showTimewarpGuide();
      }
    },
    openSendLaterModal: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: "paused",
          action: "wiggle"
        });
      }
      if (this.sendLaterModal) {
        this.sendLaterModal.destroyRecursive(true);
        this.sendLaterModal = null;
      }
      this.sendLaterModal = new SendLater({
        model: this.model,
        active: true,
        selectStoOnOpen: !!this.selectStoOnOpen
      });
      window.ga("send", "event", "campaign_checklist", "click", "schedule");
      window.ga("send", "event", "timewarp_campaign_checklist", "click", "schedule");
    },
    openSendNowModal: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: "paused",
          action: "wiggle"
        });
      }
      if (!this.sendNowModal) {
        this.sendNowModal = new SendNow({
          model: this.model
        });
      }
      this.sendNowModal.showSendNowDialog();
      window.ga("send", "event", "campaign_checklist", "click", "send");
    },
    trackFinishLater: function () {
      window.ga("send", "event", "campaign_checklist", "click", "finish_later");
    },
    openNotifySendersModal: function () {
      if (!this.notifySendersModal) {
        this.notifySendersModal = new NotifySenders({
          model: this.model
        });
      }
      this.notifySendersModal.show();
    },
    /**
     * Format - March 22nd [, 2020] 1:25pm EST
     * @param {Date} dateObj to be formatted
     * @returns {String} the needed, formatted, date string
     */
    _getDateString: function (dateObj) {
      // dates are now objects
      if (dateObj && dateObj instanceof Object && dateObj.hasOwnProperty("jsDate")) {
        var jsDate = dateObj.jsDate;
        // TODO: TZ Formatting - check with design about date formatting for Campaign Status with Month_Day_Year_Time and TZ label
        var formattedDate = dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR_TIME);
        return formattedDate + " " + this.timezoneLabel;
      }
      var today = new Date();
      var dateStr =
      // month
      locale.format(dateObj, {
        selector: "date",
        datePattern: "MMMM"
      }) + " "
      // day
      + utils.getDayWithOrdinal(dateObj.getDate())
      // year (if not this year)
      + (today.getFullYear() !== dateObj.getFullYear() ? ", " + dateObj.getFullYear() : "") + " "
      // time
      + locale.format(dateObj, {
        selector: "date",
        datePattern: "h:mm a v"
      });
      // h:mm a v is suppose to add the browser's timezone to the format,
      // but this can be different than the user's default tz
      // confusing the user, seeing a time on the campaign listing slat -> then the campaign status

      return dateStr;
    }
  });
});