define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user"], function (declare, lang, base, context, flags, logger, ioQuery, user) {
  return declare([base], {
    targetElement: "adaChatWindow",
    logRequest: function () {
      var queryObj = this.getQueryParams();
      logger.info("contact-support", "ada", {
        "plan": "pfree",
        "topic": null,
        "sk_ref": queryObj.ref ? queryObj.ref : null,
        "kb_ref": queryObj.slg ? queryObj.slg : null
      });
    },
    getQueryParams: function () {
      var location = window.location.href;
      var query = location.substring(location.indexOf("?") + 1, location.length);
      return ioQuery.queryToObject(query);
    }
  });
});