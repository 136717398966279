define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/registry"], function (declare, base, mUrl, registry) {
  return declare([base], {
    user_id: null,
    /**
     * Opens delete user Modal
     * @param {number} id - the id of the mcadmin user that is being deleted
     */
    deleteUser: function (id) {
      this.$el.userIdInput.value = id;
      this.$widget.deleteDialog.show();
    },
    /**
     * exports list of agents permissions
     */
    exportPermissions: function () {
      document.location = mUrl.toUrl("/peaches2/admin/agents/export", {
        type: "permissions"
      });
    }
  });
});