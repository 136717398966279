define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/wizard", "mojo/views/_base", "mojo/widgets/KbSearch"], function (declare, on, query, wizard, base, KbSearch) {
  return declare([base], {
    onReady: function () {
      wizard.instantiateMobilePreviewMenu();
      query("[data-kb-help]").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        KbSearch.main().show();
      });
    }
  });
});