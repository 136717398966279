define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/authz", "mojo/widgets/Renderable", "mojo/widgets/UpgradeActionBlock", "mojo/user", "./Item", "./email-editor/reminderNotification", "dojo/text!./templates/container.html", "mojo/lib/flags", "mojo/context", "mojo/lib/logger"], function (declare, lang, dom, domConstruct, domClass, when, Authz, Renderable, UpgradeActionBlock, user, Item, reminderNotification, tpl, flags, context, logger) {
  return declare([Renderable, Authz], {
    templateString: tpl,
    renderedItems: null,
    shouldShowBreadcrumbsToOnboarding: null,
    positions: {
      "before": "headerContainer",
      "info": "infoContainer",
      "default": "primaryItemsContainer",
      "after": "secondaryItemsContainer"
    },
    emailEditorReminderType: null,
    renderedHtml: "",
    constructor: function (props) {
      this.state = {
        model: props.model,
        campaignType: props.campaignType
      };
    },
    save: function (data) {
      this.trackSave();
      return when(this.onSave(data, this.state.model), this._setModel.bind(this));
    },
    trackSave: function () {
      this.renderedItems.forEach(function (item) {
        if (item.state.isActive && typeof item.trackSave === "function") {
          item.trackSave();
        }
      });
    },
    _setModel: function (model) {
      this.setState({
        model: model
      });

      // re-render status after model is set to catch model/audience status changes
      this.renderStatus();
    },
    setActiveItem: function () {
      this.renderedItems.forEach(function (item) {
        if (!item.state.isActive) {
          item.setState({
            isDisabled: true
          });
        }
      });
      this.toggleSidebarIsItemActive(true);
      this.status.setState({
        isItemActive: true
      });
    },
    clearActiveItem: function () {
      this.trackCancel();
      this.renderedItems.forEach(function (item) {
        item.setState({
          isDisabled: false
        });
      });
      this.toggleSidebarIsItemActive(false);
      this.status.setState({
        isItemActive: false
      });
    },
    trackCancel: function () {
      this.renderedItems.forEach(function (item) {
        if (!item.state.isDisabled && typeof item.trackCancel === "function") {
          item.trackCancel();
        }
      });
    },
    toggleSidebarIsItemActive: function (isItemActive) {
      if (this.sidebar) {
        this.sidebar.setState({
          isItemActive: isItemActive
        });
      }
    },
    render: function () {
      this.inherited(arguments);
      this.renderItems();
      this.renderStatus();
      this.renderSidebar();
      this.renderBreadcrumbs();
      this.renderEditorToggleReminderBlock();
    },
    renderBreadcrumbs: function () {
      if (this.shouldShowBreadcrumbsToOnboarding) {
        this.breadcrumbsLink.classList.remove("display--none");
      }
    },
    renderSidebar: function () {
      if (this.sidebarWidget) {
        if (this.sidebar) {
          this.sidebar.destroy();
        }
        var container = this.sidebarContainer;
        domClass.remove(container, "hide");
        this.sidebar = new this.sidebarWidget({
          model: this.state.model
        });
        this.sidebar.placeAt(container);
      }
    },
    renderStatus: function () {
      var model = this.state.model;
      var gptGenerated = this.gptGenerated;
      var completeItems = this.items.filter(function (item) {
        return item.isComplete(model) && item.isValid(model);
      });
      var isReady = completeItems.length === this.items.length;
      var remainingItems = this.items.length - completeItems.length;
      if (this.status) {
        this.status.destroy();
      }

      // Render this to the defined "av slot" so that it will be a fixed instance rather than inline
      var container = dom.byId("slot-campaignChecklistStatusBar");
      this.status = new this.statusWidget({
        model: model,
        completeItems: completeItems,
        isReady: isReady,
        remainingItems: remainingItems,
        listDisabled: model.list ? model.list.disabled : false,
        gptGenerated: gptGenerated
      });
      this.status.placeAt(container);
    },
    renderItems: function () {
      if (this.renderedItems) {
        this.renderedItems.forEach(function (item) {
          if (item.unmount) {
            item.unmount();
          }
        });
      }
      this.renderedItems = this.items.map(function (config) {
        // Hide the STO banner from the checklist editor for users who can see recommendation badges
        if (config.position === "info" && this.state.campaignType === "regular") {
          return;
        }
        var position = this.positions[config.position || "default"];
        var ItemWidget = config.widget || Item;
        var item = new ItemWidget(lang.mixin({}, config, {
          onSave: this.save.bind(this),
          onActivate: function () {
            this.setActiveItem();
            if (config.onActivate) {
              config.onActivate(this.state);
            }
          }.bind(this),
          onDeactivate: this.clearActiveItem.bind(this),
          model: this.state.model,
          getCurrentlyRenderedItem: this.getCurrentlyRenderedItem.bind(this),
          badge: config.badge
        }));
        item.placeAt(this[position]);
        return item;
      }, this);
      this.renderedItems = this.renderedItems.filter(function (x) {
        return !!x;
      });

      /*
      * Temprorary patch to disable rendered items based on their own isDisabled variable.
      * Rendered items state management is currently flawed because each renderedItem shares the same
      * attributes. Thus leading to the last known state impacting all of the rendered items.
      */
      if (this.renderedItems[1].state.isDisabled !== this.items[1].isDisabled) {
        this.renderedItems[1].state.isDisabled = this.items[1].isDisabled;
      } else if (this.renderedItems[2].state.isDisabled !== this.items[2].isDisabled) {
        this.renderedItems[2].state.isDisabled = this.items[2].isDisabled;
      }
    },
    getCurrentlyRenderedItem: function (name, model) {
      if (!this.renderedItems) {
        return;
      }
      var matchingCurrentItem = this.renderedItems.filter(function (item) {
        return name === item.className;
      })[0];
      return matchingCurrentItem;
    },
    _trackCreativeAssistatntUpgradeAction: function (planDisplayName) {
      logger.googleAnalytics.trackEvent("creative assistant for email", "cta", "button", {
        dimension53: "upgrade",
        dimension54: window.location.pathname,
        dimension56: "upgrade moment",
        dimension96: "creative assistant for email",
        dimension97: planDisplayName,
        dimension98: "access creative assistant emails"
      });
      logger.googleAnalytics.trackEvent("checkout ca email", "start", "checklist banner", {
        dimension54: window.location.pathname,
        dimension97: planDisplayName
      });
    },
    renderEditorToggleReminderBlock: function () {
      if (this.emailEditorReminderType && this.headerContainer) {
        var notificationBlock = new reminderNotification({
          campaignId: this.model.campaignId,
          inlineEditor: this.emailEditorReminderType === "inline",
          renderedHtml: this.renderedHtml
        });
        domClass.add(this.headerContainer, "email-toggle-reminder-block-container");
        notificationBlock.placeAt(this.headerContainer, "last");
      } else if (this.model.hasCreativeAssistantTemplate && !this.userHasFeature("creative_assistant_for_email") && this.headerContainer) {
        var caUpgradeBlock = new UpgradeActionBlock({
          body: {
            title: "Upgrade your account to send Creative Assistant emails and more",
            copy: "Send beautiful, on-brand emails with Creative Assistant plus much more when you upgrade."
          },
          fixedGATrackingCallback: this._trackCreativeAssistatntUpgradeAction,
          onCtaPressCallback: true,
          featureName: "creative_assistant_for_email"
        });
        domClass.add(this.headerContainer, "email-toggle-reminder-block-container");
        domClass.add(this.headerContainer, "ca-upgrade-banner");
        logger.googleAnalytics.trackEvent("checkout ca email", "in view", "checklist");
        caUpgradeBlock.placeAt(this.headerContainer, "last");
      }
    }
  });
});