/**
 * @module mojo/views/misc/dojo-translation-playground
 *
 * This is a ViewScript file that is dynamically injected into the View on page load.
 *
 * See https://confluence.mailchimp.com/x/CIDjFQ
 */
define([// Contains functions to define Dojo classes, which support standard Object Oriented concepts within Dojo
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// ViewScript Mixin
"mojo/views/_base",
// https://dojotoolkit.org/reference-guide/1.9/dojo/html.html
"dojo/html", "mojo/widgets/campaigns/CreateButton",
// Module designed to handle translation ingestion and string retrieval
"mojo/Translator"], function (declare, base, html, CreateButton, Translator) {
  /**
   * Extend from the mojo/views/_base mixing which gives us our overall page lifecycle.
   * This mixin extends dojo/Stateful module which creates the basis for our lifecycle and state management.
   *
   * See: https://dojotoolkit.org/reference-guide/1.9/dojo/Stateful.html#
   */
  return declare([base], {
    /**
     * This function is called after the dom is parsed. We'll have the ability to access any elements in the dom
     * with data-mc-el or data-mc-group automatically without having to do any querying.
     *
     * This function isn't a standard one used throughout the entire codebase. It's largely only available within
     * files within mailchimp/web/js/mojo/views/*
     */
    onReady: function () {
      // Call "private" functions to do the work instead of overloading the onReady function.
      this._injectTranslatedStrings();
      this._initCreateButton();
    },
    /**
     * Inject the strings in the dom. Important to note this happens relatively late within the page load. So there
     * is a potential of flashing missing content.
     *
     * @private
     */
    _injectTranslatedStrings: function () {
      // Always best to make sure the node is in the dom
      if (this.$el.simpleViewScriptNode) {
        // Here's an okay scenario in which we can use innerText. This is because we don't already have a template string we can override,
        // we're largely working with raw HTML and need to fill it with content.
        this.$el.simpleViewScriptNode.innerText = Translator.translate("audience_management_segment_edit_status_type_cleaned_info");
      }
      if (this.$el.simpleViewScriptWithParamsNode) {
        this.$el.simpleViewScriptWithParamsNode.innerText = Translator.translateWithParams("contacts_match_conditions_eme", {
          count: 2,
          preview_name: "Segment Name"
        });
      }

      // In the event we have HTML in the string, we need to handle that uniquely.
      if (this.$el.simpleViewScriptWithHtmlNode) {
        // This key has some inlined HTML
        var key = "nuni_editor_info_pane_signup_for_litmus";

        // Notice we're not doing this.$el.simpleViewScriptWithHtmlNode.innerHtml. That is considered unsafe
        // and a vector for XSS. Instead, we should lean on the dojo/html module
        html.set(this.$el.simpleViewScriptWithHtmlNode, Translator.translate(key));
      }
    },
    /**
     * Dynamically create the button.
     *
     * @private
     */
    _initCreateButton: function () {
      // Create an instance of Create Button.
      // Interestingly we could also pass the buttonLabel as a prop like so, but that is duplicative
      // and defeats the demonstration purposes. For transparency that'd look like this.
      // this.createButton = new CreateButton({buttonLabel: Translator.translate("create_new_btn")});
      this.createButton = new CreateButton();

      // The placeAt function allows us to decouple initialization from injection.
      this.createButton.placeAt(this.$el.createButtonNode);

      // The startup function kicks off the rendering.
      this.createButton.startup();
    }
  });
});