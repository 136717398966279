define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/BlueprintCard.html", "mojo/intent-overlay/ChannelIcon"], function (declare, lang, registry, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, domClass, on, topic, blueprintCardTpl, ChannelIcon // invoked by template
) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: blueprintCardTpl,
    blueprint: null,
    isVisible: false,
    cardClass: "",
    postMixInProperties: function () {
      this.inherited(arguments);
      this.title = this.blueprint.title;
      this.pathways = this.blueprint.pathways;
      this.description = this.blueprint.description;
      this.types = this.blueprint.types;
      this.channel = this.blueprint.options[0].type || "none";
    },
    postCreate: function () {
      this.blueprint.options.map(function (option) {
        domClass.toggle(this.cardAutomation, "display--block", ["automation", "variate", "transactional", "rss", "abandoned_browse"].indexOf(option.type) !== -1);
      }, this);
    },
    focus: function () {
      this.cardLink.focus();
    }
  });
});