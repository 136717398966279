/**
 * Specialized select input box that can create another input based on its config. The created input will be used to
 * return the "Extra" attribute
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "./_SegmentInputMixin", "./Select", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/segmentation/input/LocationValidation", "mojo/utils/I18nTranslation", "mojo/segmentation/input/ZipCode", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _SegmentInputMixin, Select, domConstruct, domAttr, lang, array, LocationValidation, I18nTranslation, ZipCodeInput, flags, context) {
  var milesOfText = " miles of ";
  return declare([_WidgetBase, _SegmentInputMixin], {
    ops: null,
    opsMap: null,
    configs: null,
    extraInput: null,
    postMixInProperties: function () {
      this.opsMap = {};
      this.options = [];

      // Add the options that the Select parent understands
      array.forEach(this.ops, lang.hitch(this, function (op) {
        this.opsMap[op.value] = op;
        var option = {
          "label": op.label,
          "value": op.value
        };
        this.options.push(option);
      }));
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        milesOfText = I18nTranslation.translate("audience_management_segment_builder_predicted_location_milesOfText");
      }
    },
    buildRendering: function () {
      this.domNode = domConstruct.create("div", {
        "class": "segment-extra-select"
      });
    },
    postCreate: function () {
      this.selectContainer = domConstruct.create("div", {
        className: "ipgeo-distance-select-container condition-value"
      });
      this.selectInput = new Select({
        "options": this.options
      });
      this.selectInput.placeAt(this.selectContainer);
      this.addonNode = domConstruct.create("span", {
        textContent: milesOfText
      });
      domConstruct.place(this.addonNode, this.selectContainer);
      domConstruct.place(this.selectContainer, this.domNode);
      this.selectInput.on("change", lang.hitch(this, "renderInput"));
      this.renderInput();
    },
    /*
     * The previous solution used an inline-require to re-import the creator.
     * The async nature of re-importing the creator was causing a race condition, which
     * was causing this to fail in prod, hence recreating the creator here.
     */
    extraCreator: function (configs, inputConfig) {
      var creators = {
        "zip_code": function (conf, inputConf) {
          return ZipCodeInput({
            "class": "segment-block",
            configs: conf,
            inputConfig: inputConf
          });
        },
        "location_validation": function (conf, inputConf) {
          return LocationValidation({
            configs: conf,
            inputConfig: inputConf
          });
        }
      };
      var input = creators[inputConfig.type](configs, inputConfig);
      input.segmentInputType = inputConfig.type;
      if (input.startup) {
        input.startup();
      }
      return input;
    },
    renderInput: function () {
      if (this.extraInput) {
        this.extraInput.destroy();
        this.extraInput = null;
      }
      var opConfig = this.opsMap[this.getValue()];
      var inputConfig = opConfig.input;
      if (inputConfig) {
        this.extraInput = new this.extraCreator(this.configs, inputConfig);
        domConstruct.place(this.extraInput.domNode, this.domNode);
      }
    },
    getValueHTML: function () {
      return this.extraInput ? this.extraInput.getDisplayHTML() : "";
    },
    getDisplayHTML: function () {
      return this.selectInput.getDisplayHTML() + milesOfText + this.getValueHTML();
    },
    getValue: function () {
      return parseInt(this.selectInput.getValue(), 10);
    },
    setValue: function (value) {
      this.selectInput.setValue(value);
    },
    setExtra: function (extraValue) {
      if (this.extraInput) {
        this.extraInput.setValue(extraValue);
      }
    },
    getExtra: function () {
      var extra = null;
      if (this.extraInput) {
        extra = this.extraInput.getValue();
      }
      return extra;
    },
    getHiddenValues: function () {
      var hiddenValues = null;
      if (this.extraInput && this.extraInput.getHiddenValues) {
        hiddenValues = this.extraInput.getHiddenValues();
      }
      return hiddenValues;
    },
    setHiddenValues: function (ast) {
      if (this.extraInput && this.extraInput.setHiddenValues) {
        this.extraInput.setHiddenValues(ast);
      }
    },
    destroy: function () {
      this.destroyDescendants();
      this.inherited(arguments);
    }
  });
});