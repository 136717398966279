define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, on) {
  return declare([_WidgetBase], {
    startup: function () {
      on(this.domNode, "click", function (e) {
        if (typeof window.Optanon !== "undefined" && typeof window.Optanon.ToggleInfoDisplay === "function") {
          e.preventDefault();
          window.Optanon.ToggleInfoDisplay();
        }
      });
    }
  });
});