define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/utils/string", "mojo/lib/logger", "mojo/widgets/smart-mark/SmartMark", "mojo/user", "mojo/lib/flags", "dojo/text!./templates/subject-criteria-list.html"], function (dojo, declare, lang, domConstruct, debounce, on, Renderable, stringUtil, logger, SmartMark, user, flags, template) {
  var DEBOUNCE_TIME = 250;

  // Evaluation statuses
  var STATUS_DEFAULT = "default";
  var STATUS_PASS = "pass";
  var STATUS_FAIL = "fail";

  // Word count evaluation constants
  var MAX_WORD_COUNT_FOR_GOOD_SUBJECT = 9;
  var WORD_COUNT_DESCRIPTION_DEFAULT = "Try to use no more than " + MAX_WORD_COUNT_FOR_GOOD_SUBJECT + " words";
  var WORD_COUNT_TITLE_PASS = "It's short and sweet";
  var WORD_COUNT_DESCRIPTION_PASS = "Subject lines with fewer than " + MAX_WORD_COUNT_FOR_GOOD_SUBJECT + " words tend to perform better";
  var WORD_COUNT_TITLE_FAIL = "Keep it short and sweet";
  var WORD_COUNT_DESCRIPTION_FAIL = "Try to trim your subject line to be less than " + MAX_WORD_COUNT_FOR_GOOD_SUBJECT + " words";

  // Emoji count evaluation constants
  var MAX_EMOJI_COUNT_FOR_GOOD_SUBJECT = 1;
  var EMOJI_COUNT_DESCRIPTION_DEFAULT = "Try to use no more than " + MAX_EMOJI_COUNT_FOR_GOOD_SUBJECT + " emoji";
  var EMOJI_COUNT_TITLE_PASS = "Emojis are great... in small quantities";
  var EMOJI_COUNT_DESCRIPTION_PASS = "We suggest using no more than " + MAX_EMOJI_COUNT_FOR_GOOD_SUBJECT;
  var EMOJI_COUNT_TITLE_FAIL = "Emojis are great... in small quantities";
  var EMOJI_COUNT_DESCRIPTION_FAIL = "We suggest using no more than " + MAX_EMOJI_COUNT_FOR_GOOD_SUBJECT;

  // Character count evaluation constants
  var MAX_CHARACTER_COUNT_FOR_GOOD_SUBJECT = 60;
  var CHARACTER_COUNT_DESCRIPTION_DEFAULT = "Avoid using more than " + MAX_CHARACTER_COUNT_FOR_GOOD_SUBJECT + " characters";
  var CHARACTER_COUNT_TITLE_PASS = "Space is limited";
  var CHARACTER_COUNT_DESCRIPTION_PASS = "Great job staying under " + MAX_CHARACTER_COUNT_FOR_GOOD_SUBJECT + " characters";
  var CHARACTER_COUNT_TITLE_FAIL = "Space is limited";
  var CHARACTER_COUNT_DESCRIPTION_FAIL = "You'll want to keep your character count below " + MAX_CHARACTER_COUNT_FOR_GOOD_SUBJECT;

  // Punctuation count evaluation constants
  var MAX_PUNCTUATION_COUNT_FOR_GOOD_SUBJECT = 3;
  var PUNCTUATION_COUNT_DESCRIPTION_DEFAULT = "Avoid using more than " + MAX_PUNCTUATION_COUNT_FOR_GOOD_SUBJECT + " punctuation marks";
  var PUNCTUATION_COUNT_TITLE_PASS = "That's impressive";
  var PUNCTUATION_COUNT_DESCRIPTION_PASS = "You got your point across without using too many punctuation marks";
  var PUNCTUATION_COUNT_TITLE_FAIL = "Less is more";
  var PUNCTUATION_COUNT_DESCRIPTION_FAIL = "We suggest using no more than " + MAX_PUNCTUATION_COUNT_FOR_GOOD_SUBJECT + " punctuation marks";

  // Default results
  var defaultEvalCriteria = {
    wordCount: {
      status: STATUS_DEFAULT,
      title: "",
      description: WORD_COUNT_DESCRIPTION_DEFAULT
    },
    emojiCount: {
      status: STATUS_DEFAULT,
      title: "",
      description: EMOJI_COUNT_DESCRIPTION_DEFAULT
    },
    characterCount: {
      status: STATUS_DEFAULT,
      title: "",
      description: CHARACTER_COUNT_DESCRIPTION_DEFAULT
    },
    punctuationCount: {
      status: STATUS_DEFAULT,
      title: "",
      description: PUNCTUATION_COUNT_DESCRIPTION_DEFAULT
    }
  };
  return declare([Renderable], {
    STATUS_FAIL: STATUS_FAIL,
    STATUS_PASS: STATUS_PASS,
    state: {
      evalCriteria: Object.values(defaultEvalCriteria),
      evalCriteriaObject: {},
      shouldShowSurvey: false
    },
    templateString: template,
    constructor: function (props) {
      if (props && props.subject) {
        this.updateCriteria(props.subject);
      }
    },
    updateCriteria: debounce(function (subject) {
      var subjectWithoutMergeTags = this.stripOutMergeTags(subject);
      var nextEvalCriteriaObject = {
        wordCount: this.getWordCriteriaItem(subjectWithoutMergeTags),
        emojiCount: this.getEmojiCriteriaItem(subjectWithoutMergeTags),
        characterCount: this.getCharacterCriteriaItem(subjectWithoutMergeTags),
        punctuationCount: this.getPunctuationCriteriaItem(subjectWithoutMergeTags)
      };
      this.logUpdatedEvalCriteria(this.state.evalCriteriaObject, nextEvalCriteriaObject, subject);
      var isSubjectNonEmptyString = !!subject;
      var evalCriteria = Object.values(nextEvalCriteriaObject);
      var allPassed = true;
      for (var i = 0; i < evalCriteria.length; i++) {
        if (evalCriteria[i].status === 'fail') {
          allPassed = false;
          break;
        }
      }
      this.addPersistentExtraProps({
        allSubjectLineCriteriaPassed: allPassed
      });
      this.setState({
        evalCriteria: evalCriteria,
        evalCriteriaObject: nextEvalCriteriaObject,
        shouldShowSurvey: isSubjectNonEmptyString
      });
    }, DEBOUNCE_TIME),
    stripOutMergeTags: function (message) {
      var mergeTagRegex = /(\*\|[^\|]+\|\*)/g;
      return message.replace(mergeTagRegex, "");
    },
    logUpdatedEvalCriteria: function (evalCriteria, nextEvalCriteria, subject) {
      for (var key in evalCriteria) {
        if (evalCriteria.hasOwnProperty(key)) {
          var currentCriteria = evalCriteria[key];
          var nextCriteria = nextEvalCriteria[key];
          var didCriteriaChange = currentCriteria.status !== nextCriteria.status;
          var wasChangeFromOrToAFailedStatus = currentCriteria.status === this.STATUS_FAIL || nextCriteria.status === this.STATUS_FAIL;
          // we want to log changes that are more than just default status changing to pass
          if (didCriteriaChange && wasChangeFromOrToAFailedStatus) {
            logger.googleAnalytics.trackEvent("subject-line-tester", "updated-feedback", key + ":" + nextCriteria.status + ":" + subject);
          }
        }
      }
    },
    getWordCriteriaItem: function (subject) {
      //default values
      var result = defaultEvalCriteria.wordCount;
      if (subject) {
        var wordCount = stringUtil.getWordCount(subject);
        if (wordCount <= MAX_WORD_COUNT_FOR_GOOD_SUBJECT) {
          result = {
            status: STATUS_PASS,
            title: WORD_COUNT_TITLE_PASS,
            description: WORD_COUNT_DESCRIPTION_PASS
          };
        } else {
          result = {
            status: STATUS_FAIL,
            title: WORD_COUNT_TITLE_FAIL,
            description: WORD_COUNT_DESCRIPTION_FAIL
          };
        }
      }
      return result;
    },
    getEmojiCriteriaItem: function (subject) {
      //default values
      var result = defaultEvalCriteria.emojiCount;
      if (subject) {
        var emojiCount = stringUtil.getEmojiCount(subject);
        if (emojiCount <= MAX_EMOJI_COUNT_FOR_GOOD_SUBJECT) {
          result = {
            status: STATUS_PASS,
            title: EMOJI_COUNT_TITLE_PASS,
            description: EMOJI_COUNT_DESCRIPTION_PASS
          };
        } else {
          result = {
            status: STATUS_FAIL,
            title: EMOJI_COUNT_TITLE_FAIL,
            description: EMOJI_COUNT_DESCRIPTION_FAIL
          };
        }
      }
      return result;
    },
    getCharacterCriteriaItem: function (subject) {
      //default values
      var result = defaultEvalCriteria.characterCount;
      if (subject) {
        var characterCount = stringUtil.getSymbolLength(subject);
        if (characterCount <= MAX_CHARACTER_COUNT_FOR_GOOD_SUBJECT) {
          result = {
            status: STATUS_PASS,
            title: CHARACTER_COUNT_TITLE_PASS,
            description: CHARACTER_COUNT_DESCRIPTION_PASS
          };
        } else {
          result = {
            status: STATUS_FAIL,
            title: CHARACTER_COUNT_TITLE_FAIL,
            description: CHARACTER_COUNT_DESCRIPTION_FAIL
          };
        }
      }
      return result;
    },
    getPunctuationCriteriaItem: function (subject) {
      var result = defaultEvalCriteria.punctuationCount;
      if (subject) {
        var punctuationCount = stringUtil.getPunctuationCount(subject);
        if (punctuationCount <= MAX_PUNCTUATION_COUNT_FOR_GOOD_SUBJECT) {
          result = {
            status: STATUS_PASS,
            title: PUNCTUATION_COUNT_TITLE_PASS,
            description: PUNCTUATION_COUNT_DESCRIPTION_PASS
          };
        } else {
          result = {
            status: STATUS_FAIL,
            title: PUNCTUATION_COUNT_TITLE_FAIL,
            description: PUNCTUATION_COUNT_DESCRIPTION_FAIL
          };
        }
      }
      return result;
    },
    render: function () {
      this.inherited(arguments);
      var smartMark = new SmartMark({
        tier: 1,
        label: "Based on best practices"
      });
      smartMark.placeAt(this.smartMarkContainer, "only");
    }
  });
});