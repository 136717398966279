define(["mojo/lib/flags", "./models/blocks/Text", "./models/blocks/BoxedText", "./models/blocks/Divider", "./models/blocks/Image", "./models/blocks/ImageGroup", "./models/blocks/ImageCard", "./models/blocks/ImageCaption", "./models/blocks/SocialShare", "./models/blocks/SocialFollow", "./models/blocks/Button", "./models/blocks/Footer", "./models/blocks/Code", "./models/blocks/RssHeader", "./models/blocks/RssItems", "./models/blocks/Video", "./models/blocks/Api", "./models/blocks/ProductRec", "./models/blocks/Product", "./models/blocks/Payment", "./models/blocks/AbandonedCart", "./models/blocks/AbandonedBrowse", "./models/blocks/PromoCode", "./models/blocks/SignUpForm", "./models/blocks/WebVideo", "vendor/lodash-amd/filter", "vendor/lodash-amd/keyBy"], function (flags, Text, BoxedText, Divider, Image, ImageGroup, ImageCard, ImageCaption, SocialShare, SocialFollow, Button, Footer, Code, RssHeader, RssItems, Video, Api, ProductRec, Product, Payment, AbandonedCart, AbandonedBrowse, PromoCode, SignUpForm, WebVideo, filter, keyBy) {
  // what type of Neapolitan editor this is
  var editingType = null;

  // make sure all the blocks from the arguments are in this list, when adding new blocks
  var blocksFromArguments = [Text, BoxedText, Divider, Image, ImageGroup, ImageCard, ImageCaption, SocialShare, SocialFollow, Button, Footer, Code, RssHeader, RssItems, Video, Api, ProductRec, Product, Payment, AbandonedCart, AbandonedBrowse, PromoCode, SignUpForm, WebVideo];
  var blocksByType = {};
  var i;
  var blockOverrides = {};
  for (i = 0; i < blocksFromArguments.length; i++) {
    var editor = blocksFromArguments[i];
    var typeRef = editor.prototype.type;
    if (typeRef) {
      var j = 0;
      while (blocksByType.hasOwnProperty(typeRef)) {
        typeRef = editor.prototype.type + "_" + j;
        j++;
      }
      blocksByType[typeRef] = editor;
    }
  }
  // incredmentally add mobile supported block as needed;
  var mobileBlockMap = {
    "text": true,
    "boxedText": true,
    "divider": true,
    "button": true,
    "footer": true,
    "image": true,
    "imageCard": true,
    "imageGroup": true,
    "imageCaption": true,
    "video": true,
    "socialFollow": true,
    "socialShare": true,
    "product": true,
    "productRec": true,
    "abandonedCart": true,
    "promoCode": true
  };

  /**
   * validPlatformCheck
   * @param {Array} ecommStores is a list of available stores for the block
   * @returns {Boolean} flag for if block is available for campaign type with platform found
   */
  var validPlatformCheck = function (ecommStores) {
    // Loop through map to find an allowed platform, if found return true
    var platformFound = false;
    ecommStores.forEach(function (ecommStore) {
      if (ecommStore.show_promo_code_block === true) {
        platformFound = true;
      }
    });
    return platformFound;
  };
  var filterBlockByCampaignType = function (editorModule, campaignType, user, isMobileNative, ecommUser) {
    var block = editorModule.prototype;
    var validVisible = block._visible;
    var validType = block._campaignType ? block._campaignType === campaignType : true;
    var validEcomm = block._ecommOnly ? user.isAnyEcomm : true;
    var validEditingType = block._editingTypes.indexOf(editingType) >= 0;
    var validPaid = block._paidOnly ? block._paidOnly && user.hasPaid : true;
    var validFlag = block._featureFlag ? user.flagIsOn(block._featureFlag) : true;
    var validMobile = isMobileNative ? mobileBlockMap[block.type] : true;
    var validPage = campaignType === "page" ? mobileBlockMap[block.type] : true;
    var validBlock = validVisible && validType && validEcomm && validEditingType && validPaid && validFlag && validMobile && validPage;
    return validBlock;
  };
  return {
    /**
     * The overrides will override a block's default attributes in the constructor
     *
     * @param type
     * @param overrides
     */
    overrideBlockDefaults: function (type, overrides) {
      blockOverrides[type] = overrides;
    },
    getBlocks: function () {
      return blocksByType;
    },
    getFilteredBlocks: function (blockToHide) {
      // build a new object with keys of types after fitlered by blocks to hide
      var filteredBlocksByType = keyBy(filter(blocksByType, function (BlockModel) {
        return blockToHide.indexOf(BlockModel.prototype.type) === -1;
      }), "prototype.type");
      return filteredBlocksByType;
    },
    /**
     * getBlockTypesByCampaign
     * @param {String} campaignType is the types of campaigns matched for each block
     * @param {Object} user is the user module for getting user specific settings for blocks behind flags
     * @param {Boolean} isMobileNative is a flag for showing only mobile supported blocks
     * @param {Object} ecommStores is a list of available stores for the block
     * @returns {Array} visibleBlocks as array of block types as strings
     */
    getBlockTypesByCampaign: function (campaignType, user, isMobileNative, ecommStores, ecommUser) {
      var visibleBlocks = [];
      for (var type in blocksByType) {
        if (blocksByType.hasOwnProperty(type)) {
          var editorByType = blocksByType[type];
          var blockVisible = filterBlockByCampaignType(editorByType, campaignType, user, isMobileNative);
          if (blockVisible) {
            visibleBlocks.push(type);
          }
        }
      }
      return visibleBlocks;
    },
    create: function (type, data) {
      var blockOverride = null;
      if (blockOverrides[type]) {
        blockOverride = blockOverrides[type];
      }
      return new blocksByType[type](data, blockOverride);
    },
    /**
     * set the type of Neapolitan editor
     * @param {string} type is which type of Neapolitan editor this is, e.g. "campaigns", "page" or "template"
     */
    setEditingType: function (type) {
      editingType = type;
    }
  };
});