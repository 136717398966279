/**
 * Widget for each individual ChannelItem. I.e. Facebook, Instagram. Each ChannelItem should be responsible for
 * itself and cross-talk between them should be nonexistent.
 * @module mojo/widgets/ads/facebook/edit-connect/ChannelItem
 * @see mojo/widgets/ads/facebook/EditConnectSlat
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./PlacementInput", "dojo/text!./templates/ChannelItem.html"], function (declare, array, lang, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, on, domClass, PlacementInput, tplStr) {
  /**
   * Widget for each individual ChannelItem. I.e. Facebook, Instagram. Each ChannelItem should be responsible for
   * itself and cross-talk between them should be nonexistent.
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    /**
     * Function responsible for initializing the widget and loading handlers. Should stay short.
     */
    postCreate: function () {
      this.setup();

      // Init handlers
      on(this.switch, "change", lang.hitch(this, "handleSelection"));
    },
    /**
     * Handle some default actions that are part of the creation of the widget.
     */
    setup: function () {
      if (this.isEnabled()) {
        this.switch.set("value", this.data.selected);
        this.buildPlacementInput();
        this.handleSelection();
      } else {
        this.handleDisabled();
      }
    },
    /**
     * Create placement selection input based on data passed into the widget.
     */
    buildPlacementInput: function () {
      this.input = new PlacementInput({
        label: this.data.label,
        identifier: this.data.identifier,
        placements: this.data.placements
      });
      this.input.placeAt(this.placements);
      this.input.startup();
    },
    /**
     * Handle interactions when toggling the switch or when widget is first loaded.
     */
    handleSelection: function () {
      domClass.toggle(this.placements, "grayscale no-clicky", !this.isSelected());
    },
    /**
     * Set a number of opinionated styles and toggle visibility of certain elements if channel is disabled.
     */
    handleDisabled: function () {
      // Show helpful message
      this.disabledMsg.innerHTML = this.data.description;
      domClass.remove(this.disabledContainer, "hide");

      // Show appropriate icon
      domClass.remove(this.disabledIcon, "hide");
      domClass.add(this.icon, "hide");

      // Make the switch obviously disabled
      this.switch.set("disabled", "disabled");
      domClass.toggle(this.controlContainer, "cursor-not-allowed dim6");
    },
    /**
     * Do a simple check of whether or not the switch is checked.
     * @returns {boolean} - Is the switch checked?
     */
    isSelected: function () {
      return this.switch.get("value") !== false;
    },
    /**
     * @returns {boolean} - Is the channel enabled?
     */
    isEnabled: function () {
      return this.data.enabled;
    },
    /**
     * Public method for retrieval of necessary form data for this channel.
     *
     * @returns {obj} - A key-value pair of all of the inputs name and values or an empty object.
     */
    getFormData: function () {
      var formData = {};
      if (this.isSelected()) {
        // Add a key/value pair for the switch. Key ends up being "fb_selected"
        formData[this.data.identifier + "_selected"] = true;
        // Append PlacementInput selection to the object
        formData = lang.mixin(formData, this.input.getFormData());
      }
      return formData;
    }
  });
});