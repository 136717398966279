define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/_Part", "dijit/_Templated", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dojo/text!./templates/filterMessage.html"], function (declare, lang, on, _Part, _Templated, array, domStyle, domClass, aspect, entities, tpl) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    postMixInProperties: function () {
      this.keyMap = {};
      this.filterKeys = [];
      array.forEach(this.filters, lang.hitch(this, function (filter) {
        this.keyMap[filter.key] = lang.clone(filter);
        this.filterKeys.push(filter.key);
      }));
    },
    init: function () {
      var self = this;
      this.own(on(this.resetFilter, "click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        array.forEach(self.filters, function (filter) {
          self.target.clearFilter(filter.key);
        });
      }), aspect.after(this.target, 'onFilterChange', function () {
        domStyle.set(self.domNode, 'display', self.canDisplay() ? '' : 'none');
        if (self.canDisplay()) self._displayMessage();
      }));
      self._displayMessage();
    },
    _displayMessage: function () {
      var messages = [];
      var searchString = "";
      for (var key in this.keyMap) {
        if (this.target.hasFilter(key)) {
          var filter = this.keyMap[key];
          switch (filter.type) {
            case 'select':
              var option = entities.encode(filter.options[this.target.getFilter(key)]);
              messages.push(filter.label + ": " + option);
              break;
            case 'text':
              var textValue = entities.encode(this.target.getFilter(key));
              messages.push(filter.label + ": " + textValue);
              break;
            case 'checkbox':
              if (this.target.getFilter(key)) {
                messages.push(entities.encode(filter.label));
              }
              break;
            case 'search':
              var searchTerm = entities.encode(this.target.getFilter(key));
              searchString = '<strong>Searching for</strong> "' + searchTerm + '"';
              break;
            default:
              throw Error("Unrecognized type " + filter.type);
          }
        }
      }
      var filterHTML = '';
      array.forEach(messages, function (m) {
        if (filterHTML) filterHTML += ", ";
        filterHTML += m;
      });
      var messageHTML = '';
      if (searchString) {
        messageHTML += searchString;
      }
      if (filterHTML) {
        if (messageHTML) messageHTML += ", ";
        messageHTML += "<strong>Filtered By:</strong> " + "<span>" + filterHTML + "</span>";
      }
      this.message.innerHTML = messageHTML;
    },
    canDisplay: function () {
      return array.some(this.filterKeys, lang.hitch(this, function (key) {
        return this.target.hasFilter(key);
      }));
    }
  });
});