define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "./_SegmentInputMixin", "./Date", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _SegmentInputMixin, DateInput, domConstruct, lang, on, date, I18nTranslation, flags, context) {
  var andText = "and";
  var invalidRangeText = "Invalid date range";
  var blankText = "blank";
  return declare([_WidgetBase, _SegmentInputMixin], {
    "class": "flex",
    // Reference to the left date input
    leftDate: null,
    // Reference to the right date input
    rightDate: null,
    postMixInProperties: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        andText = I18nTranslation.translate("audience_management_segment_edit_option_and");
        invalidRangeText = I18nTranslation.translate("audience_management_segment_builder_invalid_date_range");
        blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    buildRendering: function () {
      this.domNode = domConstruct.create("div");
      this.leftDate = new DateInput();
      this.leftDate.startup();
      this.rightDate = new DateInput();
      this.rightDate.startup();
      this._addInitialConstraints();
      this.own(on(this.leftDate, "change", lang.hitch(this, "_adjustRightConstraint")), on(this.rightDate, "change", lang.hitch(this, "_adjustLeftConstraint")));
      domConstruct.place(this.leftDate.domNode, this.domNode);
      domConstruct.place("<span class=\"margin--lv2 !margin-top--lv0 !margin-left--lv0 !margin-bottom--lv1 flex flex-center\">" + andText + "</span>", this.domNode);
      domConstruct.place(this.rightDate.domNode, this.domNode);
    },
    getDisplayHTML: function () {
      if (this.leftDate.isValid() && this.rightDate.isValid()) {
        if (this.leftDate.isBlank() && this.rightDate.isBlank()) {
          return "<span class=\"dim6\">(" + blankText + ")</span>";
        }
        return this.leftDate.getDisplayHTML() + ' <span class="fwn"> ' + andText + "</span> " + this.rightDate.getDisplayHTML();
      }
      return "<div class=\"invalid-error !margin--lv0\">" + invalidRangeText + "</div>";
    },
    getValue: function () {
      return [this.leftDate.getValue(), this.rightDate.getValue()];
    },
    setValue: function (values) {
      this.leftDate.setValue(values[0]);
      this.rightDate.setValue(values[1]);
    },
    _addInitialConstraints: function () {
      var leftVal = null;
      var rightVal = null;
      if (this.leftDate.value instanceof Date) {
        leftVal = new Date(this.leftDate.value);
      }
      if (this.rightDate.value instanceof Date) {
        rightVal = new Date(this.rightDate.value);
      }
      var sameDay = leftVal && rightVal && date.difference(leftVal, rightVal, "day") === 0;
      if (sameDay) {
        this.rightDate.set("value", date.add(leftVal, "day", 1));
      }
    },
    _adjustLeftConstraint: function () {
      var maxVal = null;
      if (this.rightDate.value instanceof Date) {
        maxVal = date.add(this.rightDate.value, "day", -1);
      }
      this.leftDate.constraints.max = maxVal;
    },
    _adjustRightConstraint: function () {
      var minVal = null;
      if (this.leftDate.value instanceof Date) {
        minVal = date.add(this.leftDate.value, "day", 1);
      }
      this.rightDate.constraints.min = minVal;
    }
  });
});