define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "mojo/views/_base", "mojo/api/postcard", "mojo/user", "mojo/utils", "mojo/widgets/checklist-editor/Container", "mojo/widgets/checklist-editor/status/PostcardStatus", "mojo/widgets/checklist-editor/items/PostcardBillingSidebar", "./getChecklistItems"], function (declare, lang, on, request, mUrl, base, postcardApi, user, utils, ChecklistEditorContainer, PostcardStatus, PostcardBillingSidebar, getChecklistItems) {
  return declare([base], {
    onReady: function () {
      var accountUpgradeDetails = this.account_upgrade_details;
      var postcardModel = postcardApi.createFromProperties(this.postcard);
      var checklist = new ChecklistEditorContainer({
        statusWidget: PostcardStatus,
        sidebarWidget: PostcardBillingSidebar,
        model: postcardModel,
        willUpdate: function (prevState, nextState) {
          if (prevState.model.audienceBuildingStatus.isFetchingAddresses() && nextState.model.audienceBuildingStatus.isReady()) {
            utils.toast(nextState.model.audienceSize && nextState.model.audienceSize > 0 ? "Done! We found " + nextState.model.audienceSize + " addresses for your postcard campaign." : "Uh-oh. We had trouble building your audience.");
          }
        },
        items: getChecklistItems(postcardModel, {
          pricingData: this.postcard_pricing_data,
          accountUpgradeDetails: accountUpgradeDetails
        }),
        onSave: function (data, model) {
          return model.patch(data);
        }
      });
      checklist.placeAt(this.$el.postcardChecklist);

      // Terms of Service Dialog
      if (this.$widget.tosDialog) {
        this.$widget.tosDialog.show();
        on(this.$el.cancelTos, "click", lang.hitch(this, function () {
          this.$widget.tosDialog.hide();
          window.ga("send", "event", "Modal / Postcard Campaign Terms of Use", "Clicked / Cancel", name);
        }));
        on(this.$el.acceptTos, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          window.ga("send", "event", "Modal / Postcard Campaign Terms of Use", "Clicked / I Accept", name);
          request.post(mUrl.toUrl("/postcards/accept-terms"), {
            handleAs: "json"
          }).then(lang.hitch(this, function (data) {
            if (data.status === "success") {
              this.$widget.tosDialog.hide();
            } else {
              throw new Error("Unknown error " + JSON.stringify(data));
            }
          }));
        }));
      }
    }
  });
});