define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "mojo/url", "mojo/utils",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/filter-bar.html",
// Widgets
"mojo/analytics/parts/_Part", "dijit/form/DropDownButton", "../tags/TagBadge", "./SendCampaignDropdown",
// API
"../tags/Api", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags"], function (declare, lang, domClass, domConstruct, on, number, mUrl, mUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, barTplStr, _Part, DropDownButton, TagBadge, SendCampaignDropdown, api, I18nTranslation, context, flags) {
  return declare([_Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    target: null,
    listId: null,
    templateString: barTplStr,
    filterButton: null,
    filterOverlay: null,
    sendCampaignDropdown: null,
    tags: [],
    tagBadgeHandles: [],
    tagFilter: [],
    tagLimit: 5,
    filteredByTags: "Filtered by Tags",
    init: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize();
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          this.filteredByTags = I18nTranslation.translate("audience_management_filtered_by_tags");
        }
      }
      this.setupFilterOverlay();
      this.setupEventHandlers();
      this.setupSendCampaign();
      this.updateContactCount();
      this.populateFilterList();
      this.updateFilterOverlay();
    },
    /**
     * Set up the UI for filtering by tag(s)
     */
    setupFilterOverlay: function () {
      this.filterButton = new DropDownButton({
        "label": this.filteredByTags,
        "dropDown": this.filterOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.filterButton);
      this.filterButton.startup();
      this.filterOverlay.on("applyTag", lang.hitch(this, "applyTagToFilter"));
      this.filterOverlay.on("removeTag", lang.hitch(this, "removeTagFromFilter"));
    },
    /**
     * Set up event handlers
     */
    setupEventHandlers: function () {
      on(this.closeButton, "click", lang.hitch(this, "onCloseClick"));
      this.target.watch("total", lang.hitch(this, "updateContactCount"));
    },
    /**
     * Append the passed-in campaign send dropdown
     */
    setupSendCampaign: function () {
      this.sendCampaignDropdown = new SendCampaignDropdown({
        target: this.target,
        listId: this.listId,
        campaignOrigin: "seg-bar-filter"
      }, this.sendCampaignContainer);
      this.sendCampaignDropdown.startup();
    },
    /**
     * Update the contact count display text
     */
    updateContactCount: function () {
      var newInnerText = number.format(this.target.total) + " contact";
      if (this.target.total !== 1) {
        newInnerText += "s contain";
      } else {
        newInnerText += " contains";
      }
      this.filterContactCount.innerText = newInnerText;
    },
    /**
     * Populate the tag filter list with the provided list of tags
     */
    populateFilterList: function () {
      // Discard existing badges and event handles
      domConstruct.empty(this.filterListContainer);
      this.tagBadgeHandles = [];
      var self = this;
      // Repopulate the tag badge list based on the passed in tags list
      this.tagFilter.forEach(lang.hitch(this, function (tag) {
        var tagBadge = new TagBadge({
          "tag": tag,
          "tagName": tag.tag_name,
          "readOnly": false
        });
        tagBadge.placeAt(this.filterListContainer, "last");
        var tagHandle = on(tagBadge, "remove", function () {
          self.removeTagFromFilter(tag);
        });
        this.tagBadgeHandles.push(tagHandle);
      }));

      //If max filters have been added, swap the tag overlay for a message overlay
      if (this.tagFilter.length >= this.tagLimit) {
        domClass.add(this.filterButtonContainer, "hide");
        domClass.remove(this.filterButtonMaxContainer, "hide");
      } else {
        domClass.remove(this.filterButtonContainer, "hide");
        domClass.add(this.filterButtonMaxContainer, "hide");
      }
    },
    /**
     * Apply the selected tag to the filter conditions
     * @param {object} tag to apply
     */
    applyTagToFilter: function (tag) {
      this.tagFilter.push(tag);
      api.updateTagFilter(this.listId, this.tagFilter, lang.hitch(this, "genericSuccessCallback"));
    },
    /**
     * Remove the selected tag from the filter conditions
     * @param {object} tag to remove
     */
    removeTagFromFilter: function (tag) {
      var indexToSplice = -1;
      this.tagFilter.forEach(function (tagFilter, index) {
        if (tag.tag_id === tagFilter.tag_id) {
          indexToSplice = index;
        }
      });
      if (indexToSplice > -1) {
        this.tagFilter.splice(indexToSplice, 1);
        api.updateTagFilter(this.listId, this.tagFilter, lang.hitch(this, "genericSuccessCallback"));
      }
    },
    /**
     * Generic callback to show messaging about filter apply/remove success
     */
    genericSuccessCallback: function () {
      mUtils.toast("Updated tag filter");
      this.filterButton.closeDropDown();

      // If all filters have been removed, close the filter bar
      if (this.tagFilter.length < 1) {
        this.onClose();
      } else {
        this.populateFilterList();
      }
      this.updateFilterOverlay();
      this.onFilterUpdate(this.tagFilter);
    },
    /**
     * Pass the filtered-by IDs to the overlay for marking
     */
    updateFilterOverlay: function () {
      var tagsToMark = [];
      this.tagFilter.forEach(function (filter) {
        tagsToMark.push(filter.tag_id);
      });
      this.filterOverlay.setSelection({
        "all": tagsToMark,
        "mixed": []
      });
    },
    /**
     * Clear all tags from the filter
     */
    clearAllTagsCallback: function () {
      this.tagFilter = [];
      this.genericSuccessCallback();
    },
    /**
     * Override of _Part canDisplay function
     * @returns {boolean} can display value
     */
    canDisplay: function () {
      return true;
    },
    /**
     * Event hooks
     */
    onCloseClick: function () {
      api.updateTagFilter(this.listId, [], lang.hitch(this, "clearAllTagsCallback"));
    },
    onClose: function () {},
    onFilterUpdate: function (tagIds) {},
    onSendAction: function () {},
    onAdAction: function () {},
    onLookalikeAction: function () {}
  });
});