define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/FixableWrapper.html"], function (declare, domClass, on, query, domGeom, domStyle, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    wrapDistanceFromTopOfPage: 0,
    wrapHeight: 0,
    distanceFromTopOfWrap: 0,
    _onScroll: function () {
      var hasMetScrollThreshold = domGeom.position(this.domNode.parentNode).y <= this.fixedThreshold + this.wrapDistanceFromTopOfPage;
      var isShorterThanWrapper = domGeom.getContentBox(this.domNode).h + this.distanceFromTopOfWrap < this.wrapHeight;
      domClass.toggle(this.domNode, this.fixedClass, hasMetScrollThreshold && isShorterThanWrapper);
      domStyle.set(this.domNode, {
        top: hasMetScrollThreshold && isShorterThanWrapper ? this.fixedThreshold + this.wrapDistanceFromTopOfPage + "px" : ""
      });
    },
    _onResize: function (e) {
      this.wrapDistanceFromTopOfPage = domGeom.position(this.wrapper).y;
      this.wrapHeight = domGeom.getContentBox(this.wrapper).h;
      this.distanceFromTopOfWrap = this.domNode.offsetTop;
    },
    resetFixedScroll: function () {
      this._onScroll();
      this._onResize();
    },
    postCreate: function () {
      var wrapper = query("#wrap");
      if (wrapper[0]) {
        this.wrapper = wrapper[0];
        this._onResize();
        on(this.wrapper, "scroll", this._onScroll.bind(this));
        on(window, "resize", this._onResize.bind(this));
      } else {
        throw "Global element #wrap must exist on the page to use this widget.";
      }
    }
  });
});