define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/api/postcard", "mojo/postcard/Report", "mojo/postcard/widgets/ReportPage"], function (declare, _base, postcardApi, Report, ReportPage) {
  return declare([_base], {
    onReady: function () {
      var postcard = postcardApi.createFromProperties(this.postcard);
      var report = new Report(postcard, this.report);
      new ReportPage({
        postcard: postcard,
        report: report
      }).placeAt(this.$el.reportPageContainer);
    }
  });
});