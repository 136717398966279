define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/registry", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog"], function (declare, base, mUrl, registry, on, Dialog, domStyle) {
  return declare([base], {
    user_id: null,
    multipleIps: null,
    onReady: function () {
      var self = this;
    },
    _addUser: function (domain) {
      this.$el.addDomainInput.value = domain;
      this.$widget.addUserDialog.show();
    },
    _viewComments: function (comments) {
      this.$el.userComments.innerHTML = comments;
      this.$widget.userCommentDialog.show();
    },
    _removeUser: function (id, domain) {
      this.$el.userIdInput.value = id;
      this.$el.removeDomainInput.value = domain;
      this.$widget.removeUserDialog.show();
    }
  });
});