define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/Dialog"], function (declare, on, query, base, mUrl, Dialog) {
  var handleCancelBtn = function (dialog) {
    //Close dialog on cancel button
    query('.cancel-button', dialog.domNode).on('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      dialog.hide();
    });
  };
  return declare([base], {
    showDismissReconfirmModal: function (listID) {
      var dialog = new Dialog({
        'title': 'Are you sure?',
        'onHide': function () {
          dialog.destroyRecursive();
        },
        'onDownloadEnd': function () {
          handleCancelBtn(dialog);
        }
      });
      dialog.setHref(mUrl.toUrl('lists/dismiss-reconfirm-modal', {
        'id': listID
      }));
      dialog.show();
    }
  });
});