/**
 * A widget that contains inputs that allow a user to edit the following CSS attributes:
 *
 *  - box-shadow
 *
 *  Neapolitan switches to use this input when all of the css attributes are present for a particular
 *  css selector.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "dijit/_WidgetsInTemplateMixin", "./_WithLabelMixin", "./ColorWithAlpha", "dojo/text!./templates/boxShadowInput.html"], function (declare, Color, domClass, on, _TemplatedMixin, _WidgetBase, _WidgetsInTemplateMixin, _WithLabelMixin, ColorWithAlphaInput, tpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _WithLabelMixin], {
    templateString: tpl,
    label: "Box-Shadow",
    selector: null,
    value: "none",
    xOffset: 0,
    _xNegative: false,
    _xCentered: false,
    yOffset: 0,
    _yPositive: false,
    _yCentered: false,
    // The value for position, parsed from full box-shadow value, and split from position values "[1px 1px 1px 1px] rgba(0,0,0,.5)"
    _position: "",
    // The value for position string, parsed from full box-shadow value, and split from position values "[1px 1px 1px 1px] rgba(0,0,0,.5)"
    _position_string: "",
    // The value for rgbaColor, parsed from full box-shadow value, and split from color portion "1px 1px 1px 1px [color: rgba([0,0,0],.5)", instantiate Dojo Color
    rgbaColor: "",
    position_offset: 10,
    DEFAULT_OFFSET: 10,
    DEFAULT_UNIT: "px",
    DEFAULT_BLUR: 25,
    _blur: 25,
    DEFAULT_SPREAD: 0,
    _spread: 0,
    DEFAULT_COLOR: "rgba(0, 0, 0, 0.5)",
    /**
     * Helper for setting the attribute and firing onChange event
     *
     * @param {string} attr Setting the box-shadow module attribute
     * @param {string} value Setting the position value
     * @private
     */
    _firePositionEvent: function (attr, value) {
      this.set(attr, value);
      if (value !== "none") {
        // build value from this.get(position), built-color (this.get(color), this.get(opacity));
        var pos = this._buildPositionValue();
        var bsValue = pos + " " + this.rgbaColor.toCss(true);
        this.set("value", bsValue);
      } else {
        this.set("value", value);
      }
      this.onChange(this.get("value"));
    },
    /**
     * Helper for setting the attribute and firing onChange event
     *
     * @param {string} newValue Setting the color and opacity value
     * @private
     */
    _changeColorWithAlpha: function (newValue) {
      var pos = this._buildPositionValue();
      var bsValue = pos + " " + newValue.toCss(true);
      this.set("value", bsValue);
      this.onChange(this.get("value"));
    },
    /**
     * Helper for setting the attribute and firing onChange event
     *
     * @param {string} newValue Setting the blur value
     * @private
     */
    _changeBlur: function (newValue) {
      if (this.value !== "none") {
        this._blur = Math.ceil(newValue);
        var newPosition = this._buildPositionValue();
        var bsValue = newPosition + " " + this.rgbaColor;
        this.set("value", bsValue);
      }
      this.onChange(this.get("value"));
    },
    /**
     * Helper for setting the attribute and firing onChange event
     *
     * @param {string} newValue Setting the blur value
     * @private
     */
    _changeOffset: function (newValue) {
      if (this.value !== "none") {
        var wholeOffset = Math.ceil(newValue);
        this.xOffset = this._setXOffset(wholeOffset);
        this.yOffset = this._setYOffset(wholeOffset);
        this.position_offset = wholeOffset;
        var newPosition = this._buildPositionValue();
        var bsValue = newPosition + " " + this.rgbaColor;
        this.set("value", bsValue);
      }
      this.onChange(this.get("value"));
    },
    _setXOffset: function (value) {
      var negative = "-";
      if (this._xCentered) {
        return 0;
      }
      if (this._xNegative && value > 0) {
        negative = "-";
      } else {
        negative = "";
      }
      return Number(negative + value);
    },
    _setYOffset: function (value) {
      var negative = "-";
      if (this._yCentered) {
        return 0;
      }
      if (this._yNegative && value > 0) {
        negative = "-";
      } else {
        negative = "";
      }
      return Number(negative + value);
    },
    postCreate: function () {
      this.inherited(arguments);
      this.originalValue = this.value;

      // set module props from this.value
      this._setupInputs(this.value);

      // TODO: clean up _createBoxShadowPosition
      // to not trap xCentered, yCentered, _xNegative, and _yNegative these are side-effects of the _createBoxShadowPosition
      // invoke this method to change the xCentered, yCentered, _xNegative, and _yNegative
      this._createBoxShadowPosition(this._position_string);

      // Listen to all changes
      this.own(on(this.positionInput, "change", function (val) {
        var pos = this._createBoxShadowPosition(val);
        this._firePositionEvent("_position", pos);
      }.bind(this)), on(this.blurInput, "change", function (val) {
        this._changeBlur(val);
      }.bind(this)), on(this.offsetInput, "change", function (val) {
        this._changeOffset(val);
      }.bind(this)));
      this.watch("rgbaColor", function (prop, oldValue, newValue) {
        // set value with position and toCSS;
        this._changeColorWithAlpha(newValue);
      }.bind(this));
      this.watch("value", function (prop, oldValue, newValue) {
        // hide color and opacity
        if (oldValue !== newValue && newValue === "none") {
          domClass.add(this.boxShadowColorOptions, "hide", true);
          domClass.add(this.boxShadowBlurOffsetOptions, "hide", true);
        }
        // show color and opacity
        if (oldValue !== newValue && oldValue === "none") {
          domClass.remove(this.boxShadowColorOptions, "hide", true);
          domClass.remove(this.boxShadowBlurOffsetOptions, "hide", true);
        }
      }.bind(this));
    },
    _buildPositionValue: function () {
      return this.xOffset + "px " + this.yOffset + "px " + this._blur + "px " + this._spread;
    },
    _setInputValues: function () {
      this.colorInput.set("value", this.rgbaColor);
      this.positionInput.attr("value", this._position_string);
      this.blurInput.attr("value", this._blur);
      this.offsetInput.attr("value", this.position_offset);
    },
    _setupInputs: function (value) {
      if (value !== "none") {
        this._position = this._parseValue("position", value);
        this._blur = this._parseValue("blur", value);
        this.xOffset = this._parseValue("xOffset", value);
        this.yOffset = this._parseValue("yOffset", value);
        this.position_offset = this._parseValue("positionOffset", value);
        this._spread = this._parseValue("spread", value);
        this._position_string = this._parseBoxShadowPosition(this._position);
        this.rgbaColor = new Color(this._parseValue("color", this.value));
      } else {
        this._position_string = "none";
        this.rgbaColor = new Color(this.DEFAULT_COLOR);
        this._blur = this.DEFAULT_BLUR;
        this._spread = this.DEFAULT_SPREAD;
        this.position_offset = this.DEFAULT_OFFSET;
        domClass.add(this.boxShadowColorOptions, "hide", true);
        domClass.add(this.boxShadowBlurOffsetOptions, "hide", true);
      }
      this._setInputValues();
    },
    _parseValue: function (prop, value) {
      var splitProperties = value.split(/\s?(rgba)/);
      var positionProperties = splitProperties[0].split(/\s/);
      switch (prop) {
        case "positionOffset":
          // need to check both x and y: positionProperties[0] and positionProperties[1]
          return Math.abs(Number(positionProperties[0].replace("px", ""))) || Math.abs(Number(positionProperties[1].replace("px", "")));
        case "xOffset":
          return Number(positionProperties[0].replace("px", ""));
        case "yOffset":
          return Number(positionProperties[1].replace("px", ""));
        case "blur":
          return Number(positionProperties[2].replace("px", ""));
        case "spread":
          return Number(positionProperties[3].replace("px", ""));
        case "position":
          return splitProperties[0];
        case "color":
          return splitProperties[1] + splitProperties[2];
        default:
          throw new Error("no property was parsed for box-shadow");
      }
    },
    _createBoxShadowPosition: function (position) {
      // var xOffset;
      // var yOffset;
      var isPositionNone = false;
      switch (position) {
        case "top-left":
          this.xOffset = Number("-" + this.position_offset);
          this.yOffset = Number("-" + this.position_offset);
          this._xNegative = true;
          this._yNegative = true;
          this._xCentered = false;
          this._yCentered = false;
          break;
        case "top-center":
          this.xOffset = 0;
          this.yOffset = Number("-" + this.position_offset);
          this._xNegative = false;
          this._yNegative = true;
          this._xCentered = true;
          this._yCentered = false;
          break;
        case "top-right":
          this.xOffset = this.position_offset;
          this.yOffset = Number("-" + this.position_offset);
          this._xNegative = false;
          this._yNegative = true;
          this._xCentered = false;
          this._yCentered = false;
          break;
        case "middle-left":
          this.xOffset = Number("-" + this.position_offset);
          this.yOffset = 0;
          this._xNegative = true;
          this._yNegative = false;
          this._xCentered = false;
          this._yCentered = true;
          break;
        case "middle-right":
          this.xOffset = this.position_offset;
          this.yOffset = 0;
          this._xNegative = false;
          this._yNegative = false;
          this._xCentered = false;
          this._yCentered = true;
          break;
        case "bottom-left":
          this.xOffset = Number("-" + this.position_offset);
          this.yOffset = this.position_offset;
          this._xNegative = true;
          this._yNegative = false;
          this._xCentered = false;
          this._yCentered = false;
          break;
        case "bottom-center":
          this.xOffset = 0;
          this.yOffset = this.position_offset;
          this._xNegative = false;
          this._yNegative = false;
          this._xCentered = true;
          this._yCentered = false;
          break;
        case "bottom-right":
          this.xOffset = this.position_offset;
          this.yOffset = this.position_offset;
          this._xNegative = false;
          this._yNegative = false;
          this._xCentered = false;
          this._yCentered = false;
          break;
        default:
          isPositionNone = true;
          break;
      }
      if (isPositionNone) {
        return "none";
      }
      return this._buildPositionValue();
      // return xOffset + " " + yOffset + " " + this.DEFAULT_BLUR + " " + this.DEFAULT_SPREAD;
    },
    _parseBoxShadowPosition: function (position) {
      var splitBoxShadowPos = position.split(" ");
      var positionAsText = "";

      // splitBoxShadowPos[2] is the boxShadow
      // splitBoxShadowPos[0] and splitBoxShadowPos[1] are the offset
      switch (splitBoxShadowPos[1]) {
        case "-" + this.position_offset + "px":
          positionAsText += "top-";
          break;
        case this.position_offset + "px":
          positionAsText += "bottom-";
          break;
        case "0px":
          // check for "-" of x, otherwise it's none;
          if (splitBoxShadowPos[0] !== 0) {
            positionAsText += "middle-";
          } else {
            positionAsText = "none";
          }
          break;
        default:
          positionAsText = "none";
          break;
      }
      if (positionAsText === "none") {
        return positionAsText;
      }
      switch (splitBoxShadowPos[0]) {
        case "-" + this.position_offset + "px":
          positionAsText += "left";
          break;
        case "0px":
          positionAsText += "center";
          break;
        case this.position_offset + "px":
          positionAsText += "right";
          break;
        default:
          positionAsText = "none";
          break;
      }
      return positionAsText;
    },
    /**
     * onColorChange is event fired from ColorWithAlpha input
     * @param {Object} rgbaColor this will be the dojo/Color object
     */
    onColorChange: function (rgbaColor) {
      // value
      this.set("rgbaColor", rgbaColor);
    },
    onChange: function (value) {},
    reset: function () {
      this.set("value", this.originalValue);
      this._setupInputs(this.value);
    }
  });
});