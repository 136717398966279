define(["mojo/url", "mojo/social-post/social-post-model", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user"], function (mUrl, SocialPost, xhr, user) {
  var api = {
    createFromProperties: function (properties, socialNetworks) {
      // Before creating a social post, make sure selected profile still
      // exists in list.
      if (properties.platform_content && socialNetworks) {
        properties.platform_content.forEach(function (networkContent) {
          var networkProfiles = socialNetworks[networkContent.platform] || [];
          if (networkContent.foreign_profile_id) {
            var matchingProfile = networkProfiles.profiles.filter(function (profile) {
              return profile.id === networkContent.foreign_profile_id;
            })[0];
            if (!matchingProfile) {
              networkContent.foreign_profile_id = null;
            }
          }
        });
      }
      return new SocialPost(properties);
    },
    patchById: function (socialPostId, patch) {
      var request = xhr.post(mUrl.toUrl("/social-posts/patch", {
        "id": socialPostId
      }), {
        "handleAs": "json",
        "data": {
          "patch": JSON.stringify(patch),
          "__csrf_token": user.getCsrfToken()
        }
      }).then(this.createFromProperties.bind(this), function (e) {
        throw new Error(e.response ? e.response.data.message : "Something went wrong.");
      });
      return request;
    }
  };
  return api;
});