/**
 * Add functions that keeps track of a filter map.
 * Uses postMixInProperties, so call this.inherited if your main widget overrides it.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dstore/db/LocalStorage"], function (declare, lang, LocalStorage) {
  return declare(null, {
    _permanentlyPersisted: true,
    _getPrefixedId: function (id, prefix) {
      return prefix ? prefix + "_" + id : id;
    },
    savedValues: function () {
      this._savedValues = this._savedValues || new LocalStorage([]);
      return this._savedValues;
    },
    getAllSavedValues: function (prefix) {
      return this.savedValues().data.reduce(lang.hitch(this, function (acc, item) {
        if (!prefix || item.id.indexOf(prefix + "_") === 0) {
          acc.push({
            id: item.id.replace(prefix + "_", ""),
            value: item.value
          });
        }
        return acc;
      }), []);
    },
    getSavedValue: function (id, prefix) {
      return this.savedValues().getSync(this._getPrefixedId(id, prefix));
    },
    setSavedValue: function (id, value, prefix) {
      return this.savedValues().put({
        id: this._getPrefixedId(id, prefix),
        value: value
      });
    },
    removeSavedValue: function (id, prefix) {
      return this.savedValues().remove(this._getPrefixedId(id, prefix));
    },
    removeAllSavedValues: function (prefix) {
      this.savedValues().data.forEach(lang.hitch(this, function (item) {
        if (!prefix || item.id.indexOf(prefix + "_") === 0) {
          this.savedValues().remove(item.id);
        }
      }));
    }
  });
});