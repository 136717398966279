define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "./api", "mojo/neapolitan/editors/_EditorMixin", "dojo/text!./templates/clientEditor.html", "dojo/text!./templates/clientEditor/section.html", "dojo/text!./templates/clientEditor/row.html", "mojo/lib/flags", "mojo/context", "mojo/utils", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, array, lang, domConstruct, domAttr, html, on, domClass, number, api, _EditorMixin, tpl, sectionTpl, rowTpl, flags, context, utils, I18nTranslation) {
  /**
   * Encapsulates a row in the client editor. Contains a check state that relies on a dijit checkbox
   */
  var Row = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: rowTpl,
    // A particular client that this row represents
    client: null,
    checked: false,
    postCreate: function () {
      this.own(on(this.domNode, "click", lang.hitch(this, function () {
        this.set("checked", !this.checked);
      })));
    },
    onChange: function (value) {},
    _setCheckedAttr: function (value) {
      this.checkBox.set("checked", value);
      if (this.checked != value) {
        this._set("checked", value);
        this.onChange(value);
      }
    }
  });

  /**
   * Encapsulate a section that contains a set of client rows.
   */
  var Section = declare([_WidgetBase, _TemplatedMixin], {
    templateString: sectionTpl,
    sectionName: "Section Name",
    clientType: null,
    store: null,
    rows: null,
    // Keeps count of the number of selected rows
    numSelectedRows: 0,
    translatedSelectAll: "Select All",
    postMixInProperties: function () {
      if (!this.clientType) throw new Error("clientType must be set");
      if (!this.store) throw new Error("store must be set");
      this.sectionName = this.store.clientLabels[this.clientType];
      if (context.flagIsOn(flags.EEE_BACKFILL_STRINGS_NUNI)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedSelectAll = I18nTranslation.translate("nuni_editor_select_all");
        }.bind(this));
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      var self = this;
      this.rows = [];
      var availableClients = this._getAvailableClients();
      if (availableClients.length) {
        array.forEach(availableClients, function (client) {
          var row = new Row({
            client: client
          });
          row.startup();
          row.placeAt(self.rowContainer);
          self.own(on(row, "change", function (checked) {
            self.set("numSelectedRows", self.numSelectedRows + (checked ? 1 : -1));
            self._updateSelectionText();
          }));
          self.rows.push(row);
        });
      } else {
        domClass.add(this.domNode, "hide");
      }
      this.own(on(this.selectionLink, "click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (self.numSelectedRows) {
          self.deselectAll();
        } else {
          self.selectAll();
        }
      }));
    },
    _updateSelectionText: function () {
      var linkText = this.numSelectedRows == 0 ? "Select All" : "Deselect All (" + this.numSelectedRows + ")";
      html.set(this.selectionLink, linkText);
    },
    selectAll: function () {
      array.forEach(this.rows, function (row) {
        row.set("checked", true);
      });
    },
    deselectAll: function () {
      array.forEach(this.rows, function (row) {
        row.set("checked", false);
      });
    },
    /**
     * Filter out clients that have already ran and have previews
     * @private
     * @returns {Array}
     */
    _getAvailableClients: function () {
      return array.filter(this.store.clients[this.clientType], lang.hitch(this, function (client) {
        return !this.store.previews || this.store.previews && !this.store.previews.hasOwnProperty(client.id);
      }));
    },
    /**
     * Return a list of clientIds that were selected.
     * @returns {Array}
     */
    getSelectedClients: function () {
      return array.map(this.getSelectedRows(), function (row) {
        return row.client.id;
      });
    },
    /**
     * Filter & return all rows that are selected.
     * @returns {Array}
     */
    getSelectedRows: function () {
      return array.filter(this.rows, function (row) {
        return row.checked;
      });
    },
    destroy: function () {
      array.forEach(this.rows, function (row) {
        row.destroy();
      });
      this.inherited(arguments);
    }
  });

  /**
   * Editor widget that allows a user to choose the clients that they want to run.
   * Uses a topic as a means to let dependent widgets handle the event when clients are selected.
   */
  var Editor = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _EditorMixin], {
    sliderEditor: true,
    templateString: tpl,
    paneId: "preview",
    store: null,
    // References all sections created.
    sections: null,
    clientsStats: null,
    preselectedClients: null,
    translatedChooseClients: "Choose clients",
    translatedAddClients: "Add clients",
    constructor: function () {
      this.sections = [];
      this.clientsStats = [];
    },
    postMixInProperties: function () {
      if (context.flagIsOn(flags.EEE_BACKFILL_STRINGS_NUNI)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedSelectAll = I18nTranslation.translate("nuni_editor_select_all");
          this.translatedChooseClients = I18nTranslation.translate("nuni_editor_choose_clients");
          this.translatedAddClients = I18nTranslation.translate("nuni_editor_add_clients");
        }.bind(this));
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      this.fetchData().then(lang.hitch(this, function () {
        this._createSection("client");
        this._createSection("mobile");
        this._createSection("web");
        this._updateAvailableTokens();
        this.setupTopClients();
        this.setupPreselectedClients();
        domClass.toggle(this.topClientsContainer, "hide", this.hasPreviews() && !this.hasTopClients());
      }));
      this.own(on(this.addClientsBtn, "click", lang.hitch(this, "_selectClients")));
    },
    setupTopClients: function () {
      array.forEach(this.clientsStats, lang.hitch(this, function (client) {
        var node = domConstruct.toDom('<li>' + client.name + ' <span class="float-right nofloat-small">' + number.format(client.percent * 100, {
          places: 1
        }) + '%</span></li>');
        domConstruct.place(node, this.topClients);
      }));
      if (context.flagIsOn(flags.EMAILIN_FIX_INBOX_PREVIEW_CLIENTS_LINK)) {
        domClass.toggle(this.topClientsLink, "hide", !this.hasTopClients());
      } else {
        domClass.toggle(this.topClientsLinkOld, "hide", !this.hasTopClients());
      }
    },
    setupPreselectedClients: function () {
      if (!this.hasPreviews()) {
        array.forEach(this.sections, lang.hitch(this, function (section) {
          array.forEach(section.rows, lang.hitch(this, function (row) {
            if (this.preselectedClients.indexOf(row.client.id) !== -1) {
              row.set("checked", true);
            }
          }));
        }));
        this.preselectedClientsText.innerHTML = this.hasTopClients() ? "We’ve preselected your subscribers’ top email clients." : "We’ve preselected the top 10 email clients used by most subscribers.";
        this.preselectedClientsText.innerHTML += " You can add more clients for greater coverage";
      }
      domClass.toggle(this.preselectedClientsText, "hide", this.hasPreviews());
    },
    hasPreviews: function () {
      return this.store.hasPreviews === true;
    },
    hasTopClients: function () {
      return this.clientsStats && this.clientsStats.length > 0;
    },
    fetchData: function () {
      var req = this.store.campaignId ? api.getCampaignClientsStats(this.store.campaignId) : api.getTemplateClientsStats(this.store.templateId);
      req.then(lang.hitch(this, function (results) {
        this.clientsStats = results.clientsStats;
        this.preselectedClients = results.preselectedClients;
      }));
      return req;
    },
    _selectClients: function () {
      var selectedClients = [];
      array.forEach(this.sections, function (section) {
        selectedClients = selectedClients.concat(section.getSelectedClients());
      });
      this._updateAvailableTokens();
      this.store.runPreview(selectedClients);
      this.done();
    },
    _getNumSelectedRows: function () {
      var numSelected = 0;
      array.forEach(this.sections, function (section) {
        numSelected += section.numSelectedRows;
      });
      return numSelected;
    },
    _updateAvailableTokens: function () {
      this.store.tokens.set("available", this.store.tokens.balance - this._getNumSelectedRows());
    },
    /**
     * Update the button label that shows how many clients are currently selected.
     * @private
     */
    _updateButtonLabel: function () {
      var numSelected = this._getNumSelectedRows();
      var label;
      if (numSelected > 0) {
        label = "Add " + numSelected + " Client" + (numSelected != 1 ? "s" : "");
        if (this.store.tokens.available >= 0) {
          domAttr.remove(this.addClientsBtn, "disabled");
        } else {
          domAttr.set(this.addClientsBtn, "disabled", "disabled");
        }
      } else {
        label = "Add clients";
        domAttr.set(this.addClientsBtn, "disabled", "disabled");
      }
      html.set(this.addClientsBtn, label);
    },
    _createSection: function (clientType) {
      var section = new Section({
        clientType: clientType,
        store: this.store
      });
      section.startup();
      section.placeAt(this.body);
      this.own(section.watch("numSelectedRows", lang.hitch(this, function () {
        this._updateAvailableTokens();
        this._updateButtonLabel();
      })));
      this.sections.push(section);
      return section;
    },
    onClose: function () {
      this.destroy();
    },
    _onDone: function (e) {
      e.stopPropagation();
      e.preventDefault();
      array.forEach(this.sections, function (section) {
        section.deselectAll();
      });
      this.done();
    },
    _onTopClientsLinkClick: function (e) {
      e.stopPropagation();
      e.preventDefault();
      utils.disclosureElement("top-clients", e.target, true);
    },
    destroy: function () {
      array.forEach(this.sections, function (section) {
        section.destroy();
      });
      this.inherited(arguments);
    }
  });

  // Reference the only editor that should be allowed in the page.
  var editor;

  /**
   * Users of the module should only be able to hide and show the current editor.
   */
  return {
    show: function (store) {
      if (!editor || editor._destroyed) {
        editor = new Editor({
          store: store
        });
      }
      editor.show();
      return editor;
    },
    hide: function () {
      if (editor && !editor._destroyed) {
        editor.done();
      }
    }
  };
});