define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "./_Base", "dojo/text!./templates/SocialShareEditor/editor.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/connect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/widgets/ItemList", "mojo/url", "mojo/context", "mojo/lib/flags", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, _Widget, _Templated, _Base, tmpl, lang, query, on, domAttr, array, connect, ItemList, mUrl, context, flags, _DynamicContentMixin) {
  return declare([_Base, _DynamicContentMixin], {
    type: 'socialShare',
    draggableListItem: true,
    templateString: tmpl,
    constructor: function () {
      // Used for the editor markup
      this.iconBaseUrl = mUrl.addProxy("http://cdn-images.mailchimp.com/icons/social-block-v2");
    },
    postCreate: function () {
      this.inherited(arguments);

      // Conditional to create itemList for emails and landing pages
      this.itemList = this._createItemList().placeAt(this.itemList);
      this.connect(this.itemList, 'onChange', 'saveItems');
      this.connect(this.itemList, 'onContentChange', 'updateItems');
      this.block.set("items", this.itemList.getOutputModel());
      this._highlightSelectedLayout();
      this._highlightSelectedIconStyle();
      this._toggleCustomUrlInput();
      var self = this;
      on(this.layoutSelector, 'li:click', function () {
        self._selectLayout(this);
      });
      on(this.iconStyleSelector, '.iconStyle:click', function () {
        self._selectIconStyle(this);
      });
      on(this.iconTypeInput, "change", function () {
        self._selectIconType(this.value);
        self.block.set("iconType", this.value);
      });
      this._selectIconType(this.block.iconType);
      this.iconTypeInput.set("value", this.block.iconType);
      this.sync("align", this.alignInput, 'value');
      this.watch("align", function (name, old, value) {
        query(self.layoutSelector).style("textAlign", value);
      });
      this.sync("adjustableWidth", this.adjustableWidthInput, "value");
      this.sync("shareContentSource", this.shareContentSourceInput, "value");
      this.watch("shareContentSource", function () {
        self._toggleCustomUrlInput();
      });
      this.sync("customUrl", this.customUrlInput, "value");
      this.connect(this.customUrlInput, "onKeyUp", function () {
        this.block.set("customUrl", this.customUrlInput.get("value"));
      });
      this.sync("customDescription", this.customDescriptionInput, "value");
      this.connect(this.customDescriptionInput, "onKeyUp", function () {
        this.block.set("customDescription", this.customDescriptionInput.get("value"));
      });
    },
    _createItemList: function () {
      var availableItemTypes = [];
      if (context.flagIsOn(flags.EM2_REMOVE_GOOGLE_PLUS_SOCIAL_BLOCKS)) {
        availableItemTypes = [{
          value: "facebookShare",
          label: "Facebook"
        }, {
          value: "twitterShare",
          label: "X (formerly Twitter)"
        }, {
          value: "linkedInShare",
          label: "LinkedIn"
        }, {
          value: "pinterestShare",
          label: "Pinterest"
        }, {
          value: "instapaperShare",
          label: "Instapaper"
        }];
      } else {
        availableItemTypes = [{
          value: "facebookShare",
          label: "Facebook"
        }, {
          value: "twitterShare",
          label: "X (formerly Twitter)"
        }, {
          value: "linkedInShare",
          label: "LinkedIn"
        }, {
          value: "pinterestShare",
          label: "Pinterest"
        }, {
          value: "instapaperShare",
          label: "Instapaper"
        }, {
          value: "googlePlusShare",
          label: "Google +1"
        }];
      }
      var itemListOptions = {
        showAddButton: true,
        addButtonText: "Add another service",
        items: this.block.items,
        maxItems: 6,
        draggableItems: this.draggableListItem,
        doFilterAvailableItemTypes: true,
        availableItemTypes: availableItemTypes
      };
      if (this.block._campaignRenderingType !== "page") {
        itemListOptions.availableItemTypes.push({
          value: "forwardToFriend",
          label: "Forward to Friend"
        });
        itemListOptions.maxItems = 7;
      }
      return new ItemList(itemListOptions);
    },
    _selectIconType: function (type) {
      query('.iconType', this.domNode).style('display', 'none');
      switch (type) {
        case "outline":
          query(this.outlineTypeContainer).style('display', '');
          break;
        default:
          query(this.solidTypeContainer).style('display', '');
          break;
      }
    },
    _toggleCustomUrlInput: function () {
      switch (this.block.shareContentSource) {
        case 'custom':
          query(this.customUrlInputContainer).style('display', 'block');
          break;
        case 'campaign':
          query(this.customUrlInputContainer).style('display', 'none');
          break;
      }
    },
    _highlightSelectedLayout: function () {
      query('li:selected', this.layoutSelector).removeClass('selected');
      var arrangement = this.block.itemArrangement;
      var layout = this.block.blockLayout;
      var layoutClassName = '.' + arrangement + layout.charAt(0).toUpperCase() + layout.slice(1);
      query(layoutClassName, this.layoutSelector).addClass('selected');
    },
    _selectLayout: function (layoutIcon) {
      this.block.set("blockLayout", domAttr.get(layoutIcon, 'data-blockLayout'));
      this.block.set("itemArrangement", domAttr.get(layoutIcon, 'data-itemArrangement'));
      this._highlightSelectedLayout();
      this.save();
    },
    _highlightSelectedIconStyle: function () {
      query('.iconStyle:selected', this.iconStyleSelector).removeClass('selected');
      query('.iconStyle.' + this.block.iconStyle, this.iconStyleSelector).addClass('selected');
    },
    _selectIconStyle: function (iconStyle) {
      this.block.set("iconStyle", domAttr.get(iconStyle, 'data-iconStyle'));
      this._highlightSelectedIconStyle();
      this.save();
    },
    updateItems: function () {
      this.block.set("items", this.itemList.getOutputModel());
    },
    saveItems: function () {
      this.updateItems();
      this.save();
    },
    onDataUpdate: function (data) {
      this.itemList.reset(data.items);
    }
  });
});