define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Text"], function (declare, Text) {
  return declare([Text], {
    type: "boxedText",
    _name: "Boxed Text",
    _kbLink: "https://mailchimp.com/help/use-boxed-text-content-blocks/",
    _defaultText: "This is a Boxed Text block. Use a contrasting background to draw attention to this content.",
    constructor: function (model) {
      this.styles = [{
        "name": "Text style",
        "selector": ".mcnTextContent, .mcnTextContent p",
        "props": {
          "color": {
            "value": "#F2F2F2"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "14px"
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": "normal"
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": "center"
          }
        }
      }, {
        "name": "Container style",
        "selector": ".mcnTextContentContainer",
        "props": {
          "background-color": {
            "value": "#404040"
          },
          "border": {
            "value": ""
          }
        }
      }];
      if (model._campaignRenderingType === "page") {
        this.styles[1].props["box-shadow"] = {
          "value": "none"
        };
      }
    }
  });
});