define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/CurrencyTextBox", "./_SegmentInputMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, CurrencyTextBox, _SegmentInputMixin, array, I18nTranslation, flags, context) {
  return declare([CurrencyTextBox, _SegmentInputMixin], {
    blankText: "blank",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        this.blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    getDisplayHTML: function () {
      return this.value ? this.value : "<span class=\"dim6\">(" + this.blankText + ")</span>";
    },
    getValue: function () {
      return this.value;
    },
    setValue: function (value) {
      this.set("value", value);
    }
  });
});