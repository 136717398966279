/**
 * A js widget version of mc:helptip
 *
 * Meant to be subclassed.
 *
 * // web/js/mojo/MyHelpTip
 * ```
 * define([
 *     "dojo/_base/declare",
 *     "mojo/widgets/HelpTip",
 * ], function (
 *     declare,
 *     HelpTip
 * ) {
 *     return declare([HelpTip], {
 *         postCreate: function () {
 *             this.inherited(arguments);
 *             this.addLine("Some helpful additional information");
 *         },
 *     })
 * });
 * ```
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/template", "dojo/text!./templates/HelpTip.html", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, domConstruct, template, helpTipTpl, flags, I18nTranslation, context) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    /**
     * Populated by Dojo attach point in template.
     *
     * @type DOMNode
     */
    containerNode: null,
    templateString: helpTipTpl,
    label: "What's this?",
    readMore: "Read more",
    postCreate: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        this.readMore = I18nTranslation.translate("read_more");
      }
      if (this.href) {
        this.addLine(template("<a href='<%= href %>' target='_blank' rel='noopener noreferrer'>" + this.readMore + "&rarr;</a>")({
          href: this.href
        }));
      }
    },
    /**
     * Add a line of information to this tool tip.
     *
     * ```
     * tip.addLine("The bright side of the moon always faces the sun, because the moon is illuminated by the sun.");
     * ```
     *
     * @param {string} html HTML to wrap in an <li> and place into the tool tip pop-up
     * @param {string?} position Passed through as third argument to domConstruct
     */
    addLine: function (html, position) {
      domConstruct.place("<li>" + html + "</li>", this.containerNode, position);
    }
  });
});