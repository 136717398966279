define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable"], function (declare, Renderable) {
  return declare([Renderable], {
    templateString: '<div class="c-channelIcon ${this.getChannelIcon()}"></div>',
    icons: {
      "c-channelIcon--ad": ["facebook", "google"],
      "c-channelIcon--site": ["signup_form_popup", "embedded_form"],
      "c-channelIcon--postcard": ["postcard"],
      "c-channelIcon--page": ["page", "landing_page"],
      "c-channelIcon--email": ["email", "regular", "plaintext", "variate", "rss", "transactional"],
      "c-channelIcon--social": ["social", "social_post"],
      "c-channelIcon--survey": ["survey"]
    },
    getChannelIcon: function () {
      var channelIcon = Object.keys(this.icons).filter(function (icon) {
        return this.icons[icon].indexOf(this.channel) !== -1;
      }, this)[0];
      return channelIcon;
    }
  });
});