define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/blocks/TextEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "mojo/neapolitan/widgets/EditorContainerMobile", "mojo/neapolitan/editors/_DynamicContentMixin", "dojo/text!./templates/TextEditor/editorMobile.html"], function (declare, TextEditor, _MobileBlockMixin, EditorContainerMobile, _DynamicContentMixin, tmpl) {
  return declare([TextEditor, _MobileBlockMixin, _DynamicContentMixin], {
    templateString: tmpl,
    type: "text-mobile",
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["columnSelector", "recoverContentWarning"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});