define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, mUrl, on) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.viewLoginAccount) {
        this.$group.viewLoginAccount.forEach(function (el) {
          on(el, "click", function () {
            self.viewLoginDetails(domAttr.get(el, "data-id"), domAttr.get(el, "data-name"));
          });
        });
      }
      if (this.activeSection === "login-details") {
        this.viewLoginDetails(window.location.href);
      }
    },
    /**
     * adds the Login Details content pane when view login details is clicked on view logins
     * @param {string} id - id of ther login account
     * @param {string} name - username or fname/lname
     */
    viewLoginDetails: function (id, name) {
      var self = this;
      var newPaneObj = {
        title: "Login Details: " + name,
        href: mUrl.toUrl("/peaches2/users/account", {
          _s: "login-details",
          user_id: self.pageJs.userAttrs.userId,
          login_id: id
        }),
        stub: "login-details",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.loginDetailsSection = null;
          return true;
        }
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true, id);
    }
  });
});