define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, xhr, mUrl) {
  var EcommerceStore = declare(null, {
    constructor: function (properties) {
      this.name = properties.name;
      this.storeId = properties.store_id;
      this.listId = properties.list_id || null;
      this.siteUrl = properties.site_url;
    }
  });
  return {
    filters: {
      hasMemberList: function (store) {
        return store.listId;
      }
    },
    /**
     * @return {Promise} Resolves to an array of EcommerceStore
     */
    list: function () {
      return xhr.get(mUrl.toUrl("channels/get-active-stores"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return data.map(function (store) {
          return new EcommerceStore(store);
        });
      });
    },
    /**
     * @return {Promise} Resolves to an array of EcommerceStore
     */
    listAbandonedBrowse: function () {
      return xhr.get(mUrl.toUrl("channels/get-abandonedbrowse-stores"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return data.map(function (store) {
          return new EcommerceStore(store);
        });
      });
    }
  };
});