define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/SurveyMonkey.html"], function (declare, lang, xhr, domClass, on, mUrl, mPusher, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    _setAppInstallUrlAttr: {
      node: "integrationButtonNode",
      type: "attribute",
      attribute: "href"
    },
    postCreate: function () {
      if (this.integrationInstallStatus === true) {
        this._setupDisconnectButton();
      }
      this.own(on.once(this.disconnectButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._disconnectIntegration();
      })));
      this.own(on(this.testButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._testConnection();
      })));
      this._loadData();
    },
    _testConnection: function () {
      var test_url = "/account/integrations/survey-monkey-test";
      var self = this;
      dojo.xhrPost({
        "url": mUrl.toUrl(test_url),
        "content": {},
        "handleAs": "text",
        "load": function (data) {
          var txtbox = self.testResults;
          var loading = self.integrationLoading;
          dojo.style(loading, "display", "block");
          dojo.style(loading, "display", "none");
          dojo.style(txtbox, "display", "block");
          txtbox.innerHTML = data;
        }
      });
    },
    _loadData: function () {
      var self = this;
      dojo.xhrGet({
        "url": mUrl.toUrl("/account/connected-sites/internal-integration/get-survey-monkey-connection-info"),
        "handleAs": "json",
        "load": function (data) {
          self._setConnectionNodeValue(self.verifiedTime, data.verified_time, "Verified: ");
          self._setConnectionNodeValue(self.lastVerifiedMessage, data.last_verified_msg, "Last message: ");
          self._setConnectionNodeValue(self.lastDataMessage, data.last_data_msg, "Last data message: ");
          self._setConnectionNodeValue(self.lastDatatime, data.last_data_time, "Last data message sent: ");
        }
      });
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _setupDisconnectButton: function () {
      domClass.remove(this.integrationButtonNode, "p1 disabled button-loading");
      domClass.add(this.integrationButtonNode, "hide");
      this._loadData();
      domClass.remove(this.connectionResults, "hide");
      this.integrationButtonNode.innerText = "Disconnect";
      this.own(on.once(this.integrationButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._disconnectIntegration(14);
      })));
    },
    _disconnectIntegration: function () {
      //26 is survey monkey, make this not magic number pls.
      query = {
        'sys': 26
      };
      // eslint-disable-next-line no-undef
      lang.hitch(this, dojo.xhrPost({
        'url': mUrl.toUrl('/account/integrations/delete'),
        'content': query,
        'handleAs': 'json'
      }));
      window.ga('send', 'event', 'connected sites', 'platform disconnect', 'button', {
        'dimension53': 'Disconnect',
        'dimension54': '/account/integrations/delete',
        'dimension55': 'Disconnect link on integration detail screen',
        'dimension62': 'SurveyMonkey'
      });
      this._displayWaiting();
      location.reload();
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    _setupConnectButton: function () {
      domClass.remove(this.integrationButtonNode, "disabled button-loading");
      domClass.add(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Connect";
    }
  });
});