define(["dijit/Dialog", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo/text!./templates/initial-choice-modal.html", "mojo/events-pipeline-beacon/bulk-email-campaign-event", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/user", "mojo/lib/flags", "mojo/context"], function (Dialog, declare, lang, domClass, on, dom, domConstruct, xhr, mUrl, tpl, trackEvent, _WidgetBase, _TemplatedMixin, context, flags) {
  return declare([_WidgetBase, _TemplatedMixin], {
    // This widget is specifically for the multivariate wizard. It allows users to choose which editor
    // they want to use for their A/B campaign content.
    templateString: tpl,
    campaignId: null,
    dialog: null,
    contentId: null,
    selectDnDEditorButton: null,
    selectInlineEditorButton: null,
    postCreate: function () {
      var self = this;
      this.dialog = new Dialog({
        "class": "feature-dialog email-editor-feature-dialog",
        content: tpl,
        closable: true,
        onHide: function () {
          self.dialog.destroyRecursive();
        },
        onCancel: function () {},
        onClose: function () {
          self.dialog.hide();
        }
      });
      this.dialog.show();
      this.selectDnDEditorButton = dom.byId("selectDnDEditorButton");
      this.selectInlineEditorButton = dom.byId("selectInlineEditorButton");
      this.pathname = window.location.href.split("mailchimp.com/")[1].split("?")[0];
      on(this.selectDnDEditorButton, "click", lang.hitch(this, function () {
        // handle selection
        this.setLoadButton(this.selectDnDEditorButton);
        trackEvent("campaign:selected", {
          screen: this.pathname,
          object: "campaign",
          object_detail: "editor_selection",
          action: "selected",
          scope_area: "editor_selection",
          ui_object: "button",
          ui_object_detail: "select_the_classic_builder",
          ui_action: "clicked",
          ui_access_point: "modal",
          editor_type: "nea",
          campaign_id: Number(this.campaignId)
        });
        this.handleEditorSelection(false);
      }));
      on(this.selectInlineEditorButton, "click", lang.hitch(this, function () {
        // handle selection
        this.setLoadButton(this.selectInlineEditorButton);
        trackEvent("campaign:selected", {
          screen: this.pathname,
          object: "campaign",
          object_detail: "editor_selection",
          action: "selected",
          scope_area: "editor_selection",
          ui_object: "button",
          ui_object_detail: "select_the_new_builder",
          ui_action: "clicked",
          ui_access_point: "modal",
          editor_type: "nuni",
          campaign_id: Number(this.campaignId)
        });
        this.handleEditorSelection(true);
      }));
    },
    setLoadButton: function (el) {
      domClass.add(el, "button-loading");
      el.disabled = true;
    },
    setUnloadButton: function (el) {
      domClass.remove(el, "button-loading");
      el.disabled = false;
    },
    handleEditorSelection: function (inlineEditor) {
      var editor = inlineEditor ? "inline" : "dnd";
      var url = "/campaigns/wizard/variate/edit-content?id=" + this.campaignId + "&content=" + this.contentId + "&editor=" + editor;
      setTimeout(function () {
        window.top.location.href = url;
      }, 1000);
    }
  });
});