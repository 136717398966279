define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit", "dojo-proxy-loader?name=dojo/main!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, mUrl, dijit, dojo, on, domClass, domConstruct, query, registry, xhr) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      var prePayLedgerExportModal = self.$widget.prePayLedgerExport;
      var exportType = null; // credit or ledger

      var prepayCreditsListContainer = dojo.byId("prepay-credits-list-container");
      var prepayLedgerListContainer = dojo.byId("prepay-ledger-list-container");

      // If the table containers mounted properly then load the tables with a (today — today-1month) filter
      if (!!prepayCreditsListContainer && !!prepayLedgerListContainer) {
        var today = self.getFormattedDate();
        var oneMonthAgo = self.getFormattedDate(-1);
        query("[data-element='start-date-prepay-credits']")[0].value = oneMonthAgo;
        query("[data-element='end-date-prepay-credits']")[0].value = today;
        query("[data-element='start-date-prepay-ledger']")[0].value = oneMonthAgo;
        query("[data-element='end-date-prepay-ledger']")[0].value = today;
        self.filterCreditsTableAction();
        self.filterLedgerTableAction();
      }

      // Show the prepay ledger export modal
      if (self.$el.btExportPrePayCredits) {
        on(self.$el.btExportPrePayCredits, "click", function (e) {
          e.preventDefault();
          exportType = "Credits";
          document.getElementById("exportType").innerHTML = exportType + " ";
          document.getElementById("startDateExport").value = document.getElementById("startDateCredits").value;
          document.getElementById("endDateExport").value = document.getElementById("endDateCredits").value;
          prePayLedgerExportModal.show();
        });
      }
      if (self.$el.btExportPrePayLedger) {
        on(self.$el.btExportPrePayLedger, "click", function (e) {
          e.preventDefault();
          exportType = "Ledger";
          document.getElementById("exportType").innerHTML = exportType + " ";
          document.getElementById("startDateExport").value = document.getElementById("startDateLedger").value;
          document.getElementById("endDateExport").value = document.getElementById("endDateLedger").value;
          prePayLedgerExportModal.show();
        });
      }

      // Close the prepay ledger export modal
      if (self.$el.btCancelPrePayExportRequest) {
        on(self.$el.btCancelPrePayExportRequest, "click", function (e) {
          e.preventDefault();
          prePayLedgerExportModal.hide();
        });
      }

      // Handle export request button click
      if (self.$el.btSubmitPrePayExportRequest) {
        on(self.$el.btSubmitPrePayExportRequest, "click", function (e) {
          e.preventDefault();
          domClass.add(self.$el.btSubmitPrePayExportRequest, "disabled");
          switch (exportType) {
            case "Credits":
              self.exportCreditAction();
              break;
            case "Ledger":
              self.exportLedgerAction();
              break;
            default:
              break;
          }
        });
      }
    },
    reloadCreditsTableAction: function () {
      var self = this;
      query("[data-element='start-date-prepay-credits']")[0].value = "";
      query("[data-element='end-date-prepay-credits']")[0].value = "";
      xhr(mUrl.toUrl("/peaches2/pager/billing/prepay-credits", {
        user_id: self.pageJs.userAttrs.userId,
        per_page: 1000,
        asc: true,
        s: "prepay-credits"
      }), {
        handleAs: "json"
      }).then(function (response) {
        if (!!response.content) {
          self.addTableElementToListContainer("prepay-credits-list-container", "prepay-credits-list", response.content);
        }
      });
    },
    reloadLedgerTableAction: function () {
      var self = this;
      query("[data-element='start-date-prepay-ledger']")[0].value = "";
      query("[data-element='end-date-prepay-ledger']")[0].value = "";
      xhr(mUrl.toUrl("/peaches2/pager/billing/prepay-ledger", {
        user_id: self.pageJs.userAttrs.userId,
        per_page: 1000,
        asc: true,
        s: "prepay-ledger"
      }), {
        handleAs: "json"
      }).then(function (response) {
        if (!!response.content) {
          self.addTableElementToListContainer("prepay-ledger-list-container", "prepay-ledger-list", response.content);
        }
      });
    },
    filterCreditsTableAction: function () {
      var self = this;
      var startDate = self.convertDateFormat(query("[data-element='start-date-prepay-credits']")[0].value);
      var endDate = self.convertDateFormat(query("[data-element='end-date-prepay-credits']")[0].value);
      xhr(mUrl.toUrl("/peaches2/pager/billing/prepay-credits", {
        user_id: self.pageJs.userAttrs.userId,
        start_date: startDate,
        end_date: endDate,
        per_page: 1000,
        asc: true,
        s: "prepay-credits"
      }), {
        handleAs: "json"
      }).then(function (response) {
        if (!!response.content) {
          self.addTableElementToListContainer("prepay-credits-list-container", "prepay-credits-list", response.content);
        }
      });
    },
    filterLedgerTableAction: function () {
      var self = this;
      var startDate = self.convertDateFormat(query("[data-element='start-date-prepay-ledger']")[0].value);
      var endDate = self.convertDateFormat(query("[data-element='end-date-prepay-ledger']")[0].value);
      xhr(mUrl.toUrl("/peaches2/pager/billing/prepay-ledger", {
        user_id: self.pageJs.userAttrs.userId,
        start_date: startDate,
        end_date: endDate,
        per_page: 1000,
        asc: true,
        s: "prepay-ledger"
      }), {
        handleAs: "json"
      }).then(function (response) {
        if (!!response.content) {
          self.addTableElementToListContainer("prepay-ledger-list-container", "prepay-ledger-list", response.content);
        }
      });
    },
    addTableElementToListContainer: function (listContainerId, childTableId, childTable) {
      var listContainer = dojo.byId(listContainerId);
      if (listContainer !== null) {
        // Clear the existing container if it exists
        listContainer.innerHTML = "";
        var table = domConstruct.create("div", {
          id: childTableId,
          "data-dojo-type": "mojo/analytics/List",
          "data-dojo-props": "pageSize: 10"
        });
        table.innerHTML = childTable;
        // Cheap way to show the user the table was updated regardless of data change
        setTimeout(function () {
          domConstruct.place(table, listContainerId);
        }, 25);
      }
    },
    exportCreditAction: function () {
      var self = this;
      var start = self.convertDateFormat(query("[data-element='export-filter-start']")[0].value);
      var end = self.convertDateFormat(query("[data-element='export-filter-end']")[0].value);
      window.location.href = "/peaches2/users/billing/prepay-credit-export?user_id=" + self.pageJs.userAttrs.userId + "&start=" + start + "&end=" + end;
    },
    exportLedgerAction: function () {
      var self = this;
      // Check if the query has a start and end date
      if (!query("[data-element='export-filter-start']")[0].value || !query("[data-element='export-filter-end']")[0].value) {
        window.location.href = "/peaches2/users/billing/prepay-ledger-export?user_id=" + self.pageJs.userAttrs.userId;
      } else {
        var start = self.convertDateFormat(query("[data-element='export-filter-start']")[0].value);
        var end = self.convertDateFormat(query("[data-element='export-filter-end']")[0].value);
        window.location.href = "/peaches2/users/billing/prepay-ledger-export?user_id=" + self.pageJs.userAttrs.userId + "&start=" + start + "&end=" + end;
      }
    },
    openCreditDetails: function (id) {
      var self = this;
      var newPaneObj = {
        title: "Credit Details: " + id,
        href: mUrl.toUrl("/peaches2/users/billing", {
          _s: "credit-details",
          user_id: self.pageJs.userAttrs.userId,
          credit_id: id
        }),
        stub: "credit-details",
        closable: true
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true, id);
    },
    convertDateFormat: function (input) {
      var parts = input.split("/");
      var dateObject = new Date(parts[2], parts[0] - 1, parts[1]);
      return dateObject.toISOString().split("T")[0];
    },
    /**
     * Gets today's date in the format MM/DD/YYYY
     * @param {number} offsetMonths - date offset in months
     * @returns {string} - formatted date
     */
    getFormattedDate: function (offsetMonths) {
      var currentDate = new Date();
      if (offsetMonths) {
        currentDate.setMonth(currentDate.getMonth() + offsetMonths);
      }
      var formatter = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
      return formatter.format(currentDate);
    },
    /**
     * adds the order Details content pane
     * @param {number} id - order id
     */
    viewOrderDetails: function (id) {
      if (id) {
        var self = this;
        var newPaneObj = {
          title: "Order Details: " + id,
          href: mUrl.toUrl("/peaches2/users/billing", {
            _s: "order-details",
            user_id: self.pageJs.userAttrs.userId,
            order_id: id
          }),
          stub: "order-details",
          closable: true,
          onClose: function () {
            self.pageJs.tabContainer.orderDetailsSection = null;
            return true;
          }
        };
        this.pageJs.tabContainer.addNewPane(newPaneObj, true, id);
      }
    },
    /**
     * Trigger the dialog that presents options to change prepay credit expiration
     *
     * @param {string} credit_id - the id of a prepay credit
     * @param {string} expirationDate - the date that the expiration should be changed to
     */
    openModifyExpirationDialog: function (credit_id, expirationDate) {
      this.$el.prepayCreditIdVal.value = credit_id;
      this.$el.prepayExpirationDate.value = expirationDate;
      this.$widget.prepayCreditExpirationModifyArea.show();
    },
    openConfirmVoidDialog: function (credit_id) {
      query("[data-element='prepayVoidId']")[0].value = credit_id;
      var confirmVoidDialog = registry.byId("prepay-void-dialog");
      confirmVoidDialog.show();
    }
  });
});