define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Source"], function (declare, Source) {
  return declare("dojo.dnd.AutoSource", Source, {
    // summary:
    //		a source that syncs its DnD nodes by default

    constructor: function /*===== node, params =====*/
    () {
      // summary:
      //		constructor of the AutoSource --- see the Source constructor for details
      this.autoSync = true;
    }
  });
});