define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/MenuItem", "mojo/app/surveys"], function (declare, MenuItem, surveys) {
  return declare([MenuItem], {
    surveyId: null,
    // populated by data-dojo-props

    postCreate: function () {
      this.set("label", "Delete");
    },
    onClick: function () {
      surveys.showDeletionPrompt([this.surveyId]).then(function () {
        // hard reload because this menu item isn't aware of where it might be living
        window.location.reload();
      });
    }
  });
});