define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/store/Memory", "dojo/store/Cache", "dojo/store/util/QueryResults", "dojox/html/entities", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/form/FilteringSelect", "dojo/text!./templates/automationProductPicker.html", "dojo/text!./templates/automationProductResultItem.html", "mojo/url"], function (declare, lang, dom, request, Memory, Cache, QueryResults, htmlEntities, _WidgetBase, _TemplatedMixin, FilteringSelect, template, resultItemTpl, mUrl) {
  /**
   * Custom memory store that is used to feed the filtering select the values.
   */
  var ProductStore = declare([Memory], {
    listId: null,
    query: function (query) {
      var term = lang.isString(query) ? query : query.name.toString();
      var url = mUrl.toUrl("/ecommerce/products-search-by-list", {
        list_id: this.listId,
        term: term
      });

      // Let's return id, name, label which are used to power the FilteringSelect UI
      var products = request(url, {
        handleAs: "json"
      }).then(function (results) {
        return results.map(function (product) {
          // Wrap http:// image URLs to prevent insecure content warnings
          product.image_url = mUrl.addProxy(product.image_url);
          return {
            "product": product,
            "id": product.product_id,
            "name": htmlEntities.decode(product.title),
            "label": lang.replace(resultItemTpl, product)
          };
        });
      });
      return QueryResults(products);
    }
  });
  var AutomationProductPicker = declare([_WidgetBase, _TemplatedMixin], {
    templateString: template,
    productsStore: null,
    _dataStores: null,
    postMixInProperties: function () {
      this._dataStores = {};
    },
    postCreate: function () {
      this._initInputs();
    },
    _initInputs: function () {
      this.productSearchInput = new FilteringSelect({
        autoComplete: false,
        queryExpr: "${0}",
        labelAttr: "label",
        labelType: "html",
        placeHolder: "Search by name",
        invalidMessage: "No product found.",
        required: false
      }, this.productSearchInput);
      this.productSearchInput.startup();
      this.productSearchInput.store = this._getMemoryStore(this.list_id);
    },
    _getMemoryStore: function (listId) {
      if (!this._dataStores[listId]) {
        this._dataStores[listId] = new Cache(new ProductStore({
          listId: listId
        }), new Memory());
      }
      return this._dataStores[listId];
    }
  });
  return AutomationProductPicker;
});