define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/social-post/social-post-api", "mojo/social-post/Report", "mojo/social-post/widgets/ReportPage"], function (declare, _base, socialPostApi, Report, ReportPage) {
  return declare([_base], {
    onReady: function () {
      var socialPost = socialPostApi.createFromProperties(this.social_post);
      var report = new Report(socialPost, this.stats);
      var page = new ReportPage({
        socialPost: socialPost,
        report: report
      });
      page.placeAt(this.$el.reportPageContainer);
      page.startup();
    }
  });
});