define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, query, domConstruct, domAttr, _Block) {
  return declare([_Block], {
    type: "api",
    _name: "Api",
    _visible: false,
    editable: true,
    clientName: null,
    clientLogo: null,
    constructor: function () {
      this.html = '<div class="mcnTextContent"></div>';
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var content = domConstruct.create("div");
      domConstruct.place(this.html, content, "only");
      var link = query('a', content)[index];
      if (link && domAttr.get(link, 'href') == oldUrl) {
        link.href = newUrl;
        this.html = content.innerHTML;
        return this.save();
      } else {
        return this.inherited(arguments);
      }
    }
  });
});