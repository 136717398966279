/**
 * A select-all checkbox that will modify the "selected" attribute in the model.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dojox/form/TriStateCheckBox"], function (declare, lang, on, _Part, TriStateCheckBox) {
  return declare([_Part], {
    init: function () {
      this.checkbox = new TriStateCheckBox({
        checked: false
      });
      this.checkbox.placeAt(this.domNode);
      on(this.checkbox, "click", lang.hitch(this, function (e) {
        if (this.checkbox.get("value") == "mixed") this.checkbox.set("value", "on");
        this.toggleCheck();
      }));
      this.target.selected.watch(lang.hitch(this, function () {
        if (this.target.isAllSelected()) {
          this.checkbox.set("value", "on");
        } else if (this.target.hasSelection()) {
          this.checkbox.set("value", "mixed");
        } else {
          this.checkbox.set("value", false);
        }
      }));
    },
    toggleCheck: function () {
      if (this.checkbox.get("value") == "on") {
        this.target.toggleAll(true);
      } else if (this.checkbox.get("value") === false) {
        this.target.toggleAll(false);
      }
    }
  });
});