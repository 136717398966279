/**
 * Mixin for adding Adyen credit card payments
 */
define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request/script", "mojo/lib/logger", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation", "/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js"], function (arrays, declare, Deferred, domForm, query, scriptLoader, logger, flags, context, I18nTranslation, dojoRequire) {
  var SECURED_FIELDS_VERSION = "1.5.2";
  var SECURED_FIELDS_JS_URLS = {
    test: "https://checkoutshopper-test.adyen.com/checkoutshopper/assets/js/sdk/checkoutSecuredFields." + SECURED_FIELDS_VERSION + ".js",
    prod: "https://checkoutshopper-live.adyen.com/checkoutshopper/assets/js/sdk/checkoutSecuredFields." + SECURED_FIELDS_VERSION + ".min.js"
  };
  var WEB_COMPONENTS_VERSION = "4.1.0";
  var WEB_COMPONENT_JS = "/js/vendor/adyen/" + WEB_COMPONENTS_VERSION + "/adyen-mc.js";
  return declare([], {
    hasRetriedInitialization: false,
    translatedTerms: {},
    initializeAdyenCardPayments: function (production, originKey, formNode, handlers, includeCVV) {
      var sf = this._buildSecuredFieldsInstance(production, originKey, formNode);
      I18nTranslation.initialize().then(function (value) {
        this.translatedTerms = value;
      }.bind(this));

      // This is truly a last-ditch effort to help an issue where these
      // fields are not loading properly.
      // If the promise is not resolved within 3 seconds, just retry.
      if (!this.hasRetriedInitialization) {
        this.hasRetriedInitialization = true;
        setTimeout(function () {
          if (!sf.isResolved()) {
            logger.info("billing-checkout-fe", "Trying to reinitialize payment form on billing info page.");
            this.initializeAdyenCardPayments();
          }
        }.bind(this), 3000);
      }
      var validFields = [];
      if (handlers && handlers.onValid) {
        var requiredFields = ["encryptedCardNumber", "encryptedExpiryMonth", "encryptedExpiryYear"];
        if (includeCVV) {
          requiredFields[requiredFields.length] = "encryptedSecurityCode";
        }
        sf.then(function (securedFields, error) {
          if (error) {
            console.warn(this.translatedTerms["adyen_payment_error_credit_card_fields"] || "Error initializing cc fields", error);
            window.bugsnagClient.notify(error);
          }
          // The library supports an onAllValid callback, but this expects a CVC field too. We don't always have one
          securedFields.onFieldValid(function (adyenState) {
            var fieldName = adyenState.encryptedFieldName;
            if (adyenState.valid && validFields.indexOf(fieldName) === -1) {
              validFields.push(fieldName);
            }
            if (!adyenState.valid) {
              validFields = arrays.filter(validFields, function (n) {
                return fieldName !== n;
              });
            }
            var allValid = arrays.every(requiredFields, function (n) {
              return validFields.indexOf(n) >= 0;
            });
            handlers.onValid(allValid);
          });
          securedFields.onBrand(function (brand) {
            validFields["cardBrand"] = brand;
            var cardBrand = validFields["cardBrand"].brand;
            validFields.push(cardBrand);
            handlers.onBrand(cardBrand);
          });
        });
      }
    },
    getAdyenCardPaymentsFields: function (formNode) {
      var form = domForm.toObject(query("form", formNode)[0]);
      return {
        encryptedCardNumber: form.encryptedCardNumber,
        encryptedExpiryMonth: form.encryptedExpiryMonth,
        encryptedExpiryYear: form.encryptedExpiryYear,
        encryptedSecurityCode: form.encryptedSecurityCode
      };
    },
    initializeCvcForm: function (production, originKey, formNode, handlers) {
      var sf = this._buildSecuredFieldsInstance(production, originKey, formNode);
      if (handlers && handlers.onValid) {
        sf.then(function (securedFields) {
          securedFields.onFieldValid(handlers.onValid);
          securedFields.onBrand(handlers.onBrand);
        });
      }
    },
    gatherThreeDsDeviceInfo: function () {
      //compare: https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/utils/browserInfo.ts
      return {
        "acceptHeader": "*/*",
        "colorDepth": screen.colorDepth,
        "javaEnabled": window.navigator.javaEnabled && window.navigator.javaEnabled(),
        "language": window.navigator.language,
        "userAgent": window.navigator.userAgent,
        "screenHeight": screen.height,
        "screenWidth": screen.width,
        "timeZoneOffset": new Date().getTimezoneOffset()
      };
    },
    handleThreeDsChallenge: function (challengeData, production, clientKey, mountNode, onResult) {
      var components = this._buildWebComponentsInstance(production, clientKey, onResult);
      components.then(function (checkout) {
        checkout.createFromAction(challengeData, {}).mount(mountNode);
      });
    },
    _buildSecuredFieldsInstance: function (production, originKey, formNode) {
      var libraryConfig = {
        configObject: {
          originKey: originKey
        },
        rootNode: formNode,
        allowAVDetection: "none"
      };
      var script = new Deferred();
      var url = production ? SECURED_FIELDS_JS_URLS.prod : SECURED_FIELDS_JS_URLS.test;
      var loaded = false;
      try {
        dojoRequire([url], function (securedFields) {
          loaded = true;
          script.resolve(securedFields(libraryConfig));
        });
      } catch (e) {
        if (!loaded) {
          script.reject(e);
        } else {
          throw e;
        }
      }
      return script.promise;
    },
    _buildWebComponentsInstance: function (production, clientKey, onResult) {
      var libraryConfig = {
        clientKey: clientKey,
        environment: production ? "live" : "test",
        locale: "en-US",
        onAdditionalDetails: onResult
      };
      var script = new Deferred();
      var url = WEB_COMPONENT_JS;
      var loaded = false;
      try {
        dojoRequire([url], function () {
          loaded = true;
          script.resolve(new window.AdyenCheckout(libraryConfig));
        });
      } catch (e) {
        if (!loaded) {
          script.reject(e);
        } else {
          throw e;
        }
      }
      return script.promise;
    }
  });
});