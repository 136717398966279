define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/bridge"], function (declare, _base, on, utils, bridge) {
  return declare([_base], {
    onReady: function () {
      if (this.$el.sendInvite) {
        on(this.$el.sendInvite, "click", function () {
          utils.toggleButtonLoadingState(this);
          window.ga("send", "event", "upsell_moment", "button_click", JSON.stringify({
            location: "action_settings_user",
            upgrade_reason: "seats",
            button_text: "send_invite"
          }));
        });
      }
      if (this.$el.openPlanSelector) {
        on(this.$el.openPlanSelector, "click", this._initOpenPlanSelector.bind(this));
      }
    },
    _initOpenPlanSelector: function () {
      var closePlanSelector = function () {
        if (this.unmount) {
          this.unmount();
        }
      };
      bridge("revenue").then(function (result) {
        this.unmount = result.mountPlanSelector(this.$el.planSelectorComponent, {
          allowDowngrades: false,
          onRequestClose: closePlanSelector.bind(this)
        });
      }.bind(this));
    }
  });
});