define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/UnsupportedBlock/blockMobile.html", "mojo/utils/string"], function (declare, domConstruct, _Base, _MobileBlockMixin, tmpl, stringUtils) {
  return declare([_Base, _MobileBlockMixin], {
    type: "unsupported-mobile",
    templateString: tmpl,
    constructor: function (obj) {
      this.inherited(arguments);
      this.unsupportedTypeText = stringUtils.capitalize(obj.block.type);
    },
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
      domConstruct.place("<span>The " + this.unsupportedTypeText + " </span>", this.unsupportType, "first");
    }
  });
});