/**
 * Widget that overrides dijit's horizontal slider to add the concept a value label on the handle.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/HorizontalSlider", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, HorizontalSlider, domConstruct, dom) {
  return declare([HorizontalSlider], {
    sliderLabelHtml: '<div role="presentation" aria-hidden="true" data-mc-el="sliderValueLabel" class="slider-label alignc relative inline-block fwb small-meta border-radius--lv1"></div>',
    postCreate: function () {
      this.inherited(arguments);
      this.sliderLabel = domConstruct.place(this.sliderLabelHtml, this.sliderComponents, "last");
      this.sliderLabel.innerHTML = this.value;
    },
    // Remove super-sensitive mouse scroll behavior
    _mouseWheeled: function () {}
  });
});