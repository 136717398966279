define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  return declare("Poll", [], {
    intervalId: null,
    pollFunction: null,
    intervalTime: null,
    constructor: function (newPollFunction, newIntervalTime) {
      this.pollFunction = newPollFunction;
      this.intervalTime = newIntervalTime;
    },
    start: function (newPollFunction, newIntervalTime) {
      this.pollFunction = newPollFunction || this.pollFunction;
      this.intervalTime = newIntervalTime || this.intervalTime;
      this.stop();
      this.intervalId = setInterval(this.pollFunction, this.intervalTime);
    },
    stop: function () {
      clearInterval(this.intervalId);
    },
    getFakeData: function () {
      return 10;
    }
  });
});