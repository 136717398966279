define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, xhr, mUrl, domClass) {
  return declare([base], {
    //handles submit click to show either download links, no results, or errors
    handleClick: function () {
      var params = null;
      var hasUrls = document.getElementById("hasUrls");
      var noUrls = document.getElementById("noUrls");
      var errors = document.getElementById("errors");
      try {
        var _mc = window._mc;
        params = {
          query: {
            list_id: _mc.$widget.listIdSelect.getValue(),
            user_id: _mc.userAttrs.userId,
            date: _mc.$widget.dateSelect.getValue()
          },
          handleAs: "json"
        };
      } catch (e) {}

      // reset results
      if (hasUrls) {
        hasUrls.innerHTML = "";
      }
      if (params) {
        this._fetchData(params);
      } else if (hasUrls && noUrls && errors) {
        domClass.add(hasUrls, "hide");
        domClass.add(noUrls, "hide");
        domClass.remove(errors, "hide");
      }
    },
    // xhr call to fetch download links and handle display
    _fetchData: function (params) {
      var hasUrls = document.getElementById("hasUrls");
      var noUrls = document.getElementById("noUrls");
      xhr(mUrl.toUrl("/peaches2/users/lists/get-list-restore-urls"), params).then(function (data) {
        if (data.urls && data.urls.length !== 0) {
          data.urls.forEach(function (url) {
            //build anchor tags with returned urls
            var a = document.createElement("a");
            var type = "";
            if (url.search("cleaned") > 0) {
              type = "Cleaned";
            } else if (url.search("members") > 0) {
              type = "Members";
            } else if (url.search("transactional") > 0) {
              type = "Transactional";
            } else if (url.search("unsubscribed") > 0) {
              type = "Unsubscribed";
            }
            var linkText = document.createTextNode(type);
            a.appendChild(linkText);
            a.href = url;
            if (hasUrls) {
              hasUrls.appendChild(a);
              hasUrls.appendChild(document.createElement("br"));
            }
          });
          if (hasUrls && noUrls) {
            domClass.remove(hasUrls, "hide");
            domClass.add(noUrls, "hide");
          }
        } else if (hasUrls && noUrls) {
          domClass.add(hasUrls, "hide");
          domClass.remove(noUrls, "hide");
        }
      });
    }
  });
});