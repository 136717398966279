define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.hitch
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "dijit/MenuSeparator", "mojo/widgets/input/InfoMenuItem"], function (lang, declare, array, query, Select, MenuSeparator, InfoMenuItem) {
  return declare([Select], {
    /**
     * Overriding fill content to add meta and badges to the option object
     */
    _fillContent: function () {
      this.inherited(arguments);

      // Only do this when it's declared in the markup
      if (this.srcNodeRef) {
        array.forEach(this.options, lang.hitch(this, function (option) {
          var optionNode = query("[value=" + option.value + "]", this.srcNodeRef)[0];
          if (optionNode) {
            option.meta = optionNode.getAttribute("data-meta");
            option.imageSrc = optionNode.getAttribute("data-image-src");
            option.skip = optionNode.getAttribute("data-skip");
            option.badge = optionNode.getAttribute("data-badge");
            option.badgeType = optionNode.getAttribute("data-badge-type") || "info";
          }
        }));
      }
    },
    _getMenuItemForOption: function ( /*_FormSelectWidget.__SelectOption*/option) {
      // summary:
      //        For the given option, return the menu item that should be
      //        used to display it.  This can be overridden as needed
      if (!option.value && !option.label) {
        // We are a separator (no label set for it)
        return new MenuSeparator({
          ownerDocument: this.ownerDocument
        });
      } else {
        // Just a regular menu option
        var click = lang.hitch(this, "_setValueAttr", option);
        var item = new InfoMenuItem({
          option: option,
          label: (this.labelType === 'text' ? (option.label || '').toString().replace(/&/g, '&amp;').replace(/</g, '&lt;') : option.label) || this.emptyLabel,
          onClick: click,
          ownerDocument: this.ownerDocument,
          dir: this.dir,
          textDir: this.textDir,
          disabled: option.disabled || false
        });
        item.focusNode.setAttribute("role", "option");
        return item;
      }
    },
    _addOptionItem: function ( /*_FormSelectWidget.__SelectOption*/option) {
      // summary:
      //		For the given option, if it doesn't have a data-skip attribute, add an option to our dropdown.
      //		If the option doesn't have a value, then a separator is added
      //		in that place.
      if (this.dropDown && !option.skip) {
        this.dropDown.addChild(this._getMenuItemForOption(option));
      }
    }
  });
});