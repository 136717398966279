define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/headerInfoPane.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/KeyShortcuts", "mojo/url", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _Widget, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, on, request, lang, domConstruct, domClass, array, KeyShortcuts, mUrl, I18nTranslation, flags, context) {
  return declare([_Widget, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    campaignId: null,
    rowTpl: "<li class='!margin-bottom--lv4'><p class='fwb !margin--lv0'>{label}:</p><p class='!margin--lv0'>{value}</p></li>",
    showMerge: false,
    mergePosition: null,
    // Used to keep track of the state and allow the UI to react accordingly to data being fetched.
    fetching: false,
    _fetchRequest: null,
    postMixInProperties: function () {
      if (!this.campaignId) throw new Error("HeaderInfoEditor requires a campaignId");
    },
    postCreate: function () {
      this.own(on(this.liveMergeSwitch, 'change', lang.hitch(this, function () {
        this._cancelRequest();
        if (this.liveMergeSwitch.checked) {
          this.nextMember();
        } else {
          this.mergePosition = null;
          domClass.remove(this.mergeNextBtn, "disabled");
          this.hideMergeControls();
          this.refreshHeaderInfo();
        }
      })), on(this.mergePrevBtn, "click", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.prevMember();
      })), on(this.mergeNextBtn, "click", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.nextMember();
      })), KeyShortcuts.onSpecialKey('RIGHT_ARROW', lang.hitch(this, function () {
        if (this.liveMergeSwitch.checked) {
          this._highlightBtn(this.mergeNextBtn);
          this.nextMember();
        }
      })), KeyShortcuts.onSpecialKey('LEFT_ARROW', lang.hitch(this, function () {
        if (this.liveMergeSwitch.checked) {
          this._highlightBtn(this.mergePrevBtn);
          this.prevMember();
        }
      })));
      this.refreshHeaderInfo();
      this.hideMergeControls();

      // Assign default values incase we don't have flag on or fetching translations fail
      this.headerTitle.innerText = "Header Info";
      this.mergeInfoText.innerText = "Enable live merge tag info";
      if (context.flagIsOn(flags.I18N_NUNI)) {
        I18nTranslation.initialize().then(function () {
          if (I18nTranslation.isTranslationObjectEmpty()) {
            return;
          }
          this.headerTitle.innerText = I18nTranslation.translate("nuni_editor_header_title");
          this.mergeInfoText.innerText = I18nTranslation.translate("nuni_editor_info_pane_toggle_merge");
        }.bind(this));
      }
    },
    nextMember: function () {
      if (!domClass.contains(this.mergeNextBtn, "disabled") && !this.fetching) {
        if (this.mergePosition == null) {
          this.mergePosition = 0;
          this.showMergeControls();
        } else {
          this.mergePosition++;
        }
        this.refreshHeaderInfo();
      }
    },
    prevMember: function () {
      if (!this.fetching && this.mergePosition != null) {
        if (this.mergePosition <= 0) {
          this.mergePosition = null;
          this.hideMergeControls();
        } else {
          this.mergePosition--;
        }
        this.refreshHeaderInfo();
      }
    },
    refreshHeaderInfo: function () {
      if (!this.fetching) {
        this.fetching = true;
        var self = this;
        if (this.mergePosition == null) {
          this._fetchRequest = this._updateHeaderInfo();
        } else {
          this._fetchRequest = this._getPreviewRecipient(this.mergePosition);
          this._fetchRequest.then(function (recipient) {
            var memberId = recipient.member_id;
            domClass.toggle(self.mergeNextBtn, "disabled", recipient.is_last);
            self._updateHeaderInfo(memberId);
          });
        }
      }
    },
    showMergeControls: function () {
      domClass.remove(this.mergePrevBtn, "hide");
      domClass.remove(this.mergeNextBtn, "hide");
      this.refreshHeaderInfo();
    },
    hideMergeControls: function () {
      this.liveMergeSwitch.set('checked', false);
      domClass.add(this.mergePrevBtn, "hide");
      domClass.add(this.mergeNextBtn, "hide");
    },
    _highlightBtn: function (btn) {
      domClass.add(btn, "active");
      setTimeout(lang.hitch(this, function () {
        domClass.remove(btn, "active");
      }), 200);
    },
    /**
     * Update the header info dom by calling the server
     *
     * @param {number=} memberId or null
     * @private
     */
    _updateHeaderInfo: function (memberId) {
      var self = this;
      var metaTpl = "<p class='!margin--lv0 dim-el fwb small-meta'>{meta}</p>";
      self.onMemberLoad(memberId);
      return this._getHeaderInfo(memberId).then(function (data) {
        if (!self._destroyed) {
          domConstruct.empty(self.body);
          var ul = domConstruct.toDom("<ul></ul>");
          array.forEach(data.rows, function (row) {
            var li = domConstruct.place(lang.replace(self.rowTpl, row), ul);
            if (row.meta) {
              domConstruct.place(lang.replace(metaTpl, row), li);
            }
          });
          if (data.info_message) {
            self.feedbackBody.innerHTML = data.info_message;
            domClass.remove(self.feedbackContainer, "hide");
          } else {
            domClass.add(self.feedbackContainer, "hide");
          }
          domConstruct.place(ul, self.body);
        }
        self._finishRequest();
      });
    },
    _finishRequest: function () {
      this.fetching = false;
      this._fetchRequest = null;
    },
    _cancelRequest: function () {
      if (this.fetching) {
        if (this._fetchRequest) {
          this._fetchRequest.cancel();
        }
        this._fetchRequest = null;
        this.fetching = false;
      }
    },
    /**
     * Fetch the recipient at the given position for previewing purposes.
     *
     * @param {number} position
     * @returns {dojo/promise/Promise}
     *
     * @private
     */
    _getPreviewRecipient: function (position) {
      var params = {
        'id': this.campaignId,
        'pos': position
      };
      return request.get(mUrl.toUrl("campaigns/preview-recipient"), {
        'handleAs': "json",
        'query': params
      });
    },
    /**
     * Fetch for an array of rows that represents the rendered live merge fields
     *
     * @param {number} memberId
     * @returns {dojo/promise/Promise}
     *
     * @private
     */
    _getHeaderInfo: function (memberId) {
      var params = {
        'id': this.campaignId,
        'e': memberId ? memberId : "default",
        'type': 'json'
      };
      return request.get(mUrl.toUrl('campaigns/preview-headers'), {
        'query': params,
        'handleAs': 'json'
      });
    },
    // Custom callback function
    onMemberLoad: function (memberId) {}
  });
});