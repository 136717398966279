define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "d3/d3"], function (declare, _WidgetBase, lang, dom, domConstruct, on, domGeom, utils, d3) {
  var _tau = 2 * Math.PI;
  return declare([_WidgetBase], {
    circleSize: 65,
    // width and height of circle. px

    pathWidth: 9,
    pathBackgroundColor: "#e0e0e0",
    chartProgressBar: "#52bad5",
    startValue: 0,
    // default,

    animateOnVisibility: true,
    buildRendering: function () {
      this.inherited(arguments);
      this.endValue = this.domNode.innerHTML;
      domConstruct.empty(this.domNode);
      var svgContainer;
      var arc;
      var path;

      // creates svg container
      svgContainer = d3.select(this.domNode).append("svg").attr("width", this.circleSize).attr("height", this.circleSize).append("g").attr("transform", "translate(" + this.circleSize / 2 + "," + this.circleSize / 2 + ")");

      // create a reusable arc
      arc = d3.svg.arc().innerRadius(this.circleSize / 2 - this.pathWidth).outerRadius(this.circleSize / 2).startAngle(0);
      this.arc = arc;

      // creates Background circle
      svgContainer.append("path").datum({
        endAngle: _tau
      }).style("fill", this.pathBackgroundColor).attr("d", arc);

      // adds text object
      this.textValue = svgContainer.append("text").attr("x", 2).attr("y", -1).attr("text-anchor", "middle").attr("dominant-baseline", "central").attr("class", "fwb p small-meta").style("fill", "#484848").text(this.startValue + "%");

      // blue background arc
      path = svgContainer.append("path").datum({
        endAngle: this.startValue / 100 * _tau
      }).style("fill", this.chartProgressBar).attr("d", arc);
      this.path = path;
    },
    animateDoughnut: function () {
      var self = this;
      var arcTween = function (transition, angle) {
        transition.attrTween("d", function (d) {
          var interp = d3.interpolate(d.endAngle, angle);
          return function (t) {
            d.endAngle = interp(t);
            // update text as well but in whole numbers only
            if (self.textValue) {
              self.textValue.text(parseInt(d.endAngle * 100 / _tau, 10) + "%");
            }
            return self.arc(d);
          };
        });
      };
      self.path.transition().delay(222).duration(1500).call(arcTween, self.endValue / 100 * _tau, self.startValue);
    },
    postCreate: function () {
      var self = this;
      if (this.animateOnVisibility) {
        utils.onVisibility(self.domNode, lang.hitch(self, self.animateDoughnut));
      } else {
        this.animateDoughnut();
      }
    }
  }); // eo declare
}); // eo define