define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/form/HorizontalRuleLabels", "./_WithLabelMixin", "dojo/text!./templates/measurementSlider.html"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, HorizontalRuleLabels, _WithLabelMixin, tpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _WithLabelMixin], {
    templateString: tpl,
    // MeasurementSlider defaults to a slider for percentages
    minimum: 0,
    maximum: 100,
    unit: "%",
    wholeNumbersOnly: false,
    // horizontalSliderOverride may be used to mixin custom properties
    // directly into the HorizontalSlider - options here take top priority
    horizontalSliderOverride: {},
    postMixInProperties: function () {
      this.inherited(arguments);
      if (!this.sliderLabels) {
        this.sliderLabels = [this.minimum + this.unit, this.maximum + this.unit];
      }
    },
    postCreate: function () {
      this.originalValue = this.get("value");
      this.set("value", this.get("value"));
      this.slider.on("change", this._setValueAttr.bind(this));
      if (this.wholeNumbersOnly) {
        var interval = this.maximum - this.minimum + 1;
        this.slider.set("discreteValues", interval);
      }
      Object.keys(this.horizontalSliderOverride).forEach(function (optKey) {
        this.slider.set(optKey, this.horizontalSliderOverride[optKey]);
      }.bind(this));
      this.inherited(arguments);
    },
    startup: function () {
      var labelEl = new HorizontalRuleLabels({
        labels: this.sliderLabels
      });
      labelEl.startup();
      labelEl.placeAt(this.slider.domNode, "after");
    },
    _setValueAttr: function (value) {
      value = String(value);

      // value could either have or not have unit, depending
      // on where setValueAttr is being called from.
      // These variables are set to be explicit:
      var valueWithoutUnit = value.replace(this.unit, "");
      var valueWithUnit = valueWithoutUnit + this.unit;
      if (this.slider) {
        this.slider.set("value", valueWithoutUnit, false);
      }
      this._set("value", valueWithUnit);
      this.onChange(valueWithUnit);
      this.inherited(arguments, [valueWithoutUnit]);
    },
    reset: function () {
      this.set("value", this.originalValue);
    },
    onChange: function (value) {/* no-op */}
  });
});