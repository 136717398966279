/**
 * @module mojo.app.lists.import.match
 *
 * Contains public functions for reserving and releasing destinations in a mapping from import column identifiers to
 * destinations.
 *
 * Glossary:
 * card - refers to a single card in the importing interface, which also happens to correspond with an import column
 * import column - a single column of the user's import
 * columnIndex - the zero-indexed id for a single import column
 * mapping - a correspondence between import columns and destinations
 * destination - a place for a column's data to be written to, generally merge fields, but also merge field components or checkbox-type list groups
 * `map-deleted` - CSS class indicating that the field was removed from the mapping, aka skipped
 * `unnamed` - CSS class indicating that the field's import column has yet to be mapped, ui shows "(unmatched column)"
 */
define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "dojox", "dijit/registry", "../MergeField", "./Destination", "./Column", "vendor/lodash-amd/intersection", "mojo/user", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo, dijit, dojox, registry, MergeField, Destination, Column, intersection, user, flags, topic) {
  var match = {
    /**
     * Map of merge field index to import column index.
     *
     * For example, given:
     *  - an import with two columns
     *  - a list with merge fields EMAIL, FNAME, CITY (with merge_ids 0, 1, and 2)
     *  - that the user has chosen to to map the first import column to EMAIL and the second column to CITY
     * Then the value of merge_map will be:
     *  {
     *      "0": 0,
     *      "2": 1,
     *  }
     *
     * @property object[string, integer]
     */
    merge_map: {},
    /**
     * Map of import column index to destination.
     * The value at each index represents the destination that the import column will be imported to.
     *
     * `false` means it was skipped. see `match.COLUMN_MAP_SKIP`.
     *
     * In the same example as above, the value of column_map might be:
     *  {0: 0, 1: false, 2: "2"}
     *
     * Folks can also map import columns to checkbox-type list groups. In this case, column_map might look like:
     *  [0: 0, 1: false, 2: "mycheckbox"}
     *
     * @property object[Number, Number|boolean|string]
     */
    column_map: {},
    /**
     * This value in the column_map, indicates that the columnId was skipped.
     */
    COLUMN_MAP_SKIP: false,
    /**
     * This value is used as a sentinel value to represent that a column is unmatched, ie. there is no value in the column map for its columnId
     */
    COLUMN_MAP_NONE: null,
    example_rows: null,
    /**
     * List of merge_ids that were created while on the matching page.
     *
     * @property Array.<Number>
     */
    merge_ids_created_during_session: []
  };
  match.topic = {
    CHANNEL: "mojo/app/lists/import/match",
    subscribe: function (listener) {
      var subscriptionHandle = topic.subscribe(match.topic.CHANNEL, listener);
      return subscriptionHandle;
    },
    publish: function (message) {
      topic.publish(match.topic.CHANNEL, message);
    }
  };

  /**
   * Remove a reservation, by providing which import column is releasing it.
   *
   * @param {Number} columnIndex - The column that is releasing its reservation
   */
  match.deleteReservation = function (columnIndex) {
    if (typeof match.column_map[columnIndex] !== "undefined") {
      delete match.merge_map[match.column_map[columnIndex]];
    }
    match.column_map[columnIndex] = match.COLUMN_MAP_SKIP;
    match.announceChanges();
  };

  /**
   * Call "dibs" on a destination, so no other import column can be mapped to it.
   *
   * @param {Number} columnIndex - The column making the reservation
   * @param {String} destinationId - The destination be reserved
   */
  match.reserveDestination = function (columnIndex, destinationId) {
    // values in column_map should be strings
    match.column_map[columnIndex] = destinationId + "";

    // unset merges already in the map to avoid duplicate values
    // this is because 1) merge_map is an object and 2) the mappings are flipped
    for (var prop in match.merge_map) {
      if (match.merge_map.hasOwnProperty(prop) === false) {
        continue;
      }
      if (match.merge_map[prop] === columnIndex) {
        delete match.merge_map[prop];
      }
    }
    match.merge_map[destinationId] = columnIndex;
    match.announceChanges();
  };

  /**
   * @param {mojo.app.lists.import.Destination} destination - The destination to determine if is reserved
   * @return {Boolean} - True indicates this destination has been reserved
   */
  match.isDestinationReserved = function (destination) {
    var selectedIds = Object.values(match.getColumnMap());
    var isDestinationReserved = selectedIds.indexOf(destination.id); // keeps the same behavior as before
    if (isDestinationReserved !== -1) {
      return true;
    }

    // Disable components if parent is selected
    var getParentDestinationIds = match.getDestinations().filter(function (givenDestination) {
      return givenDestination.type === Destination.TYPE_MERGE_FIELD;
    }).map(function (givenDestination) {
      return givenDestination.id;
    });
    if (intersection(selectedIds, getParentDestinationIds, [destination.id.split(".")[0]]).length > 0) {
      return true;
    }

    // Disable parent if any component is selected
    var getChildDestinationIds = selectedIds.map(function (childId) {
      return childId.split(".")[0];
    }).indexOf(destination.id) !== -1;
    if (getChildDestinationIds) {
      return true;
    }
    return false;
  };

  /**
   * @return {string[]} List of destinations which have been reserved
   */
  match.getReservedDestinations = function () {
    var reservedDestinations = [];
    var destinations = match.getDestinations();
    for (var i = 0; i < destinations.length; i++) {
      var destination = destinations[i];
      if (match.isDestinationReserved(destination)) {
        reservedDestinations.push(destination.id);
      }
    }
    return reservedDestinations;
  };

  /**
   * @return {Number[]} List of columnIndex which aren't matched
   */
  match.getUnmatchedColumnIndices = function () {
    var unmatched = [];
    for (var columnIndex in match.header) {
      if (match.header.hasOwnProperty(columnIndex) === false) {
        continue;
      }
      var value = match.column_map[columnIndex];
      // undefined indicates it was never reserved to begin with
      if (typeof value === "undefined") {
        unmatched.push(parseInt(columnIndex, 10));
      }
    }
    return unmatched;
  };

  /**
   * Announce that internal matching state has changed.
   */
  match.announceChanges = function () {
    match.topic.publish({});
  };

  /**
   * @return {object} column_id keys, destination_id values
   */
  match.getColumnMap = function () {
    var map = {};
    for (var columnId in match.column_map) {
      if (match.column_map.hasOwnProperty(columnId) === false) {
        continue;
      }
      var value = match.column_map[columnId];
      if (value === match.COLUMN_MAP_SKIP) {
        continue;
      }
      var destinationId = value + "";
      map[columnId] = destinationId;
    }
    return map;
  };
  match.getHeaderForColumnIndex = function (columnIndex) {
    if (match.header.hasOwnProperty(columnIndex) === false) {
      return null;
    }
    return match.header[columnIndex];
  };

  /**
   * Query what mergeId is reserved for the given columnIndex
   *
   * @param {Number} columnIndex The columnIndex to query for
   * @return {string|null|boolean} The mergeId reserved, or empty string if no reservation for this columnIndex
   */
  match.getDestinationForColumnIndex = function (columnIndex) {
    if (match.column_map.hasOwnProperty(columnIndex) === false) {
      return match.COLUMN_MAP_NONE; // no destination for this column index
    }
    var maybeDestination = match.column_map[columnIndex];
    if (maybeDestination === match.COLUMN_MAP_SKIP) {
      return match.COLUMN_MAP_SKIP; // specifically not matched, so no destination
    }
    var destinationId = maybeDestination + "";
    return destinationId;
  };
  match.addNewMergeField = function (mergeId, mergeInfo) {
    match.merge_info[mergeId] = mergeInfo;
    match.merge_ids_created_during_session.push(mergeId);
    match.announceChanges();
  };

  /**
  * List out the merge_ids which were created during this session on the matching page.
  *
  * @return {Array.<Number>} Array of merge_ids
  */
  match.getListOfNewlyCreatedMergeIds = function () {
    return match.merge_ids_created_during_session.slice();
  };
  match.getDestinations = function () {
    var destinations = [];

    // merge_info includes interest_groups
    // you can tell interest_groups from merge_fields because interest_groups have a `checked` field
    // this is prone to failure, but the only current way to disambiguate them without introducing another field
    for (var destinationId in match.merge_info) {
      if (match.merge_info.hasOwnProperty(destinationId) === false) {
        continue;
      }
      var merge = match.merge_info[destinationId];
      destinations.push(new Destination(destinationId, match.getDestinationName(merge), Destination.TYPE_MERGE_FIELD));
      if (merge.hasOwnProperty("components")) {
        for (var i = 0; i < merge.components.length; i++) {
          var component = merge.components[i];
          destinations.push(new Destination(destinationId + "." + component, match.getComponentDestinationName(merge, component), Destination.TYPE_MERGE_FIELD_COMPONENT));
        }
      }
    }
    destinations.push(new Destination("OPTIN_TIME", "Opt-in Time", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination("OPTIN_IP", "Opt-in IP Address", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination("CONFIRM_TIME", "Opt-in Confirmation Time", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination("CONFIRM_IP", "Opt-in Confirmation IP Address", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination("MC_LANGUAGE", "Language", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination("MARKETING_PERMISSIONS", "Marketing Permissions", Destination.TYPE_ADVANCED_FIELD));
    destinations.push(new Destination(Destination.ID_TAGS, "Tags", Destination.TYPE_ADVANCED_FIELD));
    return destinations;
  };
  match.getDestinationName = function (mergeInfo) {
    var name = mergeInfo.name;
    if (mergeInfo.field_type === MergeField.TYPE_ADDRESS) {
      name += " - Combined";
    }
    return name;
  };
  match.getComponentDestinationName = function (mergeInfo, component) {
    var nameSuffixMap = {};
    nameSuffixMap[MergeField.TYPE_ADDRESS] = {};
    nameSuffixMap[MergeField.TYPE_ADDRESS]["addr1"] = "Street Address";
    nameSuffixMap[MergeField.TYPE_ADDRESS]["addr2"] = "Address Line 2";
    nameSuffixMap[MergeField.TYPE_ADDRESS]["city"] = "City";
    nameSuffixMap[MergeField.TYPE_ADDRESS]["state"] = "State/Prov/Region";
    nameSuffixMap[MergeField.TYPE_ADDRESS]["zip"] = "ZIP/Postal";
    nameSuffixMap[MergeField.TYPE_ADDRESS]["country"] = "Country";
    var suffix = nameSuffixMap[mergeInfo.field_type][component];
    return mergeInfo.name + " - " + suffix;
  };
  match.getDestinationById = function (destinationId) {
    var destinations = match.getDestinations();
    for (var i = 0; i < destinations.length; i++) {
      var destination = destinations[i];
      if (destination.id === destinationId) {
        return destination;
      }
    }
    return null;
  };

  /**
   * @return {mojo.app.lists.import.Column[]} List of columns in this import
   */
  match.getColumns = function () {
    var columns = [];
    for (var columnId in match.example_rows[0]) {
      if (match.example_rows[0].hasOwnProperty(columnId) === false) {
        continue;
      }
      columns.push(new Column(columnId, match.example_rows[0][columnId]));
    }
    return columns;
  };
  match.onLoad = function () {
    // Import Mapping Hotkeys
    dojo.connect(window.document, "keydown", dojo.hitch(this, window.hotKeyHandler));
    match.announceChanges();
  };
  match.registerKeyHandler = function (keyHandler) {
    match.keyhandler = keyHandler;
  };
  match.hotKeyHandler = function (e) {
    var checkForInputs = function (localE) {
      return localE.target && (localE.target.tagName.toUpperCase() === "INPUT" || localE.target.tagName.toUpperCase() === "TEXTAREA");
    };
    var checkForInputsInIE = function (localE) {
      if (typeof localE.srcElement === "undefined") {
        return false;
      }
      return localE.srcElement && (localE.srcElement.tagName.toUpperCase() === "INPUT" || localE.srcElement.tagName.toUpperCase() === "TEXTAREA");
    };
    // Browser tab switching in Windows uses CTRL + #, so let"s not do anything when ctrl is held down
    if (e.ctrlKey || e.metaKey) {
      return false;
    }
    var keyCode = e.keyCode;
    // Handle ESC or ENTER even if we"re in a field.
    switch (keyCode) {
      case 27:
        // ESC key
        match.keyhandler.escape();
        break;
      case 13:
        // Enter key - Save new field unless we haven"t fired showNewFieldForm yet.
        match.keyhandler.enter();
        break;
      default:
        break;
    }
    // Make sure we"re not inside of something that types text
    if (!!checkForInputs(e) === false && !!checkForInputsInIE(e) === false) {
      switch (keyCode) {
        case 27:
          // escape key
          match.keyhandler.escape();
          break;
        case 37:
          // left arrow
          match.keyhandler.leftArrow();
          e.preventDefault();
          break;
        case 39:
          // right arrow
          match.keyhandler.rightArrow();
          e.preventDefault();
          break;
        case 40:
          // down arrow
          match.keyhandler.downArrow();
          break;
        case 88:
          // x key
          match.keyhandler.x();
          break;
        default:
          break;
      }
    }
  };
  return match;
});