define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase",
// Reverse Bridge
"mojo/reverse-bridge"], function (declare, _WidgetBase, ReverseBridge) {
  return declare([_WidgetBase], {
    close: function () {
      this.reverseBridgeUnmount();
    },
    openModal: function () {
      var props = {
        isOpen: true,
        onRequestClose: this.close.bind(this),
        campaignId: (this.campaignId || "").toString(),
        isTemplateEditor: this.isTemplateEditor
      };
      this.reverseBridgeUnmount = ReverseBridge("initial-choice-modal-alternate", props);
    }
  });
});