/**
 * @module mojo.utils.number
 *
 * Number utility methods.
 *
 * All methods' first argument should be a number.
 * Method names follow a prefix pattern which indicates their behavior:
 *  - toSomething: takes the entire number and formats it in a certain way, eg. toFileSize
 */
define([], function () {
  var exports = {
    /**
     * Take a number and format it in USD.
     *
     * @param {Number} number - A number value, like 12.3
     * @return {string} Value formatted in USD, like $12.30
     */
    toUsdCurrency: function (number) {
      // toFixed converts the value to a string, and ensures there's two digits after the decimal point
      return "$" + number.toFixed(2);
    },
    /**
     * Implement's PHP's numberformat in javascript.
     *
     * @param {Number} number - The value to format, eg `1234.5678`
     * @param {Number} [decimals] - Quantity of decimal places to preserve, defaults to 2
     * @param {string} [dec_point] - Delimiter for decimal point, defaults to "."
     * @param {string} [thousands_sep] - Delimiter for thousands place, defaults to ","
     * @return {string} The formatted output, eg. `"1234.57"`
     */
    toFormat: function (number, decimals, dec_point, thousands_sep) {
      // http://kevin.vanzonneveld.net
      // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
      // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
      // +     bugfix by: Michael White (http://crestidg.com)
      // +     bugfix by: Benjamin Lupton
      // +     bugfix by: Allan Jensen (http://www.winternet.no)
      // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
      // *     example 1: number_format(1234.5678, 2, '.', '');
      // *     returns 1: 1234.57
      // * we grabbed from http://snipplr.com/view/5945/javascript-numberformat--ported-from-php/
      var n = number,
        c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
      var d = typeof dec_point == "undefined" ? "," : dec_point;
      var t = typeof thousands_sep == "undefined" ? "." : thousands_sep,
        s = n < 0 ? "-" : "";
      var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
      return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    },
    /**
     * Format a number as a file size.
     *
     * @param {Number} number - Value to format as file size
     * @return {string} Human-readable string
     */
    toFileSize: function (number) {
      //borrowed from http://snipplr.com/view/5949/
      if (number >= 1073741824) {
        return exports.toFormat(number / 1073741824, 2, ".", "") + " Gb";
      }
      if (number >= 1048576) {
        return exports.toFormat(number / 1048576, 2, ".", "") + " Mb";
      }
      if (number >= 1024) {
        return exports.toFormat(number / 1024, 0) + " Kb";
      }
      return exports.toFormat(number, 0) + " bytes";
    },
    /**
     * Returns 1st for 1, 2nd for 2, etc. For Every Day in Month
     *
     * @param {Number} number - number between 1 and 31
     * @returns {string} the original number with the ordinal indicator attached.
     */
    toOrdinal: function (number) {
      var ordinals = ["th", "st", "nd", "rd"];
      return String(number) + ((number < 11 || number > 13) && ordinals[number % 10] ? ordinals[number % 10] : ordinals[0]);
    },
    /**
     * Shortens a number to "Nk" if it's over 1000
     *
     * @param {Number} number - number
     * @returns {string} the number shortened with a k if necessary
     */
    toThousandsString: function (number) {
      return number >= 1000 ? Math.floor(number / 1000) + "K" : String(number);
    },
    /**
     * Shortens a number to "NM" where M is the magnitude, up to 1 trillion
     * For values that are less than 1/10th of their magnitude, (e.g. 1-9k, 1-9m, 1-9b) additionally show a single decimal place (3.5k)
     *
     * @param {Number} number number
     *
     * @returns {string} the number shortened (if above 1k) with a k, m, b, t or as an exponent (with one decimal of precision where useful)
     */
    toShortString: function (number) {
      var k = 1000;
      var m = 1000000;
      var b = 1000000000;
      var t = 1000000000000;
      var z = Number.MAX_SAFE_INTEGER;
      var _makeString = function (n, magnitude, postfix, fraction) {
        // fraction = 10 means show 10ths, e.g. 1.1, 3.5, 2.9
        // fraction = 1 means show 1ths, e.g. integers
        // intentionally rounds down rather than using .toPrecision(), so as to avoid inflating numbers
        return Math.floor(n / (magnitude / fraction)) / fraction + postfix;
      };
      if (number < k) {
        return number + "";
      } else if (number < k * 10) {
        return _makeString(number, k, "K", 10);
      } else if (number < m) {
        return _makeString(number, k, "K", 1);
      } else if (number < m * 10) {
        return _makeString(number, m, "M", 10);
      } else if (number < b) {
        return _makeString(number, m, "M", 1);
      } else if (number < b * 10) {
        return _makeString(number, b, "B", 10);
      } else if (number < t) {
        return _makeString(number, b, "B", 1);
      } else if (number < t * 10) {
        return _makeString(number, t, "T", 10);
      } else if (number < z) {
        // All remaining numbers that JS can safely handle are displayed in trillions, e.g. a quadrillion will be `1000T`. This avoids ambiguity of whether Q is quadrillion or quintillion (though quintillion is larger than JS can handle)
        return _makeString(number, t, "T", 1);
      }
      // Numbers larger than a zillion (Number.MAX_SAFE_INTEGER) are displayed with the shortest possible exponent, e.g. `9e+15`
      return number.toExponential(1);
    }
  };
  return exports;
});