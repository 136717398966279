define(["mojo/app/lists/Import"], function (Import) {
  var review = {
    /**
     * @type mojo.app.lists.Import
     */
    "import": null,
    bootstrap: function (data) {
      this.import = Import.fromView(data.import);
    }
  };
  return review;
});