define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dijit/popup", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/user", "mojo/utils", "dojo/text!./templates/postcard-budget.html"], function (declare, Renderable, popup, ItemButtons, user, utils, tpl) {
  var MINIMUM_BUDGET_IN_USER_CURRENCY = 1;
  var DAILY_SPENDING_LIMIT_IN_USER_CURRENCY = 10000;
  var REMAINING_DAILY_SPEND_IN_USER_CURRENCY = DAILY_SPENDING_LIMIT_IN_USER_CURRENCY;
  var PostcardBudget = declare([Renderable], {
    currencySymbol: user.currencySymbol,
    isCurrencySymbolBehindAmount: user.isCurrencySymbolBehindAmount,
    templateString: tpl,
    numberFormat: utils.numberFormat,
    MAXIMUM_FREE_SUBSCRIBER_LIMIT: 2000,
    constructor: function (props) {
      this.PRICE_PER_CART_ESTIMATE_IN_USER_CURRENCY = window._mc.transactional_price_per_cart_estimate;
      MINIMUM_BUDGET_IN_USER_CURRENCY = Math.ceil(window._mc.minimum_max_budget_in_user_currency);
      this.MINIMUM_BUDGET_IN_USER_CURRENCY = MINIMUM_BUDGET_IN_USER_CURRENCY;
      DAILY_SPENDING_LIMIT_IN_USER_CURRENCY = Math.floor(window._mc.daily_spending_limit_in_user_currency);
      this.DAILY_SPENDING_LIMIT_IN_USER_CURRENCY = DAILY_SPENDING_LIMIT_IN_USER_CURRENCY;
      REMAINING_DAILY_SPEND_IN_USER_CURRENCY = Math.floor(window._mc.remaining_daily_spend_in_user_currency);
      this.REMAINING_DAILY_SPEND_IN_USER_CURRENCY = REMAINING_DAILY_SPEND_IN_USER_CURRENCY;
      this.userLevel = user.userLevel;
      this.totalListSize = window._mc.account_upgrade_details.total_list_size;
      this.state = {
        creditRechargeAmount: props.model.creditRechargeAmountInUserCurrency || 10,
        isPricingTableVisible: false
      };
    },
    onTogglePricingTable: function (e) {
      e.preventDefault();
      this.setState({
        isPricingTableVisible: !this.state.isPricingTableVisible
      });
    },
    showTooltip: function (tooltipName) {
      var tooltipWidget = this.tooltips[tooltipName].widget;
      this.tooltips[tooltipName].isVisible = true;
      popup.open({
        popup: tooltipWidget,
        around: this.domNode.querySelector("[data-tooltip='" + tooltipName + "']")
      });

      // Place the focus on the tooltip dialog in order to close onBlur
      tooltipWidget.focus();
    },
    onUpdateCreditRechargeAmount: function (e) {
      this._onUpdateNumericalInput("creditRechargeAmount", e);
    },
    _onUpdateNumericalInput: function (stateKey, e) {
      this.preserveFocus(e);
      var valueWithOnlyDigits = e.target.value.replace(/\D/g, "");
      var valueAsNumber = Number(valueWithOnlyDigits);
      var newValue = isNaN(valueAsNumber) || valueWithOnlyDigits === "" ? null : valueAsNumber;
      var newState = {
        _cacheBust: new Date().getTime()
      };
      newState[stateKey] = newValue;
      this.setState(newState);
    },
    getData: function () {
      return {
        creditRechargeAmountInUserCurrency: this.state.creditRechargeAmount
      };
    },
    getCurrencyInputClass: function () {
      if (this.currencySymbol.length <= 1) {
        return null;
      }
      return this.currencySymbol.length <= 2 ? "c-currencyInput--wide" : "c-currencyInput--wider";
    },
    getMaxCarts: function () {
      return Math.floor(this.state.creditRechargeAmount / this.PRICE_PER_CART_ESTIMATE_IN_USER_CURRENCY);
    },
    isBudgetValid: function () {
      return this.state.creditRechargeAmount >= this.MINIMUM_BUDGET_IN_USER_CURRENCY && !this.doesBudgetExceedDailySpendingLimit();
    },
    doesBudgetExceedDailySpendingLimit: function () {
      return this.state.creditRechargeAmount > this.REMAINING_DAILY_SPEND_IN_USER_CURRENCY;
    },
    formatCurrency: function (currencyAmount) {
      if (!currencyAmount) {
        return "";
      }
      if (this.isCurrencySymbolBehindAmount) {
        return currencyAmount + " " + this.currencySymbol;
      }
      return this.currencySymbol + currencyAmount;
    },
    _renderBudgetErrors: function () {
      if (!this.budgetErrorLabel) {
        return;
      }
      if (this.isBudgetValid()) {
        this.budgetErrorLabel.innerText = "";
        this.budgetErrorLabel.classList.add("hide");
        return;
      }
      var errorMessage;
      if (this.doesBudgetExceedDailySpendingLimit()) {
        errorMessage = "Your selected budget puts you over the " + this.formatCurrency(this.DAILY_SPENDING_LIMIT_IN_USER_CURRENCY) + " daily spending limit.";
      } else {
        errorMessage = "You must set a max budget of at least " + this.formatCurrency(this.MINIMUM_BUDGET_IN_USER_CURRENCY);
      }
      this.budgetErrorLabel.innerText = errorMessage;
      this.budgetErrorLabel.classList.remove("hide");
    },
    _renderItemButtons: function () {
      if (!this.isActive) {
        return;
      }
      if (this.itemButtons) {
        this.itemButtons.destroy();
      }
      this.itemButtons = new ItemButtons({
        label: "Budget",
        getData: this.getData.bind(this),
        onSave: this.onSave,
        cancel: this.cancel,
        activate: this.activate,
        isActive: this.isActive,
        isValid: this.isBudgetValid()
      });
      this.itemButtons.placeAt(this.itemButtonsContainer);
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        this._renderBudgetErrors();
        this._renderItemButtons();
      }
    }
  });
  PostcardBudget.isComplete = function (model) {
    return model.creditRechargeAmountInUserCurrency;
  };
  PostcardBudget.isValid = function (model) {
    return true;
  };
  return PostcardBudget;
});