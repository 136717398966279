/**
 * MobileNavList
 *
 * Simple Helper widget to toggle appropriate classes for our Pattern Library Mobile Nav
 * This allows us to reuse a common partial for all three variants of the navigation
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase"], function (declare, domClass, query, _WidgetBase) {
  return declare([_WidgetBase], {
    anchorClassList: "",
    selectedClass: "selected",
    postCreate: function () {
      this.anchorNodes = query("a", this.domNode);
      this.itemNodes = query("li", this.domNode);
      this._hydrateAnchorClasses();
      this._initSelectedIndicator();
    },
    /**
     * We know that we want to add certain classes to every <a> tag within the containing node. This appends them
     * to all <a> tag within the widget. These are passed in with the anchorClassList prop.
     *
     * @private
     */
    _hydrateAnchorClasses: function () {
      this.anchorNodes.addClass(this.anchorClassList);
    },
    /**
     * This widget wraps around an Avesta-rendered partial that affords us the ability to use utility methods
     * to indicate what page are currently on. In an effort to not recreate that within JS, create some middle-wear
     * so we can reuse the same markup for both the Regular list and tablet/phone navigations.
     *
     * Look for whether any of the <li> have selected class on them, add that to the child anchor.
     * @private
     */
    _initSelectedIndicator: function () {
      this.itemNodes.forEach(function (itemNode) {
        if (domClass.contains(itemNode, this.selectedClass)) {
          var childAnchor = query("a", itemNode)[0];
          domClass.add(childAnchor, this.selectedClass);
        }
      }.bind(this));
    }
  });
});