define(["mojo/lib/flags", "mojo/lib/logger", "mojo/user"], function (flags, logger, user) {
  return function () {
    var args = Array.from(arguments);
    var eventName = typeof args[0] === "string" && args.shift() || "bulk_campaign:engaged";
    logger.eventsPipeline.publish(eventName, Object.assign({
      org: "sbseg",
      purpose: "prod",
      scope: "mc",
      initiative_name: "bulk_email",
      scope_area: "bulk_campaigns"
    }, args[0]), {
      customLoginTraits: args[1],
      customAccountTraits: args[2]
    });
  };
});