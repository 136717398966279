define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"./registry" // registry.getEnclosingWidget(), registry.byNode()
], function (declare, registry) {
  // module:
  //		dijit/_Contained

  return declare("dijit._Contained", null, {
    // summary:
    //		Mixin for widgets that are children of a container widget
    // example:
    //	|	// make a basic custom widget that knows about its parents
    //	|	declare("my.customClass",[dijit._WidgetBase, dijit._Contained],{});

    _getSibling: function ( /*String*/which) {
      // summary:
      //		Returns next or previous sibling
      // which:
      //		Either "next" or "previous"
      // tags:
      //		private
      var node = this.domNode;
      do {
        node = node[which + "Sibling"];
      } while (node && node.nodeType != 1);
      return node && registry.byNode(node); // dijit/_WidgetBase
    },
    getPreviousSibling: function () {
      // summary:
      //		Returns null if this is the first child of the parent,
      //		otherwise returns the next element sibling to the "left".

      return this._getSibling("previous"); // dijit/_WidgetBase
    },
    getNextSibling: function () {
      // summary:
      //		Returns null if this is the last child of the parent,
      //		otherwise returns the next element sibling to the "right".

      return this._getSibling("next"); // dijit/_WidgetBase
    },
    getIndexInParent: function () {
      // summary:
      //		Returns the index of this widget within its container parent.
      //		It returns -1 if the parent does not exist, or if the parent
      //		is not a dijit/_Container

      var p = this.getParent();
      if (!p || !p.getIndexOfChild) {
        return -1; // int
      }
      return p.getIndexOfChild(this); // int
    }
  });
});