define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "vendor/lodash-amd/find", "mojo/widgets/Renderable", "mojo/user", "mojo/utils", "mojo/url", "mojo/lib/logger", "dojo/text!./templates/_personalized-to.html"], function (declare, lang, array, registry, find, Renderable, user, utils, mUrl, logger, tpl) {
  var PersonalizedTo = declare([Renderable], {
    templateString: tpl,
    showPersonalToRec: false,
    state: {
      isPersonalizedToInfoDisplayed: false,
      showCustomInput: false,
      mergeFields: null,
      settings: null,
      forceHide: null
    },
    constructor: function (props) {
      this.state.mergeFields = props.mergeFields;
      this.state.settings = props.settings;
      this.showPersonalToRec = props.showPersonalToRec;
      this.state.forceHide = props.forceHide;
    },
    initializePersonalizeToSetting: function () {
      this.defaultMergeOptions = [{
        value: "please-select",
        label: "Please select",
        selected: this.state.settings.personalizeToTag === ""
      }].concat(this.getMergeTagOptions());
      var valueIsDefault = this.valueIsDefaultMergeTag(this.state.settings.personalizeToTag);
      var customShouldBeSelected = this.state.settings.personalizeToTag === "custom-merge-tag-field" || this.state.settings.personalizeToTag !== "" ? !valueIsDefault : false;
      var customObj = {
        value: "custom-merge-tag-field",
        label: "Custom",
        selected: customShouldBeSelected
      };
      this.defaultMergeOptions.push(customObj);
      if (this.state.settings.personalizeToTag !== "" && !valueIsDefault) {
        this.setState({
          showCustomInput: valueIsDefault === false
        });
      }
    },
    render: function () {
      this.initializePersonalizeToSetting();
      this.inherited(arguments);
    },
    _createMergeTags: function () {
      return this.state.mergeFields.filter(lang.hitch(this, function (currentItem, currentIndex) {
        if (currentItem.var === "FNAME" || currentItem.var === "LNAME") {
          currentItem.value = "*|" + currentItem.var + "|*";
          currentItem.label = "*|" + currentItem.var + "|*";
          currentItem.selected = "*|" + currentItem.var + "|*" === this.state.settings.personalizeToTag;
          return true;
        }
        return false;
      }));
    },
    getMergeTagOptions: function () {
      var options = this._createMergeTags();
      var hasLname = find(options, {
        "value": "*|LNAME|*"
      });
      var hasFname = find(options, {
        "value": "*|FNAME|*"
      });
      if (hasLname && hasFname) {
        options.push({
          value: "*|FNAME|* *|LNAME|*",
          label: "*|FNAME|* *|LNAME|*",
          selected: "*|FNAME|* *|LNAME|*" === this.state.settings.personalizeToTag
        });
      }
      return options;
    },
    valueIsDefaultMergeTag: function (value) {
      if (value === "custom-merge-tag-field") {
        return false;
      }
      return array.some(this.defaultMergeOptions, function (item) {
        return item.value === value;
      });
    },
    viewPersonalizedToDisclosure: function () {
      this.setState({
        isPersonalizedToInfoDisplayed: !this.state.isPersonalizedToInfoDisplayed
      });
    },
    togglePersonalizeTo: function (isChecked) {
      var newSettingsState = lang.mixin({}, this.state.settings, {
        personalizeTo: isChecked
      });
      if (isChecked) {
        this.setState({
          settings: newSettingsState
        });
        this.initializePersonalizeToSetting();
      } else {
        newSettingsState.personalizeToTag = "";
        this.setState({
          settings: newSettingsState,
          showCustomInput: false
        });
      }
      var eventAction = isChecked ? "select" : "unselect";
      logger.googleAnalytics.trackEvent("campaign_checklist", eventAction, "add_recipients:personalize_to_field");
    },
    setPersonalizeToTag: function (value) {
      var personalizeTo = lang.mixin({}, this.state.settings, {
        personalizeToTag: value === "please-select" ? "" : value
      });
      this.setState({
        showCustomInput: !this.valueIsDefaultMergeTag(value),
        settings: personalizeTo
      });
    },
    handlePersonalizeToTagInputBlur: function (event) {
      var value = event.target.value;
      if (value) {
        this.setPersonalizeToTag(value);
      }
    },
    getData: function () {
      return this.state.settings.personalizeTo && this.state.settings.personalizeToTag !== "custom-merge-tag-field" ? this.state.settings.personalizeToTag : null;
    }
  });
  return PersonalizedTo;
});