define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dijit/_WidgetBase", "dijit/_AttachMixin", "vendor/lodash-amd/template"], function (declare, lang, html, query, parser, _WidgetBase, _AttachMixin, template) {
  return declare([_WidgetBase, _AttachMixin], {
    state: {},
    // Allows AttachMixin to find all the attach-event and attach-point nodes
    searchContainerNode: true,
    parseContent: true,
    constructor: function (props) {
      // Check for any required props and throw an exception if not provided
      if (this.requiredProps) {
        var missingProps = this.requiredProps.filter(function (propName) {
          return props[propName] === undefined;
        });
        if (missingProps.length > 0) {
          throw "🤓 The following properties are missing from " + this.widgetName + ": " + missingProps.join(", ");
        }
      }
      // This can't work without a template string
      if (!this.templateString) {
        throw new Error("No templateString provided to Renderable Widget");
      }

      // Bind update listener to any state changes
      this.watch("state", this._update.bind(this));
    },
    setState: function (nextState) {
      this.set("state", lang.mixin({}, this.state, nextState));
    },
    willUpdate: function () {},
    _update: function (key, prevValue, nextValue) {
      if (this.shouldUpdate(prevValue, nextValue)) {
        this.willUpdate(prevValue, nextValue);
        this.render();
      }
    },
    shouldUpdate: function (prevValue, nextValue) {
      return JSON.stringify(prevValue) !== JSON.stringify(nextValue);
    },
    buildRendering: function () {
      this.inherited(arguments);

      // Only compile the template once
      this.template = template(this.templateString);

      // Trigger initial render
      this.render();
    },
    //The tag must include an id to use preserveFocus();
    preserveFocus: function (event) {
      var target = event.type === "blur" && event.relatedTarget ? event.relatedTarget : event.target;
      this._focusOnNextRender = target && target.id;
      this._focusonNextRenderCaretPositionStart = target.selectionStart;
      this._focusonNextRenderCaretPositionEnd = target.selectionEnd;
    },
    onRestoreFocus: function () {},
    _restoreFocus: function () {
      if (this._focusOnNextRender) {
        var focusNode = document.getElementById(this._focusOnNextRender);
        if (focusNode) {
          this._setCursorPositon(focusNode, this._focusonNextRenderCaretPositionStart, this._focusonNextRenderCaretPositionEnd);
          focusNode.focus();
          this._focusOnNextRender = null;
          this.__focusonNextRenderCaretPositionStart = null;
          this.__focusonNextRenderCaretPositionEnd = null;
        }
      }
    },
    _setCursorPositon: function (node, posStart, posEnd) {
      if (node.setSelectionRange) {
        node.setSelectionRange(posStart, posEnd);
        return true;
      }
      if (node.createTextRange) {
        var textRange = node.createTextRange();
        textRange.collapse(true);
        textRange.moveEnd("character", posEnd);
        textRange.moveStart("character", posStart);
        textRange.select();
        return true;
      }
      return false;
    },
    render: function () {
      this._detachTemplateNodes(this.domNode);
      html.set(this.domNode, this.template(this.state));

      // Enables AttachMixin to now rendered content,
      // this is normally done by an inherited buildRendering but the nodes won't exist until now
      this._attachTemplateNodes(this.domNode);
      if (this.parseContent) {
        parser.parse({
          rootNode: this.domNode,
          propsThis: this
        });
      }
      this.onRestoreFocus();
      this._restoreFocus();
    }
  });
});