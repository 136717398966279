/**
 * @module mojo/widgets/FaviconColorSelect
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "dijit/TooltipDialog", "dijit/popup", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, TooltipDialog, popup, on, dom, domConstruct, domClass) {
  // The order of this array determines the order of the color options in the dropdown (wrapping from left to right)
  var FAVICON_COLORS = ["cavendish", "green", "purple", "cornflower", "orange", "pink"];
  var FAVICON_BASE_PATH = "https://cdn-images.mailchimp.com/product/brand_assets/favicons/favicon-";
  var DEFAULT_FAVICON = "https://cdn-images.mailchimp.com/product/brand_assets/favicons/favicon.ico";
  var getCurrentUserId = function () {
    return new URLSearchParams(window.location.search).get("user_id");
  };
  var updateSavedColorsWithNewUser = function (colors, currentUserId, selectedColor) {
    var currentUserIndex;
    for (var i = 0, l = colors.length; i < l; ++i) {
      if (colors[i].userId === currentUserId) {
        currentUserIndex = i;
      }
    }

    // If currentUserId has been assigned color, remove from the array
    var currentUserAssignedColor = currentUserIndex >= 0;
    if (currentUserAssignedColor) {
      colors = colors.slice(0, currentUserIndex).concat(colors.slice(currentUserIndex + 1, colors.length));
    }
    var currentUserAssignedNewColor = {
      userId: currentUserId,
      color: selectedColor
    };
    colors.unshift(currentUserAssignedNewColor);
    return colors;
  };
  var updateFaviconLinkTag = function (faviconPath) {
    var link = document.createElement("link"),
      oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "icon";
    link.href = faviconPath;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  };
  var changeFaviconColor = function (colorName) {
    var useDefaultColor = FAVICON_COLORS.indexOf(colorName) === -1;
    if (useDefaultColor) {
      updateFaviconLinkTag(DEFAULT_FAVICON);
    } else {
      var faviconPath = FAVICON_BASE_PATH + colorName + ".ico";
      updateFaviconLinkTag(faviconPath);
    }
  };
  var changeSelectDisplayColor = function (selectedColor) {
    domClass.remove("current-color");
    domClass.add("current-color", "current-color color-option " + selectedColor);
  };
  var getFaviconColorsFromLocalStorage = function () {
    var faviconColors = localStorage.getItem("faviconColors");
    var parsedFaviconColors = JSON.parse(faviconColors);

    // safety check to make sure this returns array
    if (!faviconColors || !Array.isArray(parsedFaviconColors)) {
      return [];
    }
    return parsedFaviconColors;
  };
  var initFaviconColor = function () {
    var currentUserId = getCurrentUserId();
    var faviconColors = getFaviconColorsFromLocalStorage();
    var currentUser;
    faviconColors.forEach(function (item) {
      if (item.userId === currentUserId) {
        currentUser = item;
      }
    });
    if (!currentUser) {
      return;
    }
    changeFaviconColor(currentUser.color);
    changeSelectDisplayColor(currentUser.color);
  };
  var setFaviconColorInLocalStorage = function (selectedColor) {
    var currentUserId = getCurrentUserId();

    // Only save to localStorage, if on a specific userId page
    if (currentUserId) {
      var faviconColors = getFaviconColorsFromLocalStorage();
      var updatedFaviconColors = updateSavedColorsWithNewUser(faviconColors, currentUserId, selectedColor);

      // Only store the colors of the 50 most recent
      if (updatedFaviconColors.length > 50) {
        updatedFaviconColors.pop();
      }
      window.localStorage.setItem("faviconColors", JSON.stringify(updatedFaviconColors));
    }
  };
  var bindColorOptionEvents = function (colorSelectOption, color) {
    on(colorSelectOption, "click", function () {
      setFaviconColorInLocalStorage(color);
      changeSelectDisplayColor(color);
      changeFaviconColor(color);
      popup.close();
    });
  };
  var createColorOptions = function (parentElement) {
    FAVICON_COLORS.forEach(function (color) {
      var classString = "color-option " + color;
      var colorSelectOption = domConstruct.create("span", {
        id: color,
        "class": classString
      });
      bindColorOptionEvents(colorSelectOption, color);
      domConstruct.place(colorSelectOption, dom.byId(parentElement));
    });
  };
  return declare([_WidgetBase, _TemplatedMixin], {
    postCreate: function () {
      initFaviconColor();
      var tooltipContent = domConstruct.create("div", {
        id: "tooltip-content",
        "class": "tooltip-content "
      });
      createColorOptions(tooltipContent);
      var faviconColorsTooltipDialog = new TooltipDialog({
        id: "favicon-colors-dialog",
        "class": "favicon-colors-container",
        content: tooltipContent,
        onShow: function () {
          this.focus();
        },
        _onBlur: function () {
          // User must have clicked a blank area of the screen, so close the TooltipDialog
          popup.close(faviconColorsTooltipDialog);
        }
      });
      var faviconSelectNode = dom.byId("favicon-select");
      on(faviconSelectNode, "click", function () {
        // This closes the search dropdown if open when the favicon color dropdown is opened
        domClass.remove(dom.byId("search-dialog"), "search-dialog-active");
        popup.open({
          popup: faviconColorsTooltipDialog,
          around: faviconSelectNode,
          orient: ["below-centered"],
          onCancel: function () {
            // User pressed escape, so close myself
            popup.close(faviconColorsTooltipDialog);
          }
        });
      });
    }
  });
});