define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/webhooks", "mojo/app/peaches2/utils"], function (declare, base, mUrl, webhooks, p2Utils) {
  return declare([base, webhooks], {
    users: null,
    // passed from controller

    onReady: function () {
      this.inherited(arguments);
      var self = this;
      var shards = this.users.list;
      if (shards) {
        Object.keys(shards).forEach(function (obj) {
          self.$widget["webHook" + obj].set("onDownloadEnd", function () {
            p2Utils.utils.refreshOnError(this);
            self.webhookHeadCheckAll();
          });
        });
      }
    }
  });
});