define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/char-count.html"], function (declare, domClass, Renderable, tpl) {
  return declare([Renderable], {
    state: {
      charCountRemaining: null
    },
    templateString: tpl,
    setCount: function (charCountRemaining) {
      this.setState({
        charCountRemaining: charCountRemaining
      });
    },
    render: function () {
      this.inherited(arguments);
      domClass.add(this.domNode, "display--inline");
    }
  });
});