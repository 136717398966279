define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/utils", "mojo/user", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "mojo/api/segment", "dojo/text!./templates/item-buttons.html"], function (declare, lang, on, domClass, Renderable, utils, user, I18nTranslation, context, flags, logger, segmentApi, tpl) {
  return declare([Renderable], {
    saveLabel: "Save",
    cancelLabel: "Cancel",
    errorLabel: "Resolve",
    showToastMessage: false,
    successToastText: "Your changes have been ",
    successToastVerb: "saved",
    templateString: tpl,
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.saveLabel = I18nTranslation.translate("landing_pages_save_label");
        this.cancelLabel = I18nTranslation.translate("landing_pages_cancel_label");
      }
    },
    state: {
      isSaving: false,
      segmentData: null,
      eventProperties: {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit",
        object: "segment",
        object_detail: "create_segment",
        ui_object: "button",
        ui_object_detail: "save_segment",
        ui_action: "clicked",
        ui_access_point: "form_submit_button",
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        campaign_id: parseInt(new URLSearchParams(window.location.search).get("id"), 10)
      }
    },
    /**
     * Adds a segment's first 5 conditions to a list of properties for
     * an event that will be published to the Events Pipeline. We cap it
     * at 5 as there could be an unlimited number of conditions.
     *
     * @param {object} properties Map of an event's existing properties and corresponding values
     * @param {array} segmentConditions An array of segment condition objects with corresponding
     * fields and values.
     * @return {object} properties Map of an event's properties with segment conditions
     * @private
     */
    _addSegmentConditionsToEventProperties: function (properties, segmentConditions) {
      var props = properties;
      if (!segmentConditions.length) {
        return props;
      }
      var conditions = segmentConditions.slice(0, 5);
      // Loop through and add each condition's field, op, value
      // and value_op / extra (if it exists) to the properties object
      conditions.forEach(function (condition, i) {
        Object.keys(condition).forEach(function (key) {
          var index = i + 1;
          var keyName = key + "_" + index;
          props[keyName] = condition[key];
        });
      });
      return props;
    },
    save: function (event) {
      event.preventDefault();
      if (this.onBeforeSave !== undefined && !this.onBeforeSave()) {
        return;
      }
      var data = this.getData();
      this.setState({
        errors: {},
        isSaving: true,
        segmentData: data
      });
      this.onSave(data).then(this._handleSuccessfulSave.bind(this), this._handleErrorSave.bind(this));
    },
    _handleSuccessfulSave: function () {
      // Publish Segment Saved success event to Events Pipeline

      // Check to see if we are in the Edit Recipients "Advanced Segment"
      // or "Regular Segment" context as the Save button is used in many
      // different contexts in the same form
      if (Object.keys(this.state.segmentData).includes("recipients")) {
        var segmentType = this.state.segmentData.recipients.segment.type;
        if ([segmentApi.TYPE_CUSTOM, segmentApi.TYPE_CUSTOM_PRO].includes(segmentType)) {
          var segment = this.state.segmentData.recipients.segment.value;
          // Advanced segment
          if (segmentType === segmentApi.TYPE_CUSTOM_PRO) {
            var advancedSuccessProperties = this._addSegmentConditionsToEventProperties(this.state.eventProperties, segment.conditions);
            advancedSuccessProperties.segmentation_method = "advanced";
            advancedSuccessProperties.action = "succeeded";
            logger.eventsPipeline.publish("segment:succeeded", advancedSuccessProperties);
            // Regular segment
          } else {
            var regularSuccessProperties = this._addSegmentConditionsToEventProperties(this.state.eventProperties, segment.conditions);
            regularSuccessProperties.segmentation_method = "regular";
            regularSuccessProperties.action = "succeeded";
            logger.eventsPipeline.publish("segment:succeeded", regularSuccessProperties);
          }
        }
      }
      if (!this._destroyed) {
        this.setState({
          isSaving: false
        });
      }
      if (this.showToastMessage) {
        utils.toast(this.successToastText + this.successToastVerb + ".", "success");
      }
    },
    _handleErrorSave: function (error) {
      // Publish Segment Saved error event to Events Pipeline

      // Check to see if we are in the Edit Recipients "Advanced Segment"
      // or "Regular Segment" context as the Save button is used in many
      // different contexts in the same form
      if (Object.keys(this.state.segmentData).includes("recipients")) {
        var segmentType = this.state.segmentData.recipients.segment.type;
        if ([segmentApi.TYPE_CUSTOM, segmentApi.TYPE_CUSTOM_PRO].includes(segmentType)) {
          var segment = this.state.segmentData.recipients.segment.value;
          // Advanced segment
          if (segmentType === segmentApi.TYPE_CUSTOM_PRO) {
            var advancedErrorProperties = this.state.eventProperties;
            advancedErrorProperties.segmentation_method = "advanced";
            advancedErrorProperties.segmentation_error = error.message;
            advancedErrorProperties.action = "failed";
            logger.eventsPipeline.publish("segment:failed", advancedErrorProperties);
            // Regular segment
          } else {
            var regularErrorProperties = this.state.eventProperties;
            regularErrorProperties.segmentation_method = "regular";
            regularErrorProperties.segmentation_error = error.message;
            regularErrorProperties.action = "failed";
            logger.eventsPipeline.publish("segment:failed", regularErrorProperties);
          }
        }
      }
      if (!this._destroyed) {
        if (this.onSaveError) {
          this.onSaveError(error);
        }
        this.setState({
          isSaving: false
        });
      }
    }
  });
});