define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/widgets/Renderable", "mojo/utils", "mojo/lib/logger", "dojo/text!./templates/subjectDialog.html"], function (declare, query, on, Dialog, Renderable, utils, logger, tpl) {
  var REGEX_DIGIT_ENDS_WITH_ZERO = /^(\d+)(\.)(0)$/;

  /**
   * Takes a float open rate and transforms it into a number with one floating digit.
   * If the floating digit is zero, it removes the decimal and zero.
   *
   * @param {float} openRate 
   * @return {string}
   */
  function formatOpenRate(openRate) {
    var fmtOpenRate = (openRate * 100).toFixed(1) + "";
    if (fmtOpenRate.match(REGEX_DIGIT_ENDS_WITH_ZERO)) {
      fmtOpenRate = fmtOpenRate.substr(0, fmtOpenRate.length - 2);
    }
    return fmtOpenRate;
  }
  ;
  return declare([Renderable], {
    templateString: tpl,
    toggleSurveyListener: null,
    MAX_SUBJECTS_TO_DISPLAY: 5,
    SURVEY_RES_YES: "useful_yes",
    SURVEY_RES_NO: "useful_no",
    constructor: function (props) {
      var hasRecentCampaigns = props.model && props.model.recentCampaigns && props.model.recentCampaigns.length > 0;
      var campaignsSubjectPerf = [];
      if (hasRecentCampaigns) {
        campaignsSubjectPerf = props.model.recentCampaigns.map(function (campaign) {
          return {
            subject: campaign.subject,
            openRate: formatOpenRate(campaign.open_rate)
          };
        });
      }
      this.state = {
        hasRecentCampaigns: hasRecentCampaigns,
        campaignsSubjectPerf: campaignsSubjectPerf,
        shouldShowSurvey: true,
        hasFinishedSurvey: false
      };
    },
    render: function () {
      this.inherited(arguments);
      var dialogCloseIcon = query(".dijitDialogFocused .dijitDialogCloseIcon")[0];
      if (dialogCloseIcon && !this.toggleSurveyListener) {
        this.toggleSurveyListener = on(dialogCloseIcon, "click", function () {
          this.setState({
            shouldShowSurvey: !this.state.hasFinishedSurvey
          });
        }.bind(this));
      }
    },
    respondToSurvey: function (event) {
      var isValidResponse = event.target && event.target.id && [this.SURVEY_RES_YES, this.SURVEY_RES_NO].includes(event.target.id);
      if (isValidResponse) {
        var eventLabel = "recent_subject_line_performance_survey:" + event.target.id;
        logger.googleAnalytics.trackEvent("campaign_checklist", "click", eventLabel);
        this.setState({
          hasFinishedSurvey: true
        });
      }
    }
  });
});