/* eslint-disable no-undef */
define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context"], function (dom, registry, flags, I18nTranslation, context) {
  var REGION = "administrative_area_level_1";
  var CITY = "locality";
  var COUNTRY = "country";
  var invalidText = "Unable to locate the location entered. Please try again.";
  var maps = {
    marker: null,
    map: null,
    def_lat: null,
    def_lang: null,
    loaded: null,
    loadCode: function () {
      if (maps.loaded) {
        return;
      }
      google.load("maps", "3", {
        "other_params": "client=gme-mailchimp&sensor=false",
        "callback": function () {}
      });
      maps.loaded = true;
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        invalidText = I18nTranslation.translate("audience_management_segment_builder_invalid_location");
      }
    },
    initMap: function (id, lat, lng) {
      //deals with reloading saved segments
      if (lat !== null) {
        var latlng = new google.maps.LatLng(maps.def_lat, maps.def_lng);
        maps.addressSearch(id, latlng, false);
      }
    },
    getAddressValue: function (key, components) {
      for (var i = 0; i < components.length; i++) {
        var component = components[i];
        if (component.types.indexOf(key) !== -1) {
          return component.short_name;
        }
      }
    },
    addressSearch: function (id, latlng, show) {
      var map_id = id + "-map";
      maps.map = new google.maps.Map(document.getElementById(map_id), {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: false,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false
      });
      var geocoder = new google.maps.Geocoder();
      var address = null;
      var pars;
      if (latlng === null) {
        pars = {
          "address": dom.byId(id + "-address").value
        };
      } else {
        pars = {
          "latLng": latlng
        };
      }
      var point;
      geocoder.geocode(pars, function (result, status) {
        if (status !== google.maps.GeocoderStatus.OK) {
          // eslint-disable-next-line no-alert
          alert(invalidText);
        } else {
          dom.byId(id + "-address").value = result[0].formatted_address;
          var city_id = id + "-city";
          var region_id = id + "-region";
          var country_id = id + "-cc";
          var addressComponents = result[0].address_components;
          dom.byId(city_id).value = maps.getAddressValue(CITY, addressComponents);
          dom.byId(region_id).value = maps.getAddressValue(REGION, addressComponents);
          dom.byId(country_id).value = maps.getAddressValue(COUNTRY, addressComponents);
          point = result[0].geometry.location;
          if (show !== false) {
            registry.byId(id + "-mapdialog").show();
            maps.map.fitBounds(result[0].geometry.viewport);
            google.maps.event.trigger(maps.map, "resize");
            maps.addMarker(id, point, 9);
          }
        }
      });
      return false;
    },
    addMarker: function (id, point, zoom) {
      if (maps.marker !== null) {
        maps.marker.setMap(null);
      }
      maps.marker = new google.maps.Marker({
        position: point,
        map: maps.map
      });
      maps.map.setCenter(point);
      if (zoom !== null) {
        maps.map.setZoom(zoom);
      }
      try {
        var lat_id = id + "-lat";
        var lng_id = id + "-lng";

        //in case this gets used outside the segments
        dom.byId(lat_id).value = point.lat();
        dom.byId(lng_id).value = point.lng();
      } catch (e) {}
    }
  };
  mojo.maps = maps;
  return maps;
});