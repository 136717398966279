define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, on, base) {
  return declare([base], {
    onReady: function () {
      var self = this;
      on(this.$el.addAccountButton, "click", function () {
        self.$widget.addAccountDialog.show();
      });
    },
    deleteTestingAccount: function (id) {
      this.$el.userIdInput.value = id;
      this.$widget.deleteDialog.show();
    }
  });
});