define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/tip_placement.html", "dojo/request", "mojo/url", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./TipSingle"], function (declare, domClass, _WidgetBase, _TemplatedMixin, tpl, request, mUrl, lang, TipSingle) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    count: null,
    placement: null,
    placementName: null,
    profile: null,
    postCreate: function () {
      this._fetchTips().then(lang.hitch(this, function (response) {
        var tipCount = response.tips.length;
        for (var i = 0; i < tipCount; i++) {
          this._insertTip(response.tips[i], this._widthClass(tipCount), this.placementName);
        }
      }));
    },
    _insertTip: function (tip_data, width_class, placementName) {
      var tip = new TipSingle({
        "tip": tip_data,
        "width_class": width_class,
        placementName: placementName
      });
      tip.startup();
      tip.placeAt(this.domNode);
    },
    _fetchTips: function () {
      return request.get(mUrl.toUrl("/contextual-tips", this._conditions()), {
        "handleAs": "json"
      });
    },
    _conditions: function () {
      var conditions = {
        "count": this.count,
        "placement": this.placement
      };
      if (this.profile !== null) {
        conditions.profile = JSON.stringify(this.profile);
      }
      return conditions;
    },
    _widthClass: function (count) {
      switch (count) {
        case 1:
          return "flex-full";
        case 2:
          return "flex-half";
        case 3:
          return "flex-third";
        case 4:
          return "flex-fourth";
        default:
          return "flex-auto";
      }
    },
    show: function () {
      domClass.remove(this.domNode, "hide");
    },
    hide: function () {
      domClass.add(this.domNode, "hide");
    }
  });
});