define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block", "./_ImageMixin"], function (declare, lang, _Block, _ImageMixin) {
  return declare([_Block, _ImageMixin], {
    type: "imageGroup",
    _name: "Image Group",
    constructor: function () {
      this.images = [null, null];
      this.selectedLayout = [2];
      this.styles = this._getImageStyles();
    },
    onImageUpload: function (image, id) {
      var images = lang.clone(this.images);
      images[id] = image;
      this.set("images", images);
      this.save();
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      if (this.images && this.images[index] && this.images[index].link && this.images[index].link.href === oldUrl) {
        this.images[index].link.href = newUrl;
        if (this.images[index].link.text && this.images[index].link.text === oldUrl) {
          this.images[index].link.href = newUrl;
        }
        return this.save();
      } else {
        return this.inherited(arguments);
      }
    }
  });
});