define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/_Renderer", "dojo/text!../editors/blocks/templates/TextEditor/outputWeb.html"], function (declare, _Renderer, tpl) {
  return declare("mojo.neapolitan.web-renderers.TextBlock", [_Renderer], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      var columns = [];
      var totalWidth = meta.numberOfColumns > 1 ? meta.containerWidth : meta.containerWidth;
      // Accepts an array of cont0ent or just a string for content
      var contents = typeof meta.content === "string" ? [meta.content] : meta.content;
      for (var i = 0; i < meta.numberOfColumns; i++) {
        var split = meta.splitOption[i];
        var content = contents[i] ? contents[i] : "";
        columns.push({
          "content": content,
          "flexStyle": this._getFlexStyle(split)
        });
      }
      return {
        columns: columns,
        columnCount: meta.numberOfColumns
      };
    },
    _getFlexStyle: function (split) {
      var flexStyle = "flex: ";
      switch (split) {
        case 65:
          flexStyle += "2 0 0;";
          break;
        case 35:
          flexStyle += "1 0 0;";
          break;
        default:
          flexStyle += "1;";
          break;
      }
      return flexStyle;
    }
  });
});