define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "mojo/context", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/ProductRecEditor/vertical.html", "dojo/text!../editors/blocks/templates/ProductRecEditor/horizontal.html", "dojo/text!../editors/blocks/templates/ProductRecEditor/_product-content.html", "dojox/dtl", "dojox/dtl/Context", "dojox/dtl/tag/logic", "dojox/dtl/tag/loop"], function (declare, _Renderer, ButtonRenderer, Link, context, flags, lang, domConstruct, verticalTpl, horizontalTpl, contentTpl, dtl, dtlContext) {
  return declare([_Renderer], {
    verticalTemplate: null,
    horizontalTemplate: null,
    // Template that puts all the product attributes in rows
    contentTemplate: null,
    // Keeps a reference to a button renderer which is the same renderer that handles a button block
    buttonRenderer: null,
    constructor: function () {
      this.inherited(arguments);
      this._loadTemplates();
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * This block overrides the default getContent from the base to do its own rendering logic.
     *
     * @param tplModel
     * @returns {*|HTMLElement}
     */
    getContent: function (tplModel) {
      var outputModel = this.getOutputModel(lang.clone(tplModel));
      outputModel = lang.mixin({
        outerPadding: this.outerPadding,
        innerPadding: this.innerPadding
      }, outputModel);
      var template = tplModel.layout == "vertical" ? this.verticalTemplate : this.horizontalTemplate;
      var dom = domConstruct.toDom(lang.trim(template.render(new dtlContext(outputModel))));
      return this._inlineStyles(dom, tplModel.styles);
    },
    /**
     * Loads up all the templates
     * @private
     */
    _loadTemplates: function () {
      this.verticalTemplate = new dojox.dtl.Template(verticalTpl);
      this.horizontalTemplate = new dojox.dtl.Template(horizontalTpl);
      this.contentTemplate = new dojox.dtl.Template(contentTpl);
    },
    /**
     * Generate the correct open tag for product recommendation
     *
     * @param meta
     * @returns {string}
     * @private
     */
    _getMergeTagOpen: function (meta) {
      var tagAttributes = [];
      if (meta.numProducts) {
        tagAttributes.push("$total=" + meta.numProducts);
      }
      if (meta.startIndex) {
        tagAttributes.push("$start_index=" + meta.startIndex);
      }
      if (meta.storeId) {
        tagAttributes.push("$store_id=" + meta.storeId);
      }
      if (meta.recommendationStrategy) {
        tagAttributes.push("$recommendation_strategy=" + meta.recommendationStrategy);
      }
      var tagAttrStr = "";
      if (tagAttributes.length) {
        tagAttrStr = "[" + tagAttributes.join(",") + "]";
      }
      return "*|PRODUCT_RECOMMENDATION:" + tagAttrStr + "|*";
    },
    /**
     * The column width changes based on number of products and which layout a user have chosen
     *
     * @param meta
     *
     * @returns {Number}
     * @private
     */
    _calculateColumnWidth: function (meta) {
      var columnWidth = meta.containerWidth;
      switch (meta.layout) {
        case "vertical":
          if (meta.numProducts > 1) {
            columnWidth = columnWidth / meta.numProducts;
          }
          break;
        case "horizontal":
          columnWidth = columnWidth / 2;
          break;
        default:
          throw new Error("No layout defined");
      }
      return columnWidth;
    },
    /**
     * Use our button-block renderer to render the button itself.
     *
     * @param meta
     * @returns {*}
     * @private
     */
    _buttonMarkup: function (meta) {
      var mergeUrl = meta.buttonLinkType && meta.buttonLinkType == "cart" ? "*|PRODUCT:CART_URL|*" : "*|PRODUCT:URL|*";
      var buttonMeta = {
        "buttonText": meta.buttonText,
        "buttonWidth": meta.buttonWidth,
        "align": meta.buttonAlign,
        "link": new Link(mergeUrl, "*|PRODUCT:TITLE|*")
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    /**
     * Return the markup of the content of each product. This includes name, desc, price and button
     * @private
     */
    _contentMarkup: function (meta) {
      var rows = [];
      if (meta.titleEnabled) {
        rows.push({
          "html": "*|PRODUCT:TITLE|*<br />",
          "className": "mcnProductTitle",
          "styles": "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (meta.priceEnabled) {
        rows.push({
          "html": "*|PRODUCT:PRICE|*<br />",
          "className": "mcnProductPrice",
          "styles": "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (meta.buttonEnabled) {
        rows.push({
          "html": this._buttonMarkup(meta),
          "className": "mcnProductButton",
          "styles": "padding-top:18px"
        });
      }
      return rows.length ? lang.trim(this.contentTemplate.render(new dtlContext({
        "rows": rows
      }))) : "";
    },
    getOutputModel: function (meta) {
      var columnWidth = this._calculateColumnWidth(meta);
      var imageWidth = columnWidth - 36;
      return {
        contentHTML: this._contentMarkup(meta),
        columnWidth: columnWidth,
        imageWidth: imageWidth,
        totalPadding: this.outerPadding * 2,
        precMergeTagOpen: this._getMergeTagOpen(meta),
        precMergeTagClose: "*|END:PRODUCT_RECOMMENDATION|*"
      };
    }
  });
});