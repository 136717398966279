define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", './QueryResults', "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, Deferred, QueryResults, when) {
  // module:
  //		this is a mixin that can be used to provide async methods,
  // 		by implementing their sync counterparts
  function promised(method, query) {
    return function () {
      var deferred = new Deferred();
      try {
        deferred.resolve(this[method].apply(this, arguments));
      } catch (error) {
        deferred.reject(error);
      }
      if (query) {
        // need to create a QueryResults and ensure the totalLength is
        // a promise.
        var queryResults = new QueryResults(deferred.promise);
        queryResults.totalLength = when(queryResults.totalLength);
        return queryResults;
      }
      return deferred.promise;
    };
  }
  return declare(null, {
    get: promised('getSync'),
    put: promised('putSync'),
    add: promised('addSync'),
    remove: promised('removeSync'),
    fetch: promised('fetchSync', true),
    fetchRange: promised('fetchRangeSync', true)
  });
});