define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Templated", "dijit/_Widget", "dijit/registry", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/mimetype", "mojo/context", "mojo/lib/flags", "mojo/utils", "mojo/lib/clipboard", "dojo/text!./templates/UploadProgressWindow.html", "dojo/text!./templates/UploadProgressItem.html"], function (declare, lang, array, on, _Templated, _Widget, registry, domStyle, domClass, topic, mimetype, context, flags, utils, clipboard, uploadProgressWindowTpl, uploadProgressItemTpl) {
  var UploadProgressItem = declare("UploadProgressItem", [_Widget, _Templated], {
    templateString: uploadProgressItemTpl,
    name: null,
    _setNameAttr: {
      node: "fileName",
      type: "innerText"
    },
    action: null,
    _setActionAttr: {
      node: "link",
      type: "innerText"
    },
    dataUrl: null,
    promise: null,
    status: null,
    behavior: null,
    postCreate: function () {
      on(this.link, "click", lang.hitch(this, "onLinkClick"));
      if (this.behavior === "compress-item") {
        this.promise.then(lang.hitch(this, "onUploadFinish"), lang.hitch(this, "onCompressError"));
      } else {
        this.promise.then(function (response) {
          if (context.flagIsOn(flags.HOP_UNDEFINED_MESSAGE_CONTENT_MANAGER)) {
            this._handleImageUpload(response);
          } else {
            this.onUploadFinish(response);
          }
        }.bind(this), lang.hitch(this, "onUploadError"));
      }
      this.onUploadStart();
    },
    /**
     * Method to handle the resolve of the upload function promise. The server will either send the image object details
     * or an error object when a user uploads an invalid file (too large, incorrect format, etc).
     *
     * @param {Object} response - Response object from the promise - error object || imageContent object
     * @private
     */
    _handleImageUpload: function (response) {
      if (response.error) {
        this.onUploadError(response.error);
      } else {
        this.onUploadFinish(response);
      }
    },
    onUploadStart: function () {
      this._setProgress(10);
      this.type = this.type || "none";
      this._setThumbnail(this._getGenericFileIcon(this.type), true);
      this.set("status", "uploading");
    },
    onUploadFinish: function (contentItem) {
      this._hideProgress();
      this.contentItem = contentItem;
      this._setThumbnail(contentItem.getThumbnailUrl() || this._getGenericFileIcon(this.type));
      this.set("status", "finished");
      this.set("action", "Copy URL");
    },
    onUploadError: function (error) {
      this._hideProgress();
      domClass.toggle(this.link, "hide", true);
      this.set("status", "error");
      this.set("name", "Failed to upload");
    },
    onCompressError: function (error) {
      this._hideProgress();
      domClass.toggle(this.link, "hide", true);
      this.set("status", "error");
      this.set("name", "Failed to compress MMS image");
    },
    isUploading: function () {
      return this.status === "uploading";
    },
    onLinkClick: function () {
      if (!this.contentItem) {
        return;
      }
      clipboard.copy(this.contentItem.url);
      utils.globalPublish("show-toast", [{
        "message": "The file URL is copied—paste when you're ready.",
        "type": "success"
      }]);
      window.ga("send", "event", "Content Studio", "Content Studio Index", "Upload / Copy URL");
    },
    _setProgress: function (value) {
      domStyle.set(this.progress, "width", value + "%");
    },
    _hideProgress: function () {
      domClass.toggle(this.progressMeter, "hide", true);
    },
    _setThumbnail: function (src, isIcon) {
      domStyle.set(this.thumbnail, "backgroundImage", "url('" + src + "')");
      if (isIcon) {
        domClass.add(this.thumbnail, "icon");
      }
    },
    _getGenericFileIcon: function (type) {
      if (mimetype.isPlainText(type)) {
        type = "text";
      } else if (mimetype.isImage(type)) {
        type = "imageother";
      } else if (mimetype.isAudio(type) || type.indexOf("audio") === 0) {
        type = "audio";
      } else if (mimetype.isDocument(type)) {
        type = "document";
      } else if (mimetype.isSpreadsheet(type)) {
        type = "spreadsheet";
      } else if (mimetype.isPresentation(type)) {
        type = "presentation";
      } else {
        type = "unknown";
      }
      return "https://cdn-images.mailchimp.com/product/files/file-manager-" + type + "-small.svg";
    }
  });
  return declare("UploadProgressWindow", [_Widget, _Templated], {
    templateString: uploadProgressWindowTpl,
    baseClass: "fileUploader",
    isShowing: false,
    isMinimized: false,
    defaultAction: "view",
    titleText: "Upload Manager",
    _setTitleTextAttr: {
      node: "title",
      type: "innerText"
    },
    postCreate: function () {
      on(this.closeButton, "click", lang.hitch(this, "close"));
      on(this.minimizeButton, "click", lang.hitch(this, "minimize"));
      topic.subscribe("mojo/content-manager/api/upload", lang.hitch(this, "onUpload"));
      topic.subscribe("mojo/content-manager/api/create-mms", lang.hitch(this, "onCompress"));
    },
    onUpload: function (message) {
      var skipUploadPane = message.skipUploadPane;
      if (skipUploadPane === true) {
        return;
      }
      var file = message.file;
      var promise = message.promise;
      var uploadProgressItem = new UploadProgressItem({
        name: "Uploading...",
        dataUrl: file.data || file.data_url,
        promise: promise,
        type: file.type
      });
      promise.then(lang.hitch(this, function (contentItem) {
        if (context.flagIsOn(flags.HOP_UNDEFINED_MESSAGE_CONTENT_MANAGER) && !contentItem.name) {
          // If there is no name with an item due to a failed upload we will early return
          return;
        }
        uploadProgressItem.set("name", contentItem.name);
        this.updateTitle();
      }));
      uploadProgressItem.placeAt(this.uploadList, "first");
      this.open();
    },
    onCompress: function (message) {
      var skipUploadPane = message.skipUploadPane;
      if (skipUploadPane === true) {
        return;
      }

      //var file = message.file;
      var promise = message.promise;
      var file = message.file;
      var uploadProgressItem = new UploadProgressItem({
        name: "Compressing image for MMS",
        dataUrl: file.url,
        promise: promise,
        type: file.extension,
        behavior: "compress-item"
      });
      promise.then(lang.hitch(this, function (contentItem) {
        uploadProgressItem.set("name", contentItem.name);
        this.updateTitle();
      }));
      uploadProgressItem.placeAt(this.uploadList, "first");
      this.open();
    },
    open: function () {
      domClass.toggle(this.domNode, "hide", false);
      this.updateTitle();
    },
    minimize: function () {
      domClass.toggle(this.uploadList, "hide");
    },
    close: function () {
      domClass.toggle(this.domNode, "hide", true);
      domClass.toggle(this.uploadList, "hide", false);
      this.uploadList.innerHtml = "";
    },
    updateTitle: function () {
      var total = this.uploadList.children.length;
      var numUploading = array.filter(this.uploadList.children, function (uploadItemDomNode) {
        var uploadItem = registry.byNode(uploadItemDomNode);
        return uploadItem.isUploading() === false;
      }).length;
      var percentage = Math.round(numUploading / total * 100);
      this.set("titleText", "Uploading " + numUploading + " of " + total + " (" + percentage + "%)");
    }
  });
});