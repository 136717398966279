define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/utils", "mojo/widgets/Autocomplete", "mojo/context", "mojo/lib/flags", "mojo/lib/logger",
// Mixins
"dijit/_WidgetBase", "mojo/analytics/parts/_Part",
// Widgets
"./AllAudiencesSearchHint", "mojo/utils/I18nTranslation", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, lang, debounce, topic, when, user, utils, Autocomplete, context, flags, logger, _WidgetBase, _Part, AllAudiencesSearchHint, I18nTranslation, EcsEvents, EcsTracking) {
  var placeHolderContentWithGlobalSwitcherDefaults;
  if (context.flagIsOn(flags.WEF_REWORK_CAMPAIGNS_SEARCH_BOX)) {
    placeHolderContentWithGlobalSwitcherDefaults = {
      "recent": "Find a campaign by name",
      "ongoing": "Find an ongoing campaign by name",
      "draft": "Find a draft campaign by name",
      "completed": "Find a completed campaign by name",
      "archived": "Find an archived automation by name",
      "folder": "Find a campaign in this folder by name",
      "regular": "Find an email campaign by name",
      "automation": "Find an automation by name",
      "page": "Find a landing page by name",
      "ad_all": "Find an ad campaign by name",
      "postcard": "Find a postcard campaign by name",
      "social_post": "Find a social post campaign by name"
    };
  } else {
    placeHolderContentWithGlobalSwitcherDefaults = {
      "recent": "Find a campaign by name or type",
      "ongoing": "Find an ongoing campaign by name or type",
      "draft": "Find a draft campaign by name or type",
      "completed": "Find a completed campaign by name or type",
      "archived": "Find an archived automation by name",
      "folder": "Find a campaign in this folder by name or type",
      "regular": "Find an email campaign by name",
      "automation": "Find an automation by name",
      "page": "Find a landing page by name",
      "ad_all": "Find an ad campaign by name",
      "postcard": "Find a postcard campaign by name",
      "social_post": "Find a social post campaign by name"
    };
  }
  return declare([_WidgetBase, _Part], {
    translationObject: null,
    placeHolderContentWithGlobalSwitcher: placeHolderContentWithGlobalSwitcherDefaults,
    constructor: function () {
      I18nTranslation.initialize().then(function (translations) {
        this.translationObject = translations;
        if (!context.flagIsOn(flags.WEF_REWORK_CAMPAIGNS_SEARCH_BOX)) {
          this.placeHolderContentWithGlobalSwitcher["recent"] = this.translationObject.campaigns_search_recent_placeholder;
        } else {
          this.placeHolderContentWithGlobalSwitcher["recent"] = this.translationObject.campaigns_searchbox_recent_placeholder;
        }
      }.bind(this));
    },
    /**
     * Check currently applied folder/bucket filters and generate placeholder text
     * @private
     * @returns {string} appropriate placeholder text
     */
    _getPlaceholderText: function () {
      return this.translationObject.campaigns_search_default_placeholder;
    },
    /**
     * Fetch list of type filters available to user
     * @return {*} Promise - A relic of a previous time in which we thought we'd pull filters from the server
     */
    getValidTypeFilters: function () {
      var results = ["regular", "automation", "page", "ad_all", "postcard", "variate", "rss", "plaintext", "social_post", "unfiled"];
      results.push("all");
      return when(results);
    },
    /**
     * Clear all type, search, and list filters currently applied.
     * @private
     */
    _clearFilters: function () {
      if (this.target.hasFilter("search") || this.target.hasFilter("type") || this.target.hasFilter("list")) {
        this.target.clearFilter("search");
        this.target.clearFilter("type");
      }
    },
    _isViewByTypeSelected: function () {
      return this.target.getFilter("type") && this.target.getFilter("type") !== "all";
    },
    /**
     * @override canDisplay method in parent _Part class
     * @inheritDoc
     * @return {boolean} - Always show
     */
    canDisplay: function () {
      return true;
    },
    /**
     * Render the search hint text to suggest viewing "all audiences"
     * @private
     */
    _setupAllAudiencesSearchHint: function () {
      var allAudiencesSearchHint = new AllAudiencesSearchHint();
      allAudiencesSearchHint.placeAt(this.domNode);
    },
    _trackTextSearch: function (searchTerm) {
      if (this.target._isReportsPage()) {
        var searchListener = topic.subscribe(this.target.getTopic(), function (list) {
          logger.googleAnalytics.trackEvent("reports", "search", searchTerm, {
            dimension57: list.get("renderedItemCount")
          });
          searchListener.remove();
        });
      }
    },
    /**
     * Init Function (Inherited from Part, Runs Post Render)
     *
     * @inheritDoc
     */
    init: function () {
      this.getValidTypeFilters().then(lang.hitch(this, function (results) {
        var searchBox;
        var typeSuggestions;
        if (!context.flagIsOn(flags.WEF_REWORK_CAMPAIGNS_SEARCH_BOX)) {
          typeSuggestions = [];
          typeSuggestions.filter(function (suggestion) {
            return results.indexOf(suggestion.value) > -1;
          });
          var suggestions = [];
          if (!this._isViewByTypeSelected()) {
            suggestions = typeSuggestions;
          }
          searchBox = new Autocomplete({
            placeholder: "",
            suggestions: suggestions,
            onSuggestionSelected: lang.hitch(this, function (filter) {
              if (filter.filterType === "type") {
                logger.info("campaign-status-tracking", "User clicked Type Suggestion via Search Bar", {
                  "status_bucket": filter.value,
                  "view_by": "Search Bar Suggestion",
                  "log_classification": "sensitive"
                });
                this._trackTextSearch("category - " + filter.label);
              }
              this._clearFilters();
              this.target.setFilter(filter.filterType, filter.value);
            }),
            onTextSearch: lang.hitch(this, function (searchText) {
              // If there is no "bucket" (status) filter, the user clicked 'View by Type' filter.
              if (this._isViewByTypeSelected()) {
                this.target.clearFilter("search");
              }
              this.target.setFilter("search", searchText);
              this._trackTextSearch(searchText);
            }),
            onFocus: lang.hitch(this, function (focusedElement) {
              focusedElement.select();
              if (context.flagIsOn(flags.WAYFI_SEARCH_ECS_TRACKING)) {
                if (window && window.location && window.location.href) {
                  if (window.location.href.includes("/campaigns")) {
                    EcsTracking.track(EcsEvents.AllCampaignsSearchEvent);
                  } else if (window.location.href.includes("/reports")) {
                    EcsTracking.track(EcsEvents.ReportsPageSearchEvent);
                  }
                }
              }
            }),
            onClear: lang.hitch(this, function () {
              // If there is no "bucket" (status) filter, the user clicked 'View by Type' filter.
              if (this._isViewByTypeSelected()) {
                this.target.clearFilter("search");
              } else {
                this._clearFilters();
              }
            }),
            shouldSuggestInitially: lang.hitch(this, function (suggestion) {
              var hasFolders = this.target.folders && this.target.folders.length > 0;
              return suggestion.filterType === "type" && (suggestion.value !== "unfiled" || !this.target.getFilter("folder") && hasFolders);
            }),
            shouldSuggestInSearches: lang.hitch(this, function (suggestion) {
              var hasFolders = this.target.folders && this.target.folders.length > 0;
              return suggestion.value !== "unfiled" || !this.target.getFilter("folder") && hasFolders;
            })
          });
        } else {
          searchBox = new Autocomplete({
            placeholder: "",
            onTextSearch: lang.hitch(this, function (searchText) {
              // If there is no "bucket" (status) filter, the user clicked 'View by Type' filter.
              if (this._isViewByTypeSelected()) {
                this.target.clearFilter("search");
              }
              this.target.setFilter("search", searchText);
              this._trackTextSearch(searchText);
            }),
            onFocus: lang.hitch(this, function (focusedElement) {
              focusedElement.select();
              if (context.flagIsOn(flags.WAYFI_SEARCH_ECS_TRACKING)) {
                if (window && window.location && window.location.href) {
                  if (window.location.href.includes("/campaigns")) {
                    EcsTracking.track(EcsEvents.AllCampaignsSearchEvent);
                  } else if (window.location.href.includes("/reports")) {
                    EcsTracking.track(EcsEvents.ReportsPageSearchEvent);
                  }
                }
              }
            }),
            onClear: lang.hitch(this, function () {
              // If there is no "bucket" (status) filter, the user clicked 'View by Type' filter.
              if (this._isViewByTypeSelected()) {
                this.target.clearFilter("search");
              } else {
                this._clearFilters();
              }
            })
          });
        }
        searchBox.set("searchText", "");

        //Check for initial search, type, or list filter
        if (this.target.getFilter("search")) {
          searchBox.set("searchText", this.target.getFilter("search"));
        } else if (!this._isViewByTypeSelected() && !context.flagIsOn(flags.WEF_REWORK_CAMPAIGNS_SEARCH_BOX)) {
          searchBox.set("selectedSuggestion", this.target.getFilter("type") || this.target.getFilter("list"));
        }

        //Set up watchers for search, type, and list
        this.target.watchFilter("search", debounce(lang.hitch(this, function (name, old, newValue) {
          searchBox.set("searchText", newValue);
        })), 0);
        this.target.watchFilter("type", debounce(lang.hitch(this, function (name, old, newValue) {
          searchBox.set("placeholderText", this._getPlaceholderText());
          if (!context.flagIsOn(flags.WEF_REWORK_CAMPAIGNS_SEARCH_BOX)) {
            if (this._isViewByTypeSelected()) {
              searchBox.setNewSuggestions([]);
              searchBox.set("searchText", "");
            } else {
              searchBox.setNewSuggestions(typeSuggestions);
              searchBox.set("selectedSuggestion", newValue);
            }
          }
        })), 0);

        //Set initial placeholder text and watch for bucket/folder changes in the future
        searchBox.set("placeholderText", this._getPlaceholderText());
        this.target.watchFilter("bucket", debounce(lang.hitch(this, function () {
          searchBox.set("placeholderText", this._getPlaceholderText());
        })), 0);
        this.target.watchFilter("folder", debounce(lang.hitch(this, function () {
          searchBox.set("placeholderText", this._getPlaceholderText());
        })), 0);
        searchBox.placeAt(this.domNode);
        this._setupAllAudiencesSearchHint();
      }));
    }
  });
});