define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "vendor/lodash-amd/find", "vendor/lodash-amd/identity", "mojo/context", "mojo/lib/flags", "mojo/url", "mojo/utils", "mojo/utils/date", "mojo/url", "mojo/api/brand-assets", "./DeleteDialog", "./BrandDeleteDialog", "mojo/utils/I18nTranslation"], function (declare, lang, array, request, topic, Deferred, Stateful, find, identityFunction, context, flags, url, utils, dateUtils, mUrl, brandAssetsApi, DeleteDialog, BrandDeleteDialog, I18nTranslation) {
  var ContentItemVersion = declare(null, {
    createdAt: null,
    contentType: null,
    width: null,
    height: null,
    dimensions: null,
    url: null,
    urls: null,
    download: null,
    size: null,
    tt_created: "Created",
    tt_dimensions: "Dimensions",
    tt_size: "Size",
    tt_createdBy: "Created by",
    tt_revisionHistory: "Revision history",
    tt_price: "Price",
    tt_sku: "SKU",
    constructor: function (properties) {
      this.createdAt = dateUtils.i18nToRelativeString(new Date(properties.created_at * 1000));
      this.createdAtDate = new Date(properties.created_at * 1000);
      this.contentType = properties.content_type || "image";
      this.sizeInBytes = properties.size;
      this.size = properties.size ? utils.formatFileSize(properties.size) : null;
      this.width = properties.width;
      this.height = properties.height;
      this.dimensions = this.width && this.height ? this.width + " × " + this.height + " px" : null;
      this.url = properties.url || "";
      this.urls = properties.urls || {};
      this.download = this.url;
      this.fileExtension = this.url.split(".").reverse()[0];

      // If the content item is a URL, the fileExtension often has query params attached to it.
      // Let's remove those.
      if (this.fileExtension.indexOf("?") !== -1) {
        this.fileExtension = this.fileExtension.split("?")[0];
      }
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        this.tt_created = I18nTranslation.translate("content_studio_revision_created");
        this.tt_dimensions = I18nTranslation.translate("content_studio_revision_dimensions");
        this.tt_size = I18nTranslation.translate("content_studio_revision_size");
        this.tt_createdBy = I18nTranslation.translate("content_studio_revision_created_by");
        this.tt_revisionHistory = I18nTranslation.translate("content_studio_revision_history");
        this.tt_price = I18nTranslation.translate("content_studio_revision_price");
        this.tt_sku = I18nTranslation.translate("content_studio_revision_sku");
      }
    },
    isImage: function () {
      return this.contentType === "image";
    },
    getDisplayUrl: function () {
      if (this.urls && "display" in this.urls) {
        return this.urls.display;
      }
      return this.url;
    },
    getCanonicalUrl: function () {
      return this.getDisplayUrl();
    },
    /**
     * Get translated display properties
     * @return {Array} Return array of properties
     */
    getDisplayProperties: function () {
      var properties = [];
      properties.push({
        label: this.tt_created,
        value: this.createdAt
      });
      if (this.dimensions) {
        properties.push({
          label: this.tt_dimensions,
          value: this.dimensions
        });
      }
      if (this.size) {
        properties.push({
          label: this.tt_size,
          value: this.size
        });
      }
      if (this.createdBy) {
        properties.push({
          label: this.tt_createdBy,
          value: this.createdBy
        });
      }
      if (this.price) {
        properties.push({
          label: this.tt_price,
          value: this.price
        });
      }
      if (this.sku) {
        properties.push({
          label: this.tt_sku,
          value: "SKU " + this.sku
        });
      }
      return properties;
    },
    getThumbnailUrl: function () {
      if (!this.isImage()) {
        return null;
      }
      var thumbnailUrl = this.urls && this.urls.thumbnail_500 ? this.urls.thumbnail_500 : this.url;
      var proxiedThumbnailUrl = mUrl.addProxy(thumbnailUrl);
      return proxiedThumbnailUrl;
    },
    getMmsVersion: function () {
      if (this.urls && "mms" in this.urls) {
        return this.urls.mms;
      }
      return null;
    },
    isMmsCompatible: function () {
      var mmsMaxFilesize = 600000;
      var size = this.sizeInBytes;
      return this.urls && "mms" in this.urls && size <= mmsMaxFilesize;
    },
    getFixedParameters: function () {
      return {};
    }
  });
  var ContentItem = declare(ContentItemVersion, {
    contentId: null,
    sourceId: null,
    name: null,
    numVersions: null,
    versions: null,
    constructor: function (properties) {
      this.contentId = properties.content_id;
      this.sourceId = properties.source;
      this.name = properties.name;
      this.numVersions = properties.num_versions;
      this.versions = array.map(properties.versions || [], function (version) {
        return new ContentItemVersion(version);
      });
    }
  });
  var FileItem = declare(ContentItem, {
    createdBy: null,
    constructor: function (properties) {
      this.createdBy = properties.created_by;
    }
  });
  var ProductItem = declare([ContentItem], {
    constructor: function (properties) {
      this.productId = properties.product_id;
      this.price = properties.price;
      this.sku = properties.sku;
      this.description = properties.description;
    }
  });
  var GiphyItem = declare([ContentItem], {
    constructor: function (properties) {
      this.rating = properties.rating;
      this.fileExtension = "gif";
    }
  });
  var InstagramItem = declare([ContentItem], {
    constructor: function (properties) {
      this.caption = properties.caption;
      this.comments_count = properties.comments_count;
      this.like_count = properties.like_count;
      // we're cheating here—Instagram technically allows PNG, but there's no way to pull the extension from the
      // URL, so we're faking the most likely solution. So that the images don't get filtered out in some image-
      // only contexts.
      this.fileExtension = "jpg";
    },
    getDisplayProperties: function () {
      return [{
        label: "Comments",
        value: this.comments_count
      }, {
        label: "Likes",
        value: this.like_count
      }];
    }
  });
  var BrandItem = declare([ContentItem], {
    createdBy: null,
    constructor: function (properties) {
      this.createdBy = properties.created_by;
      this.name = "Your Default Logo";
    },
    getCanonicalUrl: function () {
      return "*|BRAND:LOGO|*";
    },
    getFixedParameters: function () {
      return {
        width: 196,
        height: null
      };
    }
  });
  var ContentEngineItem = declare([ContentItem], {});
  var Source = declare(null, {
    constructor: function (properties) {
      this.id = properties.id;
      this.userLabel = properties.userLabel;
      this.itemClass = properties.itemClass;
      this.isHostedElsewhere = properties.isHostedElsewhere;
      this.revisionUserLabel = properties.revisionUserLabel;
      this.supportedActions = properties.supportedActions;
      this.deleteModalClass = properties.deleteModalClass;
      this.onDeleteHandler = properties.onDeleteHandler;
      this.supportsMobile = properties.supportsMobile;
      this.supportsKioskMode = properties.supportsKioskMode;
    },
    toInputOption: function () {
      return {
        value: this.id,
        label: this.userLabel
      };
    },
    /**
     * Check if this Source supports an action
     *
     * @param {string} actionId Action to check
     * @return {boolean} True iff this source supports the given action
     */
    isActionSupported: function (actionId) {
      return this.supportedActions.indexOf(actionId) >= 0;
    }
  });
  var api = {
    navCreativeAssistant: "Creative Assistant",
    navMyFiles: "My files",
    navRevision: "Revision history",
    navProducts: "Products",
    navProductVariants: "Product Variants",
    navGiphy: "Giphy",
    navInstagram: "Instagram",
    navMyLogo: "My logo",
    _convertResponseFromNative: function (resp) {
      return {
        content_id: resp.id,
        content_type: resp.type,
        created_at: new Date(resp.created_at),
        created_by: "Dev Chimpster",
        folder: resp.folder_id,
        height: resp.height,
        name: resp.name,
        num_versions: 0,
        size: resp.size,
        source: api.SOURCE_FILE,
        url: resp.full_size_url,
        urls: {
          display: resp.full_size_url,
          thumbnail: resp.thumbnail_url
        },
        versions: [],
        width: resp.width
      };
    },
    _buildContentItem: function (properties) {
      var source = this.getSource(properties.source);
      var itemClass = source ? source.itemClass : ContentItem;
      return new itemClass(properties);
    },
    _post: function (endpoint, data, responseItemTransformer) {
      var promise = request.post(url.toUrl(endpoint), {
        handleAs: "json",
        data: data
      }).then(lang.hitch(this, function (response) {
        if (lang.isArray(response)) {
          return array.map(response, function (item) {
            var transformedItem = responseItemTransformer ? responseItemTransformer(item) : item;
            return this._buildContentItem(transformedItem);
          }, this);
        }
        return this._buildContentItem(response.info);
      }), function error(err) {
        return {
          error: err.message,
          data: err.response.data
        };
      });
      return promise;
    },
    _get: function (endpoint, data) {
      return request.get(url.toUrl(endpoint), {
        handleAs: "json",
        query: data
      }).then(lang.hitch(this, function success(response) {
        if (response.items) {
          response.items = array.map(response.items, lang.hitch(this, function (item) {
            return this._buildContentItem(item);
          }));
          return response;
        }
        return this._buildContentItem(response.info);
      }), lang.hitch(this, function error(err) {
        var response = err.response;

        // If 401 from instagram, return response data
        if (response.status === 401) {
          return response.data;
        }

        // Otherwise, throw original error
        throw err;
      }));
    },
    getInfo: function (contentId, sourceId) {
      return api._get("content/get-info", {
        content_id: contentId,
        source: sourceId
      });
    },
    getAutoDesigner: function () {
      return request.get(url.toUrl("auto-designer/get-auto-designer"), {
        handleAs: "json"
      });
    },
    setAdTooltipCompletion: function () {
      return request.get(url.toUrl("auto-designer/set-ca-user-setting?key=ca-my-files-tooltip&value=true"), {
        handleAs: "json"
      });
    },
    upload: function (contentOrigin, data, name, fields, sourceContext) {
      return api._post("content/upload", {
        data: data,
        name: name,
        "fields[folder]": (fields || {}).folder,
        "fields[content_origin]": contentOrigin,
        on_conflict: "keep_both",
        context: sourceContext
      });
    },
    /**
     * Upload content by passing the url to the backend.
     *
     * @param {string} sourceId - Source to upload this file to
     * @param {string} contentOrigin The origin of the content, eg. if it came from giphy, it'd be 'giphy:abcd1234'
     * @param {string} dataUrl The URL the back-end should request, to get the data
     * @param {string} conflictStrategy - conflict strategy to use
     * @return {Promise.<ContentItem>} Newly uploaded content item
     */
    uploadFromUrl: function (sourceId, contentOrigin, dataUrl, conflictStrategy, sourceContext) {
      if (!conflictStrategy) {
        conflictStrategy = api.CONFLICT_STRATEGY_KEEP_BOTH;
      }
      return api._post("content/upload", {
        source: sourceId,
        data_url: dataUrl,
        "fields[content_origin]": contentOrigin,
        "fields[originating_url]": dataUrl,
        on_conflict: conflictStrategy,
        context: sourceContext
      });
    },
    update: function (contentOrigin, contentId, data) {
      return api._post("content/update", {
        content_id: contentId,
        data: data,
        "fields[content_origin]": contentOrigin
      });
    },
    updateFromUrl: function (contentOrigin, contentId, dataUrl, sourceContext) {
      return api._post("content/update", {
        content_id: contentId,
        data_url: dataUrl,
        "fields[content_origin]": contentOrigin,
        context: sourceContext
      });
    },
    updateOrUploadFromUrl: function (contentOrigin, contentId, dataUrl) {
      // all callers of updateOrUploadFromUrl() assume that the file is being uploaded
      // into the file source. if this assumption changes, add a sourceId argument to updateOrUploadFromUrl,
      // and remove this variable.
      var sourceId = api.SOURCE_FILE;
      return api.updateFromUrl(contentOrigin, contentId, dataUrl).then(null, function (error) {
        if (error.response.status === 404) {
          return api.uploadFromUrl(sourceId, contentOrigin, dataUrl);
        }
        throw error;
      });
    },
    "delete": function (contentId, sourceId) {
      return api._post("content/delete", {
        content_id: contentId,
        source: sourceId
      }, function (responseItem) {
        return responseItem.info;
      });
    },
    rename: function (contentId, sourceId, newName) {
      return api._post("content/update", {
        content_id: contentId,
        source: sourceId,
        "fields[name]": newName
      });
    },
    list: function (sourceId, params) {
      // Content Engine does not display a list
      if (sourceId === "content-engine") {
        return new Promise(function (resolve) {
          resolve(JSON.parse('{"status":"success","status_code":0,"pagination":{"total_count":0,"count":0,"offset":0},"items":[]}'));
        });
      }
      return api._get("content/list", lang.mixin({
        source: sourceId
      }, params));
    },
    search: function (sourceId, params) {
      return api._get("content/search", lang.mixin({
        source: sourceId
      }, params));
    },
    getSourceOptions: function (sourceId) {
      // Content Engine does  not display a list
      if (sourceId === "content-engine") {
        return new Promise(function (resolve) {
          resolve(JSON.parse('{"status":"success","source_options":{"filters": [], "sorts":[],"accounts":[]}}'));
        });
      }
      return request.get(url.toUrl("content/get-source-options", {
        source: sourceId
      }), {
        handleAs: "json"
      }).then(function (response) {
        return response;
      });
    },
    getAuthChannel: function () {
      return request.get(url.toUrl("/account/integrations/get-push-channel/"), {
        handleAs: "json"
      }).then(function (response) {
        if (response.channel) {
          return response.channel;
        }
      });
    },
    /**
     * List available sources.
     *
     * @return {Source[]} List of available Content Studio sources
     */
    getSources: function () {
      var sources = [];
      if (context.bridgeBundles !== null) {
        sources.push(new Source({
          id: api.SOURCE_CONTENT_ENGINE,
          userLabel: this.navCreativeAssistant,
          itemClass: ContentEngineItem,
          supportedActions: [],
          isHostedElsewhere: true,
          supportsMobile: true,
          supportsKioskMode: false
        }));
      }
      sources.push(new Source({
        id: api.SOURCE_FILE,
        userLabel: this.navMyFiles,
        itemClass: FileItem,
        isHostedElsewhere: false,
        revisionUserLabel: this.navRevision,
        supportedActions: [api.SOURCE_ACTION_FOLDERS, api.SOURCE_ACTION_DELETE, api.SOURCE_ACTION_EDIT, api.SOURCE_ACTION_RENAME, api.SOURCE_ACTION_DOWNLOAD, api.SOURCE_ACTION_SEARCH, api.SOURCE_ACTION_PROMOTE_TO_BRAND, api.SOURCE_ACTION_UPLOAD],
        deleteModalClass: DeleteDialog,
        onDeleteHandler: identityFunction,
        supportsMobile: true,
        supportsKioskMode: true
      }));
      sources.push(new Source({
        id: api.SOURCE_PRODUCT,
        userLabel: this.navProducts,
        itemClass: ProductItem,
        isHostedElsewhere: true,
        revisionUserLabel: this.navProductVariants,
        supportedActions: [api.SOURCE_ACTION_EDIT, api.SOURCE_ACTION_SEARCH],
        supportsMobile: true,
        supportsKioskMode: false
      }));
      sources.push(new Source({
        id: api.SOURCE_GIPHY,
        userLabel: this.navGiphy,
        itemClass: GiphyItem,
        isHostedElsewhere: true,
        supportedActions: [api.SOURCE_ACTION_SEARCH],
        supportsMobile: true,
        supportsKioskMode: false
      }));
      sources.push(new Source({
        id: api.SOURCE_INSTAGRAM,
        userLabel: this.navInstagram,
        itemClass: InstagramItem,
        isHostedElsewhere: true,
        supportedActions: [],
        supportsMobile: false,
        supportsKioskMode: false
      }));
      sources.push(new Source({
        id: api.SOURCE_BRAND,
        userLabel: this.navMyLogo,
        itemClass: BrandItem,
        isHostedElsewhere: false,
        supportedActions: [api.SOURCE_ACTION_DELETE],
        deleteModalClass: BrandDeleteDialog,
        onDeleteHandler: function (value) {
          brandAssetsApi.setBrandItem(null);
          return value;
        },
        supportsMobile: true,
        supportsKioskMode: false
      }));
      return sources;
    },
    /**
     * Find a specific source by its id.
     *
     * @param {string} sourceId Id to lookup by
     * @return {Source} Source definition
     * @throws {Error} If no source with given id
     */
    getSource: function (sourceId) {
      var source = find(this.getSources(), {
        id: sourceId
      });
      if (!source) {
        throw new Error("Cannot find source with id: '" + sourceId + "'");
      }
      return source;
    },
    readFileAsDataUrl: function (file) {
      var deferred = new Deferred();
      var fileReader = new FileReader();
      fileReader.onload = function (e) {
        deferred.resolve(e.target.result);
      };
      fileReader.readAsDataURL(file);
      return deferred.promise;
    },
    generateContentOriginFromContentItem: function (contentItem) {
      var contentOrigin = contentItem.sourceId + ":" + contentItem.contentId;
      return contentOrigin;
    },
    buildImageItemFromNativeApiCall: function (properties) {
      return new ContentItem(this._convertResponseFromNative(properties));
    },
    createMmsImage: function (content_id, data_url) {
      return api._post("content/create-mms", {
        content_id: content_id,
        data_url: data_url,
        context: "MMS"
      });
    },
    checkMmsVersionExists: function (content_id, source) {
      var deferred = new Deferred();
      api._get("content/get-info", {
        content_id: content_id,
        source: source
      }).then(function (res) {
        if (res.urls && res.urls.mms) {
          deferred.resolve(true);
        } else {
          deferred.resolve(false);
        }
      }, function (err) {
        deferred.reject(err);
      });
      return deferred.promise;
    },
    FileItem: FileItem,
    SOURCE_CONTENT_ENGINE: "content-engine",
    SOURCE_FILE: "file",
    SOURCE_GIPHY: "giphy",
    SOURCE_PRODUCT: "product",
    SOURCE_BRAND: "brand",
    SOURCE_INSTAGRAM: "instagram",
    SOURCE_ACTION_FOLDERS: "folders",
    SOURCE_ACTION_SEARCH: "search",
    SOURCE_ACTION_DELETE: "delete",
    SOURCE_ACTION_EDIT: "edit",
    SOURCE_ACTION_RENAME: "rename",
    SOURCE_ACTION_DOWNLOAD: "download",
    SOURCE_ACTION_PROMOTE_TO_BRAND: "promote_to_brand",
    SOURCE_ACTION_UPLOAD: "upload",
    CONFLICT_STRATEGY_KEEP_BOTH: "keep_both",
    CONFLICT_STRATEGY_OVERWRITE: "overwrite",
    ERROR_CODE_FILE_CONFLICT: 3
  };
  return api;
});