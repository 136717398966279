define(["dijit/Dialog", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (Dialog, xhr, url, query, Deferred) {
  var surveys = {};
  surveys.delete = function (surveyIds) {
    return xhr.post("/lists/surveys/delete", {
      "data": JSON.stringify({
        "survey_id[]": surveyIds
      })
    });
  };
  surveys.replicate = function (surveyId) {
    return xhr.post(url.toUrl("/lists/surveys/replicate-post", {
      survey_id: surveyId
    }), {
      handleAs: "json"
    }).then(function (data) {
      return {
        replicaSurveyId: data.replica_survey_id
      };
    });
  };
  surveys.getPublicUrl = function (surveyId) {
    return xhr.get(url.toUrl('lists/surveys/get-public-url', {
      survey_id: surveyId
    }), {
      handleAs: "json"
    }).then(function (data) {
      return {
        url: data.url
      };
    });
  };
  surveys.showDeletionPrompt = function (surveyIds) {
    var deferred = new Deferred();
    var dialog = new Dialog({
      "title": "Are you sure?",
      "onHide": function () {
        dialog.destroyRecursive();
      },
      "onDownloadEnd": function () {
        query(".cancel-button", dialog.domNode).on("click", function (cancelClickEvent) {
          cancelClickEvent.stopPropagation();
          cancelClickEvent.preventDefault();
          dialog.hide();
        });
        query("form", dialog.domNode).on("submit", function (formSubmitEvent) {
          formSubmitEvent.stopPropagation();
          formSubmitEvent.preventDefault();
          var deleteCount = surveyIds.length;
          surveys.delete(surveyIds).then(function () {
            dialog.hide();
            deferred.resolve();
          }, function () {
            dialog.hide();
            deferred.reject();
          });
        });
      }
    });
    dialog.setHref(url.toUrl("/lists/surveys/delete-confirm", {}));
    dialog.show();
    return deferred.promise;
  };
  return surveys;
});