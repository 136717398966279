define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "mojo/user", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, _WidgetBase, user, flags, xhr, mUrl) {
  /*
  This class is a wrapper around the embed2 ADA API.
   It coordinates ADA's state amongst any instantiations of this class.
  It also hides undesirable DOM elements that are loaded by embed2.
   Loads ADA script into the DOM. Keeps track of the current conversation i.e. "metaFields"
  by storing a parameter on the window - window.adaWorkflow. If already loaded toggle ADA's view state,
  when the metaFields change restart the API.
   It's important to call adaEmbed from the top-most window -- window.top
   */

  return declare("Ada", null, {
    constructor: function () {},
    containsAda: function () {
      return window.top.hasOwnProperty("adaEmbed");
    },
    adaLoaded: function () {
      return !!window.top.document.getElementById("ada-button-frame");
    },
    createMetaFields: function (additionalFields) {
      var userInfo = {};
      xhr.get(mUrl.toUrl("/support/get-ada-meta-user-info")).then(function (response) {
        if (response.user_info) {
          userInfo = response.user_info;
        }
      });
      return Object.assign({}, userInfo, additionalFields);
    },
    loadAda: function (metaFields) {
      var handle = "mailchimp";
      window.top.adaEmbed.start({
        handle: handle,
        adaReadyCallback: function () {
          if (window.top.document.getElementById("ada-button-frame") !== null) {
            window.top.document.getElementById("ada-button-frame").hidden = true;
          }
          window.top.adaEmbed.toggle();
          window.top.adaObj = metaFields;
        },
        toggleCallback: function (isDrawerOpen) {
          //disables Qualtrics feedback button while chat window is open
          if (window.top.QSI && window.top.QSI.API && isDrawerOpen) {
            window.top.QSI.API.unload();
          } else if (window.top.QSI && window.top.QSI.API) {
            window.top.QSI.API.load().done(window.top.QSI.API.run);
          }
          if (window.top.document.getElementById("ada-chat-frame") !== null) {
            window.top.document.getElementById("ada-chat-frame").hidden = !isDrawerOpen;
          }
        },
        metaFields: metaFields
      });
    },
    updateWorkflow: function (metaFields) {
      var nextWorkflow = metaFields.workflow;
      var nextReferral = metaFields.referral;
      var prevWorkflow = null;
      var prevReferral = null;
      if (window.top.adaObj && window.top.adaObj.workflow || window.top.adaObj.workflow === "") {
        prevWorkflow = window.top.adaObj.workflow;
      }
      if (window.top.adaObj && window.top.adaObj.referral) {
        prevReferral = window.top.adaObj.referral;
      }

      // Dojo compiler doesn't support ES6 syntax, chaining below is necessary
      if (nextWorkflow !== prevWorkflow || nextReferral !== prevReferral) {
        window.top.adaEmbed.reset({
          metaFields: metaFields
        }).then(function () {
          window.top.adaObj = metaFields;
          window.top.adaEmbed.toggle();
        });
      } else {
        window.top.adaEmbed.toggle();
      }
    },
    adaClicked: function (fields) {
      if (!this.containsAda()) {
        return;
      }
      var metaFields = this.createMetaFields(fields);
      if (!this.adaLoaded()) {
        this.loadAda(metaFields);
      } else {
        var scopedUpdateWorkflow = this.updateWorkflow;
        window.top.adaEmbed.getInfo().then(function (data) {
          if (!data.isDrawerOpen) {
            scopedUpdateWorkflow(metaFields);
          }
        }).catch(function (error) {
          // TODO (CED-1298): Determine how to handle ADA outages
          throw error;
        });
      }
    }
  });
});