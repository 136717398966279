/**
 * Module to tag containers that should only be visible if a row was selected
 * Pass a 'hideOnSelect = true' arg to reverse the behavior
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "./_Part"], function (declare, lang, domStyle, Velocity, _Part) {
  return declare([_Part], {
    animationDuration: 225,
    init: function () {
      this.target.selected.watch(lang.hitch(this, "_toggleDisplay"));
    },
    _toggleDisplay: function () {
      if (this.domNode.style.display === "none") {
        // Fade in
        if (this.canDisplay()) {
          Velocity(this.domNode, "stop");
          Velocity(this.domNode, {
            opacity: [1, [0.7, 0, 0.175, 1], 0]
          }, {
            duration: this.animationDuration,
            display: "inline"
          });
        }
      } else {
        // Fade out
        if (!this.canDisplay()) {
          Velocity(this.domNode, "stop");
          Velocity(this.domNode, {
            opacity: [0, [0.7, 0, 0.175, 1], 1]
          }, {
            duration: this.animationDuration,
            display: "none"
          });
        }
      }
    },
    canDisplay: function () {
      if (this.hideOnSelect) {
        return !this.inherited(arguments) || !this.target.hasSelection();
      } else {
        return this.inherited(arguments) && this.target.hasSelection();
      }
    }
  });
});