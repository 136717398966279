/**
 * A mixin that all segmentation inputs share to allow us to make sure they behave
 * in a consistent matter.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  return declare(null, {
    /**
     * Reference to the whole config of the segment editor
     */
    configs: null,
    /**
     * Reference to the input config that generated this input
     */
    inputConfig: null,
    /**
     * Type of the input. Auto-filled by mojo/segmentation/Creator during creation.
     */
    segmentInputType: null,
    /**
     * Provide HTML representation that's suitable for the view-mode of an input
     * @returns {string}
     */
    getDisplayHTML: function () {
      throw new Error("Segmentation inputs must provide display text");
    },
    /**
     * Return the value that this input wants to use. An array is returned in cases
     * where the AST can support multiple values.
     *
     * @returns {number|string|array}
     */
    getValue: function () {
      throw new Error("Segmentation inputs must have a value");
    },
    /**
     * Optionally return any value operators. This is only used for inputs that can
     * provide multiple values.
     *
     * @returns {number|string|array}
     */
    getValueOp: function () {
      return null;
    },
    /**
     * The "extra" value that our conditions support. Totally dependent on the input to return this
     * attribute.
     *
     * @returns {number|string}
     */
    getExtra: function () {
      return null;
    },
    /**
     * Checks for the compatibility of two inputs.
     *
     * Default implementation is to compare the input configs and if they are the same, then it's a safe
     * assumption. If a field needs to always be unique, override this and provide a custom way of determining
     * compatibility.
     *
     * @param inputConfig
     * @returns {boolean}
     */
    isCompatible: function (inputConfig) {
      return JSON.stringify(this.inputConfig) === JSON.stringify(inputConfig);
    },
    /**
     * Functions that allow the input to repopulate based on saved AST values.
     */
    setValue: function (value) {},
    setValueOp: function (valueOP) {},
    setExtra: function (extra) {}
  });
});