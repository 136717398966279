define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/registry", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, base, mUrl, registry, on, FilterMenu, FilterMessage) {
  return declare([base], {
    user_id: null,
    onReady: function () {
      var self = this;
      var filterSettings = {
        filters: [{
          label: "User ID",
          key: "user_id",
          type: "text"
        }, {
          label: "Username or company",
          key: "username",
          type: "text"
        }, {
          label: "MTA",
          key: "mta",
          type: "text"
        }, {
          label: "Status",
          key: "status",
          type: "select",
          options: {
            Y: "Can send",
            N: "Can not send"
          }
        }, {
          label: "Type",
          key: "type",
          type: "select",
          options: {
            SHEDICATED: "SHEDICATED",
            DEDICATED: "DEDICATED"
          }
        }, {
          label: "Custom DNS",
          key: "dns",
          type: "select",
          options: {
            GENERIC: "GENERIC",
            ELIGIBLE: "ELIGIBLE",
            CUSTOM: "CUSTOM",
            LEGACY: "LEGACY"
          }
        }, {
          label: "Charged for IPs?",
          key: "is_billable",
          type: "select",
          options: {
            Y: "Yes",
            N: "No"
          }
        }]
      };
      filterSettings.target = "dedicated-ip-list";
      var filter = new FilterMenu(filterSettings);
      this.$el.dedicatedIpListFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.dedicatedIpListFiltersMessage.appendChild(filterMessage.domNode);
      on(this.$el.createIpButton, "click", function () {
        self.$widget.createIpDialog.show();
      });
    },
    /**
     * sets this.user_id and opens delete modal
     * @param {string} id - user id that should be delete
     */
    deleteIp: function (id) {
      this.$el.userIdInput.value = id;
      this.$el.idInput.value = id;
      this.$widget.deleteDialog.show();
    }
  });
});