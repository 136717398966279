define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "./api", "mojo/neapolitan/editors/_EditorMixin", "dojo/text!./templates/clientHistoryEditor.html", "dojo/text!./templates/clientHistoryEditor/row.html", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, array, lang, domConstruct, domAttr, html, on, domClass, locale, api, _EditorMixin, tpl, rowTpl, flags, context, I18nTranslation) {
  var Row = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: rowTpl,
    // Boolean to denote if this row was selected to show a preview
    selected: false,
    translatedCreatedOn: "Created on ",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.EEE_BACKFILL_STRINGS_NUNI)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedCreatedOn = I18nTranslation.translate("nuni_editor_created_on");
        }.bind(this));
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      this.formattedDate.innerHTML = this._formatDate(this.preview.finished_timestamp);
      this.own(on(this.domNode, "click", lang.hitch(this, "select")), this.watch("selected", lang.hitch(this, function () {
        domClass.toggle(this.domNode, "active", this.selected);
      })));
    },
    _formatDate: function (timestamp) {
      var date = new Date(timestamp * 1000);
      return locale.format(date, {
        formatLength: "medium",
        timePattern: "h:mm a"
      });
    },
    select: function () {
      if (!this.selected) {
        this.set("selected", true);
        this.onSelected();
      }
    },
    deselect: function () {
      this.set("selected", false);
    },
    // Callback when a row is selected
    onSelected: function () {}
  });
  var Editor = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _EditorMixin], {
    sliderEditor: true,
    templateString: tpl,
    paneId: "preview",
    store: null,
    client: null,
    clientHistory: null,
    selectedRow: null,
    constructor: function () {
      this.rows = [];
    },
    postCreate: function () {
      this.fetchData().then(lang.hitch(this, function () {
        array.forEach(this.clientHistory, lang.hitch(this, function (preview) {
          var row = new Row({
            preview: preview
          });
          row.startup();
          row.placeAt(this.rowContainer);
          this.rows.push(row);
          this.own(on(row, "selected", lang.hitch(this, function () {
            this.select(row);
          })));
        }));
      }));
    },
    fetchData: function () {
      var req = this.store.campaignId ? api.getCampaignClientHistory(this.store.campaignId, this.client.id) : api.getTemplateClientHistory(this.store.templateId, this.client.id);
      req.then(lang.hitch(this, function (results) {
        this.clientHistory = results.previews;
      }));
      return req;
    },
    select: function (row) {
      this.deselect();
      this.selectedRow = row;
      this.store.previews.set(row.preview.client, row.preview);
    },
    deselect: function () {
      if (this.selectedRow) {
        this.selectedRow.deselect();
        this.selectedRow = null;
      }
    },
    onClose: function () {
      var last = this.clientHistory[0];
      this.store.previews.set(last.client, last);
      this.destroy();
    },
    _onDone: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.done();
    },
    destroy: function () {
      array.forEach(this.rows, function (row) {
        row.destroy();
      });
      this.inherited(arguments);
    }
  });

  // Reference the only editor that should be allowed in the page.
  var editor;

  /**
   * Users of the module should only be able to hide and show the current editor.
   */
  return {
    show: function (store, client) {
      if (!editor || editor._destroyed) {
        editor = new Editor({
          store: store,
          client: client
        });
      }
      editor.show();
      return editor;
    },
    hide: function () {
      if (editor && !editor._destroyed) {
        editor.done();
      }
    }
  };
});