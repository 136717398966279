define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dojo/request", "dijit/registry", "mojo/url"], function (declare, _WidgetBase, lang, domConstruct, parser, request, registry, mUrl) {
  return declare([_WidgetBase], {
    path: null,
    emailId: null,
    postCreate: function () {
      this.inherited(arguments);
      this.getView().then(lang.hitch(this, function (content) {
        domConstruct.place(content, this.domNode);
        parser.parse(this.domNode);
        this.onLoad();
      }));
    },
    // functions passed to modules using OverlayContentMixin
    onLoad: function () {},
    destroy: function () {
      widgets = registry.findWidgets(this.domNode);
      widgets.forEach(function (widget) {
        widget.destroyRecursive();
      });
      this.inherited(arguments);
    },
    getView: function () {
      return request.get(mUrl.toUrl(this.path), {
        'handleAs': "text",
        'query': {
          'emailId': this.emailId
        }
      });
    }
  });
});