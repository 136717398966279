/**
 * Simple button to manage consistency of the Create Campaign button and a single loader for IntentOverlay aka front
 * door.
 * @module mojo/widgets/campaigns/CreateButton
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/CreateButton.html", "mojo/intentOverlay", "mojo/lib/flags", "mojo/lib/logger", "mojo/context", "mojo/Translator",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, lang, on, tpl, intentOverlay, flags, logger, context, Translator, _WidgetBase, _TemplatedMixin) {
  return declare([_WidgetBase, _TemplatedMixin], {
    buttonLabel: "Create campaign",
    buttonClass: "button-wink button-wink-primary add-btn",
    buttonLocation: "",
    templateString: tpl,
    postMixInProperties: function () {
      // Override the default translation prior to rendering
      this.buttonLabel = Translator.translate("create_new_btn");

      // Allow any other potential postMixInProperties pieces through
      this.inherited(arguments);
    },
    /**
     * Instantiate the front door and attach handler
     */
    postCreate: function () {
      var isInsideIframe = window.top !== window;
      if (!isInsideIframe) {
        this.intentOverlay = intentOverlay.getIntentOverlay();
        on(this.domNode, "click", lang.hitch(this, "_showFrontDoor"));
      } else {
        on(this.domNode, "click", this._showFrontDoorInTopFrame);
      }
    },
    _showFrontDoor: function () {
      this.intentOverlay.set("createButtonLocation", this.buttonLocation);
      this.intentOverlay.router.go("/create-campaign");
    },
    // This code path ensures that we don't ever execute Front Door in
    // the iframe of the single-page app. Instead, we update the top
    // frame and let it all happen there.
    _showFrontDoorInTopFrame: function () {
      // ECS event for create without experimentation
      var customProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "lifecycle_marketing_wayfinding",
        scope_area: "product_front_door",
        screen: "/campaigns",
        object: "navigation",
        object_detail: "all_campaigns",
        action: "selected",
        ui_object: "button",
        ui_object_detail: "create_new",
        ui_action: "clicked",
        ui_access_point: "header"
      };
      logger.eventsPipeline.publish("navigation:selected", customProperties);

      // This is whack, but it works. We have to trigger a hashchange
      // proper in the top frame in order for the hashchange to be picked
      // up by the intent overlay. Updating the hash locally (e.g. in
      // this frame) bubbles to the top parent, but it is updated via
      // history.replaceState; this is insufficient. So we instead set
      // the top location's hash directly.
      window.top.location.hash = "#/create-campaign";
    }
  });
});