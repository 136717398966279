/**
 * Main Navigation
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_AttachMixin"], function (declare, domClass, query, topic, _WidgetBase, _AttachMixin) {
  return declare([_WidgetBase, _AttachMixin], {
    postCreate: function () {
      // Declaring the previous position
      this._previousPosition = 0;
      window.addEventListener("scroll", this._onScroll.bind(this));
    },
    /**
     * Attach an scroll event listener for the navigation
     */
    _onScroll: function () {
      // variables
      var currentPosition = window.scrollY;
      var navNode = this.domNode.querySelector(".c-patternsMainNavigation");

      /**
       * Using requestAnimationFrame to run a specific function before the next repaint
       */
      window.requestAnimationFrame(function () {
        // When scrolling down
        if (currentPosition > this._previousPosition) {
          navNode.classList.remove("is-active", "is-at-top");
          navNode.classList.add("is-scrolling-down");
          // When at the top of the page
        } else if (currentPosition === 0) {
          navNode.classList.remove("is-active");
          navNode.classList.add("is-at-top");
          // When scrolling up
        } else {
          navNode.classList.add("is-active");
          navNode.classList.remove("is-scrolling-down");
        }

        // Tracking the previous postion after conditional statement
        this._previousPosition = currentPosition;
      });
    }
  });
});