/**
 * @class mojo.app.lists.import.Column
 *
 * Represents a column of data to import.
 *
 * @property {string} id Identifier for column, counting from zero up, as strings, eg. "3"
 * @property {string} name Human-readable identifier for column, might be from a CSV header row or the first value found, eg. "Email Address", "Alice"
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var Column = declare(null, {
    constructor: function (id, name) {
      this.id = id;
      this.name = name;
    }
  });
  return Column;
});