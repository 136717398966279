define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/utils/date"], function (declare, context, flags, dateUtils) {
  var SocialPostContent = declare(null, {
    constructor: function (properties) {
      this.socialPostContentId = properties.social_posts_content_id || null;
      this.platform = properties.platform;
      this.foreignProfileId = properties.foreign_profile_id || null;
      this.message = "";
      this.imageUrls = [];
      if (properties.content) {
        this.message = properties.content.message || "";
        this.imageUrls = properties.content.image_urls || [];
      }
    }
  });
  var SocialPost = declare(null, {
    constructor: function (properties) {
      this.socialPostId = properties.social_post_id;
      this.name = properties.name;
      this.status = properties.status;
      this.content = properties.platform_content ? properties.platform_content.map(function (contentProps) {
        return new SocialPostContent(contentProps);
      }) : [];
      // dateTransform is used to get a JS date object from a
      // date string (date_str) and the timezone offset (tz_float) of that date
      // dates used for calculation comparison
      this.postedAt = properties.posted_at ? dateUtils.dateTransform(properties.posted_at) : null;
      // update for date transform - if string, return new Date, if object return new Date TZ affected.
      this.updatedAt = properties.updated_at ? dateUtils.dateTransform(properties.updated_at) : null;
      this.scheduledTime = properties.scheduled_time ? dateUtils.dateTransform(properties.scheduled_time) : null;
      // update for date transform - if string, return new Date, if object return new Date TZ affected.
    }
  });
  SocialPost.getPatchFromData = function (data) {
    if (data === null) {
      return {};
    }
    var patch = {};
    if ("name" in data) {
      patch.name = data.name;
    }
    if ("platformContent" in data) {
      patch.platform_content = data.platformContent.map(function (contentData) {
        var contentPatch = {
          platform: contentData.platform,
          "social_posts_content_id": contentData.id
        };
        if ("foreignProfileId" in contentData) {
          contentPatch.foreign_profile_id = contentData.foreignProfileId;
        }
        if ("message" in contentData || "imageUrls" in contentData) {
          contentPatch.content = {};
          contentPatch.content.message = contentData.message;
          contentPatch.content.image_urls = contentData.imageUrls;
        }
        return contentPatch;
      });
    }
    return patch;
  };
  return SocialPost;
});