define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/FilteredSearch.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, tplStr, on, lang, domAttr, topic, domClass, debounce, query, keys) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    placeholder: 'Search',
    wait: 250,
    searchTopic: null,
    onChange: function (newValue) {},
    onFocus: function (event) {},
    onBlur: function (event) {},
    onKeyDown: function (event) {},
    postCreate: function () {
      var self = this;
      this.own(on(this.filteredInput, "focus", lang.hitch(this, function (event) {
        this.onFocus(event);
      })), on(this.filteredInput, "blur", lang.hitch(this, function (event) {
        this.onBlur(event);
      })), on(this.filteredInput, "keydown", lang.hitch(this, function (event) {
        this.onKeyDown(event);
      })), on(this.filteredInput, "keyup", debounce(function (event) {
        switch (event.charCode || event.keyCode) {
          // If esc is pressed then clear search
          case keys.ESCAPE:
            self._clearSearch();
            break;

          // If tab is press do nothing
          case keys.TAB:
          case keys.DOWN_ARROW:
          case keys.UP_ARROW:
            break;

          // If enter is pressed and no value, do nothing
          case keys.ENTER:
            if (this.value.length === 0) {
              break;
            }

          // Publish topic to url passed to widget
          default:
            if (this.value.length === 0) {
              self._clearSearch();
              break;
            }
            topic.publish("search/" + self.searchTopic, this.value);
            domClass.remove(self.filteredClear, "hide");
            self.onChange(this.value);
        }
      }, this.wait)), on(this.filteredClear, 'click', function (event) {
        self._clearSearch();
        event.preventDefault();
      }));
    },
    setValue: function (newValue) {
      this.filteredInput.value = newValue || "";
      domClass.toggle(this.filteredClear, "hide", !newValue);
    },
    getValue: function () {
      return this.filteredInput.value;
    },
    onClear: function () {},
    _clearSearch: function () {
      domClass.add(this.filteredClear, "hide");
      this.filteredInput.value = '';
      this.onClear();
    }
  });
});