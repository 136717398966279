define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/form/Rating", "./_SegmentInputMixin", "mojo/user", "mojo/lib/flags", "mojo/lib/logger"], function (declare, domConstruct, Rating, _SegmentInputMixin, user, flags, logger) {
  var MAX_STARS = 5;
  var DEFAULT_VALUE = 3;
  return declare([Rating, _SegmentInputMixin], {
    numStars: MAX_STARS,
    postMixInProperties: function () {
      if (!this.value) {
        this.value = DEFAULT_VALUE;
      }
      this.inherited(arguments);
    },
    getDisplayHTML: function () {
      var stars = '';
      for (var i = 1; i <= MAX_STARS; i++) {
        var icon = "freddicon " + (i <= this.value ? "star-fill" : "graystar-fill");
        stars += '<span class="' + icon + '"></span>';
      }
      return stars;
    },
    getValue: function () {
      return this.value;
    },
    setValue: function (value) {
      this.set("value", value);
    },
    destroy: function () {
      if (this.domNode) {
        domConstruct.destroy(this.domNode);
        delete this.domNode;
      }
      this.inherited(arguments);
    },
    // Publish a Segment Engaged event to the Events Pipeline
    _logToEventsPipeline: function () {
      var sharedEventProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        object: "segment",
        object_detail: "create_segment",
        action: "engaged",
        ui_object: "drop_down",
        ui_object_detail: "segment_conditions",
        ui_action: "clicked",
        ui_access_point: "form_segment",
        segmentation_method: "advanced",
        condition_field: null
      };
      var contactTableProperties = Object.assign({
        scope_area: "contacts",
        screen: "lists/members"
      }, sharedEventProperties);
      var emailChecklistProperties = Object.assign({
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit"
      }, sharedEventProperties);

      // If event is from the Email Checklist page
      if (window.location.pathname.startsWith("/i/campaigns/edit")) {
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        var campaign_id = parseInt(new URLSearchParams(window.location.search).get("id"), 10);
        // Add campaign_id to email checklist properties
        emailChecklistProperties.campaign_id = campaign_id;
        logger.eventsPipeline.publish("segment:engaged", emailChecklistProperties);
        // If event is from the Contact Table page
      } else if (window.location.pathname.startsWith("/i/lists/members")) {
        logger.eventsPipeline.publish("segment:engaged", contactTableProperties);
      }
    },
    onChange: function () {
      this._logToEventsPipeline();
    }
  });
});