define(["mojo/context", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/hash", "dojo/parser", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojox/html/entities", "mojo/utils", "mojo/utils/date", "mojo/url", "mojo/user", "mojo/lib/flags",
// Widgets
"mojo/widgets/contact-profile/Actions", "mojo/widgets/contact-profile/StarRating", "mojo/widgets/FeedbackBlock", "dijit/form/Select",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./EmailMarketingEngagementHelpTip", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/profile-overview.html",
// API
"./Api"], function (context, declare, lang, domConstruct, domClass, on, hash, parser, topic, registry, htmlEntities, utils, dateUtils, mUrl, user, flags, Actions, StarRating, FeedbackBlock, Select, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, EmailMarketingEngagementHelpTip, ContactProfileEvent, overviewTplString, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: overviewTplString,
    memberID: null,
    listID: null,
    listName: null,
    memberAvatar: null,
    memberName: null,
    hasPredictedDemo: null,
    predictedGender: null,
    predictedAge: null,
    originatingSource: null,
    //HTML

    originatingTimestamp: null,
    optInTimestamp: null,
    statusBadge: null,
    //HTML

    isSubscribed: null,
    isAdminUnsubscribed: null,
    isSubscriberUnsubscribed: null,
    isPending: null,
    isTransactional: null,
    isVIP: null,
    canAddVIP: null,
    isArchived: null,
    isHardDeleted: false,
    emailMarketingEngagement: null,
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.getProfileOverview();
      this.setupListeners();
      var viewEvent = new ContactProfileEvent("audience:viewed", {
        action: "viewed",
        ui_object: "screen",
        ui_object_detail: "",
        ui_action: "viewed",
        ui_access_point: "",
        event_origin: "dojo"
      });
      viewEvent.publishEvent();
    },
    /**
     * Set up and inject all data
     */
    setupProfile: function () {
      //Hide container while we're working on it
      domClass.add(this.profileOverviewContainer, "hide");

      //Add feedback blocks
      this.handleFeedbackBlocks();

      //Add badges
      this.handleBadges();

      //Add actions dropdown
      this.createSelect();

      //Add star rating widget
      this.createStarRating();

      //POPULATE PROFILE OVERVIEW WITH DATA
      //Set avatar
      if (this.memberAvatar) {
        var avatarTag = "<img src=" + this.memberAvatar + " class='border-radius--lv1' style='object-fit:contain;'/>";
        domConstruct.place(avatarTag, this.avatarContainer, "only");
      }

      //Set name
      this.nameContainer.innerText = this.memberName;

      //Set Email Marketing Engagement Rating
      // if the contact profile rating is not New, Bounced, Transactional and is currently subscribed, display the EME rating, otherwise hide it
      if (this.emailMarketingEngagement !== "Bounced" && this.emailMarketingEngagement !== "New" && this.isTransactional === false && this.isSubscribed === true) {
        this.emailMarketingEngagementContainer.innerText = "Email Marketing Engagement: " + this.emailMarketingEngagement;
        //pass in rating to tooltip file
        var tooltipText = new EmailMarketingEngagementHelpTip({
          rating: this.emailMarketingEngagement
        });
        tooltipText.startup();
        tooltipText.placeAt(this.tooltipContainer);
      } else {
        domClass.add(this.engagementFeatureContainer, "hide");
      }

      //Set originating source and timestamp
      this.originatingSource = domConstruct.toDom(this.originatingSource);
      domConstruct.place(this.originatingSource, this.originatingSourceContainer, "only");
      if (this.originatingTimestamp && this.originatingTimestamp.date) {
        var dateString = this.originatingTimestamp.date.split("-").join("/");
        dateString = dateString.substr(0, dateString.indexOf("."));
        var dateObj = new Date(dateString);
        this.originatingTimestamp = " on " + dateUtils.getDateOnlyString(dateObj, false) + " at " + dateUtils.getTimeString(dateObj, false);
        this.originatingTimestampContainer.innerText = this.originatingTimestamp;
      }
      if (this.optInTimestamp && this.optInTimestamp.date) {
        var dateString = this.optInTimestamp.date.split("-").join("/");
        dateString = dateString.substr(0, dateString.indexOf("."));
        var dateObj = new Date(dateString);
        this.optInTimestamp = "Opted in on " + dateUtils.getDateOnlyString(dateObj, false) + " at " + dateUtils.getTimeString(dateObj, false);
        this.optInTimestampContainer.innerText = this.optInTimestamp;
      }

      //Set predicted demographics if enabled for this user
      if (this.hasPredictedDemo) {
        this.genderContainer.innerText = this.predictedGender;
        this.ageContainer.innerText = this.predictedAge;
        if (this.isArchived) {
          domClass.add(this.clearDemographicsContainer, "hide");
        } else if (this.clearDemographicsButton) {
          domClass.remove(this.clearDemographicsButton, "hide");
        }
        domClass.remove(this.predictedDemoContainer, "hide");
      } else {
        domClass.add(this.predictedDemoContainer, "hide");
      }
      //Show container now that data has been injected
      domClass.remove(this.profileOverviewContainer, "hide");
    },
    /**
     * Set up listener for actions taken on the dropdown
     */
    setupListeners: function () {
      topic.subscribe("contact-profile/action", lang.hitch(this, "getProfileOverview"));
    },
    /**
     * Handle subscriber-state cases where we need to display a feedback block
     */
    handleFeedbackBlocks: function () {
      domConstruct.empty(this.feedbackBlockContainer);
      if (this.isTransactional) {
        this.createFeedbackBlock("This person is only eligible for <a href='https://mailchimp.com/help/best-practices-for-e-commerce-subscribers/' target='_blank'>transactional emails</a>.");
      }
      if (this.isOptedOutOfOneToOneMessaging === true) {
        this.createFeedbackBlock("This contact has opted out of <a href='https://mailchimp.com/help/set-up-your-mailchimp-inbox/#Allow_contacts_to_opt_out' target='_blank'>1:1 messaging</a>.");
      }
      if (this.isHardDeleted) {
        this.createFeedbackBlock("This contact is deleted.");
      } else if (this.isArchived) {
        this.createFeedbackBlock("This contact is archived.");
      }
    },
    /**
     * Create and display a feedback block
     * @param {string} message Message to display in feedback block
     */
    createFeedbackBlock: function (message) {
      this.feedbackBlock = new FeedbackBlock({
        message: message,
        type: "warning"
      });
      this.feedbackBlock.placeAt(this.feedbackBlockContainer, "last");
    },
    /**
     * Show and hide the correct badges for the contact's state
     */
    handleBadges: function () {
      //Subscription status badge
      domConstruct.empty(this.statusBadgeContainer);
      this.statusBadge = domConstruct.toDom(this.statusBadge);
      domConstruct.place(this.statusBadge, this.statusBadgeContainer, "last");

      //VIP badge
      if (this.isVIP) {
        domClass.remove(this.vipBadge, "hide");
      } else {
        domClass.add(this.vipBadge, "hide");
      }
    },
    /**
     * Create the actions dropdown
     */
    createSelect: function () {
      domConstruct.empty(this.actionsContainer);
      var ActionsWidget = new Actions({
        "memberID": this.memberID,
        "contactID": this.contactID,
        "listID": this.listID,
        "listName": this.listName,
        "isSubscribed": this.isSubscribed,
        "isAdminUnsubscribed": this.isAdminUnsubscribed,
        "isSubscriberUnsubscribed": this.isSubscriberUnsubscribed,
        "isOptedOutOfOneToOneMessaging": this.isOptedOutOfOneToOneMessaging,
        "isPending": this.isPending,
        "isTransactional": this.isTransactional,
        "isArchived": this.isArchived,
        "isHardDeleted": this.isHardDeleted,
        "isVIP": this.isVIP,
        "canAddVIP": this.canAddVIP
      });
      ActionsWidget.placeAt(this.actionsContainer, "only");
      ActionsWidget.startup();
    },
    /**
     * Create the star rating
     */
    createStarRating: function () {
      domConstruct.empty(this.starRatingContainer);
      var StarRatingWidget = new StarRating({
        "memberID": this.memberID
      });
      StarRatingWidget.placeAt(this.starRatingContainer, "only");
      StarRatingWidget.startup();
    },
    /**
     * API call to get the profile overview information
     */
    getProfileOverview: function () {
      api.getProfileOverview(this.memberID, lang.hitch(this, "hydrate"));
    },
    /**
     * Update data and re-create view
     * @param {string} response Api response
     */
    hydrate: function (response) {
      this.memberAvatar = response.memberAvatar;
      this.memberName = response.memberName;
      this.hasPredictedDemo = response.hasPredictedDemo;
      this.predictedGender = response.predictedGender;
      this.predictedAge = response.predictedAge;
      this.originatingSource = response.originatingSource;
      this.originatingTimestamp = response.originatingTimestamp;
      this.optInTimestamp = response.optInTimestamp;
      this.statusBadge = response.statusBadge;
      this.isSubscribed = response.isSubscribed;
      this.isAdminUnsubscribed = response.isAdminUnsubscribed;
      this.isSubscriberUnsubscribed = response.isSubscriberUnsubscribed;
      this.isPending = response.isPending;
      this.isTransactional = response.isTransactional;
      this.isOptedOutOfOneToOneMessaging = response.isOptedOutOfOneToOneMessaging;
      this.isVIP = response.isVIP;
      this.canAddVIP = response.canAddVIP;
      this.isHardDeleted = response.isHardDeleted;
      this.isArchived = response.isArchived;
      this.emailMarketingEngagement = response.emailMarketingEngagement;
      this.setupProfile();
    }
  });
});