/**
 * Displaying DateTime with a Timezone from the browser
 * 
 * itemTimeStamp
 * displayFormat
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "mojo/utils/date"], function (declare, _WidgetBase, dateUtils) {
  return declare([_WidgetBase], {
    itemTimeStamp: null,
    itemDateStamp: null,
    postCreate: function () {
      if (this.itemTimeStamp) {
        // this.itemTimeStamp is formatted in PHP with DateTime::ATOM format
        var itemActivityTime = new Date(this.itemTimeStamp);
        this.domNode.innerText = dateUtils.getTimeString(itemActivityTime, true);
      }
    }
  });
});