/**
 * Carousel Item
 * @module mojo/widgets/ads/facebook/edit-content/CarouselItem.js
 * @see mojo/widgets/ads/facebook/edit-content/Carousel.js
 * @see mojo/widgets/ads/facebook/EditContentSlat.js
 *
 * The widget that's responsible for interactions associated within each carousel item. There should be next-to-no
 * cross-talk between each item. Consider Carousel.js as the container and controller.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "mojo/context", "mojo/lib/flags", "mojo/widgets/ads/facebook/edit-content/ImageBlock", "mojo/widgets/ads/facebook/edit-content/InputWithLabel",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/CarouselItem.html"], function (declare, lang, on, topic, Select, context, flags, ImageBlock, InputWithLabel, Renderable, tplStr) {
  /**
   * Base widget responsible for loading all other widgets.
   */
  return declare([Renderable], {
    templateString: tplStr,
    // Ad Unique Identifier. Used for publishing to backend.
    adId: null,
    // Which item in the carousel is this under? Necessary for updating backend.
    index: null,
    // Content passed in at load
    data: null,
    // Reference to the containing carousel for better data binding.
    carousel: null,
    // There's a variation of this interface that doesn't allow editing of the URL.
    lockUrl: false,
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      this._initImageBlock();
      this._initInputs();
    },
    /**
     * Run this at widget creation time, making sure that we have all of the data we need.
     */
    postMixInProperties: function () {
      this._validateProps();
    },
    /**
     * Create the image block that allows folks to change the image of this carousel item.
     *
     * @private
     */
    _initImageBlock: function () {
      this.imageInput = new ImageBlock({
        adId: this.adId,
        index: this.index,
        imageUrl: this.data.imageUrl ? this.data.imageUrl : "",
        imageClass: this._hasSiblings() ? "v-fbAttachment--carousel" : "v-fbAttachment--default"
      });
      this.imageInput.placeAt(this.imageBlockContainer);
      this.imageInput.startup();

      // On change tell the Carousel to update
      this.imageInput.watch("imageUrl", this._notifyCarouselOfUpdate.bind(this));
    },
    /**
     * There are several different inputs within this interface. This method configures them and places them
     * in the appropriate dom nodes.
     *
     * @private
     */
    _initInputs: function () {
      var linkConfig = {
        type: "text",
        label: "Link to",
        name: "link_url",
        content: {
          value: this.data.linkUrl,
          placeholder: "Website URL"
        }
      };
      var titleConfig = {
        type: "text",
        label: "Title",
        name: "title",
        lengthLimit: 30,
        content: {
          value: this.data.name,
          placeholder: "Add a headline",
          helpText: "Appears in Facebook and Instagram carousel ads."
        }
      };
      var buttonConfig = {
        name: "this.name",
        id: this.id + "_input",
        "class": "full-width",
        maxHeight: -1,
        value: this.data.callToAction ? this.data.callToAction : "",
        options: [{
          label: "Choose one",
          value: ""
        }, {
          label: "Shop now",
          value: "SHOP_NOW"
        }, {
          label: "Book now",
          value: "BOOK_TRAVEL"
        }, {
          label: "Learn more",
          value: "LEARN_MORE"
        }, {
          label: "Get showtimes",
          value: "GET_SHOWTIMES"
        }, {
          label: "Sign up",
          value: "SIGN_UP"
        }, {
          label: "Download",
          value: "DOWNLOAD"
        }, {
          label: "Contact us",
          value: "CONTACT_US"
        }, {
          label: "Apply now",
          value: "APPLY_NOW"
        }, {
          label: "Donate now",
          value: "DONATE_NOW"
        }, {
          label: "Request time",
          value: "REQUEST_TIME"
        }, {
          label: "See menu",
          value: "SEE_MENU"
        }, {
          label: "Listen now",
          value: "LISTEN_NOW"
        }]
      };
      var descriptionConfig = {
        type: "textarea",
        label: "Description",
        name: "description",
        required: false,
        lengthLimit: 150,
        content: {
          value: this.data.description,
          placeholder: "Briefly describe your ad",
          helpText: "Appears only in Facebook ads."
        }
      };

      // Create and place new widgets
      this.titleInput = new InputWithLabel(titleConfig, this.titleContainer);
      this.descriptionInput = new InputWithLabel(descriptionConfig, this.descriptionContainer);
      this.ctaInput = new Select(buttonConfig).placeAt(this.buttonContainer);
      this.linkInput = this.lockUrl ? null : new InputWithLabel(linkConfig, this.linkContainer);

      // Watch ctaInput select, if change show save bar in UI
      on(this.ctaInput, "change", lang.hitch(this, "_compareCtaValues"));
    },
    /**
     * Public method to retrieve data about this individual carousel item. Note: This intentionally does
     * not include the image. That gets submitted at a separate endpoint.
     *
     * @returns {{link_url: *, title: *, cta: *, description: *}} - The contents of the carousel item.
     */
    getFormData: function () {
      return {
        image_url: this.imageInput.getValue(),
        title: this.titleInput.getValue(),
        cta: this.ctaInput.getValue(),
        description: this.descriptionInput.getValue(),
        link_url: this.linkInput ? this.linkInput.getValue() : this.data.linkUrl
      };
    },
    /**
     * One of the few times CarouselItem needs to know about any other items is for showing the appropriate
     * styling for the image block. Call out to Carousel since it's the keeper of state.
     *
     * @returns {boolean} Do I have any sisters or bros?
     * @private
     */
    _hasSiblings: function () {
      return this.carousel.items.length > 1;
    },
    /**
     * This instance needs to be able to tell it's parent that something has changed. This creates a method in
     * which to do so.
     *
     * @private
     */
    _notifyCarouselOfUpdate: function () {
      this.carousel.updateItemData( /*itemIndex*/this.index, /*resetButtons*/true);

      // Non gif/still images are editable in which a user can create a carousel add. Enable/Disable the add button in
      // the carousel on image update or change. Returns a boolean value
      var enableAddButton = this.imageInput.isMediaEditable(this.imageInput.imageUrl);
      this.carousel.toggleAddButtonState(enableAddButton);
      topic.publish("content/carouselItem/imageSaved");
    },
    /**
     * We make some assumptions about passed in properties. Make sure we are shooting errors if those assumptions
     * aren't met.
     *
     * @private
     */
    _validateProps: function () {
      if (this.adId === null) {
        throw new Error("Ad Unique ID undeclared. It is required.");
      }
      if (this.index === null) {
        throw new Error("A Carousel Item index was not found. It is required.");
      }
      if (this.carousel === null) {
        throw new Error("Reference to parent not found.");
      }
      if (this.lockUrl === true && this.data.linkUrl === "") {
        throw new Error("linkUrl was requested to be locked but no link was passed in.");
      }
    },
    /**
     * Check to see if the cta value in the form matches the value from the data
     *
     * @private
     */
    _compareCtaValues: function () {
      if (this.ctaInput.getValue() !== this.data.callToAction) {
        topic.publish("content/carouselItem/inputChanged", {
          hasChanged: true
        });
      }
    }
  });
});