define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url"], function (declare, base, xhr, utils, mUrl) {
  return declare([base], {
    onReady: function () {},
    /**
     * Trigger the dialog that presents options to change plan update date
     *
     * @param {number} id - the id of an plan update
     * @param {string} date - the date that the update should be changed to
     */
    changeUpdateDate: function (id, date) {
      this.$el.planUpdateId.value = id;
      this.$el.updateDate.value = date;
      this.$widget.planUpdateModifyWarningArea.show();
    },
    cancelDomainRenewal: function (id) {
      if (confirm("Are you sure you want to cancel this renewal?")) {
        var url = mUrl.toUrl("/peaches2/users/billing/plan-update-modify", {
          user_id: this.pageJs.userAttrs.userId,
          plan_update_id: id,
          cancel_renewal: 'cancel',
          _s: 'plan-update-modify'
        });
        console.log(url);
        xhr(url, {
          handleAs: "json"
        }).then(function (response) {
          if (response.status === "success") {
            utils.globalPublish("show-toast", [{
              "message": "Successfully cancelled renewal!",
              "type": "success"
            }]);
            location.reload();
          } else {
            utils.globalPublish("show-toast", [{
              "message": "An error occurred while trying to cancel your renewal: " + response.exception,
              "type": "error"
            }]);
          }
        });
      }
    },
    unCancelDomainRenewal: function (id) {
      if (confirm("Are you sure you want to uncancel this renewal?")) {
        var url = mUrl.toUrl("/peaches2/users/billing/plan-update-modify", {
          user_id: this.pageJs.userAttrs.userId,
          plan_update_id: id,
          cancel_renewal: 'uncancel',
          _s: 'plan-update-modify'
        });
        console.log(url);
        xhr(url, {
          handleAs: "json"
        }).then(function (response) {
          if (response.status === "success") {
            utils.globalPublish("show-toast", [{
              "message": "Successfully uncancelled renewal!",
              "type": "success"
            }]);
            location.reload();
          } else {
            utils.globalPublish("show-toast", [{
              "message": "An error occurred while trying to uncancel your renewal: " + response.exception,
              "type": "error"
            }]);
          }
        });
      }
    }
  });
});