define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dijit/form/FilteringSelect", "dijit/form/RadioButton", "dojo/text!./templates/FilteringSelectUser.html"], function (declare, _base, lang, domAttr, Memory, FilteringSelect, RadioButton, resultUserTpl) {
  return declare([_base], {
    onReady: function () {
      var self = this;

      // Creates a dojo FilteringSelect widget that searches for names and displays label style
      var userSearchInput = new FilteringSelect({
        autoComplete: false,
        queryExpr: "*${0}*",
        // name attribute is needed for select to submit any values
        name: "login_id",
        searchAttr: "fullname",
        labelAttr: "label",
        labelType: "html",
        placeHolder: "Search by name",
        invalidMessage: "No user found.",
        required: true,
        onChange: function () {
          if (this.value === "") {
            domAttr.set(self.$el.submitBtn, "disabled", true);
          } else {
            domAttr.remove(self.$el.submitBtn, "disabled");
          }
        }
      }, self.$el.userSearch);
      userSearchInput.startup();

      // Attributes used to power the FilteringSelect UI
      var users = this.userList.map(function (user) {
        return {
          "user": user,
          // id attribute is needed for onChange event to fire
          "id": user.login_id,
          "login_id": user.login_id,
          "fullname": user.fname + " " + user.lname,
          "label": lang.replace(resultUserTpl, user)
        };
      });

      // Set the options in the select to the users
      userSearchInput.store = new Memory({
        data: users
      });
    }
  });
});