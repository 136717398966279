define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dijit/Dialog", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!mojo/widgets/campaigns/templates/CampaignPauseConfirmDialog.html", "mojo/authz", "mojo/url", "mojo/utils"], function (dom, domAttr, query, domForm, request, Dialog, Deferred, pauseDialogTemplate, Authz, mUrl, utils) {
  var handleCancelBtn = function (dialog) {
    //Close dialog on cancel button
    query(".cancel-button", dialog.domNode).on("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      dialog.hide();
    });
  };
  return {
    confirmUnpublishPage: function (uniqueId, pageName) {
      var promise = new Deferred();
      var dialog = new Dialog({
        title: "Are you sure?",
        "onHide": function () {
          dialog.destroyRecursive();
        },
        "onDownloadEnd": function () {
          handleCancelBtn(dialog);
          query("form", dialog.domNode).on("submit", function (e) {
            e.stopPropagation();
            e.preventDefault();
            request.post(domAttr.get(this, "action"), {
              data: domForm.toObject(this),
              handleAs: "json"
            }).then(function (result) {
              dialog.hide();
              switch (result.status) {
                case "success":
                  promise.resolve(result);
                  utils.toast('"' + pageName + '" was successfully unpublished');
                  break;
                case "error":
                  promise.reject(result);
                  break;
                default:
                  promise.reject("Unknown status `" + result.status + "`. " + (result.error_msg || ""));
                  break;
              }
            }, function () {
              dialog.hide();
              promise.reject();
            });
          });
        }
      });
      dialog.setHref(mUrl.toUrl("landing-pages/confirm-unpublish-page", {
        id: uniqueId
      }));
      dialog.show();
      return promise;
    },
    confirmCancel: function (uniqueId) {
      var promise = new Deferred();
      var dialog = new Dialog({
        title: "End your campaign?",
        onHide: function () {
          dialog.destroyRecursive();
        },
        onDownloadEnd: function () {
          handleCancelBtn(dialog);
          query("form", dialog.domNode).on("submit", function (e) {
            e.stopPropagation();
            e.preventDefault();
            request.post(domAttr.get(this, "action"), {
              data: domForm.toObject(this),
              handleAs: "json"
            }).then(function (result) {
              dialog.hide();
              switch (result.status) {
                case "success":
                  promise.resolve(result);
                  break;
                case "error":
                  promise.reject(result);
                  break;
                default:
                  promise.reject("Unknown status `" + result.status + "`. " + (result.error_msg || ""));
                  break;
              }
            }, function () {
              dialog.hide();
              promise.reject();
            });
          });
        }
      });
      dialog.setHref(mUrl.toUrl("ads/confirm-cancel", {
        id: uniqueId
      }));
      dialog.show();
      return promise;
    },
    confirmExtension: function (ad_unique_id, budget, end_time) {
      var promise = new Deferred();
      var dialog = new Dialog({
        title: "Are you sure?",
        onHide: function () {
          dialog.destroyRecursive();
        },
        onDownloadEnd: function () {
          handleCancelBtn(dialog);
          query("form", dialog.domNode).on("submit", function (e) {
            e.stopPropagation();
            e.preventDefault();
            request.post(domAttr.get(this, "action"), {
              data: domForm.toObject(this),
              handleAs: "json"
            }).then(function (result) {
              dialog.hide();
              switch (result.status) {
                case "success":
                  promise.resolve(result);
                  break;
                case "error":
                  promise.reject(result);
                  break;
                default:
                  promise.reject("Unknown status `" + result.status + "`. " + (result.error_msg || ""));
                  break;
              }
            }, function () {
              dialog.hide();
              promise.reject();
            });
          });
        }
      });
      dialog.setHref(mUrl.toUrl("ads/confirm-budget-extension", {
        id: ad_unique_id,
        budget: budget,
        end_time: end_time
      }));
      dialog.show();
      return promise;
    },
    confirmDeleteAd: function (uniqueId, index) {
      var promise = new Deferred();
      var dialog = new Dialog({
        "title": "Are you sure?",
        "onHide": function () {
          dialog.destroyRecursive();
        },
        "onDownloadEnd": function () {
          handleCancelBtn(dialog);
          query("form", dialog.domNode).on("submit", function (e) {
            e.stopPropagation();
            e.preventDefault();
            request.post(domAttr.get(this, "action"), {
              data: domForm.toObject(this),
              handleAs: "json"
            }).then(function (result) {
              dialog.hide();
              switch (result.status) {
                case "success":
                  promise.resolve(result);
                  break;
                case "error":
                  promise.reject(result);
                  break;
                default:
                  promise.reject("Unknown status `" + result.status + "`. " + (result.error_msg || ""));
                  break;
              }
            }, function () {
              dialog.hide();
              promise.reject();
            });
          });
        }
      });
      dialog.setHref(mUrl.toUrl("ads/google/confirm-delete-ad", {
        id: uniqueId,
        index: index
      }));
      dialog.show();
      return promise;
    },
    pauseAdWithConfirmDialogOnUpgrade: function (uniqueId) {
      var promise = new Deferred();
      var isGoogleAd = uniqueId.startsWith("02"); // Google Ads have an id prefix of 02

      var auth = new Authz();
      var canUseGoogleRemarketing = auth.userHasFeature("ad_google_remarketing");
      if (isGoogleAd && !canUseGoogleRemarketing) {
        //Create confirm dialog
        var dialog = new Dialog({
          "title": "Pause your campaign?",
          "content": pauseDialogTemplate,
          onHide: function () {
            dialog.destroyRecursive();
          }
        });
        var form = dialog.domNode.querySelector("form");
        form.addEventListener("submit", function (e) {
          e.preventDefault();
          this.pauseAd(uniqueId).then(function (result) {
            dialog.onHide();
            promise.resolve(result);
          });
        }.bind(this));
        var cancelButton = dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']");
        cancelButton.addEventListener("click", function () {
          dialog.onHide();
          promise.reject(null);
        });
        dialog.show();
      } else {
        this.pauseAd(uniqueId).then(function (result) {
          promise.resolve(result);
        });
      }
      return promise;
    },
    pauseAd: function (uniqueId) {
      var promise = new Deferred();
      request.post(mUrl.toUrl("/ads/pause", {
        id: uniqueId
      }), {
        handleAs: "json"
      }).then(function (result) {
        switch (result.status) {
          case "success":
            promise.resolve(result);
            break;
          case "error":
            promise.reject(result);
            break;
          default:
            promise.reject("Unknown status " + result.status);
            break;
        }
      });
      return promise;
    },
    unpauseAd: function (uniqueId) {
      var promise = new Deferred();
      request.post(mUrl.toUrl("/ads/unpause", {
        id: uniqueId
      }), {
        handleAs: "json"
      }).then(function (result) {
        switch (result.status) {
          case "success":
            promise.resolve(result);
            break;
          case "error":
            promise.reject(result);
            break;
          default:
            promise.reject("Unknown status " + result.status);
            break;
        }
      });
      return promise;
    }
  };
});