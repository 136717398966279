define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/peaches"], function (declare, base, domAttr, on, mUrl, peaches) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      self.loadAbuseChart();
      on(this.$el.growthSelect, "change", function () {
        self.changeList();
      });
    },
    loadAbuseChart: function () {
      var self = this;
      new peaches.AbusePerformanceOverviewChart("campaign-history", mUrl.toUrl("/peaches2/users/campaign-abuse-history-json-data", {
        user_id: self.pageJs.userAttrs.userId
      }));
    },
    changeList: function () {
      var selectedOption = this.$el.growthSelect.value;
      var self = this;
      if (selectedOption === "All Lists") {
        self.loadAbuseChart();
      }
      var url = mUrl.toUrl("/peaches2/users/campaign-abuse-history-json-data", {
        user_id: self.pageJs.userAttrs.userId,
        list_id: selectedOption
      });
      new peaches.AbusePerformanceOverviewChart("campaign-history", url);
    }
  });
});