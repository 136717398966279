define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/utils", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/logger", "mojo/validation/email", "mojo/validation/localPart", "dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dom, Deferred, lang, declare, on, query, context, utils, base, xhr, mUrl, logger, validateEmail, validateLocalPart, kernel) {
  return declare([base], {
    onReady: function () {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "oneclick"
        });
      }

      /**
      * SMU-107: Prevent submission on form errors.
      */
      this._submitSignupForm();

      /**
      * Track username and email input errors and
      * marketing newsletter opt-in checkbox.
      */
      if (this.$el.signupSubmitBtn) {
        if (this.marketing_newsletter || this.email_error || this.username_error || this.password_error) {
          this._trackInputErrors(this);
        }
      }

      /**
      * If username input error exists, call _clearUsernameHelperTextFocus()
      * with argument of error message
      */
      if (this.username_error) {
        if (this.isMostlyRemoveUsernameExperiment) {
          // Show hidden username if error
          this._showUsername();
        }
      }
      if (this.$el.usernameInput) {
        utils.attachClearValidationOnUpdate(this.$el.usernameInput);
      }
      this._addLinkToDuplicateUsernameError();
      this._attachSignupInputChange();
      this._attachUsernameValidationOnBlur();
      this._attachEmailValidation();
    },
    _sendGoogleAnalyticsEvent: function (category, action, label, customParams) {
      if (!customParams) {
        // initializing custome params to empty object
        customParams = {};
      }
      var $client_id_field = document.getElementById("client_id");
      if ($client_id_field && $client_id_field.value) {
        // check if the client id exists, pass it as custom param.
        customParams["dimension63"] = $client_id_field.value;
      }
      window.ga("send", "event", category, action, label, customParams);
    },
    /**
    * Validate form input fields before submission.
    * SMU-107: Prevent submission on form errors.
    *
    * @param {object} event DOM event.
    */
    _submitSignupForm: function () {
      var self = this;
      on(dom.byId("oneclick-form"), "submit", function (event) {
        // Initially prevent submission
        event.preventDefault();
        utils.toggleButtonLoadingState(self.$el.signupSubmitBtn, "Please Wait...", "button-loading");
        var fullValidation = function () {
          // Validate fields
          var emptyFields = query("#new_email")[0].value.length;
          var isValid = self._validateForm();
          var invalidForm = !isValid || emptyFields && emptyFields.length > 0;
          if (invalidForm) {
            self._handleInvalidForm();
            self._handleInvalidFormAnalytics(self);
            utils.toggleButtonLoadingState(self.$el.signupSubmitBtn);
          } else {
            self._sendGoogleAnalyticsEvent("send", "event", "oneclick signup", "submit");
            document.getElementById("oneclick-form").submit();
            // Submit after validation.
          }
          if (context.flagIsOn("smu.validate_username_recaptcha_v3_throttling")) {
            if (kernel.global && kernel.global.logLoginDomainReCaptchaV3Token && typeof kernel.global.logLoginDomainReCaptchaV3Token === "function") {
              kernel.global.logLoginDomainReCaptchaV3Token("signup_clicked");
            }
          }
          if (self.callRecaptchaProgrammatically && self.isRecaptchaOnSignup && !invalidForm) {
            // recaptcha callback will handle submit
            event.preventDefault();
            try {
              window.grecaptcha.execute(this.recaptchaSiteKey);
            } catch (e) {
              // send to bugsnag and submit. if grecaptch.execute() fails the request will likely fail
              // server-side recaptcha validation, but this will reload the page for the user.
              window.bugsnagClient.notify(e);
              document.getElementById("oneclick-form").submit();
            }
          }
        };
        self._usernameApiCall().then(fullValidation);
      });
    },
    /**
    * Validate form inputs and returns
    * false if input errors exist. Otherwise true.
    * SMU-107: Prevent submission on form errors. Scroll cursor
    * to first field with errors upon unsuccessful submission.
    *
    * @returns {boolean} true if form is valid. False if not.
    */
    _validateForm: function () {
      var successClass = ".success-check";
      return query(successClass).length === 1;
    },
    _invalidFormFocus: function (promise) {
      return function () {
        // Place cursor on erroneous input
        var invalidIds = "#new_email.invalid";
        var invalidInput = query(invalidIds)[0];
        if (invalidInput.type !== "email") {
          var inputFieldEnd = invalidInput.value.length;
          invalidInput.setSelectionRange(inputFieldEnd, inputFieldEnd);
        }
        invalidInput.focus();
        promise.resolve();
      };
    },
    /**
    * Creates promise that resolves once username input has been validated
    * Ensures first input with error is attributed cursor focus.
    * SMU-107: Prevent submission on form errors. Scroll cursor
    * to first field with errors upon unsuccessful submission.
    * SMU-602: Ensure show-username validation is run on if user tries to
    * submit the form without blurring the email field
    */
    _handleInvalidForm: function () {
      var self = this;
      var promise = new Deferred();
      var callback = lang.hitch(self, self._invalidFormFocus(promise));
      self._usernameApiCall().then(callback);
    },
    /**
    * Loop through nodes with errors and return error messages
    * for Google Analytics tracking.
    * SMU-107: Fire Sign Up click events in GA.
    *
    * @param {object} event object of field input error values.
    */
    _handleInvalidFormAnalytics: function (event) {
      // Create object of all inputs and their error messages
      var invalidInputs = query("input.invalid:not(.av-hidden)");
      var allInputErrors = {};
      invalidInputs.forEach(function (node) {
        var invalidInputId = node.id;
        var invalidInput = dom.byId(invalidInputId);
        var errorMessage = query(invalidInput).siblings(".invalid-error");
        allInputErrors[invalidInputId] = errorMessage[0] && errorMessage[0].innerHTML;
      });
      var emailError = allInputErrors.email ? allInputErrors.email : null;
      var usernameError = allInputErrors.new_username ? allInputErrors.new_username.split(".")[0] : null;
      var passwordError = allInputErrors.new_password ? true : null;
      // Parse object and send GA each error ID and error message
      this._sendGoogleAnalyticsEvent("Welcome", "oneclick", "submit; email_error: " + emailError + "; username_error: " + usernameError + "; password_error: " + passwordError);
    },
    /**
    * Attach an event listener to remove error on username
    * input when helper text displays
    * SMU-103: Remove error on usernameInput focus when
    * helper text displays
    */
    _clearUsernameHelperTextFocus: function () {
      utils.showHideElemByClass("field-help", false);
      on(this.$el.usernameInput, "keydown", function () {
        utils.showHideElemByClass("field-help", true);
        utils.showHideElemByClass("invalid-error", false);
      });
    },
    /**
     * Generate and display an error message to prompt the user to login or create a new username
     *
     * @param {string} prefix the error message prefix
     */
    _addShowUsernameError: function (prefix) {
      var emailInput = this.$el.emailInput;
      var errorMessage = prefix + " :login or :create.";
      var loginLink = document.createElement("a");
      loginLink.textContent = "Log in";
      loginLink.href = this._makeLoginLink(emailInput.value);
      loginLink.id = "log_in_link";
      var createLink = document.createElement("a");
      createLink.textContent = "create a new username";
      createLink.id = "create_new_username_link";
      errorMessage = errorMessage.replace(":login", loginLink.outerHTML);
      errorMessage = errorMessage.replace(":create", createLink.outerHTML);
      utils.addValidationError(emailInput, errorMessage);
      document.getElementById(createLink.id).addEventListener("click", function (event) {
        this._showCheckmark(emailInput, true);
        utils.removeValidationErrors(emailInput);
        this._showAndFocusUsername(true);
        this._sendGoogleAnalyticsEvent("sign_up", "click", createLink.textContent);
      }.bind(this));
      document.getElementById(loginLink.id).addEventListener("click", function (event) {
        event.preventDefault();

        // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#hitCallback
        var done = false;
        function followLink() {
          if (done) {
            return;
          }
          done = true;
          document.location = loginLink.href;
        }
        this._sendGoogleAnalyticsEvent("sign_up", "click", loginLink.textContent, {
          "hitCallback": followLink
        });
        setTimeout(followLink, 2000);
      }.bind(this));
    },
    /**
     * Call /signup/show-username API
     *
     * @param {object} params - API request params
     * @returns {promise} - a thenable promise
     */
    _callShowUsernameApi: function (params) {
      var emailInput = this.$el.emailInput;
      var usernameInput = this.$el.usernameInput;

      // re-initialize username before making request
      usernameInput.value = "";
      return xhr.post(mUrl.toUrl("/signup/show-username"), {
        handleAs: "json",
        data: params
      }).then(function (result) {
        // JIC username was revealed before this request completed
        if (!utils.isElemHidden("username-input-container")) {
          this._showCheckmark(emailInput, true);
          return;
        }
        if (result.showUsername) {
          var errorMessage = "This email is already in use.";
          this._addShowUsernameError(errorMessage);
          this._sendGoogleAnalyticsEvent("sign_up", "instant_error", errorMessage);
        } else {
          // Treat as an email validation success.
          this._showCheckmark(emailInput, true);
          if (result.showUsername === false) {
            usernameInput.value = emailInput.value;
            this._validateUsername();
          } else {
            // throttled response
            this._showAndFocusUsername();
          }
        }
      }.bind(this), function (error) {
        this._showCheckmark(emailInput, true);
        usernameInput.value = emailInput.value;
        console.error("Show username API request error", error);
      }.bind(this));
    },
    /**
     * Attach an event listener to track marketing newsletter
     * username, email and password errors in Google Analytics.
     * SMU-60: GA tracking to accurately track errors on page refresh
     *
     * @param {object} event object of field input error values.
     */
    _trackInputErrors: function (event) {
      this._sendGoogleAnalyticsEvent("Welcome", "getstarted", "submit; opt_out: " + event.marketing_newsletter + "; email_error: " + event.email_error + "; username_error: " + event.username_error + "; password_error: " + event.password_error);
    },
    _showCheckmark: function (elem, show) {
      if (show) {
        query(elem).addClass("success-check");
      } else {
        query(elem).removeClass("success-check");
      }
    },
    _showUsername: function () {
      utils.showHideElemByClass("username-input-container", true);
    },
    _showAndFocusUsername: function (isClick) {
      this._showUsername();
      this.$el.usernameInput.focus();
      logger.infoByType("show_username", {
        isClick: isClick || false
      });
    },
    /**
     * Attach blur event listener to the username field to make validate username API call
     *
     * SMU-61: Call validate username API on focus out.
     */
    _attachUsernameValidationOnBlur: function () {
      on(this.$el.usernameInput, "blur", function () {
        this._validateUsername();
      }.bind(this));
    },
    _attachEmailValidation: function () {
      var shouldFireKeydownEvent = true;
      var emailInput = this.$el.usernameInput;

      // Invoke removeValidationErrors function only once on keydown.
      on(emailInput, "keydown", function () {
        if (!shouldFireKeydownEvent) {
          return;
        }
        shouldFireKeydownEvent = false;
        utils.removeValidationErrors(emailInput);
      });
    },
    _makeLoginLink: function (username) {
      return "/?username=" + encodeURIComponent(username);
    },
    /**
     * Add link to redirect user to login page on duplicate username error.
     */
    _addLinkToDuplicateUsernameError: function () {
      var usernameInput = this.$el.usernameInput;
      if (usernameInput) {
        var targetMsg = "Great minds think alike - someone already has this username.";
        var elem = query(usernameInput).siblings(".invalid-error:contains('" + targetMsg + "')");
        if (elem && elem.length > 0) {
          var elemA = document.createTextNode(" If it\'s you, ");
          var elemB = document.createElement("a");
          elemB.href = this._makeLoginLink(usernameInput.value);
          elemB.textContent = "log in";
          var elemC = document.createTextNode(".");
          elem[0].appendChild(elemA);
          elem[0].appendChild(elemB);
          elem[0].appendChild(elemC);
        }
      }
    },
    /**
    * Make an API call to validate username.
    */
    _validateUsername: function () {
      var self = this;
      var usernameInput = self.$el.usernameInput;
      var validEmail = self._validateEmail();
      if (!validEmail || !usernameInput) {
        return;
      }
      // Clear existing validation errors
      utils.removeValidationErrors(usernameInput);
      self._showCheckmark(usernameInput, false);

      // Username GET request
      self._usernameApiCall();
    },
    _usernameApiCall: function () {
      var self = this;
      var usernameInput = self.$el.usernameInput;

      // skip API call if the field is blank
      if (self.isMostlyRemoveUsernameExperiment && usernameInput.value === "") {
        return new Deferred();
      }
      function _callAPI(params) {
        return xhr.post(mUrl.toUrl("/signup/validate-username", params), {
          handleAs: "json"
        }).then(function (result) {
          if (!result || result.success || !result.data.error_message) {
            self._showCheckmark(usernameInput, true);
            return;
          }
          self._clearUsernameHelperTextFocus();

          // Log error messages to GA
          var gaLabel = result.data.error_message + " (SigSci Code: " + result.data.sigsci_code + ").";
          self._sendGoogleAnalyticsEvent("sign_up", "instant_error", gaLabel);

          // If error is of type validation, show it to user
          if (result.data.sigsci_code === "5") {
            utils.addValidationError(usernameInput, result.data.error_message);
            self._addLinkToDuplicateUsernameError();
            self._showCheckmark(usernameInput, false);
            if (self.isMostlyRemoveUsernameExperiment && utils.isElemHidden("username-input-container")) {
              self._showAndFocusUsername();
            }
          } else {
            self._showCheckmark(usernameInput, true);
          }
        }, function (error) {
          self._showCheckmark(usernameInput, true);
          console.error("Username validate API request error", error);
        });
      }
      if (context.flagIsOn("smu.validate_username_recaptcha_v3_throttling") && kernel.global && kernel.global.getLoginDomainReCaptchaV3Token && typeof kernel.global.getLoginDomainReCaptchaV3Token === "function") {
        kernel.global.getLoginDomainReCaptchaV3Token("validate_username").then(function (recaptcha_result) {
          var recaptcha_token = null;
          var response_context = null;
          if (recaptcha_result) {
            recaptcha_token = recaptcha_result.token;
            response_context = JSON.stringify(recaptcha_result.context);
          }
          return _callAPI({
            username: usernameInput.value,
            recaptcha_token: recaptcha_token,
            response_context: response_context
          });
        });
      } else {
        return _callAPI({
          username: usernameInput.value
        });
      }
    },
    /**
    * Email validation
    *
    * @returns {boolean} - true if email is valid, otherwise false
    */
    _validateEmail: function () {
      var emailInput = this.$el.usernameInput;
      if (!emailInput.value) {
        this._showCheckmark(emailInput, false);
        return false;
      }
      return this._getEmailValidationResponse();
    },
    _getEmailValidationResponse: function () {
      var emailInput = this.$el.usernameInput;
      var emailError = validateEmail(emailInput.value) || validateLocalPart(emailInput.value);
      if (emailError) {
        var errorMessage = emailError.message;

        // Log error messages to GA
        this._sendGoogleAnalyticsEvent("sign_up", "instant_error", errorMessage);
        utils.addValidationError(emailInput, errorMessage);
        this._showCheckmark(emailInput, false);
        return false;
      }
      this._showCheckmark(emailInput, true);
      return true;
    },
    /**
     * Capture input changes in Google Analytics
     */
    _attachSignupInputChange: function () {
      var self = this;
      // fields changes for signup inputs tracked in GA
      var inputs = {
        emailInput: "usernameInput"
      };
      Object.keys(inputs).forEach(function (input) {
        if (this.$el[input]) {
          on(this.$el[input], "change", function () {
            self._sendGoogleAnalyticsEvent("sign up", "field input", inputs[input]);
          });
        }
      }.bind(this));
    }
  });
});