define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var Report = declare([], {
    constructor: function (postcard, props) {
      this.postcard = postcard;
      this.orderStatus = props.order_status === null ? null : {
        key: props.order_status.key,
        validKeys: props.order_status.valid_keys,
        description: props.order_status.description
      };
      this.audienceDescription = props.audience_description;
      this.statusDescription = this.orderStatus !== null ? this.orderStatus.description : this.postcard.status;
      this.sendCounts = {
        total: props.total_postcards,
        domestic: props.total_domestic_postcards,
        international: props.total_international_postcards
      };
      this.attribution = {
        promoCodeUsageCount: props.attribution.promo_code_usage_count,
        promoCodeUsageRevenue: props.attribution.promo_code_usage_revenue,
        promoCodeUsageNewCustomers: props.attribution.promo_code_new_customers
      };
      this.recipientLocationToCountMap = props.recipient_location_counts;
      this.spend = {
        total: props.spend.total,
        currencyCode: props.spend.currency_code
      };
    }
  });
  return Report;
});