define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "dijit/registry", "mojo/utils", "mojo/lib/logger", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/widgets/FeedbackBlock", "dojo/window", "mojo/widgets/ada/Ada", "mojo/utils/I18nTranslation", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (dojo, declare, base, query, on, domAttr, domClass, dijit, registry, utils, logger, xhr, mUrl, FeedbackBlock, win, Ada, I18nTranslation, EcsEvents, EcsTracking) {
  return declare([base], {
    /**
     *  Boolean value to identify if customer is "high value" or not, passed from AccountController
     */
    isHighValueCustomer: false,
    /**
     *  Ada user metafields object
     */
    adaUserMetafields: {},
    /**
     *  Boolean value on whether user can pause their account
     */
    isAbleToPause: false,
    onReady: function () {
      this.attachEventHandlers();
      EcsTracking.track(EcsEvents.CloseSurveyPageViewedEvent);
      I18nTranslation.initialize();
    },
    /**
    * Check to see if checkbox element has an associated label.
    */
    _getLabel: function (el) {
      // Does the input have an id and if so is there a label associated with that id?
      var label;
      if (el.id) {
        label = query("label[for='" + el.id + "']");
        if (label && label.length > 0) {
          return label;
        }
      }
      // Is input wrapped in label?
      if (el) {
        label = query(el).closest("label");
        if (label && label.length > 0) {
          return label;
        }
      }
      return null;
    },
    /**
     * Ensure user has inputted value in confirm password field
     * before enabled delete account button
     */
    validatePassword: function () {
      if (this.$el.passwordInput.value !== "") {
        domClass.remove(this.$el.passwordInput, "invalid");
        return true;
      }
      domClass.add(this.$el.passwordInput, "invalid");
      return false;
    },
    /**
     * Show the Confirm deletion dialog
     */
    showConfirmationDialog: function () {
      var dialog = registry.byId("confirm-delete-dialog");
      dialog.refocus = false;
      dialog.show();
      EcsTracking.track(EcsEvents.CloseSurveyViewDeleteConfirmationModalEvent);
    },
    _sendECSCancelEvent: function () {
      EcsTracking.track(EcsEvents.CloseSurveyCancelButtonClickedEvent);
    },
    /**
     * Show the Confirm deletion dialog
     */
    hideConfirmationDialog: function () {
      var dialog = registry.byId("confirm-delete-dialog");
      dialog.hide();
      this.confirmationPrompted = false;
    },
    hidePlatformQuestion: function () {
      domClass.add(this.$el.platformQuestion, "hide");
      domClass.add(this.$el.otherReasonInput, "hide");
      this.$el.otherReasonInput.value = "";
      this.$el.platformQuestionInput.value = "";
    },
    // Add/Remove selected class to input's label
    toggleInputSelect: function (el) {
      var label = this._getLabel(el);
      if (label) {
        if (el.checked) {
          label.addClass("selected");
        } else {
          label.removeClass("selected");
        }
      }
    },
    /**
     * Validate survey, then prompt user for confirmation
     */
    submitSurvey: function () {
      var url = "/account/verify-password-for-close-survey";
      var closeSurveyForm = this.$el.closeSurvey;
      var formData = new FormData(closeSurveyForm);
      EcsTracking.track(EcsEvents.CloseSurveyPermanentlyDeleteAccountButtonClickedEvent);
      xhr.post(mUrl.toUrl(url), {
        "data": formData,
        "handleAs": "json"
      }).then(function (resp) {
        if (resp.status === "success") {
          this.showConfirmationDialog();
          this.confirmationPrompted = true;
          return;
        }
        // if not success, show error
        this.onSubmitError(resp.error);
      }.bind(this), function (err) {
        // log to catch any FE errors
        logger.info("close-confirmed-action", "caught exception when verifying password in close-confirmation form: ", {
          error: err.toString()
        });
      });
      return;
    },
    attachEventHandlers: function () {
      on(query("[name='whyClose[]']"), "change", function (e) {
        //Show/hide platform question
        var hidePlatform = false;
        query("#whyClose_3, #whyClose_4").forEach(function (node) {
          if (node.checked) {
            hidePlatform = true;
          }
        });
        if (hidePlatform) {
          this.hidePlatformQuestion();
        } else {
          domClass.remove(this.$el.platformQuestion, "hide");
        }

        // Disable checkboxes when 3 or more are checked
        var checked = query("[name='whyClose[]']:checked");
        if (checked.length >= 3) {
          query("[name='whyClose[]']:not(:checked)").forEach(function (node) {
            domAttr.set(node, "disabled", true);
            domClass.add(node.parentNode, "dijitCheckBoxDisabled");
          });
        } else {
          query("[name='whyClose[]']").forEach(function (node) {
            domAttr.set(node, "disabled", false);
            domClass.remove(node.parentNode, "dijitCheckBoxDisabled");
          });
        }

        // toggle labels' selected class
        this.toggleInputSelect(e.target);
      }.bind(this));
      on(this.$el.deleteButton, "click", this.submitSurvey.bind(this));
      on(this.$el.cancelButton, "click", this._sendECSCancelEvent.bind(this));
      if (this.$el.chatButton) {
        on(this.$el.chatButton, "click", this._handleHelpbotButtonClick.bind(this));
      }

      // Validate confirm password input on change.
      on(this.$el.passwordInput, "change", this.validatePassword.bind(this));
      on(this.$el.passwordInput, "blur", this.validatePassword.bind(this));

      // delete account confirmation
      on(this.$el.deleteAccountConfirmation, "click", this._performCloseAction.bind(this));

      // submitting survey via keyboard
      on(this.$el.closeSurvey, "submit", this._performCloseAction.bind(this));
      if (this.isAbleToPause) {
        // invoke Ada chatbox
        on(this.$el.helpbotButton, "click", this._handleHelpbotButtonClick.bind(this));
      }
    },
    /**
     * Send an event to Google Analytics
     * and submit the close-survey
     * (which subsequently closes account and redirects to confirmation)
     */
    _performCloseAction: function (e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!this.confirmationPrompted) {
        this.submitSurvey();
        return;
      }
      var action = "delete";
      if (dijit.byId("validate-" + action).validate()) {
        try {
          this.submitCloseSurvey();
          // log after form submit execution
          logger.info("close-confirmed-action", "successfully executed form submit on front end", {});
        } catch (err) {
          // log to catch any FE errors
          logger.info("close-confirmed-action", "caught exception when submitting close-confirmation form: ", {
            error: err.toString()
          });
          this.onSubmitError();
        }
      }
    },
    submitCloseSurvey: function () {
      var url = "/account/submit-close-survey-and-close-account";
      var closeSurveyForm = this.$el.closeSurvey;
      window.ga("send", "event", window.location.pathname, "deleteaccount", "delete");
      EcsTracking.track(EcsEvents.CloseSurveyDeleteYourAccountButtonClickedEvent);

      // set button to loading state
      var submitButton = this.$el.deleteAccountConfirmation;
      var loadingText = "Deleting...";
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        loadingText = I18nTranslation.translate("account_close_survey_deleting");
      }
      utils.toggleButtonLoadingState(submitButton, loadingText, "button-loading disabled");
      domAttr.set(submitButton, "disabled", true);

      // submit form
      var formData = new FormData(closeSurveyForm);
      xhr.post(mUrl.toUrl(url), {
        "data": formData,
        "handleAs": "json"
      }).then(function (resp) {
        if (resp.status === "success") {
          this.onCloseSuccess(resp);
          return;
        }
        // if not success, show error
        this.onSubmitError(resp.error);
      }.bind(this), function (err) {
        // log to catch any FE errors
        logger.info("close-confirmed-action", "caught exception when submitting close-confirmation form: ", {
          error: err.toString()
        });
        this.onSubmitError();
      }.bind(this));
    },
    /**
     * Pass account info and redirect to close confirmation page
     *
     * @param {Object} resp - the response object
     */
    onCloseSuccess: function (resp) {
      if (!resp) {
        this.onSubmitError();
      }
      var accountName = encodeURIComponent(resp.accountName);
      var id = encodeURIComponent(resp.id);
      var memberSince = encodeURIComponent(resp.memberSince);
      var closureDate = encodeURIComponent(resp.closureDate);
      var isPaidUser = encodeURIComponent(resp.isPaidUser);
      var queryParams = accountName + "&id=" + id + "&memberSince=" + memberSince + "&closureDate=" + closureDate + "&isPaidUser=" + isPaidUser;
      window.top.location.href = "/close-account-confirmation?accountName=" + queryParams;
    },
    /**
     * Clear button loading status and show error.
     * @param {string} error - error code showing reported problem
     */
    onSubmitError: function (error) {
      var submitButton = this.$el.deleteAccountConfirmation;
      domAttr.set(submitButton, "disabled", false);
      var passwdNotCorrectText = "Sorry, that password isn't right. Please try again.";
      var contactAdminText = "Sorry, an error occured.  Please contact support or try again";
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        passwdNotCorrectText = I18nTranslation.translate("account_close_survey_passwd_not_correct");
        contactAdminText = I18nTranslation.translate("account_close_survey_contact_support");
      }
      this.hideConfirmationDialog();
      var message = error === "password" ? passwdNotCorrectText : contactAdminText;
      var block = new FeedbackBlock({
        message: message,
        type: "error"
      });
      if (this.$el.feedbackAnchor) {
        dojo.empty(this.$el.feedbackAnchor);
        block.placeAt(this.$el.feedbackAnchor, "first");
        win.scrollIntoView(block.domNode);
      } else {
        console.warn("Expected element for feedback block anchor, but none exists");
      }
    },
    /**
     * Start Ada chatbot
     */
    _handleHelpbotButtonClick: function () {
      var oicuEventDetails = {
        detail: {
          entryPoint: "pause-cancel-main-banner",
          experience: "mcContactUs"
        }
      };
      var launchOICUEvent = new CustomEvent("launch_oicu", oicuEventDetails);
      top.window.dispatchEvent(launchOICUEvent);

      // track helpbot button click event
      EcsTracking.track(EcsEvents.CloseSurveyConnectWithUsButtonClickedEvent);
    }
  });
});