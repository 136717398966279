/**
 * Custom module to support velocity with dojo/NodeList
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (lang, query, Velocity) {
  lang.extend(query.NodeList, {
    velocity: function () {
      var partial = lang.partial(Velocity, this);
      partial.apply(null, arguments);
      return this;
    }
  });
});