define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/registry", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/user", "mojo/lib/logger", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/ContentListItem.html"], function (declare, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, registry, query, domClass, domAttr, on, context, flags, user, logger, lang, array, Deferred, contentListItemTpl) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: contentListItemTpl,
    contentItem: null,
    contentManager: null,
    sourceId: null,
    contentId: null,
    contentType: "image",
    name: "",
    url: null,
    thumbnailUrl: null,
    numVersions: null,
    meta: "",
    formattedMeta: "",
    disabledReason: null,
    /**
     * @description handleContentSelection is passed in to handle
     * when the list item is clicked. It is used to pass the event back up to the list
     */
    handleContentSelection: null,
    postMixInProperties: function () {
      this.sourceId = this.contentItem.sourceId;
      this.contentId = this.contentItem.contentId;
      // Treat ico files like images for display purposes
      this.contentType = this.contentItem.fileExtension === "ico" ? "image" : this.contentItem.contentType;
      this.name = this.contentItem.name;
      this.url = this.contentItem.url;
      // Since ico files don't have thumbnails, use the url real instead
      this.thumbnailUrl = this.contentItem.fileExtension === "ico" ? this.contentItem.url : this.contentItem.getThumbnailUrl();
      this.numVersions = this.contentItem.numVersions;

      // For UI purposes we visually treat the parent as a version for MC files
      if (this.contentManager.fileList.sourceId === "file") {
        this.numVersions++;
      }
      this.meta = array.map(this.contentItem.getDisplayProperties(), function (property) {
        return property.value;
      }).join(" · ");
    },
    postCreate: function () {
      on(this.itemInput, "change", lang.hitch(this, "handleSelection"));
      on(this.gridImageNode, "error", lang.hitch(this, "handleError"));
      on(this.listImageNode, "error", lang.hitch(this, "handleError"));
      on(this.domNode, "dblclick", lang.hitch(this, "handleDblClick"));
      this.toggleVersionIndicator();

      /**
       * Even though 'ico' files are in the 'image_other' type, they can
       * be previewed in an img tag. Exceptions made where needed.
       */
      domClass.toggle(this.gridImageNode, "hide", this.contentType !== "image" && this.contentItem.fileExtension !== "ico");
      domClass.toggle(this.listImageNode, "hide", this.contentType !== "image" && this.contentItem.fileExtension !== "ico");
      if (this.contentManager.context === "MMS") {
        // If there is an MMS version and its not compatible, disable it.
        if (this.contentItem.getMmsVersion() !== null && !this.contentItem.isMmsCompatible()) {
          this.disabledReason = "Image size exceeds 600 KB";
        }
      }

      // Is this file disabled for some reason?
      // Perhaps it's too big, too small, or the wrong type of image.
      if (this.disabledReason) {
        domClass.add(this.domNode, "disabled");

        // disable the checkbox
        domAttr.set(this.itemInput, "disabled", "true");

        // change the cursor
        domClass.remove(this.itemLabel, "cursor--pointer");
        domClass.add(this.itemLabel, "cursor--not-allowed");

        // show the disabled reason overlay and badge
        domClass.add(this.overlay, "hide");
        domClass.remove(this.disabledOverlay, "hide");
        domClass.remove(this.disabledReasonListBadge, "hide");

        // set the reason text
        domAttr.set(this.disabledReasonGridBadge, "textContent", this.disabledReason);
        domAttr.set(this.disabledReasonListBadge, "textContent", this.disabledReason);
      }
    },
    handleDblClick: function () {
      if (!this.disabledReason) {
        this.contentManager.select(this.contentItem);
      }
    },
    // Debugging logging for image display failures https://jira.mailchimp.com/browse/GEN-1306
    handleError: function (e) {
      if (e.currentTarget.src.includes("mcusercontent")) {
        logger.info("content-studio", "My Files image display failed", {
          src: e.currentTarget.src,
          time: new Date().toString(),
          locale: navigator.language,
          log_classification: "sensitive"
        });
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4) {
            var responseDetails = {
              status: xhttp.status,
              statusText: xhttp.statusText,
              headers: xhttp.getAllResponseHeaders(),
              body: xhttp.responseText
            };
            logger.info("content-studio", "My Files failed response details: ", {
              response: responseDetails,
              log_classification: "sensitive"
            });
          }
        };
        xhttp.ontimeout = function () {
          logger.info("content-studio", "My Files image response timeout: ", {
            message: e,
            log_classification: "sensitive"
          });
        };
        xhttp.open("GET", e.currentTarget.src, true);
        xhttp.send();
      }
    },
    handleSelection: function () {
      domClass.toggle(this.gridImage, "c-fileGridItem--selected");
      if (this.handleContentSelection) {
        this.handleContentSelection();
      }
    },
    /**
     * Only show a version indicator two or more versions available.
     */
    toggleVersionIndicator: function () {
      domClass.toggle(this.gridBadge, "hide", this.numVersions < 2);
      domClass.toggle(this.listBadge, "hide", this.numVersions < 2);
    }
  });
});