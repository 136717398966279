define(["mojo/lib/statsd"], function (statsd) {
  /**
   * RUM metrics
   *
   * This module collects and sends RUM metrics to /metrics for the given page key.
   *
   * NOTE: Any changes to the key format need to be updated in
   * MC\Metrics\PageKey::ALLOWED_SUFFIXES or the metrics will be ignored.
   */

  // Rate at which to sample metrics. This is used on all pages, so the rate should
  // remain at 0.1 unless there is a compelling reason to raise it.
  var SAMPLE_RATE = 0.1;

  /**
   * Get the timings. Prefer the newer PerformanceNavigationTiming where available
   *
   * @returns {Object} key/values of metrics
   */
  function getTimings() {
    if ("getsEntriesByType" in performance) {
      var entries = performance.getEntriesByType("navigation");
      if (entries.length > 0) {
        var t = entries[0];
        return {
          dns: t.domainLookupEnd - t.domainLookupStart,
          ttfb: t.responseStart - t.startTime,
          tcp: t.connectEnd - t.connectStart,
          contentLoaded: t.domContentLoadedEventEnd - t.domContentLoadedEventStart,
          interactive: t.domInteractive,
          complete: t.domComplete
        };
      }
    }

    // Redirects from a different origin cause navigationStart to
    // be zeroed out.
    var startTime = performance.timing.navigationStart;
    if (startTime === 0) {
      startTime = performance.timing.fetchStart;
    }
    return {
      dns: performance.timing.domainLookupEnd - performance.timing.domainLookupStart,
      ttfb: performance.timing.responseStart - startTime,
      tcp: performance.timing.connectEnd - performance.timing.connectStart,
      contentLoaded: performance.timing.domContentLoadedEventEnd - performance.timing.domContentLoadedEventStart,
      interactive: performance.timing.domInteractive - performance.timing.domLoading,
      complete: performance.timing.domComplete - performance.timing.domLoading
    };
  }

  /**
   * Create the recording function for the given pageKey
   *
   * @param {string} pageKey page key of the page to be measured
   *
   * @returns {Function} the recording function
   */
  function createRecorder(pageKey) {
    return function () {
      // If for some reason the pageKey is empty, bail out early.
      // Also, some odd combinations of OS 10.9.x and Safari do not
      // have window.performance.
      if (pageKey === "" || !window.performance) {
        return;
      }

      // NOTE: `device` in these keys is a placeholder, eventually we want to segment
      // these stats by desktop, mobile, tablet, etc.
      statsd.increment(pageKey + ".rum.device.views", 1, SAMPLE_RATE);
      var timings = getTimings();
      Object.keys(timings).forEach(function (key) {
        var measurement = Math.round(timings[key]);
        if (measurement >= 0) {
          statsd.timing(pageKey + ".rum.device." + key, measurement, SAMPLE_RATE);
        }
      });
    };
  }
  return function (pageKey) {
    window.addEventListener("load", createRecorder(pageKey));
  };
});