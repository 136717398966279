/**
 * Acts as a manager of all the individual widgets that composes inbox preview.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/Destroyable", "dojo/promise/all", "mojo/Pusher", "./inbox-preview/api", "./inbox-preview/ClientPane", "./inbox-preview/ContentPane"], function (declare, lang, array, on, domClass, topic, Stateful, Destroyable, all, mPusher, api, ClientPane, ContentPane) {
  var Tokens = declare([Stateful], {
    balance: null,
    // Takes pending transactions into account
    available: null,
    channel: null,
    channelName: null,
    _connectPusher: function () {
      mPusher.load().then(lang.hitch(this, function (pusher) {
        this.channel = pusher.subscribe(this.channelName);
        this._channelCallback = lang.hitch(this, "_updateTokens");
        this.channel.bind("tokens-updated", this._channelCallback);
      }));
    },
    _updateTokens: function (data) {
      this.set("balance", data.balance);
    },
    _disconnectPusher: function () {
      if (this.channel) {
        this.channel.unbind(this._channelCallback);
        mPusher.load().then(lang.hitch(this, function (pusher) {
          pusher.unsubscribe(this.channelName);
        }));
      }
    },
    destroy: function () {
      this.inherited(arguments);
      this._disconnectPusher();
    }
  });

  /**
   * A shared store of clients and previews that have run.
   */
  var Store = declare([Stateful, Destroyable], {
    campaignId: null,
    templateId: null,
    tokens: null,
    // Uses dojo/Stateful to allow widgets to watch for changes for each individual client preview
    previews: null,
    hasPreviews: false,
    // Meta-data for litmus clients
    clients: null,
    clientLabels: {
      "web": "Web-based",
      "mobile": "Mobile",
      "client": "Desktop"
    },
    numClientsSelected: 0,
    previewsError: null,
    // Reference to the pusher channel instance
    channel: null,
    _channelCallback: null,
    channelName: null,
    constructor: function (params) {
      lang.mixin(this, params);
      this.previews = new Stateful();
      this.clients = {};
    },
    /**
     * Calls the server to update previews, clients and tokens.
     * @returns {dojo/Promise}
     */
    fetchData: function () {
      var previewsReq = this.campaignId ? api.getCampaignPreviews(this.campaignId) : api.getTemplatePreviews(this.templateId);
      var tokensReq = api.getTokens();
      var returnReq = all({
        p: previewsReq,
        t: tokensReq
      });
      returnReq.then(lang.hitch(this, function (results) {
        this.clients = results.p.clients;
        this.update(results.p.previews);
        this.channelName = results.p.pushChannel;
        this._connectPusher();
        this.tokens.balance = results.t.balance;
        this.tokens.available = results.t.balance;
        this.tokens.numFreeMonthly = results.t.numFreeMonthly;
        this.tokens.channelName = results.t.pushChannel;
        this.tokens._connectPusher();
      }));
      return returnReq;
    },
    update: function (previews) {
      for (var clientId in previews) {
        if (previews.hasOwnProperty(clientId)) {
          this.previews.set(clientId, previews[clientId]);
        }
      }
    },
    runPreview: function (clients) {
      this.tokens.set("balance", this.tokens.balance - clients.length);
      this.hasPreviews = true;
      clients.forEach(lang.hitch(this, function (clientId) {
        this.previews.set(clientId, {
          client: clientId,
          status: "queued"
        });
      }));
      var req = this.campaignId ? api.previewCampaign(this.campaignId, clients) : api.previewTemplate(this.templateId, clients);
      req.then(lang.hitch(this, function (response) {
        this.set("previewsError", response.success ? null : response.message);
        this.update(response.previews);
      }));
    },
    markAsViewed: function (preview, viewed) {
      api.updatePreview(preview.preview_id, {
        "viewed": viewed
      }).then(lang.hitch(this, function (preview) {
        this.previews.set(preview.client, preview);
      }));
    },
    markAsApproved: function (preview, approved) {
      api.updatePreview(preview.preview_id, {
        "approved": approved
      }).then(lang.hitch(this, function (preview) {
        this.previews.set(preview.client, preview);
      }));
    },
    _connectPusher: function () {
      mPusher.load().then(lang.hitch(this, function (pusher) {
        this.channel = pusher.subscribe(this.channelName);
        this._channelCallback = lang.hitch(this, "_updatePreview");
        this.channel.bind("preview-updated", this._channelCallback);
      }));
    },
    _updatePreview: function (preview) {
      this.previews.set(preview.client, preview);
    },
    _disconnectPusher: function () {
      if (this.channel) {
        this.channel.unbind(this._channelCallback);
        mPusher.load().then(lang.hitch(this, function (pusher) {
          pusher.unsubscribe(this.channelName);
        }));
      }
    },
    destroy: function () {
      this.inherited(arguments);
      this._disconnectPusher();
    }
  });
  return declare([Destroyable], {
    campaignId: null,
    templateId: null,
    contentPaneDom: null,
    clientPaneDom: null,
    // Contains a reference to where the inbox preview content is displayed
    contentPane: null,
    // Contains a reference to the panel that shows which clients have run some inbox preview
    clientPane: null,
    // List of clients that litmus support. Fetched from server
    clients: null,
    // Map of clients that have run for the given campaign or template. Fetched from server.
    previews: null,
    // Boolean to denote the visibility state of the entire inbox preview feature
    visible: false,
    // Flag to verify whether it has gathered all the information required to display from the server.
    _ready: false,
    constructor: function (params) {
      lang.mixin(this, params);
      if (!this.campaignId && !this.templateId) {
        throw new Error("Must provide a campaign or a template for inbox preview");
      }
      if (!this.contentPaneDom) {
        throw new Error("contentPaneDom must be passed to InboxPreview");
      }
      if (!this.clientPaneDom) {
        throw new Error("clientPaneDom must be passed to InboxPreview");
      }
      domClass.add(this.contentPaneDom, "hide");
      this.store = new Store({
        campaignId: this.campaignId,
        templateId: this.templateId,
        tokens: new Tokens()
      });
      this.store.fetchData().then(lang.hitch(this, function () {
        this._ready = true;
        // Don't initialize the widget if it's already destroyed after the async request
        if (!this._destroyed) {
          this._setupWidgets();
        }
      }));
      this.own(this.store);
    },
    show: function () {
      this.visible = true;
      this._toggleVisibility();
    },
    hide: function () {
      this.visible = false;
      this._toggleVisibility();
    },
    _toggleVisibility: function () {
      if (this._ready) {
        domClass.toggle(this.clientPane.domNode, "hide", !this.visible);
        domClass.toggle(this.contentPaneDom, "hide", !this.visible);
        domClass.toggle(this.clientPane.domNode, "hide", !this.visible);
      }
    },
    _setupWidgets: function () {
      this.contentPane = new ContentPane({
        store: this.store
      });
      this.contentPane.placeAt(this.contentPaneDom);
      this.own(this.contentPane);
      this.clientPane = new ClientPane({
        store: this.store,
        onClientSelected: lang.hitch(this, function (preview) {
          this.contentPane.set("selectedClient", preview.client);
        })
      });
      this.clientPane.placeAt(this.clientPaneDom);
      this.own(this.clientPane);
      this._toggleVisibility();
    }
  });
});