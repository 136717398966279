/**
 * A module that constraints a set of Sliders to a max value
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, on, lang, array) {
  return declare([], {
    sliders: [],
    maxValue: 10,
    minValue: 1,
    constructor: function (args) {
      lang.mixin(this, args);
      array.forEach(this.sliders, lang.hitch(this, "addSlider"));
    },
    addSlider: function (slider) {
      if (array.indexOf(this.sliders, slider) === -1) {
        this.sliders.push(slider);
      }
      on(slider, "change", lang.hitch(this, "_updateProgress", slider));
    },
    _updateProgress: function (slider) {
      array.forEach(this.sliders, lang.hitch(this, function (slider) {
        var potential = this._getPotential(slider);
        var total = this._getTotal();
        slider.set("potential", potential);
        slider.set("total", total);
      }));
    },
    _getTotal: function () {
      var total = 1;
      array.forEach(this.sliders, function (slider) {
        total *= slider.value;
      });
      return total;
    },
    _getPotential: function (tarSlider) {
      var maxPotential = this.maxValue;
      array.forEach(this.sliders, function (slider) {
        if (tarSlider != slider) {
          maxPotential = Math.floor(maxPotential / slider.value);
        }
      });
      return maxPotential;
    }
  });
});