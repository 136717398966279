define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, base, on, context, EcsTracking, EcsEvents) {
  return declare([base], {
    entrypoint: "",
    onReady: function () {
      if (context.flagIsOn("identity_bridge.forgot_password_ecs")) {
        this._fireResetPasswordLandedECSEvent();
        this._attachECSListeners();
      }
    },
    // fire ECS event for when user lands on login page
    _fireResetPasswordLandedECSEvent: function () {
      EcsTracking.track(EcsEvents.PasswordResetSuccessEvent, {
        entry_point: this.entrypoint
      });
    },
    // attach ECS listeners to DOM element for tracking
    _attachECSListeners: function () {
      var returnToLoginButton = this.$el.returnToLoginButton;
      var entrypoint = this.entrypoint;
      if (returnToLoginButton) {
        on(returnToLoginButton, "click", function () {
          EcsTracking.track(EcsEvents.ReturnToLoginEvent, {
            entry_point: entrypoint
          });
        });
      }
    }
  });
});