define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo/text!../editors/blocks/templates/AbandonedBrowseEditor/output.html"], function (declare, _Renderer, ButtonRenderer, Link, tpl) {
  return declare([_Renderer], {
    outputTplStr: tpl,
    buttonRenderer: null,
    constructor: function () {
      this.inherited(arguments);
      this.buttonRenderer = new ButtonRenderer();
    },
    getOutputModel: function (meta) {
      var priceEnabled = meta.priceEnabled;
      var buttonEnabled = meta.buttonEnabled;
      return {
        "priceEnabled": priceEnabled,
        "buttonEnabled": buttonEnabled,
        "buttonHtml": this._buttonMarkup(meta),
        "mergeTagOpen": "*|ABANDONED_BROWSE:|*",
        "mergeTagClose": "*|END:ABANDONED_BROWSE|*"
      };
    },
    _buttonMarkup: function (meta) {
      var mergeUrl = "*|PRODUCT:URL|*";
      if (meta.buttonLinkType && meta.buttonLinkType === "cart") {
        mergeUrl = "*|PRODUCT:CART_URL|*";
      }
      var buttonMeta = {
        "buttonText": meta.buttonText,
        "buttonWidth": meta.buttonWidth,
        "align": meta.buttonAlign,
        "link": new Link(mergeUrl, "*|PRODUCT:TITLE|*")
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    }
  });
});