define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, _WidgetBase) {
  var EDIT_MODE = "edit";
  var VIEW_MODE = "view";
  return declare([_WidgetBase], {
    value: "",
    label: null,
    // either view or edit
    mode: VIEW_MODE,
    setValue: function (value) {
      this.value = value;
    },
    setLabel: function (label) {
      this.label = label;
    },
    getLabel: function () {
      return this.label;
    },
    getValue: function () {
      return this.value;
    },
    /**
     * Wipe out the tag value and label
     */
    clear: function () {
      this.setValue("");
      this.setLabel("");
    },
    /**
     * Toggles back to the view mode. In this mode, you can delete and focus on the element.
     */
    viewMode: function () {
      this.set("mode", VIEW_MODE);
    },
    /**
     * Switches the tag to edit mode. This allows the tag to be edited through the text input.
     */
    editMode: function () {
      this.set("mode", EDIT_MODE);
    },
    inEditMode: function () {
      return this.mode === EDIT_MODE;
    },
    inViewMode: function () {
      return this.mode === VIEW_MODE;
    },
    isBlank: function () {
      return this.getValue() === "";
    },
    destroy: function () {
      this.inherited(arguments);
      this.onDestroy();
    },
    focus: function () {
      this.domNode.focus();
    },
    /**
     * Called when the enter key is hit, before the TagList processes it.
     * Return true to signify that the tag handled the enter key and doesn't need to bubble up,
     * false otherwise.
     *
     * @returns {boolean}
     */
    onEnter: function () {
      return false;
    },
    /**
     * Will be called when this tag is destroyed.
     */
    onDestroy: function () {}
  });
});