define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/context", "mojo/lib/flags", "mojo/url", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, context, flags, mUrl, p2Utils, array, domAttr, on, domClass, xhr, dom) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      this.totalCreditsInput = this.$el.totalCreditsInput;
      var previousIndex = 0;
      array.forEach(this.$group.creditTypeRadio, function (radioButton, i) {
        var currentIndex = i;
        if (!domClass.contains(self.$group.creditTypeWrap[currentIndex], "hide")) {
          previousIndex = i;
        }
        on(radioButton, "change", function () {
          self.saveCreditTypeState(radioButton.value);
          domClass.add(self.$group.creditTypeWrap[previousIndex], "hide");
          domClass.remove(self.$group.creditTypeWrap[currentIndex], "hide");
          previousIndex = currentIndex;
        });
      });
      on(this.$el.creditAccountForm, "submit", function (e) {
        e.stopPropagation();
        e.preventDefault();
        self.validateCreditAccountForm();
      });

      //credit monkeyrewards
      var currentMonkeyRewardsInput = this.$el.currentMonkeyRewardsInput;
      var monkeyRewardsToCreditInput = this.$el.monkeyRewardsToCreditInput;
      var monkeyRewardsTotalAmount = this.$el.monkeyRewardsTotalAmount;

      //Monkey rewards
      on(monkeyRewardsToCreditInput, "keyup", function () {
        self.setCreditAccount(monkeyRewardsToCreditInput.value, currentMonkeyRewardsInput.value, monkeyRewardsTotalAmount, true);
      });
      on(monkeyRewardsToCreditInput, "change", function () {
        self.setCreditAccount(monkeyRewardsToCreditInput.value, currentMonkeyRewardsInput.value, monkeyRewardsTotalAmount, true);
      });

      //credit months
      var currentMonthsInput = this.$el.currentMonthsInput;
      var monthsToCreditInput = this.$el.monthsToCreditInput;
      var creditMonthsTotalAmount = this.$el.creditMonthsTotalAmount;
      if (currentMonthsInput) {
        on(monthsToCreditInput, "keyup", function () {
          self.setCreditAccount(monthsToCreditInput.value, currentMonthsInput.value, creditMonthsTotalAmount, true);
        });

        //credit months
        on(monthsToCreditInput, "change", function () {
          self.setCreditAccount(monthsToCreditInput.value, currentMonthsInput.value, creditMonthsTotalAmount, true);
        });
      }

      //credit free mandrill monthly blocks
      var currentfreeMandrillMonthlyBlocksInput = this.$el.currentFreeMandrillMonthlyBlocksInput;
      var freeMandrillMonthlyBlocksToCreditInput = this.$el.freeMandrillMonthlyBlocksToCreditInput;
      var freeMandrillMonthlyBlocksTotalAmount = this.$el.freeMandrillMonthlyBlocksTotalAmount;

      //Mandrill Monthly blocks
      if (currentfreeMandrillMonthlyBlocksInput) {
        on(freeMandrillMonthlyBlocksToCreditInput, "keyup", function () {
          self.setCreditAccount(freeMandrillMonthlyBlocksToCreditInput.value, currentfreeMandrillMonthlyBlocksInput.value, freeMandrillMonthlyBlocksTotalAmount, true);
        });
        on(freeMandrillMonthlyBlocksToCreditInput, "change", function () {
          self.setCreditAccount(freeMandrillMonthlyBlocksToCreditInput.value, currentfreeMandrillMonthlyBlocksInput.value, freeMandrillMonthlyBlocksTotalAmount, true);
        });
      }

      //credit mandrill blocks
      var currentMandrillBlocksInput = this.$el.currentMandrillBlocksInput;
      var mandrillBlocksToCreditInput = this.$el.mandrillBlocksToCreditInput;
      var mandrillBlocksTotalAmount = this.$el.mandrillBlocksTotalAmount;
      if (mandrillBlocksToCreditInput) {
        on(mandrillBlocksToCreditInput, "change", function () {
          self.setCreditAccount(mandrillBlocksToCreditInput.value, currentMandrillBlocksInput.value, mandrillBlocksTotalAmount, true);
        });
        on(mandrillBlocksToCreditInput, "keyup", function () {
          self.setCreditAccount(mandrillBlocksToCreditInput.value, currentMandrillBlocksInput.value, mandrillBlocksTotalAmount, true);
        });
      }
      var otherCreditsToCreditInput = this.$el.otherCreditsToCreditInput;
      if (otherCreditsToCreditInput) {
        on(otherCreditsToCreditInput, "change", function () {
          self.totalCreditsInput.value = otherCreditsToCreditInput.value;
        });
        on(otherCreditsToCreditInput, "keyup", function () {
          self.totalCreditsInput.value = otherCreditsToCreditInput.value;
        });
      }

      // set up on-the-fly revenue warning
      var creditsInput = this.$el.otherCreditsToCreditInput;
      this.exchange_rate = creditsInput.getAttribute("data-exchange-rate");
      creditsInput.addEventListener("keyup", this.processCreditsChange.bind(this));
      creditsInput.addEventListener("change", this.processCreditsChange.bind(this));
      // run once at setup
      this.processCreditsChange().bind(this);
    },
    /**
     *Convert an amount to USD and round it to cents
     * @param {number} amount - the amount that should be refunded
     * @param {number} exchangeRate - exchange rate from USD to native. So to get the exchange rate _to_ USD, divide.
     */
    getAmountInUSD: function (amount, exchangeRate) {
      return (Math.ceil(amount / exchangeRate * 100) / 100).toFixed(2);
    },
    /**
     *Update the UI with the amount credited in USD on-the-fly when credit amount is edited
     *
     */
    processCreditsChange: function () {
      var amount = this.$el.otherCreditsToCreditInput.value;
      amount = amount === "" ? 0 : parseFloat(amount);
      if (typeof amount !== "number" || isNaN(amount)) {
        this.$el.otherCreditsToCreditInput.classList.add("invalid");
      } else {
        this.$el.otherCreditsToCreditInput.classList.remove("invalid");
      }
      if (this.exchange_rate && this.exchange_rate !== "") {
        amount = this.getAmountInUSD(amount, this.exchange_rate);
      }
      this.$el.normalizedCreditAmount.innerText = amount;
    },
    saveCreditTypeState: function (type) {
      xhr(mUrl.toUrl("/peaches2/users/billing/save-credit-type-state", {
        user_id: this.pageJs.userAttrs.userId,
        type: type
      }), {
        handleAs: "json"
      });
    },
    parseCurrency: function (num) {
      return num.replace(/,/g, "");
    },
    commaSeparateNumber: function (val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    },
    setCreditAccount: function (creditAmmount, currentTotal, totalTarget, sendTotal) {
      var newVal = this.parseCurrency(currentTotal);
      var total = Number(creditAmmount) + Number(newVal);
      var newValWithCommas = this.commaSeparateNumber(total.toFixed(2));
      if (sendTotal) {
        this.totalCreditsInput.value = total.toFixed(2);
      } else {
        this.totalCreditsInput.value = creditAmmount.toFixed(2);
      }
      totalTarget.innerHTML = newValWithCommas;
    },
    validateCreditAccountForm: function () {
      var totalCreditsInput = this.$el.totalCreditsInput;
      var creditAccountForm = this.$el.creditAccountForm;
      var creditReasonInput = this.$el.creditReasonInput;
      var creditExpireDate = this.$el.creditExpireDate;
      if (context.flagIsOn(flags.FINOPS_ALLOW_NEGATIVE_CREDITS)) {
        if (isNaN(totalCreditsInput.value)) {
          p2Utils.utils.setFlash("Amount must be a number.", "error");
          return false;
        }
      } else if (isNaN(totalCreditsInput.value) || Number(totalCreditsInput.value) <= 0) {
        p2Utils.utils.setFlash("Amount must be greater than zero.", "error");
        return false;
      }
      if (creditReasonInput.value.length === 0) {
        p2Utils.utils.setFlash("Dont forget to add the Reason for this credit.", "error");
        domClass.add(this.$el.creditReasonInput, "invalid");
        return false;
      }
      creditAccountForm.submit();
    }
  });
});