/**
 * Circular Loader
 * @module mojo/widgets/CircularLoader.js
 *
 * Widget wrapper for a D3 loading animation. Configurable to allow different sizes and colors.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase",
// Libs
"d3/d3"], function (declare, lang, mColors, dom, domConstruct, query, _WidgetBase, d3) {
  // The circle constant
  var _tau = 2 * Math.PI; // http://tauday.com/tau-manifesto

  return declare([_WidgetBase], {
    bgColor: null,
    color: null,
    loaderSize: 50,
    // px

    // By default the loader is started.
    animate: true,
    strokeWidth: 5,
    // px

    animDuration: 3500,
    // ms

    /**
     * Properties that are private and changed after all properties are mixed-in
     */
    isAnim: true,
    center: null,
    radius: null,
    constructor: function () {
      // Set these at creation but allow them to be overridden as part of postMixinProperties
      this.color = this._getDefaultColor();
      this.bgColor = this._getDefaultBgColor();
    },
    _getDefaultColor: function () {
      return mColors.brandColors.kale.hex;
    },
    _getDefaultBgColor: function () {
      return mColors.brandColors.peppercorn15.rgb;
    },
    postMixInProperties: function () {
      this.center = {
        x: this.loaderSize / 2,
        y: this.loaderSize / 2
      };
      this.radius = this.loaderSize / 2;
      if (this.animate === false) {
        this.isAnim = false;
      }
    },
    buildRendering: function () {
      var _self = this;
      this.inherited(arguments);

      // Create svg container
      this.svg = d3.select(this.domNode).append("svg").attr("width", this.loaderSize).attr("height", this.loaderSize).append("g").attr("transform", "translate(" + this.loaderSize / 2 + "," + this.loaderSize / 2 + ")");

      // Create arc for loader
      this.arc = d3.svg.arc().innerRadius(this.radius - this.strokeWidth).outerRadius(this.radius);

      // Create loader's background
      this.background = this.svg.append("path").datum({
        startAngle: 1e-6,
        endAngle: _tau
      }).style("fill", this.bgColor).attr("d", this.arc);

      // Create main loader path
      this.foreground = this.svg.append("path").attr("id", "path-" + _self.id).attr("clip-path", "url(#clipPath-" + _self.id + ")").datum({
        startAngle: 1e-6,
        endAngle: 1e-6
      }).style("fill", this.color).attr("d", this.arc);

      // Create clipPath mask for chaser effect
      this.foregroundMask = this.svg.append("clipPath").attr("id", "clipPath-" + _self.id);
      this.foregroundMask.append("path").datum({
        startAngle: 1e-6,
        endAngle: _tau
      }).style("fill", "#990000").attr("d", this.arc);
    },
    postCreate: function () {
      this.render();
    },
    render: function () {
      var _self = this;
      if (_self.animate) {
        _self._animateLoader();
      }
    },
    _animateLoader: function () {
      var _self = this;
      var delay1_factor, duration2_factor, delay2_factor, duration1_factor;
      delay1_factor = duration2_factor = 0.28;
      delay2_factor = duration1_factor = 0.43;
      var _arcTweenEnd = function (transition, angleEnd) {
        transition.attrTween("d", function (d) {
          var interpolateEnd = d3.interpolate(d.endAngle, angleEnd);
          return function (t) {
            d.endAngle = interpolateEnd(t);
            return _self.arc(d);
          };
        });
      };
      var _arcTweenStart = function (transition, angleStart) {
        transition.attrTween("d", function (d) {
          var interpolateStart = d3.interpolate(d.startAngle, angleStart);
          return function (t) {
            d.startAngle = interpolateStart(t);
            return _self.arc(d);
          };
        });
      };
      if (this.isAnim) {
        _self.foreground.transition().delay(delay1_factor * _self.animDuration).duration(duration1_factor * _self.animDuration).call(_arcTweenEnd, _tau).each("end", function (d, i) {
          // reset arc
          _self.foreground.transition().duration(999).call(_arcTweenEnd, 1e-6);
        });
        var maskPath = _self.foregroundMask.select("path");
        maskPath.transition().delay(delay2_factor * _self.animDuration).duration(duration2_factor * _self.animDuration).call(_arcTweenStart, _tau).each("end", function (d, i) {
          // reset arc
          maskPath.transition().duration(999).call(_arcTweenStart, 1e-6);

          // Recursive call to animate
          _self._animateLoader();
        });
      }
    },
    // Start loader animation
    start: function () {
      if (!this.isAnim) {
        this.isAnim = true;
        this._animateLoader();
      }
    },
    // Stop loader animation
    stop: function () {
      this.isAnim = false;
    },
    destroy: function () {
      if (this.svg) {
        domConstruct.destroy(this.svg[0][0].ownerSVGElement);
      }
      this.inherited(arguments);
    }
  }); // eo declare
}); // eo define