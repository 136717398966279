define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/find", "mojo/page/settings", "mojo/widgets/Renderable", "dojo/text!./templates/active-settings-view.html", "mojo/widgets/checklist-editor/items/page-settings/TrackingOptions", "mojo/widgets/checklist-editor/items/page-settings/CcpaOptions", "mojo/widgets/checklist-editor/items/page-settings/FBCcpaOptions", "dijit/focus", "mojo/widgets/checklist-editor/items/PageSettings", "mojo/user", "mojo/lib/flags"], function (declare, lang, find, pageSettings, Renderable, tpl, TrackingOptions, CcpaOptions, FBCcpaOptions, focusUtil, SettingsPage, user, flags) {
  var ActiveSettingsView = declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      if (typeof props.state === "undefined") {
        throw new TypeError("state property is required");
      }
      if (typeof props.onSave === "undefined") {
        throw new TypeError("onSave property is required");
      }
      if (typeof props.handleCancel === "undefined") {
        throw new TypeError("handleCancel property is required");
      }
    },
    onBeforeSave: function () {
      if (!this.trackingOptions.state.acceptedTerms) {
        return false;
      }
      this.trackingOptions.validateBeforeSave();
      if (this.trackingOptions.hasValidationErrors()) {
        if (focusUtil.curNode) {
          focusUtil.curNode.blur();
        }
        return false;
      }
      return true;
    },
    getData: function () {
      var trackingSettingsData = this.trackingOptions.getData();
      if (this.ccpaOptions) {
        trackingSettingsData.tracking_settings.push(this.ccpaOptions.getData());
      }
      if (this.fbccpaOptions) {
        trackingSettingsData.tracking_settings.push(this.fbccpaOptions.getData());
      }
      return trackingSettingsData;
    },
    render: function () {
      this.inherited(arguments);
      if (this.trackingOptions) {
        this.trackingOptions.destroy();
      }
      this.trackingOptions = new TrackingOptions({
        state: lang.clone(this.state.tracking)
      });
      this.trackingOptions.placeAt(this.trackingOptionsContainer);
      if (this.ccpaOptions) {
        this.ccpaOptions.destroy();
      }
      this.ccpaOptions = new CcpaOptions({
        state: {
          googleRestrictedDataProcessing: this.state.tracking.googleRestrictedDataProcessing
        }
      });
      this.ccpaOptions.placeAt(this.ccpaOptionsContainer);
      if (this.fbccpaOptions) {
        this.fbccpaOptions.destroy();
      }
      this.fbccpaOptions = new FBCcpaOptions({
        state: {
          facebookRestrictedDataProcessing: this.state.tracking.facebookRestrictedDataProcessing
        }
      });
      this.fbccpaOptions.placeAt(this.fbccpaOptionsContainer);
    }
  });
  return ActiveSettingsView;
});