define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_AttachMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _AttachMixin, on, query, array, parser, lang, dom, domClass, registry, ready) {
  var enableOnSelectWidget = declare([_WidgetBase, _AttachMixin], {
    constructor: function () {
      this._checked = [];
    },
    postCreate: function () {
      var self = this;
      ready(function () {
        var checkboxes = registry.findWidgets(self.domNode);
        if (checkboxes) {
          checkboxes.forEach(function (box, index) {
            self._checked[index] = box.checked;
            on(box, "change", function () {
              domClass.toggle(self.submitButton, "disabled", self.checkStatus(index));
            });
          });
        }
      });
    },
    // postCreate

    checkStatus: function (index) {
      var self = this;
      var checkboxes = registry.findWidgets(this.domNode);
      this._values = [];
      if (checkboxes) {
        checkboxes.forEach(function (box, ndx) {
          self._values.push(box.checked);
        });
        // Only enable button if checkbox checked values are different
        return JSON.stringify(self._values) === JSON.stringify(self._checked);
      }
    }
  });
  return enableOnSelectWidget;
});