/**
 * Icon Swatch
 * @module mojo/widgets/pattern-library/IconSwatch.js
 *
 * Widget that represents the idea of a single swatch. Handles all markup and interactions
 * associated with an individual swatch.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/IconSwatch.html"], function (declare, Renderable, tplStr) {
  /**
   * TODO: Replace Renderable with standard _WidgetBase and _TemplatedMixin. It creates an extra div node that's problematic.
   */
  return declare([Renderable], {
    templateString: tplStr,
    code: "",
    classcode: ""
  });
});