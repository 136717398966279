define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./_EditViewMixin", "dijit/focus", "dojo/text!./templates/group.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./ConditionRow", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _EditViewMixin, focusUtil, tpl, on, lang, array, domClass, ConditionRow, I18nTranslation, flags, context) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _EditViewMixin], {
    templateString: tpl,
    subGroup: false,
    configs: null,
    conditions: null,
    subscribed: "Subscribed",
    unsubscribed: "Unsubscribed",
    non_subscribed: "Non-subscribed",
    cleaned: "Cleaned",
    contacts_that_match: "contacts that match",
    of_the_following_conditions: "of the following conditions:",
    find: "Find",
    remove_all: "Remove all",
    add: "Add",
    constructor: function () {
      this.conditions = [];
    },
    postMixInProperties: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
        I18nTranslation.initialize().then(function (translations) {
          this.subscribed = translations.reporting_comparative_report_subscribed;
          this.unsubscribed = translations.reporting_comparative_report_unsubscribed;
          this.non_subscribed = translations.reporting_comparative_report_non_subscribed;
          this.cleaned = translations.reporting_comparative_report_cleaned;
          this.contacts_that_match = translations.reporting_comparative_report_contacts_that_match;
          this.of_the_following_conditions = translations.reporting_comparative_report_of_the_following_conditions;
          this.of_the_following_conditions = translations.reporting_comparative_report_find;
          this.of_the_following_conditions = translations.reporting_comparative_report_remove_all;
          this.of_the_following_conditions = translations.reporting_comparative_report_add;
        }.bind(this));
      }
    },
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function () {
          if (!I18nTranslation.isTranslationObjectEmpty()) {
            this.removeAllBtn.innerText = I18nTranslation.translate("audience_management_segment_edit_remove_all");
            this.addBtnText.innerText = I18nTranslation.translate("audience_management_segment_edit_add_button_text");
            this.find.innerText = I18nTranslation.translate("audience_management_segment_edit_find");
            this.contactsThatMatch.innerText = I18nTranslation.translate("audience_management_segment_edit_contacts_that_match");
            this.ofTheFollowingConditions.innerText = I18nTranslation.translate("audience_management_segment_edit_of_following_conditions");
          }
        }.bind(this));
      }
      this.own(on(this.addConditionBtn, "click", lang.hitch(this, "addCondition")));
      this.viewMode();
    },
    addCondition: function () {
      var row = new ConditionRow({
        "configs": this.configs,
        "field": this.configs.defaultField
      });
      row.startup();
      row.placeAt(this.conditionList);
      row.on("destroy", lang.hitch(this, function () {
        this.onConditionDelete(row);
      }));
      focusUtil.focus(row.domNode);
      this.conditions.push(row);
      this.onConditionCountChange();
      return row;
    },
    onConditionDelete: function (row) {
      this.conditions.splice(this.conditions.indexOf(row), 1);
      this.onConditionCountChange();
    },
    getAST: function () {
      var conditions = array.map(this.conditions, function (condition) {
        return condition.getAST();
      });
      return {
        "match": this.matchOpSelect.value,
        "contact_status": this.statusOpSelect.value,
        "conditions": conditions
      };
    },
    fromAST: function (ast) {
      if (ast.match) {
        this.matchOpSelect.set("value", ast.match);
      }
      if (ast.contact_status) {
        this.statusOpSelect.set("value", ast.contact_status);
      }
      array.forEach(ast.conditions, lang.hitch(this, function (condiAST) {
        // For fields that no longer exists (ex. groups), skip those conditions.
        if (this.configs.fields.hasOwnProperty(condiAST.field)) {
          var cond = this.addCondition();
          cond.fromAST(condiAST);
        }
      }));
    },
    /**
     * Callback when a condition is added or removed.
     */
    onConditionCountChange: function () {}
  });
});