define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "mojo/widgets/Folder", "mojo/lib/logger"], function (declare, lang, _PartMixin, Folder, logger) {
  return declare([Folder, _PartMixin], {
    "buttonText": "Move To",
    "canEdit": false,
    "selectable": false,
    "includeAll": false,
    "isReportsPage": false,
    onMove: function (folders, folderId) {
      if (this.source) {
        this.source.updateCounts(folders);
        if (!this.target.getFilter("folder")) {
          this.source.setSelectedFolder(folderId);
        } else {
          this.target.render();
        }
        Object.keys(this.source.target.selected).forEach(function (key) {
          if (key !== "_watchCallbacks" && this.source.target.selected[key] === true) {
            logger.googleAnalytics.trackEvent("reports", "move to folder", "comparative");
          }
        }, this);
      }
    },
    onRemoveFromFolder: function () {
      this.onSelect(0);
    },
    onSelect: function (folderId) {
      this.request("move-to-folder", {
        "id[]": this.target.getSelection(),
        "fid": folderId,
        "filter": this.isReportsPage ? "report" : "" // Check to see if on reports page and pass up filter
      }).then(lang.hitch(this, function (data) {
        this.onMove(data.folders, data.folder ? data.folder.id : 0);
      }));
    }
  });
});