define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojox/dtl", "dojox/dtl/Context", "dojo/text!../editors/blocks/templates/ImageWithCaptionEditor/output.html", "dojo/text!../editors/blocks/templates/ImageWithCaptionEditor/_horizontal.html", "dojo/text!../editors/blocks/templates/ImageWithCaptionEditor/_vertical.html"], function (declare, _Renderer, dtl, dtlContext, tpl, horizontalTpl, verticalTpl) {
  return declare("mojo.neapolitan.renderers.ImageWithCaptionBlock", [_Renderer], {
    outputTplStr: tpl,
    constructor: function () {
      this.horizontalTemplate = new dojox.dtl.Template(horizontalTpl);
      this.verticalTemplate = new dojox.dtl.Template(verticalTpl);
    },
    getOutputModel: function (meta) {
      var captions = [];
      var calculatedWidths = this._getWidths(meta);
      var numOfCaptions = Number(meta.numberOfCaptions);
      for (var i = 0; i < numOfCaptions; i++) {
        var caption = meta.captions[i];
        if (!caption) {
          caption = {
            image: null,
            text: ""
          };
        }
        if (caption.image) {
          if (caption.image && caption.image.width > calculatedWidths.image) {
            caption.image.calculatedWidth = calculatedWidths.image;
          } else {
            caption.image.calculatedWidth = caption.image.width;
          }
        }
        caption.columnWidth = calculatedWidths.columnWidth;
        captions.push(caption);
      }
      var blockHtml = this._getCaptionTemplate(meta.captionPosition).render(new dtlContext({
        widths: calculatedWidths,
        align: meta.align,
        position: meta.captionPosition,
        captions: captions,
        container: meta.container,
        innerPadding: this.innerPadding,
        outerPadding: this.outerPadding,
        firstPadding: "0 " + this.innerPadding + "px " + this.innerPadding + "px " + this.innerPadding + "px ",
        lastPadding: this.innerPadding + "px " + this.innerPadding + "px " + "0 " + this.innerPadding + "px ",
        singlePadding: "0 " + this.innerPadding + "px ",
        captionCount: numOfCaptions
      }));
      return {
        blockHtml: blockHtml
      };
    },
    _getCaptionTemplate: function (position) {
      switch (position) {
        case "left":
        case "right":
          return this.horizontalTemplate;
        case "top":
        case "bottom":
          return this.verticalTemplate;
        default:
          throw new Error("Unknown captionPosition");
      }
    },
    _getWidths: function (meta) {
      var wholeWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2);
      var imageWidth;
      var imageContainerWidth;
      var captionWidth;
      var columnWidth = false;
      var width;
      var numOfCaptions = Number(meta.numberOfCaptions);
      switch (meta.captionPosition) {
        case "top":
        case "bottom":
          if (numOfCaptions === 2) {
            width = Math.floor(wholeWidth / numOfCaptions);
            imageContainerWidth = captionWidth = width;
            imageWidth = imageContainerWidth - this.innerPadding * 2;
          } else {
            width = Math.floor((wholeWidth - this.innerPadding * 2 * (numOfCaptions - 1)) / numOfCaptions);
            imageContainerWidth = imageWidth = captionWidth = width;
          }
          break;
        case "left":
        case "right":
          switch (meta.captionWidth) {
            case "half":
              captionWidth = imageContainerWidth = imageWidth = Math.floor((wholeWidth - this.innerPadding * 4) / 2);
              break;
            case "one-third":
              captionWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 3);
              imageContainerWidth = wholeWidth - captionWidth - this.innerPadding * 4;
              imageWidth = imageContainerWidth;
              break;
            case "two-third":
              imageContainerWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 3);
              captionWidth = wholeWidth - imageContainerWidth - this.innerPadding * 4;
              imageWidth = imageContainerWidth;
              break;
            case "three-quarter":
              imageContainerWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 4);
              captionWidth = wholeWidth - imageContainerWidth - this.innerPadding * 4;
              imageWidth = imageContainerWidth;
              break;
            default:
              throw new Error("Unknown captionWidth");
          }
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return {
        caption: captionWidth,
        container: imageContainerWidth,
        image: imageWidth,
        columnWidth: columnWidth
      };
    }
  });
});