define(["dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/NoDataBlock.html", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation"], function (ready, declare, lang, domClass, domAttr, on, _WidgetBase, _TemplatedMixin, tplStr, context, flags, I18nTranslation) {
  // These correlate to real files in /css/icons/no-data/ or https://cdn-images.mailchimp.com/product/illustrations/
  // respectively. This is reflective of all of the no-data states, even if not directly using
  // this widget at the moment.

  // TODO @alehner: Remove unused assets after brand launch
  var defaultIconKey = "art-empty-default-alt";
  var iconMigrationMap = {
    /* eslint-disable key-spacing */
    "art-line-graph": "art-line-graph",
    "empty-brand": "art-empty-logo",
    // New illustration coming
    "empty-campaigns": "art-empty-campaigns",
    // Called through declarative instantiation often
    "empty-comments": "art-empty-conversations",
    "empty-contact-activity": "art-empty-activity",
    "empty-ecomm": "art-empty-products",
    "empty-facebook": "art-empty-default",
    "empty-facebook-pages": "art-empty-fb-pages",
    "empty-facebook-ron": "art-empty-fb",
    "empty-general": defaultIconKey,
    // Used heavily outside of widget context
    "empty-landing-pages-reports": "art-empty-lander",
    "empty-lists": "art-empty-lists",
    "empty-notifications": "art-empty-notifications",
    // Used outside of widget context
    "empty-payment": "art-empty-payment",
    "empty-reports": "art-empty-reports",
    "empty-templates": "art-empty-templates",
    "goose-egg": defaultIconKey,
    // Used heavily outside of widget context
    "import-from-file": defaultIconKey,
    // Used outside of widget context
    "import-from-url": defaultIconKey,
    // Used outside of widget context
    "no-analytics": defaultIconKey,
    // Used outside of widget context
    "no-image": defaultIconKey,
    // Used outside of widget context
    "no-list": "art-empty-lists",
    // Used outside of widget context
    "no-report": "art-empty-reports",
    // Used outside of widget context
    "no-tags": "art-empty-default"
    /* eslint-enable key-spacing */
  };
  var translatedMessages = function (string, jsonKey) {
    var newString = string;
    if (context.flagIsOn(flags.I18N_NUNI) && !I18nTranslation.isTranslationObjectEmpty()) {
      newString = I18nTranslation.translate(jsonKey);
      return newString;
    }
    return newString;
  };
  ready(function () {
    I18nTranslation.initialize("I18N_NUNI");
  });
  var translatedAudienceMessages = function (string, jsonKey) {
    var newString = string;
    if (context.flagIsOn(flags.I18N_IHOP_WEEK_1) && !I18nTranslation.isTranslationObjectEmpty()) {
      newString = I18nTranslation.translate(jsonKey);
      return newString;
    }
    return newString;
  };
  ready(function () {
    I18nTranslation.initialize("I18N_IHOP_WEEK_1");
  });
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    type: "",
    icon: null,
    title: null,
    message: null,
    buttonText: null,
    buttonClass: null,
    buttonLink: null,
    buttonClickHandler: null,
    buttonNewWindow: false,
    _iconPath: null,
    // Set as part of postMixinProperties. Do not override.

    // Cancel _WidgetBase's _setTitleAttr so a tooltip doesn't appear over the entire widget
    _setTitleAttr: null,
    anchorStartTag: null,
    anchorEndTag: null,
    postMixInProperties: function () {
      // Set defaults for if a custom prop on this type is not passed in
      var tDefault = this._getDefaultProps(this.type);
      // Set values in template
      this.icon = this._validateProp(this.icon, tDefault.icon);
      this.title = this._validateProp(this.title, tDefault.title);
      this.message = this._validateProp(this.message, tDefault.message);
      this.buttonText = this._validateProp(this.buttonText, tDefault.buttonText);
      this.buttonLink = this._validateProp(this.buttonLink, tDefault.buttonLink);
      this.buttonNewWindow = this._validateProp(this.buttonNewWindow, tDefault.buttonNewWindow);
      this.handleButtonClick = this._validateProp(this.handleButtonClick, tDefault.buttonNewWindow);
      this._iconPath = this._getIconImageUrl();
      if (this.hasOwnProperty("flag") && context.flagIsOn(flags[this.flag])) {
        I18nTranslation.initialize().then(function (values) {
          if (I18nTranslation.isTranslationObjectEmpty()) {
            return;
          }
          var blockProps = this._getDefaultProps();
          if (blockProps.translated) {
            if (values.hasOwnProperty(blockProps.titleTranslationKey)) {
              this.title = I18nTranslation.translate(blockProps.titleTranslationKey);
            }
            if (values.hasOwnProperty(blockProps.messageTranslationKey)) {
              if (blockProps.variableInsertion) {
                var anchorStartTag = this.anchorStartTag;
                var anchorEndTag = this.anchorEndTag;
                this.message = I18nTranslation.getTranslationString(values[blockProps.messageTranslationKey], [anchorStartTag, anchorEndTag]);
              } else {
                this.message = I18nTranslation.translate(blockProps.messageTranslationKey);
              }
            }
            if (values.hasOwnProperty(blockProps.buttonTextTranslationKey)) {
              this.buttonText = I18nTranslation.translate(blockProps.buttonTextTranslationKey);
            }
          }
        }.bind(this));
      }
    },
    postCreate: function () {
      // Hide elements if unused
      domClass.toggle(this.noDataIcon, "hide", !this.icon);
      domClass.toggle(this.noDataTitle, "hide", !this.title);
      domClass.toggle(this.noDataMessage, "hide", !this.message);

      // Buttons require both text and a link
      domClass.toggle(this.noDataButton, "hide", !this.buttonText || !this.buttonLink);
      if (this.buttonNewWindow) {
        domAttr.set(this.noDataButton, "target", "_blank");
      }
      if (this.handleButtonClick) {
        on(this.noDataButton, "click", this.handleButtonClick);
      }
    },
    /**
     * Sets the default values for whatever type was passed in
     *
     * @return {Object} Contains all default values for block type
     */
    _getDefaultProps: function () {
      var tDefault;
      switch (this.type) {
        case "automations":
          tDefault = {
            icon: "art-empty-campaigns",
            title: "You haven't sent any automations yet",
            message: "You can set up an automation to trigger for things like an <a href='https://mailchimp.com/help/create-an-abandoned-cart-email/' target='_blank'>abandoned cart</a>, a welcome email, and even to wish a subscriber happy birthday!",
            buttonText: "Set Up An Automation",
            buttonLink: "/#/create-campaign/explore/emailCampaign:custom"
          };
          break;
        case "campaigns":
          tDefault = {
            icon: "art-empty-campaigns",
            title: "You haven't sent any campaigns yet",
            message: "<a href='https://mailchimp.com/help/getting-started-with-campaigns/' target='_blank'>Campaigns</a> are emails, ads, and other messages you share with contacts in your <a href='https://mailchimp.com/help/getting-started-with-lists/' target='_blank'>list</a>.<br>Don't have any contacts yet? No problem. Design a test email campaign and send it to yourself."
          };
          break;
        case "comments":
          tDefault = {
            icon: "art-empty-conversations",
            title: "Get feedback on this campaign.",
            message: "Start by <a href='#' data-dojo-attach-point='sendTestLink'>sending a test email</a> to your collaborators.<br>When they reply their comments will appear here."
          };
          break;
        case "compliance":
          tDefault = {
            icon: "art-empty-compliance",
            title: "Everything's running smoothly!",
            message: "Keep up the good work! Check out our <a href='https://mailchimp.com/help/about-compliance-for-email-marketing/' target='_blank'>compliance tips</a> for best practices and to avoid potential sending pitfalls."
          };
          break;
        case "ecomm":
          this.anchorStartTag = "<a href='https://mailchimp.com/help/about-connected-sites/' target='_blank'>";
          this.anchorEndTag = "</a>";
          tDefault = {
            icon: "art-empty-products",
            title: "You don't have any eCommerce data yet",
            message: "Once you have a <a href='https://mailchimp.com/help/about-connected-sites/' target='_blank'>store connected</a>, you can view your data here at any time.",
            translated: true,
            variableInsertion: true,
            titleTranslationKey: "no_data_block_ecomm_title",
            messageTranslationKey: "no_data_block_ecomm_message"
          };
          break;
        case "landing-pages-reports":
          tDefault = {
            icon: "art-empty-lander",
            title: "No landing page reports yet",
            message: "Reports show useful metrics like views and clicks. Ready to start collecting data?",
            buttonText: "Create a Landing Page",
            buttonLink: "/campaigns/#/create-campaign/explore/page"
          };
          break;
        case "ad-recommendations":
          tDefault = {
            icon: "art-empty-conversations",
            title: "Nothing to see here",
            message: "Your ad's doing great so far! Here are some <a href='https://blog.mailchimp.com/creating-an-effective-facebook-ad-campaign/' target='_blank'>best practices</a> to keep the good vibes going."
          };
          break;
        case "lists":
          tDefault = {
            icon: "art-empty-lists",
            title: translatedAudienceMessages("You haven't created an audience yet", "havent_created_audience"),
            message: translatedAudienceMessages("Audiences are where your contacts (aka subscribers!) live. You'll need one of these to send campaigns to the outside world.", "audiences_are_where")
          };
          break;
        case "notifications":
          tDefault = {
            icon: "art-empty-notifications",
            title: "Nothing new to report!"
          };
          break;
        case "organize":
          tDefault = {
            icon: "art-empty-default-alt",
            title: translatedMessages("This folder is empty", "empty_folder_message_title"),
            message: translatedMessages("<a href='https://mailchimp.com/help/organize-campaigns-into-folders/' target='_blank'>Learn how to organize using folders</a>", "empty_folder_message_body")
          };
          break;
        case "payment":
          tDefault = {
            icon: "art-empty-payment",
            title: "Looks like you don't have a payment method on file",
            translated: true,
            titleTranslationKey: "no_data_block_no_payment"
          };
          break;
        case "reports":
          tDefault = {
            icon: "empty-reports",
            title: "You don't have any reports yet",
            message: "After you send your first campaign, you'll be able to see reports on how it performed, who opened it, and more!"
          };
          break;
        case "templates":
          tDefault = {
            icon: "art-empty-reports",
            title: translatedMessages("You don't have any saved templates", "saved_tab_empty_state_title"),
            message: translatedMessages("A template is a saved design that can be reused when creating email campaigns.", "saved_tab_empty_state_message")
          };
          break;
        case "purchased-templates":
          tDefault = {
            icon: "purchased-empty-min",
            message: "Need some inspiration? Explore premium email campaign templates designed by Mailchimp experts.",
            buttonText: "Browse The Marketplace",
            buttonLink: "https://mailchimp.com/marketplace/templates"
          };
          break;
        case "no-marketplace-purchased-templates":
          tDefault = {
            icon: "art-empty-default-alt",
            message: "Nothing to see here just yet. Check back soon."
          };
          break;
        case "contact-activity":
          tDefault = {
            icon: "art-empty-activity",
            title: "No activity yet",
            message: "Subscriber, e-commerce and other activity will show up here"
          };
          break;
        case "tags":
          tDefault = {
            icon: "art-empty-default",
            title: "You don't have any tags yet",
            message: "Create custom tags to help you organize and target contacts. <a href='https://mailchimp.com/help/getting-started-tags' target='_blank'>Learn about tags.</a>"
          };
          break;
        case "segments":
          this.anchorStartTag = "<a href='https://mailchimp.com/help/about-auto-update-and-static-segments/' target='_blank'>";
          this.anchorEndTag = "</a>";
          tDefault = {
            icon: "art-empty-default-alt",
            title: "You don't have any segments yet",
            message: "<a href='https://mailchimp.com/help/about-auto-update-and-static-segments/' target='_blank'>Learn about segments</a>",
            translated: true,
            variableInsertion: true,
            titleTranslationKey: "no_data_block_segments_title",
            messageTranslationKey: "no_data_block_segments_message"
          };
          break;
        case "email-domains":
          tDefault = {
            icon: "art-empty-default-alt",
            title: "You haven’t verified a domain yet",
            message: 'Before you can send campaigns with a <span class="font-style--italic">From email address</span> like you@yourdomain.com, you\'ll need to verify that you have access to an email address at that domain. <a href="https://mailchimp.com/help/verify-a-domain/" target="_blank">Learn more</a>.',
            buttonText: "Verify a Domain",
            buttonLink: "#"
          };
          break;
        case "branded-domains":
          tDefault = {
            icon: "art-empty-default-alt",
            title: "You haven’t verified a custom domain yet",
            message: 'Verify a domain to share custom landing pages. To learn more, read our <a href="https://mailchimp.com/help/landing-page-domains/" target="_blank">custom domain setup guide</a>.',
            buttonText: "Verify Custom Domain",
            buttonLink: "#"
          };
          break;
        case "chimp-chatter":
          tDefault = {
            icon: "art-empty-default",
            title: "Nothing's unpacked yet",
            message: ""
          };
          break;
        case "no-location-activity":
          tDefault = {
            icon: "art-empty-default",
            title: "No location activity yet",
            message: "",
            translated: true,
            titleTranslationKey: "no_location_activity"
          };
          break;
        case "no-links-clicked":
          tDefault = {
            icon: "art-empty-default",
            title: "No links clicked yet",
            message: "",
            translated: true,
            titleTranslationKey: "no_links_clicked"
          };
          break;
        case "no-opens":
          tDefault = {
            icon: "art-empty-default",
            title: "No opens yet",
            message: "",
            translated: true,
            titleTranslationKey: "no_opens"
          };
          break;
        case "covid-toolkit":
          tDefault = {
            icon: "art-line-graph",
            title: "COVID-19 Response Toolkit",
            message: "There are many ways to get your business online, let's find your next step.",
            buttonText: "Learn more",
            buttonLink: "https://mailchimp.com/get-your-business-online"
          };
          break;
        case "my-logo":
          {
            tDefault = {
              icon: "empty-brand",
              title: "Set a default logo",
              message: "We’ll automatically add it wherever there’s a placeholder logo in your templates.<br>You can always replace it with another image later.",
              buttonText: "Upload logo",
              translated: true,
              titleTranslationKey: "content_studio_set_default_logo",
              messageTranslationKey: "content_studio_logo_message",
              buttonTextTranslationKey: "content_studio_upload_logo_button"
            };
            break;
          }
        default:
          tDefault = {
            icon: "art-empty-default-alt",
            title: "Goose egg",
            message: "There's no information available."
          };
          break;
      }
      // TODO: Log when an invalid value is requested
      return tDefault;
    },
    /**
     * Generate the full URL to an illustration if we're only giving an icon.
     *
     * @returns {String} - Full relative path to an icon.
     * @private
     */
    _getIconImageUrl: function () {
      return lang.replace("https://cdn-images.mailchimp.com/product/brand_assets/illos/{_icon}.png", {
        "_icon": this._getIconKey()
      });
    },
    /**
     * Intercept icon lookup prior to rendering and replace with a new style in case we are calling an old icon name.
     *
     * @returns {String} - An updated icon name
     * @private
     */
    _getIconKey: function () {
      return iconMigrationMap[this.icon] ? iconMigrationMap[this.icon] : this.icon; // Will intentionally pass through an empty string
    },
    /**
     * Checks to see if a custom prop has been inputted, if not sets to default
     * @param {string} pCustom - A custom value, if there is one
     * @param {string} pDefault - The default value for this type
     * @return {string} Value that should be used in block
     */
    _validateProp: function (pCustom, pDefault) {
      if (pCustom || pDefault) {
        // Not null value means something was passed in as a prop and takes precedent
        if (pCustom !== null) {
          return pCustom;
        }
        return !!pCustom ? pCustom : pDefault;
      }
      return "";
    }
  });
});