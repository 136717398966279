define(["dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/focus"], function (on, array, ready, query, keys, focus) {
  // Holds all the char-based callbacks
  var callbacks = {};
  // Holds all the special keyup callbacks.
  var nonPrintableCallbacks = {};
  var isInput = function (e) {
    return e.target && array.indexOf(['INPUT', 'TEXTAREA', 'SELECT'], e.target.tagName.toUpperCase()) != -1;
  };
  var fromCK = function (e) {
    return typeof e.sender != 'undefined';
  };
  ready(function () {
    // Look in the current window
    on(window.document, 'keyup', handleNonPrintableKeys);
    on(window.document, 'keypress', handlePrintableKeys);

    // Listen for clicks on iframes that belong to us also.
    query('iframe').forEach(function (frame) {
      // Let's start the events the first time
      attachIframeEvents(frame);
      // Reattach events on iframe load
      on(frame, 'load', function () {
        attachIframeEvents(this);
      });
    });
  });
  var attachIframeEvents = function (frame) {
    var domain = document.location.protocol + '//' + document.location.host;
    if (frame.src.slice(0, domain.length) == domain) {
      on(frame.contentWindow.document, 'keyup', function (e) {
        handleNonPrintableKeys(e);
      });
      on(frame.contentWindow.document, 'keypress', function (e) {
        handlePrintableKeys(e);
      });
    }
  };

  // Handles non-printable keys (like ESC)
  var handleNonPrintableKeys = function (e) {
    // Any special key is a no-go since browsers might do something with it.
    if (!(e.ctrlKey || e.metaKey || e.altKey || e.shiftKey)) {
      var keyCode = e.data ? e.data.keyCode : e.keyCode;
      for (var key in keys) {
        if (keys.hasOwnProperty(key) && keys[key] === keyCode) {
          if (!isInput(e)) {
            if (nonPrintableCallbacks[keyCode] && nonPrintableCallbacks[keyCode].length) {
              array.forEach(nonPrintableCallbacks[keyCode], function (f) {
                f(e);
              });
              focus.curNode && focus.curNode.blur();
            }
          }
          break;
        }
      }
    }
  };

  // Handle Keyboard events that have printable keys
  var handlePrintableKeys = function (e) {
    // Any special key is a no-go since browsers might do something with it.
    if (!(e.ctrlKey || e.metaKey || e.altKey || e.shiftKey)) {
      var charCode = e.data ? e.data.charCode : e.charCode;
      if (!isInput(e) && !fromCK(e)) {
        if (callbacks[charCode] && callbacks[charCode].length) {
          array.forEach(callbacks[charCode], function (f) {
            f(e);
          });
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  };
  var keyShortcuts = {
    onKey: function (keyCode, callback) {
      var charCode = keyCode.charCodeAt(0);
      if (!callbacks[charCode]) callbacks[charCode] = [];
      callbacks[charCode].push(callback);
      return {
        remove: function () {
          var index = array.indexOf(callbacks[charCode], callback);
          if (index >= 0) {
            callbacks[charCode].splice(index, 1);
          }
        }
      };
    },
    /**
     * Handle non-printable keys events.
     * @param keyCode string of any special key code available in dojo/keys module.
     * @param callback the callback function
     */
    onSpecialKey: function (keyCode, callback) {
      var charCode = keys[keyCode];
      if (!nonPrintableCallbacks[charCode]) nonPrintableCallbacks[charCode] = [];
      nonPrintableCallbacks[charCode].push(callback);
      return {
        remove: function () {
          var index = array.indexOf(nonPrintableCallbacks[charCode], callback);
          if (index >= 0) {
            nonPrintableCallbacks[charCode].splice(index, 1);
          }
        }
      };
    },
    onEsc: function (callback) {
      return keyShortcuts.onSpecialKey('ESCAPE', callback);
    },
    onEnter: function (input, callback) {
      return on(input, 'keyup', function (e) {
        if (!(e.ctrlKey || e.metaKey || e.altKey || e.shiftKey)) {
          var keyCode = e.data ? e.data.keyCode : e.keyCode;
          if (isInput(e) && !fromCK(e) && keyCode === keys.ENTER) {
            callback(e);
          }
        }
      });
    }
  };
  return keyShortcuts;
});