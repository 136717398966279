define(["dojo-proxy-loader?name=dojo/_base/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dijit/form/Button", "dojo-proxy-loader?name=dojo/json!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/connect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/Pusher", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/lib/logger"], function (xhr, Dialog, Button, json, connect, lang, array, utils, mPusher, user, context, flags, logger) {
  var failedQueue = [];
  var handleErrors = function (error, ioargs) {
    switch (ioargs.xhr.status) {
      case 404:
        utils.globalPublish("show-toast", [{
          message: 'Oops. Looks like the block no longer exist.',
          type: 'error'
        }]);
        break;
      case 403:
        // Logged out. no-op, let the default reauth dialog handle this.
        break;
      default:
        utils.globalPublish("show-toast", [{
          message: 'Oops. Looks like something went wrong. Try again.',
          type: 'error'
        }]);
    }
  };
  var requeueOnErrors = function (error, ioargs) {
    handleErrors(error, ioargs);
    if (ioargs.xhr.status == 403) {
      failedQueue.push(ioargs);
    }
  };
  connect.subscribe("mojo/reauth/complete", function (t) {
    array.forEach(failedQueue, function (failedxhr) {
      var content = failedxhr.args.content;
      content.__csrf_token = t;
      xhr.post({
        url: failedxhr.url,
        content: content,
        sync: true,
        handleAs: 'json'
      });
    });
    failedQueue = [];
  });

  /**
   * When a response is empty, it's likely your token has expired.
   */
  var checkEmptyResponse = function (response) {
    if (!response) {
      refreshCampaign();
    }
  };

  /**
   * The root URL that will contain all these neapolitan endpoints. This can be campaigns, templates or anything
   * that supports these sets of endpoints.
   * @param editorRootUrl - specify the root url controller that will contain all these endpoints.
   * @param id - the model ID that all these endpoints use to identify what you are editing (template, campaign, etc)
   * @param variant - an optional variant parameter
   *
   */
  return function (editorRootUrl, id, variant) {
    /**
     * Add the common params that we pass to the server.
     */
    var setupParams = function (url, params) {
      params.url = editorRootUrl + url;
      if (!params.content) {
        params.content = {};
      }
      if (params.content) {
        params.content.id = id;
        if (variant) {
          params.content.variant = variant;
        }
        params.content.__csrf_token = window.top.__csrf;
        params.content.socket_id = mPusher.getSocketId();
      }
      if (!params.hasOwnProperty('handleAs')) {
        params.handleAs = 'json';
      }
      return params;
    };
    return {
      'update': function (containerId, blockId, options, sync) {
        if (!sync) sync = false;
        var content = lang.mixin({
          'blockId': blockId,
          'container': containerId
        }, options);
        if (content.data && !lang.isString(content.data)) {
          content.data = json.stringify(content.data);
        }
        return xhr.post(setupParams('/update-block', {
          'content': content,
          'sync': sync,
          'load': checkEmptyResponse,
          'error': requeueOnErrors
        }));
      },
      'updateAll': function (data, options) {
        var content = lang.mixin(options, {
          'blocks': json.stringify(data)
        });
        return xhr.post(setupParams('/update-all-blocks', {
          'content': content,
          'load': checkEmptyResponse,
          'error': requeueOnErrors
        }));
      },
      create: function (containerId, html, index, blockType, blockData, isDefault) {
        // log ecomm-block usage to kibana
        var isEcomm = ["product", "productRec", "promoCode"].indexOf(blockType) >= 0;
        if (isEcomm) {
          logger.info("ecomm-blocks", "Ecomm Blocks - Block Added", {
            block_type: blockType,
            campaign_id: window.app.campaignId,
            campaign_type: window.app.campaignType,
            connected_stores: window.app.allEcommStores.length,
            log_classification: "sensitive"
          });
        }
        if (typeof isDefault === "undefined") {
          isDefault = false;
        }
        return xhr.post(setupParams("/new-block", {
          content: {
            type: blockType,
            container: containerId,
            html: html,
            index: index,
            data: json.stringify(blockData),
            is_default: isDefault
          },
          sync: true,
          load: checkEmptyResponse,
          error: handleErrors
        }));
      },
      createBulk: function (containerId, blocks) {
        return xhr.post(setupParams("/new-bulk-block", {
          content: {
            container: containerId,
            data: json.stringify(blocks)
          },
          sync: true,
          load: checkEmptyResponse,
          error: handleErrors
        }));
      },
      remove: function (containerId, blockId) {
        // log ecomm block usage to kibana
        var block = window.app._byIds[blockId];
        var blockType = block ? block.type : undefined;
        var isEcomm = ["product", "productRec", "promoCode"].indexOf(blockType) >= 0;
        if (isEcomm) {
          logger.info("ecomm-blocks", "Ecomm Blocks - Block Removed", {
            block_type: blockType,
            campaign_id: window.app.campaignId,
            campaign_type: window.app.campaignType,
            connected_stores: window.app.allEcommStores.length,
            log_classification: "sensitive"
          });
        }
        return xhr.post(setupParams("/remove-block", {
          content: {
            blockId: blockId,
            container: containerId
          },
          sync: true,
          load: checkEmptyResponse,
          error: handleErrors
        })).then(lang.hitch(this, function () {
          // set block to have isDeleted prop for update-block to not be called
          block._isDeleted = true;
        }));
      },
      'get': function (blockId) {
        return xhr.get(setupParams('/get-block', {
          'content': {
            'blockId': blockId
          },
          'error': handleErrors
        }));
      },
      'getByIds': function (ids) {
        if (lang.isArray(ids)) {
          return xhr.get(setupParams('/get-block', {
            'content': {
              'ids': ids.join(',')
            },
            'error': handleErrors
          }));
        } else {
          throw new Error("getByIds expects an array");
        }
      },
      'getBlocks': function () {
        return xhr.get(setupParams("/get-blocks", {
          'error': handleErrors
        }));
      },
      "saveStyles": function (styles, source) {
        var url = "/save-styles";
        if (source) {
          url += "?source=" + source;
        }
        return xhr.post(setupParams(url, {
          "content": {
            "styles": json.stringify(styles)
          },
          "handleAs": "text"
        }));
      },
      'getStyles': function () {
        return xhr.get(setupParams("/design-styles", {}));
      },
      'mcedit': {
        /**
         * Given a mcedit block, return the content
         */
        getText: function (editId) {
          return xhr.get(setupParams("/get-content", {
            content: {
              'block': editId
            },
            'handleAs': 'text'
          }));
        },
        /**
         * Given a mcedit for an image, return the image metadata
         */
        getImage: function (editId) {
          return xhr.get(setupParams("/get-image", {
            content: {
              'block': editId
            }
          }));
        },
        /**
         * Save mc:edit that are not <img> tags
         * @param section the id for the mc:edit
         * @param value the HTML value
         */
        saveText: function (section, value) {
          return xhr.post(setupParams("/save-content", {
            content: {
              'section': section,
              'value': value
            },
            handleAs: 'text'
          }));
        },
        /**
         * Save mc:edit that is added to an <img> tag
         * @param mcEditId the id for the mc:edit
         * @param data this is either HTML (string) or a Object that represents an image.
         * These are the attributes on the object:
         * url,alt,align,link,target,width,height,border-width,border-style,border-color
         */
        saveImage: function (mcEditId, data) {
          var content = {
            'image-block': mcEditId
          };
          if (typeof data == "string") {
            content['text'] = data;
          } else {
            for (var attr in data) {
              if (data.hasOwnProperty(attr)) {
                switch (attr) {
                  case 'src':
                    content['image-url'] = data[attr];
                    break;
                  case 'link':
                    if (data[attr]) {
                      // To keep the old editor working, we convert the image structure of the new editor
                      // to what this old endpoint requires
                      content['image-link'] = data[attr].href;
                      if (data[attr]['attrs'] && data[attr]['attrs']['target'] === '_blank') {
                        content['image-target'] = true;
                      }
                    }
                    break;
                  default:
                    content['image-' + attr] = data[attr];
                }
              }
            }
          }
          return xhr.post(setupParams("/save-image", {
            content: content,
            handleAs: 'text'
          }));
        },
        /**
         * Remove the image for the specified mc:edit.
         * If setDefault is true, it will toggle the default image. Otherwise it will be empty.
         *
         * @param mcEditId
         * @param setDefault
         * @returns {*}
         */
        removeImage: function (mcEditId, setDefault) {
          if (typeof setDefault == 'undefined') setDefault = false;
          return xhr.post(setupParams("/remove-image", {
            'content': {
              'block': mcEditId,
              'set_default': setDefault
            }
          }));
        },
        /**
         * Use this to recover content from blockName to the mc:edit identified by mcEditId
         */
        recoverContent: function (mcEditId, blockName, type) {
          return xhr.post(setupParams("/set-content-from-preserved", {
            'content': {
              'section': mcEditId,
              'block': blockName,
              'type': type ? type : 'html'
            }
          }));
        }
      },
      'hideable': {
        toggle: function (name) {
          return xhr.post(setupParams('/toggle-hideable', {
            'content': {
              'name': name
            },
            'sync': true
          }));
        }
      },
      'repeatables': {
        getContent: function (name, index) {
          return xhr.get(setupParams('/get-repeatable-content', {
            'content': {
              'name': name,
              'index': index
            }
          }));
        },
        add: function (name) {
          return xhr.post(setupParams('/add-repeatable', {
            'content': {
              'name': name
            },
            'sync': true
          }));
        },
        remove: function (name, index) {
          return xhr.post(setupParams('/remove-repeatable', {
            'content': {
              'name': name + ":" + index
            },
            'sync': true
          }));
        },
        reorder: function (name, oldIndex, index) {
          return xhr.post(setupParams("/reorder-repeatable", {
            'content': {
              'name': name + ":" + oldIndex,
              'index': index
            },
            'sync': true
          }));
        },
        changeVariant: function (name, repeatIdx, variant) {
          return xhr.post(setupParams("/change-repeatable-variant", {
            'content': {
              'name': name + ":" + repeatIdx,
              'variant': variant
            }
          }));
        }
      },
      getPreservedContent: function (blockName, type) {
        return xhr.get(setupParams('/preserved-block-preview', {
          'content': {
            'block': blockName,
            'type': type ? type : 'html'
          },
          'handleAs': 'text'
        }));
      },
      markLinkAsChecked: function (linkId) {
        return xhr.get(setupParams('/mark-link', {
          'content': {
            'link': linkId
          }
        }));
      },
      linkScreenshot: function (linkId) {
        return xhr.get(setupParams('/link-screenshot', {
          'content': {
            'link': linkId
          }
        }));
      },
      makeVideoThumbnail: function (src) {
        return xhr.get(setupParams('/make-video-thumbnail', {
          'content': {
            'src': encodeURI(src)
          }
        }));
      }
    };
  };
});