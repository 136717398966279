define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select"], function (declare, Select) {
  return declare([Select], {
    maxHeight: -1,
    constructor: function (props) {
      this.options = [{
        label: "Top-Left",
        value: "top-left"
      }, {
        label: "Top-Center",
        value: "top-center"
      }, {
        label: "Top-Right",
        value: "top-right"
      }, {
        label: "Middle-Left",
        value: "middle-left"
      }, {
        label: "Middle-Right",
        value: "middle-right"
      }, {
        label: "Bottom-Left",
        value: "bottom-left"
      }, {
        label: "Bottom-Center",
        value: "bottom-center"
      }, {
        label: "Bottom-Right",
        value: "bottom-right"
      }, {
        label: "None",
        value: "none"
      }];
      if (props.isOptional) {
        this.options.unshift({
          label: "Not specified",
          value: ""
        });
      }
    }
  });
});