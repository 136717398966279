define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo/text!../editors/blocks/templates/ProductEditor/outputWeb.html"], function (declare, _Renderer, ButtonRenderer, Link, tpl) {
  return declare([_Renderer], {
    buttonRenderer: null,
    outputTplStr: tpl,
    constructor: function () {
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * Use Button block renderer to render the button itself.
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @returns {string} html content of how a button should look
     * @private
     */
    _buttonMarkup: function (meta, product) {
      var buttonMeta = {
        buttonText: product.buttonText,
        buttonWidth: meta.buttonWidth,
        align: meta.buttonAlign,
        link: new Link(product.getButtonLinkUrl(), product.title)
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    /**
     * Return the markup of the content of each product. This includes name, desc, price and button
     *
     * @param {Object} meta - ref to the output model's meta.
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @return {Array} Data needed to render the rows in the template
     *
     * @private
     */
    _contentMarkup: function (meta, product) {
      var rows = [];
      if (product.title) {
        rows.push({
          html: product.title + "<br />",
          className: "mcnProductTitle",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.price) {
        rows.push({
          html: product.price + "<br />",
          className: "mcnProductPrice",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.descriptionEnabled && product.description) {
        rows.push({
          html: product.description + "<br/>",
          className: "mcnProductDescription",
          styles: "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.buttonEnabled) {
        rows.push({
          html: this._buttonMarkup(meta, product),
          className: "mcnProductButton",
          styles: "padding-top:18px"
        });
      }
      return rows;
    },
    /**
     * Return classes for outer container based on layout
     *
     * @param {Object} meta - Output model's metadata
     *
     * @return {String} The final classname
     *
     * @private
     */
    _getContainerClasses: function (meta) {
      var classes = "mcnRecContentContainer";
      if (meta.layout === "vertical" && meta.products.length > 1) {
        classes += " mcnRecContentContainer--row";
      }
      return classes;
    },
    _getContainerStyles: function (meta) {
      var flexStyles = meta.layout === "vertical" ? "flex:1; flex-direction: column" : "flex-shrink: 0; flex-direction: row";
      return flexStyles + ";padding-top: 9px; padding-bottom: 9px; display: flex;";
    },
    _getImageContentStyles: function (meta) {
      var flexStyles = meta.layout === "vertical" ? "flex-shrink: 0" : "flex: 1";
      return flexStyles + ";padding-left: 18px; padding-right: 18px";
    },
    getOutputModel: function (meta) {
      var self = this;
      var productsData = meta.products.map(function (product) {
        return {
          product: product,
          body: self._contentMarkup(meta, product)
        };
      });
      return {
        productsData: productsData,
        flexDirection: meta.layout === "vertical" ? "row" : "column",
        flexWrap: meta.layout === "vertical" ? "wrap" : "no-wrap",
        containerClasses: this._getContainerClasses(meta),
        containerStyles: this._getContainerStyles(meta),
        imageContentStyles: this._getImageContentStyles(meta),
        textContentStyles: meta.layout === "vertical" ? "" : "flex: 1"
      };
    }
  });
});