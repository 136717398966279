define(["dojo/request", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url", "mojo/app/lists/members", "mojo/user"], function (request, ioQuery, utils, mUrl, listMembers, user) {
  var segmentUrls = {
    countUrl: mUrl.toUrl("pro/segments/count"),
    popupUrl: mUrl.toUrl("pro/segments/popup")
  };
  var segment = {
    /**
     * Fetch the count for the given AST
     *
     * @param {Number} list_id
     * @param {Object} ast
     * @param {String} type
     * @returns {dojo/Promise}
     */
    refreshSegmentCount: function (list_id, ast, type) {
      return request.post(segmentUrls.countUrl, {
        'data': {
          'list_id': list_id,
          'type': type,
          'ast': JSON.stringify(ast)
        },
        'handleAs': 'json'
      });
    },
    popupSegment: function (list_id, ast, type) {
      var url = segmentUrls.popupUrl + '?list_id=' + list_id + '&type' + type + '&ast=' + JSON.stringify(ast);
      var winOptions = 'toolbar=0,scrollbars=1,location=0,status=1,menubar=0,resizable=1,width=600,height=400';
      var win = window.open(url, '_blank', winOptions);
      utils.checkPopupWin(win);
      return false;
    },
    /**
     * Return a cached count for a particular segment that's stored.
     *
     * @param {Number} listId
     * @param {Number} segmentId
     */
    getCachedCount: function (listId, segmentId) {
      return request.get(mUrl.toUrl("pro/segments/cached-count"), {
        "query": {
          "list_id": listId,
          "segment_id": segmentId
        },
        "handleAs": "json"
      });
    },
    /**
     * Return the latest information related to the a segmentation.
     *
     * @param listId
     * @param segmentId
     * @returns {*}
     */
    getSegment: function (listId, segmentId) {
      return request.get(mUrl.toUrl("pro/segments/view", {
        "list_id": listId,
        "segment_id": segmentId
      }), {
        "handleAs": "json"
      });
    },
    /**
     * Save a segment that's tied to comparative report.
     *
     * @param {Number} list_id
     * @param {Number} report_id
     * @param {Number} segment_id
     * @param {String} name
     * @param {Object} ast
     */
    saveReportSegment: function (list_id, report_id, segment_id, name, ast) {
      var params = {
        id: report_id,
        segment_id: segment_id,
        ast: JSON.stringify(ast),
        name: name,
        list_id: list_id,
        __csrf_token: user.getCsrfToken()
      };
      var param_string = ioQuery.objectToQuery(params);
      window.location.href = mUrl.toUrl("pro/reports/save-segment?" + param_string);
    },
    /**
     * Run the given advanced segmentation.
     *
     * @param {Number} listId
     * @param {Number} segmentId
     * @param {String} name
     * @param {Object} ast
     *
     * @returns {dojo/Promise}
     */
    runListSegment: function (listId, segmentId, name, ast) {
      var referrer = listMembers.contactTableReferrer;
      return request.post(mUrl.toUrl("pro/segments/run"), {
        "data": {
          "list_id": listId,
          "segment_id": segmentId,
          "name": name,
          "ast": JSON.stringify(ast),
          "contact_table_referrer": referrer
        },
        "handleAs": "json"
      });
    },
    reRunSegment: function (listId, segmentId) {
      return request.post(mUrl.toUrl('pro/segments/re-run'), {
        "data": {
          "list_id": listId,
          "segment_id": segmentId
        },
        "handleAs": "json"
      });
    },
    /**
     * Sets the target advanced segment as the current segment to look at.
     *
     * @param listId
     * @param segmentId
     */
    setListSegment: function (listId, segmentId) {
      return request.post(mUrl.toUrl("pro/segments/select-segment"), {
        "data": {
          "list_id": listId,
          "segment_id": segmentId
        },
        "handleAs": "json"
      });
    },
    /**
     * Deletes the pro segment
     *
     * @param {number} listId
     * @param {number} segmentId
     * @returns {*}
     */
    deleteSegment: function (listId, segmentId) {
      return request.post(mUrl.toUrl("pro/segments/delete"), {
        "data": {
          "list_id": listId,
          "segment_id": segmentId
        },
        "handleAs": "json"
      });
    }
  };
  return segment;
});