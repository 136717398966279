define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo/text!./templates/segmentDial.html", "dojo/parser", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "d3/d3", "mojo/colors", "mojo/utils/I18nTranslation"], function (declare, lang, _Widget, _Templated, tpl, parser, domClass, d3, mColors, I18nTranslation) {
  return declare([_Widget, _Templated], {
    templateString: tpl,
    maxValue: 8,
    value: 1,
    combinationsLabel: "Combinations",
    maxLabel: "Max",
    maxZeroLabel: "Max of",
    setValue: function (value) {
      this.render();
    },
    render: function () {
      var vis;
      var dial;
      var width = this.width || 160;
      var height = this.height || 160;
      var radius = this.radius || width / 2;
      var segmentScale = d3.scale.linear().range([0, 360]).domain([0, this.maxValue]);
      vis = d3.select(this.segmentDial).append("svg:svg").attr("class", "dial block").attr("width", width).attr("height", height).attr("style", "margin: 0 auto");
      dial = vis.append("svg:g").attr("transform", "translate(" + radius + ", " + radius + ")");
      var dialStrokeColor;
      dialStrokeColor = mColors.brandColors.parsnip.hex;

      // BACKGROUND CIRCLE
      dial.append("svg:circle").attr("r", radius - 4).attr("class", "dial").style("fill", "white").attr("stroke", dialStrokeColor).attr("stroke-width", 8);
      this.dial = dial;

      // VARIATE SELECTOR ARC
      var arc = d3.svg.arc().innerRadius(radius - 8).outerRadius(radius).startAngle(0);
      this.arc = arc;
      var pathFillColor;
      pathFillColor = mColors.brandColors.apple.hex;
      var path = dial.append("svg:path").datum({
        endAngle: 0
      }).style("fill", pathFillColor).attr("d", arc);
      this.path = path;

      // SEGMENT TICKS
      var ticks = dial.append("svg:g").attr("class", "ticks");
      ticks.selectAll(".tick").data(d3.range(0, this.maxValue)).enter().append("svg:line").attr("class", "tick").attr("x1", -1).attr("x2", -1).attr("y1", radius + 1).attr("y2", radius - 9).attr("transform", function (d) {
        return "rotate(" + (180 + segmentScale(d)) + ")";
      }).style("fill", "none").attr("stroke-width", 4).attr("stroke", "white");
    },
    drawArc: function (oldValue, newValue) {
      var newAngle = Math.PI * 2 / this.maxValue * oldValue;
      var endAngle = Math.PI * 2 / this.maxValue * newValue;
      var self = this;
      self.path.datum({
        endAngle: newAngle
      }).transition().ease("elastic", 1, 2).duration(200).call(arcTween, endAngle, self.arc);
      function arcTween(transition, angle, arc) {
        transition.attrTween("d", function (d) {
          var interp = d3.interpolate(d.endAngle, angle);
          return function (t) {
            d.endAngle = interp(t);
            return arc(d);
          };
        });
      }
    },
    updateLabel: function () {
      domClass.toggle(this.maxLabel, "hide", this.value < this.maxValue);
      var self = this;

      // Interpolate text between current value (that.text()) and new value (self.value)
      d3.select(this.valueLabel).transition().duration(200).tween("text", function () {
        // eslint-disable-next-line consistent-this
        var that = d3.select(this);
        var i = d3.interpolate(that.text(), self.value);
        return function (t) {
          that.text(Math.round(i(t)));
        };
      });
    },
    updateZeroLabel: function () {
      domClass.toggle(this.maxZeroLabel, "hide", this.value > 0);
    },
    postMixInProperties: function () {
      I18nTranslation.initialize();
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        this.combinationsLabel = I18nTranslation.translate("segment_dial_combinations_label");
        this.maxLabel = I18nTranslation.translate("segment_dial_max_label");
        this.maxZeroLabel = I18nTranslation.translate("segment_dial_max_of_label");
      }
    },
    postCreate: function () {
      this.render();
      this.watch("value", lang.hitch(this, function (name, old, newV) {
        this.drawArc(old, newV);
        this.updateLabel();
        this.updateZeroLabel();
      }));
    }
  });
});