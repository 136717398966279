define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../_base"], function (lang, dd) {
  var logic = lang.getObject("filter.logic", true, dd);
  /*=====
   logic = {
   	// TODO: summary
   };
   =====*/

  lang.mixin(logic, {
    default_: function (value, arg) {
      // summary:
      //		If value is unavailable, use given default
      return value || arg || "";
    },
    default_if_none: function (value, arg) {
      // summary:
      //		If value is null, use given default
      return value === null ? arg || "" : value || "";
    },
    divisibleby: function (value, arg) {
      // summary:
      //		Returns true if the value is divisible by the argument"
      return parseInt(value, 10) % parseInt(arg, 10) === 0;
    },
    _yesno: /\s*,\s*/g,
    yesno: function (value, arg) {
      // summary:
      //		arg being a comma-delimited string, value of true/false/none
      //		chooses the appropriate item from the string
      if (!arg) {
        arg = 'yes,no,maybe';
      }
      var parts = arg.split(dojox.dtl.filter.logic._yesno);
      if (parts.length < 2) {
        return value;
      }
      if (value) {
        return parts[0];
      }
      if (!value && value !== null || parts.length < 3) {
        return parts[1];
      }
      return parts[2];
    }
  });
  return logic;
});