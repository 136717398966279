/**
 * This handles rendering all the output markup for blocks.
 * Opting to explicitly require each output file to allow dojo's built system to bundle it all together.
 */
define(["./email-renderers/BoxedTextBlock", "./email-renderers/ButtonBlock", "./email-renderers/DividerBlock", "./email-renderers/ImageCardBlock", "./email-renderers/ImageBlock", "./email-renderers/ImageGroupBlock", "./email-renderers/ImageWithCaptionBlock", "./email-renderers/TextBlock", "./email-renderers/SocialFollowBlock", "./email-renderers/SocialShareBlock", "./email-renderers/CodeBlock", "./email-renderers/RssItemsBlock", "./email-renderers/RssHeaderBlock", "./email-renderers/VideoBlock", "./email-renderers/ApiBlock", "./email-renderers/ProductRecBlock", "./email-renderers/ProductBlock", "./email-renderers/AbandonedCartBlock", "./email-renderers/AbandonedBrowseBlock", "./email-renderers/PromoCodeBlock", "./web-renderers/SignUpFormBlock", "./web-renderers/TextBlock", "./web-renderers/BoxedTextBlock", "./web-renderers/DividerBlock", "./web-renderers/CodeBlock", "./web-renderers/ImageBlock", "./web-renderers/ImageCardBlock", "./web-renderers/ButtonBlock", "./web-renderers/ProductBlock", "./web-renderers/PaymentBlock", "./web-renderers/ImageWithCaptionBlock", "./web-renderers/SocialFollowBlock", "./web-renderers/ImageGroupBlock", "./web-renderers/PromoCodeBlock", "./web-renderers/SocialShareBlock", "./web-renderers/WebVideoBlock"], function (BoxedTextBlock, ButtonBlock, DividerBlock, ImageCardBlock, ImageBlock, ImageGroupBlock, ImageWithCaptionBlock, TextBlock, SocialFollowBlock, SocialShareBlock, CodeBlock, RssItemsBlock, RssHeaderBlock, VideoBlock, ApiBlock, ProductRecBlock, ProductBlock, AbandonedCartBlock, AbandonedBrowseBlock, PromoCodeBlock, SignUpFormBlock, TextBlockWeb, BoxedTextBlockWeb, DividerBlockWeb, CodeBlockWeb, ImageBlockWeb, ImageCardBlockWeb, ButtonBlockWeb, ProductBlockWeb, PaymentBlockWeb, ImageWithCaptionBlockWeb, SocialFollowBlockWeb, ImageGroupBlockWeb, PromoCodeBlockWeb, SocialShareBlockWeb, WebVideoBlock) {
  var renderers = {
    "boxedText": new BoxedTextBlock(),
    "button": new ButtonBlock(),
    "divider": new DividerBlock(),
    "imageCard": new ImageCardBlock(),
    "image": new ImageBlock(),
    "imageGroup": new ImageGroupBlock(),
    "imageCaption": new ImageWithCaptionBlock(),
    "socialFollow": new SocialFollowBlock(),
    "socialShare": new SocialShareBlock(),
    "text": new TextBlock(),
    "code": new CodeBlock(),
    "rssItems": new RssItemsBlock(),
    "rssHeader": new RssHeaderBlock(),
    "video": new VideoBlock(),
    "api": new ApiBlock(),
    "productRec": new ProductRecBlock(),
    "product": new ProductBlock(),
    "abandonedCart": new AbandonedCartBlock(),
    "abandonedBrowse": new AbandonedBrowseBlock(),
    "promoCode": new PromoCodeBlock()
  };

  // TODO: replace all with web renderers as they are built

  var webRenderers = {
    "text": new TextBlockWeb(),
    "boxedText": new BoxedTextBlockWeb(),
    "divider": new DividerBlockWeb(),
    "signUpForm": new SignUpFormBlock(),
    "code": new CodeBlockWeb(),
    "imageCard": new ImageCardBlockWeb(),
    "button": new ButtonBlockWeb(),
    "image": new ImageBlockWeb(),
    "imageGroup": new ImageGroupBlockWeb(),
    "imageCaption": new ImageWithCaptionBlockWeb(),
    "socialFollow": new SocialFollowBlockWeb(),
    "product": new ProductBlockWeb(),
    "payment": new PaymentBlockWeb(),
    "promoCode": new PromoCodeBlockWeb(),
    "socialShare": new SocialShareBlockWeb(),
    "webVideo": new WebVideoBlock()
  };

  // These blocks share a renderer
  renderers["footer"] = renderers.text;
  return {
    getMarkup: function (type, tplModel, campaignRenderingType) {
      var emailRenderer = renderers[type];
      var webRenderer = webRenderers[type];
      if (campaignRenderingType && campaignRenderingType === "page") {
        return webRenderer.getMarkup(tplModel, campaignRenderingType);
      }
      return emailRenderer.getMarkup(tplModel, campaignRenderingType);
    },
    getContent: function (type, tplModel, campaignRenderingType) {
      if (campaignRenderingType && campaignRenderingType === "page") {
        return webRenderers[type].getContent(tplModel, campaignRenderingType);
      }
      return renderers[type].getContent(tplModel, campaignRenderingType);
    }
  };
});