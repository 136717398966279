define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils"], function (declare, base, domAttr, on, xhr, domClass, p2Utils) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.loggingStatusCheckbox) {
        this.$group.loggingStatusCheckbox.forEach(function (checkbox) {
          on(checkbox, "click", function () {
            var apikey = checkbox.value;
            var checked = checkbox.checked;

            // It appears that the checkbox check state is changed before this method is called, so 'checked' is actually the opposite of what's on the screen
            var new_value = "off";
            if (checked) {
              new_value = "on";
            }
            xhr(self.pageJs.peachesUrl("/integrations/api-set-logging"), {
              query: {
                user_id: self.pageJs.userAttrs.userId,
                apikey: apikey,
                logging_status: new_value
              },
              handleAs: "json"
            }).then(function (data) {
              if (data === "on") {
                checkbox.checked = true;
              } else if (data === "off") {
                checkbox.checked = false;
              } else {
                p2Utils.setFlash("Error encountered: " + data, "error");
              }
            });
          });
        });
      }
      if (this.$group.viewApikeyData) {
        this.$group.viewApikeyData.forEach(function (el) {
          on(el, "click", function () {
            var apikey = domAttr.get(this, "data-apikey");
            var target_ul = self.$el.apiCallList;
            // Clear the call list and call details list
            target_ul.innerHTML = "<div><strong>Loading results...</strong></div>";
            self.$el.apiCallData.innerHTML = "";

            // Swap selection
            self.$group.viewApikeyData.removeClass("selected");
            domClass.add(this, "selected");
            var call_list_request = xhr(self.pageJs.peachesUrl("/integrations/api-watcher-data"), {
              query: {
                user_id: self.pageJs.userAttrs.userId,
                apikey: apikey
              }
            }).then(function (data) {
              target_ul.innerHTML = data;
              self.parse();
              on(self.$group.callListItem, "click", function () {
                var call_id = domAttr.get(this, "data-call-id");

                // Clear the call details panel
                self.$el.apiCallData.innerHTML = "<strong>Loading results...</strong>";

                // Swap selection
                self.$group.callListItem.forEach(function (listItem) {
                  domClass.remove(listItem, "selected");
                });
                domClass.add(this, "selected");
                xhr(self.pageJs.peachesUrl("/integrations/api-watcher-data"), {
                  query: {
                    user_id: self.pageJs.userAttrs.userId,
                    call_id: call_id
                  }
                }).then(function (res) {
                  self.$el.apiCallData.innerHTML = res;
                });
              });
            });
          });
        });
      }
    }
  });
});