define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/app/campaigns", "mojo/utils", "mojo/utils/date", "./Slat", "./Touchpoint", "dojo/text!./templates/EffortSlat.html"], function (declare, lang, domClass, entities, campaigns, utils, dateUtils, Slat, Touchpoint, effortSlatTpl) {
  return declare([Slat], {
    templateString: effortSlatTpl,
    channelIcon: "c-channelIcon--social",
    // when social posting icon becomes available change this
    statusContent: {},
    statsOrder: ["total_reach", "engagements"],
    statsContent: {
      "total_reach": {
        label: "Total Reach"
      },
      "engagements": {
        label: "Total Engagements"
      }
    },
    typeContent: {
      "effort": {
        label: "Marketing Campaign"
      }
    },
    actions: {},
    constructor: function (properties) {
      this.inherited(arguments);
    },
    _buildCheckbox: function () {
      // This will happen in the individual touchpoint slat
    },
    postCreate: function () {
      this.inherited(arguments);
      var touchpointsNode = this.touchpointsNode;
      var groupHeaderNode = this.groupHeaderNode;
      var touchpoints = this.model.rawModel.touchpoints;
      if (touchpoints) {
        var indentTouchpoints;
        if (touchpoints.length > 1) {
          domClass.remove(groupHeaderNode, "hide");
          indentTouchpoints = true;
        } else {
          domClass.remove(this.domNode, "padding-bottom--lv3");
          indentTouchpoints = false;
        }
        touchpoints.forEach(function (t) {
          var touchpoint = new Touchpoint({
            touchpoint: t,
            indent: indentTouchpoints
          });
          touchpoint.placeAt(touchpointsNode);
        });
      }
    },
    buildChannelIcon: function () {
      // no thanks
    },
    _buildTypeName: function () {
      // no thank you
    }
  });
});