define(["../main", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/colors"], function (dojox, lang, Color, colors) {
  var cx = lang.getObject("color", true, dojox);
  /*===== cx = dojox.color =====*/

  //	alias all the dojo.Color mechanisms
  cx.Color = Color;
  cx.blend = Color.blendColors;
  cx.fromRgb = Color.fromRgb;
  cx.fromHex = Color.fromHex;
  cx.fromArray = Color.fromArray;
  cx.fromString = Color.fromString;

  //	alias the dojo.colors mechanisms
  cx.greyscale = colors.makeGrey;
  lang.mixin(cx, {
    fromCmy: function ( /* Object|Array|int */cyan, /*int*/magenta, /*int*/yellow) {
      // summary:
      //		Create a dojox.color.Color from a CMY defined color.
      //		All colors should be expressed as 0-100 (percentage)

      if (lang.isArray(cyan)) {
        magenta = cyan[1], yellow = cyan[2], cyan = cyan[0];
      } else if (lang.isObject(cyan)) {
        magenta = cyan.m, yellow = cyan.y, cyan = cyan.c;
      }
      cyan /= 100, magenta /= 100, yellow /= 100;
      var r = 1 - cyan,
        g = 1 - magenta,
        b = 1 - yellow;
      return new Color({
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
      }); //	dojox.color.Color
    },
    fromCmyk: function ( /* Object|Array|int */cyan, /*int*/magenta, /*int*/yellow, /*int*/black) {
      // summary:
      //		Create a dojox.color.Color from a CMYK defined color.
      //		All colors should be expressed as 0-100 (percentage)

      if (lang.isArray(cyan)) {
        magenta = cyan[1], yellow = cyan[2], black = cyan[3], cyan = cyan[0];
      } else if (lang.isObject(cyan)) {
        magenta = cyan.m, yellow = cyan.y, black = cyan.b, cyan = cyan.c;
      }
      cyan /= 100, magenta /= 100, yellow /= 100, black /= 100;
      var r, g, b;
      r = 1 - Math.min(1, cyan * (1 - black) + black);
      g = 1 - Math.min(1, magenta * (1 - black) + black);
      b = 1 - Math.min(1, yellow * (1 - black) + black);
      return new Color({
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
      }); //	dojox.color.Color
    },
    fromHsl: function ( /* Object|Array|int */hue, /* int */saturation, /* int */luminosity) {
      // summary:
      //		Create a dojox.color.Color from an HSL defined color.
      //		hue from 0-359 (degrees), saturation and luminosity 0-100.

      if (lang.isArray(hue)) {
        saturation = hue[1], luminosity = hue[2], hue = hue[0];
      } else if (lang.isObject(hue)) {
        saturation = hue.s, luminosity = hue.l, hue = hue.h;
      }
      saturation /= 100;
      luminosity /= 100;
      while (hue < 0) {
        hue += 360;
      }
      while (hue >= 360) {
        hue -= 360;
      }
      var r, g, b;
      if (hue < 120) {
        r = (120 - hue) / 60, g = hue / 60, b = 0;
      } else if (hue < 240) {
        r = 0, g = (240 - hue) / 60, b = (hue - 120) / 60;
      } else {
        r = (hue - 240) / 60, g = 0, b = (360 - hue) / 60;
      }
      r = 2 * saturation * Math.min(r, 1) + (1 - saturation);
      g = 2 * saturation * Math.min(g, 1) + (1 - saturation);
      b = 2 * saturation * Math.min(b, 1) + (1 - saturation);
      if (luminosity < 0.5) {
        r *= luminosity, g *= luminosity, b *= luminosity;
      } else {
        r = (1 - luminosity) * r + 2 * luminosity - 1;
        g = (1 - luminosity) * g + 2 * luminosity - 1;
        b = (1 - luminosity) * b + 2 * luminosity - 1;
      }
      return new Color({
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
      }); //	dojox.color.Color
    }
  });
  cx.fromHsv = function ( /* Object|Array|int */hue, /* int */saturation, /* int */value) {
    // summary:
    //		Create a dojox.color.Color from an HSV defined color.
    //		hue from 0-359 (degrees), saturation and value 0-100.

    if (lang.isArray(hue)) {
      saturation = hue[1], value = hue[2], hue = hue[0];
    } else if (lang.isObject(hue)) {
      saturation = hue.s, value = hue.v, hue = hue.h;
    }
    if (hue == 360) {
      hue = 0;
    }
    saturation /= 100;
    value /= 100;
    var r, g, b;
    if (saturation == 0) {
      r = value, b = value, g = value;
    } else {
      var hTemp = hue / 60,
        i = Math.floor(hTemp),
        f = hTemp - i;
      var p = value * (1 - saturation);
      var q = value * (1 - saturation * f);
      var t = value * (1 - saturation * (1 - f));
      switch (i) {
        case 0:
          {
            r = value, g = t, b = p;
            break;
          }
        case 1:
          {
            r = q, g = value, b = p;
            break;
          }
        case 2:
          {
            r = p, g = value, b = t;
            break;
          }
        case 3:
          {
            r = p, g = q, b = value;
            break;
          }
        case 4:
          {
            r = t, g = p, b = value;
            break;
          }
        case 5:
          {
            r = value, g = p, b = q;
            break;
          }
      }
    }
    return new Color({
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    }); //	dojox.color.Color
  };
  lang.extend(Color, {
    toCmy: function () {
      // summary:
      //		Convert this Color to a CMY definition.
      var cyan = 1 - this.r / 255,
        magenta = 1 - this.g / 255,
        yellow = 1 - this.b / 255;
      return {
        c: Math.round(cyan * 100),
        m: Math.round(magenta * 100),
        y: Math.round(yellow * 100)
      }; //	Object
    },
    toCmyk: function () {
      // summary:
      //		Convert this Color to a CMYK definition.
      var cyan, magenta, yellow, black;
      var r = this.r / 255,
        g = this.g / 255,
        b = this.b / 255;
      black = Math.min(1 - r, 1 - g, 1 - b);
      cyan = (1 - r - black) / (1 - black);
      magenta = (1 - g - black) / (1 - black);
      yellow = (1 - b - black) / (1 - black);
      return {
        c: Math.round(cyan * 100),
        m: Math.round(magenta * 100),
        y: Math.round(yellow * 100),
        b: Math.round(black * 100)
      }; //	Object
    },
    toHsl: function () {
      // summary:
      //		Convert this Color to an HSL definition.
      var r = this.r / 255,
        g = this.g / 255,
        b = this.b / 255;
      var min = Math.min(r, b, g),
        max = Math.max(r, g, b);
      var delta = max - min;
      var h = 0,
        s = 0,
        l = (min + max) / 2;
      if (l > 0 && l < 1) {
        s = delta / (l < 0.5 ? 2 * l : 2 - 2 * l);
      }
      if (delta > 0) {
        if (max == r && max != g) {
          h += (g - b) / delta;
        }
        if (max == g && max != b) {
          h += 2 + (b - r) / delta;
        }
        if (max == b && max != r) {
          h += 4 + (r - g) / delta;
        }
        h *= 60;
      }
      return {
        h: h,
        s: Math.round(s * 100),
        l: Math.round(l * 100)
      }; //	Object
    },
    toHsv: function () {
      // summary:
      //		Convert this Color to an HSV definition.
      var r = this.r / 255,
        g = this.g / 255,
        b = this.b / 255;
      var min = Math.min(r, b, g),
        max = Math.max(r, g, b);
      var delta = max - min;
      var h = null,
        s = max == 0 ? 0 : delta / max;
      if (s == 0) {
        h = 0;
      } else {
        if (r == max) {
          h = 60 * (g - b) / delta;
        } else if (g == max) {
          h = 120 + 60 * (b - r) / delta;
        } else {
          h = 240 + 60 * (r - g) / delta;
        }
        if (h < 0) {
          h += 360;
        }
      }
      return {
        h: h,
        s: Math.round(s * 100),
        v: Math.round(max * 100)
      }; //	Object
    }
  });
  return cx;
});