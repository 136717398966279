/**
 * @module mojo.utils.date
 *
 * Date utility methods.
 *
 * All methods' first argument should be a Date instance.
 * Method names follow a prefix pattern which indicates their behavior:
 *  - toSomething: takes a date and formats it in a certain way, eg. toRelativeString
 *  - getSomething: (optionally) take a date and pick out a particular part of it, or calculate something about it, eg. getTimePassed
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/context", "mojo/utils/I18nTranslation", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./string", "./number"], function (lang, locale, user, flags, mLogger, context, I18nTranslation, Deferred, stringUtils, numberUtils) {
  var USER_BROWSER_TZ = "";
  try {
    USER_BROWSER_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    //log error
  }
  var exports = {
    FORMAT_TYPES_FN: {
      DATE_TIME: "getDateString",
      DATE: "getDateOnlyString",
      TIME: "getTimeString"
    },
    TZ_FORMAT_TYPES_FN: {
      DATE_TIME: "getDateStringWithTzOffset"
    },
    DATE_STRING_FORMAT_NAMES: {
      MONTH_DAY_YEAR: "month day, year",
      MONTH_DAY_YEAR_TIME: "month day, year time",
      DAYNAME_MONTH_DAY_YEAR: "dayname, month ordinal-day, year",
      DAYNAME_MONTH_DAY_YEAR_TIME: "dayname, month ordinal-day, year at time",
      TIME: "time"
    },
    getCurrentTimestamp: function () {
      return Math.round(Date.now() / 1000);
    },
    /**
     * Utility that calculates how long it's been since the given timestamp.
     *
     * @param {Date} date - Instance to compare to NOW
     * @return {{seconds: number, minutes: number, hours: number, days: number}} Structured amount of passed time
     */
    getTimePassed: function (date) {
      var diff = new Date() - date;
      var seconds = diff / 1000;
      var minutes = 0;
      var hours = 0;
      var days = 0;
      if (seconds >= 60) {
        minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        if (minutes >= 60) {
          hours = Math.floor(minutes / 60);
          minutes = minutes % 60;
          if (hours >= 24) {
            days = Math.floor(hours / 24);
            hours = hours % 24;
          }
        }
      }
      return {
        "seconds": seconds,
        "minutes": minutes,
        "hours": hours,
        "days": days
      };
    },
    /**
     * Utility that auto formats a date to a relative date if it is less than a week old
     *
     * @param {Date} date JS formatted timestamp
     * @returns {string} formatted date
     */
    toRelativeString: function (date) {
      var timePassed = exports.getTimePassed(date);
      var dateTimeString = locale.format(date, {
        formatLength: "medium",
        timePattern: "h:mm a"
      });
      var str = "";
      if (timePassed.days > 0) {
        str += timePassed.days + " " + stringUtils.toPlural("day", timePassed.days) + " ";
      }
      if (timePassed.hours > 0) {
        str += timePassed.hours + " " + stringUtils.toPlural("hour", timePassed.hours) + " ";
      }
      if (timePassed.minutes > 0) {
        str += timePassed.minutes + " " + stringUtils.toPlural("minute", timePassed.minutes) + " ";
      }
      str = str.trim();
      if (!str) {
        return "less than a minute ago";
      }
      return timePassed.days >= 7 ? dateTimeString : str + " " + "ago";
    },
    /**
     * Translated Utility that auto formats a date to a relative date if it is less than a week old
     *
     * @param {Date} date JS formatted timestamp
     * @returns {string} formatted date
     */
    i18nToRelativeString: function (date) {
      var timePassed = exports.getTimePassed(date);
      var dateTimeString = I18nTranslation.getLocalizedDate(date, "full", true);
      var day = "day";
      var hour = "hour";
      var minute = "minute";
      var ago_label = "ago";
      var str = "";
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        day = I18nTranslation.translate("content_studio_day");
        hour = I18nTranslation.translate("content_studio_hour");
        minute = I18nTranslation.translate("content_studio_minute");
        ago_label = I18nTranslation.translate("content_studio_ago_label");
        if (timePassed.days > 0) {
          day = I18nTranslation.translate("content_studio_day_plural");
          str += timePassed.days + " " + day + " ";
        }
        if (timePassed.hours > 0) {
          hour = I18nTranslation.translate("content_studio_hour_plural");
          str += timePassed.hours + " " + hour + " ";
        }
        if (timePassed.minutes > 0) {
          minute = I18nTranslation.translate("content_studio_minute_plural");
          str += timePassed.minutes + " " + minute + " ";
        }
        str = str.trim();
        if (!str) {
          return I18nTranslation.translate("content_studio_less_than_a_minute");
        }
        return timePassed.days >= 7 ? dateTimeString : str + " " + ago_label;
      }
      this.toRelativeString(date);
    },
    /**
     *
     * @param {String} timezoneId from user
     * @param {String} date date for time of year for timezone name
     * @return {String} timeZoneDisplay values for displaying timezone
     */
    getTimezoneName: function (timezoneId, date) {
      if (!exports.isValidTimeZone(timezoneId)) {
        return "";
      }
      // get timeZone and return abbreviations if US based, full name if International
      var timeZoneObject = Intl.DateTimeFormat("en-US", {
        timeZone: timezoneId,
        timeZoneName: "long"
      });
      var dateToLabel = date || new Date();
      timeZoneObject = timeZoneObject.formatToParts(dateToLabel);
      var timeZoneDisplay = "";
      timeZoneObject.forEach(function (obj) {
        if (obj.type === "timeZoneName") {
          timeZoneDisplay = obj.value;
        }
      });
      switch (timeZoneDisplay) {
        case "Pacific Standard Time":
        case "Pacific Daylight Time":
          timeZoneDisplay = "PT";
          break;
        case "Mountain Standard Time":
        case "Mountain Daylight Time":
          timeZoneDisplay = "MT";
          break;
        case "Central Standard Time":
        case "Central Daylight Time":
          timeZoneDisplay = "CT";
          break;
        case "Eastern Standard Time":
        case "Eastern Daylight Time":
          timeZoneDisplay = "ET";
          break;
        default:
          break;
      }
      return timeZoneDisplay;
    },
    /**
     * For using browser Intl API, the timezone needs to be IANA compliant to be use to set the date formats
     * @param {String} tz from defaults settings
     * @return {Boolean} whether the timezone string is valid
     */
    isValidTimeZone: function (tz) {
      try {
        Intl.DateTimeFormat(undefined, {
          timeZone: tz
        });
        return true;
      } catch (ex) {
        // log dojo isValidTimeZone call
        mLogger.info("timezone-incorrect-date-display", "User timezone.id is not valid for Intl.DateTimeFormat (dojo)", {
          timezoneId: tz,
          log_classification: "sensitive"
        });
        return false;
      }
    },
    /**
     * Utility to convert a date object _to_ a user's timezone or _from_ a user's timezone
     *
     * @param {Date} date a date to parse
     * @param {Boolean} isFromUserTimezone is the date to pare in the user's timezone?
     * @returns {Date} date object adjusted for user timezone
     */
    getTimezoneOffsetDate: function (date, isFromUserTimezone) {
      //If the user has a timezone specified, adjust the date object for string formatting by dojo locale
      if (user.timezoneOffset !== null && !isNaN(user.timezoneOffset)) {
        var today = new Date();
        var oneMinuteInMS = 1000 * 60;
        var oneHourInMS = oneMinuteInMS * 60;
        if (isFromUserTimezone) {
          date = new Date(date.getTime() + today.getTimezoneOffset() * oneMinuteInMS * -1 - user.timezoneOffset * oneHourInMS);
        } else {
          date = new Date(date.getTime() - today.getTimezoneOffset() * oneMinuteInMS * -1 + user.timezoneOffset * oneHourInMS);
        }
      }
      return date;
    },
    /**
     * Utility to convert a date object _to_ a user's timezone or _from_ a user's timezone
     *
     * @param {Date} date a date to parse
     * @param {Number} tzOffsetValue is the float of the tz offset of the date sent
     * @returns {Date} date object adjusted for user timezone
     */
    getTimezoneOffsetDateWithTzOffset: function (date, tzOffsetValue) {
      //If the date has a timezone specified, adjust the date object for string formatting by dojo locale
      if (tzOffsetValue || tzOffsetValue === 0) {
        var oneMinuteInMS = 1000 * 60;
        var oneHourInMS = oneMinuteInMS * 60;
        date = new Date(date.getTime() - date.getTimezoneOffset() * oneMinuteInMS * -1 + tzOffsetValue * oneHourInMS);
      }
      return date;
    },
    /**
     * Utility to return a nicely formatted day of week, date, and time string in the user's timezone
     *
     * @param {Date} value a date to parse
     * @returns {string} date string in format "DayOfWeek Month Day (, optional year) Hour:Minute AM/PM"
     */
    getDateString: function (value) {
      value = exports.getTimezoneOffsetDate(value);
      // compare date time
      var today = new Date();
      var dateStr =
      // weekday
      locale.format(value, {
        selector: "date",
        datePattern: "EEE"
      }) + ", "
      // month
      + locale.format(value, {
        selector: "date",
        datePattern: "MMMM"
      }) + " "
      // day
      + numberUtils.toOrdinal(value.getDate())
      // year (if not this year)
      + (today.getFullYear() !== value.getFullYear() ? ", " + value.getFullYear() : "") + " "
      // time
      + locale.format(value, {
        selector: "date",
        datePattern: "h:mm a"
      });
      return dateStr;
    },
    // duplicate this method - add parameter for tzOffset
    /**
     * Utility to return a nicely formatted day of week, date, and time string in the user's timezone
     *
     * @param {Date} value a date to parse
     * @param {Number} tzOffsetValue is float value of the tz offset from the specific date sent
     * @returns {string} date string in format "DayOfWeek Month Day (, optional year) Hour:Minute AM/PM"
     */
    getDateStringWithTzOffset: function (value, tzOffsetValue) {
      value = exports.getTimezoneOffsetDateWithTzOffset(value, tzOffsetValue);
      // compare date time
      var today = new Date();
      var dateStr =
      // weekday
      locale.format(value, {
        selector: "date",
        datePattern: "EEE"
      }) + ", "
      // month
      + locale.format(value, {
        selector: "date",
        datePattern: "MMMM"
      }) + " "
      // TODO: for ordering Month and Day add check for user's settings on date_format "US, UK, US24, UK24"
      // day
      + numberUtils.toOrdinal(value.getDate())
      // year (if not this year)
      + (today.getFullYear() !== value.getFullYear() ? ", " + value.getFullYear() : "") + " "
      // time
      + locale.format(value, {
        selector: "date",
        datePattern: "h:mm a"
      });
      // TODO: for datePattern: "h:mm a" add check for user's settings on date_format "US, UK, US24, UK24"

      return dateStr;
    },
    /**
     * Utility to return a nicely formatted time-only string in the user's timezone
     *
     * @param {Date} value a date to parse
     * @param {boolean} useTimezone indicate whether or not to adjust for user's timezone
     * @returns {string} date string in format "Hour:Minute AM/PM"
     */
    getTimeString: function (value, useTimezone) {
      var isTZValid = useTimezone && exports.isValidTimeZone(user.timezoneId);
      if (!isTZValid) {
        var label = USER_BROWSER_TZ ? " " + exports.getTimezoneName(USER_BROWSER_TZ, value) : "";
        return locale.format(value, {
          selector: "date",
          datePattern: "h:mm a"
        }) + label;
      }
      try {
        var time;
        time = Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "2-digit",
          timeZone: user.timezoneId
        });
        var timeTimezoneLabel = exports.getTimezoneName(user.timezoneId, value);
        time = time.format(value);
        time.replace("AM", "am");
        time.replace("PM", "pm");
        time.replace(":00", "");
        if (useTimezone) {
          return time + " " + timeTimezoneLabel;
        }
        return time;
      } catch (e) {}
    },
    /**
     * Utility to return a nicely formatted date-only string in the user's timezone
     *
     * @param {Date} value a date to parse
     * @param {boolean} useTimezone indicate whether or not to adjust for user's timezone
     * @returns {string} date string in format "Month Day (, optional year)"
     */
    getDateOnlyString: function (value, useTimezone) {
      // format using user's timezone
      var isTZValid = useTimezone && exports.isValidTimeZone(user.timezoneId);
      if (isTZValid) {
        var date;
        try {
          date = Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "2-digit",
            weekday: "long",
            timeZone: user.timezoneId
          });
          return date.format(value);
        } finally {}
      }
      if (useTimezone) {
        value = exports.getTimezoneOffsetDate(value);
      }
      var today = new Date();
      var dateStr =
      // month
      locale.format(value, {
        selector: "date",
        datePattern: "MMMM"
      }) + " "
      // day
      + numberUtils.toOrdinal(value.getDate())
      // year (if not this year)
      + (today.getFullYear() !== value.getFullYear() ? ", " + value.getFullYear() : "");
      return dateStr;
    },
    /**
     * formatDateTime - will return
     * @param {Date} date - js date to be formatted
     * @param {String} formatName - will be from a list of constant format names
     * @param {Boolean} userLocale will be false, or a user setting for setting a specific locale
     * @return {String} date as a formatted string by the formatName
     */
    formatDateTime: function (date, formatName, userLocale) {
      var localeOptions = {
        selector: "date"
      };
      if (userLocale) {
        // set the locale with passed in string
        localeOptions.locale = userLocale;
      }
      var dateStr = "";
      var today = new Date();
      var dayPattern = lang.clone(localeOptions);
      dayPattern.datePattern = "EEE";
      var monthPattern = lang.clone(localeOptions);
      monthPattern.datePattern = "MMMM";
      var timePattern = lang.clone(localeOptions);
      timePattern.datePattern = "h:mm a";
      switch (formatName) {
        case this.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR:
          dateStr =
          // month
          locale.format(date, monthPattern) + " "
          // day
          + numberUtils.toOrdinal(date.getDate())
          // year (if not this year)
          + (today.getFullYear() !== date.getFullYear() ? ", " + date.getFullYear() : "");
          break;
        case this.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR_TIME:
          dateStr =
          // month
          locale.format(date, monthPattern) + " "
          // day
          + numberUtils.toOrdinal(date.getDate())
          // year (if not this year)
          + (today.getFullYear() !== date.getFullYear() ? ", " + date.getFullYear() : "") + " "
          // time
          + locale.format(date, timePattern);
          break;
        case this.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME:
          dateStr =
          // weekday
          locale.format(date, dayPattern) + ", "
          // month
          + locale.format(date, monthPattern) + " "
          // day
          + numberUtils.toOrdinal(date.getDate())
          // year (if not this year)
          + (today.getFullYear() !== date.getFullYear() ? ", " + date.getFullYear() : "") + " "
          // time
          + locale.format(date, timePattern);
          break;
        case this.DATE_STRING_FORMAT_NAMES.TIME:
          dateStr = locale.format(date, timePattern);
          break;
        default:
          dateStr =
          // month
          locale.format(date, monthPattern) + " "
          // day
          + numberUtils.toOrdinal(date.getDate())
          // year (if not this year)
          + (today.getFullYear() !== date.getFullYear() ? ", " + date.getFullYear() : "") + " at "
          // time
          + locale.format(date, timePattern);
          break;
      }
      return dateStr;
    },
    /**
     * dateTransform will be used to deserialize and transform objects, representing dates, returned from request for campaign/account collections.
     * @param {Unknown} value this could be a string or an object
     * @property {date_str} - UTC Date from an ISO8601 string without timezone offset data
     * @property {tz_float} - float value of timezone offset in hours
     * @property {date_formatted} - date/time as should be formated per controller action
     * @return {Object} dateObj normalized for displaying dates in app
     */
    dateTransform: function (value) {
      if (typeof value === "string") {
        // this is current default behavior
        return new Date(value);
      }
      if (value instanceof Object) {
        var utcDate = value.date_str ? new Date(value.date_str) : null;
        if (!utcDate) {
          return null;
        }
        var tzOffsetDate = exports.getTimezoneOffsetDateWithTzOffset(utcDate, value.tz_float);
        return lang.mixin(value, {
          utcStr: value.date_str,
          jsDate: tzOffsetDate
        });
      }
      throw "Value is not a String or Object";
    }
  };
  return exports;
});