/**
 * Widget that serves as the primary means of communication between the native mobile ad
 * environment and the slats on the page.
 *
 * @module /mojo/widgets/ads/mobile-checklist/EventRouter.js
 * @see /mojo/views/ads/edit-mobile.js
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, lang, topic, _WidgetBase, _TemplatedMixin) {
  /**
   * Base widget responsible for creating all child placement widgets.
   */
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div><div data-dojo-attach-point='slatContainer'></div></div>",
    campaignUniqueId: null,
    // Each campaign type has it's own interface. This creates a common method for controlling slats.
    slatInterface: null,
    // Device abstraction that allows common communication strategy. Basically iOS or Android.
    deviceInterface: null,
    // Slat currently running.
    currentSlat: null,
    // Device that's currently being used.
    currentDevice: null,
    /**
     * Workhorse of the widget. Create child widgets and establish handlers
     */
    postCreate: function () {
      this._initDeviceInterface();
    },
    /**
     * Create the necessary native environment and listen for feedback from it.
     *
     * @private
     */
    _initDeviceInterface: function () {
      this.currentDevice = new this.deviceInterface();
      this.currentDevice.startup();

      // We use watch as a mechanism for the currentDevice to communicate.
      // Listen for any changes and pass that onto the currently running slat.
      this.currentDevice.watch("selectedSlat", lang.hitch(this, "_handleSlatSwitch"));
      this.currentDevice.watch("save", lang.hitch(this, "_handleBtnPress"));
      this.currentDevice.watch("cancel", lang.hitch(this, "_handleBtnPress"));
      this.currentDevice.watch("back", lang.hitch(this, "_handleBtnPress"));
      this.currentDevice.watch("uploadedImage", lang.hitch(this, "_handleFileUploadSuccess"));
      this.handleEventRouterReady();
    },
    /**
     * Other widgets are going to be responsible for changing the slats.
     * Every time the slat changes, reset the current slat (if applicable) and start fresh.
     *
     * @private
     */
    _handleSlatSwitch: function () {
      this._resetCurrentSlat();
      this.currentSlat = new this.slatInterface({
        slatName: this.currentDevice.selectedSlat.name,
        campaignUniqueId: this.campaignUniqueId,
        useSimulator: this.useSimulator,
        router: this
      });
      this.currentSlat.placeAt(this.slatContainer);
      this.currentSlat.startup();
    },
    /**
     * After a slat is canceled we need to notify the current device
     * The current slat doesn't need to know that it's been canceled or not.
     *
     * @param {object} payload - Empty object mixed with reload boolean
     * @private
     */
    handleSlatCancel: function (payload) {
      this.handleSlatFinished(lang.mixin(payload, {
        reload: false
      }));
    },
    /**
     * Similar to handleSlatCancel, notify the current device the slat is finished.
     *
     * @param {object} payload - Data mixed in with reload boolean
     * @public
     */
    handleSlatSaveSuccessful: function (payload) {
      this.handleSlatFinished(lang.mixin(payload, {
        reload: true
      }));
    },
    /**
     * For some reason the slat save failed. This usually means the user entered invalid content and that needs
     * to be fixed before saving is complete.
     *
     * @public
     */
    handleSlatSaveFailed: function () {
      this.currentDevice.actionSlatSaveFailed();
    },
    /**
     * React to button presses that a user performs in the device interface. Pass the button
     * type on to the current slat for interpretation.
     *
     * @param {string} btnName - save, cancel, or back.
     * @throws if an invalid param is passed
     * @private
     */
    _handleBtnPress: function (btnName) {
      this._requireCurrentSlat();
      switch (btnName) {
        case "save":
          this.currentSlat.actionSave();
          break;
        case "back":
          this.currentSlat.actionBack();
          break;
        case "cancel":
          this.handleSlatCancel({});
          break;
        default:
          throw new Error("Unknown button type");
      }
    },
    /**
     * After router is notified pass the uploaded image data to the Image Block to handle
     * insertion into carousel item.
     *
     * @private
     */
    _handleFileUploadSuccess: function () {
      this._requireCurrentSlat();

      // All data associated with the uploaded image that we can pull from
      var uploadedImageData = this.currentDevice.uploadedImage.imageProperties;
      topic.publish("content/manager/native/upload/success", uploadedImageData);
    },
    /**
     * Call on various events onto the current device for interpretation.
     *
     */
    handleEventRouterReady: function () {
      this.currentDevice.actionEventRouterReady();
    },
    handleSlatLoaded: function () {
      this.currentDevice.actionSlatLoaded();
    },
    handleSlatFinished: function (payload) {
      this.currentDevice.actionSlatFinished(payload);
    },
    handleExpiredSession: function () {
      this.currentDevice.actionExpiredSession();
    },
    handleOpenFileBrowse: function () {
      this.currentDevice.actionOpenFileBrowse();
    },
    /**
     * There's nothing stopping someone from "pressing" a button before a slat is active
     * This method allows us to provide feedback if that happens.
     *
     * @throws if a slat isn't "on".
     * @private
     */
    _requireCurrentSlat: function () {
      if (!this.currentSlat) {
        throw new Error("Action is unavailable. No slat currently enabled");
      }
    },
    /**
     * We'll need to reset this.currentSlat so we can start fresh with a new version.
     *
     * @private
     */
    _resetCurrentSlat: function () {
      if (this.currentSlat) {
        this.currentSlat.destroyRecursive();
      }
    }
  });
});