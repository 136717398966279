define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/peaches2/utils"], function (declare, base, p2Utils) {
  return declare([base], {
    onReady: function () {
      var urlParams = new URLSearchParams(document.location.search); // if IE support is needed we can always do ioQuery.queryToObject(document.location.search)
      var messageVerb = urlParams.get("messageVerb");
      var discountName = urlParams.get("discountName");
      var tabToView = urlParams.get("tab") || "";
      var tabParam = tabToView === "" ? "" : "?tab=" + tabToView;
      if (messageVerb && discountName) {
        p2Utils.utils.setFlash("Discount <b>" + discountName + "</b> has been successfully " + messageVerb + ".", "success");
        window.history.replaceState(null, "", document.location.pathname + tabParam); //remove message params from the URL;
      }
    }
  });
});