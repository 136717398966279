define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking", "mojo/context", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, EcsEvents, EcsTracking, context, flags, query, on, domAttr) {
  return declare([base], {
    onReady: function () {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "list_detail",
          user_id: this.userId,
          login_id: this.loginId,
          list_id: this.listId
        });
      }

      // ECS page viewed: 93
      var previousUrlFull = window.top.history.state ? window.top.history.state.referrer : window.top.document.referrer;
      var previousPageUrl = previousUrlFull ? new URL(previousUrlFull).pathname : "";
      var zeroState = false;
      var educationBlock = query("#education-block");
      if (educationBlock.length) {
        zeroState = true;
      }
      EcsTracking.track(EcsEvents.AudienceOrganizationViewGroupsPageEvent, {
        previous_page_url: previousPageUrl,
        zero_state: zeroState
      });

      // ECS learn more forms : 94
      var learnMoreSignupForm = query("#learn-more-signup-forms")[0];
      if (learnMoreSignupForm) {
        on(learnMoreSignupForm, "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "link",
            ui_object_detail: "learn_more_sign_up_forms",
            ui_action: "clicked",
            ui_access_point: "center"
          });
        });
      }

      // ECS learn more segmentation : 95
      var learnMoreSegmentation = query("#learn-more-segmentation")[0];
      if (learnMoreSegmentation) {
        on(query("#learn-more-segmentation")[0], "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "link",
            ui_object_detail: "learn_more_segmentation",
            ui_action: "clicked",
            ui_access_point: "center"
          });
        });
      }

      // ECS create groups : 96
      var listAddGroup = query("#lists-add-group")[0];
      if (listAddGroup) {
        on(query("a", listAddGroup), "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "button",
            ui_object_detail: "create_groups",
            ui_action: "clicked",
            ui_access_point: "center",
            zero_state: zeroState
          });
        });
      }

      // ECS save group : 97
      var saveNewGroup = query("#save-new-grouping")[0];
      if (saveNewGroup) {
        on(saveNewGroup, "click", function () {
          var groupCategoryName = query("#new-grouping-name")[0].value;
          var groups = [];
          query('[data-dojo-id="new_group_list"]').forEach(function (item) {
            if (item) {
              query("input", item).forEach(function (input) {
                if (input.value) {
                  groups.push(input.value);
                }
              });
            }
          });
          var groupNames = groups.join(",");
          var numberOfGroups = groups.length;
          var signupFormOption = "";
          query('[name="new-grouping-type"]').forEach(function (item) {
            if (item.checked) {
              // eslint-disable-next-line no-nested-ternary
              signupFormOption = item.value === "checkboxes" ? "as_checkboxes"
              // eslint-disable-next-line no-nested-ternary
              : item.value === "radio" ? "as_radio_buttons"
              // eslint-disable-next-line no-nested-ternary
              : item.value === "dropdown" ? "as_a_dropdown" : "dont_show";
              return;
            }
          });
          EcsTracking.track(EcsEvents.AudienceOrganizationClickSaveWhileCreatingAGroupsEvent, {
            group_category_name: groupCategoryName,
            group_names: groupNames,
            number_of_groups: numberOfGroups,
            signup_form_option: signupFormOption
          });
        });
      }

      // ECS edit a group category : 98
      var editAGroupCategory = query("#edit-group-category")[0];
      if (editAGroupCategory) {
        on(editAGroupCategory, "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "link",
            ui_object_detail: "edit",
            ui_action: "clicked",
            ui_access_point: "group_category_slat"
          });
        });
      }

      // ECS save group category name: 99
      var saveAGroupCategory = query("#save-edit-group-category")[0];
      if (saveAGroupCategory) {
        on(saveAGroupCategory, "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "link",
            ui_object_detail: "save",
            ui_action: "clicked",
            ui_access_point: "group_category_slat"
          });
        });
      }

      // ECS delete group category name: 100
      var deleteAGroupCategory = query("#delete-group-category")[0];
      if (deleteAGroupCategory) {
        on(deleteAGroupCategory, "click", function () {
          EcsTracking.track(EcsEvents.AudienceOrganizationGroupsEvent, {
            action: "engaged",
            ui_object: "link",
            ui_object_detail: "delete",
            ui_action: "clicked",
            ui_access_point: "group_category_slat"
          });
        });
      }
    }
  });
});