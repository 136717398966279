/**
 * Android Simulator for the web
 * @module /mojo/widgets/ads/mobile-checklist/AndroidSimulator.js
 * @see /mojo/views/ads/edit-mobile.js
 *
 * A basic UI that simulates what's necessary for iOS and Android.
 *
 * You'll find we reference window quite often in this. This is intended to behave as the Android and iOS
 * bridges do.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin",
// Templates
"dojo/text!./templates/AndroidSimulator.html"], function (declare, lang, domAttr, domClass, domConstruct, domStyle, utils, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    // The inputs you'll see at the top of the page.
    templateString: tplStr,
    // Reference to the overall interface domNode. Only used for styling purposes.
    interfaceContainer: null,
    // We need the buttons to be a bit different for different slats.
    slatActionsMap: {
      connect: {
        label: "Channel",
        saveLabel: "Save",
        cancel: true
      },
      audience: {
        label: "Audience",
        saveLabel: "Save",
        cancel: true
      },
      budget: {
        label: "Budget",
        saveLabel: "Save",
        cancel: true
      },
      billing: {
        label: "Billing",
        saveLabel: "Done",
        cancel: false
      },
      cvv: {
        label: "Security code",
        saveLabel: "Save",
        cancel: true
      },
      tos: {
        label: "Terms Of Service",
        saveLabel: "Agree",
        cancel: true
      },
      content: {
        label: "",
        saveLabel: "",
        cancel: false
      }
    },
    // State of current slat. Every time it changes perform some actions.
    currentSlat: null,
    _setCurrentSlatAttr: function (newVal) {
      // Handle some interface things
      domClass.toggle(this.interfaceContainer, "hide", newVal === "");
      domClass.toggle(this.backBtn, "dim6 no-clicky", newVal === "");
      domClass.toggle(this.buttonContainer, "hide", newVal === "");
      domClass.toggle(this.mockUI, "hide", newVal !== "");
      if (newVal !== "") {
        // Handle labels and buttons
        this.slatLabel.innerText = this.slatActionsMap[newVal].label;
        this.saveBtn.innerText = this.slatActionsMap[newVal].saveLabel;
        domClass.toggle(this.cancelBtn, "hide", this.slatActionsMap[newVal].cancel === false);

        // Actually request a new slat
        window.nativeEvents.userInSlat(newVal);
      }
    },
    postCreate: function () {
      this._initWebToNativeEvents();

      // Make the interface box a bit prettier. Hide it until a slat has been loaded.
      domClass.add(this.interfaceContainer, "hide position--relative size1of3 size1of2@tablet centered border-gray overflow-y--auto");
      // Set an experimental height. Just an approximation
      domStyle.set(this.interfaceContainer, {
        height: "600px"
      });
      // Visually move the back button bar beneath it.
      domConstruct.place(this.actionBar, this.interfaceContainer, "after");
      // Configure current slat to empty just for the first load.
      this.set("currentSlat", "");
    },
    /**
     * This is a rough example of what you might see on the Android or iOS side. Methods being registered at a
     * window level that call some sort of action. This method simply creates the window.Android object and then
     * establishes methods that are callable from anywhere.
     *
     * @private
     */
    _initWebToNativeEvents: function () {
      window.Android = {
        "readyToReceiveEvents": lang.hitch(this, "_demoWebToNativeEvent", "The page is ready. Start requesting slats!"),
        "slatLoaded": lang.hitch(this, "_handleSlatLoaded"),
        "slatSaveFailed": lang.hitch(this, "_demoWebToNativeEvent", "The selected slat failed validation."),
        "isFinished": lang.hitch(this, "_handleIsFinished"),
        "expiredSession": lang.hitch(this, "_demoWebToNativeEvent", "The session was expired, better reload."),
        "openFileBrowse": lang.hitch(this, "_demoWebToNativeEvent", "Open the native file browser.")
      };
    },
    /**
     * Open a specific Checklist Slat.
     *
     * @param {Object} e - Standard click event
     * @private
     */
    _openSlat: function (e) {
      this.set("currentSlat", e.target.dataset.slatName ? e.target.dataset.slatName : "");
    },
    /**
     * We need to give some kind of feedback that the slat interface did something. Short of wiring a bunch of
     * interactions for the simulator, we are instead simply showing a toaster message.
     *
     * @param {string} demoMessage - Something to put in the toast message.
     * @private
     */
    _demoWebToNativeEvent: function (demoMessage) {
      utils.toast(demoMessage);
    },
    /**
     * Once the slat is 100% loaded do some small tweaks.
     *
     * TODO: Add support for back button once wired
     *
     * @private
     */
    _handleSlatLoaded: function () {
      this._demoWebToNativeEvent("The slat was loaded. Hooray.");
    },
    /**
     * Simulate the experience of a native device once a slat is done.
     * We won't actually do any reloading.
     *
     * TODO: Add support for back button once wired
     *
     * @param {String} payload - Chunk of data passed through the bridge. Stringified JSON.
     * @private
     */
    _handleIsFinished: function (payload) {
      // Reset the slat input and nodes.
      this.set("currentSlat", "");

      // Real Android requires the payload to be a String. Convert it back into a usable object.
      payload = JSON.parse(payload);
      // Give feedback for the CVV
      if (payload.cvv) {
        this._demoWebToNativeEvent("CVV Slat was closed. CVV: " + payload.cvv);

        // Skip the rest of this function.
        return;
      }

      // Give feedback to what happened.
      var message = payload.reload === true ? "The slat was saved. You should reload the Detail View." : "The slat was canceled. Don't bother reloading.";
      this._demoWebToNativeEvent(message);
    },
    // Button actions. Call out to the Native Events in _DeviceMixin
    // Click handlers are within the template.
    _handleSave: function () {
      window.nativeEvents.slatSaved();
    },
    _handleCancel: function () {
      window.nativeEvents.slatCanceled();
    },
    _handleBack: function () {
      window.nativeEvents.backPressed();
      utils.toast("Back event called but isn't wired to anything.");
    }
  });
});