/**
 * Mixin that adds functions that can be used to store state on the url hash.
 * Uses postMixInProperties, so call this.inherited if your main widget overrides it.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo/Stateful"], function (declare, lang, array, mUrl, Stateful) {
  return declare(null, {
    postMixInProperties: function () {
      this.inherited(arguments);
      this.createHashParams();
    },
    createHashParams: function () {
      var params = mUrl.getHashParams();
      if (params['t'] == this.id) {
        delete params['t'];
        this._hashParams = new Stateful(params);
      } else {
        this._hashParams = new Stateful();
      }
      this._persisted = true;
    },
    getAllHashParams: function () {
      var hash = {};
      for (var key in this._hashParams) {
        if (this._validHashKey(key)) hash[key] = this._hashParams[key];
      }
      return hash;
    },
    getHashParam: function (key) {
      return this._hashParams.get(key);
    },
    setHashParam: function (key, value) {
      this._hashParams.set(key, value);
      this.updateHash();
    },
    unsetHashParam: function (key) {
      this._hashParams.set(key, null);
      this.updateHash();
    },
    restoreHashParams: function () {
      this.updateHash();
    },
    updateHash: function () {
      var params = {};
      for (var key in this._hashParams) {
        if (this._validHashKey(key)) {
          params[key] = this._hashParams[key];
        }
      }
      // Store 't' as the ID of this widget
      params['t'] = this.id;
      mUrl.setHashParams(params);
    },
    _validHashKey: function (value) {
      return this._hashParams.hasOwnProperty(value) && value.indexOf("_") != 0 && this._hashParams[value] != null;
    }
  });
});