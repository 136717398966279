define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, base, domAttr, on, mUrl) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      on(this.$el.searchEmailForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.findEmail();
      });
    },
    findEmail: function () {
      var self = this;
      var email = encodeURIComponent(this.$el.searchEmailInput.value);
      var url = mUrl.toUrl("/peaches2/users/lists}", {
        _s: "list-activity",
        user_id: self.pageJs.userAttrs.userId,
        search_email: email
      });
      this.pageJs.tabContainer.listActivitySection.set("href", url);
    }
  });
});