define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, lang, base, domAttr, on, topic, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    autoresponderRendered: null,
    onReady: function () {
      var self = this;
      topic.subscribe("mojo/analytics/List/autoresponders-list/render", lang.hitch(this, function (list) {
        var pageJs = this.pageJs;
        self.pageJs.tabContainer.autoresponderSection.set("title", "Autoresponder (" + list.total + ")");
        self.parse(this.elId);
        if (self.$group.autoresponderEmailTool) {
          self.$group.autoresponderEmailTool.forEach(function (el) {
            registry.byId(el.id).set("onChange", function () {
              pageJs.openEmailTool(this);
            });
          });
        }
        if (self.$group.deletAutoresponderButtons) {
          self.$group.deletAutoresponderButtons.forEach(function (el) {
            on(el, "click", function () {
              pageJs.deleteCampaign(domAttr.get(el, "data-campaign-id"), null, "autoresponder");
            });
          });
        }
        if (self.$group.manageAutoresponderBounces) {
          self.$group.deletAutomanageAutoresponderBouncesresponderButtons.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageBounces(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.manageAutoresponderLinks) {
          self.$group.manageAutoresponderLinks.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageLinks(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (self.$group.viewAutoresponderDetails) {
          self.$group.viewAutoresponderDetails.forEach(function (el) {
            on(el, "click", function () {
              pageJs.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (!this.autoresponderRendered) {
          this.autoresponderRendered = true;
          pageJs.loadFilter("autoresponder");
        }
      }));
    }
  });
});