/**
 * @module mojo/widgets/contact-profile/EmailMarketingEngagementHelpTip
 *
 * Displays a tool tip to give more information about email Marketing Engagement Rating, and link out to the KB article.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/HelpTip"], function (declare, domConstruct, HelpTip) {
  return declare([HelpTip], {
    label: "",
    rating: "",
    toolTipText: "",
    href: "https://mailchimp.com/help/about-email-marketing-engagement/",
    postMixInProperties: function () {
      switch (this.rating) {
        case "Rarely":
          this.toolTipText = "This is someone who is not very engaged and rarely opens and clicks your emails.";
          break;
        case "Sometimes":
          this.toolTipText = "This is someone who is moderately engaged and sometimes opens and clicks your emails.";
          break;
        case "Often":
          this.toolTipText = "This is someone who is highly engaged and often opens and clicks your emails.";
          break;
        default:
          this.toolTipText = "This rating does not exist";
      }
    },
    postCreate: function () {
      var innerHtml = "<li>" + this.toolTipText + "</li>";
      this.inherited(arguments);
      this.addLine(innerHtml, "first");
    }
  });
});