define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/api/page", "mojo/page/statustypes", "mojo/widgets/campaigns/CampaignUrlErrorDialog", "mojo/widgets/checklist-editor/items/CampaignName", "mojo/widgets/checklist-editor/Container", "mojo/widgets/checklist-editor/items/List", "mojo/widgets/checklist-editor/items/PageContent", "mojo/widgets/checklist-editor/items/PageSettings", "mojo/widgets/checklist-editor/status/PageStatus", "mojo/widgets/checklist-editor/items/Store", "mojo/widgets/checklist-editor/items/PageTitle", "mojo/widgets/checklist-editor/items/PageSocialShare", "mojo/widgets/checklist-editor/items/PageUrl", "mojo/widgets/InfoBlock", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "mojo/url", "mojo/user"], function (declare, domConstruct, base, lang, ioQuery, pageApi, PageStatusTypes, CampaignUrlErrorDialog, CampaignName, ChecklistEditorContainer, List, Content, PageSettings, PageStatus, Store, PageTitle, PageSocialShare, PageUrl, InfoBlock, I18nTranslation, context, flags, logger, mUrl, user) {
  // Placeholders
  var isComplete = function (page) {
    return true;
  };
  var isValid = function (page) {
    return true;
  };
  var LP_TRACKING_CATEGORY = "landing pages editor";
  var LP_TRACKING_ACTION = "page title and site icon";
  return declare([base], {
    pageTitle: "Page Title and Site Icon",
    editDesign: "Edit Design",
    editAudience: "Edit Audience",
    andTags: "and Tags",
    audience: "Audience",
    contactsWill: "Contacts will be added to ",
    editTitle: "Edit Title And Icon",
    socialShare: "Social Share Preview",
    editPreview: "Edit Preview",
    manageTheContent: "Manage the content that appears when you share your page URL in social posts.",
    url: "URL",
    content: "Content",
    designTheContent: "Design the content for your landing page.",
    editTags: "Edit Tags",
    nameIsTooLong: "Your landing page name is too long.",
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.pageTitle = I18nTranslation.translate("landing_pages_page_title");
        this.editDesign = I18nTranslation.translate("landing_pages_edit_design");
        this.editAudience = I18nTranslation.translate("landing_pages_edit_audience");
        this.andTags = I18nTranslation.translate("landing_pages_and_tags");
        this.audience = I18nTranslation.translate("landing_pages_audience");
        this.contactsWill = I18nTranslation.translate("landing_pages_contacts_will");
        this.editTitle = I18nTranslation.translate("landing_pages_edit_title");
        this.socialShare = I18nTranslation.translate("landing_pages_social_share");
        this.editPreview = I18nTranslation.translate("landing_pages_edit_preview");
        this.manageTheContent = I18nTranslation.translate("landing_pages_manage_the_content");
        this.url = I18nTranslation.translate("landing_pages_url");
        this.content = I18nTranslation.translate("landing_pages_content");
        this.designTheContent = I18nTranslation.translate("landing_pages_design_the_content");
        this.editTags = I18nTranslation.translate("landing_pages_edit_tags");
        this.nameIsTooLong = I18nTranslation.translate("landing_pages_name_is_too_long");
      }
    },
    onReady: function () {
      this.postMixInProperties();
      this.page.content_button = this.editDesign;

      //TODO (ogaius-obaseki) handle case where request mapper is down
      if (this.isRequestMapperDown) {
        var errorDialog = new CampaignUrlErrorDialog({
          pageUrl: this.page.page_url.url,
          pageId: this.page.page_id
        });
      }

      // If we have entered this page from a link to edit the audience/tags (as indicated by query param), set that slat to active
      var uri = window.location.href;
      var query = uri.substring(uri.indexOf("?") + 1, uri.length);
      var queryObject = ioQuery.queryToObject(query);
      this.isAudienceSlatActive = queryObject["active_slat"] === "audience";

      // when adding the store, the connectedListStore module will be created for the intent: signup, product (list, store)
      // when list/store is updated, include PageSettings in the list of conditional checklist items
      var connectedListStore;
      var isPageDraftStatus = this.page.status === PageStatusTypes.DRAFT;
      var taggingEnabled = this.page.page_has_signup_block && user.hasRole("manager");
      var tagLabel = taggingEnabled ? " " + this.andTags : "";
      var storeEditLabel = taggingEnabled && this.page.status !== "draft" ? "Edit Tags" : "Edit Store";
      var audienceEditLabel = taggingEnabled && this.page.status !== "draft" ? this.editTags : this.editAudience;
      var tagPreview = "";
      var formatTagPreview = function (signupTags) {
        var tagQty = signupTags.length;
        if (tagQty > 0) {
          tagPreview = " and tagged with <span class='font-weight--bold'>" + encodeString(signupTags[0].tag_name) + "</span>";
        }
        if (tagQty > 1) {
          var additionalTagsPreview = formatAdditionalTagsPreview(signupTags);
          tagPreview += " and <span class='tags-preview-container position--relative font-weight--bold'> " + (tagQty - 1);
          tagPreview += "<span class=' c-meterToolTip_bubble position--absolute display--none v-bg-gray-dark border-radius--lv1 padding--lv2 !padding-top--lv1 !padding-bottom--lv1'>" + additionalTagsPreview + "</span></span> more";
        }
        function formatAdditionalTagsPreview(tags) {
          var formattedTags = "";
          tags.forEach(function (tag, index) {
            if (index > 0) {
              formattedTags += "<span class='white-space--nowrap'>" + encodeString(tag.tag_name) + "</span>";
            }
          });
          return formattedTags;
        }
        function encodeString(str) {
          return str.replace(/</g, "&lt;");
        }
        return tagPreview;
      };
      if (taggingEnabled) {
        tagPreview = formatTagPreview(this.page.signup_tags);
      }
      if (this.page.store_id) {
        connectedListStore = {
          label: "Store" + tagLabel,
          description: "New subscribers will be added to the audience ",
          tagPreview: tagPreview,
          formatTagPreview: formatTagPreview,
          actionLabel: "Set Store",
          editLabel: storeEditLabel,
          contentWidget: Store,
          isComplete: function (model) {
            return !!model.list.id;
          },
          isValid: isValid,
          isDisabled: taggingEnabled ? false : !isPageDraftStatus,
          canToggleAvailability: taggingEnabled ? true : isPageDraftStatus,
          isActive: this.isAudienceSlatActive
        };
      } else {
        connectedListStore = {
          label: this.audience + tagLabel,
          description: this.contactsWill,
          tagPreview: tagPreview,
          formatTagPreview: formatTagPreview,
          actionLabel: "Set Audience",
          editLabel: audienceEditLabel,
          contentWidget: List,
          isComplete: function (model) {
            return !!model.list.id;
          },
          isValid: isValid,
          isDisabled: !user.hasRole("author") || !(isPageDraftStatus || taggingEnabled),
          canToggleAvailability: taggingEnabled ? true : isPageDraftStatus,
          isActive: this.isAudienceSlatActive
        };
      }
      var editingDisabled = !user.hasRole("author") || this.page.content_type === "multichannel" && !user.flagIsOn(flags.EDITING_PLATFORM_LANDING_PAGE_ALPHA);
      // editingDisabled disables editing LP made with the new editor since LP can now be made on the mobile app with the new editor
      // However we want to enable editing LP with the new editor is the LP alpha is turned on for the user in the future

      var checklistItems = [{
        label: "Name",
        position: "before",
        widget: CampaignName,
        isComplete: isComplete,
        isValid: isValid,
        validationMessage: this.nameIsTooLong
      }, {
        label: this.pageTitle,
        editLabel: this.editTitle,
        contentWidget: PageTitle,
        isComplete: PageTitle.isComplete,
        isValid: PageTitle.isValid,
        isDisabled: !user.hasRole("manager"),
        onActivate: function () {
          logger.googleAnalytics.trackEvent(LP_TRACKING_CATEGORY, LP_TRACKING_ACTION, "edit", {
            dimension58: "cta type",
            dimension59: "button"
          });
        },
        trackSave: function () {
          logger.googleAnalytics.trackEvent(LP_TRACKING_CATEGORY, LP_TRACKING_ACTION, "save", {
            dimension58: "cta type",
            dimension59: "button"
          });
        },
        trackCancel: function () {
          logger.googleAnalytics.trackEvent(LP_TRACKING_CATEGORY, LP_TRACKING_ACTION, "cancel", {
            dimension58: "cta type",
            dimension59: "inline"
          });
        }
      }, {
        label: this.socialShare,
        editLabel: this.editPreview,
        description: this.manageTheContent,
        contentWidget: PageSocialShare,
        isComplete: PageSocialShare.isComplete,
        isValid: PageSocialShare.isValid
      }, {
        label: this.url,
        contentWidget: PageUrl,
        isComplete: PageUrl.isComplete,
        isValid: PageUrl.isValid,
        isDisabled: !this.page.page_url.canCustomizeArchiveUrl && !user.hasRole("manager"),
        canToggleAvailability: this.page.page_url.canCustomizeArchiveUrl
      }];
      checklistItems.push(connectedListStore);
      checklistItems.push({
        label: this.content,
        description: this.designTheContent,
        contentWidget: Content,
        actionLabel: this.page.content_button,
        editLabel: this.page.content_button,
        errorLabel: this.page.content_button,
        isComplete: Content.isComplete,
        isValid: Content.isValid,
        isDisabled: editingDisabled,
        canToggleAvailability: !editingDisabled,
        activate: lang.hitch(this, function () {
          if (this.page.has_template === false) {
            window.location.href = mUrl.toUrl(this.page.select_template_url);
          } else {
            window.location.href = mUrl.toUrl("/landing-pages/design", {
              id: this.page.page_id
            });
          }
        })
      });
      checklistItems.push({
        label: "Settings",
        position: "after",
        widget: PageSettings,
        isComplete: isComplete,
        isValid: isValid
      });
      var checklist = new ChecklistEditorContainer({
        statusWidget: PageStatus,
        model: pageApi.createFromProperties(this.page),
        items: checklistItems,
        isValid: isValid,
        onSave: function (data, model) {
          // TODO: should I do this in CampaignUrlDialog
          // strip model/data duplicates
          var hasExistingUrl = model.campaignUrl && model.campaignUrl.mostRecentArchiveMapping !== null;
          if (data.campaign_url && hasExistingUrl && data.campaign_url.brand_token && model.campaignUrl.brandToken === data.campaign_url.brand_token) {
            delete data.campaign_url.brand_token;
          }
          var updateTagPreview = function (preview) {
            checklistItems.filter(function (item) {
              return item.tagPreview !== undefined;
            })[0].tagPreview = preview;
          };
          if (taggingEnabled && data.tagPreview) {
            updateTagPreview(data.tagPreview);
          } else {
            updateTagPreview("");
          }

          //Ga track - social share preview
          if (data.socialShareImage !== undefined) {
            logger.googleAnalytics.trackEvent("landing pages editor", "social share", "image save");
          }
          var title = data.title;
          if (title) {
            title = title.replace(/&#039;/g, "'");
          }
          data.title = title;
          return model.patch(data);
        }
      });
      checklist.placeAt(this.$el.checklist);
    }
  });
});