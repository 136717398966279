define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/context", "mojo/lib/flags"], function (declare, xhr, mUrl, context, flags) {
  var PaymentStore = declare(null, {
    constructor: function (properties) {
      this.name = properties.name;
      this.platform = properties.platform;
      this.store_id = properties.store_id;
      this.isSyncing = properties.isSyncing;
      this.currencySymbol = properties.currencySymbol;
      this.currency = properties.currency;
      this.min_charge_amount = properties.min_charge_amount;
      this.max_charge_amount = properties.max_charge_amount;
    }
  });
  var PaymentItem = declare(null, {
    constructor: function (product) {
      this.description = product.description;
      this.image_url = product.image_url;
      this.price = product.price;
      this.id = product.product_id;
      this.title = product.title;
    }
  });
  var SquareProductItem = declare(null, {
    constructor: function (product) {
      this.title = product.title;
      this.description = product.description;
      this.price = product.price;
      this.price_amount = product.price_amount;
      this.id = Number(product.product_id);
      this.product_foreign_id = product.product_foreign_id;
      this.product_variant_foreign_id = product.product_variant_foreign_id;
      this.sku = product.sku;
      this.product_image_url = product.product_image_url;
    },
    toJSON: function () {
      return {
        title: this.title,
        description: this.description,
        price: this.price,
        price_amount: this.price_amount,
        id: this.id,
        product_foreign_id: this.product_foreign_id,
        product_variant_foreign_id: this.product_variant_foreign_id,
        sku: this.sku,
        product_image_url: this.product_image_url
      };
    }
  });
  return {
    /**
     * @return {Promise} Resolves to an array of EcommerceStore
     */
    getPaymentStore: function () {
      return xhr.get(mUrl.toUrl("channels/get-payment-stores"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return this.createPaymentStores(data);
      }.bind(this));
    },
    createProduct: function (productObj) {
      var promise = xhr.post(mUrl.toUrl("ecommerce/products/create"), {
        handleAs: "json",
        data: productObj
      }).then(function (res) {
        if (res.success) {
          return {
            product: new SquareProductItem(res.data.product)
          };
        }
        return {
          error: res.error
        };
      });
      return promise;
    },
    patchProduct: function (productObj, product_id) {
      var promise = xhr.post(mUrl.toUrl("ecommerce/products/patch", {
        "id": product_id
      }), {
        handleAs: "json",
        data: {
          patch: JSON.stringify(productObj)
        }
      }).then(function (res) {
        if (res.success) {
          var editedProduct = new SquareProductItem(res.data.product);
          return {
            product: editedProduct.toJSON()
          };
        }
        return {
          error: res.error
        };
      });
      return promise;
    },
    /**
     * @param {string} storeId is the id of payment store
     * @return {Promise} Resolves to a Object with isSyncing or error
     */
    startPaymentStoreSync: function (storeId) {
      return xhr.post(mUrl.toUrl("ecommerce/payment-store/sync"), {
        handleAs: "json",
        "data": {
          "store_id": storeId
        }
      });
    },
    createPaymentStores: function (stores) {
      return stores.map(function (store) {
        return new PaymentStore(store);
      });
    },
    getStoreProducts: function (storeId) {
      var url = mUrl.toUrl("/ecommerce/products/get-products", {
        store_id: this.storeId
      });
      var req = xhr.post(url, {
        handleAs: "json",
        data: {
          store_id: storeId
        }
      });
      // Let's return id, name, label which are used to power the FilteringSelect UI
      return req.then(function (res) {
        var products = res.data && res.data.products;
        if (res.success) {
          if (Array.isArray(products) && products.length) {
            return products.map(function (product) {
              // Wrap http:// image URLs to prevent insecure content warnings
              product.product_image_url = mUrl.addProxy(product.product_image_url);
              var squareProduct = new SquareProductItem(product);
              // the listing of products wants an object not a module with a constructor.
              // only return the data
              return squareProduct.toJSON();
            });
          }
          return [];
        }
        // display error
        // TODO: todd-gandee, use toaster to Feedback to display error?
        return [];
      }, function (error) {
        // display toaster for request to get products
        return [];
      });
    },
    getOneStoreProduct: function (storeId, productId) {
      var url = mUrl.toUrl("/ecommerce/products-search", {
        store_id: this.storeId,
        type: "singleProduct"
      });
      var req = xhr.post(url, {
        handleAs: "json",
        data: {
          store_id: storeId,
          product_id: productId
        }
      });
      // Let's return id, name, label which are used to power the FilteringSelect UI
      return req.then(function (results) {
        if (Array.isArray(results) && results.length) {
          return results.map(function (product) {
            // Wrap http:// image URLs to prevent insecure content warnings
            product.image_url = mUrl.addProxy(product.image_url);
            // flag for product management
            return new SquareProductItem(product);
          });
        }
        // TODO: todd-gandee, use toaster to Feedback to display error?
      });
    }
  };
});