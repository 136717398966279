define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./lambda"], function (kernel, lang, df) {
  // This module adds high-level functions and related constructs:
  //	- object/dictionary helpers

  // Defined methods:
  //	- take any valid lambda argument as the functional argument
  //	- skip all attributes that are present in the empty object
  //		(IE and/or 3rd-party libraries).

  var empty = {};
  lang.mixin(df, {
    // object helpers
    keys: function ( /*Object*/obj) {
      // summary:
      //		returns an array of all keys in the object
      var t = [];
      for (var i in obj) {
        if (!(i in empty)) {
          t.push(i);
        }
      }
      return t; // Array
    },
    values: function ( /*Object*/obj) {
      // summary:
      //		returns an array of all values in the object
      var t = [];
      for (var i in obj) {
        if (!(i in empty)) {
          t.push(obj[i]);
        }
      }
      return t; // Array
    },
    filterIn: function ( /*Object*/obj, /*Function|String|Array*/f, /*Object?*/o) {
      // summary:
      //		creates new object with all attributes that pass the test
      //		implemented by the provided function.
      o = o || kernel.global;
      f = df.lambda(f);
      var t = {},
        v,
        i;
      for (i in obj) {
        if (!(i in empty)) {
          v = obj[i];
          if (f.call(o, v, i, obj)) {
            t[i] = v;
          }
        }
      }
      return t; // Object
    },
    forIn: function ( /*Object*/obj, /*Function|String|Array*/f, /*Object?*/o) {
      // summary:
      //		iterates over all object attributes.
      o = o || kernel.global;
      f = df.lambda(f);
      for (var i in obj) {
        if (!(i in empty)) {
          f.call(o, obj[i], i, obj);
        }
      }
      return o; // Object
    },
    mapIn: function ( /*Object*/obj, /*Function|String|Array*/f, /*Object?*/o) {
      // summary:
      //		creates new object with the results of calling
      //		a provided function on every attribute in this object.
      o = o || kernel.global;
      f = df.lambda(f);
      var t = {},
        i;
      for (i in obj) {
        if (!(i in empty)) {
          t[i] = f.call(o, obj[i], i, obj);
        }
      }
      return t; // Object
    }
  });
  return df;
});