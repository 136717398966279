define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/views/fresh/peaches2/users/lists/index"], function (declare, base, mUrl, tabContainerUtil, listIndex) {
  return declare([base, listIndex], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    activeModule: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "list-details" || mUrl.getHashParams().t === "list-details-section") {
        var listIdFromUrl = new URL(window.location.href).searchParams.get("list_id");
        if (listIdFromUrl) {
          this.viewListDetails(listIdFromUrl);
        }
        if (mUrl.getHashParams().id) {
          this.viewListDetails(mUrl.getHashParams().id);
        }
      }
    },
    /**
     * Opens the view list details tab
     * @param listId
     */

    viewListDetails: function (listId) {
      var self = this;
      var newPaneObj = {
        title: "List Details: " + listId,
        href: mUrl.toUrl("/peaches2/users/lists?user_id=" + self.userAttrs.userId + "&_s=list-details&list_id=" + listId),
        stub: "list-details",
        closable: true,
        onClose: function () {
          self.tabContainer.listDetailsSection = null;
          return true;
        },
        onDownloadEnd: function () {
          self.listDetailsInit(listId, this);
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, listId);
    }
  });
});