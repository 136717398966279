/**
 * @module mojo/widgets/contact-profle/GeolocationHelpTip
 *
 * Displays a tool tip to give more information about geolocation, and link out to the KB.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/HelpTip", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, domConstruct, HelpTip, flags, I18nTranslation, context) {
  return declare([HelpTip], {
    label: "",
    href: "https://mailchimp.com/help/about-geolocation/",
    text: "Geolocation is based on your contact’s IP address when they interact with your emails and signup forms.",
    postCreate: function () {
      this.inherited(arguments);
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        this.text = I18nTranslation.translate("geolocation_tooltip_text");
      }
      var innerHtml = "<li>" + this.text + " </li>";
      this.addLine(innerHtml, "first");
    }
  });
});