/**
 * Upgrade Action Block Demo
 * @module mojo/widgets/pattern-library/UpgradeActionDemo.js
 *
 * Widget used to preview variants of the Upgrade Action Block.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "mojo/widgets/Switch", "mojo/widgets/UpgradeActionBlock", "mojo/utils", "vendor/lodash-amd/merge",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/UpgradeActionDemo.html"], function (declare, Select, Switch, UpgradeActionBlock, utils, merge, Renderable, tplStr) {
  /**
   * Rendering of a single Preview. Regularly destroyed on change of the variant input.
   */
  var Preview = declare([Renderable], {
    templateString: "<div></div>",
    configObj: null,
    /**
     * Part of Dojo's lifecycle. Create a new version of the UpgradeActionBlock and pass it
     * the configuration object as configured in the parent widget.
     */
    postCreate: function () {
      this.block = new UpgradeActionBlock(this.configObj);
      this.block.placeAt(this.domNode);
      this.block.startup();
    },
    /**
     * Public method for toggling the loading state.
     */
    toggleLoadingState: function () {
      this.block.toggleCtaLoadingState();
    }
  });

  /**
   * Primary widget responsible for rendering the Example block, creating the variant dropdown, and generating each
   * Preview widget.
   *
   * This widget also demonstrates the flexibility of the UpgradeActionBlock and how it can be configured with
   * either a callback OR a dojo/topic.
   */
  return declare([Renderable], {
    templateString: tplStr,
    // Responsible for generating the type of variants and what classes they pass to the component.
    variantClassMap: {
      large: "",
      small: "c-upgradeActionBlock--small"
    },
    /**
     * Get the party started!
     */
    postCreate: function () {
      this._initDismissSwitch();
      this._initVariantSelect();
    },
    /**
     * Create and place the Dismissible Switch
     *
     * @private
     */
    _initDismissSwitch: function () {
      this.dismissSwitch = new Switch({
        id: this.id + "_dismissInputNode",
        // Unique ID for a11y
        onChange: this._handleAnyInputChange.bind(this),
        checked: false // We want it "off" by default
      });
      this.dismissSwitch.placeAt(this.switchContainer);
      this.dismissSwitch.startup();
    },
    /**
     * Create and place the Variant Select input.
     *
     * @private
     */
    _initVariantSelect: function () {
      this.variantInput = new Select({
        id: this.id + "_sizeInputNode",
        // Unique ID for a11y
        onChange: this._handleAnyInputChange.bind(this),
        options: this._getSelectOptions(),
        "class": "!margin--lv0"
      });
      this.variantInput.placeAt(this.selectContainer);
      this.variantInput.startup();

      // Call once for the first load
      this._handleAnyInputChange();
    },
    /**
     * Embed the block on the page. Nothing special going on here, just instantiating the widget.
     *
     * @private
     */
    _initPreview: function () {
      this.preview = new Preview({
        configObj: this._getConfigObj()
      });
      this.preview.placeAt(this.previewContainer);
      this.preview.startup();

      // Set a max width to make a more realistic preview.
      this.previewContainer.setAttribute("style", this.variantInput.get("value") === "small" ? "max-width: 500px;" : "");
    },
    /**
     * The configuration for the widget changes based on whether we'd like to render the preview as dismissible or not.
     *
     * @returns {{body: string}|*} — Configuration object for block instantiation.
     * @private
     */
    _getConfigObj: function () {
      var baseConfig = {
        body: {
          title: "Upgrade your account to unlock new features",
          copy: "The list you're importing has more contacts than your account allows. You can upgrade your account, archive, or delete contacts"
        },
        onCtaPressCallback: this._handleButtonPress.bind(this),
        variantClass: this.variantClassMap[this.variantInput.get("value")]
      };
      var dismissKeyValuePairs = {
        isDismissable: true,
        dismissConfig: {
          innerText: "Dismiss",
          onDismissPressCallback: this._handleDismissButtonPress.bind(this)
        }
      };
      return this.dismissSwitch.get("checked") ? merge(baseConfig, dismissKeyValuePairs) : baseConfig;
    },
    /**
     * Loop through the variantClassMap and generate input options accordingly.
     *
     * @returns {array[]} - Collection of options with keys for label, value, and selected
     * @private
     */
    _getSelectOptions: function () {
      return Object.keys(this.variantClassMap).map(function (key, i) {
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),
          value: key,
          selected: i === 0
        };
      });
    },
    /**
     * Clear the current preview and start a new one.
     *
     * @private
     */
    _handleAnyInputChange: function () {
      // Reset existing preview if there is any.
      if (this.preview) {
        this.preview.destroy();
        this.preview = null;
      }
      this._initPreview();
    },
    /**
     * Method called regardless of listening method mechanism
     *
     * @private
     */
    _handleButtonPress: function () {
      utils.globalPublish("show-toast", [{
        "message": "Upgrade button pressed!",
        "type": "success"
      }]);

      // For demonstration purposes, remove the loading indicator.
      setTimeout(function () {
        this.preview.toggleLoadingState();
      }.bind(this), 2000);
    },
    /**
     * Method called regardless of listening method mechanism
     *
     * @private
     */
    _handleDismissButtonPress: function () {
      utils.globalPublish("show-toast", [{
        "message": "Dismiss button pressed!",
        "type": "success"
      }]);

      // Reset the preview after a second
      setTimeout(function () {
        this._handleAnyInputChange();
      }.bind(this), 1000);
    }
  });
});