define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/Neapolitan", "mojo/views/_base", "mojo/widgets/KbSearch", "mojo/widgets/ActionableDialog"], function (declare, dom, has, on, query, Neapolitan, base, KbSearch, ActionableDialog) {
  return declare([base], {
    onReady: function () {
      window.app.setDesignStyles(dom.byId("design-editor"));

      // This is to get around the fact that you can't scroll iframes in IOS
      if (has("ios")) {
        query(".iframe-wrapper").addClass("ios-iframe-scroll");
      }
      query("[data-kb-help]").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        KbSearch.main().show();
      });
      query("[data-save-on-click]").forEach(function (el) {
        on(el, "click", function (e) {
          e.preventDefault();
          var href = el.href;
          // change text on button
          if (window.app.isPagePublished) {
            e.target.innerText = "Publishing...";
          } else {
            e.target.innerText = "Saving...";
          }
          window.app.saveAll().then(function () {
            e.target.innerText = window.app.saveButtonText;
            window.location = href;
          });
        });
      });
      var freddieWizardLogo = query('[data-mc-el="wizardFreddieLogo"]')[0];
      on(freddieWizardLogo, "click", function (e) {
        if (!window.app.isPagePublished || !window.app.didMakeChanges) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        new ActionableDialog({
          title: "Are you sure?",
          contentMessage: "You’ve made changes that have not been published. " + "If you exit now, we’ll save your work so you can come back later, " + "but visitors to your landing page will not see these changes.",
          primaryActionLabel: "Exit without publishing",
          onClickPrimaryAction: function (evt, dialog) {
            window.onbeforeunload = null;
            window.location = freddieWizardLogo.getAttribute("href");
          },
          secondaryActionLabel: "Keep editing",
          onClickSecondaryAction: function (evt, dialog) {
            dialog.hide();
          }
        }).show();
      });
    }
  });
});