define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (lang) {
  // module:
  //		dojo/data/util/sorter
  // summary:
  //		TODOC

  var sorter = {};
  lang.setObject("dojo.data.util.sorter", sorter);
  sorter.basicComparator = function ( /*anything*/a, /*anything*/b) {
    // summary:
    //		Basic comparison function that compares if an item is greater or less than another item
    // description:
    //		returns 1 if a > b, -1 if a < b, 0 if equal.
    //		'null' values (null, undefined) are treated as larger values so that they're pushed to the end of the list.
    //		And compared to each other, null is equivalent to undefined.

    //null is a problematic compare, so if null, we set to undefined.
    //Makes the check logic simple, compact, and consistent
    //And (null == undefined) === true, so the check later against null
    //works for undefined and is less bytes.
    var r = -1;
    if (a === null) {
      a = undefined;
    }
    if (b === null) {
      b = undefined;
    }
    if (a == b) {
      r = 0;
    } else if (a > b || a == null) {
      r = 1;
    }
    return r; //int {-1,0,1}
  };
  sorter.createSortFunction = function ( /* attributes[] */sortSpec, /*dojo/data/api/Read*/store) {
    // summary:
    //		Helper function to generate the sorting function based off the list of sort attributes.
    // description:
    //		The sort function creation will look for a property on the store called 'comparatorMap'.  If it exists
    //		it will look in the mapping for comparisons function for the attributes.  If one is found, it will
    //		use it instead of the basic comparator, which is typically used for strings, ints, booleans, and dates.
    //		Returns the sorting function for this particular list of attributes and sorting directions.
    // sortSpec:
    //		A JS object that array that defines out what attribute names to sort on and whether it should be descenting or asending.
    //		The objects should be formatted as follows:
    // |	{
    // |		attribute: "attributeName-string" || attribute,
    // |		descending: true|false;   // Default is false.
    // |	}
    // store:
    //		The datastore object to look up item values from.

    var sortFunctions = [];
    function createSortFunction(attr, dir, comp, s) {
      //Passing in comp and s (comparator and store), makes this
      //function much faster.
      return function (itemA, itemB) {
        var a = s.getValue(itemA, attr);
        var b = s.getValue(itemB, attr);
        return dir * comp(a, b); //int
      };
    }
    var sortAttribute;
    var map = store.comparatorMap;
    var bc = sorter.basicComparator;
    for (var i = 0; i < sortSpec.length; i++) {
      sortAttribute = sortSpec[i];
      var attr = sortAttribute.attribute;
      if (attr) {
        var dir = sortAttribute.descending ? -1 : 1;
        var comp = bc;
        if (map) {
          if (typeof attr !== "string" && "toString" in attr) {
            attr = attr.toString();
          }
          comp = map[attr] || bc;
        }
        sortFunctions.push(createSortFunction(attr, dir, comp, store));
      }
    }
    return function (rowA, rowB) {
      var i = 0;
      while (i < sortFunctions.length) {
        var ret = sortFunctions[i++](rowA, rowB);
        if (ret !== 0) {
          return ret; //int
        }
      }
      return 0; //int
    }; // Function
  };
  return sorter;
});