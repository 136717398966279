define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "mojo/utils"], function (declare, lang, aspect, Deferred, dom, domAttr, domClass, domStyle, on, _Widget, utils) {
  return declare([_Widget], {
    /** string that's used for saving. (mc:edit="xxxx") **/
    editId: null,
    type: "mcedit",
    duplicate: false,
    isMobile: false,
    postMixInProperties: function () {
      if (!this.endpoint) {
        throw new Error("Must pass a endpoint wrapper");
      }
      this.isMobile = window.app && window.app.nativeUtils && window.app.nativeUtils.getNativeObject();
    },
    buildRendering: function () {
      this.inherited(arguments);
      this.editId = domAttr.get(this.domNode, "mc:edit");
    },
    getEditor: function () {
      throw new Error("Must implement getEditor");
    },
    /**
     * setting active for mobile users
     */
    setBlockActive: function () {
      utils.globalPublish("neapolitan/block/activate", {
        id: this.id,
        model: this
      });
      // activate here;
      domClass.toggle(this.domNode, "native-highlight-mobile--active");
    },
    /**
     * setting inactive for mobile users
     */
    setBlockInActive: function () {
      domClass.toggle(this.domNode, "native-highlight-mobile--active");
    },
    openEditor: function (id) {
      if (!id) id = 'default';
      if (!this._editors) this._editors = {};
      var editorData = this._editors[id];
      if (!this.isEditorOpen(id)) {
        editorData = {
          'editor': this.getEditor(),
          'open': true
        };
        this._editors[id] = editorData;
        this._currentEditor = editorData;
        editorData.editor.show();
        aspect.after(editorData.editor, "destroy", function () {
          editorData.open = false;
        });
      }
    },
    closeEditor: function (id) {
      if (!id) id = 'default';
      if (!this._editors) this._editors = {};
      var editorData = this._editors[id];
      if (editorData) {
        editorData.editor.done();
      }
    },
    isEditorOpen: function (id) {
      if (!id) id = 'default';
      if (!this._editors) return false;
      var editorData = this._editors[id];
      return editorData && editorData.open;
    },
    /**
     * This is what the link checker calls to let the block know about a change in a url.
     *
     * @param oldUrl - The old url
     * @param newUrl - The new url that this is changing to.
     * @param index - the index of this url in the context of this block
     */
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var promise = new Deferred();
      promise.reject("Oops! Looks like we unable to change this link. Contact support if the problem persist.");
      return promise;
    },
    canLinkUpdate: function () {
      return true;
    },
    /**
     * Notify the block to update itself from the server.
     */
    update: function () {},
    destroy: function () {
      if (this._currentEditor && this._currentEditor.open) {
        this._currentEditor.editor.cancelAndHide();
      }
      this.inherited(arguments);
    }
  });
});