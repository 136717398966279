define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/widgets/Renderable", "mojo/lib/logger", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/ContentManagerPane", "dojo/text!./templates/page-social-share.html", "dijit/Tooltip", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags", "dojox/html/entities"], function (declare, utils, Renderable, logger, ItemButtons, ContentManagerPane, tpl, Tooltip, ready, I18nTranslation, context, flags, htmlEntities) {
  var MAX_CHARACTER_LENGTH = 150;
  var PageSocialShare = declare([Renderable], {
    templateString: tpl,
    errorMsg: "Please add title, description, and image.",
    manageTheContent: "Manage the content that appears when you share your page URL in social posts.",
    socialShareTitleText: "Social Share Title",
    titleWillAppear: "This title will appear when you share your page URL in social posts.",
    socialShareDescriptionText: "Social Share Description",
    descriptionWillAppear: "This description will appear when you share your page URL in social posts.",
    home: "Home",
    descriptionGoesHere: "Your landing page description goes here",
    replaceTheImage: "Replace the image",
    uploadImage: "Upload Image",
    constructor: function (props) {
      this.state = {
        errors: props.errors || {},
        socialShareTitle: props.model.socialShareTitle,
        socialShareDescription: props.model.socialShareDescription,
        socialShareImage: props.model.socialShareImage || null,
        campaignUrl: props.model.campaignUrl
      };
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.manageTheContent = I18nTranslation.translate("landing_pages_manage_the_content");
        this.socialShareTitleText = I18nTranslation.translate("landing_pages_social_share_title_text");
        this.titleWillAppear = I18nTranslation.translate("landing_pages_title_will_appear");
        this.socialShareDescriptionText = I18nTranslation.translate("landing_pages_social_share_description_text");
        this.descriptionWillAppear = I18nTranslation.translate("landing_pages_description_will_appear");
        this.home = I18nTranslation.translate("landing_pages_home");
        this.descriptionGoesHere = I18nTranslation.translate("landing_pages_description_goes_here");
        this.replaceTheImage = I18nTranslation.translate("landing_pages_replace_the_image");
        this.uploadImage = I18nTranslation.translate("landing_pages_upload_image");
      }
    },
    getData: function () {
      var nextState = {
        socialShareImage: this.state.socialShareImage,
        socialShareTitle: this.state.socialShareTitle,
        socialShareDescription: this.state.socialShareDescription
      };
      return nextState;
    },
    _initToolTip: function (selector, content) {
      this.toolTip = new Tooltip({
        connectId: [selector],
        position: ["below"],
        label: content
      });
    },
    //Set title and description values
    setValues: function (event) {
      var newValue = null;
      var errors = {};
      if (event.target) {
        newValue = event.target.value;
        this.preserveFocus(event);
        var inputError = this.validate(event.target.value);
        if (inputError) {
          errors[event.target] = inputError;
        }
      } else {
        newValue = event;
      }
      var nextState = {};
      nextState[event.target.name] = htmlEntities.encode(event.target.value);
      this.setState(nextState);
    },
    /**
    * GA Tracking input
    *
    * @param {event} e - Pointer Event
    */
    _handleInputBlur: function (e) {
      this.postMixInProperties();
      var label = e.target.id === "socialShareTitle" ? "title" : "description";
      logger.googleAnalytics.trackEvent("landing pages editor", "social share", "edit " + label);
    },
    validate: function (value) {
      var error;
      if (value === "" || value === null) {
        error = this.errorMsg;
      }
      return error;
    },
    _openContentStudio: function () {
      ContentManagerPane.show({
        actions: ["select", "edit"],
        allowedFileTypes: ["png", "jpg", "jpeg"],
        onSelectContentItem: this._handleImageSelection.bind(this)
      });
    },
    _handleImageSelection: function (img) {
      var errors = {};
      var nextState = {
        errors: errors
      };
      nextState.socialShareImage = img.url;
      this.setState({
        socialShareImage: img.url
      });

      //Ga track - category, action, label
      logger.googleAnalytics.trackEvent("landing pages editor", "social share", "image upload");
    },
    render: function () {
      var self = this;
      self.inherited(arguments);
      if (this.isActive) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.itemButtons = new ItemButtons({
          label: "Social Share",
          getData: this.getData.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          isActive: this.isActive,
          isValid: Object.keys(this.state.errors).length === 0
        });
        this.itemButtons.placeAt(this.itemButtonsContainer);
        if (this.toolTip) {
          this.toolTip.destroy();
        }

        //Using setTimeout() to place at the end of the queue to ensure renderable node exists.
        setTimeout(function () {
          self._initToolTip("titleToolTip", "The title should include the name of your page or keywords that clearly describe your page.");
          self._initToolTip("descriptionToolTip", "The description should describe the content that appears on the page and why people should be interested in it.");
        }, 0);
      }
    }
  });
  PageSocialShare.isComplete = function (model) {
    return model.socialShareTitle && model.socialShareTitle !== "";
  };
  PageSocialShare.isValid = function (page) {
    return utils.getSymbolLength(page.socialShareTitle) <= MAX_CHARACTER_LENGTH;
  };
  return PageSocialShare;
});