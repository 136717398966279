define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/request-helpers", "mojo/lib/Poller", "mojo/user", "mojo/lib/flags", "./campaign/PresendChecksCollection"], function (declare, lang, xhr, all, Deferred, mUrl, requestHelpers, Poller, user, flags, PresendChecksCollection) {
  var Automation;
  var AutomationList = declare(null, {
    constructor: function (properties) {
      this.listId = properties.list_id;
      this.name = properties.name;
    }
  });
  var AutomationContent = declare(null, {
    constructor: function (properties) {
      this.subject = properties.content.subject;
      this.previewText = properties.content.preview_text;
      this.shortUrlLength = properties.content.short_url.length;
    }
  });
  var AutomationRecipents = declare(null, {
    constructor: function (properties) {
      this.delayValue = properties.trigger.delay;
      this.delayString = properties.trigger.delay_string;
      this.delayDirection = properties.trigger.delay_direction;
      this.includesImportedContacts = properties.trigger.includes_imported_contacts === "Y";
      this.hasSegmentCondition = properties.has_segment_condition;
      if (this.hasSegmentCondition) {
        this.landingPageName = properties.landing_page.name;
        this.landingPageId = properties.landing_page.id;
      }
    }
  });
  var CampaignFrom = declare(null, {
    constructor: function (properties) {
      this.fromName = properties.content.from_name;
      this.fromEmail = properties.content.from_email;
      this.isVerifiedEmail = properties.content.is_verified_email;
      this.isAuthenticatedDomain = properties.content.is_authenticated_domain;
      this.sendingEmail = properties.content.sending_email;
    }
  });
  var CampaignPromotion = declare(null, {
    constructor: function (properties) {
      if (properties.promotion) {
        this.promotion = properties.promotion;
        this.promotionIsEnabled = properties.promotion.is_enabled;
        this.promotionIsComplete = properties.promotion.is_complete;
      }
    }
  });
  var CampaignTestSend = declare(null, {
    constructor: function (properties) {
      this.nonaccountRecipients = properties ? properties.nonaccount_recipients : [];
      this.accountRecipients = properties ? properties.account_recipients : [];
      this.recipients = properties ? properties.recipients : [];
    },
    getDefaultTestEmails: function () {
      return this.nonaccountRecipients.join(", ");
    }
  });
  var api = {
    /**
     * Status constants
     */
    AUTOMATION_STATUS_DRAFT: "save",
    AUTOMATION_STATUS_PROCESSING: "processing",
    AUTOMATION_STATUS_SENT: "sent",
    AUTOMATION_STATUS_SENDING: "sending",
    AUTOMATION_STATUS_PAUSED: "paused",
    /**
     * Instantiate a new Automation from the given properties
     *
     * @param {Object} automationProperties - Properties to build the Automation instance from
     * @return {Automation} A full instance
     */
    createFromProperties: function (automationProperties) {
      return new Automation(automationProperties);
    },
    /**
     * Apply a patch of properties to the automation, and get a full, updated, automation back
     *
     * @param {string} campaignId - Automation id to update
     * @param {Object} patch - Properties to update
     * @return {Promise.<Automation>} A Promise resolving to a new updated automation instance
     */
    patchById: function (campaignId, patch) {
      return xhr.post(mUrl.toUrl("/automations/single-email-welcome/patch", {
        "id": campaignId
      }), {
        "handleAs": "json",
        "data": {
          "patch": JSON.stringify(patch) // dojo doesn't serialize json objects
        }
      }).then(this.createFromProperties.bind(this), requestHelpers.throwError);
    }
  };
  Automation = declare(null, {
    /**
     * Takes a properties bundle, and unpacks it to front-end-friendly structure, delegating
     * to sub-models along the way
     *
     * @param {Object} properties - Properties from automation endpoints
     */
    constructor: function (properties) {
      this._timestamp = Date.now();
      this.name = properties.title;
      this.subject = properties.content.subject;
      this.campaignId = properties.campaign_id;
      this.parentId = properties.parent_id;
      this.updatedAt = properties.updated_at ? new Date(properties.updated_at) : null;
      this.status = properties.status || null;
      this.uniqueID = properties.unique_id || null;
      this.list = properties.list ? new AutomationList(properties.list) : null;
      this.list_name = properties.list_name;
      this.content = new AutomationContent(properties);
      this.from = new CampaignFrom(properties);
      this.renderedHtml = properties.rendered_html;
      this.isContentReady = properties.is_content_ready;
      this.screenshotUrl = properties.screenshot_url;
      this.presendChecks = new PresendChecksCollection(properties.presend_checks);
      this.promotion = new CampaignPromotion(properties);
      this.lastTestSend = new CampaignTestSend(properties.last_test_send);
      this.hasRewards = properties.has_rewards;
      this.recipients = new AutomationRecipents(properties);
      this.isSingleWelcome = properties.is_single_welcome;
      this.uniqueListId = properties.unique_list_id;
      this.recipeId = properties.recipe_id;
      this.authorizedEmail = properties.authorized_email;
    },
    patch: function (data) {
      data = data || {};
      var patch = {};
      if (data.listId) {
        patch = lang.mixin(patch, {
          "list_id": data.listId
        });
      }
      if (data.from) {
        patch.content = lang.mixin({}, patch.content, {
          from_name: data.from.fromName,
          from_email: data.from.fromEmail
        });
      }
      if (data.content && (data.content.subject || data.content.previewText)) {
        patch.content = lang.mixin({}, patch.content, {
          subject: data.content.subject,
          preview_text: data.content.previewText
        });
      }
      if (data.name) {
        patch.content = lang.mixin({}, patch.content, {
          title: data.name
        });
      }
      if (data.trigger) {
        patch.trigger = lang.mixin({}, patch.trigger, {
          delay: data.trigger.delayValue
        });

        // do not patch "includesImportedContacts" property if automation hasSegmentCondition (is linked to a landing page)
        if (!this.recipients.hasSegmentCondition) {
          patch.trigger.includesImportedContacts = data.trigger.includesImportedContacts;
        }
      }
      return api.patchById(this.campaignId, patch);
    }
  });
  return api;
});