define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags"], function (declare, user, flags) {
  return declare(null, {
    _getImageStyles: function () {
      var styles = [];
      styles.push({
        "name": "Image style",
        "selector": "img.mcnImage, img.mcnRetinaImage",
        "props": {
          "border": {
            "value": ""
          },
          "border-radius": {
            "value": "",
            "minimum": 0,
            "maximum": 50,
            "unit": "%",
            "wholeNumbersOnly": true
          }
        }
      });
      if (window.app && window.app.campaignType === "page" && this.type !== "imageCard") {
        styles[0].props["box-shadow"] = {
          value: "none"
        };
      }
      return styles;
    }
  });
});