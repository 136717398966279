define([], function () {
  function isMobile() {
    return Boolean(window.app && window.app.nativeUtils && window.app.nativeUtils.getNativeObject());
  }
  return {
    addTo: function (imageRow) {
      var maxWidth = 1200;
      var edtorActionToOpen = "resize";
      if (isMobile()) {
        imageRow.addMaxWidthRestriction(maxWidth, "Images should be around 800px to 1200px wide. Please resize this image on desktop.", edtorActionToOpen);
      } else {
        imageRow.addMaxWidthRestriction(maxWidth, "This image is so big it might cause slow load times or get clipped in people’s inboxes. Aim for 800 – 1200px wide instead.", edtorActionToOpen, "Let's fix it");
      }
    }
  };
});