define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "vendor/lodash-amd/camelCase", "vendor/lodash-amd/object", "mojo/context", "mojo/lib/flags"], function (declare, lang, array, request, xhr, Deferred, mUrl, camelCase, object, context, flags) {
  function getGroupedAutomations(blueprints) {
    return blueprints.filter(function (blueprint) {
      return blueprint.automated_groups.length > 0;
    }).reduce(function (memo, blueprint) {
      blueprint.automated_groups.forEach(function (group) {
        var key = camelCase(group.title);
        memo[key] = memo[key] || {
          key: key,
          title: group.title,
          blueprints: []
        };
        memo[key].blueprints.push(blueprint);
      });
      return memo;
    }, {});
  }

  /**
   * Represents a form field for an option
   */
  var OptionField = declare(null, {
    type: null,
    name: null,
    value: null,
    label: null,
    constructor: function (properties) {
      this.type = properties.type;
      this.name = properties.name;
      this.value = properties.value;
      this.label = properties.label;
    },
    toString: function () {
      return "OptionField=(" + this.type + ", " + this.name + ")";
    }
  });

  /**
   * Factory for building from an object
   *
   * @param {string} name - Name of field
   * @param {Object<string, string>} obj - Other properties of field, like type and value
   * @returns {OptionField} - The OptionField for this string
   */
  OptionField.fromObject = function (name, obj) {
    var properties = lang.mixin({}, obj, {
      "name": name
    });
    return new OptionField(properties);
  };

  /**
   * Represents an option for how to build a blueprint
   *
   * @property {string} id - Identifier for option
   * @property {string} title - Title of option
   * @property {string} description - Description of option
   * @property {string} type - Indicates which create endpoint to use
   * @property {Object[]} fields - Option's form fields
   * @property {Object<string, string>} parameters - Parameters to build hidden form fields for
   */
  var Option = declare(null, {
    id: null,
    title: null,
    description: null,
    type: null,
    fields: null,
    parameters: null,
    isAvailable: null,
    isAutomationAuthorized: null,
    shopchimpStoreName: null,
    constructor: function (properties, groupedAutomations) {
      this.id = properties.id;
      this.title = properties.title;
      this.description = properties.description || "";
      this.blurbs = properties.blurbs || [];
      var type = properties.type;
      if (Option.isValidType(type) === false) {
        throw new RangeError("Unknown type '" + type + "' building Option '" + this.id + "'");
      }
      this.type = type;
      this.fields = Option.buildFields(properties.fields);
      this.parameters = properties.parameters || {};
      if (this.id === "custom") {
        this.groupedAutomations = groupedAutomations;
      }

      //is this option available per the Authz policy?
      this.isAvailable = properties.is_available;
      this.isAutomationAuthorized = properties.is_automation_authorized;
      this.shopchimpStoreName = properties.shopchimp_store_name;
    },
    getEndpoint: function () {
      var type = this.type;
      var url = null;
      switch (type) {
        case "rss":
          url = "/campaigns/create";
          break;
        case "automation":
          url = "/automations/create";
          break;
        case "welcome":
          url = "automations/single-email-welcome/create-from-front-door";
          break;
        case "abandoned_browse":
          url = "/automations/abandoned-browse/create";
          break;
        case "abandoned_cart":
          url = "/automations/abandoned-cart/create";
          break;
        case "transactional":
          url = "/automations/transactional/create";
          break;
        case "facebook":
          url = "/ads/create";
          break;
        case "signup_form_popup":
          url = "/lists/signup-forms/popup/editor";
          break;
        case "postcard":
          url = "/postcards/create";
          break;
        case "landing_page":
          url = "/landing-pages/create";
          break;
        case "embedded_form":
          url = "audience/forms/embedded-form/editor";
          break;
        default:
          throw new Error("Unknown type " + type);
      }
      return mUrl.toUrl(url);
    }
  });

  /**
   * Check if the type is a known valid type
   *
   * @param {string} type - Type to check
   * @returns {boolean} True if type is known
   */
  Option.isValidType = function (type) {
    var validTypes = ["rss", "automation", "welcome", "abandoned_browse", "abandoned_cart", "transactional", "facebook", "signup_form_popup", "landing_page", "postcard", "postcard_recurring", "postcard_abandoned_cart", "embedded_form"];
    return validTypes.indexOf(type) >= 0;
  };

  /**
   * Build an array of OptionField from the fields map of an Option
   * handling whether the field value is an old-style string, or
   * new-style parameter set
   *
   * @param {Object<string, string|Object>} fields - From backend
   * @returns {OptionField[]} - List of option fields
   */
  Option.buildFields = function (fields) {
    return object.entries(fields).map(function (entry) {
      var name = entry[0];
      var value = entry[1];
      return OptionField.fromObject(name, value);
    }, this);
  };
  var Blueprint = declare(null, {
    id: null,
    title: null,
    description: null,
    pathways: null,
    featured: null,
    options: null,
    searchKeywords: null,
    constructor: function (properties, groupedAutomations) {
      this.id = properties.id;
      this.title = properties.title;
      this.description = properties.description;
      this.pathways = properties.pathways;
      this.featured = properties.featured;
      this.searchKeywords = properties.search_keywords;
      this.options = properties.options.map(function (option) {
        return new Option(option, groupedAutomations);
      });
    },
    isPartOfPathway: function (pathwayId) {
      return this.pathways.indexOf(pathwayId) !== -1;
    }
  });
  return {
    /**
     * @return {Promise<Blueprint[]>} Resolves to an array of Blueprint
     */
    list: function () {
      return xhr.get(mUrl.toUrl("channels/blueprints"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        var groupedAutomations = getGroupedAutomations(data.blueprints);
        return data.blueprints.map(function (properties) {
          return new Blueprint(properties, groupedAutomations);
        });
      });
    }
  };
});