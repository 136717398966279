/**
 * Mixin for doing all the hide/show of full page overlays such as search and kb-search.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, domClass, domStyle, Deferred, dom, lang, Velocity, domAttr) {
  return declare([], {
    "overlayClass": '',
    "lastFocusedElement": null,
    isVisible: function () {
      return domStyle.get(this.domNode, "visibility") == "visible" && domStyle.get(this.domNode, "opacity") == 1;
    },
    show: function (focusField) {
      var deferred = new Deferred();
      if (!this.isVisible()) {
        // Focus management
        this.lastFocusedElement = document.activeElement;
        this.onShow();
        Velocity(this.domNode, "stop");
        domStyle.set(this.domNode, "zIndex", 940);
        domClass.add(document.documentElement, this.overlayClass + " overflow-hidden");
        Velocity(this.domNode, {
          "opacity": [1, "easeOutCubic", 0.2],
          "translateY": ["0%", "easeOutQuint", "-100%"]
        }, {
          "visibility": "visible",
          "duration": 475,
          "complete": lang.hitch(this, function () {
            if (dom.byId(focusField)) {
              dom.byId(focusField).focus();
            }
            domAttr.set(this.domNode, "aria-hidden", "false");
            deferred.resolve();
          })
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    },
    /**
     * @param {Object} props: map of properties we can pass.
     *  animate: whether or not to animate the overlay
     */
    hide: function (props) {
      var deferred = new Deferred();
      props = props || {};
      if (this.isVisible()) {
        this.onHide();
        var duration = props.animate === false ? 0 : 375;
        Velocity(this.domNode, "stop");
        Velocity(this.domNode, {
          "translateY": ["-100%", "easeOutQuint", "0%"]
        }, {
          "duration": duration,
          "visibility": "hidden",
          "opacity": 0.2,
          "complete": lang.hitch(this, function () {
            domStyle.set(this.domNode, "zIndex", -5);
            domClass.replace(document.documentElement, "", this.overlayClass + " overflow-hidden");
            domAttr.set(this.domNode, "aria-hidden", "true");

            // Focus management
            if (this.lastFocusedElement) {
              this.lastFocusedElement.focus();
              this.lastFocusedElement = null;
            }
            deferred.resolve();
          })
        });
      } else {
        deferred.resolve();
      }
      return deferred.promise;
    },
    onHide: function () {},
    onShow: function () {}
  });
});