define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/user", "mojo/url", "dojo/window", "mojo/widgets/FeedbackBlock", "mojo/lib/logger", "mojo/utils", "mojo/lib/flags", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, domClass, xhr, on, _base, user, mUrl, win, FeedbackBlock, logger, utils, flags, ioQuery, EcsEvents, EcsTracking) {
  return declare([_base], {
    canDowngrade: null,
    pricingId: null,
    currentMarketingPricingId: null,
    currentMarketingPlanName: null,
    newPlanPackageId: null,
    onReady: function () {
      if (this.$el.acknowledgeLegalBox) {
        on(this.$el.acknowledgeLegalBox, "click", this.acknowledgeLegalBox.bind(this));
        on(this.$el.acknowledgeLegalBox, "click", this.sendLegalCheckboxClickEvent.bind(this));
      }
      if (this.$el.editContacts) {
        on(this.$el.editContacts, "click", this.editContacts.bind(this));
      }
      if (this.$el.editAudiences) {
        on(this.$el.editAudiences, "click", this.editAudiences.bind(this));
      }
      if (this.$el.editAutomations) {
        on(this.$el.editAutomations, "click", this.editAutomations.bind(this));
      }
      if (this.$el.editSeats) {
        on(this.$el.editSeats, "click", this.editSeats.bind(this));
      }
      if (this.$el.cancelDowngrade) {
        on(this.$el.cancelDowngrade, "click", this.logAnalyticsEvent.bind(this));
      }
      if (this.$el.downgradeButton) {
        on(this.$el.downgradeButton, "click", this.legalBoxAlert.bind(this));
      }
      if (this.$el.downgradeButtonEl) {
        on(this.$el.downgradeButtonEl, "click", this.sendDowngradeButtonClickEvent.bind(this));
      }
      if (this.$el.downgradeForm) {
        on(this.$el.downgradeForm, "submit", this.submitDowngradeRequest.bind(this));
      }
      var cleanedSearch = window.location.search.replace("?", "");
      var query = ioQuery.queryToObject(cleanedSearch);
      if (query.referer) {
        if (query.referer.includes("/reports/content")) {
          var cancelReferer = encodeURIComponent(query.referer + "&canceled=true");
          this.$el.cancelDowngrade.href = this.$el.cancelDowngrade + "?referer=" + cancelReferer;
        }
        this.$el.backLink.href = query.referer;
      }
      EcsTracking.track(EcsEvents.FeaturesReviewPageViewedEvent);
    },
    /**
     * Call the downgrade-user-to-plan endpoint
     * And redirect to confirmation on success
     * or show error on failure
     *
     * @param {MouseEvent} e Button click event
     */
    submitDowngradeRequest: function (e) {
      e && e.preventDefault();
      var cleanedSearch = window.location.search.replace("?", "");
      var query = ioQuery.queryToObject(cleanedSearch);
      var url = "/account/features-review/downgrade-user-to-plan";
      utils.toggleButtonLoadingState(this.$el.downgradeButtonEl, "Downgrading...", "button-loading disabled");
      xhr.post(mUrl.toUrl(url), {
        "handleAs": "json",
        "data": {
          "pricing_id": query && query.pricing_id
        }
      }).then(function (resp) {
        utils.toggleButtonLoadingState(this.$el.downgradeButtonEl, "Downgrade", "button-loading disabled");
        if (resp.status === "success") {
          // track
          logger.googleAnalytics.trackEvent("downgrade", "click", "downgrade plan", {
            dimension53: e.target.innerText.toLowerCase(),
            dimension97: this.currentMarketingPlanName,
            dimension102: this.currentMarketingPricingId,
            dimension105: this.newPlanPackageId,
            dimension106: this.pricingId
          });
          if (query.referer) {
            window.top.location = query.referer;
          } else {
            window.top.location = "/account/billing/plans";
          }
          return;
        }
        this.onDowngradeError(resp.exception);
      }.bind(this), function (err) {
        this.onDowngradeError();
      }.bind(this));
    },
    onDowngradeError: function (errMsg) {
      var message = errMsg ? errMsg : "Sorry, an error occured.  Please try again";
      var block = new FeedbackBlock({
        message: message,
        type: "error",
        containerClass: "padding"
      });
      if (this.$el.feedbackAnchor) {
        dojo.empty(this.$el.feedbackAnchor);
        block.placeAt(this.$el.feedbackAnchor, "first");
        win.scrollIntoView(block.domNode);
      } else {
        console.warn("Expected element for feedback block anchor, but none exists");
      }
    },
    acknowledgeLegalBox: function (e) {
      var userAcknowledged = e.target.checked;
      var downgradeButton = document.getElementById("downgrade");
      var legalBoxAlert = document.getElementById("legalBoxAlert");
      if (userAcknowledged && this.canDowngrade) {
        downgradeButton.classList.remove("disabled");
        legalBoxAlert.classList.add("hide");
      } else {
        downgradeButton.classList.add("disabled");
      }
    },
    //if a user has not checked the legalese, onclick of the downgrade button, show an alert to point the user to the legalese box
    //this function removes the hide class on the alert box
    legalBoxAlert: function (e) {
      var userAcknowledged = document.getElementById("legalBox");
      var legalBoxAlert = document.getElementById("legalBoxAlert");
      if (!userAcknowledged.checked) {
        legalBoxAlert.classList.remove("hide");
      }
    },
    editContacts: function () {
      window.open(mUrl.toUrl("/lists/members/"), "_blank");
    },
    editAudiences: function () {
      window.open(mUrl.toUrl("/audience/manage/"), "_blank");
    },
    editAutomations: function () {
      window.open(mUrl.toUrl("/campaigns/"), "_blank");
    },
    editSeats: function () {
      window.open(mUrl.toUrl("/account/users/"), "_blank");
    },
    logAnalyticsEvent: function (e) {
      var srcInfo = {
        "location": window.location.pathname,
        "button_text": e.target.innerText.trim().replace(/\s/g, "_")
      };
      window.ga("send", "event", "downgrade", "cancel_pending_downgrade", JSON.stringify(srcInfo));
    },
    sendLegalCheckboxClickEvent: function () {
      EcsTracking.track(EcsEvents.FeaturesReviewPageCheckboxClickedEvent);
    },
    sendDowngradeButtonClickEvent: function () {
      EcsTracking.track(EcsEvents.FeaturesReviewPageDowngradeButtonClickedEvent);
    }
  });
});