define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "mojo/widgets/Renderable", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/reminder-notification.html", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, on, velocity, Renderable, domClass, tplStr, mUrl, xhr) {
  return declare([Renderable], {
    templateString: tplStr,
    campaignId: null,
    inlineEditor: false,
    renderedHtml: "",
    handleEditorSelection: function () {
      var startNewCampaign = this.renderedHtml !== "";
      var label = this.inlineEditor ? "nuni select" : "nea select";
      var ctaText = this.inlineEditor ? "Give our new builder a whirl" : "Return to the classic builder";
      window.ga("send", "event", "campaign_checklist", "editor opt in", label, {
        dimension53: ctaText,
        dimension55: "campaign checklist",
        dimension56: "navigate"
      });
      if (this.reminderContainer) {
        domClass.add(this.reminderContainer, "faded no-clicky");
      }
      xhr.post(mUrl.toUrl("/campaigns/handle-editor-selection"), {
        'handleAs': 'json',
        'data': {
          'id': this.campaignId,
          'inlineEditor': this.inlineEditor,
          'startNewCampaign': startNewCampaign
        }
      }).then(lang.hitch(this, function (data) {
        if (data.status === "success") {
          window.top.location.href = data.redirect;
        }
        if (this.reminderContainer) {
          domClass.remove(this.reminderContainer, "faded no-clicky");
        }
      }));
    },
    dismissReminder: function () {
      xhr.post("/campaigns/dismiss-editor-reminder").then(lang.hitch(this, this.dismissBanner));
      window.ga("send", "event", "campaign_checklist", "editor opt in", "close prompt", {
        dimension53: "x",
        dimension55: this.inlineEditor ? "nuni editor prompt closed" : "nea editor prompt closed",
        dimension56: "close prompt"
      });
    },
    dismissBanner: function () {
      velocity(this.reminderContainer, {
        opacity: [0, 1],
        maxHeight: [0, 300]
      }, {
        duration: 400,
        display: "none"
      });
    }
  });
});