define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/conversations", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, base, conversations, topic, registry) {
  return declare([base], {
    onReady: function () {
      conversations.initializeListWidgets("conversation-list");
      // SWMCAPPS Survey
      topic.subscribe('mojo/analytics/conversation-list/ready', function () {
        if (typeof window.usabilla_live === "function" && registry.byId('conversation-list').total > 0) {
          window.usabilla_live('trigger', 'swmcapps_1_1_survey_1');
        }
      });
    }
  });
});