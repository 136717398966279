define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "d3/d3", "mojo/charts", "mccharts/mccharts", "mojo/colors",
//Translations
"mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, xhr, lang, on, base, mUrl, d3, mojoCharts, mccharts, mColors, flags, context, I18nTranslation) {
  return declare([base], {
    translatedTerms: [],
    listId: null,
    // Passed from the controller

    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedTerms = value;
        }.bind(this));
      }
    },
    onReady: function () {
      if (this.$el.campaignHistory) {
        this._initOldCampaignPerformanceChart();
      }
      if (this.$el.campaignPerformance) {
        this._initCampaignPerformanceChart();
      }
      if (this.$el.audienceGrowthChart) {
        this._initAudienceGrowthChart();
      }
      window.dataLayer.push({
        event: "e_pageLoad",
        destination: "list_detail",
        list_id: this.listId,
        login_id: this.loginId,
        user_id: this.userId
      });
    },
    _initAudienceGrowthChart: function () {
      var existingSubscribers = this.translatedTerms["audience_overview_existing_subscribers"] || "Existing subscribers";
      var audienceChange = this.translatedTerms["audience_overview_audience_change"] || "Audience Change";
      var noData = this.translatedTerms["audience_overview_no_growth_data"] || "Hmm...Looks like you don't have growth data for this time period.";
      var audienceGrowthChart = mccharts.models.stackBarChart();
      this._overviewAjaxCalls(mUrl.toUrl("dashboard/audience"), {
        period: 7,
        list_id: this.listId
      }).then(function (data) {
        var audienceData = {
          "subscribers": data.growth.data.subscribers.active,
          "net_growth": data.growth.data.subscribers.net_growth
        };
        audienceGrowthChart.aspectRatio(2, 1);
        audienceGrowthChart.xAxis.keyValue("date");
        audienceGrowthChart.yAxis.keyValue("val");
        audienceGrowthChart.yAxis.maxTicks(6);
        audienceGrowthChart.colors([mColors.dataViz.combo1.color1, mColors.dataViz.combo1.color2]);
        audienceGrowthChart.noDataMessage(noData);
        var audienceGrowthLegend = audienceGrowthChart.legend();
        audienceGrowthLegend.seriesLabels([existingSubscribers, audienceChange]);
        audienceGrowthLegend.horizontalAlign("right");
        var audienceGrowthChartToolTip = audienceGrowthChart.tooltip();
        audienceGrowthChartToolTip.enabled(true);
        audienceGrowthChartToolTip.xOffset(10);
        audienceGrowthChartToolTip.dataFormatter(function (d) {
          var commaFormat = d3.format(",");
          return d3.time.format("%b %d, %Y")(d.x) + "<br/>" + audienceGrowthLegend.seriesLabels()[d.seriesIndex] + ": " + commaFormat(d.y);
        });
        audienceGrowthChart.loadChart("#audience-growth-chart", audienceData);
      });
      on(this.$widget.audienceDaySelect, "change", lang.hitch(this, function (val) {
        this._overviewAjaxCalls(mUrl.toUrl("dashboard/audience"), {
          period: val,
          list_id: this.listId
        }).then(function (data) {
          // Update Growth Chart
          var config = {};
          if (val === "360") {
            config.xAxisFormat = "%b %y";
          } else {
            config.xAxisFormat = "%b %d";
          }
          var audienceData = {
            "subscribers": data.growth.data.subscribers.active,
            "net_growth": data.growth.data.subscribers.net_growth
          };
          audienceGrowthChart.update(audienceData, config);
        });
      }));
    },
    _initCampaignPerformanceChart: function () {
      var openRate = this.translatedTerms["audience_overview_open_rate"] || "Open Rate";
      var clickRate = this.translatedTerms["audience_overview_click_rate"] || "Click Rate";
      var noData = this.translatedTerms["audience_overview_no_campaign_performance"] || "Hmmm, seems there is no data to show";
      var campaignPerformance = mccharts.models.multiLineChart();
      this._overviewAjaxCalls(mUrl.toUrl("/reports/campaign-history-json-data"), {
        list_id: this.listId
      }).then(function (data) {
        campaignPerformance.xAxis.showTicks(false);
        campaignPerformance.yAxis.keyValues(["openRate", "clickRate"]);
        campaignPerformance.yAxis.tickFormat(mccharts.u.percentageTick);
        campaignPerformance.colors([mColors.dataViz.combo1.color1, mColors.dataViz.combo1.color2]);
        campaignPerformance.noDataMessage(noData);
        var legend = campaignPerformance.legend();
        legend.seriesLabels([openRate, clickRate]);
        legend.horizontalAlign("right");
        var lineChartToolTip = campaignPerformance.tooltip();
        lineChartToolTip.enabled(true);
        lineChartToolTip.dataFormatter(function (d) {
          var str = "";
          if (d.serie0) {
            str = openRate + "<span class='font-weight--bold'>" + d.serie0.openRate + "%</span> <br>";
          }
          if (d.serie1) {
            str = str + clickRate + "<span class='font-weight-bold'>" + d.serie1.clickRate + "%</span> <br>";
          }
          if (d.serie0) {
            str = str + d.serie0.bubbleText;
          } else {
            str = str + d.serie1.bubbleText;
          }
          return str;
        });
        campaignPerformance.loadChart("#campaign-performance", [data, data]);
      });
    },
    _initOldCampaignPerformanceChart: function () {
      new mojoCharts.PerformanceOverviewChart("campaign-history", mUrl.toUrl("/reports/campaign-history-json-data", {
        list_id: this.listId
      }));
    },
    _overviewAjaxCalls: function (url, query) {
      return xhr(url, {
        query: query,
        handleAs: "json"
      });
    }
  });
});