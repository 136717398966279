define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/neapolitan/style-keys", "dojo/text!./templates/paddingInput.html"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, styleKeys, tpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    label: "Padding",
    templateString: tpl,
    /**
     * paddingValues is an Object structured like the following:
     *
     * {
     *   [styleKeys.PADDING_TOP]: "15px",
     *   [styleKeys.PADDING_RIGHT]: "15px",
     *   [styleKeys.PADDING_BOTTOM]: "15px",
     *   [styleKeys.PADDING_LEFT]: "15px"
     * }
     *
     * Default values of an empty string is set in the constructor.
     */
    paddingValues: {},
    _paddingStyleKeyToAttachPoint: {},
    constructor: function (opts) {
      styleKeys.PADDING_PROPS.forEach(function (paddingKey) {
        this.paddingValues[paddingKey] = opts.properties[paddingKey] || "";
      }.bind(this));

      // Setup Object for associating styleKeys with Measurement input widgets
      this._paddingStyleKeyToAttachPoint[styleKeys.PADDING_TOP] = "paddingTopInput";
      this._paddingStyleKeyToAttachPoint[styleKeys.PADDING_LEFT] = "paddingLeftInput";
      this._paddingStyleKeyToAttachPoint[styleKeys.PADDING_RIGHT] = "paddingRightInput";
      this._paddingStyleKeyToAttachPoint[styleKeys.PADDING_BOTTOM] = "paddingBottomInput";
      this.inherited(arguments);
    },
    postCreate: function () {
      // Sync bottom input to top input
      this.paddingTopInput.on("change", function (value) {
        this.paddingBottomInput.set("value", value);
      }.bind(this));

      // Sync right input to left input
      this.paddingLeftInput.on("change", function (value) {
        this.paddingRightInput.set("value", value);
      }.bind(this));
      styleKeys.PADDING_PROPS.forEach(function (paddingKey) {
        var inputKey = this._paddingStyleKeyToAttachPoint[paddingKey];
        this[inputKey].set("disabled", false);
        this[inputKey].on("change", function (value) {
          this.onChange(paddingKey, value);
        }.bind(this));
      }.bind(this));
      this.inherited(arguments);
    },
    onChange: function () {/* no-op */}
  });
});