define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/CampaignsL0ZeroState.html"], function (declare, on, _WidgetBase, _TemplatedMixin, templateString) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: templateString,
    // required
    heading: null,
    body: null,
    // optional
    ctaText: null,
    ctaLink: null
  });
});