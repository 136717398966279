define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/fx", "dojo-proxy-loader?name=dojo/_base/fx!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/activity-feed/activity-item-survey-response.html"], function (declare, domClass, domConstruct, domStyle, fx, baseFx, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl) {
  var SurveyResponseFeedItem = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    data: null,
    surveyResponseUrl: '',
    postMixInProperties: function () {
      var url = new URL(window.location);
      url.pathname = 'lists/surveys/results';
      url.searchParams.delete('id');
      url.searchParams.set('survey_id', this.data.survey_id);
      if (this.data.survey_response_id) {
        url.searchParams.set('response', this.data.survey_response_id);
      }
      this.surveyUrl = url.toString();
    }
  });
  return SurveyResponseFeedItem;
});