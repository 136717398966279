define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../web-renderers/ImageCardBlock", "dojo/text!../editors/blocks/templates/WebVideoEditor/outputWeb.html"], function (declare, ImageCardBlock, tpl) {
  return declare("mojo.neapolitan.web-renderers.WebVideoBlock", [ImageCardBlock], {
    outputTplStr: tpl,
    videoContainerStyles: " position:relative; height:0; overflow:hidden; padding-bottom:",
    _getVideoContainerStyles: function (meta) {
      if (meta.video && meta.mergeString !== "") {
        return this.videoContainerStyles + String(meta.video.heightRatio) + "%;";
      }
      return "";
    },
    // overwrite the _getContentFlex for web video because the iframe video takes slightly
    // more space than the flex-basis percentage with a caption on right and left alignment
    // to combat this hostile take-over of space, set the flex-shrink on the caption to 1
    _getContentFlex: function (captionWidth, captionPosition) {
      var captionFlex;
      var imageFlex;
      if (captionPosition === "top" || captionPosition === "bottom") {
        captionFlex = "0 0 auto";
        imageFlex = "0 0 auto";
      } else {
        switch (captionWidth) {
          case "one-third":
            captionFlex = "0 1 30%";
            imageFlex = "0 0 70%";
            break;
          case "two-third":
            imageFlex = "0 0 30%";
            captionFlex = "0 1 70%";
            break;
          case "three-quarter":
            captionFlex = "0 1 75%";
            imageFlex = "0 0 25%";
            break;
          default:
            captionFlex = "0 1 50%";
            imageFlex = "0 0 50%";
            break;
        }
      }
      return {
        caption: captionFlex,
        image: imageFlex
      };
    },
    getOutputModel: function (meta) {
      var calculatedWidths = this._getWidths(meta);
      var caption = meta.caption;
      var cardImageContentStyle = this._getCardImageContentStyle(this._getImagePadding(meta.captionPosition, true), meta.captionWidth, meta.captionPosition);
      var imageContentClassNames = this._getClassName(meta.captionPosition);
      var styleFlexDirection = this._getFlexDirection(meta.captionPosition);
      if (caption.image) {
        if (caption.image && caption.image.width > calculatedWidths.image) {
          caption.image.calculatedWidth = calculatedWidths.image;
        } else {
          caption.image.calculatedWidth = caption.image.width;
        }
      }
      if (!meta.video || !meta.video.mergeString) {
        meta.video = null;
      }
      if (!meta.captionEnabled) {
        cardImageContentStyle = this._getCardImageContentStyle(this._getImagePadding("bottom", true), null, "bottom");
        caption = null;
        imageContentClassNames = this._getClassName("bottom");
        styleFlexDirection = this._getFlexDirection("bottom");
      }
      return {
        widths: calculatedWidths,
        align: meta.align,
        caption: caption,
        innerPadding: this.innerPadding,
        imageContentClassNames: imageContentClassNames,
        styleFlexDirection: styleFlexDirection,
        totalImagePadding: this._getImagePadding(meta.captionPosition, meta.edgeToEdge),
        cardImageContentStyle: cardImageContentStyle,
        captionContentStyle: this._getCaptionContentStyle(meta.captionWidth, meta.captionPosition),
        video: meta.video,
        videoContainerStyles: this._getVideoContainerStyles(meta)
      };
    }
  });
});