define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-traverse", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/touch", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (array, event, dom, domClass, query, /* adds methods to query */nodeListTraverse, on, touch, ready) {
  ready(function () {
    // Vars
    var hoverlist = query(".hover-list");
    var maincontent = dom.byId("main-content");
    var wizardcontainer = query(".wizard-container")[0];
    var columntoggle = query(".column-toggle")[0];
    var active = [];
    var actions = {
      closeAll: function (target, index) {
        if (active.length > 0 && index !== active[0]) {
          domClass.remove(query(".hover-list")[active[0]], "stayopen");
          active.length = 0;
        }
        this.handleClick(target, index);
      },
      handleClick: function (target, index) {
        if (domClass.contains(target, "stayopen")) {
          domClass.remove(target, "stayopen");
          active.pop();
        } else {
          domClass.add(target, "stayopen");
          active.push(index);
        }
      }
    };
    hoverlist.forEach(function (h, index) {
      on(h, "click", function (e) {
        actions.closeAll(this, index);
      });
    });

    // Close hover-list menu on clicks outside of hover-list
    if (maincontent) {
      on(maincontent, "click", function (e) {
        var q = query(e.target).parents(".hover-list");
        if (q.length === 0) {
          if (active.length > 0) {
            domClass.remove(query(".hover-list")[active[0]], "stayopen");
            active.length = 0;
          }
        }

        // Column toggle popup that shows up with the subscriber table
        var r = query(e.target).parents(".reorder");
        if (r.length === 0) {
          if (columntoggle && domClass.contains(columntoggle, "column-toggle--active")) {
            domClass.remove(columntoggle, "column-toggle--active");
          }
        }
      });
    }
    if (wizardcontainer) {
      on(wizardcontainer, "click", function (e) {
        var q = query(e.target).parents(".hover-list");
        if (q.length === 0) {
          if (active.length > 0) {
            domClass.remove(query(".hover-list")[active[0]], "stayopen");
            active.length = 0;
          }
        }
      });
    }
  }); // ready
});