define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/TextBox", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/widgets/ColorPicker", "./_WithResetLinkMixin"], function (declare, TextBox, domConstruct, domClass, domStyle, keys, Color, ColorPicker, _WithResetLinkMixin) {
  // Global color picker for all color inputs

  var colorPicker = null;
  function getColorPicker() {
    if (!colorPicker || colorPicker._destroyed) {
      colorPicker = new ColorPicker();
      colorPicker.startup();
      domConstruct.place(colorPicker.domNode, document.body);
    }
    return colorPicker;
  }
  return declare([TextBox, _WithResetLinkMixin], {
    label: "Color",
    // Value to use when the input is empty. Allow us to change the default to "transparent" or "none"
    valueOnEmpty: "",
    // Control the visibility of the no color control on the color picker
    noColorEnabled: false,
    postCreate: function () {
      this.picker = getColorPicker();
      if (this.picker) {
        this.picker.addInput(this);
      }
      domClass.add(this.domNode, "c-colorInput");
      this.colorSwatch = domConstruct.place("<div class='c-colorInput_previewSwatch' title='Color preview'/>", this.domNode, "first");
      this.inherited(arguments);

      // if the starting value is null, always hide the reset.
      this.toggleResetLink(this.value === null || !this._isEmptyValue(this.value));
      this.toggleUnsetIndicator();
      this.on("keydown", function (e) {
        if (e.keyCode === keys.ENTER) {
          e.stopPropagation();
          e.preventDefault();
          this._setValueAttr(this.get("value"), true);
        }
      });
    },
    onChange: function () {
      var value = this.value;
      this.toggleResetLink(!this._isEmptyValue(value));
      this.toggleUnsetIndicator();
      if (!this._isEmptyValue(value)) {
        var color = Color.fromString("#" + value.replace(/#/g, ""));
        color = color || Color.fromString(value);
        value = color == null ? '#ffffff' : color.toHex();
        this.set('value', value, false);
      }
    },
    _isEmptyValue: function (value) {
      return value === null || value === "" || value === this.valueOnEmpty;
    },
    _setValueAttr: function (value) {
      if ((value === null || value === "") && value !== this.valueOnEmpty) {
        this.inherited(arguments, [this.valueOnEmpty]);
      } else {
        this.inherited(arguments);
      }

      // Clear out the visible textbox if the value matches `valueOnEmpty`
      this.textbox.value = this._isEmptyValue(value) ? "" : value;
      this._updateColor();
    },
    _updateColor: function () {
      if (this.colorSwatch) {
        domStyle.set(this.colorSwatch, 'backgroundColor', this.value);
      }
    },
    startup: function () {
      this.inherited(arguments);
      this._updateColor();
    },
    onReset: function () {
      this.set("value", this.valueOnEmpty);
    },
    toggleUnsetIndicator: function () {
      domClass.toggle(this.colorSwatch, "v-unsetSwatch", this._isEmptyValue(this.value));
    }
  });
});