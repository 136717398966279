/**
 * Hamburger button
 *
 * Widget used to display tablet and/or phone navigation.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./MobileNavigation",
// Mixins
"dijit/_WidgetBase", "dijit/_AttachMixin"], function (declare, domClass, on, query, topic, MobileNavigation, _WidgetBase, _AttachMixin) {
  return declare([_WidgetBase, _AttachMixin], {
    /**
     * Attach an event listener for the navigation changing
     */
    postCreate: function () {
      on(this.hamburgerBtnNode, "click", this._toggleMenu);
      topic.subscribe("patterns/navigation/change", this._handleNavChange.bind(this));
    },
    /**
     * Listen to the topic subscription and toggle the appropriate button icons.
     *
     * @param {object} navState - { visible: true/false }
     * @private
     */
    _handleNavChange: function (navState) {
      domClass.toggle(this.freddicon, "cross", navState.visible);
      domClass.toggle(this.freddicon, "hamburger", !navState.visible);
    },
    /**
     * Measure visibility of the navigation and show/hide accordingly.
     *
     * @private
     */
    _toggleMenu: function () {
      if (MobileNavigation.isVisible()) {
        MobileNavigation.hide();
        return;
      }
      MobileNavigation.show();
    }
  });
});