define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dijit/registry", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, topic, base, mUrl, tabContainerUtil, FilterMenu, FilterMessage, registry, dom) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        tab.preload = true;
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      topic.subscribe("mojo/analytics/List/page-list/render", function (list) {
        self.parse();
        tabContainer.pagesSection.set("title", "Landing Pages (" + list.total + ")");
      });
      topic.subscribe("mojo/analytics/List/page-deleted-list/render", function (list) {
        self.parse();
        tabContainer.deletedPagesSection.set("title", "Deleted Landing Pages (" + list.total + ")");
      });
      if (this.activeSection === "page-details" || mUrl.getHashParams().t === "page-details-section") {
        var pageIdFromUrl = new URL(window.location.href).searchParams.get("page_id");
        if (pageIdFromUrl) {
          this.viewPageDetails(pageIdFromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewPageDetails(mUrl.getHashParams().id);
        }
      }
    },
    /**
     *  opens page details tab
     * @param {number} page_id - the page id
     */
    viewPageDetails: function (page_id) {
      var self = this;
      var newPaneObj = {
        title: "Page Details: " + page_id,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "page-details",
          user_id: self.userAttrs.userId,
          page_id: page_id
        }),
        stub: "page-details",
        closable: true,
        onClose: function () {
          self.tabContainer.pageDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, page_id);
    },
    /**
     * resizes the height of the iframe to fit the whole page.
     */
    resizeIframe: function () {
      var pageIframe = dom.byId("HtmlIframe");
      var frameheight = frames.pageIframe.document.body.scrollHeight;
      pageIframe.style.height = frameheight + "px";
    }
  });
});