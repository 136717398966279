define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo/text!./templates/SliderPaneWrapper.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/url", "dojo/request"], function (declare, query, _Widget, _Templated, tpl, on, html, event, keys, lang, Deferred, Dialog, mUrl, request) {
  /**
   * A confirmation dialog for deletin' stuffs.
   */
  return declare([_Widget, _Templated], {
    templateString: tpl,
    dialog: null,
    deferred: null,
    dialogTitle: "Ad Campaign Terms of Use",
    ad: null,
    type: null,
    postCreate: function () {
      this.deferred = new Deferred();
    },
    execute: function () {
      this.resolve();
    },
    resolve: function () {
      this.deferred.resolve();
      this.dialog.hide();
    },
    cancel: function () {
      this.deferred.reject(false);
      this.dialog.hide();
    },
    show: function () {
      this.dialog = new Dialog({
        "title": this.dialogTitle,
        "onHide": function () {
          this.cancel();
          this.dialog.destroyRecursive();
        }.bind(this),
        "onDownloadEnd": function () {
          query("[data-mc-el='cancelTos']", this.dialog.domNode).on("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            this.cancel();
          }.bind(this));
          query("[data-mc-el='acceptTos']", this.dialog.domNode).on("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            this._accept();
          }.bind(this));
        }.bind(this)
      });
      this.dialog.setHref(mUrl.toUrl("/ads/landing-page-tos", {
        id: this.ad.id
      }));
      this.dialog.startup();
      this.dialog.show();
      return this.deferred;
    },
    /**
    * If the user accepts the terms we tell the backend and hide the modal.
    * If the call to the backend fails for some reason the user will have
    * topress accept again.
    **/
    _accept: function () {
      request.post(mUrl.toUrl("/ads/" + this.type + "/accept-landing-page-terms"), {
        data: {
          id: this.ad.uniqueId
        },
        handleAs: "json"
      }).then(function (data) {
        if (data.status === "success") {
          this.resolve();
        } else {
          throw new Error("Unknown error " + JSON.stringify(data));
        }
      }.bind(this));
    }
  });
});