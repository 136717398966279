define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dijit/registry", "mojo/url", "mojo/user", "mojo/utils", "mojo/lib/flags",
// Mixins
"mojo/analytics/parts/_Part", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/top-level-selection-bar.html",
// Widgets
"mojo/analytics/parts/_Part", "dijit/form/DropDownButton", "./FilterBar", "./SegmentBar", "../tags/TagOverlay", "../tags/Api", "./SegmentOverlay", "./NewSegmentButton", "vendor/pluralize/pluralize", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, lang, domClass, domConstruct, domStyle, ioQuery, on, when, number, registry, mUrl, user, utils, flags, Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, barTplStr, _Part, DropDownButton, FilterBar, SegmentationBar, TagOverlay, TagApi, SegmentOverlay, NewSegmentButton, pluralize, I18nTranslation, context) {
  return declare([_Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: barTplStr,
    target: null,
    contactsSelected: false,
    listId: null,
    tags: [],
    filterBar: null,
    filterOverlay: null,
    tagFilter: [],
    segmentBar: null,
    segmentOverlay: null,
    segmentStore: null,
    selectSegment: null,
    editSegment: null,
    saveNewSegment: null,
    openAdvSegmentCreation: null,
    openSegmentCreation: null,
    closeSegmentCreation: null,
    addRemoveTagsOverlay: null,
    bulkActionsMenu: null,
    previewName: null,
    translations: [],
    // Turn actions on/off depending on whether or not we're in the data management tool mode
    isDataManagementView: false,
    onConfirmPrivacyDelete: null,
    permanentDeleteText: "Permanently Delete",
    /*
    * Get translations on instantiation
    */
    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (values) {
          this.translations = values;
        }.bind(this));
      }
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        this.permanentDeleteText = I18nTranslation.translate("permanently_delete");
      }
    },
    /**
     * Initialize UI, set up data, and append event handlers
     */
    init: function () {
      if (this.isDataManagementView) {
        this.setupDataManagementView();
      }
      TagApi.watchTags(lang.hitch(this, function (tags) {
        this.tags = tags;
      }), this.listId);

      // Setup re-usable overlays
      this.setupSegmentOverlay();
      this.setupTagFilterOverlay();
      this.setupTagSegmentOverlay();
      this.setupUntagSegmentOverlay();

      // Set up the different bar options
      this.setupActionBar();
      this.setupSegmentBar();
      this.setupTagFilterBar();

      // Restore state in the case of a refresh
      this.restoreState();
    },
    /**
     * Restore bar state from session on page load
     */
    restoreState: function () {
      if (this.target.tagFilter) {
        this.showFilterBar();
      } else if (this.target.segment) {
        if (this.target.segment.id) {
          // If a segment is preloaded into the Subscriber Table assume the user was viewing a segment
          // rather than editing it.
          // This has an unfortunate side-effect of potentially showing the view mode when folks
          // navigate directly to the edit screen.
          this.showViewSegment();
        } else {
          this.showPreviewSegment();
        }
      }
    },
    /**
     * Toggle classes if we are viewing the page in privacy tool mode
     */
    setupDataManagementView: function () {
      domClass.add(this.actionsBarContainer, "privacy");
      domClass.add(this.topLevelBarContainer, "privacy");
      domClass.add(this.newSegmentButton, "privacy");
      domClass.remove(this.privacyDeleteButton, "hide");
      domClass.remove(this.privacyDeleteButtonActionBar, "hide");
    },
    /**
     * Set up the bar that shows up
     * when contacts are selected in the target
     */
    setupActionBar: function () {
      // Set tag audience label conditionally
      var tagAudienceButtonLabel = "Tag All";

      // Button and overlay to apply/remove tags from selected contacts
      this.addRemoveTagsOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": true,
        "analyticsData": "Add or Remove Tags"
      });
      this.addRemoveTagsButton = new DropDownButton({
        "label": this.translations["all_contacts_add_or_remove_tags"] || "Add or Remove Tags",
        "dropDown": this.addRemoveTagsOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.addRemoveTagsButton);
      this.addRemoveTagsButton.startup();

      // Button and overlay to apply tags to entire audience
      this.tagAudienceOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": true,
        "analyticsData": "Tag All",
        "confirmationRequired": true,
        "confirmationWarningText": "You are about to tag all contacts in your audience."
      });
      this.tagAudienceButton = new DropDownButton({
        "label": tagAudienceButtonLabel,
        "dropDown": this.tagAudienceOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.tagAudienceButton);
      this.tagAudienceButton.startup();
      domStyle.set(this.tagAudienceButton.domNode, {
        "display": "none"
      });

      // Button and overlay to remove tags from entire audience
      this.untagAudienceOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": false,
        "bulkUntagTarget": "audience",
        "tagActionClass": "tag-action-buttons-list--removable",
        "analyticsData": "Untag All",
        "confirmationRequired": true,
        "confirmationWidgetId": "confirmUntagAudienceInput",
        "confirmationTitle": "UNTAG",
        "confirmationHelpText": "You are about to untag all selected contacts",
        "confirmationButtonText": "Untag Contacts"
      });
      this.untagAudienceButton = new DropDownButton({
        "label": "Untag All",
        "dropDown": this.untagAudienceOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.untagAudienceButton);
      this.untagAudienceButton.startup();
      domStyle.set(this.untagAudienceButton.domNode, {
        "display": "none"
      });

      // Button to apply tags to entire segment
      this.tagSegmentButton = new DropDownButton({
        "label": "Tag All",
        "dropDown": this.tagSegmentOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.tagSegmentButton);
      this.tagSegmentButton.startup();
      domStyle.set(this.tagSegmentButton.domNode, {
        "display": "none"
      });

      // Button to untag an entire segment
      this.untagSegmentButton = new DropDownButton({
        "label": "Untag All",
        "dropDown": this.untagSegmentOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.untagSegmentButton);
      this.untagSegmentButton.startup();
      domStyle.set(this.untagSegmentButton.domNode, {
        "display": "none"
      });
      if (user.flagIsOn(flags.AM_ORGANIZE_SELECTED_CONTACTS_COUNT)) {
        this.target.watch("selectedContactCountAction", lang.hitch(this, function (name, oldValue, newValue) {
          this.selectedContactCountMessage.innerText = this.formatSelectedContactCountMessage(newValue);
        }));
      }

      //Enable the tagging confirmation modal if select all has been pressed
      this.target.watch("allAll", lang.hitch(this, function (name, oldValue, newValue) {
        // Swap between tagging selection, segment, and audience
        if (newValue) {
          domStyle.set(this.addRemoveTagsButton.domNode, {
            "display": "none"
          });
          if (this.target.segment) {
            domStyle.set(this.tagSegmentButton.domNode, {
              "display": "inline-block"
            });
            domStyle.set(this.untagSegmentButton.domNode, {
              "display": "inline-block"
            });
          } else {
            domStyle.set(this.tagAudienceButton.domNode, {
              "display": "inline-block"
            });
            domStyle.set(this.untagAudienceButton.domNode, {
              "display": "inline-block"
            });
          }
        } else {
          domStyle.set(this.tagAudienceButton.domNode, {
            "display": "none"
          });
          domStyle.set(this.untagAudienceButton.domNode, {
            "display": "none"
          });
          domStyle.set(this.tagSegmentButton.domNode, {
            "display": "none"
          });
          domStyle.set(this.addRemoveTagsButton.domNode, {
            "display": "inline-block"
          });
        }
      }));
      on(this.addRemoveTagsOverlay, "open", lang.hitch(this, "getSelectionTaggingData"));
      on(this.addRemoveTagsOverlay, "applyTag", lang.hitch(this, "applyTagToSelection"));
      on(this.addRemoveTagsOverlay, "removeTag", lang.hitch(this, "removeTagFromSelection"));
      on(this.addRemoveTagsOverlay, "createTag", lang.hitch(this, "createTagForSelection"));

      // For tagging an entire audience, we currenly only support creating/adding tags (not removal)
      on(this.tagAudienceOverlay, "applyTag", lang.hitch(this, "applyTagToAudience"));
      on(this.tagAudienceOverlay, "createTag", lang.hitch(this, "createTagForAudience"));
      on(this.untagAudienceOverlay, "removeTag", lang.hitch(this, "untagAudienceOrSegment"));
      on(this.untagSegmentOverlay, "removeTag", lang.hitch(this, "untagAudienceOrSegment"));
      if (!this.isDataManagementView) {
        this.actionsButton = new DropDownButton({
          "label": this.translations["all_contacts_actions_label"] || "Actions",
          "dropDown": registry.byNode(this.bulkActionsMenu),
          "dropDownPosition": ["below", "above"]
        }, this.actionsButton);
        this.actionsButton.startup();
      } else {
        domClass.add(this.actionsButton, "hide");
      }
    },
    /**
     * Retrieve data required for creating the tag widget
     *
     * @private
     */
    getSelectionTaggingData: function () {
      this.addRemoveTagsOverlay.setLoadingState(true);
      TagApi.getSelectedContactsTags(this.listId, this.target.getSelection(), lang.hitch(this, "updateTaggingWidget"));
    },
    /**
     * Loop through the list tags and the tags for selected contacts
     * Mark the state of each list tag accordingly
     * Set the tag selection on the overlay
     * @param {object} selectedContactsWithTags Map of selected contact(s) and their tag(s)
     *
     * @private
     */
    updateTaggingWidget: function (selectedContactsWithTags) {
      var selectedContactCount = Object.keys(selectedContactsWithTags).length;
      var allTags = [];
      var mixedTags = [];
      var tags = {};

      //loop through each list tag
      Object.keys(selectedContactsWithTags).forEach(function (contact) {
        selectedContactsWithTags[contact].forEach(function (contactTagId) {
          if (!tags[contactTagId]) {
            tags[contactTagId] = 0;
          }
          tags[contactTagId]++;
        });
      });
      for (var tagId in tags) {
        if (tags.hasOwnProperty(tagId)) {
          if (tags[tagId] === selectedContactCount) {
            allTags.push(parseInt(tagId, 10));
          } else {
            mixedTags.push(parseInt(tagId, 10));
          }
        }
      }
      this.addRemoveTagsOverlay.setSelection({
        "all": allTags,
        "mixed": mixedTags
      });
      this.addRemoveTagsOverlay.setLoadingState(false);
    },
    /**
     * Make API call to apply tag to selected contacts
     * @param {object} tag Tag to apply
     * @private
     */
    applyTagToSelection: function (tag) {
      TagApi.applyTagToContacts(this.listId, this.target.getSelection(), tag.tag_id, lang.hitch(this, "taggingSuccessCallback"));
    },
    /**
     * Make API call to apply tag to entire audience
     * @param {object} tag Tag to apply
     * @private
     */
    applyTagToAudience: function (tag) {
      TagApi.applyTagToAudience(this.listId, tag.tag_id, lang.hitch(this, "tagAllSuccessCallback"));
    },
    /**
     * Make API call to untag audience or segment
     * @param {boolean} untagAudience Should entire audience be untagged
     * @param {object} tag Tag to apply
     * @private
     */
    untagAudienceOrSegment: function (untagAudience, tag) {
      TagApi.untagAudienceOrSegment(untagAudience, this.listId, tag.tag_name, tag.tag_id, lang.hitch(this, "untagAudienceOrSegmentSuccessCallback"));
    },
    /**
     * Make API call to remove tag from selected contacts
     * @param {object} tag Tag to remove
     * @private
     */
    removeTagFromSelection: function (tag) {
      TagApi.removeTagFromContacts(this.listId, this.target.getSelection(), tag.tag_id, lang.hitch(this, "taggingSuccessCallback"));
    },
    /**
     * Make API call to create new tag and apply it to selected contacts
     * @private
     */
    createTagForSelection: function () {
      TagApi.createTagApplyToContacts(this.listId, this.addRemoveTagsOverlay.getInputValue(), this.target.getSelection(), lang.hitch(this, "taggingSuccessCallback"));
    },
    /**
     * Make API call to create new tag and apply it to entire audience
     * @private
     */
    createTagForAudience: function () {
      TagApi.createTagApplyToAudience(this.listId, this.tagAudienceOverlay.getInputValue(), lang.hitch(this, "tagAllSuccessCallback"));
    },
    /**
     * Success callback to be called on update of audience's tags
     * @private
     */
    tagAllSuccessCallback: function () {
      utils.toast("Your audience is currently being tagged.");
      this.target.refresh();
      this.addRemoveTagsButton.closeDropDown();
    },
    /**
     * Success callback to be called on update of audience's tags
     * @private
     */
    untagAudienceOrSegmentSuccessCallback: function () {
      utils.toast("Your selected contacts are currently being untagged.");
      this.target.refresh();
    },
    /**
     * Success callback to be called on update of contact(s) tags
     * @private
     */
    taggingSuccessCallback: function () {
      utils.toast("Successfully updated tags for selected contacts");
      this.target.refresh();
      this.addRemoveTagsButton.closeDropDown();
    },
    /**
     * Set up UI and event handlers tag filtering overlay
     */
    setupTagFilterOverlay: function () {
      this.filterOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": false,
        "analyticsData": "Tag Filter"
      });
      this.filterButton = new DropDownButton({
        "label": this.translations["audience_management_filter_tags"] || "Filter by Tags",
        "dropDown": this.filterOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.filterButton);
      this.filterButton.startup();
      on(this.filterOverlay, "applyTag", lang.hitch(this, "showFilterBar"));
      this.filterOverlay.setSelection({
        "all": this.tagFilter,
        "mixed": []
      });
      TagApi.watchTags(lang.hitch(this, function (tags) {
        if (!tags || tags.length < 1) {
          this.filterButton.setDisabled(true);
        } else {
          this.filterButton.setDisabled(false);
        }
      }), this.listId);
    },
    /**
     * Set up UI and event handlers filtering contacts by tag bar
     */
    setupTagFilterBar: function () {
      this.filterBar = new FilterBar({
        "target": this.target,
        "listId": this.listId,
        "filterOverlay": this.filterOverlay,
        "tagFilter": this.tagFilter
      });
      this.filterBar.placeAt(this.filterBarContainer, "only");
      on(this.filterBar, "close", lang.hitch(this, "closeSecondaryBars"));
      on(this.filterBar, "filterUpdate", lang.hitch(this, "refreshTableFilter"));
    },
    /**
     * Set up UI and event handlers for segment list overlay
     */
    setupSegmentOverlay: function () {
      this.segmentOverlay = new SegmentOverlay({
        "target": this.target,
        "listId": this.listId,
        "segmentStore": this.segmentStore,
        "selectSegment": this.selectSegment
      });
      this.segmentButton = new DropDownButton({
        "label": this.translations["audience_management_view_segment"] || "View Segment",
        "dropDown": this.segmentOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.segmentButton);
      this.segmentButton.startup();
      on(this.segmentButton, "click", lang.hitch(this, function () {
        window.ga("send", "event", "navigation", "cta", "dropdown", {
          cd53: this.segmentButton.label,
          cd55: "view segment submenu",
          cd56: "open menu"
        });
      }));
      on(this.segmentOverlay, "selectSegment", lang.hitch(this, function (segment) {
        window.ga("send", "event", "navigation", "cta", "dropdown", {
          cd53: this.segmentButton.label,
          cd55: "view segment submenu",
          cd56: "select segment"
        });
        this.segmentBar.updateSelectedSegment(segment);
        this.showViewSegment();
      }));
      on(this.segmentOverlay, "editSegment", lang.hitch(this, "showEditSegment"));
      this.newSegmentButton = new NewSegmentButton({
        onRegularSegmentClick: function () {
          this.handleCreateSegment();
        }.bind(this),
        onAdvancedSegmentClick: function () {
          this.handleCreateAdvSegment();
        }.bind(this)
      }, this.newSegmentButton);
      this.newSegmentButton.startup();
    },
    /**
     * Set up UI for overlay to tag entire segment or segment preview
     */
    setupTagSegmentOverlay: function () {
      this.tagSegmentOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": true,
        "analyticsData": "Tag Segment or Segment Preview",
        "confirmationRequired": true,
        "confirmationWarningText": "You are about to tag all contacts in this segment."
      });
    },
    /**
     * Set up UI for overlay to untag entire segment or segment preview
     */
    setupUntagSegmentOverlay: function () {
      this.untagSegmentOverlay = new TagOverlay({
        "listId": this.listId,
        "tagCreationAllowed": false,
        "bulkUntagTarget": "segment",
        "tagActionClass": "tag-action-buttons-list--removable",
        "analyticsData": "Untag Segment or Segment Preview",
        "confirmationRequired": true,
        "confirmationWidgetId": "confirmUntagSegmentInput",
        "confirmationTitle": "UNTAG",
        "confirmationHelpText": "You are about to untag all selected contacts"
      });
    },
    /**
     * Set up UI and event handlers for segment bar
     */
    setupSegmentBar: function () {
      this.segmentBar = new SegmentationBar({
        "listId": this.listId,
        "target": this.target,
        "segmentOverlay": this.segmentOverlay,
        "tagSegmentOverlay": this.tagSegmentOverlay,
        "previewName": this.previewName
      });
      this.segmentBar.placeAt(this.segmentBarContainer, "only");
      if (this.getQuery().open_segment) {
        this.showCreateSegment();
      }
      on(this.segmentBar, "editSegment", lang.hitch(this, "showEditSegment"));
      on(this.segmentBar, "saveNewSegment", lang.hitch(this, "saveNewSegment"));
      on(this.segmentBar, "rerunAdvanced", lang.hitch(this, "rerunAdvancedSegment"));
      on(this.segmentBar, "close", lang.hitch(this, "closeSecondaryBars"));
    },
    /**
     * Show UI for filtering by tag(s) bar
     */
    showFilterBar: function () {
      this.filterButton.closeDropDown();
      domClass.add(this.topLevelBarContainer, "hide");
      domClass.remove(this.filterBarContainer, "hide");
    },
    /**
     * Show UI for segment bar
     */
    showSegmentBar: function () {
      domClass.add(this.topLevelBarContainer, "hide");
      domClass.remove(this.segmentBarContainer, "hide");
    },
    /**
     * Show the bar for viewing a segment preview
     */
    showPreviewSegment: function () {
      this.segmentBar.setMode("preview");
      this.showSegmentBar();
    },
    /**
     * Show the bar for viewing a segment
     */
    showViewSegment: function () {
      this.segmentButton.closeDropDown();
      this.segmentBar.setMode("view");
      this.showSegmentBar();
    },
    /**
     * Handle clicking the "New Advanced Segment button"
     */
    handleCreateAdvSegment: function () {
      when(this.openAdvSegmentCreation(), lang.hitch(this, function () {
        this.showCreateSegment();
      }));
    },
    /**
     * Handle clicking the "New Segment button"
     */
    handleCreateSegment: function () {
      when(this.openSegmentCreation(), lang.hitch(this, function () {
        this.showCreateSegment();
      }));
    },
    /**
     *  Show the bar for creating a new segment
     */
    showCreateSegment: function () {
      this.segmentBar.setMode("create");
      this.showSegmentBar();
      window.ga("send", "event", "segments", "start", "table view", {
        cm11: 1
      });
    },
    /**
     * Get the query string for the current page as an object
     * @returns {object} query converted to object
     */
    getQuery: function () {
      var queryString = window.location.search;
      queryString = queryString[0] === "?" ? queryString.substr(1) : null;
      if (queryString) {
        return ioQuery.queryToObject(queryString);
      }
      return {};
    },
    /**
     * Show the bar for editing a segment from the segment overlay
     * @param {object} segment to edit
     */
    showEditSegment: function (segment) {
      if (segment) {
        this.target.setSegment(segment);
      }

      // this.segmentButton starts as a domNode. It gets converted
      // to a widget as part of the initialization.
      // checking the type here avoids a race condition
      if (typeof this.segmentButton.closeDropDown === "function") {
        this.segmentButton.closeDropDown();
      }
      when(this.editSegment(), lang.hitch(this, function () {
        this.segmentBar.setMode("edit");
        this.showSegmentBar();
      }));
    },
    /**
     * Close all secondary bar UI (filter and segment bars)
     * Clear session segment and close segment builder
     */
    closeSecondaryBars: function () {
      // if we're showing an ES segment, we'll be clearing the segment later down the line on close, so skip for now
      if (!this.target.showingElasticSearchSegment) {
        this.target.clearSegment();
      }
      this.closeSegmentCreation();
      // Show/hide the UI components
      domClass.remove(this.topLevelBarContainer, "hide");
      domClass.add(this.filterBarContainer, "hide");
      domClass.add(this.segmentBarContainer, "hide");
      domStyle.set(this.untagSegmentButton.domNode, {
        "display": "none"
      });
    },
    /**
     * Reload the subscriber table when session has been updated
     * E.g. for filter updates and segment selection
     * @param {array} tagIds list of current filter tags
     */
    refreshTableFilter: function (tagIds) {
      var newFilter = tagIds.length > 0 ? tagIds : null;
      if (newFilter) {
        this.target.setToCustomSegment();
      } else {
        this.target.clearSegment();
      }
      this.target.setTagFilter(newFilter);
      this.target.requery();
    },
    /**
     * Override of _Part canDisplay function
     * @returns {boolean} can display value
     */
    canDisplay: function () {
      return true;
    },
    /**
     * Formats message to inform the user of
     * how many contacts they have selected
     * @param {string} action Action
     *
     * @returns {string} Msg
     */
    formatSelectedContactCountMessage: function (action) {
      var prefix = "";
      var suffix = "";
      if (action === "selectAll") {
        prefix = "All ";
        suffix = " including cleaned contacts";
      }
      var selectedContactCount = action === "selectAll" ? this.target.total : this.target.selectedContactCount;
      return prefix + number.format(selectedContactCount) + pluralize(" contact", selectedContactCount) + " selected" + suffix;
    }
  });
});