/**
 * Terms of Service Slat
 * @module mojo/widgets/ads/mobile-checklist/TermsOfServiceSlat.js
 * @see mojo/widgets/ads/mobile-checklist/FacebookSlat.js
 *
 * Pulls the terms of service content into the slats for mobile
 * Surfaced during Pre-Flight and must be accepted to publish
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"./_EventAdapterMixin", "dijit/_TemplatedMixin",
// Templates
"dojo/text!./templates/TermsOfServiceSlat.html"], function (declare, lang, query, domAttr, request, domConstruct, xhr, mUrl, _EventAdapterMixin, _TemplatedMixin, tplStr) {
  return declare([_EventAdapterMixin, _TemplatedMixin], {
    templateString: tplStr,
    postEndpoint: "/accept-terms",
    postMixInProperties: function () {
      // Since the endpoints are campaign specific we are mixing in the campaign type
      // with the widget's defined endpoint
      this.postEndpoint = "ads/" + this.campaignType + "/" + this.postEndpoint;
    },
    postCreate: function () {
      this._initTermsOfServiceRequest();
    },
    /**
     * Reach out to the backend to get the Terms of Service
     * then handle the success or error of the request
     *
     * @private
     */
    _initTermsOfServiceRequest: function () {
      var req = request.get(mUrl.toUrl("/ads/tos", {
        id: this.id
      }), {
        handleAs: "text"
      });
      req.then(function (response) {
        // Place the returned content into the domNode
        domConstruct.place(response, this.tosContainer);

        // Get the Campaign Specific Terms of Service Links (Facebook or Google)
        var campaignTosLinks = query("#fbTos a", this.tosContainer);

        // For each campaign specific link we want to give it target attribute of _blank
        campaignTosLinks.forEach(function (domNodeTarget) {
          domAttr.set(domNodeTarget, "target", "_blank");
        });

        // Notify the router that the slat loaded
        this.onLoaded();
      }.bind(this), function (error) {
        throw new Error("An error occurred retrieving Terms of Service");
      });
    },
    /**
     * Accept TOS and post to the acceptTerms endpoint
     *
     * @private
     */
    _acceptTos: function () {
      var action = xhr.post(mUrl.toUrl(this.postEndpoint), {
        data: {
          id: this.id
        },
        "handleAs": "json"
      });
      action.then(lang.hitch(this, "_onPostResponse"));
    },
    /**
     * Listen for what the response of the POST was and route to the appropriate callbacks.
     *
     * @param {obj} response - response containing data about the response and any messaging
     * @private
     */
    _onPostResponse: function (response) {
      switch (response.status) {
        case "success":
          this.onDone(response);
          break;
        default:
          throw new Error("Unknown TOS response state " + response.status);
      }
    },
    /**
     * Agree to terms of service on save/accept
     */
    __save: function () {
      this._acceptTos();
    },
    __cancel: function () {},
    __back: function () {}
  });
});