define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil"], function (declare, base, mUrl, tabContainerUtil) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "login-details") {
        this.modules.viewLogins.viewLoginDetails(window.location.href, "");
      }
    },
    refreshUserActivityPane: function (uid, page, type) {
      type = typeof type !== "undefined" ? type : "";
      if (this.tabContainer.userActivitySection) {
        var url = mUrl.toUrl("/peaches2/users/account", {
          _s: "user-activity",
          type: type,
          user_id: uid,
          page: page
        });
        this.tabContainer.userActivitySection.setHref(url);
      }
    }
  });
});