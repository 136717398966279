define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./RssItemsEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/RssItemsEditor/editorMobile.html"], function (declare, _RSSEditor, _MobileBlockMixin, editorTplMobile) {
  return declare([_RSSEditor, _MobileBlockMixin], {
    type: "rssItems-mobile",
    templateString: editorTplMobile,
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});