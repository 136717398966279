define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./TextEditor", "mojo/user", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo/text!./templates/FooterEditor/editor.html"], function (declare, TextEditor, user, domStyle, on, registry, editorTpl) {
  return declare([TextEditor], {
    type: 'footer',
    templateString: editorTpl,
    postCreate: function () {
      this.inherited(arguments);
      if (user.hasRole('manager')) {
        var dialog = registry.byId('edit-contactinfo-modal');
        if (dialog) {
          domStyle.set(this.editContactInfo, 'display', '');
          on(this.editContactInfo, 'click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            dialog.show();
          });
        }
      }
    }
  });
});