define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/MeterLarge.html", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity"], function (declare, _WidgetBase, _TemplatedMixin, tplStr, domClass, domStyle, domGeom, Velocity) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    type: null,
    value: null,
    label: "You",
    leftLabel: "Negative",
    rightLabel: "Positive",
    meterClass: null,
    postCreate: function () {
      this._initStyles();
      this._setPopover();
      this.animateFill();
    },
    _initStyles: function () {
      if (this.type === "spectrum") {
        this.meterClass = "spectrum";
        domClass.remove(this.meterAverage, "hide");
      }
      if (this.meterClass) {
        domClass.add(this.meterBarNode, this.meterClass);
      }
    },
    _setPopover: function () {
      var popoverWidth = domGeom.position(this.popover).w;
      var arrowPosition = Math.round(popoverWidth / 2 * -1);
      domStyle.set(this.popover, "right", arrowPosition + "px");
    },
    animateFill: function () {
      Velocity(this.meterFill, {
        width: this.value + "%"
      }, {
        easing: "easeInOut",
        duration: this.value >= 50 ? 1500 : 1000
      });
    }
  });
});