define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/form/Button", "dojo/text!./templates/ActionableDialogContent.html", "mojo/widgets/Dialog"], function (declare, lang, domConstruct, on, _WidgetBase, _TemplatedMixin, Button, contentTplStr, Dialog) {
  var ActionableDialogContent = declare([_WidgetBase, _TemplatedMixin], {
    templateString: contentTplStr,
    contentMessage: "",
    primaryActionLabel: "",
    onClickPrimaryAction: null,
    secondaryActionLabel: "",
    onClickSecondaryAction: null,
    dialog: null,
    postCreate: function () {
      this._setUpContentMessage();
      this._setUpPrimaryAction();
      this._setUpSecondaryAction();
    },
    _setUpContentMessage: function () {
      if (!this.contentMessage) {
        return;
      }
      domConstruct.create("p", {
        innerHTML: this.contentMessage
      }, this.contentArea, "first");
    },
    _setUpPrimaryAction: function () {
      if (!this.primaryActionLabel) {
        return;
      }
      var btn = Button({
        label: this.primaryActionLabel,
        "class": "button p0",
        "id": "primaryActionButton"
      });
      if (this.onClickPrimaryAction) {
        var self = this;
        on(btn, "click", function (evt) {
          self.onClickPrimaryAction(evt, self.dialog);
        });
      }
      btn.placeAt(this.actionBar, "last");
    },
    _setUpSecondaryAction: function () {
      if (!this.secondaryActionLabel) {
        return;
      }
      var link = domConstruct.create("a", {
        href: "#",
        innerHTML: this.secondaryActionLabel
      });
      if (this.onClickSecondaryAction) {
        var self = this;
        on(link, "click", function (evt) {
          self.onClickSecondaryAction(evt, self.dialog);
        });
      }
      domConstruct.place(link, this.actionBar, "last");
    }
  });
  var ActionableDialog = declare([Dialog], {
    constructor: function () {
      this.content = new ActionableDialogContent(lang.mixin(arguments[0], {
        "dialog": this
      }));
    }
  });
  return ActionableDialog;
});