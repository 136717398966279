define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// dom.setSelectable
"./_WidgetBase", "./_TemplatedMixin", "./_Contained", "dojo/text!./templates/MenuSeparator.html"], function (declare, dom, _WidgetBase, _TemplatedMixin, _Contained, template) {
  // module:
  //		dijit/MenuSeparator

  return declare("dijit.MenuSeparator", [_WidgetBase, _TemplatedMixin, _Contained], {
    // summary:
    //		A line between two menu items

    templateString: template,
    buildRendering: function () {
      this.inherited(arguments);
      dom.setSelectable(this.domNode, false);
    },
    isFocusable: function () {
      // summary:
      //		Override to always return false
      // tags:
      //		protected

      return false; // Boolean
    }
  });
});