define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/url", "mojo/app/peaches2/utils"], function (declare, base, query, xhr, domAttr, domStyle, domConstruct, dom, registry, mUrl, p2Utils) {
  return declare([base], {
    shards: null,
    searchId: null,
    onReady: function () {
      var self = this;
      if (this.shards && this.searchId) {
        this.shards.forEach(function (obj) {
          self.$widget["dc" + obj].set("onLoad", function () {
            p2Utils.utils.refreshOnError(this);
            self.parse(this.id);
            if (self.$el["conditions" + obj]) {
              xhr(mUrl.toUrl("/peaches2/qa/search/verify-conditions"), {
                query: {
                  conditions: self.$el["conditions" + obj].innerHTML
                },
                handleAs: "json"
              }).then(function (data) {
                // Verify that every row meet the special conditions. Hide those who don't
                // Special conditions are those conditions that need to be verified in the user's database, not global
                query("[data-user-id]").forEach(function (entry, i) {
                  var user_id = domAttr.get(entry, "data-user-id");
                  if (data.indexOf(user_id) === -1) {
                    domConstruct.destroy(entry);
                  }
                });

                // If there are still users in the table, make it visible. Otherwise, show the no data
                domStyle.set(self.$el["stillLoading" + obj], "display", "none");
                domStyle.set(self.$el["shardTable" + obj], "display", "");
              });
            }
          });
        });
      }
    },
    doCancel: function () {
      this.$widget.billingDropdown.attr("value", "Select Billing Type");
      this.$widget.attributes.options.forEach(function (item) {
        item.selected = false;
      });
      this.$widget.attributes._updateSelection();
      this.$widget.discount.attr("value", "Select Discount");
      this.$widget.integration.attr("value", "Select Integration");
      this.$widget.campaign.attr("value", "Select Campaign Type");
      this.$el.listSize.value = "";
      this.$el.sends.value = "";
      this.$el.exports.checked = false;
      this.$el.imports.checked = false;
      this.$el.orders.checked = false;
      domConstruct.destroy(this.$el.results);
      return false;
    },
    /**
     * clears search cache
     */
    clearCache: function () {
      xhr(mUrl.toUrl("/peaches2/qa/search/clear-cache"), {
        query: {
          search_id: this.searchId
        },
        handleAs: "json"
      }).then(function (data) {
        p2Utils.utils.setFlash(data.status + ": Cache cleared", "success");
      });
    }
  });
});