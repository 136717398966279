define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/activity-feed/activity-item-ecomm-order-item.html"], function (declare, lang, domStyle, domClass, on, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, itemTplString) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: itemTplString,
    item: null,
    /**
     * Set the background image, event handlers, and quantity badge for the order item
     */
    postCreate: function () {
      domStyle.set(this.orderItemImage, "background-image", "url('" + this.item.image_url + "')");
      if (this.item.product_url) {
        domClass.add(this.orderItemContainer, "cursor-pointer");
        on(this, "click", lang.hitch(this, function () {
          var win = window.open(this.item.product_url, "_blank");
          win.focus();
        }));
      }
      if (this.item.quantity > 1) {
        domClass.remove(this.quantityBadge, "hide");
      }
    }
  });
});