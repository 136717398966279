define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, on) {
  return declare([base], {
    onReady: function () {
      this.imageField = document.querySelector("[name='billboard_image'][accept][data-max-file-size][data-max-file-size-formatted]");
      if (!this.imageField) {
        return;
      }
      this.maxFileSize = this.imageField.dataset.maxFileSize;
      this.maxFileSizeFormatted = this.imageField.dataset.maxFileSizeFormatted;
      this.validFileTypes = this.imageField.accept.split(",");
      this.fileTypesFormatted = this.imageField.accept.replace(/image\//g, " ");
      this.isValid = true;
      this.addEventListeners();
    },
    addEventListeners: function () {
      var self = this;
      this.imageField.addEventListener("change", function (e) {
        // Remove an existing error messages
        self.reset();
        var file = self.imageField.files[0];

        // Validate image file size is under max
        self.validateMaxFileSize(file);

        // Validate the image type is a supported on
        self.validateImageType(file);
        if (!self.isValid) {
          self.imageField.value = "";
        }
      });
    },
    reset: function () {
      this.isValid = true;
      this.imageField.parentNode.querySelectorAll(".invalid-error").forEach(function (el) {
        el.parentNode.removeChild(el);
      });
    },
    validateMaxFileSize: function (file) {
      if (file.size > this.maxFileSize) {
        this.imageField.insertAdjacentHTML("afterend", "<div class='invalid-error'>Image file size is too large and exceeds " + this.maxFileSizeFormatted + "</div>");
        this.isValid = false;
      }
    },
    validateImageType: function (file) {
      if (this.validFileTypes.indexOf(file.type) === -1) {
        this.imageField.insertAdjacentHTML("afterend", "<div class='invalid-error'>Image file type is invalid and not one of " + this.fileTypesFormatted + "</div>");
        this.isValid = false;
      }
    }
  });
});