define(["dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "./entri/constants", "./entri/domainChecklistApi"], function entri(Deferred, all, xhr, mUrl, constants, domainChecklistApi) {
  var exports = {};

  // From @mc/script-loader
  function load(url) {
    var deferred = new Deferred();
    var script = document.createElement("script");
    script.onload = function () {
      deferred.resolve(url);
    };
    script.onerror = function (event) {
      deferred.reject([event, url]);
    };
    script.async = true;
    script.src = url;
    document.body.appendChild(script);
    return deferred.promise;
  }

  // From @mc/entri/config
  function loadScriptOnce() {
    var deferred = new Deferred();
    var container = window;
    if (!container.entri) {
      load(constants.ENTRI_SCRIPT_URI).then(function () {
        deferred.resolve([container.entri, container]);
      }, function () {
        deferred.reject("load entri failed");
      });
    } else {
      deferred.resolve([container.entri, container]);
    }
    return deferred.promise;
  }

  // From @mc/entri/config
  function getConfig() {
    var deferred = new Deferred();
    xhr.get(mUrl.toUrl(constants.ENTRI_CONFIG_URI), {
      handleAs: "json"
    }).then(function (config) {
      if (config && typeof config === "object" && !Array.isArray(config)) {
        deferred.resolve(Object.assign({}, config, {
          dnsRecords: constants.ENTRI_CONFIG_DNS_RECORDS,
          // When set to true, Entri will automatically check if there is an existing and valid DMARC record.
          // If so, Entri will not update the DMARC record.
          validateDmarc: true
        }));
      } else {
        deferred.reject("invalid getConfig response");
      }
    }, function () {
      deferred.reject("unexpected getConfig response");
    });
    return deferred.promise;
  }

  // From @mc/entri/withEntri
  function withEntri() {
    var deferred = new Deferred();
    all([loadScriptOnce(), getConfig()]).then(function (allResult) {
      var loader = allResult[0];
      var config = allResult[1];
      var entriObj = loader[0];
      var container = loader[1];
      deferred.resolve([entriObj, config, container]);
    }, function (reason) {
      deferred.reject(reason);
    });
    return deferred.promise;
  }

  // From @mc/entri/entri
  function parseDomain(domainOrEmailAddress) {
    var matches = (domainOrEmailAddress || "").match(
    // email@sub.example.com -> { hostname: 'sub.example.com', subdomain: 'sub', domain: 'example.com' }
    /(?:.*@)?(.*?(?:(.*?)\.)?([^.@]+\.[^.]+))$/) || [];
    return {
      hostname: matches[1],
      subdomain: matches[2],
      domain: matches[3]
    };
  }

  // From @mc/entri/entri
  function handleOnEntriStepChange(event) {
    var detail = event.detail;
    var registrar = domainChecklistApi.convertProviderToRegistrar(detail.provider);
    if ([constants.ENTRI_STEP_FINISHED_SUCCESSFULLY, constants.ENTRI_STEP_FINISHED_SUCCESSFULLY_MANUAL].includes(detail.step)) {
      domainChecklistApi.startAuthentication(detail.domain, registrar, 5);
    }
  }

  // From @mc/entri/entri
  function handleOnEntriClose(event) {
    var detail = event.detail;
    if (detail.lastStatus === constants.ENTRI_STEP_INITIAL && detail.authenticateManuallyClicked) {
      var container = event.target || window;
      var pathName = container ? container.location.pathname : undefined;
      if (pathName && !pathName.startsWith(constants.ACCOUNT_DOMAINS_PATH) && container.top && container.top.location) {
        container.top.location.assign(constants.ACCOUNT_DOMAINS_PATH);
      }
    }
    event.target.removeEventListener(constants.ENTRI_EVENT_STEP_CHANGE, handleOnEntriStepChange, false);
    event.target.removeEventListener(constants.ENTRI_EVENT_CLOSE, handleOnEntriClose, false);
  }

  // From @mc/entri/entri
  exports.checkDomain = function (domain) {
    var deferred = new Deferred();
    withEntri().then(function (withEntriResult) {
      var entriObj = withEntriResult[0];
      var config = withEntriResult[1];
      var domainParts = parseDomain(domain);
      entriObj.checkDomain(domainParts.hostname, config).then(function (result) {
        deferred.resolve({
          result: result,
          config: config,
          domain: domainParts.hostname
        });
      }).catch(function () {
        deferred.reject("entri checkDomain failed");
      });
    }, function (reason) {
      deferred.reject(reason);
    });
    return deferred.promise;
  };

  // From @mc/entri/entri
  exports.showEntri = function (properties) {
    var deferred = new Deferred();
    withEntri().then(function (withEntriResult) {
      var entriObj = withEntriResult[0];
      var config = withEntriResult[1];
      var container = withEntriResult[2];
      var promises = [];
      var showDeferred = new Deferred();
      entriObj.showEntri(Object.assign({}, config, {
        whiteLabel: constants.ENTRI_CONFIG_WHITE_LABEL
      }, properties)).then(function () {
        showDeferred.resolve();
      }).catch(function () {
        showDeferred.reject();
      });
      promises.push(showDeferred.promise);
      if (properties && properties.prefilledDomain) {
        promises.push(domainChecklistApi.startChecklist(properties.prefilledDomain));
      }
      all(promises).then(function (result) {
        var domainChecklist = result[1];
        container.addEventListener(constants.ENTRI_EVENT_CLOSE, handleOnEntriClose, false);
        container.addEventListener(constants.ENTRI_EVENT_STEP_CHANGE, handleOnEntriStepChange, false);
        deferred.resolve({
          config: config,
          domainChecklist: domainChecklist
        });
      }, function (reason) {
        deferred.reject(reason);
      });
    }, function (reason) {
      deferred.reject(reason);
    });
    return deferred.promise;
  };

  // From @mc/entri/entri
  exports.showEntriIfSupported = function (domain, properties) {
    var deferred = new Deferred();
    exports.checkDomain(domain).then(function (result) {
      var checkDomainResult = result.result;
      var config = result.config;
      if (checkDomainResult.provider === constants.ENTRI_PROVIDER_UNKNOWN) {
        deferred.reject("unknown provider");
      } else if (checkDomainResult.setupType === constants.ENTRI_SETUP_TYPE_MANUAL) {
        deferred.reject("manual setup type");
      } else {
        exports.showEntri(Object.assign({}, properties, {
          prefilledDomain: result.domain
        }), config).then(function () {
          deferred.resolve();
        }, function (reason) {
          deferred.reject(reason);
        });
      }
    }, function (reason) {
      deferred.reject(reason);
    });
    return deferred.promise;
  };
  return exports;
});