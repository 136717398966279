/**
 * Post Upgrade Dialog
 * @module mojo/widgets/automation/PostUpgradeDialog.js
 *
 * See http://ux.mailchimp.com/product/patterns/dialogs#feature for feature dialog documentation
 *
 * Simple widget to customize the content inside the feature dialog and hook into methods
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/PostUpgradeDialog.html"], function (declare, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    img: {
      path: "https://cdn-images.mailchimp.com/product/brand_assets/illos/art-other-automate.png",
      alt: "Illustration of flower"
    },
    body: {
      title: "Restart Your Automated Email Series",
      copy: "Now that you've upgraded, you can review and resume your automated email series that were previously paused."
    },
    hasDismissLink: true,
    dismissConfig: {
      onDismissPressCallback: null,
      innerText: "Maybe later"
    },
    ctaText: "",
    dismissText: null,
    /**
     * Public method to toggle disabled state of the CTA
     */
    toggleCtaLoadingState: function () {
      if (this._isButtonDisabled()) {
        this.ctaButton.classList.remove("button-loading");
        this.ctaButton.removeAttribute("disabled");
      } else {
        this.ctaButton.classList.add("button-loading");
        this.ctaButton.setAttribute("disabled", "disabled");
      }
    },
    /**
     * Do a quick check on the status of the button.
     *
     * @returns {boolean} Is the button currently "disabled"
     * @private
     */
    _isButtonDisabled: function () {
      return this.ctaButton.classList.contains("button-loading") && this.ctaButton.getAttribute("disabled") === "disabled";
    },
    /**
     * What should we do after presses the main CTA within the dialog?
     */
    onCtaButtonPress: function () {},
    /**
     * What should we when someone dismisses the dialog
     */
    onDismissBtnPress: function () {}
  });
});