/**
 * Small widget used to manipulate the letter spacing of certain font inputs.
 *
 * @module mojo/widgets/input/LetterSpacing
 * @see mojo/widgets/input/Font
 * @see dijit/form/Select
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "./_WithLabelMixin"], function (declare, Select, _WithLabelMixin) {
  return declare([Select, _WithLabelMixin], {
    label: "Letter Spacing",
    range: 5,
    maxHeight: -1,
    "class": "!margin-bottom--lv0",
    /**
     * Create all of the Select options based on widget's range. Place a "normal" between the negative
     * and positive values.
     */
    constructor: function () {
      this.options = [];
      for (var negVal = -this.range; negVal < 0; negVal++) {
        this.options.push({
          label: negVal + "px",
          value: negVal + "px"
        });
      }
      this.options.push({
        label: "Normal",
        value: "normal"
      });
      for (var posVal = 0; posVal <= this.range; posVal++) {
        this.options.push({
          label: posVal + "px",
          value: posVal + "px"
        });
      }
    }
  });
});