define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/app/conversations", "mojo/lib/flags", "mojo/context", "mojo/user", "dojo/text!./templates/inactive-settings-view.html"], function (declare, Renderable, conversations, flags, context, user, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    isNeaLinksFlagEnabled: function () {
      return true;
    },
    isGoogleAdsCCPAEnabled: function () {
      return true;
    },
    constructor: function (props) {
      if (typeof props.state === "undefined") {
        throw new TypeError("state property is required");
      }
      if (typeof props.onSave === "undefined") {
        throw new TypeError("onSave property is required");
      }
      if (typeof props.activate === "undefined") {
        throw new TypeError("activate property is required");
      }
    }
  });
});