define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/DeferredList", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dijit/registry", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (lang, DeferredList, dom, domAttr, on, query, xhr, mUrl, registry, EcsTracking, EcsEvents) {
  var handleCancelBtn = function (dialog) {
    //Close dialog on cancel button
    query(".cancel-button", dialog.domNode).on("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      dialog.hide();
    });
  };
  var getPermittedSegmentType = function (type) {
    var permittedType = "";
    if (type) {
      switch (type) {
        case "advanced":
          permittedType = "saved_advanced";
          break;
        case "sms_campaign_saved_advanced":
          permittedType = "custom_advanced";
          break;
        case "sms_campaign_static":
          permittedType = "pasted";
          break;
        case "saved":
          permittedType = "saved_regular";
          break;
        case "static":
          permittedType = "tag";
          break;
        case "custom":
          permittedType = "custom_regular";
          break;
        default:
          break;
      }
    }
    return permittedType;
  };
  function getRegistrationStatus() {
    return xhr(mUrl.toUrl("/all-campaigns/get-sms-settings"), {
      handleAs: "json"
    });
  }
  function getSegmentType(listId, segmentId) {
    return xhr(mUrl.toUrl("/lists/sms/api/get-segment-by-id?list_id=" + listId + "&segment_id=" + segmentId), {
      method: "GET",
      handleAs: "json"
    });
  }
  function getSmsContent(campaignId) {
    return xhr(mUrl.toUrl("autolyse/dev.php/twirp/mailchimp.smseditor.smseditor.v1.SmsEditorService/Get"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      handleAs: "json",
      data: JSON.stringify({
        source: {
          type: "CONTENT_SOURCE_TYPE_NATIVE_BULK",
          id: campaignId
        }
      })
    });
  }
  function getCampaignDetails(campaignId) {
    return xhr(mUrl.toUrl("autolyse/dev.php/twirp/mailchimp.smscampaign.smscampaigns.v1.SmsCampaignsService/Get"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      handleAs: "json",
      data: JSON.stringify({
        id: campaignId
      })
    });
  }
  return {
    showPauseModal: function (action, title, campaignId) {
      var content = "scheduled the campaign, <strong>" + title + "</strong> to be sent";
      dom.byId("pause-action").innerHTML = content;
      on(dom.byId("pause-link"), "click", this.pauseAndEdit.bind(this, action, campaignId));
      registry.byId("pause-dialog").show();
    },
    pauseAndEdit: function (action, campaignId) {
      var url = mUrl.toUrl("/sms/campaign/unschedule?id=" + campaignId);
      return xhr.post(url, {
        handleAs: "json"
      }).then(lang.hitch(this, function (response) {
        if (response.success) {
          window.location.href = action.href;
        }
      }));
    },
    replicate: function (campaignId) {
      var url = mUrl.toUrl("/sms/campaign/replicate?id=" + campaignId);
      return xhr.post(url, {
        handleAs: "json"
      }).then(function (response) {
        var campaignDetails = getCampaignDetails(campaignId);
        campaignDetails.then(function (details) {
          var campaign = details.campaign;
          var registrationStatus = getRegistrationStatus();
          var smsContent = getSmsContent(campaign.id);
          var segmentSendToType = !!campaign.segments[0] ? getSegmentType(campaign.listId, campaign.segments[0]) : "";
          var segmentDoNotSendToType = !!campaign.excludedSegments[0] ? getSegmentType(campaign.listId, campaign.excludedSegments[0]) : "";
          var scheduleType = null;
          if (!campaign.scheduleTime || !campaign.sendStartTime) {
            scheduleType = "";
          } else {
            scheduleType = campaign.scheduleTime === campaign.sendStartTime ? "send_now" : "scheduled";
          }
          var deferredList = [registrationStatus, smsContent];
          if (segmentSendToType !== "") {
            deferredList.push(segmentSendToType);
          }
          if (segmentDoNotSendToType !== "") {
            deferredList.push(segmentDoNotSendToType);
          }
          var dl = new DeferredList(deferredList);
          dl.then(function (res) {
            var smsRegistrationStatus = res[0][1].smsSettings.registrationStatus;
            EcsTracking.track(EcsEvents.SmsReplicateEvent, {
              screen: "/campaigns",
              action: "engaged",
              sms_registration_status: smsRegistrationStatus,
              replicated_campaign_has_title: !!campaign.title,
              replicated_campaign_has_content: !!res[1][1].content.messageBody,
              replicated_campaign_scheduled_type: scheduleType,
              replicated_campaign_segment_send_to: res.length > 2 ? getPermittedSegmentType(res[2][1].type) : segmentSendToType,
              replicated_campaign_segment_do_not_send_to: res.length > 3 ? getPermittedSegmentType(res[3][1].type) : segmentDoNotSendToType
            });
            EcsTracking.track(EcsEvents.SmsCreateCampaignEvent, {
              screen: "/campaigns",
              create_campaign_origin: "all-campaigns",
              sms_registration_status: smsRegistrationStatus,
              sms_campaign_id: response.campaign_id,
              has_campaign_name: !!campaign.title,
              replicate_from_campaign: true
            });
          });
        });
        if (response.campaign_id) {
          window.location.href = "/sms/bulk?id=" + encodeURIComponent(response.campaign_id);
        }
      });
    }
  };
});