define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/FileManager", "mojo/neapolitan/mobile/utils"], function (declare, base, query, domClass, FileManager, mobileUtils) {
  return declare([base], {
    onReady: function () {
      window.app = {};
      window.app.nativeEvents = mobileUtils.setupNativeListeners();
      window.app.nativeUtils = mobileUtils.setupNativeUtils();
      window.app.nativeStateHandler = mobileUtils.setupNativeStateHandler();

      // add class to body for this tab only
      domClass.add(query("body")[0], "e-content_tab");
      if (!FileManager.isShown()) {
        FileManager.show({
          actions: "copy, preview, edit, rename, delete, create, select",
          isKioskMode: true,
          onSelectContentItem: function (contentItem) {
            mobileUtils.didSelectContent(contentItem.contentId);
          }
        });
      }
    }
  });
});