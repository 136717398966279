define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/user", "mojo/url", "mojo/app/account/clients/revokeAccess", "mojo/SinglePageApplicationUtils", "mojo/lib/logger"], function (declare, base, lang, domAttr, on, registry, user, url, revokeAccess, SinglePageApplicationUtils, logger) {
  return declare([base], {
    inviteEmail: null,
    inviteInviterKey: null,
    loginId: null,
    onReady: function () {
      if (this.$group.removeAccountBtn) {
        on(this.$group.removeAccountBtn, "click", lang.hitch(this, function (e) {
          revokeAccess.showRemoveAgencyDialog(domAttr.get(e.target, "data-agency-id"));
        }));
      }
      if (this.$group.revokeAccessBtn) {
        on(this.$group.revokeAccessBtn, "click", lang.hitch(this, function (e) {
          var tAgencyId = domAttr.get(e.target, "data-agency-id");
          var tLoginId = domAttr.get(e.target, "data-login-id");
          if (tLoginId === null) {
            revokeAccess.showRemoveAgencyDialog(tAgencyId);
          } else {
            revokeAccess.showRemoveAgencyUserDialog(tAgencyId, tLoginId);
          }
        }));
      }
      if (this.$el.toggleSeatsDetails) {
        on(this.$el.toggleSeatsDetails, "click", lang.hitch(this, function (e) {
          this._onToggleSeatsDetails();
        }));
      }
    },
    _onToggleSeatsDetails: function () {
      this.$el.seatsUpgradeImageContainer.classList.toggle("seatsUpgradeImageContainer--hide");
      this.$el.toggleSeatsDetails.classList.toggle("disclosure-open");
      window.ga("send", "event", "seats", "dropdown", "why would I need to add more seats");
    },
    /**
     * Called from _remove-agency-user-dialog
     */
    submitRemoveUser: function () {
      revokeAccess.doRemoveAgencyUser();
    },
    /**
     * Called from _remove-agency-account-dialog
     */
    submitRemoveAccount: function () {
      revokeAccess.doRemoveAgencyAccount();
    },
    showDeleteConfirmation: function (id) {
      this.loginId = id;
      registry.byId("delete-user-dialog").show();
    },
    doDeleteLogin: function () {
      var path = SinglePageApplicationUtils.prefixPath("/account/users/delete");
      window.location = url.toUrl(path, {
        "login_id": this.loginId,
        "__csrf_token": user.__csrfToken
      });
    },
    showRevokeInviteConfirmation: function (email, inviterKey) {
      this.inviteEmail = email;
      this.inviteInviterKey = inviterKey;
      registry.byId("delete-invite-dialog").show();
    },
    doDeleteInvite: function () {
      var path = SinglePageApplicationUtils.prefixPath("/account/users/revoke-invitation");
      window.location = url.toUrl(path, {
        "email": this.inviteEmail,
        "inviter_key": this.inviteInviterKey,
        "__csrf_token": user.__csrfToken
      });
    },
    handleSeatsUpgradeClick: function (planDisplayName) {
      logger.googleAnalytics.trackEvent("account", "cta", "button", {
        dimension53: "upgrade to add more seats",
        dimension54: window.location.pathname,
        dimension56: "upgrade moment",
        dimension96: "account",
        dimension97: planDisplayName,
        dimension98: "add additional seats"
      });
    }
  });
});