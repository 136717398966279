/**
 * Base class for a part. If you use this mixin, it will handle hide/showing and when to initialize itself.
 *
 * @param target is either a String id that references a widget or a reference to an actual widget. The widget needs to have
 * a 'total' attribute that's used to key on when to show/hide
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "dijit/registry", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Widget, lang, array, on, sync, domStyle, Select, registry, topic) {
  return declare([_Widget], {
    constructor: function () {
      this._watches = [];
    },
    postMixInProperties: function () {
      if (!this.target) {
        throw new Error("attribute target was not specified");
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      domStyle.set(this.domNode, "display", "none");
      if (typeof this.target == "string") {
        if (registry.byId(this.target)) {
          this.target = registry.byId(this.target);
          this._display();
        } else {
          var handle = topic.subscribe("mojo/analytics/" + this.target + "/ready", lang.hitch(this, function () {
            this.target = registry.byId(this.target);
            this._display();
            handle.remove();
          }));
        }
      } else {
        this._display();
      }
    },
    /**
     * Handles displaying the part & initializes it once.
     */
    _display: function () {
      this.init();
      this._watches.push(this.target.watch('total', lang.hitch(this, 'toggleDisplay')));
      this.toggleDisplay();
    },
    toggleDisplay: function () {
      var canDisplay = this.canDisplay();
      domStyle.set(this.domNode, 'display', canDisplay ? '' : 'none');
      canDisplay ? this.onShow() : this.onHide();
    },
    /**
     * Implement this handle your post create logic.
     */
    init: function () {
      this.inherited(arguments);
    },
    /**
     * Logic to handle when this part can display. By default, it hides itself when there's no results set.
     * Can override to perform your own custom display logic.
     *
     * @return {boolean}
     */
    canDisplay: function () {
      return this.target.total > 0;
    },
    onShow: function () {},
    onHide: function () {},
    uninitialize: function () {
      array.forEach(this._watches, "item.unwatch();");
      this._watches = [];
    }
  });
});