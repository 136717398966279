/**
 * Configuration of the Connected Sites app selection router
 */
define([], function () {
  // pages with base URLs
  return {
    instruction: {
      match: ":identifier",
      go: function (id) {
        return id;
      }
    },
    selection: {
      match: "",
      go: function () {
        return "";
      }
    }
  };
});