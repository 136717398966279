define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate"], function (declare, query, domConstruct, domClass) {
  return declare(null, {
    skipContainer: false,
    label: "Unknown",
    subLabel: false,
    subLabelClasses: "c-paneLabel--small fwn padding--lv0",
    postCreate: function () {
      this.inherited(arguments);
      if (!this.skipContainer) {
        var containerClass = "style-" + this.label.toLowerCase().replace(" ", "");
        var container = "<div class='style-field selfclear " + containerClass + "'></div>";
        query(this.domNode).wrap(container);
        this.inputContainerNode = this.domNode.parentNode;
        var label = "<label>" + this.label + "</label>";
        this.labelNode = domConstruct.place(label, this.inputContainerNode, "first");
        if (this.subLabel) {
          domClass.add(this.labelNode, this.subLabelClasses);
        }
      }
    }
  });
});