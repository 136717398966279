define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/api/ecommerce-store", "mojo/url", "mojo/utils", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/ItemButtons", "vendor/lodash-amd/template", "dojo/text!./templates/store.html"], function (declare, lang, domClass, registry, ecommerceStoreApi, mUrl, utils, Renderable, ItemButtons, template, tpl) {
  var Store = declare([Renderable], {
    templateString: tpl,
    _originalStoreId: null,
    hasEditButton: true,
    noOptionsConnectedSiteStoreDescription: template('If you need help, we’ve got you covered. <a href="${link}">Connect your site</a>, or check out <a target="_blank" href="https://mailchimp.com/help/connect-your-online-store-to-mailchimp/">How to Use Mailchimp for E-Commerce</a>.'),
    state: {
      storeOptions: [],
      storeId: null,
      storeName: "",
      listName: "",
      hasStores: false,
      changedStore: false
    },
    constructor: function (props) {
      this._originalStoreId = props.model.store.id;
      this.hasSignupForm = props.model.hasSignupForm;
      this.state.storeId = props.model.store.id;
      this.state.storeName = props.model.store.name;
      this.state.listName = props.model.list.name;
    },
    postCreate: function () {
      this.connectYourStoreLink = mUrl.toUrl("/account/connected-sites/wizard/");
      ecommerceStoreApi.list().then(lang.hitch(this, function (data) {
        this.setStoreOptions(data);
      }));
    },
    getData: function () {
      return {
        storeId: this.state.storeId,
        storeName: this.state.storeName
      };
    },
    getOptionMeta: function (option) {
      var meta = option.totalSubscribers.toLocaleString() + " subscribed contacts";
      if (option.hasEcommerceStore) {
        meta += " &middot; Store connected";
      }
      return meta;
    },
    setStoreOptions: function (response) {
      var hasStoreOptions = Boolean(response.length > 0);
      if (hasStoreOptions) {
        this.setState({
          storeOptions: response,
          hasStores: hasStoreOptions
        });
      } else {
        this.noStoreMessage = this.noOptionsConnectedSiteStoreDescription({
          link: this.connectYourStoreLink
        });
        this.render();
      }
    },
    onStoreChange: function (storeId) {
      var changedStore = false;
      if (this.hasSignupForm && this._originalStoreId !== storeId) {
        changedStore = true;
      }
      this.setState({
        storeId: Number(storeId),
        isValid: !!storeId,
        changedStore: changedStore
      });
    },
    getStoreTagOptions: function () {
      var options = this.state.storeOptions.map(lang.hitch(this, function (item) {
        var isSelected = this.state.storeId && item.storeId === this.state.storeId ? true : false;
        return {
          value: item.storeId,
          label: item.name,
          selected: isSelected
        };
      }));
      options.unshift({
        label: "Choose a store"
      });
      return options;
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive && this.state.hasStores) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.itemButtons = new ItemButtons({
          label: "Store",
          getData: this.getData.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          activate: this.activate,
          isActive: this.isActive,
          isValid: this.state.storeId ? true : this.state.isValid
        });
        this.itemButtons.placeAt(this.itemButtonsContainer);
      }
    }
  });

  // Static methods used by the checklist item
  Store.isComplete = function (model) {
    return !!model.storeId;
  };
  Store.isValid = Store.isComplete;
  return Store;
});