/**
 * Widget built to manage the edit state of the Budget Facebook slat.
 *
 * @module mojo/widgets/ads/facebook/EditBudgetSlat
 * @see mojo/widgets/ads/_EditSlatMixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/utils", "./edit-budget/DurationInput",
// Mixins
"mojo/widgets/ads/checklist/_EditSlatMixin",
// Templates
"dojo/text!./edit-budget/templates/EditBudgetSlat.html"], function (declare, lang, debounce, on, user, utils, DurationInput, _EditSlatMixin, tplStr) {
  /**
   * Base widget reflecting the core pieces necessary to operate the budget slat.
   */
  return declare([_EditSlatMixin], {
    content: tplStr,
    slatId: "budget",
    postEndpoint: "/ads/facebook/post-budget",
    currencySymbol: user.currencySymbol,
    isCurrencySymbolBehindAmount: user.isCurrencySymbolBehindAmount,
    /**
     * Attach appropriate handlers once the dom is ready on the widget load.
     */
    postCreate: function () {
      // Call a function anytime someone manually changes the budget input. Use a debounce to give
      // folks the opportunity to finish typing or pasting.
      on(this.budgetInput, "keydown", debounce(lang.hitch(this, "_handleBudgetChange"), 300));
      on(this.budgetInput, "paste", debounce(lang.hitch(this, "_handleBudgetChange"), 300));
      if (this.isCurrencySymbolBehindAmount) {
        this.prefixCurrencySymbol.remove();
        this.budgetInput.classList.remove("c-currencyInput");
      } else {
        this.suffixCurrencySymbol.remove();
      }
    },
    /**
     * Setup the data for this slat.
     *
     * @param {obj} data - an object with keys for ad_id (the unique one, of course) and end_time.
     */
    setData: function (data) {
      // Load some widget-level values we'll need
      this.adId = data.ad_id;
      this.budget = data.total_budget ? data.total_budget : data.min_daily_budget;
      this.minDailyBudget = Number(data.min_daily_budget);
      this.formattedDailyBudget = data.min_daily_budget_formatted;
      this.currencyMessage = data.currency_message;

      // Update the input with the passed-in budget value
      this.budgetInput.value = this.budget;

      // Setup duration input
      var durationData = {
        budget: this.budget,
        minDailyBudget: this.minDailyBudget,
        formattedDailyBudget: this.formattedDailyBudget,
        currencyMessage: this.currencyMessage,
        isRelative: data.is_relative,
        // The duration input is responsible for updating this domNode
        // Pass a reference to it instead of doing anything fancy.
        perDayLabel: this.perDayLabel,
        // Convert string representation of PHP date to JS date object. PHP prefers dates in seconds from
        // Epoch, JS measures by milliseconds so account for the difference.
        startTime: new Date(data.start_time * 1000),
        endTime: new Date(data.end_time * 1000)
      };
      this.duration = new DurationInput(durationData);
      this.duration.placeAt(this.durationContainer);
      this.duration.startup();
    },
    /**
     * If someone manually changes the budget, notify the duration input. If there happens to be errors,
     * clear them.
     */
    _handleBudgetChange: function () {
      utils.removeAllErrors(this.domNode);
      this.budget = this.budgetInput.value;
      this.duration.setBudget(this.budget);
    },
    /**
     * Called from within the template. Format the data that's going to the backend and POST out to the endpoint.
     */
    handleSave: function () {
      if (!this._passesSimpleValidation()) {
        // Call into the same feedback given hook that happens with a real request.
        this.onPostErrorFeedbackGiven();
        return;
      }

      // Basic validation successful. Post!
      this.postForm(lang.mixin({
        id: this.adId,
        budget: this.budget
      }, this.duration.getValues()));
    },
    /**
     * If the backend returned errors, surface them in the interface.
     *
     * @param {obj} response - {status: "error", errors: {general_budget: "This ad has already been published."}}. The response comes back with additional, unused data.
     */
    onPostError: function (response) {
      // Issue with the budget value specifically
      if (response.errors.budget) {
        this.budgetInput.classList.add("invalid");
      }

      // The backend tries to pass an input name that isn't meeting validation. Grab the first error message
      // out of the errors object and pass it to our generic error node.
      // Ideally we'd be able to use utils.showFormErrors(this.domNode, response.errors) but we don't have a
      // 1:1 input to error location. Also styling conflicts with the layout.
      var currentError = response.errors[Object.keys(response.errors)[0]];
      utils.addError(this.errorAnchor, currentError);
    },
    /**
     * Give some immediate feedback instead of requiring an extra HTTP request.
     * Anything more complex will be handled on the backend.
     *
     * @return {boolean} - Continue with submission?
     * @private
     */
    _passesSimpleValidation: function () {
      if (this.budget === "") {
        this.budgetInput.classList.add("invalid");
        utils.addError(this.errorAnchor, "Please enter a budget");
        return false;
      }
      if (this.budget < this.minDailyBudget) {
        // Messaging is already visible from the DurationInput so just highlight the field.
        this.budgetInput.classList.add("invalid");
        return false;
      }
      return true;
    }
  });
});