/**
* An animated badge used to indicate "smart," data science backed features
* There are 3 tiers of smart mark (Tier 1, Tier 2, and Tier 3) that indicate the level of "smarts"
* N.B. Tier 3 is currently only available in the React version of this widget
*
* TODO: Currently, we only use the condensed version of Tier 2 in Dojo so the animations for the full version have not been implemented
**/
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/Tooltip", "dojo/text!./smart-mark.html", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/mouse!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, Tooltip, template, domAttr, domClass, on, mouse) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: template,
    tier: 1,
    condensed: false,
    label: null,
    toggletipText: null,
    postCreate: function () {
      this.inherited(arguments);

      // Show the tier 1 markup
      if (this.tier === 1) {
        domClass.remove(this.tierOne, "hide");
      }
      // Show the tier 2 markup
      if (this.tier === 2) {
        if (this.condensed) {
          domClass.add(this.tierTwo, "condensed");
          domClass.add(this.tierTwoLabel, "hide");
        }
        domClass.remove(this.tierTwo, "hide");

        // Handle mouse events and swap visible animation
        var self = this;
        on(this.tierTwo, mouse.enter, function () {
          domClass.add(self.animateIntro, "hide");
          domClass.add(self.animateIdle, "hide");
          domClass.remove(self.animateHover, "hide");
        });
        on(this.tierTwo, mouse.leave, function () {
          domClass.add(self.animateIntro, "hide");
          domClass.add(self.animateHover, "hide");
          domClass.remove(self.animateIdle, "hide");
        });

        // Create tooltip with passed-in text
        if (this.toggletipText) {
          new Tooltip({
            connectId: this.tierTwo,
            showDelay: 0,
            label: this.toggletipText
          });
        }
      }
    },
    triggerAnimation: function () {
      domAttr.set(this.animateIntro, "src", "https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/components/smartmark/tier2intro_optimized.gif" + "?a=" + Math.random());
    }
  });
});