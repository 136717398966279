/**
 * @class mojo.report.widgets.RecipientsMap
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/RecipientsMap.html"], function (declare, Renderable, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.state = {
        recipientCounts: props.recipientCounts,
        totalRecipientCount: props.recipientCounts.reduce(function (acc, count) {
          return acc + count.recipientCount;
        }, 0)
      };
      this.AmCharts = window.AmCharts;
      this.locationNameMap = this.AmCharts.maps.worldLow.svg.g.path.reduce(function (acc, country) {
        acc[country.id] = country.title;
        return acc;
      }, {});
    },
    render: function () {
      this.inherited(arguments);
      this.renderMap();
    },
    renderMap: function () {
      var map = new this.AmCharts.AmMap();
      map.pathToImages = "/ammap/images/";
      map.panEventsEnabled = true;
      map.colorSteps = 25;
      map.balloon.adjustBorderColor = true;
      map.balloon.color = "#222222";
      map.balloon.borderColor = "#222222";
      map.balloon.borderThickness = 1;
      map.balloon.cornerRadius = 4;
      map.balloon.fillColor = "#ffffff";
      map.balloon.textShadowColor = "#ffffff";
      var dataProvider = {
        mapVar: this.AmCharts.maps.worldLow,
        getAreasFromMap: true
      };
      map.dataProvider = dataProvider;
      map.areasSettings = {
        autoZoom: false,
        selectedColor: "#6dc5dc",
        rollOverOutlineColor: "#333333",
        color: "#aaaaaa",
        colorSolid: "#333333"
      };
      map.zoomControl.buttonFillColor = "#aaaaaa";
      map.zoomControl.buttonRollOverColor = "#666666";
      map.zoomControl.buttonSize = 14;
      var areas = this.state.recipientCounts.map(function (count) {
        var countryName = this.locationNameMap[count.countryCode] || "Unknown";
        var label = count.recipientCount + " postcards were sent to " + countryName;
        return {
          balloonText: label,
          title: label,
          id: count.countryCode,
          value: count.recipientCount,
          color: map.areasSettings.colorSolid
        };
      }, this);
      map.dataProvider = {
        mapVar: this.AmCharts.maps.worldLow,
        getAreasFromMap: true,
        zoomDuration: 0,
        areas: areas
      };
      window.addEventListener("resize", function () {
        // Hide zoom and pan controls for screen size < 1024px
        if (window.matchMedia("(max-width: 1024px)").matches) {
          map.zoomControl.zoomControlEnabled = false;
          map.zoomControl.panControlEnabled = false;
        } else {
          map.zoomControl.zoomControlEnabled = true;
          map.zoomControl.panControlEnabled = true;
        }
      });
      map.write(this.node_map);
    }
  });
});