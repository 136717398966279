/**
 * Payment Method Summary view
 *
 * Simple widget to display the current billing information. This shouldn't talk to the backend
 * or get fancy.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/PaymentMethod.html"], function (declare, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    // Feature of Renderable mixin. Gives an error message if we aren't passing this value.
    requiredProps: ["payment_type"],
    /**
     * Part of the widget cycle. Run before we truly show anything to the user.
     */
    postCreate: function () {
      this._validateProps();
    },
    /**
     * This widget handles most of the payment method states. To be resilient, give feedback if an unexpected
     * state is passed.
     *
     * @private
     */
    _validateProps: function () {
      var validTypes = ["none", "credit_card", "paypal", "adyen", "bank"];
      if (validTypes.indexOf(this.payment_type) === -1) {
        throw new Error("'" + this.payment_type + "'" + " is not an expected payment method type.'");
      }
    }
  });
});