define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, base, dom, domStyle, mUrl, on, p2Utils, xhr, registry) {
  return declare([base], {
    onReady: function () {
      var self = this;
      on(this.$el.deleteAllErrorsButton, "click", function () {
        self.$widget.deleteAllErrorsDialog.show();
      });
      on(this.$el.closeDeleteAllButton, "click", function () {
        self.$widget.deleteAllErrorsDialog.hide();
      });
      on(this.$el.closeViewErrorsDialog, "click", function () {
        self.$widget.jobErrorsDialog.hide();
      });
    },
    /**
     *  opens a modal and adds data returned from the modal into the modals content
     * @param {number} job - the job id
     */
    viewErrors: function (job) {
      var self = this;
      xhr(mUrl.toUrl("/peaches2/admin/job-server/view-errors"), {
        query: {
          job: job
        }
      }).then(function (data) {
        self.$el.viewErrorsDialogContent.innerHTML = data;
        self.$widget.jobErrorsDialog.show();
      });
    },
    /**
     * Attmepts to run the job again.
     * @param {number} job - the id of the job
     * @param {string} queue - the job's position in the queue
     */
    retryJob: function (job, queue) {
      var self = this;
      xhr(mUrl.toUrl("/peaches2/admin/job-server/retry-single"), {
        query: {
          db_name: queue,
          job_id: job
        },
        handleAs: "json"
      }).then(function (data) {
        if (data.success) {
          domStyle.set("error-" + data.job_id, "display", "none");
          self.$widget.jobErrorsDialog.hide();
          p2Utils.utils.setFlash("Retrying job " + data.job_id, "info");
        } else {
          self.$widget.jobErrorsDialog.hide();
          p2Utils.utils.setFlash("Error occurred", "error");
        }
      });
    },
    /**
     * deletes any failed jobs
     * @param {number} job - the id of the job
     * @param {string} queue - the job's position in the queue
     */
    deleteJob: function (job, queue) {
      var self = this;
      xhr(mUrl.toUrl("/peaches2/admin/job-server/delete-single"), {
        query: {
          db_name: queue,
          job_id: job
        },
        handleAs: "json"
      }).then(function (data) {
        if (data.success) {
          domStyle.set("error-" + data.job_id, "display", "none");
          self.$widget.jobErrorsDialog.hide();
          p2Utils.utils.setFlash("Deleting job " + data.job_id, "info");
        } else {
          dom.byId("error-" + data.job_id + "-action-delete").innerHTML = "error occurred";
        }
      });
    }
  });
});