define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/utils", "mojo/context", "mojo/authz", "mojo/utils/date", "mojo/app/placards", "mojo/app/campaigns", "mojo/lib/flags", "mojo/lib/logger", "dijit/registry", "dijit/Dialog", "mojo/utils/I18nTranslation", "./Slat", "dojo/text!./templates/CampaignPauseConfirmDialog.html", "mojo/user"], function (declare, domClass, on, lang, entities, utils, context, Authz, dateUtils, placards, campaigns, flags, logger, registry, Dialog, I18nTranslation, Slat, pauseDialogTemplate, user) {
  return declare([Slat, Authz], {
    channelIcon: "c-channelIcon--ad",
    statusContent: {
      "scheduled": {
        label: "Scheduled",
        badge: "warning",
        description: "Scheduled to post on <strong>{startDate}</strong>"
      },
      "paused": {
        label: "Paused",
        badge: "warning",
        description: "Paused on <strong>{pausedAt}</strong>"
      },
      "pending": {
        label: "In Review",
        badge: "warning",
        description: "In review since <strong>{publishedAt}.</strong> We'll notify you when it's ready."
      },
      "active": {
        label: "Published",
        badge: "info",
        description: "Published on {startDate} <strong>{reach}</strong>"
      },
      "rejected": {
        label: "Rejected",
        badge: "error",
        description: "Resolve any issues to resubmit your ad for approval."
      },
      "completed": {
        label: "Completed",
        badge: "success",
        description: "Completed on {endDate} <strong>{reach}</strong>"
      },
      "canceled": {
        label: "Canceled",
        badge: "error",
        description: "Canceled on <strong>{canceledDate}</strong>",
        byNetworkDescription: "Canceled on <strong>{canceledDate}</strong> by {networkName}"
      },
      "draft": {
        label: "Draft",
        badge: "",
        description: "Edited on <strong>{updatedAt}</strong>"
      },
      "finalized": {
        label: "Finalized",
        badge: "",
        description: "Finalized on <strong>{endDate}</strong>"
      },
      "partialRejected": {
        label: "Some Rejected",
        badge: "warning",
        description: "Partially published on <strong>{startDate}</strong>"
      },
      "partialPending": {
        label: "Some In Review",
        badge: "warning",
        description: "Partially published on <strong>{startDate}</strong>"
      }
    },
    typeContent: {
      "facebook": {
        label: "Ad Campaign",
        networkName: "Facebook"
      },
      "google": {
        label: "Google remarketing ad",
        networkName: "Google"
      }
    },
    statsOrder: ["impressions", "clicks", "revenue"],
    statsContent: {
      "clicks": {
        label: "Clicks"
      },
      "reach": {
        label: "Reach"
      },
      "impressions": {
        label: "Impressions"
      },
      "revenue": {
        label: "Revenue"
      }
    },
    actions: {
      "edit": {
        label: "Edit"
      },
      "view": {
        label: "View ad"
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "replicate": {
        label: "Replicate"
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
        }
      },
      "pause": {
        label: "Pause",
        onClick: function () {
          var isGoogleAd = this.model.type === "google";
          var canUseGoogleRemarketing = this.userHasFeature("ad_google_remarketing");
          if (isGoogleAd && !canUseGoogleRemarketing) {
            //Create confirm dialog
            var dialog = new Dialog({
              "title": "Pause your campaign?",
              "content": pauseDialogTemplate,
              onHide: function () {
                dialog.destroyRecursive();
              }
            });
            var form = dialog.domNode.querySelector("form");
            form.addEventListener("submit", function (e) {
              e.preventDefault();
              placards.pauseAd(this.model.uniqueId, this.model.type).then(function () {
                registry.byId("campaigns-list").render();
                dialog.onHide();
              });
            }.bind(this));
            var cancelButton = dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']");
            cancelButton.addEventListener("click", function () {
              dialog.onHide();
            });
            dialog.show();
          } else {
            placards.pauseAd(this.model.uniqueId, this.model.type).then(function () {
              registry.byId("campaigns-list").render();
            });
          }
        }
      },
      "unpause": {
        label: "Unpause",
        onClick: function () {
          placards.unpauseAd(this.model.uniqueId, this.model.type).then(function () {
            registry.byId("campaigns-list").render();
          }, function (errorResponse) {
            if (!errorResponse.errors || !errorResponse.errors.publish) {
              return;
            }
            utils.toast(errorResponse.errors.publish, "error");
          });
        }
      },
      "cancel": {
        label: "End",
        onClick: function () {
          placards.confirmCancel(this.model.uniqueId, this.model.type).then(function () {
            registry.byId("campaigns-list").render();
          });
        }
      },
      "download": {
        label: "Download"
      }
    },
    statusContentTranslated: {},
    typeContentTranslated: {},
    statsContentTranslated: {},
    actionsTranslated: {},
    /**
     * override
     */
    _buildTypeName: function () {
      this.node_typeName.innerHTML = this.model.typeLabel;
    },
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "scheduled": {
          label: translationObject.campaigns_scheduled,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_ad_scheduled_description", ["{startDate}"])
        },
        "paused": {
          label: translationObject.campaigns_paused,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_ad_paused_description", ["{pausedAt}"])
        },
        "pending": {
          label: translationObject.campaigns_in_review,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_in_review_description", ["{publishedAt}"])
        },
        "active": {
          label: translationObject.campaigns_published,
          badge: "info",
          description: I18nTranslation.translate("campaigns_ad_published_description", ["{startDate}", "{reach}"])
        },
        "rejected": {
          label: translationObject.campaigns_rejected,
          badge: "error",
          description: translationObject.campaigns_ad_rejected_description
        },
        "completed": {
          label: translationObject.campaigns_completed,
          badge: "success",
          description: I18nTranslation.translate("campaigns_ad_completed_descrption", ["{endDate}", "{reach}"])
        },
        "canceled": {
          label: translationObject.campaigns_canceled,
          badge: "error",
          description: I18nTranslation.translate("campaigns_ad_canceled_description", ["{canceledDate}"]),
          byNetworkDescription: I18nTranslation.translate("campaigns_ad_canceled_network_description", ["{canceledDate}", "{networkName}"])
        },
        "draft": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_ad_draft_description", ["{updatedAt}"])
        },
        "finalized": {
          label: translationObject.campaigns_finalized,
          badge: "",
          description: I18nTranslation.translate("campaigns_ad_finalized_description", ["{endDate}"])
        },
        "partialRejected": {
          label: translationObject.campaigns_some_rejected,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_ad_some_rejected_description", ["{startDate}"])
        },
        "partialPending": {
          label: translationObject.campaigns_some_in_review,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_ad_some_rejected_description", ["{startDate}"])
        }
      };
      this.typeContentTranslated = {
        "facebook": {
          label: translationObject.campaigns_ad_campaign,
          networkName: translationObject.campaigns_facebook
        },
        "google": {
          label: translationObject.campaigns_google_remarketing_ad,
          networkName: translationObject.campaigns_google
        }
      };
      this.statsContentTranslated = {
        "clicks": {
          label: translationObject.campaigns_clicks
        },
        "reach": {
          label: translationObject.campaigns_reach
        },
        "impressions": {
          label: translationObject.campaigns_impressions
        },
        "revenue": {
          label: translationObject.campaigns_revenue
        }
      };
      this.actionsTranslated = {
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        "view": {
          label: translationObject.campaigns_actions_view_ad
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Ads");
            }
          }
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
          }
        },
        "pause": {
          label: translationObject.campaigns_actions_pause,
          onClick: function () {
            var isGoogleAd = this.model.type === "google";
            var canUseGoogleRemarketing = this.userHasFeature("ad_google_remarketing");
            if (isGoogleAd && !canUseGoogleRemarketing) {
              //Create confirm dialog
              var dialog = new Dialog({
                "title": translationObject.campaigns_actions_pause_ad_title,
                "content": pauseDialogTemplate,
                onHide: function () {
                  dialog.destroyRecursive();
                }
              });
              var form = dialog.domNode.querySelector("form");
              form.addEventListener("submit", function (e) {
                e.preventDefault();
                placards.pauseAd(this.model.uniqueId, this.model.type).then(function () {
                  registry.byId("campaigns-list").render();
                  dialog.onHide();
                });
              }.bind(this));
              var cancelButton = dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']");
              cancelButton.addEventListener("click", function () {
                dialog.onHide();
              });
              dialog.show();
            } else {
              placards.pauseAd(this.model.uniqueId, this.model.type).then(function () {
                registry.byId("campaigns-list").render();
              });
            }
          }
        },
        "unpause": {
          label: translationObject.campaigns_actions_unpause,
          onClick: function () {
            placards.unpauseAd(this.model.uniqueId, this.model.type).then(function () {
              registry.byId("campaigns-list").render();
            }, function (errorResponse) {
              if (!errorResponse.errors || !errorResponse.errors.publish) {
                return;
              }
              utils.toast(errorResponse.errors.publish, "error");
            });
          }
        },
        "cancel": {
          label: translationObject.campaigns_actions_end,
          onClick: function () {
            placards.confirmCancel(this.model.uniqueId, this.model.type).then(function () {
              registry.byId("campaigns-list").render();
            });
          }
        },
        "download": {
          label: translationObject.campaigns_actions_download
        }
      };
    },
    /**
     * override
     */
    buildAdditionalMetaData: function () {
      if (this.statusContent[this.model.status]) {
        var template = this.statusContent[this.model.status].description;
        if (this.model.wasCanceledByNetwork) {
          template = this.statusContent[this.model.status].byNetworkDescription;
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "pausedAt":
            case "startDate":
            case "endDate":
            case "publishedAt":
            case "updatedAt":
            case "canceledDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            case "networkName":
              return this.typeContent[this.model.type].networkName;
            case "reach":
              return this.model.stats && this.model.stats.reach ? "&middot; " + utils.getThousandsString(this.model.stats.reach && this.model.stats.reach.value || 0) + " reached" : "";
            default:
              return this.model[key] || "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.statusContentTranslated[this.model.status]) {
        var template = this.statusContentTranslated[this.model.status].description;
        if (this.model.wasCanceledByNetwork) {
          template = this.statusContentTranslated[this.model.status].byNetworkDescription;
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "pausedAt":
            case "startDate":
            case "endDate":
            case "publishedAt":
            case "updatedAt":
            case "canceledDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            case "networkName":
              return this.typeContentTranslated[this.model.type].networkName;
            case "reach":
              return this.model.stats && this.model.stats.reach ? "&middot; " + utils.getThousandsString(this.model.stats.reach && this.model.stats.reach.value || 0) + " reached" : "";
            default:
              return this.model[key] || "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildListName: function () {
      this.node_listName.appendChild(utils.compileTemplate(this.model.audience ? this.template_listName : "", {
        listName: this.model.audience,
        segmentName: this.model.hasSegment && this.model.segmentDisplay ? this.model.segmentDisplay : "",
        snapshotDate: "",
        snapshotDateClass: "hide"
      }));
    }
  });
});