/**
 * Top level widget for the platform selection UI
 * @module /mojo/widgets/account/connected-sites/StoreSelectionManager
 * @see /mojo/widgets/FilteredSearch
 * @see /mojo/widgets/account/connected-sites/PlatformsList
 * @see /mojo/widgets/account/connected-sites/FilterList
 *
 * @typedef Platform
 * @type {object}
 * @property {string} name - name to display
 * @property {string} identifier - unique identifier
 * @property {string} category - category
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/StoreSelectionManager.html", "mojo/user", "mojo/lib/flags", "mojo/widgets/FilteredSearch", "mojo/widgets/account/connected-sites/PlatformsList", "mojo/widgets/account/connected-sites/FilterList", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, topic, domConstruct, domClass, on, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, managerTpl, user, flags, FilteredSearch, PlatformsList, FilterList, xhr, mUrl) {
  var NdAlert = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: "<div class='flex align-self--flex-start border-default border-radius--lv2 padding--lv2'>\
            <span class='freddicon warn-circle warn-circle-color padding-right--lv2'></span>\
            <div class='flex flex-col'>\
                <p class='margin-bottom--lv0'><strong>ShopSync is our recommended pick</strong></p>\
                <p class='margin-bottom--lv0'>The ShopSync integration is the best way to tie your Mailchimp and Shopify accounts together. <a href='https://mailchimp.com/help/switch-shopify-shopsync/'>Learn how to set it up</a></p>\
            </div>\
        </div>"
  });
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: managerTpl,
    // data-dojo-attach-point(s)
    platformsListNode: null,
    filteredSearchNode: null,
    hasConnectedSite: false,
    selectedCategory: null,
    _setSelectedCategoryAttr: function (newSelectedCategory) {
      this._updatePlatformsList(this.searchString, newSelectedCategory, this.platforms);
      this._set("selectedCategory", newSelectedCategory);
    },
    searchString: null,
    _setSearchStringAttr: function (newSearchString) {
      this._updatePlatformsList(newSearchString, this.selectedCategory, this.platforms);
      this._set("searchString", newSearchString);
    },
    /** @type {Platform[]} constructor prop */
    platforms: [],
    // from constructor
    filters: [],
    authorizedAppsSection: null,
    // PlatformsList widget - passed filtered platforms array
    platformsListWidget: null,
    authorizedAppsNode: null,
    /**
     * Return list of platforms matching the category string
     * @param {string} categoryStr category to match
     * @param {object[]} inputPlatforms array of platforms to filter
     * @returns {object[]} filtered list of platforms
     */
    _filterByCategory: function (categoryStr, inputPlatforms) {
      if (!categoryStr || categoryStr === "" || categoryStr === "all") {
        return inputPlatforms;
      }
      var filteredPlatforms = inputPlatforms.filter(function (platform) {
        return platform && platform.category && platform.category.toLowerCase() === categoryStr.toLowerCase();
      });
      return filteredPlatforms;
    },
    /**
     * Filter this.platforms based on a search string
     * - Tests for a substring match in platform.name or platform.indentifier
     * @param {string} filterString substring to test for
     * @param {string} inputPlatforms list of platforms to filter through
     * @returns {Platform[]} platforms that match the search string
     */
    _filterBySearchString: function (filterString, inputPlatforms) {
      // test for case insensitive substring match
      var re = new RegExp("^.*" + filterString + ".*$", "i");

      // return all platforms on empty string
      if (!filterString || filterString === "") {
        return inputPlatforms;
      }

      // test if search string matches display name or indentifier
      var filteredPlatforms = inputPlatforms.filter(function (platform) {
        return re.test(platform.displayName) || re.test(platform.identifier);
      });
      return filteredPlatforms;
    },
    /**
     * Apply the category and search filters and pass result to platformsListWidget
     * @param {string} search search string to match
     * @param {string} category catergory string to match
     * @param {object[]} inputPlatforms array of platforms to begin filtering
     */
    _updatePlatformsList: function (search, category, inputPlatforms) {
      var categoryFiltered = this._filterByCategory(category, inputPlatforms);
      var searchFiltered = this._filterBySearchString(search, categoryFiltered);
      this.platformsListWidget.set("platforms", searchFiltered);
    },
    /**
     * Handler for typed search event
     * @param {string} searchValue - value passed by FilteredSearch widget
     */
    _handleSearchEvent: function (searchValue) {
      this.set("searchString", searchValue);
      if (searchValue !== "") {
        window.ga('send', 'event', 'connected sites', 'search', searchValue, {
          'dimension57': this.platformsListWidget.platforms.length
        });
      }
    },
    /**
     * Handler for filter list click event
     * @param {string} category - value passed by FilterdList widget
     */
    _handleFilterEvent: function (category) {
      this.set("selectedCategory", category);
      window.ga('send', 'event', 'connected sites', 'filter', category, {
        'dimension57': this.platformsListWidget.platforms.length
      });
    },
    /**
     * Handle clicks for 'view more integrations'
     */
    _onViewMoreClick: function (event) {
      var url = event.target.href;
      if (url) {
        url = url.split(/[?#]/)[0];
      }
      window.ga("send", "event", "connected sites", "view more integrations", "", {
        'dimension53': event.target.innerText,
        'dimension54': url,
        'dimension56': 'navigate to integrations directory'
      });
      xhr.get(mUrl.toUrl("account/connected-sites/optimizely-metric?viewMore=1"), {
        "handleAs": "json"
      });
    },
    postCreate: function () {
      // initialize and place search bar
      new FilteredSearch({
        placeholder: "Type to search",
        searchTopic: "platformName",
        wait: 750,
        onClear: function () {
          this._handleSearchEvent("");
        }.bind(this)
      }).placeAt(this.filteredSearchNode);
      new FilterList({
        filters: this.filters
      }).placeAt(this.filterListNode);

      // subscribe to search event
      this.searchHandler = topic.subscribe("search/platformName", this._handleSearchEvent.bind(this));

      // subscribe to filter click event
      this.filterCategoryHandler = topic.subscribe("filter/store-selection", this._handleFilterEvent.bind(this));

      // store widget to update after search event
      this.platformsListWidget = new PlatformsList({
        platforms: this.platforms,
        filters: this.filters
      });
      this.platformsListWidget.placeAt(this.platformsListNode);
      this.authorizedAppsNode.appendChild(this.authorizedAppsSection);
    },
    _ndMessaging: function () {
      if (!user.isND) {
        domClass.add(this.feedbackContainerNode, "padding-top--lv2");
        return new NdAlert().placeAt(this.feedbackContainerNode);
      }
    },
    destroy: function () {
      // delete topic subsciptions
      this.searchHandler.remove();
      this.filterCategoryHandler.remove();
    }
  });
});