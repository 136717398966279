define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo/string", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dijit/focus", "dijit/registry", "mojo/url", "mojo/utils/string", "mojo/utils"], function (declare, lang, base, string, domClass, dom, on, request, focusUtil, registry, mUrl, stringUtils, utils) {
  var MAX_LENGTH = 100;
  return declare([base], {
    listId: null,
    formSubmitted: false,
    onReady: function () {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "list_detail",
          user_id: this.userId,
          login_id: this.loginId,
          list_id: this.listId
        });
      }
    },
    /**
     * Shows the create tag dialog
     */
    createTagDialog: function () {
      this.$el.newTagInput.value = "";
      // Make sure to reset the remaining character count when modal is closed/opened
      this.checkRemainingCharacters(this.$el.newTagInput, false);
      registry.byId("create-tag-dialog").show();
      setTimeout(lang.hitch(this, function () {
        focusUtil.focus(this.$el.newTagInput);
      }), 100);
    },
    /**
     * Shows the rename tag dialog
     * @param {int} tag_id the id of the tag to be renamed
     * @param {string} current_name the current name of the tag to be renamed
     */
    renameTagDialog: function (tag_id, current_name) {
      this.$el.tagIdInput.value = tag_id;
      this.$el.tagNameInput.value = current_name;
      // Set remaining characters based on existing tag name
      this.checkRemainingCharacters(this.$el.tagNameInput, true);
      registry.byId("rename-tag-dialog").show();
      setTimeout(lang.hitch(this, function () {
        focusUtil.focus(this.$el.tagNameInput);
      }), 100);
    },
    /**
     * Handles the provided input
     * @param {domElement} input the input to be handled
     * @param {boolean} isRename Are we renaming an existing tag?
     */
    handleInput: function (input, isRename) {
      input.value = stringUtils.stripEmojis(input.value);
      this.checkRemainingCharacters(input, isRename);
    },
    /**
     * Toggle visibility of character count on removing / receiving focus
     * @param {boolean} isFocused Does the input have focus?
     * @param {boolean} isRename Are we renaming an existing tag?
     */
    toggleFocus: function (isFocused, isRename) {
      var messageContainer = this.$el.newTagCharsRemainingContainer;
      if (isRename) {
        messageContainer = this.$el.renameTagCharsRemainingContainer;
      }
      if (isFocused) {
        domClass.remove(messageContainer, "hide");
      } else {
        domClass.add(messageContainer, "hide");
      }
    },
    /**
     * Check the number of remaining characters for a tag name,
     * showing visual feedback indicating remaining characters.
     * @param {domElement} input The input to check
     * @param {boolean} isRename Are we renaming an existing tag?
     */
    checkRemainingCharacters: function (input, isRename) {
      var characterCount = this.$el.newTagCharsRemainingCount;
      if (isRename) {
        characterCount = this.$el.renameTagCharsRemainingCount;
      }
      utils.checkInputLength(input, characterCount, MAX_LENGTH, true);
    },
    /**
     * Shows the delete segment modal
     * Adds multiple tag ids to be deleted to hidden input
     */
    openBulkDeleteModal: function () {
      var tag_ids = registry.byId("segments-list").getSelection().join(",");
      this.$el.deleteIdsInput.value = tag_ids;
      registry.byId("confirm-delete-segment").show();
    },
    /**
     * Opens the delete modal for a single deletion
     * @param {int} tag_id the id of the tag to be deleted
     */
    openSingleDeleteModal: function (tag_id) {
      this.$el.deleteIdsInput.value = tag_id;
      registry.byId("confirm-delete-segment").show();
    },
    /**
     * Submit form and disable button to prevent issues with double clicking
     * @param {object} event from form
     */
    submitAndDisable: function (event) {
      if (!this.formSubmitted) {
        this.formSubmitted = true;
      } else {
        event.preventDefault();
      }
    }
  });
});