define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Templated", "dijit/_Widget", "dijit/_WidgetsInTemplateMixin", "mojo/utils", "mojo/widgets/ConfirmInput", "dojo/text!./templates/confirmRemove.html"], function (declare, domConstruct, _Templated, _Widget, _WidgetsInTemplateMixin, utils, ConfirmInput, template) {
  return declare([_Widget, _Templated, _WidgetsInTemplateMixin], {
    templateString: template,
    confirmMessageElement: "",
    confirmInput: null,
    removeButtonText: "Remove",
    confirmRemoveAction: null,
    cancelRemoveAction: null,
    postCreate: function () {
      // throw error if no confirmMessageElement
      if (!this.params.confirmMessageElement) {
        throw new Error("Confirm message to display.");
      }
      domConstruct.place(this.params.confirmMessageElement, this.confirmMessage);
      if (this.params.confirmText) {
        this._addConfirmInput(this.params.confirmText);
      } else {
        domConstruct.remove(this.confirmTextInput);
      }
    },
    _addConfirmInput: function (confirmText) {
      this.confirmInput = new ConfirmInput({
        confirm: confirmText
      });
      this.confirmInput.placeAt(this.confirmTextInput);
    },
    onCancelClick: function () {
      this.cancelRemoveAction();
    },
    onConfirmRemoveClick: function (event) {
      if (!this.confirmInput) {
        utils.toggleButtonLoadingState(event.target);
        this.confirmRemoveAction();
      } else if (this.confirmInput && this.confirmInput.validate()) {
        utils.toggleButtonLoadingState(event.target);
        this.confirmRemoveAction();
      }
    }
  });
});