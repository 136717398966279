define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-data", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-prop!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dojox/validate", "dojox/validate/check", "dojox/validate/web", "dojox/validate/us", "dojox/validate/ca", "mojo/context", "mojo/lib/flags"], function (declare, base, dom, domClass, html, on, query, data, domAttr, domProp, utils, domConstruct, htmlEntities, validate, check, web, us, ca, context, flags) {
  return declare([base], {
    swagObjFactory: function (swagDomNode) {
      return {
        node: swagDomNode,
        name: domAttr.get(swagDomNode, "data-swag-name"),
        image: query("img", swagDomNode),
        picker: query("input[type=radio]", swagDomNode),
        pickerText: query(".swag_item-picker_text", swagDomNode),
        sizeSelector: query("select", swagDomNode),
        dijitRadio: query(".dijit.dijitRadio", swagDomNode),
        isSelected: function () {
          domClass.add(this.node, "is-selected");
          domClass.add(this.dijitRadio[0], "dijitRadioChecked dijitChecked");
          html.set(this.pickerText[0], "Picked");
        },
        deSelected: function () {
          domClass.remove(this.node, "is-selected");
          domClass.remove(this.dijitRadio[0], "dijitRadioChecked dijitChecked");
          html.set(this.pickerText[0], "Pick This");
        }
      };
    },
    selectSwag: function (swagObj) {
      var self = this;
      if (typeof self.pickedSwag !== 'undefined') {
        self.pickedSwag.deSelected();
      }
      self.pickedSwag = swagObj;
      swagObj.isSelected();

      //Add item title to form heading, button and hidden field
      html.set(self.$el.swagFormTitleText, swagObj.name);
      html.set(self.$el.swagFormButtonText, swagObj.name);
      domAttr.set(self.$el.itemTitle, "value", swagObj.name);

      //Add item title, size and image to confirm window
      html.set(self.$el.swagTitle, swagObj.name);
      domAttr.set(self.$el.swagImage, "src", domAttr.get(swagObj.image[0], "src"));
      query(".swag_item-error").orphan();
    },
    isSkuValid: function () {
      if (dom.byId("sku").value === "") {
        query(".swag_item-error").orphan();
        domConstruct.place('<div class="feedback-block error size1of1 section swag_item-error"> <div class="lastUnit size1of1"> <div class="media"> <div class="media-image"> <span class="freddicon cross-circle-large"></span> </div> <div class="media-body padding--lv2"> <strong>Oops! Did you forget to select your swag item and/or size? </strong> </div> </div> </div> </div>', dom.byId("swag_card-grid"), "last");
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        return false;
      } else {
        return true;
      }
    },
    //A two character state or province code is required for any US/Canada address.
    //This function validates the value of the state field.
    isValidState: function (value, obj) {
      var selectedCountry = obj.$el.formCountry.textContent;
      var result = true;

      //Validate US States
      if (selectedCountry === "USA") {
        result = validate.us.isState(value);
      }

      //Validate Canadian Provinces
      if (selectedCountry === "Canada") {
        result = validate.ca.isProvince(value);
      }
      return {
        isValid: result,
        message: "Please enter a valid two-character State or Province."
      };
    },
    isValidEmail: function (value) {
      var result = validate.isEmailAddress(value);
      return {
        isValid: result,
        message: "Please enter a valid email."
      };
    },
    isValidPhone: function (value) {
      // this regex used in welcome.js#validateAddressPhoneNumber()
      // only approximates whether the phone number is valid, not super exact
      var result = /^$|^(?=.*?[1-9])[0-9() +-]+$/.test(value);
      return {
        isValid: result,
        message: "Please enter a valid phone number."
      };
    },
    // SUTO-428 Require phone number for non-US orders
    requirePhoneOutsideUs: function (formCountry, verificationArray1, verificationArray2) {
      // Don't duplicate domclass or phone entry if field is already marked as required
      if (!domClass.contains(dom.byId("phone-number-label"), "required")) {
        domClass.add(dom.byId("phone-number-label"), "required");
        verificationArray1.push("phone");
        verificationArray2.push("phone");
      }
    },
    removePhoneRequirement: function (formCountry, verificationArray1, verificationArray2) {
      domClass.remove(dom.byId("phone-number-label"), "required");
      domClass.remove(dom.byId("phone"), "invalid");
      if (document.getElementById("phone").nextSibling.textContent === "Please enter a value.") {
        dojo.destroy(document.getElementById("phone").nextSibling);
      }
      var index1 = verificationArray1.indexOf("phone");
      if (index1 > -1) {
        verificationArray1.splice(index1, 1);
      }
      var index2 = verificationArray2.indexOf("phone");
      if (index2 > -1) {
        verificationArray2.splice(index2, 1);
      }
    },
    checkFormCountry: function (formCountry, verificationArray1, verificationArray2) {
      if (formCountry.textContent !== "USA") {
        this.requirePhoneOutsideUs(formCountry, verificationArray1, verificationArray2);
      } else {
        // remove phone requirement if country is changed to USA
        if (formCountry.textContent === "USA" && domClass.contains(dom.byId("phone-number-label"), "required")) {
          this.removePhoneRequirement(formCountry, verificationArray1, verificationArray2);
        }
      }
    },
    checkForm: function (form, e) {
      var check;
      var self = this;

      //Clear previous form validation results upon submission
      query("input[type=text]").removeClass("invalid");
      query(".swag-form-error").orphan();

      //Check state/province based on the shipping country
      if (self.$el.formCountry.textContent === "USA" || self.$el.formCountry.textContent === "Canada") {
        check = validate.check(form, self.profile1);
      } else {
        check = validate.check(form, self.profile2);
      }
      if (check.isSuccessful() && self.isSkuValid()) {
        //Copy the text from the selected country
        html.set(self.$el.confirmCountry, self.$el.formCountry.textContent);

        //Copy values from form into confirm modal
        query("input", self.$el.swagForm).forEach(function (node) {
          var fieldValue = domAttr.get(node, "value");
          var fieldId = domAttr.get(node, "data-mc-el");
          if (fieldValue && fieldId) {
            var confirmId = fieldId.replace("form", "confirm");
            html.set(self.$el[confirmId], htmlEntities.encode(fieldValue));
          }
        });
        confirmOrder.show();
      } else {
        //Add errors to required fields
        var missingFields = check.getMissing();
        if (missingFields.length) {
          missingFields.forEach(function (currentField) {
            if (!domClass.contains(dom.byId(currentField), "invalid")) {
              domClass.add(dom.byId(currentField), "invalid");
              domConstruct.place('<span class="invalid-error swag-form-error">Please enter a value.</span>', dom.byId(currentField), "after");
            }
          });
        }
      }
      //Add errors to invalid fields
      var invalidFields = check.getInvalid();
      if (invalidFields.length) {
        invalidFields.forEach(function (currentField) {
          //Only add invalid error if error isn't already on field
          if (!domClass.contains(dom.byId(currentField.field), "invalid")) {
            domClass.add(dom.byId(currentField.field), "invalid");
            domConstruct.place('<span class="invalid-error swag-form-error">' + currentField.message + '</span>', dom.byId(currentField.field), "after");
          }
        });
      }
    },
    onReady: function () {
      var self = this;
      var formCountry = this.$el.formCountry;
      self.swag = [];
      self.submitCnt = 0;
      query('[data-swag]').forEach(function (swagNode) {
        self.swag.push(self.swagObjFactory(swagNode));
      });
      self.swag.forEach(function (swagObj) {
        on(swagObj.picker, "change", function (e) {
          domAttr.set(dom.byId("sku"), "value", swagObj.sizeSelector[0].value);
          self.selectSwag(swagObj);
        });
        on(swagObj.sizeSelector, "change", function (e) {
          domAttr.set(dom.byId("sku"), "value", swagObj.sizeSelector[0].value);
          self.selectSwag(swagObj);
        });
      });

      //Create form validation profiles based on the shipping destination country
      self.profile1 = {
        required: ["fname", "lname", "email", "addr1", "city", "state", "zip"],
        constraints: {
          "email": self.isValidEmail,
          "phone": self.isValidPhone,
          "state": [self.isValidState, self]
        }
      };
      self.profile2 = {
        required: ["fname", "lname", "email", "addr1", "city", "zip"],
        constraints: {
          "email": self.isValidEmail,
          "phone": self.isValidPhone
        }
      };
      self.checkFormCountry(formCountry, self.profile1.required, self.profile2.required);
      on(formCountry, "blur", function () {
        self.checkFormCountry(formCountry, self.profile1.required, self.profile2.required);
      });

      //Validate form when send my swag button clicked on page
      on(self.$el.sendSwag, "click", function (e) {
        self.checkForm(self.$el.swagForm, e);
      });

      //submit order from confirm window
      on(self.$el.confirmSendOrder, "click", function (e) {
        if (self.submitCnt === 0) {
          self.submitCnt++;
          self.$el.swagForm.requestSubmit();
        }
      });
    }
  });
});