define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/date",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!../templates/activity-feed/activity-item-generic-signup.html"], function (declare, dateUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    campaignSendTime: null,
    message: null,
    postMixInProperties: function () {
      var sentDate = new Date(this.data.formatted_user_time);

      // be explicit about needing the timezone in the time stamp string
      // add timezone to activity feed time stamp
      this.campaignSendTime = dateUtils.getTimeString(sentDate, true);
      if (this.data.signup_category === "generic_add") {
        this.message = "via another audience";
      } else if (this.data.signup_category === "manual_add") {
        this.message = "Manually";
      } else if (this.data.signup_category === "hosted_form") {
        this.message = "via Hosted Signup Form";
      } else if (this.data.signup_category === "social") {
        this.message = "via Social Channel";
      } else if (this.data.signup_category === "popup") {
        this.message = "via Popup Form";
      } else if (this.data.signup_category === "embedded") {
        this.message = "via Embedded Form";
      } else if (this.data.signup_category === "api") {
        this.message = "via API";
      } else if (this.data.signup_category === "quickbooks") {
        this.message = "via QuickBooks";
      } else if (this.data.signup_category === "website_popup") {
        this.message = "via Website Popup Form";
      }
    }
  });
});