define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/context", "mojo/lib/flags", "mojo/utils/date"], function (declare, lang, xhr, mUrl, context, flags, dateUtils) {
  var Outreach = declare(null, {
    /**
     * Takes a bag of properties, and instantiates an Outreach model
     *
     * @param {Object} properties - Properties from endpoint
     */
    constructor: function (properties) {
      // dateTransform is used to get a JS date object from a
      // date string (date_str) and the timezone offset (tz_float) of that date
      var lastUpdatedDate;
      // dates used for calculation comparison
      this.createdAt = properties.created_at ? dateUtils.dateTransform(properties.created_at) : null;
      this.updatedAt = properties.updated_at ? dateUtils.dateTransform(properties.updated_at) : null;
      lastUpdatedDate = this.updatedAt || this.createdAt;
      this.lastUpdatedDate = lastUpdatedDate ? lastUpdatedDate : null;

      // dates used for display
      this.postedAt = properties.posted_at ? dateUtils.dateTransform(properties.posted_at) : null;
      this.sendDate = properties.send_time ? dateUtils.dateTransform(properties.send_time) : null;
      this.nextOrderDate = properties.next_order_date ? dateUtils.dateTransform(properties.next_order_date) : null;
      this.publishedAt = properties.published_at ? dateUtils.dateTransform(properties.published_at) : null;
      this.scheduledSendTime = properties.scheduled_send_time ? dateUtils.dateTransform(properties.scheduled_send_time) : null;
      this.pausedAt = properties.paused_at ? dateUtils.dateTransform(properties.paused_at) : null;
      this.startDate = properties.start_time ? dateUtils.dateTransform(properties.start_time) : null;
      this.endDate = properties.end_time ? dateUtils.dateTransform(properties.end_time) : null;
      this.canceledDate = properties.canceled_at ? dateUtils.dateTransform(properties.canceled_at) : null;
      this.lastDeliveryDate = properties.last_delivery_time ? dateUtils.dateTransform(properties.last_delivery_time) : null;
      this.rssLastSendDate = properties.rss_last_send_time ? dateUtils.dateTransform(properties.rss_last_send_time) : null;
      this.lastPausedDate = properties.last_paused_at ? dateUtils.dateTransform(properties.last_paused_at) : this.lastUpdatedDate;
      this.archivedDate = properties.archived_at ? dateUtils.dateTransform(properties.archived_at) : null;
      this.uniqueId = properties.unique_id ? properties.unique_id : properties.id;
      this.campaignId = properties.campaign_id;
      this.pageId = properties.page_id;
      this.automationId = properties.automation_id;
      this.autoresponderId = properties.autoresponder_id;
      this.postcardId = properties.postcard_id;
      this.socialId = properties.social_post_id;
      this.surveyId = properties.survey_id;
      this.adId = properties.ad_id;
      this.title = properties.title;
      this.titleLink = properties.title_link;
      this.status = properties.status;
      this.statusReason = properties.status_reason;
      this.bucket = properties.bucket;
      this.type = properties.type;
      this.typeLabel = properties.type_label;
      this.listName = properties.list ? properties.list.name : null;
      this.lastUpdatedBy = properties.last_updated_by;
      this.wasCanceledByNetwork = properties.was_canceled_by_facebook;
      this.rssScheduleString = properties.rss_schedule_string;
      this.rssScheduleHour = properties.rss_schedule_hour;
      this.sendPercentage = properties.delivery_status_percentage;
      this.showSendPercentage = properties.show_delivery_status_percentage;
      this.isTimewarp = properties.is_timewarp;
      this.editorType = properties.email_editor_type;
      this.audience = properties.email_source_name;
      this.hasSegment = properties.has_segment;
      this.segmentDisplay = properties.segment_display;
      this.snapshotDate = properties.snapshot_date ? dateUtils.dateTransform(properties.snapshot_date) : null;
      this.stats = properties.stats;
      this.availableActions = properties.available_actions;
      this.availableReportActions = properties.available_report_actions;
      this.autoresponderTriggerString = properties.autoresponder_trigger_string;
      this.totalEmailsCount = properties.total_emails_count;
      this.pausedEmailsCount = properties.paused_emails_count;
      this.workflowType = properties.workflow_type;
      this.storeName = properties.store_name;
      this.needsAttention = properties.needs_attention;
      this.pageUrl = properties.page_url;
      this.hasStore = properties.has_store;
      this.hasSignUpForm = properties.has_signup_form;
      this.shouldShowComplianceMessage = properties.should_show_compliance_message;
      this.shouldShowTermsMessage = properties.should_show_terms_message;
      this.shouldShowDisconnectMessage = properties.should_show_disconnect_message;
      this.shouldShowSendErrorMessage = properties.should_show_send_error_message;
      this.editUrl = properties.edit_url;
      this.hasUpdatesWhilePublished = properties.has_updates_while_published;
      this.postcardAutomationStrategy = properties.automation_trigger && properties.automation_trigger.strategy;
      this.wasSourceDeleted = properties.was_source_deleted;
      this.hasNdStore = !!properties.has_nd_store;
      this.hasFailedPost = properties.has_failed_post;
      this.rawModel = properties;
      this.signupTags = properties.signup_tags;
      this.audienceStrategy = properties.audience_strategy;
      this.campaignListLocation = properties.campaign_list_location || null;
      this.creditsUsed = properties.credits_used;
      this.recipientCount = properties.recipient_count;
    },
    /**
     * Return whether this Outreach is an ad
     *
     * @return {bool} True if this Outreach is an ad
     */
    isAd: function () {
      return ["facebook", "google"].indexOf(this.type) > -1;
    },
    /**
     * Return whether this Outreach is an automation
     *
     * @return {bool} True if this Outreach is an automation
     */
    isAutomation: function () {
      return ["autoresponder", "automation"].indexOf(this.type) > -1;
    },
    /**
     * Return whether this Outreach is a postcard
     *
     * @return {bool} True if this Outreach is a postcard
     */
    isPostcard: function () {
      return this.type === "postcard";
    },
    /**
     * Return whether this Outreach is a social post
     *
     * @return {bool} True if this Outreach is a social post
     */
    isSocial: function () {
      return this.type === "social";
    },
    isEffort: function () {
      return this.type === "effort";
    },
    /**
     * Return whether this Outreach is a page
     *
     * @return {bool} True if this Outreach is a page
     */
    isPage: function () {
      return ["page"].indexOf(this.type) > -1;
    }
  });
  var api = {
    /**
     * Status reason constants
     */
    STATUS_REASON_COMPLIANCE: "compliance",
    /**
     * @param {Object} params - Params for outreach endpoint
     * @return {Promise<Outreach[]>} A Promise resolving to an array of Outreach
     */
    list: function (params) {
      var decoratedParams = lang.clone(params);

      //create the concept on an "unfiled" type filter that sends up a folder ID of 0
      if (!params["filters[folder]"] && params["filters[type]"] === "unfiled") {
        decoratedParams["filters[type]"] = undefined;
        decoratedParams["filters[folder]"] = 0;
      }

      //when user is asking for "automation", include autoresponders and transactional emails
      if (params["filters[type]"] === "automation") {
        decoratedParams["filters[type]"] = "automation_all";
      }

      //ensure that a top level filter is always provided (default to recent)
      if (!params["filters[folder]"] && !params["filters[bucket]"]) {
        decoratedParams["filters[bucket]"] = "recent";
      }
      var endpoint = params.campaign_list_location === "reports" ? "/reports/get-campaign-data" : "/channels";
      return xhr.get(mUrl.toUrl(endpoint), {
        handleAs: "json",
        query: decoratedParams
      }).then(lang.hitch(this, function (response) {
        var items = response.items.map(function (properties) {
          // mix bucket back into item
          return lang.mixin(properties, {
            bucket: decoratedParams["filters[bucket]"] || "",
            campaign_list_location: decoratedParams["campaign_list_location"]
          });
        }).map(this._createFromProperties.bind(this)
        // _createFromProperties will return null for invalid Outreaches, filter those out here
        ).filter(function (item) {
          return item;
        });
        return {
          items: items,
          count: response.count
        };
      }));
    },
    /**
     * Instantiate a new Outreach from the given properties
     *
     * @param {Object} outreachProperties - Properties to build the instance from
     * @return {Outreach} A full instance
     */
    _createFromProperties: function (outreachProperties) {
      try {
        var outreach = new Outreach(outreachProperties);
        return outreach;
      } catch (error) {
        var msg = "Error initializing Outreach: " + JSON.stringify(outreachProperties);
        console.error(msg);
        window.bugsnagClient.notify(msg);
        return null;
      }
    }
  };
  return api;
});