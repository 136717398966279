/**
 * Uses mojo/neapolitan/widgets/ImageRow as the input to select an image
 * for the css attribute background-image.
 *
 * Will only output either url(xxx.jpg) or none as attributes for this css property
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/backgroundImageInput.html", "mojo/neapolitan/widgets/ImageRow", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, domConstruct, domClass, lang, tpl, ImageRow, on) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    value: "none",
    postCreate: function () {
      this.inputContainerNode = this.domNode;
      this.imageRow = new ImageRow({
        "image": this._cssToImage(this.value),
        "actions": ["edit"]
      });
      this.imageRow.startup();
      domConstruct.place(this.imageRow.domNode, this.imageRowContainer);
      this.imageRow.on("change", lang.hitch(this, function () {
        // Just update the value & avoid calling dijit's callbacks
        this._set("value", this._imageToCss(this.imageRow.image));
        this.onChange(this.get("value"));
        this._toggleRemoveAction();
      }));
      this.own(on(this.removeLink, "click", lang.hitch(this, function () {
        this.imageRow.removeImage();
      })));
      this._toggleRemoveAction();
    },
    /**
     * A programmatically way to open image selection process for the image row
     */
    chooseImage: function () {
      this.imageRow.openFileManager();
    },
    /**
     * Updates the ImageRow with whatever the value attribute is currently set to.
     *
     * @private
     */
    _updateImageRow: function () {
      if (this.imageRow) {
        this.imageRow.updateImage(this._cssToImage(this.value));
        this._toggleRemoveAction();
      }
    },
    /**
     * When value is updated, make sure to update the image row
     * @param value
     * @private
     */
    _setValueAttr: function (value) {
      this._set("value", value);
      this._updateImageRow();
    },
    /**
     * Toggle the remove link based on whether an image is currently selected.
     * @private
     */
    _toggleRemoveAction: function () {
      domClass.toggle(this.removeLink, "hide", this.imageRow.image == null);
    },
    /**
     * Given the image object from ImageRow, return the background-image value.
     *
     * @param {Object} image
     * @returns string
     * @private
     */
    _imageToCss: function (image) {
      if (image && image.src) {
        return 'url("' + image.src + '")';
      } else {
        return "none";
      }
    },
    /**
     * Given the css attribute for background-image, we parse out the image url
     *
     * @param css
     * @returns {*}
     * @private
     */
    _cssToImage: function (css) {
      var regex = /^url\((.+)\)/;
      var match = css.match(regex);
      if (match && match[1]) {
        var url = match[1];
        var first = url[0];
        var last = url[url.length - 1];
        var imageUrl;
        if (first == '"' || first == "'" && first == last) {
          imageUrl = url.substr(1, url.length - 2);
        } else {
          imageUrl = url;
        }
        return {
          "src": imageUrl
        };
      } else {
        return null;
      }
    },
    onChange: function (value) {}
  });
});