define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/star-rating.html",
//API
"./Api"], function (declare, lang, on, domConstruct, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, starTplString, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: starTplString,
    memberID: null,
    starRating: null,
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      this.getStarRating();
    },
    /**
     * Generate stars markup based on list member's star rating number
     */
    createStarRating: function () {
      var starListTemplate = "";
      var fillStarTemplate = '<li role=\"presentation\"><span class=\"freddicon star-fill\"></span></li>';
      var emptyStarTemplate = '<li role=\"presentation\"><span class=\"freddicon graystar-fill\"></span></li>';
      for (var i = 1; i <= 5; i++) {
        if (this.starRating >= i) {
          starListTemplate += fillStarTemplate;
        } else {
          starListTemplate += emptyStarTemplate;
        }
      }
      domConstruct.place(starListTemplate, this.starListContainer, "last");
    },
    /**
     * Api request to get list member star rating
     */
    getStarRating: function () {
      api.getStarRating(this.memberID, lang.hitch(this, "hydrate"));
    },
    /**
     * Update data and view
     * @param {string} response Api response
     */
    hydrate: function (response) {
      this.starRating = response.starRating;
      this.createStarRating();
    }
  });
});