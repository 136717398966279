define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/window", "dijit/_WidgetBase", "mojo/utils", "mojo/widgets/_CustomStyleRulesMixin", "./PagesSignupFormBlock"], function (declare, lang, Deferred, on, has, win, _WidgetBase, utils, _CustomStyleRulesMixin, SignupForm) {
  return declare([_WidgetBase, _CustomStyleRulesMixin], {
    version: "1.0",
    template: 1,
    maxWidth: 960,
    // media query break point
    mobileView: false,
    // true if window width is < maxWidth
    layoutCssNode: null,
    // Reference to the link dom that loads the css
    customCssNode: null,
    // custom form styles from editor

    // User-configurable attributes
    config: {},
    subscribeUrl: "#",
    honeypotFieldName: "",
    postMixInProperties: function () {
      // Pull in any attributes from the config
      if (this.config.template) {
        this.template = this.config.template;
      }
      if (this.config.version) {
        this.version = this.config.version;
      }
      if (this.config.styles) {
        this.styles = this.config.styles;
      }
    },
    postCreate: function () {
      this.signupForm = new SignupForm({
        config: this.config
      });
      this.loadCustomCss();
      on(window, "resize", lang.hitch(this, function () {
        var mvTmp = this._isMobileView();
        if (mvTmp !== this.mobileView) {
          this.mobileView = mvTmp;
        }
      }));
    },
    startup: function () {
      this.inherited(arguments);
      this.mobileView = this._isMobileView();
    },
    _isMobileView: function () {
      return win.getBox().w < this.maxWidth ? true : false;
    },
    loadSignupFlagStyles: function () {
      var signupStylesDeferred = new Deferred();
      var signupStyles = document.createElement("link");
      signupStyles.rel = "stylesheet";
      signupStyles.type = "text/css";
      signupStyles.href = "/css/signup-forms/pages/signup-button-flag.css";
      signupStyles.media = "all";
      utils.globalPublish("neapolitan/preview/merge/template/add/css/flag", {
        linkElement: signupStyles
      });
      return signupStylesDeferred.promise;
    },
    loadCustomCss: function () {
      this.customCssNode = this.createStyleNode(document, "customSignupFormStyle");
      var sheet = this.getStyleSheet(this.customCssNode);

      // styles.neapolitan is relic of a transition process to change the model of signup styles.
      // TODO: todd-gandee remove this conditional in 2020 - seems like enough time from the transition.
      if (this.styles.hasOwnProperty("neapolitan")) {
        this.styles.neapolitan.forEach(function (style) {
          var props = Object.keys(style.props);
          props.forEach(function (prop) {
            this.addCSSRule(sheet, style.selector, prop + ": " + style.props[prop].value + ";");
          }.bind(this));
        }.bind(this));

        // add button alignment and button styles??
        if (this.styles.hasOwnProperty("formStyles")) {
          for (var formButtonStyle in this.styles.formStyles) {
            if (this.styles.formStyles.hasOwnProperty(formButtonStyle)) {
              this._addAlignmentStyles(this, sheet, this.styles.formStyles, formButtonStyle);
            }
          }
        }
      } else {
        // should never be in a state where neapolitan doesn't exist without the flag.
        // when we turn the flag off, on style with neapolitan styles when they exist
        // only for button and label, not neapolitan and formStyles in formConfig.styles
        if (this.styles.hasOwnProperty("button")) {
          // _addButtonStyles
          this._addButtonStyles(this, sheet, this.config.styles, "button");
        }
        if (this.styles.hasOwnProperty("labels")) {
          // _addLabelStyles
          this._addLabelStyles(this, sheet, this.config.styles, "labels");
        }
      }
    },
    // the function below describe two different states of the styles for SignupFormBlock.
    // _addButtonStyles and _addLabelStyles are for the unflagged version of rendering formConfig.styles
    /**
     * _addButtonStyles
     * @param {Object} signupFormContainer is the module object
     * @param {Object} sheet is the stylesheet to which to add style
     * @param {Object} stylesInFormConf is the formConfig style object to process specific styled elements
     * @param {String} domElKey is the object key for which DOM element is represented
     */
    _addButtonStyles: function (signupFormContainer, sheet, stylesInFormConf, domElKey) {
      // addCSSRule from _CustomStyleRulesMixin
      for (var domElProp in stylesInFormConf[domElKey]) {
        if (stylesInFormConf[domElKey].hasOwnProperty(domElProp)) {
          switch (domElProp) {
            case "color":
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "background-color:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            case "hover_color":
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button:hover", "background-color:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            case "text_color":
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "color:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            case "border":
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "border:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            case "alignment":
              if (stylesInFormConf[domElKey][domElProp] === "right") {
                signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:right;");
              } else if (stylesInFormConf[domElKey][domElProp] === "center") {
                signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:none;margin-left:auto;margin-right:auto;");
                if (has("ie") <= 9) {
                  signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "display:table-cell;");
                  signupFormContainer.addCSSRule(sheet, ".webContent__content .content__button", "margin-left:auto;margin-right:auto;display:table;");
                } else {
                  signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "display:table;");
                }
              } else {
                signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:left;");
              }
              break;
            case "style":
              if (stylesInFormConf[domElKey][domElProp] === "full") {
                signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "width:100%;");
              } else {
                signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "width:auto;");
              }
              break;
            default:
              break;
          }
          // add max-width on container but old style
          signupFormContainer.addCSSRule(sheet, ".mcnSignUpFormContentContainer", "flex: auto; max-width: 450px");
        }
      }
    },
    /**
     * _addLabelStyles
     * @param {Object} signupFormContainer is the module object
     * @param {Object} sheet is the stylesheet to which to add style
     * @param {Object} stylesInFormConf is the formConfig style object to process specific styled elements
     * @param {String} domElKey is the object key for which DOM element is represented
     */
    _addLabelStyles: function (signupFormContainer, sheet, stylesInFormConf, domElKey) {
      // addCSSRule from _CustomStyleRulesMixin
      for (var domElProp in stylesInFormConf[domElKey]) {
        if (stylesInFormConf[domElKey].hasOwnProperty(domElProp)) {
          switch (domElProp) {
            case "color":
              signupFormContainer.addCSSRule(sheet, ".webContent__content label", "color:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            case "font":
              signupFormContainer.addCSSRule(sheet, ".webContent__content label", "font-family:" + stylesInFormConf[domElKey][domElProp] + ";");
              break;
            default:
              break;
          }
        }
      }
    },
    /**
     * _addAlignmentStyles
     * @param {Object} signupFormContainer is the module object
     * @param {Object} sheet is the stylesheet to which to add style
     * @param {Object} stylesInFormConf is the formConfig style object to process specific styled elements
     * @param {String} domElKey is the object key for which DOM element is represented
     */
    _addAlignmentStyles: function (signupFormContainer, sheet, stylesInFormConf, domElKey) {
      // addCSSRule from _CustomStyleRulesMixin
      switch (domElKey) {
        case "buttonAlignment":
          if (stylesInFormConf[domElKey] === "right") {
            signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:right;");
          } else if (stylesInFormConf[domElKey] === "center") {
            signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:none;margin-left:auto;margin-right:auto;");
            if (has("ie") <= 9) {
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "display:table-cell;");
              signupFormContainer.addCSSRule(sheet, ".content__button", "margin-left:auto;margin-right:auto;display:table;");
            } else {
              signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "display:table;");
            }
          } else {
            signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "float:left;");
          }
          break;
        case "buttonWidthStyle":
          if (stylesInFormConf[domElKey] === "full") {
            signupFormContainer.addCSSRule(sheet, ".webContent__content .button", "width:100%;");
          }
          break;
        case "formWidth":
          signupFormContainer.addCSSRule(sheet, ".mcnSignUpFormContentContainer", "flex:0 0 " + stylesInFormConf[domElKey]);
          break;
        default:
          break;
      }
    }
  });
});