define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // Dojo utilities
"dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Mojo imports
"mojo/user", "mojo/lib/flags",
// Templates
"dojo/text!./templates/CampaignChecklistRecs.html"], function (declare, lang, on, domConstruct, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, user, flags, recsTemplate) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: recsTemplate,
    state: {
      recommendation: null,
      forceHide: null
    },
    constructor: function (props) {
      this.state.recommendation = props.recommendation;

      /**
      *A user account that has less than 2 contacts in their audience will get an empty state on their audience tab
      *This also checks how many contacts a user has
      */
      this.state.forceHide = props.forceHide;
    },
    postCreate: function () {
      this.inherited(arguments);

      // Display alternative styling if this recommendation badge is shown 
      // alongside the Smart Mark
      domClass.add(this.badgeContainer, 'c-recBadge--withSmartMark');

      /**
      * If a user has 0 contacts or
      *If a user also gets the empty state on their audience tab as well,
      *then the (Recommended for you) recommendation will be hidden from the personalize to field
      */
      if (this.state.forceHide) {
        domClass.add(this.badgeContainer, "hide");
      }
    }
  });
});