define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, base, FilterMenu, FilterMessage) {
  return declare([base], {
    onReady: function () {
      var filterSettings = {
        "target": "suspicious-patterns-list",
        "filters": [{
          "label": "Type",
          "key": "suspicious_type",
          "type": "select",
          "options": {
            "supported send": "Suspicious Send Patterns (supported)",
            "unsupported send'": "Suspicious Send Patterns (unsupported)",
            "monthly": "4XMonthly",
            "stalked": "Stalked Users",
            "signup": "Signup - Bad Domain",
            "replyto": "Replyto - Bad Domain",
            "listformspam": "List Form Spam",
            "verification": "List Form Spam",
            "large list upload": "Large List Upload",
            "big sender": "Big Sender",
            "vetting": "Vetting",
            "potential spammer": "Potential Spammer"
          }
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.suspiciousPatternsFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.suspiciousPatternsFiltersMessage.appendChild(filterMessage.domNode);
    }
  });
});