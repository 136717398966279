define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/peaches2/tabContainerUtil", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, tabContainerUtil, p2Utils, topic, registry, FilterMenu, FilterMessage, dom, domAttr, on, domClass, utils, mUrl, xhr) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    campaingId: null,
    sectionId: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        tab.preload = true;
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      this.modules.autoresponder.set("campaignIndex", this);
      on(this.$el.deleteCampaignCancelButton, "click", lang.hitch(this, function () {
        this.cancelDelete();
      }));
    },
    /**
     * this is the call back function that is fired when a selecte tool is changed from the campaigns list
     * @param {object} e - event passed from the event listener
     * @return {boolean}
     */

    openEmailTool: function (e) {
      var url = e.attr("value");
      if (!url) {
        return false;
      }
      var popup = url.substring(0, 5) === "http:" || url.substring(0, 6) === "https:";
      if (popup) {
        window.open(url);
      } else {
        window.location = url;
      }
      return false;
    },
    /**
     * opens the delete campaign modal
     * @param {string} id - the campaign id that should be deleted
     * @param {string} type - campaign type
     * @param {string }sid - section that this is being called from
     */

    deleteCampaign: function (id, type, sid) {
      this.$el.campaignId.value = id;
      this.$el.sectionId.value = sid;
      this.$el.regularUserId.value = this.userAttrs.userId;
      if (type === "variate-child") {
        domClass.remove("deleteVariateText", "hide");
      } else {
        domClass.add("deleteVariateText", "hide");
      }
      this.$widget.deleteDialog.show();
    },
    /**
     * submits the deleteion of the campaign and checks to make sure a note is provided
     * @param {object} e - the event passed from the event handler.
     */
    submitDeletedCampaign: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$el.deleteNote.value.length === 0) {
        utils.addError(this.$el.deleteNote, "Please provide a note");
      } else if (this.$el.deleteNote.value.length < 5) {
        utils.addError(this.$el.deleteNote, "Please provide a more meaningful note");
      } else {
        this.$el.deleteDialogForm.submit();
      }
    },
    /**
     * fires when the cancel button is clicked from the delete dialog
     */
    cancelDelete: function () {
      this.$widget.deleteDialog.hide();
      utils.removeErrors(this.$el.deleteNote);
      this.$el.deleteNote.value = "";
    },
    /**
     *  opens manage links tab
     * @param {number} cid - the campaign id
     */
    manageLinks: function (cid) {
      var self = this;
      var newPaneObj = {
        title: "Campaign Links: " + cid,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-links",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        stub: "campaign-links",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignLinksSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     *  opens campaign details tab
     * @param {number} cid - the campaign id
     */
    viewCampaignDetails: function (cid) {
      var self = this;
      var newPaneObj = {
        title: "Campaign Details: " + cid,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-details",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        stub: "campaign-details",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     *  opens manage bounces tab
     * @param {number} cid - the campaign id
     */
    manageBounces: function (cid) {
      var self = this;
      var newPaneObj = {
        title: "Bounces: " + cid,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-bounces",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        stub: "campaign-bounces",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignBouncesSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     * resizes the height of the iframe to fit the whole page.
     */
    resizeIframe: function () {
      var campaignIframe = dom.byId("HtmlIframe");
      var frameheight = frames.campaignIframe.document.body.scrollHeight;
      campaignIframe.style.height = frameheight + "px";
    },
    /**
     * runs saveCampaignDetialsTab and resizeIfram
     */
    showCampaignContent: function () {
      this.saveCampaignDetailsTab("content");
      this.resizeIframe();
    },
    /**
     * saves the campaign details current state
     * @param {string} subsection_id - the section that this is being fired from.
     */
    saveCampaignDetailsTab: function (subsection_id) {
      xhr(mUrl.toUrl("/peaches2/users/campaigns/save-campaign-details-state", {
        user_id: this.userAttrs.userId,
        section: subsection_id
      }), {
        handleAs: "json"
      }).then(function (data) {}, function (err) {
        p2Utils.utils.setFlash(err, "error");
      });
    },
    /**
     * translate campaign archive
     * @param {string} cid - campaign id
     * @param {string} archiveUrl - campaign archive url
     */
    translateToggle: function (cid, archiveUrl) {
      // translate it to english using google translate
      var iframesrc = "http://translate.google.com/translate?tl=en&u=" + escape(archiveUrl);
      iframesrc += "&translate_to=en";
      window.open(iframesrc, cid, "height=500,width=700");
    },
    sendEventSearchForm: function (uid, cid, input_id) {
      var email = dom.byId(input_id).value;
      var widget = registry.byId("event-search-subsection");
      widget.setHref(mUrl.toUrl("/peaches2/users/campaigns/event-search}", {
        user_id: uid,
        cid: cid,
        email: email
      }));
    },
    loadFilter: function (section) {
      var filter = null;
      var filterMessage = null;
      var filterSettings = {
        "filters": [{
          "label": "Status",
          "key": "status",
          "type": "select",
          "options": {
            "save": "Saved",
            "paused": "Paused",
            "schedule": "Scheduled",
            "sending": "sending",
            "sent": "Sent",
            "canceled": "Cancelled"
          }
        }, {
          "label": "Created since...",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day ago",
            "2": "Two days ago",
            "7": "One week ago"
          }
        }, {
          "label": "List ID",
          "key": "list_id",
          "type": "text"
        }, {
          "label": "FROM Name",
          "key": "from_name",
          "type": "text"
        }, {
          "label": "FROM Email",
          "key": "from_email",
          "type": "text"
        }, {
          "label": "Title",
          "key": "title",
          "type": "text"
        }, {
          "label": "Subject",
          "key": "subject",
          "type": "text"
        }, {
          "label": "Parent Campaign",
          "key": "parent",
          "type": "text"
        }, {
          "label": "Include this parent",
          "key": "include_parent",
          "type": "checkbox"
        }]
      };
      if (section === "autoresponder") {
        filterSettings.target = "autoresponders-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("autoresponders-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("autoresponders-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "automation") {
        filterSettings.target = "automations-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("automations-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("automations-filters-message").appendChild(filterMessage.domNode);
      }
      filterSettings.filters.unshift({
        "label": "Type",
        "key": "type",
        "type": "select",
        "options": {
          "regular": "Regular",
          "plaintext": "Plain text",
          "variate": "Multivariate",
          "variate-child": "Multivariate Child",
          "absplit": "A/B Split",
          "rss": "RSS"
        }
      });
      if (section === "regular") {
        filterSettings.target = "regular-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("campaigns-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("campaigns-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "deleted") {
        filterSettings.target = "deleted-campaigns-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("deleted-campaigns-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("deleted-campaigns-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "deleted-automation") {
        filterSettings.target = "deleted-automations-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("deleted-automations-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("deleted-automations-filters-message").appendChild(filterMessage.domNode);
      }
    }
  });
});