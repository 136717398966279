/**
 * iOS Device Interface
 * @module /mojo/widgets/ads/mobile-checklist/iOSDevice.js
 * @see /mojo/widgets/ads/mobile-checklist/_DeviceMixin.js
 *
 * Each variation of the device interface has it's own quirks. Most of the "work"
 * happens in the _DeviceMixin mixin.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/ads/mobile-checklist/_DeviceMixin"], function (declare, lang, on, _DeviceMixin) {
  return declare([_DeviceMixin], {
    platformType: "ios",
    postCreate: function () {
      this.inherited(arguments);
      this._initExternalTargetRequest();
    },
    /**
     * Initialize the click event on all "a" elements
     *
     * @private
     */
    _initExternalTargetRequest: function () {
      on(window, on.selector("a", "click"), lang.hitch(this, "_handleExternalTargetRequest"));
    },
    /**
     * If the link clicked has a target equal to "_blank"
     * prevent it from opening a new tab and pass the href to be consumed by device
     *
     * @param {MouseEvent} e - Contains info for current clicked element
     * @private
     */
    _handleExternalTargetRequest: function (e) {
      var domNode = e.target;

      // Check to see if clicked link has a target of "_blank" and grab the value
      if (domNode.target === "_blank") {
        e.preventDefault();

        // Pass the href value down to the device
        this.__actionExternalTargetRequested(domNode.href);
      }
    },
    /**
     * Map actions to the window-level methods that iOS is listening for. The iOS WebView will inject
     * these methods as part of creation of the web view.
     *
     * iOS requires us to pass at least something along with postMessage so we're including an empty
     * object
     *
     * @private
     */
    __actionEventRouterReady: function () {
      window.webkit.messageHandlers.readyToReceiveEvents.postMessage({});
    },
    __actionSlatLoaded: function () {
      window.webkit.messageHandlers.slatLoaded.postMessage({});
    },
    __actionSlatFinished: function (payload) {
      window.webkit.messageHandlers.isFinished.postMessage(payload);
    },
    __actionSlatSaveFailed: function () {
      window.webkit.messageHandlers.slatSaveFailed.postMessage({});
    },
    __actionExpiredSession: function () {
      window.webkit.messageHandlers.expiredSession.postMessage({});
    },
    __actionExternalTargetRequested: function (payload) {
      window.webkit.messageHandlers.externalTargetRequested.postMessage(payload);
    },
    // iOS doesn't need to be told when someone opens a file browser. Do nothing when this method is called.
    __actionOpenFileBrowse: function () {}
  });
});