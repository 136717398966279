/**
 * Mixin for adding Chase HPF event handling
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, lang, ioQuery, query, dijitRegistry) {
  var badCardType = ["<strong>Try a different card</strong>", "We accept Visa, Mastercard, Discover, and Amex. Some currencies don't support Discover or Amex."];
  return declare([], {
    errorCodes: {
      "000": "Success",
      "200": "Missing name",
      "310": "Invalid CC number",
      "315": "Invalid CC number",
      "330": "Missing expiration",
      "340": "Missing expiration",
      "350": "Invalid CVV",
      "355": "Invalid CVV",
      "357": "Invalid data",
      //"360": "Gateway error", //more details in gateway code
      "370": "Invalid expiration"
    },
    gatewayCodes: {
      //these are just the most common; we know of a lot more
      "516": "Inactive merchant account",
      "521": "Invalid input",
      "9582": "Duplicate profile ID"
    },
    customErrorMessages: {
      "320": badCardType,
      "((841))": badCardType,
      //yes, the parens
      "841": badCardType
    },
    constructor: function () {
      this.initializeChaseGlobalHandlers();
    },
    setChaseCurrency: function (currency) {
      var frame = query('[data-mc-el="hostedPaymentFrame"]')[0];
      if (frame) {
        var currencyParam = currency ? "&currency=" + currency : "";
        frame.src = this.chaseURL + "?dijit_id=" + this.id + currencyParam;
      }
    },
    /**
     * Interpret the alphanumeric error codes from Chase into user-consumable validation messages
     *
     * @param {string} errorCode an error code from the Chase system
     * @param {string} gatewayCode an (optional) error code from the underlying card processor
     * @param {string} gatewayMessage an (optional) description of the failure from the card processor
     *
     * @returns {array} a list of error messages (possibly containing markup)
     */
    formatChaseError: function (errorCode, gatewayCode, gatewayMessage) {
      var validationMessages;
      if (errorCode && this.customErrorMessages[errorCode.split("|")[0]]) {
        validationMessages = this.customErrorMessages[errorCode.split("|")[0]];
      } else if (gatewayCode && this.customErrorMessages[gatewayCode]) {
        validationMessages = this.customErrorMessages[gatewayCode];
      } else {
        validationMessages = ["<strong>Confirm your information and try again</strong>"];
        if (errorCode) {
          var codes = errorCode.split("|");
          for (var i = 0; i < codes.length; i++) {
            if (this.errorCodes[codes[i]]) {
              validationMessages.push(this.errorCodes[codes[i]]);
            }
          }
        }
        if (gatewayCode && this.gatewayCodes[gatewayCode]) {
          validationMessages.push(this.gatewayCodes[gatewayCode]);
        } else if (gatewayMessage) {
          validationMessages.push(gatewayMessage);
        }
      }
      return validationMessages;
    },
    /**
     * Sets up event handlers used by the Chase HPF form
     */
    initializeChaseGlobalHandlers: function () {
      if (window.chaseEventsRegistry) {
        return;
      }

      //we need to make sure the events get routed to the correct widget, thus a registry
      window.chaseEventsRegistry = [];

      //called by the response from /account/chase-gateway/form
      window.associateWidgetToChaseForm = function (dijitId, uid) {
        var widget = dijitRegistry.byId(dijitId);
        var handlers = widget.getChaseEventHandlers();
        if (handlers) {
          window.chaseEventsRegistry[uid] = handlers;
          handlers.init();
        }
      };
      var domain = window.location.host;
      window.addEventListener("message", function (event) {
        if (!event.origin.endsWith(domain) || event.data.type !== "chaseHFPEvent" || event.data.params === null || event.data.params.uID === null) {
          return;
        }
        var handlers = window.chaseEventsRegistry[event.data.params.uID];
        if (!handlers) {
          return;
        }
        var eventName = event.data.event;
        if (eventName === "startCREPayment") {
          handlers.start();
        } else if (eventName === "cancelCREPayment") {
          handlers.cancel();
        } else if (eventName === "completeCREPayment") {
          handlers.finish(parseInt(event.data.params.code, 10), event.data.params.uID);
        } else if (eventName === "creHandleDetailErrors") {
          handlers.error(event.data.params.errorCode, event.data.params.gatewayCode, event.data.params.gatewayMessage);
        } else if (eventName === "whatCVV2" && handlers.cvvExplainer) {
          handlers.cvvExplainer();
        }
      }, false);
    }
  });
});