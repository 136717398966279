/**
 * A widget that contains inputs that allow a user to edit the following CSS attributes:
 *
 *  - background-image
 *  - background-color
 *  - background-size
 *  - background-position
 *  - background-repeat
 *
 *  Neapolitan switches to use this input when all of the css attributes are present for a particular
 *  css selector.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "dijit/_WidgetsInTemplateMixin", "mojo/neapolitan/style-keys", "./ColorWithAlpha", "dojo/text!./templates/backgroundInput.html"], function (declare, lang, Color, domClass, domConstruct, on, _TemplatedMixin, _WidgetBase, _WidgetsInTemplateMixin, styleKeys, ColorWithAlphaInput, tpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    selector: null,
    // The value for background-image
    image: null,
    // The value for background-color
    color: "",
    // The value for background-position
    position: "",
    // The value for background-repeat
    repeat: "no-repeat",
    // The value for background-size
    size: "",
    DEFAULT_OVERLAY_COLOR: "rgba(0, 0, 0, 0.0)",
    // The RGBA value for the background overlay color
    overlayColor: "",
    postCreate: function (params) {
      this.inherited(arguments);
      this.originalParams = params;
      var hasAnImage = this.image.indexOf("url") > -1;
      var self = this;

      // Background overlay color feature
      if (this.canAddColorOverlay) {
        var rgbaRegex = /(rgba([^)]*)\))/g;
        var existingOverlay = null; // default behavior

        if (hasAnImage) {
          existingOverlay = this.image.match(rgbaRegex);
          this.image = this._extractUrlBackgroundProperty(this.image)[0];
        }
        if (existingOverlay !== null) {
          this.overlayColor = new Color(existingOverlay[0]);
        } else {
          this.overlayColor = new Color(this.DEFAULT_OVERLAY_COLOR);
        }

        // Setup overlay color input value
        this.overlayColorInput.set("value", this.overlayColor);
      } else {
        domConstruct.destroy(this.colorOverlayNode);
      }

      // Setup all the values on all the inputs
      this._setupInputsValues();

      // Hide fallback color KB article from Landing Pages
      if (window.app.campaignType === "page") {
        domConstruct.destroy(this.fallbackColorKB);
      }

      // Listen to all changes
      this.own(on(this.chooseImageLink, "click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.imageInput.chooseImage();
      }), on(this.imageInput, "change", function () {
        self._fireChangeEvent("image", self.imageInput.get("value"));
      }), on(this.colorInput, "change", function () {
        if (!self._hasImage()) {
          var colorVal = self.colorInput.value;
          self.fallbackColorInput.set("value", colorVal);
          self._fireChangeEvent("color", colorVal);
        }
      }), on(this.fallbackColorInput, "change", function () {
        if (self._hasImage()) {
          var colorVal = self.fallbackColorInput.value;
          self.colorInput.set("value", colorVal);
          self._fireChangeEvent("color", colorVal);
        }
      }), on(this.positionInput, "change", function () {
        self._fireChangeEvent("position", self.positionInput.get("value"));
      }), on(this.sizeInput, "change", function () {
        self._fireChangeEvent("size", self.sizeInput.get("value"));
      }), on(this.repeatInput, "change", function () {
        self._fireChangeEvent("repeat", self.repeatInput.get("value"));
      }));
      this._toggleContainers();
    },
    /**
     * Helper for setting the attribute and firing onChange event
     *
     * @param {string} attr Setting the background attribute
     * @param {string} value Setting the background style value
     * @private
     */
    _fireChangeEvent: function (attr, value) {
      this.set(attr, value);
      this.onChange("background-" + attr, value);
    },
    _setImageAttr: function (value) {
      this._set("image", value);
      this._toggleContainers();
    },
    /**
     * Method to extract the "URL" value from the background property.
     *
     * @param {string} bkgrndImgStr A string that contains a property with a linear gradient and url() val, or just a url() val
     * @return {string} "url(<path-to-the-image>)". No semicolon.
     */
    _extractUrlBackgroundProperty: function (bkgrndImgStr) {
      var urlRegex = /(url.+)\S[^;]+/g;
      return bkgrndImgStr.match(urlRegex);
    },
    /**
     * Helper to figure out what the image string should be based on presence of a color overlay
     */

    _generateImageString: function (imgUrl, colorString) {
      if (colorString !== "transparent") {
        var cleanUrl = this._extractUrlBackgroundProperty(imgUrl)[0];
        return "linear-gradient(" + colorString + ", " + colorString + "), " + cleanUrl;
      }
      return this._extractUrlBackgroundProperty(imgUrl);
    },
    /**
     * Since image is the background-image attribute, we have to check for "none" as an empty state.
     *
     * @returns {boolean} Tells us if a background image has been set or not
     * @private
     */
    _hasImage: function () {
      return this.image && this.image !== "none";
    },
    /**
     * Toggle between the two containers that allows a user to tweak background-image specific attributes
     * or just the background color
     *
     * @private
     */
    _toggleContainers: function () {
      domClass.toggle(this.noImageContainer, "hide", this._hasImage());
      domClass.toggle(this.withImageContainer, "hide", !this._hasImage());
    },
    _setupInputsValues: function () {
      this.colorInput.set("value", this.color);
      this.fallbackColorInput.set("value", this.color);
      this.imageInput.set("value", this.image);
      this.positionInput.set("value", this.position);
      this.repeatInput.set("value", this.repeat);
      this.sizeInput.set("value", this.size);
    },
    /**
     * Given a property and the value, correctly update the input
     *
     * @param {string} property - one of the background css properties
     * @param {string} value - the value of the background css property
     */
    updateValue: function (property, value) {
      switch (property) {
        case styleKeys.BG_COLOR:
          if (this._hasImage()) {
            this.fallbackColorInput.set("value", value);
          } else {
            this.colorInput.set("value", value);
          }
          break;
        case styleKeys.BG_IMAGE:
          this.set("image", value);
          this.imageInput.set("value", value);
          break;
        case styleKeys.BG_POSITION:
          this.positionInput.set("value", value);
          break;
        case styleKeys.BG_SIZE:
          this.sizeInput.set("value", value);
          break;
        case styleKeys.BG_REPEAT:
          this.repeatInput.set("value", value);
          break;
        default:
      }
    },
    /**
     * onColorChange is event fired from ColorWithAlpha input
     * @param {Object} rgbaColor this will be the dojo/Color object
     */
    onColorChange: function (rgbaColor) {
      // value
      // this.set("overlayColor", rgbaColor);
      if (this._hasImage()) {
        var colorString = rgbaColor.toCss(true);
        // process image string based on RGBA value
        var imageString = this._generateImageString(this.image, colorString);
        this.overlayColor = rgbaColor;
        // set image value
        // this.imageInput.set("value", imageString);
        this._fireChangeEvent("image", imageString);
      }
    },
    _updateAllProps: function () {
      this.onChange("background-color", this.color);
      this.onChange("background-image", this.image);
      this.onChange("background-position", this.position);
      this.onChange("background-repeat", this.repeat);
      this.onChange("background-size", this.size);
    },
    reset: function () {
      // iterate over this.params to match existing props with old props
      var keys = Object.keys(this.params);
      keys.map(function (key) {
        this[key] = this.params[key];
      }.bind(this));
      this._updateAllProps();
      this._setupInputsValues();
    },
    onChange: function (property, value) {}
  });
});