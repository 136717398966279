/**
 * A class that represents the structure of a Link for the editor
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  return declare(null, {
    /**
     * @param {String} href
     * @param {String} title
     */
    constructor: function (href, title) {
      this.attrs = {};
      this.href = href;
      this.title = title;
    }
  });
});