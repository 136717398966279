define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/layout/TabContainer", "dijit/layout/ContentPane", "mojo/app/peaches2/utils", "mojo/url", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/isFunction", "dijit/registry"], function (declare, TabContainer, ContentPane, p2Utils, mUrl, on, isFunction, registry) {
  return declare(null, {
    container: null,
    viewModule: {},
    /**
     * creates a tab container and adds content panes that are passed
     *
     * @param {string} tabEl - can be id or node this is where the tab container will live
     * @param {array} pageJs - this is the current pages js object that will include any methods that need to avaialble to the modules
     * usersAttrs and the pageTabs
     * @param {object} modules - the modules that should be loaded by the content panes
      */

    createContentPanes: function (tabEl, pageJs, modules) {
      var self = this;
      if (tabEl) {
        this._createTabContainer(tabEl);
      } else {
        console.error("The createContentPanes utility requires a string or node reference to be passed for the html element that the tab will be added to tabContainer");
        return;
      }
      if (pageJs.pageTabs) {
        pageJs.pageTabs.forEach(function (obj) {
          if (mUrl.getHashParams().t) {
            obj.active = false;
          }
          var newPane = new ContentPane({
            title: obj.title,
            href: obj.href,
            selected: obj.active,
            id: obj.stub + "-section",
            preload: obj.preload
          });
          self[self._camelCaseStub(obj.stub) + "Section"] = newPane;
          self._addOndownloadEnd(obj, newPane, modules, pageJs);
          self.container.addChild(newPane);
        });
        if (mUrl.getHashParams().t) {
          self.container.selectChild(self[self._camelCaseStub(mUrl.getHashParams().t)]);
        }
      } else {
        console.error("An array of objects should be passed and should include a title, stub, and href for each object in the array");
        return;
      }
      if (this.container) {
        this.container.startup();
        this._watchHashParam();
      }
    },
    /**
     * if we get a stub with dashes we need to change them to camelCase
     * @param  {string} str - stub that should be changed to camelCase
     * @private
     * @return {string} camelCased string
     */
    _camelCaseStub: function (str) {
      return str.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
    },
    /**
     * watches for chances from the #t: param in url and
     * selects tab based on the string in the param
     * @private
     */
    _watchHashParam: function () {
      var self = this;
      // Selecting the desired tab when the URL hash changes
      on(window, "hashchange", function (e) {
        var section_id = mUrl.getHashParams()["t"];
        if (section_id) {
          if (!section_id.endsWith("-section")) {
            return false;
          }
          self.container.selectChild(self[self._camelCaseStub(section_id)]);
        }
      });

      // Updating URL hash when a tab is clicked
      this.container.watch("selectedChildWidget", function (name, old, nval) {
        if (nval.id.endsWith("-hidden")) {
          return false;
        }
        mUrl.setHashParams({
          "t": nval.id
        });
      });
    },
    _createTabContainer: function (tabEl) {
      this.container = new TabContainer({
        doLayout: false
      }, tabEl);
    },
    /**
     * adds a new contentPane to the current tabContainer. If there is already a tabContainer
     * created this will add the new pane to that existing tabContainer.
     * Passing tabEl will create a new tabContainer.
     * passing targetEl will add the contentPane to that element with that id.
     *
     * @param {object} obj - should include the title and href
     * @param {boolean} selected - will select the tab when it is added
     * @param {string} tabEl - the id of the tab that this pane should added to.
     * @param {string} targetEl - id or reference node of where the pane should live
     */
    addNewPane: function (obj, selected, id, tabEl, targetEl) {
      var self = this;
      var tab = self[self._camelCaseStub(obj.stub) + "Section"];
      if (tab) {
        tab.set("title", obj.title);
        tab.set("href", obj.href);
        self.container.selectChild(tab);
      } else {
        if (!targetEl && !this.container) {
          this._createTabContainer(tabEl);
        }
        var newPane = new ContentPane({
          title: obj.title,
          href: obj.href,
          closable: obj.closable,
          onClose: function () {
            self[self._camelCaseStub(obj.stub) + "Section"] = null;
            return true;
          },
          id: obj.stub + "-section"
        });
        if (obj.onDownloadEnd) {
          newPane.set("onDownloadEnd", obj.onDownloadEnd);
        }
        self[self._camelCaseStub(obj.stub) + "Section"] = newPane;
        if (targetEl) {
          newPane.placeAt(targetEl).startup();
          registry.byId(targetEl).selectChild(newPane);
        }
        if (!targetEl && this.container) {
          this.container.addChild(newPane);
        }
        tab = newPane;
      }
      if (selected) {
        this.container.selectChild(tab);
        mUrl.setHashParams({
          t: obj.stub + "-section",
          id: id
        });
      }
    },
    /**
     * adds the content pane's javascript functionality so that it is initialized on download end
     * @param {object} paneObj - the setup information for the pane from the backend
     * @param {object} pane - the dijit contentpane object
     * @param {object} modules - the modules that should be loaded by the content panes
     * @param {object} pageJs - the current page.js.
     * @private
     */

    _addOndownloadEnd: function (paneObj, pane, modules, pageJs) {
      var self = this;
      Object.keys(modules).forEach(function (k, i) {
        var moduleIsFunction = isFunction(modules[k]);
        var initModule = null;
        if (k === self._camelCaseStub(paneObj.stub)) {
          if (moduleIsFunction) {
            initModule = new modules[k]({
              elId: paneObj.id,
              pageJs: pageJs
            });
            self.viewModule[self._camelCaseStub(paneObj.stub)] = initModule;
          } else {
            self.viewModule[self._camelCaseStub(paneObj.stub)] = modules[k];
          }
          pane.set("onDownloadEnd", function () {
            if (moduleIsFunction) {
              initModule.parse(paneObj.id);
              initModule.onReady();
            } else {
              modules[k].onReady();
              self.viewModule[self._camelCaseStub(paneObj.stub)] = modules[k];
            }
          });
        }
      });
    }
  }); // eo declare
}); // eo define