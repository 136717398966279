define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/PathwayCardList.html", "./BlueprintCard", "vendor/pluralize/pluralize"], function (declare, lang, domConstruct, on, domClass, query, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, pathwayCardListTpl, BlueprintCard, pluralize) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: pathwayCardListTpl,
    blueprintCountText: null,
    postMixInProperties: function () {
      var blueprintCount = this.blueprints.length;
      this.blueprintCountText = blueprintCount + pluralize(" Campaign", blueprintCount);
    },
    postCreate: function () {
      if (this.isOpen) {
        domClass.toggle(this.detailsSummary, ["disclosure-open", "disclosure-closed"]);
        domClass.remove(this.detailsContent, "display--none");
      }
      on(this, "click", lang.hitch(this, function () {
        domClass.toggle(this.detailsSummary, ["disclosure-open", "disclosure-closed"]);
        domClass.toggle(this.detailsContent, "display--none");
      }));
      this.renderBlueprintCards();
    },
    renderBlueprintCards: function () {
      domConstruct.empty(this.list);
      this.blueprints.map(function (card) {
        domConstruct.place(card.domNode, this.list);
      }, this);
    },
    _onFocus: function (origin) {
      if (origin !== "mouse") {
        domClass.add(this.detailsSummary, "disclosure-open");
        domClass.remove(this.detailsSummary, "disclosure-closed");
        domClass.remove(this.detailsContent, "display--none");
        this.blueprints[0].focus();
      }
    }
  });
});