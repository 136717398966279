define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/hash", "dijit/registry", "dojox/html/entities",
// Widgets
"mojo/widgets/contact-profile/FieldsListItem",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/fields-list.html",
// API
"./Api"], function (declare, lang, domConstruct, domClass, on, hash, registry, htmlEntities, FieldsListItem, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, ContactProfileEvent, listTplString, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: listTplString,
    memberID: null,
    listName: null,
    listItems: null,
    listEndpoint: null,
    editModalID: null,
    isArchived: false,
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      api.getInfoList(this.memberID, this.listEndpoint, lang.hitch(this, "hydrate"));
      var self = this;
      if (this.editModalID && !this.isArchived) {
        domClass.remove(this.editLink, "hide");
        on(this.editLink, "click", function () {
          registry.byId(self.editModalID).show();
          hash(self.editModalID);
          var tracking_ui_detail = null;
          switch (self.editModalID) {
            case "edit-profile-dialog":
              tracking_ui_detail = "edit";
              break;
            case "edit-groups-dialog":
              tracking_ui_detail = "edit_groups";
              break;
            case "edit-gdpr-dialog":
              tracking_ui_detail = "edit_gdpr";
              break;
            default:
              break;
          }
          var event = new ContactProfileEvent("audience:started", {
            action: "started",
            ui_object: "link_text",
            ui_object_detail: tracking_ui_detail,
            ui_action: "clicked",
            ui_access_point: "right_column",
            event_origin: "dojo"
          });
          event.publishEvent();
        });
      }
    },
    /**
     * Creates ContactListItem widgets for each entry in listItems
     */
    createListItems: function () {
      if (this.listItems.length > 0) {
        domClass.remove(this.listOuterContainer, "hide");
        Object.keys(this.listItems).forEach(function (listItem) {
          var item = new FieldsListItem({
            "data": {
              "label": this.listItems[listItem].label,
              "value": this.listItems[listItem].value,
              "type": this.listItems[listItem].type,
              "extras": this.listItems[listItem].extras
            }
          });
          item.placeAt(this.listContainer, "last");
        }, this);
      } else {
        domConstruct.destroy(this.listOuterContainer);
      }
    },
    /**
     * Determine the type of list item based on list item flags
     * @param {Object} listItem
     * @returns {string} Type of listItem
     */
    getListItemType: function (listItem) {
      if (listItem.isLocation) {
        return "location";
      }
      if (listItem.isSocial) {
        return "social";
      }
      return "normal";
    },
    /**
     * Update data and view
     * @param {string} response Api response
     */
    hydrate: function (response) {
      this.listItems = response;
      this.createListItems();
    }
  });
});