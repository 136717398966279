define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// keys
"dojo/text!./templates/Menu.html", "./_MenuBase"], function (declare, keys, template, _MenuBase) {
  // module:
  //		dijit/DropDownMenu

  return declare("dijit.DropDownMenu", _MenuBase, {
    // summary:
    //		A menu, without features for context menu (Meaning, drop down menu)

    templateString: template,
    baseClass: "dijitMenu",
    // Arrow key navigation
    _onUpArrow: function () {
      this.focusPrev();
    },
    _onDownArrow: function () {
      this.focusNext();
    },
    _onRightArrow: function ( /*Event*/evt) {
      this._moveToPopup(evt);
      evt.stopPropagation();
      evt.preventDefault();
    },
    _onLeftArrow: function ( /*Event*/evt) {
      if (this.parentMenu) {
        if (this.parentMenu._isMenuBar) {
          this.parentMenu.focusPrev();
        } else {
          this.onCancel(false);
        }
      } else {
        evt.stopPropagation();
        evt.preventDefault();
      }
    }
  });
});