define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin"], function (declare, on, topic, query, domClass, lang, _Widget, _TemplatedMixin, _WidgetInTemplateMixin) {
  /**
   * Wrapper for a single list item
   *  - store associated data
   *  - make local class updates on selection state change
   * @typedef FilterListItem
   * @property {string} category
   * @property {string} displayText
   * @property {boolean} selected
   */
  var FilterListItem = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: "<li class='cursor--pointer'><a href='#' data-dojo-attach-point='displayTextNode'></a></li>",
    // set by constructor
    category: null,
    displayText: "",
    _setDisplayTextAttr: {
      node: "displayTextNode",
      type: "innerHTML"
    },
    selected: false,
    _setSelectedAttr: function (isSelected) {
      // transition to selected
      if (isSelected && !this.selected) {
        domClass.add(this.domNode, "selected");
      } else if (!isSelected && this.selected) {
        // remove selected style
        domClass.remove(this.domNode, "selected");
      }
      this._set("selected", isSelected);
    }
  });

  /**
   * Publish selected filter
   * @typedef FilterList
   */
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: "<ul class='regular'></ul>",
    // set by constructor
    filters: [],
    // Track currently selected element
    selectedFilter: null,
    _setSelectedFilterAttr: function (newFilter) {
      // deselect previously selected filter
      if (this.selectedFilter) {
        this.selectedFilter.set("selected", false);
      }
      this._set("selectedFilter", newFilter);
    },
    /**
     * Update `this.selectedFIlter` and makes calls to update the
     * previous and next filter's `selected` state
     * @param {FilterListItem} filterItem FilterListItem to set as selected
     */
    _setActiveFilter: function (filterItem) {
      // check that a new filter has been selected
      if (filterItem.get("selected") === false) {
        // update filter selection status
        filterItem.set("selected", true);
        this.set("selectedFilter", filterItem);

        // publish selected filter to StoreSelectionManager
        topic.publish("filter/store-selection", filterItem.category);
      }
    },
    /**
     * Creates a FilterListItem
     * - When a filter is clicked update the active item
     * - Place in dom
     * @param {Object} filter data to create filter
     * @returns {FilterListItem} Widget
     */
    _createFilterItem: function (filter) {
      var filterItem = FilterListItem({
        displayText: filter.displayText,
        category: filter.category,
        selected: false
      });
      on(filterItem.domNode, "click", this._setActiveFilter.bind(this, filterItem));
      filterItem.placeAt(this.domNode);
      return filterItem;
    },
    postCreate: function () {
      // store the first item to be "selected"
      var firstFilterItem = this._createFilterItem(this.filters[0]);
      // create the rest of the filter items
      this.filters.slice(1).map(this._createFilterItem.bind(this));
      // select first item as active
      this._setActiveFilter(firstFilterItem);
    }
  });
});