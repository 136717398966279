/**
 * The "Reverse Bridge" is functionally the same in that it mounts a React component on a DOM element on a Dojo page.
 *
 * It an improvement upon the classic Bridge pattern, but it does have a couple of requirements:
 * - It can only be used inside of the Dojo iframe inside of the SPA, so they cannot be used on pages outside of the SPA
 * - Props must be serializable (https://developer.mozilla.org/en-US/docs/Glossary/Serialization). We do a check for this in ReverseBridgeRenderer.js.
 * This is typically not too problematic, but functions have to be binded a certain way in Dojo and called a certain way in the bridged React component.
 *
 * If your use-case can fulfill the above requirements, the Reverse Bridge is superior to the original implementation of the bridge pattern for a few reasons:
 * - It does not require an additional webpack setup (the classic bridge does)
 * - It shares scope and dependencies with the top frame (classic bridges don't, and people would often pull in a bunch of needless stuff into their bridges, like SPA code)
 * - Because of the above, they're more easily re-purposed in non-Dojo code
 * - Performance is improved because classic bridges required download / parse / execution of duplicate libraries in the iframe (wink, react, react-dom, etc.)
 */
define(["mojo/context", "mojo/lib/logger"], function (context, logger) {
  function windowTopPostMessage(action, id, properties, fns) {
    window.top.postMessage({
      channel: "bridge",
      payload: {
        action: action,
        id: id,
        properties: properties,
        fns: fns
      }
    }, window.location.origin);
  }
  return function mount(id, _properties) {
    var properties = {},
      fns = {};
    logger.info("js_assets", "[bridges] React injection into dojo (reverse)", {
      bridgeName: id,
      pathName: window.location.pathname,
      properties: properties
    });
    for (var key in _properties) {
      if (!_properties.hasOwnProperty(key)) {
        continue;
      }
      var value = _properties[key];
      if (typeof value === "function") {
        fns[key] = value;
      } else {
        properties[key] = value;
      }
    }
    windowTopPostMessage("mount", id, properties, Object.keys(fns));
    function handleMessage(event) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (!event.data || !event.data.channel) {
        return;
      }
      var channel = event.data.channel;
      var payload = event.data.payload;
      if (channel === "bridge" && payload.action === "fn") {
        _properties[payload.key](JSON.parse(payload.args));
      }
    }
    window.addEventListener("message", handleMessage);
    return function umount() {
      windowTopPostMessage("unmount", id);
      window.removeEventListener("message", handleMessage);
    };
  };
});