define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "mojo/context"], function (declare, xhr, mUrl, lang, Deferred, user, flags, context) {
  return declare(null, {
    userData: null,
    constructor: function () {
      window.$zopim || function (d, s) {
        var z = $zopim = function (c) {
            z._.push(c);
          },
          $ = z.s = d.createElement(s),
          e = d.getElementsByTagName(s)[0];
        z.set = function (o) {
          z.set._.push(o);
        };
        z._ = [];
        z.set._ = [];
        $.async = !0;
        $.setAttribute("charset", "utf-8");
        $.src = "//v2.zopim.com/?3b6ziEnZVB74oBc8AtnqB7kdH8QS4Zst";
        z.t = +new Date();
        $.type = "text/javascript";
        e.parentNode.insertBefore($, e);
      }(document, "script");
      $zopim(function () {
        //hide in-window chat popups
        $zopim.livechat.hideAll();
      });
      this.userData = xhr.get(mUrl.toUrl("kb/get-support-settings"), {
        handleAs: "json"
      });
    },
    getChatDeptStatus: function (callback) {
      this.userData.then(lang.hitch(this, function (response) {
        if (response.userData) {
          var userData = response.userData;
          $zopim(function () {
            // Show the chat button based on which departments are online.
            $zopim.livechat.setOnConnected(function () {
              var getDept = $zopim.livechat.departments.getDepartment;
              var dept;
              if (userData.user_level === "pro" || userData.golden_monkey === true) {
                dept = getDept("pro");
                if (dept.status === "offline") {
                  dept = getDept("technical-support");
                }
              } else {
                dept = getDept("technical-support");
                if (dept.status === "offline") {
                  dept = getDept("task-us");
                }
              }
              if (dept === undefined || context.flagIsOn(flags.FORCE_CHATS_OFFLINE)) {
                dept = {
                  status: "offline"
                };
              }
              if (dept.status === "online") {
                callback(true);
              } else {
                callback(false);
              }
            });
          });
        }
      }));
    }
  });
});