/**
 * @module mojo/Translator
 */
define([// Base dependencies
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/template", "mojo/page/data",
// Flags
"mojo/lib/flags", "mojo/context"], function (declare, lang, _WidgetBase, Deferred, template, pageData, flags, context) {
  /**
   * Persistence point for an instance of Translator.
   * @type {null|Object}
   */
  var instance = null;

  /**
   * Constant for target node. See _js_settings.html.
   * @type {string}
   */
  var IN_PAGE_TRANSLATION_TARGET_ID = "js-dojo-translation-data";

  /**
   * Translator widget. Not intended to be interfaced with directly.
   */
  var Translator = declare([_WidgetBase], {
    /**
     * Cache of translations. If this is !null, we have successfully hydrated the data.
     * @type {null|Object}
     */
    cache: null,
    /**
     * Invoked before rendering occurs, and before any dom nodes are created.
     */
    postMixInProperties: function () {
      this._init().then(
      // no-op
      function (success) {}, function (err) {
        console.error(err);
      });
      this.inherited(arguments);
    },
    /**
     * Simplest method for retrieval of translation. Given a key, attempt to return
     * the value from cache.
     *
     * @param {String} key unique identifier for the string needing to be translated.
     * @returns {String} Translated string
     */
    handleTranslation: function (key) {
      if (!this.cache) {
        console.warn("Translation attempted before initialization for", key);
      }
      return this._getValueByKey(key);
    },
    /**
     * Given a key and an object representing variables we'd like to mixin to the string,
     * build a new string and return it.
     *
     * @param {String} key unique identifier for the string needing to be translated.
     * @param {Object} params Object representing key-value-pairs we need to replace.
     * @returns {String} Translated string
     */
    handleTranslationWithParams: function (key, params) {
      // Look for {something} in strings
      var regex_pattern = /{([\s\S]+?)}/g;

      // Adjust for singular and plural situations.
      if ("count" in params) {
        key = this._getGrammaticalNumberKeyByCount(key, params.count);
      }

      // Leverage lodash's template engine for string interpolation.
      // Override expected template strings with custom regex.
      // See https://lodash.com/docs/4.17.15#template
      var compiledTemplate = template(
      // Use simple handleTranslation method as a getter.
      this.handleTranslation(key), {
        interpolate: regex_pattern
      });
      return compiledTemplate(params);
    },
    /**
     * Start up the widget and create a promise around loading of data. The assumption
     * around a promise existing is to enable expansion into asynchronously pulling
     * translations if we ever need to.
     *
     * @returns {Promise} Promise
     * @private
     */
    _init: function () {
      var deferred = new Deferred();
      if (this._isInPageTranslationsAvailable()) {
        var inPageData = pageData.getAndParse(IN_PAGE_TRANSLATION_TARGET_ID);
        if (inPageData.status === "success") {
          this.set("cache", inPageData.translations);
          deferred.resolve("Cache set. Hydrating with in-page translations");
        }
      } else {
        deferred.reject("Failure to get translations data");
      }
      return deferred.promise;
    },
    /**
     * Helpful check for whether we can rely on page-provided data
     *
     * @returns {boolean} Can we possibly retrieve data from the body?
     * @private
     */
    _isInPageTranslationsAvailable: function () {
      return pageData.isAvailable(IN_PAGE_TRANSLATION_TARGET_ID);
    },
    /**
     * Assuming cache is built, retrieve the value of a given key.
     *
     * @param {String} key unique identifier for the string needing to be translated.
     *
     * @returns {String} Value of key in cache file, likely the translated string
     * @private
     */
    _getValueByKey: function (key) {
      if (key in this.cache) {
        return this.cache[key];
      }
      return "Invalid key: " + key;
    },
    /**
     * Most latin-based languages have subtle differences in sentence structure when including a number.
     * Number positioning, pluralization, and more can different when a number is 0, 1, or 2+. This is referred to
     * as grammatical numbers. An example:
     *
     * {
     *     "contacts_match_conditions_eme": "{count} contacts match the condition Email Marketing Engagement is {preview_name}",
     *     "contacts_match_conditions_eme_0": "{count} contacts match the condition Email Marketing Engagement is {preview_name}",
     *     "contacts_match_conditions_eme_1": "{count} contact matches the condition Email Marketing Engagement is {preview_name}",
     * }
     *
     * This helper function is designed to return the appropriate pluralized or singular string based on the count
     * value. This prevents implementers from needing to self-manage such logic.
     *
     * Here is the expected behavior:
     *
     * console.log(Translator.translateWithParams('contacts_match_conditions_eme', 50));
     * >> "2 contacts match the condition Email Marketing Engagement is {preview_name}"
     *
     * console.log(Translator.translateWithParams('contacts_match_conditions_eme', 1));
     * >> "1 contact matches the condition Email Marketing Engagement is {preview_name}"
     *
     * @param {String} key unique identifier for the string needing to be translated.
     * @param {number} count .
     *
     * @returns {String} key — adjusted key
     * @private
     */
    _getGrammaticalNumberKeyByCount: function (key, count) {
      // Let JS do it's coercion into a string.
      var potential_key = key + "_" + count;

      // Check for whether the key has been configured as a translation option.
      if (potential_key in this.cache) {
        return potential_key;
      }

      // Fall back to original key.
      return key;
    }
  });

  /**
   * Singleton. Creates a standard API for interfacing with the library and forces
   * the browser to only create a single instance.
   */
  return {
    readyPromise: null,
    /**
     * Helper method for initializing instance of translator
     *
     * @returns {Object} An instance of Translator
     *
     * @private
     */
    _getInstance: function () {
      if (!instance) {
        instance = new Translator();
        instance.startup();
      }
      return instance;
    },
    /**
     * Thennable function for verifying that everything is fully loaded before
     * attempting a translation. This should be done at a high level so as not
     * to conflict with synchronous expectations of most widgets.
     *
     * It's recommended if you do implement this, you handle errors. A callback is
     * available as the second argument of the then().
     *
     * Translator.isReady().then(
     *      function () {
     *          console.log("translations are ready!");
     *      },
     *      function (err) {
     *          console.error(err);
     *      }
     * );
     *
     * @returns {Promise} Promise
     */
    isReady: function () {
      // Prevent duplicate instances. If a later caller attempts
      // to use this, immediately resolve the promise.
      if (!this.readyPromise) {
        this.readyPromise = new Deferred();
        // Get the instance of Translator
        var i = this._getInstance();
        try {
          // Instance has already been fully initialized. Resolve
          // the promise.
          if (i.get("cache") !== null) {
            this.readyPromise.resolve(true);
          }
          // In the event the initialization is taking longer than expected,
          // attach a listener for establishment of cache.
          i.watch("cache", function () {
            // Trust that this firing means the cache was set correctly.
            this.readyPromise.resolve(true);
          }.bind(this));
        } catch (e) {
          this.readyPromise.reject("Failure to get translations data");
        }
      }
      return this.readyPromise.promise;
    },
    /**
     * Translate a simple string. If you're reasonably sure you don't have any variables,
     * reach for this one.
     *
     * Invoke by calling Translator.translate("key_from_json_file");
     *
     * @param {String} key unique identifier for the string needing to be translated.
     * @returns {String} Translated string
     */
    translate: function (key) {
      return this._getInstance().handleTranslation(key);
    },
    /**
     * Similar to Translator.translate() however allows for "mixing in" variables.
     *
     * Invoke by calling Translator.translateWithParams("key_from_json_file", {foo: 123, bar: "baz"});
     *
     * @param {String} key unique identifier for the string needing to be translated.
     * @param {Object} params Object representing key-value-pairs we need to replace.
     * @returns {String} Translated string
     */
    translateWithParams: function (key, params) {
      return this._getInstance().handleTranslationWithParams(key, params);
    }
  };
});