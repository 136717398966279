define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/MenuSeparator", "dojo/text!./input/templates/optGroupLabel.html"], function (declare, MenuSeparator, tplOptGroupLabel) {
  var OptGroupLabel = declare([MenuSeparator], {
    templateString: tplOptGroupLabel,
    label: "",
    message: ""
  });

  /**
   * Label and message are optional key/value pairs.  If OptGroupLabel is used
   * elsewhere, adding a new key/value pair here will not break. If adding
   * another key/value pair is an option, add it here.
   */

  return OptGroupLabel; // exporting this as a class
});