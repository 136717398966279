define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "mojo/views/_base", "mojo/api/automation", "mojo/user", "mojo/utils", "mojo/lib/flags", "mojo/context", "mojo/widgets/checklist-editor/Container", "mojo/widgets/checklist-editor/status/AutomationStatus", "mojo/widgets/checklist-editor/items/AutomationRecipients", "mojo/widgets/checklist-editor/items/Content", "mojo/widgets/checklist-editor/items/Subject", "mojo/widgets/checklist-editor/items/From", "mojo/widgets/checklist-editor/items/CampaignName"], function (declare, lang, on, request, mUrl, base, automationApi, user, utils, flags, context, ChecklistEditorContainer, AutomationStatus, AutomationRecipients, Content, Subject, From, CampaignName) {
  var isComplete = function (automation) {
    return true;
  };
  var isValid = function (automation) {
    return true;
  };
  var isDisabled = function (status) {
    if (status === automationApi.AUTOMATION_STATUS_SENDING) {
      return true;
    }
    return false;
  };
  var getContentButtonText = function (status, rendered_html, is_content_ready) {
    if (status === automationApi.AUTOMATION_STATUS_SENDING) {
      return "Edit design";
    } else if (!rendered_html) {
      return "Design Email";
    } else if (is_content_ready) {
      return "Edit design";
    } else {
      return "Edit";
    }
  };
  return declare([base], {
    onReady: function () {
      this.model = automationApi.createFromProperties(this.automation);
      var contentButtonText = getContentButtonText(this.model.status, this.model.renderedHtml, this.model.isContentReady);
      // in order to include imported contacts 1cw cannot be linked to a landing page.
      var userCanIncludeImportedContacts = !this.model.recipients.landingPageName;
      var checklistItems = [{
        label: "Name",
        position: "before",
        widget: CampaignName,
        isComplete: isComplete,
        isValid: isValid,
        isDisabled: isDisabled(this.model.status),
        isSingleEmailWelcome: true,
        validationMessage: "Your automation name is too long."
      }, {
        label: "Sends To",
        contentWidget: AutomationRecipients,
        description: "Who are you sending this campaign to?",
        editLabel: userCanIncludeImportedContacts ? "Edit Delay and Recipients" : "Edit Delay",
        isComplete: AutomationRecipients.isComplete,
        isValid: AutomationRecipients.isValid,
        isDisabled: isDisabled(this.model.status),
        userCanIncludeImportedContacts: userCanIncludeImportedContacts,
        onActivate: function () {
          window.ga("send", "event", "one_click_builder", "click", this.editLabel.toLowerCase());
        },
        trackSave: function () {
          window.ga("send", "event", "one_click_builder", "click", this.editLabel.toLowerCase() + ":save");
        },
        trackCancel: function () {
          window.ga("send", "event", "one_click_builder", "click", this.editLabel.toLowerCase() + ":cancel");
        }
      }, {
        label: "From",
        description: "Who is sending this campaign?",
        editLabel: "Edit From",
        contentWidget: From,
        isComplete: From.isComplete,
        isValid: From.isValid,
        isDisabled: isDisabled(this.model.status),
        onActivate: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_from");
        },
        trackSave: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_from:save");
        },
        trackCancel: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_from:cancel");
        }
      }, {
        label: "Subject",
        description: "What's the subject line for this campaign?",
        contentWidget: Subject,
        isComplete: Subject.isComplete,
        isValid: Subject.isValid,
        isAutomation: true,
        isDisabled: isDisabled(this.model.status),
        onActivate: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_subject");
        },
        trackSave: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_subject:save");
        },
        trackCancel: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_subject:cancel");
        }
      }, {
        label: "Content",
        description: "Design the content for your email.",
        contentWidget: Content,
        actionLabel: contentButtonText,
        editLabel: contentButtonText,
        errorLabel: contentButtonText,
        isComplete: Content.isComplete,
        isValid: Content.isValid,
        isDisabled: isDisabled(this.model.status),
        activate: function () {
          window.ga("send", "event", "one_click_builder", "click", "edit_design");
          window.location.href = mUrl.toUrl("/i/campaigns/show", {
            id: this.model.campaignId
          });
        }
      }];
      var checklist = new ChecklistEditorContainer({
        statusWidget: AutomationStatus,
        model: this.model,
        items: checklistItems,
        onSave: function (data, model) {
          return model.patch(data);
        }
      });
      checklist.placeAt(this.$el.automationChecklist);
    }
  });
});