define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'mojo/widgets/TopSliderPane', 'dojo/text!./templates/AddSegmentPane.html', "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, TopSliderPane, tplStr, lang) {
  return declare([TopSliderPane], {
    reportId: null,
    titleText: "Mailchimp Comparative Reports",
    badgeText: "Add Segment",
    paneClasses: "add-segment-container",
    content: tplStr,
    constructor: function (params) {
      lang.mixin(this, params);
      if (!this.reportId) throw Error("Can't create segment without a report Id.");
    },
    postCreate: function () {
      this.inherited(arguments);
    },
    show: function () {
      return this.inherited(arguments);
    }
  });
});