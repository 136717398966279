define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dijit/form/Select", "dijit/MenuSeparator", "dojox/html/entities", "mojo/url", "mojo/api/segment", "dojo/text!./templates/segmentsDropdown.html", "mojo/widgets/OptGroupLabel", "mojo/widgets/input/_WithLabelMixin"], function (declare, lang, request, on, domConstruct, domClass, _Widget, _Templated, Select, MenuSeparator, entities, mUrl, segmentApi, tpl, OptGroupLabel, _WithLabelMixin) {
  var OptGroupSelect = declare([Select, _WithLabelMixin], {
    _fillContent: function () {
      this.inherited(arguments);

      // Drop a class on the dropdown widget so that option items indent correctly
      domClass.add(this.dropDown.domNode, "dijitSelectWithOptionGroup");
    },
    _getMenuItemForOption: function (option) {
      // Load a different template for option group labels
      if (option.optGroup) {
        return new OptGroupLabel({
          label: option.optGroupLabel,
          message: option.message
        });
      }
      return this.inherited(arguments);
    }
  });
  var SegmentDropdown = declare([_Widget, _Templated], {
    templateString: tpl,
    // If you want, something like "Choose one" to be the first option before the actual options, set this value.
    firstOption: null,
    nameAttribute: "segment_id",
    /**
     * Function that should be used to fetch segments. It not provided, sourceUrl will be used.
     */
    sourceFunction: null,
    sourceUrl: "/lists/segments/saved",
    createOption: false,
    createSegmentOptionText: "Create a new segment",
    // array to store segment options
    options: [],
    // for converting segment types to more user-facing names
    labels: {
      "advanced": "Advanced Segments",
      "saved_pro": "Advanced Segments",
      "saved": "Segments",
      "static": "Tags",
      "pasted": "Pasted",
      "fuzzy": "Fuzzy",
      "prebuilt": "Pre-built Segments",
      "contacts": "Contacts",
      "contact-status": "Contacts",
      "subscriber-engagement": "Subscriber Engagement",
      "customer-behavior": "Customer Behavior",
      "subscriber-profile": "Demographics"
    },
    /**
     * Load and populate this dropdown with segments for a given list
     *
     * @param {Number} listId the id of the list
     */
    load: function (listId) {
      var updateFunction = this._update.bind(this, listId);
      if (this.sourceFunction) {
        this.sourceFunction(listId).then(updateFunction);
      } else {
        segmentApi.getSegmentGroups(listId, this.sourceUrl).then(updateFunction);
      }
    },
    _getGroupLabel: function (type) {
      return this.labels[type] || type;
    },
    _update: function (listId, segmentGroups) {
      // reset options array
      this.options = [];
      if (this.firstOption) {
        this.options.push({
          "value": SegmentDropdown.OPTION_ENTIRE_LIST,
          "label": this.firstOption
        });
      }
      if (this.createOption) {
        this.options.push({
          "value": SegmentDropdown.OPTION_CUSTOM_SEGMENT,
          "label": this.createSegmentOptionText,
          "selected": this.selected === SegmentDropdown.OPTION_CUSTOM_SEGMENT
        });
      }
      var hasSegment = false;
      segmentGroups.forEach(function (segmentGroup) {
        // only add the option group if we have options to choose from
        if (segmentGroup.segments.length > 0 || segmentGroup.type === "static") {
          hasSegment = true;

          // Create opt group sections
          this.options.push({
            "value": "",
            "label": "",
            "message": segmentGroup.type === "static" && segmentGroup.segments.length === 0 ? "Use tags to label your customers and organize your list." : "",
            "selected": false,
            "optGroup": true,
            "optGroupLabel": this._getGroupLabel(segmentGroup.type)
          });
          segmentGroup.segments.forEach(function (segment) {
            var segmentName = entities.encode(segment.name);
            var key = segmentGroup.type + ":" + segment.segmentId;
            this.options.push({
              "value": key,
              "label": segmentName,
              "selected": this.selected === key
            });
          }.bind(this));
        }
      }.bind(this));

      // If nothing selected, default to first item
      if (!this.selected && !this.firstOption) {
        this.options[1].selected = true;
      }

      // Update segmentSelect dropdown options
      this.segmentSelect.set("options", this.options);
      this.segmentSelect.startup();
      if (!hasSegment) {
        this.createSegment.href = mUrl.toUrl("/lists/members", {
          id: listId,
          open_segment: 1
        });
        domClass.remove(this.noSegments, "hide");
        domClass.add(this.segmentSelectContainer, "hide");
      } else {
        domClass.remove(this.segmentSelectContainer, "hide");
        domClass.add(this.noSegments, "hide");
      }
    },
    postCreate: function () {
      if (this.listId) {
        this.segmentSelect = new OptGroupSelect({
          options: this.options,
          name: "segment_id",
          id: this.id + "_segmentSelect",
          maxHeight: -1,
          disabled: !!this.disabled,
          "class": "select-small !margin-bottom--lv0 full-width"
        }).placeAt(this.segmentSelectContainer);
        this.load(this.listId);
        on(this.segmentSelect, "change", lang.hitch(this, function (event) {
          if (this.onChange) {
            return this.onChange(event);
          }
        }));
      }
    }
  });
  SegmentDropdown.OPTION_CUSTOM_SEGMENT = "_CUSTOM_";
  SegmentDropdown.OPTION_ENTIRE_LIST = "";
  return SegmentDropdown;
});