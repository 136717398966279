define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_FocusMixin"], function (declare, domClass, _FocusMixin) {
  /**
   * Helpful mixin that implements the logic that toggles elements and classes.
   */
  return declare([_FocusMixin], {
    changeStateOnFocus: true,
    viewClass: "view-mode",
    editClass: "edit-mode",
    viewMode: function () {
      domClass.add(this.domNode, this.viewClass);
      domClass.remove(this.domNode, this.editClass);
      this.onViewMode();
    },
    editMode: function () {
      domClass.add(this.domNode, this.editClass);
      domClass.remove(this.domNode, this.viewClass);
      this.onEditMode();
    },
    _onFocus: function () {
      this.inherited(arguments);
      if (this.changeStateOnFocus) {
        this.editMode();
      }
    },
    _onBlur: function () {
      this.inherited(arguments);
      if (this.changeStateOnFocus) {
        this.viewMode();
      }
    },
    onViewMode: function () {},
    onEditMode: function () {}
  });
});