/**
 * Specialized select input box that can create another input based on its config. The created input will be used to
 * return the "Extra" attribute
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "./_SegmentInputMixin", "./Select", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/TooltipDialog", "dijit/popup", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context", "/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js"], function (declare, _WidgetBase, _SegmentInputMixin, Select, domConstruct, domAttr, dom, lang, TooltipDialog, popup, array, on, flags, I18nTranslation, context, dojoRequire) {
  var tooltipContent = "This feature helps you market to your contacts based on their purchasing behavior.";
  var inStoreText = "in store";
  return declare([_WidgetBase, _SegmentInputMixin], {
    ops: null,
    opsMap: null,
    configs: null,
    valueInput: null,
    postMixInProperties: function () {
      this.opsMap = {};
      this.options = [];

      // Add the options that the Select parent understands
      array.forEach(this.ops, lang.hitch(this, function (op) {
        this.opsMap[op.value] = op;
        var option = {
          "label": op.label,
          "value": op.value
        };
        this.options.push(option);
      }));
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        inStoreText = I18nTranslation.translate("audience_management_segment_builder_in_store");
        tooltipContent = I18nTranslation.translate("audience_management_segment_builder_tooltip");
      }
    },
    buildRendering: function () {
      this.domNode = domConstruct.create("div", {
        "class": "segment-extra-select-store"
      });
    },
    postCreate: function () {
      this.inherited(arguments);
      this.selectInput = new Select({
        "class": "condition-value",
        "options": this.options
      });
      this.selectInput.placeAt(this.domNode);
      this.setStoreCopySpan();
      this.renderInput();
      this.setToolTipSpan();
    },
    startup: function () {
      var toolTipSpan = dom.byId("tool-tip-span");
      if (toolTipSpan) {
        on(toolTipSpan, "click", function () {
          var tooltipDialog = new TooltipDialog({
            content: "<p class='help-tip-lines selfclear'>" + tooltipContent + "</p>",
            onMouseLeave: function () {
              popup.hide(tooltipDialog);
              tooltipDialog.destroy();
            }
          });
          popup.open({
            popup: tooltipDialog,
            around: toolTipSpan,
            orient: ["above-centered"]
          });
        });
      }
    },
    renderInput: function () {
      if (this.valueInput) {
        return;
      }
      var opConfig = this.opsMap[this.getValue()];
      var inputConfig = opConfig.input;
      var self = this;
      if (inputConfig) {
        // Avoids circular dependency.
        require(["mojo/segmentation/input/Creator"], function (InputCreator) {
          self.valueInput = InputCreator.create(self.configs, inputConfig);
          domConstruct.place(self.valueInput.domNode, self.domNode);
        });
      }
    },
    getValueHTML: function () {
      return this.valueInput ? this.valueInput.getDisplayHTML() : "";
    },
    getDisplayHTML: function () {
      return this.selectInput.getDisplayHTML() + " " + inStoreText + ": " + this.getValueHTML();
    },
    getValue: function () {
      return this.selectInput.getValue();
    },
    setStoreCopySpan: function () {
      var storeSpan = domConstruct.create("span", {
        innerHTML: "<p> " + inStoreText + ": </p>",
        id: "store-copy",
        "class": "store-copy"
      });
      domConstruct.place(storeSpan, this.domNode);
    },
    setToolTipSpan: function () {
      var toolTipSpan = domConstruct.create("span", {
        id: "tool-tip-span",
        "class": "freddicon info-circle"
      });
      domConstruct.place(toolTipSpan, this.domNode, "last");
    },
    setValue: function (value) {
      this.selectInput.setValue(value);
      this.renderInput();
    },
    setExtra: function (extraValue) {
      if (this.valueInput) {
        this.valueInput.setValue(extraValue);
      }
    },
    getExtra: function () {
      var extra = null;
      if (this.valueInput) {
        extra = this.valueInput.getValue();
      }
      return extra;
    },
    destroy: function () {
      if (this.selectInput) {
        this.selectInput.destroy();
      }
      if (this.valueInput) {
        this.valueInput.destroy();
      }
      this.inherited(arguments);
    }
  });
});