define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/form/Select", "mojo/analytics/parts/_Part", "dojo/text!./templates/CampaignSortDropdown.html", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/user", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, lang, domClass, on, _TemplatedMixin, Select, _Part, templateString, context, flags, I18nTranslation, user, EcsEvents, EcsTracking) {
  return declare([_Part, _TemplatedMixin], {
    templateString: templateString,
    translationsObject: null,
    sortByText: "Sort by",
    constructor: function () {
      I18nTranslation.initialize().then(function (translations) {
        this.translationsObject = translations;
        this.sortByText = this.translationsObject.campaigns_sort_by;
      }.bind(this));
    },
    canDisplay: function () {
      return true;
    },
    updateDropdown: function (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.dropdown.set("value", newValue);
      }
    },
    init: function () {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        var edited_text = this.translationsObject.campaigns_last_edited;
        this.dropdown = new Select({
          value: this.target.sort ? this.target.sort : "updated_at",
          options: [{
            label: edited_text,
            value: "updated_at"
          }, {
            label: this.translationsObject.campaigns_creation_date,
            value: "created_at"
          }]
        });
      } else {
        var fallback_edited_text = "Last edited";
        this.dropdown = new Select({
          value: this.target.sort ? this.target.sort : "updated_at",
          options: [{
            label: fallback_edited_text,
            value: "updated_at"
          }, {
            label: "Creation date",
            value: "created_at"
          }]
        });
      }
      this.dropdown.placeAt(this.domNode).startup();
      this.watch("sort", lang.hitch(this, function (name, oldValue, newValue) {
        this.updateDropdown(oldValue, newValue);
      }));
      this.dropdown.on("click", lang.hitch(this, function () {
        EcsTracking.track(EcsEvents.AllCampaignsSortByDropdownEvent);
      }));
      this.dropdown.on("change", lang.hitch(this, function (newValue) {
        if (newValue === "created_at") {
          EcsTracking.track(EcsEvents.AllCampaignsSortByCreationDateEvent);
        } else {
          EcsTracking.track(EcsEvents.AllCampaignsSortByLastUpdatedEvent);
        }
        this.target.updateSort(newValue);
      }));
    }
  });
});