define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "mojo/widgets/Folder"], function (declare, lang, _PartMixin, Folder) {
  return declare([Folder, _PartMixin], {
    init: function () {
      this.inherited(arguments);

      // Put clearing the filter to the end of the execution.
      setTimeout(lang.hitch(this, function () {
        var folderId = this.target.getFilter("folder");
        if (folderId) {
          // If it's not a known folder, clear it.
          if (this.getFolder(folderId)) {
            this.setSelectedFolder(this.target.getFilter("folder"), true);
          } else {
            this.target.clearFilter('folder');
          }
        }
      }), 0);
    },
    onSelect: function (folderId) {
      this.target.setFilter('folder', folderId);
    },
    onDeselect: function () {
      this.target.clearFilter('folder');
    },
    canDisplay: function () {
      return this.inherited(arguments) || this.target.hasFilter('folder');
    }
  });
});