define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/_confirm-automation.html", "dijit/registry", "mojo/url", "mojo/utils/string", "mojo/context", "mojo/lib/flags"], function (declare, lang, dom, domStyle, Renderable, tpl, registry, mUrl, stringUtils, context, flags) {
  return declare([Renderable], {
    templateString: tpl,
    campaignId: null,
    automationType: null,
    action: null,
    confirmButtonText: null,
    bodyAction: null,
    capitalize: stringUtils.capitalize,
    constructor: function (props) {
      this.state = {
        model: props.model
      };
      this.campaignId = props.model.campaignId;
      this.automationType = props.automationType;
      if (props.status === "paused") {
        this.action = "resume";
        this.confirmButtonText = "Resume";
      }
      if (props.status === "save") {
        this.action = "start";
        this.confirmButtonText = "Start Now";
      }
    },
    showConfirmAutomationDialog: function () {
      var confirmAutomationModal = registry.byId(this.confirmAutomationModal.id);
      confirmAutomationModal.show();
    },
    startConfirmed: function () {
      registry.byId(this.confirmAutomationModal.id).set("title", "Starting...");
      registry.byId(this.confirmAutomationModal.id).set("closable", false);
      if (this.automationType === "singleWelcome") {
        window.top.location.href = mUrl.toUrl("/automations/single-email-welcome/start", {
          "id": this.campaignId
        });
      } else {
        window.top.location.href = mUrl.toUrl("automations/finished-automation", {
          "id": this.campaignId
        });
      }
    }
  });
});