define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dijit/Dialog", "mojo/widgets/Renderable", "mojo/utils", "mojo/url", "dojo/text!./templates/campaign-name.html", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation"], function (declare, lang, query, request, Dialog, Renderable, utils, mUrl, tpl, context, flags, I18nTranslation) {
  return declare([Renderable], {
    templateString: tpl,
    validationMessage: "Your campaign name is too long.",
    editName: "Edit name",
    constructor: function (props) {
      this.state = {
        isActive: false,
        isDisabled: props.isDisabled ? props.isDisabled : false,
        isSaving: false,
        error: "",
        name: props.model.name || "",
        status: props.model.status,
        parentId: props.model.parentId,
        isSingleEmailWelcome: props.isSingleEmailWelcome,
        flagNuni: true,
        uniqueListId: props.model.uniqueListId,
        recipeId: props.model.recipeId
      };
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.editName = I18nTranslation.translate("landing_pages_edit_name");
      }
    },
    edit: function (e) {
      e.preventDefault();

      // If the widget is disabled on click of edit do nothing
      if (this.state.isDisabled) {
        return;
      }
      if (!this.state.isDisabled) {
        this.setState({
          isActive: true
        });
      }
      this.onActivate();
      window.ga("send", "event", "one_click_builder", "click", "edit_name");
    },
    cancel: function (e) {
      e.preventDefault();
      this.state.name = this.model.name;
      this.setState({
        "isActive": false,
        "error": ""
      });
      this.onDeactivate();
      window.ga("send", "event", "one_click_builder", "click", "edit_name:cancel");
    },
    validate: function (val) {
      if (utils.getSymbolLength(val) > 100) {
        return this.validationMessage;
      }
      return null;
    },
    updateValue: function (e) {
      var obj = {};
      obj[e.target.name] = e.target.value;
      obj.error = this.validate(e.target.value);
      this.preserveFocus(e);
      this.setState(obj);
    },
    shouldUpdate: function (key) {
      if (key === "name") {
        return false;
      }
      return this.inherited(arguments);
    },
    save: function (e) {
      e.preventDefault();
      this.setState({
        "isSaving": true
      });
      this.onSave({
        name: this.state.name
      }).then(lang.hitch(this, function () {
        this.setState({
          "isActive": false,
          "isSaving": false
        });
        this.onDeactivate();
      }), lang.hitch(this, function (err) {
        this.setState({
          "isSaving": false
        });
        this.onDeactivate();
      }));
      window.ga("send", "event", "one_click_builder", "click", "edit_name:save");
    },
    launchUseAdvancedSettingsModal: function (e) {
      e.preventDefault();
      if (this.state.isDisabled) {
        return;
      }
      var dialog = new Dialog({
        title: "Switch to advanced settings",
        "onHide": function () {
          dialog.destroyRecursive();
        },
        "onDownloadEnd": function () {
          query(".cancel-button", dialog.domNode).on("click", function (cancelClick) {
            cancelClick.stopPropagation();
            cancelClick.preventDefault();
            this.onclick = null; //prevent this function from getting called again with multiple clicks
            window.ga("send", "event", "one_click_builder", "click", "use_advanced_settings:cancel");
            dialog.hide();
          });
          query(".confirm-send-button", dialog.domNode).on("click", function () {
            this.disabled = true;
            window.ga("send", "event", "one_click_builder", "click", "switch_to_advanced_settings:use_advanced_settings");
            var form = document.getElementById("use-advanced-settings-form");
            var button = document.createElement('button');
            button.setAttribute('type', 'submit');
            form.appendChild(button);
            button.click();
          });
        }
      });
      dialog.setHref(mUrl.toUrl("automations/confirm-use-advanced-settings", {
        "parentId": this.state.parentId
      }));
      dialog.show();
    },
    createNewWelcomeCJ: function (e) {
      window.ga("send", "event", "one_click_builder", "click", "create_welcome_cj");
      var requestUrl = mUrl.toUrl('/prebuilt-journeys/create-workflow-with-recipe');
      var data = {
        "list_id": this.state.uniqueListId,
        "recipe_id": this.state.recipeId,
        "workflow_name": this.state.name,
        "settings": []
      };
      return request.post(requestUrl, {
        handleAs: "json",
        data: JSON.stringify(data)
      }).then(function (response) {
        document.location.href = mUrl.toUrl("customer-journey/builder", {
          "id": response.id
        });
      }, function (response) {
        utils.toast("Error: " + response.response.data.error);
      });
    }
  });
});