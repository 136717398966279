define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dijit/registry", "mojo/app/pro/segment", "mojo/widgets/VariateSelector", "mojo/widgets/VariateSelectorGroup", "mojo/widgets/SegmentDial", "mojo/app/wizard", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, base, lang, query, on, domClass, domStyle, aspect, number, registry, proSegment, Selector, SelectorGroup, SegmentDial, wizard, flags, context, I18nTranslation) {
  return declare([base], {
    maxVariation: 8,
    // Reference to the dial widget
    dial: null,
    // Reference to the SelectorGroup module
    selectorGroup: null,
    proSegmentId: null,
    showSegmentLoader: false,
    translatedTerms: {},
    listId: null,
    onReady: function () {
      I18nTranslation.initialize().then(function (value) {
        this.translatedTerms = value;
        this._handleSegmentLoading();
        this.dial = new SegmentDial({
          maxValue: this.maxVariation
        });
        this.dial.placeAt(this.$el.dial);
        this._createVariateSelectors();
        this._setupPartialControls();
        this._setupDelayControls();
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "campaign_detail",
          outreach_id: this.outreachId,
          outreach_type: this.type,
          login_id: this.loginId ? this.loginId : null,
          user_id: this.userId ? this.userId : null
        });
      }.bind(this));
    },
    /**
     * returns the current test percentage according to the input
     *
     * @returns {number} anywhere from 10-100
     */
    getTestPercent: function () {
      return +this.$el.testSizeInput.value;
    },
    /**
     * the total combinations user is currently testing
     * @returns {number}
     */
    getTotalVariants: function () {
      return +this.selectorGroup.getTotal();
    },
    /**
     * Initializes all the variate selectors
     */
    _createVariateSelectors: function () {
      var self = this;

      // Translations
      var subjectLine = this.translatedTerms["campaigns_wizard_variates_subject_line"] || "Subject line";
      var fromName = this.translatedTerms["campaigns_wizard_variates_from_name"] || "From name";
      var content = this.translatedTerms["campaigns_wizard_variates_content"] || "Content";
      var sendTime = this.translatedTerms["campaigns_wizard_variates_send_time"] || "Send time";
      this.subjectSelector = this._createVariateSelector(this.$el.numSubjectsInput, subjectLine);
      this.fromNameSelector = this._createVariateSelector(this.$el.numFromNameInput, fromName);
      this.contentSelector = this._createVariateSelector(this.$el.numContentInput, content);
      this.scheduleSelector = this._createVariateSelector(this.$el.numScheduleInput, sendTime);

      // Add each selector to the group
      this.selectorGroup = new SelectorGroup({
        "maxValue": this.maxVariation,
        "selectors": [this.subjectSelector, this.fromNameSelector, this.contentSelector, this.scheduleSelector]
      });
      aspect.after(this.selectorGroup, "onChange", function () {
        self._updateDialValue();
        self._updateSliderCombinations();
        self._updateRecipientCounts();
        self._toggleSendPerCombinationMsg();
      }, true);
      this._updateDialValue();
      this._updateSliderCombinations();
      aspect.after(this.scheduleSelector, "onChange", lang.hitch(this, "_handleScheduleSelection"));
      this._toggleTestElements();
      this._handleScheduleSelection();
    },
    /**
     * Updates the hidden input we use to determine the percent to test.
     *
     * @param percent (10-100)
     * @private
     */
    _updateTestPercent: function (percent) {
      if (percent < 10) percent = 10;
      this.$el.testSizeInput.value = percent;
      this._updateRecipientCounts();
      this._toggleSendPerCombinationMsg();
    },
    _updateDialValue: function () {
      var currentVal = this.getTotalVariants();
      this.dial.set("value", currentVal > 1 ? currentVal : 0);
      domClass.toggle(this.$el.maxCombinationMsg, "invisible", currentVal === 8 ? false : true);
    },
    _updateSliderCombinations: function () {
      this.$widget.testSizeSlider.set("numCombinations", this.getTotalVariants());
    },
    _setupDelayControls: function () {
      registry.byId('winner-select').on("change", lang.hitch(this, "_toggleDelayControls"));
      this._toggleDelayControls();
    },
    _toggleDelayControls: function () {
      var winnerOption = registry.byId('winner-select').value;
      switch (winnerOption) {
        case "manual":
          domClass.add(this.$el.waitTimeLabel, "hide");
          domClass.add(registry.byId("wait-time").domNode, "hide");
          domClass.add(this.$el.ecommMessage, "hide");
          domClass.remove(this.$el.manualMessage, "hide");
          break;
        case "total_revenue":
          domClass.remove(this.$el.waitTimeLabel, "hide");
          domClass.remove(registry.byId("wait-time").domNode, "hide");
          domClass.remove(this.$el.ecommMessage, "hide");
          domClass.add(this.$el.manualMessage, "hide");
          break;
        default:
          domClass.remove(this.$el.waitTimeLabel, "hide");
          domClass.remove(registry.byId("wait-time").domNode, "hide");
          domClass.add(this.$el.ecommMessage, "hide");
          domClass.add(this.$el.manualMessage, "hide");
      }
    },
    /**
     * Updates all the stats that are related to the recipients count.
     *
     * @private
     */
    _updateRecipientCounts: function () {
      var testPercent = this.getTestPercent();
      var testCount = Math.floor(this.sendCount * (testPercent / 100));
      this.countPerCombination = Math.floor(testCount / this.getTotalVariants());
      this.$el.countPerCombination.innerHTML = number.format(this.countPerCombination);
      var remainingCount = this.sendCount - testCount;
      this.$el.countRemaining.innerHTML = number.format(remainingCount);
      this.$el.testCountLabel.innerHTML = number.format(testCount);
      this.$el.winningListLabel.innerHTML = 100 - testPercent + "%";
      this.$el.testListLabel.innerHTML = testPercent + "%";
      this.$el.sliderValueLabel.innerHTML = testPercent + "%";
    },
    /**
     * Dim out the recommendation message when it reaches 4000 and then hide it when 5000 is reached.
     */
    _toggleSendPerCombinationMsg: function () {
      domStyle.set(this.$el.sendPerCombinationMsg, "opacity", this.countPerCombination < 4000 ? 1 : 0.6);
      domClass.toggle(this.$el.comboWarningContainer, "hide", this.countPerCombination >= 5000);
    },
    /**
     * Add event handling to toggle the controls to pick what portion of the list to send to.
     */
    _setupPartialControls: function () {
      var self = this;

      // Update the value on the slider
      this.$widget.testSizeSlider.set("value", self.getTestPercent());

      // behavior for percentage slider
      this.$widget.testSizeSlider.on("change", function () {
        self._updateTestPercent(self._sliderPercent());
        self._toggleTestElements();
      });
      self._updateRecipientCounts();
      self._toggleSendPerCombinationMsg();
    },
    /**
     * Return value of the slider widget. Use getTestPercent() for the actual percentage currently being tested on.
     */
    _sliderPercent: function () {
      return Math.round(this.$widget.testSizeSlider.value);
    },
    _toggleTestElements: function () {
      var percent = this._sliderPercent();
      percent == 100 ? this._onFullTest() : this._onPartialTest();
    },
    /**
     * Method that hides/show any element that react based on the fact that this is sending to a portion of recipients
     * @private
     */
    _onPartialTest: function () {
      this._toggleWinnerControls(true);
      this._toggleSends(true);
      this._updateTestPercent(this._sliderPercent());
    },
    /**
     * Function that hides/show any element that should react to the sending to the entire recipient.
     * @private
     */
    _onFullTest: function () {
      this._toggleSends(false);
      this._updateTestPercent(100);
      this._toggleWinnerControls(false);
    },
    /**
     * Show/Hide controls to choose partial list test
     *
     * @param {Boolean} show
     */
    _toggleWinnerControls: function (show) {
      domClass.toggle(this.$el.winnerOptionsContainer, "hide", !show);
    },
    /**
     * Show/Hide controls for send time message
     * @param {Boolean} show
     */
    _toggleSendTimeMsg: function (show) {
      domClass.toggle(this.$el.sendTimeMsg, "hide", !show);
    },
    /**
     * Show/Hide controls for Remaining Sends
     * @param {Boolean} show
     */
    _toggleSends: function (show) {
      domClass.toggle(this.$el.remainingContainer, "hide", !show);
      domClass.toggle(this.$el.testSizeContainer, "hide", !show);
      domClass.toggle(this.$el.winningListLabel, "hide", !show);
    },
    /**
     * Enable/Disable a user's ability to toggle the option to choose a portion of the list.
     * This is done when a user decides to test send time.
     */
    _handleScheduleSelection: function () {
      var isSelected = this.scheduleSelector.value > 0;
      this._toggleTestElements();
      this._toggleSendTimeMsg(isSelected);
      this._toggleTestSlider(!isSelected);
    },
    _toggleTestSlider: function (enabled) {
      if (enabled) {
        if (this._prevSliderValue) {
          this.$widget.testSizeSlider.set("value", this._prevSliderValue);
        }
        this.$widget.testSizeSlider.set("disabled", false);
      } else {
        this._prevSliderValue = this.$widget.testSizeSlider.value;
        this.$widget.testSizeSlider.set("value", 100);
        this.$widget.testSizeSlider.set("disabled", true);
      }
    },
    /**
     * Handles the creation of VariateSelectors
     *
     * @param {Dom} input
     * @param {String} label
     *
     * @returns {mojo/widgets/VariateSelector}
     */
    _createVariateSelector: function (input, label) {
      return new Selector({
        "label": label,
        "value": input.value ? parseInt(input.value, 10) : 0,
        "name": input.name,
        "maximum": this.maxVariation
      }, input);
    },
    /**
     * Ping the server until it gets a count for the segment and it will reload the page.
     * @private
     */
    _handleSegmentLoading: function () {
      var tries = 1;
      var self = this;
      var checkCount = function () {
        proSegment.getCachedCount(self.listId, self.proSegmentId).then(function (data) {
          if (data.count != null) {
            window.location.reload();
          } else {
            var timeout = 1000 * tries;
            if (timeout > 10000) timeout = 10000;
            setTimeout(checkCount, timeout);
            tries++;
          }
        });
      };
      if (this.showSegmentLoader) {
        query('.goToNextStepContainer').addClass('disabled');

        // Don't check too quickly to avoid a jarring experience.
        setTimeout(checkCount, 4000);
      }
    }
  });
});