/* eslint-disable default-case */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "./_Base", "dojo/text!./templates/SocialFollowEditor/editor.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/widgets/ItemList", "mojo/url", "mojo/neapolitan/editors/_DynamicContentMixin", "dijit/form/Select", "mojo/neapolitan/widgets/EditorContainer", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Widget, _Templated, _Base, tmpl, lang, query, on, domAttr, array, domConstruct, ItemList, mUrl, _DynamicContentMixin) {
  return declare([_Base, _DynamicContentMixin], {
    draggableListItem: true,
    type: "socialFollow",
    templateString: tmpl,
    constructor: function () {
      // Used in the editor's markup
      this.iconBaseUrl = mUrl.addProxy("http://cdn-images.mailchimp.com/icons/social-block-v2");
    },
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      var socialFollowDropdownOptions = [{
        value: "facebookFollow",
        label: "Facebook"
      }, {
        value: "twitterFollow",
        label: "X (formerly Twitter)"
      }, {
        value: "linkedInFollow",
        label: "LinkedIn"
      }, {
        value: "youTubeFollow",
        label: "YouTube"
      }, {
        value: "pinterestFollow",
        label: "Pinterest"
      }, {
        value: "tumblrFollow",
        label: "Tumblr"
      }, {
        value: "instagramFollow",
        label: "Instagram"
      }, {
        value: "snapchatFollow",
        label: "Snapchat"
      }, {
        value: "tikTokFollow",
        label: "TikTok"
      }, {
        value: "threadsFollow",
        label: "Threads"
      }, {
        value: "twitchFollow",
        label: "Twitch"
      }, {
        value: "whatsAppFollow",
        label: "WhatsApp"
      }, {
        value: "soundCloudFollow",
        label: "SoundCloud"
      }, {
        value: "githubFollow",
        label: "GitHub"
      }, {
        value: "vimeoFollow",
        label: "Vimeo"
      }, {
        value: "mediumFollow",
        label: "Medium"
      }, {
        value: "redditFollow",
        label: "Reddit"
      }, {
        value: "flickrFollow",
        label: "Flickr"
      }, {
        value: "dribbbleFollow",
        label: "Dribbble"
      }, {
        value: "spotifyFollow",
        label: "Spotify"
      }, {
        value: "houzzFollow",
        label: "Houzz"
      }, {
        value: "vkFollow",
        label: "VKontakte"
      }, {
        value: "rssFollow",
        label: "RSS"
      }, {
        value: "emailLink",
        label: "Email"
      }, {
        value: "genericLink",
        label: "Website"
      }];
      var currentItems = this.block.items;
      currentItems.forEach(function (item) {
        if (!array.some(socialFollowDropdownOptions, function (availableItemType) {
          return availableItemType.value === item.type;
        })) {
          // if item is vine...
          if (item.type === "vineFollow") {
            socialFollowDropdownOptions.push({
              value: item.type,
              label: "Vine"
            });
          }
          // if item is googlePlus...
          if (item.type === "googlePlusFollow") {
            socialFollowDropdownOptions.push({
              value: item.type,
              label: "Google Plus"
            });
          }
        }
      });
      this.itemList = new ItemList({
        showAddButton: true,
        addButtonText: "Add another service",
        items: this.block.items,
        maxItems: 10,
        draggableItems: this.draggableListItem,
        availableItemTypes: socialFollowDropdownOptions
      }).placeAt(this.itemList);
      this.connect(this.itemList, "onChange", "saveItems");
      this.connect(this.itemList, "onContentChange", "updateItems");
      this.updateItems();
      this.sync("display", this.displayInput, "value");
      this.watch("display", lang.hitch(this, function (name, old, value) {
        // Text display only has inline item arrangement, so let's force it here
        if (value === "text") {
          this.block.set("itemArrangement", "inline");
        }
        this._filterLayoutsDisplay();
      }));
      this.connect(this.displayInput, "onChange", function () {
        this.save();
      });
      this._filterLayoutsDisplay();
      this._highlightSelectedIconStyle();
      on(this.layoutSelector, "li:click", function () {
        self._selectLayout(this);
      });
      on(this.iconStyleSelector, ".iconStyle:click", function () {
        self._selectIconStyle(this);
      });
      on(this.iconTypeInput, "change", function () {
        self._selectIconType(this.value);
        self.block.set("iconType", this.value);
      });
      this._selectIconType(this.block.iconType);
      this.sync("iconType", this.iconTypeInput, "value");
      this.sync("align", this.alignInput, "value");
      this.sync("adjustableWidth", this.adjustableWidthInput, "value");
    },
    _selectIconType: function (type) {
      query(".iconType", this.domNode).style("display", "none");
      switch (type) {
        case "outline":
          query(this.outlineTypeContainer).style("display", "");
          break;
        default:
          query(this.solidTypeContainer).style("display", "");
          break;
      }
    },
    _filterLayoutsDisplay: function () {
      switch (this.block.display) {
        case "text":
          query(".textOnly", this.domNode).style("display", "block");
          query(".iconOnly", this.domNode).style("display", "none");
          query(".iconAndText", this.domNode).style("display", "none");
          query(".iconStyleContainer", this.domNode).style("display", "none");
          break;
        case "icon":
          query(".textOnly", this.domNode).style("display", "none");
          query(".iconOnly", this.domNode).style("display", "block");
          query(".iconAndText", this.domNode).style("display", "none");
          query(".iconStyleContainer", this.domNode).style("display", "block");
          break;
        case "both":
          query(".textOnly", this.domNode).style("display", "none");
          query(".iconOnly", this.domNode).style("display", "none");
          query(".iconAndText", this.domNode).style("display", "block");
          query(".iconStyleContainer", this.domNode).style("display", "block");
          break;
      }
      this._highlightSelectedLayout();
    },
    _highlightSelectedLayout: function () {
      query("li:selected", this.layoutSelector).removeClass("selected");
      var arrangement = this.block.itemArrangement;
      var layout = this.block.blockLayout;
      var layoutClassName = "." + arrangement + layout.charAt(0).toUpperCase() + layout.slice(1);
      query(layoutClassName, this.layoutSelector).addClass("selected");
    },
    _selectLayout: function (layoutIcon) {
      this.block.set("blockLayout", domAttr.get(layoutIcon, "data-blockLayout"));
      this.block.set("itemArrangement", domAttr.get(layoutIcon, "data-itemArrangement"));
      this._highlightSelectedLayout();
      this.save();
    },
    _highlightSelectedIconStyle: function () {
      query(".iconStyle:selected", this.iconStyleSelector).removeClass("selected");
      query(".iconStyle." + this.block.iconStyle, this.iconStyleSelector).addClass("selected");
    },
    _selectIconStyle: function (iconStyle) {
      this.block.set("iconStyle", domAttr.get(iconStyle, "data-iconStyle"));
      this._highlightSelectedIconStyle();
      this.save();
    },
    updateItems: function () {
      this.block.set("items", this.itemList.getOutputModel());
    },
    saveItems: function () {
      this.updateItems();
      this.save();
    },
    onDataUpdate: function (data) {
      this.itemList.reset(data.items);
    }
  });
});