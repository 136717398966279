/**
 * Meter Demo
 * @module mojo/widgets/pattern-library/MeterDemo.js
 *
 * Widget used to preview variants of the meter.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/MeterDemo.html", "dojo/text!./templates/Meter.html"], function (declare, Select, Renderable, tplStr, meterTpl) {
  /**
   * Rendering of a single Preview. Regularly destroyed on change of the variant input.
   */
  var Preview = declare([Renderable], {
    templateString: meterTpl,
    variantClass: null
  });

  /**
   * Primary widget responsible for rendering the Example, creating the variant dropdown, and generating each
   * Preview widget.
   *
   * This widget also demonstrates the flexibility of the UpgradeActionBlock and how it can be configured with
   * either a callback OR a dojo/topic.
   */
  return declare([Renderable], {
    templateString: tplStr,
    // Responsible for generating the type of variants and what classes they pass to the component.
    variantClassMap: {
      positive: "",
      warning: "meter--warning",
      error: "meter--error",
      neutral: "meter--neutral"
    },
    /**
     * Get the party started!
     */
    postCreate: function () {
      this._initVariantSelect();
    },
    /**
     * Create and place the Variant Select input.
     *
     * @private
     */
    _initVariantSelect: function () {
      this.variantInput = new Select({
        id: this.id + "_variantNode",
        // Unique ID for a11y
        onChange: this._handleAnyInputChange.bind(this),
        options: this._getSelectOptions(),
        "class": "!margin--lv0"
      });
      this.variantInput.placeAt(this.selectContainer);
      this.variantInput.startup();

      // Call once for the first load
      this._handleAnyInputChange();
    },
    /**
     * Embed the block on the page. Nothing special going on here, just instantiating the widget.
     *
     * @private
     */
    _initPreview: function () {
      this.preview = new Preview({
        variantClass: this.variantClassMap[this.variantInput.get("value")]
      });
      this.preview.placeAt(this.previewContainer);
      this.preview.startup();
    },
    /**
     * Loop through the variantClassMap and generate input options accordingly.
     *
     * @returns {array[]} - Collection of options with keys for label, value, and selected
     * @private
     */
    _getSelectOptions: function () {
      return Object.keys(this.variantClassMap).map(function (key, i) {
        return {
          label: key.charAt(0).toUpperCase() + key.slice(1),
          value: key,
          selected: i === 0
        };
      });
    },
    /**
     * Clear the current preview and start a new one.
     *
     * @private
     */
    _handleAnyInputChange: function () {
      // Reset existing preview if there is any.
      if (this.preview) {
        this.preview.destroy();
        this.preview = null;
      }
      this._initPreview();
    }
  });
});