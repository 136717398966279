define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/blockStyleEditor.html", "./StyleEditor", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "velocity/velocity"], function (declare, template, StyleEditor, domStyle, domClass, domConstruct, domAttr, utils, velocity) {
  return declare([StyleEditor], {
    templateString: template,
    type: '',
    kbLink: null,
    isOptional: true,
    widgetsInTemplate: true,
    postCreate: function () {
      this.inherited(arguments);
      this.connect(this.applyCheckbox, "onChange", "_applyToAll");
      this.connect(this.revertBtn, "click", "revert");
      if (this.kbLink) {
        domAttr.set(this.styleKbLink, 'href', this.kbLink);
        domClass.remove(this.styleKbLinkWrap, 'hide');
      }
    },
    _applyToAll: function () {
      this.onApplyToAll(domAttr.get(this.applyCheckbox, "checked"));
    },
    onApplyToAll: function (state) {},
    onChange: function () {
      domClass.remove(this.revertBtn, 'no-clicky');
      velocity(this.revertBtn, {
        opacity: 1
      }, {
        'duration': 200
      });
      utils.globalPublish("neapolitan/blocks/style/changed");
    },
    onRevert: function () {
      domClass.add(this.revertBtn, 'no-clicky');
      velocity(this.revertBtn, {
        opacity: 0
      }, {
        'duration': 200
      });
    }
  });
});