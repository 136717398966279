define(["dijit/Dialog", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo/text!./templates/CampaignUrlErrorDialog.html"], function (Dialog, declare, lang, _WidgetBase, _TemplatedMixin, on, domClass, xhr, mUrl, tpl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    campaignUrl: "",
    campaignId: "",
    saveAndExitUrl: mUrl.toUrl("campaigns"),
    continueButtonLabel: "Continue",
    cancelButtonLabel: "Save for later",
    saveInstructions: "You can choose to save your campaign as a draft and try again later, or continue to build your campaign now and send with this default campaign URL:",
    postCreate: function () {
      var dialog = new Dialog({
        title: "Campaign URL Error",
        content: this.domNode,
        closable: false
      });
      on(this.continueButton, "click", lang.hitch(this, function () {
        domClass.add(this.continueButton, "button-loading");
        this.disablePrettyUrl().then(function () {
          dialog.hide();
        });
      }));
      if (this.onCancel) {
        on(this.cancelButton, "click", lang.hitch(this, function (event) {
          this.onCancel(event);
          dialog.hide();
        }));
      }
      dialog.show();
    },
    disablePrettyUrl: function (e) {
      return xhr(mUrl.toUrl("campaigns/wizard/disable-pretty-url"), {
        query: {
          id: this.campaignId
        },
        handleAs: "json"
      });
    }
  });
});