/**
 * MarketingHero is used to display one large image with configuration avsilable for buttons and text.
 *
 * @return {object} - the instance of marketingHero
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/logger", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/MarketingHero.html"], function (declare, logger, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    content: tplStr,
    boldText: "",
    copyText: "",
    linkText: "",
    linkHref: "",
    imageSrc: "",
    eventLabel: "",
    position: "",
    textAlignment: "",
    postCreate: function () {
      // Track if the user is seeing the templates image
      if (this.buttonLabel.toLowerCase() === 'try the templates') {
        logger.googleAnalytics.trackEvent("recommendation block", "modal load", "grow audience with page templates", {
          nonInteraction: 1
        });
      }
      this.inherited(arguments);
    },
    cta: function (e) {
      var customDimensions = {
        dimension53: e.target.innerText,
        dimension54: this.destinationUrl,
        dimension55: "promo",
        dimension56: this.event
      };
      logger.googleAnalytics.trackEvent("front_door", "cta", this.buttonLabel, customDimensions);
    }
  });
});