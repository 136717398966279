define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, lang, base, domAttr, domClass, on, topic, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    deletedCampaignsRendered: null,
    deletedAutomationsRendered: null,
    onReady: function () {
      var self = this;
      this.parse(this.elId);
      var pageJs = this.pageJs;
      this.$widget.chooseDeletedCampaign.set("onChange", function () {
        if (this.value === "campaigns") {
          domClass.remove(self.$el.deletedCampaignsList, "hide");
          domClass.add(self.$el.deletedAutomationsList, "hide");
          self.$el.deletedTitle.innerHTML = "Deleted Campaigns";
        }
        if (this.value === "automations") {
          domClass.add(self.$el.deletedCampaignsList, "hide");
          domClass.remove(self.$el.deletedAutomationsList, "hide");
          self.$el.deletedTitle.innerHTML = "Deleted Automations";
        }
      });
      topic.subscribe("mojo/analytics/List/deleted-campaigns-list/render", lang.hitch(this, function (list) {
        this.parse(self.pageJs.tabContainer.deletedSection.id);
        if (this.$group.manageDeletedLinks) {
          this.$group.manageDeletedLinks.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageLinks(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (this.$group.viewDeletedCampaign) {
          this.$group.viewDeletedCampaign.forEach(function (el) {
            on(el, "click", function () {
              pageJs.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (!this.deletedCampaignsRendered) {
          pageJs.loadFilter("deleted");
          this.deletedCampaignsRendered = true;
        }
      }));
      topic.subscribe("mojo/analytics/List/deleted-automations-list/render", lang.hitch(this, function (list) {
        this.parse(self.pageJs.tabContainer.deletedSection.id);
        if (this.$group.manageDeletedAutomationLinks) {
          this.$group.manageDeletedAutomationLinks.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageLinks(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (this.$group.deletedAutomationsEmailTool) {
          this.$group.deletedAutomationsEmailTool.forEach(function (el) {
            registry.byId(el.id).set("onChange", function () {
              pageJs.openEmailTool(this);
            });
          });
        }
        if (this.$group.manageDeletedAutomationBounces) {
          this.$group.manageDeletedAutomationBounces.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageBounces(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (this.$group.manageDeletedAutomationLinks) {
          this.$group.manageDeletedAutomationLinks.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageLinks(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (this.$group.viewDeletedAutomation) {
          this.$group.viewDeletedAutomation.forEach(function (el) {
            on(el, "click", function () {
              pageJs.viewCampaignDetails(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (this.$group.manageDeletedAutomationBounces) {
          this.$group.manageDeletedAutomationBounces.forEach(function (el) {
            on(el, "click", function () {
              pageJs.manageBounces(domAttr.get(el, "data-campaign-id"));
            });
          });
        }
        if (!this.deletedAutomationsRendered) {
          pageJs.loadFilter("deleted-automation");
          this.deletedAutomationsRendered = true;
        }
      }));
    }
  });
});