define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl", "dojox/dtl/Context", "dojo/text!../editors/blocks/templates/ImageCardEditor/output.html", "dojo/text!../editors/blocks/templates/ImageCardEditor/_horizontal.html", "dojo/text!../editors/blocks/templates/ImageCardEditor/_vertical.html"], function (declare, _Renderer, array, domConstruct, domStyle, dtl, dtlContext, tpl, horizontalTpl, verticalTpl) {
  return declare("mojo.neapolitan.renderers.ImageCardBlock", [_Renderer], {
    outputTplStr: tpl,
    constructor: function () {
      this.horizontalTemplate = new dojox.dtl.Template(horizontalTpl);
      this.verticalTemplate = new dojox.dtl.Template(verticalTpl);
    },
    getOutputModel: function (meta) {
      var calculatedWidths = this._getWidths(meta);
      var caption = meta.caption;
      if (caption.image) {
        if (caption.image && caption.image.width > calculatedWidths.image) {
          caption.image.calculatedWidth = calculatedWidths.image;
        } else {
          caption.image.calculatedWidth = caption.image.width;
        }
      }
      var blockHtml = this._getCaptionTemplate(meta.captionPosition).render(new dtlContext({
        widths: calculatedWidths,
        align: meta.align,
        position: meta.captionPosition,
        caption: caption,
        container: meta.container,
        innerPadding: this.innerPadding,
        outerPadding: this.outerPadding,
        totalPadding: this.outerPadding * 2,
        firstPadding: "0 " + this.innerPadding + "px " + this.innerPadding + "px " + this.innerPadding + "px ",
        lastPadding: this.innerPadding + "px " + this.innerPadding + "px " + "0 " + this.innerPadding + "px ",
        singlePadding: "0 " + this.outerPadding + "px "
      }));
      return {
        blockHtml: blockHtml,
        totalPadding: this.outerPadding * 2,
        outerPadding: this.outerPadding
      };
    },
    _getCaptionTemplate: function (position) {
      switch (position) {
        case "left":
        case "right":
          return this.horizontalTemplate;
        case "top":
        case "bottom":
          return this.verticalTemplate;
        default:
          throw new Error("Unknown captionPosition");
      }
    },
    _getWidths: function (meta) {
      var wholeWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2);
      var imageWidth;
      var captionWidth;
      var imagePadding;
      var imageContainer;
      var calculatedInnerPadding = 0;
      var calculatedTextSidePadding = 0;
      var calculatedTextTopPadding = 0;
      switch (meta.captionPosition) {
        case "top":
        case "bottom":
          var width = Math.floor(wholeWidth - this.innerPadding * 4);
          imageWidth = captionWidth = width;
          calculatedInnerPadding = this.innerPadding;
          imagePadding = this.innerPadding * 2;
          if (meta.edgeToEdge === true) {
            //edge to edge
            captionWidth += this.innerPadding * 2;
            imageWidth += this.innerPadding * 4;
            imagePadding = 0;
            calculatedInnerPadding += this.innerPadding;
            calculatedTextSidePadding += this.innerPadding;
          }
          break;
        case "left":
        case "right":
          imagePadding = this.innerPadding * 2;
          switch (meta.captionWidth) {
            case "half":
              captionWidth = Math.floor((wholeWidth - this.innerPadding * 4) / 2);
              imageContainer = Math.floor(wholeWidth / 2);
              imageWidth = Math.floor(imageContainer - this.innerPadding * 2);
              break;
            case "one-third":
              captionWidth = Math.floor((wholeWidth - this.innerPadding * 2) / 3);
              imageContainer = Math.floor(wholeWidth - captionWidth - this.innerPadding * 2);
              imageWidth = Math.floor(imageContainer - this.innerPadding * 2);
              break;
            case "two-third":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2) / 3);
              captionWidth = Math.floor(wholeWidth - imageWidth - this.innerPadding * 4);
              imageContainer = Math.floor(imageWidth + this.innerPadding * 2);
              break;
            case "three-quarter":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 4);
              captionWidth = Math.floor(wholeWidth - imageWidth - this.innerPadding * 4);
              imageContainer = Math.floor(imageWidth + this.innerPadding * 2);
              break;
            default:
              throw new Error("Unknown captionWidth");
          }
          //edge to edge
          if (meta.edgeToEdge) {
            imageWidth += this.innerPadding * 2;
            imagePadding = 0;
            calculatedTextSidePadding += this.innerPadding * 2;
            calculatedTextTopPadding += this.innerPadding;
            calculatedInnerPadding += this.innerPadding;
          }
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      var borderOffset = this._getImageBorderOffset(meta);
      if (borderOffset < imageWidth) {
        imageWidth -= borderOffset;
      }
      return {
        edgeToEdge: meta.edgeToEdge,
        caption: captionWidth,
        container: imageContainer,
        image: imageWidth,
        imagePadding: imagePadding,
        calculatedInnerPadding: calculatedInnerPadding,
        calculatedTextSidePadding: calculatedTextSidePadding,
        calculatedTextTopPadding: calculatedTextTopPadding
      };
    },
    /**
     * Gets the total width of a border that we need to subtract from the image. This is required as of 10.5 since
     * the new basic templates that uses 2+ columns will stack if the width exceed the container.
     */
    _getImageBorderOffset: function (meta) {
      var offset = 0;
      if (meta.styles && meta.styles instanceof Array) {
        array.forEach(meta.styles, function (style) {
          if (style.name === "Card" && style.props && style.props.border) {
            var border = style.props.border;
            if (border && border.value) {
              // Use the browser to parse the css correctly.
              var tmpDom = domConstruct.create("div");
              domStyle.set(tmpDom, "border", border.value);
              var borderWidth = domStyle.get(tmpDom, "borderWidth");

              // Value for one-side, so double it.
              if (borderWidth) {
                offset = +borderWidth * 2;
              }
            }
          }
        });
      }
      return offset;
    }
  });
});