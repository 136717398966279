define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dijit/registry", "mojo/user", "mojo/utils", "mojo/utils/date", "mojo/utils/string", "mojo/lib/clipboard", "mojo/app/placards", "mojo/app/campaigns", "mojo/widgets/checklist-editor/PagePublish", "./Slat", "mojo/url", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "mojo/widgets/FeedbackBlock", "mojo/utils/I18nTranslation"], function (declare, domClass, domConstruct, on, lang, entities, registry, user, utils, dateUtils, stringUtils, clipboard, placards, campaigns, PagePublish, Slat, mUrl, context, flags, logger, FeedbackBlock, I18nTranslation) {
  return declare([Slat], {
    channelIcon: "c-channelIcon--page",
    statusContent: {
      "published": {
        label: "Published",
        badge: "success",
        landing_page_url: "<a title={pageUrl} href={pageUrl}>{pageUrl}</a>",
        description: "Published <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      },
      "unpublished": {
        label: "Unpublished",
        badge: "",
        description: "Edited <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      },
      "draft": {
        label: "Draft",
        badge: "",
        description: "Edited <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      }
    },
    actions: {
      "edit": {
        label: "Edit"
      },
      "view": {
        label: "View landing page"
      },
      "replicate": {
        label: "Replicate"
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
        }
      },
      "publish": {
        label: "Publish",
        onClick: function (event) {
          event.preventDefault();
          document.location.href = mUrl.toUrl("/landing-pages/publish", {
            "id": this.model.pageId,
            "__csrf_token": user.__csrfToken
          });
        }
      },
      "unpublish": {
        label: "Unpublish",
        onClick: function () {
          var self = this;
          placards.confirmUnpublishPage(this.model.pageId, this.model.title).then(function () {
            registry.byId("campaigns-list").render();
          }, function () {
            utils.toast('Unable to unpublish "' + self.model.title + '". Please try again in a few minutes.', "error");
          });
        }
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "copy_url": {
        label: "Copy URL",
        onClick: function () {
          this.copyUrl();
        }
      }
    },
    statusContentTranslated: {},
    actionsTranslated: {},
    listNamesTranslated: {},
    statsContentTranslated: {},
    tagName: {},
    _constructStats: function () {
      var baseStatsOrder = ["visits", "clicks"];
      var baseStatsContents = {
        "visits": {
          label: "Visits"
        },
        "clicks": {
          label: "Clicks"
        }
      };
      if (this.model.hasStore) {
        baseStatsOrder = baseStatsOrder.concat("revenue");
        baseStatsContents["revenue"] = {
          label: "Revenue"
        };
      }
      if (this.model.hasSignUpForm) {
        baseStatsOrder = baseStatsOrder.concat("signups");
        baseStatsContents["signups"] = {
          label: "Subscribes"
        };
      }
      this.statsOrder = baseStatsOrder;
      this.statsContent = baseStatsContents;
    },
    /**
     * @override
     *
     * @param {object} translationObject i18n object
     */
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "published": {
          label: translationObject.campaigns_published,
          badge: "success",
          landing_page_url: "<a title={pageUrl} href={pageUrl}>{pageUrl}</a>",
          description: I18nTranslation.translate("campaigns_published_description", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "unpublished": {
          label: translationObject.campaigns_unpublished,
          badge: "",
          description: I18nTranslation.translate("campaigns_edited_by_you", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "draft": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_edited_by_you", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        }
      };
      this.actionsTranslated = {
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        "view": {
          label: translationObject.campaigns_actions_view_landing_page
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
          }
        },
        "publish": {
          label: translationObject.campaigns_actions_publish,
          onClick: function (event) {
            event.preventDefault();
            document.location.href = mUrl.toUrl("/landing-pages/publish", {
              "id": this.model.pageId,
              "__csrf_token": user.__csrfToken
            });
          }
        },
        "unpublish": {
          label: translationObject.campaigns_actions_unpublish,
          onClick: function () {
            var self = this;
            placards.confirmUnpublishPage(this.model.pageId, this.model.title).then(function () {
              registry.byId("campaigns-list").render();
            }, function () {
              utils.toast('Unable to unpublish "' + self.model.title + '". Please try again in a few minutes.', "error");
            });
          }
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Landing pages");
            }
          }
        },
        "copy_url": {
          label: translationObject.campaigns_actions_copy_url,
          onClick: function () {
            this.copyUrl();
          }
        }
      };
      this.listNamesTranslated = {
        "store": {
          label: translationObject.campaigns_store_label
        },
        "audience": {
          label: translationObject.campaigns_audience_label
        }
      };
      this.statsContentTranslated = {
        "visits": {
          label: translationObject.campaigns_visits
        },
        "clicks": {
          label: translationObject.campaigns_clicks
        },
        "revenue": {
          label: translationObject.campaigns_revenue
        },
        "signups": {
          label: translationObject.campaigns_subscribes
        }
      };
      this.tagName = translationObject.campaigns_page_tag;
    },
    /**
     * @override Slat
     */
    postCreate: function () {
      this._constructStats();
      this.inherited(arguments);
      this.buildTagNames();
    },
    /**
     * @override Slat
     */
    _buildTypeName: function () {
      this.node_typeName.innerHTML = this.model.typeLabel;
    },
    /**
     * @override Slat
     */
    _buildTypeNameTranslated: function () {
      this.node_typeName.innerHTML = this.model.typeLabel;
    },
    /**
     * override
     */
    buildAdditionalMetaData: function () {
      if (this.statusContent[this.model.status]) {
        var urlTemplate = this.statusContent[this.model.status].landing_page_url;
        var template = this.statusContent[this.model.status].description;
        this.node_additionalMetaData_url.innerHTML = lang.replace(urlTemplate || "", {
          pageUrl: this.model.pageUrl
        });
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "pausedAt":
            case "startDate":
            case "endDate":
            case "publishedAt":
            case "updatedAt":
            case "canceledDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] || "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.statusContentTranslated[this.model.status]) {
        var urlTemplate = this.statusContentTranslated[this.model.status].landing_page_url;
        var template = this.statusContentTranslated[this.model.status].description;
        this.node_additionalMetaData_url.innerHTML = lang.replace(urlTemplate || "", {
          pageUrl: this.model.pageUrl
        });
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "lastUpdatedDate":
            case "pausedAt":
            case "startDate":
            case "endDate":
            case "publishedAt":
            case "updatedAt":
            case "canceledDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] || "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildListName: function () {
      var label;
      if (this.model.hasStore && this.model.storeName) {
        label = this.listNamesTranslated["store"].label + this.model.storeName;
      } else if (this.model.listName) {
        label = this.listNamesTranslated["audience"].label + this.model.listName;
      }
      this.node_listName.appendChild(utils.compileTemplate(label ? this.template_listName : "", {
        listName: label,
        segmentName: this.model.hasSegment && this.model.segmentDisplay ? this.model.segmentDisplay : "",
        snapshotDate: "",
        snapshotDateClass: "hide"
      }));
    },
    buildTagNames: function () {
      if (this.model.signupTags.length === 0) {
        return;
      }
      var label = domConstruct.toDom(this.tagName);
      var tagList = document.createElement("span");
      this.model.signupTags.forEach(function (tag, index) {
        var tagEl = document.createElement("span");
        tagEl.setAttribute("title", tag.tag_name);
        tagEl.innerText = stringUtils.truncate(tag.tag_name, 15, true);
        tagList.append(tagEl);
        if (index < this.model.signupTags.length - 1) {
          tagList.append(", ");
        }
      }.bind(this));
      domConstruct.place(label, this.node_tagNames);
      domConstruct.place(tagList, this.node_tagNames);
    },
    /**
     * Copy the url for this page to the clipboard
     */
    copyUrl: function () {
      clipboard.copy(this.model.pageUrl);
      utils.toast('The "' + this.model.title + '" URL was was copied to your clipboard. Go forth and share!');
    },
    /**
     * This function is currently unused while the AM Organize Squad works
     * to repair the backend logic that determines if there are unpublished updates
     */
    addUnpublishedEditsFeedback: function () {
      if (!this.model.hasUpdatesWhilePublished) {
        return;
      }
      var feedback = new FeedbackBlock({
        message: "You've made changes that have not been published. " + this._generateLinkHTML("Keep editing and publish", this.model.editUrl),
        type: "warning"
      });
      domClass.remove(feedback.domNode, "section");
      feedback.placeAt(this.node_additionalMetaData, "after");
    }
  });
});