define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/tip_single.html", "dojo/request", "mojo/url", "mojo/lib/logger"], function (declare, domClass, domAttr, on, lang, _WidgetBase, _TemplatedMixin, tpl, request, mUrl, mLogger) {
  var A_TAG_REGEX = /<a href="(.*)"(.*)>(.*)<\/a>/;
  var LINKS_TO_TRACK = [{
    searchText: 'customer re-engagement automation',
    eventAction: 'page load',
    eventLabel: 'customer reengagement automation'
  }, {
    searchText: 'google remarketing ads',
    eventAction: 'page load',
    eventLabel: 'google remarketing ads'
  }, {
    searchText: 'product recommendations',
    eventAction: 'page load',
    eventLabel: 'personalized product recommendations'
  }, {
    searchText: 'design your ad best practices',
    eventAction: 'page load',
    eventLabel: 'ad design best practices'
  }, {
    searchText: 'segemented campaigns target audience',
    eventAction: 'page section load',
    eventLabel: 'target audience with segmentation'
  }];
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    tip: {},
    width_class: "flex-auto",
    postCreate: function () {
      this.tipTextNode.innerHTML = this.tip.text;
      var hasLink = this.tip.text.match(A_TAG_REGEX);
      if (hasLink) {
        var textToSearch = this.tip.text.toLowerCase();
        LINKS_TO_TRACK.forEach(function (trackingData) {
          var searchRegex = trackingData.searchText.split(' ').join('(.*)');
          if (textToSearch.search(searchRegex) > -1) {
            mLogger.googleAnalytics.trackEvent('recommendation block', trackingData.eventAction, trackingData.eventLabel, {
              nonInteraction: 1
            });
          }
        });
      }
      var trackImpression = lang.hitch(this, function (e) {
        this._logEvent("impression");
      });
      var trackClick = lang.hitch(this, function (e) {
        var category = "Tips / " + this.placementName;
        var action = "Clicked";
        var label = this._sanitizeUrl(domAttr.get(e.target, "href"));
        this._logEvent("click");
        this._trackGAEvent(category, action, label);
      });
      trackImpression();
      on(this, "a:click", trackClick);
    },
    _logEvent: function (action) {
      mLogger.marketing.logData({
        "page": mLogger.stdPath(window.location.pathname),
        "action": "tip-" + action,
        "tip_id": this.tip.id,
        "tip_location": this.placementName
      });
    },
    _trackGAEvent: function (category, action, label) {
      window.ga("send", "event", category, action, label);
    },
    _sanitizeUrl: function (url) {
      var sanitizedUrl = url.replace(/.*?:\/\//g, "").split("?")[0];
      return sanitizedUrl;
    }
  });
});