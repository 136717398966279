define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/promotion/utils", "mojo/lib/logger", "mojo/views/_base"], function (declare, on, query, promotionUtils, logger, base) {
  return declare([base], {
    onReady: function () {
      var self = this;
      query("[data-lp-social-share]").forEach(function (el) {
        var logSocialType = el.dataset.lpSocialShare;
        var logPageId = el.dataset.lpId;
        on(el, "click", function (e) {
          logger.info("landing-pages", "Published Page Social Promotion", {
            "page_id": logPageId,
            "social_network": logSocialType,
            "log_classification": "sensitive"
          });
          return true;
        });
      });
      promotionUtils.setupButtonEvents();
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "reports",
          login_id: self.login_id,
          user_id: self.user_id
        });
      }
    }
  });
});