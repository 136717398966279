define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo/store/Cache", "dojo/store/util/QueryResults", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/form/FilteringSelect", "dojo/text!./templates/productPicker.html", "dojo/text!./templates/productResultItem.html", "dijit/Dialog", "mojo/url", "mojo/utils", "mojo/lib/flags", "mojo/user"], function (declare, lang, request, on, domClass, domAttr, aspect, Deferred, Memory, Cache, QueryResults, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, FilteringSelect, tpl, resultItemTpl, Dialog, mUrl, utils, flags, user) {
  var PICK_TYPE = {
    PRODUCT: "product",
    TOP_SELLERS: "topSellers"
  };

  /**
   * A custom memory store that is used to feed the filtering select the values.
   */
  var ProductStore = declare([Memory], {
    storeId: null,
    query: function (query) {
      var term = lang.isString(query) ? query : query.name.toString();
      var url = mUrl.toUrl("/ecommerce/products-search", {
        store_id: this.storeId,
        term: term
      });
      var req = request(url, {
        handleAs: "json"
      });

      // Let's return id, name, label which are used to power the FilteringSelect UI
      var products = req.then(function (results) {
        return results.map(function (product) {
          // Wrap http:// image URLs to prevent insecure content warnings
          product.image_url = mUrl.addProxy(product.image_url);
          product.productInfo = this.createProductInfo(product.price, product.sku);
          return {
            "product": product,
            "id": product.product_id,
            "name": utils.decodeHTMLEntities(product.title),
            "label": lang.replace(resultItemTpl, product)
          };
        }.bind(this));
      }.bind(this));
      products.total = products.then(function (results) {
        return results.length;
      });
      return QueryResults(products);
    },
    createProductInfo: function (price, sku) {
      if (sku) {
        return price + " &middot; SKU " + sku;
      }
      return price;
    }
  });
  var ProductPicker = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    stores: null,
    deferredInsert: null,
    dialogTitle: null,
    // A reference to the promise we create that fetches the stores from the controller.
    _readyPromise: null,
    // Keep a dojo store per ecommerce store.
    _dataStores: null,
    _pickType: null,
    postMixInProperties: function () {
      this._dataStores = {};
    },
    postCreate: function () {
      this._readyPromise = this._getStores().then(lang.hitch(this, function (data) {
        this.stores = data.stores;
        this._initInputs();
      }));
      this.own(on(this.cancelBtn, "click", lang.hitch(this, function () {
        this._hide();
      })), on(this.saveBtn, "click", lang.hitch(this, function () {
        switch (this._pickType) {
          case PICK_TYPE.PRODUCT:
            var store = this._getMemoryStore(this.storeSelect.value);
            var selectedId = this.productSearchInput.value;
            if (selectedId) {
              var productMeta = store.get(this.productSearchInput.value);
              this._productsChosen([productMeta.product]);
            }
            break;
          case PICK_TYPE.TOP_SELLERS:
            this._returnTopSellers();
            break;
          default:
            throw new Error("Unknown pick type:" + this._pickType);
        }
      })));
    },
    /**
     * Prompts the user to choose a product and returns a promise that will succeed when
     * a user chooses a product. If the user cancels out of it, the promise is rejected.
     *
     * @returns {dojo/Promise} - a dojo promise
     */
    chooseProduct: function () {
      this._pickType = PICK_TYPE.PRODUCT;
      this.deferredInsert = new Deferred();

      // Wait until we get back the stores before showing the dialog.
      this._readyPromise.then(lang.hitch(this, function () {
        this._show();
      }));
      return this.deferredInsert.promise;
    },
    /**
     * Prompts the user to choose a product and returns a promise that will succeed when
     * a user chooses a product. If the user cancels out of it, the promise is rejected.
     * This differs from chooseProduct() because it locks the user into the selected store.
     *
     * @param {bool} pStore - the store to pull products from
     *
     * @returns {dojo/Promise} - a dojo promise
     */
    chooseProductFromStore: function (pStore) {
      this._pickType = PICK_TYPE.PRODUCT;
      this.deferredInsert = new Deferred();

      // Wait until we get back the stores before showing the dialog.
      this._readyPromise.then(lang.hitch(this, function () {
        this._show();
        this.storeSelect.value = pStore;
        this.productSearchInput.store = null;
        this.productSearchInput.reset();
        this.productSearchInput.store = this._getMemoryStore(this.storeSelect.value);
        domClass.add(this.storeContainer, "hide");
      }));
      return this.deferredInsert.promise;
    },
    /**
     * Returns the top sellers for a given e-commerce store. Will prompt the user if they have
     * more than 1 store.
     *
     * @returns {dojo/Promise} - a dojo promise
     */
    chooseTopSellers: function () {
      this._pickType = PICK_TYPE.TOP_SELLERS;
      this.deferredInsert = new Deferred();
      this._readyPromise.then(lang.hitch(this, function () {
        if (this.stores.length > 1) {
          domClass.add(this.productSearchContainer, "hide");
          this.saveBtn.innerHTML = "Select";
          this.dialogTitle = "Select a store";
          this._show();
        } else if (this.stores.length === 1) {
          this._returnTopSellers();
        }
      }));
      return this.deferredInsert.promise;
    },
    /**
     * Returns the top sellers for a given e-commerce store.
     *
     * @param {object} pStore - A store
     * @returns {dojo/Promise} - a dojo promise
     */
    chooseTopSellersFromStore: function (pStore) {
      this._pickType = PICK_TYPE.TOP_SELLERS;
      this.deferredInsert = new Deferred();
      this._readyPromise.then(lang.hitch(this, function () {
        if (this.stores.length > 1) {
          this.storeSelect.value = pStore;
          this.productSearchInput.store = null;
          this.productSearchInput.reset();
          this.productSearchInput.store = this._getMemoryStore(this.storeSelect.value);
        }
        this._returnTopSellers();
      }));
      return this.deferredInsert.promise;
    },
    /**
     * Toggle the insert button based on the state of the product picker
     *
     * @private
     */
    _toggleInsertBtn: function () {
      switch (this._pickType) {
        case PICK_TYPE.PRODUCT:
          domAttr.set(this.saveBtn, "disabled", !this.productSearchInput.value);
          break;
        default:
          domAttr.remove(this.saveBtn, "disabled");
      }
    },
    /**
     * Helper that encapsulates all the behavior that happens when a product is chosen.
     *
     * @param {Array} products - a list of products
     * @private
     */
    _productsChosen: function (products) {
      this.deferredInsert.resolve(products);
      this._hide();
    },
    _initInputs: function () {
      this.stores.forEach(lang.hitch(this, function (store) {
        this.storeSelect.addOption({
          "value": store.store_id,
          "label": store.name
        });
      }));

      // Hide the store selector when it's only one store.
      if (this.stores.length === 1) {
        domClass.add(this.storeContainer, "hide");
      }
      this.productSearchInput = new FilteringSelect({
        autoComplete: false,
        queryExpr: "${0}",
        labelAttr: "label",
        labelType: "html",
        placeHolder: "Search by name",
        invalidMessage: "No product found.",
        required: false
      }, this.productSearchInput);
      this.productSearchInput.startup();
      this.productSearchInput.store = this._getMemoryStore(this.storeSelect.value);
      this.own(on(this.storeSelect, "change", lang.hitch(this, function () {
        this.productSearchInput.store = null;
        this.productSearchInput.reset();
        this.productSearchInput.store = this._getMemoryStore(this.storeSelect.value);
      })), on(this.productSearchInput, "change", lang.hitch(this, function () {
        this._toggleInsertBtn();
      })));
    },
    /**
     * Return the appropriate dojo store for a particular ecommerce store. (my head)
     *
     * @param {string} storeId - ID for an ecommerce store
     *
     * @returns {Memory} - a dojo/store that can retrieve products for a particular ecommerce store
     * @private
     */
    _getMemoryStore: function (storeId) {
      if (!this._dataStores[storeId]) {
        this._dataStores[storeId] = new Cache(new ProductStore({
          storeId: storeId
        }), new Memory());
      }
      return this._dataStores[storeId];
    },
    _getStores: function () {
      return request.get(mUrl.toUrl("/ecommerce/stores"), {
        handleAs: "json"
      });
    },
    /**
     * Fetch the top sellers on the selected store and return them to the promise
     *
     * @private
     */
    _returnTopSellers: function () {
      request.get(mUrl.toUrl("/ecommerce/products-search"), {
        query: {
          store_id: this.storeSelect.value,
          type: "topSellers"
        },
        handleAs: "json"
      }).then(lang.hitch(this, function (data) {
        this._productsChosen(data);
      }));
    },
    /**
     * Sets up a dijit/Dialog that uses the domNode of this widget as the content.
     *
     * @private
     */
    _show: function () {
      this.dialog = new Dialog({
        "title": "Select a product",
        "content": this.domNode,
        "style": "width:450px;",
        "class": "dijitDialog--fullScreen@phone"
      });

      // Destroy the module when the dialog closes.
      aspect.after(this.dialog, "hide", lang.hitch(this, function () {
        this.destroy();
      }));
      this.dialog.startup();
      this.dialog.show();
      this._toggleInsertBtn();
    },
    _hide: function () {
      if (this.dialog) {
        this.dialog.hide();
      } else {
        this.destroy();
      }
    },
    destroy: function () {
      this.inherited(arguments);
    }
  });
  var currentPicker = null;
  var getPicker = function () {
    if (currentPicker) {
      currentPicker._hide();
    }
    currentPicker = new ProductPicker();
    currentPicker.startup();
    return currentPicker;
  };
  return {
    chooseProduct: function () {
      return getPicker().chooseProduct();
    },
    chooseProductFromStore: function (pStore) {
      return getPicker().chooseProductFromStore(pStore);
    },
    chooseTopSellers: function () {
      return getPicker().chooseTopSellers();
    },
    chooseTopSellersFromStore: function (pStore) {
      return getPicker().chooseTopSellersFromStore(pStore);
    }
  };
});