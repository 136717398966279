define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Switch", "mojo/widgets/Renderable", "mojo/utils", "dojo/text!./templates/_postcard-preview.html"], function (declare, domClass, Switch, Renderable, utils, tpl) {
  return declare([Renderable], {
    isContentClipped: false,
    shouldShowClippingGuide: true,
    templateString: tpl,
    onReady: function () {},
    constructor: function (props) {
      if (!props.name) {
        throw new Error("Name prop is required but not found for PostcardPreview");
      }
      this.modalId = "previewModal" + props.name;
      this.modalTitle = props.name + " preview";
      if (window[this.modalId]) {
        window[this.modalId].destroyRecursive();
      }
    },
    postCreate: function () {
      this.watch("shouldShowClippingGuide", function (name, oldValue, value) {
        this.toggleClippingGuide(value);
      }.bind(this));
      this.toggleClippingGuide(this.shouldShowClippingGuide);
    },
    toggleClippingGuide: function (shouldShowGuide) {
      domClass.toggle(this.clippingOverlay, "hide", shouldShowGuide);
      domClass.toggle(this.clippingGuide, "hide", !shouldShowGuide);
      domClass.toggle(this.clippingGuideLegend, "dim-el", !shouldShowGuide);
    },
    _writePreviewContent: function (iframe, previewContent) {
      iframe.contentDocument.write(previewContent);
      iframe.contentDocument.close();
      this.isClipped = this.checkForClipping(iframe);
      this.onReady(this.isClipped);
    },
    _renderIframeWhenReady: function (iframe, previewContent) {
      // Sometimes the iframe's contentDocument is loaded synchronously,
      // but sometimes it's not and we have to wait for a load event to fire.
      if (iframe.contentDocument) {
        this._writePreviewContent(iframe, previewContent);
      } else {
        iframe.addEventListener("load", this._writePreviewContent.bind(this, iframe, previewContent));
      }
    },
    checkForClipping: function (iframe) {
      var previewContent = iframe.contentWindow.document.body.querySelector(".page-content");
      if (!previewContent) {
        return false;
      }
      var userContent = previewContent.querySelectorAll("[data-user-content]");
      var userContentHeight = [].reduce.call(userContent, function (acc, el) {
        return acc + el.scrollHeight;
      }, 0);
      var clippedUserContent = [].filter.call(userContent, function (el) {
        return el.offsetHeight < el.scrollHeight;
      });
      var availablePreviewSpace = previewContent.offsetHeight;
      var logo = previewContent.querySelector(".logo-container");
      if (logo) {
        availablePreviewSpace -= logo.offsetHeight;
      }
      return clippedUserContent.length > 0 || userContentHeight > availablePreviewSpace;
    },
    render: function () {
      this.inherited(arguments);
      if (this.previewContent) {
        this._renderIframeWhenReady(this.previewFrame, this.previewContent);
        this._renderIframeWhenReady(this.modalFrame, this.previewContent);
        var clippingGuideSwitch = new Switch({
          checked: this.shouldShowClippingGuide,
          description: "Show print guides",
          onChange: function (shouldShowClippingGuide) {
            this.set("shouldShowClippingGuide", shouldShowClippingGuide);
          }.bind(this)
        });
        clippingGuideSwitch.placeAt(this.clippingGuideSwitch);
        clippingGuideSwitch.startup();
      }
    }
  });
});