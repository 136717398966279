define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dijit/registry", "mojo/lib/flags", "mojo/url", "mojo/user", "mojo/widgets/campaigns/PlainTextDialog", "mojo/widgets/Previewer", "mojo/views/_base"], function (declare, lang, domStyle, domAttr, on, query, Dialog, registry, flags, mUrl, user, PlainTextDialog, Previewer, base) {
  return declare([base], {
    onReady: function () {
      var self = this;

      // Refresh the status bar whenever a checklist slat state is changed
      query("[data-checklist-slat]").forEach(function (e) {
        if (domAttr.get(e, "data-checklist-slat") !== "status-bar") {
          self.$widget.checklist.addOnSlatRenderedListener(domAttr.get(e, "data-checklist-slat"), function () {
            self.$widget.statusBar.getView("status-bar");
          });
        }
      });
      this._productTypeSelect();
    },
    _productTypeSelect: function () {
      on(window.document, on.selector("[data-mc-el='productType']", "attrmodified-value"), function (e) {
        var widget = registry.getEnclosingWidget(e.target);
        var newestProducts = query("[data-mc-el='newestProducts']")[0];
        var bestProducts = query("[data-mc-el='bestProducts']")[0];
        if (widget) {
          switch (widget.value) {
            case "best_selling":
              domStyle.set(bestProducts, "display", "block");
              domStyle.set(newestProducts, "display", "none");
              break;
            default:
              //show newestProducts
              domStyle.set(newestProducts, "display", "block");
              domStyle.set(bestProducts, "display", "none");
          }
        }
      });
    },
    openDomainVerification: function (dom) {
      var email = domAttr.get(dom, "data-email");
      var url = mUrl.toUrl("/account/verify-domain/", {
        "step": "add-new",
        "email": email,
        "cid": this.childId
      });
      if (!this.verificationDialog) {
        this.verificationDialog = new Dialog({
          "title": "Verify Domain",
          "style": "width:620px;",
          "refreshOnShow": "true",
          "id": "mc-module-dialog",
          "onHide": lang.hitch(this, function () {
            this.$widget.checklist.getView("from");
          })
        });
      }
      this.verificationDialog.setHref(url);
      this.verificationDialog.show();
    },
    previewContent: function () {
      if (!this.previewer) {
        this.previewer = new Previewer({
          "previewId": this.childId,
          "previewType": "campaign",
          "titleText": this.parentTitle,
          "showMergeInfo": true
        });
      }
      this.previewer.show();
    },
    editPlainText: function () {
      PlainTextDialog.show(this.childId);
    }
  });
});