define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/fontInput.html", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "mojo/neapolitan/style-keys", "mojo/widgets/input/FontFamily", "mojo/widgets/input/FontSize", "mojo/widgets/input/Color", "mojo/widgets/input/TextAlign", "mojo/widgets/input/LineHeight", "mojo/widgets/input/FontStyle", "mojo/widgets/input/LetterSpacing"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, domConstruct, domClass, lang, array, on, user, flags, styleKeys, FontFamily, FontSize, Color, TextAlign, LineHeight, FontStyle, LetterSpacing) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    selector: null,
    properties: null,
    isOptional: false,
    familyInput: null,
    sizeInput: null,
    colorInput: null,
    alignInput: null,
    lineHeightInput: null,
    spacingInput: null,
    styleInput: null,
    useExtendedWebFonts: false,
    postMixInProperties: function () {
      if (!this.properties) {
        this.properties = {};
      }
      this._commonInputProps = {
        isOptional: this.isOptional,
        selector: this.selector,
        subLabel: true
      };
    },
    postCreate: function () {
      if (this.properties.hasOwnProperty(styleKeys.FONT_FAMILY)) {
        this._addFamilyInput(this.properties[styleKeys.FONT_FAMILY]);
      }
      if (this.properties.hasOwnProperty(styleKeys.FONT_SIZE)) {
        this._addSizeInput(this.properties[styleKeys.FONT_SIZE]);
      }
      if (this.properties.hasOwnProperty(styleKeys.COLOR)) {
        var label = this.properties.hasOwnProperty("label") ? this.properties.label : null;
        this._addColorInput(this.properties[styleKeys.COLOR], label);
      }
      if (this.properties.hasOwnProperty(styleKeys.TEXT_ALIGN)) {
        this._addAlignInput(this.properties[styleKeys.TEXT_ALIGN]);
      }
      if (this.properties.hasOwnProperty(styleKeys.LINE_HEIGHT)) {
        this._addLineHeightInput(this.properties[styleKeys.LINE_HEIGHT]);
      }
      if (this.properties.hasOwnProperty(styleKeys.LETTER_SPACING)) {
        this._addSpacingInput(this.properties[styleKeys.LETTER_SPACING]);
      }
      if (this._hasStyleProperties()) {
        this._addFontStyleInput();
      }
    },
    updateValue: function (property, value) {
      switch (property) {
        case styleKeys.FONT_FAMILY:
          this.familyInput.set("value", value, false);
          break;
        case styleKeys.FONT_SIZE:
          this.sizeInput.set("value", value, false);
          break;
        case styleKeys.COLOR:
          this.colorInput.set("value", value, false);
          break;
        case styleKeys.TEXT_ALIGN:
          this.alignInput.set("value", value, false);
          break;
        case styleKeys.LINE_HEIGHT:
          this.lineHeightInput.set("value", value, false);
          break;
        case styleKeys.LETTER_SPACING:
          this.spacingInput.set("value", value, false);
          break;
        case styleKeys.FONT_WEIGHT:
        case styleKeys.FONT_STYLE:
        case styleKeys.TEXT_DECORATION:
          this.styleInput.updateValue(property, value);
          break;
        default:
      }
    },
    _hasStyleProperties: function () {
      return array.some(styleKeys.FONT_STYLES_PROPS, lang.hitch(this, function (prop) {
        return this.properties.hasOwnProperty(prop);
      }));
    },
    _addFamilyInput: function (value) {
      this.familyInput = new FontFamily(lang.mixin({
        value: value,
        label: "Font",
        webfont_enable: true,
        useExtendedWebFonts: this.useExtendedWebFonts
      }, this._commonInputProps));
      this.familyInput.startup();
      domConstruct.place(this.familyInput.inputContainerNode, this.familyContainer, "only");
      domClass.remove(this.familyContainer, "hide");
      this.own(on(this.familyInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.FONT_FAMILY, this.familyInput.value);
      })));
    },
    _addSizeInput: function (value) {
      this.sizeInput = new FontSize(lang.mixin({
        label: "Size",
        value: value
      }, this._commonInputProps));
      this.sizeInput.startup();
      domConstruct.place(this.sizeInput.inputContainerNode, this.sizeContainer, "only");
      domClass.remove(this.sizeColorContainer, "hide");
      domClass.remove(this.sizeContainer, "hide");
      this.own(on(this.sizeInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.FONT_SIZE, this.sizeInput.value);
      })));
    },
    _addColorInput: function (value, label) {
      this.colorInput = new Color(lang.mixin({
        value: value,
        label: label || "Color"
      }, this._commonInputProps));
      this.colorInput.startup();
      domConstruct.place(this.colorInput.inputContainerNode, this.colorContainer, "only");
      domClass.remove(this.sizeColorContainer, "hide");
      domClass.remove(this.colorContainer, "hide");
      this.own(on(this.colorInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.COLOR, this.colorInput.value);
      })));
    },
    _addFontStyleInput: function () {
      this.styleInput = new FontStyle(lang.mixin({
        label: "Styles",
        properties: this.properties
      }, this._commonInputProps));
      domConstruct.place(this.styleInput.inputContainerNode, this.styleContainer, "only");
      domClass.remove(this.styleAlignContainer, "hide");
      domClass.remove(this.styleContainer, "hide");
      this.own(on(this.styleInput, "change", lang.hitch(this, function (property, value) {
        this.onChange(property, value);
      })));
    },
    _addAlignInput: function (value) {
      this.alignInput = new TextAlign(lang.mixin({
        label: "Align",
        value: value
      }, this._commonInputProps));
      this.alignInput.startup();
      domConstruct.place(this.alignInput.inputContainerNode, this.alignContainer, "only");
      domClass.remove(this.styleAlignContainer, "hide");
      domClass.remove(this.alignContainer, "hide");
      this.own(on(this.alignInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.TEXT_ALIGN, this.alignInput.value);
      })));
    },
    _addLineHeightInput: function (value) {
      this.lineHeightInput = new LineHeight(lang.mixin({
        value: value
      }, this._commonInputProps));
      this.lineHeightInput.startup();
      domConstruct.place(this.lineHeightInput.inputContainerNode, this.lineHeightContainer, "only");
      domClass.remove(this.lineHeightSpacingContainer, "hide");
      domClass.remove(this.lineHeightContainer, "hide");
      this.own(on(this.lineHeightInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.LINE_HEIGHT, this.lineHeightInput.value);
      })));
    },
    _addSpacingInput: function (value) {
      this.spacingInput = new LetterSpacing(lang.mixin({
        label: "Spacing",
        value: value
      }, this._commonInputProps));
      this.spacingInput.startup();
      domConstruct.place(this.spacingInput.inputContainerNode, this.spacingContainer, "only");
      domClass.remove(this.lineHeightSpacingContainer, "hide");
      domClass.remove(this.spacingContainer, "hide");
      this.own(on(this.spacingInput, "change", lang.hitch(this, function () {
        this.onChange(styleKeys.LETTER_SPACING, this.spacingInput.value);
      })));
    },
    onChange: function (property, value) {}
  });
});