/* eslint-disable block-scoped-var */
/* eslint-disable consistent-this */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "d3/d3", "mojo/colors"], function (declare, query, dom, domAttr, domStyle, domConstruct, domGeom, on, Deferred, debounce, d3, mColors) {
  var D3ProReports = {};
  var dataColors = mColors.dataViz.combo1;
  D3ProReports.chart = declare([], {
    // param dataType: pass url as <String> to retrieve data or an array of data
    constructor: function (elementId, dataType, config) {
      var _self = this;
      /*
       * config object
       * metric: sends, opens, clicks, bounces, unsubs, abuse_reports
       * comparative: baseline, segment1, segment2, segment3, segment4 (always will be compared to a smoothed baseline)
       */
      if (!config) {
        this.config = {
          "metric": "opens",
          "comparative": "0"
        };
      } else {
        this.config = config;
      }
      this.elementId = elementId;
      this.chartContainer = d3.select("#" + this.elementId);
      this.margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 50
      };
      this.aspect_width = 16;
      this.aspect_height = 9;
      this.mobile_threshold = 500;

      // Colors
      this.chartColors = [dataColors.color1, dataColors.color2, dataColors.color3, dataColors.color4, dataColors.color5];

      // Format date for tooltip
      this.tooltipFormatDate = d3.time.format("%B %e, %Y");

      // Create SVG container for chart
      this.createSVGContainer(elementId);
      if (typeof dataType === "string") {
        // Assume is URL
        var dataProviderUrl = dataType;
        // Load data
        var loadingData = this.loadData(dataProviderUrl);
        loadingData.then(function (msg) {
          if (msg === "success") {
            // Once data is loaded, create chart components and make chart
            var metricData = _self.getMetricData(_self.config.metric);
            _self.makeChart(metricData);
          }
        });
      } else if (typeof dataType === "object") {
        // object with segment count and metric arrays
        _self.chartData = dataType.metrics;
        var metricData = _self.getMetricData(_self.config.metric);
        _self.makeChart(metricData);
      } else {
        console.error("Can't load data");
      }

      // Attach resize event
      /*
       * Note: onresize can be inefficient, using debounce for better performance
       */
      on(window, "resize", debounce(function (e) {
        _self.redraw();
      }, 500));
    },
    getMetricData: function (metric) {
      var _self = this;
      if (metric === "sends") {
        _self.metricName = "Sends";
        _self.metricBaselineKey = "sends";
        return _self.chartData.sends;
      } else if (metric === "opens") {
        _self.metricName = "Open Rate";
        _self.metricBaselineKey = "open_rate";
        return _self.chartData.opens;
      } else if (metric === "clicks") {
        _self.metricName = "Click Rate";
        _self.metricBaselineKey = "click_rate";
        return _self.chartData.clicks;
      } else if (metric === "bounces") {
        _self.metricName = "Bounces";
        _self.metricBaselineKey = "bounce_rate";
        return _self.chartData.bounces;
      } else if (metric === "unsubs") {
        _self.metricName = "Unsubscribes";
        _self.metricBaselineKey = "unsub_rate";
        return _self.chartData.unsubs;
      } else if (metric === "abuse_reports") {
        _self.metricName = "Abuse";
        _self.metricBaselineKey = "abuse_rate";
        return _self.chartData.abuse_reports;
      }
    },
    setMetricData: function (metric) {
      var _self = this;
      _self.config.metric = metric;
      _self.redraw();
    },
    createSVGContainer: function () {
      var containerPos = domGeom.position(dom.byId(this.elementId)),
        w = containerPos.w,
        h = containerPos.h;

      // Calculate width from container width and height based on aspect ratio
      this.width = w - this.margin.left - this.margin.right;
      this.height = Math.ceil(this.width * this.aspect_height / this.aspect_width) - this.margin.top - this.margin.bottom;
      this.chartContainer.attr("width", this.width);
      this.chartContainer.attr("height", this.height);

      // On smaller screens reduce the ticks for y-axis
      this.num_ticks_xaxis = 10;
      this.num_ticks_yaxis = 10;
      if (this.width < this.mobile_threshold) {
        this.num_ticks_xaxis = this.num_ticks_yaxis = 5;
      }
      this.svg = this.chartContainer.append("svg").attr("id", "svg-" + this.elementId).attr("width", this.width + this.margin.left + this.margin.right).attr("height", this.height + this.margin.top + this.margin.bottom).append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
    },
    redraw: function () {
      var _self = this;
      // Clear chart container so we can recreate
      domConstruct.empty(_self.elementId);
      _self.createSVGContainer();
      var metricData = _self.getMetricData(_self.config.metric);
      _self.makeChart(metricData);
    },
    createToolTipBehavior: function () {
      var _self = this;
      this.focus = this.svg.append("g").attr("class", "focus").style("display", "none");

      //Focus line Y axis
      this.focus.append("line").attr("class", "x").style("stroke", "#e0e0e0").style("stroke-width", "2").attr("y1", 5).attr("y2", this.height);

      // Focus dot
      this.focus.append("circle").attr("r", 3.5).attr("stroke", "#333").attr("fill", "none").attr("stroke-width", 3);

      // Overlay for tooltips
      var tooltip = d3.select("#tooltip-" + _self.elementId);
      this.svg.append("rect").attr("class", "d3-tt-overlay").attr("width", this.width).attr("height", this.height).on("mouseover", function () {
        _self.focus.style("display", null);
      }).on("mouseout", function () {
        tooltip.transition().duration(300).style("opacity", 0);
        _self.focus.style("display", "none");
      }).on("mousemove", function (a, b) {
        _self.mousemove(_self, this);
      });
    },
    loadData: function (dataProviderUrl) {
      var _self = this;
      var deferred = new Deferred();

      // Parse date with format from json
      var parseDate = d3.time.format("%Y-%m-%d %H:%M:%S").parse;
      d3.json(dataProviderUrl, function (error, data) {
        if (error) {
          console.error(error);
          deferred.reject("fail");
        }
        var metrics = data.metrics;
        // eslint-disable-next-line guard-for-in
        for (var metric in metrics) {
          metrics[metric].forEach(function (d) {
            d.send_time = parseDate(d.send_time);
          });
        }
        _self.chartData = data;
        var abuse_array = _self.chartData.abuse_reports;
        var bounces_array = _self.chartData.bounces;
        var clicks_array = _self.chartData.clicks;
        var opens_array = _self.chartData.opens;
        var sends_array = _self.chartData.sends;
        var unsubs_array = _self.chartData.unsubs;
        deferred.resolve("success");
      });
      return deferred.promise;
    },
    makeChart: function (data) {
      var _self = this;

      // Create tooltip div
      var tooltipDiv = domConstruct.place("<div class='d3-tooltip'>", dom.byId(_self.elementId), "first");
      domAttr.set(tooltipDiv, "id", "tooltip-" + _self.elementId);
      domStyle.set(tooltipDiv, "opacity", 0);

      // Set chart series
      _self.setChartSeries(data, _self.metricName);

      // Set X-Axis
      _self.setXAxis(data);

      // Set Y-Axis
      _self.setYAxis(data);

      // Set Baseline path
      _self.setBaselinePath(data);

      // Set comparative data points
      _self.setComparativeDataPoints(_self.metricValues, _self.config.comparative);
    },
    // Custom method to extract metric data and generate chart domain for y-axis in pro reports
    setChartSeries: function (data, metricName) {
      var _self = this;

      // TODO: it would be nice to set the keys with a function, that way we don't have to compute all keys. For example comparing only two charts.

      // Just get metric keys
      // i.e(Metric = open_rate --> open_rate, segment0_open_rate, segment1_open_rate)
      var keys = d3.keys(data[0]).filter(function (key) {
        switch (key) {
          case "send_time":
            break;
          case "name":
            break;
          case "send_rate":
            // TODO: eliminate this attr from JSON in the controller
            break;
          default:
            var p = new RegExp("_name");
            if (!p.test(key)) {
              return key;
            }
        }
      });

      // Create discrete scale and domain
      this.keysDomain = d3.scale.ordinal().range([_self.height, 0]).domain(keys);

      // Metric values
      this.metricValues = this.keysDomain.domain().map(function (name) {
        return {
          rate_label: name,
          rate_values: data.map(function (d) {
            // Number.parseFloat(string)
            // Part of the ECMAScript 2015 (ES6) standard but IE does not support, thanks IE
            // do it "old" school
            var v = parseFloat(d[name]);

            // WTF?????? why why whyyyyy
            if (d[name] === "undefined" || d[name] === 0 || d[name] === "0.0" || isNaN(d[name])) {
              v = "0.0";
            }
            return {
              send_time: d.send_time,
              value: v,
              name: d.name
            };
          })
        };
      });
    },
    setXAxis: function (data) {
      var _self = this;
      this.x = d3.time.scale().range([0, this.width]);
      var customTimeFormat = d3.time.format.multi([[".%L", function (d) {
        return d.getMilliseconds();
      }], [":%S", function (d) {
        return d.getSeconds();
      }], ["%I:%M", function (d) {
        return d.getMinutes();
      }], ["%I %p", function (d) {
        return d.getHours();
      }], ["%a %d", function (d) {
        return d.getDay() && d.getDate() !== 1;
      }], ["%b %d", function (d) {
        return d.getDate() !== 1;
      }], ["%b", function (d) {
        return d.getMonth();
      }], ["%Y", function () {
        return true;
      }]]);
      this.xAxis = d3.svg.axis().scale(_self.x).orient("bottom").tickFormat(customTimeFormat).ticks(_self.num_ticks_xaxis);

      // Set the range of values for the x-axis
      this.xDomain = _self.x.domain(d3.extent(data, function (d) {
        return d.send_time;
      }));

      // X-Axis
      this.svg.append("g").attr("class", "x axis").attr("transform", "translate(0," + this.height + ")").call(this.xAxis);
    },
    setYAxis: function (data) {
      var _self = this;
      var tickFormat = _self.percentageTick;
      if (_self.config.metric === "sends") {
        tickFormat = _self.numberTick;
      }
      this.y = d3.scale.linear().range([this.height, 0]);
      this.yAxis = d3.svg.axis().scale(_self.y)
      //.tickSize(0)
      .tickSize(-this.width, 0, 0) // make ticks wide so we can draw axis lines
      .tickFormat(tickFormat).tickPadding(10).ticks(_self.num_ticks_yaxis).orient("left");

      // Single series y-axis domain
      //y.domain(d3.extent(data, function(d) { return d.open_rate; }));

      // Multi series y-axis domain
      var min = d3.min(this.metricValues, function (oor) {
        return d3.min(oor.rate_values, function (v) {
          return v.value;
        });
      });
      var max = d3.max(this.metricValues, function (oor) {
        return d3.max(oor.rate_values, function (v) {
          return v.value;
        });
      });

      // TODO: data controller, can we enforce not strings???
      // Create a max range if all is zero
      if (max === 0 || max === "0.0") {
        max = 1;
      }
      this.yDomain = _self.y.domain([Number(min), Number(max)]);

      // Y-Axis
      this.svg.append("g").attr("class", "y axis").call(this.yAxis);
    },
    setBaselinePath: function (data, tension) {
      // In case we want to add tension to the baseline in order to make it smoother
      var t = tension;
      if (!tension) {
        t = 0.85;
      }
      var _self = this;
      // Baseline svg path
      var baselineLine = d3.svg.line().interpolate("bundle").tension(t) // basis, or bundle (bundle uses tension to smooth the line)
      .x(function (d) {
        return _self.x(d.send_time);
      }).y(function (d) {
        if (d.hasOwnProperty(_self.metricBaselineKey)) {
          return _self.y(Number(d[_self.metricBaselineKey]));
        }
      }); //TODO: define metric series somewhere else, via function?

      var path = this.svg.append("path")
      //.datum(data)
      .attr("class", "baseline").attr("stroke", this.chartColors[0]).attr("d", baselineLine(data));
      var pathLength = path.node().getTotalLength();

      // init path before animation
      path.attr("stroke-dasharray", pathLength + " " + pathLength).attr("stroke-dashoffset", pathLength);

      // wait a bit and animate baseline path
      setTimeout(function () {
        path.transition().duration(1000).ease("linear").attr("stroke-dashoffset", 0);
      }, 500);
    },
    // @params data: metricValues, index correspond to baseline, segment1, segment2..... of any given metric: open_rate, click_rate....
    // @params dataSetIndex: corresponds to what set are we comparing to: baseline = 0, segment1 = 1, segment2 = 2...
    setComparativeDataPoints: function (data, dataSetIndex) {
      var _self = this;
      var strokeWidth = 3,
        r = 3.5,
        dotFill = "#FFF";
      // Make data points smaller for mobile view
      if (_self.width < _self.mobile_threshold) {
        strokeWidth = 1.5;
        r = 3;
      }

      // Set datasetindex so we can access it from tooltip
      this.dataSetIndex = dataSetIndex;

      // wait a bit so we can start transitions for data points
      setTimeout(function () {
        // Dots for segments
        if (data[dataSetIndex]) {
          _self.svg.selectAll("dot").data(data[dataSetIndex].rate_values).enter().append("circle").attr("r", 1e-6).style("opacity", 1e-6).attr("cx", function (d) {
            return _self.x(d.send_time);
          }).attr("cy", function (d) {
            if (d.value) {
              return _self.y(d.value);
            }
          }).attr("stroke-width", strokeWidth).attr("fill", dotFill).attr("stroke", _self.chartColors[dataSetIndex]).transition().delay(function (d, i) {
            return i * 20;
          }).duration(200).attr("r", r).style("opacity", 1);

          // Create tooltip
          _self.createToolTipBehavior();
        }
      }, 1000);
    },
    // On MouseMove
    mousemove: function (a, b) {
      var _self = this,
        overlay = b,
        midpointOverlay = domAttr.get(overlay, "width") / 2,
        xMouseCoord = d3.mouse(overlay)[0];
      var x0 = _self.x.invert(d3.mouse(overlay)[0]),
        i = this.bisectDate(this.metricValues[this.dataSetIndex].rate_values, x0, 1),
        d0 = this.metricValues[this.dataSetIndex].rate_values[i - 1],
        d1 = this.metricValues[this.dataSetIndex].rate_values[i];
      if (d1 !== undefined) {
        // last element in graph
        var d = x0 - d0.send_time > d1.send_time - x0 ? d1 : d0;
        if (d.value) {
          // Focus group (dot + Y axis line) position
          //focus.attr("transform", "translate(" + x(d.send_time) + "," + y(d.open_rate) + ")");
          this.focus.select("circle").attr("transform", "translate(" + _self.x(d.send_time) + "," + _self.y(d.value) + ")");
          this.focus.select(".x").attr("transform", "translate(" + _self.x(d.send_time) + "," + _self.y(d.value) + ")").attr("y2", _self.height - _self.y(d.value));

          // Tooltip
          var tooltip = d3.select("#tooltip-" + _self.elementId);
          var v = _self.percentageTick(d.value);
          if (_self.config.metric === "sends") {
            v = d3.format(",")(d.value);
          }
          tooltip.html("<span class='d3-tooltip-text'>" + d.name + "</span>" + "<br>" + "<span>" + this.metricName + ": " + "<span class='fwb'>" + v + "</span>" + "</span>" + "<br>" + "<span>" + this.tooltipFormatDate(d.send_time) + "</span>");

          // Swap tootltip y-axis position based on mouse x coordinate
          var tooltipBox = tooltip.node().getBoundingClientRect();
          if (xMouseCoord < midpointOverlay) {
            var x = this.x(d.send_time) + 60;
            var y = this.y(d.value);
            tooltip.style("transform", "translate(" + x + "px," + y + "px)");
          } else {
            var x = this.x(d.send_time) - tooltipBox.width + 40;
            var y = this.y(d.value);
            tooltip.style("transform", "translate(" + x + "px," + y + "px)");
          }
          tooltip.transition().duration(300).style("opacity", 0.9);
        }
      }
    },
    // Add percentage to y-axis tick
    percentageTick: function (d) {
      return d + "%";
    },
    // Add percentage to y-axis tick
    numberTick: function (d) {
      return d3.format("s")(d);
    },
    // Bisect date
    bisectDate: d3.bisector(function (d) {
      return d.send_time;
    }).left
  });
  D3ProReports.utils = {
    /* @param: dataProviderUrl
     * Utility function to load data via URL as a promise.
     *  Returns an object with promise status (success, fail). If successful data is available via data attribute of the returned object.
     *  This function should be helpful to load data beforehand and pass the necessary data to chart constructors to generate different charts with same set of data,
     *  eliminating the need to do several AJAX requests
     */
    loadData: function (dataProviderUrl) {
      var deferred = new Deferred();

      // Parse date with format from json
      var parseDate = d3.time.format("%Y-%m-%d %H:%M:%S").parse;
      d3.json(dataProviderUrl, function (error, data) {
        if (error) {
          console.error(error);
          deferred.reject({
            "promise": "fail"
          });
        }
        var metrics = data.metrics;
        // eslint-disable-next-line guard-for-in
        for (var metric in metrics) {
          metrics[metric].forEach(function (d) {
            d.send_time = parseDate(d.send_time);
          });
        }
        deferred.resolve({
          "promise": "success",
          data: data
        });
      });
      return deferred.promise;
    }
  };
  return D3ProReports;
});