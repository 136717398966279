define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/logger"], function (declare, user, logger) {
  return declare([], {
    baseProperties: {
      org: "sbseg",
      purpose: "prod",
      scope: "mc",
      initiative_name: "human_assistance",
      scope_area: "support",
      ui_object: "button",
      event_origin: "dojo"
    },
    eventProperties: {},
    constructor: function (eventType, additionalProperties) {
      this.eventType = eventType;
      this.eventProperties = Object.assign(additionalProperties, this.baseProperties);
    },
    publishEvent: function () {
      logger.eventsPipeline.publish(this.eventType, this.eventProperties);
    }
  });
});