define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/activity-feed.html",
// Widgets
"mojo/widgets/NoDataBlock", "mojo/widgets/contact-profile/activity-feed/ConversationFeedItem", "mojo/widgets/contact-profile/activity-feed/EcommFeedItem", "mojo/widgets/contact-profile/activity-feed/EmailFeedItem", "mojo/widgets/contact-profile/activity-feed/NoteFeedItem", "mojo/widgets/contact-profile/activity-feed/SubUnsubFeedItem", "mojo/widgets/contact-profile/activity-feed/MarketingPermissionFeedItem", "mojo/widgets/contact-profile/activity-feed/PostcardSentFeedItem", "mojo/widgets/contact-profile/activity-feed/LandingPageSignupFeedItem", "mojo/widgets/contact-profile/activity-feed/SquatterSignUpFeedItem", "mojo/widgets/contact-profile/activity-feed/WebsiteSignUpFeedItem", "mojo/widgets/contact-profile/activity-feed/EcommerceSignupFeedItem", "mojo/widgets/contact-profile/activity-feed/GenericSignupFeedItem", "mojo/widgets/contact-profile/activity-feed/EventFeedItem", "mojo/widgets/contact-profile/activity-feed/SurveyResponseFeedItem", "mojo/widgets/contact-profile/activity-feed/InboxThreadOutboundFeedItem", "mojo/widgets/contact-profile/activity-feed/InboxThreadInboundFeedItem", "mojo/app/lists/profile/EventsInfoBlock",
// API
"./Api"], function (declare, lang, on, domConstruct, domClass, xhr, topic, user, flags, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr, NoDataBlock, ConversationFeedItem, EcommFeedItem, EmailFeedItem, NoteFeedItem, SubUnsubFeedItem, MarketingPermissionFeedItem, PostcardSentFeedItem, LandingPageSignupFeedItem, SquatterSignUpFeedItem, WebsiteSignUpFeedItem, EcommerceSignupFeedItem, GenericSignupFeedItem, EventFeedItem, SurveyResponseFeedItem, InboxThreadOutboundFeedItem, InboxThreadInboundFeedItem, EventsInfoBlock, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    memberID: null,
    activityFeed: null,
    slice: null,
    size: null,
    activities: null,
    next_slice: null,
    isArchived: false,
    /**
     * The containing element for the info block
     *
     * @type DOMNode
     */
    eventsInfoBlockContainer: null,
    /**
     * Initialize data and set initial slice parameters
     */
    postCreate: function () {
      this.slice = 0;
      this.size = 15;
      this.activities = [];
      this.inherited(arguments);
      this.installEventsCriteriaInfoBlock();
      this.structureTypeFilter();
      this.getActivityFeed();
      this.handleNoteCreation();
    },
    /**
     * Attach event handlers
     */
    startup: function () {
      on(this.showMoreActivitiesButton, "click", lang.hitch(this, "showMoreActivities"));
    },
    /**
     * Populate the feed with widgets
     */
    populateFeed: function () {
      var self = this;
      this.handleEmptyState();
      this.handleViewMoreButton();

      //outer loop for date blocks
      this.activityFeed.forEach(function (dateEntry, index) {
        if (dateEntry.date && dateEntry.date.length > 0) {
          if (index > 0) {
            domConstruct.place("</ul>", self.feedListContainer);
          }
          var dateHeader = "<h4 class='!margin-bottom--lv2 !padding-top--lv4'>" + dateEntry.date + "</h4>";
          domConstruct.place(dateHeader, self.feedListContainer);
          domConstruct.place("<ul class='feed-date-entry' id='" + "date-" + index + "'>", self.feedListContainer);
        }

        //inner loop for activity blocks
        dateEntry.feed.forEach(function (feedItem) {
          switch (feedItem.activity_type) {
            case "conversation":
              feedItem.label = "Conversation";
              self.createConversationFeedItem(feedItem, index);
              break;
            case "order":
              feedItem.label = "Purchased";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/purchase.svg";
              self.createEcommFeedItem(feedItem, index);
              break;
            case "sent":
              feedItem.label = "Was Sent";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createEmailFeedItem(feedItem, index);
              break;
            case "open":
              feedItem.label = "Opened";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/open.svg";
              self.createEmailFeedItem(feedItem, index);
              break;
            case "click":
              feedItem.label = "Clicked";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/click.svg";
              self.createEmailFeedItem(feedItem, index);
              break;
            case "bounce":
              if (feedItem.bounce_type === "hard") {
                feedItem.label = "Hard bounced";
              } else if (feedItem.bounce_type === "soft") {
                feedItem.label = "Soft bounced";
              } else {
                feedItem.label = "Bounced";
              }
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/bounce.svg";
              self.createEmailFeedItem(feedItem, index);
              break;
            case "note":
              feedItem.label = feedItem.created_by + " wrote a note:";
              feedItem.memberID = self.memberID;
              feedItem.refreshFeed = lang.hitch(self, "resetFeed");
              self.createNoteFeedItem(feedItem, index);
              break;
            case "subscribe":
              feedItem.label = "Subscribed";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/sub.svg";
              self.createSubUnsubFeedItem(feedItem, index);
              break;
            case "unsub":
              feedItem.label = "Unsubscribed";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/unsub.svg";
              self.createSubUnsubFeedItem(feedItem, index);
              break;
            case "marketing_permission":
              // TODO: Replace icons
              if (feedItem.marketing_permission_opted_in === "Y") {
                feedItem.label = "Opted-in";
                feedItem.preposition = "to";
                feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/sub.svg";
              } else {
                feedItem.label = "Opted-out";
                feedItem.preposition = "of";
                feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/unsub.svg";
              }
              self.createMarketingPermissionFeedItem(feedItem, index);
              break;
            case "postcard_sent":
              feedItem.label = "Was sent";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createPostcardSentFeedItem(feedItem, index);
              break;
            case "squatter_signup":
              feedItem.label = "Website Signup";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createSquatterSignUpFeedItem(feedItem, index);
              break;
            case "website_signup":
              feedItem.label = "Website Signup";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createWebsiteSignUpFeedItem(feedItem, index);
              break;
            case "landing_page_signup":
              feedItem.label = "Landing Page Signup";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createLandingPageSignupFeedItem(feedItem, index);
              break;
            case "ecommerce_signup":
              feedItem.label = "Ecommerce Page Signup";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createEcommerceSignupFeedItem(feedItem, index);
              break;
            case "generic_signup":
              feedItem.label = "Generic Page Signup";
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.createGenericSignupFeedItem(feedItem, index);
              break;
            case "event":
              feedItem.label = feedItem.event_name;
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/event.svg";
              self.placeFeedItem(new EventFeedItem({
                "data": feedItem
              }), index);
              break;
            case "survey_response":
              feedItem.surveyTitle = feedItem.survey_title;
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              self.placeFeedItem(new SurveyResponseFeedItem({
                "data": feedItem
              }), index);
              break;
            case "inbox_thread":
              feedItem.threadSubject = feedItem.thread_subject;
              feedItem.icon = "https://cdn-images.mailchimp.com/product/contact-profile/send.svg";
              if (feedItem.thread_direction === "INBOUND") {
                self.placeFeedItem(new InboxThreadInboundFeedItem({
                  "data": feedItem
                }), index);
              } else {
                self.placeFeedItem(new InboxThreadOutboundFeedItem({
                  "data": feedItem
                }), index);
              }
              break;
            default:
              //console.log("Unknown feedItem type");
              break;
          }
        });
      });
    },
    /**
     * Display empty state illustration if the feed contains 0 items
     */
    handleEmptyState: function () {
      if (this.activityFeed.length > 0) {
        domClass.add(this.emptyBlock, "hide");
      } else {
        domConstruct.empty(this.feedListContainer);
        domClass.remove(this.emptyBlock, "hide");
      }
    },
    /**
     * Add show more button if there are extra activities
     */
    handleViewMoreButton: function () {
      if (this.next_slice) {
        domClass.remove(this.showMoreActivitiesButton, "hide");
      } else {
        domClass.add(this.showMoreActivitiesButton, "hide");
      }
    },
    /**
     * Construct and place a ConversationFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createConversationFeedItem: function (feedItem, index) {
      var item = new ConversationFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place an EcommFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createEcommFeedItem: function (feedItem, index) {
      var item = new EcommFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place a flexible EmailFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createEmailFeedItem: function (feedItem, index) {
      var item = new EmailFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place a NoteFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createNoteFeedItem: function (feedItem, index) {
      var item = new NoteFeedItem({
        "data": feedItem,
        "isReadOnly": this.isArchived
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place a SubUnsubFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createSubUnsubFeedItem: function (feedItem, index) {
      var item = new SubUnsubFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * construct and place MarketingPermissionFeedItem widget
     * @param {Object} feedItem Feed item data
     */
    createMarketingPermissionFeedItem: function (feedItem, index) {
      var item = new MarketingPermissionFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place PostcardSentFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createPostcardSentFeedItem: function (feedItem, index) {
      var item = new PostcardSentFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place SignupFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createWebsiteSignUpFeedItem: function (feedItem, index) {
      var item = new WebsiteSignUpFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place SignupFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createSquatterSignUpFeedItem: function (feedItem, index) {
      var item = new SquatterSignUpFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place SignupFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createLandingPageSignupFeedItem: function (feedItem, index) {
      var item = new LandingPageSignupFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place SignupFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createEcommerceSignupFeedItem: function (feedItem, index) {
      var item = new EcommerceSignupFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Construct and place SignupFeedItem widget
     * @param {Object} feedItem Feed item data
     * @param {index} index Feed item index
     */
    createGenericSignupFeedItem: function (feedItem, index) {
      var item = new GenericSignupFeedItem({
        "data": feedItem
      });
      this.placeFeedItem(item, index);
    },
    /**
     * Util method to place widgets in the list container
     * @param {Object} item Feed widget
     */
    placeFeedItem: function (item, index) {
      item.placeAt("date-" + index, "last");
    },
    /**
     * Api request to get activity feed
     * @param {string} typeFilter activity type to filter down to
     */
    getActivityFeed: function (typeFilter) {
      api.getActivityFeed(this.memberID, typeFilter, this.slice, this.size, lang.hitch(this, "hydrate"));
    },
    /**
     * Clears out feed and refreshes with new filter
     * @param {string} typeFilter activity type to filter down to
     */
    handleFilterChange: function () {
      this.typeFilter = this.activityTypeFilter.get("value");
      this.resetFeed();
    },
    /**
     * Updates feed after note is created
     */
    handleNoteCreation: function () {
      var self = this;
      topic.subscribe("contact-profile/note-added", function () {
        var resetFeed = lang.hitch(self, "resetFeed");
        resetFeed();
      });
    },
    /**
     * Clears out feed and refreshes it
     */
    resetFeed: function () {
      this.activities = [];
      this.getActivityFeed(this.typeFilter);
    },
    /**
     * Api request to get activity feed
     */
    showMoreActivities: function () {
      api.getActivityFeed(this.memberID, this.typeFilter, this.next_slice, this.size, lang.hitch(this, "hydrate"));
    },
    installEventsCriteriaInfoBlock: function () {
      EventsInfoBlock.shouldShow().then(function (should) {
        if (should === false) {
          return;
        }
        var block = new EventsInfoBlock();
        block.placeAt(this.eventsInfoBlockContainer);
        block.startup();
      }.bind(this));
    },
    /**
     * Populates activity type dropdown filter for feed
     */
    structureTypeFilter: function () {
      var statusFilter = this.activityTypeFilter;
      var statuses = {
        "all": "All Activity",
        "ecomm": "E-commerce",
        "sent": "Sends",
        "opens": "Opens",
        "clicks": "Clicks",
        "bounces": "Bounces",
        "unsubs": "Unsubscribes",
        "note": "Notes",
        "conversation": "Conversations",
        "marketing_permissions": "Marketing Permissions",
        "postcard_sent": "Postcards Sent",
        "signup": "Signup",
        "event": "Events",
        "survey_response": "Survey Responses",
        "inbox_thread": "Inbox Messages",
        "web_engagement": "Web Engagement"
      };
      var options = Object.keys(statuses).map(function (status) {
        return {
          value: status,
          label: statuses[status],
          selected: true
        };
      });
      statusFilter.addOption(options);

      // refresh feed based on filter selection
      on(statusFilter, "change", lang.hitch(this, "handleFilterChange"));
    },
    /**
     * Update data and view
     * @param {string} response Api response
     */
    hydrate: function (response) {
      this.activities = this.activities.concat(response.activities);
      this.activityFeed = api.structureActivityFeed(this.activities);
      this.next_slice = response.next_slice_start;
      domConstruct.empty(this.feedListContainer);
      this.populateFeed();
    }
  });
});