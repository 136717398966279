define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/promptErrorDisplay.html", "mojo/neapolitan/genai/aiassistant/prompt-creator"], function (declare, on, domClass, lang, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, promptCreator) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    // data-dojo-attach-point in the template
    retryButton: null,
    // data-dojo-attach-point in the template
    dismissLink: null,
    // error to render in template
    errorMessage: "",
    // A reference to a prompt created through prompt-creator
    prompt: null,
    postMixInProperties: function () {
      if (!this.prompt) {
        return;
      }
      switch (this.prompt.responseError) {
        case promptCreator.responseErrors.unsupportedLanguage:
          this.errorMessage = "Sorry, we do not support requests in that language.";
          break;
        default:
          this.errorMessage = "Oops! We had issues handling your request.";
      }
    },
    postCreate: function () {
      this.own(on(this.retryButton, "click", lang.hitch(this, "onPromptRetry")), on(this.dismissLink, "click", lang.hitch(this, "_handleDismissLink")));
      this._toggleActions();
    },
    /**
     * Handles when the dismiss link is clicked by hiding the entire error.
     *
     * @private
     */
    _handleDismissLink: function () {
      domClass.add(this.domNode, "hide");
    },
    /**
     * Handle setting the appropriate action buttons based on the prompt
     *
     * @private
     */
    _toggleActions: function () {
      var retryable = this.prompt.responseError !== promptCreator.responseErrors.unsupportedLanguage;
      domClass.toggle(this.retryButton, "hide", !retryable);
    },
    /**
     * Dojo widget event for when we want to shorten a prompt
     */
    onPromptRetry: function () {}
  });
});