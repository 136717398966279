define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on, topic) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      topic.subscribe("mojo/analytics/List/orders-list/render", function (list) {
        self.parse(self.elId);
        if (self.$group.viewOrderDetailsLink) {
          self.$group.viewOrderDetailsLink.forEach(function (el) {
            on(el, "click", function () {
              self.viewOrderDetails(domAttr.get(el, "data-url"), domAttr.get(el, "data-order-id"));
            });
          });
        }
      });
    },
    /**
     * adds the order Details content pane
     * @param {string} url - url that the pane should load the data from
     * @param {number} id - order id
     */
    viewOrderDetails: function (url, id) {
      var self = this;
      var newPaneObj = {
        title: "Order Details: " + id,
        href: url,
        stub: "order-details",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.orderDetails = null;
          return true;
        }
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     *Refund a users's order
     * @param {number} id - the id of an order
     * @param {string} amount - the account that should be refunded
     * @param {string} card - the card that was used for order
     * @param {string} datetime - the data of the refund
     * @param {string} lastFour - last four digits of card used
     * @param {string} check_refund_rules - checks for refund rules
     */
    refundOrder: function (id, amount, card, datetime, lastFour, check_refund_rules) {
      this.$el.refundOrderId.value = id;
      this.$el.refundWarningText.innerHTML = "Are you sure you want to refund order " + id + " on " + datetime + "?  This will reduce revenue by the amount refunded.";
      this.$el.refundAmount.value = amount;
      this.$el.checkRefundRules.value = check_refund_rules;
      this.$widget.refundWarningArea.show();
    },
    /**
     *Refund a users's order
     * @param {number} id - the id of an order
     * @param {string} amount - the account that should be refunded
     * @param {string} card - the card that was used for order
     * @param {string} datetime - the data of the refund
     * @param {string} lastFour - last four digits of card used
     * @param {string} check_refund_rules - checks for refund rules
     */
    refundOrderItem: function (id, amount, card, datetime, lastFour, check_refund_rules) {
      this.$el.refundOrderItemId.value = id;
      this.$el.refundWarningText.innerHTML = "Are you sure you want to refund order item " + id + " on " + datetime + "?  This will reduce revenue by the amount refunded.";
      this.$el.refundItemAmount.value = amount;
      this.$el.checkRefundRules.value = check_refund_rules;
      this.$widget.refundItemWarningArea.show();
    },
    /**
     * Retry failed order
     * @param {string} id - order id of the failed order
     */
    retryOrder: function (id) {
      this.$el.retryOrderId.value = id;
      this.$el.retryWarningText.innerHTML = "Are you sure you want to retry order " + id + "?";
      this.$widget.retryWarningArea.show();
    },
    /**
     * opens a chargeback for the order id passed
     * @param {number} order_id - the order id that should be charged back
     */
    openChargeback: function (order_id) {
      this.$el.openChargebackId.value = order_id;
      this.$el.openChargebackWarningText.innerHTML = "Are you sure you want to open a chargeback on " + "<strong>order " + order_id + "</strong>? <br/>It's sure to disable sending until the user pays it back.";
      this.$widget.openChargebackWarningArea.show();
    },
    /**
     * void a chargeBack
     * @param {number} order_id - the order id
     */
    voidChargeback: function (order_id) {
      this.$el.voidChargebackId.value = order_id;
      this.$el.voidChargebackWarningText.innerHTML = "Are you sure you want to void the chargeback on " + "<strong>order " + order_id + "</strong>?";
      this.$widget.voidChargebackWarningArea.show();
    },
    /**
     * cancel retries
     * @param {number} order_id - the order id
     */
    cancelRetries: function (order_id) {
      this.$el.cancelRetriesId.value = order_id;
      this.$el.cancelRetriesWarningText.innerHTML = "Are you sure you want to cancel retries on <strong>order " + order_id + "</strong>?";
      this.$widget.cancelRetriesWarningArea.show();
    }
  });
});