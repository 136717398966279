define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/utils/I18nTranslation", "dojo/text!./templates/CustomerJourneyGenAiCTA.html"], function (declare, on, _WidgetBase, _TemplatedMixin, I18nTranslation, templateString) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: templateString,
    zeroStateTitle: "Try automations powered by AI",
    zeroStateDescription: "We'll add content and your brand styles to an automated email—all you have to do is review.",
    ctaText: "Explore AI journeys",
    betaText: "Beta",
    ctaLink: null,
    ctaImage: null,
    _trackCtaLink: function (event) {
      event.preventDefault();
      window.ga("send", "event", "campaigns", "cta", "button", {
        "dimension53": event.target.innerText,
        "dimension54": "/customer-journey",
        "dimension55": "campaigns list",
        "dimension56": "navigate"
      });
      window.location.href = "/customer-journey/explore/";
    },
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.createTranslations(translations);
      }.bind(this));
    },
    createTranslations: function (translations) {
      this.zeroStateTitle = translations.campaigns_zero_ai_title;
      this.zeroStateDescription = translations.campaigns_zero_ai_description;
      this.ctaText = translations.campaigns_zero_ai_btn;
      this.betaText = translations.campaigns_zero_ai_beta;
      return;
    },
    postCreate: function () {
      on(this.ctaLink, "click", this._trackCtaLink);
    }
  });
});