define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/templates"], function (declare, base, on, templates) {
  return declare([base], {
    // From controller
    templateId: null,
    templateName: null,
    onReady: function () {
      var _self = this;
      on(this.$el.previewButton, 'click', function (e) {
        e.preventDefault();
        templates.previewLayout(_self.templateId, _self.templateName, "template");
      });
    }
  });
});