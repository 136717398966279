define(["dojo/Stateful", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "mojo/user", "mojo/utils/number",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Widgets
"dijit/form/DropDownButton", "dijit/DropDownMenu", "dijit/MenuItem",
// Templates
"dojo/text!./audience-switcher.html",
//Translations
"mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (Stateful, declare, lang, domAttr, domClass, domConstruct, on, request, mUrl, mUser, numberUtils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, DropDownButton, DropDownMenu, MenuItem, tplString, flags, context, I18nTranslation, ready) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplString,
    allAudiencesKey: "all",
    allAudiencesLabel: "All audiences",
    audienceList: [],
    contactCount: null,
    currentAudienceId: null,
    currentAudienceName: null,
    isDisabled: false,
    hasBottomBorder: false,
    hasBottomPadding: true,
    hasLinkToViewAudiences: false,
    isAllAudiencesEnabled: false,
    isAllSelected: false,
    isElcEligible: false,
    isSMSEnabled: false,
    onStartupCallback: null,
    onAudienceOpenCallback: null,
    onSwitchAudienceCallback: null,
    smsSubscriberCount: null,
    subscriberCount: null,
    translatedTerms: {},
    translatedContactTerm: null,
    /**
     * Perform appropriate setup actions
     */
    postCreate: function () {
      this._fetchAndHydrate();
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedTerms = value;
        }.bind(this));
        this.allAudiencesLabel = this.translatedTerms["audience_managment_switcher_cta_all_audience"];
      }
    },
    /**
     * Enable / disable audience selection.
     */
    setDisabled: function (isDisabled) {
      this.isDisabled = isDisabled;
      if (this.dropdownButton) {
        this.dropdownButton.setDisabled(isDisabled);
        this.dropdownButton.setLabel(isDisabled ? this.allAudiencesLabel : this.currentAudienceName);
      }
      this._injectData();
    },
    /**
     * Fetch the data pertaining to this widget and re-hydrate the widget
     * @private
     */
    _fetchAndHydrate: function () {
      var requestUrl = "/lists/switcher/get-switcher-data";
      var dataRequest = request.get(requestUrl, {
        handleAs: "json"
      });
      dataRequest.then(lang.hitch(this, function (response) {
        this.isAllSelected = response.isAllSelected;
        this.audienceList = response.audienceList;
        this.isElcEligible = response.isElcEligible;
        this.isSMSEnabled = response.isSMSEnabled;

        // Set up the UI for the case where all audiences is persisted
        if (this.isAllAudiencesEnabled && this.isAllSelected) {
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", this.allAudiencesKey);
          this.currentAudienceName = this.allAudiencesLabel;
        } else {
          this.contactCount = numberUtils.toFormat(response.contactCount, null, null, ",");
          //Use .set() so this property can be watched by external widgets
          this.set("currentAudienceId", response.currentAudienceId);
          this.currentAudienceName = response.currentAudienceName;
          this.subscriberCount = numberUtils.toFormat(response.subscriberCount, null, null, ",");
          this.smsSubscriberCount = numberUtils.toFormat(response.smsSubscriberCount, null, null, ",");
        }

        //Translations
        if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
          I18nTranslation.initialize().then(function (value) {
            if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
              this.translatedContactTerm = this.contactCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_contact"] : this.translatedTerms["audience_managment_switcher_info_sentence_contacts"];
            }
            var path = this.isElcEligible ? "/audience/contacts?clear_segment=true&id=" + this.currentAudienceId : "/lists/members/clear-and-view?id=" + this.currentAudienceId;
            var contactLink = mUser.hasRole("manager") ? '<a href="' + path + '"class="font-weight--bold" title="Your contacts">' + this.contactCount + "</a>" : '<span class="font-weight--bold" title="Your contacts">' + this.contactCount + "</span>";
            var sentence;
            if (this.isElcEligible) {
              if (this.isSMSEnabled) {
                sentence = I18nTranslation.translate("audience_managment_switcher_subscriber_contact_information_sms", [contactLink, this.translatedContactTerm, '<a class="font-weight--bold" href="javascript:;">' + this.subscriberCount + "</a>", this.subscriberCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_subscriber"] : this.translatedTerms["audience_managment_switcher_info_sentence_subscribers"], '<a class="font-weight--bold" href="javacript:;">' + this.smsSubscriberCount + "</a>", this.smsSubscriberCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_subscriber"] : this.translatedTerms["audience_managment_switcher_info_sentence_subscribers"]]);
              } else {
                sentence = I18nTranslation.translate("audience_managment_switcher_subscriber_contact_information_email", [contactLink, this.translatedContactTerm, '<a class="font-weight--bold" href="javascript:;">' + this.subscriberCount + "</a>", this.subscriberCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_subscriber"] : this.translatedTerms["audience_managment_switcher_info_sentence_subscribers"]]);
              }
            } else {
              sentence = I18nTranslation.translate("audience_managment_switcher_subscriber_contact_information", [contactLink, this.translatedContactTerm, '<a class="font-weight--bold" href="javascript:;">' + this.subscriberCount + "</a>", this.subscriberCount === "1" ? this.translatedTerms["audience_managment_switcher_info_sentence_is_subscribed"] : this.translatedTerms["audience_managment_switcher_info_sentence_are_subscribers"]]);
            }
            this.audienceData.innerHTML = sentence;
            this.selectLabel.textContent = this.translatedTerms["audience_managment_switcher_select_label"] || "Current audience";

            // Inject SMS badge text, show badge
            if (this.isElcEligible && this.isSMSEnabled) {
              this.switcherSmsBadge.innerText = this.translatedTerms["audience_managment_switcher_info_sms_badge"];
              domClass.remove(this.switcherSmsBadge, "hide");
            }

            // Create buttons to view subscribers
            if (mUser.hasRole("manager")) {
              // Set up email subscriber link
              if (this.isElcEligible) {
                on(this.audienceData.childNodes[2], "click", lang.hitch(this, function () {
                  this._onSubscribersClick();
                }));
              } else {
                on(this.audienceData.childNodes[3], "click", lang.hitch(this, function () {
                  this._onSubscribersClick();
                }));
              }
              // Set up SMS subscriber link
              if (this.isElcEligible && this.isSMSEnabled) {
                on(this.audienceData.childNodes[4], "click", lang.hitch(this, function () {
                  this._onSmsSubscribersClick();
                }));
              }
            } else if (this.isElcEligible) {
              this.audienceData.childNodes[2].outerHTML = '<span class="font-weight--bold">' + this.subscriberCount + "</span>";
              if (this.isSMSEnabled) {
                this.audienceData.childNodes[4].outerHTML = '<span class="font-weight--bold">' + this.smsSubscriberCount + "</span>";
              }
            } else {
              this.audienceData.childNodes[3].outerHTML = '<span class="font-weight--bold">' + this.subscriberCount + "</span>";
            }
          }.bind(this));
        }

        // Call the passed-in startup callback function
        if (this.onStartupCallback) {
          this.onStartupCallback(this.currentAudienceId);
        }

        // Setup all UI
        if (this.audienceList.length > 1) {
          this._injectData();
          this._setupSwitcher();
        } else if (this.audienceList.length === 1) {
          this._injectData();
          this._setupSingleList();
        } //Otherwise, the have 0 lists so the switcher should remain hidden
      }));
    },
    /**
     * Inject response data into the template
     * @private
     */
    _injectData: function () {
      // We do not have data to inject if the user is viewing "All audiences"
      if (!(this.isAllAudiencesEnabled && this.isAllSelected) && !this.isDisabled) {
        //Links to the contact table are not active if the user is not a manager or higher
        if (mUser.hasRole("manager")) {
          //Setup link to view all contacts (via the contact table)
          this.contactsLink.innerText = this.contactCount;
          var path = this.isElcEligible ? "/audience/contacts?clear_segment=true&id=" + this.currentAudienceId : "/lists/members/clear-and-view?id=" + this.currentAudienceId;
          domAttr.set(this.contactsLink, "href", path);

          //Setup link to view all subscribers (via setting the session segment)
          this.subscribersLink.innerText = this.subscriberCount;
          on(this.subscribersLink, "click", lang.hitch(this, function () {
            this._onSubscribersClick();
          }));
          domClass.remove(this.audienceData, "hide");
        } else {
          this.contactsDisplay.innerText = this.contactCount;
          this.subscribersDisplay.innerText = this.subscriberCount;
          domClass.remove(this.audienceDataReadOnly, "hide");
        }
      } else {
        // Hide data containers when viewing "All audiences"
        domClass.add(this.audienceData, "hide");
        domClass.add(this.audienceDataReadOnly, "hide");
      }
      if (this.hasBottomBorder) {
        domClass.add(this.container, "border-bottom");
      }
      if (!this.hasBottomPadding) {
        domClass.remove(this.container, "padding-bottom--lv4");
      }

      // Prevent flash of incomplete template
      domClass.remove(this.container, "hide");
    },
    /**
     * Set up the state where only one audience exists
     * Only the current audience name is displayed
     * @private
     */
    _setupSingleList: function () {
      this.singleAudienceName.innerText = this.currentAudienceName;
      domClass.remove(this.singleAudienceName, "hide");
      domClass.add(this.switcherContainer, "hide");
    },
    /**
     * Set up the state where 2+ audiences exist
     * A dropdown menu is shown with the current audience as the selected option
     * @private
     */
    _setupSwitcher: function () {
      domConstruct.empty(this.switcher);
      var dropdown = new DropDownMenu({
        style: "display: none;",
        onOpen: this.onAudienceOpenCallback
      });

      /**
       * Add each audience to the dropdown
       */
      this.audienceList.forEach(lang.hitch(this, function (audience) {
        if (audience.id !== this.currentAudienceId) {
          if (this.isElcEligible && audience.isSMSEnabled) {
            dropdown.addChild(new MenuItem({
              label: "<p class='margin-bottom--lv0'>" + audience.name + "<span class='badge success margin-left--lv3'>" + this.translatedTerms["audience_managment_switcher_info_sms_badge"] + "</span></p>",
              onClick: lang.hitch(this, function () {
                this._onAudienceClick(audience);
              })
            }));
          } else {
            dropdown.addChild(new MenuItem({
              label: "<p class='margin-bottom--lv0'>" + audience.name + "</p>",
              onClick: lang.hitch(this, function () {
                this._onAudienceClick(audience);
              })
            }));
          }
        }
      }).bind(this));

      /**
       * Render the "All audiences" button if enabled
       */
      if (this.isAllAudiencesEnabled && !this.isAllSelected && this.audienceList.length > 1) {
        dropdown.addChild(new MenuItem({
          label: "<p class='font-weight--bold margin-bottom--lv0'>" + (this.translatedTerms["audience_managment_switcher_cta_all_audience"] || "All audiences") + "</p>",
          onClick: lang.hitch(this, function () {
            this._onAudienceClick("all");
          })
        }));
      }

      /**
       * Render the "View audiences" link if enabled
       */
      if (this.hasLinkToViewAudiences) {
        var href = "/audience/manage/";
        dropdown.addChild(new MenuItem({
          label: "<a class='view-audiences-link' href='" + href + "'>" + (this.translatedTerms["audience_managment_switcher_cta_view_audience"] || "All Audiences") + "</a>"
        }));
      }
      var dropdownButton = new DropDownButton({
        label: this.isDisabled ? this.allAudiencesLabel : this.currentAudienceName,
        dropDown: dropdown,
        dropDownPosition: ["below"],
        disabled: this.isDisabled
      });
      this.dropdownButton = dropdownButton;
      dropdownButton.placeAt(this.switcher, "only");
      domClass.remove(this.switcherContainer, "hide");
      domClass.add(this.singleAudienceName, "hide");
    },
    /**
     * Handle the click event from the switcher dropdown menu
     * @param {object} audience to switch to
     * @private
     */
    _onAudienceClick: function (audience) {
      if (audience === this.allAudiencesKey) {
        this._onAllAudiencesClick();
      } else {
        // Only overwrite the "All audiences" value if we're coming from a context that supports "All audiences"
        var switchAudienceUrl = "/lists/switcher/set-selected-audience?id=" + audience.id;
        switchAudienceUrl += "&overwrite_all=" + this.isAllAudiencesEnabled;
        var switchAudienceRequest = request.get(switchAudienceUrl, {
          handleAs: "json"
        });
        var result = switchAudienceRequest.then(lang.hitch(this, function () {
          //Call the passed-in callback to perform actions as a result of the list switch
          this.onSwitchAudienceCallback(audience.id);
          this._fetchAndHydrate();
        }));
      }
    },
    /**
     * When the user selects "All" from the dropdown, we need to update the dropdown Label and hide irrelevant UI
     * @private
     */
    _onAllAudiencesClick: function () {
      var switchAudienceRequest = request.get("/lists/switcher/set-select-all-audiences", {
        handleAs: "json"
      });
      var result = switchAudienceRequest.then(lang.hitch(this, function () {
        this.onSwitchAudienceCallback(this.allAudiencesKey);
        this._fetchAndHydrate();
      }));
    },
    /**
     * Set the session segment to view the email subscribers only
     * Navigate to /lists/members?id={currentAudienceId}
     * @private
     */
    _onSubscribersClick: function () {
      var formData = new FormData();
      var segmentCondition = {
        match: "all",
        conditions: [{
          field: "contact_status",
          op: "status_is",
          value: ["Y"]
        }]
      };
      formData.append("segment", JSON.stringify(segmentCondition));
      if (this.isElcEligible) {
        var pathWithSegment = encodeURI("/audience/contacts?segment=" + JSON.stringify(segmentCondition) + "&id=" + this.currentAudienceId);
        window.top.location.href = pathWithSegment;
        return;
      }
      var setSessionRequest = request.post("/lists/segments/members/storeJson?list_id=" + this.currentAudienceId, {
        handleAs: "json",
        data: formData
      });
      var result = setSessionRequest.then(lang.hitch(this, function () {
        var path = this.isElcEligible ? "/audience/contacts/?id=" + this.currentAudienceId + "&previewName=Subscribers" : "/lists/members?id=" + this.currentAudienceId;
        window.top.location.href = path;
      }));
    },
    /**
     * Set the session segment to view the SMS subscribers only
     * Navigate to /lists/members?id={currentAudienceId}
     * @private
     */
    _onSmsSubscribersClick: function () {
      var formData = new FormData();
      var segmentCondition = {
        match: "all",
        conditions: [{
          field: "sms_contact_status",
          op: "status_is",
          value: ["Y"]
        }]
      };
      formData.append("segment", JSON.stringify(segmentCondition));
      if (this.isElcEligible) {
        var pathWithSegment = encodeURI("/audience/contacts?segment=" + JSON.stringify(segmentCondition) + "&id=" + this.currentAudienceId);
        window.top.location.href = pathWithSegment;
        return;
      }
      var setSessionRequest = request.post("/lists/segments/members/storeJson?list_id=" + this.currentAudienceId, {
        handleAs: "json",
        data: formData
      });
      var result = setSessionRequest.then(lang.hitch(this, function () {
        var path = this.isElcEligible ? "/audience/contacts/?id=" + this.currentAudienceId : "/lists/members?id=" + this.currentAudienceId;
        window.top.location.href = path;
      }));
    },
    /**
     * Public function to allow programmatic setting of "All audiences"
     * from contexts outside of the switcher itself
     */
    setIsAllAudiences: function () {
      this._onAllAudiencesClick();
    }
  });
});