define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/user", "mojo/support/chat", "mojo/bridge", "mojo/lib/flags", "mojo/widgets/ada/Ada", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, base, domClass, on, debounce, query, xhr, lang, mUrl, user, Chat, bridge, flags, Ada, EcsEvents, EcsTracking) {
  return declare([base], {
    /**
     *  Boolean value to identify if customer is "high value" or not, passed from AccountController
     */
    isHighValueCustomer: false,
    /**
     *  Ada user metafields object
     */
    adaUserMetafields: {},
    COVID19_PAUSE_THREE_MONTHS_PROMO_CODE: "COVID19_PAUSE_THREE_MONTHS",
    service: "zendesk",
    /**
     * Attach page-level event handlers. This page is a mix-bag of node visibility controlled via Avesta
     * versus JS. Check for existence of node prior to attaching event.
     */
    onReady: function () {
      this._validatePauseDialogExists();
      this._checkChatAvailability();
      this._initCloseOrPauseSelection();

      // Confirmed pause button from within the dialog
      if (this.$el.pauseAccountConfirmBtn) {
        on(this.$el.pauseAccountConfirmBtn, "click", debounce(this._handlePauseConfirm.bind(this), 500));
        on(this.$el.pauseAccountConfirmBtn, "click", this._sendPauseModalConfirmClickedEvent.bind(this));
      }

      // Dialog Cancel Button
      if (this.$el.pauseAccountCancelBtn) {
        on(this.$el.pauseAccountCancelBtn, "click", this._handlePauseDialogCancel.bind(this));
        on(this.$el.pauseAccountCancelBtn, "click", this._sendPauseModalCancelClickedEvent.bind(this));
      }

      // Restart Account Button
      if (this.$el.restartAccountBtn) {
        on(this.$el.restartAccountBtn, "click", this._handleRestartAccountButtonClick.bind(this));
      }

      // Delete Account Continue Button
      if (this.$el.deleteAccountContinueBtn) {
        on(this.$el.deleteAccountContinueBtn, "click", this._handleContinueToDeleteButtonClick.bind(this));
      }
    },
    onPause: function () {
      window.ga("send", "event", "account-pause", "button_click", JSON.stringify({
        button_text: "pause",
        account_status: "no outstanding charges"
      }));
      if (this.pausePromo && this.pausePromo.isEligible) {
        this._initCovid19Promo(this._handlePauseDialogOpen.bind(this));
        window.ga("send", "event", "covid19promo", "button_click", JSON.stringify({
          show_promo: "true",
          promo_code: "COVID19_PAUSE_THREE_MONTHS"
        }));
        return;
      }
      this._handlePauseDialogOpen();
    },
    /**
     * Init the react version of
     * the close/pause selection
     */
    _initCloseOrPauseSelection: function () {
      bridge("revenue").then(function (bridge) {
        this.unmount = bridge.mountCloseAccountSelection(this.$el.pauseOrDeleteSelection, {
          pausesRemainingDescription: this.pausesRemainingDescription,
          isAbleToPause: this.isAbleToPause,
          planName: this.planName,
          isPaygo: this.isPaygo,
          isLegacyMonthly: this.isLegacyMonthly,
          hasLegacyTransactionalPlan: this.hasLegacyTransactionalPlan,
          hasSmsPlan: this.hasSmsPlan,
          hasMultiplePackages: this.hasMultiplePackages,
          whyNotPause: this.whyNotPause,
          isLegacyPro: this.isLegacyPro,
          acbCharges: this.acbCharges,
          currency: this.currency,
          currencyFormat: this.currencyFormat,
          additionalUsers: this.additionalUsers,
          accountName: this.accountName,
          registeredDomains: this.registeredDomains,
          isChatOnline: this.isChatOnline,
          onPause: this.onPause.bind(this),
          userId: this.userId
        });
      }.bind(this));
    },
    /**
     * Close the promo and launch the pause confirmation dialog
     */
    _closeCovid19Promo: function (onDismiss) {
      this.unmountCovid19Promo && this.unmountCovid19Promo();
      // Because user has already seen the promo,
      // mark eligibility as false (unless resurfacing experiment is running)
      if (this.pausePromo && !user.flagIsOn(flags.MONETIZATION_PAUSE_PROMO_RESURFACING_EXPERIMENT)) {
        this.pausePromo.isEligible = false;
      }
      onDismiss();
    },
    /**
     * This signature reflects the generic implementation of MerchandisingPromo acceptance.
     */
    _onCovidPromoAccept: function (confirmationMessageText, elgiblePromotions, redirectURL) {
      window.top.location = redirectURL;
    },
    /**
    * Use bridge to init covid19Promo
    */
    _initCovid19Promo: function (onDecline) {
      var onDeclineFn = function () {
        this._closeCovid19Promo(onDecline);
      };
      bridge("revenue").then(function (result) {
        var props = {
          promoCode: this.COVID19_PAUSE_THREE_MONTHS_PROMO_CODE,
          labelsKey: this.COVID19_PAUSE_THREE_MONTHS_PROMO_CODE,
          trackingId: this.COVID19_PAUSE_THREE_MONTHS_PROMO_CODE,
          dynamicContent: {
            duration: this.pausePromo.duration
          },
          onDecline: onDeclineFn.bind(this),
          onAccept: this._onCovidPromoAccept
        };
        this.unmountCovid19Promo = result.mountCovid19Promo(this.$el.covid19Promo, props);
      }.bind(this));
    },
    /**
     * Validate that they've filled out the required validation field and then perform the real action.
     *
     * @private
     */
    _handlePauseConfirm: function () {
      // Check that they've filled the mojo/widgets/ConfirmInput out properly.
      if (this.$widget.pauseConfirmInput.validate()) {
        this._performCloseAction("pause");
      }
    },
    /**
     * Send an event to Google Analytics and redirect them to a confirmation page.
     *
     * @param {String} actionStr - Leave flexibility to pass something other than "pause"
     * @private
     */
    _performCloseAction: function (actionStr) {
      window.ga("send", "event", window.location.pathname, actionStr + "account", actionStr);
      var queryParams = {
        "type": actionStr,
        "__csrf_token": user.getCsrfToken()
      };
      document.location = mUrl.toUrl("/i/account/close-confirmed", queryParams);
    },
    /**
     * Toggle visibility of Pause / Cancel dialog helper methods
     * @private
     */
    _handlePauseDialogOpen: function () {
      this.$widget.pauseAccountConfirmDialog.show();

      // invoke Ada chatbox
      on(this.$el.helpbotButton, "click", this._handleHelpbotButtonClick.bind(this));
    },
    _handlePauseDialogCancel: function () {
      this.$widget.pauseAccountConfirmDialog.hide();
    },
    /**
     * Other methods in this widget assume existence of the confirmation dialog. Test for it early in the
     * lifecycle.
     *
     * @private
     */
    _validatePauseDialogExists: function () {
      if (!this.$widget.pauseAccountConfirmDialog) {
        throw new Error("Pause / Cancel dialog not found on page");
      }
    },
    /**
     * Check to see if chat is available at this time
     * @private
     */
    _checkChatAvailability: function () {
      var chat = new Chat();
      var userData = xhr.get(mUrl.toUrl("kb/get-support-settings"), {
        handleAs: "json"
      });
      userData.then(lang.hitch(this, function (response) {
        if (response && response.userData.user_level === "pfree") {
          this.service = "ada";
          this._setChatOptionVisibility(true);
        }
      }));
      if (this.service === "zendesk") {
        chat.getChatDeptStatus(lang.hitch(this, this._setChatOptionVisibility));
      }
    },
    /**
     * Check to see if chat is available at this time
     * @param {Boolean} is_visible - decides whether chat should be visible
     * @private
     */
    _setChatOptionVisibility: function (is_visible) {
      if (is_visible) {
        this._showChatOption();
      } else {
        this._hideChatOption();
      }
    },
    _showChatOption: function () {
      if (this.$el.chatOptionContainer) {
        domClass.remove(this.$el.chatOptionContainer, "js-hide");
      }
    },
    _hideChatOption: function () {
      if (this.$el.chatOptionContainer) {
        domClass.add(this.$el.chatOptionContainer, "js-hide");
      }
    },
    /**
     * Start Ada chatbot
     */
    _handleHelpbotButtonClick: function () {
      var adaWorkflow = this.isHighValueCustomer ? "accountclose_CTA_HV" : "accountclose_CTA";
      var params = this.adaUserMetafields;
      params.workflow = adaWorkflow;
      // the code below is for the Ada chatbot flow
      // var adaInstance = new Ada();
      // adaInstance.adaClicked(params);
      var oicuEventDetails = {
        detail: {
          entryPoint: "pause-cancel-main-banner",
          experience: "mcContactUs"
        }
      };
      var launchOICUEvent = new CustomEvent("launch_oicu", oicuEventDetails);
      top.window.dispatchEvent(launchOICUEvent);
    },
    _handleRestartAccountButtonClick: function () {
      EcsEvents.ClosePageUnpauseButtonClickedEvent.experiment_variant = "control";
      EcsTracking.track(EcsEvents.ClosePageUnpauseButtonClickedEvent);
      var csrfToken = user.getCsrfToken();
      window.top.location.href = "/account/un-pause-account?__csrf_token=" + csrfToken;
    },
    _sendPauseModalConfirmClickedEvent: function () {
      EcsEvents.PauseModalPauseButtonClickedEvent.experiment_variant = "control";
      EcsTracking.track(EcsEvents.PauseModalPauseButtonClickedEvent);
    },
    _sendPauseModalCancelClickedEvent: function () {
      EcsEvents.PauseModalNeverMindButtonClickedEvent.experiment_variant = "control";
      EcsTracking.track(EcsEvents.PauseModalNeverMindButtonClickedEvent);
    },
    _handleContinueToDeleteButtonClick: function () {
      EcsEvents.ClosePageDeleteButtonClickedEvent.experiment_variant = "control";
      EcsTracking.track(EcsEvents.ClosePageDeleteButtonClickedEvent);
      window.top.location.href = "/account/close-survey";
    }
  });
});