define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./SocialShareEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/SocialShareEditor/editorMobile.html", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, SocialShareEditor, _MobileBlockMixin, tmpl, _DynamicContentMixin) {
  return declare([SocialShareEditor, _MobileBlockMixin, _DynamicContentMixin], {
    draggableListItem: false,
    type: "socialShare-mobile",
    templateString: tmpl,
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});