/**
 * Top level widget for the platform selection UI
 * @module /mojo/widgets/account/connected-sites/PlatformsListItem
 * @see /mojo/widgets/account/connected-sites/PlatformsList
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/router", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/colors", "mojo/user", "mojo/lib/flags", "mojo/context", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./routes.config", "dojo/text!./templates/PlatformsListItem.html", "mojo/lib/logger"], function (declare, router, on, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, mColors, user, flags, context, domStyle, domClass, ROUTES, templateStr, logger) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // from constructor
    identifier: null,
    connectionStatus: null,
    connected: null,
    hasError: null,
    _setIdentifierAttr: {
      node: "platformImage",
      type: "attribute",
      attribute: "alt"
    },
    displayName: "",
    _setDisplayNameAttr: {
      node: "platformName",
      type: "innerHTML"
    },
    logoPath: "",
    _setLogoPathAttr: {
      node: "platformImage",
      type: "attribute",
      attribute: "src"
    },
    isConnected: function () {
      if (this.hasError) {
        domClass.remove(this.connectionStatus, "tick-fill");
        domClass.add(this.connectionStatus, "warn-circle-color");
      }
      domClass.remove(this.connectionStatus, "hide");
    },
    //add the image with new name
    postMixInProperties: function () {
      this.logoPath = "https://cdn-images.mailchimp.com/product/third-party-logos/" + this.identifier + "-icon.svg";
    },
    _viewInstructions: function (e) {
      e.preventDefault();
      if (this.identifier === "square-commerce") {
        if (this.connected) {
          window.location = "/integrations/manage?name=square";
        } else {
          window.open("https://mailchimp.com/marketplace/apps/square");
        }
        // NUA-353 Updating Shopify in app link and deep link.
      } else if (this.identifier === "shopsync") {
        // [PIE-411] Redirect connected app to manage MVP
        if (this.connected) {
          window.location = "/integrations/manage?name=shopify";
        } else {
          router.go(ROUTES.instruction.go("shopify"));
        }
      } else {
        router.go(ROUTES.instruction.go(this.identifier));
      }
    },
    postCreate: function () {
      if (this.connected) {
        this.isConnected();
      }
      this.own(on(this.domNode, "click", this._viewInstructions.bind(this)));
    }
  });
});