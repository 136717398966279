define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/ImageGroupEditor/output.html"], function (declare, _Renderer, array, lang, tpl) {
  return declare("mojo.neapolitan.renderers.ImageGroupBlock", [_Renderer], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      return {
        images: this._getImageGroupModel(meta)
      };
    },
    _getImageGroupModel: function (meta) {
      var model = [];
      var imageIndex = 0;
      array.forEach(meta.selectedLayout, lang.hitch(this, function (numPerRow) {
        var row = [];
        var imageContainerWidth = this._getCalculatedContainerWidth(numPerRow, meta.containerWidth);
        for (var i = 0; i < numPerRow; i++) {
          if (numPerRow == 2) {
            var columnWidth = (meta.containerWidth - this.innerPadding * 2 - this.innerPadding * 4) / numPerRow;
          } else {
            var columnWidth = meta.containerWidth - this.innerPadding * 4;
          }
          if (meta.images[imageIndex] && meta.images[imageIndex].hasOwnProperty('src')) {
            var imageData = lang.mixin({}, meta.images[imageIndex]);
            imageData.calculatedWidth = imageContainerWidth;
            // Only limit the calculated width of an image if it's wider than it's container
            if (imageData.width < imageContainerWidth) {
              imageData.calculatedWidth = imageData.width;
            } else {
              imageData.calculatedWidth = imageContainerWidth;
            }
            imageData.columnWidth = columnWidth;
            row.push(imageData);
          } else {
            row.push(lang.mixin({
              src: null,
              calculatedWidth: imageContainerWidth,
              index: imageIndex,
              columnWidth: columnWidth
            }));
          }
          imageIndex++;
        }
        model.push(row);
      }));
      return model;
    },
    _getCalculatedContainerWidth: function (numPerRow, containerWidth) {
      if (numPerRow == 2) {
        return Math.floor(containerWidth / 2 - this.outerPadding * 4);
      } else {
        return Math.floor(containerWidth - this.outerPadding * 4);
      }
    }
  });
});