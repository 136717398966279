define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, xhr, mUrl) {
  var ConnectedSite = declare(null, {
    constructor: function (properties) {
      this.name = properties.name;
      this.siteId = properties.site_id;
      this.siteUrl = properties.site_url;
      this.storeId = properties.store_id;
    }
  });
  return {
    filters: {
      hasStore: function (site) {
        return site.storeId;
      }
    },
    /**
     * @return {Promise} Resolves to an array of connected site objects, each having a name, siteId, and siteUrl
     *                   property.
     */
    list: function () {
      return xhr.get(mUrl.toUrl("channels/connected-sites"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return data.map(function (store) {
          return new ConnectedSite(store);
        });
      });
    }
  };
});