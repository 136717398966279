define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/ThumbnailPreview.html", "dojo/text!./templates/InlineThumbnailPreview.html"], function (declare, domClass, _WidgetBase, _TemplatedMixin, tplStr, inlineTplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    imageUrl: null,
    // Optional parameter that can be provided to load an iframe preview instead of
    // a thumbnail image.
    previewHtmlUrl: null,
    // When using the iframe strategy, this controls how wide the original preview is
    // rendered before scaling it down to fit the container
    viewportWidth: 660,
    // Aspect of the iframe preview, in w/h form.
    previewAspect: 0.920634920634921,
    // Whether or not to use an image thumbnail.
    usesThumbnails: true,
    // Store the resize handler for easy cleanup on destroy.
    resizeHandler: null,
    borderStyle: "dashed",
    _setBorderStyleAttr: function (borderStyle) {
      this.borderStyle = borderStyle;
      domClass.toggle(this.domNode, "border-dashed", this.borderStyle === "dashed");
    },
    constructor: function (props) {
      if (props.previewHtmlUrl) {
        this.templateString = inlineTplStr;
        this.usesThumbnails = false;
      }
    },
    setupIframe: function () {
      var rect = this.iframeNode.parentNode.getBoundingClientRect();
      var width = rect.width;
      var height = width * (1 / this.previewAspect);

      // Set the iframe to the desired viewport width
      this.iframeNode.setAttribute("width", this.viewportWidth);
      this.iframeNode.setAttribute("height", height * (this.viewportWidth / width));

      // Scale the iframe back down to fit the container.
      this.iframeNode.style.transform = "scale(" + width / this.viewportWidth + ")";
      this.iframeNode.style.transformOrigin = "0 0";

      // Need to explicitly set the parent's height, otherwise it retains the
      // iframe's pre-scaled height.
      this.iframeNode.parentNode.style.height = height + "px";

      // Take the iframe out of the document flow to fix its set width pushing
      // other elements off the page at smaller screen sizes
      this.iframeNode.style.position = "absolute";
      this.iframeNode.style.left = "0";
      this.iframeNode.style.top = "0";
    },
    postCreate: function () {
      if (this.usesThumbnails) {
        return;
      }
      this.resizeHandler = this.setupIframe.bind(this);

      // Set initial size
      this.setupIframe();
      requestAnimationFrame(this.resizeHandler);
      window.addEventListener("resize", this.resizeHandler);
    },
    destroy: function () {
      if (this.resizeHandler) {
        window.removeEventListener("resize", this.resizeHandler);
      }
    }
  });
});