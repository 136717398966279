define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/utils/I18nTranslation",
// Templates
"dojo/text!./templates/AllAudiencesSearchHint.html"], function (declare, lang, domClass, topic, utils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, I18nTranslation, template) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: template,
    hintText: "You can also search by ",
    hintTextLink: "all audiences",
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.hintText = translations.campaigns_search_hint;
        this.hintTextLink = translations.campaigns_search_hint_link;
      }.bind(this));
    },
    postCreate: function () {
      this.inherited(arguments);
      var _mc = window._mc;
      this._toggleVisibility(_mc.audienceSwitcher.currentAudienceId);
      _mc.audienceSwitcher.watch("currentAudienceId", function (name, oldValue, value) {
        this._toggleVisibility(value);
      }.bind(this));
    },
    _toggleVisibility: function (audienceId) {
      var _mc = window._mc;
      if (audienceId === _mc.audienceSwitcher.allAudiencesKey) {
        domClass.add(this.container, "hide");
      } else {
        domClass.remove(this.container, "hide");
      }
    },
    _onAllAudiencesHintClick: function () {
      var _mc = window._mc;
      _mc.audienceSwitcher.setIsAllAudiences();
      utils.trackEvent({
        "eventCategory": "All audiences search hint",
        "eventAction": "Clicked"
      });
    }
  });
});