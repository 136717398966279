define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo/store/Cache", "dojo/store/util/QueryResults", "mojo/widgets/input/TagAutocomplete", "mojo/widgets/input/TagList", "mojo/url"], function (declare, lang, request, dom, Memory, Cache, QueryResults, TagAutocomplete, TagList, mUrl) {
  var createInterestId = function (interest) {
    return (interest.id + "_" + interest.name).replace(/,/g, "");
  };

  // piggy-back of dojo's in-memory store and override
  // the query method to fetch from our own endpoint
  var InterestsStore = declare([Memory], {
    // normally the ID property is id, and while we do have an id, we will need to compare the users input
    // to items in the cached store, so its best to create our own normalized id and key on that.
    idProperty: "nameKey",
    query: function (query) {
      // queries could either be a string or a hash of name-value pairs to match.
      // in our case, looks like the widget using this store sends an object and tries to match "name".
      // there's some weirdness there too: query.name is actually a "fake" string object with a toString() method.
      query = lang.isString(query) ? query : query.name.toString();
      var url = mUrl.toUrl("/ads/facebook/find-interests", {
        q: query,
        limit: 100
      });
      var interests = request(url, {
        handleAs: "json"
      }).then(function (results) {
        results.forEach(function (result) {
          result.nameKey = result.name.toLowerCase(); // normalize our key. just lowering will work.
          result.id = createInterestId(result); // we'll need to store the name as well, so include it
        });
        return results;
      });

      // store query results should also attach a total value
      // (or, as in this case, promise) to the results.
      interests.total = interests.then(function (results) {
        return results.length;
      });
      return QueryResults(interests);
    }
  });
  var interestCache = new Memory({
    idProperty: "nameKey"
  });
  var InterestsTagModel = declare([TagAutocomplete], {
    store: new Cache(new InterestsStore(), interestCache),
    autoComplete: false,
    // when the user hits enter, the autocomplete field's value may not have been updated yet, so we need to force
    // the tag to update its value if the user's current input is valid.
    onEnter: function () {
      var input = this.getCurrentInput().toLowerCase();
      var item = interestCache.get(input);
      if (item) {
        this.setValue(item.id);
        this.setLabel(item.name);
      }
      return true; // we return true to signify that we handled the event ourselves.
    }
  });
  return declare([TagList], {
    TagModel: InterestsTagModel,
    interests: [],
    postCreate: function () {
      var self = this;
      this.inherited(arguments); // this changes context, so we can no longer use it.

      var interests = self.interests || [];
      interests.forEach(lang.hitch(function (interest) {
        self._addTag(createInterestId(interest), interest.name);
      }));
    },
    // whenever we add a tag, we actually want to populate a single hidden input
    // with all of the tags we have so far, separated by commas.
    _addTag: function (value, label) {
      var tag = this.inherited(arguments);
      this.updateField();
      return tag;
    },
    _removeTag: function (tag) {
      this.inherited(arguments);
      this.updateField();
    },
    updateField: function () {
      // get the values, but get rid of duplicate tags
      var unique = {};
      var values = this.getValues().filter(function (_value) {
        var v = _value.trim();
        if (!unique[v]) {
          unique[v] = true;
          return true;
        }
        return false;
      });
      dom.byId("audience-interests-list").value = values.join();
    }
  });
});