define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/widgets/FilteredSearch"], function (declare, base, domAttr, domClass, on, topic, utils, FilteredSearch) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    /**
     * The id of this section's tab
     */
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.addFeatureButtons) {
        this.$group.addFeatureButtons.forEach(function (el) {
          on(el, "click", function () {
            self.addFeature(domAttr.get(el, "data-feature-id"), domAttr.get(el, "data-feature-name"));
          });
        });
      }
      if (this.$group.removeFeatureButtons) {
        this.$group.removeFeatureButtons.forEach(function (el) {
          on(el, "click", function () {
            self.removeFeature(domAttr.get(el, "data-feature-id"), domAttr.get(el, "data-feature-name"));
          });
        });
      }
      on(document.getElementById("copyFlagsBtn"), "click", function () {
        var flagElements = document.querySelectorAll(".flagName");
        var flags = [];
        flagElements.forEach(function (flagElement) {
          flags.push(flagElement.innerText);
        });
        navigator.clipboard.writeText(flags.join(","));
      });

      // Following blocks of code are responsible for reading recently used flags from a cookie and
      // pushing those to the top of the table list of inactive flags
      // More recent flags are at the end of the array, capped at 50 flags
      // now shared with in-app/admin app via web/js/src/__internal__/Flags.js
      var recentFlagsStorageKey = "recentlyUsedFlags";
      function saveRecentFlags(flagsArray) {
        var domain = document.location.host.indexOf("admin") > -1 ? "admin.mailchimp.com" : "dev.mailchimp.com";
        var mostRecent50Flags = flagsArray.slice(-50);
        document.cookie = recentFlagsStorageKey + "=" + JSON.stringify(mostRecent50Flags) + "; domain=" + domain + "; path=/; secure; max-age=31536000; samesite=strict;";
      }
      function getRecentFlags() {
        return JSON.parse(utils.getCookie(recentFlagsStorageKey)) || [];
      }
      var recentFlags = getRecentFlags();
      var inactiveFlagsTableBodySelector = "#inactive-tbl tbody";
      var inactiveFlagsStaticList = document.querySelectorAll(inactiveFlagsTableBodySelector + " tr");
      var inactiveFlagsLiveList = document.getElementsByClassName("inactive-feature-row");
      var inactiveFlagRowNodes = {};
      if (recentFlags.length > 0) {
        for (var a = 0; a < inactiveFlagsStaticList.length; a++) {
          var inactiveFlagName = inactiveFlagsStaticList[a].children[1].innerText;
          inactiveFlagRowNodes[inactiveFlagName] = inactiveFlagsStaticList[a];
        }
        // look through inactive flag table rows to find ones used recently and insert them before first tr element
        for (var y = 0; y < recentFlags.length; y++) {
          if (inactiveFlagRowNodes.hasOwnProperty(recentFlags[y])) {
            var recentFlagName = recentFlags[y];
            document.querySelector(inactiveFlagsTableBodySelector).insertBefore(inactiveFlagRowNodes[recentFlagName], inactiveFlagsLiveList[0]);
            inactiveFlagsLiveList[0].children[0].style.borderLeft = "3px solid #007c89";
          }
        }
      }
      on(document.querySelector("#add-feature-form"), "submit", function (event) {
        // when clicking the + icon, make sure to save that flag to a cookie to use above
        try {
          var recentlyUsedFlags = getRecentFlags();
          for (var z = 0; z < this.children.length; z++) {
            if (this.children[z].id === "feature-id") {
              var currentFlagName = this.children[z].value;
              // eslint-disable-next-line no-loop-func
              var flagsToSave = recentlyUsedFlags.filter(function (flagName) {
                return flagName !== currentFlagName;
              });
              flagsToSave.push(this.children[z].value);
              saveRecentFlags(flagsToSave);
            }
          }
        } catch (err) {
          // its ok if this fails, but logging error for debugging purposes
          console.error(err);
        }
      });
      on(document.querySelector("#add-features-form"), "submit", function (event) {
        // when adding multiple flags via comma-separated input form, make sure to save those flags to a cookie to use above
        try {
          var recentlyUsedFlags = getRecentFlags();
          for (var w = 0; w < this.children.length; w++) {
            if (this.children[w].id === "features_list") {
              var flagsBeingAdded = this.children[w].value.replace(" ", "").split(",");
              // eslint-disable-next-line no-loop-func
              var flagsToSave = recentlyUsedFlags.filter(function (flagName) {
                return flagsBeingAdded.indexOf(flagName) === -1;
              });
              flagsToSave = flagsToSave.concat(flagsBeingAdded);
              saveRecentFlags(flagsToSave);
            }
          }
        } catch (err) {
          // its ok if this fails, but logging error for debugging purposes
          console.error(err);
        }
      });

      // when MCAdmin_Flag::SEARCH_INACTIVE_FEATURES is on, show a search bar
      // presence of this element should signify whether the flag is enabled
      if (this.$el.nameFilterContainer) {
        // create a filtered search for flag names
        new FilteredSearch({
          placeholder: "Search by feature name",
          searchTopic: "flagNameFilter",
          onClear: function () {
            self.$group.inactiveFeatureRows.removeClass("hide");
          }
        }).placeAt(this.$el.nameFilterContainer).startup();

        // subscribe to and handle filtered searches by flag name
        topic.subscribe("search/flagNameFilter", this._handleFlagNameSearch.bind(this));
      }
    },
    /**
     * opens a modal to enable a feature flag in Mailchimp
     * @param {string} feature_id - the id of the feature flag
     * @param {string}feature_name - name of the feature flag
     */

    addFeature: function (feature_id, feature_name) {
      this.$el.featureId.value = feature_id;
      this.$el.featureStartTime.value = "";
      this.$el.featureEndTime.value = "";
      this.$el.addFeatureText.innerHTML = "Do you want to add <strong>" + feature_name + "</strong> to this user?";
      this.$widget.addFeatureDialog.show();
    },
    /**
     * opens a modal to disable a feature flag in Mailchimp
     * @param {string} user_feature_id - id of the feature flag
     * @param {string} feature_name - name of the feature flag
     */
    removeFeature: function (user_feature_id, feature_name) {
      this.$el.featureRemoveId.value = user_feature_id;
      this.$el.removeFeatureText.innerHTML = "Are you sure you want to remove <strong>" + feature_name + "</strong> from this user?";
      this.$widget.removeFeatureDialog.show();
    },
    /**
     * Performs a search-by-flag-name within the inactive features tab. Hides and shows rows.
     * @param {string} searchString - string being searched for
     * @private
     */
    _handleFlagNameSearch: function (searchString) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
      searchString = searchString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      this.$group.inactiveFeatureRows.forEach(function (row) {
        if (row.getAttribute("data-row-feature-id").search(new RegExp(searchString, "i")) === -1) {
          domClass.add(row, "hide");
        } else {
          domClass.remove(row, "hide");
        }
      });
    }
  });
});