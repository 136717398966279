/**
 * Widget built to manage the View state of the Ad Channel (aka Connect) Facebook slat.
 *
 * @module mojo/widgets/ads/facebook/ViewConnectSlat
 * @see mojo/widgets/ads/facebook/EditConnectSlat
 * @see mojo/widgets/ads/_EditSlatMixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/array", "vendor/lodash-amd/filter",
// Extend
"mojo/widgets/ads/facebook/EditConnectSlat",
// Templates
"dojo/text!./edit-connect/templates/ViewConnectSlat.html"], function (declare, lang, arrayUtils, filter, EditConnectSlat, tplStr) {
  return declare([EditConnectSlat], {
    content: tplStr,
    baseClass: "c-adsPlacementSummary",
    // Hide the save and cancel buttons
    actionBarEnabled: false,
    /**
     * Setup the data for this slat.
     *
     * @override
     *
     * @param {obj} data - an object with keys for ad_id (the unique one, of course) and pages/placements.
     */
    setData: function (data) {
      // Assign top-level vars
      this.pages = data.pages;
      this.channels = data.placements;
      this._setPageData();
      this._setDescription();
      this._initChannels();
    },
    /**
     * A big difference between View mode and Edit mode of the connect slat is that View mode
     * doesn't have page selection. As such, the selected page is the first obj in this.pages
     * that is "selected".
     *
     * @override
     *
     * @return {obj} - the selected page object.
     * @private
     */
    _getSelectedPage: function () {
      return filter(this.pages, {
        "selected": true
      })[0];
    },
    /**
     * For the View widget we only want to load channels that are enabled and selected.
     *
     * @override
     *
     * @param {obj} channel - The currently iterated over channel from this.initChannels().
     */
    _createChannel: function (channel) {
      if (channel.enabled && channel.selected) {
        this.inherited(arguments);
      }
    },
    /**
     * Provide a human-readable summary of where their ads are going to be placed.
     *
     * @private
     */
    _setDescription: function () {
      var enabledChannels = filter(this.channels, {
        "enabled": true,
        "selected": true
      });
      var labels = enabledChannels.map(function (channel) {
        return channel.label;
      });
      this.description.innerText = "Your ad will run on " + arrayUtils.toEnglishEnumeration(labels) + ".";
    }
  });
});