/**
 * A widget that shows to the user the number of currently selected items and a button to deselect them.
 */
define([// Core Dependencies
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // Common modules
"dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation",
// Mixins in which this widget extends
"mojo/analytics/parts/_Part", "dijit/_Templated",
// Template
"dojo/text!./templates/DeselectAll.html"], function (declare, lang, on, stringUtils, flags, context, I18nTranslation, _Part, _Templated, tpl) {
  // Extends the _Part mixin which manages visibility and gives access to target behaviors.
  return declare([_Part, _Templated], {
    templateString: tpl,
    str_cta: "Deselect All",
    /**
     * Called by _Part mixin to initialize watchers
     */
    init: function () {
      // Listen for changes to number selected and update view
      this.target.selected.watch(lang.hitch(this, "updateTotal"));

      // Deselect all on button click
      on(this.deselectBtn, "click", lang.hitch(this, function () {
        this.target.toggleAll(false);
      }));
    },
    /**
     * Lifecycle hook that makes updating widget properties before rendering possible.
     */
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function () {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.str_cta = I18nTranslation.translate("content_studio_deselect_all");
      }.bind(this));
      this.inherited(arguments);
    },
    /**
     * Get the count of currently selected items, append the label while handling pluralization, and inject
     * in the dom.
     */
    updateTotal: function () {
      var numSelected = this.target.getSelection().length;
      var countString = numSelected + " " + stringUtils.toPlural("item", numSelected) + " selected";
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        countString = I18nTranslation.translate("content_studio_selected_count", {
          count: numSelected
        });
      }
      this.summary.innerHTML = countString;
    }
  });
});