define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/utils/date"], function (declare, context, flags, dateUtils) {
  var Report = declare([], {
    constructor: function (socialPost, stats) {
      this.socialPost = socialPost;
      this.cumulativeStats = {
        engagements: stats.cumulative.engagements,
        impressions: stats.cumulative.impressions,
        reach: stats.cumulative.reach
      };
      this.statsByPlatform = stats.network_breakdown;
      this.platforms = this.statsByPlatform.map(function (stat) {
        return stat.platform;
      }, this);
      this.lastUpdatedDate = this.statsByPlatform.reduce(function (mostRecentFetchTime, stat) {
        // mostRecenetFetchTime is the reduce accumulator, is it a date?
        var mostRecentFetchTimeDateObj = mostRecentFetchTime.date_str ? new Date(mostRecentFetchTime.date_str) : false;
        // just using it to compare time ints
        var mostRecentFetchTimeAsInt = mostRecentFetchTimeDateObj ? mostRecentFetchTimeDateObj.getTime() : 0;
        var platformLastFetchTimeDateObj = stat.last_report_fetch_time && stat.last_report_fetch_time.date_str ? new Date(stat.last_report_fetch_time.date_str) : false;
        // just using it to compare time ints
        var platformLastFetchTimeAsInt = platformLastFetchTimeDateObj ? platformLastFetchTimeDateObj.getTime() : 0;
        // Keep track of the most recent fetch time
        mostRecentFetchTime = platformLastFetchTimeAsInt > mostRecentFetchTimeAsInt ? stat.last_report_fetch_time : mostRecentFetchTime;
        return mostRecentFetchTime;
      }, this.socialPost.postedAt);
      // with this.lastUpdatedDate as new date object,
      // dateTransform is used to get a JS date object from a
      // date string (date_str) and the timezone offset (tz_float) of that date
      // use this to transform the date_str to add a jsDate to the Object
      // jsDate will be used to render the correct time string in the template
      this.lastUpdatedDate = dateUtils.dateTransform(this.lastUpdatedDate);
    }
  });
  return Report;
});