define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Widgets
"mojo/widgets/contact-profile/activity-feed/EcommOrderItem",
// Templates
"dojo/text!../templates/activity-feed/activity-item-ecomm-order.html", "vendor/pluralize/pluralize", "mojo/context", "mojo/lib/flags"], function (declare, lang, array, domConstruct, domClass, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, EcommOrderItem, activityTplStr, pluralize, context, flags) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    purchaseCount: null,
    items_left: null,
    pluralizedItems: "",
    campaignUrl: "",
    /**
     * Calculate and re-format relevant data for this widget
     */
    postMixInProperties: function () {
      var purchaseCount = 0;
      this.data.order_items.forEach(function (element) {
        purchaseCount += element.quantity;
      });
      this.purchaseCount = purchaseCount;
      this.items_left = this.purchaseCount - 2;
      this.pluralizedItems = this.purchaseCount + pluralize(" item", this.purchaseCount);
      this.orderItemCount = this.data.order_items.length;
      this.order_items = this.data.order_items;
      this.campaignUrl = mUrl.toUrl("/reports/summary?id=" + this.data.outreach_id);
    },
    /**
     * Append ecomm activity to DOM
     * There are 3 flexible containers (left, center, and right) that are filled based
     * on the total number of items in the order
     */
    postCreate: function () {
      switch (this.orderItemCount) {
        case 0:
          break;
        case 1:
          this.createOrderItem(this.order_items[0], this.leftItemContainer);
          break;
        case 2:
          this.createOrderItem(this.order_items[0], this.leftItemContainer);
          this.createOrderItem(this.order_items[1], this.centerItemContainer);
          break;
        case 3:
          this.createOrderItem(this.order_items[0], this.leftItemContainer);
          this.createOrderItem(this.order_items[1], this.centerItemContainer);
          this.createOrderItem(this.order_items[2], this.rightItemContainer);
          break;
        default:
          this.createOrderItem(this.order_items[0], this.leftItemContainer);
          this.createOrderItem(this.order_items[1], this.centerItemContainer);
          if (this.data.order_url) {
            this.createSeeMoreLink();
          } else {
            this.createPlusText();
          }
          break;
      }
      domClass.toggle(this.orderIdWithLink, "hide", !this.data.order_url);
      domClass.toggle(this.orderId, "hide", !!this.data.order_url);
      domClass.toggle(this.campaignInfo, "hide", !this.data.outreach_id);
    },
    /**
     * Create a new order item to show each product (item) in the order
     * @param {object} order_item product/item to show
     * @param {object} destination_container container to place the product/item in
     */
    createOrderItem: function (order_item, destination_container) {
      new EcommOrderItem({
        item: order_item
      }).placeAt(destination_container);
    },
    /**
     * If there are more than 3 items in the order, add a "See More" link to view the whole order
     */
    createSeeMoreLink: function () {
      var link_options = {
        innerHTML: "&#43; " + this.items_left + " more",
        href: this.data.order_url,
        target: "_blank",
        "class": "padding-top--lv2"
      };
      domConstruct.create("a", link_options, this.rightItemContainer);
    },
    createPlusText: function () {
      var text_node_options = {
        innerHTML: "&#43; " + this.items_left + " more",
        "class": "padding-top--lv2"
      };
      domConstruct.create("p", text_node_options, this.rightItemContainer);
    }
  });
});