define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js"], function (xhr, mUrl, domConstruct, dom, win, Deferred, dojoRequire) {
  var braintreeTokenUrl = "/account/billing/generate-braintree-token";
  var makeModalAppear = function () {
    if (dom.byId("bt-pp-cancel")) {
      dom.byId("bt-pp-cancel").click();
    }
    if (dom.byId("braintree-paypal-button")) {
      dom.byId("braintree-paypal-button").click();
    }
  };
  var deviceData = function (formId, merchantId, isProduction) {
    var deviceDataDeferred = new Deferred();
    dojoRequire(["https://js.braintreegateway.com/v1/braintree-data.js"], function () {
      /* eslint-disable no-undef */
      BraintreeData.setup(merchantId, formId, isProduction ? BraintreeData.environments.production : BraintreeData.environments.sandbox);
      /* eslint-enable no-undef */
      deviceDataDeferred.resolve();
    });
    return deviceDataDeferred.promise;
  };
  var showPaypallDeferred;
  var showPaypalNewModal = function (onUnsupport) {
    showPaypallDeferred = new Deferred();
    //create a hidden container, then simulate a click on it to make the paypal modal appear
    var container = "paypal-hidden";
    if (dom.byId(container) === null) {
      //braintree not set up yet, inject it in to dom
      domConstruct.place('<div id="' + container + '" style="display:none"></div>', win.body());
      xhr(mUrl.toUrl(braintreeTokenUrl), {
        handleAs: "json"
      }).then(function (data) {
        dojoRequire(["https://js.braintreegateway.com/v2/braintree.js"], function (braintree) {
          braintree.setup(data.token, "paypal", {
            container: container,
            singleUse: false,
            displayName: "Mailchimp",
            enableBillingAddress: true,
            locale: "us",
            onReady: function () {
              //inject anti-fraud device data then show modal
              deviceData("billing-form", data.merchant_id, data.production).then(makeModalAppear());
            },
            onPaymentMethodReceived: function (obj) {
              showPaypallDeferred.resolve(obj);
            },
            onUnsupported: function () {
              //if any error occurs during paypal flow, such as unsupported browser
              onUnsupport();
            }
          });
        });
      }, function (error) {
        //Handle a failure to get a braintree client setup token
        showPaypallDeferred.resolve(error);
        onUnsupport(error.message);
      });
    } else {
      //braintree was already set up once on the page, so click its button to make it appear
      deviceData("billing-form").then(makeModalAppear());
    }
    return showPaypallDeferred.promise;
  };
  var showPaypal = function (onUnsupport) {
    showPaypallDeferred = new Deferred();
    //create a hidden container, then simulate a click on it to make the paypal modal appear
    var container = "paypal-hidden";
    if (dom.byId(container) === null) {
      //braintree not set up yet, inject it in to dom
      domConstruct.place('<div id="' + container + '" style="display:none"></div>', win.body());
      xhr(mUrl.toUrl(braintreeTokenUrl), {
        handleAs: "json"
      }).then(function (data) {
        dojoRequire(["https://js.braintreegateway.com/v2/braintree.js"], function (braintree) {
          braintree.setup(data.token, "paypal", {
            container: container,
            singleUse: false,
            displayName: "Mailchimp",
            enableBillingAddress: true,
            locale: "us",
            onReady: function () {
              //inject anti-fraud device data then show modal
              deviceData("billing-form", data.merchant_id, data.production).then(makeModalAppear);
            },
            onPaymentMethodReceived: function (obj) {
              showPaypallDeferred.resolve(obj);
            },
            onUnsupported: function (e) {
              //if any error occurs during paypal flow, such as unsupported browser
              onUnsupport();
            }
          });
        });
      }, function (error) {
        //Handle a failure to get a braintree client setup token
        showPaypallDeferred.resolve(error);
        onUnsupport(error.message);
      });
    } else {
      //braintree was already set up once on the page, so click its button to make it appear
      deviceData("billing-form").then(makeModalAppear);
    }
    return showPaypallDeferred.promise;
  };
  return {
    showPayPal: showPaypal,
    showPayPalNewModal: showPaypalNewModal
  };
});