define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/promptPane.html", "mojo/user", "mojo/context", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents", "mojo/lib/logger", "mojo/widgets/experiments/ExperimentLog", "mojo/lib/flags", "mojo/neapolitan/genai/aiassistant/prompt-creator", "mojo/neapolitan/genai/aiassistant/PromptPane/PromptDisplay", "mojo/neapolitan/genai/aiassistant/PromptPane/PromptErrorDisplay", "mojo/neapolitan/genai/aiassistant/PromptPane/ThumbsFeedbackDialog", "mojo/neapolitan/utils", "./tracking-helper"], function (declare, lang, on, domClass, domAttr, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, user, context, EcsTracking, EcsEvents, logger, ExperimentLog, flags, promptCreator, PromptDisplay, PromptErrorDisplay, ThumbsFeedbackDialog, neapUtils, TrackingHelper) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    id: "aiPrompt",
    templateString: tpl,
    // data-dojo-attach-point in the template
    historyList: null,
    // data-dojo-attach-point in the template
    promptInput: null,
    // data-dojo-attach-point in the template
    aiPromptTerms: null,
    // data-dojo-attach-point in the template
    aiPromptPaneBody: null,
    // data-dojo-attach-point in the template
    aiPrompt: null,
    // data-dojo-attach-point in the template
    newTextStartingPoints: null,
    // data-dojo-attach-point in the template
    rewriteText: null,
    // data-dojo-attach-point in the template
    bottomBar: null,
    // data-dojo-attach-point in the template
    cancelBtn: null,
    // data-dojo-attach-point in the template
    originalTextContainer: null,
    // data-dojo-attach-point in the template
    originalTextCopy: null,
    // data-dojo-attach-point in the template
    quickActions: null,
    // data-dojo-attach-point in the template
    customPromptContainer: null,
    // data-dojo-attach-point in the template
    shareFeedback: null,
    // data-dojo-attach-point in the template
    promptPaneHeaderLabel: null,
    _setPromptPaneHeaderLabelAttr: {
      node: "promptPaneHeaderLabel",
      type: "innerHTML"
    },
    // data-dojo-attach-point in the template
    aiDisclaimer: null,
    _aiRefineModeDisclaimerCopy: "AI may produce inaccurate content; you are responsible for your emails.",
    _aiWriteModeDisclaimerCopy: "Generative AI may produce inaccurate or biased information; you are responsible for your email content.",
    writeWithAiCopy: "Write with AI",
    editWithAiCopy: "Edit with AI",
    // starting text to use for the prompt. If null, the first step will be to get AI Assistant to write one.
    startingText: null,
    // Whether a request is being made.
    isLoading: false,
    // This property indicates the mode of the AI assistant.
    // If it's true, the assistant is in "Edit with AI" mode. If it's false, the assistant is in "Write with AI" mode.
    isInRefineMode: false,
    // Tone options that we can use to render select boxes
    toneOptions: null,
    // The id for current block we're editing
    blockId: null,
    dialog: null,
    constructor: function (params) {
      lang.mixin(this, params);

      // Explicitly adding this so each instance gets a new copy.
      this.toneOptions = [{
        value: "Authentic",
        label: "Authentic"
      }, {
        value: "Friendly",
        label: "Friendly"
      }, {
        value: "Grateful",
        label: "Grateful"
      }, {
        value: "Heartfelt",
        label: "Heartfelt"
      }, {
        value: "Informative",
        label: "Informative"
      }, {
        value: "Professional",
        label: "Professional"
      }, {
        value: "Urgent",
        label: "Urgent"
      }, {
        value: "Witty",
        label: "Witty"
      }, {
        value: "Persuasive",
        label: "Persuasive"
      }, {
        value: "Excited",
        label: "Excited"
      }, {
        value: "Playful",
        label: "Playful"
      }];
    },
    _hasDefaultText: function () {
      return neapUtils.blockHasDefaultText(this.blockId);
    },
    setStartingText: function (startingText) {
      // If the startingText is not provided or the block contains default text, the startingText is set to null.
      // Otherwise, it is set to the provided startingText.
      if (!startingText || this._hasDefaultText()) {
        this.startingText = null;
      } else {
        this.startingText = startingText;

        // If the PromptPane improvements are enabled and the assistant is in RefineMode, the originalTextCopy
        // is updated with the startingText and the originalTextContainer is made visible.
        if (this.isInRefineMode) {
          this.originalTextCopy.innerHTML = this.startingText;
          domClass.remove(this.originalTextContainer, "hide");
        }
      }
    },
    postCreate: function () {
      this.own(on(this.promptInput, "promptSubmit", function (value, tone) {
        // Toggle custom prompt container
        domClass.toggle(this.customPromptContainer, "hide");
        this._handlePromptSubmit(value, tone);
        this.shareFeedback.handleGenerationEventFeedbackRefresh();
      }.bind(this)), on(this.newTextStartingPoints, "startingPointClick", function (value) {
        this.promptInput.setInputValue(value);
        this.promptInput.focusAndPlaceCursorAtEnd();

        // Hide suggestion starters
        domClass.add(this.newTextStartingPoints.domNode, "hide");
      }.bind(this)), on(this.cancelBtn, "click", function () {
        EcsTracking.track(EcsEvents.CancelBottomEvent, TrackingHelper.getEventParams());
        this.hide();
      }.bind(this)), on(this.shareFeedback, "thumbsFeedbackSubmitted", function (thumbFeedback) {
        this._handleThumbsFeedbackSubmitted(thumbFeedback);
      }.bind(this)));
      this.quickActions.on("promptShorten", function () {
        if (this.startingText) {
          var extraParams = null;
          extraParams = {
            ai_shortcut_selected_h1: "Shorten"
          };
          EcsTracking.track(EcsEvents.ShortenEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(this.startingText).shorten());
        } else {
          throw new Error("Attempted to shorten starting text with no text");
        }
        this.shareFeedback.handleGenerationEventFeedbackRefresh();
      }.bind(this));
      this.quickActions.on("promptLengthen", function () {
        if (this.startingText) {
          var extraParams = null;
          extraParams = {
            ai_shortcut_selected_h1: "Lengthen"
          };
          EcsTracking.track(EcsEvents.LengthenEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(this.startingText).lengthen());
        } else {
          throw new Error("Attempted to lengthen starting text with no text");
        }
        this.shareFeedback.handleGenerationEventFeedbackRefresh();
      }.bind(this));
      this.quickActions.on("promptToneChange", function (tone) {
        if (this.startingText) {
          var extraParams = {
            ai_shortcut_selected_h1: "Change Tone",
            ai_shortcut_selected_h2: tone,
            ai_tone_selected: tone
          };
          EcsTracking.track(EcsEvents.ChangeToneEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(this.startingText).changeTone(tone));
        } else {
          throw new Error("Attempted to change starting text tone with no text");
        }
        this.shareFeedback.handleGenerationEventFeedbackRefresh();
      }.bind(this));
      this.quickActions.on("promptFixSpelling", function () {
        if (this.startingText) {
          if (context.flagIsOn(flags.EM2_NEA_GENAI_GRAMMAR_SPELLING_SHORTCUT_TRACKING)) {
            var extraParams = {
              ai_shortcut_selected_h1: "correct_spelling_grammar"
            };
            EcsTracking.track(EcsEvents.CorrectSpellingGrammarEvent, TrackingHelper.getEventParams(extraParams));
          }
          this._execPrompt(promptCreator.refine(this.startingText).fixSpelling());
        } else {
          throw new Error("Attempted to correct spelling on starting text with no text");
        }
        this.shareFeedback.handleGenerationEventFeedbackRefresh();
      }.bind(this));
      this.quickActions.on("customPrompt", function () {
        EcsTracking.track(EcsEvents.CustomPromptEvent, TrackingHelper.getEventParams());
        domClass.toggle(this.customPromptContainer, "hide");
        domClass.toggle(this.quickActions.customPromptButton, "active", !this.customPromptContainer.classList.contains("hide"));
      }.bind(this));
    },
    _trackInsertEvent: function (prompt) {
      var event = EcsEvents.ApplyEvent;
      if (prompt) {
        event.campaign_id = window.app.campaignId;
        event.campaign_type = window.app.campaignType;
        event.generated_text = this._trimEventValue(prompt.responseContent);
        event.ai_custom_prompt = this._trimEventValue(prompt.getPromptText());
        event.ai_tone_selected = prompt.getTone();
        event.generation_attempt_count = this.historyList.getLength();
        EcsTracking.track(event);
      }
      ExperimentLog.logOptimizely("nea-gen-ai-click-apply-generated-text");
    },
    /**
     * Handle what happens when the insert button is clicked.
     *
     * Use the current visible prompt and fires the insert event
     *
     * @private
     */
    _handleInsertBtnClick: function () {
      var currentPromptDisplay = this.historyList.getCurrentItem();
      if (currentPromptDisplay) {
        EcsTracking.track(EcsEvents.InsertBottomEvent, TrackingHelper.getEventParams());

        // Mark and track block as gptGenerated
        this._markBlockAsGptGenerated(currentPromptDisplay);
        ExperimentLog.logOptimizely("nea-gen-ai-click-apply-generated-text");
        this.onInsert(currentPromptDisplay.prompt.responseContent);
      }
    },
    _trimEventValue: function (value) {
      if (value && value.length && value.length > 128) {
        return value.substring(0, 125) + "...";
      }
      return value;
    },
    _trackGenerateSuccess: function (prompt) {
      var event = EcsEvents.GenerateEvent;
      if (prompt && prompt.responseStatus === "success") {
        event.campaign_id = window.app.campaignId;
        event.campaign_type = window.app.campaignType;
        event.ai_custom_prompt = prompt.commandParams && prompt.commandParams.text ? this._trimEventValue(prompt.commandParams.text) : "";
        event.generated_text = this._trimEventValue(prompt.responseContent);
        event.intuit_tid = prompt.intuit_tid;
        event.ai_tone_selected = prompt.tone;
        event.ai_shortcut_selected_h1 = "Generate";
        EcsTracking.track(event);
      }
    },
    _trackTryAgainSuccess: function (prompt) {
      if (prompt && prompt.responseStatus === "success") {
        var event = EcsEvents.TryAgainEvent;
        event.campaign_id = window.app.campaignId;
        event.campaign_type = window.app.campaignType;
        event.regenerated_text = this._trimEventValue(prompt.responseContent);
        event.intuit_tid = prompt.intuit_tid;
        event.ai_shortcut_selected_h1 = "Try Again";
        EcsTracking.track(event);
      }
    },
    _handleThumbsFeedbackSubmitted: function (feedback) {
      // add thumb tracking
      EcsTracking.track(EcsEvents.TrackNeaInlineAIFeedbackEvents, TrackingHelper.getEventParamsNeaAIInlineFeedback({
        // insert thumbs_up or thumbs_down as ui_object_detail, effectively sorting into the correct events
        ui_object_detail: this._trimEventValue(feedback),
        feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down"
      }));
      if (feedback !== "thumbs_up") {
        if (this.dialog) {
          // if there is dialog box instance already(from prev feedback interact), ramp it down first and then erase it
          this.dialog.destroy();
          this.dialog = null;
        }
        if (!this.dialog) {
          this.dialog = new ThumbsFeedbackDialog();
          this.dialog.on("thumbsFeedbackTextSubmitted", function (textFeedback, selectedOptions) {
            // thumb feedback + modal text tracking/logging
            EcsTracking.track(EcsEvents.TrackNeaInlineAIFeedbackModalEvent, TrackingHelper.getEventParamsNeaAIInlineFeedback({
              feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
              ui_object_detail: "submit",
              feedback_text: this._trimEventValue(textFeedback),
              feedback_response_choice: selectedOptions,
              entrypoint_detail: this.isInRefineMode ? "bottom_bar" : EcsEvents.TrackNeaInlineAIFeedbackModalEvent.entrypoint_detail
            }));
            logger.info("creative-assistant", "neapolitan ai assistant feedback", TrackingHelper.getEventParams({
              feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
              feedback_text: this._trimEventValue(textFeedback),
              feedback_response_choice: selectedOptions,
              editor_type: "nea"
            }));
          });
          this.dialog.on("thumbsFeedbackModalClosed", function () {
            // modal close tracking
            EcsTracking.track(EcsEvents.TrackNeaInlineAIFeedbackModalCloseEvent, TrackingHelper.getEventParamsNeaAIInlineFeedback({
              feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
              ui_object_detail: "x"
            }));
            logger.info("creative-assistant", "neapolitan ai assistant feedback", TrackingHelper.getEventParams({
              feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
              editor_type: "nea"
            }));
          });
          this.dialog.on("thumbsFeedbackModalTextFieldFocus", function () {
            // modal text field focus tracking
            EcsTracking.track(EcsEvents.TrackNeaInlineAIFeedbackModalTextFieldFocusEvent, TrackingHelper.getEventParamsNeaAIInlineFeedback({
              feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
              ui_object_detail: "free_text"
            }));
          });
          this.dialog.show();
        }
      } else {
        logger.info("creative-assistant", "neapolitan ai assistant feedback", TrackingHelper.getEventParams({
          feedback_type: feedback === "thumbs_up" ? "thumbs up" : "thumbs down",
          editor_type: "nea"
        }));
      }
    },
    _handlePromptSubmit: function (promptText, tone) {
      this._setConversationMode();
      var sourceText = this._getExistingSourceText();
      var prompt;
      if (!sourceText) {
        prompt = promptCreator.create(promptText).withTone(tone);
      } else {
        prompt = promptCreator.refine(sourceText).withFreeformCommand(promptText);
      }
      ExperimentLog.logOptimizely("nea-gen-ai-click-generate-text");
      this._execPrompt(prompt);
    },
    /**
     * Marks block as gptGenerated by tracking each successful transaction id of the generated text.
     * This will be useful when calculating edit distance of the generated text.
     *
     * @param {object} prompt - Response object from GenOS
     * @private
     */
    _markBlockAsGptGenerated: function (prompt) {
      var block = window.app.byId(this.blockId) || null;
      if (block && prompt && prompt.hasOwnProperty("intuit_tid")) {
        // Obtain block data
        var blockData = block.data() || {};

        // Prep gptGenerated object and add transaction id
        var gptGenerated = !!blockData && blockData.gptGenerated || {
          transactionIds: [],
          usedInlineAssistantEditor: true
        };
        gptGenerated.transactionIds.push(prompt.intuit_tid);
        gptGenerated.usedInlineAssistantEditor = true;

        // Update the block
        block.updateData({
          gptGenerated: gptGenerated
        });
      }
    },
    _handlePromptExec: function (prompt) {
      if (prompt.responseStatus === promptCreator.responseStatuses.failure) {
        this.promptError = new PromptErrorDisplay({
          prompt: prompt
        });
        this.promptError.on("promptRetry", function () {
          this._execPrompt(promptCreator.clone(prompt)).then(this._trackTryAgainSuccess.bind(this));
        }.bind(this));
        this.promptError.placeAt(this.aiPromptPaneBody, "first");
        // Show starting state if there's no successful prompts
        if (!this.historyList.getCurrentItem()) {
          if (this.startingText) {
            this._setRewriteMode();
          } else {
            this._setWriteMode();
          }
        }
      } else {
        var promptDisplay = new PromptDisplay({
          prompt: prompt,
          toneOptions: this.toneOptions
        });
        promptDisplay.on("promptInsert", function () {
          this._trackInsertEvent(prompt);

          // Mark and track block as gptGenerated
          this._markBlockAsGptGenerated(prompt);
          this.onInsert(prompt.responseContent);
        }.bind(this));
        promptDisplay.on("promptShorten", function () {
          var extraParams = {
            ai_shortcut_selected_h1: "Shorten"
          };
          EcsTracking.track(EcsEvents.ShortenEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(prompt.responseContent).shorten());
          this.shareFeedback.handleGenerationEventFeedbackRefresh();
        }.bind(this));
        promptDisplay.on("promptLengthen", function () {
          var extraParams = {
            ai_shortcut_selected_h1: "Lengthen"
          };
          EcsTracking.track(EcsEvents.LengthenEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(prompt.responseContent).lengthen());

          // Hide custom prompt container if visible
          if (this.isInRefineMode) {
            domClass.add(this.customPromptContainer, "hide");
          }
          this.shareFeedback.handleGenerationEventFeedbackRefresh();
        }.bind(this));
        promptDisplay.on("promptRetry", function () {
          this._execPrompt(promptCreator.clone(prompt)).then(this._trackTryAgainSuccess.bind(this));
          this.shareFeedback.handleGenerationEventFeedbackRefresh();
        }.bind(this));
        promptDisplay.on("promptToneChange", function (tone) {
          var extraParams = {
            ai_shortcut_selected_h1: "Change Tone",
            ai_shortcut_selected_h2: tone,
            ai_tone_selected: tone
          };
          EcsTracking.track(EcsEvents.ChangeToneEvent, TrackingHelper.getEventParams(extraParams));
          this._execPrompt(promptCreator.refine(prompt.responseContent).changeTone(tone));
          this.shareFeedback.handleGenerationEventFeedbackRefresh();
        }.bind(this));
        promptDisplay.on("promptFeedbackShared", function (feedback) {
          var extraParams = {
            ai_shortcut_selected_h1: "Share Feedback",
            nea_inline_feedback: this._trimEventValue(feedback)
          };
          EcsTracking.track(EcsEvents.SubmitFeedbackModalEvent, TrackingHelper.getEventParams(extraParams));
          logger.info("creative-assistant", "neapolitan ai assistant feedback", TrackingHelper.getEventParams({
            nea_inline_feedback: feedback
          }));
        }.bind(this));
        promptDisplay.on("customPrompt", function () {
          EcsTracking.track(EcsEvents.CustomPromptEvent, TrackingHelper.getEventParams());
          this.promptInput.showRewritePlaceholderCopy();
          domClass.add(this.newTextStartingPoints.domNode, "hide");
          domClass.toggle(this.customPromptContainer, "hide");
          domClass.toggle(promptDisplay.customPromptButton, "active", !this.customPromptContainer.classList.contains("hide"));
        }.bind(this));
        this.historyList.addItem(promptDisplay);
      }
      this._setLoading(false);
      this._toggleInsertButton();

      // Toggle the active state of the generate prompt CTA
      if (this.promptInput) {
        this.promptInput.togglePromptButton();
      }
      return prompt;
    },
    _execPrompt: function (prompt) {
      if (this.isLoading) {
        return;
      }

      // Hide custom prompt container if visible
      domClass.add(this.customPromptContainer, "hide");

      // hide the quick actions, and update margin to the bottom of the 'aiResponseBubble' element.
      if (this.isInRefineMode) {
        domClass.add(this.quickActions.domNode, "hide");
        domClass.add(this.originalTextContainer.querySelector(".aiResponseBubble"), "margin-bottom--lv4");
        domClass.remove(this.originalTextContainer.querySelector(".aiResponseBubble"), "margin-bottom--lv2");
      }
      this._setConversationMode();
      this._setLoading(true);
      // Always Reset error
      if (this.promptError) {
        this.promptError.destroy();
      }
      return prompt.exec().then(this._handlePromptExec.bind(this));
    },
    _getExistingSourceText: function () {
      // Make sure we're operating on the item that's currently being viewed
      var currentPrompt = this.historyList.getCurrentItem();
      if (currentPrompt && currentPrompt.responseText) {
        return currentPrompt.responseText;
      }
      return this.startingText;
    },
    /**
     * Handles setting the loading value along with updating the UI to appropriate display loading indicators
     *
     * @param {boolean} isLoading value to set loading to
     *
     * @private
     */
    _setLoading: function (isLoading) {
      this.isLoading = isLoading;
      this.promptInput.setLoading(this.isLoading);
      this.historyList.setLoading(this.isLoading);
    },
    /**
     * Handle setting the UI to writing new content based on a prompt
     *
     * @private
     */
    _setWriteMode: function () {
      this.isInRefineMode = false;

      // Set up prompt text container & header labels
      this.set("promptPaneHeaderLabel", this.writeWithAiCopy);
      this.promptInput.showNormalPlaceholderCopy();

      // Hide quick actions and original text container
      domClass.add(this.quickActions.domNode, "hide");
      domClass.add(this.originalTextContainer, "hide");

      // Show custom prompt bar
      domClass.remove(this.customPromptContainer, "hide");

      // Hide history list and show prompt starters
      domClass.add(this.historyList.domNode, "hide");
      domClass.remove(this.newTextStartingPoints.domNode, "hide");
    },
    /**
     * Handles setting the UI to rewrite existing content
     *
     * @private
     */
    _setRewriteMode: function () {
      this.isInRefineMode = !this._hasDefaultText();

      // Set up prompt text container & header labels
      this.set("promptPaneHeaderLabel", this.editWithAiCopy);
      this.promptInput.showRewritePlaceholderCopy();
      domClass.add(this.historyList.domNode, "hide");
      domClass.add(this.newTextStartingPoints.domNode, "hide");
      if (this.customPromptContainer && this.aiDisclaimer) {
        domClass.remove(this.customPromptContainer, "margin-top--lv5");
        this.aiDisclaimer.innerHTML = this._aiRefineModeDisclaimerCopy;
      }
    },
    /**
     * Handles setting the UI to the normal writing/conversation mode
     *
     * @private
     */
    _setConversationMode: function () {
      if (this.isInRefineMode) {
        this.promptInput.showRewritePlaceholderCopy();
      } else {
        this.promptInput.showNormalPlaceholderCopy();
      }
      domClass.remove(this.historyList.domNode, "hide");
      if (!this.isInRefineMode) {
        domClass.add(this.newTextStartingPoints.domNode, "hide");
      }
    },
    show: function () {
      // Class uses animation, so let's ensure it's done after dom has been in place
      setTimeout(function () {
        domClass.add(this.domNode, "showPane");
      }.bind(this));

      // Make sure we have the appropriate starting text on load
      // This helps determine what copy to use on the prompt pane's header
      if (!this.startingText) {
        var startingText = neapUtils.stripHTML(window.editor.getData()) || null;
        this.setStartingText(startingText);
      }
      if (this.startingText) {
        this._setRewriteMode();
      } else {
        this._setWriteMode();
      }
    },
    hide: function () {
      domClass.remove(this.domNode, "showPane");
    },
    /**
     * dojo widget event
     *
     * @param {string} textContent text of the generated content
     */
    onInsert: function (textContent) {}
  });
});