/**
 * Color Swatch With Contrast Information
 * @module mojo/widgets/pattern-library/SwatchWithContrast.js
 *
 * Widget that represents the idea of a single swatch. Handles all markup and interactions
 * associated with an individual swatch.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "mojo/lib/clipboard", "mojo/utils",
// Vendor
"vendor/ColorContrastChecker/color-contrast-checker",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/SwatchWithContrast.html"], function (declare, keys, mColors, clipboard, utils, ccc, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    baseClass: "colorSwatchNode",
    color: {},
    colorFormat: "hex",
    status: {},
    textColors: ["coconut", "kale", "peppercorn"],
    /**
     * Do some work before first rendering
     */
    postMixInProperties: function () {
      this.isValidColorCode = ccc.isValidColorCode(this.color.hex);
      if (this.isValidColorCode) {
        this.textColors.forEach(function (foreground) {
          var foregroundHexStr = mColors.brandColors[foreground].hex;
          this.status[foreground] = {
            size1: ccc.isLevelAA(foregroundHexStr, this.color.hex, 16),
            size2: ccc.isLevelAA(foregroundHexStr, this.color.hex, 24)
          };
        }.bind(this));
      }
    },
    /**
     * Copy the selected colorFormat to the clipboard. Publish to the global topic to create a notification.
     *
     * @private
     */
    _copyColor: function () {
      clipboard.copy(this.color[this.colorFormat]);
      utils.globalPublish("show-toast", [{
        "message": this.color[this.colorFormat] + " is copied—paste when you're ready.",
        "type": "success"
      }]);
    },
    /**
     * Respond to click on a swatch node.
     *
     * @param {Event} event - click
     * @private
     */
    _onClick: function (event) {
      event.preventDefault();
      this._copyColor();
    },
    /**
     * Intercept keypress and potentially call method to copy color to clipboard.
     *
     * @param {Event} event - onkeydown
     * @private
     */
    _onKeyDown: function (event) {
      if (event.keyCode === keys.ENTER) {
        this._copyColor();
      }
    }
  });
});