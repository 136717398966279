define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/focus", "mojo/widgets/input/_TagBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/_FocusMixin", "dojo/text!./templates/tagAutocomplete.html", "dijit/form/FilteringSelect", "dojo/store/Memory", "dojox/html/entities"], function (declare, lang, on, keys, domClass, domGeom, domStyle, focusUtil, _TagBase, _TemplatedMixin, _WidgetsInTemplateMixin, _FocusMixin, tpl, FilteringSelect, Memory, htmlEntities) {
  // Extra width offset to prevent text scrolling on the input.
  var TAG_OFFSET_WIDTH = 50;
  return declare([_TagBase, _TemplatedMixin, _WidgetsInTemplateMixin, _FocusMixin], {
    templateString: tpl,
    // the store used to populate the autocomplete
    store: null,
    // set this to false to prevent user input from being overwritten when the result dropdown updates.
    autoComplete: true,
    // see the queryExpr field in _SearchMixin
    queryExpr: "${0}",
    postCreate: function () {
      domClass.add(this.inputField._buttonNode, "hide");

      // apply our flags to our autocomplete widget
      this.inputField.autoComplete = this.autoComplete;
      this.inputField.store = this.store;
      this.inputField.queryExpr = this.queryExpr;
      this.own(
      // Normally, the _FocusMixin plugin would ensure that this widget was properly
      // focused when clicked on, but our FilterSelect widget LOVES to hog focus and
      // do its own thing, so _onFocus isn't called on click, so we have to manually
      // focus this widget when we click on it.
      on(this, "click", lang.hitch(this, function (e) {
        focusUtil.focus(this.domNode);
        e.stopPropagation();
        e.preventDefault();
      })), on(this, "dblclick", lang.hitch(this, "_onDblClick")), on(this.removeBtn, "click", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.destroy();
      })), on(this.inputField, "change", lang.hitch(this, function () {
        // their documentation is wrooooong, the same property (the label)
        // is displayed for both of these. So we have to get the item manually
        // and grab the values we want.
        // // var value = this.inputField.get('value');
        // // var label = this.inputField.get('displayedValue');

        var item = this.inputField.item;
        if (item) {
          this.setValue(item.id);
          this.setLabel(item.name);
        }

        // because the TagList doesn't receive the Enter keyup due to our FilteringSelect
        // widget swallowing it, we have to manually shift focus to the next tag.
        this.inputField.focusNode.blur();
        this.tagList.moveToNewTag();
      })));
      this.setValue(this.value);
      this.viewMode();
    },
    /**
     * Get what the user has currently typed in.
     * @returns {string}
     */
    getCurrentInput: function () {
      return this.inputField.focusNode.value || "";
    },
    isBlank: function () {
      // For this type of tag, we're empty if we don't have a value, like normal,
      // but but we also have to check to make sure that the user hasn't typed
      // anything (if they're currently in edit mode).
      return !this.getValue() && (this.inViewMode() || !this.getCurrentInput());
    },
    setLabel: function (value) {
      this.inherited(arguments);
      this._updateValue();
    },
    viewMode: function () {
      if (this.domNode) {
        domClass.add(this.domNode, "view-mode");
        domClass.remove(this.domNode, "edit-mode");
      }
      if (this.focused) {
        domClass.add(this.domNode, "active");
      }
      this._updateValue();
      this.inputField.focusNode.blur();
      this.inherited(arguments);
    },
    editMode: function () {
      if (this.domNode) {
        domClass.remove(this.domNode, "view-mode");
        domClass.add(this.domNode, "edit-mode");
      }
      this._adjustTextInput();
      focusUtil.focus(this.inputField.focusNode);
      domClass.remove(this.domNode, "active");
      this.inherited(arguments);
    },
    /**
     * Updates the underlying value and the label to point to the current input value
     * @private
     */
    _updateValue: function () {
      this.tagLabel.innerHTML = htmlEntities.encode(this.getLabel());
      this._adjustTextInput();
    },
    /**
     * Helper that resizes the text input based on the content size.
     * Requires the usage of the tag label width as a means to calculating the length of the tag word.
     * @private
     */
    _adjustTextInput: function () {
      var pos = domGeom.position(this.tagLabel);
      var width = pos.w + TAG_OFFSET_WIDTH;
      domStyle.set(this.inputField, "width", width + "px");
    },
    focus: function () {
      // again, the FilterSelect hogs focus, so we have to manually call
      // _onFocus() because our _FocusMixin won't work here.
      focusUtil.focus(this.domNode);
      this._onFocus();
    },
    _onFocus: function () {
      domClass.toggle(this.domNode, "active", this.inViewMode());
      this.inherited(arguments);
    },
    _onBlur: function () {
      domClass.remove(this.domNode, "active");
      this.viewMode();
      this.inherited(arguments);
    },
    /**
     * When a user double clicks on the tag, we want to go into edit mode.
     */
    _onDblClick: function () {
      if (this.inViewMode()) {
        this.editMode();
        this.inputField.select();
      }
    }
  });
});