define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (xhr) {
  return {
    checkGenAiEligibility: function () {
      return xhr.post("/content-generation/check-gen-ai-eligibility", {
        "handleAs": "json",
        "data": {
          "feature": "marketing_gen_ai_features"
        }
      });
    }
  };
});