define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/account/connected-sites", "mojo/lib/logger"], function (declare, _base, domAttr, query, on, connectedSites, logger) {
  return declare([_base], {
    onReady: function () {
      on(this.$el.nextBtn, "click", function (e) {
        if (document.referrer.match(/campaigns\/wizard\/neapolitan|landing-pages\/editor/)) {
          var campaignId = document.referrer.match(/id=\d+/)[0];
          campaignId = campaignId ? campaignId.substring(3) : null;
          logger.info("ecomm-blocks", "Ecomm Blocks - Connected Site Chosen", {
            campaign_id: campaignId
          });
        }
      });

      // Watch for platform selection
      if (this.$group.platformOptions) {
        var manualGetStartedBtn = this.$el.manualGetStartedBtn;
        var platformRadioBtn = this.$group.platformRadio;
        var currentRadioBtn = null;
        platformRadioBtn.forEach(function (radioButton) {
          on(radioButton, "click", function () {
            // uncheck the platform if clicked a second time
            if (currentRadioBtn === this.value) {
              this.checked = false;
              currentRadioBtn = null;
              connectedSites.disableNext();
              if (manualGetStartedBtn) {
                domAttr.remove(manualGetStartedBtn, "disabled", "disabled");
              }
            } else {
              // enable the next btn & disable the Get Started btn if platform selected
              currentRadioBtn = this.value;
              connectedSites.enableNext();
              if (manualGetStartedBtn) {
                domAttr.set(manualGetStartedBtn, "disabled", "disabled");
              }
            }
          });
        });
      }
    }
  });
});