/**
 * Device Interface Mixin
 * This mixin is used as the common code between the different native platforms.
 *
 * Private functions are indicated as such, public functions are free to be overridden.
 * "__methodName" denotes required implementation by children.
 *
 * @mixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url",
// Mixins
"dijit/_WidgetBase"], function (declare, lang, request, mUrl, _WidgetBase) {
  return declare([_WidgetBase], {
    // The simple string we use to differentiate platform types.
    // i.e. "ios" || "android" || "web"
    platformType: null,
    // Allow a child implementation to be disabled in one go
    enabled: true,
    postCreate: function () {
      this._initNativeToWebEvents();
    },
    /**
     * Run this at widget creation time, making sure that we have all of the data we need.
     */
    postMixInProperties: function () {
      this._validateProps();
    },
    /**
     * Public method that Event Router communicates into to indicate that the Router is
     * ready to listen to events. This is used to tell the Native devices that they can
     * start requesting slats.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     */
    actionEventRouterReady: function () {
      this.__actionEventRouterReady();
    },
    /**
     * Public method that Event Router communicates into to indicate that the loaded
     * slat is ready.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     */
    actionSlatLoaded: function () {
      this.__actionSlatLoaded();
    },
    /**
     * Public method that Event Router communicates into to indicate that the slat has successfully saved.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     *
     * @param {bool} shouldReload - Pass reload state onto the device
     */
    actionSlatFinished: function (shouldReload) {
      this.__actionSlatFinished(shouldReload);
    },
    /**
     * Public method that Event Router communicates into to indicate that the slat did not save successfully.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     */
    actionSlatSaveFailed: function () {
      this.__actionSlatSaveFailed();
    },
    /**
     * Public method that Event Router communicates into to indicate that the page session
     * has timed out. This'll allow the device interface to know that they need to refresh the view.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     */
    actionExpiredSession: function () {
      this.__actionExpiredSession();
    },
    /**
     * Public method that Event Router communicates into to indicate that a user opened the file browser.
     * This won't be implemented on anything except for Android.
     *
     * Each device interface implementation will handle this differently. Call out to
     * a required config method.
     */
    actionOpenFileBrowse: function () {
      this.__actionOpenFileBrowse();
    },
    /**
     * We need to create an object at window-level that Native devices can call methods in.
     * Establish those methods and listen for when they are called.
     *
     * @private
     */
    _initNativeToWebEvents: function () {
      window.nativeEvents = {
        "userInSlat": lang.hitch(this, "_notifyRouterSetSlat"),
        "slatSaved": lang.hitch(this, "_notifyRouterBtnPress", "save"),
        "slatCanceled": lang.hitch(this, "_notifyRouterBtnPress", "cancel"),
        "backPressed": lang.hitch(this, "_notifyRouterBtnPress", "back"),
        "fileUploadSuccess": lang.hitch(this, "_notifyRouterFileUploadSuccessful")
      };
    },
    /**
     * Notify Event Router that the selected slat has changed.
     *
     * @param {string} slatName - One of the valid slat names for the current campaign.
     * @private
     */
    _notifyRouterSetSlat: function (slatName) {
      this.set("selectedSlat", {
        name: slatName,
        randToken: Math.random()
      });
    },
    /**
     * Notify Event Router that a hardware or software button has been pressed.
     * Use Math.random() since it doesn't matter what the value is, simply that it's
     * different.
     *
     * @param {string} btnName - save, cancel, or back.
     * @private
     */
    _notifyRouterBtnPress: function (btnName) {
      this.set(btnName, Math.random());
    },
    /**
     * Notify Event Router that an image has been uploaded successfully.
     *
     * @param {object} uploadedImageObject - Object containing all data associated with the image (name, id, url, etc)
     * @private
     */
    _notifyRouterFileUploadSuccessful: function (uploadedImageObject) {
      this.set("uploadedImage", {
        imageProperties: uploadedImageObject,
        randToken: Math.random()
      });
    },
    /**
     * There are properties that will need to be configured on all child widgets.
     *
     * @throws if props aren't valid.
     * @private
     */
    _validateProps: function () {
      if (!this.platformType) {
        throw new Error("platformType name undeclared. It is required.");
      }

      // We will be rolling out types somewhat separately, we need a method in which to provide
      // error feedback.
      if (this.enabled === false) {
        throw new Error("The '" + this.platformType + "'" + " platform type is not currently enabled.");
      }
    },
    /**
     * Notify the Device that the Event Router is fully ready to open slats.
     *
     * @throws if not overridden by child instance.
     */
    __actionEventRouterReady: function () {
      throw new Error("actionEventRouterReady has not been implemented by the " + this.platformType + " platform type");
    },
    /**
     * Respond to the notification that the slat is ready.
     *
     * @throws if not overridden by child instance.
     */
    __actionSlatLoaded: function () {
      throw new Error("actionSlatLoaded has not been implemented by the " + this.platformType + " platform type");
    },
    /**
     * Respond to the notification that the slat is finished.
     *
     * @throws if not overridden by child instance.
     */
    __actionSlatFinished: function () {
      throw new Error("actionSlatFinished has not been implemented by the " + this.platformType + " platform type");
    },
    /**
     * Respond to the notification that the slat save was attempted but there was validation errors in the slat.
     *
     * @throws if not overridden by child instance.
     */
    __actionSlatSaveFailed: function () {
      throw new Error("actionSlatSaveFailed has not been implemented by the " + this.platformType + " platform type");
    },
    /**
     * Respond to the notification that the page is no longer authed.
     * Tells Native to refresh the view.
     *
     * @throws if not overridden by child instance.
     */
    __actionExpiredSession: function () {
      throw new Error("actionExpiredSession has not been implemented by the " + this.platformType + " platform type");
    },
    /**
     * Respond to the notification that the Native Environment should open the file browser.
     *
     * @throws if not overridden by child instance.
     */
    __actionOpenFileBrowse: function () {
      throw new Error("actionOpenFileBrowse has not been implemented by the " + this.platformType + " platform type");
    }
  });
});