define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "./_WithLabelMixin"], function (declare, domConstruct, Select, _WithLabelMixin) {
  return declare([Select, _WithLabelMixin], {
    label: "Width",
    defaultOptions: [{
      label: "100%",
      value: "1 1 100%"
    }, {
      label: "90%",
      value: "0 0 90%"
    }, {
      label: "80%",
      value: "0 0 80%"
    }, {
      label: "70%",
      value: "0 0 70%"
    }, {
      label: "60%",
      value: "0 0 60%"
    }, {
      label: "50%",
      value: "0 0 50%"
    }, {
      label: "25%",
      value: "0 0 25%"
    }],
    constructor: function (props) {
      // set options from props or default set
      this.options = props.options || this.defaultOptions;
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      // create the narrow select box with grid layout unit, without a size, so the contents dictate size.
      this.selectContainer = domConstruct.place("<div class='unit !padding-left--lv0 full-width--xltablet'></div>", this.domNode.parentNode);
      domConstruct.place(this.domNode, this.selectContainer);
    }
  });
});