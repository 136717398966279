/**
 * TextBlockStyleWarningMixin
 * Contains a Mixin for Blocks with Text Editing to warn about inline style
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/neapolitan/mobile/utils"], function (declare, lang, array, utils, MobileUtils) {
  var _inlineTextStyleConfig = {
    tags: ["h1", "h2", "h3", "h4"],
    attributes: ["style"]
  };
  return declare(null, {
    postCreate: function () {
      this.inherited(arguments);
      // If we need this warning in Desktop, this is easily removed
      // the Mixin can then be added to Desktop Blocks, and removed from Mobile blocks to be universal
      var isMobile = MobileUtils.getNativeObject();
      // for Classic Campaigns there is no style tab, so you do not need the warning for inline style
      var blockContent;
      if (isMobile) {
        // conditional needed to differiate from Classic and Custom which use mcedit content
        if (this.block) {
          blockContent = this._getContentFromBlock(this.block);
        }

        // for Coded (if different than Classic)
        if (blockContent) {
          this._checkContentForInlineStyles(blockContent);
        }
      }
    },
    /**
     * _getContentFromBlock return an array of content strings if the block has content
     * blocks can have a variety of content types: content(array of strings), caption(Object), captions (array of Objects)
     * @param {Object} block will have the content or caption(s)
     * @return {Array} contentArray array of content as strings
     */
    _getContentFromBlock: function (block) {
      var contentArray;
      if (block.content) {
        return block.content;
      }
      if (block.caption) {
        return [block.caption.text];
      }
      if (block.captions) {
        return block.captions.map(function (caption) {
          return caption.text;
        });
      }
      return false;
    },
    /**
     * Scan block content entries for tags and attributes outlined in _inlineStyleConfig
     * @param {String} str string to test if contains tags or attributes from the config
     * @return {Boolean} if content string has inline styles from config
     */
    _checkContentStringForStyles: function (str) {
      var tagsWithInlineStyles = _inlineTextStyleConfig.tags.filter(function (tag) {
        return str && str.indexOf("<" + tag) !== -1;
      });
      var attrsWithInlineStyles = _inlineTextStyleConfig.attributes.filter(function (attribute) {
        return str && str.indexOf(attribute + "=") !== -1;
      });
      return tagsWithInlineStyles.length > 0 || attrsWithInlineStyles.length > 0;
    },
    /**
     * Check for inline styles with _checkContentStringForStyles
     * @param {Array} contentArr array from block.contents, the entries for single or multiple columns
     */
    _checkContentForInlineStyles: function (contentArr) {
      var contentWithStyles = contentArr.filter(lang.hitch(this, function (content) {
        return this._checkContentStringForStyles(content);
      }));
      if (contentWithStyles.length > 0) {
        // globalPublish is used with a subscribe in the StyleEditor.js file to show the warning
        utils.globalPublish("current/block/has/inline/style", {
          hasStyles: true
        });
      }
    }
  });
});