define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/blocks/ProductRecEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/ProductRecEditor/editorMobile.html", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, ProductRecEditor, _MobileBlockMixin, tmpl, _DynamicContentMixin) {
  return declare([ProductRecEditor, _MobileBlockMixin, _DynamicContentMixin], {
    type: "productRec-mobile",
    templateString: tmpl,
    storeMap: null,
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});