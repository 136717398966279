define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "./_WithLabelMixin"], function (declare, array, Select, _WithLabelMixin) {
  return declare([Select, _WithLabelMixin], {
    label: "Line height",
    constructor: function (props) {
      this.options = [{
        label: "Normal",
        value: "100%"
      }, {
        label: "Slight",
        value: "125%"
      }, {
        label: "1 1/2 spacing",
        value: "150%"
      }, {
        label: "Double space",
        value: "200%"
      }];
      if (props.isOptional) {
        this.options.unshift({
          label: "Not specified",
          value: ""
        });
      }
    }
  });
});