define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dijit/registry", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/number", "mojo/url", "mojo/lib/logger", "vendor/pluralize/pluralize", "mojo/widgets/input/InfoSelect", "dojo/text!./templates/UpdateWebsiteAudience.html", "dojo/NodeList-traverse", "dojo/NodeList-manipulate"], function (declare, _Widget, _Templated, registry, lang, on, domAttr, dom, query, xhr, domConstruct, numberUtil, mUrl, logger, pluralize, InfoSelect, template) {
  return declare([_Widget, _Templated], {
    templateString: template,
    audiences: [],
    // set from controller

    website: [],
    // set from controller

    audienceCountById: [],
    confirmDeleteDialog: null,
    audienceSelectLabelTemplate: '<label for="audience-select" class="v-visuallyHidden">Select an Audience</label>',
    audienceSubmitButtonTemplate: '<button data-dojo-type="dijit/form/Button" type="submit" class="button p1" tabindex="1">Update Audience</button>',
    postCreate: function () {
      this.confirmDeleteDialog = registry.byId("confirm-delete-audience-dialog");
      this.audienceSelectCell = dom.byId("audience-select-cell");
      this.audienceSelectControls = dom.byId("audience-update-controls");

      // Insert website name
      this.websiteName.textContent = this.website.name;

      // Insert website link and label
      domAttr.set(this.websiteLink, "href", "/website/dashboard?id=" + this.website.id);
      this.websiteLink.textContent = this.website.name;

      // Initialize audience select if the user has an audience available
      // to switch their website to
      if (Object.keys(this.audiences).length) {
        this._createAudienceSelect();
        domConstruct.place(this.audienceSubmitButtonTemplate, this.audienceSelectControls, "first");

        // Track submit click
        on(query('button[type="submit"]', this.audienceSelectControls)[0], 'click', function () {
          logger.googleAnalytics.trackEvent("audience", "click", "audience_still_in_use:update_audience");
        });
      } else {
        this.audienceSelectCell.textContent = this.website.audienceName;
        this.audienceMemberCount.textContent = this._formatMemberCount(this.website.subscriberCount);
      }
      if (this.confirmDeleteDialog) {
        // Update the title of the dialog
        this.confirmDeleteDialog.set("title", "Audience still in use");

        // Track cancel button click
        query('.cancel-button', this.audienceSelectControls).on('click', function () {
          logger.googleAnalytics.trackEvent("audience", "click", "audience_still_in_use:cancel");
        });
        query('form', this.confirmDeleteDialog.domNode).on('submit', lang.hitch(this, function (e) {
          e.preventDefault();
          xhr.post(mUrl.toUrl('lists/update-website-audience', {
            'audience-select': this.audienceSelect.get("value"),
            'websiteId': this.website.id
          })).then(lang.hitch(this, function () {
            this.confirmDeleteDialog.reload();
          }));
        }));
      }
      ;
    },
    _createAudienceSelect: function () {
      var options = [];
      for (var index in this.audiences) {
        var audience = this.audiences[index];
        options.push({
          value: audience.id,
          label: audience.name,
          meta: this._formatMemberCount(audience.contactCount) + " " + pluralize('Contact', audience.contactCount)
        });

        // Map audience contact count to the audience's id
        this.audienceCountById[audience.id] = this._formatMemberCount(audience.subscriberCount);
      }
      ;
      this.audienceSelect = new InfoSelect({
        class: "!margin--lv0",
        id: "audience-select",
        name: "audience-select",
        maxHeight: "-1",
        required: "true",
        options: options
      });
      on(this.audienceSelect, "change", lang.hitch(this, function () {
        this._updateAudienceMemberCount();
        logger.googleAnalytics.trackEvent("audience", "click", "audience_still_in_use:audience_dropdown");
      }));
      domAttr.set(this.audienceSelect, "tabindex", "1");

      // Place both the select and the label template
      domConstruct.place(this.audienceSelectLabelTemplate, this.audienceSelectCell, "first");
      this.audienceSelect.placeAt(this.audienceSelectCell);

      // Set initial contact count value
      this._updateAudienceMemberCount();
    },
    _updateAudienceMemberCount: function () {
      this.audienceMemberCount.textContent = this.audienceCountById[this.audienceSelect.get("value")];
    },
    _formatMemberCount: function (number) {
      return numberUtil.toFormat(number, 0, ".", ",");
    }
  });
});