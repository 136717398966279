define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojox/html/entities",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/add-note.html",
// API
"./Api"], function (declare, lang, on, xhr, domClass, topic, mUrl, htmlEntities, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, ContactProfileEvent, noteTplStr, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: noteTplStr,
    noteHeader: null,
    memberID: null,
    noteID: null,
    editSuccessCallback: null,
    maxChars: 1000,
    charCount: 0,
    remainingChars: 1000,
    noteText: null,
    showCancel: null,
    cancelFunction: null,
    isArchived: false,
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      domClass.remove(this.noteContainer, "hide");
      this.setupInput();
      if (this.isArchived) {
        domClass.add(this.noteContainer, "hide");
      }
    },
    /**
     * Set up note input initial state:
     * 1. Remaining characters label
     * 2. On save and onkeyup event handlers
     * 3. Correct header and value for edit and add modes
     */
    setupInput: function () {
      //if user is editing an existing note
      if (this.noteText) {
        this.noteTextarea.value = this.noteText;
        this.noteHeader.innerText = "Edit Note";
        this.saveNoteButton.innerText = "Save Note";
        if (this.showCancel && this.cancelFunction) {
          domClass.remove(this.cancelNoteButton, "hide");
          on(this.cancelNoteButton, "click", lang.hitch(this, "cancelFunction"));
        }
      } else {
        //if user is authoring a new note
        this.noteHeader.innerText = "Write a Note";
      }
      on(this.saveNoteButton, "click", lang.hitch(this, "saveNote"));
      on(this.noteTextarea, "click", lang.hitch(this, "handleNoteTextareaClick"));
      on(this.noteTextarea, "keyup", lang.hitch(this, "updateCharCount"));
      this.updateCharCount();
    },
    /**
     * Update remaining characters label and button status based on char count
     */
    updateCharCount: function () {
      this.charCount = this.noteTextarea.value.length;
      this.remainingChars = this.maxChars - this.charCount;
      this.noteText = this.noteTextarea.value;
      this.remainingCharsContainer.innerHTML = htmlEntities.encode(this.remainingChars.toString());
      if (this.charCount > 0 && this.charCount <= this.maxChars) {
        domClass.remove(this.saveNoteButton, "disabled");
      } else {
        domClass.add(this.saveNoteButton, "disabled");
      }
    },
    /**
     * Api request to save note to list member
     */
    saveNote: function () {
      if (this.memberID && this.charCount > 0) {
        if (this.noteID) {
          api.editNote(this.memberID, this.noteText, this.noteID, this.editSuccessCallback);
        } else {
          api.addNote(this.memberID, this.noteText, lang.hitch(this, "resetInput"));
        }
        var event = new ContactProfileEvent("audience:submitted", {
          action: "submitted",
          ui_object: "button",
          ui_object_detail: "add_note",
          ui_action: "clicked",
          ui_access_point: "center",
          event_origin: "dojo"
        });
        event.publishEvent();
      }
    },
    handleNoteTextareaClick: function () {
      var event = new ContactProfileEvent("audience:engaged", {
        action: "engaged",
        ui_object: "text_field",
        ui_object_detail: "write_an_internal_note_here",
        ui_action: "clicked",
        ui_access_point: "center"
      });
      event.publishEvent();
    },
    /**
     * Reset data and view
     */
    resetInput: function () {
      this.noteTextarea.value = "";
      this.updateCharCount();
      topic.publish("contact-profile/note-added");
    }
  });
});