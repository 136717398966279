define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dijit/registry"], function (declare, topic, base, mUrl, tabContainerUtil, FilterMenu, FilterMessage, registry) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    brandedDomainsTabs: [],
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.brandedDomainsTabs.forEach(function (tab) {
        tab.preload = true;
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      topic.subscribe("mojo/analytics/List/branded-domain-list/render", function (list) {
        self.parse();
        tabContainer.brandedDomainsSection.set("title", "Branded Domains (" + list.total + ")");
      });
      topic.subscribe("mojo/analytics/List/branded-domain-deleted-list/render", function (list) {
        self.parse();
        tabContainer.deletedBrandedDomainsSection.set("title", "Deleted Branded Domains (" + list.total + ")");
      });
    }
  });
});