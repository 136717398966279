define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/lib/flags", "mojo/user"], function (declare, base, lang, on, registry, flags, user) {
  return declare([base], {
    // captcha enabled value passed by controller
    "captchaEnabled": null,
    // captcha user enabled value passed by controller
    "captchaUserEnabled": null,
    // double optin status passed by controller
    "doubleOptin": null,
    onReady: function () {
      // Register double optin switch
      this.optinSwitch = registry.byId("double-optin");

      // Register reCAPTCHA checkbox
      this.recaptchaInput = registry.byId("enable_captcha");

      // Set reCAPTCHA to corresponding checkbox value
      if (this.doubleOptin) {
        this.recaptchaInput.set("checked", this.captchaEnabled === "Y");
      } else {
        this.recaptchaInput.set("checked", this.captchaUserEnabled === "Y");
      }

      // Update reCAPTCHA checkbox on double optin toggle
      on(this.optinSwitch, "change", lang.hitch(this, function () {
        // Update reCAPTCHA to corresponding checkbox value
        if (this.optinSwitch.checked) {
          this.recaptchaInput.set("checked", this.captchaEnabled === "Y");
        } else {
          this.recaptchaInput.set("checked", this.captchaUserEnabled === "Y");
        }
      }));
      window.dataLayer.push({
        event: "e_pageLoad",
        destination: "list_detail",
        user_id: this.userId,
        login_id: this.loginId,
        list_id: this.listId
      });
    }
  });
});