define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/segmentation/Segment", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, Segment, on, lang) {
  return declare([base], {
    listId: null,
    onReady: function () {
      this.segmentEditor = new Segment({
        "listId": this.listId,
        "savedAST": this._loadFromStorage()
      }, this.$el.segmentDom);
      on(this.$el.saveBtn, "click", lang.hitch(this, "_saveToStorage"));
      on(this.$el.clearBtn, "click", lang.hitch(this, "_clearStorage"));
    },
    _clearStorage: function (str) {
      sessionStorage.removeItem(this.listId + "_ast");
    },
    _saveToStorage: function () {
      var ast = this.segmentEditor.getAST();
      sessionStorage.setItem(this.listId + "_ast", JSON.stringify(ast));
    },
    _loadFromStorage: function () {
      var savedAST = null;
      try {
        savedAST = sessionStorage.getItem(this.listId + "_ast");
        savedAST = JSON.parse(savedAST);
      } catch (e) {
        // do nothing.
      }
      return savedAST;
    }
  });
});