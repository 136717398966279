define(["dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/_base/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "codemirror4/lib/codemirror", "codemirror4/mode/htmlmixed/htmlmixed"], function (domConstruct, declare, base, dom, on, request, domForm, mUrl, xhr, query, array, CodeMirror) {
  return declare([base], {
    onReady: function () {
      var self = this;
      var hiddenFormHtml = self.$el.hiddenHtml;
      var hiddenFormDiffMode = self.$el.hiddenDiffMode;
      var diffMode = self.$el.diffMode;
      var loadButton = self.$el.loadBtn;
      var clearButton = self.$el.clearBtn;
      var htmlEditor = this.applyCodeMirrorTo("template-html");

      // To get around not being able to start a file download via XHR request, we populate a hidden form
      // field with the editor's contents when they change, and make "Export" a form submit button
      on(htmlEditor, "change", function () {
        hiddenFormHtml.value = htmlEditor.getValue();
      });
      on(diffMode, "change", function () {
        hiddenFormDiffMode.value = diffMode.value;
      });
      on(loadButton, "click", function () {
        request.post(mUrl.toUrl("/peaches2/tools/html-sanitizer-diff/generate-diff"), {
          data: {
            in_html: htmlEditor.getValue(),
            diff_mode: diffMode.value
          },
          "handleAs": "text"
        }).then(function (text) {
          self.$el.contentDom.innerHTML = text;
        });
      });
      on(clearButton, "click", function () {
        domConstruct.empty(self.$el.contentDom);
      });
      hiddenFormHtml.value = "";
      hiddenFormDiffMode.value = diffMode.value;
    },
    applyCodeMirrorTo: function (textAreaID) {
      var _textArea = dom.byId(textAreaID);
      if (_textArea) {
        var htmlEditor = CodeMirror.fromTextArea(_textArea, {
          mode: "text/html",
          autofocus: true,
          tabMode: "indent",
          indentUnit: 2,
          smartIndent: false,
          theme: "default",
          autoClearEmptyLines: true,
          lineWrapping: true,
          lineNumbers: true
        });
        setTimeout(function () {
          htmlEditor.setOption("theme", "default");
        }, 5);
        var hlLine = htmlEditor.addLineClass(0, "activeline");
        htmlEditor.on("cursorActivity", function () {
          var cur = htmlEditor.getLineHandle(htmlEditor.getCursor().line);
          if (cur !== hlLine) {
            htmlEditor.removeLineClass(hlLine, "background", "activeline");
            hlLine = htmlEditor.addLineClass(cur, "background", "activeline");
          }
        });
        htmlEditor.on("change", function () {
          _textArea.value = htmlEditor.getValue();
        });
        htmlEditor.focus();
        return htmlEditor;
      }
    }
  });
});