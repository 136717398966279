define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/MenuItem", "mojo/app/surveys"], function (declare, MenuItem, surveys) {
  return declare([MenuItem], {
    surveyId: null,
    // populated by data-dojo-props

    postCreate: function () {
      this.set("label", "Replicate");
    },
    onClick: function () {
      surveys.replicate(this.surveyId).then(function (data) {
        window.location.href = "/lists/surveys/edit?survey_id=" + data.replicaSurveyId;
      });
    }
  });
});