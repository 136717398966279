/**
 * Slat mixin to be used as the common code between different slats.
 * Private functions are indicated as such, public functions are free to be overridden.
 *
 * @mixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/EditSlatWrapper.html"], function (declare, lang, domAttr, xhr, topic, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, wrapperTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    // Child widgets will all have their own templates that will inject into this one
    templateString: wrapperTplStr,
    // Required to be configured on all child widgets
    postEndpoint: null,
    // Required to be configured on all child widgets
    slatId: null,
    // Show the save and cancel buttons by default
    actionBarEnabled: true,
    /**
     * Before we render the edit slat, we need to verify the children are passing required props and that we are
     * adding in the proper templates.
     */
    postMixInProperties: function () {
      this._validateRequiredProps();
      this._buildTemplate();
    },
    /**
     * Setup the data for this slat. This will usually be called from the Checklist on render.
     * To be implemented by all child widgets
     *
     * @param {obj} data - Dependent upon child implementation.
     */
    setData: function (data) {},
    /**
     * What should we do after someone saves the slat? You'll probably want to massage form data and eventually
     * call postForm().
     * To be implemented by all child widgets
     */
    handleSave: function () {},
    /**
     * Post a form to the endpoint appropriate for the child widget.
     *
     * @param {obj} formData - Key value pairs of the form names and values
     */
    postForm: function (formData) {
      var action = xhr.post(mUrl.toUrl(this.postEndpoint), {
        "handleAs": "json",
        "data": formData
      });
      action.then(lang.hitch(this, "_onPostResponse"));
    },
    /**
     * Call back for handling a successful POST
     * To be implemented by all child widgets though we are setting a default here.
     *
     * @param {obj} response - response containing data about the response and any messaging
     */
    onPostSuccess: function (response) {
      if (this.cancelButton) {
        // The cancel button has a listener attached that effectively loads the view. Simulate a click.
        this.cancelButton.click();
        return;
      }

      // Most implementations either override this method or have the cancelButton node.
      // This throw gives feedback if those two conditions aren't met.
      throw new Error("Slat save successful but closing of the slat has failed.");
    },
    /**
     * Call back for handling a POST with an error
     * To be implemented by all child widgets
     *
     * @param {obj} response - response containing data about the response and any messaging
     */
    onPostError: function (response) {},
    /**
     * Event other widgets can tap into to know when error feedback has been successfully given.
     */
    onPostErrorFeedbackGiven: function () {},
    /**
     * There are properties that will need to be configured on all child widgets. Throws error if needs
     * aren't met.
     *
     * @private
     */
    _validateRequiredProps: function () {
      if (!this.slatId) {
        throw new Error("slatId unimplemented. It is required.");
      }
      if (!this.postEndpoint) {
        throw new Error("postEndpoint unimplemented. It is required.");
      }
    },
    /**
     * It's expected that all children will have templates of their own. Considering we want most of them to have the
     * same save and cancel buttons, inject the child template into the appropriate section of the mixins wrapper.
     *
     * @private
     */
    _buildTemplate: function () {
      this.templateString = this.actionBarEnabled ? lang.replace(this.templateString, {
        "_content": this.content
      }) : this.content;
    },
    /**
     * Publish a PubSub event that other JS can listen to.
     *
     * We are trying to phase out the PubSub method in the Checklist interface. Whenever possible,
     * we should depend on render listeners within ChecklistInterface.
     * @deprecated
     *
     * @param {obj} response - Response data.
     * @private
     */
    _publishUpdate: function (response) {
      topic.publish("campaigns/checklist-interface", lang.mixin({
        itemId: this.slatId
      }, response));
    },
    /**
     * Listen for what the response of the POST was and route to the appropriate callbacks.
     *
     * @param {obj} response - response containing data about the response and any messaging
     * @private
     */
    _onPostResponse: function (response) {
      this._publishUpdate(response);
      switch (response.status) {
        case "success":
          this.onPostSuccess(response);
          break;
        case "error":
          this.onPostError(response);
          this.onPostErrorFeedbackGiven();
          break;
        default:
          throw new Error("Unknown state " + response.status);
      }
    },
    /**
     * Child templates have the potential to omit parts of the action bar.
     * Check for the existence of the cancel button before setting attributes.
     *
     * Follows the custom setter approach: https://dojotoolkit.org/reference-guide/1.10/quickstart/writingWidgets.html#custom-setters-getters
     *
     * @private
     */
    _setSlatIdAttr: function () {
      if (this.cancelButton) {
        domAttr.set(this.cancelButton, "data-mc-slat", this.slatId);
      }
    }
  });
});