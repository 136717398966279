define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/InfoBlock.html", "mojo/user"], function (declare, domAttr, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, infoBlockTemplateString, user) {
  /**
   * @module mojo/widgets
   * @class InfoBlock
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: infoBlockTemplateString,
    imageSrc: null,
    headerText: null,
    infoText: null,
    //Link params are optional
    linkText: null,
    linkHref: null,
    settingId: null,
    addClass: null,
    boldText: null,
    //Optional icon 
    type: "info",
    containerClass: null,
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      if (this.linkText && this.linkHref) {
        this.infoLink.innerText = this.linkText;
        domAttr.set(this.infoLink, "href", this.linkHref);
        domClass.remove(this.infoLink, "hide");
      }
      if (this.addClass) {
        domClass.add(this.newClass, this.addClass);
      }
      if (this.boldText) {
        this.additionalText.innerText = this.boldText;
        domClass.remove(this.additionalText, "hide");
      }
      this.connect(this.closeIcon, "click", function () {
        if (this.settingId) {
          user.saveSetting(this.settingId, "true");
        }
        this.destroy();
      });
      var icons = {
        success: "tick-circle",
        warning: "warn-circle",
        error: "not-allowed",
        info: "info-circle"
      };
      if (this.icon) {
        domClass.add(this.domNode, this.type);
        domClass.add(this.domNode, this.containerClass);
        domClass.add(this.icon, icons[this.type]);
      }
    }
  });
});