define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl", "dojox/dtl/Context", "mojo/url", "dojo/text!../editors/blocks/templates/SocialFollowEditor/outputWeb.html", "dojo/text!../editors/blocks/templates/SocialFollowEditor/_verticalWeb.html", "dojo/text!../editors/blocks/templates/SocialFollowEditor/_horizontalWeb.html", "dojo/text!../editors/blocks/templates/SocialFollowEditor/_itemWeb.html", "../email-renderers/_Renderer"], function (declare, lang, dtl, dtlContext, mUrl, tpl, verticalTpl, horizontalTpl, itemTpl, _Renderer) {
  var justifyContentMap = {
    "center": "center",
    "left": "flex-start",
    "right": "flex-end"
  };
  var flexDirectionIconMap = {
    "inline": "row",
    "stacked": "column"
  };
  var defaultText = {
    "facebookFollow": "Facebook",
    "twitterFollow": "Twitter",
    "linkedInFollow": "LinkedIn",
    "youTubeFollow": "YouTube",
    "pinterestFollow": "Pinterest",
    "googlePlusFollow": "Google Plus",
    "emailLink": "Email",
    "tumblr": "Tumblr",
    "instagram": "Instagram",
    "snapchat": "Snapchat",
    "githubFollow": "Github",
    "vimeoFollow": "Vimeo",
    "vineFollow": "Vine",
    "mediumFollow": "Medium",
    "redditFollow": "Reddit",
    "flickrFollow": "Flickr",
    "dribbbleFollow": "Dribbble",
    "spotifyFollow": "Spotify",
    "houzzFollow": "Houzz",
    "vkFollow": "VKontakte",
    "tiktok": "TikTok",
    "threads": "Threads",
    "twitch": "Twitch",
    "whatsapp": "WhatsApp",
    "rssFollow": "RSS",
    "genericLink": "Website"
  };
  return declare("mojo.neapolitan.web-renderers.SocialFollowBlock", [_Renderer], {
    outputTplStr: tpl,
    constructor: function () {
      this.verticalTemplate = new dtl.Template(verticalTpl);
      this.horizontalTemplate = new dtl.Template(horizontalTpl);
      this.socialItemTemplate = new dtl.Template(itemTpl);
    },
    _getBlockTemplate: function (blockLayout) {
      switch (blockLayout) {
        case "vertical":
          return this.verticalTemplate;
        case "horizontal":
        default:
          return this.horizontalTemplate;
      }
    },
    defaultText: defaultText,
    _getServiceIconUrl: function (item, iconStyle, iconType, itemArrangement) {
      var iconName;
      var iconSize;
      var urlPrefix = "http://cdn-images.mailchimp.com/icons/social-block-v2/";
      var urlSuffix = ".png";
      switch (item.type) {
        case "facebookFollow":
          iconName = "facebook";
          break;
        case "twitterFollow":
          iconName = "twitter";
          break;
        case "linkedInFollow":
          iconName = "linkedin";
          break;
        case "youTubeFollow":
          iconName = "youtube";
          break;
        case "pinterestFollow":
          iconName = "pinterest";
          break;
        case "googlePlusFollow":
          iconName = "googleplus";
          break;
        case "emailLink":
          iconName = "forwardtofriend";
          break;
        case "tumblrFollow":
          iconName = "tumblr";
          break;
        case "instagramFollow":
          iconName = "instagram";
          break;
        case "snapchatFollow":
          iconName = "snapchat";
          break;
        case "soundCloudFollow":
          iconName = "soundcloud";
          break;
        case "githubFollow":
          iconName = "github";
          break;
        case "vimeoFollow":
          iconName = "vimeo";
          break;
        case "vineFollow":
          iconName = "vine";
          break;
        case "mediumFollow":
          iconName = "medium";
          break;
        case "redditFollow":
          iconName = "reddit";
          break;
        case "flickrFollow":
          iconName = "flickr";
          break;
        case "dribbbleFollow":
          iconName = "dribbble";
          break;
        case "spotifyFollow":
          iconName = "spotify";
          break;
        case "houzzFollow":
          iconName = "houzz";
          break;
        case "vkFollow":
          iconName = "vk";
          break;
        case "rssFollow":
          iconName = "rss";
          break;
        case "tikTokFollow":
          iconName = "tiktok";
          break;
        case "threadsFollow":
          iconName = "threads";
          break;
        case "twitchFollow":
          iconName = "twitch";
          break;
        case "whatsAppFollow":
          iconName = "whatsapp";
          break;
        default:
          iconName = "link";
          break;
      }
      var iconTypePrefix = "";
      switch (iconType) {
        case "outline":
          iconTypePrefix = iconType + "-";
          break;
        default:
          break;
      }

      // inline = 48, stacked = 96
      iconSize = itemArrangement === "inline" ? "48" : "96";
      var url = urlPrefix + iconTypePrefix + iconStyle + "-" + iconName + "-" + iconSize + urlSuffix;
      item.iconUrl = mUrl.addProxy(url);
    },
    _setDefaultText: function (item) {
      if (!item.text) {
        var text = this.defaultText[item.type];
        item.text = text ? text : "Link";
      }
    },
    _setItemMargins: function (meta, idx, count, displayText, displayIcon) {
      var marginTop = 0;
      var marginRight = 0;
      var marginBottom = 0;
      var marginLeft = 0;
      var marginStr;

      // set padding for item, based on itemArrangement, align, blockLayout
      if (meta.itemArrangement === "stacked") {
        if (meta.blockLayout === "vertical") {
          // isLast
          marginBottom = idx + 1 < count ? this.innerPadding : 0;
        } else {
          switch (meta.align) {
            case "left":
              if (idx + 1 < count) {
                marginRight = this.innerPadding;
              }
              marginLeft = this.innerPadding;
              break;
            case "right":
              if (idx > 0) {
                marginLeft = this.innerPadding;
              }
              marginRight = this.innerPadding;
              break;
            default:
              marginRight = this.innerPadding;
              marginLeft = this.innerPadding;
              break;
          }
        }
      } else {
        if (meta.blockLayout === "vertical") {
          marginBottom = idx + 1 < count ? this.innerPadding : 0;
        } else {
          marginBottom = displayText ? this.innerPadding : 0;
          switch (meta.align) {
            case "left":
              if (idx + 1 < count) {
                marginRight = this.innerPadding * 2;
              } else {
                marginRight = this.innerPadding;
              }
              marginLeft = this.innerPadding;
              break;
            case "right":
              if (idx > 0) {
                marginLeft = this.innerPadding * 2;
              } else {
                marginLeft = this.innerPadding;
              }
              marginRight = this.innerPadding;
              break;
            case "center":
            default:
              if (idx + 1 < count) {
                marginRight = this.innerPadding * 2;
              } else {
                marginRight = this.innerPadding;
              }
              marginLeft = this.innerPadding;
              break;
          }
        }
      }
      marginStr = marginTop + "px  " + marginRight + "px  " + marginBottom + "px  " + marginLeft + "px";
      return marginStr;
    },
    _getIconMargin: function (blockLayout, itemArrangement, displayText, displayIcon, idx, count) {
      var marginTop = 0;
      var marginRight = 0;
      var marginBottom = 0;
      var marginLeft = 0;
      var marginStr;

      // set padding for item, based on itemArrangement, align, blockLayout

      if (displayText && itemArrangement === "stacked") {
        if (blockLayout === "vertical") {
          marginBottom = 5;
          marginRight = this.innerPadding;
          marginLeft = this.innerPadding;
          if (idx + 1 < count) {
            marginBottom = 0;
          }
        }
      }
      if (!displayText) {
        if (blockLayout === "vertical") {
          marginBottom = 5;
          marginRight = this.innerPadding;
          marginLeft = this.innerPadding;
          if (idx + 1 < count) {
            marginLeft = this.innerPadding;
            marginRight = this.innerPadding;
          }
        } else {
          marginBottom = 5;
        }
      }
      marginStr = marginTop + "px  " + marginRight + "px  " + marginBottom + "px  " + marginLeft + "px";
      return marginStr;
    },
    _getIconWidth: function (itemArrangement, blockLayout, displayText) {
      var iconOnlyPaddingWidth = "42px";
      var iconOnlyWidth = "24px";
      var iconTextWidth = "auto";
      if (itemArrangement === "stacked") {
        return iconTextWidth;
      }
      if (blockLayout === "horizontal") {
        return iconOnlyWidth;
      }
      if (displayText && blockLayout === "vertical") {
        return iconOnlyWidth;
      }
      return iconOnlyPaddingWidth;
    },
    _setupItemTemplate: function (item, itemArrangement, blockLayout, displayText, displayIcon, idx, count) {
      var itemContext = new dtlContext({
        item: item,
        itemArrangement: flexDirectionIconMap[itemArrangement],
        iconWidth: this._getIconWidth(itemArrangement, blockLayout, displayText),
        iconMargin: this._getIconMargin(blockLayout, itemArrangement, displayText, displayIcon, idx, count),
        displayText: displayText,
        displayIcon: displayIcon,
        textIconMargin: displayIcon && itemArrangement === "inline" ? "5px" : "0px"
      });
      item.template = this.socialItemTemplate.render(itemContext);
    },
    getOutputModel: function (meta) {
      var displayText = true;
      var displayIcon = true;
      switch (meta.display) {
        case "text":
          displayIcon = false;
          break;
        case "icon":
          displayText = false;
          break;
        default:
          break;
      }
      meta.items.forEach(lang.hitch(this, function (item, idx, arr) {
        this._getServiceIconUrl(item, meta.iconStyle, meta.iconType, meta.itemArrangement);
        this._setDefaultText(item);
        if (!item.link) {
          item.link = "";
        }
        item.margins = this._setItemMargins(meta, idx, arr.length, displayText, displayIcon);
        this._setupItemTemplate(item, meta.itemArrangement, meta.blockLayout, displayText, displayIcon, idx, arr.length);
      }));
      var blockHtml = this._getBlockTemplate(meta.blockLayout).render(new dtlContext({
        items: meta.items,
        align: justifyContentMap[meta.align],
        adjustableWidth: meta.adjustableWidth ? "1 1 auto" : "0 0 none",
        innerPadding: this.innerPadding
      }));
      return {
        blockHtml: blockHtml,
        align: justifyContentMap[meta.align],
        innerPadding: this.innerPadding,
        outerPadding: this.outerPadding
      };
    }
  });
});