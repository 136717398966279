define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/url", "mojo/utils", "mojo/user", "mojo/lib/flags",
// Widgets
"mojo/widgets/contact-profile/FieldsList",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/contact-fields.html",
// API
"./Api"], function (declare, lang, domClass, domConstruct, htmlEntities, mUrl, utils, user, flags, FieldsList, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, fieldsTplString, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: fieldsTplString,
    memberID: null,
    openRate: null,
    clickRate: null,
    hasEcomm: null,
    orderStatsByCurrency: null,
    orderCount: null,
    formattedZeroAmount: null,
    connectStoreUrl: null,
    gdprEnabled: false,
    isArchived: false,
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      this.setupLists();
      this._showNdIfNeeded();
      if (this.hasEcomm) {
        domClass.remove(this.ecommEngagementContainer, "hide");
      } else {
        domClass.remove(this.nonEcommEngagementContainer, "hide");
      }
      if (this.orderStatsByCurrency !== null && this.orderStatsByCurrency.length > 1) {
        domClass.remove(this.totalOrdersContainer, "hide");
      } else {
        domClass.remove(this.averageOrderContainer, "hide");
      }
    },
    /**
     * Re-format data for display
     */
    postMixInProperties: function () {
      this.openRate *= 100;
      if (this.openRate === Math.floor(this.openRate)) {
        this.openRate = utils.numberFormat(this.openRate, 0, ".", "");
      } else {
        this.openRate = utils.numberFormat(this.openRate, 1, ".", "");
      }
      this.clickRate *= 100;
      if (this.clickRate === Math.floor(this.clickRate)) {
        this.clickRate = utils.numberFormat(this.clickRate, 0, ".", "");
      } else {
        this.clickRate = utils.numberFormat(this.clickRate, 1, ".", "");
      }
      this.connectStoreUrl = mUrl.toUrl("/account/connected-sites/wizard/");
      this.orderCount = this.orderCount !== null ? this.orderCount : 0;
      if (this.orderStatsByCurrency !== null && this.orderStatsByCurrency.length) {
        this.formattedTotal = this.orderStatsByCurrency.map(function (stat) {
          return stat.formattedTotal;
        }).join(" · ");
        this.formattedOrderAverage = this.orderStatsByCurrency.map(function (stat) {
          return stat.formattedAverageTotal;
        }).join(" · ");
      } else {
        this.formattedTotal = this.formattedZeroAmount;
        this.formattedOrderAverage = this.formattedZeroAmount;
      }
    },
    /**
     * Set up each of the data sections
     */
    setupLists: function () {
      var ProfileInfoList = new FieldsList({
        "listName": "Profile Information",
        "memberID": this.memberID,
        "listEndpoint": "all",
        "editModalID": "edit-profile-dialog",
        "isArchived": this.isArchived
      });
      ProfileInfoList.placeAt(this.contactFieldsContainer);
      var GroupsInfoList = new FieldsList({
        "listName": "Groups",
        "memberID": this.memberID,
        "listEndpoint": "groups",
        "editModalID": "edit-groups-dialog",
        "isArchived": this.isArchived
      });
      GroupsInfoList.placeAt(this.contactFieldsContainer);
      if (this.gdprEnabled) {
        var GdprInfoList = new FieldsList({
          "listName": "GDPR",
          "memberID": this.memberID,
          "listEndpoint": "gdpr",
          "editModalID": "edit-gdpr-dialog",
          "isArchived": this.isArchived
        });
        GdprInfoList.placeAt(this.contactFieldsContainer);
      }
      var MetaInfoList = new FieldsList({
        "listName": "Other",
        "memberID": this.memberID,
        "listEndpoint": "meta",
        "editModalID": "edit-meta-dialog",
        "isArchived": this.isArchived
      });
      MetaInfoList.placeAt(this.contactFieldsContainer);
    },
    _showNdIfNeeded: function () {
      var ndMessage = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
        templateString: "<div>\
                        <span class='freddicon warn-circle warn-circle-color padding-right--lv1'></span>\
                        <span class='small-meta'>\
                            <span data-dojo-attach-point='messageNode'></span>\
                            <a data-dojo-type='mojo/widgets/account/connected-sites/nd/NdResourceLink'></a>\
                        </span>\
                    </div>",
        message: "",
        _setMessageAttr: {
          node: "messageNode",
          type: "innerText"
        }
      });
      var targetContainer = this.hasEcomm ? this.node_ndMessageEcom : this.node_ndMessageNonEcom;
      if (user.flagIsOn(flags.ND_CIRCUIT_BREAKER_DISCONNECT) && user.isND) {
        domConstruct.empty(targetContainer);
        new ndMessage({
          message: "Revenue data from Shopify is no longer available. "
        }).placeAt(targetContainer);
      }
    }
  });
});