define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/_Overlay", "dojo/text!./templates/SubscriberTableOverlay.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/segmentation/api", "mojo/analytics/VisTableListMembers", "mojo/analytics/VisQuery", "mojo/google/Grid", "mojo/google/Query"], function (declare, _WidgetBase, _TemplatedMixin, _Overlay, tpl, on, domAttr, lang, domConstruct, mUrl, api, VisTableListMembers, VisQuery, Grid, Query) {
  var singleton = null;
  var SubscriberTableOverlay = declare([_WidgetBase, _TemplatedMixin, _Overlay], {
    overlayClass: "subscriber-table-overlay",
    templateString: tpl,
    showPagination: true,
    persistTableSettings: true,
    postMixInProperties: function () {
      if (!singleton) {
        singleton = this;
      }
    },
    postCreate: function () {
      on(this.closeButton, "click", lang.hitch(this, function (e) {
        window.history.back();
      }));
      on(window, "popstate", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.hide();
      }));
    },
    load: function (listId, ast, settings, type) {
      window.history.pushState(null, null, window.location.pathname + window.location.search + '#overlay');
      lang.mixin(this, settings);
      var params = {
        'list_id': listId,
        'type': type,
        'ast': JSON.stringify(ast)
      };
      var options = {
        'sortable': false
      };
      this._createMemberTable(listId, mUrl.toUrl("/pro/segments/popup-data"), params, options);
      this.show();
    },
    /**
     * Loads up the segment that is currently in the session for a campaign and list.
     * Used in the recipients section when a segment is loaded in the session when the count calls are made.
     *
     * @param {number} listId - list id
     * @param {number} campaignId - the campaign id
     * @param {object} settings - allows to set the visibility of pagination or whether or not to persist data
     */
    loadRegular: function (listId, campaignId, settings) {
      window.history.pushState(null, null, window.location.pathname + window.location.search + "#overlay");
      lang.mixin(this, settings);
      var params = {
        id: campaignId,
        lid: listId
      };
      var options = {
        sortable: false
      };
      this._createMemberTable(listId, mUrl.toUrl("/campaigns/segments/popup-data"), params, options);
      this._loadDescription(params);
      this.show();
    },
    /**
     * Helper to create the subscriber table
     *
     * @param {number} listId - the list id for the subscriber table
     * @param {string} url - the endpoint that we're loading the subscriber data from
     * @param {object} params - url params that goes with the url
     * @param {object} options = additional options for the VisTableListMembers module
     *
     * @private
     */
    _createMemberTable: function (listId, url, params, options) {
      var req = api.getSubscribersVisGridQuery(listId);
      req.then(lang.hitch(this, function (query) {
        this.membersTable = new VisTableListMembers({
          query: new VisQuery(url, query, params),
          pageSize: 100,
          options: options,
          showPagination: this.showPagination,
          persistTableSettings: this.persistTableSettings
        });
        this.membersTable.placeAt(this.memberGridContainer);
      }));
    },
    /**
     * Grab the description and shove it into the html
     *
     * @param {object} params - url params that goes with the url
     *
     * @private
     */
    _loadDescription: function (params) {
      var req = api.getSubcribersDescriptionQuery(params);
      req.then(lang.hitch(this, function (result) {
        this._setMemberDescription(result);
      }));
    },
    _setMemberDescription: function (description) {
      this.memberDescriptionContainer.innerHTML = description;
    },
    onShow: function () {},
    onHide: function () {
      this.membersTable.destroy();
      this._setMemberDescription("");
    }
  });

  // Only need a single overlay per page so limit the exposure of the widget implementation.
  return {
    main: function () {
      if (!singleton) {
        singleton = new SubscriberTableOverlay();
        singleton.placeAt(document.body);
      }
      return singleton;
    }
  };
});