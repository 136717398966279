define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request/iframe"], function (query, domConstruct, declare, lang, arrayUtil, domForm, request) {
  return declare("dojox.form.uploader._IFrame", [], {
    // summary:
    //		A mixin for dojox/form/Uploader that adds Ajax upload capabilities via an iframe.
    //
    // description:
    //		Only supported by IE, due to the specific iFrame hack used.  Progress events are not
    //		supported.
    //		
    //

    postMixInProperties: function () {
      this.inherited(arguments);
      if (this.uploadType === "iframe") {
        this.uploadType = "iframe";
        this.upload = this.uploadIFrame;
      }
    },
    uploadIFrame: function (data) {
      // summary:
      //		Internal. You could use this, but you should use upload() or submit();
      //		which can also handle the post data.

      var formObject = {},
        sendForm,
        form = this.getForm(),
        url = this.getUrl(),
        self = this;
      data = data || {};
      data.uploadType = this.uploadType;

      // create a temp form for which to send data
      //enctype can't be changed once a form element is created
      sendForm = domConstruct.place('<form enctype="multipart/form-data" method="post"></form>', this.domNode);
      arrayUtil.forEach(this._inputs, function (n, i) {
        // don't send blank inputs
        if (n.value !== '') {
          sendForm.appendChild(n);
          formObject[n.name] = n.value;
        }
      }, this);

      // add any extra data as form inputs		
      if (data) {
        //formObject = domForm.toObject(form);
        for (nm in data) {
          if (formObject[nm] === undefined) {
            domConstruct.create('input', {
              name: nm,
              value: data[nm],
              type: 'hidden'
            }, sendForm);
          }
        }
      }
      request.post(url, {
        form: sendForm,
        handleAs: "json",
        content: data
      }).then(function (result) {
        domConstruct.destroy(sendForm);
        if (result["ERROR"] || result["error"]) {
          self.onError(result);
        } else {
          self.onComplete(result);
        }
      }, function (err) {
        console.error('error parsing server result', err);
        domConstruct.destroy(sendForm);
        self.onError(err);
      });
    }
  });
});