define(["dojo-proxy-loader?name=dojo/main!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (dojo, declare, base, on, query, dijit) {
  return declare([base], {
    onReady: function () {
      var _mc = window._mc;
      query(".permission-profile").on("change", function (event) {
        var clickedProfileCheckbox = dijit.getEnclosingWidget(this);
        var parentProfileIds = _mc.parentProfileIds[clickedProfileCheckbox.id];

        // Check each parent checkbox
        parentProfileIds.forEach(function (id) {
          id = id.toString();

          // Skip clicked checkbox
          if (id === clickedProfileCheckbox.id) {
            return;
          }
          var parentProfileCheckbox = dijit.getEnclosingWidget(query("#" + id)[0]);
          parentProfileCheckbox.set({
            checked: true
          });
        });
        if (clickedProfileCheckbox.checked === false) {
          // Uncheck all descendents
          var descendents = query(this).closest("tr").query("input");
          descendents.forEach(function (descendent) {
            // Do not uncheck if this is the the clicked permission profile.
            if (descendent.id !== clickedProfileCheckbox.id) {
              dijit.getEnclosingWidget(descendent).set({
                checked: false
              });
            }
          });
        }
      });

      //disable default function of return key
      on(document, "keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });
    }
  });
});