define(["/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domClass.toggle
"dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // has("dijit-legacy-requires")
"dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // kernel.deprecated
"dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.trim
"dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_FormWidget", "./_ButtonMixin", "dojo/text!./templates/Button.html", "../a11yclick" // template uses ondijitclick
], function (dojoRequire, declare, domClass, has, kernel, lang, ready, _FormWidget, _ButtonMixin, template) {
  // module:
  //		dijit/form/Button

  // Back compat w/1.6, remove for 2.0
  if (has("dijit-legacy-requires")) {
    ready(0, function () {
      var requires;
      require(["dijit/form/DropDownButton", "dijit/form/ComboButton", "dijit/form/ToggleButton"]); // use indirection so modules not rolled into a build
    });
  }
  var Button = declare("dijit.form.Button" + (0 ? "_NoBidi" : ""), [_FormWidget, _ButtonMixin], {
    // summary:
    //		Basically the same thing as a normal HTML button, but with special styling.
    // description:
    //		Buttons can display a label, an icon, or both.
    //		A label should always be specified (through innerHTML) or the label
    //		attribute.  It can be hidden via showLabel=false.
    // example:
    // |	<button data-dojo-type="dijit/form/Button" onClick="...">Hello world</button>
    //
    // example:
    // |	var button1 = new Button({label: "hello world", onClick: foo});
    // |	dojo.body().appendChild(button1.domNode);

    // showLabel: Boolean
    //		Set this to true to hide the label text and display only the icon.
    //		(If showLabel=false then iconClass must be specified.)
    //		Especially useful for toolbars.
    //		If showLabel=true, the label will become the title (a.k.a. tooltip/hint) of the icon.
    //
    //		The exception case is for computers in high-contrast mode, where the label
    //		will still be displayed, since the icon doesn't appear.
    showLabel: true,
    // iconClass: String
    //		Class to apply to DOMNode in button to make it display an icon
    iconClass: "dijitNoIcon",
    _setIconClassAttr: {
      node: "iconNode",
      type: "class"
    },
    baseClass: "dijitButton",
    templateString: template,
    // Map widget attributes to DOMNode attributes.
    _setValueAttr: "valueNode",
    _setNameAttr: function (name) {
      // avoid breaking existing subclasses where valueNode undefined.  Perhaps in 2.0 require it to be defined?
      if (this.valueNode) {
        this.valueNode.setAttribute("name", name);
      }
    },
    _fillContent: function ( /*DomNode*/source) {
      // Overrides _Templated._fillContent().
      // If button label is specified as srcNodeRef.innerHTML rather than
      // this.params.label, handle it here.
      // TODO: remove the method in 2.0, parser will do it all for me
      if (source && (!this.params || !("label" in this.params))) {
        var sourceLabel = lang.trim(source.innerHTML);
        if (sourceLabel) {
          this.label = sourceLabel; // _applyAttributes will be called after buildRendering completes to update the DOM
        }
      }
    },
    _setShowLabelAttr: function (val) {
      if (this.containerNode) {
        domClass.toggle(this.containerNode, "dijitDisplayNone", !val);
      }
      this._set("showLabel", val);
    },
    setLabel: function ( /*String*/content) {
      // summary:
      //		Deprecated.  Use set('label', ...) instead.
      kernel.deprecated("dijit.form.Button.setLabel() is deprecated.  Use set('label', ...) instead.", "", "2.0");
      this.set("label", content);
    },
    _setLabelAttr: function ( /*String*/content) {
      // summary:
      //		Hook for set('label', ...) to work.
      // description:
      //		Set the label (text) of the button; takes an HTML string.
      //		If the label is hidden (showLabel=false) then and no title has
      //		been specified, then label is also set as title attribute of icon.
      this.inherited(arguments);
      if (!this.showLabel && !("title" in this.params)) {
        this.titleNode.title = lang.trim(this.containerNode.innerText || this.containerNode.textContent || '');
      }
    }
  });
  if (0) {
    Button = declare("dijit.form.Button", Button, {
      _setLabelAttr: function ( /*String*/content) {
        this.inherited(arguments);
        if (this.titleNode.title) {
          this.applyTextDir(this.titleNode, this.titleNode.title);
        }
      },
      _setTextDirAttr: function ( /*String*/textDir) {
        if (this._created && this.textDir != textDir) {
          this._set("textDir", textDir);
          this._setLabelAttr(this.label); // call applyTextDir on both focusNode and titleNode
        }
      }
    });
  }
  return Button;
});