define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/account/connected-sites"], function (declare, _base, domAttr, query, on, lang, array, utils, keys, debounce, event, connectedSites) {
  return declare([_base], {
    // Placeholder for feedback message
    errMsg: "",
    // Custom shop url i.e. shopify
    shopUrl: "",
    // Store url from input
    urlInput: "",
    // Boolean for locating shopurl in url input
    urlIndex: "",
    // Store shop subdomain
    subDomain: "",
    onReady: function () {
      var self = this;

      // Enable next button on input focus and clear errors
      on(this.$el.siteUrl, "focus", function () {
        connectedSites.enableNext();
        if (query(".invalid-error")[0]) {
          self.hideErrMessage();
        }
      });

      // Setup next button
      on(connectedSites.nextButton, "click", function (e) {
        // Prevent click if url is not valid
        if (!self._validateUrl()) {
          e.stopPropagation();
          e.preventDefault();
        }
      });
    },
    // Validate Url input
    _validateUrl: function () {
      // Grab url from input
      this.urlInput = this.$el.siteUrl;

      // Validate each url based on platform
      switch (this.$el.platform.value) {
        case "shopify":
          // Set shop url
          this.shopUrl = "myshopify";
          // Split url and find shopUrl
          this.urlIndex = array.indexOf(this.urlInput.value.split("."), this.shopUrl);
          // Split and store subdomain
          this.subDomain = this.urlInput.value.split(".", 1);

          // If no value or does not contain a shopify url
          if (this.subDomain[0] === "" || this.urlIndex < 0) {
            // Prompt the user to enter a correct url
            this.errMsg = "Please enter a URL with the format store-name.myshopify.com";

            // If url is incorrect, show error message
            this.showErrMessage();
            return;
          }
          break;
        case "bigcommerce":
          // Set shop url
          this.shopUrl = "mybigcommerce";
          // Split url and find shopUrl
          this.urlIndex = array.indexOf(this.urlInput.value.split("."), this.shopUrl);
          // Split and store subdomain
          this.subDomain = this.urlInput.value.split(".", 1);

          // If no value or does not contain a bigcommerce url
          if (!this.urlInput || this.subDomain[0] === "" || this.urlIndex < 0) {
            // Prompt the user to enter a correct url
            this.errMsg = "Please enter a URL with the format store-name.mybigcommerce.com";

            // If url is incorrect, show error message
            this.showErrMessage();
            return;
          }
          break;
        default:
          // Basic URL check
          if (!/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(this.urlInput.value)) {
            // Prompt the user to enter a correct url
            this.errMsg = "Please enter a valid URL";

            // If url is incorrect, show error message
            this.showErrMessage();
            return;
          }
          break;
      }

      // Else url is correct
      return true;
    },
    // Show error message & disable next button
    showErrMessage: function () {
      utils.addError(this.urlInput, this.errMsg);
      connectedSites.disableNext();
    },
    // Hide error message & enable next button
    hideErrMessage: function () {
      utils.removeErrors(this.urlInput);
      connectedSites.enableNext();
    }
  });
});