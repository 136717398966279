define(["mojo/api/postcard", "mojo/widgets/checklist-editor/items/PostcardRecipients", "mojo/widgets/checklist-editor/items/PostcardRecurringSchedule", "mojo/widgets/checklist-editor/items/PostcardContent", "mojo/widgets/checklist-editor/items/PostcardTrigger", "mojo/widgets/checklist-editor/items/PostcardBudget", "mojo/widgets/checklist-editor/items/CampaignName", "mojo/widgets/checklist-editor/items/CampaignNameStatic", "mojo/user", "mojo/lib/logger"], function (postcardApi, PostcardRecipients, PostcardRecurringSchedule, PostcardContent, PostcardTrigger, PostcardBudget, CampaignName, CampaignNameStatic, user, logger) {
  function _getStaticNameByStrategy(strategy) {
    if (strategy === "abandoned_cart") {
      return "Abandoned Cart Postcard";
    }
    return "";
  }
  function computeDescription(list, strategy, isReadOnly, audience) {
    var defaultDescription = "You can mail postcards to any current contacts. Just make sure their addresses are <a href='https://mailchimp.com/help/format-contact-addresses/' target='blank'>formatted correctly.</a> ";
    var postcardLink = "<a href='/#/create-campaign/explore/postcard' target='_blank'>create a postcard.</a>";
    var deletedDescription = "This postcard was sent to a deleted list or segment. To choose new recipients, " + postcardLink;
    if (strategy === "regular" && list !== null) {
      // ie. one-time bulk
      return "Send your postcard to contacts in up to <a href='https://mailchimp.com/help/about-postcards/#where+you+can+send' target='_blank'>27 countries.</a> We can help you find addresses for your existing contacts or reach a brand-new audience.";
    }
    if (isReadOnly && list === null) {
      if (audience === "lookalike") {
        return "This postcard was sent to recipients similar to a deleted list or segment. To choose new recipients, " + postcardLink;
      }
      return deletedDescription;
    }
    return defaultDescription;
  }
  return function getChecklistItems(model, props) {
    var audience = model.audienceStrategy;
    var list = model.list;
    var strategy = model.automationTrigger.strategy || "regular";
    var isReadOnly = model.status !== postcardApi.POSTCARD_STATUS_DRAFT && model.status !== postcardApi.POSTCARD_STATUS_PAUSED;
    if (isReadOnly) {
      logger.info("direct-mail", "Viewing Read-only-checklist", {
        postcard_id: model.postcardId,
        log_classification: "sensitive"
      });
    }
    var NAME = {
      label: "Name",
      position: "before",
      widget: CampaignName,
      isComplete: function () {
        return true;
      },
      isValid: function () {
        return true;
      }
    };
    var NAME_STATIC = {
      name: _getStaticNameByStrategy(strategy),
      label: "Name",
      position: "before",
      widget: CampaignNameStatic,
      isComplete: function () {
        return true;
      },
      isValid: function () {
        return true;
      }
    };
    var SCHEDULE = {
      label: "Schedule",
      description: "How often should this campaign be sent?",
      contentWidget: PostcardRecurringSchedule,
      isComplete: PostcardRecurringSchedule.isComplete,
      isValid: PostcardRecurringSchedule.isValid,
      isReadOnly: isReadOnly
    };
    var BUDGET = {
      label: "Budget",
      actionLabel: "Set Budget",
      description: "Pay for your postcards up front, and we'll send them out as carts are abandoned.",
      contentWidget: PostcardBudget,
      isComplete: PostcardBudget.isComplete,
      isValid: PostcardBudget.isValid,
      isReadOnly: isReadOnly,
      extraProps: {
        pricingData: props.pricingData
      }
    };
    var RECIPIENTS = {
      label: "To",
      description: computeDescription(list, strategy, isReadOnly, audience),
      actionLabel: "Add Recipients",
      editLabel: "Edit Recipients",
      contentWidget: PostcardRecipients,
      isComplete: PostcardRecipients.isComplete,
      isValid: PostcardRecipients.isValid,
      isPending: PostcardRecipients.isPending,
      errorLabel: "Resolve",
      isReadOnly: isReadOnly,
      extraProps: {
        pricingData: props.pricingData,
        audienceStrategies: model.getSupportedAudienceStrategies(),
        accountUpgradeDetails: props.accountUpgradeDetails
      },
      className: "PostcardRecipients",
      onActivate: function () {
        logger.googleAnalytics.trackEvent("postcards builder", "add recipients", "open");
      }
    };
    var TRIGGER = {
      label: "Sends to",
      description: "How often should this campaign be sent?",
      contentWidget: PostcardTrigger,
      isReadOnly: true,
      isComplete: function () {
        return !model.wasSourceDeleted;
      },
      isValid: function () {
        return true;
      }
    };
    var CONTENT = {
      label: "Content",
      description: "How do you want your postcard to look?",
      contentWidget: PostcardContent,
      isComplete: PostcardContent.isComplete,
      isValid: function () {
        return true;
      },
      isReadOnly: isReadOnly,
      onActivate: function () {
        logger.googleAnalytics.trackEvent("postcards builder", "add content", "open");
      }
    };
    var CHECKLIST_LAYOUTS = {
      regular: [NAME, RECIPIENTS, CONTENT],
      recurring: [NAME, SCHEDULE, RECIPIENTS, CONTENT],
      "abandoned_cart": [NAME_STATIC, TRIGGER, BUDGET, CONTENT]
    };
    if (!CHECKLIST_LAYOUTS[strategy]) {
      throw new Error("Unknown checklist strategy: " + strategy + " was supplied");
    }
    return CHECKLIST_LAYOUTS[strategy];
  };
});