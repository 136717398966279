define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/focus", "mojo/widgets/CircularLoader", "dojo/text!./templates/segment.html", "./api", "./Group", "./SubGroup", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, domConstruct, domClass, lang, on, array, focusUtil, CircularLoader, tpl, api, Group, SubGroup, I18nTranslation, flags, context) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    listId: null,
    // Keeps a reference to the raw config
    _rawConfig: null,
    configs: null,
    mainGroup: null,
    subGroups: null,
    savedAST: null,
    configType: "full",
    postMixInProperties: function () {
      this.subGroups = [];
    },
    postCreate: function () {
      this._beforeLoad();
      api.getConfig(this.listId, this.configType).then(lang.hitch(this, function (configs) {
        this._setupConfigs(configs);
        this._init();
        this._afterLoad();
        if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
          I18nTranslation.initialize().then(function () {
            if (!I18nTranslation.isTranslationObjectEmpty()) {
              this.addGroupBtnText.innerText = I18nTranslation.translate("audience_management_segment_add_group_button_text");
            }
          }.bind(this));
        }
      }));
    },
    _init: function () {
      this.mainGroup = new Group({
        configs: this.configs
      });
      this.mainGroup.startup();
      this.mainGroup.placeAt(this.mainGroupDom);
      this.mainGroup.on("conditionCountChange", lang.hitch(this, "onConditionCountChange"));
      focusUtil.focus(this.mainGroup.domNode);
      if (this.savedAST) {
        this._fromAST(this.savedAST);
      } else {
        // If it's a new segment, add a single condition.
        this.mainGroup.addCondition();
      }
      this.own(on(this.addSubGroupBtn, "click", lang.hitch(this, function () {
        var subGroup = this.addSubGroup();
        subGroup.addCondition();
      })));
    },
    /**
     * Take the expected config and build one that's better suited for the UI access of the data.
     *
     * @param configs
     * @private
     */
    _setupConfigs: function (configs) {
      this._rawConfig = configs;
      var fieldConfigs = {};
      array.forEach(configs.fields, lang.hitch(this, function (field) {
        var clonedField = lang.clone(field);
        var opsMap = {};
        array.forEach(clonedField.ops, function (op) {
          opsMap[op.value] = op;
        });
        clonedField.opsMap = opsMap;
        fieldConfigs[field.field] = clonedField;
      }));
      this.configs = {
        "fields": fieldConfigs,
        "optionValues": lang.clone(configs.option_values),
        "listId": this.listId,
        "defaultField": configs.default_field
      };
    },
    addSubGroup: function () {
      var nestedGroup = new SubGroup({
        configs: this.configs
      });
      nestedGroup.startup();
      domConstruct.place(nestedGroup.domNode, this.subGroupsContainer);
      nestedGroup.on("destroy", lang.hitch(this, function () {
        this.subGroups.splice(this.subGroups.indexOf(nestedGroup), 1);
      }));
      nestedGroup.on("conditionCountChange", lang.hitch(this, "onConditionCountChange"));
      this.subGroups.push(nestedGroup);
      return nestedGroup;
    },
    getAST: function () {
      var mainAST = this.mainGroup.getAST();
      mainAST.sub_conditions = array.map(this.subGroups, function (group) {
        return group.getAST();
      });
      return mainAST;
    },
    _fromAST: function (ast) {
      this.mainGroup.fromAST(ast);
      if (ast.sub_conditions) {
        array.forEach(ast.sub_conditions, lang.hitch(this, function (subCondAST) {
          var subGroup = this.addSubGroup();
          subGroup.fromAST(subCondAST);
        }));
      }
    },
    getConditionCount: function () {
      if (this.mainGroup) {
        var count = this.mainGroup.conditions.length;
        if (this.subGroups) {
          array.forEach(this.subGroups, lang.hitch(this, function (group) {
            count += group.conditions.length;
          }));
        }
        return count;
      }
      return 0;
    },
    _beforeLoad: function () {
      // Add the circular loader.
      this.loader = new CircularLoader({
        color: '#6dc5dc',
        bgColor: '#d0d0d0'
      });
      this.loader.startup();
      this.loader.placeAt(this.loaderDom);

      // For sufficiently fast return calls, don't show the spinner.
      // 100ms is perceived instant threshold, but squeezing an additional 50
      this._loaderTimeout = setTimeout(lang.hitch(this, function () {
        domClass.remove(this.loaderContainer, "hide");
      }), 150);
    },
    _afterLoad: function () {
      domClass.add(this.loaderContainer, "hide");
      domClass.remove(this.segmentContainer, "hide");
      clearTimeout(this._loaderTimeout);

      // Cleanup the module
      this.loader.destroy();
    },
    /**
     * Callback when a condition is added or removed. to either group or sub group
     */
    onConditionCountChange: function () {}
  });
});