define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/utils", "mojo/user", "dojo/text!./templates/postcard-recurring-schedule.html"], function (declare, Renderable, ItemButtons, utils, user, tpl) {
  var INTERVAL_OPTIONS = [{
    value: "30d",
    label: "Every month"
  }, {
    value: "90d",
    label: "Every 3 months"
  }, {
    value: "180d",
    label: "Every 6 months"
  }];
  if (user.type === "internal") {
    INTERVAL_OPTIONS.unshift({
      value: "1d",
      label: "Every day (for internal testing)"
    });
  }
  var PostcardRecurringSchedule = declare([Renderable], {
    intervals: INTERVAL_OPTIONS,
    templateString: tpl,
    constructor: function (props) {
      var trigger = props.model.automationTrigger;
      this.state = {
        interval: trigger.settings.interval
      };
    },
    getData: function () {
      var data = {
        automationTrigger: {
          strategy: "recurring",
          settings: {
            interval: this.state.interval
          }
        }
      };
      return data;
    },
    handleIntervalChange: function (interval) {
      this.setState({
        interval: interval
      });
    },
    getIntervalLabelByValue: function (value) {
      var selectedInterval = this.intervals.filter(function (interval) {
        return interval.value === value;
      })[0];
      return selectedInterval.label;
    },
    _renderItemButtons: function () {
      if (!this.isActive) {
        return;
      }
      if (this.itemButtons) {
        this.itemButtons.destroy();
      }
      this.itemButtons = new ItemButtons({
        label: "Schedule",
        getData: this.getData.bind(this),
        onSave: this.onSave,
        cancel: this.cancel,
        activate: this.activate,
        isActive: this.isActive,
        isValid: true
      });
      this.itemButtons.placeAt(this.itemButtonsContainer);
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        this._renderItemButtons();
      }
    }
  });
  PostcardRecurringSchedule.isComplete = function (model) {
    var triggerSettings = model.automationTrigger.settings;
    return triggerSettings.interval;
  };
  PostcardRecurringSchedule.isValid = function (model) {
    return true;
  };
  return PostcardRecurringSchedule;
});