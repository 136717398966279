/**
 * Edit Payment Method
 *
 * The mobile editing experience for adding/swapping payment methods. Current payment method info
 * is passed into this widget. This widget communicates with the backend to save payment method changes.
 *
 * "__methodName" denotes required implementation.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/api/billing", "mojo/utils", "mojo/widgets/billing/PaymentMethod",
// Mixins
"./_EventAdapterMixin", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Template
"dojo/text!./templates/EditPaymentMethod.html"], function (declare, debounce, on, billingApi, utils, PaymentMethod, _EventAdapterMixin, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  return declare([_EventAdapterMixin, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    // Container for saved card lookups
    cardTypeCache: {},
    /**
     * Part of Dojo's lifecycle. Once nodes are ready, attach an event.
     */
    postCreate: function () {
      on(this.ccInput, "keyup, paste", debounce(this._assessCardType.bind(this), 200));
    },
    /**
     * Once the widget is fully loaded, tell the parent widget it's ready to go.
     */
    startup: function () {
      this.inherited(arguments);
      this.onLoaded();
    },
    /**
     * We want to change the credit cards input label to be contextual to the number they are inputting.
     *
     * @private
     */
    _assessCardType: function () {
      utils.removeAllErrors(this.domNode);

      // Don't bother unless we know we have enough data
      if (this.ccInput.value.length < 4) {
        return;
      }
      this._getOrFillLabel(this.ccInput.value.substr(0, 4));
    },
    /**
     * We want to keep the number of requests down. Especially for events triggered by keystroke.
     * Use a simple caching to avoid making a request on each keystroke.
     *
     * @param {Number} firstFourDigits - First 4 of credit card number
     * @private
     */
    _getOrFillLabel: function (firstFourDigits) {
      if (this.cardTypeCache[firstFourDigits]) {
        this.set("ccLabel", this.cardTypeCache[firstFourDigits]);
        return;
      }
      this._getCardType(firstFourDigits);
    },
    /**
     * Actually reach out to the backend and use their methods of assessing card type. If we
     * get a success back, save it to the "cache."
     *
     * For invalid cards, just make it the default text.
     *
     * @param {Number} firstFourDigits - First 4 of credit card number
     * @private
     */
    _getCardType: function (firstFourDigits) {
      billingApi.getCardType({
        num: firstFourDigits
      }).then(function (response) {
        switch (response.status) {
          case "success":
            this.cardTypeCache[firstFourDigits] = response.type_description;
            this.set("ccLabel", response.type_description);
            break;
          case "error":
            this.set("ccLabel", "Credit Card Number");
            break;
          default:
            throw new Error("Unhandled card type response state");
        }
      }.bind(this));
    },
    /**
     * Custom setter for the Credit Card Input label contents. Called by this.set("ccLabel", "something");
     *
     * @param {String} str - What we want the label to be
     * @private
     */
    _setCcLabelAttr: function (str) {
      this.ccLabel_node.innerText = str;
    },
    /**
     * Method in which the parent widget calls. Validate any current fields and submit to the backend.
     *
     * @private
     */
    __save: function () {
      if (!this._passesSimpleValidation()) {
        this.onDoneWithError();
        return;
      }

      // Push out the form data to the backend and wait for a response.
      billingApi.postPaymentMethod(this._getFormData()).then(this._handlePostMethodResponse.bind(this));
    },
    /**
     * Method in which the parent widget calls. Make sure the .
     *
     * @private
     */
    __cancel: function () {
      // TODO: Validate that the user isn't mid-progress on something.
      this.onDone();
    },
    /**
     * Give feedback on the user's inputs pass the minimum check. Actual card validation will happen on POST
     *
     * @return {boolean} - Continue with submission?
     * @private
     */
    _passesSimpleValidation: function () {
      if (this.ccInput.value === "") {
        utils.addError(this.ccInput, "Please enter a value.");
        return false;
      }
      return true;
    },
    /**
     * Simple method to grab the contents the backend cares about. For now, hard code the method type
     * until we add more supported types.
     *
     * @returns {{method: string, cc_num: *, cc_exp_month: *, cc_exp_year: *}} - Data to be passed to backend.
     * @private
     */
    _getFormData: function () {
      return {
        method: "credit_card",
        cc_num: this.ccInput.value,
        cc_exp_month: this.monthInput.getValue(),
        cc_exp_year: this.yearInput.getValue()
      };
    },
    /**
     * Handle what to do after someone saves the form.
     *
     * @param {Object} response - Data the server is sending us back.
     * @private
     */
    _handlePostMethodResponse: function (response) {
      switch (response.status) {
        case "success":
          this.onDone();
          break;
        case "error":
          utils.showFormErrors(this.domNode, response.errors ? response.errors : {});
          this.onDoneWithError();
          break;
        default:
          throw new Error("Unhandled update payment method response state");
      }
    }
  });
});