define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"dojo/i18n",
// i18n.getLocalization
"./MappedTextBox", "dojo/i18n!./nls/validate"], function (declare, i18n, MappedTextBox) {
  // module:
  //		dijit/form/RangeBoundTextBox

  var RangeBoundTextBox = declare("dijit.form.RangeBoundTextBox", MappedTextBox, {
    // summary:
    //		Base class for textbox form widgets which defines a range of valid values.

    // rangeMessage: String
    //		The message to display if value is out-of-range
    rangeMessage: "",
    /*=====
    // constraints: RangeBoundTextBox.__Constraints
    constraints: {},
    ======*/

    rangeCheck: function ( /*Number*/primitive, /*dijit/form/RangeBoundTextBox.__Constraints*/constraints) {
      // summary:
      //		Overridable function used to validate the range of the numeric input value.
      // tags:
      //		protected
      return ("min" in constraints ? this.compare(primitive, constraints.min) >= 0 : true) && ("max" in constraints ? this.compare(primitive, constraints.max) <= 0 : true); // Boolean
    },
    isInRange: function /*Boolean*/ /*===== isFocused =====*/
    () {
      // summary:
      //		Tests if the value is in the min/max range specified in constraints
      // tags:
      //		protected
      return this.rangeCheck(this.get('value'), this.constraints);
    },
    _isDefinitelyOutOfRange: function () {
      // summary:
      //		Returns true if the value is out of range and will remain
      //		out of range even if the user types more characters
      var val = this.get('value');
      if (val == null) {
        return false;
      } // not yet valid enough to compare to
      var outOfRange = false;
      if ("min" in this.constraints) {
        var min = this.constraints.min;
        outOfRange = this.compare(val, typeof min == "number" && min >= 0 && val != 0 ? 0 : min) < 0;
      }
      if (!outOfRange && "max" in this.constraints) {
        var max = this.constraints.max;
        outOfRange = this.compare(val, typeof max != "number" || max > 0 ? max : 0) > 0;
      }
      return outOfRange;
    },
    _isValidSubset: function () {
      // summary:
      //		Overrides `dijit/form/ValidationTextBox._isValidSubset()`.
      //		Returns true if the input is syntactically valid, and either within
      //		range or could be made in range by more typing.
      return this.inherited(arguments) && !this._isDefinitelyOutOfRange();
    },
    isValid: function ( /*Boolean*/isFocused) {
      // Overrides dijit/form/ValidationTextBox.isValid() to check that the value is also in range.
      return this.inherited(arguments) && (this._isEmpty(this.textbox.value) && !this.required || this.isInRange(isFocused)); // Boolean
    },
    getErrorMessage: function ( /*Boolean*/isFocused) {
      // Overrides dijit/form/ValidationTextBox.getErrorMessage() to print "out of range" message if appropriate
      var v = this.get('value');
      if (v != null /* and !undefined */ && v !== '' && (typeof v != "number" || !isNaN(v)) && !this.isInRange(isFocused)) {
        // don't check isInRange w/o a real value
        return this.rangeMessage; // String
      }
      return this.inherited(arguments);
    },
    postMixInProperties: function () {
      this.inherited(arguments);
      if (!this.rangeMessage) {
        this.messages = i18n.getLocalization("dijit.form", "validate", this.lang);
        this.rangeMessage = this.messages.rangeMessage;
      }
    }
  });
  /*=====
  RangeBoundTextBox.__Constraints = declare(null, {
  	// min: Number
  	//		Minimum signed value.  Default is -Infinity
  	// max: Number
  	//		Maximum signed value.  Default is +Infinity
  });
  =====*/
  return RangeBoundTextBox;
});