define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, lang, on, domClass, base) {
  return declare([base], {
    onReady: function () {
      // This component is inside an iframe, beware the distinction
      var subjectLineForm = this.$el.subjectLineForm;
      var formField = this.$el.subjectLineField;
      on(subjectLineForm, "submit", function () {
        // this is wrapped in an iframe, so we have to call its
        // parent to get the top level window
        window.parent.gtmEvent({
          "event": "email_subject_search",
          "search": formField.value
        });

        // log another event as part of a subject research funnel
        window.parent.ga("send", "event", "campaign_checklist", "click", JSON.stringify({
          context: "write_a_good_subject_line",
          text: "Search",
          query: formField.value
        }));
      });
    }
  });
});