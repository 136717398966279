define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/PromoCodeEditor/basicTemplate.html", "dojo/text!../editors/blocks/templates/PromoCodeEditor/_promo-content.html", "dojox/dtl", "dojox/dtl/Context"], function (declare, _Renderer, ButtonRenderer, Link, lang, domConstruct, basicTpl, contentTpl, dtl, dtlContext) {
  return declare([_Renderer], {
    // Basic template
    outputTplStr: basicTpl,
    // Template that puts all the promo attributes in rows
    contentTemplate: null,
    // Keeps a reference to a button renderer which is the same renderer that handles a button block
    buttonRenderer: null,
    constructor: function () {
      this.inherited(arguments);

      // Load the content template
      this.contentTemplate = new window.dojox.dtl.Template(contentTpl);

      // Initialize button renderer
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * Return the markup of the content of each promo code. This includes description, code, expiration and button
     *
     * @param {Object} meta - ref to the output model's meta.
     *
     * @return {string} the markup for the inner content of the block.
     *
     * @private
     */
    _contentMarkup: function (meta) {
      var rows = [];
      if (meta.descriptionEnabled && meta.description) {
        rows.push({
          "html": meta.description,
          "className": "mcnPromoDescription",
          "styles": "padding-bottom:18px;",
          "align": meta.align
        });
      }
      if (!meta.promoCode) {
        meta.promoCode = "Promo Code";
      } else {
        meta.promoCode = meta.mergeTag;
      }
      rows.push({
        "html": meta.promoCode,
        "className": "mcnPromoCode",
        "styles": "padding-bottom: 18px; word-break: break-all;",
        "align": meta.align
      });
      if (meta.expirationEnabled) {
        var expiration = null;

        // Check for expiration date
        if (!meta.expirationDate) {
          expiration = "No expiration date";
        } else {
          expiration = "Expiration date: " + meta.expirationDate;
        }
        rows.push({
          "html": expiration,
          "className": "mcnExpirationDate",
          "styles": "padding-bottom:18px;",
          "align": meta.align
        });
      }
      if (meta.buttonEnabled) {
        rows.push({
          "html": this._buttonMarkup(meta),
          "className": "mcnButtonContent"
        });
      }
      return rows.length ? lang.trim(this.contentTemplate.render(new dtlContext({
        "rows": rows
      }))) : "";
    },
    /**
     * Use Button block renderer to render the button.
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     *
     * @returns {string} html content of how a button should look
     * @private
     */
    _buttonMarkup: function (meta) {
      var buttonMeta = {
        "buttonText": meta.buttonText,
        "buttonWidth": meta.buttonWidth,
        "link": new Link(meta.link, meta.title)
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    getOutputModel: function (meta) {
      var description = meta.description;
      var promoCode = meta.promoCode;
      var mergeTag = meta.mergeTag;
      var expirationDate = meta.expirationDate;
      var promoId = meta.promoId;
      var promoBody = this._contentMarkup(meta);
      return {
        description: description,
        promoCode: promoCode,
        expirationDate: expirationDate,
        promoId: promoId,
        mergeTag: mergeTag,
        align: meta.align,
        promoBody: promoBody,
        buttonText: meta.buttonText,
        buttonWidth: meta.buttonWidth,
        href: meta.link
      };
    }
  });
});