define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/ccpa-options.html", "mojo/page/settings"], function (declare, Renderable, tpl, pageSettings) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      if (typeof props.state === "undefined") {
        throw new TypeError("state property is required");
      }
    },
    toggleCcpaRestricted: function (isChecked) {
      this.setState({
        googleRestrictedDataProcessing: isChecked
      });
    },
    getData: function () {
      return {
        setting_type: pageSettings.TYPES.ccpa,
        enabled: this.state.googleRestrictedDataProcessing,
        data: {}
      };
    }
  });
});