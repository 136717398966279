/**
 * @module mojo/widgets/ActionButtonPane
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/ActionButtonPane.html", "mojo/widgets/IconButtonGroup"], function (declare, lang, domConstruct, query, on, domClass, Select, _WidgetBase, _TemplatedMixin, tpl, IconButtonGroup) {
  //
  return declare([_WidgetBase, _TemplatedMixin], {
    // get attributes for adding button actions, their priority
    templateString: tpl,
    // button templates have a wrapper div with flex-auto.  it will fill flex space.
    buttonTemplate: "<div class=\"flex-auto text-align--{buttonAlign} padding-top--lv2 padding-bottom--lv2\"><button class=\"button {cssOptions}\">{buttonText}</button></div>",
    selectTemplate: "<div class=\"flex-auto text-align--{selectAlign} padding-top--lv2 padding-bottom--lv2\"></div>",
    postCreate: function () {
      // get attributes for buttons, info text, selectors
      // parse params for button
      if (this.params.button) {
        this._setupButton(this.params.button);
      }
      if (this.params.select) {
        this._setupSelect(this.params.select);
      }
      if (this.params.iconToggle) {
        this._setupIconToggle(this.params.iconToggle);
      }
    },
    _setupButton: function (buttonOptions) {
      var tplOpts = {
        "buttonAlign": buttonOptions.align,
        "buttonText": buttonOptions.buttonText,
        "cssOptions": buttonOptions.colorStyles + " margin-right--lv0"
      };
      var actionButton = domConstruct.place(lang.replace(this.buttonTemplate, tplOpts), this.actionpFlexPaneEl);
      on(actionButton, "button:click", function (evt) {
        buttonOptions.action();
      });
    },
    _setupSelect: function (selectOptions) {
      // add option elements to Select widget
      this.sectionToggle = new Select({
        "class": selectOptions.classNames,
        "options": selectOptions.options
      });
      // setup options for aligning Select in ActionButtonPane flex container
      var tplOpts = {
        "selectAlign": selectOptions.align
      };
      var flexContainerSelect = domConstruct.place(lang.replace(this.selectTemplate, tplOpts), this.actionpFlexPaneEl, selectOptions.order);
      domConstruct.place(this.sectionToggle.domNode, flexContainerSelect, "first");
      // add onChange event or onInput event for select widget
      on(this.sectionToggle, "change", selectOptions.onChange);
      domClass.add(this.sectionToggle.domNode, "\!width--auto");
    },
    /*
    * @function setupIconToggle
    * @property
    */
    _setupIconToggle: function (iconToggleOptions) {
      // iconToggleOptions
      // add option elements to Select widget
      // setup options for aligning Select in ActionButtonPane flex container
      this.iconGroup = new IconButtonGroup(iconToggleOptions);
      domConstruct.place(this.iconGroup.domNode, this.actionpFlexPaneEl, iconToggleOptions.order);
    }
  });
});