define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block", "./_ButtonMixin"], function (declare, _Block, _ButtonMixin) {
  return declare([_Block, _ButtonMixin], {
    type: "promoCode",
    _name: "Promo Code",
    _kbLink: "https://mailchimp.com/help/use-promo-code-content-blocks/",
    _ecommOnly: true,
    _editingTypes: ["campaign", "page"],
    _requiresPlatform: true,
    _paidOnly: false,
    _renderMergeTags: true,
    constructor: function (model) {
      this.styles = [{
        "name": "Container style",
        "selector": ".mcnPromoCodeContentContainer",
        "props": {
          "background-color": {
            "value": "#EFEFEF"
          },
          "background-image": {
            "value": ""
          },
          "background-size": {
            "value": ""
          },
          "background-position": {
            "value": ""
          },
          "background-repeat": {
            "value": "no-repeat"
          },
          "border": {
            "value": "1px solid #999999"
          }
        }
      }, {
        "name": "Promo code",
        "selector": ".mcnPromoCode",
        "props": {
          "color": {
            "value": "#555555"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "48px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "bold"
          }
        }
      }, {
        "name": "Expiration date",
        "selector": ".mcnExpirationDate",
        "props": {
          "color": {
            "value": "#555555"
          },
          "font-family": {
            "value": "Helvetica"
          },
          "font-size": {
            "value": "14px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "normal"
          }
        }
      }].concat(this._getButtonStyles(model._campaignRenderingType));

      // Promo code meta
      this.promoCode = null;
      this.promoId = null;
      this.mergeTag = null;
      this.expirationDate = null;
      this.expirationEnabled = true;
      this.align = "center";

      // Button settings
      this.buttonText = "Shop Now";
      this.buttonWidth = "";
      this.buttonEnabled = true;
      this.link = "";

      // Description settings
      this.descriptionEnabled = false;
      this.description = null;
    },
    updatePromoCode: function (promoId, promoCode, expirationDate, mergeTag) {
      this.promoId = promoId;
      this.promoCode = promoCode;
      this.expirationDate = expirationDate;
      this.mergeTag = mergeTag;
      this.refreshPromos();
    },
    /**
     * Forces a refresh of promos with itself to fire the onChange events
     */
    refreshPromos: function () {
      this.set("promoCode", this.promoCode);
      this.save();
    },
    /**
     * Toggle description in promo block
     */
    toggleDescription: function () {
      this.descriptionEnabled = !this.descriptionEnabled;
      this.refreshPromos();
    },
    /**
     * Toggle expiration date in promo block
     */
    toggleExpiration: function () {
      this.expirationEnabled = !this.expirationEnabled;
      this.refreshPromos();
    },
    /**
     * Toggle button in promo block
     */
    toggleButton: function () {
      this.buttonEnabled = !this.buttonEnabled;
      this.refreshPromos();
    },
    /**
     * Update layout alignment in promo block
     *
     * @param {string} align - new the layout alignment
     */
    updateAlignment: function (align) {
      this.align = align;
      this.refreshPromos();
    }
  });
});