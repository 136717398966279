/*
* AdjustContactTierBlock
* @module mojo/widgets/account/paywall/AdjustContactTierBlock
*
* Widget for the template paywall
*/
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/logger", "mojo/widgets/UpgradeActionBlock"], function (declare, xhr, mUrl, logger, UpgradeActionBlock) {
  return declare([UpgradeActionBlock], {
    isDismissable: true,
    currentPlanId: null,
    onCtaPressCallback: true,
    constructor: function () {
      this.inherited(arguments);
      this.dismissConfig = {
        onDismissPressCallback: this.dismissMistierButton.bind(this),
        innerText: "Dismiss"
      };
      this.onComplete = this.acknowledgeMistier.bind(this);
    },
    acknowledgeMistier: function () {
      this.destroyRecursive();
      xhr(mUrl.toUrl("/account/adjust-plan/set-has-acknowledged-overage", {
        old_plan_id: this.currentPlanId,
        new_plan_id: this.pricingPlanId,
        location: location.pathname
      }), {
        handleAs: "json"
      });
    },
    dismissMistierButton: function () {
      xhr(mUrl.toUrl("/account/adjust-plan/set-has-acknowledged-overage", {
        location: location.pathname
      }), {
        handleAs: "json"
      });

      //Google Analytics
      var label = {
        location: "after_list_upload",
        reason: "adjust_to_save",
        button_text: "dismiss"
      };
      logger.googleAnalytics.trackEvent("adjust_tier", "button_click", JSON.stringify(label));
    }
  });
});