define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on, p2Utils, xhr, mUrl, utils, array, domClass) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      var credits = this.$el.prePayCreditsSelect;
      if (this.$el.billAccountButton) {
        on(this.$el.billAccountButton, "click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          // eslint-disable-next-line no-alert
          if (confirm("Clicking Continue will actually charge the customer for a monthly renewal of their current plan. Before proceeding, ensure that the account is on the correct plan and should be billed now.")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/pre-pay-batch-billing-post", {
              user_id: self.pageJs.userAttrs.userId,
              s: "pre-pay-batch-billing"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": response.message,
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to bill the pre-pay user's account: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      array.forEach(this.$group.prepayOrderTypeRadio, function (radioButton, i) {
        on(radioButton, "change", function () {
          if (radioButton.value === "multi_account_prepay_credits") {
            domClass.remove(self.$el.multiAccountPrepaymentContractIDWrapper, "hide");
            domClass.remove(self.$el.multiAccountPrepaymentTaxAmountWrapper, "hide");
          } else {
            domClass.add(self.$el.multiAccountPrepaymentContractIDWrapper, "hide");
            domClass.add(self.$el.multiAccountPrepaymentTaxAmountWrapper, "hide");
          }
        });
      });
      array.forEach(this.$group.prepayOrderRenewalTypeRadio, function (radioButton, i) {
        on(radioButton, "change", function () {
          if (radioButton.value === "multi_account_prepay_renewal") {
            domClass.remove(self.$el.multiAccountPrepaymentStartDateWrapper, "hide");
          } else {
            domClass.add(self.$el.multiAccountPrepaymentStartDateWrapper, "hide");
          }
        });
      });
      this.updateCreditsAmount(credits.options[credits.selectedIndex].text);
      on(this.$el.prePayCreditsSelect, "change", function () {
        self.updateCreditsAmount(this.options[this.selectedIndex].text);
      });
      on(this.$el.prePayPackageForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.validatePrepayOrderForm();
      });
      on(this.$el.prePayCreditsForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.validatePrepayCreditsForm();
      });
      on(this.$el.prePayMonthlyForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.validatePrepayMonthlyForm();
      });
    },
    /**
     * updates the credit amount select
     * @param val - value from the credits amount select
     */

    updateCreditsAmount: function (val) {
      var dollars = val.match(/\$[0-9]+(\.[0-9][0-9])?/g)[0].replace("$", "");
      this.$el.prePayCreditsAmount.value = dollars;
    },
    /**
     * validates the prepay paygo credits form
     * @return {boolean} - will return if credit amount is empty
     */
    validatePrepayCreditsForm: function () {
      if (this.$el.prePayCreditsAmount.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a value", "error");
        return false;
      }
      this.$el.prePayCreditsForm.submit();
    },
    /**
     * validates the prepay monthly credits form
     * @return {boolean} - will return if the credit amount input and select are empty
     */
    validatePrepayMonthlyForm: function () {
      if (this.$el.prePayAmountInput.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a value", "error");
        return false;
      }
      if (this.$el.prePayMonthlyPlanSelect.value === "") {
        p2Utils.utils.setFlash("Please enter an option from the select box", "error");
        return false;
      }
      this.$el.prePayMonthlyForm.submit();
    },
    /**
     * validates the prepay credits order form
     * @return {boolean} - will return if the "Multi-account Prepayment" is selected and the contract id is empty
     */
    validatePrepayOrderForm: function () {
      if (this.$el["multi-account-prepayment-radio"].checked && this.$el.multiAccountPrepaymentContractID.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a Contract ID value", "error");
        return false;
      }
      if (this.$el["multi-account-prepayment-radio"].checked && this.$el.taxAmountInput.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a Tax Amount value", "error");
        return false;
      }
      if (this.$el["multi-account-prepayment-radio"].checked && this.$el.multiAccountPrepaymentContractLength.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a Contract Length value", "error");
        return false;
      }
      if (this.$el["multi-account-prepayment-radio"].checked && this.$el["multi-account-prepayment-renewal"].checked && this.$el.multiAccountPrepaymentStartDate.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a Contract Start Date value", "error");
        return false;
      }
      this.$el.prePayPackageForm.submit();
    }
  });
});