/**
 * @class mojo.report.widgets.StatsTableEntries
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/StatsTableEntries.html", "../Stat", "vendor/lodash-amd/every"], function (declare, Renderable, tpl, Stat, every) {
  var StatsTableEntries = declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      var stats = props.stats;
      if (Array.isArray(stats) === false) {
        throw new Error("Must provide `stats` prop with type `array`");
      }
      var everyStatIsStat = every(stats, function (stat) {
        var isStat = stat instanceof Stat;
        var isArray = Array.isArray(stat);
        if (isArray) {
          return every(stat, function (subStat) {
            return subStat instanceof Stat;
          });
        }
        return isStat;
      });
      if (everyStatIsStat === false) {
        throw new Error("`stats` prop may contain only `mojo.report.Stat`s or arrays of `mojo.report.Stat`s");
      }
      var format = props.format;
      if (typeof format !== "string") {
        throw new Error("Must provide `format` prop with type `string`");
      }
      if (StatsTableEntries.FORMATS.indexOf(format) === -1) {
        var validFormats = StatsTableEntries.FORMATS.map(function (validFormat) {
          return "'" + validFormat + "'";
        }).join(" ");
        throw new Error("`format` prop value must be one of: " + validFormats + ". Found '" + format + "'");
      }
      this.state = {
        stats: stats,
        format: format,
        FORMAT_FACTOID: StatsTableEntries.FORMAT_FACTOID,
        FORMAT_BREAKDOWN: StatsTableEntries.FORMAT_BREAKDOWN
      };
    }
  });
  StatsTableEntries.FORMAT_FACTOID = "factoid";
  StatsTableEntries.FORMAT_BREAKDOWN = "breakdown";
  StatsTableEntries.FORMATS = [StatsTableEntries.FORMAT_FACTOID, StatsTableEntries.FORMAT_BREAKDOWN];
  return StatsTableEntries;
});