/**
 * Android Device Interface
 * @module /mojo/widgets/ads/mobile-checklist/AndroidDevice.js
 * @see /mojo/widgets/ads/mobile-checklist/_DeviceMixin.js
 *
 * Each variation of the device interface has it's own quirks. Most of the "work"
 * happens in the _DeviceMixin mixin.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/ads/mobile-checklist/_DeviceMixin"], function (declare, _DeviceMixin) {
  return declare([_DeviceMixin], {
    platformType: "android",
    /**
     * Map actions to the window-level methods that Android is listening for. The Android WebView will inject
     * these methods as part of creation of the web view.
     *
     * @private
     */
    __actionEventRouterReady: function () {
      window.Android.readyToReceiveEvents();
    },
    __actionSlatLoaded: function () {
      window.Android.slatLoaded();
    },
    __actionSlatFinished: function (payload) {
      // For Android we must send the object (payload) as a string
      // or else it will return undefined
      window.Android.isFinished(JSON.stringify(payload));
    },
    __actionSlatSaveFailed: function () {
      window.Android.slatSaveFailed();
    },
    __actionExpiredSession: function () {
      window.Android.expiredSession();
    },
    __actionOpenFileBrowse: function () {
      window.Android.openFileBrowse();
    }
  });
});