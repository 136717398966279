define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/contentPane.html", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, domConstruct, domClass, lang, tpl, flags, context, I18nTranslation) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    imageTpl: '<img src="{src}" class="!margin-bottom--lv8 drop-shadow">',
    store: null,
    selectedClient: null,
    translatedPoweredBy: "Powered by",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.EEE_BACKFILL_STRINGS_NUNI)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedPoweredBy = I18nTranslation.translate("nuni_editor_powered_by");
        }.bind(this));
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      this.own(this.store.previews.watch(lang.hitch(this, function (clientName) {
        if (this.selectedClient === clientName) {
          this._showInboxPreview();
        }
      })));
      this.watch("selectedClient", lang.hitch(this, function () {
        this._showInboxPreview();
      }));
      this._showInboxPreview();
    },
    _showInboxPreview: function () {
      var preview = this.getPreview();
      if (preview) {
        domConstruct.empty(this.contentContainer);
        switch (preview.status) {
          case "queued":
            domClass.add(this.contentContainer, "hide");
            domClass.add(this.litmusMsgContainer, "hide");
            domClass.remove(this.loadingContainer, "hide");
            domClass.add(this.loadingContainer, "animate-preview-pane");
            break;
          case "failed":
            domClass.add(this.contentContainer, "hide");
            domClass.add(this.litmusMsgContainer, "hide");
            domClass.remove(this.loadingContainer, "hide");
            domClass.remove(this.loadingContainer, "animate-preview-pane");
            break;
          case "completed":
            domConstruct.place(lang.replace(this.imageTpl, {
              "src": preview.url
            }), this.contentContainer, "only");
            domClass.add(this.loadingContainer, "hide");
            domClass.remove(this.loadingContainer, "animate-preview-pane");
            domClass.remove(this.contentContainer, "hide");
            domClass.remove(this.litmusMsgContainer, "hide");
            break;
        }
      } else {
        domClass.add(this.contentContainer, "hide");
        domClass.remove(this.loadingContainer, "hide");
      }
    },
    getPreview: function () {
      if (this.selectedClient) {
        return this.store.previews[this.selectedClient];
      } else {
        return null;
      }
    }
  });
});