define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "mojo/neapolitan/editors/_CKMixin", "mojo/user", "mojo/context", "mojo/lib/flags", "dojo/text!./templates/ImageCardEditor/editor.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "mojo/neapolitan/MaxWidthRestriction", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, _Base, _CKMixin, user, context, flags, tmpl, lang, query, domConstruct, html, MaxWidthRestriction, _DynamicContentMixin) {
  return declare([_Base, _CKMixin, _DynamicContentMixin], {
    type: "imageCard",
    templateString: tmpl,
    postCreate: function () {
      this.inherited(arguments);
      this._eventAndSyncSetup();
      this._toggleOptions();
      this._setupPreservedContent();
    },
    _eventAndSyncSetup: function () {
      this._setImagePadding();
      document.getElementById("editor-pane").classList.add("embedded");
      this.sync("captionPosition", this.captionPositionInput, "value");
      this.sync("captionWidth", this.captionWidthInput, "value");
      this.sync("align", this.alignInput, "value");
      this.sync("edgeToEdge", this.edgeToEdgeToggle, "checked");
      this.watch("captionPosition", lang.hitch(this, function () {
        this._toggleOptions();
      }));
      this.watch("edgeToEdge", lang.hitch(this, function () {
        this._setImagePadding();
      }));
      this.connect(this.imageRow, "onChange", "onImageListChange");
      MaxWidthRestriction.addTo(this.imageRow);
      this._updateImage();
      this.watch("caption", lang.hitch(this, "_updateImage"));
    },
    _updateImage: function () {
      this.imageRow.set("image", this.block.caption.image);
      this.imageRow.render();
    },
    _setImagePadding: function () {
      if (this.block.edgeToEdge) {
        this.originalImagePadding = this.outerPadding;
        this.imagePadding = 0;
      } else if (this.originalImagePadding) {
        this.imagePadding = this.originalImagePadding;
      }
    },
    startup: function () {
      this.showCKEditor(this.ckeditorContainer, this.timestampContainer, this.block.caption.text, lang.hitch(this, "publishReady"));
    },
    show: function () {
      this.publishSetup();
    },
    onCKChange: function () {
      var caption = this.block.caption;
      caption.text = this.editor.getData();
      this.block.set("caption", caption);
    },
    _reloadEditor: function () {
      this.showCKEditor(this.ckeditorContainer, this.timeStampContainer, this.block.caption.text);
    },
    _toggleOptions: function () {
      switch (this.block.captionPosition) {
        case "left":
        case "right":
          query(this.captionWidthInputContainer).style("display", "block");
          var captionSide = this.block.captionPosition.charAt(0).toUpperCase() + this.block.captionPosition.slice(1) + " ";
          html.set(this.captionSide, captionSide);
          break;
        case "top":
        case "bottom":
          query(this.captionWidthInputContainer).style("display", "none");
          query(this.alignInputContainer).style("display", "block");
          break;
        default:
          break;
      }
    },
    onUploading: function (isUploading) {
      this.imageRow.toggleUploadMessage(isUploading);
    },
    onImageListChange: function () {
      var caption = this.block.caption;
      caption.image = this.imageRow.image;
      this.block.set("caption", caption);
      this.save();
    }
  });
});