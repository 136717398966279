define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/FeaturesList.html"], function (declare, domConstruct, domClass, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    featuresListNode: null,
    headerNode: null,
    descriptionNode: null,
    // from constructor
    listItems: null,
    header: null,
    description: null,
    postCreate: function () {
      this._renderHeaderAndDescription();
      this._renderListItems();
    },
    _renderHeaderAndDescription: function () {
      this.headerNode.innerText = this.header;
      if (this.description) {
        domClass.remove(this.descriptionNode, "hide");
        this.descriptionNode.innerText = this.description;
      }
    },
    _renderItem: function (listItemContent) {
      var content = typeof listItemContent === 'object' ? listItemContent.label : listItemContent;
      var listItemElement = domConstruct.create("li");
      domClass.add(listItemElement, "margin-bottom--lv1");
      listItemElement.innerHTML = "<span class='freddicon menu-right deprecated-textColorGreen'></span><span class='margin-left--lv2'>" + content + "</span>";
      domConstruct.place(listItemElement, this.featuresListNode);
    },
    _renderListItems: function () {
      this.listItems.map(this._renderItem.bind(this));
    }
  });
});