define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "mojo/views/_base", "mojo/url", "mojo/utils", "mojo/utils/string", "mojo/app/lists/segments", "mojo/app/pro/segment", "dijit/registry"], function (declare, lang, all, base, mUrl, utils, stringUtils, segments, ProSegment, registry) {
  return declare([base], {
    listId: null,
    segmentList: null,
    advancedSegmentList: null,
    onReady: function () {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "list_detail",
          user_id: this.userId,
          login_id: this.loginId,
          list_id: this.listId
        });
      }
      this.segmentList = registry.byId("segments-list");
      this.advancedSegmentList = registry.byId("advanced-segments-list");
    },
    /**
     * Show the confirm dialog and updates the execute to delete selected.
     */
    confirmDeleteSelected: function () {
      var dialog = registry.byId("confirm-delete-segment");
      var self = this;
      dialog.onExecute = function () {
        dialog.hide();
        self.deleteSelectedSegments();
      };
      dialog.show();
    },
    /**
     * Find all checked segments and deletes them.
     */
    deleteSelectedSegments: function () {
      var selectedIds = this.segmentList.getSelection();
      segments.deleteSegment(this.listId, selectedIds.join(",")).then(lang.hitch(this, function () {
        this.segmentList.render();
        this._toastDeleteMessage(selectedIds.length);
      }), function () {
        utils.toast("Error occurred and could not delete segments");
      });
    },
    /**
     * Use the ProSegment JS API and select this segment and direct to the contact table. Include a param
     * that tells the screen to automatically open the editor
     *
     * @param {string} segmentId id for the advanced segment
     */
    editAdvancedSegment: function (segmentId) {
      ProSegment.setListSegment(this.listId, segmentId).then(lang.hitch(this, function () {
        window.top.location.href = mUrl.toUrl("/lists/members", {
          "id": this.listId,
          "open_segment": 1
        });
      }));
    },
    /**
     * Use the ProSegment JS API and select this segment and direct to the contact table
     *
     * @param {string} segmentId id for the advanced segment
     */
    viewAdvancedSegment: function (segmentId) {
      ProSegment.setListSegment(this.listId, segmentId).then(lang.hitch(this, function () {
        window.top.location.href = mUrl.toUrl("/lists/members", {
          "id": this.listId
        });
      }));
    },
    /**
     * Show the confirm dialog and updates the execute to delete selected advanced segments.
     */
    confirmDeleteSelectedAdvanced: function () {
      var dialog = registry.byId("confirm-delete-segment");
      var self = this;
      dialog.onExecute = function () {
        dialog.hide();
        self.deleteSelectedAdvancedSegments();
      };
      dialog.show();
    },
    /**
     * Delete all advanced segments that are selected
     */
    deleteSelectedAdvancedSegments: function () {
      var selectedIds = this.advancedSegmentList.getSelection();
      var listId = this.listId;
      var promises = selectedIds.map(function (id) {
        return ProSegment.deleteSegment(listId, id);
      });
      all(promises).then(lang.hitch(this, function () {
        this.advancedSegmentList.render();
        this._toastDeleteMessage(selectedIds.length);
      }));
    },
    /**
     * Re-run an advanced segment and refresh the list.
     *
     * @param {string} segmentId id for advanced segment
     */
    reRunAdvancedSegment: function (segmentId) {
      ProSegment.reRunSegment(this.listId, segmentId).then(lang.hitch(this, function () {
        this.advancedSegmentList.render();
      }));
    },
    /**
     * Delete a specific advanced segment and refresh the view
     *
     * @param {string} segmentId id for advanced segment
     */
    deleteAdvancedSegment: function (segmentId) {
      ProSegment.deleteSegment(this.listId, segmentId).then(lang.hitch(this, function () {
        this.advancedSegmentList.render();
        this._toastDeleteMessage(1);
      }));
    },
    /**
     * Show a toast for delete segments
     *
     * @param {number} totalDeleted number of segments that were deleted
     * @private
     */
    _toastDeleteMessage: function (totalDeleted) {
      var message = totalDeleted + " " + stringUtils.toPlural("segment", totalDeleted) + " successfully deleted";
      utils.toast(message);
    }
  });
});