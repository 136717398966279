/**
 * Widget built to manage the edit state of the Audience Facebook slat.
 *
 * @module mojo/widgets/ads/facebook/EditAudienceSlat
 * @see mojo/widgets/ads/_EditSlatMixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/user", "mojo/utils", "mojo/url", "mojo/utils/string", "mojo/authz", "mojo/lib/logger", "dijit/form/CheckBox", "dijit/form/Select",
// Widgets
"./edit-audience/LocationInput", "./edit-audience/InterestInput", "mojo/widgets/FeedbackBlock", "mojo/widgets/input/InfoSelect", "mojo/widgets/SegmentDropdown", "mojo/widgets/UpgradeActionBlock", "mojo/widgets/ads/TosDialog",
// for mobile check
"mojo/neapolitan/mobile/utils",
// Mixin
"mojo/widgets/ads/checklist/_EditSlatMixin",
// Templates
"dojo/text!./edit-audience/templates/EditAudienceSlat.html",
// Constants
"../constants"], function (declare, lang, domAttr, domClass, domConstruct, number, on, query, topic, context, flags, user, utils, mUrl, stringUtils, Authz, logger, CheckBox, Select, LocationInput, InterestInput, FeedbackBlock, InfoSelect, SegmentDropdown, UpgradeActionBlock, TosDialog, mobileUtils, _EditSlatMixin, tplStr, adsConstants) {
  var TOPIC_GEOTARGETING = "ads/facebook-location-taglist";
  var LOOKALIKE = "lookalike";
  var LIST = "list";
  var INTERESTS = "interests";
  var CONNECTED_SITE = "connected-site";
  var TARGET_LIST = "targetList";
  var TARGET_LIKES = "targetLikes";
  var GENDER_ALL = 0;
  var GENDER_MEN = 1;
  var GENDER_WOMEN = 2;
  var AGE_MIN = 18;
  var AGE_MAX = 65;
  return declare([_EditSlatMixin, Authz], {
    content: tplStr,
    slatId: "audience",
    postEndpoint: "/ads/facebook/post-audience",
    constructor: function () {
      this.adsConstants = adsConstants;
    },
    postCreate: function () {
      this.inherited(arguments);
      var self = this;

      // Don't allow users to save if they have conflicting locations
      topic.subscribe(TOPIC_GEOTARGETING, function (data) {
        if (!self.saveButton) {
          return;
        }
        domClass.toggle(self.saveButton, "disabled", !!data.conflicts);
      });
      var group = this.audienceChoiceCardGroup;
      group.onOptionsChanged = this.handleAudienceChoice.bind(this);
      this.canUseFacebookRemarketing = this.userHasFeature("ad_facebook_retargeting");
      var showWebsiteVisitorPaidBadge = !this.canUseFacebookRemarketing;
      group.addOption({
        type: "radio",
        id: CONNECTED_SITE,
        title: "Website visitors",
        description: "Reconnect with people after they visit your website.",
        badge: showWebsiteVisitorPaidBadge ? "Upgrade" : null,
        disabled: false
      });
    },
    /**
     * Setup the data for this slat.
     *
     * @param {obj} data - an object with keys sent from the backend w/ necessary info to render
     * the widget on first load.
     */
    setData: function (data) {
      // Fill in widget-level vars that we're gonna need
      this.ad = data.ad;
      this.connectedSites = data.connectedSites;
      this.audienceType = data.audienceType;
      this.targetLikes = data.targetLikes;
      this.emailSourceId = data.emailSourceId;
      this.sourceIsSegment = data.sourceIsSegment;
      this.selectedSegment = data.selectedSegment;
      this.hasContacts = data.hasContacts;
      this.hasSimilar = data.hasSimilar;
      this.lists = data.lists;
      this.countries = data.countries;
      this.defaultListId = data.defaultListId;
      this.currentRequiredSize = data.currentRequiredSize;
      this.excludeContactAudience = data.excludeContactAudience;
      this.hasAcceptedLandingPageTOS = data.hasAcceptedLandingPageTOS;
      this.hasSpecialAdCategory = data.hasSpecialAdCategory;
      this._init();
      this.handleAudienceChoice();
    },
    /**
     * Handles initial loading of all elements in the widget.
     * @private
     */
    _init: function () {
      this._initChoiceCards();
      this._initTargetInput();
      this._initListInput();
      this._initSegmentInput();
      this._initIncludeListInput();
      this._initIncludeLikesInput();
      this._initCountryInput();
      this._initLocationInput();
      this._initGenderInput();
      this._initAgeInput();
      this._initInterestsInput();
      this._toggleExcludeContactMessage();
      this._toggleIncludeListContainer();
      this._initConnectedSitesInput();
    },
    /**
     * Loop through the available audience views and show appropriate one based on selection.
     */
    handleAudienceChoice: function () {
      var self = this;
      var audienceType = this._getSelectedAudience();
      var formElements = query("[data-form-type]");
      formElements.map(function (element) {
        utils.removeAllErrors(element);
        return domAttr.getNodeProp(element, "data-form-type").indexOf(audienceType) < 0 ? domClass.add(element, "hide") : domClass.remove(element, "hide");
      });
      // destroy notification for audience CONNECTED_SITE
      if (this.upgradeActionBlock) {
        this.upgradeActionBlock.destroyRecursive();
      }
      // enable save
      if (this.saveButton) {
        domAttr.remove(this.saveButton, "disabled");
      }
      // Check for changes to the list dropdown, and modify the list to enable/disable appropriate options.
      switch (audienceType) {
        case LOOKALIKE:
          self.currentRequiredSize = adsConstants.minLookalikeCount;
          break;
        case LIST:
          self.currentRequiredSize = adsConstants.minAdCount;
          break;
        case CONNECTED_SITE:
          if (!this.canUseFacebookRemarketing) {
            // show notification for audience CONNECTED_SITE
            if (!mobileUtils.getNativeObject()) {
              this._renderRemarketingNotification();
            }
            // disable save
            if (this.saveButton) {
              domAttr.set(this.saveButton, "disabled", true);
            }
          } else {
            // enable save
            domAttr.remove(this.saveButton, "disabled");
          }
          if (this.hasAcceptedLandingPageTOS === false) {
            if (!this.dialog) {
              //This is to assure that terms of service only appears once.
              this.dialog = new TosDialog({
                ad: this.ad,
                type: "facebook"
              });
              this.dialog.show().then(function () {
                domAttr.set(this.saveButton, "disabled", false);
              }.bind(this), function () {
                this.dialog.cancel();
                domAttr.set(this.saveButton, "disabled", true);
                this.dialog = null; // clears dialog so the tos can appear if the user were to return to "connect-sites"
              }.bind(this));
            }
          }
          break;
        default:
          break;
      }
      var options = this.listInput.getOptions();
      options.forEach(function (option) {
        if (option.meta) {
          // make sure we parse according to English rules, where thousands are grouped by commas.
          var count = number.parse(option.meta.split(" ")[0], {
            locale: "en-us"
          });
          option.disabled = count < self.currentRequiredSize || option.listDisabled;
        }
      });
      this.listInput.updateOption(options);
      this._toggleTargetInputDisable();
      this.handleTargetToggle();
    },
    /**
     * Disable list target option if conditions are not met.
     */
    _toggleTargetInputDisable: function () {
      var listInput = this.targetInputList;
      var likesInput = this.targetInputLikes;
      var checkedOptions = this.audienceChoiceCardGroup.getCheckedOptions();
      var checkedOption = checkedOptions[0] ? checkedOptions[0].id : null;

      // Checks to see if New People radio is selected and if the user's list is not > 500 contacts
      var disableLookAlikeAudience = checkedOption === "lookalike" && !this.hasSimilar;

      // Checks to see if Contacts radio is selected and if the user's list is not > 100 contacts
      var disableListAudience = checkedOption === "list" && !this.hasContacts;

      // Checks to see if flag for Merch is on and user has feature this.canUseFacebookRemarketing
      var disableConnectedSite = checkedOption === CONNECTED_SITE && !this.canUseFacebookRemarketing;

      // If contact audience is being excluded we want to disable the Your List option
      var disableListExcludedContacts = checkedOption === "list" && this.excludeContactAudience;
      if (disableLookAlikeAudience || disableListAudience) {
        this._disableCheckRadio(likesInput, listInput);
        // Hides the message if contacts are being excluded, if not show message
        domClass.toggle(this.targetListDisabledMessage, "hide", disableListExcludedContacts);
        this.handleTargetToggle();
      } else if (disableListExcludedContacts) {
        this._disableCheckRadio(likesInput, listInput);
      } else if (disableConnectedSite) {
        if (this.connectedSitesList && this.connectedSitesList.domNode) {
          this._disableConnectedSitesSelect(this.connectedSitesList.domNode);
        }
      } else {
        this.targetInputList.set("disabled", false);
        this._enableConnectedSitesSelect(this.connectedSitesList.domNode);
        domClass.add(this.targetListDisabledMessage, "hide");
      }
    },
    /**
     * Listen for a country update and notify the tag list widget.
     */
    handleCountryUpdate: function () {
      if (this.locationTagListInput && this.locationTagListInput.length > 0) {
        this.locationTagListInput.set("country", this.countryInput.getValue());
      }
    },
    /**
     * Hides the segment dropdown if no list id.
     */
    handleListUpdate: function () {
      if (!this.listInput) {
        return;
      }
      var listId = this.listInput.value;
      if (listId) {
        this.segmentInput.load(listId);
        domClass.remove(this.segmentContainer, "hide");
      } else {
        domClass.add(this.segmentContainer, "hide");
      }
    },
    /**
     * Method to disable a radio input from being selected and check the other
     * @param {domNode} radioToCheck - Radio Button to automatically check
     * @param {domNode} radioToDisable - Radio Button to disable
     * @private
     */
    _disableCheckRadio: function (radioToCheck, radioToDisable) {
      radioToCheck.set("checked", true);
      radioToDisable.set("disabled", true);
    },
    _disableConnectedSitesSelect: function (domEl) {
      domAttr.set(domEl, "disabled", true);
      domClass.toggle(domEl, "hide", true);
    },
    _enableConnectedSitesSelect: function (domEl) {
      domAttr.remove(domEl, "disabled");
      domClass.toggle(domEl, "hide", false);
    },
    /**
     * Show / Hide the exclude your list message
     * @private
     */
    _toggleExcludeContactMessage: function () {
      domClass.toggle(this.audienceExclusionMessage, "hide", !this.excludeContactAudience);
    },
    /**
     * Show / Hide the include your list checkbox
     * for targeting New People similar to list
     * @private
     */
    _toggleIncludeListContainer: function () {
      domClass.toggle(this.includeListContainer, "hide", this.excludeContactAudience);
    },
    /**
     * Hide radio toggle options if radio is not selected.
     */
    handleTargetToggle: function () {
      var selectedTarget = this._getSelectedTargeting();
      domClass.toggle(this.targetListContainer, "hide", selectedTarget !== TARGET_LIST);
      domClass.toggle(this.targetLikesContainer, "hide", selectedTarget !== TARGET_LIKES);
      // Hide segment dropdown if no list selected
      this.handleListUpdate();
    },
    /**
     * Called from within the template. Format the data that's going to the backend and POST out to the endpoint.
     */
    handleSave: function () {
      var audienceData = {};
      this._isValid();
      switch (this._getSelectedTargeting()) {
        case TARGET_LIST:
          var segmentSelect = this.segmentInput.segmentSelect;
          audienceData = {
            "targeting_likes": false,
            "list_id": this.listInput.value,
            "segment_id": segmentSelect.getValue(),
            "include_source": this._audienceAllowsIncludingExistingContacts() ? this.includeListInput.checked : null
          };
          break;
        case TARGET_LIKES:
          audienceData = {
            "targeting_likes": true,
            "include_source": this._audienceAllowsIncludingExistingContacts() ? this.includeLikesInput.checked : null
          };
          break;
        case null:
          // Folks likely have "interests" selected, do nothing.
          break;
        default:
          throw new Error("Unknown targeting option");
      }
      var interestData = {
        "country": this._getSelectedCountry(),
        "locations": [this.locationTagListInput.getValues()],
        "gender": this._getSelectedGender(),
        "age_min": this.ageMinInput.getValue(),
        "age_max": this.ageMaxInput.getValue(),
        "interests": [this.interestsTagListInput.getValues()]
      };
      var audience = this._getSelectedAudience();
      var formData;
      if (audience === CONNECTED_SITE) {
        formData = {
          "site_id": this.connectedSitesList.value
        };
      } else {
        formData = lang.mixin(this._audienceAllowsListOrFacebookTargeting() ? audienceData : {}, this._audienceAllowsInterestTargeting() ? interestData : {});
      }

      // Combine formData with needed info and post out to the server.
      this.postForm(lang.mixin(formData, {
        "id": this.ad.id,
        "audience": audience
      }));
    },
    onPostError: function (response) {
      // Handle errors and show appropriate messaging.
      utils.showFormErrors(this.domNode, response.errors);
    },
    /**
     * Handles initial loading of choice cards. Sets which card is checked
     * and whether any cards are disabled or not.
     * @private
     */
    _initChoiceCards: function () {
      // Set which card is checked on load
      var choiceCard = this.audienceChoiceCardGroup.getChoiceCard(this.audienceType);

      // get all card types and adjust what is disabled or enabled depending on if there
      // is a special ad category or not
      var interestsCard = this.audienceChoiceCardGroup.getChoiceCard("interests");
      var lookalikeCard = this.audienceChoiceCardGroup.getChoiceCard("lookalike");
      var listCard = this.audienceChoiceCardGroup.getChoiceCard("list");
      if (this.hasSpecialAdCategory) {
        // show special ad category copy
        this.specialAdCategoryText.style.display = "block";
        if (interestsCard) {
          interestsCard.setDisabled("true", "special_ad_category");
        }
        if (lookalikeCard) {
          lookalikeCard.setDisabled("true", "special_ad_category");
        }
        if (listCard) {
          listCard.setChecked(true);
        }
      } else {
        if (choiceCard) {
          choiceCard.setChecked(true);
        }
      }
    },
    /**
     * Set which radio option is checked based on data from backend.
     * @private
     */
    _initTargetInput: function () {
      var radioNode = this.targetLikes ? this.targetInputLikes : this.targetInputList;
      radioNode.set("checked", true);
      this.handleTargetToggle();
    },
    /**
     * Handles initial loading of lists. Creates list options
     * and sets which is selected.
     * @private
     */
    _initListInput: function () {
      var self = this;
      var options = [];
      options.push({
        "value": "",
        "label": "Choose an audience"
      });

      // Create all the options for the list select
      for (var i = 0; i < self.lists.length; i++) {
        options.push({
          "value": self.lists[i]["id"],
          "label": self.lists[i]["name"],
          "meta": self.lists[i]["contactsMemberCount"] + " " + stringUtils.toPlural("contact", self.lists[i]["contactsMemberCount"]),
          "disabled": self.lists[i]["contactsMemberCount"] < self.currentRequiredSize,
          "selected": self.lists[i]["contactsMemberCount"] >= self.currentRequiredSize && self.lists[i]["id"] === self.emailSourceId,
          "listDisabled": self.lists[i]["isDisabled"]
        });
      }
      this.input = new InfoSelect({
        "class": "select-small !margin-bottom--lv3 margin-right--lv0 full-width",
        "name": "list",
        "options": options
      });
      this.input.placeAt(this.listInput);
      this.input.startup();
      this.listInput = this.input;
    },
    /**
     * Handles initial loading of segments. Creates segment options
     * and sets which is selected. Shows and hides container.
     * @private
     */
    _initSegmentInput: function () {
      var self = this;

      // Hide the entire option if conditions aren't met
      if (!this.sourceIsSegment && !this.defaultListId) {
        domClass.add(self.segmentContainer, "hide");
      }
      this.input = new SegmentDropdown({
        "class": "margin-bottom--lv4 margin-right--lv0 full-width",
        "sourceUrl": "lists/segments/saved-ads",
        "listId": self.sourceIsSegment ? self.emailSourceId : self.defaultListId,
        "selected": self.selectedSegment,
        "firstOption": null
      });
      this.input.placeAt(this.segmentInput);
      this.input.startup();
      this.segmentInput = this.input;
    },
    /**
     * Handles initial loading of the include list checkbox.
     * Creates checkbox and sets based on data from backend.
     * Disables if contacts are excluded from ad
     * @private
     */
    _initIncludeListInput: function () {
      var self = this;
      this.input = new CheckBox({
        "type": "checkbox",
        "value": this.ad.includeSourceInTarget,
        "checked": this.excludeContactAudience ? false : this.ad.includeSourceInTarget,
        "disabled": this.excludeContactAudience,
        "readOnly": this.excludeContactAudience
      });
      this.input.placeAt(this.includeListInput);
      this.input.startup();
      this.includeListInput = this.input;
    },
    /**
     * Handles initial loading of the include likes checkbox.
     * Creates checkbox and sets based on data from backend.
     * @private
     */
    _initIncludeLikesInput: function () {
      var self = this;
      this.input = new CheckBox({
        "type": "checkbox",
        "value": this.ad.includeSourceInTarget,
        "checked": this.ad.includeSourceInTarget
      });
      this.input.placeAt(this.includeLikesInput);
      this.input.startup();
      this.includeLikesInput = this.input;
    },
    /**
     * Handles initial loading of country. Creates country options
     * and sets which is selected.
     * @private
     */
    _initCountryInput: function () {
      var self = this;
      var options = [{
        label: "Choose one",
        value: 0
      }];

      // Create all the options for country select
      Object.keys(this.countries).forEach(function (key) {
        options.push({
          value: key,
          label: self.countries[key],
          selected: self.ad.lookalikeCountryCode === key
        });
      });
      this.countryInput = new Select({
        options: options,
        name: "country",
        id: "audience-country",
        maxHeight: -1,
        "class": "select-small size1of2 margin-bottom--lv4",
        "aria-describedby": "countryDescription"
      }).placeAt(this.countryInputContainer);

      // Watch countryInput select, if change then handle update
      on(this.countryInput, "change", lang.hitch(this, "handleCountryUpdate"));
    },
    /**
     * Handles initial loading of location. Creates the location tag
     * list widget and sets values within.
     *
     * Also creates conflict message feedback block
     * @private
     */
    _initLocationInput: function () {
      var self = this;
      this.input = new LocationInput({
        "locations": self.ad.targetingSpecs.locations
      });
      this.input.placeAt(this.locationTagListInput);
      this.input.startup();
      this.locationTagListInput = this.input;
      this.message = new FeedbackBlock({
        "title": "Heads up!",
        "message": "Areas can’t overlap. Please remove the location(s) you don’t want to use.",
        "type": "warning"
      });
      this.message.placeAt(this.locationConflictMessage);
      this.message.startup();
      this.locationConflictMessage = this.message;
    },
    /**
     * Handles initial loading of gender. Sets which gender is selected.
     * @private
     */
    _initGenderInput: function () {
      switch (this.ad.targetingSpecs.gender) {
        case GENDER_ALL:
          this.genderAllInput.checked = true;
          break;
        case GENDER_MEN:
          this.genderMenInput.checked = true;
          break;
        case GENDER_WOMEN:
          this.genderWomenInput.checked = true;
          break;
        default:
          break;
      }
    },
    /**
     * Handles initial loading of age. Creates age options for
     * min and max selects and sets which are selected.
     * @private
     */
    _initAgeInput: function () {
      var minOptions = [],
        maxOptions = [];

      // Create all the options for both age selects
      for (var i = AGE_MIN; i <= AGE_MAX; i++) {
        // Need to pass value and label as string
        var j = i.toString();
        minOptions.push({
          value: j,
          label: j,
          selected: this.ad.targetingSpecs.minAge === i
        });
        maxOptions.push({
          value: j,
          label: i === AGE_MAX ? i + "+" : j,
          selected: this.ad.targetingSpecs.maxAge === i || AGE_MAX === i && !this.ad.targetingSpecs.maxAge
        });
      }

      // Create and place age select boxes
      this.ageMinInput = new Select({
        options: minOptions,
        name: "age_min",
        id: "age_min",
        maxHeight: -1,
        "class": "select-small margin-bottom--lv3 !margin-left-right--lv0 !width--auto",
        "aria-describedby": "minAgeLabel",
        "aria-labelledby": "ageLabel"
      }).placeAt(this.ageMinInputContainer);
      this.ageMaxInput = new Select({
        options: maxOptions,
        name: "age_max",
        id: "age_max",
        maxHeight: -1,
        "class": "select-small margin-bottom--lv3 !margin-left-right--lv0 !width--auto",
        "aria-describedby": "maxAgeLabel",
        "aria-labelledby": "ageLabel"
      }).placeAt(this.ageMaxInputContainer);
    },
    /**
     * Handles initial loading of interests. Creates the interests tag
     * list widget and sets values within.
     * @private
     */
    _initInterestsInput: function () {
      var self = this;
      this.input = new InterestInput({
        "interests": self.ad.targetingSpecs.interests
      });
      this.input.placeAt(this.interestsTagListInput);
      this.input.startup();
      this.interestsTagListInput = this.input;
    },
    _initConnectedSitesInput: function () {
      var self = this;
      if (this.connectedSites.length) {
        var options = this.connectedSites.map(function (site) {
          return {
            value: site.id,
            label: site.name,
            selected: self.ad.site_id === site.id
          };
        });
        this.connectedSitesList.addOption(options);
        this.connectedSitesList.set("value", self.ad.site_id || "");
      } else {
        domClass.remove(this.noConnectedSites.domNode, "hide");
        domClass.add(this.connectedSitesList.domNode, "hide");
      }
    },
    /**
     * Helper method to disable a choice card.
     * @param {domNode} card - The highest-level choice card div
     * @param {domNode} input - The true hidden input of the choice card
     * @param {domNode} label - The visible portion of the choice card
     * @private
     */
    _disableCard: function (card, input, label) {
      input.disabled = true;
      domClass.add(card, "cursor-not-allowed");
      domClass.add(label, "dim6 disabled-container");
    },
    /**
     * Simple validation to make sure we're submitting values that the backend can interpret. This
     * is not encompassing of every input available.
     *
     * @private
     */
    _isValid: function () {
      // Using a correct audience type? The backend will only look for one of these three.
      var validAudienceOptions = [LOOKALIKE, LIST, INTERESTS, CONNECTED_SITE];
      if (validAudienceOptions.indexOf(this._getSelectedAudience()) === -1) {
        throw new Error(this._getSelectedAudience() + " is an invalid audience option");
      }

      // We may know there are more than these genders, but the backend doesn't.
      var validGenders = [GENDER_ALL, GENDER_MEN, GENDER_WOMEN];
      if (validGenders.indexOf(this._getSelectedGender()) === -1) {
        throw new Error(this._getSelectedGender() + " is an invalid gender option. The backend is expecting a 0, 1, or 2.");
      }

      // We only want them to be able to select from options we provide them. "0" is the default "Choose one"
      // value
      var countryCode = this._getSelectedCountry();
      if (countryCode !== null && countryCode !== "0" && !this.countries.hasOwnProperty(countryCode)) {
        throw new Error(countryCode + " does not match one of the available country options.");
      }
    },
    /**
     * Helper method to test whether or not to include interest targeting settings
     *
     * @return {boolean} - Does the currently selected audience allow interest targeting
     * @private
     */
    _audienceAllowsInterestTargeting: function () {
      return this._getSelectedAudience() !== LIST;
    },
    /**
     * Helper method to test whether or not to include list/FB targeting options
     *
     * @return {boolean} - Does the currently allow list or FB page like data?
     * @private
     */
    _audienceAllowsListOrFacebookTargeting: function () {
      return this._getSelectedAudience() !== INTERESTS;
    },
    /**
     * For both Facebook Page Likes and list-based targeting we offer the ability to "Include contacts as part of
     * the audience." This option is not available for other audience types.
     *
     * @return {boolean} - Has a checkmark for including contacts as part of audience.
     * @private
     */
    _audienceAllowsIncludingExistingContacts: function () {
      return this._getSelectedAudience() === LOOKALIKE;
    },
    /**
     * Helper method to get the selected audience type. This correlates to the big radio buttons at the top of the
     * slat.
     *
     * @return {string} - The value of the top-level audience decision.
     * @private
     */
    _getSelectedAudience: function () {
      var checkedOptions = this.audienceChoiceCardGroup.getCheckedOptions();
      if (checkedOptions.length) {
        return checkedOptions[0].id;
      }
    },
    /**
     * Lookalike and page targeting both allow folks to be able to select whether the seed audience is their
     * Facebook Page likes or a selected list. This method returns which they picked. If they are only using
     * interest targeting, give 'em null since that's not applicable.
     *
     * @return {null|String} - "targetList", "targetLikes", or null
     * @private
     */
    _getSelectedTargeting: function () {
      return this._audienceAllowsListOrFacebookTargeting() ? query("input[name='targetInput']:checked", this.domNode)[0].value : null;
    },
    /**
     * Helper method to get the user selected gender target.
     *
     * @return {number} - Representing the gender. 0 == All, 1 == Males, 2 == Females
     * @private
     */
    _getSelectedGender: function () {
      return Number(query("input[name='gender']:checked", this.domNode)[0].value);
    },
    /**
     * The only time a selected country is valid is if they are doing lookalikes.
     *
     * @return {string} selected country code or null.
     * @private
     */
    _getSelectedCountry: function () {
      return this._getSelectedAudience() === LOOKALIKE ? this.countryInput.getValue().toString() : null;
    },
    // /**
    //  * Render a payment tier notification and upgrade option for facebook ad remarketing
    //  * @private
    //  */
    _renderRemarketingNotification: function () {
      var gaTrackingLabelProps = {
        location: "ad_checklist_audience_banner",
        upgrade_reason: "facebook_remarketing_audience",
        button_text: "upgrade_now"
      };
      this.upgradeActionBlock = new UpgradeActionBlock({
        body: {
          title: "Turn visitors into customers",
          copy: "Run ads on Facebook and Instagram to remind people to come back to your site."
        },
        gaTrackingCategory: "upsell_moment",
        gaTrackingAction: "button_click",
        gaTrackingLabelProps: gaTrackingLabelProps,
        featureName: "ad_facebook_retargeting",
        onComplete: function () {
          this.canUseFacebookRemarketing = this.userHasFeature("ad_facebook_retargeting");
          if (this.canUseFacebookRemarketing) {
            domConstruct.destroy(this.featureNotification);
            this.handleAudienceChoice();
            var connectedSiteType = this.audienceChoiceCardGroup.getChoiceCard(CONNECTED_SITE);
            domClass.add(connectedSiteType.badgeNode, "hide");
          }
        }.bind(this),
        onCtaPressCallback: true
      });
      this.upgradeActionBlock.placeAt(this.featureNotification, "only");
    }
  });
});