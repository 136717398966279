define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "dijit/_WidgetBase", "mojo/url", "mojo/context", "mojo/lib/flags"], function (declare, on, query, xhr, dialog, _WidgetBase, mUrl, context, flags) {
  return declare("mojo.widgets.DowngradePlanModal", [_WidgetBase], {
    removeOptionsFromElement: function (el) {
      var i;
      for (i = el.options.length - 1; i >= 0; i--) {
        el.remove(i);
      }
    },
    postCreate: function () {
      var self = this;
      on(this.domNode, "click", function () {
        var getAvailablePackages = function () {
          xhr.get(mUrl.toUrl("/account/downgrade-plan/get-available-downgrade-packages"), {
            handleAs: "json"
          }).then(function (response) {
            if (response.status === "success") {
              var slNewPackage = query(".new-package")[0];
              self.removeOptionsFromElement(slNewPackage);
              var initialOption = document.createElement("option");
              initialOption.text = "Select a package";
              initialOption.value = "";
              slNewPackage.add(initialOption);
              response.packages.forEach(function (newPackage) {
                var option = document.createElement("option");
                option.text = newPackage.name;
                option.value = newPackage.value;
                slNewPackage.add(option);
              });
              query(".plan-dropdown").addClass("js-hide");
            } else {
              var packageSection = query(".package-dropdown")[0];
              var errorMessage = document.createElement("div");
              errorMessage.setAttribute("class", "invalid-error");
              errorMessage.textContent = "Error: unable to get list of packages. Please refresh this page and try again.";
              packageSection.appendChild(errorMessage);
            }
          });
        };
        var getAvailablePlans = function (newPackage) {
          if (newPackage !== "") {
            xhr.post(mUrl.toUrl("/account/downgrade-plan/get-available-downgrade-plans"), {
              handleAs: "json",
              data: {
                "newPackage": newPackage
              }
            }).then(function (response) {
              if (response.status === "success") {
                var slNewPlan = query(".new-plan")[0];
                self.removeOptionsFromElement(slNewPlan);
                var initialOption = document.createElement("option");
                initialOption.text = "Select an audience size";
                initialOption.value = "";
                slNewPlan.add(initialOption);
                response.plans.forEach(function (plan) {
                  var option = document.createElement("option");
                  option.text = plan.name;
                  option.value = plan.value;
                  slNewPlan.add(option);
                });
                query(".plan-dropdown").removeClass("js-hide");
                self.modal._position();
              }
            });
          } else {
            query(".plan-dropdown").addClass("js-hide");
            query(".estimate").addClass("js-hide");
          }
        };
        var getEstimatedMonthlyCharge = function (newPricing) {
          xhr.post(mUrl.toUrl("/account/downgrade-plan/get-estimated-monthly-charge"), {
            handleAs: "json",
            data: {
              "newPricing": newPricing
            }
          }).then(function (response) {
            if (response.status === "success") {
              var estimateValue = query(".estimate-value")[0];
              estimateValue.innerText = response.order.amount;
              var billingDate = query(".billing-date")[0];
              billingDate.innerText = response.order.billingDate;
              var estimateDiv = query(".estimate");
              estimateDiv.removeClass("js-hide");
              var btDowngrade = query(".downgrade-button");
              btDowngrade.removeClass("disabled");
              self.modal._position();
            }
          });
        };
        var getCurrentPlan = function () {
          xhr.get(mUrl.toUrl("/account/downgrade-plan/get-current-plan"), {
            handleAs: "json"
          }).then(function (response) {
            if (response.status === "success") {
              var currentPackageName = query(".current-package-name")[0];
              currentPackageName.innerText = response.package_name;
              query(".current-package").removeClass("js-hide");
              query(".package-dropdown").removeClass("js-hide");
              self.modal._position();
            }
          });
        };
        var downgradeReview = function (pricing_id) {
          window.location.href = mUrl.toUrl("/account/features-review", {
            "pricing_id": pricing_id
          });
        };
        var logAnalyticsEvent = function () {
          var srcInfo = {
            "location": window.location.pathname,
            "button_text": self.domNode.innerText.trim().replace(/\s/g, "_")
          };
          window.ga("send", "event", "downgrade", "initiate_downgrade", JSON.stringify(srcInfo));
        };
        self.modal = new dialog({
          "style": "width: 750px;",
          "title": "Downgrade Your Plan",
          "onHide": function () {
            this.destroyRecursive();
          },
          "onDownloadEnd": function () {
            getCurrentPlan();
            getAvailablePackages();
            var Dialog = self.modal;
            query(".cancel-button", Dialog.domNode).on("click", function (e) {
              e.stopPropagation();
              e.preventDefault();
              Dialog.hide();
            });
            query(".downgrade-button", Dialog.domNode).on("click", function (e) {
              e.stopPropagation();
              e.preventDefault();
              var pricing_id = query(".new-plan")[0].value;
              downgradeReview(pricing_id);
            });
            query(".new-package", Dialog.domNode).on("change", function (e) {
              e.stopPropagation();
              e.preventDefault();
              getAvailablePlans(e.target.value);
            });
            query(".new-plan", Dialog.domNode).on("change", function (e) {
              e.stopPropagation();
              e.preventDefault();
              getEstimatedMonthlyCharge(e.target.value);
            });
          }
        });
        self.modal.set("href", mUrl.toUrl("account/downgrade-plan-modal"));
        self.modal.show();
        logAnalyticsEvent();
      });
    }
  });
});