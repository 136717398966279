/* eslint-disable curly */
/* eslint-disable valid-jsdoc */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-this */
define(["dojo/parser", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/app/lists/members", "dijit/Dialog", "dojo/NodeList-manipulate"], function (parser, query, lang, domForm, domConstruct, request, Deferred, registry, array, mUrl, listMembers, Dialog) {
  var attachEvents = function (dialog, promise, saveUrl) {
    //Close dialog on cancel button
    query(".cancel-button", dialog.domNode).on("click", function (e) {
      e.stopPropagation();
      e.preventDefault();
      dialog.hide();
    });
    query("form", dialog.domNode).on("submit", function (e) {
      e.stopPropagation();
      e.preventDefault();
      window.ga("send", {
        hitType: "event",
        eventCategory: "segments",
        eventAction: "save",
        eventLabel: "regular",
        dimension13: 1,
        dimension14: 1
      });
      var form = this;
      var saveRequest = request.post(mUrl.toUrl(saveUrl), {
        data: domForm.toObject(form),
        sync: true,
        handleAs: "text"
      });
      saveRequest.response.then(function (response) {
        var contentType = response.getHeader("Content-Type");
        if (contentType == "application/json") {
          promise.resolve(JSON.parse(response.data));
          dialog.hide();
        } else {
          array.forEach(registry.findWidgets(form), function (w) {
            w.destroyRecursive();
          });
          domConstruct.place(response.data, dialog.containerNode, "only");
          parser.parse(dialog.domNode);
          attachEvents(dialog, promise, saveUrl);
        }
      });
    });
  };
  var segments = {
    confirmSave: function (id, segmentId) {
      var defer = new Deferred();
      var saveUrl = "lists/segments/save-dialog-post";

      /**
       * Pass the page that redirected to the legacy segment builder
       * For tracking CUB experiment legacy saves
       */
      var referrer = listMembers.contactTableReferrer;
      var dialog = new Dialog({
        "title": "Save Segment",
        "onDownloadEnd": function () {
          attachEvents(dialog, defer, saveUrl);
        },
        "onHide": function () {
          dialog.destroyRecursive();
        }
      });
      dialog.setHref(mUrl.toUrl("lists/segments/save-dialog", {
        "id": id,
        "segment_id": segmentId,
        "contact_table_referrer": referrer
      }));
      dialog.show();
      return defer.promise;
    },
    updateSavedSegment: function (id, segmentId, segmentName) {
      var updateUrl = "lists/segments/update-saved-segment";

      /**
       * Pass the page that redirected to the legacy segment builder
       * For tracking CUB experiment legacy saves
       */
      var referrer = listMembers.contactTableReferrer;
      return request.post(mUrl.toUrl(updateUrl), {
        "handleAs": "json",
        "data": {
          "id": id,
          "segment_id": segmentId,
          "segment_name": segmentName,
          "contact_table_referrer": referrer
        }
      });
    },
    /**
     * Fetch a segment UI form for a particular segment
     * See endpoint for options
     */
    getEditor: function (id, options) {
      if (!options) options = {};
      var params = lang.mixin({
        id: id
      }, options);
      return request.post(mUrl.toUrl("lists/segments/editor"), {
        "data": params,
        "handleAs": "text"
      });
    },
    /**
     * Delete segments
     *
     * @param {string} listId the target list id that segments
     * @param {string} id a single id or a comma separate list of segment ids to delete
     *
     * @returns {dojo/Promise} a dojo promise to handle success/failure
     */
    deleteSegment: function (listId, id) {
      return request.post(mUrl.toUrl("lists/segments/delete"), {
        "data": {
          "id": listId,
          "ids": id,
          "as_json": true
        },
        "handleAs": "json"
      });
    },
    /**
     * Store the segmentData generated by our segment editor in the session to be the currently viewed segment
     *
     * @param listId
     * @param segmentData
     *
     * @returns {dojo/Promise}
     */
    storeSegment: function (listId, segmentData) {
      return request.post(mUrl.toUrl("lists/segments/members/store", {
        "list_id": listId
      }), {
        "data": segmentData,
        "handleAs": "json",
        "sync": true
      });
    }
  };
  return segments;
});