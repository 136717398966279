define(["dojo/request", "mojo/url"], function (request, mUrl) {
  var MAX_BATCH_WAIT = 4000;
  var MAX_FAILURES = 3;
  var batch = [];
  var endpoint = mUrl.toUrl("/metrics");
  var sending = false;
  var flushId = null;
  var batchWaitId = null;
  var lastBatch = null;
  var failures = 0;

  /**
   * Flush any currently batched metrics to the endpoint
   *
   * Do not call directly, use scheduleFlush.
   */
  function flush() {
    if (sending || batch.length === 0) {
      return;
    }
    sending = true;

    // Copy the current batch in case new things
    // are batched while this is in flight
    var send = batch.slice(0);
    batch = [];
    request.post(endpoint, {
      data: {
        batch: JSON.stringify(send)
      }
    }).then(function () {
      failures = 0;
      lastBatch = +new Date();
      sending = false;
      scheduleBatch();
    }, function () {
      failures++;

      // On failure, add back to the batch
      batch = batch.concat(send);
      lastBatch = +new Date();
      sending = false;
      scheduleBatch();
    });
  }

  /**
   * Schedule a flush of any batched metrics. Uses requestIdleCallback if available
   */
  function scheduleFlush() {
    if (!batch.length) {
      return;
    }
    if (window.requestIdleCallback) {
      if (flushId) {
        window.cancelIdleCallback(flushId);
      }
      flushId = window.requestIdleCallback(flush, {
        timeout: 1000
      });
      return;
    }
    if (flushId) {
      clearTimeout(flushId);
    }
    flushId = setTimeout(flush, 1000);
  }

  /**
   * Schedule a flush of the batch. This effectively debounces the call
   * to the endpoint so as not to overwhelm it when a lot of things are being recorded.
   */
  function scheduleBatch() {
    // If the call to the endpoint is failing often,
    // just stop sending.
    if (failures > MAX_FAILURES) {
      return;
    }
    if (batchWaitId) {
      clearTimeout(batchWaitId);
    }

    // Force a schedule at least once every MAX_BATCH_WAIT
    if (lastBatch && +new Date() - lastBatch > MAX_BATCH_WAIT) {
      scheduleFlush();
      return;
    }
    batchWaitId = setTimeout(scheduleFlush, 1000);
  }

  /**
   * Add a metric to the batch and schedule a batch. Debounces
   * scheduling to reduce the amount of backend roundtrips.
   *
   * @param {string} action - statsd method to use
   * @param {string} stat - name of the stat
   * @param {number} value - value to pass to the stat
   * @param {number} rate - sampling rate
   */
  function record(action, stat, value, rate) {
    if (!lastBatch) {
      lastBatch = +new Date();
    }
    batch.push([action, stat, value, rate]);
    scheduleBatch();
  }
  return {
    /**
     * increment a counter
     *
     * @param {string} stat - name of the stat
     * @param {number} count - number to increment the counter by
     * @param {number} rate - sampling rate
     */
    increment: function (stat, count, rate) {
      count = count || 1;
      rate = rate || 0.01;
      record("increment", stat, count, rate);
    },
    timing: function (stat, measurement, rate) {
      rate = rate || 0.01;
      record("timing", stat, measurement, rate);
    }
  };
});