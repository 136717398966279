define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "mojo/context", "mojo/lib/flags", "./_Block", "./_ImageMixin"], function (declare, lang, query, domConstruct, domAttr, array, stringUtils, context, flags, _Block, _ImageMixin) {
  return declare([_Block, _ImageMixin], {
    type: "imageCard",
    _name: "Image Card",
    _kbLink: "https://mailchimp.com/help/use-image-card-content-blocks/",
    _defaultCaption: "Input caption text here. Use the block\'s Settings tab to change the caption position and set other styles.",
    constructor: function (model) {
      this.caption = {
        text: this._defaultCaption,
        image: null
      };
      this.captionPosition = "bottom"; // [top,left,right,bottom]
      this.captionWidth = "two-third"; // [half, one-third, two-third, three-quarter]
      this.edgeToEdge = true;
      this.align = 'left';
      this.styles = [{
        "name": "Text style",
        'selector': '.mcnTextContent, .mcnTextContent p',
        'props': {
          'color': {
            'value': '#F2F2F2'
          },
          'font-family': {
            'value': 'Helvetica'
          },
          'font-size': {
            'value': '14px'
          },
          'font-style': {
            'value': ''
          },
          'font-weight': {
            'value': 'normal'
          },
          'line-height': {
            'value': ''
          },
          'text-align': {
            'value': 'center'
          }
        }
      }, {
        'name': 'Card',
        // When changing this, update ImageCardBlock's _getImageBorderOffset
        'selector': '.mcnImageCardRightContentInner, .mcnImageCardLeftContentInner, .mcnImageCardBottomContent, .mcnImageCardTopContent',
        'props': {
          'background-color': {
            'value': '#404040'
          },
          'border': {
            'value': ''
          }
        }
      }];
      if (model._campaignRenderingType === "page") {
        this.styles[1].props["box-shadow"] = {
          "value": "none"
        };
      }
      this.styles = this.styles.concat(this._getImageStyles());
    },
    onImageUpload: function (image) {
      var caption = this.caption;
      caption.image = image;
      this.set("caption", caption);
      this.save();
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var content = this.getContent();
      var link = query('a', content)[index];
      if (link && domAttr.get(link, 'href') == oldUrl) {
        var result;
        if (this.captionPosition == 'left' || this.captionPosition == 'right') {
          result = this._handleHorizontalLinkUpdate(oldUrl, newUrl, link, content);
        } else {
          result = this._handleVerticalLinkUpdate(oldUrl, newUrl, link, content);
        }
        if (result) {
          domConstruct.destroy(content);
          return this.save();
        }
      }
      domConstruct.destroy(content);
      return this.inherited(arguments);
    },
    /**
     * Handles tweaking links for captions that uses left/right positioning
     */
    _handleHorizontalLinkUpdate: function (oldUrl, newUrl, link) {
      var textClass = ".mcnImageCard" + stringUtils.capitalize(this.captionPosition) + "TextContentContainer";
      var imageClass = ".mcnImageCard" + stringUtils.capitalize(this.captionPosition) + "ImageContentContainer";
      var imageContainer = query(link).parents(imageClass)[0];
      if (imageContainer) {
        return this._updateImageUrl(oldUrl, newUrl);
      } else {
        // Have to handle arbitrary link on the HTML of the caption.
        var textContainer = query(link).parents(textClass)[0];
        // Find the positioning of the index in the context of the container that holds text.
        var linkIndex = array.indexOf(query('a', textContainer), link);
        return this._updateContentUrl(linkIndex, oldUrl, newUrl);
      }
    },
    _handleVerticalLinkUpdate: function (oldUrl, newUrl, link) {
      var textClass = '.mcnTextContent';
      var imageClass = ".mcnImageCard" + stringUtils.capitalize(this.captionPosition) + "ImageContent";
      var imageContainer = query(link).parent(imageClass)[0];
      if (imageContainer) {
        return this._updateImageUrl(oldUrl, newUrl);
      } else {
        // Have to handle arbitrary link on the HTML of the caption.
        var textContainer = query(link).parents(textClass)[0];
        // Find the positioning of the index in the context of the container that holds text...
        var linkIndex = array.indexOf(query('a', textContainer), link);
        return this._updateContentUrl(linkIndex, oldUrl, newUrl);
      }
    },
    /**
     * Helper function to change the url for a particular caption's image.
     */
    _updateImageUrl: function (oldUrl, newUrl) {
      if (this.caption && this.caption.image && this.caption.image.link && this.caption.image.link.href == oldUrl) {
        this.caption.image.link.href = newUrl;
        if (this.caption.image.link.text == oldUrl) {
          this.caption.image.link.text = newUrl;
        }
        return true;
      }
      return false;
    },
    /**
     * Helper function to change the url for a particular link in the content.
     */
    _updateContentUrl: function (linkIndex, oldUrl, newUrl) {
      var captionWrap = domConstruct.create('div');
      var captionDom = domConstruct.toDom(this.caption.text);
      domConstruct.place(captionDom, captionWrap);
      domAttr.set(query('a', captionWrap)[linkIndex], 'href', newUrl);
      this.caption.text = query(captionWrap).html();
      domConstruct.destroy(captionWrap);
      return true;
    }
  });
});