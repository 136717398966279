define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "dojox"], function (dojo, dijit, dojox) {
  dojo.provide('mojo.campaigns');

  /**
   * Pop up the confirmation dialog box making sure that you want to delete the selected campaigns
   * @param {String} confirm_url the url to use for the confirmation dialog
   * @param {Integer} campaign_id optional specific campaign to delete
   * @return boolean false
   */
  mojo.campaigns.confirmDeleteSelected = function (confirm_url, campaign_id) {
    var dialog = dijit.byId('campaign-delete-dialog');
    var ids = mojo.campaigns.getGridCampaignIdSelection();
    if (campaign_id && dojo.indexOf(ids, campaign_id) < 0) {
      mojo.campaigns.single_id = campaign_id;
      ids[ids.length] = campaign_id;
    }
    if (!ids.length) {
      return false;
    }
    mojo.campaigns.setGridCampaignIdSelection(ids);
    var query = {
      'id[]': ids
    };
    dialog.setHref(confirm_url + '?' + dojo.objectToQuery(query));
    dialog.show();
    return false;
  };

  /**
   * Actually send the requests to delete the campaigns, then update the grid to reflect the removed campaigns
   * @param {String} delete_url the url to use for deleting the campaigns
   */
  mojo.campaigns.deleteConfirmed = function (delete_url) {
    var entered = dojo.byId('confirm-text').value;
    var expected = dojo.byId('confirm-key').value;
    var errMsg = dojo.byId('confirm-delete-error');
    if (expected.localeCompare(entered) != 0) {
      dojo.style(errMsg, 'display', 'block');
      errMsg.innerHTML = 'Please enter the text exactly as it is displayed to confirm deletion.';
      return;
    } else {
      errMsg.innerHTML = '';
      dojo.style(errMsg, 'display', 'none');
      dijit.byId('campaign-delete-dialog').hide();
    }
    var ids = mojo.campaigns.getGridCampaignIdSelection();
    if (mojo.campaigns.single_id && dojo.indexOf(ids, mojo.campaigns.single_id) < 0) {
      ids[ids.length] = mojo.campaigns.single_id;
      delete mojo.campaigns.single_id;
    }
    var query = {
      'id[]': ids
    };
    var delete_count = ids.length;
    dojo.xhrPost({
      'url': delete_url,
      'content': query,
      'load': function () {
        var type = 'campaign';
        if (typeof grid === 'undefined') {
          loadList('refresh', '');
          type = 'autoresponder';
        } else {
          grid.refresh();
        }
        dojo.publish('show-toast', [{
          'message': delete_count + ' ' + type + (delete_count != 1 ? 's' : '') + ' successfully deleted',
          'type': 'message'
        }]);
      }
    });
  };

  /**
   * Set the current list of campaign ids from the grid selection
   * @param {Array} ids array of campaign ids to set as the selection
   */
  mojo.campaigns.setGridCampaignIdSelection = function (ids) {
    for (var i = 0; i < ids.length; i++) {
      var el = dojo.byId('del-campaign-' + ids[i]);
      if (!el) el = dojo.byId('del-autoresponder-' + ids[i]);
      if (el && !el.checked) {
        el.checked = true;
      }
    }
  };

  /**
   * Get the current list of campaign ids from the grid selection
   * @return {Array} array of campaign_ids
   */
  mojo.campaigns.getGridCampaignIdSelection = function () {
    var ids = [];
    dojo.query('.campaign-grid input[type="checkbox"]').forEach(function (el) {
      if (el.checked) {
        var parts = el.id.split('-');
        if (parts[0] == 'del') {
          ids.push(parts[2]);
        }
      }
    });
    return ids;
  };

  /**
   * Go to the next step in the wizard
   * @param {node} button element to update
   */
  mojo.campaigns.goToNextStep = function (button) {
    dojo.attr(button, 'value', 'please wait...');
    setTimeout(function () {
      button.disabled = true;
    }, 0);
    var form = dojo.byId('wizard-form');
    form.submit();
  };
});