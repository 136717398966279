define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojox/dtl", "dojox/dtl/Context", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo/text!../editors/blocks/templates/SocialFollowEditor/output.html", "dojo/text!../editors/blocks/templates/SocialFollowEditor/_horizontal.html", "dojo/text!../editors/blocks/templates/SocialFollowEditor/_vertical.html"], function (declare, _Renderer, dtl, dtlContext, lang, array, mUrl, tpl, horizontalTpl, verticalTpl) {
  var defaultText = {
    "facebookFollow": "Facebook",
    "twitterFollow": "Twitter",
    "linkedInFollow": "LinkedIn",
    "youTubeFollow": "YouTube",
    "pinterestFollow": "Pinterest",
    "googlePlusFollow": "Google Plus",
    "emailLink": "Email",
    "tumblr": "Tumblr",
    "instagram": "Instagram",
    "snapchat": "Snapchat",
    "githubFollow": "Github",
    "vimeoFollow": "Vimeo",
    "vineFollow": "Vine",
    "mediumFollow": "Medium",
    "redditFollow": "Reddit",
    "flickrFollow": "Flickr",
    "dribbbleFollow": "Dribbble",
    "spotifyFollow": "Spotify",
    "houzzFollow": "Houzz",
    "vkFollow": "VKontakte",
    "tiktok": "TikTok",
    "threads": "Threads",
    "twitch": "Twitch",
    "whatsapp": "WhatsApp",
    "rssFollow": "RSS",
    "genericLink": "Website"
  };
  return declare("mojo.neapolitan.renderers.SocialFollowBlock", [_Renderer], {
    outputTplStr: tpl,
    defaultText: defaultText,
    constructor: function () {
      this.horizontalTemplate = new dojox.dtl.Template(horizontalTpl);
      this.verticalTemplate = new dojox.dtl.Template(verticalTpl);
    },
    _getBlockTemplate: function (layout) {
      switch (layout) {
        case "vertical":
          return this.verticalTemplate;
        case "horizontal":
        default:
          return this.horizontalTemplate;
      }
    },
    _getServiceIconUrl: function (item, iconStyle, iconType, itemArrangement) {
      var iconName;
      var iconSize;
      var urlPrefix = "http://cdn-images.mailchimp.com/icons/social-block-v2/";
      var urlSuffix = ".png";
      switch (item.type) {
        case "facebookFollow":
          iconName = "facebook";
          break;
        case "twitterFollow":
          iconName = "twitter";
          break;
        case "linkedInFollow":
          iconName = "linkedin";
          break;
        case "youTubeFollow":
          iconName = "youtube";
          break;
        case "pinterestFollow":
          iconName = "pinterest";
          break;
        case "googlePlusFollow":
          iconName = "googleplus";
          break;
        case "emailLink":
          iconName = "forwardtofriend";
          break;
        case "tumblrFollow":
          iconName = "tumblr";
          break;
        case "instagramFollow":
          iconName = "instagram";
          break;
        case "snapchatFollow":
          iconName = "snapchat";
          break;
        case "soundCloudFollow":
          iconName = "soundcloud";
          break;
        case "githubFollow":
          iconName = "github";
          break;
        case "vimeoFollow":
          iconName = "vimeo";
          break;
        case "vineFollow":
          iconName = "vine";
          break;
        case "mediumFollow":
          iconName = "medium";
          break;
        case "redditFollow":
          iconName = "reddit";
          break;
        case "flickrFollow":
          iconName = "flickr";
          break;
        case "dribbbleFollow":
          iconName = "dribbble";
          break;
        case "spotifyFollow":
          iconName = "spotify";
          break;
        case "houzzFollow":
          iconName = "houzz";
          break;
        case "vkFollow":
          iconName = "vk";
          break;
        case "rssFollow":
          iconName = "rss";
          break;
        case "tikTokFollow":
          iconName = "tiktok";
          break;
        case "threadsFollow":
          iconName = "threads";
          break;
        case "twitchFollow":
          iconName = "twitch";
          break;
        case "whatsAppFollow":
          iconName = "whatsapp";
          break;
        default:
          iconName = "link";
          break;
      }
      var iconTypePrefix = "";
      switch (iconType) {
        case "outline":
          iconTypePrefix = iconType + "-";
          break;
      }
      switch (itemArrangement) {
        case "inline":
          iconSize = "48";
          break;
        case "stacked":
        default:
          iconSize = "96";
          break;
      }
      var url = urlPrefix + iconTypePrefix + iconStyle + "-" + iconName + "-" + iconSize + urlSuffix;
      item.iconUrl = mUrl.addProxy(url);
    },
    _setDefaultText: function (item) {
      if (!item.text) {
        var text = this.defaultText[item.type];
        if (!text) text = "Link";
        item.text = text;
      }
    },
    getOutputModel: function (meta) {
      array.forEach(meta.items, lang.hitch(this, function (item) {
        this._getServiceIconUrl(item, meta.iconStyle, meta.iconType, meta.itemArrangement);
        this._setDefaultText(item);
        if (!item.link) item.link = "";
      }));
      var displayText = true;
      var displayIcon = true;
      switch (meta.display) {
        case "text":
          displayIcon = false;
          break;
        case "icon":
          displayText = false;
          break;
      }

      // This is for outlook compatibility. We have to subtract 40 to avoid DPI issues.
      var calculatedWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2) - 40;
      var blockHtml = this._getBlockTemplate(meta.blockLayout).render(new dtlContext({
        items: meta.items,
        itemArrangement: meta.itemArrangement,
        displayText: displayText,
        displayIcon: displayIcon,
        align: meta.align,
        adjustableWidth: meta.adjustableWidth,
        innerPadding: this.innerPadding,
        calculatedWidth: calculatedWidth
      }));
      return {
        blockHtml: blockHtml
      };
    }
  });
});