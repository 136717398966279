/**
 * Confirm Input Translated
 * This widget is a wrapper around the mojo/widgets/ConfirmInput widget.
 * It has the option of taking in translation keys for the "confirm" word and help tip as params.
 * It also takes in an optional flag to put the translations behind.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/ConfirmInput", "mojo/lib/flags", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, ConfirmInput, flags, I18nTranslation, context) {
  return declare([ConfirmInput], {
    confirmKey: "",
    helptipKey: "",
    translationFlag: "",
    translatedTerms: {},
    /**
     * Tap into the postMixInProperties lifecycle hook to dynamically use translation keys for the attributes.
     */
    postMixInProperties: function () {
      if (this.translationFlag === "" || context.flagIsOn(this.translationFlag)) {
        I18nTranslation.initialize().then(function (value) {
          this.translatedTerms = value;
        }.bind(this));
      }

      // If translations have been initialized and we're flagged, override the label.
      this.confirm = this.translatedTerms[this.confirmKey] || this.confirm;
      this.helptip = this.translatedTerms[this.helptipKey] || this.helptip;
      this.error = this.translatedTerms["delete_confirmation_error"] || this.error;
      this.type = this.translatedTerms["delete_confirmation_type"] || this.type;
      this.confirmText = this.translatedTerms["delete_confirmation_to_confirm"] || this.confirmText;
      this.inherited(arguments);
    }
  });
});