define([], function () {
  return '\
<nav> \
  <div class="mclp-wrapper" style="display: flex; justify-content: center;"> \
    <div class="contentContainer"> \
      <div class="mc-nav-container"> \
        <div class="burger-container"> \
          <div id="burger"> \
            <div class="bar topBar"></div> \
            <div class="bar midBar"></div> \
            <div class="bar btmBar"></div> \
          </div> \
        </div> \
        <ul class="menu"> \
          {% for link in links %} \
            <li class="menu-item menu-item-{{ link.text }}"><a href="{{ link.link }}" class="menu-item-link">{{ link.text }}</a></li> \
          {% endfor %} \
        </ul> \
      </div> \
    </div> \
  </div> \
</nav> ';
});