/**
 * Upgrade Action Link
 * @module mojo/widgets/UpgradeActionLink.js
 *
 * This is a stripped-down version of UpgradeActionBlock that simply routes the user to /products upon clicking the CTA.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/UpgradeActionLink.html"], function (declare, context, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    // An image is required
    img: {
      path: "https://cdn-images.mailchimp.com/product/brand_assets/illos/art-other-first-purchase.png",
      alt: "Illustration of caped woman leaping over mountain"
    },
    // Keep under 270 characters.
    body: {
      title: "You're getting close to your send limit",
      copy: "You can keep sending, but you'll be charged for any sends that go beyond your account's limit. Adjust your plan to avoid additional charges."
    },
    // A CTA is required
    cta: {
      innerText: "Upgrade Now",
      // Highly recommended default
      className: "p0" // Button priority. See http://ux.mailchimp.com/patterns/buttons
    },
    // Optional ability to add a variant classname. Currently only c-upgradeActionLink--small is used.
    variantClass: null
  });
});