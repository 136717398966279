/**
 * Widget built to show a checklist's status and associated actions.
 * @module mojo/widgets/StatusBar
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/widgets/StatusBarActions", "dojo/text!./templates/StatusBar.html"], function (declare, array, debounce, domClass, domConstruct, domStyle, on, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, StatusBarActions, tplStr) {
  // Constants match statuses set in AdInterface
  var STATUS_DRAFT = "draft";
  var STATUS_PENDING = "pending";
  var STATUS_SCHEDULED = "scheduled";
  var STATUS_ACTIVE = "active";
  var STATUS_PAUSED = "paused";
  var STATUS_REJECTED = "rejected";
  var STATUS_COMPLETED = "completed";

  /**
   * Widget that is responsible for creating and updating a checklist's status bar.
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    actions: {},
    // Passed in through widget declaration
    status: {},
    // Global vars for status bar template
    icon: {
      classes: ""
    },
    title: "",
    label: "",
    badge: {
      type: "",
      label: ""
    },
    // Objects to be filled with options depending on status
    badgeOptions: {},
    actionOptions: [],
    /**
     * Create objects here so we can use our constants as keys. Thanks ES5... :/
     */
    _createBadgeOptions: function () {
      // Set badge options according to status
      this.badgeOptions[STATUS_DRAFT] = {
        type: "",
        label: "Draft"
      };
      this.badgeOptions[STATUS_PENDING] = {
        type: "warning",
        label: "In review"
      };
      this.badgeOptions[STATUS_SCHEDULED] = {
        type: "warning",
        label: "Scheduled"
      };
      this.badgeOptions[STATUS_ACTIVE] = {
        type: "info",
        label: "Published"
      };
      this.badgeOptions[STATUS_PAUSED] = {
        type: "warning",
        label: "Paused"
      };
      this.badgeOptions[STATUS_REJECTED] = {
        type: "error",
        label: "Rejected"
      };
      this.badgeOptions[STATUS_COMPLETED] = {
        type: "success",
        label: "Completed"
      };
    },
    /**
     * Sets properties in template in time for first load.
     */
    postMixInProperties: function () {
      this.inherited(arguments);
      this._createBadgeOptions();
      this.title = this.status.title;
      this.label = this.status.label;
      this.badge = this.badgeOptions[this._getStatusName()];
    },
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      this._createActions();
      this._placeActions();
      this._createActionOptions();
      this._setAvailableActions();
      on(window, "resize", debounce(function (event) {
        self._onResize();
      }, 100));
    },
    /**
     * Places status bar at dom node and returns widget to page js.
     *
     * @param {Object} params - Any data required to setup the status bar.
     * @param {Node} domNode - Where the widget will be placed.
     *
     * @returns {Object} - This status bar widget.
     */
    render: function (params, domNode) {
      this.placeAt(domNode);
      this._onResize();
      return this;
    },
    /**
     * Check current width of the browser through classes on action containers,
     * and switch between displaying mobile or desktop actions
     */
    _onResize: function () {
      // Don't resize if all elements haven't been loaded yet
      if (!this.actionContainerDesktop) {
        return;
      }

      // Move actions to mobile
      if (domStyle.get(this.actionContainerDesktop, "display") === "none") {
        domConstruct.place(this.actions.containerNode, this.actionsMobile);
        // Move actions to desktop
      } else {
        domConstruct.place(this.actions.containerNode, this.actionsDesktop);
      }
    },
    /**
     * Instantiate this status bar's custom action widget.
     */
    _createActions: function () {
      this.actions = new StatusBarActions({
        status: this.status
      });
    },
    /**
     * After the custom action widget is created, place it in this template.
     */
    _placeActions: function () {
      domConstruct.place(this.actions.containerNode, this.actionsDesktop);
      this.actions.startup();
    },
    /**
     * @override
     * Create options for which actions are shown for each status.
     */
    _createActionOptions: function () {},
    /**
     * Show or hide actions depending on action options set for status.
     */
    _setAvailableActions: function () {
      var self = this;
      array.forEach(this.actionOptions[this._getStatusName()], function (action) {
        array.forEach(self.actions.containerNode.childNodes, function (item) {
          domClass.toggle(action, "hide", action === item);
        });
      });
    },
    /**
     * Override, use this function if there are more statuses to check for
     * than the ones passed in from the controller.
     *
     * @returns {string} The true status of the ad.
     */
    _getStatusName: function () {
      return this.status.name;
    }
  });
});