define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/text!./templates/page-status.html", "mojo/widgets/Renderable", "mojo/user", "mojo/lib/logger", "mojo/url", "mojo/utils", "mojo/widgets/checklist-editor/PagePublish", "mojo/page/statustypes", "mojo/lib/flags", "mojo/views/campaigns/wizard/confirm", "dijit/Dialog", "./_SendLater"], function (lang, declare, request, tpl, Renderable, user, logger, mUrl, utils, PagePublish, PageStatusTypes, flags) {
  return declare([Renderable], {
    templateString: tpl,
    /**
     * Constant url for Finish later link
     */
    finishLaterUrl: mUrl.toUrl("/campaigns"),
    /**
     * Constant url for mailchi.mp error page
     */
    errorPageNotFoundUrl: "https://mailchi.mp/404",
    enableButtonWhenUserIsPaused: null,
    state: {
      isItemActive: false
    },
    constructor: function (props) {
      this.enableButtonWhenUserIsPaused = user.isPaused;
      this.state.model = props.model;
      this.state.completeItems = props.completeItems;
      this.state.remainingItems = props.remainingItems;
      this.state.isReady = props.isReady;
      this.state.shouldShowFinishLaterLink = true;
      this.state.hasPro = user.pro;
      this.state.heading = "Let's get started!";
      this.state.subHeading = "Draft landing page";
      this.state.buttons = [{
        classes: "c-slatButton button p1",
        otherAttrs: this.enableButtonWhenUserIsPaused ? this.getPublishAttribute() : "disabled='disabled'",
        value: "Publish"
      }];
      this.state.icon = {
        classes: "c-channelIcon c-channelIcon--page"
      };
      this.pageStatusBadge = {};
      this.pageStatusBadge[PageStatusTypes.DRAFT] = {
        label: "Draft",
        badge: ""
      };
      this.pageStatusBadge[PageStatusTypes.PUBLISHED] = {
        label: "Published",
        badge: "success"
      };
      this.pageStatusBadge[PageStatusTypes.UNPUBLISHED] = {
        label: "Unpublished",
        badge: ""
      };
      this.campaignType = "page";
      var preventPublishing = props.listDisabled;
      var disabledClass = preventPublishing ? " disabled" : "";
      var editingDisabled = this.state.model.contentType === "multichannel" && !user.flagIsOn(flags.EDITING_PLATFORM_LANDING_PAGE_ALPHA);
      if (editingDisabled) {
        // MOBILE STATE
        this.state.heading = "Landing Page Created On Mobile App";
        this.state.subHeading = "This landing page was created with the Mailchimp mobile app. To edit, please use our mobile app editor. " + "<a href=\"" + "https://mailchimp.com/help/create-landing-page-mobile/" + "\" target=\"_blank\" title=\"Domain Mobile Landing Page\">About landing pages on mobile.</a>";
        this.state.shouldShowFinishLaterLink = true;
        this.state.buttons = [{
          classes: "display--none"
        }];
      } else if (this.state.model.status === PageStatusTypes.PUBLISHED) {
        // PUBLISHED STATE
        this.state.heading = "Your landing page is live.";
        this.state.subHeading = "<a href=\"" + this.state.model.campaignUrl.url + "\" target=\"_blank\" title=\"" + this.state.model.title + "\">" + this.state.model.campaignUrl.url + "</a>";
        this.state.shouldShowFinishLaterLink = false;
        this.state.buttons = [{
          classes: "c-slatButton button p1",
          otherAttrs: this.getPublishAttribute(),
          value: "Promote Your Page"
        }];
      } else if (this.state.model.status === PageStatusTypes.UNPUBLISHED) {
        var subHeading;
        if (this.state.model.parkedPageUrl) {
          subheading = "Visitors who try to access your landing page URL will see this " + "<a href=\"" + this.state.model.parkedPageUrl + "\" target=\"_blank\" title=\"Domain Parked Page\">parked page.</a>";
        } else {
          subheading = "Visitors who try to access your landing page URL will see this " + "<a href=\"" + this.errorPageNotFoundUrl + "\" target=\"_blank\" title=\"Landing Page Not Found Error\">error page.</a>";
        }
        // UNPUBLISHED STATE
        this.state.heading = "Your landing page is not live.";
        this.state.subHeading = subheading;
        this.state.shouldShowFinishLaterLink = false;
        this.state.buttons = [{
          classes: "c-slatButton button p1" + disabledClass,
          otherAttrs: this.getPublishAttribute(),
          value: "Publish"
        }];
        if (preventPublishing) {
          this.state.heading = "Your landing page is not live & publishing is disabled";
        }
      } else if (!this.state.model.canSend) {
        this.state.heading = "Publishing Disabled";
        this.state.subHeading = "";
      } else if (this.state.isReady) {
        this.state.heading = "Ready to publish!";
        this.state.subHeading = "Don’t worry, no one will see it until you share the URL. You can make changes to a published landing page at any time.";
        this.state.buttons = [{
          classes: "c-slatButton button p1" + disabledClass,
          otherAttrs: this.getPublishAttribute(),
          value: "Publish"
        }];
        if (preventPublishing) {
          this.state.heading = "Publishing Disabled";
        }
      } else if (this.state.remainingItems >= 4) {
        this.state.heading = "Let's get started!";
        this.state.subHeading = "";
      } else if (this.state.remainingItems >= 2) {
        this.state.heading = "A few more things to do.";
        this.state.subHeading = "";
      } else if (this.state.remainingItems === 1) {
        this.state.heading = "Almost done! 1 more thing to do.";
        this.state.subHeading = "";
      } else if (this.state.remainingItems === 0) {
        this.state.heading = "Error";
        this.state.subHeading = "We've made a huge mistake.";
        throw new Error("Checklist thinks it is complete, but is still not in `isReady` state");
      }
      this.taggingEnabled = this.state.model.hasSignupForm && user.hasRole("manager");
    },
    /**
     * Disables publish if user does not have publish permissions
     *
     * @returns string
     */
    getPublishAttribute: function () {
      if (user.hasRole("manager")) {
        return "data-dojo-attach-event='click: openPublishPage'";
      }
      return "disabled='disabled'";
    },
    openPublishPage: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: "paused",
          action: "wiggle"
        });
      }
      this.setState({
        buttons: [{
          classes: "c-slatButton button p0",
          value: "Publishing..."
        }]
      });
      if (this.taggingEnabled) {
        if (this.state.model.signup_tags.length > 0) {
          logger.googleAnalytics.trackEvent("Tags on Landing Pages - Setup", "Clicked", "Publish with Tags");
        } else {
          logger.googleAnalytics.trackEvent("Tags on Landing Pages - Setup", "Clicked", "Publish without Tags");
        }
      }
      window.top.location.href = mUrl.toUrl("/landing-pages/publish", {
        "id": this.state.model.pageId,
        "__csrf_token": user.__csrfToken
      });
    }
  });
});