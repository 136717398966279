/* eslint-disable */
/**
 * Performance Overview Chart that mirrors Application structure
 * @module mojo/widgets/sandbox/AppPerformanceOverviewChart.js
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user",
// Mixins
"mojo/widgets/Renderable"], function (declare, array, mColors, dom, domClass, domStyle, domAttr, domConstruct, query, xhr, user, Renderable) {
  var AmCharts = window.AmCharts;
  var mockData = [{
    "openUrl": "/reports/summary?id=754937",
    "openRate": "57.36",
    "clickUrl": "/reports/summary?id=754937",
    "clickRate": "8.59",
    "bubbleText": "[Snap] Free shipping is back!\n16 Jun,2016 4:37 pm [Thu]",
    "campaignId": 754937,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=757681",
    "openRate": "39.63",
    "clickUrl": "/reports/summary?id=757681",
    "clickRate": "2.54",
    "bubbleText": "Issue #11: Fulfillment\n21 Jun,2016 2:00 pm [Tue]",
    "campaignId": 757681,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=763925",
    "openRate": "55.31",
    "clickUrl": "/reports/summary?id=763925",
    "clickRate": "8.91",
    "bubbleText": "Freddie and Co. July 4th Promo\n28 Jun,2016 1:00 pm [Tue]",
    "campaignId": 763925,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=770605",
    "openRate": "36.84",
    "clickUrl": "/reports/summary?id=770605",
    "clickRate": "1.37",
    "bubbleText": "Issue #12: Launch Recap\n28 Jun,2016 2:00 pm [Tue]",
    "campaignId": 770605,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=742449",
    "openRate": "58.08",
    "clickUrl": "/reports/summary?id=742449",
    "clickRate": "5.31",
    "bubbleText": "Freddie and Co. + Lost-n-Found Youth\n30 Jun,2016 10:53 am [Thu]",
    "campaignId": 742449,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=775757",
    "openRate": "93.62",
    "clickUrl": "/reports/summary?id=775757",
    "clickRate": "32.83",
    "bubbleText": "1st Employee Freddie Billboard\n30 Jun,2016 1:43 pm [Thu]",
    "campaignId": 775757,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=777477",
    "openRate": "34.87",
    "clickUrl": "/reports/summary?id=777477",
    "clickRate": "0.75",
    "bubbleText": "Issue #13: Bank Account and Business License\n5 Jul,2016 2:00 pm [Tue]",
    "campaignId": 777477,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=780197",
    "openRate": "55.92",
    "clickUrl": "/reports/summary?id=780197",
    "clickRate": "18.93",
    "bubbleText": "Freddie and Co. Pintrill Announcement\n12 Jul,2016 11:11 am [Tue]",
    "campaignId": 780197,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=794177",
    "openRate": "33.33",
    "clickUrl": "/reports/summary?id=794177",
    "clickRate": "1.25",
    "bubbleText": "Issue #13.5: Tips from an Accounting Expert!\n12 Jul,2016 2:11 pm [Tue]",
    "campaignId": 794177,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=791357",
    "openRate": "34.54",
    "clickUrl": "/reports/summary?id=791357",
    "clickRate": "1.48",
    "bubbleText": "Issue #14: Introducing our 2nd Collection\n19 Jul,2016 2:00 pm [Tue]",
    "campaignId": 791357,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=805769",
    "openRate": "54.64",
    "clickUrl": "/reports/summary?id=805769",
    "clickRate": "10.87",
    "bubbleText": "Freddie and Co. + Pintrill\n21 Jul,2016 11:00 am [Thu]",
    "campaignId": 805769,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=809345",
    "openRate": "32.54",
    "clickUrl": "/reports/summary?id=809345",
    "clickRate": "2.29",
    "bubbleText": "Issue #15: Product Photography\n26 Jul,2016 2:00 pm [Tue]",
    "campaignId": 809345,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=811273",
    "openRate": "31.37",
    "clickUrl": "/reports/summary?id=811273",
    "clickRate": "0.95",
    "bubbleText": "Issue #16: Three Stories\n2 Aug,2016 2:08 pm [Tue]",
    "campaignId": 811273,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=826685",
    "openRate": "54.39",
    "clickUrl": "/reports/summary?id=826685",
    "clickRate": "4.63",
    "bubbleText": "Introducing re:imagine/ATL\n4 Aug,2016 1:00 pm [Thu]",
    "campaignId": 826685,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=828861",
    "openRate": "30.82",
    "clickUrl": "/reports/summary?id=828861",
    "clickRate": "1.45",
    "bubbleText": "Issue #16.5: Tips from a Social Media Expert\n9 Aug,2016 2:00 pm [Tue]",
    "campaignId": 828861,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=840669",
    "openRate": "29.30",
    "clickUrl": "/reports/summary?id=840669",
    "clickRate": "1.87",
    "bubbleText": "Issue #17: Developing a Design Aesthetic\n16 Aug,2016 2:15 pm [Tue]",
    "campaignId": 840669,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=849709",
    "openRate": "49.38",
    "clickUrl": "/reports/summary?id=849709",
    "clickRate": "2.91",
    "bubbleText": "PINTRILL - Catch Em All (new hands)\n18 Aug,2016 10:06 am [Thu]",
    "campaignId": 849709,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=852185",
    "openRate": "28.17",
    "clickUrl": "/reports/summary?id=852185",
    "clickRate": "1.38",
    "bubbleText": "Issue #18: Freddie and Co.&#039;s MailChimp Account\n23 Aug,2016 2:00 pm [Tue]",
    "campaignId": 852185,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=845565",
    "openRate": "59.01",
    "clickUrl": "/reports/summary?id=845565",
    "clickRate": "12.72",
    "bubbleText": "Freddie and Co. at The Decatur Book Festival\n25 Aug,2016 10:00 am [Thu]",
    "campaignId": 845565,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=857969",
    "openRate": "26.90",
    "clickUrl": "/reports/summary?id=857969",
    "clickRate": "1.19",
    "bubbleText": "Issue #19: Brand Voice\n30 Aug,2016 4:00 pm [Tue]",
    "campaignId": 857969,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=860409",
    "openRate": "26.14",
    "clickUrl": "/reports/summary?id=860409",
    "clickRate": "0.47",
    "bubbleText": "Issue #20: Product Testing\n7 Sep,2016 4:10 pm [Wed]",
    "campaignId": 860409,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=859725",
    "openRate": "25.30",
    "clickUrl": "/reports/summary?id=859725",
    "clickRate": "0.52",
    "bubbleText": "Issue #21: Holiday Prep\n14 Sep,2016 2:26 pm [Wed]",
    "campaignId": 859725,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=885629",
    "openRate": "55.77",
    "clickUrl": "/reports/summary?id=885629",
    "clickRate": "16.17",
    "bubbleText": "Collection #3 Launch\n15 Sep,2016 1:22 pm [Thu]",
    "campaignId": 885629,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=892905",
    "openRate": "24.55",
    "clickUrl": "/reports/summary?id=892905",
    "clickRate": "0.72",
    "bubbleText": "Issue #22: Selling in Person (Plus, our third collection launch!)\n21 Sep,2016 2:00 pm [Wed]",
    "campaignId": 892905,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=903117",
    "openRate": "23.83",
    "clickUrl": "/reports/summary?id=903117",
    "clickRate": "1.00",
    "bubbleText": "Issue #23: Packaging\n28 Sep,2016 2:45 pm [Wed]",
    "campaignId": 903117,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=909805",
    "openRate": "43.66",
    "clickUrl": "/reports/summary?id=909805",
    "clickRate": "5.36",
    "bubbleText": "Baron Book Bundle\n30 Sep,2016 1:45 pm [Fri]",
    "campaignId": 909805,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=911093",
    "openRate": "25.31",
    "clickUrl": "/reports/summary?id=911093",
    "clickRate": "0.57",
    "bubbleText": "Issue #24: Baron Fig&#039;s Story\n4 Oct,2016 2:46 pm [Tue]",
    "campaignId": 911093,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=924625",
    "openRate": "24.32",
    "clickUrl": "/reports/summary?id=924625",
    "clickRate": "0.53",
    "bubbleText": "Issue #25: The Book Blunder\n12 Oct,2016 3:03 pm [Wed]",
    "campaignId": 924625,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=937477",
    "openRate": "24.97",
    "clickUrl": "/reports/summary?id=937477",
    "clickRate": "0.44",
    "bubbleText": "Issue #26: Strategic Merchandising\n18 Oct,2016 3:41 pm [Tue]",
    "campaignId": 937477,
    "industryAvg": "29.56"
  }, {
    "openUrl": "/reports/summary?id=950165",
    "openRate": "14.39",
    "clickUrl": "/reports/summary?id=950165",
    "clickRate": "2.92",
    "bubbleText": "BAGGUxPoketo Collection\n27 Oct,2016 3:40 pm [Thu]",
    "campaignId": 950165,
    "industryAvg": "29.56"
  }];
  return declare([Renderable], {
    chartNoData: '<div class="c-noDataBlock text-align--center border-gray border-radius--lv2"><div class="c-noDataBlock_icon" style="background-image: url(\'https://cdn-images.mailchimp.com/product/brand_assets/illos/art-empty-reports.png\')"></div><h2 class="c-noDataBlock_title">We don’t have enough campaign performance data yet.</h2></div>',
    templateString: "<div id='performance-overview' style='height: 400px'></div>",
    elementId: "performance-overview",
    dataProviderUrl: mockData,
    customColors: null,
    hasIndustry: true,
    postCreate: function () {
      this.renderData();
    },
    renderData: function (chartData) {
      this.chart = new AmCharts.AmSerialChart();
      this.chart.pathToImages = "http://dev.mailchimp.com/charts/amcharts/images/";
      this.chart.dataProvider = mockData;
      this.chart.categoryField = "bubbleText";
      this.chart.startDuration = 0;
      this.chart.autoMargins = false;
      this.chart.marginTop = 10;
      this.chart.marginRight = 0;
      // Change height to fit two line text
      this.chart.marginBottom = 100;
      this.chart.marginLeft = 50;
      // Color change here - Y-axis label colors
      this.chart.color = mColors.brandColors.peppercorn.hex;
      this.chart.balloon.showBullet = false;
      this.chart.balloon.textShadowColor = "none";
      this.chart.balloon.horizontalPadding = 15;
      this.chart.balloon.verticalPadding = 5;
      this.chart.balloon.borderThickness = 0;
      this.chart.balloon.borderThickness = 0;
      this.chart.balloon.shadowAlpha = 0;
      this.chart.balloon.color = "#FFFFFF";
      this.chart.balloon.fillAlpha = 1;
      this.chart.balloon.adjustBorderColor = false;
      // Typography Change Here to Graphik
      this.chart.fontFamily = "'Graphik Web',' Helvetica Neue', Arial, Helvetica, Verdana, sans-serif";
      // Font Size Change Here to 14px
      this.chart.fontSize = 14;
      this.chart.addListener("clickGraphItem", this.dataPointClickedEvt);
      if (this.hasIndustry) {
        this.indavg = new AmCharts.AmGraph();
        this.indavg.title = "Industry Average";
        this.indavg.valueField = "industryAvg";
        this.indavg.type = "line";
        // Color change here - Industry Avg
        this.indavg.lineColor = mColors.dataViz.combo1.color3;
        this.indavg.dashLength = 3;
        this.indavg.balloonText = "[[value]]% Industry Avg";
        this.indavg.lineThickness = 2;
        this.indavg.bullet = "round";
        this.indavg.bulletSize = 0;
        this.indavg.fillAlphas = 0.0;
        // New property for Balloon Color - Peppercorn Variation (90%)
        this.indavg.balloonColor = mColors.dataViz.flyover.flagFill;
        this.chart.addGraph(this.indavg);
      }
      this.clicks = new AmCharts.AmGraph();
      this.clicks.title = "Click Rate";
      this.clicks.valueField = "clickRate";
      this.clicks.type = "smoothedLine";
      // Color change here
      this.clicks.lineColor = mColors.dataViz.combo1.color2;
      this.clicks.balloonText = "[[value]]% Click Rate";
      this.clicks.lineThickness = 3;
      this.clicks.bullet = "round";
      this.clicks.bulletColor = "#fff";
      // Color change here
      this.clicks.bulletBorderColor = mColors.dataViz.combo1.color2;
      this.clicks.bulletBorderThickness = 2;
      this.clicks.bulletBorderAlpha = 1;
      this.clicks.bulletSize = 6;
      this.clicks.fillAlphas = 0.3;
      // New property for Balloon Color - Peppercorn Variation (90%)
      this.clicks.balloonColor = mColors.dataViz.flyover.flagFill;
      this.chart.addGraph(this.clicks);
      this.opens = new AmCharts.AmGraph();
      this.opens.title = "Open Rate";
      this.opens.valueField = "openRate";
      this.opens.type = "smoothedLine";
      // Color change here
      this.opens.lineColor = mColors.dataViz.combo1.color1;
      this.opens.balloonText = "[[value]]% Open Rate";
      this.opens.lineThickness = 2;
      this.opens.bullet = "round";
      this.opens.bulletColor = "#fff";
      // Color change here
      this.opens.bulletBorderColor = mColors.dataViz.combo1.color1;
      this.opens.bulletBorderThickness = 2;
      this.opens.bulletBorderAlpha = 1;
      this.opens.bulletSize = 6;
      this.opens.fillAlphas = 0.0;
      // New property for Balloon Color - Peppercorn Variation (90%)
      this.opens.balloonColor = mColors.dataViz.flyover.flagFill;
      this.chart.addGraph(this.opens);
      var catAxis = this.chart.categoryAxis;
      catAxis.autoGridCount = false;
      catAxis.gridPosition = "start";
      catAxis.dashLength = 0;
      // Color change here - X-axis line color
      catAxis.axisColor = mColors.brandColors.peppercorn.hex;
      catAxis.axisThickness = 2;
      catAxis.gridAlpha = 0;
      catAxis.labelsEnabled = false;
      var valAxis = new AmCharts.ValueAxis();
      valAxis.dashLength = 0;
      valAxis.axisAlpha = 0;
      valAxis.unit = "%";
      // Color change here - No Y Axis line for this example, but change if needed
      valAxis.axisColor = "";
      valAxis.axisThickness = 0;
      if (array.some(chartData, function (data) {
        return data && data.openRate == 100;
      })) {
        valAxis.maximum = 100;
      }
      this.chart.addValueAxis(valAxis);
      var chartCursor = new AmCharts.ChartCursor();
      // Color change here - Bottom Fly-over/Cursor Color
      chartCursor.cursorColor = mColors.dataViz.flyover.flagFill;
      chartCursor.cursorAlpha = 0.2;
      chartCursor.cursorPosition = "middle";
      chartCursor.bulletsEnabled = true;
      chartCursor.bulletSize = 6;
      // Add new property to only show one balloon/fly-over at a time
      chartCursor.oneBalloonOnly = true;
      this.chart.addChartCursor(chartCursor);
      dom.byId(this.elementId).innerHTML = "";
      this.chart.write(this.elementId);
    },
    dataPointClickedEvt: function (event) {
      var url = "";
      var graphClicked = event.graph.valueField;
      if (graphClicked == "clickRate") {
        url = event.item.dataContext.clickUrl;
        window.location = url;
      }
      if (graphClicked == "openRate") {
        url = event.item.dataContext.openUrl;
        window.location = url;
      }
    },
    cursorChangedEvt: function (event) {
      var dataProvider = event.target.chart.dataProvider;
      var data = dataProvider[event.index];
      if (typeof data !== "undefined" && typeof dataProvider !== "undefined") {
        var report_rows = query("[data-campaignId]");
        var _parentRow, _selRow;
        report_rows.forEach(function (node) {
          _parentRow = node.parentElement.parentElement;
          if (domClass.contains(_parentRow, "google-visualization-table-tr-sel")) {
            domClass.remove(_parentRow, "google-visualization-table-tr-sel");
          }
        });
        if (typeof query("[data-campaignId=" + data.campaignId + "]")[0] !== "undefined") {
          _selRow = query("[data-campaignId=" + data.campaignId + "]")[0].parentElement.parentElement;
          domClass.add(_selRow, "google-visualization-table-tr-sel");
        }
      }
    }
  });
});