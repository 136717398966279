define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents", "mojo/lib/flags", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/Dialog", "dojo/text!./templates/feedbackDialog.html", "dojo/text!./templates/thumbsFeedbackDialog.html", "../tracking-helper"], function (declare, lang, domClass, domAttr, on, context, EcsTracking, EcsEvents, flags, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Dialog, tpl, thumbsTpl, TrackingHelper) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    dialogTitleText: "Thanks for your feedback!",
    instructionText: "Additional context (optional)",
    submitButtonText: "Submit",
    closeButtonText: "Close",
    cancelText: "Cancel",
    submitSuccessMessage: "Thanks for submitting your feedback",
    maxLength: 500,
    placeholderText: "Please exclude personal details from your feedback.",
    multipleChoiceHeadline: "Why did you choose this answer?",
    choiceOne: "Content doesn't match my brand voice",
    choiceTwo: "Content is irrelevant to my goals",
    choiceThree: "Content flagged for moderation",
    choiceFour: "I dont want to use AI for this",
    choiceFive: "Too many bugs",
    // data-dojo-attach-point in the template
    choiceOneCheck: null,
    // data-dojo-attach-point in the template
    choiceTwoCheck: null,
    // data-dojo-attach-point in the template
    choiceThreeCheck: null,
    // data-dojo-attach-point in the template
    choiceFourCheck: null,
    // data-dojo-attach-point in the template
    choiceFiveCheck: null,
    // data-dojo-attach-point in the template
    cancelLink: null,
    // data-dojo-attach-point in the template
    submitButton: null,
    // data-dojo-attach-point in the template
    closeButton: null,
    // data-dojo-attach-point in the template
    textarea: null,
    // data-dojo-attach-point in the template
    formContainer: null,
    // data-dojo-attach-point in the template
    charCounter: null,
    hasSubmitted: false,
    selectedOptions: [],
    constructor: function () {
      this.templateString = thumbsTpl;
    },
    postCreate: function () {
      this.own(on(this.submitButton, "click", lang.hitch(this, "_handleSubmit")), on(this.cancelLink, "click", lang.hitch(this, "_handleClose")), on(this.closeButton, "click", lang.hitch(this, "_handleClose")), on(this.textarea, "focus", lang.hitch(this, "_handleFeedbackTextFieldFocus")), on(this.textarea, "input", lang.hitch(this, "_toggleSubmitButton")), on(this.choiceOneCheck, "change", lang.hitch(this, "_toggleSubmitButton")), on(this.choiceTwoCheck, "change", lang.hitch(this, "_toggleSubmitButton")), on(this.choiceThreeCheck, "change", lang.hitch(this, "_toggleSubmitButton")), on(this.choiceFourCheck, "change", lang.hitch(this, "_toggleSubmitButton")), on(this.choiceFiveCheck, "change", lang.hitch(this, "_toggleSubmitButton")));
    },
    _handleSubmit: function () {
      if (this.choiceOneCheck.checked) {
        this.selectedOptions.push("does_not_match_brand_voice");
      }
      if (this.choiceTwoCheck.checked) {
        this.selectedOptions.push("irrelevant_to_goals");
      }
      if (this.choiceThreeCheck.checked) {
        this.selectedOptions.push("flagged_moderation");
      }
      if (this.choiceFourCheck.checked) {
        this.selectedOptions.push("dont_want_ai");
      }
      this.hide();
      this.hasSubmitted = true;
      this.selectedOptions = [];
      if (this.selectedOptions.length === 0) {
        this.onThumbsFeedbackTextSubmitted(this.textarea.value, null);
      } else {
        this.onThumbsFeedbackTextSubmitted(this.textarea.value, this.selectedOptions);
      }
    },
    _handleClose: function () {
      this.onThumbsFeedbackModalClosed();
      this.hide();
    },
    _handleFeedbackTextFieldFocus: function () {
      this.onThumbsFeedbackModalTextFieldFocus();
    },
    /**
     * Disable submit button was on whether there's any text in the input box
     */
    _toggleSubmitButton: function () {
      if (this.textarea.value || this.choiceOneCheck.checked || this.choiceTwoCheck.checked || this.choiceThreeCheck.checked || this.choiceFourCheck.checked || this.choiceFiveCheck.checked) {
        domAttr.remove(this.submitButton, "disabled");
      } else {
        domAttr.set(this.submitButton, "disabled", "disabled");
      }
    },
    show: function () {
      if (!this.dialog) {
        this.dialog = new Dialog({
          "title": this.dialogTitleText,
          "onCancel": lang.hitch(this, "_handleClose"),
          "content": this
        });
      }

      // If it was previously submitted, let's clear the value on show()
      if (this.hasSubmitted) {
        this.hasSubmitted = false;
        this._resetTextInput();
      }
      this._toggleSubmitButton();
      EcsTracking.track(EcsEvents.ViewFeedbackModalEvent, TrackingHelper.getEventParams());
      this.dialog.show();
    },
    _resetTextInput: function () {
      this.textarea.value = "";
      this.charCounter._countRemainingCharacters(this.textarea);
    },
    _trimEventValue: function (value) {
      if (value && value.length && value.length > 128) {
        return value.substring(0, 125) + "...";
      }
      return value;
    },
    hide: function () {
      if (this.dialog) {
        this.dialog.hide();
      }
    },
    /**
     * Dojo widget event for when a customer submits the feedback submits
     *
     * @param {string} feedback the feedback typed into the text area
     */
    onThumbsFeedbackTextSubmitted: function (feedback) {},
    /**
     * Dojo widget event for when a customer closes the feedback modal using the x or "cancel" buttons (for tracking)
     */
    onThumbsFeedbackModalClosed: function () {},
    // /**
    //  * Dojo widget event for when a customer focuses onto the text field in the feedback modal (for tracking)
    //  */
    onThumbsFeedbackModalTextFieldFocus: function () {},
    destroy: function () {
      this.inherited(arguments);
      if (this.dialog) {
        this.dialog.destroy();
      }
    }
  });
});