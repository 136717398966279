/**
 * Code to handle mc:edit for content besides images.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "mojo/neapolitan/editors/_CKMixin", "dojo/text!./templates/text.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Base, _CKMixin, template, lang, on, registry, query, domClass, domAttr, domGeom, domStyle) {
  return declare([_Base, _CKMixin], {
    templateString: template,
    startup: function () {
      this.inherited(arguments);
      this.showCKEditor(this.ckeditorContainer, this.timestampContainer, this.mcedit.content, lang.hitch(this, 'publishReady'));
    },
    show: function () {
      this.publishSetup();
    },
    postCreate: function () {
      this.inherited(arguments);
      var isFooter = this.mcedit.isFooter && this.mcedit.isFooter();
      var self = this;
      domStyle.set(this.footerContactInfo, 'display', isFooter ? 'block' : 'none');
      var contactInfoDialog = registry.byId('edit-contactinfo-modal');
      if (isFooter && contactInfoDialog) {
        domStyle.set(this.contactEditLink, 'display', '');
        on(this.contactEditLink, 'click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          contactInfoDialog.show();
        });
      }

      // Show the switch button and add the onclick.
      if (window.app.hasPreservedContent()) {
        domStyle.set(this.switchContentBtn, 'display', '');
        on(this.switchContentBtn, 'click', function (e) {
          e.stopPropagation();
          e.preventDefault();
          window.app.openPreservedContentDialog().then(function (preservedContent) {
            self.mcedit.recoverContent(preservedContent).then(function (result) {
              self.mcedit.set("content", result.content);
              self.editor.setData(result.content);
            });
          });
        });
      }
      var sections = query('.mojoEditableTextSection', this.domNode);
      if (sections && sections.length) {
        sections.forEach(lang.hitch(this, function (node) {
          // Listen to the scroll event and fixes the toolbar at the top.
          if (domAttr.get(node, "data-mojo-ck-scroll")) {
            this._handleCKToolbarScroll(node);
          }
        }));
      }
    },
    _onDone: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.done();
    },
    onCKChange: function () {
      var content = this.editor.getData();
      this.mcedit.set("content", content);
    },
    _handleCKToolbarScroll: function (node) {
      var toolbarYPos = null;
      on(node, 'scroll', function () {
        if (!toolbarYPos) {
          var toolbar = query('.cke_toolbox', node)[0];
          if (toolbar) {
            var pos = domGeom.position(toolbar);
            toolbarYPos = pos.y + pos.h;
          }
        }
        if (toolbarYPos) {
          var fixedScrollbarOffset = 1;
          var isScrolled = domGeom.position(node).y + node.scrollTop - fixedScrollbarOffset > toolbarYPos && node.scrollTop > fixedScrollbarOffset;
          domClass.toggle(node, "contentEditorScrolled", isScrolled);
        }
      });
    }
  });
});