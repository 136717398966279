define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, domAttr, domClass, on, topic) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    deletedCampaignsRendered: null,
    deletedAutomationsRendered: null,
    onReady: function () {
      var self = this;
      this.parse(this.elId);
      var pageJs = this.pageJs;
      this.$widget.chooseDeletedCampaign.set("onChange", function () {
        if (this.value === "campaigns") {
          domClass.remove(self.$el.deletedCampaignsList, "hide");
          domClass.add(self.$el.deletedAutomationsList, "hide");
          self.$el.deletedTitle.innerHTML = "Deleted Campaigns";
        }
        if (this.value === "automations") {
          domClass.add(self.$el.deletedCampaignsList, "hide");
          domClass.remove(self.$el.deletedAutomationsList, "hide");
          self.$el.deletedTitle.innerHTML = "Deleted Automations";
        }
      });
      topic.subscribe("mojo/analytics/List/deleted-campaigns-list/render", lang.hitch(this, function (list) {
        if (!this.deletedCampaignsRendered) {
          pageJs.loadFilter("deleted");
          this.deletedCampaignsRendered = true;
        }
      }));
      topic.subscribe("mojo/analytics/List/deleted-automations-list/render", lang.hitch(this, function (list) {
        if (!this.deletedAutomationsRendered) {
          pageJs.loadFilter("deleted-automation");
          this.deletedAutomationsRendered = true;
        }
      }));
    }
  });
});