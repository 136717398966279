define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/account/clients/revokeAccess"], function (declare, base, lang, domAttr, on, revokeAccess) {
  return declare([base], {
    onReady: function () {
      on(this.$group.revokeAccessBtn, "click", lang.hitch(this, function (e) {
        var tClientId = domAttr.get(e.target, "data-client-id");
        var tLoginId = domAttr.get(e.target, "data-login-id");
        if (tLoginId === null) {
          revokeAccess.showRemoveAccountConfirmation(tClientId);
        } else {
          revokeAccess.showRemoveUserConfirmation(tClientId, tLoginId);
        }
      }));
    },
    /**
     * Called from _remove-user-dialog
     */
    submitRemoveUser: function () {
      revokeAccess.doRemoveUser();
    },
    /**
     * Called from _remove-account-dialog
     */
    submitRemoveAccount: function () {
      revokeAccess.doRemoveAccount();
    }
  });
});