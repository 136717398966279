/**
 * Utils related to Hosted Payment Form providers
 * Supported Payment Providers are: DLocal, Adyen, and Chase (default)
 */
define(["mojo/lib/flags"], function (flags) {
  /**
  * Determine if user should be shown dlocal payment form
  * @param {string} currency string representation of currency values
  * @param {string[]} dLocalCurrencies list of currencies supported by dLocal
  * @returns {bool} should dLocal form be used
  */
  function shouldUseDLocal(currency, dLocalCurrencies) {
    return dLocalCurrencies.indexOf(currency) !== -1;
  }

  /**
   * Determine if user should be shown adyen form
   * @param {string} currency string representation of currency values
   * @param {object} adyenCurrencies currencies  supported by adyen
   * @returns {bool} should user be shown adyen form
   */
  function shouldUseAdyen(currency, adyenCurrencies) {
    return adyenCurrencies.indexOf(currency) !== -1;
  }

  /**
   * Given a user and a payment currency
   * determine which hosted payment form provider to use
   * @param {string} currency string representation of currency values
   * @param {object} providerCurrencies list of supported currencies by provider.
   * @param {bool|null} preferAdyen should we prefer Adyen for all currencies (other than Dlocal)
   * @returns {'dlocal'|'adyden'|'default'} Hosted Payment Form Provider.
   */
  return {
    getPaymentFormProvider: function (currency, providerCurrencies, preferAdyen) {
      // check to see if Adyen should be used
      var adyenCurrencies = providerCurrencies && providerCurrencies["adyen"] || [];
      if (shouldUseAdyen(currency, adyenCurrencies)) {
        return "adyen";
      }
      var dLocalCurrencies = providerCurrencies && providerCurrencies["dlocal"] || [];
      if (preferAdyen && shouldUseDLocal(currency, dLocalCurrencies) === false) {
        return "adyen";
      }

      // check to see if dLocal should be used
      if (shouldUseDLocal(currency, dLocalCurrencies)) {
        return "dlocal";
      }
      return "default";
    }
  };
});