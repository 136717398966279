define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo/text!./templates/ButtonEditor/editor.html", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/LinkPicker", "mojo/neapolitan/utils", "mojo/neapolitan/editors/_DynamicContentMixin", "mojo/neapolitan/widgets/EditorContainer", "dijit/form/Select", "dijit/form/TextBox"], function (declare, _Base, tmpl, domConstruct, lang, LinkPicker, neapUtils, _DynamicContentMixin) {
  return declare([_Base, _DynamicContentMixin], {
    type: 'button',
    templateString: tmpl,
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      this.linkPicker = new LinkPicker(lang.mixin({
        anchors: window.getAllAnchors(),
        inline: true,
        showButtons: false,
        showRemoveLink: false
      }, this.block.link));
      domConstruct.place(this.linkPicker.domNode, this.linkForm);

      // Sync to Model
      this.sync("align", this.buttonAlignInput, "value");
      this.sync("buttonWidth", this.buttonWidthInput, "value");
      this.sync("buttonText", this.buttonTextInput, "value");

      // We want keyup since we are fancy
      this.connect(this.buttonTextInput, 'onKeyUp', function () {
        self.block.set("buttonText", self.buttonTextInput.get("value"));
      });
    },
    _save: function () {
      this.saveAndHide();
    },
    save: function () {
      var linkData = this.linkPicker.getLink();

      // Encode safe url to prevent xss
      linkData.href = neapUtils.encodeSafeUrl(linkData.href);
      this.block.set("link", linkData);
      return this.inherited(arguments);
    },
    onDataUpdate: function (data) {
      this.linkPicker.set(data.link);
      this.linkPicker.refreshInputs();
    }
  });
});