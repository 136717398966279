define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"./CheckBox", "./_RadioButtonMixin"], function (declare, CheckBox, _RadioButtonMixin) {
  // module:
  //		dijit/form/RadioButton

  return declare("dijit.form.RadioButton", [CheckBox, _RadioButtonMixin], {
    // summary:
    //		Same as an HTML radio, but with fancy styling.

    baseClass: "dijitRadio"
  });
});