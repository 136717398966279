define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var QueryResult = declare([], {
    /**
     * Build the Google Visualization datatable and be generally API-compatible with google.visualization.QueryResponse
     * @param {object} data the JSON string returned from the MC_Google_Visualization server
     */
    constructor: function (data) {
      if (data.status == 'success') {
        var table = new google.visualization.DataTable();
        var meta = data.meta;
        var iter_col;
        for (var i = 0; iter_col = meta[i]; ++i) {
          table.addColumn(iter_col.type, iter_col.label, iter_col.id);
        }
        delete meta;
        delete iter_col;
        var rows = data.data;
        table.addRows(rows.length);
        var iter_row;
        var iter_cell;
        for (var i = 0; iter_row = rows[i]; ++i) {
          for (var j = 0; iter_cell = iter_row[j]; ++j) {
            table.setCell(i, j, iter_cell.v, iter_cell.f);
          }
        }
        this.table = table;
        this.error = false;
      } else {
        this.table = null;
        this.error = data.code;
        this.error_msg = data.msg;
        this.error_detail = data.detail;
      }
    },
    /**
     * Getter functions for the QueryResult class
     */
    getDataTable: function () {
      return this.table;
    },
    /**
     * Getter functions for the QueryResult class
     */
    getDetailedMessage: function () {
      return this.error_detail;
    },
    /**
     * Getter functions for the QueryResult class
     */
    getMessage: function () {
      return this.error_msg;
    },
    /**
     * Getter functions for the QueryResult class
     */
    getReasons: function () {
      return [this.error];
    },
    /**
     * Getter functions for the QueryResult class
     */
    hasWarning: function () {
      return false;
    },
    /**
     * Getter functions for the QueryResult class
     */
    isError: function () {
      return this.error ? true : false;
    }
  });
  return declare([], {
    constructor: function (url, query) {
      this.url = url;
      this.query = query;
    },
    setQuery: function (query) {
      this.query = query;
    },
    /**
     * Actually send the query (just use dojo), and call func(QueryResponse) when done
     * @param {Function} func the callback function
     */
    send: function (func) {
      var content = {};
      if (this.query) content['q'] = this.query;
      dojo.xhrPost({
        'url': this.url,
        'content': content,
        'handleAs': 'json',
        'load': function (data) {
          var result = new QueryResult(data);
          func(result);
        },
        'error': function (data) {
          var result = new QueryResult({
            'status': 'error',
            'code': 'server_error',
            'msg': 'Server Error',
            'detail': data
          });
          func(result);
        }
      });
    }
  });
});