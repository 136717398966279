define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/colors", "mojo/url", "mojo/user", "mojo/widgets/ButtonGroup", "mojo/widgets/FeedbackBlock",
// Libs
"d3/d3", "mccharts/mccharts", "mojo/lib/flags",
// Mixin
"mojo/views/_base"], function (declare, lang, domAttr, domClass, domConstruct, query, xhr, Dialog, mColors, mUrl, user, ButtonGroup, FeedbackBlock, d3, mccharts, flags, _base) {
  return declare([_base], {
    onReady: function () {
      if (this.$el.performanceChart) {
        this._initCharts();
      }
      if (this.$group.recDetailsButtons) {
        this._initRecommendationDialogs();
      }

      // Notify user about a broken connected site
      if (this.active) {
        var statusUrl = mUrl.toUrl("reports/ads/site-status?id=" + this.ad_id);
        xhr(statusUrl, {
          "handleAs": "json"
        }).then(lang.hitch(this, function (response) {
          if (response.status === "error") {
            var feedbackBlock = new FeedbackBlock({
              title: "Paused Ad",
              message: "Your site is no longer connected. <a href=\"" + mUrl.toUrl("account/connected-sites") + "\">Connect your site</a> to continue running your ad.",
              type: "error"
            });
            domConstruct.place(feedbackBlock.domNode, "ad-status-area", "first");
          }
        }));
      }
    },
    /**
     * Create all the dialogs and listeners for when folks are ready to click them.
     *
     * @private
     */
    _initRecommendationDialogs: function () {
      this.$group.recDetailsButtons.forEach(lang.hitch(this, function (btn) {
        // Preload all of the dialogs for faster showing.
        var recCode = domAttr.get(btn, "data-recommendation-code");
        var dialog = this._loadDialog(recCode, this.ad_id);

        // Open the dialog if someone clicks the button.
        query(btn).on("click", function () {
          dialog.show();
        });
      }));
    },
    /**
     * Instantiate each dialog
     *
     * @param {Number} recCode - Such as 1942022 which correlates to a code from Facebook. This will eventually be
     * by the Unique ID of the recommendation.
     * @param {Number} adId - The id of the ad on the page.
     *
     * @return {obj} Dialog
     * @private
     */
    _loadDialog: function (recCode, adId) {
      var dialog = new Dialog({
        "title": "Recommendation details",
        "autofocus": false,
        "onDownloadEnd": function () {
          query("[data-action='closeDialog']", dialog.domNode).on("click", function (e) {
            dialog.hide();
          });
        }
      });
      dialog.setHref(mUrl.toUrl("/reports/ads/recommendation-dialog", {
        id: adId,
        code: recCode
      }));
      return dialog;
    },
    /**
     * Create all of the various charts and handle interactions.
     *
     * @private
     */
    _initCharts: function () {
      // Toggle visiblility of the appropriate chart
      var chartTabs = this._getChartTabs();
      chartTabs.on("change", lang.hitch(this, function () {
        if (chartTabs.value === "Revenue") {
          domClass.add(this.$el.impressionsChartContainer, "hide");
          domClass.add(this.$el.clicksChartContainer, "hide");
          domClass.add(this.$el.audienceChartContainer, "hide");
          domClass.remove(this.$el.revenueChartContainer, "hide");
        } else if (chartTabs.value === "Clicks") {
          domClass.add(this.$el.impressionsChartContainer, "hide");
          domClass.add(this.$el.revenueChartContainer, "hide");
          domClass.add(this.$el.audienceChartContainer, "hide");
          domClass.remove(this.$el.clicksChartContainer, "hide");
        } else if (chartTabs.value === "Impressions") {
          domClass.add(this.$el.clicksChartContainer, "hide");
          domClass.add(this.$el.revenueChartContainer, "hide");
          domClass.add(this.$el.audienceChartContainer, "hide");
          domClass.remove(this.$el.impressionsChartContainer, "hide");
        } else {
          domClass.add(this.$el.clicksChartContainer, "hide");
          domClass.add(this.$el.revenueChartContainer, "hide");
          domClass.add(this.$el.impressionsChartContainer, "hide");
          domClass.remove(this.$el.audienceChartContainer, "hide");
        }
      }));
      if (this.audience && this.audience.length > 0) {
        this._loadChart("#audienceChart", this.audience, "audience", mColors.dataViz.combo1.color1);
      }
      if (this.clicks && this.clicks.length > 0) {
        this._loadChart("#clicksChart", this.clicks, "clicks", mColors.dataViz.combo1.color1);
      }
      if (this.impressions && this.impressions.length > 0) {
        this._loadChart("#impressionsChart", this.impressions, "impressions", mColors.dataViz.combo1.color1);
      }
      if (this.revenue && this.revenue.length > 0) {
        var revenueDisplayCurrency = this["revenue_display_currency"];
        var currencyFormat = function (d) {
          return user.formatCurrency(d, revenueDisplayCurrency);
        };
        var chart = mccharts.models.stackBarChart();
        chart.aspectRatio(3, 1);
        chart.colors([mColors.dataViz.combo1.color1]);
        chart.xAxis.keyValue("date");
        chart.yAxis.keyValue("revenue");
        chart.yAxis.tickFormat(currencyFormat);
        chart.yAxis.maxTicks(10);
        chart.showLegend(false);
        var toolTip = chart.tooltip();
        toolTip.enabled(true);
        toolTip.xOffset(10);
        toolTip.dataFormatter(function (d) {
          var dFormatted = user.formatCurrency(d.y, revenueDisplayCurrency);
          return d3.time.format("%b %d, %Y")(d.x) + "<br/>" + "Revenue: " + dFormatted;
        });
        chart.loadChart("#revenueChart", {
          "revenue": this.revenue
        });
      }

      // has to be hidden after; for some reason d3 will cry if it can't calculate the height of the element
      domClass.add(this.$el.clicksChartContainer, "hide");
      domClass.add(this.$el.revenueChartContainer, "hide");
      domClass.add(this.$el.audienceChartContainer, "hide");
    },
    /**
     * Create the chart in D3 using our mccharts library
     *
     * @param {string} nodeId - The node in which you want the chart to display
     * @param {obj} data - Graphable data
     * @param {string} metric - selector for which part of the data to display
     * @param {string} color  - color of the chart
     * @private
     */
    _loadChart: function (nodeId, data, metric, color) {
      var chart = mccharts.models.lineTimeSeriesChart();
      var labels = {
        impressions: "Impressions",
        clicks: "Clicks",
        audience: "Audience size"
      };
      var toolTip = chart.tooltip();
      toolTip.enabled(true);
      toolTip.xOffset(10);
      toolTip.dataFormatter(function (d) {
        return d3.time.format("%b %d, %Y")(d.date) + "<br/>" + labels[metric] + ": " + d[metric];
      });
      chart.aspectRatio(3, 1);
      chart.lineStrokeColor(color);
      chart.colors([color]);
      chart.xAxis.keyValue("date");
      chart.yAxis.keyValue(metric);
      chart.yAxis.maxTicks(9);
      chart.loadChart(nodeId, data.map(function (d) {
        d.date = new Date(d.date); // for single line time series, the x-axis data must be a date.
        return d;
      }));
    },
    /**
     * Generate the button group necessary for navigating the chart
     *
     * @return {obj} ButtonGroup
     * @private
     */
    _getChartTabs: function () {
      var tabItemTemplate = "<li class='c-buttonGroupItem'>" + "<button data-mc-value='{value}' title='{title}' class='c-buttonGroupButton v-buttonGroupUnderlined'>{label}</button>" + "</li>";

      // for toggling between clicks and impressions
      var tabOptions = [{
        title: "Impressions",
        value: "Impressions",
        label: "Impressions"
      }, {
        title: "Clicks",
        value: "Clicks",
        label: "Clicks"
      }];

      // if we have revenue, show the label.
      if (this.revenue) {
        tabOptions.push({
          title: "Revenue",
          value: "Revenue",
          label: "Revenue"
        });
      }
      if (this.audience) {
        tabOptions.push({
          title: "Audience",
          value: "Audience",
          label: "Audience"
        });
      }
      var chartTabs = new ButtonGroup({
        options: tabOptions,
        itemTpl: tabItemTemplate,
        activeClass: " v-buttonGroupUnderlined--active"
      });
      chartTabs.placeAt(this.$el.chartToggler);
      chartTabs.startup();
      chartTabs.setSelected(tabOptions[0].value);
      return chartTabs;
    }
  });
});