define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/Pusher", "mojo/url"], function (lang, xhr, mPusher, mUrl) {
  var api = {
    getIsSyncing: function (siteId) {
      var endpoint = "/account/connected-sites/is-syncing";
      if (!!siteId) {
        endpoint = endpoint.concat("?id=", siteId);
      }
      return xhr.get(mUrl.toUrl(endpoint), {
        "handleAs": "json"
      });
    },
    onSyncStart: function (channelName, callback) {
      return mPusher.load().then(function (pusher) {
        var channel = pusher.subscribe(channelName);
        channel.bind("sync-start", callback);
        return channel;
      });
    },
    onSyncComplete: function (channelName, callback) {
      return mPusher.load().then(function (pusher) {
        var channel = pusher.subscribe(channelName);
        channel.bind("sync-complete", callback);
        return channel;
      });
    },
    disconnectSync: function () {
      mPusher.disconnect();
    }
  };
  return api;
});