define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/utils", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "vendor/lodash-amd/filter", "mojo/context", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, lang, base, on, registry, utils, user, flags, mLogger, _filter, context, EcsEvents, EcsTracking) {
  var USER_BROWSER_TZ = window.Intl && window.Intl.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone : null;
  return declare([base], {
    isAgency: null,
    // Passed from controller

    curRegion: "All",
    curTzOptions: null,
    allTzOptions: null,
    onReady: function () {
      if (this.$el.dateFormatInput && context.flagIsOn(flags.I18N_DATE_ACCOUNT_SETTINGS)) {
        this._attachDateFormatChangeHandler();
      }
      if (this.$el.submitButton && context.flagIsOn(flags.I18N_DATE_ACCOUNT_SETTINGS)) {
        this._attachSubmitButtonClickHandler();
      }
      // Throws event when agency "save" button is clicked
      if (this.$el.agencyForm) {
        on(this.$el.agencyForm, "submit", lang.hitch(this, function (e) {
          this._submitAgencySetting(e);
        }));
      }
      this.allTzOptions = Object.values(this.extendedTimezones);
      this.curTzOptions = this.allTzOptions;
      // create tz options list
      registry.byId("default-timezone").set("options", this.curTzOptions);
      registry.byId("default-timezone").set("value", user.timezoneId);
      registry.byId("timezone-region").set("options", this.extendedTimezoneRegions);
      registry.byId("timezone-region").on("change", this._handleChangeRegion.bind(this));
      registry.byId("timezone-region").set("value", this.curRegion);
      registry.byId("default-timezone").on('click', function (evt) {
        // clean up with channels.timezone_update_extended_list
        mLogger.info("timezone-id-update", "timezone list selection clicked", {
          log_classification: "sensitive"
        });
      }.bind(this));
      registry.byId("default-timezone").on('change', function (val) {
        // clean up with channels.timezone_update_extended_list
        console.log("Timezone value: ", val);
        mLogger.info("timezone-id-update", "timezone list selection changed", {
          log_classification: "sensitive",
          "cur_timezone": user.timezoneId,
          "browser_timezone": USER_BROWSER_TZ,
          "new_timezone": val,
          "user_tz_deprecated": this.userTzDeprecated,
          "user_tz_dst_deprecated": this.userTzDSTDeprecated
        });
      }.bind(this));
      registry.byId("timezone-region").on('click', function (evt) {
        // clean up with channels.timezone_update_extended_list
        mLogger.info("timezone-id-update", "timezone region selection clicked", {
          log_classification: "sensitive"
        });
      }.bind(this));
      registry.byId("timezone-region").on('change', function (val) {
        // clean up with channels.timezone_update_extended_list
        console.log("Region value: ", val);
        mLogger.info("timezone-id-update", "timezone region selection changed", {
          log_classification: "sensitive",
          "region": val
        });
      }.bind(this));
    },
    _attachDateFormatChangeHandler: function () {
      on(this.$widget.dateFormatInput, "change", lang.hitch(this, function (e) {
        EcsEvents.I18nSelectDateFormatEvent.selected_date_format = this.$widget.dateFormatInput.get("value");
        EcsTracking.track(EcsEvents.I18nSelectDateFormatEvent);
      }));
    },
    _attachSubmitButtonClickHandler: function () {
      on(this.$el.submitButton, "click", lang.hitch(this, function (e) {
        EcsEvents.I18nSaveDateFormatEvent.saved_date_format = this.$widget.dateFormatInput.get("value");
        EcsTracking.track(EcsEvents.I18nSaveDateFormatEvent);
      }));
    },
    _handleChangeRegion: function (e) {
      if (e === "All") {
        this.curTzOptions = this.allTzOptions;
        registry.byId("default-timezone").set("value", user.timezoneId);
      } else {
        this.curTzOptions = _filter(this.allTzOptions, {
          'data-region': e
        });
      }
      registry.byId("default-timezone").set("options", this.curTzOptions);
      if (this.curRegion !== e) {
        this.curRegion = e;
        registry.byId("default-timezone").focus();
        registry.byId("default-timezone").focus();
        registry.byId("default-timezone").loadAndOpenDropDown();
        registry.byId("default-timezone").dropDown.focus();
      }
    },
    /**
     * Check whether form should just be submitted or dialog should show
     * @param {Object} e Event data
     */
    _submitAgencySetting: function (e) {
      // User is currently an agency, and has unchecked the agency checkbox
      // So show them a dialog to make sure they want to disable agency features
      if (this.isAgency === "Y" && this.$el.agencyCheck.checked === false) {
        e.stopPropagation();
        e.preventDefault();
        utils.toggleButtonLoadingState(this.$el.agencyFormButton);
        registry.byId("disable-agency-dialog").show();
      }
    }
  });
});