/**
 *  For campaigns that may be in the process of sending (from MCMTA's perspective),
 *  update to sent if they've finished.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html"], function (declare, _WidgetBase, xhr, mUrl, lang, domClass, array, html) {
  var widgetIds = [];
  var campaignIds = [];
  var timeout = null;
  return declare([_WidgetBase], {
    campaignId: null,
    updateType: null,
    removeClass: null,
    addClass: null,
    newHTML: null,
    constructor: function (props) {
      if (!props.campaignId) {
        throw new Error("You must specify campaignId");
      }
      if (!props.updateType) {
        throw new Error("You must specify updateType of 'replace-class' or 'replace-string'");
      }
      if (props.updateType === 'replace-class' && (!props.removeClass || !props.addClass)) {
        throw new Error("You must specify removeClass and addClass");
      }
      if (props.updateType === 'replace-string' && !props.newHTML) {
        throw new Error("You must specify newHTML");
      }
    },
    postCreate: function () {
      if (widgetIds.indexOf(this.id) === -1) {
        widgetIds.push(this.id);
      }
      if (campaignIds.indexOf(this.campaignId) === -1) {
        campaignIds.push(this.campaignId);
      }
      if (timeout === null) {
        timeout = setTimeout(lang.hitch(this, this.checkInProgressCampaigns), 0);
      }
    },
    checkInProgressCampaigns: function () {
      xhr.get(mUrl.toUrl("campaigns/get-send-confirmed-campaigns"), {
        handleAs: "json",
        query: {
          "ids[]": campaignIds
        }
      }).then(lang.hitch(this, "updateCampaigns"));
    },
    updateCampaigns: function (campaignIdsToUpdate) {
      array.forEach(widgetIds, function (widgetId) {
        var widget = dijit.byId(widgetId);
        if (campaignIdsToUpdate.indexOf(widget.campaignId) != -1) {
          if (widget.updateType === 'replace-class') {
            domClass.replace(widget.domNode, widget.addClass, widget.removeClass);
          }
          if (widget.updateType === 'replace-string') {
            html.set(widget.domNode, widget.newHTML);
          }
        }
      });
      widgetIds = [];
      campaignIds = [];
      timeout = null;
    }
  });
});