define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/form/CheckBox"], function (declare, lang, aspect, query, registry, CheckBox) {
  var lastSelectedBox = null;
  return declare([CheckBox], {
    baseClass: "dijitCheckBox selectCheckBox",
    postCreate: function () {
      this.inherited(arguments);
      aspect.after(this, "onClick", lang.hitch(this, function (e) {
        if (e.shiftKey && lastSelectedBox != null) {
          var checkboxes = query('.selectCheckBox');
          var clickedBox = this.domNode;
          var from = null,
            to = null;
          var found = checkboxes.some(function (el, idx) {
            if (el == clickedBox || lastSelectedBox == el) {
              if (from == null) {
                from = idx;
              } else {
                to = idx;
              }
            }
            return from != null && to != null;
          });
          if (found) {
            for (var i = from; i <= to; i++) {
              var node = registry.byNode(checkboxes[i]);
              if (!node.disabled) {
                node.set('value', this.get('value'));
              }
            }
          }
        }
        lastSelectedBox = this.domNode;
      }), true);
    }
  });
});