define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dijit/TitlePane"], function (declare, _base, array, lang, domConstruct, on, utils, TitlePane) {
  return declare([_base], {
    folders: null,
    onReady: function () {
      if (this.folders) {
        array.forEach(this.folders, lang.hitch(this, "_renderFolderCode"));
      }
    },
    /**
     * Render the pane that contains the folder name and code for a user to copy and paste
     * 
     * @param {Object} folder
     * 
     * @private
     */
    _renderFolderCode: function (folder) {
      var id = "folder-" + folder.id;
      var codeTextArea = domConstruct.create("textarea", {
        "id": id,
        "innerHTML": folder.code
      });
      on(codeTextArea, "click", function () {
        utils.selectAll(id);
      });
      var pane = new TitlePane({
        title: folder.name,
        content: codeTextArea,
        open: false
      });
      pane.startup();
      domConstruct.place(pane.domNode, this.$el.codeContainer);
    }
  });
});