define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "dojox"], function (dojo, dijit, dojox) {
  dojo.provide('mojo.peaches');
  var graphsColors = ['#cfbecd', '#b79db5', '#9f7d9c', '#fbd4b4', '#f69343', '#ffeebf', '#fede88'];
  mojo.peaches.OverallUnsubscribeReasons = function (elementId, dataProviderUrl, userId, legendId) {
    this.elementId = elementId;
    this.dataProviderUrl = dataProviderUrl;
    this.userId = userId;
    this.legendId = legendId;
    var self = this;
    dojo.xhrGet({
      'url': this.dataProviderUrl,
      'content': {
        'user_id': this.userId
      },
      'handleAs': 'json',
      'load': function (data) {
        var chartData = [];
        var dataSet = data.all_time;
        if (self.legendId === 'ninety-day-legend') {
          dataSet = data.last_90_days;
        } else if (self.legendId === 'thirty-day-legend') {
          dataSet = data.last_30_days;
        }
        for (var prop in dataSet) {
          var dataVal = dataSet[prop];
          switch (prop) {
            case '':
              prop = 'Unspecified';
              break;
            case 'NORMAL':
              prop = 'No longer interested';
              break;
            case 'NOSIGNUP':
              prop = 'Did not sign up';
              break;
            case 'SPAM':
              prop = 'Spammy Content';
              break;
            case 'OTHER':
              prop = 'Other';
              break;
            case 'INAPPROPRIATE':
              prop = 'Inappropriate';
              break;
            case 'BYADMIN':
              prop = 'Unsub by Admin';
          }
          var obj = {
            "type": prop,
            "value": dataVal
          };
          chartData.push(obj);
        }
        var legendLocation = dojo.byId(self.legendId);
        legendLocation.innerHTML = '';
        var swatchCount = -1;
        chartData.forEach(function (entry, i) {
          swatchCount++;
          var theLegend = '<div class="size1of1 lastUnit flex small-meta nowrap">' + '<span style="flex: 0 0 85%">' + '<span class="swatch c-overallUnsubs_swatch c-swatch--' + swatchCount + '"></span>' + '<span>' + entry.type + '</span>' + '</span>' + '<span class="fwb">' + (entry.value * 100).toFixed(1) + '%</span>' + '</div>';
          dojo.place(theLegend, legendLocation, 'last');
        });
        var checkValue = [];
        for (i = 0; i < chartData.length; i++) {
          checkValue.push(chartData[i].value);
        }
        if (checkValue[0] === 0 && checkValue[1] === 0 && checkValue[2] === 0 && checkValue[3] === 0 && checkValue[4] === 0 && checkValue[5] === 0) {
          self.elementId.innerHTML = '';
          var noData = '<div class="no-data alignc">' + '<img role="presentation" src="/css/icons/no-data/goose-egg.svg" class="icon">' + '<h2>Goose egg. There\'s no information available.</h2>' + '</div>';
          dojo.place(noData, self.elementId, 'only');
        } else {
          self.elementId.innerHTML = '';
          self.renderData(chartData);
        }
      }
    });
  };
  mojo.peaches.OverallUnsubscribeReasons.prototype.renderData = function (chartData) {
    var chart = AmCharts.makeChart(this.elementId, {
      "type": "pie",
      "theme": "light",
      "dataProvider": chartData,
      "titleField": "type",
      "valueField": "value",
      "labelRadius": 5,
      "colors": graphsColors,
      "marginBottom": 0,
      "marginLeft": 0,
      "marginTop": 0,
      "radius": "42%",
      "innerRadius": "80%",
      "labelText": "[[title]]",
      "pathToImages": "/charts/amcharts/images/",
      "fontFamily": "'Open Sans','Helvetica Neue',Arial,Helvetica,Verdana,sans-serif",
      "fontSize": 13,
      "percentFormatter": {
        "precision": 1,
        "decimalSeparator": '.',
        "thousandsSeparator": ','
      },
      "pullOutRadius": "0%",
      "balloonText": '<span class="fwb">[[percents]]%</span> <span class="capitalize">[[title]]</span>',
      "balloon": {
        "borderThickness": 0,
        "animationDuration": 0.25,
        "fadeOutDuration": 0.25,
        "shadowColor": "transparent",
        "horizontalPadding": 12,
        "verticalPadding": 6,
        "color": "#fff",
        "fillColor": "#373737",
        "cornerRadius": 3,
        "fillAlpha": 1,
        "textAlign": "left",
        "startDuration": 0,
        "sequencedAnimation": true,
        "startAlpha": "100%"
      },
      "labelsEnabled": false,
      "startRadius": "0%",
      "startDuration": 0,
      "pullOutRadius": "0%",
      "angle": 0,
      "depth3D": 0,
      "minRadius": 2
    });
  };
  mojo.peaches.PerformanceOverviewChart = function (elementId, dataProviderUrl, customColors) {
    this.elementId = elementId;
    this.dataProviderUrl = dataProviderUrl;
    this.customColors = customColors;
    var chart = this;
    dojo.xhrGet({
      'url': this.dataProviderUrl,
      'content': '',
      'handleAs': 'json',
      'load': function (data) {
        if (data && data.length > 0) {
          chart.renderData(data);
        } else {
          dojo.byId(elementId).innerHTML = '<div class="no-data-sample border panel-block alignc" style="height:168px;"><img src="https://cdn-images.mailchimp.com/product/icons/campaign-performance.png"><p class="empty-tagline">We don\'t have enough campaign performance data yet.</p></div>';
          dojo.byId('campaign-performance-legend').style.display = 'none';
          dojo.byId('reports-performance-chart').style.display = 'none';
        }
      },
      'error': function (data) {
        console.log('error: ' + JSON.stringify(data));
      }
    });
  };
  mojo.peaches.PerformanceOverviewChart.prototype.renderData = function (chartData) {
    this.chart = new AmCharts.AmSerialChart();
    this.chart.pathToImages = "/charts/amcharts/images/";
    this.chart.dataProvider = chartData;
    this.chart.categoryField = "bubbleText";
    this.chart.startDuration = 1;
    this.chart.marginTop = 25;
    this.chart.marginBottom = 50;
    this.chart.marginLeft = 50;
    this.chart.color = "#555";
    this.chart.balloon.showBullet = false;

    // Listener for data points clicks
    this.chart.addListener("clickGraphItem", this.dataPointClickedEvt);
    var clicks = new AmCharts.AmGraph();
    clicks.title = "Click Rate";
    clicks.valueField = "clickRate";
    //clicks.urlField = "clickURL";
    clicks.type = "line";
    clicks.lineColor = this.customColors ? this.customColors[0] : "#ed5d3b";
    clicks.balloonText = "[[value]]% Click Rate";
    clicks.lineThickness = 2;
    clicks.bullet = "round";
    clicks.bulletColor = "#fff";
    clicks.bulletSize = 6;
    clicks.bulletBorderColor = this.customColors ? this.customColors[0] : "#ed5d3b";
    clicks.bulletBorderThickness = 3;
    clicks.fillAlphas = 0.0;
    this.chart.addGraph(clicks);
    var opens = new AmCharts.AmGraph();
    opens.title = "Open Rate";
    opens.valueField = "openRate";
    //opens.urlField = "openURL";
    opens.type = "line";
    opens.lineColor = this.customColors ? this.customColors[1] : "#2c9ab7";
    opens.balloonText = "[[value]]% Open Rate";
    opens.lineThickness = 2;
    opens.bullet = "round";
    opens.bulletColor = "#fff";
    opens.bulletSize = 6;
    opens.bulletBorderColor = this.customColors ? this.customColors[1] : "#2c9ab7";
    opens.bulletBorderThickness = 3;
    opens.fillAlphas = 0.0;
    this.chart.addGraph(opens);
    var catAxis = this.chart.categoryAxis;
    catAxis.autoGridCount = false;
    catAxis.gridPosition = "start";
    catAxis.dashLength = 5;
    catAxis.axisColor = "#ccc";
    var valAxis = new AmCharts.ValueAxis();
    valAxis.dashLength = 5;
    valAxis.unit = "%";
    valAxis.axisColor = "#ccc";
    this.chart.addValueAxis(valAxis);
    var chartCursor = new AmCharts.ChartCursor();
    chartCursor.cursorColor = "#333";
    chartCursor.cursorAlpha = .2;
    chartCursor.cursorPosition = "middle";
    chartCursor.bulletsEnabled = false;
    this.chart.addChartCursor(chartCursor);

    /*
    Listener attached to cursor that allows to fire when
    cursor changes postion/snaps to data point.
    */
    chartCursor.addListener("changed", this.cursorChangedEvt);
    var chartScrollbar = new AmCharts.ChartScrollbar();
    chartScrollbar.graph = opens;
    this.chart.addChartScrollbar(chartScrollbar);
    dojo.byId(this.elementId).innerHTML = '';
    this.chart.write(this.elementId);
  };

  // Data point click event
  mojo.peaches.PerformanceOverviewChart.prototype.dataPointClickedEvt = function (event) {
    var url = "";
    var graphClicked = event.graph.valueField;
    if (graphClicked == "clickRate") {
      url = event.item.dataContext.clickUrl;
      window.location = url;
    }
    if (graphClicked == "openRate") {
      url = event.item.dataContext.openUrl;
      window.location = url;
    }
  };

  //Cursor changed event implementation
  mojo.peaches.PerformanceOverviewChart.prototype.cursorChangedEvt = function (event) {
    var dataProvider = event.target.chart.dataProvider;
    var data = dataProvider[event.index];
    if (typeof data != 'undefined' && typeof dataProvider != 'undefined') {
      var report_rows = dojo.query('[data-campaignId]');
      var _parentRow, _selRow;
      report_rows.forEach(function (node) {
        _parentRow = node.parentElement.parentElement;
        if (dojo.hasClass(_parentRow, "google-visualization-table-tr-sel")) {
          dojo.removeClass(_parentRow, "google-visualization-table-tr-sel");
        }
      });
      if (typeof dojo.query('[data-campaignId=' + data.campaignId + ']')[0] != 'undefined') {
        _selRow = dojo.query('[data-campaignId=' + data.campaignId + ']')[0].parentElement.parentElement;
        dojo.addClass(_selRow, "google-visualization-table-tr-sel");
      }
    }
  };
  mojo.peaches.AbusePerformanceOverviewChart = function (elementId, dataProviderUrl, customColors) {
    this.elementId = elementId;
    this.dataProviderUrl = dataProviderUrl;
    this.customColors = customColors;
    var chart = this;
    dojo.xhrGet({
      'url': this.dataProviderUrl,
      'content': '',
      'handleAs': 'json',
      'load': function (data) {
        if (data && data.length > 0) {
          chart.renderData(data);
        } else {
          dojo.byId(elementId).innerHTML = '<div class="no-data"><img src="https://cdn-images.mailchimp.com/product/icons/campaign-performance.png"><p>We don\'t have enough campaign performance data yet.</p></div>';
          var campaignPerformanceLegend = dojo.byId("campaign-performance-legend");
          if (campaignPerformanceLegend) {
            campaignPerformanceLegend.style.display = "none";
          }
        }
      },
      'error': function (data) {
        console.log('error: ' + JSON.stringify(data));
      }
    });
  };
  mojo.peaches.AbusePerformanceOverviewChart.prototype.renderData = function (chartData) {
    this.chart = new AmCharts.AmSerialChart();
    this.chart.pathToImages = "/charts/amcharts/images/";
    this.chart.dataProvider = chartData;
    this.chart.categoryField = "bubbleText";
    this.chart.startDuration = 1;
    this.chart.marginTop = 25;
    this.chart.marginBottom = 50;
    this.chart.marginLeft = 50;
    this.chart.color = "#555";
    this.chart.balloon.showBullet = false;

    // Listener for data points clicks
    this.chart.addListener("clickGraphItem", this.dataPointClickedEvt);
    var abuse = new AmCharts.AmGraph();
    abuse.title = "Abuse Rate";
    abuse.valueField = "abuseRate";
    abuse.type = "line";
    abuse.lineColor = this.customColors ? this.customColors[0] : "#ed5d3b";
    abuse.balloonText = "[[value]]% Abuse Rate";
    abuse.lineThickness = 2;
    abuse.bullet = "round";
    abuse.bulletColor = "#ed5d3b";
    abuse.bulletSize = 6;
    abuse.bulletBorderColor = this.customColors ? this.customColors[0] : "#ed5d3b";
    abuse.bulletBorderThickness = 3;
    abuse.fillAlphas = 0.0;
    this.chart.addGraph(abuse);
    var bounce = new AmCharts.AmGraph();
    bounce.title = "Bounce Rate";
    bounce.valueField = "bounceRate";
    bounce.type = "line";
    bounce.lineColor = this.customColors ? this.customColors[1] : "#2c9ab7";
    bounce.balloonText = "[[value]]% Bounce Rate";
    bounce.lineThickness = 2;
    bounce.bullet = "round";
    bounce.bulletColor = "#2c9ab7";
    bounce.bulletSize = 6;
    bounce.bulletBorderColor = this.customColors ? this.customColors[1] : "#2c9ab7";
    bounce.bulletBorderThickness = 3;
    bounce.fillAlphas = 0.0;
    this.chart.addGraph(bounce);
    var unsub = new AmCharts.AmGraph();
    unsub.title = "Unsub Rate";
    unsub.valueField = "unsubRate";
    unsub.type = "line";
    unsub.lineColor = this.customColors ? this.customColors[1] : "#b1e0ec";
    unsub.balloonText = "[[value]]% Unsub Rate";
    unsub.lineThickness = 2;
    unsub.bullet = "round";
    unsub.bulletColor = "#b1e0ec";
    unsub.bulletSize = 6;
    unsub.bulletBorderColor = this.customColors ? this.customColors[1] : "#b1e0ec";
    unsub.bulletBorderThickness = 3;
    unsub.fillAlphas = 0.0;
    this.chart.addGraph(unsub);
    var catAxis = this.chart.categoryAxis;
    catAxis.autoGridCount = false;
    catAxis.gridPosition = "start";
    catAxis.dashLength = 5;
    catAxis.axisColor = "#ccc";
    var valAxis = new AmCharts.ValueAxis();
    valAxis.dashLength = 5;
    valAxis.unit = "%";
    valAxis.axisColor = "#ccc";
    this.chart.addValueAxis(valAxis);
    var chartCursor = new AmCharts.ChartCursor();
    chartCursor.cursorColor = "#333";
    chartCursor.cursorAlpha = .2;
    chartCursor.cursorPosition = "middle";
    chartCursor.bulletsEnabled = false;
    chartCursor.categoryBalloonFunction = function (value) {
      return dojox.html.entities.encode(value);
    };
    this.chart.addChartCursor(chartCursor);

    /*
    Listener attached to cursor that allows to fire when
    cursor changes postion/snaps to data point.
    */
    chartCursor.addListener("changed", this.cursorChangedEvt);
    var chartScrollbar = new AmCharts.ChartScrollbar();
    chartScrollbar.graph = abuse;
    this.chart.addChartScrollbar(chartScrollbar);
    dojo.byId(this.elementId).innerHTML = '';
    this.chart.write(this.elementId);
  };

  //Cursor changed event implementation
  mojo.peaches.AbusePerformanceOverviewChart.prototype.cursorChangedEvt = function (event) {
    var dataProvider = event.target.chart.dataProvider;
    var data = dataProvider[event.index];
    if (typeof data != 'undefined' && typeof dataProvider != 'undefined') {
      var report_rows = dojo.query('[data-campaignId]');
      var _parentRow, _selRow;
      report_rows.forEach(function (node) {
        _parentRow = node.parentElement.parentElement;
        if (dojo.hasClass(_parentRow, "google-visualization-table-tr-sel")) {
          dojo.removeClass(_parentRow, "google-visualization-table-tr-sel");
        }
      });
      if (typeof dojo.query('[data-campaignId=' + data.campaignId + ']')[0] != 'undefined') {
        _selRow = dojo.query('[data-campaignId=' + data.campaignId + ']')[0].parentElement.parentElement;
        dojo.addClass(_selRow, "google-visualization-table-tr-sel");
      }
    }
  };
});