define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, query, domStyle, domClass, dom) {
  return declare([base], {
    onReady: function () {
      this.toggleSection("domain");
    },
    toggleSection: function (someId) {
      query(".sectme").forEach(function (el) {
        domStyle.set(el, "display", "none");
      });
      domStyle.set(dom.byId(someId), "display", "block");
    },
    toggleDetail: function (someId) {
      var sect = someId.split("-")[0];
      query(".detailme", someId).forEach(function (el) {
        if (domClass.contains(el, sect)) {
          return;
        }
        if (domStyle.get(el, "display") === "none") {
          domStyle.set(el, "display", "none");
          domStyle.set(el, "display", "block");
        } else {
          domStyle.set(el, "display", "block");
          domStyle.set(el, "display", "none");
        }
      });
    }
  });
});