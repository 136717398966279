define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/registry"], function (declare, dom, domAttr, on, base, mUrl, registry) {
  return declare([base], {
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      if (this.$group.signAgreementButton) {
        this.$group.signAgreementButton.forEach(function (el) {
          on(el, "click", function () {
            var agreementId = domAttr.get(el, "data-agreement-id");
            self.showSignAgreementDialog(agreementId);
          });
        });
      }
    },
    showSignAgreementDialog: function (agreementId) {
      var signAgreementDialog = registry.byId("sign-agreement-dialog");
      var confirmId = dom.byId("confirm-agreement-id");
      var formId = dom.byId("form-agreement-id");
      domAttr.set(formId, "value", agreementId);
      domAttr.set(confirmId, "textContent", agreementId);
      signAgreementDialog.show();
    }
  });
});