/**
 * Accept platforms data from controller and mount AppSelectionManager
 * @module /mojo/views/connected-sites/app-selection.js
 * @see /mojo/widgets/account/connected-sites/AppSelectionManager
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/widgets/account/connected-sites/AppSelectionManager", "mojo/user", "mojo/lib/flags"], function (declare, base, AppSelectionManager, user, flags) {
  return declare([base], {
    // From controller
    platformOptions: null,
    hasConnectedSite: false,
    supportOptions: null,
    onReady: function () {
      new AppSelectionManager({
        platformOptions: this.platformOptions,
        hasConnectedSite: this.hasConnectedSite,
        supportOptions: this.supportOptions
      }).placeAt(this.$el.appSelectionContainer);
    }
  });
});