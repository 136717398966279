define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo/text!./templates/search.html", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking"], function (declare, _Part, _Templated, tpl, array, on, lang, sync, domAttr, keys, domClass, context, flags, EcsEvents, EcsTracking) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    label: '',
    placeholder: "Search",
    syncAttr: "search",
    alwaysVisible: true,
    iconVisible: true,
    init: function () {
      domAttr.set(this.search, "placeholder", this.placeholder);
      domClass.toggle(this.submit, "hide", !this.iconVisible);
      var value = this.target.getFilter(this.syncAttr) || "";
      domAttr.set(this.search, "value", value);
      this._watches.push(this.target.watchFilter(this.syncAttr, lang.hitch(this, function (name, oldValue, value) {
        domAttr.set(this.search, "value", value);
      })));
      on(this.submit, "click", lang.hitch(this, '_setValue'));
      on(this.submit, "click", function (e) {
        if (context.flagIsOn(flags.WAYFI_SEARCH_ECS_TRACKING)) {
          if (window && window.location && window.location.href) {
            if (window.location.href.includes("/content")) {
              EcsTracking.track(EcsEvents.MyFilesSearchEvent);
            } else if (window.location.href.includes("/templates")) {
              EcsTracking.track(EcsEvents.SavedTemplatesSearchEvent);
            }
          }
        }
      });
      var self = this;
      on(this.search, "keyup", function (e) {
        var charCode = e.charCode || e.keyCode;
        if (charCode === keys.ENTER || this.value === '') {
          self._setValue();
        }
        if (charCode === keys.ESCAPE) {
          this.value = '';
          self._setValue();
        }
      });
    },
    _setValue: function () {
      this.target.setFilter(this.syncAttr, domAttr.get(this.search, "value"));
    },
    canDisplay: function () {
      return this.alwaysVisible || this.inherited(arguments);
    },
    onChange: function (value) {}
  });
});