define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Group", "dojo/text!./templates/subgroup.html", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, on, lang, Group, tpl, I18nTranslation, flags, context) {
  return declare([Group], {
    templateString: tpl,
    subscribed: "Subscribed",
    unsubscribed: "Unsubscribed",
    non_subscribed: "Non-subscribed",
    cleaned: "Cleaned",
    contacts_that_match: "contacts that match",
    of_the_following_conditions: "of the following conditions:",
    remove_group: "Remove group",
    add_to_group: "Add to group",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
        I18nTranslation.initialize().then(function (translations) {
          this.subscribed = translations.reporting_comparative_report_subscribed;
          this.unsubscribed = translations.reporting_comparative_report_unsubscribed;
          this.non_subscribed = translations.reporting_comparative_report_non_subscribed;
          this.cleaned = translations.reporting_comparative_report_cleaned;
          this.contacts_that_match = translations.reporting_comparative_report_contacts_that_match;
          this.of_the_following_conditions = translations.reporting_comparative_report_of_the_following_conditions;
          this.remove_group = translations.reporting_comparative_report_remove_group;
          this.add_to_group = translations.reporting_comparative_report_add_to_group;
        }.bind(this));
      }
    },
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize().then(function () {
          if (!I18nTranslation.isTranslationObjectEmpty()) {
            this.addToGroupBtnText.innerText = I18nTranslation.translate("audience_management_segment_edit_add_to_group_button_text");
          }
        }.bind(this));
      }
      this.inherited(arguments);
      this.own(on(this.removeAllBtn, "click", lang.hitch(this, function (e) {
        e.stopPropagation();
        e.preventDefault();
        this.destroy();
      })));
    },
    addCondition: function () {
      var condition = this.inherited(arguments);
      this._toggleConditionDelete();
      return condition;
    },
    onConditionDelete: function (row) {
      this.inherited(arguments);
      this._toggleConditionDelete();
    },
    /**
     * Disables delete option for condition rows when it's the only one condition.
     *
     * @private
     */
    _toggleConditionDelete: function () {
      if (this.conditions.length === 1) {
        this.conditions[0].toggleDelete(false);
      } else if (this.conditions.length > 1) {
        this.conditions[0].toggleDelete(true);
      }
    },
    destroy: function () {
      this.inherited(arguments);
      this.onDestroy();
    },
    onDestroy: function () {}
  });
});