define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/MicrosoftOutlook.html", "dijit/Dialog"], function (declare, lang, xhr, domClass, on, mUrl, mPusher, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    _setAppInstallUrlAttr: {
      node: "integrationButtonNode",
      type: "attribute",
      attribute: "href"
    },
    isSyncing: null,
    postCreate: function () {
      if (this.integrationInstallStatus === true) {
        this._setupDisconnectButton();
      }
      this.own(on(this.disconnectButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        if (this.isSyncing) {
          this._showDisconnectDialog();
        } else {
          this._disconnectIntegration();
        }
      })));
      this.own(on(this.testButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._testConnection();
      })));
      this._loadData();
    },
    _testConnection: function () {
      var test_url = "/account/integrations/microsoft-outlook-test";
      var self = this;
      var txtbox = self.testResults;
      self._showLoadingIndicator();
      window.dojo.xhrPost({
        "url": mUrl.toUrl(test_url),
        "content": {},
        "handleAs": "text",
        "load": function (data) {
          self._hideLoadingIndicator();
          txtbox.innerHTML = data;
        }
      });
    },
    _loadData: function () {
      var self = this;
      window.dojo.xhrGet({
        "url": mUrl.toUrl("/account/connected-sites/internal-integration/get-outlook-calendar-connection-info"),
        "handleAs": "json",
        "load": function (data) {
          self._setConnectionNodeValue(self.verifiedTime, data.verified_time, "Verified: ");
          self._setConnectionNodeValue(self.lastVerifiedMessage, data.last_verified_msg, "Last message: ");
          self._setConnectionNodeValue(self.lastDataMessage, data.last_data_msg, "Last data message: ");
          self._setConnectionNodeValue(self.lastDatatime, data.last_data_time, "Last data message sent: ");
          self._setConnectionNodeValue(self.email, data.email, "Connected account: ");
        }
      });
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (!node || data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _setupDisconnectButton: function () {
      domClass.remove(this.integrationButtonNode, "p1 disabled button-loading");
      domClass.add(this.integrationButtonNode, "hide");
      domClass.remove(this.connectionResults, "hide");
      this.integrationButtonNode.innerText = "Disconnect";
      this.own(on(this.integrationButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        if (this.isSyncing) {
          this._showDisconnectDialog();
        } else {
          this._disconnectIntegration();
        }
      })));
    },
    _showDisconnectDialog: function () {
      this.disconnectDialog.show();
    },
    _hideDisconnectDialog: function () {
      this.disconnectDialog.hide();
    },
    _disconnectIntegration: function () {
      this._showLoadingIndicator();
      xhr.post(mUrl.toUrl("/account/integrations/delete"), {
        "data": {
          "sys": 34
        },
        "handleAs": "json"
      }).then(function (response) {
        if (response && response.worked === true) {
          window.ga("send", "event", "connected sites", "platform disconnect", "button", {
            "dimension53": "Disconnect",
            "dimension54": "/account/integrations/delete",
            "dimension55": "Disconnect link on integration detail screen",
            "dimension62": "Microsoft Outlook Calendar"
          });
          window.location.reload();
        }
        self._hideLoadingIndicator();
      });
    },
    _confirmDisconnectIntegration: function () {
      this._hideDisconnectDialog();
      this._disconnectIntegration();
    },
    _showLoadingIndicator: function () {
      // Show the loading icon and hiding the txtbox showing the results
      window.dojo.style(this.testResults, "display", "none");
      window.dojo.style(this.integrationLoading, "display", "block");
    },
    _hideLoadingIndicator: function () {
      // Hide the loading icon and hiding the txtbox showing the results
      window.dojo.style(this.testResults, "display", "block");
      window.dojo.style(this.integrationLoading, "display", "none");
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    _setupConnectButton: function () {
      domClass.remove(this.integrationButtonNode, "disabled button-loading");
      domClass.add(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Connect";
    }
  });
});