/**
 * This handles rendering all the output markup for blocks.
 * Opting to explicitly require each output file to allow dojo's built system to bundle it all together.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/find", "mojo/lib/logger", "mojo/neapolitan/utils", "dojox/dtl", "dojox/dtl/Context", "dojox/dtl/tag/logic",
// modules not listed in arguments
"dojox/dtl/tag/loop", "dojox/dtl/filter/lists", "dojox/dtl/filter/logic", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate"], function (declare, lang, array, query, domConstruct, domClass, domAttr, on, _find, logger, mojoUtils, dtl, dtlContext) {
  return declare("mojo.neapolitan.renderers._Renderer", [], {
    /**
     * Default padding that are passed when rendering the html output
     */
    outerPadding: 9,
    innerPadding: 9,
    /**
     * The template string for the html that this block outputs.
     * You can ignore this and override getContent and do your own rendering.
     */
    outputTplStr: '',
    getMarkup: function (tplModel) {
      var contentDom = this.getContent(tplModel);

      /**
       * If the block has dynamic content configured, we want to insert spans
       * before and after the content that will contain the conditional tags.
       * We can do this by creating a div and appending the prefix tag, original content,
       * and suffix tag.
       * The result will look something like this:
       * <div>
       *     <span class="display:none">*|IF:FNAME=Freddie|*</span>
       *     <table>...</table> // original content
       *     <span class="display:none">*|END:IF|*</span>
       * </div>
       */
      if (tplModel.hasDynamicContent && tplModel.dynamicContentRules) {
        var dynamicContentPrefix;
        if (tplModel.dynamicContentFieldType === "Groups") {
          dynamicContentPrefix = this._buildGroupsPrefix(tplModel);
        } else {
          dynamicContentPrefix = this._buildAudienceFieldsPrefix(tplModel);
        }
        var dynamicContentSuffix = this._buildConditionalSuffix(tplModel);
        var wrapper = domConstruct.create("div");
        domConstruct.place(dynamicContentPrefix, wrapper);
        domConstruct.place(contentDom, wrapper);
        domConstruct.place(dynamicContentSuffix, wrapper);

        // Replace the original contentDom with our new wrapper
        contentDom = wrapper;
      }

      // TODO: can use .outerHTML instead of creating an element, place in, query, return html(), destroy;
      var outerHTML = contentDom.outerHTML;
      var container = domConstruct.create('div');
      domConstruct.place(contentDom, container);
      var htmlText = query(container).html();
      domConstruct.destroy();

      // log if outerHTML is different from htmlText;
      if (outerHTML !== htmlText) {
        logger.info("check-feature-use", "Found inconsistency between innerHTML with created parent and outerHTML", {
          "campaign_id": window.app.campaignId,
          log_classification: "sensitive"
        });
      }
      return htmlText;
    },
    getContent: function (tplModel) {
      if (!this.template) this.template = new dojox.dtl.Template(this.outputTplStr);
      var outputModel = this.getOutputModel(lang.clone(tplModel));
      outputModel = lang.mixin({
        outerPadding: this.outerPadding,
        innerPadding: this.innerPadding
      }, outputModel);
      var dom = domConstruct.toDom(lang.trim(this.template.render(new dtlContext(outputModel))));

      // previewer remove hover styles
      if (window.top.Previewer) {
        if (_find(tplModel.styles, {
          "selector": ".webContent__content .button:hover"
        })) {
          tplModel.styles.splice(4, 1);
        }
      }
      return this._inlineStyles(dom, tplModel.styles);
    },
    getOutputModel: function (tplModel) {
      return tplModel;
    },
    /**
     * Helper function to inline styles to the given dom
     *
     * @param {HTMLElement} rootDom block dom Element
     * @param {Object} styles element styles
     *
     * @returns {HTMLElement} block dom element with inline styles added
     *
     * @private
     */
    _inlineStyles: function (rootDom, styles) {
      if (styles) {
        styles.forEach(function (style) {
          for (var styleProperty in style.props) {
            if (style.props.hasOwnProperty(styleProperty)) {
              var value = style.props[styleProperty].value;
              if (value && value !== "") {
                var selector = style.props[styleProperty].selector || style.selector;
                if (selector === "mcBlockContainer") {
                  query(rootDom).style(mojoUtils.dashToCamel(styleProperty), value);
                } else {
                  query(selector, rootDom).style(mojoUtils.dashToCamel(styleProperty), value);
                }
              }
            }
          }
        });
      }
      return rootDom;
    },
    /**
     * Builds a string representing the closing part of a conditional for Dynamic Content,
     * Audience fields => "*|END:IF|*"
     * Groups => "*|END:INTERESTED|*"
     * @param {object} meta - block properties
     */
    _buildConditionalSuffix: function (meta) {
      var suffix = domConstruct.create("span");
      if (meta.dynamicContentFieldType === "Groups") {
        suffix = "*|END:INTERESTED|*";
      } else {
        suffix = "*|END:IF|*";
      }
      // return "<span style=\"display: none;\">" + suffix + "</span>";
      return domConstruct.create("span", {
        style: {
          display: "none"
        },
        innerHTML: suffix
      });
    },
    /**
     * Builds a string representing the opening of a conditional for Audience Fields
     * Ex: *|IF:FNAME=Freddie|*
     * @param {object} meta - block properties
     */
    _buildAudienceFieldsPrefix: function (meta) {
      var prefix = "*|" + meta.dynamicContentRules.condition + ":" + meta.dynamicContentRules.field + "=" + meta.dynamicContentRules.input + "|*";
      return domConstruct.create("span", {
        style: {
          display: "none"
        },
        innerHTML: prefix
      });
    },
    /**
     * Builds a string representing the opening of a conditional for Groups
     * Ex: *|INTERESTED:GroupTitle:GroupName|*
     * @param {object} meta - block properties
     */
    _buildGroupsPrefix: function (meta) {
      var prefix = "*|INTERESTED:" + meta.dynamicContentRules.field + ":" + meta.dynamicContentRules.input + "|*";
      if (meta.dynamicContentRules.condition === "IFNOT") {
        prefix += "*|ELSE:|*";
      }
      ;
      return domConstruct.create("span", {
        style: {
          display: "none"
        },
        innerHTML: prefix
      });
    }
  });
});