/**
 * Wink Banner
 * @module mojo/widgets/pattern-library/WinkBanner.js
 *
 * Widget used to help redirect users to React/redesign implementation of existing pattern.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/WinkBanner.html"], function (declare, Renderable, tplStr) {
  /** Init props */
  return declare([Renderable], {
    templateString: tplStr,
    message: "",
    title: "Working in React or Redesign?",
    buttonMsg: "Visit Wink",
    buttonLink: "https://designsystem.mailchimp.com/web/getting-started/introduction"
  });
});