define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/layout/TabContainer", "dijit/layout/ContentPane", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/widgets/Renderable", "dojo/text!./templates/ConfigOverlay.html", "./ConfigurationView", "mojo/intent-overlay/AutomationConfigurationView"], function (declare, lang, domConstruct, domClass, on, TabContainer, ContentPane, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, user, flags, logger, Renderable, tpl, ConfigurationView, AutomationConfigurationView) {
  var ConfigurationOverlay = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: tpl,
    blueprint: null,
    optionId: null,
    createButtonLocation: null,
    createFlowStartedAt: null,
    getOptionView: function (option) {
      var View;
      switch (option.id) {
        case "custom":
          View = AutomationConfigurationView;
          break;
        default:
          View = ConfigurationView;
      }
      return new View({
        blueprint: this.blueprint,
        onCancel: this.onClose.bind(this),
        option: option,
        isSelected: option.id === this.optionId,
        createButtonLocation: this.createButtonLocation,
        createFlowStartedAt: this.createFlowStartedAt
      });
    },
    postCreate: function () {
      this.inherited(arguments);
      this.watch("blueprint", lang.hitch(this, function (name, oldValue, value) {
        this.render();
      }));
    },
    buildTabs: function () {
      // If Automated content exists, only show that (à la Classic Automations modal)
      var automatedOption = this.blueprint.options.filter(function (option) {
        return option.title === "Automated";
      })[0];
      var options = automatedOption ? [automatedOption] : this.blueprint.options;
      if (this.tabContainer) {
        this.tabContainer.destroy();
      }
      this.tabContainer = new TabContainer({
        doLayout: false
      }, this.configView);
      options.forEach(lang.hitch(this, function (option) {
        if (option && option.isAvailable) {
          var tab = new ContentPane({
            optionId: option.id,
            title: option.title,
            content: this.getOptionView(option),
            isAvailable: option.isAvailable,
            selected: option.id === this.optionId
          });
          this.tabContainer.addChild(tab);
        }
      }));
      this.tabContainer.startup();
      setTimeout(lang.hitch(this, function () {
        if (automatedOption) {
          // Hide the tablist if Automated content exists
          this.tabContainer.tablist.destroy();
        } else {
          this.tabContainer.resize();
          domClass.add(this.tabContainer.tablist.domNode, "text-align--center noborder");
          domClass.add(this.tabContainer.tablist.containerNode, "full-width");
        }
      }), 0);
      this.tabContainer.watch("selectedChildWidget", lang.hitch(this, function (name, oldVal, newVal) {
        this.onTabChange(newVal.optionId);
      }));
    },
    render: function () {
      domConstruct.empty(this.configView);
      if (this.blueprint) {
        this.buildTabs();
      }
    }
  });
  return ConfigurationOverlay;
});