define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/logger", "mojo/analytics/parts/_Part", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/CampaignGroupConnectedSwitch.html"], function (declare, lang, on, user, logger, _Part, _TemplatedMixin, _WidgetInTemplateMixin, templateString) {
  return declare([_Part, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateString,
    init: function () {
      this.groupConnectedCampaigns.setChecked(this.checked);
      on(this.groupConnectedCampaigns, "change", lang.hitch(this, function (isChecked) {
        var campaignsList = this.target;
        campaignsList.setGroupConnectedCampaigns(isChecked);
        campaignsList.render();
        logger.googleAnalytics.trackEvent("group_connected_campaigns", "toggle", isChecked ? "on" : "off");
        user.saveSetting("group_connected_campaigns", isChecked);
      }));
    }
  });
});