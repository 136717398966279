define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "mojo/widgets/Renderable", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/editor-reminder-in-nea.html", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, on, velocity, Renderable, domClass, tplStr, mUrl, xhr) {
  return declare([Renderable], {
    templateString: tplStr,
    campaignId: null,
    handleEditorSelection: function () {
      if (this.reminderContainer) {
        domClass.add(this.reminderContainer, "faded no-clicky");
      }
      xhr.post(mUrl.toUrl("/campaigns/handle-editor-selection"), {
        'handleAs': 'json',
        'data': {
          'id': this.campaignId,
          'inlineEditor': true,
          'startNewCampaign': true
        }
      }).then(lang.hitch(this, function (data) {
        if (data.status === "success") {
          window.top.location.href = data.redirect;
        }
        if (this.reminderContainer) {
          domClass.remove(this.reminderContainer, "faded no-clicky");
        }
      }));
    },
    dismissReminder: function () {
      xhr.post("/campaigns/dismiss-editor-reminder").then(lang.hitch(this, this.dismissBanner));
    },
    dismissBanner: function () {
      var iframeDoc = dojo.byId('preview-template-container');
      if (iframeDoc) {
        domClass.remove(iframeDoc, 'eee-notification-vertical-space');
      }
      velocity(this.reminderContainer, {
        opacity: [0, 1],
        maxHeight: [0, 300]
      }, {
        duration: 400,
        display: "none"
      });
    }
  });
});