/**
 * Widget responsible for managing the duration of the ad. This widget should be able to manage
 * values passed in at instantiation or via the setBudget method.
 *
 * @module mojo/widgets/ads/facebook/edit-budget/DurationInput
 * @see mojo/widgets/ads/facebook/EditBudgetSlat
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "mojo/user", "./DateTimeInput", "vendor/lodash-amd/find",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin",
// Templates
"dojo/text!./DurationInput.html"], function (declare, lang, domClass, on, Select, user, DateTimeInput, find, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    budget: null,
    currencySymbol: user.currencySymbol,
    // Collection of all valid durations. Seconds is what's stored in the DB and days
    // is used to calculate required minimums.
    relativeDurations: [{
      label: "1 day",
      inputValue: "one_day",
      seconds: 86400,
      days: 1
    }, {
      label: "1 week",
      inputValue: "one_week",
      seconds: 604800,
      days: 7
    }, {
      label: "2 weeks",
      inputValue: "two_weeks",
      seconds: 1209600,
      days: 14
    }, {
      label: "1 month",
      inputValue: "one_month",
      seconds: 2592000,
      days: 30
    }],
    /**
     * Initial load of the widget and dom nodes.
     */
    postCreate: function () {
      this._setup();
    },
    /**
     * Public function used to notify this widget of changes to the budget.
     *
     * @param {number} newBudget - The value being passed from the parent widget on change.
     */
    setBudget: function (newBudget) {
      // Close dropdown in case it was opened before typing
      this.durationInput.closeDropDown();
      // Update the widget-level reference
      this.budget = newBudget;
      // Reload the whole duration dropdown again. This gives feedback to what duration folks are allowed.
      this._buildDurationDropdown();
      this._updatePerDayLabel();
    },
    /**
     * Public function to retrieve all of the pertinent values associated with the duration input. Designed to be
     * aware of relative durations and formatted for consumption by the backend.
     *
     * @return {obj} User-selected duration settings
     */
    getValues: function () {
      return {
        duration: this._getDurationValue(),
        "start_date": this.startDateTime.getDate(),
        "start_time": this.startDateTime.getTime(),
        "start_time_meridian": this.startDateTime.getTimeMeridian(),
        "end_date": this.endDateTime.getDate(),
        "end_time": this.endDateTime.getTime(),
        "end_time_meridian": this.endDateTime.getTimeMeridian()
      };
    },
    /**
     * Load all of the various details necessary at startup time.
     *
     * @private
     */
    _setup: function () {
      // Create start and end DateTime inputs.
      this.startDateTime = new DateTimeInput({
        label: "Start",
        dateTime: this.startTime
      });
      this.startDateTime.placeAt(this.startDateTimeContainer);
      this.startDateTime.startup();
      this.endDateTime = new DateTimeInput({
        label: "End",
        dateTime: this.endTime
      });
      this.endDateTime.placeAt(this.endDateTimeContainer);
      this.endDateTime.startup();

      // Initial loading of the select box based on values at instantiation
      this._buildDurationDropdown();
      this._handleDurationChange();
    },
    /**
     * Create a select input with all of the appropriate options and attach handler.
     *
     * @private
     */
    _buildDurationDropdown: function () {
      // Clear out the existing widget if there is one but save the input value.
      var prevSelection = null;
      if (this.durationInput) {
        prevSelection = this._getDurationValue();
        this.durationInput.destroy();
      }

      // Create new Select input and load in the dom
      this.durationInput = new Select({
        // Fill all the appropriate options
        options: this._getOptions(),
        // We're hiding the select box by default. Give the popup something to target to.
        _aroundNode: this.editLink
      });
      this.durationInput.placeAt(this.selectContainer);
      this.durationInput.startup();

      // Set duration to any previous value the user might have had.
      // TODO: Handle if the previously selected value is no longer a valid option.
      if (prevSelection) {
        this.durationInput.set("value", prevSelection);
      }
      this._updateSummary();
      on(this.durationInput, "change", lang.hitch(this, "_handleDurationChange"));
    },
    /**
     * Show/hide the custom duration inputs based on the input selection. Also update some widget-level
     * values.
     *
     * @private
     */
    _handleDurationChange: function () {
      var isCustomSelected = this._getDurationValue() === "custom";
      this.isRelative = !isCustomSelected;
      domClass.toggle(this.customDurationContainer, "hide", !isCustomSelected);
      this._updateSummary();
      this._updatePerDayLabel();
    },
    /**
     * On click of the edit link, toggle visibility of the select dropdown. Called from within the template
     *
     * @private
     */
    _handleEditLink: function () {
      this.durationInput.toggleDropDown();
    },
    /**
     * We want this to be very human readable. As such, show a little summary of what the user has selected
     * as a duration. If they don't meet the minimum budget, give them feedback.
     *
     * @private
     */
    _updateSummary: function () {
      // Toggle visibility of sections if budget minimums aren't met.
      domClass.toggle(this.minMessaging, "hide", this.budget >= this.minDailyBudget);
      domClass.toggle(this.summaryContainer, "hide", this.budget < this.minDailyBudget);
      domClass.toggle(this.customDurationContainer, "disabled-container", this.budget < this.minDailyBudget);
      var newSummary = "";
      switch (this._getDurationValue()) {
        case "custom":
          newSummary = "Choose ad duration";
          break;
        default:
          newSummary = "for <strong>" + this._getDurationLabel() + "</strong>";
          break;
      }
      this.summary.innerHTML = newSummary;
    },
    /**
     * Provide some indicator how much folks would be spending per day given the duration they've selected.
     * Only show if they have a relative date and if they meet minimums.
     *
     * @private
     */
    _updatePerDayLabel: function () {
      if (this.isRelative && this.budget >= this.minDailyBudget) {
        // Cross-reference the currently selected option with this.relativeDurations
        // to retrieve the number of days.
        var numDays = find(this.relativeDurations, ["inputValue", this._getDurationValue()]).days;
        var perDay = (Number(this.budget) / numDays).toFixed(2);
        var meetsMinimum = this.budget >= this.minDailyBudget * numDays;
        var showLabel = meetsMinimum && numDays !== 1;

        // Update the node
        this.perDayLabel.innerText = showLabel ? "Up to " + this.currencySymbol + perDay + " / day" : "";
      } else {
        // Empty the contents for absolute or no value
        this.perDayLabel.innerText = "";
      }
    },
    /**
     * Get the string value of the currently selected duration option. We're relying on aria-label since
     * there's a good change there's HTML within the label. See _getLabelMarkup().
     *
     * @return {string} label - Label of the currently selected option within the duration input.
     * @private
     */
    _getDurationLabel: function () {
      // _getSelectedOptionsAttr() is a function available through the _FormSelectWidget mixin
      return this.durationInput._getSelectedOptionsAttr()["aria-label"];
    },
    /**
     * Simple method to normalize the approach in which to get the currently selected duration.
     *
     * @return {string} select value
     * @private
     */
    _getDurationValue: function () {
      return this.durationInput.value;
    },
    /**
     * Generate all of the appropriate options for the duration dropdown and handle selection.
     *
     * @return {Array} options - Formatted as a collection for consumption by a Select widget.
     */
    _getOptions: function () {
      var durations = this.relativeDurations.map(lang.hitch(this, function (duration) {
        return {
          label: this._getLabelMarkup(duration),
          "aria-label": duration.label,
          value: duration.inputValue,
          // endTime is a new Date obj while duration.seconds is stored in PHP time. Account for that.
          selected: !!(this.isRelative && duration.seconds === this.endTime / 1000),
          disabled: this._isOptionDisabled(duration)
        };
      }));
      durations.push({
        label: "Specific duration",
        value: "custom",
        selected: !this.isRelative
      });
      return durations;
    },
    /**
     * Generate the HTML for the label of each option. If appropriate, show minimum budget needed to be met.
     *
     * @param {obj} duration - All of the settings/details around a particular placement.
     * @return {string} - HTML or simple string necessary to display a label.
     */
    _getLabelMarkup: function (duration) {
      // Do necessary math and massage data
      var labelValues = {
        label: duration.label,
        minimumBudget: (this.minDailyBudget * duration.days).toFixed(2)
      };

      // Decide whether to show a simple label or the HTML equivalent
      return this._isOptionDisabled(duration) ? lang.replace(this.tplLabelWithMinimum.innerHTML, labelValues) : duration.label;
    },
    /**
     * Simple method to determine whether or not an option within the duration dropdown
     * should be treated as disabled.
     *
     * @param {obj} duration - Formatted like so: {label: "1 day", inputValue: "one_day", seconds: 86400, days: 1}
     * @return {boolean} is disabled?
     * @private
     */
    _isOptionDisabled: function (duration) {
      return this.budget < this.minDailyBudget * duration.days;
    }
  });
});