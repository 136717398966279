define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "d3/d3"], function (declare, _WidgetBase, lang, dom, query, domConstruct, utils, d3) {
  return declare([_WidgetBase], {
    animDuration: 1333,
    // ms
    animDelay: 500,
    //ms
    numDecimals: 0,
    // Number of digits after the decimal point
    easing: "linear",
    startVal: 0,
    //default
    endVal: 100,
    //default,  from dom element

    formatNumber: ",",
    animateOnVisibility: true,
    changeOnWatch: true,
    postMixInProperties: function () {
      this.startVal = this.startVal.toString();
    },
    buildRendering: function () {
      this.inherited(arguments);
      this.endVal = this.domNode.innerHTML;
      this.domNode.innerHTML = this.startVal;
    },
    animateNumber: function () {
      var self = this;
      var starting = self.startVal;
      var ending = self.endVal;
      d3.select(this.domNode).transition().ease(self.easing).duration(this.animDuration).delay(this.animDelay).tween("text", function () {
        var domValue = d3.select(this);
        var i = d3.interpolate(starting, ending);
        return function (t) {
          var progress = parseFloat(i(t));
          domValue.text(d3.format(self.formatNumber)(progress.toFixed(self.numDecimals)));
          self.changeOnWatch = true;
          self.startVal = ending;
        };
      });
      self.watch("endVal", function () {
        if (self.changeOnWatch) {
          self.changeOnWatch = false;
          self.animateNumber();
        }
      });
    },
    postCreate: function () {
      var self = this;
      if (self.animateOnVisibility) {
        utils.onVisibility(self.domNode, lang.hitch(self, self.animateNumber));
      } else {
        self.animateNumber();
      }
    }
  }); // eo declare
}); // eo define