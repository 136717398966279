define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, base) {
  return declare([base], {
    onReady: function () {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "e_pageLoad",
          destination: "list_detail",
          user_id: this.user_id,
          login_id: this.login_id,
          list_id: this.list_id
        });
      }
    }
  });
});