define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/Facebook.html"], function (declare, lang, xhr, domClass, domAttr, on, mUrl, mPusher, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    integrationButtonNode: null,
    fbPageSelector: null,
    integrationInstallStatus: null,
    appInstallUrl: null,
    errorMessage: null,
    outageMessage: null,
    //Only initiate the pages once, we don't want duplicates
    pagesInitiated: false,
    _setAppInstallUrlAttr: {
      node: "integrationButtonNode",
      type: "attribute",
      attribute: "href"
    },
    postCreate: function () {
      this._checkForError();
      if (this.integrationInstallStatus === true) {
        this._setupDisconnectButton();
      }
      this.own(on.once(this.disconnectButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._disconnectIntegration(14);
      })));
      this.own(on(this.testButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._testConnection();
      })));
      this._loadData();
    },
    _checkForError: function () {
      if (this.errorMessage != "") {
        this.errorContainer.innerHTML = this.errorMessage;
        domClass.remove(this.errorContainer, "hide");
      }
      if (this.outageMessage != "") {
        this.outageMessageContainer.innerHTML = this.outageMessage;
        domClass.remove(this.outageMessageContainer, "hide");
      }
    },
    _testConnection: function () {
      var self = this;
      dojo.xhrPost({
        "url": mUrl.toUrl("/account/integrations/facebook-test"),
        "content": {},
        "handleAs": "text",
        "load": function (data) {
          var txtbox = self.testResults;
          var loading = self.integrationLoading;
          dojo.style(loading, "display", "block");
          dojo.style(loading, "display", "none");
          dojo.style(txtbox, "display", "block");
          txtbox.innerHTML = data;
        }
      });
    },
    _loadData: function () {
      var self = this;
      dojo.xhrGet({
        "url": mUrl.toUrl("/account/connected-sites/internal-integration/get-facebook-connection-info"),
        "handleAs": "json",
        "load": function (data) {
          domAttr.set(self.appSettingsLink, "href", data.app_settings_link);
          self._setConnectionNodeValue(self.facebookUsername, data.facebook_username, "Connected As: ");
          self._setConnectionNodeValue(self.verifiedTime, data.verified_time, "Verified: ");
          self._setConnectionNodeValue(self.lastVerifiedMessage, data.last_verified_msg, "Last message: ");
          self._setConnectionNodeValue(self.lastDataMessage, data.last_data_msg, "Last data message: ");
          self._setConnectionNodeValue(self.lastDatatime, data.last_data_time, "Last data message sent: ");
        }
      });
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _setupDisconnectButton: function () {
      domClass.remove(this.integrationButtonNode, "p1 disabled button-loading");
      domClass.add(this.integrationButtonNode, "hide");
      this._loadData();
      domClass.remove(this.connectionResults, "hide");
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      domClass.remove(this.inProgressNode, "display--none");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    _setupConnectButton: function () {
      domClass.remove(this.integrationButtonNode, "disabled button-loading");
      domClass.add(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Connect";
    },
    _disconnectIntegration: function (sys_id) {
      query = {
        'sys': sys_id
      };
      // eslint-disable-next-line no-undef
      lang.hitch(this, dojo.xhrPost({
        'url': mUrl.toUrl('/account/integrations/delete'),
        'content': query,
        'handleAs': 'json',
        'load': lang.hitch(this, function (data) {
          if (data["worked"] === true) {
            dojo.style(this.fbPageSelector, 'display', 'none');
          }
        })
      }));
      window.ga('send', 'event', 'connected sites', 'platform disconnect', 'button', {
        'dimension53': 'Disconnect',
        'dimension54': '/account/integrations/delete',
        'dimension55': 'Disconnect link on integration detail screen',
        'dimension62': 'Facebook'
      });
      this._displayWaiting();
      location.reload();
    },
    _initFacebookPagesSelector: function () {},
    _registerListDisplayHandler: function () {
      this.own(on(dojo.byId('fb_page_list_id'), "change", lang.hitch(this, "_fbListDisplay")));
    },
    /**
     * Fetch and mUrl wrapper
     *
     * @param {String} url - url to fetch
     * @param {Object} query - object added as query params
     * @return {Promise} promise object
     */
    _fetch: function (url, query) {
      return xhr.get(mUrl.toUrl(url, query), {
        handleAs: "json"
      });
    }
  });
});