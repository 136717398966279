define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/form/DropDownButton", "mojo/utils",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/tags.html",
//Widgets
"mojo/widgets/tags/TagBadge", "mojo/widgets/tags/TagOverlay",
//API
"../tags/Api"], function (declare, lang, keys, on, domConstruct, domClass, query, registry, DropDownButton, utils, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, ContactProfileEvent, tagsTplString, TagBadge, TagOverlay, api) {
  var MAX_RENDER_TAGS = 20;
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tagsTplString,
    memberID: null,
    listID: null,
    contactTagsList: [],
    listTags: [],
    taggingButton: null,
    tagOverlay: null,
    tagBadgeHandles: [],
    isArchived: false,
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      api.getContactTags(this.listID, this.memberID, lang.hitch(this, "hydrate"));
      domClass.remove(this.taggingButtonContainer, "hide");
      if (this.isArchived) {
        domClass.add(this.taggingButtonContainer, "hide");
      }
      this.tagOverlay = new TagOverlay({
        "tags": this.listTags,
        "listId": this.listID,
        "tagCreationAllowed": true,
        "analyticsData": "Contact Profile"
      });

      //Set up the Assign Tags button as a DropDownButton
      this.taggingButton = new DropDownButton({
        label: "<span class='freddicon plus padding-left--lv0'></span>",
        dropDown: this.tagOverlay,
        dropDownPosition: ["below"]
      }, this.taggingButton);
      this.taggingButton.startup();
      on(this.taggingButton, "click", lang.hitch(this, "handleTagButtonClick"));
      this.setupListeners();
      this.updateTagOverlay();
    },
    /**
     * Show the tags associated with this contact
     */
    populateCurrentTags: function () {
      this.destroyCurrentTags();
      if (this.contactTagsList.length < 1) {
        domConstruct.empty(this.contactTagsListContainer);
        domClass.remove(this.noContactTags, "hide");
      } else {
        domClass.add(this.noContactTags, "hide");
        this.contactTagsList.forEach(lang.hitch(this, function (tag, index) {
          if (index < MAX_RENDER_TAGS) {
            this.appendTagBadge(tag);
          }
        }));
        if (this.contactTagsList.length > MAX_RENDER_TAGS) {
          domClass.remove(this.showRemainingTags, "hide");
          this.remainingTagCount.innerText = this.contactTagsList.length - MAX_RENDER_TAGS;
        }
      }
    },
    /**
     * Render the remaining contact tags on click of the "+ X more" button
     */
    renderRemainingTags: function () {
      this.contactTagsList.forEach(lang.hitch(this, function (tag, index) {
        if (index >= MAX_RENDER_TAGS) {
          this.appendTagBadge(tag);
        }
      }));
      domClass.add(this.showRemainingTags, "hide");
      this.remainingTagCount.innerText = "";
    },
    /**
     * Append an individual tag to the tag badge list
     * @param {object} tag to append
     */
    appendTagBadge: function (tag) {
      var tagBadge = new TagBadge({
        "tag": tag,
        "tagName": tag.tag_name,
        "readOnly": this.isArchived
      });
      tagBadge.placeAt(this.contactTagsListContainer, "last");
      var tagHandle = on(tagBadge, "remove", lang.hitch(this, "removeTagFromContact"));
      this.tagBadgeHandles.push(tagHandle);
    },
    /**
     * Clear out all tag badge DOM nodes and event handles
     */
    destroyCurrentTags: function () {
      domConstruct.empty(this.contactTagsListContainer);
      this.tagBadgeHandles.forEach(function (handle) {
        handle.remove();
      });
      this.tagBadgeHandles = [];
    },
    /**
     * Set up all event listeners and subscriptions
     */
    setupListeners: function () {
      on(this.tagOverlay, "applyTag", lang.hitch(this, "applyTagToContact"));
      on(this.tagOverlay, "removeTag", lang.hitch(this, "removeTagFromContact"));
      on(this.tagOverlay, "createTag", lang.hitch(this, "createTag"));
      on(this.showRemainingTags, "click", lang.hitch(this, "renderRemainingTags"));
      api.watchTags(lang.hitch(this, function (tags) {
        this.listTags = tags;
      }), this.listID);
    },
    updateTagOverlay: function () {
      var contactTagIDs = [];
      this.contactTagsList.forEach(function (contactTag) {
        contactTagIDs.push(contactTag.tag_id);
      });
      this.populateCurrentTags();
      this.tagOverlay.setSelection({
        "all": contactTagIDs,
        "mixed": []
      });
    },
    handleTagButtonClick: function () {
      var event = new ContactProfileEvent("audience:engaged", {
        action: "engaged",
        ui_object: "button",
        ui_object_detail: "+",
        ui_action: "clicked",
        ui_access_point: "right_column",
        event_origin: "dojo"
      });
      event.publishEvent();
    },
    /**
     * Make API call to apply tag to selected contacts
     * @param {object} tag Tag to apply
     * @private
     */
    applyTagToContact: function (tag) {
      api.applyTagToContacts(this.listID, [this.memberID], tag.tag_id, lang.hitch(this, "successCallback"));
    },
    /**
     * Make API call to remove tag from selected contacts
     * @param {object} tag Tag to remove
     * @private
     */
    removeTagFromContact: function (tag) {
      api.removeTagFromContacts(this.listID, [this.memberID], tag.tag_id, lang.hitch(this, "successCallback"));
    },
    /**
     * Make API call to create new tag and apply it to selected contacts
     * @private
     */
    createTag: function () {
      api.createTagApplyToContacts(this.listID, this.tagOverlay.getInputValue(), [this.memberID], lang.hitch(this, "successCallback"));
    },
    /**
     * Generic success callback for updates to contact's tags
     * Show a toast message and re-hydrate the widget
     * @param {object} response Api response
     */
    successCallback: function () {
      api.getContactTags(this.listID, this.memberID, lang.hitch(this, "hydrate"));
      utils.toast("Successfully updated contact's tags");
      this.taggingButton.closeDropDown();
      this.tagOverlay.setLoadingState(true);
    },
    /**
     * Fetch the contact's tags and refresh the widget
     * @param {object} response Api Response
     */
    hydrate: function (response) {
      this.contactTagsList = response.contact_tags;
      this.updateTagOverlay();
    }
  });
});