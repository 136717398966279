define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/StaticInlineRec.html",
// Mojo
"mojo/url", "mojo/user", "mojo/lib/constants", "mojo/lib/flags"], function (declare, domAttr, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, StaticInlineRecTemplateString, mUrl, mUser, constants, flags) {
  /**
   * @module mojo/widgets
   * @class StaticInlineRec
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: StaticInlineRecTemplateString,
    smartMarkImageUrl: null,
    smartMarkImageAltText: null,
    title: null,
    body: null,
    ctaText: null,
    ctaUrl: null,
    variation: null,
    location: null,
    // Passed in through widget declaration
    mc_data: [],
    /**
    * Perform appropriate setup actions
    */
    postCreate: function () {
      this.inherited(arguments);
      this.connect(this.cta, "click", function () {
        var campaignsL0IsOn = mUser.flagIsOn(flags.WEF_LEFT_NAV_V2) && this.mc_data && this.mc_data["left_nav_v2_experiment_active"];
        // Only track this event for campaign l0
        if (campaignsL0IsOn && this.location === "campaigns") {
          if (this.variation === constants.STATIC_INLINE_REC_CAMPAIGN_MANAGER) {
            window.ga("send", "event", "campaigns", this.ctaText, "select");
          } else {
            window.ga("send", "event", "campaigns", "save this segment", "select");
          }
        }
        window.open(mUrl.toUrl(this.ctaUrl), "_self");
      });

      // Handle dismiss CTA
      var dismissRec = function (e) {
        if (e.type === "click" || e.type === "keydown" && ["Enter", "Spacebar", " "].includes(e.key)) {
          var campaignsL0IsOn = mUser.flagIsOn(flags.WEF_LEFT_NAV_V2) && this.mc_data && this.mc_data["left_nav_v2_experiment_active"];
          // Only track this event for campaign l0
          if (campaignsL0IsOn && this.location === "campaigns") {
            window.ga("send", "event", "campaigns", "save this segment", "dismiss");
          }
          if (this.variation) {
            switch (this.variation) {
              case constants.STATIC_INLINE_REC_CAMPAIGN_MANAGER:
                mUser.saveSetting(constants.STATIC_INLINE_REC_CAMPAIGN_MANAGER, "true");
                break;
              case constants.STATIC_INLINE_REC_CJB:
                mUser.saveSetting(constants.STATIC_INLINE_REC_CJB, "true");
                break;
              case constants.STATIC_INLINE_REC_INTEGRATIONS:
                mUser.saveSetting(constants.STATIC_INLINE_REC_INTEGRATIONS, "true");
                break;
              default:
                break;
            }
          }
          this.destroy();
        }
      };
      this.connect(this.dismissCta, "click", dismissRec);
      this.connect(this.dismissCta, "keydown", dismissRec);
    }
  });
});