define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dijit/Dialog", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/ConfirmOverwriteBrandLogoDialog.html"], function (declare, event, lang, _Widget, _Templated, Dialog, on, domAttr, keys, Deferred, tpl) {
  return declare([_Widget, _Templated], {
    templateString: tpl,
    dialog: null,
    deferred: null,
    postCreate: function () {
      this.deferred = new Deferred();
      var confirmSignal = on(this.confirmButton, "click", function () {
        confirmSignal.remove();
        this.resolve();
      }.bind(this));
      var cancelSignal = on(this.cancelButton, "click", function () {
        cancelSignal.remove();
        this.cancel();
      }.bind(this));
    },
    execute: function () {
      this.resolve();
    },
    resolve: function () {
      this.deferred.resolve();
      this.dialog.hide();
    },
    cancel: function () {
      this.deferred.cancel();
      this.dialog.hide();
    },
    getPromise: function () {
      return this.deferred.promise;
    },
    show: function () {
      this.dialog = new Dialog({
        "title": "Update default logo",
        "content": this
      });
      this.dialog.startup();
      this.dialog.show();
    }
  });
});