define(['./_baseCreate', './_getPrototype', './_isPrototype'], function(baseCreate, getPrototype, isPrototype) {

  /**
   * Initializes an object clone.
   *
   * @private
   * @param {Object} object The object to clone.
   * @returns {Object} Returns the initialized clone.
   */
  function initCloneObject(object) {
    return (typeof object.constructor == 'function' && !isPrototype(object))
      ? baseCreate(getPrototype(object))
      : {};
  }

  return initCloneObject;
});
