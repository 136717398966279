define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, query, dom, domStyle, on, xhr, ready, registry, domClass) {
  return declare([base], {
    onReady: function () {
      this.checkedLists = this.numberChecked();
      this.numLists = Object.keys(this.$widget).length;
      if (this.checkedLists === this.numLists) {
        this.showDeselect.call(this);
      }

      // listen for change events on all of the checkboxes
      for (var el in this.$widget) {
        if (!this.$widget.hasOwnProperty(el)) {
          continue;
        }
        this.$widget[el].on("change", this.onCheckChange.bind(this));
      }

      // listen for click on select all and deselect all links
      on(this.$el.selectAllLists, "click", this.selectAll.bind(this));
      on(this.$el.deselectAllLists, "click", this.deselectAll.bind(this));
      window.dataLayer.push({
        event: "e_pageLoad",
        destination: "list_detail"
      });
    },
    numberChecked: function () {
      var total = 0;
      for (var el in this.$widget) {
        if (!this.$widget.hasOwnProperty(el)) {
          continue;
        }
        var isChecked = Boolean(this.$widget[el].get("checked"));
        if (isChecked) {
          total += 1;
        }
      }
      return total;
    },
    showSelect: function () {
      domStyle.set(this.$el.selectAllLists, {
        "display": "block"
      });
      domStyle.set(this.$el.deselectAllLists, {
        "display": "none"
      });
    },
    showDeselect: function () {
      domStyle.set(this.$el.selectAllLists, "display", "none");
      domStyle.set(this.$el.deselectAllLists, "display", "block");
    },
    selectAll: function () {
      for (var el in this.$widget) {
        if (!this.$widget.hasOwnProperty(el)) {
          continue;
        }
        this.$widget[el].set("checked", true);
      }
      this.showDeselect.call(this);
    },
    deselectAll: function () {
      for (var el in this.$widget) {
        if (!this.$widget.hasOwnProperty(el)) {
          continue;
        }
        this.$widget[el].set("checked", false);
      }
      this.showSelect.call(this);
    },
    onCheckChange: function (isChecked) {
      // track number of checked lists
      if (isChecked) {
        this.checkedLists += 1;
      } else {
        this.checkedLists -= 1;
      }

      // if none checked -> show select all
      if (this.checkedLists === 0) {
        this.showSelect.call(this);
      } else if (this.checkedLists === this.numLists) {
        this.showDeselect.call(this);
      }
    }
  });
});