define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/peaches2/tabContainerUtil", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, tabContainerUtil, p2Utils, topic, registry, FilterMenu, FilterMessage, dom, domAttr, on, domClass, utils, mUrl, xhr) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    sectionId: null,
    onReady: function () {
      var self = this;
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      tabContainer.container.watch("selectedChildWidget", function (name, oldValue, newValue) {
        // tabContainerUtil only sets the hash param but we'd like to update the whole URL
        window.history.replaceState({
          'href': newValue.params.href
        }, "", newValue.params.href);
      });
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "journey-details" || mUrl.getHashParams().t === "journey-details-section") {
        var workflowIdFromUrl = new URL(window.location.href).searchParams.get("workflow_id");
        if (workflowIdFromUrl) {
          this.viewJourneyDetails(workflowIdFromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewJourneyDetails(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "customer-journeys-queue") {
        var workflowIdFromUrl = new URL(window.location.href).searchParams.get("workflow_id");
        if (workflowIdFromUrl) {
          this.viewJourneyQueue(workflowIdFromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewJourneyQueue(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "journey-logs" || mUrl.getHashParams().t === "journey-logs-section") {
        var workflowIdFromUrl = new URL(window.location.href).searchParams.get("workflow_id");
        if (workflowIdFromUrl) {
          this.viewJourneyLogs(workflowIdFromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewJourneyLogs(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "history" || mUrl.getHashParams().t === "history-section") {
        var workflowIdFromUrl = new URL(window.location.href).searchParams.get("workflow_id");
        var contactIdFromUrl = new URL(window.location.href).searchParams.get("contact_id");
        if (workflowIdFromUrl) {
          // eslint-disable-next-line block-scoped-var
          this.viewJourneyHistory(workflowIdFromUrl, contactIdFromUrl);
          return false;
        }
      }
    },
    /**
     *  opens journey details tab
     * @param workflow_id
     */
    viewJourneyDetails: function (workflow_id) {
      var self = this;
      var newPaneObj = {
        title: "Customer Journey Details: " + workflow_id,
        href: mUrl.toUrl("/peaches2/users/customer-journeys", {
          _s: "journey-details",
          user_id: self.userAttrs.userId,
          workflow_id: workflow_id
        }),
        stub: "customer-journeys-details",
        closable: true,
        onClose: function () {
          self.tabContainer.customerJourneysDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, 'details' + workflow_id);
      var topicHandle = topic.subscribe("mojo/analytics/List/journeys-details-list/render", lang.hitch(this, function (list) {
        this.ctx.loadFilter('customer-journeys-details');
        this.ctx.topicHandle.remove();
      }.bind({
        ctx: this
      })));
      this.topicHandle = topicHandle;
    },
    /**
     * opens journey queue tab
     * @param workflow_id
     */
    viewJourneyQueue: function (workflow_id) {
      var self = this;
      var newPaneObj = {
        title: "Customer Journey Queue: " + workflow_id,
        href: mUrl.toUrl("/peaches2/users/customer-journeys", {
          _s: "customer-journeys-queue",
          user_id: self.userAttrs.userId,
          workflow_id: workflow_id
        }),
        stub: "customer-journeys-queue",
        closable: true,
        onClose: function () {
          self.tabContainer.customerJourneysQueueSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, 'queue' + workflow_id);
      var topicHandle = topic.subscribe("mojo/analytics/List/journeys-contacts-list/render", lang.hitch(this, function (list) {
        this.ctx.loadFilter('customer-journeys-queue');
        this.ctx.topicHandle.remove();
      }.bind({
        ctx: this
      })));
      this.topicHandle = topicHandle;
    },
    /**
     * opens journey logs tab
     * @param workflow_id
     */
    viewJourneyLogs: function (workflow_id) {
      var self = this;
      var newPaneObj = {
        title: "Customer Journey Logs: " + workflow_id,
        href: mUrl.toUrl("/peaches2/users/customer-journeys", {
          _s: "journey-logs",
          user_id: self.userAttrs.userId,
          workflow_id: workflow_id
        }),
        stub: "customer-journeys-logs",
        closable: true,
        onClose: function () {
          self.tabContainer.customerJourneysLogsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, "logs" + workflow_id);
      var topicHandle = topic.subscribe("mojo/analytics/List/journeys-logs-list/render", lang.hitch(this, function (list) {
        this.ctx.loadFilter("customer-journeys-logs");
        this.ctx.topicHandle.remove();
      }.bind({
        ctx: this
      })));
      this.topicHandle = topicHandle;
    },
    /**
     * opens journey contact history tab
     * @param workflow_id
     * @param contact_id
     */
    viewJourneyHistory: function (workflow_id, contact_id) {
      var self = this;
      var newPaneObj = {
        title: "Contact History for " + contact_id + " in Journey " + workflow_id,
        href: mUrl.toUrl("/peaches2/users/customer-journeys", {
          _s: "history",
          user_id: self.userAttrs.userId,
          workflow_id: workflow_id,
          contact_id: contact_id
        }),
        stub: "customer-journeys-history",
        closable: true,
        onClose: function () {
          self.tabContainer.customerJourneysHistorySection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, "history" + workflow_id);
      var topicHandle = topic.subscribe("mojo/analytics/List/journeys-history-list/render", lang.hitch(this, function (list) {
        this.ctx.loadFilter("customer-journeys-history");
        this.ctx.topicHandle.remove();
      }.bind({
        ctx: this
      })));
      this.topicHandle = topicHandle;
    },
    openJourneyTool: function (e) {
      var value = e.attr("value");
      e.reset();
      value = value.split("_");
      var action = value[0];
      var workflowId = value[1];
      switch (action) {
        case 'details':
          this.viewJourneyDetails(workflowId);
          return;
        case 'logs':
          this.viewJourneyLogs(workflowId);
          return;
        case 'queue':
          this.viewJourneyQueue(workflowId);
          return;
        case 'pause':
          if (window.confirm('Are you sure you want to pause the journey?')) {
            window.location = mUrl.toUrl("/peaches2/pager/customer-journeys/pause", {
              user_id: this.userAttrs.userId,
              workflow_id: workflowId
            });
          }
          return;
        case 'start':
          if (window.confirm('Are you sure you want to start the journey?')) {
            window.location = mUrl.toUrl("/peaches2/pager/customer-journeys/start", {
              user_id: this.userAttrs.userId,
              workflow_id: workflowId
            });
          }
          return;
        case 'recalculate':
          if (window.confirm('Are you sure you want to recalculate the stats? (WARNING: only do this if stats are inaccurate and you have waited at least 5 minutes).')) {
            window.location = mUrl.toUrl("/peaches2/pager/customer-journeys/recalculate-stats", {
              user_id: this.userAttrs.userId,
              workflow_id: workflowId
            });
          }
          return;
      }
    },
    loadFilter: function (section) {
      var filter = null;
      var filterMessage = null;
      if (section === "customer-journeys") {
        var journeyFilterSettings = {
          "filters": [{
            "label": "Status",
            "key": "status",
            "type": "select",
            "options": {
              "sending": "Sending",
              "draft": "Draft",
              "paused": "Paused"
            }
          }, {
            "label": "List ID",
            "key": "list_id",
            "type": "text"
          }, {
            "label": "Created since...",
            "key": "created_at",
            "type": "select",
            "options": {
              "1": "One day ago",
              "2": "Two days ago",
              "7": "One week ago"
            }
          }]
        };
        journeyFilterSettings.target = "journeys-list";
        filter = new FilterMenu(journeyFilterSettings);
        dom.byId("journeys-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(journeyFilterSettings);
        dom.byId("journeys-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "customer-journeys-queue") {
        var journeyQueueFilterSettings = {
          "filters": [{
            "label": "Email Address",
            "key": "email",
            "type": "text"
          }, {
            "label": "SMS Phone Number",
            "key": "sms_phone_number",
            "type": "text"
          }, {
            "label": "Current Step ID",
            "key": "current_step_id",
            "type": "text"
          }, {
            "label": "Next Step ID",
            "key": "next_step_id",
            "type": "text"
          }, {
            "label": "Status",
            "key": "status",
            "type": "select",
            "options": {
              "done": "Done",
              "processing": "Processing",
              "forced": "Forced / Manually Added",
              "failed": "Failed",
              "removed": "Removed",
              "exited": "Exited",
              "filtered": "Filtered",
              "hidden": "Hidden"
            }
          }, {
            "label": "Arrived in State",
            "key": "arrival_timestamp",
            "type": "select",
            "options": {
              "1": "One day ago",
              "2": "Two days ago",
              "7": "One week ago"
            }
          }]
        };
        journeyQueueFilterSettings.target = "journeys-contacts-list";
        filter = new FilterMenu(journeyQueueFilterSettings);
        dom.byId("journeys-contacts-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(journeyQueueFilterSettings);
        dom.byId("journeys-contacts-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "customer-journeys-details") {
        var journeyDetailsFilter = {
          "filters": [{
            "label": "Step ID",
            "key": "step_id",
            "type": "text"
          }, {
            "label": "Step Type",
            "key": "step_type",
            "type": "select",
            "options": {
              "trigger": "Trigger",
              "delay": "Delay",
              "action": "Action",
              "condition": "Condition"
            }
          }]
        };
        journeyDetailsFilter.target = "journeys-details-list";
        filter = new FilterMenu(journeyDetailsFilter);
        dom.byId("journeys-details-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(journeyDetailsFilter);
        dom.byId("journeys-details-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "customer-journeys-logs") {
        var journeyLogsFilters = {
          "filters": [{
            "label": "Step ID",
            "key": "step_id",
            "type": "text"
          }, {
            "label": "Step Type",
            "key": "step_type",
            "type": "select",
            "options": {
              "trigger": "Trigger",
              "delay": "Delay",
              "action": "Action",
              "condition": "Condition"
            }
          }]
        };
        journeyLogsFilters.target = "journeys-logs-list";
        filter = new FilterMenu(journeyLogsFilters);
        dom.byId("journeys-logs-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(journeyLogsFilters);
        dom.byId("journeys-logs-filters-message").appendChild(filterMessage.domNode);
      }
    }
  });
});