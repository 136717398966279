define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./predicted-demographics/templates/predictedDemographics.html", "dojo/text!./predicted-demographics/templates/genderChart.html", "dojo/text!./predicted-demographics/templates/ageRangeChart.html", "mojo/widgets/predicted-demographics/genderChart", "mojo/widgets/predicted-demographics/ageRangeChart", "mojo/widgets/ButtonGroup", "mojo/widgets/predicted-demographics/api", "mojo/url", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr, tplGenderChart, tplAgeRangeChart, genderChart, ageRangeChart, ButtonGroup, api, mUrl, lang, array, on, domAttr, domClass, I18nTranslation, flags, context) {
  var _BaseChart = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    translations: {},
    listId: null,
    campaignId: null,
    chart: null,
    chartId: "",
    chartData: {},
    legendId: "",
    metricOptions: ["subscribers", "recipients", "opens", "clicks", "purchases", "revenue"],
    constructor: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS)) {
        I18nTranslation.initialize().then(function (values) {
          if (I18nTranslation.isTranslationObjectEmpty()) {
            return;
          }
          this.translations = values;
        }.bind(this));
      }
    },
    _initChart: function (data) {
      this.chartData = data;

      // Only setup controls if we have more than one metric
      if (this.chartData && Object.keys(this.chartData).length > 1) {
        this._setupMetricControls();
      }
    },
    _setupMetricControls: function () {
      // Build metric toggler options based on available data
      var options = [];
      var templateString = '<ul class="flex"></ul>';
      var itemTpl = '<li class="c-buttonGroupItem"><button type="button" data-mc-value="{value}" title="{title}" class="c-buttonGroupButton v-buttonGroupUnderlined v-buttonGroupUnderlined--compact">{label}</button></li>';
      var activeClass = "v-buttonGroupUnderlined--active";
      var metricOptionsTranslationMap = {
        "subscribers": this.translations["reporting_demographics_subscribers_text"],
        "recipients": this.translations["reporting_demographics_recipients_text"],
        "opens": this.translations["reporting_demographics_opens_text"],
        "clicks": this.translations["reporting_demographics_clicks_text"],
        "purchases": this.translations["reporting_demographics_purchases_text"],
        "revenue": this.translations["reporting_demographics_revenue_text"]
      };
      for (var metric in this.chartData) {
        if (this.chartData.hasOwnProperty(metric) && this.metricOptions.indexOf(metric) !== -1 && this.chartData[metric] !== null) {
          var translatedLabel = metricOptionsTranslationMap[metric] || metric;
          options.push({
            "value": metric,
            "label": translatedLabel
          });
        }
      }
      if (options[0]) {
        this.tabs = new ButtonGroup({
          options: options,
          templateString: templateString,
          itemTpl: itemTpl,
          activeClass: activeClass
        });
        this.tabs.placeAt(this.metricToggler);
        this.tabs.startup();
        this.tabs.setSelected(options[0].value);
        this.tabs.on("change", lang.hitch(this, "_changeMetric"));
      }
    },
    _changeMetric: function (metric) {
      this.currentMetric = metric;
      if (this.metricOptions.indexOf(metric) !== -1) {
        this._updateChart(metric);
      } else {
        throw new Error("Unhandled metric");
      }
    },
    _updateChart: function (metric) {
      this.chart.changeMetric(metric);
    }
  });
  var GenderChart = declare([_BaseChart], {
    templateString: tplGenderChart,
    chartId: "genderChartContainer",
    legendId: "genderLegendContainer",
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS)) {
        I18nTranslation.initialize().then(function () {
          if (I18nTranslation.isTranslationObjectEmpty()) {
            return;
          }
          this.genderText.innerText = I18nTranslation.translate("reporting_demographics_gender_text");
        }.bind(this));
      }
      var req = this.campaignId ? api.getCampaignGenderData(this.campaignId) : api.getListGenderData(this.listId);
      req.then(lang.hitch(this, "_initChart"));
    },
    // Extend _initChart from _BaseChart
    _initChart: function () {
      this.inherited(arguments);
      this.chart = new genderChart({
        "chartId": this.chartId,
        "legendId": this.legendId,
        "chartData": this.chartData
      });
    }
  });
  var AgeRangeChart = declare([_BaseChart], {
    templateString: tplAgeRangeChart,
    previousData: null,
    postCreate: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS)) {
        I18nTranslation.initialize().then(function () {
          if (I18nTranslation.isTranslationObjectEmpty()) {
            return;
          }
          this.ageRangeText.innerText = I18nTranslation.translate("reporting_demographics_age_range_text");
        }.bind(this));
      }
      var req = this.campaignId ? api.getCampaignGenderByAgeRangeData(this.campaignId) : api.getListGenderByAgeRangeData(this.listId);
      req.then(lang.hitch(this, "_initChart"));
    },
    // Extend _initChart from _BaseChart
    _initChart: function () {
      this.inherited(arguments);
      this.chart = new ageRangeChart({
        "chartData": this.chartData,
        "chartContainer": this.chartContainer
      });
    }
  });
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    listId: null,
    campaignId: null,
    postCreate: function () {
      this.genderChart = new GenderChart({
        listId: this.listId,
        campaignId: this.campaignId
      });
      this.genderChart.placeAt(this.genderChartContainer);
      this.genderChart.startup();
      this.ageRangeChart = new AgeRangeChart({
        listId: this.listId,
        campaignId: this.campaignId
      });
      this.ageRangeChart.placeAt(this.ageRangeChartContainer);
      this.ageRangeChart.startup();
    }
  });
});