define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/app/pro/segment", "mojo/segmentation/Segment", "mojo/widgets/SubscriberTableOverlay", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context", "dojo/NodeList-manipulate", "dojo/NodeList-traverse"], function (declare, base, query, on, lang, request, domClass, domStyle, domGeom, dom, mUrl, proSegment, Segment, SubscriberTableOverlay, I18nTranslation, flags, context) {
  return declare([base], {
    // From controller
    segmentId: null,
    // From controller
    listId: null,
    // From controller
    reportId: null,
    // Used to make sure only one count refresh happens at a time
    refreshing: false,
    // References the instance of the editor in the page
    segmentEditor: null,
    // From controller.
    savedAST: null,
    onReady: function () {
      var segmentCount = query(".segment-count-fixed")[0];
      var editor = this.$el.memberSegmentEditor;
      on(dom.byId("av-content"), "scroll", function () {
        var editorPos = domGeom.position(editor, false);
        if (editorPos.y < -370) {
          domClass.remove(segmentCount, "hide");
        } else {
          domClass.add(segmentCount, "hide");
        }
      });
      on(dom.byId("wizard-form"), "submit", function (e) {
        e.preventDefault();
      });
      this.$group.previewSubscriberBtn.on("click", lang.hitch(this, function (e) {
        e.preventDefault();
        e.stopPropagation();
        SubscriberTableOverlay.main().load(this.listId, this.segmentEditor.getAST(), {
          showPagination: false,
          persistTableSettings: false
        }, "report");
      }));
      this.$group.rerunCountBtn.on("click", lang.hitch(this, function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.refreshCount();
      }));
      on(this.$el.saveBtn, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        e.stopPropagation();
        proSegment.saveReportSegment(this.listId, this.reportId, this.segmentId, this.getNameValue(), this.segmentEditor.getAST());
      }));
      this.segmentEditor = new Segment({
        "listId": this.listId,
        "savedAST": this.savedAST,
        "configType": "report",
        "class": "border-radius--lv1 border-gray"
      }, this.$el.segmentEditorDom);
      this.segmentEditor.on("conditionCountChange", lang.hitch(this, function () {
        this.toggleAddSegmentBtn(this.segmentEditor.getConditionCount());
      }));
    },
    /**
     * Toggle the save button that adds this segment to the report.
     *
     * @param {boolean} enable boolean
     */
    toggleAddSegmentBtn: function (enable) {
      var analyzeBtn = this.$el.saveBtn;
      if (enable) {
        analyzeBtn.removeAttribute("disabled");
      } else {
        analyzeBtn.setAttribute("disabled", "disabled");
      }
    },
    togglePreviewSegment: function (enable) {
      var previewSubscriberBtn = this.$group.previewSubscriberBtn;
      var previewSubscriberText = this.$group.previewSubscriberText;
      if (enable) {
        previewSubscriberBtn.removeClass("hide");
        previewSubscriberText.addClass("hide");
      } else {
        previewSubscriberBtn.addClass("hide");
        previewSubscriberText.removeClass("hide");
      }
    },
    getNameValue: function () {
      return this.$el.nameInput.value;
    },
    /**
     * Refresh the count of the current segment and update the appropriate dom elements.
     */
    refreshCount: function () {
      var self = this;
      if (!this.refreshing) {
        this.refreshing = true;
        var ast = this.segmentEditor.getAST();
        var refresh_billing = "Refreshing &hellip;";
        if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
          I18nTranslation.initialize().then(function (translations) {
            self.$group.rerunCountBtn.html(translations.reporting_segment_refresh_billing || refresh_billing);
          });
        } else {
          self.$group.rerunCountBtn.html(refresh_billing);
        }
        var countReq = proSegment.refreshSegmentCount(this.listId, ast, "report");
        countReq.then(lang.hitch(this, function (count) {
          var html;
          var count_def_str;
          var count_plural;
          var incomplete_segment_def_str;
          var recipients;
          if (count.match(/^[0-9,]+$/)) {
            count_plural = count !== 1 ? "s" : "";
            recipients = '<span class=\"count\">' + count + "</span> recipient" + count_plural;
            if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
              html = I18nTranslation.translate("reporting_segment_recipient_plural", [count, count_plural]) || recipients;
            } else {
              html = recipients;
            }
          } else {
            count = 0;
            incomplete_segment_def_str = "This segment is incomplete. Please check entries.";
            if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
              I18nTranslation.initialize().then(function (translations) {
                html = translations.reporting_segment_incomplete_segment || incomplete_segment_def_str;
              });
            } else {
              html = incomplete_segment_def_str;
            }
          }
          this.$group.previewSubscriberBtn.html(html);
          this.$group.previewSubscriberText.html(html);
          this.togglePreviewSegment(count);
          var refresh_count = "Refresh count";
          if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
            I18nTranslation.initialize().then(function (translations) {
              self.$group.rerunCountBtn.html(translations.reporting_segment_count_refresh || refresh_count);
            });
          } else {
            self.$group.rerunCountBtn.html(refresh_count);
          }
          this.refreshing = false;
        }), lang.hitch(this, function () {
          this.refreshing = false;
        }));
      }
    }
  });
});