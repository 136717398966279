define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "dijit/DialogUnderlay", "dijit/Dialog"], function (lang, array, domClass, domConstruct, Deferred, on, stringUtils, DialogUnderlay, Dialog) {
  var browserPrefix = null;
  function prefixAnimationEvents(eventStr) {
    var prefixes = ["webkit", "Moz", "MS", ""];
    if (browserPrefix === null) {
      var p = domConstruct.create("p");
      var pStyles = p.style;
      browserPrefix = false;
      array.some(prefixes, function (prefix) {
        var attr = prefix ? prefix + "Animation" : "animation";
        if (typeof pStyles[attr] !== "undefined") {
          browserPrefix = prefix;
          return true;
        }
        return false;
      });
      domConstruct.destroy(p);
    }
    if (browserPrefix === false) {
      return false;
    }

    // Browsers besides webkit uses all lowercase non-browser specific. (ex. webkitAnimationEnd vs. animationend)
    if (browserPrefix && browserPrefix === "webkit") {
      return browserPrefix + stringUtils.capitalize(eventStr);
    }
    return eventStr.toLowerCase();
  }

  /**
   * module:
   *      mojo/widgets/Dialog
   *
   * Monkey patches dijit/Dialog to provide css3 animations to dialog hide/show
   */

  var _show = Dialog.prototype.show;
  var _hide = Dialog.prototype.hide;
  Dialog.prototype.duration = 0;
  Dialog.prototype.show = function () {
    var _defaultPromise = _show.call(this);

    // We have to add this to the underlayAttr since dijit/Dialog uses it to redo the underlay on stacks of dialog.
    if (array.indexOf(this.underlayAttrs['class'].split(' '), 'on') === -1) {
      this.underlayAttrs['class'] += ' on';
    }
    var underlay = DialogUnderlay._singleton.node;
    domClass.add(this.domNode, "dijitDialogShow");
    domClass.add(underlay, "on");
    var animationEvent = prefixAnimationEvents("TransitionEnd");
    if (animationEvent) {
      var promise = new Deferred();
      on.once(underlay, animationEvent, function () {
        promise.resolve(true);
      });
      // in the case of animations here,
      // the autofocus function of dijit/Dialog
      // fails due to timings.  Account for that here by
      // focusing after animations have completed.
      this.focusOnFirst();
      return promise;
    } else {
      return _defaultPromise;
    }
  };

  /**
   * A custom focus function that can be used to autofocus
   * a dialog
   */
  Dialog.prototype.focusOnFirst = function () {
    if (this.autofocus && this._firstFocusItem) {
      this._firstFocusItem.focus();
    }
  };

  // Provide a way to hide without animation
  Dialog.prototype.baseHide = function () {
    _hide.call(this);
  };

  // Provide a way to show without animation
  Dialog.prototype.baseShow = function () {
    _show.call(this);
  };
  Dialog.prototype.hide = function () {
    if (!this._alreadyInitialized || !this.open) return;
    var underlay = DialogUnderlay._singleton.node;
    domClass.remove(this.domNode, "dijitDialogShow");

    // Don't animate the underlay for multiple dialog stacks.
    // Note: There's 2 in the stack when one dialog is open.
    if (Dialog._dialogStack.length <= 2) {
      domClass.remove(underlay, "on");
    } else {
      return _hide.call(this);
    }
    var animationEvent = prefixAnimationEvents("TransitionEnd");
    if (animationEvent) {
      var promise = new Deferred();
      on.once(underlay, animationEvent, lang.hitch(this, function () {
        _hide.call(this);
        promise.resolve(true);
      }));
      return promise;
    } else {
      return _hide.call(this);
    }
  };
  return Dialog;
});