define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dijit/registry", "mojo/widgets/_ViewAdapterBase", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-traverse"], function (declare, lang, parser, registry, _ViewAdapterBase, query, domConstruct) {
  return declare([_ViewAdapterBase], {
    placeHolder: null,
    constructor: function (domNode) {
      var placeHolder = this.placeHolder = domConstruct.create("div");
      // remove all of the children from our original node and move them to the placeholder
      query(domNode).children().forEach(function (node) {
        // we need to remove the widgets from the registry because, technically, they don't exist
        registry.findWidgets(node).forEach(function (widget) {
          widget.destroy(true);
        });
        domConstruct.place(node, placeHolder);
      });
    },
    createView: function (position) {
      // Notice that we return a copy of the node rather than the node itself. This is so that the placeholder's
      // child positioning doesn't change. (The return value of createView() is cached, so don't worry: we're
      // not creating a clone every time.)
      var node = lang.clone(query(this.placeHolder).children()[position]);
      parser.parse(node); // this re-parses the widget and adds it back to the registry
      return node;
    },
    getCount: function () {
      return query(this.placeHolder).length;
    },
    destroy: function () {
      domConstruct.destroy(this.placeHolder);
    }
  });
});