define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dijit/registry", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils"], function (declare, base, mUrl, registry, domClass, dom, query, domAttr, domStyle, xhr, p2Utils) {
  return declare(null, {
    /**
     * creates an array of urls to check from the page
     */
    webhookHeadCheckAll: function () {
      var urls = [];
      query(".check-on-load").forEach(function (entry, i) {
        var dom_id = domAttr.get(entry, "id");
        var url = domAttr.get(entry, "data-dojo-url");
        var urlObj = {};
        urlObj.dom_id = dom_id;
        urlObj.url = url;
        urls.push(urlObj);
      });
      this._checkHead(urls);
    },
    /**
     * checks the head request again
     * @param {string} dom_id - id of element to check
     * @param {string} url - url to check
     */
    reCheckHead: function (dom_id, url) {
      var urls = [];
      var urlObj = {};
      urlObj.dom_id = dom_id;
      urlObj.url = url;
      urls.push(urlObj);
      domClass.add("pulse-" + dom_id, "block");
      domClass.remove("enabled-" + dom_id, "block");
      domClass.add("enabled-" + dom_id, "hide");
      dom.byId("errored-" + dom_id).innerHTML = "";
      this._checkHead(urls);
    },
    /**
     * purges any jobs that are currently running
     * @param {number} uid - user id for the jobs you want to purge
     */
    purgeRunningJobs: function (uid) {
      xhr(mUrl.toUrl("/peaches2/admin/webhooks/purge-stuck-jobs"), {
        handleAs: "json",
        query: {
          user_id: uid
        }
      }).then(function (data) {
        var msg = data.msg;
        if (data.status === "error") {
          msg = "Unable to purge running jobs: " + data.msg;
        }
        p2Utils.utils.setFlash(msg, data.status);
      });
    },
    /**
     * purges and jobs that are current running
     * @param {number} uid - user id for the enqueued jobs
     */
    purgeEnqueuedJobs: function (uid) {
      xhr(mUrl.toUrl("/peaches2/admin/webhooks/purge-enqueued-jobs"), {
        handleAs: "json",
        query: {
          user_id: uid
        }
      }).then(function (data) {
        var msg = data.msg;
        if (data.status === "error") {
          msg = "Unable to purge enqueued jobs: " + data.msg;
        }
        p2Utils.utils.setFlash(msg, data.status);
      });
    },
    /**
     * checks the head request of the passed url and displays the results to the user
     * @param {array} urls - array of urls
     * @private
     */
    _checkHead: function (urls) {
      var dom_id = "";
      var status = "";
      var response_time = "";
      xhr(mUrl.toUrl("/peaches2/admin/webhooks/check-head"), {
        handleAs: "json",
        query: {
          urls: JSON.stringify(urls)
        }
      }).then(function (data) {
        for (var i = 0; i < data.results.length; i++) {
          status = data.results[i].status;
          dom_id = urls[data.results[i].index].dom_id;
          response_time = data.results[i].response_time;
          if (status === undefined || status === null) {
            dom.byId("badge-" + dom_id).innerHTML = "ERROR";
            domClass.add("badge-" + dom_id, "error");
            domClass.remove("icon-" + dom_id, "time-circle");
            domClass.remove("icon-" + dom_id, "tick-circle");
            domClass.add("icon-" + dom_id, "not-allowed");
            domClass.add("icon-" + dom_id, "error");
            if (typeof data.results[i].msg !== "undefined") {
              dom.byId("errored-" + dom_id).innerHTML = data.results[i].msg;
              domClass.remove("errored-" + dom_id, "hide");
            }
          } else {
            var badge_msg = status;
            if (status >= 200 && status < 400) {
              domClass.remove("icon-" + dom_id, "time-circle");
              domClass.add("icon-" + dom_id, "tick-circle");
              domClass.add("icon-" + dom_id, "success");
              domClass.add("badge-" + dom_id, "success");
            } else {
              domClass.remove("icon-" + dom_id, "time-circle");
              domClass.add("icon-" + dom_id, "not-allowed");
              domClass.add("icon-" + dom_id, "error");
              domClass.add("badge-" + dom_id, "error");
            }
            if (typeof response_time !== "undefined") {
              badge_msg = badge_msg + " in " + response_time + "ms";
            }
            dom.byId("badge-" + dom_id).innerHTML = badge_msg;
          }
          domClass.remove("pulse-" + dom_id, "block");
          domClass.add("pulse-" + dom_id, "hide");
          domClass.add("enabled-" + dom_id, "block");
          domClass.remove("enabled-" + dom_id, "hide");
        }
      });
    },
    /**
     * disables the webook to the passed url
     * @param {string} peaches_url - url including the shard that the user is on
     * @param {number} user_id - user id
     * @param {number} list_id - list id for the associated url
     * @param {string} url - the url that we are disabling
     * @param {string} dom_id - id of the element to show that webhook is paused or enabled
     */
    disableWebhook: function (peaches_url, user_id, list_id, url, dom_id) {
      var callback = peaches_url + "/peaches2/admin/webhooks/disable";
      xhr(callback, {
        handleAs: "json",
        query: {
          user_id: user_id,
          list_id: list_id,
          url: url
        }
      }).then(function (data) {
        // Do something with the handled data
        if (data.status === "success") {
          var since = "";
          if (typeof data.since !== "undefined") {
            since = " since " + data.since;
          }
          domClass.add("enabled-wrapper-" + dom_id, "hide");
          domClass.remove("enabled-" + dom_id, "block");
          domClass.remove("pulse-" + dom_id, "block");
          domClass.add("pulse-" + dom_id, "hide");
          domClass.add("paused-" + dom_id, "block");
          domStyle.set(dom.byId("pause-button-" + dom_id), "display", "none");
          domStyle.set(dom.byId("activate-button-" + dom_id), "display", "block");
        } else {
          registry.byId("pause-webhook-error").show();
        }
      });
    },
    /**
     *enables paused webhooks
     * @param {string} peaches_url - url including the shard that the user is on
     * @param {number} user_id - user id
     * @param {number} list_id - list id for the associated url
     * @param {string} url - the url that we are disabling
     * @param {string} dom_id - id of the element to show that webhook is paused or enabled
     */
    enableWebhook: function (peaches_url, user_id, list_id, url, dom_id) {
      var callback = peaches_url + "/peaches2/admin/webhooks/enable";
      var self = this;
      xhr(callback, {
        handleAs: "json",
        query: {
          "user_id": user_id,
          "list_id": list_id,
          "url": url
        }
      }).then(function (data) {
        if (data.status === "success") {
          query("#paused-" + dom_id + " span.badge")[0].innerHTML = "Paused";
          domClass.remove("enabled-wrapper-" + dom_id, "hide");
          domClass.add("enabled-" + dom_id, "block");
          domClass.add("paused-" + dom_id, "hide");
          domClass.remove("paused-" + dom_id, "block");
          domStyle.set(dom.byId("pause-button-" + dom_id), "display", "block");
          domStyle.set(dom.byId("activate-button-" + dom_id), "display", "none");
          self.reCheckHead(dom_id, url);
        } else {
          registry.byId("enable-webhook-error").show();
        }
      });
    }
  });
});