/**
 * Freddie Animations
 * @module mojo/freddieanim.js
 *
 * Code related to the 3 custom Freddie animations that can be found as part
 * of various editors.
 *
 * To test locally see dev.mailchimp.com/campaigns/finished-sent?id={sentCampaignID},
 * dev.mailchimp.com/campaigns/finished-scheduled?id={sentCampaignID}, and the send
 * confirmation dialog for the email and postcard checklists.
 */

/* eslint-disable */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors",
// libs
"vendor/snap/snap.svg", // Doesn't need a var
"dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, array, dom, domClass, on, query, mColors, snap) {
  // Private vars
  var _freddieSend = {};
  var _highFive = {};
  var _rockOn = {};

  // Public var
  var animatronics = {};
  animatronics.FreddieSend = declare([], {
    // Need SVG element ID where to load the animation and a CSS class for the button/element triggering the interactions.
    constructor: function (elId, btnClass) {
      this.elId = elId;
      this.btnClass = btnClass;
      this.s = snap("#" + this.elId);
      this.freddieButton = {};
      this.bgCircle = {};
      this.actionBtn = null;
      this.svgDOM = null;
      this.bgCircle.mask = this.s.circle(150, 150, 125);
      this.bgCircle.mask.attr({
        fill: "#FFFFFF",
        stroke: "#000000",
        strokeWidth: 0
      });
      if (query("." + this.btnClass)) {
        this.actionBtn = query("." + this.btnClass);
      } else {
        console.error("Sorry, can't find an element with that CSS class.");
      }
      snap.load("/images/svg-freddie/freddie-send/send-it.svg", this.onLoaded);
      _freddieSend = this;
    },
    // eo Constructor

    onLoaded: function (f) {
      _freddieSend.svgDOM = f;
      _freddieSend.freddieButton.button = _freddieSend.svgDOM.select("#button");
      _freddieSend.freddieButton.hand = _freddieSend.svgDOM.select("#hand-normal");
      _freddieSend.freddieButton.handClicked = _freddieSend.svgDOM.select("#hand-clicked");
      _freddieSend.bgCircle.circle = _freddieSend.svgDOM.select("#bg-circle");
      _freddieSend.freddieButton.handClicked.attr({
        "class": "hide"
      });

      // Order of grouping is important!!!
      var gr = _freddieSend.s.group(_freddieSend.bgCircle.circle, _freddieSend.freddieButton.button, _freddieSend.freddieButton.hand, _freddieSend.freddieButton.handClicked);
      gr.attr({
        mask: _freddieSend.bgCircle.mask
      });

      // Add CSS3 animation
      _freddieSend.freddieButton.hand.attr({
        "class": "shakeAndPoint"
      });

      // Button components
      _freddieSend.freddieButton.surface = _freddieSend.freddieButton.button.select("#button-surface");
      _freddieSend.freddieButton.buttonbody = _freddieSend.freddieButton.button.select("#button-body");
      _freddieSend.freddieButton.bang = _freddieSend.freddieButton.button.select("#bang");

      // Wire Events
      _freddieSend.setUpEvents();

      // Add to SVG tag
      _freddieSend.s.add(_freddieSend.svgDOM);

      // Load Drops
      setTimeout(function () {
        _freddieSend.generateSweat(5, 5000); //params:NumDrops, maxDelay
      }, 2000);
    },
    Drop: function (delay) {
      var posX = Math.random() * (160 - 100) + 100;
      var posY = Math.random() * (100 - 45) + 45;
      var landDropRange = Math.random() * (200 - 160) + 160; //max y=200

      var dropObject = {};
      dropObject.posX = posX;
      dropObject.posY = posY;
      dropObject.size = 0;
      dropObject.stroke = "#5B3E45";
      dropObject.fill = "#D6EDEE";
      dropObject.strokeWidth = "2";
      dropObject.delay = delay;

      // SVG body
      dropObject.s = _freddieSend.s;
      var svgDrop = dropObject.s.paper.path("M11.5,13.3c0,2.5-1.5,3.5-4,3.5s-4-1-4-3.5s4-10.2,4-10.2S11.5,10.8,11.5,13.3z");
      svgDrop.attr({
        stroke: dropObject.stroke,
        fill: "#93CBC3",
        opacity: 0,
        strokeWidth: 2.0604,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10
      });
      svgDrop.transform(String("t" + dropObject.posX + "," + dropObject.posY + "s0.6,0.6"));
      svgDrop.attr({
        "class": "sweat"
      });

      // Animation for sweat drop
      dropObject.slide = function slide() {
        setTimeout(function () {
          svgDrop.animate({
            opacity: 1
          }, 500, mina.easeout, function () {
            svgDrop.animate({
              transform: String("t" + posX + "," + landDropRange)
            }, 1000, mina.easeout, function () {
              svgDrop.animate({
                opacity: 0
              }, 250, mina.easein, function () {
                svgDrop.remove();
              });
            });
          });
        }, delay);
      };
      return dropObject;
    },
    generateSweat: function (numDrops, maxDelay) {
      for (var i = 0; i < numDrops; i++) {
        var delay = Math.random() * (maxDelay - 500) + 500; //Delay between 1/2 sec and maxDelay
        var drop = new _freddieSend.Drop(delay);
        drop.slide();
      }
    },
    setUpEvents: function () {
      // Events for action button
      var mouseOverHandle = _freddieSend.actionBtn.on("mouseover", function () {
        if (_freddieSend.s.attr("class") !== "clicked") {
          _freddieSend.generateSweat(5, 8000);
        }
        _freddieSend.freddieButton.surface.animate({
          fill: "#E85D41"
        }, 333, mina.easein);
        _freddieSend.freddieButton.buttonbody.animate({
          fill: "#E85D41"
        }, 333, mina.easein);
      });
      var mouseOutHandle = _freddieSend.actionBtn.on("mouseout", function () {
        _freddieSend.freddieButton.surface.animate({
          fill: "#CC3B23"
        }, 333, mina.easein);
        _freddieSend.freddieButton.buttonbody.animate({
          fill: "#CC3B23"
        }, 333, mina.easein);
      });
      var clickHandle = _freddieSend.actionBtn.on("click", function () {
        // is clicked in case we need to target this
        _freddieSend.s.attr({
          "class": "clicked"
        });
        // Remove shake animation
        _freddieSend.freddieButton.hand.attr({
          "class": ""
        });
        //Remove sweat drops
        _freddieSend.s.selectAll("path.sweat").remove();
        // Change bg circle color
        // bgCircle.circle.animate({fill:"#90CBC2"}, 333, mina.easeinout);

        // Remove event handles for mouseover/mouseout/click events.
        mouseOverHandle.remove();
        mouseOutHandle.remove();
        clickHandle.remove();

        //Move hand to button
        _freddieSend.freddieButton.hand.animate({
          "transform": "t0,25"
        }, 300, mina.easeout, function () {
          // Hide normal hand
          _freddieSend.freddieButton.hand.attr({
            "class": "hide"
          });
          //Show clicked hand
          _freddieSend.freddieButton.handClicked.attr({
            "class": ""
          });
          // Move button down
          _freddieSend.freddieButton.surface.animate({
            cy: 186.1
          }, 300, mina.easeout, function () {});
          _freddieSend.freddieButton.bang.animate({
            "transform": "t0,5"
          }, 300, mina.easeout, function () {});

          //Button stays lit!
          _freddieSend.freddieButton.surface.attr({
            fill: "#E85D41"
          });
          _freddieSend.freddieButton.buttonbody.attr({
            fill: "#E85D41"
          });
        });
      });
    }
  });
  animatronics.HighFive = declare([], {
    // Need SVG element ID where to load the animation
    constructor: function (elId) {
      this.elId = elId;
      this.s = snap("#" + this.elId);
      this.bgCircle = {};
      this.svgDOM = null;
      this.svgs = {};
      this.timesClicked = 0;

      // mask
      this.bgCircle.mask = this.s.circle(200, 200, 200);
      this.bgCircle.mask.attr({
        fill: "#FFFFFF",
        stroke: "#000000",
        strokeWidth: 0
      });

      // background
      this.bgCircle.bg = this.s.circle(200, 200, 200);
      this.bgCircle.bg.attr({
        fill: this._getCircleBgColor()
      });
      snap.load("/images/svg-freddie/high-five/high-five-all.svg", this.loadAndInit);
      _highFive = this;
    },
    //eo Constructor

    loadAndInit: function (f) {
      _highFive.svgDOM = f;

      // Hand 1
      _highFive.svgs._01 = _highFive.svgDOM.select("#high-five-01");
      // Hand 2
      _highFive.svgs._02 = _highFive.svgDOM.select("#high-five-02");
      // Hand 3
      _highFive.svgs._03 = _highFive.svgDOM.select("#high-five-03");
      _highFive.svgs._03.palm = _highFive.svgDOM.select("#palm");
      _highFive.svgs._03.fingerprintsGrp = _highFive.svgDOM.select("#fingerprints");
      // Collect all fingerprints from the hand
      _highFive.svgs._03.fingerprints = _highFive.svgDOM.selectAll("#fingerprints > *");

      // Order of grouping is important!!!
      var gr01 = _highFive.s.group(_highFive.bgCircle.bg, _highFive.svgs._01, _highFive.svgs._02, _highFive.svgs._03);
      gr01.attr({
        mask: _highFive.bgCircle.mask
      });

      // Add to SVG tag
      _highFive.s.add(_highFive.svgDOM);

      // Then initialize position. Prevents NS_ERROR_FAILURE in FF
      _highFive.svgs._01.initStr = "s0.6r-30t-100,280";
      _highFive.svgs._01.transform(_highFive.svgs._01.initStr);
      _highFive.svgs._02.initStr = "t-50,25";
      _highFive.svgs._02.attr({
        opacity: 0
      });
      _highFive.svgs._02.transform(_highFive.svgs._02.initStr);
      _highFive.svgs._03.initStr = "t-50,30";
      _highFive.svgs._03.attr({
        opacity: 0
      });
      _highFive.svgs._03.transform(_highFive.svgs._03.initStr);
      query("#fingertips > ellipse").addClass("fingertip");

      // Wire Events
      _highFive.setUpEvents();

      // Start animation
      _highFive.animate01();
    },
    animate01: function () {
      // Chrome v37.0.2062 SVG animation issues - the line below can be removed when v37.0.2062 use is lower or with new nav.
      domClass.remove("content", "relative");

      /* wait 1/2 second to start animating */
      setTimeout(function () {
        _highFive.svgs._01.animate({
          transform: "t-50,60"
        }, 400, mina.backout, function () {
          //400ms
          setTimeout(function () {
            _highFive.svgs._01.animate({
              opacity: 0
            }, 30, mina.linear, function () {
              _highFive.svgs._01.attr({
                display: "none"
              });
              _highFive.animate02();
            });
          }, 100);
        });
      }, 500);
    },
    animate02: function () {
      _highFive.svgs._02.animate({
        opacity: 1
      }, 10, mina.linear, function () {
        _highFive.svgs._02.animate({
          opacity: 0
        }, 30, mina.linear, function () {
          _highFive.svgs._02.attr({
            display: "none"
          });
        });
      });
      _highFive.animate03();
    },
    animate03: function () {
      _highFive.svgs._03.animate({
        opacity: 1
      }, 30, mina.linear, function () {
        //Flickr of screen
        _highFive.bgCircle.bg.animate({
          fill: "#FFFFFF"
        }, 10, mina.linear, function () {
          _highFive.bgCircle.bg.animate({
            fill: _highFive._getCircleBgColor()
          }, 10);
        });
        _highFive.svgs._03.animate({
          transform: "s1.05t-50,0"
        }, 600, mina.elastic, function () {
          //600ms
          _highFive.svgs._03.animate({
            transform: "t-50,5"
          }, 1000, mina.elastic); //1000ms
          _highFive.svgs._03.fingerprintsGrp.animate({
            opacity: 0
          }, 500);
        });
      });
    },
    setUpEvents: function () {
      var container = query("#" + _highFive.elId);
      container.on("click", function () {
        if (!_highFive.s.attr("class")) {
          _highFive.s.attr({
            "class": "clicked"
          });
        }
        if (_highFive.timesClicked < 5) {
          _highFive.resetHighFive();
          _highFive.animate01();
          _highFive.makeRed();
        } else {
          window.open("http://mailchimp.com/fast-fives/", "_blank");
        }
      });
    },
    resetHighFive: function () {
      // Init attr svg01
      _highFive.svgs._01.attr({
        display: "block"
      });
      _highFive.svgs._01.transform(_highFive.svgs._01.initStr);
      _highFive.svgs._01.attr({
        opacity: 1
      });

      // Init attr svg02
      _highFive.svgs._02.attr({
        display: "block"
      });
      _highFive.svgs._02.transform(_highFive.svgs._02.initStr);

      // Init attr svg03
      _highFive.svgs._03.attr({
        opacity: 0
      });
      _highFive.svgs._03.fingerprintsGrp.attr({
        opacity: 1
      });

      /* If animation for fingerprints going, stop it!!! */
      if (_highFive.svgs._03.fingerprintsGrp.inAnim()[0]) {
        var animObj = _highFive.svgs._03.fingerprintsGrp.inAnim()[0];
        animObj.stop();
      }
      _highFive.svgs._03.transform(_highFive.svgs._03.initStr);
    },
    makeRed: function () {
      var shadesRed = ["#C3B49B", "#CCB59E", "#D8B5A3", "#E6B6A7", "#F3B7AC"];
      var shadesRedFingertips = ["#CCB59E", "#D8B5A3", "#E6B6A7", "#F3B7AC", "#D3978C"];
      _highFive.svgs._03.palm.animate({
        fill: shadesRed[_highFive.timesClicked]
      }, 600, mina.easein);
      var fingerPrints = _highFive.svgs._03.fingerprints;
      array.forEach(fingerPrints, function (entry, i) {
        entry.animate({
          fill: shadesRedFingertips[_highFive.timesClicked]
        }, 600, mina.easein);
      });
      _highFive.timesClicked++;
    },
    /**
     * We need the background color to be variable. This allows us to call
     * logic consistently.
     *
     * @private
     */
    _getCircleBgColor: function () {
      return mColors.brandColors.cucumber.hex;
    }
  });
  animatronics.RockOn = declare([], {
    constructor: function (elId) {
      this.elId = elId;
      this.s = snap("#" + this.elId);
      this.bgCircle = {};
      this.svgDOM = null;
      this.svgs = {};

      // mask
      this.bgCircle.mask = this.s.circle(200, 200, 200);
      this.bgCircle.mask.attr({
        fill: "#FFFFFF",
        stroke: "#000000",
        strokeWidth: 0
      });

      // background
      this.bgCircle.bg = this.s.circle(200, 200, 200);
      this.bgCircle.bg.attr({
        fill: this._getCircleBgColor()
      });

      // Load SVG via Snap
      snap.load("/images/svg-freddie/rock-on/rock-on-freddie.svg", this.loadAndInit);
      _rockOn = this;
    },
    //eo Constructor

    loadAndInit: function (f) {
      _rockOn.svgDOM = f;

      // Bent Horns
      _rockOn.svgs.all = _rockOn.svgDOM.select("#all");

      // Bent Horns
      _rockOn.svgs.benthorns = _rockOn.svgDOM.select("#bent-horns");

      // Straight Horns
      _rockOn.svgs.straighthorns = _rockOn.svgDOM.select("#straight-horns");

      // Order of grouping is important!!!
      var gr01 = _rockOn.s.group(_rockOn.bgCircle.bg, _rockOn.svgs.all);
      gr01.attr({
        mask: _rockOn.bgCircle.mask
      });

      // Add to SVG tag
      _rockOn.s.add(_rockOn.svgDOM);

      // Init position and visibility
      _rockOn.svgs.all.initStr = "s0.6r20,200,350t300,200";
      _rockOn.svgs.all.transform(_rockOn.svgs.all.initStr);
      _rockOn.svgs.straighthorns.attr({
        opacity: 0
      });
      _rockOn.startRocking();
    },
    startRocking: function () {
      // Fix for Chrome v37.0.2062 SVG animation issues
      domClass.remove("content", "relative");
      setTimeout(function () {
        _rockOn.svgs.all.animate({
          transform: "s1.1r-5,200,350"
        }, 500, mina.easeinout, function () {
          // Show straight horns
          _rockOn.svgs.straighthorns.animate({
            opacity: 1
          }, 20, mina.linear, function () {
            // Hide bent horns
            _rockOn.svgs.benthorns.animate({
              opacity: 0
            }, 10, mina.linear, function () {});

            // back to  1:1
            _rockOn.svgs.all.animate({
              transform: "s1r0,200,350"
            }, 300, mina.backout, function () {
              _rockOn.svgs.all.attr({
                "class": "rockingHover"
              });
            });
          });
        });
      }, 750);
    },
    /**
     * We need the background color to be variable. This allows us to call
     * logic consistently.
     *
     * @private
     */
    _getCircleBgColor: function () {
      return mColors.brandColors.cornflower.hex;
    }
  });
  return animatronics;
});