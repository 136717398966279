define(["./validate/_base"], function (validate) {
  /*=====
   return {
   // summary:
   //		Deprecated.  Should require dojox/validate modules directly rather than trying to access them through
   //		this module.
   };
   =====*/

  return validate;
});