define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "dijit/_WidgetBase", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/input/Color", "dojo/text!./templates/colorWithAlphaInput.html"], function (declare, Color, _TemplatedMixin, _WidgetBase, _WidgetsInTemplateMixin, on, ColorInput, tpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    label: "Color",
    templateString: tpl,
    colorInput: null,
    // // Value to use when the input is empty. Allow us to change the default to "transparent" or "none"
    valueOnEmpty: "",
    // // Control the visibility of the no color control on the color picker
    noColorEnabled: false,
    value: null,
    _color: null,
    // set alpha to rgbaColor a value, for alpha
    _alpha: null,
    DEFAULT_ALPHA: 0.00,
    constructor: function () {
      this.inherited(arguments);
      // set initial values of color and alpha
      if (this.value && this.value.toHex) {
        this._color = this.value.toHex();
      } else {
        this._color = this.valueOnEmpty;
      }
      if (this.value && this.value.a) {
        this._alpha = this.value.a;
      } else {
        this._alpha = this.DEFAULT_ALPHA;
      }
    },
    postCreate: function () {
      this.inherited(arguments);
      // set initial values of color and alpha

      this.colorInput = new ColorInput({
        value: this._color
      });
      this.colorInput.startup();
      this.colorInput.placeAt(this.colorInputContainer);
      this.alphaInput.set("value", this._alpha);
      this.own(on(this.colorInput, "change", this.onColorChange.bind(this)), on(this.alphaInput, "change", this.onAlphaChange.bind(this)));
    },
    onColorChange: function (colorVal) {
      if (this._color !== colorVal) {
        this._color = colorVal;
        var tempColor = this._color && Color.fromHex(this._color) ? Color.fromHex(this._color) : null;
        if (tempColor) {
          tempColor.a = this._alpha;
        }
        this.value = tempColor;
        this.onChange(this.value);
      }
    },
    onAlphaChange: function (alphaVal) {
      var toFixedAlpha = parseFloat(alphaVal.toFixed(2), 10);
      if (this._alpha !== toFixedAlpha) {
        // new Color from this._color,
        var tempColor = Color.fromHex(this._color);
        // set alpha
        this._alpha = toFixedAlpha;
        tempColor.a = this._alpha;
        this.value = tempColor;
        this.onChange(this.value);
      }
    },
    _isEmptyValue: function (value) {
      return value === null || value === "" || value === this.valueOnEmpty;
    },
    _setValueAttr: function (value) {
      // value is a dojo/color object, {r: ,g: ,b: ,a: } with methods to abstract the Hex, CSS, etc.
      // Clear out the visible textbox if the value matches `valueOnEmpty`
      this._color = value ? value.toHex() : this.valueOnEmpty;
      this._alpha = value ? value.a : this.DEFAULT_ALPHA;
      this._updateColor(this._color);
      this._updateAlpha(this._alpha);
    },
    _updateColor: function (hexColor) {
      if (this.colorInput && hexColor) {
        this.colorInput.set("value", hexColor);
      }
    },
    _updateAlpha: function (alphaValue) {
      if (this.alphaInput && alphaValue) {
        this.alphaInput.set("value", alphaValue);
      }
    },
    onReset: function () {
      this.set("value", this.valueOnEmpty);
    }
  });
});