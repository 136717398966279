define(["mojo/utils/I18nTranslation"], function (I18nTranslation) {
  return function validateEmail(str) {
    var LEGAL_PRINTABLE_REGEX = /^[0-9a-zA-Z_.\+-]+$/;
    var BAD_DOT_REGEX = /^\.|\.$/;
    var BAD_PLUS_REGEX = /^\+|\+$/;
    var DOMAIN_REGEX = /^[a-z0-9][a-z0-9\.\-_]*\.[a-z]+$/i;
    if ((str.match(/@/g) || []).length !== 1) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("email_must_contain_at"));
      }
      return new Error("An email address must contain a single @.");
    }
    var split = str.split("@");
    var username = split[0];
    var domain = split[1];
    if (split[0].length === 0) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("username_portion_empty"));
      }
      return new Error("The username portion of the email address is empty");
    }
    if (BAD_PLUS_REGEX.test(username) || BAD_DOT_REGEX.test(username) || !LEGAL_PRINTABLE_REGEX.test(username)) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("email_username_portion_invalid", [split[0]]));
      }
      return new Error("The username portion of the email address is invalid (the portion after the @: " + split[0] + ")");
    }
    if (!DOMAIN_REGEX.test(domain)) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("email_domain_portion_invalid", [split[1]]));
      }
      return new Error("The domain portion of the email address is invalid (the portion after the @: " + split[1] + ")");
    }
    var BAD_DOMAINS = ["example", "spam", "spamtrap", "yahoogroups", "googlegroups", "mailinator", "guerrillamailblock", "tyldd", "tempemail", "noclickemail"];
    var BAD_USERPATS = ["spamtrap", "^spam$"];
    var badDomains = false;
    var badUserPats = false;
    BAD_DOMAINS.forEach(function (e) {
      var domainKey = domain.split(".");
      if (e === domainKey[0].toLowerCase()) {
        badDomains = true;
      }
    });
    BAD_USERPATS.forEach(function (e) {
      if (username.toLowerCase().search(e) > -1) {
        badUserPats = true;
      }
    });
    if (badDomains || badUserPats) {
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        return new Error(I18nTranslation.translate("email_invalid"));
      }
      return new Error("This email address looks fake or invalid. Please enter a real email address.");
    }
    return null;
  };
});