/**
 * Mobile Slat Interface Mixin
 * This mixin is used as the common code between Facebook and Google Mobile Ad slats.
 *
 * Private functions are indicated as such, public functions are free to be overridden.
 * "__methodName" denotes required implementation by children.
 *
 * @mixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url", "mojo/user",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, lang, request, mUrl, user, _WidgetBase, _TemplatedMixin) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div class=\"padding--lv3\" data-dojo-attach-point=\"slatContainer\">",
    // The simple string we use to differentiate campaign types.
    // i.e. "facebook" || "google"
    campaignType: null,
    campaignUniqueId: null,
    // Allow a child implementation to be disabled in one go
    enabled: true,
    // The slat in which to load
    slatName: "",
    // Reference to the parent widget for easier data flow.
    router: null,
    /**
     * Check what kind of slat this'll be and start it.
     */
    postCreate: function () {
      if (this._getSlatConfig().checklistItem) {
        this._initChecklistItem();
      } else {
        this._initGenericChecklistItem();
      }
    },
    /**
     * Run this at widget creation time, making sure that we have all of the data we need.
     */
    postMixInProperties: function () {
      this._validateProps();
      this._validateCalledSlat();
    },
    /**
     * Public method that Event Router communicates into to indicate that the Save
     * button has been pressed.
     *
     * Different slat types will interpret this differently. Handle accordingly.
     */
    actionSave: function () {
      if (this._getSlatConfig().checklistItem) {
        this.slat.handleSave();
      } else {
        // TODO: Only use this method for handling save
        this.slat.__save();
      }
    },
    /**
     * Public method that Event Router communicates into to indicate that the back
     * button has been pressed
     *
     * TODO: Allow this.slat to know that the hardware back button has been pressed.
     */
    actionBack: function () {},
    /**
     * Notify the Event Router that the requested slat is loaded.
     *
     * @private
     */
    _notifyRouterSlatLoaded: function () {
      this.router.handleSlatLoaded();
    },
    /**
     * Notify the Event Router that the requested slat is done. This'll
     * usually happen after someone has successfully saved.
     *
     * @param {object} payload - Data passed from the child widget to campaign type
     * @private
     */
    _notifyRouterSlatSaveSuccessful: function (payload) {
      this.router.handleSlatSaveSuccessful(payload);
    },
    /**
     * Notify the Event Router that the current slat has attempted to save but there was feedback
     *
     * @private
     */
    _notifyRouterSlatSaveFailed: function () {
      this.router.handleSlatSaveFailed();
    },
    /**
     * Notify the Event Router that someone opened the Native File browser
     *
     * @private
     */
    _notifyRouterOpenFileBrowse: function () {
      this.router.handleOpenFileBrowse();
    },
    /**
     * Formal Checklist items exist in the desktop view and are somewhat opinionated.
     * Reach out to the backend and get slat HTML and data in JSON format.
     * We'll ignore the HTML since we are unlikely to need it.
     *
     * @private
     */
    _initChecklistItem: function () {
      var data = {
        "id": this.campaignUniqueId,
        "itemId": this.slatName
      };
      var req = request.get(mUrl.toUrl("/ads/checklist", data), {
        handleAs: "json"
      });
      req.then(lang.hitch(this, "_handleChecklistItemResponse"), function (error) {
        throw new Error("An error occurred retrieving Checklist Item");
      });
    },
    /**
     * Not every slat is considered a true "checklist item", but more generic
     * Get the faux slat and instantiate the widget
     *
     * @private
     */
    _initGenericChecklistItem: function () {
      var slatInstance = this._getSlatConfig().widget;

      // Create the Generic Slat Widget
      this.slat = new slatInstance({
        id: this.campaignUniqueId,
        campaignType: this.campaignType,
        slatName: this.slatName,
        useSimulator: this.useSimulator,
        onLoaded: lang.hitch(this, "_notifyRouterSlatLoaded"),
        onDone: lang.hitch(this, "_notifyRouterSlatSaveSuccessful"),
        onDoneWithError: lang.hitch(this, "_notifyRouterSlatSaveFailed")
      });
      this.slat.placeAt(this.slatContainer);
      this.slat.startup();
    },
    /**
     * Now that we have the data, get going actually placing the slat in the dom.
     *
     * @param {obj} responseData the HTML and Slat Data from the checklist endpoint.
     * @private
     */
    _handleChecklistItemResponse: function (responseData) {
      var slatInstance = this._getSlatConfig().widget;

      // Create a new widget
      this.slat = new slatInstance({
        actionBarEnabled: false,
        onPostSuccess: lang.hitch(this, "_notifyRouterSlatSaveSuccessful"),
        onPostErrorFeedbackGiven: lang.hitch(this, "_notifyRouterSlatSaveFailed")
      });
      this.slat.placeAt(this.slatContainer);
      this.slat.startup();

      // Hydrate the slat data
      this.slat.setData(responseData.data);
      this._notifyRouterSlatLoaded();
    },
    /**
     * Retrieve info about the current slat configuration
     *
     * @return {*} configuration object
     * @private
     */
    _getSlatConfig: function () {
      return this.slatConfig[this.slatName];
    },
    /**
     * There are properties that will need to be configured on all child widgets. Throws error if needs
     * aren't met.
     *
     * @private
     */
    _validateProps: function () {
      if (!this.campaignType) {
        throw new Error("campaignType name undeclared. It is required.");
      }

      // We will be rolling out campaign types separately, we need a method in which to provide
      // error feedback.
      if (this.enabled === false) {
        throw new Error("The '" + this.campaignType + "'" + " campaign type is not currently enabled.");
      }

      // We'll need the unique ID to request slat data.
      if (!this.campaignUniqueId) {
        throw new Error("campaignUniqueId name undeclared. It is required.");
      }
    },
    /**
     * @throws Throw an error if the slat type is invalid or unimplemented.
     * @private
     */
    _validateCalledSlat: function () {
      if (!this.slatName) {
        throw new Error("slatName name undeclared. It is required.");
      }
      var validSlats = this.__getValidSlatNames();
      if (validSlats.indexOf(this.slatName) === -1) {
        throw new Error("'" + this.slatName + "'" + " is not a valid slat type for '" + this.campaignType + "' campaigns.");
      }

      // Surface a slightly different error for slats that are still in-progress.
      var disabledSlats = this.__getDisabledSlatNames();
      if (disabledSlats.indexOf(this.slatName) > -1) {
        throw new Error("the '" + this.slatName + "'" + " slat type not implemented yet for '" + this.campaignType + "' campaigns.");
      }

      // Surface error if there is a required feature flag that isn't met
      if (this._getSlatConfig().flag && !user.flagIsOn(this._getSlatConfig().flag)) {
        throw new Error("the '" + this.slatName + "'" + " slat type requires the '" + this._getSlatConfig().flag + "' flag to be enabled.");
      }

      // Surface error if the current user doesn't meet authentication requirements.
      // This is no replacement for backend validation. Just a way to have early notification.
      if (this._getSlatConfig().userLevel && !user.hasRole(this._getSlatConfig().userLevel)) {
        throw new Error("the '" + this.slatName + "'" + " slat requires the '" + this._getSlatConfig().userLevel + "' user level or higher.");
      }
    },
    /**
     * Get a list of what Slat names are appropriate for the native environment to be requesting
     */
    __getValidSlatNames: function () {
      throw new Error("getValidSlatNames unimplemented by Instance");
    },
    /**
     * Get a list of what slats are still in-progress.
     */
    __getDisabledSlatNames: function () {
      throw new Error("getDisabledSlatNames unimplemented by Instance");
    }
  });
});