define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dijit/form/Button", "mojo/utils/I18nTranslation"], function (declare, _Part, lang, domStyle, sync, Button, I18nTranslation) {
  var ascTpl;
  var descTpl;
  return declare([_Part], {
    alwaysVisible: false,
    constructor: function () {
      ascTpl = '<span class="!margin-right--lv0 freddicon arrow-up" title="Change sort direction"></span>';
      descTpl = '<span class="!margin-right--lv0 freddicon arrow-down" title="Change sort direction"></span>';
      I18nTranslation.initialize("I18N_REPORTING_TRANSLATIONS").then(function (values) {
        var ascTplStartTag = '<span class="!margin-right--lv0 freddicon arrow-up" title="';
        var descTplStartTag = '<span class="!margin-right--lv0 freddicon arrow-down" title="';
        var tplEndTag = '"></span>';
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          ascTpl = I18nTranslation.getTranslationString(values.sort_direction_change_direction_up, [ascTplStartTag, tplEndTag]);
          descTpl = I18nTranslation.getTranslationString(values.sort_direction_change_direction_down, [descTplStartTag, tplEndTag]);
        }
      });
    },
    init: function () {
      sync(this.target, "asc", this, "asc");
      this.button = new Button({
        'class': this.buttonClass ? this.buttonClass : 'button',
        'label': this.asc ? descTpl : ascTpl
      }).placeAt(this.domNode);
      this.button.startup();
      this.connect(this.button, 'onClick', function () {
        this.set('asc', !this.get('asc'));
      });
      this.watch('asc', lang.hitch(this, function () {
        this.button.set('label', this.asc ? descTpl : ascTpl);
      }));
      this.target.watch('sort', lang.hitch(this, '_toggleDisplay'));
      this._toggleDisplay();
    },
    canDisplay: function () {
      return this.alwaysVisible || this.inherited(arguments) && this.target.sort;
    },
    _toggleDisplay: function () {
      domStyle.set(this.domNode, 'display', this.canDisplay() ? '' : 'none');
    }
  });
});