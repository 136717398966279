define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dijit/registry", "dojox/html/entities", "mojo/analytics/parts/_Part", "mojo/url", "mojo/app/campaigns", "mojo/analytics/parts/MoveToFolder", "mojo/lib/logger", "vendor/pluralize/pluralize", "dojo/text!./templates/CampaignActions.html", "mojo/context", "mojo/lib/flags"], function (declare, lang, domClass, query, topic, on, _Widget, _Templated, registry, htmlEntities, _Part, murl, campaigns, MoveToFolder, logger, pluralize, templateString, context, flags) {
  return declare([_Part, _Widget, _Templated], {
    templateString: templateString,
    _toggleDisplay: function () {
      domClass.toggle(this.domNode, "c-campaignManager_selectActions--visible", this.target.hasSelection());
    },
    _toggleFolderSelectedMode: function (moveToFolderMenu) {
      moveToFolderMenu.isForFolder = this.campaignNav.selectedFolderId;
      this._replaceFolders(moveToFolderMenu);
      moveToFolderMenu.toggleRemoveButton(this.campaignNav.selectedFolderId);
      moveToFolderMenu.toggleAddButton(!this.campaignNav.selectedFolderId);
    },
    _buildMoveToFolderMenu: function () {
      var moveToFolderMenu = new MoveToFolder({
        target: this.target,
        baseUrl: murl.toUrl("/campaigns/folders"),
        folders: this.folders,
        shouldExcludeUnfiled: true,
        isReportsPage: this.target._isReportsPage(),
        onChange: lang.hitch(this, function (folderId, folderName) {
          this.campaignNav.set("folders", this.campaignNav.folders.map(function (folder) {
            return folder.id === folderId ? lang.mixin(folder, {
              name: folderName
            }) : folder;
          }));
        }),
        onEditCanceled: lang.hitch(this, function () {
          //Trigger a DOM rebuild to cover any additions that weren't edited
          this.campaignNav.set("folders", this.campaignNav.folders);
        }),
        onMove: lang.hitch(this, function (newFolders, folderId) {
          this.campaignNav.set("folders", newFolders.filter(function (folder) {
            return folder.id;
          }));
          moveToFolderMenu.updateCounts(newFolders);
          if (Number(folderId)) {
            this.target.clearFilter("bucket");
            this.target.clearFilter("type");
            this.target.setFilter("folder", folderId);
          } else {
            this.target.clearFilter("folder");
            this.target.setFilter("bucket", "recent");
          }
          Object.keys(this.target.selected).forEach(function (key) {
            if (key !== "_watchCallbacks" && this.target.selected[key] === true) {
              logger.googleAnalytics.trackEvent("reports", "move to folder", "campaigns", {
                log_classification: "sensitive"
              });
            }
          }, this);
        }),
        onAdd: lang.hitch(this, function (newFolder) {
          //Explicitly set folders to postpone DOM rebuild until after an edit or cancel
          this.campaignNav.folders = this.campaignNav.folders.concat(newFolder);
        })
      }, this.node_folderMoveButton);
      topic.subscribe("/campaigns/setFolders", this._replaceFolders.bind(this, moveToFolderMenu));
      this._toggleFolderSelectedMode(moveToFolderMenu, !!this.target.getFilter("folder"));
      this.target.watchFilter("folder", lang.hitch(this, function () {
        this._toggleFolderSelectedMode(moveToFolderMenu, !!this.target.getFilter("folder"));
      }));
      return moveToFolderMenu;
    },
    _replaceFolders: function (moveToFolderMenu) {
      //Remove all folders from list in-place to preserve StatefulArray reference
      while (moveToFolderMenu.folders.length > 0) {
        moveToFolderMenu.folders.pop();
      }

      //Add all folders to StatefulArray
      this.campaignNav.folders.slice().reverse().forEach(lang.hitch(this, function (folder) {
        if (folder.id && (!this.campaignNav.selectedFolderId || folder.id !== this.campaignNav.selectedFolderId)) {
          moveToFolderMenu.folders.push(folder);
        }
      }));
    },
    _buildDeleteFoldersButton: function (moveToFolderMenu) {
      on(this.node_deleteCampaignButton, "click", lang.hitch(this, function () {
        campaigns.confirmDeletion(this.target, "campaign", lang.hitch(this, function () {
          if (moveToFolderMenu) {
            moveToFolderMenu.request("view").then(lang.hitch(this, function (data) {
              moveToFolderMenu.updateCounts(data.folders);
              this.campaignNav.set("folders", data.folders.filter(function (folder) {
                return folder.id;
              }));
            }));
          }
          this.target.page = 1;
          this.target.render();
        }));
      }));
    },
    _buildDeselectAllButton: function () {
      on(this.node_deselectButton, "click", lang.hitch(this, function () {
        campaigns.trackDeselectAll(this.target.getSelection());
        this.target.toggleAll(false);
      }));
    },
    _buildSelectionCountLabel: function () {
      //Watch for changes to target's selection and toggle display accordingly.
      this.target.selected.watch(lang.hitch(this, function () {
        var selectedCount = Object.keys(this.target.selected).reduce(lang.hitch(this, function (acc, campaignId) {
          //target.selected contains a special _watchCallbacks key, so omit it from count
          acc += campaignId !== "_watchCallbacks" && this.target.selected[campaignId] ? 1 : 0;
          return acc;
        }), 0);
        this.node_selectionCount.innerText = selectedCount + " " + pluralize("campaign", selectedCount);
      }));
    },
    canDisplay: function () {
      return true;
    },
    init: function () {
      //Watch for changes to target's selection and toggle display accordingly.
      this.target.selected.watch(this._toggleDisplay.bind(this));

      //Initialize folders with globally injected set
      this.folders = window._mc.view_folders.filter(function (folder) {
        return folder.id;
      });
      this.campaignNav = registry.byId("campaigns-nav");
      this._buildSelectionCountLabel();
      this._buildDeselectAllButton();
      var moveToFolderMenu = this._buildMoveToFolderMenu();
      this._replaceFolders(moveToFolderMenu);
      this._buildDeleteFoldersButton(moveToFolderMenu);
    }
  });
});