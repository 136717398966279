define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/LinksEntry.html"], function (declare, Renderable, tpl) {
  var LinksEntry = declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.state = {
        links: props.links
      };
    }
  });
  return LinksEntry;
});