/**
 * Widget that handles just the actions within a status bar
 * @module mojo/widgets/StatusBarActions
 * @see mojo/widgets/StatusBar
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/StatusBarActions.html"], function (declare, domAttr, context, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  /**
   * Widget that is responsible for creating and updating actions of a status bar.
   */
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    rootUrl: context.rootUrl,
    // Passed in through widget declaration
    status: null,
    /**
     * Shortcut to update a dom node's inner text and title at once.
     *
     * @param {Node} pAction The dom node to update.
     * @param {string} pName The new text to display
     */
    _setActionName: function (pAction, pName) {
      pAction.innerText = pName;
      domAttr.set(pAction, "title", pName);
    }
  });
});