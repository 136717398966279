define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo) {
  // module:
  //		dojox/main

  /*=====
  return {
  	// summary:
  	//		The dojox package main module; dojox package is somewhat unusual in that the main module currently just provides an empty object.
  	//		Apps should require modules from the dojox packages directly, rather than loading this module.
  };
  =====*/

  return dojo.dojox;
});