/**
 * This widget should be mounted onto a clickable element to open the React PlanSelector (via the "plan-selector" reverse bridge)
 *
 * The "Reverse Bridge" is a new, more lightweight version of the "classic" bridge pattern
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin",
// Reverse Bridge
"mojo/reverse-bridge",
// For Monetization and Revenue Tracking Adapter (MARTA)
"mojo/lib/logger"], function (declare, on, _WidgetBase, _TemplatedMixin, ReverseBridge, logger) {
  var REDIRECT_PATH = window.location.pathname + window.location.search + window.location.hash;
  var PLAN_SELECTOR_REDIRECT_URI = "/checkout/plan?redirect" + REDIRECT_PATH;
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<button data-dojo-attach-point='buttonContainer' class='${containerClass}'></button>",
    featureName: "",
    redirectUrl: "",
    pricingPlanId: "",
    shouldSkipPlanSelector: false,
    martaID: "",
    buttonText: "",
    onUpgradeComplete: function () {},
    closeModal: function () {
      this.reverseBridgeUnmount();
    },
    openModal: function () {
      // re-using the below redirect from a previous iteration of this component - https://git.rsglab.com/product/mailchimp/pull/61333
      // This same thing is being done
      // if the url that the user is currently on contains /content/
      // then we redirect the users tab to the paywall instead of bringing up
      // a modal
      if (/^\/content\/?$/.test(window.location.pathname)) {
        window.location = window.location.origin + PLAN_SELECTOR_REDIRECT_URI;
        return;
      }
      var props = {
        isOpen: true,
        modalProps: {
          shouldSkipPlanSelector: this.shouldSkipPlanSelector,
          redirectUrl: this.redirectUrl,
          featureName: this.featureName,
          pricingPlanId: this.pricingPlanId
        },
        martaID: this.martaID,
        onUpgradeComplete: this.onUpgradeComplete ? this.onUpgradeComplete.bind(this) : function () {},
        onRequestClose: this.closeModal.bind(this)
      };
      this.reverseBridgeUnmount = ReverseBridge("plan-selector", props);
    },
    postCreate: function () {
      this.buttonContainer.innerText = this.buttonText;
      on(this.buttonContainer, "click", this.handleClick.bind(this));
    },
    handleClick: function () {
      logger.marta.trackEvent(this.martaID);
      this.openModal();
    }
  });
});