define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/form/Select", "dijit/form/TextBox", "./Color", "./_WithResetLinkMixin"], function (declare, domClass, domConstruct, array, lang, string, on, _WidgetBase, Select, TextBox, ColorInput, _WithResetLinkMixin) {
  return declare([_WidgetBase, _WithResetLinkMixin], {
    value: null,
    initialized: false,
    label: "Border",
    postCreate: function () {
      this.isOptional = true;
      this.inherited(arguments);
      this.originalValue = this.value;
      var border = this._parseBorder(this.value);
      var options = [{
        label: 'None',
        value: 'none'
      }, {
        label: 'Solid',
        value: 'solid'
      }, {
        label: 'Dashed',
        value: 'dashed'
      }, {
        label: 'Dotted',
        value: 'dotted'
      }, {
        label: 'Double',
        value: 'double'
      }, {
        label: 'Groove',
        value: 'groove'
      }, {
        label: 'Ridge',
        value: 'ridge'
      }, {
        label: 'Inset',
        value: 'inset'
      }, {
        label: 'Outset',
        value: 'outset'
      }];
      array.forEach(options, function (opt) {
        opt.label = "<span class='mojoPreviewSelect border-style " + opt.value + "'>" + opt.label + "</span>";
      });
      this.leftContainer = domConstruct.place("<div class='unit size1of2 !padding-left--lv0 full-width--xltablet'></div>", this.domNode);
      this.rightContainer = domConstruct.place("<div class='lastUnit size1of2 !padding-left-right--lv0 full-width--xltablet float--left'></div>", this.domNode);
      this.styleInput = new Select({
        options: options,
        maxHeight: -1,
        value: border.style,
        "class": "!margin-bottom--lv1"
      }).placeAt(this.leftContainer);
      var widthWrap = domConstruct.place('<div class="float-left mojoBorderWidth"></div>', this.rightContainer);
      this.widthInput = new TextBox({
        value: border.width
      }).placeAt(widthWrap);
      domClass.add(this.widthInput.domNode, 'small-number');
      this.colorInput = new ColorInput({
        value: border.color,
        skipContainer: true
      });
      this.colorInput.startup();
      this.colorInput.placeAt(this.rightContainer);
      this.own(on(this.widthInput, 'change', lang.hitch(this, function () {
        this.widthInput.set("value", this._getWidthValue(), false);
        this._onChange();
      })), on(this.styleInput, "change", lang.hitch(this, function () {
        this._toggleStyleInputs(this.styleInput.value);
        this._onChange();
      })), on(this.colorInput, 'change', lang.hitch(this, '_onChange')));

      // Toggle additional style inputs
      this._toggleStyleInputs(border.style);
      this.toggleResetLink(this.value !== this.resetValue && this.value !== "" && this.value !== 0);
      this.initialized = true;
    },
    _setValueAttr: function (rawValue) {
      this.inherited(arguments);
      if (this.initialized) {
        this.setInputValues(rawValue);
        var value = this.get("value");
        this.toggleResetLink(value !== this.resetValue && value !== "" && value !== 0);
        this.onChange(value);
      }
    },
    _getValueAttr: function () {
      if (!this.initialized) {
        return this.inherited(arguments);
      }
      var widthValue = this._getWidthValue();
      if (widthValue) {
        return widthValue + " " + this.styleInput.get("value") + " " + this.colorInput.get("value");
      }
      return this.resetValue || 0;
    },
    /**
     * Return the width value.
     * Use "px" as the default unit. If em is set, we use that.
     * Negative numbers are set to 0 and 0 has no unit.
     */
    _getWidthValue: function () {
      var widthValue = this.widthInput.get("value");
      var unit = "px";
      var number;
      if (widthValue.indexOf("em") >= 0) {
        unit = "em";
        number = parseFloat(widthValue);
      } else {
        number = parseInt(widthValue);
      }
      number = isNaN(number) || number < 0 ? 0 : number;
      return number ? number + unit : number;
    },
    _parseBorder: function (value) {
      if (value === this.resetValue) {
        value = "";
      }
      var safeValue = value ? value : "";
      var splitBorder = safeValue.replace(";", "").split(" ");
      var widthValue = splitBorder[0] ? splitBorder[0] : "0";
      var styleValue = splitBorder[1] ? string.trim(splitBorder[1]) : "none";
      var colorInput = splitBorder[2] ? string.trim(splitBorder[2]) : "";
      return {
        "width": string.trim(widthValue),
        "style": string.trim(styleValue),
        "color": colorInput
      };
    },
    setInputValues: function (rawValue) {
      if (this.initialized) {
        var border = this._parseBorder(rawValue);
        this.widthInput.set('value', border.width, false);
        this.styleInput.set('value', border.style, false);
        this.colorInput.set('value', border.color, false);
        this.colorInput._updateColor();
      }
    },
    /**
     * Toggle additional border width and color inputs
     *
     * @param {string} borderValue - current border value
     *
     * @private
     */
    _toggleStyleInputs: function (borderValue) {
      if (borderValue === "none") {
        // Hide additional inputs
        domClass.add(this.rightContainer, "hide");
      } else {
        // Override 0px border
        if (parseInt(this.widthInput.value, 10) === 0) {
          this.widthInput.set("value", "1px", false);
        } else {
          this.widthInput.set("value", this._getWidthValue(), false);
        }
        // Show additional inputs
        domClass.remove(this.rightContainer, "hide");
      }
    },
    onChange: function (value) {},
    _onChange: function () {
      var value = this.get("value");
      this.toggleResetLink(value !== this.resetValue && value !== "" && value !== 0);
      this.onChange(value);
    },
    onReset: function () {
      this.set("value", this.params.resetValue || "0");
      this.styleInput.set("value", "none", false);
      this._toggleStyleInputs("none");
    },
    reset: function () {
      this.set("value", this.originalValue);
      this.setInputValues(this.originalValue);
    }
  });
});