/**
 * Widget built to manage the edit state of the Ad Channel (aka Connect) Facebook slat.
 *
 * @module mojo/widgets/ads/facebook/EditConnectSlat
 * @see mojo/widgets/ads/_EditSlatMixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dijit/registry", "mojo/url", "mojo/utils", "vendor/lodash-amd/merge", "./edit-connect/PageInput", "./edit-connect/ChannelItem",
// Mixins
"mojo/widgets/ads/checklist/_EditSlatMixin",
// Templates
"dojo/text!./edit-connect/templates/EditConnectSlat.html"], function (declare, lang, domClass, on, request, registry, mUrl, utils, merge, PageInput, ChannelItem, _EditSlatMixin, tplStr) {
  /**
   * Base widget reflecting the core pieces necessary to operate the connect slat.
   */
  return declare([_EditSlatMixin], {
    content: tplStr,
    slatId: "connect",
    postEndpoint: "/ads/facebook/post-connect/",
    // Collection of page info
    pages: null,
    // Collection of channels and placements
    channels: null,
    channelWidgets: [],
    permissionsMsg: "To run an ad, we need permission to manage your business, manage your Pages, and publish as Pages you manage. <a href='_link_'>Update your permissions</a>.",
    authEndpoint: "/facebook/login?redir=ads|_unique_id_",
    /**
     * Setup the data for this slat.
     *
     * @param {obj} data - an object with keys for ad_id (the unique one, of course) and end_time.
     */
    setData: function (data) {
      // Assign vars
      this.adId = data.ad_id;
      this.pages = data.pages;
      this.channels = data.placements;
      this.hasAllPermissions = data.has_all_permissions;

      //Don't try to load pages if permissions are missing.
      if (this.hasAllPermissions) {
        // Start child widgets before removing loader.
        this._initPages();
      } else {
        this._handleMissingPermissions();
      }
      this._toggleLoadingIndicator();
    },
    _handleMissingPermissions: function () {
      var link = mUrl.toUrl(this.authEndpoint.replace("_unique_id_", this.adId));
      utils.addError(this.errorAnchor, this.permissionsMsg.replace("_link_", link));
    },
    /**
     * Called from within the template. Format the data that's going to the backend and POST out to the endpoint.
     */
    handleSave: function () {
      var formData = {
        id: this.adId,
        page_id: this._getSelectedPage().pageId
      };

      // Merge formData from each channel
      this.channelWidgets.forEach(function (channel) {
        formData = lang.mixin(formData, channel.getFormData());
      });
      this.postForm(formData);
    },
    /**
     * If the backend returned errors, surface them in the interface.
     *
     * @param {obj} response - {status: "error", errors: {field: "There's something wrong."}}.
     */
    onPostError: function (response) {
      var currentError = response.errors[Object.keys(response.errors)[0]];
      utils.addError(this.errorAnchor, currentError);
    },
    /**
     * Pass the page data onto PageInput widget and create the placement widgets.
     */
    _initPages: function () {
      this.input = new PageInput({
        data: this.pages
      });
      this.input.placeAt(this.pageInput);
      this.input.startup();
      this.input.watch("page", lang.hitch(this, "_handleSelection"));
      this._handleSelection();
    },
    /**
     * Parse the placement data and create all of the necessary ChannelItems.
     */
    _initChannels: function () {
      this.channels.forEach(lang.hitch(this, "_createChannel"));
    },
    /**
     * Hide the loader and show the editing interface in it's place.
     */
    _toggleLoadingIndicator: function () {
      domClass.toggle(this.loadingState, "hide");
      domClass.toggle(this.readyState, "hide");
    },
    /**
     * Every time a page is changed or when the widget is first loaded, do these actions.
     */
    _handleSelection: function () {
      this._setPageData();
      this._resetChannelItems();
      this._initChannels();
    },
    /**
     * Merge placement data with the selected page data for rendering valid icons and enabled/disabled status.
     */
    _setPageData: function () {
      this.channels = merge(this.channels, this._getFormattedPageData());
    },
    /**
     * Create a new ChannelItem. Pulled out into function for easy overriding.
     * @param {obj} channel - The currently iterated over channel from this.initChannels().
     */
    _createChannel: function (channel) {
      var item = new ChannelItem({
        data: channel
      });
      item.placeAt(this.channelItems);
      item.startup();
      this.channelWidgets.push(item);
    },
    /**
     * On change of the page, we're reloading the entire placement section. Before we do that, we need to kill off
     * any old widgets and remove their domNodes.
     */
    _resetChannelItems: function () {
      registry.findWidgets(this.channelItems).forEach(function (widget) {
        widget.destroyRecursive(false);
      });

      // Reset this.channelWidgets since we'll need to fill it again.
      this.channelWidgets = [];
    },
    /**
     * @returns {obj} - Selected page as selected from PageInput
     */
    _getSelectedPage: function () {
      return this.input.get("page");
    },
    /**
     * To properly merge placement data with page data for clean passing to ChannelItems, we need to format the
     * data in a certain way. This is one of the few places you'll see specific mention of Instagram.
     *
     * @return {[*,*]} - Collection of page data.
     */
    _getFormattedPageData: function () {
      var selectedPage = this._getSelectedPage();
      return [{
        enabled: true,
        iconUrl: selectedPage.iconUrl
      }, {
        enabled: selectedPage.instagram.enabled,
        iconUrl: selectedPage.instagram.iconUrl
      }];
    }
  });
});