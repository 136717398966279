define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/ImageBlock", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "dojo/text!../editors/blocks/templates/ImageEditor/outputWeb.html"], function (declare, ImageBlock, lang, user, flags, tpl) {
  return declare("mojo.neapolitan.web-renderers.ImageBlock", [ImageBlock], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      var model = {
        innerPadding: this.innerPadding,
        outerPadding: this.outerPadding
      };
      if (meta.edgeToEdge) {
        model.innerPadding = 0;
        model.outerPadding = 0;
      }
      if (meta.image) {
        // imageData is the object returned if there's an image in the block
        var imageData = lang.mixin(model, meta.image);
        var imageClass = "";
        var maxWidth;
        var calculatedWidth = "auto";

        // Constrain the max-width of an image if retina box is checked
        // Apply the mcnRetinaImage, use it to set to max-width:100% on mobile
        if (meta.retina) {
          // If an image is smaller than its container calculate its retina width
          if (imageData.width < meta.containerWidth) {
            calculatedWidth = imageData.width / 2;
            maxWidth = "100%";
            imageClass = "mcnImage";
          } else {
            maxWidth = "50%";
            imageClass = "mcnRetinaImage";
          }
        } else {
          maxWidth = "100%";
          imageClass = "mcnImage";
        }

        // Update calculatedWidth if image slider is used.
        if (meta.customSize) {
          // To avoid the slider inadvertently increasing size of a gif/image set calculatedWidth to the image
          // width instead of "auto"
          calculatedWidth = meta.containerWidth > meta.image.width ? meta.image.width : meta.containerWidth;
          var customSize = parseInt(meta.customSize, 10);
          calculatedWidth = calculatedWidth * (customSize / 100);
        }
        imageData.align = meta.align;
        imageData.imageClass = imageClass;
        imageData.maxWidth = maxWidth;
        imageData.calculatedWidth = calculatedWidth;
        return imageData;
      }
      return lang.mixin(model, {
        src: null,
        calculatedWidth: meta.containerWidth
      });
    }
  });
});