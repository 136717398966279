define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dijit/registry", "mojo/app/account/clients/revokeAccess"], function (declare, base, lang, domAttr, on, url, registry, revokeAccess) {
  return declare([base], {
    requestKey: null,
    onReady: function () {
      if (this.$group.removeAccountBtn) {
        on(this.$group.removeAccountBtn, "click", lang.hitch(this, function (e) {
          revokeAccess.showRemoveAccountConfirmation(domAttr.get(e.target, "data-client-id"));
        }));
      }
    },
    /**
     * Called from _remove-account-dialog
     */
    submitRemoveAccount: function () {
      revokeAccess.doRemoveAccount();
    },
    /**
     * Shows cancel request dialog and saves request key for later
     * @param {number} pRequestKey - Unique id of request from backend
     */
    showCancelRequestConfirmation: function (pRequestKey) {
      this.requestKey = pRequestKey;
      registry.byId("cancel-request-dialog").show();
    },
    /**
     * Sends to cancel access request
     */
    doCancelRequest: function () {
      window.location = url.toUrl("/account/clients/cancel-request?key={$request->request_key}", {
        "key": this.requestKey
      });
    }
  });
});