define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, query, domConstruct, array, _Block) {
  return declare([_Block], {
    type: "socialFollow",
    _name: 'Social Follow',
    _kbLink: "https://mailchimp.com/help/use-social-follow-content-blocks/",
    constructor: function () {
      this.blockLayout = 'horizontal';
      this.itemArrangement = 'inline';
      this.iconStyle = 'color';
      this.iconType = 'solid';
      this.display = 'icon';
      this.align = 'center';
      this.adjustableWidth = '100%';
      this.items = [];
      this.styles = [{
        "name": "Container style",
        "selector": ".mcnFollowContent",
        "props": {
          "background-color": {
            "value": ""
          },
          "border": {
            "value": ""
          }
        }
      }, {
        "name": "Text style",
        "selector": ".mcnFollowTextContent a",
        "props": {
          "color": {
            "value": "#202020"
          },
          "font-family": {
            "value": "Arial"
          },
          "font-size": {
            "value": "11px"
          },
          "font-weight": {
            "value": "normal"
          },
          "text-decoration": {
            "value": "none"
          }
        }
      }];
    },
    postscript: function (params) {
      this.inherited(arguments);
      if (!this.items.length) {
        this.items = [{
          type: 'facebookFollow'
        }, {
          type: 'twitterFollow'
        }, {
          type: 'genericLink'
        }];
      }
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var content = this.getContent();
      var link = query('a', content)[index];
      if (link) {
        var iconContainer = query(link).parents('.mcnFollowIconContent')[0];
        var itemIndex = null;
        if (iconContainer) {
          var iconContainers = query('.mcnFollowIconContent', content);
          itemIndex = array.indexOf(iconContainers, iconContainer);
        } else {
          var textContainer = query(link).parents('.mcnFollowTextContent')[0];
          if (textContainer) {
            var textContainers = query('.mcnFollowTextContent', content);
            itemIndex = array.indexOf(textContainers, iconContainer);
          }
        }
        if (itemIndex >= 0) {
          if (this.items[itemIndex] && this.items[itemIndex].link == oldUrl) {
            this.items[itemIndex].link = newUrl;
            return this.save();
          }
        }
      }
      return this.inherited(arguments);
    }
  });
});