/**
 * constants shared in the /mojo directory
 */
define([], function () {
  var keys = {
    // StaticInlineRec variations
    STATIC_INLINE_REC_CAMPAIGN_MANAGER: "CampaignManagerStaticRecDismissed",
    STATIC_INLINE_REC_CJB: "CJBStaticRecDismissed",
    STATIC_INLINE_REC_INTEGRATIONS: "IntegrationsStaticRecDismissed"
  };
  return keys;
});