define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dijit/registry', 'mojo/utils', "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'mojo/charts', 'mojo/url'], function (query, dom, domClass, domStyle, domAttr, domConstruct, on, registry, utils, ready, charts, mUrl) {
  var dash = {
    //update content panes for dashboard
    updateContentPane: function (el, val) {
      var container = registry.byId(domAttr.get(el, 'data-target'));
      var url = domAttr.get(el, 'data-url');
      if (container && url) {
        container.set('href', url + '?id=' + val);
        domAttr.set(dom.byId('recent-campaigns-view-report'), 'href', mUrl.toUrl('reports/summary', {
          id: val
        }));
      }
    }
  };

  //On ready
  ready(function () {
    var body = query("body")[0];
    if (query('.content-selector')[0]) {
      query('.content-selector').forEach(function (el) {
        dash.updateContentPane(el, registry.byNode(el).value);
      });
    }
  });
  return dash;
});