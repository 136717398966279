/**
 * Mixin that adds all the selectable logic.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful"], function (declare, lang, Stateful) {
  return declare(null, {
    constructor: function () {
      this.selected = new Stateful();
    },
    hasSelection: function () {
      for (var value in this.selected) {
        if (this._valueSelected(value)) return true;
      }
      return false;
    },
    getSelection: function () {
      var selection = [];
      for (var value in this.selected) {
        if (this._valueSelected(value)) selection.push(value);
      }
      return selection;
    },
    isAllSelected: function () {
      var result = true;
      for (var value in this.selected) {
        if (this._validValue(value)) {
          result = result && this.selected[value];
        }
      }
      return result;
    },
    toggleAll: function (selection) {
      for (var value in this.selected) {
        if (this._validValue(value)) {
          this.selected.set(value, selection);
        }
      }
    },
    /**
     * Sets the value of any selection to false to trigger any remaining events and removes it from the model.
     * Call this when paging or when the view changes to keep the selection model in sync with the page.
     */
    clearSelectionModel: function () {
      for (var value in this.selected) {
        if (this._validValue(value)) {
          this.selected.set(value, false);
          delete this.selected[value];
        }
      }
    },
    /**
     * Clear selection, then check off certain items.
     *
     * @param {String[]} values The values to check off
     */
    setSelection: function (values) {
      this.clearSelectionModel();
      values.forEach(function (value) {
        this.selected.set(value, true);
      }.bind(this));
    },
    /**
     * Check if this value is selected.
     */
    _valueSelected: function (value) {
      return this._validValue(value) && this.selected[value] === true;
    },
    /**
     * Since dojo/Stateful keeps some private attributes that start with _, we should not consider those.
     */
    _validValue: function (value) {
      return this.selected.hasOwnProperty(value) && value.indexOf("_") != 0 && this.selected[value] != null;
    }
  });
});