/**
 * An input to pick a date & time. Expects value to be formatted as YYYY-MM-DD HH:MM
 * You can optionally pass a `tzLabel` to have a label for the timezone.
 *
 * @author Guan Liao
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/dateTime.html", "dojo/string", "dojo/date/locale", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/DateTextBox"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, string, locale, array, lang, on, domConstruct, DateTextBox) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    label: "Date / Time",
    templateString: tpl,
    tzLabel: "",
    datePattern: "MMM d yyyy",
    minDate: null,
    required: true,
    minuteValues: ["00", "15", "30", "45"],
    // Input name attribute
    name: "",
    value: "",
    postCreate: function () {
      var dateStr = null;
      if (this.value) {
        var dateTime = this._parseDateTime(this.value);
        dateStr = dateTime.date;
        this.hourSelect.value = dateTime.time.hour;
        this._initMinuteSelect(dateTime.time.minute);
        this.meridiemSelect.value = dateTime.time.meridiem;
      }
      this.dateInput = new DateTextBox({
        "value": dateStr,
        "required": this.required,
        "constraints": {
          "min": this.minDate,
          "datePattern": this.datePattern
        }
      }, this.dateInput);
      this.dateInput.startup();
      this.own(on(this.hourSelect, "change", lang.hitch(this, "_updateFormInput")), on(this.minuteSelect, "change", lang.hitch(this, "_updateFormInput")), on(this.meridiemSelect, "change", lang.hitch(this, "_updateFormInput")), on(this.dateInput, "change", lang.hitch(this, "_updateFormInput")));
      this._updateFormInput();
    },
    _parseDateTime: function (value) {
      var split = value.split(" ");
      var date = lang.trim(split[0]);
      var time = lang.trim(split[1]);
      var timeSplit = time.split(":");
      var hour = +lang.trim(timeSplit[0]);
      var minute = +lang.trim(timeSplit[1]);
      var meridiem = hour >= 12 ? "PM" : "AM";
      if (hour != 12) {
        hour = hour === 0 ? 12 : hour % 12;
      }
      return {
        "date": date,
        "time": {
          "hour": hour,
          "minute": minute,
          "meridiem": meridiem
        }
      };
    },
    _updateFormInput: function () {
      if (this.dateInput.value) {
        var dateStr = locale.format(this.dateInput.value, {
          "selector": "date",
          "datePattern": "y-MM-dd"
        });
        var timeStr = this._getHourValue() + ":" + this.minuteSelect.value;
        var value = dateStr + " " + timeStr;
        this.formInput.value = value;
        this.value = value;
        this.onChange(value);
      }
    },
    _getHourValue: function () {
      var hourVal = +this.hourSelect.value;
      var meridiem = this.meridiemSelect.value;
      if (hourVal === 12 && meridiem === "AM") {
        hourVal = 0;
      } else if (meridiem === "PM" && hourVal !== 12) {
        hourVal += 12;
      }
      hourVal = string.pad(hourVal, 2, "0");
      return hourVal;
    },
    /**
     * Initilize the minute select box by adding options for the minute select and hook onchange events.
     * @param {Number} minuteValue
     */
    _initMinuteSelect: function (minuteValue) {
      var self = this;
      array.forEach(this.minuteValues, function (val) {
        var opt = domConstruct.create("option", {
          "value": val,
          "label": val,
          "innerHTML": val
        });
        self.minuteSelect.add(opt);
      });
      if (minuteValue) {
        this._setMinutes(minuteValue);
      }
    },
    _setMinutes: function (minute) {
      if (this._validMinute(minute)) {
        this.minuteSelect.value = minute;
      } else {
        array.some(this.minuteValues, lang.hitch(this, function (minVal) {
          if (minVal > minute) {
            this.minuteSelect.value = minVal;
            return true;
          } else {
            return false;
          }
        }));
        if (!this.minuteSelect.value) {
          this.minuteSelect.value = this.minuteValues[0];
        }
      }
    },
    /**
     * Given a minute value, checks if this DateTime allows that minute value
     * @param {Number} minute
     * @private
     */
    _validMinute: function (minute) {
      return array.some(this.minuteValues, function (v) {
        return minute && +v === +minute;
      });
    },
    onChange: function (value) {}
  });
});