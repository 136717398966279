/**
 * A widget that represents a filterable select input for our segmentation UI.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/form/FilteringSelect", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/store/Memory", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_SegmentInputMixin"], function (declare, _WidgetBase, _FilteringSelect, domConstruct, domAttr, Memory, lang, array, _SegmentInputMixin) {
  return declare([_FilteringSelect, _SegmentInputMixin], {
    value: null,
    options: null,
    constructor: function () {
      this.set("searchAttr", "label");
      this.set("labelAttr", "label");
    },
    /**
     * On startup, inject the list of options into the store
     */
    startup: function () {
      var store = new Memory({
        data: this.options,
        idProperty: "value"
      });
      this.set("store", store);
    },
    /**
     * Get display label to show when no longer editing this condition
     * @return {string} value to display when the select is not in use
     */
    getDisplayHTML: function () {
      if (this.displayedValue) {
        return this.displayedValue;
      }
      return "(Invalid value)";
    },
    /**
     * Get selected value
     * @return {Object} selected value
     */
    getValue: function () {
      return this.value;
    },
    /**
     * Set selected value
     * @param {Object} value selected
     */
    setValue: function (value) {
      this.set("value", value);
    }
  });
});