define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, base, domAttr, on, FilterMenu, FilterMessage) {
  return declare([base], {
    onReady: function () {
      var filterSettings = {
        "target": "ledger-left-list",
        "filters": [{
          "label": "Since",
          "key": "from",
          "type": "text"
        }, {
          "label": "Up to",
          "key": "to",
          "type": "text"
        }, {
          "label": "Type",
          "key": "type",
          "type": "select",
          "options": {
            "purchase": "purchase",
            "usage": "usage",
            "expiration": "expiration",
            "adjustment": "adjustment",
            "usage canceled": "usage canceled",
            "initial credit balance": "initial credit balance",
            "plan conversion": "plan conversion",
            "refund": "refund"
          }
        }]
      };
      var filter = new FilterMenu(filterSettings);
      this.$el.ledgerLeftListFilters.appendChild(filter.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.ledgerLeftListFiltersMessage.appendChild(filterMessage.domNode);
    },
    /**
     * Trigger the dialog that presents options to change pay go credit expiration
     *
     * @param {number} id - the id of an pay go credit
     * @param {string} date - the date that the expiration should be changed to
     */
    changeExpirationDate: function (id, date) {
      this.$el.payGoCreditIdVal.value = id;
      this.$el.expirationDate.value = date;
      this.$widget.payGoCreditExpirationModifyWarningArea.show();
    },
    /**
     * Trigger the dialog that presents options to change created at
     *
     * @param {number} id - the id of an pay go credit
     * @param {string} date - the date that the created at date should be changed to
     */
    changeCreatedAtDate: function (id, date) {
      this.$el.payGoCreditId.value = id;
      this.$el.createdAtDate.value = date;
      this.$widget.payGoCreditCreatedAtModifyWarningArea.show();
    }
  });
});