define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, base, mUrl, xhr, domClass, dom, utils, on, lang, registry) {
  return declare([base], {
    onReady: function () {
      on(this.$group.bulk_actions, "click", lang.hitch(this, function () {
        this.toggleDelete();
      }));
    },
    toggleDelete: function () {
      var btn = dom.byId("perform-update-btn");
      var formatLink = dom.byId("format-link");
      var form = dom.byId("cs-batch-update-form");
      if (this.$el.remove_tier_type.checked) {
        btn.innerHTML = "Remove Tier and CSM";
        btn.onclick = this.openDialog;
        formatLink.innerHTML = "correctly formatted CSV for removing tier and CSM";
        formatLink.href = "#";
        formatLink.onclick = lang.hitch(this, function () {
          this.downloadCSVTemplate("remove");
        });
      } else {
        btn.innerHTML = "Perform Update";
        btn.onclick = function (e) {
          form.submit();
        };
        formatLink.innerHTML = "correctly formatted CSV for adding/editing CS info";
        formatLink.href = "#";
        formatLink.onclick = lang.hitch(this, function () {
          this.downloadCSVTemplate("add-edit");
        });
      }
    },
    validate: function (event) {
      if (event.target.value === "DELETE") {
        dom.byId("confirm-cs-delete-btn").disabled = false;
      } else {
        dom.byId("confirm-cs-delete-btn").disabled = true;
      }
    },
    openDialog: function (event) {
      event.preventDefault();
      registry.byId("delete-cs-data-dialog").show();
    },
    performDelete: function () {
      var form = dom.byId("cs-batch-update-form");
      form.submit();
    },
    downloadCSVTemplate: function (type) {
      var keys = [];
      var downloadTitle = "";
      switch (type) {
        case "remove":
          keys = ["UID (required)", "Notes / Errors (System Generated)"];
          downloadTitle = "Batch Remove Template.csv";
          break;
        case "add-edit":
          keys = ["UID (required)", "CSM Email", "Account Tier", "CSM Name (System Generated)", "Notes / Errors (System Generated)"];
          downloadTitle = "Batch Add or Edit Template.csv";
          break;
        default:
          break;
      }
      var csvString = ["DO NOT DELETE THIS LINE OR COLUMN TITLES. ENTER INFORMATION BELOW THE COLUMN TITLES (BEGINNING ON LINE 3).", keys.join(",")].join("\n");

      // Download CSV to Client
      var downloadLink = document.createElement("a");
      downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
      downloadLink.download = downloadTitle;
      downloadLink.click();
    }
  });
});