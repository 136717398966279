define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils"], function (declare, base, mUrl, xhr, domClass, dom, utils) {
  return declare([base], {
    exportCSUpdateData: function (e) {
      domClass.add(dom.byId("export-cs-update-data-btn"), "button-loading disabled");
      xhr(mUrl.toUrl("/peaches2/tools/customer-success/export-update-history"), {
        handleAs: "json"
      }).then(function (response) {
        domClass.remove(dom.byId("export-cs-update-data-btn"), "button-loading disabled");
        if (response.status === "success") {
          // Convert Response Array to CSV String
          var data = response.data.map(function (item) {
            return [item.user_id, item.email, item.customer_success_status, item.fname + " " + item.lname];
          });
          var keys = ["UID", "CSM Email", "Tier", "CSM Name"];
          var csvString = [keys.join(","), data.join("\n")].join("\n");

          // Download CSV String to Client
          var downloadLink = document.createElement("a");
          downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
          downloadLink.download = "CS Update History.csv";
          downloadLink.click();
        } else {
          utils.globalPublish("show-toast", [{
            "message": "An error occurred while trying to generate CSV: " + response.exception,
            "type": "error"
          }]);
        }
      });
    },
    /**
     * @param {Number} batchId Id of the Batch
     * @param {Object} event Event Object
     */
    downloadBatchCSV: function (batchId, event) {
      var targetId = event.target.id;
      domClass.add(dom.byId(targetId), "button-loading disabled");
      xhr(mUrl.toUrl("/peaches2/tools/customer-success/retrieve-records-for-batch", {
        batch_id: batchId
      }), {
        handleAs: "json"
      }).then(function (response) {
        domClass.remove(dom.byId(targetId), "button-loading disabled");
        if (response.status === "success") {
          // Convert Response Array to CSV String
          var data = response.data.map(function (item) {
            return [item.user_id, item.email, item.customer_success_status, item.fname + " " + item.lname, item.note || "N/A"];
          });
          var keys = ["UID", "CSM Email", "Account Tier", "CSM Name", "Notes / Errors"];
          var csvString = [keys.join(","), data.join("\n")].join("\n");

          // Download CSV String to Client
          var downloadLink = document.createElement("a");
          downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
          downloadLink.download = "Batch Update Data.csv";
          downloadLink.click();
        } else {
          utils.globalPublish("show-toast", [{
            "message": "An error occurred while trying to generate CSV: " + (response.exception || "Unknown Exception."),
            "type": "error"
          }]);
        }
      });
    },
    downloadFixCSV: function (invalidBatchData, event) {
      var targetId = event.target.id;
      var bulkAction = invalidBatchData.bulk_action;
      var data = invalidBatchData.data;
      var keys = ["UID", "CSM Email", "Account Tier", "CSM Name (System Generated)", "Notes / Errors (System Generated)"];
      if (bulkAction === "remove_tier_type") {
        keys = ["UID", "Notes / Errors (System Generated)"];
      }
      var entries = [];
      if (bulkAction === "remove_tier_type") {
        entries = data.map(function (item) {
          return [item.user_id, item.notes_errors];
        });
      } else {
        entries = data.map(function (item) {
          return [item.user_id, item.csm_email, item.account_tier, item.csm_name ? item.csm_name : "", item.notes_errors];
        });
      }
      var csvString = ["DO NOT DELETE THIS LINE OR COLUMN TITLES. ENTER INFORMATION BELOW THE COLUMN TITLES (BEGINNING ON LINE 3).", keys.join(","), entries.join("\n")].join("\n");

      // Download CSV String to Client
      var downloadLink = document.createElement("a");
      downloadLink.href = "data:text/csv;charset=utf-8," + encodeURI(csvString);
      downloadLink.download = "Batch Update Failures.csv";
      downloadLink.click();
    }
  });
});