define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/DateTextBox", "./_SegmentInputMixin", "dojo/date/locale", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, DateTextBox, _SegmentInputMixin, locale, user, flags, logger, I18nTranslation, context) {
  var blankText = "blank";
  return declare([DateTextBox, _SegmentInputMixin], {
    "class": "auto-width condition-value",
    /**
     * Override the default dijit behavior. If no valid date is present during creation, sets it to now.
     */
    constructor: function () {
      if (context.flagIsOn(flags.SEGMENTATION_FIX_FOR_BETWEEN_DATE)) {
        if (!this.value || this.value.toString() === this._invalidDate) {
          this.value = new Date();
        }
      } else if (!this.value || this.value === this._invalidDate) {
        this.value = new Date();
      }
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    getDisplayHTML: function () {
      if (this.isValid()) {
        if (this.isBlank()) {
          return "<span class=\"dim6\">(" + blankText + ")</span>";
        }
        return locale.format(this.get("value"), {
          "selector": "date",
          formatLength: "medium"
        });
      }
      return "<div class=\"invalid-error !margin--lv0\">an invalid date</div>";
    },
    getValue: function () {
      var value = "";
      if (context.flagIsOn(flags.SEGMENTATION_FIX_FOR_BETWEEN_DATE)) {
        if (this.value && this.value.toString() !== this._invalidDate) {
          value = this.toString();
        }
      } else if (this.value && this.value !== this._invalidDate) {
        value = this.toString();
      }
      return value;
    },
    setValue: function (value) {
      this.set("value", value);
    },
    isBlank: function () {
      return this.get("value") ? false : true;
    },
    // Publish a Segment Engaged event to the Events Pipeline
    _logToEventsPipeline: function () {
      var sharedEventProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        object: "segment",
        object_detail: "create_segment",
        action: "engaged",
        ui_object: "drop_down",
        ui_object_detail: "segment_conditions",
        ui_action: "clicked",
        ui_access_point: "form_segment",
        segmentation_method: "advanced",
        condition_field: null
      };
      var contactTableProperties = Object.assign({
        scope_area: "contacts",
        screen: "lists/members"
      }, sharedEventProperties);
      var emailChecklistProperties = Object.assign({
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit"
      }, sharedEventProperties);

      // If event is from the Email Checklist page
      if (window.location.pathname.startsWith("/i/campaigns/edit")) {
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        var campaign_id = parseInt(new URLSearchParams(window.location.search).get("id"), 10);
        // Add campaign_id to email checklist properties
        emailChecklistProperties.campaign_id = campaign_id;
        logger.eventsPipeline.publish("segment:engaged", emailChecklistProperties);
        // If event is from the Contact Table page
      } else if (window.location.pathname.startsWith("/i/lists/members")) {
        logger.eventsPipeline.publish("segment:engaged", contactTableProperties);
      }
    },
    onChange: function () {
      this._logToEventsPipeline();
    }
  });
});