define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/user", "mojo/lib/flags",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/tag-badge.html"], function (declare, lang, on, domClass, htmlentities, user, flags, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, buttonTplString) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: buttonTplString,
    readOnly: true,
    tag: null,
    tagName: null,
    maxTagLength: 15,
    /**
     * Custom setter to truncate long tag names
     *
     * @param {string} tagName visible name of tag
     * @private
     */
    _setTagNameAttr: function (tagName) {
      tagName = String(tagName);
      var truncatedTag = tagName;
      if (tagName.length > this.maxTagLength) {
        truncatedTag = tagName.substring(0, this.maxTagLength) + "...";
        this.tagButtonContainer.title = tagName;
      }
      this.tagLabel.innerText = truncatedTag;
      this._set("tagName", tagName);
    },
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      var self = this;
      if (!this.readOnly) {
        domClass.remove(this.deleteTagButton, "hide");
        this.own(on(this.deleteTagButton, "click", function () {
          self.onRemove(self.tag);
        }));
      }
    },
    /**
     * Publish event to remove tag
     */
    onRemove: function (tag) {}
  });
});