define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function domainChecklistApi(xhr, mUrl) {
  var exports = {};
  var PROVIDER_REGISTRARS = {
    dreamhost: "Dreamhost",
    godaddy: "GoDaddy",
    hover: "Hover",
    ionos: "IONOS",
    namecheap: "NameCheap",
    siteground: "Siteground",
    squarespace: "Squarespace",
    wordpress: "Wordpress.com"
  };
  exports.startChecklist = function (domain) {
    return xhr.post(mUrl.toUrl("/domain-checklist/create-domain-checklist", {
      domain: domain
    }), {
      handleAs: "json"
    });
  };
  exports.startAuthentication = function (domain, registrar, current_step) {
    return xhr.post(mUrl.toUrl("/domain-checklist/start-authentication"), {
      handleAs: "json",
      data: {
        domain: domain,
        registrar: registrar,
        current_step: current_step
      }
    });
  };
  exports.convertProviderToRegistrar = function (provider) {
    return PROVIDER_REGISTRARS[provider.toLowerCase()] || "Other";
  };
  return exports;
});