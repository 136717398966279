define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/MetaBusiness.html"], function (declare, lang, xhr, domClass, domAttr, on, mUrl, mPusher, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    integrationButtonNode: null,
    fbPageSelector: null,
    integrationInstallStatus: null,
    appInstallUrl: null,
    errorMessage: null,
    outageMessage: null,
    //Only initiate the pages once, we don't want duplicates
    pagesInitiated: false,
    _setAppInstallUrlAttr: {
      node: "integrationButtonNode",
      type: "attribute",
      attribute: "href"
    },
    postCreate: function () {
      this._checkForError();
      if (this.integrationInstallStatus === true) {
        this._setupDisconnectButton();
      } else {
        this._setupConnectButton();
      }
    },
    _checkForError: function () {
      var urlSearchParams = new URLSearchParams(window.location.search);
      var params = Object.fromEntries(urlSearchParams.entries());
      if (params.errormessage && params.errormessage !== "") {
        this.errorContainer.innerText = params.errormessage;
        domClass.remove(this.errorContainer, "hide");
      }
    },
    _showConnectionDialog: function () {
      this.connectionDialog.show();
    },
    _hideConnectionDialog: function () {
      this.connectionDialog.hide();
    },
    _connectionCallback: function (response) {
      this._hideConnectionDialog();
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _setupDisconnectButton: function () {
      domClass.add(this.integrationButtonNode, "hide");
      domClass.remove(this.disconnectButton, "hide");
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      domClass.remove(this.inProgressNode, "display--none");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    _setupConnectButton: function () {
      domClass.remove(this.integrationButtonNode, "hide");
      domClass.add(this.disconnectButton, "hide");
    },
    /**
     * Fetch and mUrl wrapper
     *
     * @param {String} url - url to fetch
     * @param {Object} query - object added as query params
     * @return {Promise} promise object
     */
    _fetch: function (url, query) {
      return xhr.get(mUrl.toUrl(url, query), {
        handleAs: "json"
      });
    }
  });
});