define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl", "dojox/dtl/Context", "dojo/text!../editors/blocks/templates/ImageCardEditor/outputWeb.html", "../email-renderers/ImageCardBlock"], function (array, declare, domConstruct, domStyle, dtl, dtlContext, tpl, ImageCardBlock) {
  var justifyContentMap = {
    "center": "center",
    "left": "flex-start",
    "right": "flex-end"
  };
  return declare("mojo.neapolitan.web-renderers.ImageCardBlock", [ImageCardBlock], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      var calculatedWidths = this._getWidths(meta);
      var caption = meta.caption;
      if (caption.image) {
        if (caption.image && caption.image.width > calculatedWidths.image) {
          caption.image.calculatedWidth = calculatedWidths.image;
        } else {
          caption.image.calculatedWidth = caption.image.width;
        }
      }
      return {
        widths: calculatedWidths,
        align: meta.align,
        caption: caption,
        innerPadding: this.innerPadding,
        imageContentClassNames: this._getClassName(meta.captionPosition),
        styleFlexDirection: this._getFlexDirection(meta.captionPosition),
        totalImagePadding: this._getImagePadding(meta.captionPosition, meta.edgeToEdge),
        cardImageContentClassName: meta.edgeToEdge ? "mcnImageCardImageContentE2E" : "mcnImageCardImageContent",
        cardImageContentStyle: this._getCardImageContentStyle(this._getImagePadding(meta.captionPosition, meta.edgeToEdge), meta.captionWidth, meta.captionPosition),
        captionContentStyle: this._getCaptionContentStyle(meta.captionWidth, meta.captionPosition),
        captionAlign: this._getCardImageAlign(meta.align)
      };
    },
    _getContentFlex: function (captionWidth, captionPosition) {
      var captionFlex;
      var imageFlex;
      if (captionPosition === "top" || captionPosition === "bottom") {
        captionFlex = "0 0 auto";
        imageFlex = "0 0 auto";
      } else {
        switch (captionWidth) {
          case "one-third":
            captionFlex = "0 0 30%";
            imageFlex = "0 0 70%";
            break;
          case "two-third":
            imageFlex = "0 0 30%";
            captionFlex = "0 0 70%";
            break;
          case "three-quarter":
            captionFlex = "0 0 75%";
            imageFlex = "0 0 25%";
            break;
          default:
            captionFlex = "0 0 50%";
            imageFlex = "0 0 50%";
            break;
        }
      }
      return {
        caption: captionFlex,
        image: imageFlex
      };
    },
    _getCardImageContentStyle: function (totalImagePadding, captionWidth, captionPosition) {
      return "display:flex; flex:" + this._getContentFlex(captionWidth, captionPosition).image + ";";
    },
    _getCardImageAlign: function (align) {
      var justifyContent = justifyContentMap[align];
      return "justify-content: " + justifyContent + ";";
    },
    _getCaptionContentStyle: function (captionWidth, captionPosition) {
      return "display:flex; flex:" + this._getContentFlex(captionWidth, captionPosition).caption;
    },
    _getWidths: function (meta) {
      var wholeWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2);
      var imageWidth;
      var captionWidth;
      var imagePadding;
      var calculatedInnerPadding = 0;
      var calculatedTextSidePadding = 0;
      var calculatedTextTopPadding = 0;
      switch (meta.captionPosition) {
        case "top":
        case "bottom":
          var width = Math.floor(wholeWidth - this.innerPadding * 4);
          imageWidth = captionWidth = width;
          calculatedInnerPadding = this.innerPadding;
          imagePadding = this.innerPadding * 2;
          if (meta.edgeToEdge === true) {
            //edge to edge
            captionWidth += this.innerPadding * 2;
            imageWidth += this.innerPadding * 4;
            imagePadding = 0;
            calculatedInnerPadding += this.innerPadding;
            calculatedTextSidePadding += this.innerPadding;
          }
          break;
        case "left":
        case "right":
          imagePadding = this.innerPadding * 2;
          captionWidth = this._getContentFlex(meta.captionWidth).caption;
          switch (meta.captionWidth) {
            case "half":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 4) / 2);
              break;
            case "one-third":
              imageWidth = wholeWidth - captionWidth - this.innerPadding * 4;
              break;
            case "two-third":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 3);
              break;
            case "three-quarter":
              imageWidth = Math.floor((wholeWidth - this.innerPadding * 2 * 2) / 4);
              break;
            default:
              throw new Error("Unknown captionWidth");
          }
          //edge to edge
          if (meta.edgeToEdge) {
            imageWidth += this.innerPadding * 2;
            imagePadding = 0;
            calculatedTextSidePadding += this.innerPadding * 2;
            calculatedTextTopPadding += this.innerPadding;
            calculatedInnerPadding += this.innerPadding;
          }
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      var borderOffset = this._getImageBorderOffset(meta);
      if (borderOffset < imageWidth) {
        imageWidth -= borderOffset;
      }
      return {
        caption: captionWidth
      };
    },
    //TODO: refactor classNames for ImageCards and use flex in those classes.
    _getClassName: function (position) {
      var className = "mcnImageCardBottomContent";
      switch (position) {
        case "top":
          className = "mcnImageCardTopContent";
          break;
        case "bottom":
          className = "mcnImageCardBottomContent";
          break;
        case "left":
          className = "mcnImageCardLeftContentInner";
          break;
        case "right":
          className = "mcnImageCardRightContentInner";
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return className;
    },
    _getFlexDirection: function (position) {
      var flexDirectionStyle = "column";
      switch (position) {
        case "top":
          flexDirectionStyle = "column-reverse";
          break;
        case "bottom":
          flexDirectionStyle = "column";
          break;
        case "left":
          flexDirectionStyle = "row-reverse";
          break;
        case "right":
          flexDirectionStyle = "row";
          break;
        default:
          throw new Error("Unknown captionPosition");
      }
      return flexDirectionStyle;
    },
    _getImagePadding: function (position, isEdge2Edge) {
      var totalImagePadding = "padding: 0px;";
      if (!isEdge2Edge) {
        switch (position) {
          case "top":
            totalImagePadding = "padding:0 18px 18px 18px;";
            break;
          case "bottom":
            totalImagePadding = "padding:18px 18px 0 18px;";
            break;
          case "left":
            totalImagePadding = "padding:18px 18px 18px 0;";
            break;
          case "right":
            totalImagePadding = "padding:18px 0 18px 18px;";
            break;
          default:
            throw new Error("Unknown captionPosition");
        }
      }
      return totalImagePadding;
    }
  });
});