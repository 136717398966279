define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./ImageCard"], function (declare, lang, Deferred, ImageCard) {
  return declare([ImageCard], {
    type: "video",
    _name: "Video",
    _kbLink: "https://mailchimp.com/help/use-video-content-blocks/",
    _imageUploaderType: 'video',
    constructor: function () {
      this.inherited(arguments);
      this.video = {
        url: '',
        image: null,
        service: null
      };
    },
    createVideoThumbnail: function (src) {
      var thumbnailDeferred = new Deferred();
      var request = this._endpoint.makeVideoThumbnail(src);
      request.then(lang.hitch(this, function (data) {
        thumbnailDeferred.resolve(data);
      }));
      return thumbnailDeferred.promise;
    }
  });
});