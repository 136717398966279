define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/variateDelayInput.html", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tpl, lang, query, domClass, domStyle, domConstruct, on, flags, context, I18nTranslation) {
  var HOUR_UNIT = "hour";
  var DAY_UNIT = "day";
  var HOUR_IN_MIN = 60;
  var DAY_IN_MIN = 1440;
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    defaultDelay: 4,
    defaultUnit: HOUR_UNIT,
    templateString: tpl,
    // Input name attribute.
    name: "",
    hoursDelay: "hours",
    daysDelay: "days",
    translatedTerms: {
      "campaigns_wizard_variate_delay_message": "We recommend waiting at least 4 hours to gather all the results."
    },
    postCreate: function () {
      I18nTranslation.initialize().then(function (value) {
        this.translatedTerms = value;
      }.bind(this));
      this._initInputs();
      this.own(on(this.unitSelect, "change", lang.hitch(this, "_updateValueInput")), on(this.delayInput, "keypress", lang.hitch(this, function (e) {
        if (e.charCode && (e.charCode < 48 || e.charCode > 57)) {
          e.preventDefault();
          e.stopPropagation();
        }
      })), on(this.delayInput, "keyup", lang.hitch(this, "_updateValueInput")));
      this._updateValueInput();
      domStyle.set(this.domNode, "display", "");
      var error = query(this.domNode).next()[0];
      if (error && domClass.contains(error, "invalid-error")) {
        domConstruct.place(error, this.errorContainer);
        domClass.remove(this.errorContainer, "hide");
      }
    },
    postMixInProperties: function () {
      I18nTranslation.initialize();
      if (!I18nTranslation.isTranslationObjectEmpty()) {
        this.hoursDelay = I18nTranslation.translate("campaigns_wizard_variate_delay_input_hour");
        this.daysDelay = I18nTranslation.translate("campaigns_wizard_variate_delay_input_day");
      }
    },
    _initInputs: function () {
      var startDelayValue = this.defaultDelay;
      var startUnit = this.defaultUnit;

      // force it into a number
      this.value = +this.value;
      if (this.value) {
        if (this.value % DAY_IN_MIN === 0) {
          startDelayValue = this.value / DAY_IN_MIN;
          startUnit = DAY_UNIT;
        } else {
          startDelayValue = this.value / HOUR_IN_MIN;
          startUnit = HOUR_UNIT;
        }
      }
      this.delayInput.value = startDelayValue;
      this.unitSelect.set("value", startUnit);
    },
    _updateValueInput: function () {
      var value = 0;
      var delayValue = +this.delayInput.value;
      switch (this.unitSelect.value) {
        case HOUR_UNIT:
          value = delayValue * HOUR_IN_MIN;
          break;
        case DAY_UNIT:
          value = delayValue * DAY_IN_MIN;
          break;
        default:
          throw new Error("Unhandled unit type");
      }
      this.valueInput.value = value;

      // Update the delay message
      this.delayMessage.innerText = this.translatedTerms.campaigns_wizard_variate_delay_message;
      this._toggleDelayMessage();
    },
    getDelayInHours: function () {
      return Math.floor(+this.valueInput.value / HOUR_IN_MIN);
    },
    _toggleDelayMessage: function () {
      domClass.toggle(this.delayMessage, "hide", this.getDelayInHours() >= 4);
    }
  });
});