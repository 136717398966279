define(["mojo/utils", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation", "dojo/NodeList-manipulate", "dojo/NodeList-traverse", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (utils, query, on, xhr, ready, domClass, I18nTranslation) {
  var deferred;
  var modal;
  var loginVerificationForm = {
    init: function (selector, defaultVerificationMethod) {
      this.elNode = query(selector);
      this.el = this.elNode[0];
      this.challengeCodeUrl = "/security/issue-code";
      this.defaultVerificationMethod = defaultVerificationMethod;
      ready(this.handleReady.bind(this));
    },
    handleReady: function () {
      this.initDom();
      this.initEvents();
      this.selectDefaultMethod();
    },
    initDom: function () {
      this.dom = {
        optionContainers: {
          all: query(".radio-description-out", this.el),
          email: query(".radio-description-out.recover-email-input", this.el),
          sms: query(".radio-description-out.sms-input", this.el),
          totp: query(".radio-description-out.totp-input", this.el),
          question: query(".radio-description-out.question-input", this.el)
        },
        buttons: {
          sendEmailCode: query(".send-email-code-button", this.el),
          sendSmsCode: query(".send-sms-code-button", this.el),
          submitVerification: query(".submit-verification-button", this.el)
        },
        radioButtons: {
          all: query(".verification-method input", this.el),
          email: query(".verification-method-email input", this.el),
          sms: query(".verification-method-sms input", this.el),
          totp: query(".verification-method-totp input", this.el),
          question: query(".verification-method-question input", this.el)
        },
        inputs: {
          email: query(".email-code-input", this.el),
          sms: query(".sms-code-input", this.el),
          totp: query(".totp-code-input", this.el),
          question: query(".question-answer-input", this.el),
          verificationMethod: query(".verification-type-input", this.el),
          verificationAnswer: query(".verification-answer-input", this.el)
        },
        workflowContainers: {
          email: {
            step1: query("#send-email-code-step1"),
            step2: query("#send-email-code-step2"),
            error: query("#send-email-code-error"),
            success: query("#send-email-code-success")
          },
          sms: {
            step1: query("#send-sms-code-step1"),
            step2: query("#send-sms-code-step2"),
            error: query("#send-sms-code-error")
          }
        },
        links: {
          resendEmailCode: query(".resend-email-code-link"),
          resendSmsCode: query(".resend-sms-code-link")
        },
        disclaimers: {
          securityCode: query("#security-code-disclaimer")
        }
      };
    },
    initEvents: function () {
      // Touch events don't trigger a change for radio buttons as we'd expect.
      // There are various discussions on stackoverflow about this
      // but the general consensus is to use a click event.
      this.dom.radioButtons.all.on("click", this.handleOptionChange.bind(this));
      this.dom.radioButtons.all.on("change", this.handleOptionChange.bind(this));
      this.dom.buttons.sendEmailCode.on("click", this.handleSendEmailCodeClick.bind(this));
      this.dom.links.resendEmailCode.on("click", this.handleSendEmailCodeClick.bind(this));
      this.dom.buttons.sendSmsCode.on("click", this.handleSendSmsCodeClick.bind(this));
      this.dom.links.resendSmsCode.on("click", this.handleSendSmsCodeClick.bind(this));
      // A vanilla event is needed to prevent a submit event
      this.el.addEventListener("submit", this.handleFormSubmit.bind(this), true);
    },
    selectDefaultMethod: function () {
      this.dom.radioButtons[this.defaultVerificationMethod][0].click();
    },
    handleOptionChange: function (e) {
      this.selectMethod(e.target.value);
    },
    handleSendEmailCodeClick: function (e) {
      // Indicate loading status on the button
      this.dom.buttons.sendEmailCode.addClass("button-loading disabled");

      // Handle the http response
      this.requestEmailCode().then(function (data) {
        // No longer loading
        this.dom.buttons.sendEmailCode.removeClass("button-loading disabled");
        if (data && data.max_attempts) {
          // An error occurred (displayed on step 2)
          this.dom.workflowContainers.email.step1.addClass("hide");
          this.dom.workflowContainers.email.step2.removeClass("hide");
          this.dom.workflowContainers.email.error.removeClass("hide");
          this.dom.workflowContainers.email.success.addClass("hide");
        } else {
          // The toast will pop up from the bottom of the page and show this message to the user
          var toastSuccessMessage = "Email verification code sent!";
          if (!I18nTranslation.isTranslationObjectEmpty()) {
            toastSuccessMessage = I18nTranslation.translate("email_verification_code_sent");
          }
          utils.toast(toastSuccessMessage, "message");
          // Display the next step in the workflow (box to enter your code)
          this.dom.workflowContainers.email.step1.addClass("hide");
          this.dom.workflowContainers.email.step2.removeClass("hide");
          this.dom.workflowContainers.email.error.addClass("hide");
          this.dom.workflowContainers.email.success.removeClass("hide");
        }

        // Show security code disclaimer after sending code
        this.dom.disclaimers.securityCode.removeClass("hide");

        // Focus the email box
        this.dom.inputs.email[0].focus();

        // Refresh submit enabled/disabled state based on whether the user is in step2 of the workflow
        this.updateSubmitDisabledState();
      }.bind(this));
    },
    handleSendSmsCodeClick: function (e) {
      // Indicate loading status on the button
      this.dom.buttons.sendSmsCode.addClass("button-loading disabled");

      // Handle the http response
      this.requestSmsCode().then(function (data) {
        // No longer loading
        this.dom.buttons.sendSmsCode.removeClass("button-loading disabled");
        if (data && data.max_attempts) {
          // An error occurred
          this.dom.workflowContainers.sms.step1.addClass("hide");
          this.dom.workflowContainers.sms.step2.addClass("hide");
          this.dom.workflowContainers.sms.error.removeClass("hide");
        } else {
          // The toast will pop up from the bottom of the page and show this message to the user
          var toastSuccessMessage = "SMS verification code sent!";
          if (!I18nTranslation.isTranslationObjectEmpty()) {
            toastSuccessMessage = I18nTranslation.translate("sms_verification_code_sent");
          }
          utils.toast(toastSuccessMessage, "message");

          // Display the next step in the workflow (box to enter your code)
          this.dom.workflowContainers.sms.error.addClass("hide");
          this.dom.workflowContainers.sms.step1.addClass("hide");
          this.dom.workflowContainers.sms.step2.removeClass("hide");

          // Show security code disclaimer after sending code
          this.dom.disclaimers.securityCode.removeClass("hide");
          // Focus the sms box
          this.dom.inputs.sms[0].focus();
        }
        // Refresh submit enabled/disabled state based on whether the user is in step2 of the workflow
        this.updateSubmitDisabledState();
      }.bind(this));
    },
    handleFormSubmit: function (e) {
      if (!this.isSubmitting) {
        // Don't allow native submit (yet)
        e.preventDefault();
        // Call our submit method which will populate the necessary hidden inputs and enable native submit
        this.submitVerification();
        // Put a loading spinner on the submit button to indicate loading
        this.dom.buttons.submitVerification.addClass("button-loading disabled");
      }
    },
    selectMethod: function (selectedMethod) {
      // Hide all verification options
      this.dom.optionContainers.all.addClass("hide");

      // Show just the selected one
      this.dom.optionContainers[selectedMethod].removeClass("hide");
      this.selectedMethod = selectedMethod;

      // Focus the relevant input, if applicable
      if (selectedMethod === "totp" || selectedMethod === "question") {
        this.dom.inputs[selectedMethod][0].focus();
      }

      // Focus the relevant input for 2-step workflows, if they are actually on step 2 for that workflow
      if ((selectedMethod === "sms" || selectedMethod === "email") && this.isCurrentWorkflowInStep2()) {
        this.dom.inputs[selectedMethod][0].focus();
      }

      // Display security disclaimer if email or sms input is visible
      //    and the radio button for the input is selected
      if (selectedMethod === "sms" && !this.dom.workflowContainers.sms.step2[0].classList.contains("hide") || selectedMethod === "email" && !this.dom.workflowContainers.email.step2[0].classList.contains("hide")) {
        this.dom.disclaimers.securityCode.removeClass("hide");
      } else {
        this.dom.disclaimers.securityCode.addClass("hide");
      }

      // Refresh submit enabled/disabled state of the submit button
      this.updateSubmitDisabledState();
    },
    isCurrentWorkflowInStep2: function () {
      return !domClass.contains(this.dom.workflowContainers[this.selectedMethod].step2[0], "hide");
    },
    updateSubmitDisabledState: function () {
      // Don't enable button if we've submitted already
      if (this.isSubmitted) {
        return;
      }
      if (this.selectedMethod === "totp" || this.selectedMethod === "question") {
        // Always enable the submit button for TOTP and security questions
        this.dom.buttons.submitVerification.removeClass("disabled");
      } else if (this.selectedMethod === "sms" || this.selectedMethod === "email") {
        // For SMS & email, they are 2-step workflows, so only enable submit if on step 2
        if (this.isCurrentWorkflowInStep2()) {
          this.dom.buttons.submitVerification.removeClass("disabled");
        } else {
          this.dom.buttons.submitVerification.addClass("disabled");
        }
      }
    },
    getCurrentAnswer: function () {
      return this.dom.inputs[this.selectedMethod].val();
    },
    requestEmailCode: function () {
      return xhr.get(this.challengeCodeUrl + "?t=email", {
        handleAs: "json"
      });
    },
    requestSmsCode: function () {
      return xhr.get(this.challengeCodeUrl + "?t=sms", {
        handleAs: "json"
      });
    },
    submitVerification: function () {
      // Populate the hidden inputs before posting
      this.dom.inputs.verificationMethod.val(this.selectedMethod);
      this.dom.inputs.verificationAnswer.val(this.getCurrentAnswer());

      // Now allow the form to submit for real
      this.isSubmitting = true;
      this.el.submit();
      this.isSubmitting = false;
      // Indicate the form has now been submitted
      this.isSubmitted = true;
    }
  };
  return loginVerificationForm;
});