define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "mojo/neapolitan/editors/_CKMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/RssItemsEditor/editor.html", "dojo/text!./templates/RssItemsEditor/excerptsContent.html", "dojo/text!./templates/RssItemsEditor/titlesContent.html", "dojo/text!./templates/RssItemsEditor/fullContent.html", "dojo/html", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "dojox/html/entities", "mojo/neapolitan/widgets/EditorContainer"], function (declare, _Base, _CKMixin, lang, editorTpl, excerptsContent, titlesContent, fullContent, html, domStyle, Select) {
  return declare([_Base, _CKMixin], {
    type: 'rssItems',
    templateString: editorTpl,
    presetsLabel: 'RSS Items Style',
    constructor: function () {
      this.contentPresets = {
        excerpts: {
          label: 'Excerpts',
          content: excerptsContent
        },
        titles: {
          label: 'Titles Only',
          content: titlesContent
        },
        full: {
          label: 'Full Content',
          content: fullContent
        },
        custom: {
          label: 'Custom',
          content: fullContent
        }
      };
    },
    postCreate: function () {
      this.inherited(arguments);
      var selectOptions = [];
      for (var preset in this.contentPresets) {
        selectOptions.push({
          value: preset,
          label: this.contentPresets[preset].label
        });
      }
      this.RSSItemsStyleInput = new Select({
        options: selectOptions
      });
      this.RSSItemsStyleInput.placeAt(this.presetContainer);
      this.sync("selectedPreset", this.RSSItemsStyleInput, "value");
      this.watch("selectedPreset", lang.hitch(this, function (name, old, value) {
        if (value == 'custom') {
          if (!this.block.customContent) {
            this.block.set("customContent", this.contentPresets.custom.content);
            this.showCKEditor(this.ckeditorContainer, this.timeStampContainer, this._getCustomContent());
          }
        }
        this.updateEditorWithContent(value);
      }));
      this.updateEditorWithContent(this.block.selectedPreset);
    },
    updateEditorWithContent: function (presetName) {
      if (presetName == 'custom') {
        domStyle.set(this.ckeditorContainer, 'display', 'block');
        domStyle.set(this.mergeTagPreviewToggle, 'display', 'none');
      } else {
        var encodedPreview = dojox.html.entities.encode(this.contentPresets[presetName].content).replace(/\n/g, "<br />");
        html.set(this.mergeTagPreview, encodedPreview);
        domStyle.set(this.ckeditorContainer, 'display', 'none');
        domStyle.set(this.mergeTagPreviewToggle, 'display', 'block');
      }
    },
    show: function () {
      this.publishSetup();
    },
    _getContent: function () {
      return this.block.selectedPreset == 'custom' ? this.block.customContent : this.contentPresets[this.block.selectedPreset].content;
    },
    _getCustomContent: function () {
      return this.block.customContent ? this.block.customContent : "";
    },
    startup: function () {
      this.showCKEditor(this.ckeditorContainer, this.timestampContainer, this._getCustomContent(), lang.hitch(this, "publishReady"));
    },
    onCKChange: function () {
      this.block.set("customContent", this.editor.getData());
    }
  });
});