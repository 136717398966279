/**
 * Adds on-click behavior to hover lists with click-to-open class.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dojo/NodeList-traverse'], function (declare, on, query, array, domStyle, event, ready) {
  var hideEvents = [];
  var hideLists = function () {
    query(".hover-list.click-to-open > li > ul").style("display", "none");
    removeEvents();
  };
  var removeEvents = function () {
    array.forEach(hideEvents, function (e) {
      e.remove();
    });
    hideEvents = [];
  };
  var convert = function (el) {
    var _ul = query('> li > ul', el);
    _ul.forEach(function (el, i) {
      var _a = query(el).siblings('a,button')[0];
      if (_a) {
        on(_a, "click", function (e) {
          hideLists();
          event.stop(e);
          if (domStyle.get(el, "display") == 'block') {
            query(el).style("display", "none");
          } else {
            if (hideEvents.length) {
              removeEvents();
            }
            hideEvents.push(on(window.document, 'click', function (e) {
              if (dojo.query(e.target).parents('.click-outside-to-close').length < 1) {
                hideLists();
              }
            }));

            // Listen for clicks on iframes that belong to us also.
            var domain = document.location.protocol + '//' + document.location.host;
            query('iframe').forEach(function (frame) {
              if (frame.src.slice(0, domain.length) == domain) {
                hideEvents.push(on.once(frame.contentWindow.document, 'click', hideLists));
              }
            });
            query(el).style("display", "block");
          }
        });
      }
    });
  };
  ready(function () {
    query(".hover-list.click-to-open").forEach(convert);
  });
  return {
    convert: convert
  };
});