define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/input/TagList", "./_SegmentInputMixin", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, TagList, _SegmentInputMixin, array, lang, htmlEntities, I18nTranslation, flags, context) {
  var blankText = "blank";
  return declare([TagList, _SegmentInputMixin], {
    operatorOptions: [{
      "label": context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty() ? I18nTranslation.translate("audience_management_segment_builder_any_label") : "Any",
      "value": "or"
    }, {
      "label": context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty() ? I18nTranslation.translate("audience_management_segment_builder_all_label") : "All",
      "value": "and"
    }],
    constructor: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    getDisplayHTML: function () {
      var html;
      var values = this.getValues();
      if (values.length) {
        values = array.map(values, function (item) {
          return htmlEntities.encode(item);
        });
        if (values.length === 1) {
          return values[0];
        }
        var last = values.pop();
        var opLabel = this.operator;
        return values.join("<span class=\"fwn padding--lv1 !padding-top-bottom--lv0 !padding-left--lv0\">,</span> ") + "<span class=\"fwn padding--lv1 !padding-top-bottom--lv0\"> " + opLabel + " </span>" + last;
      }
      html = "<span class=\"dim6\">(" + blankText + ")</span>";
      return html;
    },
    getValue: function () {
      return this.getValues();
    },
    setValue: function (values) {
      var valArr;
      if (typeof values === "string") {
        valArr = [values];
      } else {
        valArr = values;
      }
      array.forEach(valArr, lang.hitch(this, function (value) {
        this._addTag(value);
      }));
    },
    getValueOp: function () {
      return this.operator;
    },
    setValueOp: function (op) {
      this.set("operator", op);
      this.opSelect.set("value", op);
    }
  });
});