define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_TemplatedMixin", "mojo/analytics/parts/_Part", "dojo/text!./templates/CampaignSearchCount.html"], function (declare, lang, _TemplatedMixin, _Part, templateString) {
  return declare([_Part, _TemplatedMixin], {
    templateString: templateString,
    relevantFilters: ["type", "list", "search"],
    typeContent: {
      "regular": "regular",
      "rss": "RSS",
      "variate": "a/b test",
      "plaintext": "plain text",
      "ad": "ad",
      "automation": "automated",
      "unfiled": "unfiled",
      "social_post": "social"
    },
    bucketContent: {
      "recent": "",
      "draft": "draft",
      "ongoing": "ongoing",
      "completed": "completed"
    },
    canDisplay: function () {
      return this.target.total > 0 && this.relevantFilters.some(function (filter) {
        return this.target.getFilter(filter);
      }, this);
    },
    buildCountMessage: function () {
      this.node_total.innerText = this.target.total;
      var message = this.bucketContent[this.target.getFilter("bucket")] || "";
      if (this.target.getFilter("type")) {
        message += " " + (this.typeContent[this.target.getFilter("type")] || "") + " campaigns found";
      } else if (this.target.getFilter("search")) {
        message += " campaigns named \"" + this.target.getFilter("search") + "\" found";
      }
      if (this.target.getFilter("folder")) {
        message += " in current folder";
      }
      this.node_message.innerText = message;
    },
    toggleDisplay: function () {
      this.node_message.innerText = "";
      this.node_total.innerText = "";
    },
    init: function () {
      //Manually add filter watchers to make sure toggleDisplay is ALWAYS called when relevant filters change.
      this.relevantFilters.forEach(function (filter) {
        this._watches.push(this.target.watchFilter(filter, lang.hitch(this, function () {
          this.toggleDisplay();
        })));
      }, this);
    }
  });
});