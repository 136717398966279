define(["dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/url"], function (Deferred, request, mUrl) {
  /**
   * Global cache of pro segment's editor config.
   */
  var cachedConfigPromises = {};
  return {
    /**
     * Return the configuration the pro segment editor uses to render all
     * its fields. This function caches the config to avoid unnecessary reloading.
     *
     * @param {number} listId
     * @param {string} configType allows the endpoint to return different config types that have different fields.
     * @returns {*}
     */
    getConfig: function (listId, configType) {
      var cacheKey = listId + ":" + configType;
      var cachedPromise = cachedConfigPromises[cacheKey];
      if (!cachedPromise || cachedPromise.isRejected()) {
        var req = request.get(mUrl.toUrl("/lists/segments/config"), {
          "handleAs": "json",
          "query": {
            "id": listId,
            "type": configType
          }
        });
        cachedConfigPromises[cacheKey] = req;
      }
      return cachedConfigPromises[cacheKey];
    },
    getSubscribersVisGridQuery: function (listId) {
      return request.get(mUrl.toUrl("/lists/segments/get-subscribers-vis-grid-query"), {
        "handleAs": "json",
        "query": {
          "id": listId
        }
      });
    },
    getSubcribersDescriptionQuery: function (params) {
      var req = request.get(mUrl.toUrl("/campaigns/segments/popup-description"), {
        "handleAs": "html",
        "query": params
      });
      return req;
    },
    getProductListFromTerm: function (listId, term) {
      return request.get(mUrl.toUrl("/ecommerce/products-search-by-list"), {
        "handleAs": "json",
        "query": {
          "list_id": listId,
          "term": term,
          "encode_title": "N",
          "log_search": "Y"
        }
      });
    }
  };
});