define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/widgets/Renderable", "dojo/text!./templates/postcard-trigger.html"], function (declare, utils, Renderable, tpl) {
  var PostcardTrigger = declare([Renderable], {
    templateString: tpl,
    state: {
      latency: "",
      storeName: ""
    },
    constructor: function (props) {
      this.state = {
        latency: this.getReadableLatency(props.model.automationTrigger.settings.latency),
        storeName: props.model.automationTriggerStore ? props.model.automationTriggerStore.storeName : "Disconnected store"
      };
    },
    getReadableLatency: function (latency) {
      var latencyInDays = Number(latency.replace(/d/g, ""));
      return latencyInDays === 1 ? "24 hours" : latencyInDays + " days";
    }
  });
  return PostcardTrigger;
});