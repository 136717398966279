define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/editors/blocks/PromoCodeEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/PromoCodeEditor/editorMobile.html", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, PromoCodeEditor, _MobileBlockMixin, tmpl, _DynamicContentMixin) {
  return declare([PromoCodeEditor, _MobileBlockMixin, _DynamicContentMixin], {
    type: "promoCode-mobile",
    templateString: tmpl,
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["promoCodeFeedbackandStore", "sectionHeader", "promoCodeDescriptionTitle", "promoCodeSelectContainer", "promoCodeExpirationToggle", "promoCodeButtonInput"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});