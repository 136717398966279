/**
 * A group of buttons that allows only one button to be toggled.
 * This pattern is used primarily in wizards
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "./content-manager/Api"], function (declare, array, lang, domConstruct, domStyle, on, domAttr, query, _WidgetBase, _TemplatedMixin, api) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: '<ul class="flex"></ul>',
    options: [],
    itemTpl: '<li class="c-buttonGroupItem"><button type="button" data-mc-value="{value}" title="{title}" class="c-buttonGroupButton v-buttonGroupBordered" href="#">{label}</button></li>',
    activeClass: "v-buttonGroupBordered--active",
    showCaTooltip: false,
    value: "",
    postCreate: function () {
      var self = this;
      this._addOptions();
      this.own(on(this.domNode, "button:click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        var value = domAttr.get(this, "data-mc-value");
        // the value is null when adTooltip close button is clicked
        // Tooltip exp is marked as completed when user either clicks on the Creative Assistant btn or tooltip close btn

        if (self.showCaTooltip && value === "content-engine" || value === null) {
          var adTooltip = query(".adTooltip")[0];
          domStyle.set(adTooltip, "visibility", "hidden");
          api.setAdTooltipCompletion();
        }
        self.set("value", value);
      }));
      this.watch("value", lang.hitch(this, "_toggleActive"));
    },
    _toggleActive: function () {
      query("button[data-mc-value]", this.domNode).removeClass(this.activeClass);
      var value = this.value;
      if (value) {
        query("button[data-mc-value=" + value + "]", this.domNode).addClass(this.activeClass);
      }
      this.onChange(value);
    },
    _addOptions: function () {
      array.forEach(this.options, lang.hitch(this, function (option) {
        var tplOpts = lang.mixin({
          "value": "",
          "title": "",
          "label": ""
        }, option);
        // Use label as title if not provided
        if (!tplOpts.title && tplOpts.label) {
          tplOpts.title = tplOpts.label;
        }
        if (this.showCaTooltip && tplOpts.title === "Creative Assistant") {
          var tooltipText = "By using the power of AI, we'll automatically generate beautiful multichannel designs using your logo, colors, and fonts.";
          var creativeAssistant = '<li class="c-buttonGroupItem" style="position: relative;"><button class="c-buttonGroupButton v-buttonGroupList v-buttonGroupList--animColor full-width text-align--left normal-meta height-inherit border-radius--lv1 padding--lv2" data-mc-value="{value}" title="{title}"><div class="c-media"><div class="c-mediaImage v-imageThumb--animGrayscale"><img src="https://cdn-images.mailchimp.com/product/files/source-icon-{value}.svg" alt="{label} icon" class="v-contentSourceBtnImg"></div><div class="c-mediaBody--centered small-meta">{label}</div></div></button><div class="adTooltip hide"><div class="adTooltipWrapper"><button role="button" class="button-small adTooltipClose"><span class="freddicon cross closeIcon"></span></button><span class="adTooltipText">' + tooltipText + "</span></div></div></li>";
          domConstruct.place(lang.replace(creativeAssistant, tplOpts), this.domNode);
        } else {
          domConstruct.place(lang.replace(this.itemTpl, tplOpts), this.domNode);
        }
      }));
    },
    setSelected: function (value) {
      this.set("value", value);
      this._toggleActive();
    },
    onChange: function (value) {}
  });
});