define(["/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/require-module.js", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // domAttr.set
"dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // has("dijit-legacy-requires")
"dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // query
"dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./ToggleButton", "./_CheckBoxMixin", "dojo/text!./templates/CheckBox.html", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// NodeList.addClass/removeClass
"../a11yclick" // template uses ondijitclick
], function (dojoRequire, declare, domAttr, has, query, ready, ToggleButton, _CheckBoxMixin, template) {
  // module:
  //		dijit/form/CheckBox

  // Back compat w/1.6, remove for 2.0
  if (has("dijit-legacy-requires")) {
    ready(0, function () {
      var requires;
      require(["dijit/form/RadioButton"]); // use indirection so modules not rolled into a build
    });
  }
  return declare("dijit.form.CheckBox", [ToggleButton, _CheckBoxMixin], {
    // summary:
    //		Same as an HTML checkbox, but with fancy styling.
    //
    // description:
    //		User interacts with real html inputs.
    //		On onclick (which occurs by mouse click, space-bar, or
    //		using the arrow keys to switch the selected radio button),
    //		we update the state of the checkbox/radio.
    //
    //		There are two modes:
    //
    //		1. High contrast mode
    //		2. Normal mode
    //
    //		In case 1, the regular html inputs are shown and used by the user.
    //		In case 2, the regular html inputs are invisible but still used by
    //		the user. They are turned quasi-invisible and overlay the background-image.

    templateString: template,
    baseClass: "dijitCheckBox",
    _setValueAttr: function ( /*String|Boolean*/newValue, /*Boolean*/priorityChange) {
      // summary:
      //		Handler for value= attribute to constructor, and also calls to
      //		set('value', val).
      // description:
      //		During initialization, just saves as attribute to the `<input type=checkbox>`.
      //
      //		After initialization,
      //		when passed a boolean, controls whether or not the CheckBox is checked.
      //		If passed a string, changes the value attribute of the CheckBox (the one
      //		specified as "value" when the CheckBox was constructed
      //		(ex: `<input data-dojo-type="dijit/CheckBox" value="chicken">`).
      //
      //		`widget.set('value', string)` will check the checkbox and change the value to the
      //		specified string.
      //
      //		`widget.set('value', boolean)` will change the checked state.

      if (typeof newValue == "string") {
        this.inherited(arguments);
        newValue = true;
      }
      if (this._created) {
        this.set('checked', newValue, priorityChange);
      }
    },
    _getValueAttr: function () {
      // summary:
      //		Hook so get('value') works.
      // description:
      //		If the CheckBox is checked, returns the value attribute.
      //		Otherwise returns false.
      return this.checked && this._get("value");
    },
    // Override behavior from Button, since we don't have an iconNode or valueNode
    _setIconClassAttr: null,
    _setNameAttr: "focusNode",
    postMixInProperties: function () {
      this.inherited(arguments);

      // Need to set initial checked state via node.setAttribute so that form submit works
      // and IE8 radio button tab order is preserved.
      // domAttr.set(node, "checked", bool) doesn't work on IE until node has been attached
      // to <body>, see #8666
      this.checkedAttrSetting = "";
    },
    _fillContent: function () {
      // Override Button::_fillContent() since it doesn't make sense for CheckBox,
      // since CheckBox doesn't even have a container
    },
    _onFocus: function () {
      if (this.id) {
        query("label[for='" + this.id + "']").addClass("dijitFocusedLabel");
      }
      this.inherited(arguments);
    },
    _onBlur: function () {
      if (this.id) {
        query("label[for='" + this.id + "']").removeClass("dijitFocusedLabel");
      }
      this.inherited(arguments);
    }
  });
});