define(["dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "velocity/velocity", "mojo/widgets/KbSearch", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context"], function (domAttr, query, on, domClass, mUrl, velocity, kbSearch, ready, context) {
  var connectedSites = {
    // Var to store wizard next button
    nextButton: null,
    // Var to store site id
    siteId: null,
    init: function () {
      // Setup KB button
      on(query("[data-kb-help]"), "click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        kbSearch.main().show();
      });

      // Setup wizard next button
      this.nextButton = query("[data-mc-el='nextBtn']")[0];
    },
    // Add disabled class to next button
    disableNext: function () {
      if (this.nextButton) {
        domAttr.set(this.nextButton, "disabled", "disabled");
      }
    },
    // Remove disabled class from next button
    enableNext: function () {
      if (this.nextButton) {
        domAttr.remove(this.nextButton, "disabled", "disabled");
      }
    },
    // Complete and exit to the details page
    completeWizard: function () {
      // Set site id
      this.siteId = this.nextButton.value;
      if (this.siteId) {
        if (window.self !== window.top && context.flagIsOn("xp.fix_chunk_errors_account")) {
          window.top.location = mUrl.toUrl("account/connected-sites/site-detail?id=" + this.siteId);
        } else {
          window.location = mUrl.toUrl("account/connected-sites/site-detail?id=" + this.siteId);
        }
      }
    }
  };
  ready(function () {
    // Initialize connected sites
    connectedSites.init();
  });
  return connectedSites;
});