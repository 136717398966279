define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl", "dojox/dtl/Context", "mojo/url", "dojo/text!../editors/blocks/templates/SocialShareEditor/outputWeb.html", "../email-renderers/_Renderer"], function (declare, lang, dtl, dtlContext, mUrl, tpl, _Renderer) {
  var justifyContentMap = {
    "center": "center",
    "left": "flex-start",
    "right": "flex-end"
  };
  return declare("mojo.neapolitan.web-renderers.SocialShareBlock", [_Renderer], {
    outputTplStr: tpl,
    defaultText: {
      "facebookShare": "Share",
      "twitterShare": "Tweet",
      "instapaperShare": "Read Later",
      "googlePlusShare": "+1",
      "linkedInShare": "Share",
      "pinterestShare": "Pin"
    },
    getOutputModel: function (meta) {
      // Set the icon urls for the items
      meta.items.forEach(lang.hitch(this, function (item) {
        this._getServiceIconUrl(item, meta.iconStyle, meta.iconType);
        this._buildShareUrl(item, this._getShareUrl(meta), this._getDescription(meta));
        this._setDefaultText(item);
      }));
      return {
        items: meta.items,
        itemArrangement: meta.itemArrangement,
        adjustableWidth: meta.adjustableWidth,
        flexStyle: this._getFlexDirection(meta.blockLayout),
        containerWidth: this._getContainerWidth(meta.adjustableWidth),
        blockAlign: this._getBlockAlign(meta.align)
      };
    },
    _getFlexDirection: function (layout) {
      var flexDirectionStyle;
      switch (layout) {
        case "vertical":
          flexDirectionStyle = "column";
          break;
        case "horizontal":
        default:
          flexDirectionStyle = "row";
          break;
      }
      return flexDirectionStyle;
    },
    _getContainerWidth: function (meta) {
      var containerWidth;
      if (meta === "100%") {
        containerWidth = "1 1 auto";
      } else {
        containerWidth = "0 1 auto";
      }
      return containerWidth;
    },
    _getBlockAlign: function (align) {
      var justifyContent = justifyContentMap[align];
      return "justify-content: " + justifyContent + ";";
    },
    _getShareUrl: function (meta) {
      switch (meta.shareContentSource) {
        case "custom":
          return encodeURIComponent(meta.customUrl);
        case "campaign":
        default:
          return "*|PAGE:URL|*";
      }
    },
    _setDefaultText: function (item) {
      if (!item.text) {
        var text = this.defaultText[item.type];
        if (!text) {
          text = "Share";
        }
        item.text = text;
      }
    },
    _getDescription: function (meta) {
      switch (meta.shareContentSource) {
        case "custom":
          return encodeURIComponent(meta.customDescription);
        case "campaign":
        default:
          return "*|PAGE:TITLE|*";
      }
    },
    _buildShareUrl: function (item, sharedUrl, description) {
      var baseUrl, builtUrl;
      // I am not passing the sharedUrl variable to objectQueryToParams because it
      // will URL Encode the *|ARCHIVE|* merge tag and cause it not to render properly
      switch (item.type) {
        case "facebookShare":
          builtUrl = "http://www.facebook.com/sharer/sharer.php?u=" + sharedUrl;
          break;
        case "twitterShare":
          baseUrl = "http://twitter.com/intent/tweet";
          var twitterStatus = description + ": " + sharedUrl;
          builtUrl = baseUrl + "?text=" + twitterStatus;
          break;
        case "linkedInShare":
          builtUrl = "http://www.linkedin.com/shareArticle?url=" + sharedUrl + "&mini=true&title=" + description;
          break;
        case "pinterestShare":
          builtUrl = "https://www.pinterest.com/pin/find/?url=" + sharedUrl;
          break;
        case "googlePlusShare":
          baseUrl = "https://plus.google.com/share?url=" + sharedUrl;
          builtUrl = baseUrl;
          break;
        case "instapaperShare":
          builtUrl = "http://www.instapaper.com/hello2?url=" + sharedUrl + "&title=" + description;
          break;
        default:
          builtUrl = "link";
          break;
      }
      item.link = builtUrl;
    },
    _getServiceIconUrl: function (item, iconStyle, iconType) {
      var iconName;
      var urlPrefix = "http://cdn-images.mailchimp.com/icons/social-block-v2/";
      var urlSuffix = ".png";
      switch (item.type) {
        case "facebookShare":
          iconName = "facebook";
          break;
        case "twitterShare":
          iconName = "twitter";
          break;
        case "linkedInShare":
          iconName = "linkedin";
          break;
        case "pinterestShare":
          iconName = "pinterest";
          break;
        case "instapaperShare":
          iconName = "instapaper";
          break;
        case "googlePlusShare":
          iconName = "googleplus";
          break;
        default:
          iconName = "link";
          break;
      }
      var iconTypePrefix = "";
      switch (iconType) {
        case "solid":
          iconTypePrefix = "";
          break;
        case "outline":
        default:
          iconTypePrefix = iconType + "-";
          break;
      }
      var url = urlPrefix + iconTypePrefix + iconStyle + "-" + iconName + "-48" + urlSuffix;
      item.iconUrl = mUrl.addProxy(url);
    }
  });
});