define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils"], function (declare, base, domAttr, on, p2Utils) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {
      var self = this;
      var credits = this.$el.prePayCreditsSelect;
      this.updateCreditsAmount(credits.options[credits.selectedIndex].text);
      on(this.$el.prePayCreditsSelect, "change", function () {
        self.updateCreditsAmount(this.options[this.selectedIndex].text);
      });
      on(this.$el.prePayCreditsForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.validatePrepayCreditsForm();
      });
      on(this.$el.prePayMonthlyForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.validatePrepayMonthlyForm();
      });
    },
    /**
     * updates the credit amount select
     * @param val - value from the credits amount select
     */

    updateCreditsAmount: function (val) {
      var dollars = val.match(/\$[0-9]+(\.[0-9][0-9])?/g)[0].replace("$", "");
      this.$el.prePayCreditsAmount.value = dollars;
    },
    /**
     * validates the prepay paygo credits form
     * @return {boolean} - will return if credit amount is empty
     */
    validatePrepayCreditsForm: function () {
      if (this.$el.prePayCreditsAmount.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a value", "error");
        return false;
      }
      this.$el.prePayCreditsForm.submit();
    },
    /**
     * validates the prepay monthly credits form
     * @return {boolean} - will return if the credit amount input and select are empty
     */
    validatePrepayMonthlyForm: function () {
      if (this.$el.prePayAmountInput.value.length <= 0) {
        p2Utils.utils.setFlash("Please enter a value", "error");
        return false;
      }
      if (this.$el.prePayMonthlyPlanSelect.value === "") {
        p2Utils.utils.setFlash("Please enter an option from the select box", "error");
        return false;
      }
      this.$el.prePayMonthlyForm.submit();
    }
  });
});