define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, _Block) {
  return declare([_Block], {
    type: "socialShare",
    _name: "Share",
    _kbLink: "https://mailchimp.com/help/use-social-share-content-blocks/",
    constructor: function () {
      this.blockLayout = "horizontal";
      this.itemArrangement = "inline";
      this.iconStyle = "dark";
      this.iconType = "outline";
      this.align = "center";
      this.shareContentSource = "campaign";
      this.customUrl = "";
      this.customDescription = "";
      this.adjustableWidth = "100%";
      // Default items to show on new social share blocks
      this.items = [];
      this.styles = [{
        "name": "Container styles",
        "selector": ".mcnShareContent",
        "props": {
          "background-color": {
            "value": ""
          },
          "border": {
            "value": ""
          }
        }
      }, {
        "name": "Button style",
        "selector": ".mcnShareContentItem",
        "props": {
          "background-color": {
            "value": ""
          },
          "border": {
            "value": ""
          },
          "border-radius": {
            "value": "3px"
          }
        }
      }, {
        "name": "Text style",
        "selector": ".mcnShareTextContent a",
        "props": {
          "color": {
            "value": "#202020"
          },
          "font-family": {
            "value": "Arial"
          },
          "font-size": {
            "value": "12px"
          },
          "font-weight": {
            "value": "normal"
          },
          "line-height": {
            "value": "normal"
          },
          "text-align": {
            "value": "center"
          },
          "text-decoration": {
            "value": "none"
          }
        }
      }];
    },
    postscript: function (params) {
      this.inherited(arguments);
      if (!this.items.length) {
        var defaultItems = [{
          type: "facebookShare"
        }, {
          type: "twitterShare"
        }];
        if (this._campaignRenderingType !== "page") {
          defaultItems.push({
            type: "forwardToFriend"
          });
        }
        this.items = defaultItems;
      }
    }
  });
});