/**
 * @module mojo/widgets/AccessibleMenu
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_FocusMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/parser", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'dojo/NodeList-traverse'], function (declare, _WidgetBase, _FocusMixin, on, parser, lang, array, dom, domClass, domAttr, query, keys) {
  return declare([_WidgetBase, _FocusMixin], {
    menuItems: [],
    currentItemIndex: null,
    bindEvents: function () {
      var self = this;
      this.keyEvents = on(this.domNode, "keydown", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.currentItemIndex = self.menuItems.indexOf(e.target);
        return self._handleKeyDown(this, e);
      });
    },
    unbindEvents: function () {
      this.keyEvents.remove();
    },
    _handleKeyDown: function ($child, e) {
      var key = e.charCode || e.keyCode;
      switch (key) {
        case keys.ENTER:
        case keys.SPACE:
          window.location = domAttr.get(query(e.target).children("a")[0], "href");
          break;
        case keys.UP_ARROW:
          var index = this.currentItemIndex - 1;
          var node = index < 0 ? this.menuItems[this.menuItems.length - 1] : this.menuItems[index];
          node.focus();
          break;
        case keys.DOWN_ARROW:
          index = this.currentItemIndex + 1;
          node = index > this.menuItems.length - 1 ? this.menuItems[0] : this.menuItems[index];
          node.focus();
          break;
        case keys.ESCAPE:
        case keys.LEFT_ARROW:
        case keys.RIGHT_ARROW:
          break;
        case keys.TAB:
        case keys.META:
        case keys.CTRL:
        case keys.SHIFT:
        case keys.ALT:
          return true;
        case keys.TAB:
        case keys.META:
        case keys.CTRL:
        case keys.SHIFT:
        case keys.ALT:
          return true;
        default:
          // Jump to menu whose starting letter
          // matches the pressed alphanumeric key
          var alphaKey = String.fromCharCode(e.which);
          var linkIndex = this.currentItemIndex + 1;
          if (linkIndex === this.menuItems.length) {
            linkIndex = 0;
          }
          while (linkIndex !== this.currentItemIndex) {
            var firstLabelChar = query(this.menuItems[linkIndex]).children("a")[0].innerHTML.charAt(0);
            if (firstLabelChar === alphaKey) {
              this.charMatch = true;
              break;
            }
            linkIndex += 1;
            if (linkIndex === this.menuItems.length) {
              linkIndex = 0;
            }
          }
          if (this.charMatch) {
            this.menuItems[linkIndex].focus();
          } else {
            this.menuItems[this.currentItemIndex].focus();
          }
          break;
      }
    },
    setRoles: function () {
      this.menuItems.map(function (item) {
        domAttr.set(item, "role", "menuitem");
        domAttr.set(item, "tabindex", "-1");
      });
    },
    postCreate: function () {
      // All menuChild elements
      this.menuItems = query(this.domNode).children();
      this.setRoles();
    },
    _onFocus: function () {
      this.bindEvents();
      if (!domAttr.has(this.domNode, "data-mode")) {
        query(this.menuItems)[0].focus();
        this.currentItemIndex = 0;
      }
    },
    _onBlur: function () {
      domAttr.set(this.domNode, "aria-expanded", "false");
      this.unbindEvents();
      if (domAttr.has(this.domNode, "data-mode")) {
        domAttr.remove(this.domNode, "data-mode");
      }
    }
  });
});