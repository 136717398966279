define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/url", "mojo/utils/I18nTranslation"], function (declare, base, query, Dialog, mUrl, I18nTranslation) {
  return declare([base], {
    onReady: function () {
      I18nTranslation.initialize();
      this.removeSMS();
    },
    removeSMS: function () {
      var handleCancelBtn = function (dialog) {
        //Close dialog on cancel button
        query(".cancel-button", dialog.domNode).on("click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          dialog.hide();
        });
      };
      query(this.$el.removeSMS).on("click", function () {
        var titleText = "Remove Verified Phone Number";
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          titleText = I18nTranslation.translate("security_dialog_title");
        }
        var dialog = new Dialog({
          "title": titleText,
          "onHide": function () {
            dialog.destroyRecursive();
          },
          "onDownloadEnd": function () {
            handleCancelBtn(dialog);
          }
        });
        dialog.setHref(mUrl.toUrl("/account/sms-remove-confirm"));
        dialog.show();
      });
    }
  });
});