define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Renderable
"mojo/widgets/Renderable",
// Mojo utils
"mojo/user", "mojo/lib/logger", "mojo/lib/flags", "mojo/widgets/smart-mark/SmartMark",
// Templates
"dojo/text!./templates/preview-text-rec.html"], function (declare, lang, on, domConstruct, domClass, domAttr, query, domStyle, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Renderable, user, logger, flags, SmartMark, recTemplate) {
  var toggletipText = "Our smart preview text generator creates custom copy tailored to your voice and message, so you can send emails with confidence.";
  var STATUS_PT_NOT_SHOWN = 0;
  var STATUS_PT_UNAVAILABLE = 1;
  var STATUS_PT_AVAILABLE = 2;
  var STATUS_PT_REGEN_AVAILABLE = 3;
  var STATUS_PT_MAX_REGEN_REACHED = 4;
  var STATUS_PT_DISMISSED = 5;
  var shouldShowSmartPreviewTextRec = function (status) {
    return [STATUS_PT_AVAILABLE, STATUS_PT_REGEN_AVAILABLE, STATUS_PT_MAX_REGEN_REACHED].includes(status);
  };
  var PreviewTextRec = declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Renderable], {
    STATUS_PT_NOT_SHOWN: STATUS_PT_NOT_SHOWN,
    STATUS_PT_UNAVAILABLE: STATUS_PT_UNAVAILABLE,
    STATUS_PT_AVAILABLE: STATUS_PT_AVAILABLE,
    STATUS_PT_REGEN_AVAILABLE: STATUS_PT_REGEN_AVAILABLE,
    STATUS_PT_MAX_REGEN_REACHED: STATUS_PT_MAX_REGEN_REACHED,
    STATUS_PT_DISMISSED: STATUS_PT_DISMISSED,
    statusText: {},
    statusLinkText: {},
    templateString: recTemplate,
    state: {
      previewTextRecStatus: this.STATUS_PT_UNAVAILABLE,
      hasFollowedPreviewTextRec: false,
      hasSeenPreviewTextAnimation: false,
      hasSeenPreviewText: false
    },
    /**
     * Constructor
     *
     * @param props
     */
    constructor: function (props) {
      this.state.previewTextRecStatus = props.previewTextRecStatus;
      this.state.shouldShowSmartPreviewTextRec = shouldShowSmartPreviewTextRec(props.previewTextRecStatus);
      this.state.hasFollowedPreviewTextRec = props.hasFollowedPreviewTextRec;
      this.state.hasSeenPreviewTextAnimation = props.hasSeenPreviewTextAnimation;
      this.state.hasSeenPreviewText = props.hasSeenPreviewText;
      this.statusText[this.STATUS_PT_UNAVAILABLE] = "You haven't added any preview text yet.";
      this.statusText[this.STATUS_PT_AVAILABLE] = "Looks like your email still needs a preview! We can help you get started with custom preview text generated for your email.";
      this.statusText[this.STATUS_PT_REGEN_AVAILABLE] = "It looks like your email content has changed, and your preview text might not be up to date";
      this.statusText[this.STATUS_PT_MAX_REGEN_REACHED] = "We can't generate any more preview text for this email, but you can make edits before you hit send.";
      this.statusText[this.STATUS_PT_DISMISSED] = "You haven't added any preview text yet.";
      lang.mixin(this.statusText, props.text);
      this.statusLinkText[this.STATUS_PT_UNAVAILABLE] = "Add Preview Text";
      this.statusLinkText[this.STATUS_PT_AVAILABLE] = "Get My Preview Text";
      this.statusLinkText[this.STATUS_PT_REGEN_AVAILABLE] = "Get New Preview Text";
      this.statusLinkText[this.STATUS_PT_DISMISSED] = props.linkText && props.linkText[this.STATUS_PT_DISMISSED] ? props.linkText[this.STATUS_PT_DISMISSED] : ["Get Preview Text", "Write Your Own"];
      lang.mixin(this.statusLinkText, props.linkText);
      this.toggletipText = props.toggletipText ? props.toggletipText : toggletipText;
      this.onClickAddPreviewText = props.onClickAddPreviewText;
      this.onClickGetPreviewTextRec = props.onClickGetPreviewTextRec;
      this.onDismiss = props.onDismiss;

      // Need to adjust GTM data if the the user has seen preview text
      if (!window.top.gtmData.previewTextEnabled && this.state.hasSeenPreviewText) {
        window.top.gtmData.previewTextEnabled = true;
      }

      // Needed to reload qualtrics after adjusting the flag in GTM data
      function reloadQualtrics() {
        if (window.top.QSI) {
          window.top.reloadQualtrics();
        } else {
          setTimeout(reloadQualtrics, 1000);
        }
      }

      // Only try and reload Qualtrics data if the user has seen preview text
      if (window.top.gtmData.previewTextEnabled) {
        setTimeout(reloadQualtrics, 500);
      }
    },
    /**
     * postCreat
     *
     * @link https://dojotoolkit.org/reference-guide/1.10/dijit/_WidgetBase.html
     */
    postCreate: function () {
      this.inherited(arguments);
      if (this.state.shouldShowSmartPreviewTextRec) {
        var smartMark = new SmartMark({
          tier: 2,
          condensed: true,
          toggletipText: this.toggletipText
        });
        smartMark.placeAt(this.smartMark, "only");
      }
      this.dismissedTextNode.textContent = this.statusText[this.STATUS_PT_DISMISSED];
      this.dismissedLinkNode1.textContent = this.statusLinkText[this.STATUS_PT_DISMISSED][0];
      this.dismissedLinkNode2.textContent = this.statusLinkText[this.STATUS_PT_DISMISSED][1];
      if (this.smartmark && !this.hasSeenPreviewTextAnimation) {
        smartMark.triggerAnimation();
      }
      if (!this.hasSeenPreviewTextAnimation && this.state.shouldShowSmartPreviewTextRec) {
        this.executeAnimation();
      } else {
        this.setTextContent();
        this.setLinkContent();
      }
      if (this.state.hasSeenPreviewTextAnimation && this.smartMarkContainer) {
        domClass.add(this.smartMarkContainer, "static");
      }
      this.setAnimationStatus(true);
    },
    getPreviewText: function (e) {
      if (this.previewTextRecStatus !== this.STATUS_PT_DISMISSED) {
        this.smartMarkAnimation(this.onClickGetPreviewTextRec.bind(this, e));
      } else {
        this.onClickGetPreviewTextRec(e);
      }
    },
    setTextContent: function () {
      if (this.textNode) {
        this.textNode.textContent = this.statusText[this.state.previewTextRecStatus];
      }
    },
    setLinkContent: function () {
      if (this.linkNode) {
        this.linkNode.textContent = this.statusLinkText[this.state.previewTextRecStatus];
      }
    },
    executeAnimation: function () {
      if (this.xNode) {
        domStyle.set(this.xNode, "opacity", "0");
      }
      if (this.textNode) {
        domStyle.set(this.textNode, "opacity", "0");
      }
      if (this.linkNode) {
        domStyle.set(this.linkNode, "opacity", "0");
      }
      setTimeout(lang.hitch(this, this.textIntroAnimation), 2500);
    },
    textIntroAnimation: function () {
      this.setTextContent();
      this.setLinkContent();
      this.fadeInNode(this.textNode, 500);
      this.fadeInNode(this.linkNode, 500);
      this.fadeInNode(this.xNode, 500);
    },
    fadeInNode: function (targetNode, durationTime) {
      var fadeArgs = {
        node: targetNode,
        duration: durationTime
      };
      dojo.fadeIn(fadeArgs).play();
    },
    smartMarkAnimation: function (callback) {
      var smartMarkContainer = dojo.byId("previewTextNotificationContainer");
      var smartMarks = query(".tierTwo").children();
      var xMark = query(".cross")[0];
      var recText = query(".animatedRecText")[0];
      var smartMarkCelebration = smartMarks[0];
      var smartMarkAnimated = smartMarks[1];
      var smartMarkIdle = smartMarks[2];
      var fadeArgs = {
        node: recText,
        duration: 500
      };

      //Intro animation of smartmark
      domClass.remove(smartMarkAnimated, "hide");
      domClass.add(xMark, "hide");
      domClass.add(smartMarkIdle, "hide");
      domClass.add(smartMarkCelebration, "hide");
      domClass.add(smartMarkContainer, "halfWidth");
      domClass.remove(recText, "animatedRecText");
      dojo.fadeOut(fadeArgs).play();
      setTimeout(function () {
        domAttr.set(recText, "textContent", "Generating custom preview text for your email...");
        dojo.fadeIn(fadeArgs).play();
      }, 500);

      //Animation after the generate button has been clicked.
      setTimeout(function () {
        dojo.fadeOut(fadeArgs).play();
        setTimeout(function () {
          domAttr.set(recText, "textContent", "Complete!");
          dojo.fadeIn(fadeArgs).play();
        }, 500);
        setTimeout(function () {
          callback();
        }, 1000);
      }, 3000);
    },
    dismissPreviewTextRec: function (e) {
      domClass.add(this.smartMarkContainer, "hide");
      domClass.remove(this.smartMarkDismissed, "hide");
      this.onDismiss();
    },
    addPreviewText: function (e) {
      this.onClickAddPreviewText(e);
    },
    setAnimationStatus: function (status) {
      this.addPersistentExtraProps({
        hasSeenPreviewTextAnimation: status
      });
      this.setState({
        hasSeenPreviewTextAnimation: status
      });
    },
    setPreviewTextStatus: function (status) {
      this.addPersistentExtraProps({
        previewTextRecStatus: status
      });
    }
  });
  PreviewTextRec.shouldShowSmartPreviewTextRec = shouldShowSmartPreviewTextRec;
  PreviewTextRec.STATUS_PT_NOT_SHOWN = STATUS_PT_NOT_SHOWN;
  PreviewTextRec.STATUS_PT_UNAVAILABLE = STATUS_PT_UNAVAILABLE;
  PreviewTextRec.STATUS_PT_AVAILABLE = STATUS_PT_AVAILABLE;
  PreviewTextRec.STATUS_PT_REGEN_AVAILABLE = STATUS_PT_REGEN_AVAILABLE;
  PreviewTextRec.STATUS_PT_MAX_REGEN_REACHED = STATUS_PT_MAX_REGEN_REACHED;
  PreviewTextRec.STATUS_PT_DISMISSED = STATUS_PT_DISMISSED;
  return PreviewTextRec;
});