define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/SimpleViewAdapter", "velocity/velocity"], function (declare, lang, registry, _WidgetBase, domConstruct, domClass, domStyle, Deferred, SimpleViewAdapter, Velocity) {
  //////// VIEW TRANSITIONS /////////

  var fadeTransition = function (oldView, newView) {
    var deferred = new Deferred();
    domStyle.set(newView, {
      position: "absolute",
      top: 0,
      width: "100%"
    });
    domClass.remove(newView, "hide");
    Velocity(newView, "fadeIn", {
      duration: 225,
      complete: function () {
        domStyle.set(newView, {
          position: "relative",
          top: "",
          width: ""
        });
        deferred.resolve();
      }
    });
    Velocity(oldView, "fadeOut", {
      duration: 225,
      complete: function () {
        domClass.add(oldView, "hide");
      }
    });
    return deferred.promise;
  };
  var slideTransition = function (oldView, newView, oldPosition, newPosition) {
    var deferred = new Deferred();
    domStyle.set(newView, {
      position: "absolute",
      transform: oldPosition > newPosition ? "translateX(-100%)" : "translateX(100%)",
      width: "100%"
    });
    domClass.remove(newView, "hide");
    var inDirection, outDirection;
    if (oldPosition > newPosition) {
      inDirection = {
        translateX: ["0%", "-100%"]
      };
      outDirection = {
        translateX: ["100%"]
      };
    } else {
      inDirection = {
        translateX: ["0%", "100%"]
      };
      outDirection = {
        translateX: ["-100%"]
      };
    }
    Velocity(newView, inDirection, {
      duration: 225,
      complete: function () {
        domStyle.set(newView, {
          position: "relative",
          top: "",
          width: ""
        });
        deferred.resolve();
      }
    });
    Velocity(oldView, outDirection, {
      duration: 225,
      complete: function () {
        domClass.add(oldView, "hide");
      }
    });
    return deferred.promise;
  };
  return declare([_WidgetBase], {
    // Transition configuration
    transitionMap: {
      "fade": fadeTransition,
      "slide": slideTransition
    },
    // Starting position. This is frequently updated
    position: -1,
    // There are a variety of ways to pass views into the ViewSwitcher.
    // By default it's SimpleViewAdapter
    adapter: null,
    // We keep a quick copy of the views for faster reference
    viewCache: [],
    // Sometimes the viewCache gets in the way (for example dynamically instantiation widgets)
    skipCaching: false,
    // Top-level pulse check that's updating by setCurrentView()
    isAnimating: false,
    // Reference to the current view's domNode
    currentView: null,
    // Which transition style should we use? Either "fade" or "slide".
    transition: null,
    // Allows you to skip ahead of needed.
    startIndex: 0,
    /**
     * Part of Dojo's Life Cycle. Does some early domNode building and transition configuration.
     */
    postCreate: function () {
      this.currentView = domConstruct.create("div");
      if (this.transition) {
        if (this.transitionMap[this.transition]) {
          this.transitionViews = this.transitionMap[this.transition];
        } else {
          throw Error("Invalid transition " + this.transition);
        }
      }
    },
    /**
     * Also part of Dojo's lifecycle. Biggest thing we are doing is configuring the View adapter and actually
     * show the appropriate view.
     */
    startup: function () {
      // if we haven't explicitly set an adapter, we'll just switch
      // between all of the child nodes of this widget.
      if (!this.adapter) {
        this.adapter = SimpleViewAdapter(this.domNode);
      }
      domConstruct.place(this.currentView, this.domNode);
      this.setCurrentView( /* newPosition */this.startIndex, /* skipTransition */true);
    },
    /**
     * Simple public method for retrieving current position.
     *
     * @returns {number|*} - Where we are in the view array.
     */
    getCurrentPosition: function () {
      return this.position;
    },
    /**
     * Get the domNode for a specific view position. Able to use/skip cache.
     *
     * @param {Number} position - Which view do we load?
     * @returns {node} - What node to place
     *
     * @private
     */
    _getViewAt: function (position) {
      if (this.skipCaching === true) {
        return this.adapter.createView(position);
      }
      if (!this.viewCache[position]) {
        this.viewCache[position] = this.adapter.createView(position);
      }
      return this.viewCache[position];
    },
    /**
     * Switch to a different view. Use a Deferred so we can know when it is done/failed.
     *
     * @param {Number} newPosition - What item in the index do we load.
     * @param {boolean} skipTransition - Jump straight to node or do stylish animation
     * @param {boolean} isForced - In case indexes change. Set the view like you mean it.
     *
     * @returns {Promise} - Did setting the view fail?
     */
    setCurrentView: function (newPosition, skipTransition, isForced) {
      var deferred = new Deferred();
      var oldView = this.currentView;

      // Check to see if we are switching from the 1st item (Index 0)
      // to use for positioning setting
      var isFirstPosition = isForced && newPosition === 1;
      if (!this.isAnimating && (isForced === true || +newPosition !== +this.position)) {
        var newView = domConstruct.create("div", {
          class: "hide"
        });
        domConstruct.place(this._getViewAt(newPosition), newView, "only");
        domConstruct.place(newView, this.domNode, "after");
        if (!skipTransition) {
          this.isAnimating = true;
          this.transitionViews(oldView, newView, +this.position, +newPosition).then(lang.hitch(this, function () {
            this.currentView = newView;
            this.position = isFirstPosition ? 0 : newPosition;
            domConstruct.destroy(oldView);
            this.isAnimating = false;
            deferred.resolve(newView);
          }));
        } else {
          domClass.add(oldView, "hide");
          domClass.remove(newView, "hide");
          this.currentView = newView;
          this.position = newPosition;
          domConstruct.destroy(oldView);
          deferred.resolve(newView);
        }
      } else {
        deferred.resolve(oldView);
      }
      return deferred.promise;
    },
    /**
     * Simple method that toggles classes between the old and new view.
     *
     * @param {node} oldView - What view we're coming from
     * @param {node} newView - What view we're going to next
     * @returns {Promise} when classes have been successfully applied.
     */
    transitionViews: function (oldView, newView) {
      var deferred = new Deferred();
      domClass.add(oldView, "hide");
      domClass.remove(newView, "hide");
      deferred.resolve();
      return deferred.promise;
    },
    /**
     * Move to the next view as long as we aren't at the end already.
     */
    next: function () {
      if (this.position < this.adapter.getCount() - 1) {
        this.setCurrentView(this.position + 1);
      }
    },
    /**
     * Move to the previous view if we aren't already at position 0.
     */
    previous: function () {
      if (this.position > 0) {
        this.setCurrentView(this.position - 1);
      }
    },
    /**
     * Remove all of the child widgets
     */
    destroy: function () {
      this.inherited(arguments);
      this.adapter.destroy();
      for (var i = 0; i < this.viewCache.length; i++) {
        if (this.viewCache[i]) {
          registry.findWidgets(this.viewCache[i]).forEach(function (widget) {
            widget.destroyRecursive();
          });
          domConstruct.destroy(this.viewCache[i]);
          this.viewCache[i] = null;
        }
      }
    }
  });
});