define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "mojo/widgets/Renderable", "dojo/text!./templates/_send-postcard.html", "dijit/registry", "dijit/form/DateTextBox", "dijit/form/CheckBox", "mojo/authz", "mojo/url", "mojo/user", "mojo/context", "mojo/utils", "mojo/utils/date", "mojo/lib/flags", "mojo/lib/logger", "mojo/api/postcard"], function (declare, lang, dom, domAttr, domStyle, domClass, locale, Renderable, tpl, registry, DateTextBox, CheckBox, Authz, mUrl, user, context, utils, dateUtils, flags, logger, postcardsApi) {
  return declare([Renderable, Authz], {
    templateString: tpl,
    formUrl: mUrl.toUrl("postcards/send"),
    formUrlScheduled: mUrl.toUrl("postcards/schedule"),
    upgradeCheckBox: null,
    getPrimaryPreSendMessage: function () {
      if (this.requiresAccountUpgrade() && !this.isScheduledCampaign()) {
        return "You're sending " + this.postcardRecipientsDescription();
      } else if (this.isScheduledCampaign()) {
        return "";
      }
      return "You'll pay <strong>" + this.state.amountDue + "</strong> to send " + this.postcardRecipientsDescription();
    },
    // returns a string like "<strong>4 postcards</strong> to the segment <strong>Cool Dudes</strong> on your list <strong>Listy</strong>."
    postcardRecipientsDescription: function () {
      var postcard = this.state.model;
      if (postcard.automationTrigger.strategy === postcardsApi.AUTOMATION_STRATEGY_ABANDONED_CART) {
        var estimatedReach = Math.floor(this.state.amountDueInUserCurrency / window._mc.transactional_price_per_cart_estimate);
        return "<strong>up to " + estimatedReach + " postcards</strong> to customers who abandon their cart.";
      }
      var isSimilarPeopleFinder = this.state.model.audienceStrategy === "lookalike";
      var postcardQuantity = postcard.audienceSize === 1 ? "1 postcard" : postcard.audienceSize + " postcards";
      var to = isSimilarPeopleFinder ? "to people similar to" : "to";
      var listName = postcard.list && postcard.list.name;
      var segmentName = postcard.segment && postcard.segment.name;
      if (postcard.audienceStrategy === "upload") {
        return "<strong>" + postcardQuantity + "</strong> " + to + " your imported file.";
      }
      return "<strong>" + postcardQuantity + "</strong> " + to + " the segment <strong>" + segmentName + "</strong> on your list <strong>" + listName + "</strong>.";
    },
    getSecondaryPreSendMessage: function () {
      var postcard = this.state.model;
      if (this.requiresAccountUpgrade() && !this.isScheduledCampaign()) {
        return this.getAccountUpgradeMessage();
      }
      if (postcard.automationTrigger.strategy === postcardsApi.AUTOMATION_STRATEGY_ABANDONED_CART) {
        return "Don't worry, you can pause and edit your postcard at any time.";
      }
      if (postcard.automationTrigger.strategy === postcardsApi.AUTOMATION_STRATEGY_RECURRING) {
        return "Don’t worry, you can pause and edit your postcard campaign after your first order is finished processing.";
      } else if (this.isScheduledCampaign()) {
        return "Estimated postcard delivery is <strong> 5 - 9 business days</strong > after the send to printer date.  Once you schedule your campaign, your audience can\'t be changed.  Learn more about <a href=\"https://mailchimp.com/help/schedule-cancel-postcards/\" target=\"_blank\">how to schedule a postcard</a>.";
      }
      return "Once you submit your order, you won't be able to edit your postcard.";
    },
    getAccountUpgradeMessage: function () {
      var monthlyCost = window._mc.account_upgrade_details.upgrade_cost_in_user_currency;
      var isSimilarPeopleFinder = this.state.model.audienceStrategy === "lookalike";
      var highlightedFeature = isSimilarPeopleFinder ? "lookalike audience finder" : "address finder";
      return "Sending this postcard will upgrade you to a  " + monthlyCost + "/month Standard plan.  You'll get unlimited access to " + highlightedFeature + " and all Standard plan features.";
    },
    getSubmitButtonText: function () {
      var postcard = this.state.model;
      return "Confirm Order";
    },
    getScheduleButtonText: function () {
      if (this.requiresAccountUpgrade() && this.isScheduledCampaign()) {
        return "Schedule";
      }
      return "Schedule Postcard";
    },
    getPostcardModalTitle: function () {
      if (this.isScheduledCampaign()) {
        return "Schedule Your Postcard";
      }
      return "Review Your Campaign";
    },
    /**
     * This function sets the max-width on the modal based off a condition
     * @returns {String} Returns a string value of the max-width
     */
    getPostcardModalStyle: function () {
      return !this.isScheduledCampaign() ? "max-width: 500px" : "";
    },
    /**
     * This function accepts a date string and formats it for the DateTextBox in postCreate()
     * @param {*} dateToFormat accepts a date string and formats it for the DateTextBox widget
     * @returns {String} returns a date as a string
     */
    formatForDatePicker: function (dateToFormat) {
      var formatDate = locale.format(dateToFormat, {
        selector: "minDate",
        datePattern: "y-MM-dd"
      });
      return formatDate.slice(0, 10);
    },
    /**
     * This function takes a date object, adds an additional 25 hours to the date object and returns the updated date
     * @param {*} minDateToFormat accepts a date object and formats it for the DateTextBox widget
     */

    minScheduledDate: function (minDateToFormat) {
      var minDate = minDateToFormat;
      var nextDay = 25 * 60 * 60 * 1000;
      minDate.setTime(minDate.getTime() + nextDay);
      dateUtils.getDateOnlyString(minDate);
    },
    toggleScheduleBtn: function (checked) {
      domAttr.set(this.submitBtn, "disabled", !checked);
    },
    constructor: function (props) {
      this.state = {
        model: props.model,
        isTryingAddressFinder: props.model.isTryingAddressFinder,
        CVV: props.CVV,
        pricingVersion: props.pricingVersion,
        amountDue: props.amountDue,
        amountDueInUserCurrency: props.amountDueInUserCurrency,
        csrfToken: user.__csrfToken,
        scheduledDate: new Date(),
        isPostcardScheduled: props.isPostcardScheduled,
        // TODO remove coupling to window
        accountUpgradeDetails: {
          subscribers: window._mc.account_upgrade_details.total_list_size,
          monthlyAmount: window._mc.account_upgrade_details.upgrade_cost_in_user_currency,
          lowerBound: window._mc.account_upgrade_details.upgrade_list_lower_bound,
          upperBound: window._mc.account_upgrade_details.upgrade_list_upper_bound || 0,
          maxEmailSends: window._mc.account_upgrade_details.max_email_sends || 0,
          addOnContactBlockPrice: window._mc.account_upgrade_details.add_on_contact_block_price_in_user_currency,
          addOnContactBlockSize: window._mc.account_upgrade_details.add_on_contact_block_size || 0,
          additionalEmailSends: window._mc.account_upgrade_details.additional_email_sends || 0
        }
      };
      /**
       * Accepts what is in the DateTextBox value as the selected date
       * and onChange date to send to the back end
       */

      this.datePickerSelectedDate = function (selectedDate) {
        this.state.scheduledDate = new Date(selectedDate);
      };
      this.requiresAccountUpgrade = function () {
        var canUsePostcardAddressFinder = this.userHasFeature("postcard_address_finder");
        var canUsePostcardLookAlikeFinder = this.userHasFeature("postcard_lookalike_audience_finder");
        if (!canUsePostcardAddressFinder && props.model.isTryingAddressFinder) {
          return true;
        }
        if (!canUsePostcardLookAlikeFinder && this.state.model.audienceStrategy === "lookalike") {
          return true;
        }
      };
      this.isScheduledCampaign = function () {
        return this.state.isPostcardScheduled;
      };
    },
    postCreate: function () {
      if (this.isScheduledCampaign()) {
        /**
         * Updates the date in state to the minimum scheduled delivery date
         */
        this.minScheduledDate(this.state.scheduledDate);

        /**
         * Adds the date picker in the postCreate().  The minimum date and value is the date set in state.
         * If the user opens the dialog and chooses the already set date, the value will be sent to the back end as the scheduled date.
         * Note: The date picker "onChange" does not return the actual time so it returns just a date value. A change on the back end
         * will be made to support accepting the date without the time for postcard scheduling.
         */

        var showDatePicker = new DateTextBox({
          constraints: {
            datePattern: "MMMM d yyy",
            min: this.formatForDatePicker(this.state.scheduledDate)
          },
          id: "scheduleDate",
          onChange: this.datePickerSelectedDate.bind(this),
          required: true,
          value: this.formatForDatePicker(this.state.scheduledDate)
        });

        /**
         * Places the date picker in the dojo attach point in the send-postcard.html template
         */
        showDatePicker.placeAt(this.datePicker);
      }
      if (this.requiresAccountUpgrade()) {
        /** Adds the upgradeCheckBox input dynamically into the modal
        * the toggleScheduleBtn is called on the onChange event to toggle the schedule button
            **/
        this.upgradeCheckBox = new CheckBox({
          name: "upgradeCheckBox",
          id: "upgradeCheckBox",
          onChange: this.toggleScheduleBtn.bind(this),
          checked: true
        });

        /** Places the input in the div */
        this.upgradeCheckBox.placeAt(this.upgradeCheckBoxContainer);
      }
    },
    showDialog: function () {
      var sendPostcardModal = registry.byId(this.sendPostcardModal.id);
      sendPostcardModal.onHide = function () {
        sendPostcardModal.destroyRecursive();
      };
      sendPostcardModal.show();
    },
    maintainSizeOnSend: function () {
      document.getElementById("sendPostcardModal").style.maxWidth = !this.isScheduledCampaign() ? "500px" : "100%";
    },
    sendConfirmed: function (event) {
      this.maintainSizeOnSend();
      if (this.requiresAccountUpgrade()) {
        var upgradeAlert = document.getElementById("upgradeAlert");
        if (!this.upgradeCheckBox.get("checked")) {
          upgradeAlert.style.display = "block";
          return;
        }
        upgradeAlert.style.display = "none";
        this.postcardConfirmUpgradeInput.value = this.upgradeCheckBox.get("value");
      }
      registry.byId(this.sendPostcardModal.id).set("title", "Sending...");
      registry.byId(this.sendPostcardModal.id).set("closable", false);
      if (this.confirmSendPre) {
        domStyle.set(this.confirmSendPre, "display", "none");
      }
      domStyle.set(this.confirmSendAction, "display", "none");
      domStyle.set(this.confirmSendPost, "display", "block");
      if (this.state.CVV) {
        document.getElementById("hidden-cc-security-input").value = this.state.CVV;
      }

      //Google analytics
      var label = {
        location: "postcard_send",
        upgrade_reason: "postcard",
        button_text: this.getSubmitButtonText()
      };
      logger.googleAnalytics.trackEvent("upsell_moment", "button_click", JSON.stringify(label));
      var form = document.getElementById("postcard-confirm-send");
      var button = document.createElement('button');
      button.setAttribute('type', 'submit');
      form.appendChild(button);
      button.click();
    },
    scheduleConfirmed: function (event) {
      this.maintainSizeOnSend();
      if (this.requiresAccountUpgrade()) {
        var upgradeAlert = document.getElementById("upgradeAlert");
        if (!this.upgradeCheckBox.get("checked")) {
          upgradeAlert.style.display = "block";
          return;
        }
        upgradeAlert.style.display = "none";
        this.postcardScheduleConfirmUpgradeInput.value = this.upgradeCheckBox.get("value");
      }
      registry.byId(this.sendPostcardModal.id).set("title", "Scheduling...");
      registry.byId(this.sendPostcardModal.id).set("closable", false);
      if (this.confirmSendPre) {
        domStyle.set(this.confirmSendPre, "display", "none");
      }
      domStyle.set(this.confirmScheduleAction, "display", "none");
      domStyle.set(this.confirmSendPost, "display", "block");

      /**
       * Sends the stored value in the date picker input to the back end in ISO8601 format removing the time.
       */

      document.getElementById("datePickerScheduled").value = this.state.scheduledDate.toISOString().split("T")[0];
      if (this.state.CVV) {
        document.getElementById("hidden-cc-security-input-scheduled").value = this.state.CVV;
      }
      var form = document.getElementById("postcard-confirm-schedule");
      var button = document.createElement('button');
      button.setAttribute('type', 'submit');
      form.appendChild(button);
      button.click();
    }
  });
});