define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/mvc/sync", "dijit/form/Select", "dojo/text!./templates/pageSizeSelect.html", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/I18nTranslation"], function (declare, _Part, _Templated, array, sync, Select, tpl, domAttr, I18nTranslation) {
  var pageSizes = [10, 25, 50, 100];
  return declare([_Part, _Templated], {
    templateString: tpl,
    viewText: "View",
    postMixInProperties: function () {
      this.viewLabel = this.viewText;
      I18nTranslation.initialize("I18N_REPORTING_TRANSLATIONS").then(function (translations) {
        this.viewLabel = translations.table_page_size_view_label;
      }.bind(this));
    },
    init: function () {
      this.pageOptions = new Select({
        "class": "!margin--lv0 select-small valign-middle",
        "options": array.map(pageSizes, function (size) {
          return {
            label: String(size),
            value: String(size)
          };
        })
      }, this.pageOptions);
      sync(this.target, "pageSize", this.pageOptions, "value", {
        parse: function (value) {
          return parseInt(value, 10);
        }
      });
    },
    canDisplay: function () {
      return this.inherited(arguments) && this.target.total > pageSizes[0];
    }
  });
});