/**
 * Sign Up Form is a Web-Only Block, specific to Landing Pages
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/neapolitan/utils", "mojo/lib/flags", "mojo/user", "vendor/lodash-amd/find", "./_Block"], function (declare, lang, topic, neapUtils, flags, user, _find, _Block) {
  return declare([_Block], {
    type: "signUpForm",
    _campaignType: "page",
    _name: "Signup Form",
    _blockEditorControls: ["sort", "edit", "delete"],
    _renderMergeTags: true,
    mergeTag: "*|PAGE:SIGNUP_FORM|*",
    _kbLink: "https://mailchimp.com/help/use-signup-form-blocks/",
    buttonWidthStyle: "full",
    formWidth: "450px",
    buttonAlignment: "left",
    blockAlignment: "center",
    blockWidth: "450px",
    defaultFormConfigStyle: {
      "labels": {
        "font": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        "color": "#222222"
      },
      "button": {
        "color": "#4CAAD8",
        "hover_color": "#3981A3",
        "border": 0,
        "alignment": "left",
        "style": "full",
        "text_color": "#FFFFFF"
      }
    },
    constructor: function () {
      this.styles = [{
        "name": "Block container styles",
        "selector": ".mcnSignUpBlockContentContainer",
        "props": {
          "background-color": {
            "value": ""
          }
        }
      }, {
        "name": "Form label style",
        "selector": ".webContent__content label",
        "props": {
          "color": {
            "value": "#222222"
          },
          "font-family": {
            "value": "Arial, 'Helvetica Neue', Helvetica, sans-serif"
          },
          "font-size": {
            "value": ""
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": ""
          }
        }
      }, {
        "name": "Form input style",
        "selector": ".webContent__content input[type='text'], .webContent__content input[type='email'], .webContent__content select",
        "props": {
          "background-color": {
            "value": "#FFFFFF"
          },
          "color": {
            "value": "#222222"
          },
          "border": {
            "value": "",
            "resetValue": "default"
          },
          "border-radius": {
            "value": "0px",
            "unit": "px",
            "minimum": 0,
            "maximum": 50,
            "wholeNumbersOnly": true
          }
        }
      }, {
        "name": "Button style",
        "selector": ".webContent__content .button",
        "props": {
          "background-color": {
            "value": "#4CAAD8"
          },
          "border-radius": {
            "value": "3px",
            "unit": "px",
            "minimum": 0,
            "maximum": 50,
            "wholeNumbersOnly": true
          },
          "border": {
            "value": ""
          },
          "box-shadow": {
            "value": "none"
          },
          "color": {
            "value": "#FFFFFF"
          },
          "font-family": {
            "value": ""
          },
          "font-size": {
            "value": ""
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": ""
          },
          "padding-top": {
            "value": "15px"
          },
          "padding-left": {
            "value": "15px"
          },
          "padding-bottom": {
            "value": "15px"
          },
          "padding-right": {
            "value": "15px"
          }
        }
      }, {
        "name": "Button hover style",
        "selector": ".webContent__content .button:hover",
        "props": {
          "background-color": {
            "value": ""
          },
          "border": {
            "value": "",
            resetValue: "inherit inherit inherit"
          },
          "color": {
            "label": "Text color",
            "value": ""
          }
        }
      }];
      this.inherited(arguments);
    },
    // runs to merge data from existing block model
    postscript: function (params, blockOverride) {
      if (blockOverride) {
        neapUtils.extend(true, this, blockOverride);
      }
      // Find any 'props' that is setup as an array and convert them
      if (this.styles) {
        this.styles.forEach(function (style) {
          if (lang.isArray(style.props)) {
            var convertedProps = {};
            style.props.forEach(function (item) {
              convertedProps[item] = {
                "value": ""
              };
            });
            style.props = convertedProps;
          }
        });
      }
      // check out params.styles - if length is 1, it's the old setup
      if (params.hasOwnProperty("styles") && params.styles.length === 1) {
        // replace this.styles
        // are params the old or the new
        // get values from styles[0] and replace
        var paramLabelStyle = _find(params.styles, {
          "selector": ".webContent__content label"
        });
        var modelLabelStyles = _find(this.styles, {
          "selector": ".webContent__content label"
        });
        neapUtils.extend(true, modelLabelStyles, paramLabelStyle);
        delete params.styles;
      } else if (params.hasOwnProperty("styles") && params.styles.length > this.styles.length) {
        // non-flag params will collide with current this.styles objects
        // remove param styles to avoid conflict
        delete params.styles;
      }
      if (params) {
        neapUtils.extend(true, this, params);
      }
    },
    getFormConfigStyles: function () {
      return lang.mixin(this.defaultFormConfigStyle, {
        formStyles: {
          buttonWidthStyle: this.buttonWidthStyle,
          buttonAlignment: this.buttonAlignment,
          formWidth: this.formWidth
        },
        neapolitan: this.styles
      });
    },
    /**
     * @inheritDoc
     */
    _setupMergeListeners: function (mcBlockInstance) {
      mcBlockInstance.formConfigUpdate = topic.subscribe("neapolitan/preview/pages/form/config/update", mcBlockInstance.refresh.bind(mcBlockInstance));
    },
    /**
     * @inheritDoc
     */
    _teardownMergeListeners: function (mcBlockInstance) {
      if (mcBlockInstance.formConfigUpdate) {
        mcBlockInstance.formConfigUpdate.remove();
      }
    }
  });
});