define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Templated", "dijit/_Widget", "mojo/url", "mojo/widgets/ContentManagerPane", "dojo/text!./templates/imageUploader.html"], function (declare, lang, event, query, on, html, domStyle, domConstruct, domAttr, domClass, _Templated, _Widget, mUrl, ContentManagerPane, uploaderTpl) {
  var EMPTY_IMAGE_PATH = mUrl.toUrl("https://cdn-images.mailchimp.com/product/icons/empty-image.svg");
  return declare([_Widget, _Templated], {
    templateString: uploaderTpl,
    baseClass: "mojoImageUploader blockDropTarget",
    uploadCopy: null,
    emptyImage: null,
    type: "image",
    allowedFileTypes: ["image"],
    _fileDragSignal: null,
    _showDrag: false,
    emptyImagePath: EMPTY_IMAGE_PATH,
    prefillImage: null,
    constructor: function () {
      this.placeholder = false;
    },
    postMixInProperties: function () {
      this.uploadCopy = "Drop an image";
      this.emptyImage = "https://cdn-images.mailchimp.com/product/icons/empty-image.svg";
    },
    buildRendering: function () {
      if (domClass.contains(this.node, "form-image-uploader")) {
        this.inherited(arguments);
        this.isPlaceholder = true;
      } else {
        this.domNode = this.node;
        domClass.add(this.domNode, "blockDropTarget");
        this.isPlaceholder = false;
      }
    },
    postCreate: function () {
      var self = this;
      if (this.browseBtn) {
        on(this.browseBtn, "click", this.handleBrowseButtonClick.bind(this));
        on(this.fileInput, "change", function (e) {
          this.onImageUploading();
          var files = e.target.files;
          if (files.length > 0) {
            this.upload(files[0]);
          }
        }.bind(this));
      }
      if (this.removeBtn) {
        on(this.removeBtn, "click", this.handleRemoveButtonClick.bind(this));
      }
      if (this.placeholder) {
        domConstruct.place("<span>Image Placeholder</span>", this.uploadText, "only");
        if (this.browseBtn) {
          domStyle.set(this.browseBtn, "display", "none");
        }
      }
      if (this.prefillImage) {
        domAttr.set(this.imageNode, "src", this.prefillImage.url);
        domAttr.set(this.imageNode, "id", this.prefillImage.name);
      }
    },
    startup: function () {
      if (domClass.contains(this.node, "form-image-uploader")) {
        domConstruct.place(this.domNode, this.node);
      }
    },
    handleBrowseButtonClick: function (e) {
      if (this.placeholder) {
        return;
      }
      event.stop(e);
      this.fileInput.click();
    },
    handleRemoveButtonClick: function (e) {
      event.stop(e);
      // reset the image
      this.onImageRemoved();
      this.switchToEmptyView();
    },
    /**
     * resetImage is to reset the picker
     * @public
     */
    resetImage: function () {
      this.switchToEmptyView();
    },
    /**
     * Used for callback purposes. dojo.connect to this to receive the url of the image.
     * @param {Object} image -- Object containing url, width, height of the image that was selected.
     */
    onImageSelected: function (image) {},
    /**
     * Used for callback purposes. dojo.connect to this to remove the url of the image.
     */
    onImageRemoved: function () {},
    /**
     * Used for callback purposes when image uploading starts.
     */
    onImageUploading: function () {},
    upload: function (content) {
      // var promise = new Deferred();
      this.onUploading(true);
      this.setUploadProgress(0.20);
      this.switchToUploadingView();
      return ContentManagerPane.uploadFile(content, null, false).then(function (res) {
        if (!res.error) {
          this._handleSuccessfulImageUpload(res);
        } else {
          this._handleErrorImageUpload(res.error);
        }
        return res;
      }.bind(this), this._handleErrorImageUpload.bind(this));
    },
    _parseFile: function (file, currentImageWidth, promise) {
      this.setUploadProgress(0.10);
      window.parent.require(["mojo/widgets/FileManager"], lang.hitch(this, function (FileManager) {
        FileManager.uploadFile(file).then(lang.hitch(this, function (contentItem) {
          this._handleSuccessfulImageUpload(contentItem, currentImageWidth);
        }), lang.hitch(this, "_handleErrorImageUpload")).then(function () {
          promise.resolve();
        });
      }));
    },
    _handleSuccessfulImageUpload: function (imageObj, currentImageWidth) {
      this.onUploading(false);
      this.setUploadProgress(1.00);
      this.switchToUploadedView(imageObj);
      this.onImageSelected(imageObj);
    },
    _handleErrorImageUpload: function (error) {
      this.onUploading(false);
      this.setUploadProgress(0.00);
      this.switchToEmptyView();
    },
    onUploading: function (isUploading) {
      this.uploading = isUploading;
    },
    /**
     * Change the progress bar display
     *
     * @param {float} percentage Percentage from [0.0, 1.0] to display progress
     */
    setUploadProgress: function (percentage) {
      var progressBar = query(".progress_bar_inner", this.domNode)[0];
      if (!progressBar) {
        return;
      }
      var widthInteger = Math.round(percentage * 100); // scale up
      widthInteger = Math.max(0, Math.min(100, widthInteger)); // constrain to [0, 100]
      domStyle.set(progressBar, "width", widthInteger + "%");
    },
    /**
     * Switch the the uploading progress bar view
     *
     */
    switchToUploadingView: function () {
      domStyle.set(this.uploadOverlay, "display", "block");
    },
    switchToUploadedView: function (contentItem) {
      var url = contentItem.url;
      domAttr.set(this.imageNode, "src", url);
      domAttr.set(this.imageNode, "id", contentItem.name);
      // change title and subtext and dimensions
      html.set(this.titleNode, contentItem.name);
      html.set(this.browseBtn, "Replace");
      // show remove button
      domClass.toggle(this.removeBtnNode, "hide", false);
      domStyle.set(this.uploadOverlay, "display", "");
    },
    switchToEmptyView: function () {
      domAttr.set(this.imageNode, "src", this.emptyImagePath);
      domAttr.set(this.imageNode, "id", "emptyImage");
      this.fileInput.value = null;
      // change title and subtext and dimensions
      html.set(this.titleNode, "Upload an image");
      html.set(this.browseBtn, "Browse");
      // show remove button
      domClass.toggle(this.removeBtnNode, "hide", true);
      domStyle.set(this.uploadOverlay, "display", "");
    }
  });
});