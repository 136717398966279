/**
 * A widget that represents a Zip Code input for our segmentation UI.
 * Adds formatting to the text, constraining it to only 5 numerical digits.
 * Uses a native text input box, but adds additional functions to make it easier to use for segmentation.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_SegmentInputMixin", "mojo/user", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, _WidgetBase, domConstruct, domAttr, lang, array, _SegmentInputMixin, user, flags, logger, I18nTranslation, context) {
  var blankText = "blank";
  return declare([_WidgetBase, _SegmentInputMixin], {
    value: "",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.SEGMENTATION_LEGACY_BUILDER_I18N) && !I18nTranslation.isTranslationObjectEmpty()) {
        blankText = I18nTranslation.translate("audience_management_segment_builder_blank");
      }
    },
    buildRendering: function () {
      var value = this.value;
      this.domNode = domConstruct.create("input", {
        type: "text"
      });
      if (value) {
        this.domNode.value = value;
      }
      this.set("value", this.domNode.value);
      this.on("change", lang.hitch(this, "onValueChange"));
      this.on("input", lang.hitch(this, "onKeyPress"));
    },
    getDisplayHTML: function () {
      return this.value ? this.value : "<span class=\"dim6\">(" + blankText + ")</span>";
    },
    getValue: function () {
      return this.value;
    },
    setValue: function (value) {
      this.set("value", value);
    },
    // Publish a Segment Engaged event to the Events Pipeline
    _logToEventsPipeline: function () {
      var sharedEventProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        object: "segment",
        object_detail: "create_segment",
        action: "engaged",
        ui_object: "drop_down",
        ui_object_detail: "segment_conditions",
        ui_action: "clicked",
        ui_access_point: "form_segment",
        segmentation_method: "advanced",
        condition_field: null
      };
      var contactTableProperties = Object.assign({
        scope_area: "contacts",
        screen: "lists/members"
      }, sharedEventProperties);
      var emailChecklistProperties = Object.assign({
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit"
      }, sharedEventProperties);

      // If event is from the Email Checklist page
      if (window.location.pathname.startsWith("/i/campaigns/edit")) {
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        var campaign_id = parseInt(new URLSearchParams(window.location.search).get("id"), 10);
        // Add campaign_id to email checklist properties
        emailChecklistProperties.campaign_id = campaign_id;
        logger.eventsPipeline.publish("segment:engaged", emailChecklistProperties);
        // If event is from the Contact Table page
      } else if (window.location.pathname.startsWith("/i/lists/members")) {
        logger.eventsPipeline.publish("segment:engaged", contactTableProperties);
      }
    },
    onValueChange: function () {
      this._updateValue();
      this._logToEventsPipeline();
    },
    onKeyPress: function () {
      this._updateValue();
    },
    _updateValue: function () {
      var formattedValue = this.domNode.value.replace(/\D+/g, "");
      if (formattedValue.length > 5) {
        formattedValue = formattedValue.substring(0, 5);
      }
      this.set("value", formattedValue);
    }
  });
});