/**
 * CVV Slat
 * @module mojo/widgets/ads/CVVSlat.js
 * @see mojo/widgets/ads/mobile-checklist/FacebookSlat.js
 *
 * The mobile version of the CVV input. Used as an input for
 * the PreFlight step of Publishing and ad.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/api/billing", "mojo/utils", "mojo/widgets/billing/PaymentMethod",
// Mixins
"./_EventAdapterMixin", "dijit/_TemplatedMixin",
// Templates
"dojo/text!./templates/CVVSlat.html"], function (declare, domClass, debounce, on, billingApi, utils, PaymentMethod, _EventAdapterMixin, _TemplatedMixin, tplStr) {
  return declare([_EventAdapterMixin, _TemplatedMixin], {
    templateString: tplStr,
    // Widget-level var representing the contents of the CVV input
    cvvValue: null,
    // {String} visa/mast/amex/disc. Passed from backend, not available at instantiation.
    ccType: null,
    postCreate: function () {
      this._initBillingSummary();

      // Call out to a function anytime somebody types or paste into the input field
      on(this.cvvInput, "keyup, paste", debounce(this._handleInputChange.bind(this), 200));
    },
    /**
     * Reach out to the backend to get the Billing Summary to display basic card information
     *
     * @private
     */
    _initBillingSummary: function () {
      billingApi.getPaymentMethod().then(this._handleBillingSummaryResponse.bind(this), function (error) {
        throw new Error("An error occurred when retrieving billing information");
      });
    },
    /**
     * Handle the response from the server
     *
     * @param {Object} billingData - data back from account/billing/paymentMethodAction
     *
     * @private
     */
    _handleBillingSummaryResponse: function (billingData) {
      this.billingData = billingData;
      this._validateBillingData();
      this._initSummary();
      this.set("ccType", this.billingData.card_type);
    },
    /**
     * Create the Payment Method widget and place into the appropriate node. Pass along all of the billing data.
     *
     * @private
     */
    _initSummary: function () {
      this.summary = new PaymentMethod(this.billingData);
      this.summary.placeAt(this.summaryContainer);
      this.summary.startup();
    },
    /**
     * Show/hide the CVV explanation.
     *
     * @param {obj} event - Standard JS click event.
     * @private
     */
    _toggleCVVPreview: function (event) {
      utils.disclosureElement( /* Dom ID to open */this.id + "_cvv-preview", /* Target */event.target, /* Toggle show language */false);
    },
    /**
     * Give feedback on the user's cvv input to see if it passes the
     * minimum check. Validation with card on file will happen on Publish
     *
     * @return {boolean} - Continue with submission?
     * @private
     */
    _passesSimpleValidation: function () {
      // Variable to check if the CVV value is a whole number
      // Although the input is a number field a user could enter a decimal
      var cvvIsInteger = Number.isInteger(parseFloat(this.cvvValue));

      // Check to see if the CVV field is blank
      if (this.cvvValue === "") {
        utils.addError(this.cvvInput, "Please enter your security code.");
        return false;
      }

      // Checks to see if the entered value is not an integer or the correct length. Unfortunately,
      // we need to be somewhat intentionally vague.
      // CVV needs to be a whole number and exactly 4 chars for Amex. 3 chars for all others.
      if (!cvvIsInteger || this.cvvValue.length < 3 || this.ccType !== "amex" && this.cvvValue.length > 3 || this.ccType === "amex" && this.cvvValue.length < 4) {
        utils.addError(this.cvvInput, "Please enter a valid security code.");
        return false;
      }
      return true;
    },
    /**
     * Remove the errors and invalid state from the input field
     */
    _handleInputChange: function () {
      utils.removeErrors(this.cvvInput);
      this.cvvValue = this.cvvInput.value;
    },
    /**
     * This widget only has a very limited use-case: Validating for CVV. Considering there are many forms of
     * payment methods, we should log if we are trying to load this slat in an invalid state.
     *
     * @private
     */
    _validateBillingData: function () {
      if (!this.billingData.payment_type) {
        throw new Error("No " + "'payment_type' declared. This is required.");
      }
      if (this.billingData.payment_type === "none") {
        throw new Error("This account does not have a payment method. We should not be requesting the CVV slat.");
      }
      if (this.billingData.payment_type !== "credit_card") {
        throw new Error("CVV slat only valid for accounts with credit cards attached.");
      }
    },
    /**
     * Validate the content sent from the backend and set some content within the widget.
     *
     * @param {String} ccType - Value passed along as part of this.set()
     *
     * @private
     */
    _setCcTypeAttr: function (ccType) {
      this._set("ccType", ccType);

      // Fill appropriate data based on credit card brand.
      var isAmex = ccType === "amex";
      this.inputLabel.innerText = isAmex ? "Security code" : "CVV";
      domClass.remove(isAmex ? this.amexPreview : this.otherPreview, "hide");

      // Make everything visible
      domClass.toggle(this.inputContainer, "hide");

      // Tell the bridge that the slat is ready to show.
      this.onLoaded();
    },
    /**
     * Check to see if CVV passes simple validation then
     * pass the value on
     */
    __save: function () {
      if (!this._passesSimpleValidation()) {
        this.onDoneWithError();
        return;
      }
      this.onDone({
        cvv: this.cvvValue
      });
    },
    /**
     * These methods are required to be implemented. We are intentionally not doing anything with them as this
     * slat doesn't need to handle the cancel/back buttons.
     */
    __cancel: function () {},
    __back: function () {}
  });
});