/**
 * Common behavior that condition rows share among each other.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./input/Select", "./input/Creator"], function (declare, array, lang, domConstruct, SegSelect, InputCreator) {
  return declare(null, {
    /**
     * Populated during `this.renderOpInput()`
     *
     * @var {string} Operator identifier, eg. `"any"`, `"is"`, `"date_within"`
     */
    op: null,
    /**
     * Return the current selected field config which contains ops and input settings.
     *
     * @abstract
     *
     * @returns {mojo/segmentation/FieldConfig}
     */
    getFieldConfig: function () {
      throw new Error("Must implement getFieldConfig");
    },
    /**
     * Return the currently selected op config.
     */
    getOpConfig: function () {
      throw new Error("Must implement getOpConfig");
    },
    getOpHTML: function () {
      return this.opSelect ? this.opSelect.getDisplayHTML() : "";
    },
    getValueHTML: function () {
      return this.valueInput ? this.valueInput.getDisplayHTML() : "";
    },
    getExtraHTML: function () {
      return this.extraInput ? this.extraInput.getDisplayHTML() : "";
    },
    renderOpInput: function () {
      var fieldConfig = this.getFieldConfig();
      var options = array.map(fieldConfig.ops, function (op) {
        return {
          "label": op.label,
          "value": op.value
        };
      });
      if (this.opSelect) {
        this.opSelect.replaceOptions(options);
        this.renderValueInput();
        this.renderExtraInput();
      } else {
        this.opSelect = new SegSelect({
          "class": "condition-value",
          "options": options,
          "value": this.op
        });
        this.opSelect.startup();
        this.opSelect.placeAt(this.opSelectContainer);
        this.set("op", this.opSelect.value);
        this.opSelect.watch("value", lang.hitch(this, function (name, old, newValue) {
          this.set("op", newValue);
          this.renderValueInput();
          this.renderExtraInput();
          this.onOperatorChanged();
        }));
      }
    },
    onOperatorChanged: function () {
      // reorder the things
      var position = "before"; // normally, operator is displayed before value
      if (this.getFieldConfig().displaysNameBeforeOperator(this.op)) {
        position = "after"; // but, if this field is special, place the name before
      }
      domConstruct.place(this.opSelectContainer, this.valueContainer, position);
    },
    renderValueInput: function () {
      var opConfig = this.getOpConfig();
      var inputConfig = opConfig.input;
      if (inputConfig) {
        var currentInput = this.valueInput;
        if (!currentInput || currentInput && !currentInput.isCompatible(inputConfig)) {
          this._deleteValueInput();
          this.valueInput = InputCreator.create(this.configs, inputConfig);
          this.valueInput.placeAt(this.valueContainer);
        }
      } else {
        this._deleteValueInput();
      }
    },
    renderExtraInput: function () {
      var opConfig = this.getOpConfig();
      var inputConfig = opConfig.extra;
      if (!inputConfig) {
        this._deleteExtraInput();
        return;
      }
      var currentInput = this.extraInput;
      if (!currentInput || currentInput && !currentInput.isCompatible(inputConfig)) {
        this._deleteExtraInput();
        this.extraInput = InputCreator.create(this.configs, inputConfig);
        this.extraInput.placeAt(this.extraContainer);
      }
    },
    getAST: function () {
      var selectedFieldConfig = this.getFieldConfig();
      var extra = null;
      if (this.extraInput) {
        extra = this.extraInput.getValue();
      } else if (this.valueInput) {
        extra = this.valueInput.getExtra();
      }
      var node = {
        "field": selectedFieldConfig.field,
        "op": this.op,
        "value": this.valueInput ? this.valueInput.getValue() : null,
        "value_op": this.valueInput ? this.valueInput.getValueOp() : null,
        "extra": extra
      };
      if (this.valueInput && node.field === "ipgeo" && ["ipgeoin", "ipgeonotin", "ipgeocity", "ipgeonotcity"].includes(node.op)) {
        var hiddenValues = this.valueInput.getHiddenValues();
        if (hiddenValues) {
          Object.assign(node, hiddenValues);
        }
      }
      if (node.extra === null) delete node['extra'];
      if (node.value_op === null) delete node['value_op'];
      return node;
    },
    _deleteValueInput: function () {
      if (this.valueInput) {
        this.valueInput.destroy();
        this.valueInput = null;
      }
    },
    _deleteExtraInput: function () {
      if (this.extraInput) {
        this.extraInput.destroy();
        this.extraInput = null;
      }
    },
    remove: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.destroy();
    },
    destroy: function () {
      if (this.valueInput) {
        this.valueInput.destroy();
      }
      if (this.opSelect) {
        this.opSelect.destroy();
      }
      this.inherited(arguments);
      this.onDestroy();
    },
    onDestroy: function () {}
  });
});