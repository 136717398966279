define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "mojo/lib/flags", "mojo/context",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/registry", "./GeolocationHelpTip", "./events/ContactProfileEvent",
// Templates
"dojo/text!./templates/fields-list-item-container.html"], function (declare, lang, htmlEntities, domConstruct, query, on, stringUtils, flags, context, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, registry, GeolocationHelpTip, ContactProfileEvent, itemTplContainer) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: itemTplContainer,
    data: null,
    defaultValue: "&mdash;",
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      this.checkForEmails();
      this.createListItem();
    },
    /**
     * If the value is an email address, mark its type as such
     */
    checkForEmails: function () {
      if (stringUtils.isValidEmailAddress(this.data.value)) {
        this.data.type = "email";
      }
    },
    /**
     * Create markup for the list item and append it to the <li> container
     * There are currently 3 cases:
     * 1. Default list item format of label and value
     * 2. Location list item format with location link and country flag
     * 3. Social profile item format with link to social profile
     * 4. StaticModal item format with static (i.e., non-edit) modal link
     * 5. Email item format with mailto link
     */
    createListItem: function () {
      var valueTemplate;
      switch (this.data.type) {
        case "link":
          valueTemplate = "<p class='contact-field' title='" + this.data.value + "'><a href='" + this.data.value + "' target='_blank'>" + this.data.value + "</a></p>";
          break;
        case "staticModal":
          valueTemplate = "<p class='contact-field' title='" + this.data.value + "'><a href='#' class='staticModalLink'>" + this.data.value + "</a></p>";
          break;
        case "email":
          valueTemplate = "<a target='_blank' rel='noopener noreferrer' href='/audience/inbox?id=" + this.data.extras.list_id + "&contact_id=" + this.data.extras.contact_id + "'>" + this.data.value + "</a>";
          break;
        case "geolocation": // renders just like default, except that we'll tack on an info tooltip later in this method
        default:
          if (this.data.value) {
            valueTemplate = "<p class='contact-field' title='" + this.data.value + "'>" + this.data.value + "</p>";
          } else {
            valueTemplate = "<p class='contact-field dim-el'>" + this.defaultValue + "</p>";
          }
      }
      domConstruct.place(valueTemplate, this.valueContainer);

      /**
       * The static modal type is a modal that has non-editable information (in this case, the form version dialog).
       * Query for the link that is created in the staticModal case above,
       * then open the form-version-dialog modal
       */
      var staticModalLink = query(".staticModalLink")[0];
      if (staticModalLink) {
        on(staticModalLink, "click", function () {
          var formVersionDialog = registry.byId("form-version-dialog");
          formVersionDialog.show();
          var event = new ContactProfileEvent("audience:engaged", {
            action: "engaged",
            ui_object: "link_text",
            ui_object_detail: "view_gdpr_form",
            ui_action: "clicked",
            ui_access_point: "bottom",
            event_origin: "dojo"
          });
          event.publishEvent();
        });
      }
      if (this.data.type === "geolocation") {
        var tip = new GeolocationHelpTip();
        tip.startup();
        tip.placeAt(this.labelContainer);
      }
    }
  });
});