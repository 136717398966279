define([], function () {
  return {
    /**
     * Setup and start the SlingShot animation
     * @param {string} canvas_id - The ID of the element where the animation will inject into
     * @param {number} step - Which step animation we should show.
     */
    startAnimation: function (canvas_id, step) {
      var canvas = document.getElementById(canvas_id);
      var exportRoot = this._getExportRoot(step);
      var stage = new window.createjs.Stage(canvas);
      stage.addChild(exportRoot);
      stage.update();
      window.createjs.Ticker.setFPS(window.lib.properties.fps);
      window.createjs.Ticker.addEventListener("tick", stage);
    },
    /**
     * Select the appropriate animation to load.
     * @param {number} step Which step animation we should show. Throws error if invalid.
     *
     * @return {*} Instantiation of correct animation
     * @private
     */
    _getExportRoot: function (step) {
      var validSteps = [1, 2];
      if (validSteps.indexOf(step) === -1) {
        throw new Error("Unknown animation step");
      }
      return step === 1 ? new window.lib.animateslingshot() : new window.lib.animateslingshotstep2();
    }
  };
});