define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/InstallationImages.html"], function (declare, lang, domClass, domStyle, on, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  // represents a small, clickable image thumbnail
  var Thumbnail = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: "<div class='install-image-thumbnail-container'>" + "<div class='install-thumbnail-image' data-dojo-attach-point='imageNode'></div>" + "</div>",
    // from constructor
    onClick: function () {},
    imageUrl: null,
    _setImageUrlAttr: function (imageUrl) {
      domStyle.set(this.imageNode, "background-image", "url(" + imageUrl + ")");
      this._set("imageUrl", imageUrl);
    },
    selected: false,
    _setSelectedAttr: function (isSelected) {
      if (isSelected && !this.selected) {
        domClass.add(this.domNode, "active");
      } else if (!isSelected && this.selected) {
        domClass.remove(this.domNode, "active");
      }
      this._set("selected", isSelected);
    },
    postCreate: function () {
      this.own(
      // listen for click on the entire widget
      on(this.domNode, "click", lang.hitch(this, function () {
        // pass `this` widget back to the parent
        this.onClick(this);
      })));
    }
  });
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    largeImageConainterNode: null,
    thumbnailContainerNode: null,
    // from constructor
    imageUrls: [],
    // internal state
    largeImageUrl: null,
    _setLargeImageUrlAttr: {
      node: "largeImageNode",
      type: "attribute",
      attribute: "src"
    },
    selectedThumbnail: null,
    _setSelectedThumbnailAttr: function (selection) {
      // the clicked thumbnail is already selected
      if (this.selectedThumbnail === selection) {
        return;
      }

      // update large image url
      this.set("largeImageUrl", selection.imageUrl);

      // protect against initial `null` case
      if (this.selectedThumbnail) {
        // notify previously selected thumbnail to remove selection style
        this.selectedThumbnail.set("selected", false);
      }

      // notify newly selected thumbnail to add selection style
      selection.set("selected", true);
      this._set("selectedThumbnail", selection);
    },
    postCreate: function () {
      // validate there are images to render
      if (!this.imageUrls || this.imageUrls.length <= 0) {
        return;
      }

      // create image thumbnails
      this.imageUrls.map(lang.hitch(this, function (imageUrl, index) {
        var thumbnail = new Thumbnail({
          imageUrl: imageUrl,
          onClick: this.set.bind(this, "selectedThumbnail")
        });

        // set the first thumbnail as selected and as large image
        if (index === 0) {
          this.set("selectedThumbnail", thumbnail);
        }
        thumbnail.placeAt(this.thumbnailContainerNode);
      }));
    }
  });
});