define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/url", "mojo/app/peaches2/utils", "mojo/utils", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, base, domAttr, on, registry, mUrl, p2Utils, utils, FilterMenu, FilterMessage) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    contactActivityFilters: null,
    onReady: function () {
      // Pulled from: MC\ContactProfile\ActivityFilter.php
      var filterSettings = {
        "target": "contact-activity-list",
        "filters": [{
          "label": "Email Sent",
          "key": "sent",
          "type": "checkbox"
        }, {
          "label": "Email Opened",
          "key": "opens",
          "type": "checkbox"
        }, {
          "label": "Email Clicked",
          "key": "clicks",
          "type": "checkbox"
        }, {
          "label": "Subscribed",
          "key": "subscribes",
          "type": "checkbox"
        }, {
          "label": "Unsubscribed",
          "key": "unsubs",
          "type": "checkbox"
        }, {
          "label": "Bounce Occurred",
          "key": "bounces",
          "type": "checkbox"
        }, {
          "label": "Order Placed",
          "key": "ecomm",
          "type": "checkbox"
        }, {
          "label": "Note Added",
          "key": "note",
          "type": "checkbox"
        }, {
          "label": "Conversation",
          "key": "conversation",
          "type": "checkbox"
        }, {
          "label": "Marketing Permission",
          "key": "marketing_permission",
          "type": "checkbox"
        }, {
          "label": "Postcard Sent",
          "key": "postcard_sent",
          "type": "checkbox"
        }, {
          "label": "Event",
          "key": "event",
          "type": "checkbox"
        }, {
          "label": "Web Engagement",
          "key": "web_engagement",
          "type": "checkbox"
        }, {
          "label": "Survey Response",
          "key": "survey_response",
          "type": "checkbox"
        }, {
          "label": "Inbox Thread",
          "key": "inbox_thread",
          "type": "checkbox"
        }, {
          "label": "Landing Page Signup",
          "key": "landing_page_signup",
          "type": "checkbox"
        }, {
          "label": "Squatter Signup",
          "key": "squatter_signup",
          "type": "checkbox"
        }, {
          "label": "Website Signup",
          "key": "website_signup",
          "type": "checkbox"
        }, {
          "label": "Ecommerce Signup",
          "key": "ecommerce_signup",
          "type": "checkbox"
        }, {
          "label": "Generic Signup",
          "key": "generic_signup",
          "type": "checkbox"
        }]
      };

      // store this reference for later so we can clear the filters
      this.contactActivityFilters = new FilterMenu(filterSettings);
      this.$el.contactActivityFilters.appendChild(this.contactActivityFilters.domNode);
      var filterMessage = new FilterMessage(filterSettings);
      this.$el.contactActivityFiltersMessage.appendChild(filterMessage.domNode);
      var self = this;
      on(this.$el.searchEmailForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.findEmail();
      });
      on(this.$el.dcForm, "submit", function (e) {
        e.preventDefault();
        e.stopPropagation();
        self.submitDcForm();
      });
      on(this.$el.dcFormAction, "change", function (e) {
        self.toggleDcFormListInputId(e.srcElement.value);
      });
      this.toggleDcFormListInputId(this.$el.dcFormAction.value);
      if (this.$group.contactActivityBtns) {
        this.$group.contactActivityBtns.forEach(function (el) {
          on(el, "click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            self.openContactActivityDialog(domAttr.get(el, "data-dojo-value"));
          });
        });
      }
    },
    openContactActivityDialog: function (list_email_id) {
      // clear any left over filters from the last time we opened the dialog
      this.contactActivityFilters.clearFilters();
      var list = registry.byId("contact-activity-list");
      var url = list.get("url");
      list.set("url", url.concat("&list_email_id=", list_email_id));
      registry.byId("contact-activity-dialog").show();
    },
    findEmail: function () {
      var self = this;
      var email = this.$el.searchEmailInput.value;
      var url = mUrl.toUrl("/peaches2/users/lists}", {
        _s: "list-activity",
        user_id: self.pageJs.userAttrs.userId,
        search_email: email
      });
      this.pageJs.tabContainer.listActivitySection.set("href", url);
    },
    toggleDcFormListInputId: function (actionValue) {
      if (actionValue === "forget") {
        this.$el.dcFormListInputId.classList.remove("hide");
      } else {
        this.$el.dcFormListInputId.classList.add("hide");
      }
    },
    submitDcForm: function () {
      if (this.$el.dcFormAction.value === "forget" && !domAttr.get("list_id", "value")) {
        p2Utils.utils.setFlash("Please enter a list ID.", "error");
        utils.toggleButtonLoadingState(document.getElementById("dc-form-submit"));
        return;
      }
      this.$el.dcForm.submit();
    }
  });
});