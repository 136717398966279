define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, on, lang, array, aspect) {
  return declare([], {
    selectors: [],
    maxValue: 8,
    minValue: 1,
    constructor: function (args) {
      lang.mixin(this, args);
      array.forEach(this.selectors, lang.hitch(this, "addSelector"));
    },
    addSelector: function (selector) {
      if (array.indexOf(this.selectors, selector) === -1) {
        this.selectors.push(selector);
      }
      aspect.after(selector, "onChange", lang.hitch(this, function () {
        this._updateValues(selector);
      }));
      this._updateValues();
    },
    _updateValues: function (selector) {
      array.forEach(this.selectors, lang.hitch(this, function (selector) {
        var potential = this._getPotential(selector);
        var total = this.getTotal();
        selector.set("potential", potential);
        selector.set("total", total);
      }));
      this.onChange(this.getTotal());
    },
    getTotal: function () {
      var total = 1;
      array.forEach(this.selectors, lang.hitch(this, function (selector) {
        if (selector.value) {
          total *= selector.value;
        }
      }));
      return total;
    },
    _getPotential: function (curSelector) {
      var maxPotential = this.maxValue;
      array.forEach(this.selectors, function (selector) {
        if (curSelector != selector) {
          if (selector.value) {
            maxPotential = Math.floor(maxPotential / selector.value);
          }
        }
      });
      return maxPotential;
    },
    onChange: function (value) {}
  });
});