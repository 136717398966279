define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/api/brand-assets", "./_Block", "./_ImageMixin"], function (declare, lang, topic, brandAssetsApi, _Block, _ImageMixin) {
  return declare([_Block, _ImageMixin], {
    type: "image",
    _name: "Image",
    _renderMergeTags: true,
    // experiment
    customSize: null,
    constructor: function () {
      this.image = null;
      this.edgeToEdge = false;
      this.align = "center";
      this.styles = this._getImageStyles();
    },
    onImageUpload: function (image) {
      this.set("image", lang.mixin(lang.mixin({}, this.image), image));
      this.save();
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      if (this.image && this.image.link && this.image.link.href === oldUrl) {
        this.image.link.href = newUrl;
        return this.save();
      } else {
        return this.inherited(arguments);
      }
    },
    /**
     * @inheritDoc
     */
    _setupMergeListeners: function (mcBlockInstance) {
      mcBlockInstance.brandUpdateSubscription = topic.subscribe(brandAssetsApi.TOPIC_CHANGES, mcBlockInstance.refresh.bind(mcBlockInstance));
    },
    /**
     * @inheritDoc
     */
    _teardownMergeListeners: function (mcBlockInstance) {
      if (mcBlockInstance.brandUpdateSubscription) {
        mcBlockInstance.brandUpdateSubscription.remove();
      }
    }
  });
});