define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"../Calendar", "./_DateTimeTextBox"], function (declare, Calendar, _DateTimeTextBox) {
  // module:
  //		dijit/form/DateTextBox

  return declare("dijit.form.DateTextBox", _DateTimeTextBox, {
    // summary:
    //		A validating, serializable, range-bound date text box with a drop down calendar
    // example:
    // |	new DateTextBox({value: new Date(2009, 0, 20)})
    // example:
    // |	<input data-dojo-type='dijit/form/DateTextBox' value='2009-01-20'>

    baseClass: "dijitTextBox dijitComboBox dijitDateTextBox",
    popupClass: Calendar,
    _selector: "date",
    // Force calendar dropdown to below to prevent Android mousedown/mouseup double tap
    dropDownPosition: ["below"],
    // Prevent scrollbar on Calendar dropdown.  On iPad it often gets a scrollbar unnecessarily because Viewport
    // thinks the keyboard is showing.  Even if the keyboard is showing, it disappears when the calendar gets focus.
    maxHeight: Infinity,
    // value: Date
    //		The value of this widget as a JavaScript Date object, with only year/month/day specified.
    //		If specified in markup, use the format specified in `stamp.fromISOString`.
    //		set("value", ...) accepts either a Date object or a string.
    value: new Date("") // value.toString()="NaN"
  });
});