/**
 * Pattern Library Mobile Navigation
 * @module mojo/widgets/pattern-library/MobileNavigation.js
 *
 * Widget used to show tablet and phone sized navigation for the pattern library.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/startsWith",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/widgets/_SlideCard",
// Templates
"dojo/text!./templates/MobileNavigation.html"], function (declare, dojoWindow, domClass, domConstruct, on, query, topic, startsWith, _WidgetBase, _TemplatedMixin, _SlideCard, TplStr) {
  var TABLET_MAX_WIDTH = 1024;

  // Instance of the singleton
  var instance = null;

  // Event system for window resize.
  var desktopMql = window.matchMedia("(min-width: " + TABLET_MAX_WIDTH + "px)");
  var onWindowResize = function (MediaQueryListEvent) {
    if (instance.isVisible() && MediaQueryListEvent.matches) {
      instance.hide();
    }
  };

  /**
   * We need a way to close
   */
  var onWrapClick = function () {
    if (instance.isVisible()) {
      instance.hide();
    }
  };
  var Menu = declare([_WidgetBase, _TemplatedMixin, _SlideCard], {
    templateString: TplStr,
    visibilityClass: "c-popoverMenu--active",
    activeLinkClass: "c-popoverMenu__item--active",
    pubSubTopic: "patterns/navigation/change",
    postCreate: function () {
      this._placeDomNode();
      this._attachEventListeners();
      this._attachActiveIndicators();
    },
    /**
     * Add the special class and notify other widgets of the change.
     */
    show: function () {
      domClass.add(this.domNode, this.visibilityClass);
      topic.publish(this.pubSubTopic, {
        visible: true
      });
      desktopMql.addListener(onWindowResize);
      document.getElementById("wrap").addEventListener("click", onWrapClick);
    },
    /**
     * Remove the special class and notify other widgets of the change.
     */
    hide: function () {
      domClass.remove(this.domNode, this.visibilityClass);
      topic.publish(this.pubSubTopic, {
        visible: false
      });
      desktopMql.removeListener(onWindowResize);
      document.getElementById("wrap").removeEventListener("click", onWrapClick);
    },
    /**
     * Visibility of this widget is measured by the existence of a CSS class.
     *
     * @returns {bool} can folks see this thing?
     */
    isVisible: function () {
      return domClass.contains(this.domNode, this.visibilityClass);
    },
    /**
     * Since this is a singleton and not instantiated in traditionally, we'll place the
     * node at the bottom of <body>.
     *
     * @private
     */
    _placeDomNode: function () {
      domConstruct.place(this.domNode, dojoWindow.body());
    },
    /**
     * Attach on() handlers for sliding card view.
     *
     * @private
     */
    _attachEventListeners: function () {
      on(this.patternsLink, "click", function (e) {
        e.preventDefault();
        this.slideCard(this.productCard);
      }.bind(this));
      on(this.backLink, "click", function (e) {
        e.preventDefault();
        this.closeCard(this.productCard);
      }.bind(this));
    },
    /**
     * Loop through all of the links and add a class if they are deemed "active"
     *
     * @private
     */
    _attachActiveIndicators: function () {
      var links = query("[data-active-link]", this.domNode);
      if (links) {
        links.forEach(function (link) {
          domClass.toggle(link, this.activeLinkClass, this._isLinkActive(link));
        }.bind(this));
      }
    },
    /**
     * Given a certain node, measure whether we should mark it as active. Handle situations
     * in which we want to indicate parents links.
     *
     * @param {Element} linkNode A node to test against
     * @returns {boolean} is it active?
     * @private
     */
    _isLinkActive: function (linkNode) {
      var currentPath = window.location.pathname;
      var href = linkNode.getAttribute("href");
      var usePartialMatch = linkNode.getAttribute("data-active-children") !== null;
      return currentPath === href || usePartialMatch && startsWith(currentPath, href);
    }
  });
  return {
    /**
     * Retrieve or create an instance of Menu. This allows us to avoid creating multiple on the page.
     *
     * @returns {*} Instance of Menu
     * @private
     */
    _getInstance: function () {
      if (!instance) {
        instance = new Menu();
        instance.startup();
      }
      return instance;
    },
    /**
     * Make it visible.
     */
    show: function () {
      this._getInstance().show();
    },
    /**
     * Hide the overlay.
     */
    hide: function () {
      this._getInstance().hide();
    },
    /**
     * Public method for seeing whether this component is visible.
     *
     * @returns {bool} can folks see this thing?
     */
    isVisible: function () {
      return this._getInstance().isVisible();
    }
  };
});