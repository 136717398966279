/**
 * @module mojo/segmentation/input/Creator
 */
define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Inputs
"./Currency", "./Date", "./DateRange", "./ExtraSelect", "./FilterSelect", "./InlineExtraSelectStore", "./Integer", "./Select", "./DistanceSelectExtra", "./LocationValidation", "./MultiSelect", "./MultiText", "./Product", "./NumberDays", "./Rating", "./Text", "./ZipCode",
// Helpers
"dojox/html/entities",
//flags
"mojo/context", "mojo/lib/flags"], function (array, declare, lang, CurrencyInput, DateInput, DateRangeInput, ExtraSelect, FilterSelect, InlineExtraSelectStore, IntegerInput, SegSelect, DistanceSelectExtra, LocationValidation, MultiSelect, MultiTextInput, ProductInput, NumberDaysInput, RatingInput, TextInput, ZipCodeInput, htmlEntities, context, flags) {
  function findOptionValues(configs, inputConfig) {
    var options = inputConfig.options;
    var optionValues;
    if (options instanceof Array) {
      optionValues = options;
    } else if (typeof options === "string") {
      optionValues = configs.optionValues[options];
    } else {
      throw new Error("invalid input config:" + JSON.stringify(inputConfig));
    }
    var optionClone = lang.clone(optionValues);
    optionClone = array.map(optionClone, function (item) {
      if (typeof item.label === "object" && context.flagIsOn(flags.SEGMENTATION_LEGACY_CAMPAIGNS)) {
        item.value = item.label.id;
        item.label = item.label.title;
      } else if (options === "static_segments" && context.flagIsOn(flags.SEGMENTATION_ESCAPED_TAGS_LEGACY_ADVANCED_BUILDER_FIX)) {
        item.label = item.label !== null ? item.label.toString() : "";
      } else {
        item.label = item.label !== null ? htmlEntities.encode(item.label.toString()) : "";
      }
      return item;
    });
    return optionClone;
  }
  var creators = {
    "date": function (configs, inputConfig) {
      return DateInput({
        "configs": configs,
        "inputConfig": inputConfig
      });
    },
    "dateRange": function (configs, inputConfig) {
      return DateRangeInput({
        "configs": configs,
        "inputConfig": inputConfig
      });
    },
    "multiText": function (configs, inputConfig) {
      return MultiTextInput({
        "configs": configs,
        "inputConfig": inputConfig
      });
    },
    "select": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new SegSelect({
        "class": "condition-value",
        "options": optionValues,
        "configs": configs,
        "inputConfig": inputConfig
      });
    },
    "distance_select": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new DistanceSelectExtra({
        //"class": "segment-block",
        "options": optionValues,
        "configs": configs,
        "inputConfig": inputConfig,
        "ops": optionValues
      });
    },
    "zip_code": function (configs, inputConfig) {
      return ZipCodeInput({
        "class": "segment-block",
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "location_validation": function (configs, inputConfig) {
      return LocationValidation({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "multiSelect": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new MultiSelect({
        "options": optionValues,
        "nounLabel": inputConfig.noun_label,
        "configs": configs,
        "inputConfig": inputConfig
      });
    },
    "extraSelect": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new ExtraSelect({
        "configs": configs,
        "inputConfig": inputConfig,
        "ops": optionValues
      });
    },
    "filterSelect": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new FilterSelect({
        "configs": configs,
        "inputConfig": inputConfig,
        "options": optionValues
      });
    },
    "rating": function (configs, inputConfig) {
      return new RatingInput({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "text": function (configs, inputConfig) {
      return TextInput({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "currency": function (configs, inputConfig) {
      return CurrencyInput({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "numberDays": function (configs, inputConfig) {
      return NumberDaysInput({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "integer": function (configs, inputConfig) {
      return IntegerInput({
        configs: configs,
        inputConfig: inputConfig
      });
    },
    "inlineExtraSelectStore": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new InlineExtraSelectStore({
        "configs": configs,
        "inputConfig": inputConfig,
        "ops": optionValues
      });
    },
    "product": function (configs, inputConfig) {
      var optionValues = findOptionValues(configs, inputConfig);
      return new ProductInput({
        "configs": configs,
        "inputConfig": inputConfig,
        "options": optionValues
      });
    }
  };
  return {
    /**
     * @param {Object} configs - general config for the entire segmentation UI
     * @param {Object} inputConfig - the specific config for a particular input
     *
     * @returns {*} A widget for that particular `inputConfig`
     */
    create: function (configs, inputConfig) {
      var type = inputConfig.type;
      if (!creators[type]) {
        throw new Error("Unable to render input of type:" + type);
      }
      var input = creators[type](configs, inputConfig);
      input.segmentInputType = type;
      if (input.startup) {
        input.startup();
      }
      return input;
    }
  };
});