/**
 * Icons List
 * @module mojo/widgets/pattern-library/Icons.js
 *
 * Widget used to render the Icons
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/values", "dijit/form/Select", "dijit/form/TextBox", "mojo/icons", "mojo/widgets/pattern-library/IconSwatch",
// Mixins
"mojo/widgets/Renderable", "dojo/text!./templates/IconsList.html"], function (declare, domConstruct, values, Select, TextBox, freddicons, IconSwatch, Renderable, tplStr) {
  var sortByOptions = [{
    label: "Character Code",
    value: "code"
  }, {
    label: "CSS Class",
    value: "class"
  }];

  /**
   * Base widget responsible for loading all other widgets.
   */
  return declare([Renderable], {
    templateString: tplStr,
    /**
     * Dojo's lifecycle method.
     */
    postCreate: function () {
      this._initFilterByOptions();
      this._initSortByOptions();
    },
    /**
     * Initializes the sort options Select widget.
     *
     * @private
     */
    _initSortByOptions: function () {
      this.sortByOptionSelect = new Select({
        "class": "!margin--lv0",
        options: sortByOptions,
        onChange: this._handleSortBySelectChange.bind(this)
      });
      this.sortByOptionSelect.placeAt(this.sortByContainer);
      this.sortByOptionSelect.startup();
      this._handleSortBySelectChange();
    },
    /**
     * Initializes the text field for filtering icons.
     *
     * @private
     */
    _initFilterByOptions: function () {
      this.filterByText = new TextBox({
        onKeyUp: this._handleFilterByTextChange.bind(this)
      });
      this.filterByText.placeAt(this.filterByContainer);
      this.filterByText.startup();
      this._initSortLabel();
      this._initFilterLabel();
    },
    /**
     * Initializes the label for the sort options select widget.
     * @private
     */
    _initSortLabel: function () {
      this.sortLabel.innerText = "Sort by: ";
    },
    /**
     * Initializes the label for the sort options select widget.
     *
     * @private
     */
    _initFilterLabel: function () {
      this.filterLabel.innerText = "Filter by class name: ";
    },
    /**
     * Event handler which is trigger when the sort options select value is changed.
     *
     * @private
     */
    _handleSortBySelectChange: function () {
      var iconsList = values(freddicons);
      iconsList = this._filterAndSort(iconsList);
      this._renderIconsList(iconsList);
    },
    /**
     * Event handler which is trigger when the filter by text is changed.
     *
     * @private
     */
    _handleFilterByTextChange: function () {
      var iconsList = values(freddicons);
      iconsList = this._filterAndSort(iconsList);
      this._renderIconsList(iconsList);
    },
    /**
     * Filters and sorts the list of icons provided to it.
     * @param {Object[]} icons Icon List
     *
     * @returns {Object[]} icons Filtered and Sorted Icon List
     *
     * @private
     */
    _filterAndSort: function (icons) {
      var filteredList = icons;
      var keyword = this.filterByText.get("value");
      if (keyword) {
        filteredList = filteredList.filter(function (icon) {
          var re = new RegExp(keyword, "gi");
          return icon.class.match(re);
        });
      }
      var sortBy = this.sortByOptionSelect.get("value");
      filteredList.sort(function (a, b) {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      });
      return filteredList;
    },
    /**
     * Renders the icons list.
     * @param {Object[]} iconsList List of icons.
     *
     * @private
     */
    _renderIconsList: function (iconsList) {
      this._resetResultItems();
      iconsList.forEach(function (icon) {
        var iconSwatch = new IconSwatch({
          code: icon.code,
          classcode: icon.class,
          className: "unit size1of4"
        });
        iconSwatch.placeAt(this.resultItems);
        iconSwatch.startup();
      }.bind(this));
    },
    /**
     * Empties the Result Items container.
     *
     * @private
     */
    _resetResultItems: function () {
      domConstruct.empty(this.resultItems);
    }
  });
});