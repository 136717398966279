/**
 * Button Group Demo
 * @module mojo/widgets/pattern-library/ButtonGroupDemo.js
 *
 * Widget used to demonstrate how to implement the Button Group pattern.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/ButtonGroup",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/ButtonItemBordered.html", "dojo/text!./templates/ButtonItemUnderlined.html"], function (declare, ButtonGroup, Renderable, borderedBtnTplStr, underlinedBtnTplStr) {
  return declare([Renderable], {
    templateString: "<div></div>",
    type: "",
    // either 'bordered' or 'underlined'

    options: ["Desktop", "Tablet", "Phone"],
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      this._initButtonGroup();
    },
    /**
     * Programmatically instantiate the ButtonGroup widget and start tracking changes.
     *
     * @private
     */
    _initButtonGroup: function () {
      this.toggler = new ButtonGroup({
        options: this._getButtons(),
        itemTpl: this._getItemTpl(),
        activeClass: this._getActiveClass()
      });

      // Place button group
      this.toggler.placeAt(this.domNode);
      this.toggler.startup();

      // Attach change handler.
      this.toggler.on("change", this._handleTogglerChange.bind(this));

      // Set the first one as selected.
      this.toggler.setSelected(this.options[0].toLowerCase());
    },
    /**
     * Do some action on change. Careful, will likely fire on first load.
     *
     * @param {String} newValue - The changed value.
     * @private
     */
    _handleTogglerChange: function (newValue) {
      // Do some action! -- Left in for demonstration purposes.
    },
    /**
     * Generate and return buttons to be rendered by the widget.
     *
     * @returns {Array} formatted button collection.
     * @private
     */
    _getButtons: function () {
      var collection = [];
      this.options.forEach(function (option) {
        collection.push({
          label: option,
          value: option.toLowerCase()
        });
      });
      return collection;
    },
    /**
     * Grab the appropriate button item template.
     *
     * @returns {*} Template String
     * @private
     */
    _getItemTpl: function () {
      switch (this.type) {
        case "bordered":
          return borderedBtnTplStr;
        case "underlined":
          return underlinedBtnTplStr;
        default:
          throw new Error("Invalid type declared");
      }
    },
    /**
     * Grab the needed active classname based on passed in type.
     *
     * @returns {string} Classname
     * @private
     */
    _getActiveClass: function () {
      switch (this.type) {
        case "bordered":
          return "v-buttonGroupBordered--active";
        case "underlined":
          return "v-buttonGroupUnderlined--active";
        default:
          throw new Error("Invalid type declared");
      }
    }
  });
});