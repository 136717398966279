/**
 * Facebook Mobile Slat Interface
 * @module /mojo/widgets/ads/mobile-checklist/FacebookSlat.js
 * @see /mojo/widgets/ads/mobile-checklist/_SlatMixin.js
 *
 * This module is intended as a "loader" of sorts for the different slats for this mobile interface
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Slats
"mojo/widgets/ads/facebook/EditConnectSlat", "mojo/widgets/ads/facebook/EditAudienceSlat", "mojo/widgets/ads/facebook/EditBudgetSlat", "mojo/widgets/ads/facebook/MobileContentSlat", "./TermsOfServiceSlat", "./CVVSlat", "./BillingSlat",
// Mixins
"mojo/widgets/ads/mobile-checklist/_SlatMixin"], function (declare, connectSlat, audienceSlat, budgetSlat, contentSlat, tosSlat, cvvSlat, billingSlat, _SlatMixin) {
  return declare([_SlatMixin], {
    campaignType: "facebook",
    slatConfig: {
      connect: {
        widget: connectSlat,
        checklistItem: true
      },
      audience: {
        widget: audienceSlat,
        checklistItem: true
      },
      budget: {
        widget: budgetSlat,
        checklistItem: true
      },
      content: {
        widget: contentSlat
      },
      tos: {
        widget: tosSlat
      },
      cvv: {
        widget: cvvSlat,
        userLevel: "admin"
      },
      billing: {
        widget: billingSlat,
        userLevel: "admin"
      }
    },
    /**
     * Get the list of all valid slats. Required to be configured by _SlatMixin.
     * If a requested slat isn't in this list, an error is thrown.
     * @return {string[]} all of the disabled slat names.
     */
    __getValidSlatNames: function () {
      return ["connect", "audience", "budget", "content", "tos", "cvv", "billing"];
    },
    /**
     * Get the list of all of the currently disabled slats. Required to be configured by _SlatMixin
     * @return {string[]} all of the disabled slat names.
     *
     * All are currently enabled :)
     *
     */
    __getDisabledSlatNames: function () {
      return [];
    }
  });
});