/**
 * Mixin for feature authorization
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/user"], function (declare, Deferred, xhr, mUrl, user) {
  return declare([], {
    /**
     * Does the user have access to the given feature?
     *
     * @param {string} feature_name the feature
     *
     * @returns {boolean} true for allowed, false for rejected
     */
    userHasFeature: function (feature_name) {
      if (user.policy) {
        return Object.keys(user.policy).indexOf(feature_name) > -1;
      }
      return false;
    },
    /**
     * What access constraint violations prohibit the user from performing the given operation?
     *
     * @param {string} feature_name string the feature
     * @param {string} action_name string the action
     * @param {object} [context=null] details required to make the auth decision
     *
     * @returns {Promise} that will resolve with a list of failed constraints
     */
    getPolicyViolations: function (feature_name, action_name, context) {
      var deferred = new Deferred();
      if (!this.userHasFeature(feature_name)) {
        deferred.resolve({
          "no_access": {
            "message": "User does not have access to feature " + feature_name
          }
        });
      } else if (user.policy[feature_name] === "*") {
        deferred.resolve({});
      } else {
        var url = mUrl.toUrl("/account/authz");
        var payload = {
          "feature": feature_name,
          "action": action_name,
          "context": context ? JSON.stringify(context) : "[]"
        };
        return xhr.post(url, {
          data: payload,
          handleAs: "json"
        }).then(function (res) {
          return res["permitted"] ? {} : res["violations"];
        });
      }
      return deferred.promise;
    },
    /**
     * May the user perform the given operation?
     *
     * @param {string} feature_name string the feature
     * @param {string} action_name string the action
     * @param {object} [context=null] details required to make the auth decision
     *
     * @returns {Promise} that will resolve with a boolean
     */
    userMay: function (feature_name, action_name, context) {
      var violations = this.getPolicyViolations(feature_name, action_name, context);
      return violations.then(function (res) {
        return Object.keys(res).length === 0;
      });
    }
  });
});