/**
 * Carousel
 * @module mojo/widgets/ads/facebook/edit-content/Carousel.js
 * @see mojo/widgets/ads/facebook/EditContentSlat.js
 *
 * This widget is intended to serve as the interface for toggling between different carousel items.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/context", "mojo/lib/flags", "mojo/widgets/ViewSwitcher",
// Feature widgets
"mojo/widgets/ads/facebook/edit-content/Api", "mojo/widgets/ads/facebook/edit-content/CarouselItem", "dijit/Dialog", "dijit/TooltipDialog", "dijit/popup",
// Mixins
"mojo/widgets/Renderable",
// Templates
"dojo/text!./templates/Carousel.html", "dojo/text!./templates/ButtonItem.html", "dojo/text!./templates/DeleteCarouselItemDialog.html"], function (declare, lang, topic, domClass, domAttr, domConstruct, on, utils, context, flags, ViewSwitcher, ContentApi, CarouselItem, Dialog, TooltipDialog, popup, Renderable, tplStr, tplBtnItem, dialogTplStr) {
  /**
   * Widget that handles the loading of carousel items and passes them to the ViewSwitcher widget.
   * See SimpleViewAdapter.js. This works the same way but is more welcoming to widgets.
   */
  var CarouselItemViewAdapter = declare([], {
    /**
     * At creation of this object we need to make the data reachable by other methods. Adding them to "this"
     * will do.
     *
     * @param {obj} props - Data we'd like a creation of this object
     */
    constructor: function (props) {
      this.widgetContainer = props.widgetContainer;
    },
    /**
     * This method is called by ViewSwitcher whenever a view is requested. Call out to the CarouselItems
     * widget to get the requested dom node.
     *
     * Note: views are "cached" via ViewSwitcher so we don't have to worry about dupes.
     *
     * @param {number} position - Which item in the view?
     * @returns {div} - with the CarouselItem created inside. ViewSwitcher requires/assumes a domNode.
     */
    createView: function (position) {
      var widgetNode = domConstruct.create("div");
      var widget = this.widgetContainer.createWidget(position);
      widget.placeAt(widgetNode);
      widget.startup();
      return widgetNode;
    },
    /**
     * In case we use the next or previous options of the View Switcher
     *
     * @returns {number} How many carousel items there are.
     */
    getCount: function () {
      return this.widgetContainer.getCount();
    },
    destroy: function () {
      this.widgetContainer.destroy();
    }
  });
  var WidgetContainer = declare([], {
    // Widget-level array to store all of the widgets.
    widgets: [],
    /**
     * At creation of this object we need to make the data reachable by other methods. Adding them to "this"
     * will do.
     *
     * @param {obj} props - Data we'd like a creation of this object
     */
    constructor: function (props) {
      this.adId = props.adId;
      this.items = props.items;
      this.carousel = props.carousel;
    },
    /**
     * This method is called by ViewSwitcher whenever a view is requested. Go ahead and create a new instance
     * of CarouselItem and return that node.
     *
     * @param {number} position - Which item in the view?
     * @returns {div} - with the CarouselItem created inside. ViewSwitcher requires/assumes a domNode.
     */
    createWidget: function (position) {
      this.widgets[position] = new CarouselItem({
        adId: this.adId,
        index: position,
        data: this.items[position],
        carousel: this.carousel
      });
      return this.widgets[position];
    },
    /**
     * In case we use the next or previous options of the View Switcher
     *
     * @returns {number} How many carousel items there are.
     */
    getCount: function () {
      return this.items.length;
    },
    /**
     * Cleanup method. Make sure we're not sweeping things under the rug.
     */
    destroy: function () {
      this.widgets.forEach(function (widget) {
        widget.destroyRecursive(false);
      });
    },
    /**
     * Get a reference to the widget at a current position. This is useful for interfacing with ChecklistItem
     * methods directly
     *
     * @param {Number} position - What item in the array.
     * @returns {widget} Instance of CarouselItem
      */
    getWidgetByPosition: function (position) {
      return this.widgets[position];
    }
  });

  /**
   * Button Item represents the visuals of a Carousel Item. These buttons are how you swap and delete carousel views.
   * All of the actual business and event logic sits at the parent level.
   */
  var ButtonItem = declare([Renderable], {
    // Single button with some magic handling styling and events
    templateString: tplBtnItem,
    // Item identifier
    index: null,
    // What to use as label background image
    imageUrl: "",
    // Reference to the parent widget for simpler state management.
    carousel: null,
    /**
     * Tap into the methods sitting at the carousel level. This'll tell the carousel to validate, swap views, and
     * regenerate the buttons.
     *
     * @param {obj} e - https://developer.mozilla.org/en-US/docs/Web/Events/click
     * @private
     */
    _handleLabelPress: function (e) {
      e.preventDefault();
      this.carousel.onItemButtonPress(this.index);
    },
    /**
     * Generate a dialog to have the user confirm their decision to delete the carousel item
     *
     * @param {obj} e - https://developer.mozilla.org/en-US/docs/Web/Events/click
     * @private
     */
    _handleDeletePress: function (e) {
      e.preventDefault();
      var contentMsg = "This will delete the entire card. If you'd like to change the image only, hover over the image block and select Edit or Replace.";

      // Track when a user clicks the delete "x"
      window.ga("send", "event", "facebook_ad", "click", "delete_card");
      this.deleteCarouselItemDialog = new Dialog({
        title: "Are you sure?",
        content: "<div class='dijitDialogPaneContentArea dijitDialogPaneContentArea--mobile'><p>" + contentMsg + "</p></div>",
        actionBarTemplate: dialogTplStr,
        draggable: false,
        closable: true,
        onConfirmDeletion: lang.hitch(this, "_handleDeleteCarouselItemConfirm"),
        onCancel: lang.hitch(this, function () {
          window.ga("send", "event", "facebook_ad", "click", "delete_modal_cancel");
        })
      });
      this.deleteCarouselItemDialog.startup();
      this.deleteCarouselItemDialog.show();
    },
    /**
     * Destroy dialog and tap into the methods sitting at the carousel level. This'll tell the carousel to
     * delete the item, swap views, and regenerate the buttons.
     *
     * @private
     */
    _handleDeleteCarouselItemConfirm: function () {
      // Destroy the dialog
      this.deleteCarouselItemDialog.destroy();
      window.ga("send", "event", "facebook_ad", "click", "delete_modal_confirm");
      this.carousel.onDeleteItemButtonPress(this.index);
    }
  });

  /**
   * Base widget responsible for loading all other widgets.
   */
  return declare([Renderable], {
    templateString: tplStr,
    // Ad Unique Identifier. Used for publishing to backend.
    adId: null,
    // Data to fill the items
    items: [],
    // Reference to ViewSwitcher widget
    switcher: null,
    // Needs to be flexible
    maxItems: 5,
    autodesignerLandscapeAdUsed: false,
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      topic.subscribe("content/carousel/landscapeAdUsed", this._handleLandscapeAdUsed.bind(this));
      if (this.maxItems > 1) {
        this._initSwitcher(this.incompleteCarouselItemIndex);
        this._initButtons();
        on(this.addButtonNodeContainer, "click", this._showDisabledTooltipMessage.bind(this));
        return;
      }
      this._initSingleItem();
    },
    /**
     * Run this at widget creation time, making sure that we have all of the data we need.
     */
    postMixInProperties: function () {
      this._validateProps();
    },
    _handleLandscapeAdUsed: function (content) {
      this.autodesignerLandscapeAdUsed = content.landscapeAdUsed;
    },
    /**
     * If we're only allowing a single carousel item, skip all of the button and view switcher nonsense.
     *
     * @private
     */
    _initSingleItem: function () {
      this.item = new CarouselItem({
        adId: this.adId,
        index: 0,
        data: this.items[0],
        carousel: this,
        lockUrl: true
      });
      this.item.placeAt(this.switcherContainer);
      this.item.startup();
    },
    /**
     * We rely on the ViewSwitcher widget to handle animations and tracking of the different carousel items.
     * This method creates that and loads our customized View Adapter
     *
     * @param {Number} startIndex - What index to start the ViewSwitcher on instantiation
     * @private
     */
    _initSwitcher: function (startIndex) {
      // Make sure we're actually requesting a number for the starting index
      if (typeof startIndex !== "number") {
        throw new Error(startIndex + " must be a number");
      }
      this.widgets = new WidgetContainer({
        adId: this.adId,
        items: this.items,
        carousel: this
      });
      this.switcher = new ViewSwitcher({
        transition: "slide",
        startIndex: startIndex,
        skipCaching: true,
        adapter: CarouselItemViewAdapter({
          widgetContainer: this.widgets
        })
      });
      this.switcher.placeAt(this.switcherContainer);
      this.switcher.startup();
    },
    /**
     * Create all of the buttons associated with the number of carousel items
     *
     * @private
     */
    _initButtons: function () {
      this.buttons = [];
      this.items.forEach(this._createButton.bind(this));

      // Only show the add button when we can actually add some.
      domClass.toggle(this.addButtonNode, "hide", !this._canAddItem());
    },
    /**
     * Create a button for each carousel item.
     *
     * @param {obj} buttonItem - Content for a specific button
     * @param {Number} i - index. Useful for passing back to the ViewSwitcher.
     * @private
     */
    _createButton: function (buttonItem, i) {
      this.buttons[i] = new ButtonItem({
        index: i,
        selected: i === this._getCurrentCarouselItemIndex(),
        imageUrl: buttonItem.imageUrl ? buttonItem.imageUrl : "",
        carousel: this,
        // Used for calling methods back in the parent.
        "class": "v-adThumbnailItem--row"
      });
      this.buttons[i].placeAt(this.buttonsContainer);
      this.buttons[i].startup();
    },
    /**
     * Handle the creation of a new carousel item
     *
     * @private
     */
    _onAddItemButtonPress: function () {
      if (!this._canAddItem() || !this.canAddItems) {
        return;
      }
      window.ga("send", "event", "facebook_ad", "click", "add_new_card");
      this._validateAndSwitchItem( /* nextItemIndex */this.items.length, /* responseCallback */this._onAddItemPostResponse);
    },
    /**
     * Remove a carousel item.
     *
     * @param {number} itemIndex - Carousel item that was pressed
     */
    onDeleteItemButtonPress: function (itemIndex) {
      if (this.items.length === 1) {
        this._resetCarousel();
        return;
      }

      // Widget-level var assignment for easier scoping.
      this.deletedItemIndex = itemIndex;

      // We want slightly different behavior if we are deleting the 1st item
      var isFirstItem = itemIndex === 0;

      // Which Item should we switch to?
      var nextItemIndex = isFirstItem ? itemIndex + 1 : itemIndex - 1;

      // Switch to the next carousel item and then tell the backend to delete the requested item.
      this.switcher.setCurrentView(nextItemIndex, false,
      // skipTransition
      isFirstItem // isForced - Force skip if we are on 1st item.
      ).then(lang.hitch(this, function () {
        var formData = {
          id: this.adId,
          index: itemIndex
        };
        ContentApi.deleteCarouselItem(formData).then(lang.hitch(this, "_onDeleteItemPostResponse"));
      }));
    },
    /**
     * Validate and submit the current carousel item before switching
     *
     * @param {number} itemIndex - Carousel item that was pressed
     */
    onItemButtonPress: function (itemIndex) {
      // Don't do anything if we're already on the current view.
      if (itemIndex === this._getCurrentCarouselItemIndex()) {
        return;
      }
      this._validateAndSwitchItem( /* nextItemIndex */itemIndex, /* responseCallback */this._onUpdateItemPostResponse);
    },
    /**
     * Very similar to _onUpdateItemPostResponse but add a notification to this.CarouselItems.
     *
     * @param {obj} response - response containing data about the response and any messaging
     * @private
     */
    _onAddItemPostResponse: function (response) {
      switch (response.status) {
        case "success":
          this.items.push({});
          this._switchCarouselItem();
          break;
        case "error":
          this._setFormErrors(response.errors);
          break;
        default:
          throw new Error("Unknown Carousel save state " + response.status);
      }
    },
    /**
     * Listen for what the response of the POST was and respond to success or errors.
     *
     * @param {obj} response - response containing data about the response and any messaging
     * @private
     */
    _onUpdateItemPostResponse: function (response) {
      switch (response.status) {
        case "success":
          this._switchCarouselItem();
          break;
        case "error":
          this._setFormErrors(response.errors);
          break;
        default:
          throw new Error("Unknown Carousel save state " + response.status);
      }
    },
    /**
     * Listen for what the backend says about a deleted carousel item.
     *
     * @param {obj} response - response containing data about the response and any messaging
     * @private
     */
    _onDeleteItemPostResponse: function (response) {
      switch (response.status) {
        case "success":
          // Remove deleted item from array
          this.items.splice(this.deletedItemIndex, 1);
          // Clean up after ourselves
          this.deletedItemIndex = null;
          // Regenerate the buttons
          this._resetButtons();
          // Recreate ViewSwitcher
          this._resetViewSwitcher();
          break;
        default:
          throw new Error("Unknown Carousel delete state " + response.status);
      }
    },
    /**
     * Validate the contents of the current carousel item and publish changes to the
     * backend. If there are any errors, display them.
     *
     * @param {number} nextItemIndex - Carousel item to switch to next.
     * @param {function({})} responseCallback to the preferred response handler.
     * @private
     */
    _validateAndSwitchItem: function (nextItemIndex, responseCallback) {
      this._clearFormErrors();

      // Don't allow switch until simple validation has passed
      if (!this.passesSimpleValidation()) {
        return;
      }

      // Keep track of what item to switch to
      this.nextItemIndex = nextItemIndex;

      // TODO: Only post out to the backend if content has changed
      ContentApi.updateCarouselItem(this.getFormData()).then(lang.hitch(this, responseCallback));
    },
    /**
     * Call out to the switcher and actually swap views. Clear out nextItem for safety.
     *
     * @private
     */
    _switchCarouselItem: function () {
      // Update this.items array with the "saved" data. Saves us an HTTP request!
      this.updateItemData( /*itemIndex*/this._getCurrentCarouselItemIndex(), /*resetButtons*/false);

      // Make sure we're actually requesting a number to set the view to.
      if (typeof this.nextItemIndex !== "number") {
        throw new Error("Invalid item carousel index");
      }

      // Actually change the view and do a couple of simple actions.
      this.switcher.setCurrentView(this.nextItemIndex).then(function () {
        this.nextItemIndex = null;
        this._resetButtons();
      }.bind(this));
    },
    /**
     * If a user is deleting the last Carousel Item, let's go ahead and reset the whole thing. Call out to a method
     * within EditContentSlat when done.
     *
     * TODO: Show Dialog to confirm.
     *
     * @private
     */
    _resetCarousel: function () {
      ContentApi.resetCarousel(this.getFormData()).then(lang.hitch(this, "onSlatReset"));
    },
    /**
     * On change of carousel items or view we want to update the buttons.
     *
     * @private
     */
    _resetButtons: function () {
      // Clean up old widgets
      this.buttons.forEach(function (widget) {
        widget.destroyRecursive(false);
      });
      // Start 'em up again
      this._initButtons();
    },
    _resetViewSwitcher: function () {
      var currentItemIndex = this._getCurrentCarouselItemIndex();
      this.widgets.destroy();
      this.switcher.destroy();
      this._initSwitcher(currentItemIndex);
    },
    /**
     * Can items be added?
     *
     * @returns {boolean} - Show add button?
     */
    _canAddItem: function () {
      // When audodesigner single landscape ad size is used, adding button is disabled to prevent unwanted cropping caused by transition to carousel mode
      if (this.autodesignerLandscapeAdUsed) {
        return false;
      }
      return this.maxItems - this.items.length > 0;
    },
    /**
     * Actual creation and management of CarouselItems are handled by the CarouselItems widget. Look up
     * the widget for the currently visible Item.
     *
     * @returns {*} - An instance of CarouselItem.js
     *
     * @private
     */
    _getCurrentCarouselItem: function () {
      var currentIndex = this._getCurrentCarouselItemIndex();
      return this.maxItems > 1 ? this.widgets.getWidgetByPosition(currentIndex) : this.item;
    },
    /**
     * There are a few places in which we need to know the current carousel position.
     *
     * @returns {number} - The index of the current carousel item.
     *
     * @private
     */
    _getCurrentCarouselItemIndex: function () {
      return this.maxItems > 1 ? this.switcher.getCurrentPosition() : 0;
    },
    /**
     * Apply form errors to the currently displayed CarouselItem.
     *
     * @param {Object} errorObj - Form names and their respective error messaging
     *
     * @private
     */
    _setFormErrors: function (errorObj) {
      var currentItemWidget = this._getCurrentCarouselItem();
      utils.showFormErrors(currentItemWidget.domNode, errorObj);
    },
    /**
     * Clear any visible form errors for the currently displayed CarouselItem.
     *
     * @private
     */
    _clearFormErrors: function () {
      var currentItemWidget = this._getCurrentCarouselItem();
      utils.removeAllErrors(currentItemWidget.domNode);
    },
    /**
     * Public method used as a notification mechanism that the carousel should update it's data about a certain
     * item. We mutate an item within the this.items array and that allows us to keep it as the source of truth.
     *
     * This method is called from within this widget and CarouselItem.js
     *
     * @param {number} itemIndex - Index of the carousel item.
     * @param {boolean} resetButtons - Pretty self explanatory
     */
    updateItemData: function (itemIndex, resetButtons) {
      // Grab the updated info
      var newData = this.getFormData();

      // Overwrite the current item data with what would be passed to the backend.
      // This gets passed back to the new CarouselItem if it is reinstantiated.
      this.items[itemIndex] = {
        imageUrl: newData.image_url,
        linkUrl: newData.link_url,
        name: newData.title,
        callToAction: newData.cta,
        description: newData.description
      };

      // Update the buttons if the image changed.
      if (resetButtons && this.maxItems > 1) {
        this._resetButtons();
      }
    },
    /**
     * Public method used as a notification mechanism that the carousel should disable or re-enable
     * the add button for the carousel based on the users selected image from the content studio
     *
     * This method is called from within this widget and CarouselItem.js
     *
     * @param {boolean} enableAddButton - Determines if user can or can't add a carousel item
     */
    toggleAddButtonState: function (enableAddButton) {
      this.set("canAddItems", enableAddButton);
    },
    /**
     * When a user tries to add a new carousel item when the add button is disabled show them
     * a tooltip to explain why and give them context.
     *
     * @private
     */
    _showDisabledTooltipMessage: function () {
      // Only show this if they can't add items
      if (!this.canAddItems) {
        var disabledTooltip = new TooltipDialog({
          content: "Only single-image ads can support GIF files. To add more images please remove the GIF.",
          onBlur: function () {
            popup.hide(disabledTooltip);
            disabledTooltip.destroy();
          }
        });
        popup.open({
          popup: disabledTooltip,
          around: this.addButtonNodeContainer
        });

        // Place the focus on the tooltip dialog in order to close onBlur
        disabledTooltip.focus();
      }
    },
    /**
     * If the ad is a gif add we want to disable the add+ button so users won't be able
     * to create a carousel ad
     *
     * @param {boolean} canAddCarouselItem - Can user add a carousel item?
     */
    _setCanAddItemsAttr: function (canAddCarouselItem) {
      this.canAddItems = canAddCarouselItem;

      // Check the max items to see if the add button is there. Single Item ads have no add button
      if (this.maxItems !== 1) {
        if (!canAddCarouselItem) {
          domAttr.set(this.addButtonNode, "disabled", "disabled");
        } else {
          domAttr.remove(this.addButtonNode, "disabled");
        }
      }
    },
    /**
     * Public method intended to return form data for submission to the back end. We don't want other widgets
     * to talk directly to a Carousel Item since the Carousel maintains state. As such, get the current
     * CarouselItem and call a public method within that.
     *
     * @returns {*|obj|{link_url: *, title: *, cta: *, description: *}} - Contents of the carousel item plus index.
     */
    getFormData: function () {
      var currentItemWidget = this._getCurrentCarouselItem();
      // The backend expects the message body even if we're only updating a single carousel item.
      var messageInputValue = this.messageTextArea.getValue();
      return lang.mixin(currentItemWidget.getFormData(), {
        id: this.adId,
        index: this._getCurrentCarouselItemIndex(),
        message: messageInputValue
      });
    },
    /**
     * Method to return a boolean to see if the ad has valid content saved
     * We know at widget instantiation, but need to check after user has entered information
     * to see if the item collection has changed. Only really care about the 1st item.
     *
     * @returns {boolean} - Is this an empty ad?
     */
    hasValidSavedContent: function () {
      // Get the content for the first item in the carousel
      var firstItemContent = this.items[0];

      // Get the values of the linkUrl and name since those are required
      // for a valid carousel item
      var linkContent = firstItemContent.linkUrl;
      var titleContent = firstItemContent.name;

      // If those values are empty or null we can assume that the user doesn't have valid saved content.
      // Image is not included since the image value is saved on upload
      if (!linkContent || linkContent === "" || !titleContent || titleContent === "") {
        return false;
      }
      return true;
    },
    /**
     * Do a simple test for empty fields and provide feedback. Save an HTTP request!
     *
     * @returns {boolean} hasFeedback - Should we post to the server yet?
     *
     * @private
     */
    passesSimpleValidation: function () {
      var carouselFormData = this.getFormData();
      var errors = {};
      var hasFeedback = false;
      if (carouselFormData.image_url === "") {
        errors.image = "Please add an image.";
        hasFeedback = true;
      }
      if (carouselFormData.link_url === "") {
        errors.link_url = "Please add a link.";
        hasFeedback = true;
      }
      if (carouselFormData.title === "") {
        errors.title = "Please add a title.";
        hasFeedback = true;
      }
      if (carouselFormData.cta === "") {
        errors.cta = "Please select a button.";
        hasFeedback = true;
      }
      if (hasFeedback) {
        this._setFormErrors(errors);
      }
      return !hasFeedback;
    },
    /**
     * We make some assumptions about passed in properties. Make sure we are shooting errors if those assumptions
     * aren't met.
     *
     * @private
     */
    _validateProps: function () {
      if (this.adId === null) {
        throw new Error("Ad Unique ID undeclared. It is required.");
      }
      if (typeof this.maxItems !== "number" || this.maxItems % 1 !== 0) {
        throw new Error("this.maxItems invalid. Carousel was expecting a whole number");
      }
      if (this.maxItems < 1) {
        throw new Error("this.maxItems is expected to be a positive integer.");
      }
    }
  });
});