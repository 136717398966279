define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, domAttr, domConstruct, query, _Block) {
  return declare([_Block], {
    type: "rssItems",
    _name: "RSS Items",
    _kbLink: "https://mailchimp.com/help/use-rss-items-content-blocks/",
    _campaignType: 'rss',
    constructor: function () {
      this.customContent = null;
      this.selectedPreset = 'excerpts';
      this.styles = [{
        'name': 'Type',
        'selector': '.mcnTextContent, .mcnTextContentLeft, .mcnTextContentRight',
        'props': ['color', 'font-family', 'font-size', 'font-weight', 'line-height', 'text-align']
      }];
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      if (this.selectedPreset == 'custom') {
        var contentWrap = domConstruct.create('div');
        var columnDom = domConstruct.toDom(this.customContent);
        domConstruct.place(columnDom, contentWrap);
        var link = query('a', contentWrap)[index];
        if (link && domAttr.get(link, 'href') == oldUrl) {
          domAttr.set(link, 'href', newUrl);
          this.customContent = contentWrap.innerHTML;
          domConstruct.destroy(contentWrap);
          return this.save();
        }
        domConstruct.destroy(contentWrap);
      }
      return this.inherited(arguments);
    },
    canLinkUpdate: function () {
      return this.selectedPreset == 'custom';
    }
  });
});