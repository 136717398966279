define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, on, query, lang) {
  return declare([base], {
    requireUser: null,
    onReady: function () {
      this.attachEventHandlers();
    },
    attachEventHandlers: function () {
      on(query("[name=permission]"), "change", lang.hitch(this, function () {
        var checked = query("[name=permission]:checked");
        if (this.requireUser && this.requireUser.includes(checked[0].value)) {
          this.$el.uidBlock.style.display = "inline";
          this.$el.uidField.required = true;
        } else {
          this.$el.uidBlock.style.display = "none";
          this.$el.uidField.required = false;
        }
      }));
    },
    handleSubmit: function (e) {
      var checked = query("[name=permission]:checked");
      if (checked.length === 0) {
        this.$el.errorMessage.innerHTML = "You must select a permission.";
        e.stopPropagation();
        e.preventDefault();
      }
      var checkedCustomerCheckbox = query("[name=customer_checkbox]:checked");
      if (checkedCustomerCheckbox.length === 0) {
        this.$el.errorMessageCustomerCheckbox.innerHTML = "You must select an option.";
        e.stopPropagation();
        e.preventDefault();
      }
    },
    handleOnChange: function () {
      this.$el.errorMessage.innerHTML = "";
    },
    handleOnChangeCustomerCheckbox: function () {
      this.$el.errorMessageCustomerCheckbox.innerHTML = "";
    }
  });
});