/**
 * Module to communicate with React-level App Fabric performance object
 */
define([], function () {
  var performance = {
    endMarkedMeasure: function () {
      var endMarkedMeasureEvent = new Event("endMarkedMeasure");
      window.dispatchEvent(endMarkedMeasureEvent);
    }
  };
  return performance;
});