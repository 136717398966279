define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/throttle", "vendor/lodash-amd/isEqual"], function (dojo, declare, base, xhr, query, mUrl, tabContainerUtil, dom, throttle, isEqual) {
  // Parses a set of results returned by the Smarty Streets client into HTML for display back in MCAdmin
  var parseResults = function (results) {
    var finalValue = "";
    results.forEach(function (aResult) {
      // Handle multiple addresses being returned
      if (finalValue !== "") {
        finalValue += "<span>&nbsp;</span>";
      }

      // Parse each address in to a readable block
      finalValue += "<span class='badge success fwb'>" + aResult.address1 + (aResult.address2 && aResult.address2.length > 0 ? " " + aResult.address2 : "") + ", " + aResult.city + ", " + aResult.state + ", " + aResult.zip + (aResult.footnotes ? " [" + aResult.footnotes + "]" + (aResult.footnotes === "AABB" ? " <span class='badge new' title='Recommended Address'>Recommended</span>" : "") : "") + "</span><br/>";
    });
    return finalValue;
  };
  var determineReason = function (response) {
    if (response.is_valid) {
      return "N/A";
    }
    if (response.exception) {
      return response.exception;
    }
    if (!response.results || response.results.length === 0) {
      return "No valid address suggestions returned in API response (invalid address)";
    }
    if (response.results.length > 1) {
      return "Multiple address suggestions returned in API response";
    }
    return "";
  };

  // Converts an entire Smarty Streets response into HTML that can be displayed back in MCAdmin
  var convertResponse = function (response) {
    var html = "<h4 class='padding-top--lv2'>Address Verification Results</h4><ul class='leaders'><li><span>Valid: </span>";
    if (response.is_valid) {
      html += "<span class='fwb badge success'>Yes</span></li>";
    } else {
      html += response.is_valid === false ? "<span class='fwb badge error'>No</span></li>" : "<span class='fwb badge'>N/A</span></li>";
      html += "<li><span>Reason:</span><span class='badge error fwb'>" + determineReason(response) + "</li>";
    }
    if (response.results) {
      html += "<li><span>Candidates: </span>" + parseResults(response.results) + "</li>";
    }
    if (response.diagnostic_detail) {
      html += "<li><span>Diagnostic detail:</span><span class='small-meta breakall'>" + JSON.stringify(response.diagnostic_detail) + "</span></li>";
    }
    html += "</ul>";
    return html;
  };
  var validationResponse = {};
  var URL_PEACHES_USERS_ACCOUNT = "peaches2/users/account";
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      var self = this;
      var outerContent = document.getElementById("outer-content");
      var activeContainerId = tabContainer.container.selectedChildWidget.containerNode.id;
      var trackUserSummaryScroll = function () {
        // Scroll thresholds to collect - in ascending order
        var thresholds = [25, 50, 75, 100];
        var eventName = "e_scroll_depth_user_summary";
        /**
         * In order to avoid sending multiple of the same event
         * Check the data layer for the scroll events that have been sent
        */
        if (activeContainerId === "summary-section") {
          var currentScrollDepth = Math.floor(outerContent.scrollTop / (outerContent.scrollHeight - outerContent.offsetHeight) * 100);
          // Get all scroll events in data layer matching eventName
          var scrollEvents = window.dataLayer.filter(function (e) {
            return e.event === eventName;
          });

          // Create array of scroll thresholds already reported to Google Tag Manager
          var reportedThresholds = scrollEvents.map(function (dlObj) {
            return dlObj.scrollThreshold;
          });
          // If all thresholds have been reported, this eventListener can be removed.
          reportedThresholds.sort(function (a, b) {
            return a - b;
          });
          if (isEqual(reportedThresholds, thresholds)) {
            outerContent.removeEventListener("scroll", self.throttledScrollTracking);
          }

          /**
           * In order to avoid not reporting that a scroll threshold has been reached due to scrolling too fast
           * Create an array of non-reportedThresholds that currentScrollDepth is greater than or equal to.
          */
          var thresholdsToReport = thresholds.filter(function (value) {
            return !reportedThresholds.includes(value) && currentScrollDepth >= value;
          });

          // Report all thresholds that have been passed
          if (thresholdsToReport.length) {
            thresholdsToReport.forEach(function (threshold) {
              window.dataLayer.push({
                event: eventName,
                scrollThreshold: threshold
              });
            });
          }
        }
      };
      // Throttling scroll events is generally a good idea to reduce the amount of computations done per scroll
      // Creating a reference to the function returned by throttle, in order to be able to remove the event listener created
      self.throttledScrollTracking = throttle(trackUserSummaryScroll, 200);
      outerContent.addEventListener("scroll", self.throttledScrollTracking);
      if (this.activeSection === "login-details") {
        this.modules.viewLogins.viewLoginDetails(window.location.href, "");
      }
      if (this.activeSection === "login-details" || mUrl.getHashParams().t === "login-details-section") {
        var creditDetailsUrl = new URL(window.location.href).searchParams.get("login_id");
        if (creditDetailsUrl) {
          this.modules.viewLogins.viewLoginDetails(creditDetailsUrl);
        }
        if (mUrl.getHashParams().id) {
          this.modules.viewLogins.viewLoginDetails(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "swag-history" || mUrl.getHashParams().t === "swag-history-section") {
        var swagOrderUrl = new URL(window.location.href).searchParams.get("user_id");
        if (swagOrderUrl) {
          this.modules.summary.showSwagHistory(swagOrderUrl);
        }
        if (mUrl.getHashParams().id) {
          this.modules.summary.showSwagHistory(mUrl.getHashParams().id);
        }
      }
    },
    // This method will clear a users' current address validation status via AJAX
    clearValidationResults: function (uid, e) {
      if (confirm("Are you sure you want to clear the validation results for this customer?")) {
        xhr(mUrl.toUrl("/peaches2/users/account", {
          _s: "clear-address-validation-results",
          user_id: uid
        }), {
          handleAs: "json"
        }).then(function () {
          location.reload();
        });
      } else {
        e.preventDefault();
      }
    },
    // Save a users' current address validation status via AJAX
    saveValidationResults: function (uid) {
      xhr(mUrl.toUrl("/peaches2/users/account", {
        _s: "save-address-validation-results",
        user_id: uid,
        address_is_valid: validationResponse.validation_level
      }), {
        handleAs: "json"
      }).then(function () {
        location.reload();
      });
    },
    // Invoke the Smarty Streets API via AJAX and return results back to the user
    validateAddressClick: function (uid) {
      // Reset UI to a loading state
      var $verificationResults = query("#verificationResults");
      var $saveResultsButton = query("#btSaveValidationResults");
      $verificationResults.empty();
      $verificationResults.append("Loading...");
      if (!dojo.hasClass("btSaveValidationResults", "hide")) {
        $saveResultsButton.addClass("hide");
      }

      // Validate the address
      xhr(mUrl.toUrl("/peaches2/users/account", {
        _s: "validate-address",
        user_id: uid
      }), {
        handleAs: "json"
      }).then(function (response) {
        // TODO - Remove this after QA is complete -- this code is for verification of Smarty Streets API
        // Convert the response to html and load to the screen
        $verificationResults.empty();
        $verificationResults.append(convertResponse(response));

        // Cache the result so we can ultimately save if the user desires
        validationResponse = response;

        // Allow the user to save if we have a legit validation level
        if (response.is_valid !== null) {
          $saveResultsButton.removeClass("hide");
        }
      });
    },
    refreshUserActivityPane: function (uid, page, type) {
      type = typeof type !== "undefined" ? type : "";
      if (this.tabContainer.userActivitySection) {
        var url = mUrl.toUrl("/peaches2/users/account", {
          _s: "user-activity",
          type: type,
          user_id: uid,
          page: page
        });
        this.tabContainer.userActivitySection.setHref(url);
      }
    },
    /**
     * Because MCAdmin Flags aren't accessible in mojo, a separate updateUserActivityPane function is being used
     * @param {Integer} uid User ID
     * @param {Object} type Possibly orphaned argument
     * @param {String} emitter Element that invokes the function
     */
    updateUserActivityPane: function (uid, type, emitter) {
      type = type || "";
      var page = dom.byId("user-activity-page-select") ? dom.byId("user-activity-page-select").value : 0;
      var perPage = dom.byId("page-size-select").value;
      // Reset the page to 0 when updating the page size
      if (emitter.id === "page-size-select") {
        page = 0;
      }
      if (this.tabContainer.userActivitySection) {
        var url = mUrl.toUrl("/peaches2/users/account", {
          _s: "user-activity",
          type: type,
          user_id: uid,
          page: page,
          per_page: perPage
        });
        this.tabContainer.userActivitySection.setHref(url);
      }
    },
    filterLoginAccess: function (event, userId, loginAccessArray) {
      var tableBody = dom.byId("login-access-body");
      var searchValue = event.target.value.trim().toLowerCase();
      var loginAccounts = loginAccessArray.login_accounts;
      var invalidMessage = dom.byId("login-access-invalid-message");
      var generateRow = function (account) {
        var row = document.createElement("tr");
        var usernameCell = document.createElement("td");
        var fullNameCell = document.createElement("td");
        var emailCell = document.createElement("td");
        var roleCell = document.createElement("td");
        var loginIdCell = document.createElement("td");
        var loginBtnCell = document.createElement("td");
        usernameCell.innerText = account.username;
        fullNameCell.innerText = account.full_name;
        emailCell.innerText = account.email;
        roleCell.innerText = account.role;
        loginIdCell.innerText = account.user_login_id;
        var link = document.createElement("a");
        link.innerText = "Change Role to " + account.role;
        link.className = "button !margin-bottom--lv1";
        link.href = mUrl.toUrl(URL_PEACHES_USERS_ACCOUNT + "?user_id=" + userId + "&login_id=" + account.user_login_id);
        link.id = "change-role-" + account.user_login_id + "-btn";
        link.style = "min-width: 205px";
        loginBtnCell.appendChild(link);
        row.appendChild(usernameCell);
        row.appendChild(fullNameCell);
        row.appendChild(emailCell);
        row.appendChild(roleCell);
        row.appendChild(loginIdCell);
        row.appendChild(loginBtnCell);
        return row;
      };
      event.target.classList.remove("invalid");
      invalidMessage.classList.add("display--none");
      // Clear out existing elements
      while (tableBody.firstChild) {
        tableBody.removeChild(tableBody.firstChild);
      }
      // If searchValue is empty, display all loginAccounts
      if (searchValue === "") {
        loginAccounts.forEach(function (account) {
          var row = generateRow(account);
          tableBody.appendChild(row);
        });
      } else {
        // Filter loginAccounts based on provided search value
        var foundAccounts = [];
        loginAccounts.forEach(function (account) {
          if (account.username.toLowerCase().includes(searchValue) || account.full_name.toLowerCase().includes(searchValue) || account.user_login_id.toString().includes(searchValue) || account.email.toString().toLowerCase().includes(searchValue)) {
            var row = generateRow(account);
            foundAccounts.push(row);
          }
        });
        if (foundAccounts.length === 0) {
          var row = document.createElement("tr");
          var usernameCell = document.createElement("td");
          event.target.classList.add("invalid");
          invalidMessage.classList.remove("display--none");
          row.appendChild(usernameCell);
          tableBody.appendChild(row);
        } else {
          foundAccounts.forEach(function (accountRow) {
            tableBody.appendChild(accountRow);
          });
        }
      }
    }
  });
});