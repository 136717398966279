define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/user", "mojo/lib/flags", "dojo/text!./templates/_publish.html", "dijit/registry", "mojo/url"], function (declare, lang, dom, domStyle, Renderable, user, flags, tpl, registry, mUrl) {
  return declare([Renderable], {
    templateString: tpl,
    campaignId: null,
    constructor: function (props) {
      this.state = {
        model: props.model
      };
      this.pageId = props.model.pageId;
    },
    showSendNowDialog: function () {
      var publishModal = registry.byId(this.publishModal.id);
      publishModal.show();
    },
    sendConfirmed: function () {
      registry.byId(this.publishModal.id).set("title", "Publishing...");
      registry.byId(this.publishModal.id).set("closable", false);
      domStyle.set(this.confirmSendPre, "display", "none");
      domStyle.set(this.confirmSendAction, "display", "none");
      domStyle.set(this.confirmSendPost, "display", "block");
      document.location.href = mUrl.toUrl("/landing-pages/publish", {
        "id": this.pageId,
        "__csrf_token": user.__csrfToken
      });
    }
  });
});