/**
 * Base module that all viewscripts should extend. This provides some useful features that help
 * reach elements in the page a bit easier.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/registry", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/NodeList-dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate", "dojo/NodeList-traverse", "mojo/NodeList-velocity"], function (declare, lang, array, Stateful, registry, aspect, query, domAttr, ready) {
  return declare([Stateful], {
    /**
     * Holds a reference to elements tagged with data-mc-el as a dom element.
     */
    "$el": {},
    /**
     * Holds a reference to dijit/widget instance if a widget was tagged with data-mc-el.
     */
    "$widget": {},
    /**
     * Holds a reference of elements tagged with data-mc-group as dojo/NodeList.
     */
    "$group": {},
    onInit: function () {},
    onReady: function () {},
    /**
     * Parses all dom elements that have data-mc-el and caches it in the view.
     * @param {Node} parentDom
     */
    parse: function (parentDom) {
      query("[data-mc-el],[data-mc-group]", parentDom).forEach(lang.hitch(this, "_registerEl"));
    },
    run: function () {
      // Hook into dijit's registering mechanism and check for widgets added to the page.
      aspect.after(registry, "add", lang.hitch(this, "_registerWidget"), true);
      aspect.before(registry, "remove", lang.hitch(this, "_unregisterWidget"), true);
      lang.hitch(this, "onInit")();

      // Change priority to make sure this always run first before other ready calls.
      ready(200, lang.hitch(this, function () {
        this.parse(window.document);
      }));
      ready(lang.hitch(this, "onReady"));
    },
    _registerEl: function (el) {
      var id = domAttr.get(el, 'data-mc-el');
      var group = domAttr.get(el, 'data-mc-group');
      if (id) {
        this.$el[id] = el;
      } else if (id == "") {
        console.warn("[Skipped] element tagged with data-mc-el, but no value.");
      }
      if (group) {
        this.$group[group] = this.$group[group] || query.NodeList();
        this.$group[group].push(el);
      } else if (group == "") {
        console.warn("[Skipped] element tagged with data-mc-group, but no value.");
      }
    },
    _registerWidget: function (widget) {
      if (widget['data-mc-el']) {
        this.$widget[widget['data-mc-el']] = widget;
      }
    },
    _unregisterWidget: function (widgetId) {
      var widget = registry.byId(widgetId);
      var mcel = widget && widget['data-mc-el'];
      if (this.$widget[mcel]) {
        delete this.$widget[mcel];
      }
    }
  });
});