define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url", "mojo/widgets/campaigns/CampaignArchiveUrl"], function (declare, base, lang, on, domClass, dom, query, Deferred, utils, mUrl, campaignArchiveUrl) {
  return declare([base], {
    campaignId: null,
    // Passed from controller

    archivedBaseUrl: null,
    campaignToken: null,
    brandToken: null,
    rootDomain: null,
    onReady: function () {
      // Handle Emojis
      utils.parseEmoji(dom.byId("content"));

      // Handle Archive editing
      if (this.$el.archiveUrlPane) {
        this._initEditArchiveUrl();
      }
    },
    confirmLastEdit: function () {
      var defer = new Deferred();
      var dialog = this.$widget.editArchiveUrlConfirmation;
      on(this.$el.confirmationSave, "click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        defer.resolve(true);
        dialog.hide();
      });
      on(this.$el.confirmationCancel, "click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        defer.resolve(false);
        dialog.hide();
      });
      dialog.show();
      return defer.promise;
    },
    _initEditArchiveUrl: function () {
      var self = this;
      var el = self.$el;
      var initCampaignArchive = null;
      var editCampaignArchiveUrl;
      on(el.editArchiveUrlButton, "click", lang.hitch(this, function () {
        domClass.toggle(el.archiveUrlPane, "hide");
        domClass.toggle(el.editArchiveUrlPane, "hide");
        if (!initCampaignArchive) {
          initCampaignArchive = new campaignArchiveUrl({
            rootDomain: this.rootDomain,
            campaignToken: this.campaignToken,
            brandToken: this.brandToken,
            campaignId: this.campaignId,
            archivedBaseUrl: this.archivedBaseUrl,
            editMode: true
          });
          initCampaignArchive.placeAt(el.editArchiveUrlHolder);
        }
      }));
      editCampaignArchiveUrl = function () {
        if (initCampaignArchive.campaignToken === initCampaignArchive.originalValue) {
          var defer = new Deferred();
          defer.resolve();
          return defer.promise;
        }
        return initCampaignArchive.editUrl().then(lang.hitch(this, function (res) {
          if (res.response.status) {
            self.archivedUrlNumberOfRemainingEdits--;
            domClass.toggle(el.archiveUrlPane, "hide");
            domClass.toggle(el.editArchiveUrlPane, "hide");
            el.archiveUrlLink.innerHTML = res.response.url;
            el.archiveUrlLink.href = res.response.url;
          }
        }));
      };
      on(el.saveNewUrl, "click", lang.hitch(this, function () {
        if (self.archivedUrlNumberOfRemainingEdits === 1) {
          this.confirmLastEdit().then(function (isConfirmed) {
            if (isConfirmed) {
              return editCampaignArchiveUrl();
            }
            return;
          }).then(function () {
            if (self.archivedUrlNumberOfRemainingEdits === 0) {
              domClass.toggle(el.editArchiveUrlButton, "hide");
            }
          });
        } else {
          editCampaignArchiveUrl();
        }
      }));
      on(el.cancelArchiveEditing, "click", lang.hitch(this, function () {
        domClass.toggle(el.archiveUrlPane, "hide");
        domClass.toggle(el.editArchiveUrlPane, "hide");
      }));
    }
  });
});