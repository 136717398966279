define(["mojo/user", "mojo/utils/number"], function (user, numberUtils) {
  return {
    /**
     * Take a number and format it in USD.
     *
     * @deprecated See `mojo.utils.number.toUsdCurrency`
     * @param {number} value - A number value, like 12.3
     * @return {string} Value formatted in USD, like $12.30
     */
    usdCurrency: function (value) {
      return numberUtils.toUsdCurrency(value);
    },
    /**
     * Take a string and capitalize the first letter
     *
     * @param {string} value - A string, like "abc"
     * @return {string} Value titlized, like "Abc"
     */
    title: function (value) {
      var titlizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      return titlizedValue;
    }
  };
});