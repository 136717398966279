/**
 * Top level widget for the platform selection UI
 * @module /mojo/widgets/account/connected-sites/PlatformsList
 * @see /mojo/widgets/FilteredSearch
 * @see /mojo/widgets/account/connected-sites/PlatformsListItem
 * @see /mojo/widgets/account/connected-sites/StoreSelectionManager
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/isEqual", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/PlatformsList.html", "mojo/widgets/NoDataBlock", "mojo/widgets/account/connected-sites/PlatformsListItem", "mojo/user", "mojo/lib/flags"], function (declare, lang, Velocity, domAttr, Deferred, isEqual, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr, NoDataBlock, PlatformsListItem, user, flags) {
  // renders a list of platforms with a section heading
  var PlatformSection = declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: "<div class='margin-bottom--lv8'>" + "<h4 data-dojo-attach-point='displayTextNode'></h4>" + "<div data-dojo-attach-point='feedbackContainerNode'></div>" + "<div data-dojo-attach-point='listContainerNode' class='select-platform-section'></div>" + "</div>",
    platforms: null,
    _setPlatformsAttr: function (platformsArray) {
      platformsArray = this._sortPlatformsArray(platformsArray);
      this._renderPlatforms(platformsArray, this.listContainerNode);
      this._set("platforms", platformsArray);
    },
    _sortPlatformsArray: function (platformsArray) {
      platformsArray.sort(function (a, b) {
        if (a.connected && !b.connected) {
          return -1;
        }
        if (!a.connected && b.connected) {
          return 1;
        }
        return 0;
      });
      return platformsArray;
    },
    displayText: null,
    _setDisplayTextAttr: {
      node: "displayTextNode",
      type: "innerText"
    },
    _renderPlatforms: function (platforms, container) {
      platforms.map(function (platform) {
        new PlatformsListItem(platform).placeAt(container);
      });
    }
  });
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // data-dojo-attach-point(s)
    sectionContainerNode: null,
    noResultsContainerNode: null,
    noDataBlockNode: null,
    // check to skip initial animation
    hasRenderedOnce: false,
    /** @type {Platform[]} set by StoreSelectionManager */
    platforms: null,
    _setPlatformsAttr: function (platformsArray) {
      if (this.hasRenderedOnce) {
        if (isEqual(platformsArray, this.platforms)) {
          // platforms list has not changed - no updates to be made
          return;
        }

        // standard update with animation
        this._renderPlatformsWithAnimation(platformsArray);
      } else {
        // initial render - do not animate
        this._renderPlatformSections(platformsArray, this.sectionContainerNode);
      }
      this.set("hasRenderedOnce", true);
      this._set("platforms", platformsArray);
    },
    postCreate: function () {
      new NoDataBlock({
        type: "default",
        title: "No results",
        message: "We couldn't find any integrations matching your search. " + "<br />" + "Try <a href='#other'>connecting a custom website</a>, or see our <a target='_blank' href='https://connect.mailchimp.com/'>list of available integrations</a>."
      }).placeAt(this.noDataBlockNode);
    },
    /**
     * Initializes widgets and places in container DOM
     * @param {object[]} platforms data for each platform
     * @param {Node} container DOM container to render into
     */
    _renderPlatformSections: function (platforms, container) {
      var buckets = {};
      // create a result bucket for each filter category
      this.filters.map(function (filter) {
        if (filter.category !== "") {
          buckets[filter.category] = {
            displayText: filter.displayText,
            platforms: []
          };
        }
      });

      // fill the platforms array for each category bucket
      platforms.map(function (platform) {
        if (platform.category in buckets) {
          buckets[platform.category].platforms.push(platform);
        }
      }, this);

      // iterate the buckets and create PlatformSections
      Object.keys(buckets).map(lang.hitch(this, function (filterKey) {
        // category has at least one platform
        if (buckets[filterKey].platforms.length > 0) {
          new PlatformSection(buckets[filterKey]).placeAt(this.sectionContainerNode);
        }
      }));
    },
    _fadeIn: function (element) {
      var deferred = new Deferred();
      Velocity(element, "transition.slideUpIn", {
        duration: 100,
        complete: deferred.resolve
      });
      return deferred;
    },
    _fadeOut: function (element) {
      var deferred = new Deferred();
      Velocity(element, "transition.slideDownOut", {
        duration: 100,
        complete: function () {
          return deferred.resolve();
        }
      });
      return deferred;
    },
    /**
     * Iterates this.platforms to render each platform widget
     * @param {object[]} platforms array platform data object
     */
    _renderPlatformsWithAnimation: function (platforms) {
      // hide current results
      this._fadeOut(this.sectionContainerNode).then(lang.hitch(this, function () {
        if (platforms.length === 0) {
          // show no results (empty state)
          this.sectionContainerNode.innerHTML = this.noResultsContainerNode.innerHTML;
        } else {
          // render new results into sectionContainerNode
          this.sectionContainerNode.innerHTML = "";
          this._renderPlatformSections(platforms, this.sectionContainerNode);
        }
        // show new results
        this._fadeIn(this.sectionContainerNode);
      }));
    }
  });
});