define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, lang, topic, base) {
  return declare([base], {
    pageJs: null,
    ecommerceStoresListRendered: null,
    onReady: function () {
      topic.subscribe("mojo/analytics/List/ecommerce-stores-list/render", lang.hitch(this, function () {
        if (!this.ecommerceStoresListRendered) {
          this.ecommerceStoresListRendered = true;
          this.pageJs.loadFilter();
        }
      }));
    }
  });
});