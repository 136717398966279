define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/clipboard", "mojo/app/peaches2/utils", "mojo/utils"], function (declare, base, domAttr, on, mUrl, domClass, clipboard, xhr, p2Utils, utils) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    editingSummary: false,
    onReady: function () {
      var self = this;
      domClass.remove(this.$el.editSummaryButton, "hide");
      on(this.$el.editSummaryButton, "click", function () {
        self.editSummary();
      });
    },
    editSummary: function () {
      var editSummaryUrl = mUrl.toUrl("/peaches2/users/account/edit-summary", {
        user_id: this.pageJs.userAttrs.userId
      });
      this.pageJs.tabContainer.summarySection.set("href", editSummaryUrl);
      this.hideEditSummaryButton(true);
      this.editingSummary = true;
    },
    saveSummary: function (e) {
      e.preventDefault();
      var self = this;
      p2Utils.utils.sendFormViaAjax("edit-summary-form").then(function () {
        self.viewSummary();
        p2Utils.utils.updateUserTags(self.pageJs.userAttrs);
      });
    },
    viewSummary: function () {
      var summaryUrl = mUrl.toUrl("/peaches2/users/account", {
        user_id: this.pageJs.userAttrs.userId,
        _s: "summary"
      });
      this.pageJs.tabContainer.summarySection.set("href", summaryUrl);
      this.editingSummary = false;
      this.hideEditSummaryButton(false);
    },
    hideEditSummaryButton: function (hide) {
      var button = this.$el.editSummaryButton;
      if (!button) {
        return false;
      }
      if (hide === true || this.editingSummary === true) {
        domClass.add(button, "hide");
      } else {
        domClass.remove(button, "hide");
      }
    },
    showSwagHistory: function () {
      var self = this;
      var newPaneObj = {
        title: "Swag History",
        href: mUrl.toUrl("/peaches2/users/account/swag-history", {
          user_id: this.pageJs.userAttrs.userId
        }),
        stub: "swag-history",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.swagHistorySection = null;
          return true;
        }
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true);
    },
    showSegmentLogging: function () {
      var self = this;
      var newPaneObj = {
        title: "Pro Segment",
        href: mUrl.toUrl("/peaches2/users/account/pro-logging", {
          user_id: this.pageJs.userAttrs.userId
        }),
        stub: "pro-segment",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.proSegmentSection = null;
          return true;
        }
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true);
    },
    reinstate: function (email, whitelist) {
      if (typeof whitelist === "undefined") {
        whitelist = false;
      }
      var url = mUrl.toUrl("/peaches2/users/review-tools/reinstate-post", {
        user_id: this.pageJs.userAttrs.userId,
        whitelist: whitelist
      });
      if (email === "Y") {
        url += "&send_email=Y";
      }
      document.location = url;
    }
  });
});