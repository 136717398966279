define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/postcard-status.html", "dijit/Dialog", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/PostcardBillingSidebar", "mojo/widgets/campaigns/PostcardSlat", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/url", "mojo/utils", "mojo/utils/date", "mojo/api/postcard", "../SendPostcard", "dojo/text!mojo/widgets/campaigns/templates/CampaignPauseDialog.html"], function (lang, declare, topic, tpl, Dialog, Renderable, PostcardBillingSidebar, PostcardSlat, user, context, flags, mUrl, utils, dateUtils, postcardApi, SendPostcard, pauseDialogTemplate) {
  return declare([Renderable], {
    templateString: tpl,
    finishLaterUrl: mUrl.toUrl("/campaigns"),
    POSTCARD_STATUS_DRAFT: postcardApi.POSTCARD_STATUS_DRAFT,
    POSTCARD_STATUS_PROCESSING: postcardApi.POSTCARD_STATUS_PROCESSING,
    POSTCARD_STATUS_SENT: postcardApi.POSTCARD_STATUS_SENT,
    POSTCARD_STATUS_PAUSED: postcardApi.POSTCARD_STATUS_PAUSED,
    POSTCARD_STATUS_ACTIVE: postcardApi.POSTCARD_STATUS_ACTIVE,
    POSTCARD_STATUS_CANCELED: postcardApi.POSTCARD_STATUS_CANCELED,
    POSTCARD_STATUS_REASON_COMPLIANCE: postcardApi.POSTCARD_STATUS_REASON_COMPLIANCE,
    POSTCARD_STATUS_REASON_NO_RECIPIENTS: postcardApi.POSTCARD_STATUS_REASON_NO_RECIPIENTS,
    POSTCARD_STATUS_REASON_SOURCE_DELETED: postcardApi.POSTCARD_STATUS_REASON_SOURCE_DELETED,
    enableButtonWhenUserIsPaused: null,
    state: {
      isItemActive: false,
      billingInfo: null,
      isPostcardScheduled: false
    },
    constructor: function (props) {
      this.enableButtonWhenUserIsPaused = user.isPaused;
      this.state.model = props.model;
      this.state.isReady = props.isReady;
      this.state.icon = {
        classes: "c-channelIcon c-channelIcon--postcard"
      };
      this.own(topic.subscribe(PostcardBillingSidebar.SIDEBAR_UPDATED_TOPIC, function (sidebarInstance) {
        this.sidebarInstance = sidebarInstance;
        this.setState({
          billingInfo: this.sidebarInstance.getPaymentInfo()
        });
      }.bind(this)));
    },
    postCreate: function () {
      this.inherited(arguments);
      var completedSteps = this.completeItems.length;
      var currentStatus = this.state.model.status;
      var isReadyToSend = completedSteps >= 3 && currentStatus === "draft";

      // If the user filled out the postcard content & the campaign status is "ready"
      if (isReadyToSend && typeof window.usabilla_live === "function") {
        window.usabilla_live("trigger", "postcard-survey");
      }
    },
    //Check value if type is recurring or abandoned
    _isAutomated: function () {
      return !!this.model.automationTrigger.strategy;
    },
    _getStatusText: function () {
      var isReady = this.state.isReady;
      var completedSteps = this.completeItems.length;
      var currentStatus = this.state.model.status;
      var statusReason = this.state.model.statusReason;
      var automationStrategy = this.state.model.automationTrigger.strategy;
      var audienceStrategy = this.model.audienceStrategy;
      if (this.model.list ? this.model.list.disabled : false) {
        return "The audience you selected is disabled.";
      }
      if (currentStatus === this.POSTCARD_STATUS_PROCESSING) {
        if (this.model.list === null && audienceStrategy !== "upload") {
          return "The list or segment you selected for your audience has been deleted.";
        }
        return "Your postcard is processing.";
      } else if (currentStatus === this.POSTCARD_STATUS_SENT) {
        if (this.model.list === null && audienceStrategy !== "upload") {
          return "The list or segment you selected for your audience has been deleted.";
        }
        return "Your postcard has been sent!";
      } else if (statusReason === this.POSTCARD_STATUS_REASON_COMPLIANCE) {
        return "Your postcard has been rejected.";
      } else if (statusReason === this.POSTCARD_STATUS_REASON_NO_RECIPIENTS) {
        return "We could not find any addresses in your selected audience.";
      } else if (statusReason === this.POSTCARD_STATUS_REASON_SOURCE_DELETED) {
        return "The list or segment you selected for your audience has been deleted.";
      }

      //Note: automated postcards start at step 2
      if (completedSteps < 2 || completedSteps === 2 && automationStrategy !== null) {
        return "Let's get started.";
      }
      if (completedSteps >= 2 && !isReady) {
        if (this.model.list === null && audienceStrategy !== "upload") {
          return "The list or segment you selected for your audience has been deleted.";
        }
        return "Keep it up.";
      }
      if (completedSteps >= 3 && currentStatus !== "active" && currentStatus !== "paused" && currentStatus !== "canceled" && currentStatus !== "scheduled") {
        return "Your postcard is ready to send.";
      }
      if (currentStatus === "active") {
        if (automationStrategy === "recurring") {
          // scheduled_send_time will be a date, not a time, no tz data needed
          var scheduledDate = new Date(this.model.nextOrderDate);
          scheduledDate = dateUtils.getDateOnlyString(scheduledDate);
          return "Your next order is scheduled for " + scheduledDate;
        }
        if (this.model.list === null) {
          if (this.model.automationTrigger.strategy === postcardApi.AUTOMATION_STRATEGY_ABANDONED_CART) {
            return "The list, segment, or store you selected for your audience has been deleted. ";
          }
          return "The list or segment you selected for your audience has been deleted.";
        }
        return "Your campaign is currently running";
      }
      if (currentStatus === "paused") {
        return "All set to resume sending.";
      }
      if (currentStatus === "canceled") {
        return "Your postcard was canceled.";
      }
      if (currentStatus === "scheduled") {
        // scheduled_send_time will be a date, not a time, no tz data needed
        var scheduledSendTime = new Date(this.model.scheduledSendTime);
        scheduledSendTime = dateUtils.getDateOnlyString(scheduledSendTime);
        return "Your postcard will be sent to the printer on  " + scheduledSendTime + ".";
      }
    },
    _getSubmitText: function () {
      if (this.model.automationTrigger.strategy === postcardApi.AUTOMATION_STRATEGY_RECURRING) {
        return this.model.status === postcardApi.POSTCARD_STATUS_PAUSED ? "Resume Sending" : "Start Sending";
      } else if (this.model.automationTrigger.strategy === postcardApi.AUTOMATION_STRATEGY_ABANDONED_CART) {
        return this.model.status === postcardApi.POSTCARD_STATUS_PAUSED ? "Resume Workflow" : "Start Sending";
      }
      return "Send Postcard";
    },
    _redirectToReport: function () {
      window.location.href = mUrl.toUrl("/reports/summary?id=" + this.state.model.uniqueID);
    },
    _getStatusBadgeLabel: function () {
      var status = PostcardSlat.STATUS_CONTENT[this.model.statusReason] || PostcardSlat.STATUS_CONTENT[this.model.status];
      return status.label;
    },
    _getStatusBadgeClass: function () {
      var status = PostcardSlat.STATUS_CONTENT[this.model.statusReason] || PostcardSlat.STATUS_CONTENT[this.model.status];
      return status.badge;
    },
    _getLastUpdatedDateString: function () {
      // add tz formating for updated_at
      // dates are now objects
      if (this.model.updatedAt && this.model.updatedAt instanceof Object && this.model.updatedAt.hasOwnProperty("jsDate")) {
        var jsDate = this.model.updatedAt.jsDate;
        // TODO: TZ Formatting - check with design about date formatting for Campaign Status with Month_Day_Year_Time and TZ label
        var formattedDate = dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.MONTH_DAY_YEAR_TIME);
        return formattedDate;
      }
      return dateUtils.getDateString(this.model.updatedAt);
    },
    _pausePostcard: function () {
      var dialog = new Dialog({
        "title": "Would you like to pause your postcard?",
        "content": pauseDialogTemplate,
        onHide: function () {
          dialog.destroyRecursive();
        }
      });
      dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
        window.location.href = "/postcards/pause?id=" + this.state.model.postcardId;
      }.bind(this));
      dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
        dialog.hide();
      });
      dialog.show();
    },
    _canBeSent: function () {
      var hasPaymentMethod = this.state.billingInfo && this.state.billingInfo.hasPaymentMethod;
      var isPaymentRequired = this.state.billingInfo && this.state.billingInfo.isPaymentRequired;
      var hasSidebarInstance = !!this.sidebarInstance;
      var audienceDisabled = this.model.list ? this.model.list.disabled : false;
      return this.state.isReady && !audienceDisabled && !this.state.isItemActive && user.hasRole("manager") && (user.canSend || this.enableButtonWhenUserIsPaused) && hasSidebarInstance && (hasPaymentMethod || !isPaymentRequired);
    },
    _openSendNowModal: function (billingData) {
      var sendModal = new SendPostcard(lang.mixin({}, billingData, {
        model: this.state.model,
        isPostcardScheduled: this.state.isPostcardScheduled
      }));
      sendModal.showDialog();
    },
    _resumePausedPostcard: function () {
      utils.toggleButtonLoadingState(this.submitButton, "Resuming...");
      this.resumeForm.submit();
    },
    _sendInitialPostcard: function () {
      var latestBillingInfo = this.sidebarInstance && this.sidebarInstance.getPaymentInfo();
      if (latestBillingInfo && (!latestBillingInfo.isCVVRequired || latestBillingInfo.CVV)) {
        this.sidebarInstance.toggleCVVError(false);
        this._openSendNowModal({
          CVV: latestBillingInfo.CVV,
          pricingVersion: latestBillingInfo.pricingVersion,
          amountDue: latestBillingInfo.amountDue,
          amountDueInUserCurrency: latestBillingInfo.amountDueInUserCurrency
        });
      } else {
        this.sidebarInstance.toggleCVVError(true);
      }
    },
    send: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: 'paused',
          action: 'wiggle'
        });
      }
      if (this.model.status === this.POSTCARD_STATUS_PAUSED) {
        this._resumePausedPostcard();
        return;
      }
      this.setState({
        isPostcardScheduled: false
      });
      this._sendInitialPostcard();
    },
    schedule: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: 'paused',
          action: 'wiggle'
        });
      }
      this.setState({
        isPostcardScheduled: true
      });
      this._sendInitialPostcard();
    }
  });
});