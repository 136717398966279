define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module" // domAttr.set
], function (declare, domAttr) {
  // module:
  //		dijit/form/_ToggleButtonMixin

  return declare("dijit.form._ToggleButtonMixin", null, {
    // summary:
    //		A mixin to provide functionality to allow a button that can be in two states (checked or not).

    // checked: Boolean
    //		Corresponds to the native HTML `<input>` element's attribute.
    //		In markup, specified as "checked='checked'" or just "checked".
    //		True if the button is depressed, or the checkbox is checked,
    //		or the radio button is selected, etc.
    checked: false,
    // aria-pressed for toggle buttons, and aria-checked for checkboxes
    _aria_attr: "aria-pressed",
    _onClick: function ( /*Event*/evt) {
      var original = this.checked;
      this._set('checked', !original); // partially set the toggled value, assuming the toggle will work, so it can be overridden in the onclick handler
      var ret = this.inherited(arguments); // the user could reset the value here
      this.set('checked', ret ? this.checked : original); // officially set the toggled or user value, or reset it back
      return ret;
    },
    _setCheckedAttr: function ( /*Boolean*/value, /*Boolean?*/priorityChange) {
      this._set("checked", value);
      var node = this.focusNode || this.domNode;
      if (this._created) {
        // IE is not ready to handle checked attribute (affects tab order)
        // needlessly setting "checked" upsets IE's tab order
        if (domAttr.get(node, "checked") != !!value) {
          domAttr.set(node, "checked", !!value); // "mixed" -> true
        }
      }
      node.setAttribute(this._aria_attr, String(value)); // aria values should be strings
      this._handleOnChange(value, priorityChange);
    },
    postCreate: function () {
      // use postCreate instead of startup so users forgetting to call startup are OK
      this.inherited(arguments);
      var node = this.focusNode || this.domNode;
      if (this.checked) {
        // need this here instead of on the template so IE8 tab order works
        node.setAttribute('checked', 'checked');
      }

      // Update our reset value if it hasn't yet been set (because this.set()
      // is only called when there *is* a value)
      if (this._resetValue === undefined) {
        this._lastValueReported = this._resetValue = this.checked;
      }
    },
    reset: function () {
      // summary:
      //		Reset the widget's value to what it was at initialization time

      this._hasBeenBlurred = false;

      // set checked state to original setting
      this.set('checked', this.params.checked || false);
    }
  });
});