define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/values", "vendor/lodash-amd/isEqual", "mojo/url"], function (declare, xhr, when, values, isEqual, mUrl) {
  var MergeField = declare(null, {
    constructor: function (properties) {
      this.type = properties.field_type;
      this.name = properties.name;
      this.var = properties.var;
    }
  });
  var MemberList = declare(null, {
    constructor: function (properties) {
      this.listId = properties.list_id;
      this.type = properties.list_type;
      this.name = properties.name;
      this.size = properties.size;
      this.mergeFields = (properties.merge_fields || []).map(function (mergeField) {
        return new MergeField(mergeField);
      });
      this.hasInterestGroups = properties.has_interest_groups;
      // EMAIL field is always included, but can't be used to trigger campaigns with this requirement
      this.hasMergeFields = values(properties.merge_fields).length > 1;
      this.hasEcommerceStore = properties.has_ecommerce_store;
      this.totalContacts = properties.total_contacts;
      this.totalSubscribers = properties.total_subscribers;
      this.hasPopupForm = properties.has_popup_form;
      this.hasTags = properties.has_tags;
    },
    hasMergeFieldOfType: function (type) {
      return this.mergeFields.filter(function (mergeField) {
        return mergeField.type === type;
      }).length > 0;
    },
    hasMergeFieldWithValue: function (value) {
      return this.mergeFields.filter(function (mergeField) {
        return mergeField.var === value;
      }).length > 0;
    }
  });
  var _cachedRequest;
  var _cachedResult;
  return {
    /**
     * @return {Promise} Resolves to an array of MemberList
     */
    list: function (requestQuery) {
      return xhr.get(mUrl.toUrl("channels/get-all-lists"), {
        handleAs: "json",
        query: requestQuery ? requestQuery : null
      }).then(function (data) {
        return data.map(function (memberList) {
          return new MemberList(memberList);
        });
      });
    },
    /**
     * @return {Promise} A promise either of the cached result, the cached request, or a brand new request
     */
    cachedList: function (requestQuery) {
      if (_cachedResult && isEqual(requestQuery, _cachedResult.query)) {
        return Promise.resolve(_cachedResult.data);
      }
      if (_cachedRequest) {
        return _cachedRequest;
      }
      _cachedRequest = this.list.apply(this, [requestQuery]).then(function (results) {
        _cachedResult = {
          data: results,
          query: requestQuery
        };
        return _cachedResult.data;
      });
      return _cachedRequest;
    },
    filters: {
      hasBirthday: function (memberList) {
        return memberList.hasMergeFieldOfType("birthday");
      },
      hasDate: function (memberList) {
        return memberList.hasMergeFieldOfType("date");
      },
      hasGroup: function (memberList) {
        return memberList.hasInterestGroups;
      },
      hasMergeField: function (memberList) {
        return memberList.hasMergeFields;
      },
      hasEcommerceStore: function (memberList) {
        return memberList.hasEcommerceStore;
      },
      hasAtLeast100Contacts: function (memberList) {
        return memberList.totalContacts >= 100;
      },
      hasPopupForm: function (memberList) {
        return memberList.hasPopupForm;
      },
      hasTags: function (memberList) {
        return memberList.hasTags;
      }
    }
  };
});