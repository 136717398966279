define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/SurveyLink.html", "mojo/url", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation", "dijit/_WidgetBase", "dijit/_TemplatedMixin"], function (declare, on, tpl, murl, flags, context, I18nTranslation, _WidgetBase, _TemplatedMixin) {
  return declare([_WidgetBase, _TemplatedMixin], {
    userId: null,
    templateString: tpl,
    surveyText: null,
    surveyLink: null,
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        this.surveyText = translations.all_campaigns_survey_text;
      }.bind(this));
      this.surveyLink = "https://mailchimp.iad1.qualtrics.com/jfe/form/SV_9zZCjrfKDdWA7n8?mcu=" + this.userId;
    }
  });
});