define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/_upsell-postcard.html", "dijit/registry", "mojo/url", "mojo/user", "mojo/api/postcard", "mojo/lib/flags"], function (declare, lang, dom, domStyle, domClass, query, Renderable, tpl, registry, mUrl, user, postcardsApi, flags) {
  return declare([Renderable], {
    templateString: tpl,
    state: {},
    constructor: function (props) {
      this.state = {
        subscribers: props.total_list_size,
        monthlyAmount: props.upgrade_cost_in_user_currency,
        lowerBound: props.upgrade_list_lower_bound,
        upperBound: props.upgrade_list_upper_bound,
        maxEmailSends: props.max_email_sends || 0,
        addOnContactBlockPrice: props.add_on_contact_block_price_in_user_currency || 0,
        addOnContactBlockSize: props.add_on_contact_block_size || 0,
        additionalEmailSends: props.additional_email_sends || 0
      };
    },
    primaryMessage: function () {
      return "Based on your <span class=\"font-weight--bold\">" + this.state.subscribers.toLocaleString() + " contacts</span>, you'll be upgraded to the Standard plan.";
    },
    secondaryMessage: function () {
      return "<h1 class='flex flex-row flex-justify--center'>" + this.state.monthlyAmount + "<span>/month</span></h1>" + "<p>" + this.state.upperBound.toLocaleString() + " audience size</p>";
    },
    showDialog: function () {
      var sendPostcardModal = registry.byId(this.upsellPostcardModal.id);
      sendPostcardModal.onHide = function () {
        sendPostcardModal.destroyRecursive();
      };
      sendPostcardModal.show();
    }
  });
});