define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/uuid/generateRandomUuid", "mojo/user", "mojo/lib/flags"], function (xhr, generateRandomUuid, user, flags) {
  function generateTID() {
    // Not all supported browsers have support for this. Fallback to dojox uuid
    if (window.crypto && window.crypto.randomUUID) {
      return window.crypto.randomUUID();
    }
    return generateRandomUuid();
  }
  function promptCall(promptId, inputs, metadata) {
    var intuit_tid = generateTID();
    var data = {
      prompt_id: promptId,
      inputs: inputs,
      campaign_id: metadata["campaign_id"],
      campaign_type: metadata["campaign_type"],
      content_type: metadata["content_type"],
      intuit_tid: intuit_tid
    };
    return xhr.post("/prompt-manager/post", {
      "handleAs": "json",
      "data": JSON.stringify(data)
    }).then(function (response) {
      response.intuit_tid = intuit_tid;
      return response;
    });
  }

  // Neapolitan will support paragraph for now, but this can be expanded for future usage
  var contentTypes = {
    paragraph: {
      "type": "paragraph",
      "wordLimit": "200"
    }
  };
  return {
    refineParagraph: function (text, command) {
      var paragraphType = contentTypes.paragraph;
      var promptInputs = {
        command: command,
        source_text: text,
        word_limit: paragraphType.wordLimit,
        screen: "campaigns/wizard/neapolitan"
      };
      var prompt_id = "mc_marketing_inline_assistant_selected_text_transformation_v6";
      return promptCall(prompt_id, promptInputs, {
        "campaign_id": window.app && window.app.campaignId,
        "campaign_type": window.app && window.app.campaignType,
        "content_type": paragraphType.type
      });
    },
    generateParagraph: function (command, tone) {
      var paragraphType = contentTypes.paragraph;
      if (!tone) {
        tone = "excited and friendly";
      }
      var promptInputs = {
        command: command,
        tone: tone,
        screen: "campaigns/wizard/neapolitan"
      };
      var prompt_id = "mc_marketing_inline_assistant_paragraph_generation_v7";
      return promptCall(prompt_id, promptInputs, {
        "campaign_id": window.app && window.app.campaignId,
        "campaign_type": window.app && window.app.campaignType,
        "content_type": paragraphType.type
      });
    }
  };
});