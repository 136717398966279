define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojox/dtl", "dojox/dtl/Context", "dojo/text!../editors/blocks/templates/SocialShareEditor/output.html", "dojo/text!../editors/blocks/templates/SocialShareEditor/_horizontal.html", "dojo/text!../editors/blocks/templates/SocialShareEditor/_vertical.html"], function (declare, _Renderer, array, lang, mUrl, dtl, dtlContext, tpl, horizontalTpl, verticalTpl) {
  return declare("mojo.neapolitan.renderers.SocialShareBlock", [_Renderer], {
    outputTplStr: tpl,
    defaultText: {
      'facebookShare': 'Share',
      'twitterShare': 'Tweet',
      'instapaperShare': 'Read Later',
      'googlePlusShare': '+1',
      'forwardToFriend': 'Forward to Friend',
      'linkedInShare': 'Share',
      'pinterestShare': 'Pin'
    },
    constructor: function () {
      this.horizontalTemplate = new dojox.dtl.Template(horizontalTpl);
      this.verticalTemplate = new dojox.dtl.Template(verticalTpl);
    },
    getOutputModel: function (meta) {
      // Set the icon urls for the items
      array.forEach(meta.items, lang.hitch(this, function (item) {
        this._getServiceIconUrl(item, meta.iconStyle, meta.iconType);
        this._buildShareUrl(item, this._getShareUrl(meta), this._getDescription(meta));
        this._setDefaultText(item);
      }));

      // This is for outlook compatibility. We have to subtract 40 to avoid DPI issues.
      var calculatedWidth = Math.floor(meta.containerWidth - this.outerPadding * 2 - this.innerPadding * 2) - 40;
      var blockHtml = this._getBlockTemplate(meta.blockLayout).render(new dtlContext({
        items: meta.items,
        itemArrangement: meta.itemArrangement,
        align: meta.align,
        adjustableWidth: meta.adjustableWidth,
        calculatedWidth: calculatedWidth
      }));
      return {
        blockHtml: blockHtml
      };
    },
    _getBlockTemplate: function (layout) {
      switch (layout) {
        case 'vertical':
          return this.verticalTemplate;
        case 'horizontal':
        default:
          return this.horizontalTemplate;
      }
    },
    _getShareUrl: function (meta) {
      switch (meta.shareContentSource) {
        case 'custom':
          return encodeURIComponent(meta.customUrl);
          break;
        case 'campaign':
        default:
          return '*|URL:ARCHIVE_LINK_SHORT|*';
      }
    },
    _setDefaultText: function (item) {
      if (!item.text) {
        var text = this.defaultText[item.type];
        if (!text) text = "Share";
        item.text = text;
      }
    },
    _getDescription: function (meta) {
      switch (meta.shareContentSource) {
        case 'custom':
          return encodeURIComponent(meta.customDescription);
          break;
        case 'campaign':
        default:
          return '*|URL:MC_SUBJECT|*';
      }
    },
    _buildShareUrl: function (item, sharedUrl, description) {
      var baseUrl, builtUrl;
      // I am not passing the sharedUrl variable to objectQueryToParams because it
      // will URL Encode the *|ARCHIVE|* merge tag and cause it not to render properly
      switch (item.type) {
        case 'facebookShare':
          builtUrl = 'http://www.facebook.com/sharer/sharer.php?u=' + sharedUrl;
          break;
        case 'twitterShare':
          baseUrl = 'http://twitter.com/intent/tweet';
          var twitterStatus = description + ': ' + sharedUrl;
          builtUrl = baseUrl + '?text=' + twitterStatus;
          break;
        case 'linkedInShare':
          builtUrl = 'http://www.linkedin.com/shareArticle?url=' + sharedUrl + '&mini=true&title=' + description;
          break;
        case 'pinterestShare':
          builtUrl = 'https://www.pinterest.com/pin/find/?url=' + sharedUrl;
          break;
        case 'forwardToFriend':
          builtUrl = '*|FORWARD|*';
          break;
        case 'googlePlusShare':
          baseUrl = 'https://plus.google.com/share?url=' + sharedUrl;
          builtUrl = baseUrl;
          break;
        case 'instapaperShare':
          builtUrl = 'http://www.instapaper.com/hello2?url=' + sharedUrl + '&title=' + description;
          break;
        default:
          builtUrl = 'link';
          break;
      }
      item.link = builtUrl;
    },
    _getServiceIconUrl: function (item, iconStyle, iconType) {
      var iconName;
      var urlPrefix = 'http://cdn-images.mailchimp.com/icons/social-block-v2/';
      var urlSuffix = '.png';
      switch (item.type) {
        case 'facebookShare':
          iconName = 'facebook';
          break;
        case 'twitterShare':
          iconName = 'twitter';
          break;
        case 'linkedInShare':
          iconName = 'linkedin';
          break;
        case 'pinterestShare':
          iconName = 'pinterest';
          break;
        case 'instapaperShare':
          iconName = 'instapaper';
          break;
        case 'googlePlusShare':
          iconName = 'googleplus';
          break;
        case 'forwardToFriend':
          iconName = 'forwardtofriend';
          break;
        default:
          iconName = 'link';
          break;
      }
      var iconTypePrefix = '';
      switch (iconType) {
        case "outline":
          iconTypePrefix = iconType + "-";
          break;
      }
      var url = urlPrefix + iconTypePrefix + iconStyle + '-' + iconName + '-48' + urlSuffix;
      item.iconUrl = mUrl.addProxy(url);
    }
  });
});