define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/user", "mojo/lib/flags"], function (declare, xhr, mUrl, user, flags) {
  var PromoCode = declare(null, {
    constructor: function (properties) {
      this.code = properties.code;
      this.promoCodeId = properties.promo_code_id;
      this.promoRuleId = properties.promo_rule_id;
    }
  });
  return {
    fetchByStoreId: function (storeId) {
      return xhr.get(mUrl.toUrl("/ecommerce/get-promo-codes"), {
        handleAs: "json",
        query: {
          store_id: storeId
        }
      }).then(function (data) {
        var promoCodeData = data.promos;
        return promoCodeData.map(function (store) {
          return new PromoCode(store);
        });
      });
    }
  };
});