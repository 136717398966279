define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/context", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, base, context, EcsTracking, EcsEvents) {
  return declare([base], {
    entrypoint: "",
    onReady: function () {
      if (context.flagIsOn("identity_bridge.forgot_password_ecs")) {
        this._fireUsernameChallengeLandedECSEvent();
      }
    },
    // fire ECS event for when user lands on login page
    _fireUsernameChallengeLandedECSEvent: function () {
      EcsTracking.track(EcsEvents.UsernameChallengeLandedEvent, {
        entry_point: this.entrypoint
      });
    }
  });
});