define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dijit/Dialog", "mojo/app/campaigns", "mojo/social-post/widgets/SocialPlatformConstants", "mojo/context", "mojo/lib/flags", "mojo/lib/logger", "mojo/utils", "mojo/utils/date", "mojo/utils/string", "mojo/utils/I18nTranslation", "./Slat", "dojo/text!./templates/CampaignPauseSocialDialog.html", "mojo/user"], function (declare, lang, entities, Dialog, campaigns, SocialPlatformConstants, context, flags, logger, utils, dateUtils, stringUtils, I18nTranslation, Slat, pauseDialogTemplate, user) {
  var statusContent = {
    "draft": {
      label: "Draft",
      badge: "",
      description: "Edited <strong>{updatedAt}</strong>"
    },
    "complete": {
      label: "Published",
      badge: "success",
      description: "Published <strong>{postedAt}</strong>"
    },
    "paused": {
      label: "Paused",
      badge: "warning",
      description: "Paused on <strong>{updatedAt}</strong>"
    },
    "scheduled": {
      label: "Scheduled",
      badge: "warning",
      description: "Scheduled for <strong>{scheduledSendTime}</strong>"
    },
    "pending": {
      label: "Pending",
      badge: "warning",
      description: ""
    }
  };
  var SocialSlat = declare([Slat], {
    channelIcon: "c-channelIcon--social",
    statusContent: statusContent,
    statsOrder: ["reach", "impressions", "engagements"],
    statsContent: {
      "reach": {
        label: "Reach"
      },
      "impressions": {
        label: "Impressions"
      },
      "engagements": {
        label: "Engagements"
      }
    },
    typeContent: {
      "social": {
        label: "Social Post"
      }
    },
    actions: {
      "edit": {
        label: "Edit"
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
        }
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "view": {
        label: "View Social Post"
      },
      "pause": {
        label: "Pause & Edit",
        onClick: function () {
          var dialog = new Dialog({
            "title": "Pause your post?",
            "content": utils.compileTemplate(pauseDialogTemplate, {
              campaignName: this.model.title
            }),
            onHide: function () {
              dialog.destroyRecursive();
            }
          });
          dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
            window.location.href = "/social-posts/pause?id=" + this.model.socialId;
          }.bind(this));
          dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
            dialog.hide();
          });
          dialog.show();
        }
      },
      "replicate": {
        label: "Replicate"
      }
    },
    statusContentTranslated: {},
    statsContentTranslated: {},
    typeContentTranslated: {},
    actionsTranslated: {},
    /*
    * Displays a general error message in the slat if any of the
    * posts attached to this social post report did not send
    * (for example: if FB succeeded and IG failed this would
    * return an error)
    */
    _buildSendingErrorMessage: function () {
      if (this.model.hasFailedPost && this.statusContent[this.model.status].label === "Published") {
        this.node_sendErrorMessage.appendChild(utils.compileTemplate(this.template_sendErrorMessage, {
          message: "There was an issue with your campaign."
        }));
      }
      if (this.model.rawModel.failed_preflight_networks && this.model.status === "scheduled") {
        var failedNetworks = this.model.rawModel.failed_preflight_networks;
        var socialPostId = this.model.socialId;
        var failedLinks = failedNetworks.map(function (network) {
          var oAuthUrl = SocialPlatformConstants[network].OAuthUrl;
          var replaceString = oAuthUrl.replace("{socialPostId}", socialPostId);
          var compiledLink = "<a href=" + replaceString + ">" + SocialPlatformConstants[network].networkName + "</a> ";
          return compiledLink;
        });
        var icon = "<span class='freddicon warn-circle warn-circle-color padding-right--lv1'></span>";
        this.node_sendErrorMessage.innerHTML = icon + "To publish, reauthorize " + stringUtils.listify(failedLinks) + ".";
      }
    },
    buildTranslations: function (translationObject) {
      this.statusContentTranslated = {
        "draft": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_social_draft_description", ["{updatedAt}"])
        },
        "complete": {
          label: translationObject.campaigns_published,
          badge: "success",
          description: I18nTranslation.translate("campaigns_social_complete_description", ["{postedAt}"])
        },
        "paused": {
          label: translationObject.campaigns_paused,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_social_paused_description", ["{updatedAt}"])
        },
        "scheduled": {
          label: translationObject.campaigns_scheduled,
          badge: "warning",
          description: I18nTranslation.translate("campaigns_scheduled_description_postcard", ["{scheduledSendTime}"])
        },
        "pending": {
          label: translationObject.campaigns_pending,
          badge: "warning",
          description: ""
        }
      };
      this.statsContentTranslated = {
        "reach": {
          label: translationObject.campaigns_reach
        },
        "impressions": {
          label: translationObject.campaigns_impressions
        },
        "engagements": {
          label: translationObject.campaigns_engagements
        }
      };
      this.typeContentTranslated = {
        "social": {
          label: translationObject.campaigns_social_post
        }
      };
      this.actionsTranslated = {
        "edit": {
          label: translationObject.campaigns_actions_edit
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            campaigns.showRenameModal(this.model.uniqueId, entities.encode(this.model.title));
          }
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Social posts");
            }
          }
        },
        "view": {
          label: translationObject.campaigns_actions_view_social_post
        },
        "pause": {
          label: translationObject.campaigns_actions_pause_and_edit,
          onClick: function () {
            var dialog = new Dialog({
              "title": translationObject.campaigns_actions_pause_social_title,
              "content": utils.compileTemplate(pauseDialogTemplate, {
                campaignName: this.model.title
              }),
              onHide: function () {
                dialog.destroyRecursive();
              }
            });
            dialog.domNode.querySelector("[data-dojo-attach-point='pauseButton']").addEventListener("click", function () {
              window.location.href = "/social-posts/pause?id=" + this.model.socialId;
            }.bind(this));
            dialog.domNode.querySelector("[data-dojo-attach-point='cancelButton']").addEventListener("click", function () {
              dialog.hide();
            });
            dialog.show();
          }
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate
        }
      };
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaData: function () {
      if (this.statusContent[this.model.status]) {
        var template = this.statusContent[this.model.status].description;
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "postedAt":
            case "updatedAt":
            case "scheduledSendTime":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
      if (this.model.rawModel.intended_social_platforms) {
        var socialPlatforms = this.model.rawModel.intended_social_platforms;
        var platformsList = socialPlatforms.length > 0 ? "Social Post - " + socialPlatforms : "Social Post";
        this.node_typeName.innerHTML = platformsList;
      }
    },
    /**
     * @override Slat
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.statusContentTranslated[this.model.status]) {
        var template = this.statusContentTranslated[this.model.status].description;
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "postedAt":
            case "updatedAt":
            case "scheduledSendTime":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
      if (this.model.rawModel.intended_social_platforms) {
        var socialPlatforms = this.model.rawModel.intended_social_platforms;
        var platformsList = socialPlatforms.length > 0 ? "Social Post - " + socialPlatforms : "Social Post";
        this.node_typeName.innerHTML = platformsList;
      }
    }
  });
  SocialSlat.STATUS_CONTENT = statusContent;
  return SocialSlat;
});