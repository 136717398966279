define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/InlineToast.html"], function (declare, lang, Deferred, domClass, domStyle, velocity, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    message: "",
    variant: "dark",
    postCreate: function () {
      if (this.variant === "light") {
        domClass.add(this.inlineToast, "c-inlineToast--light");
      } else {
        domClass.add(this.inlineToast, "c-inlineToast--dark");
      }
    },
    showToast: function () {
      velocity(this.inlineToast, "stop");
      var isToastVisible = domStyle.get(this.inlineToast, "opacity") === "1";

      // Run the entire animation
      if (!isToastVisible) {
        this._animPopIn(this.inlineToast).then(lang.hitch(this, function () {
          this._animFadeOut(this.inlineToast);
        }));
        // Reset the delay and then fade out
      } else {
        this._animFadeOut(this.inlineToast);
      }
    },
    _animPopIn: function (element) {
      var promise = new Deferred();
      velocity(element, {
        opacity: ["1.0", "easeinout", "0"],
        translateY: ["0", "easeinout", "12px"]
      }, {
        "delay": 0,
        "duration": 200,
        "complete": function () {
          promise.resolve();
        }
      });
      return promise;
    },
    _animFadeOut: function (element) {
      var promise = new Deferred();
      velocity(element, {
        "opacity": 0
      }, {
        "delay": 3000,
        "duration": 400,
        "complete": function () {
          promise.resolve();
        }
      });
      return promise;
    }
  });
});