/**
 * @class mojo.report.widgets.StatsTable
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/StatsTable.html", "./StatsTableEntries", "./LinksEntry", "vendor/lodash-amd/every"], function (declare, Renderable, tpl, Entries, Links, every) {
  return declare([Renderable], {
    templateString: tpl,
    /**
     * @param {{children: mojo.report.widgets.StatsTableEntries[], title: string}} props Properties to build widget with
     */
    constructor: function (props) {
      var title = props.title;
      if (title && typeof title !== "string") {
        throw new Error("`title` prop must be of type `string`");
      }
      this.title = title;
      var listOfEntries = props.children;
      if (Array.isArray(listOfEntries) === false) {
        throw new Error("Must provide `children` prop with type `array`");
      }
      var isValid = every(listOfEntries, function (set) {
        return set instanceof Entries || set instanceof Links;
      });
      if (isValid === false) {
        throw new Error("`children` prop must be array of `mojo.report.widgets.StatsTableEntries`");
      }
      this.children = listOfEntries;
    },
    render: function () {
      this.inherited(arguments);
      var children = this.children;
      var midpoint = Math.floor(children.length / 2);
      var firstHalf = children.slice(0, midpoint);
      var secondHalf = children.slice(midpoint, children.length);
      this._renderChildrenToContainer(firstHalf, this.leftContainer);
      this._renderChildrenToContainer(secondHalf, this.rightContainer);
    },
    _renderChildrenToContainer: function (children, container) {
      children.map(function (child) {
        child.placeAt(container);
      });
    }
  });
});