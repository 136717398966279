define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/form/Select", "mojo/validation/Error", "mojo/context", "mojo/lib/flags", "dojo/text!./templates/FeedbackBlock.html", "vendor/lodash-amd/template", "vendor/lodash-amd/isEmpty"], function (declare, lang, html, domConstruct, when, query, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, Select, ValidationError, context, flags, feedbackBlockTemplate, template, isEmpty) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: '<div><span role="presentation" aria-hidden="true" data-dojo-type="mojo/widgets/CircularLoader" data-dojo-props="loaderSize:20, strokeWidth: 3" class="border-radius--lv1"></span></div>',
    noOptionsTemplate: template(feedbackBlockTemplate),
    validationErrorTemplate: template(feedbackBlockTemplate),
    detailedOptions: [],
    options: [],
    validators: [],
    mcDefaultValue: null,
    filter: false,
    postCreate: function () {
      this.inherited(arguments);
      this.optionsRequest = when(this.getOptions()).then(this.mapOptions.bind(this)).then(this.render.bind(this));
    },
    mapOptions: function (options) {
      this.detailedOptions = options;
      this.options = options.map(function (option, i) {
        return {
          label: option[this.labelKey],
          value: option[this.valueKey],
          disabled: this.filter ? !this.filter(option) : false
        };
      }, this).sort(function (option) {
        return option.disabled ? 1 : -1;
      });
      return this.options;
    },
    getValue: function () {
      if (!this.select) {
        return this.mcDefaultValue;
      }
      return this.select.get("value");
    },
    isValid: function () {
      var value = this.getValue();
      return this.validators.reduce(function (acc, validator) {
        return validator(value, this) && acc;
      }.bind(this), true);
    },
    toggleValidationError: function (shouldShow, templateValues) {
      var oldError = this.domNode.querySelector(".feedback-block");
      if (oldError) {
        oldError.remove();
      }
      if (shouldShow) {
        var validationError = domConstruct.toDom(this.validationErrorTemplate(lang.mixin({}, templateValues, {
          severity: "warning",
          icon: "warn-circle"
        })));
        this.domNode.appendChild(validationError);
      }
    },
    render: function (options) {
      var selectableOptions = options.filter(function (option) {
        return !option.disabled;
      });
      var hasUnselectableOptions = selectableOptions.length < options.length;
      if (this.hideFeedback && selectableOptions.length === 0 && html) {
        return html.set(this.domNode, "");
      }
      if (selectableOptions.length === 0 && html) {
        return html.set(this.domNode, this.noOptionsTemplate(lang.mixin({}, this.noOptionsFeedback, {
          severity: "info",
          icon: "info-circle"
        })));
      }

      // show automation-title field
      query("[name=automation-title]").attr("hidden", false);
      this.select = new Select({
        name: this.name,
        labelType: "text",
        options: [{
          value: "",
          label: this.defaultLabel
        }].concat(options),
        "class": "select-small full-width",
        onChange: this.onChange
      });
      if (selectableOptions.length === 1) {
        this.select.set("value", selectableOptions[0]);
      }
      if (this.domNode) {
        domConstruct.empty(this.domNode);
        this.select.placeAt(this.domNode);
        this.select.startup();
      }
      return this;
    }
  });
});