define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/events-pipeline-beacon/bulk-email-campaign-event", "mojo/context", "mojo/app/campaigns", "mojo/lib/flags", "mojo/lib/logger", "mojo/url", "mojo/user", "mojo/utils", "mojo/utils/date", "mojo/widgets/shareOverlay/ShareOverlayCaller", "mojo/analytics/parts/SelectCheckBox", "mojo/utils/I18nTranslation", "mojo/widgets/campaigns/CampaignsRenameDialog", "./Slat", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents"], function (declare, lang, domClass, on, xhr, entities, trackBulkEmailEvent, context, campaigns, flags, logger, mUrl, user, utils, dateUtils, ShareOverlay, SelectCheckBox, I18nTranslation, CampaignsRenameDialog, Slat, EcsTracking, EcsEvents) {
  return declare([Slat], {
    channelIcon: "c-channelIcon--email",
    // Note: `statusContent` looks to be unused and replaced by `statusContentTranslated`.
    statusContent: {
      "sent": {
        label: "Sent",
        badge: "success",
        description: "Sent <strong>{sendDate}</strong> to {sends} recipients by {lastUpdatedBy}",
        timewarpDescription: "Sent <strong>{sendDate}</strong>.<br/>Completed on <strong>{lastDeliveryDate}.</strong>",
        timewarpDescriptionWithoutDelivery: "Sent <strong>{sendDate}</strong>.",
        timewarpDescriptionFuture: "Delivering at <strong>{sendDate}</strong> local time.<br/>Sending will complete on <strong>{lastDeliveryDate}</strong>"
      },
      "canceled": {
        label: "Canceled",
        badge: "error",
        description: "Canceled <strong>{canceledDate}</strong>"
      },
      "save": {
        label: "Draft",
        badge: "",
        description: "Edited <strong>{lastUpdatedDate}</strong> by {lastUpdatedBy}"
      },
      "sending": {
        label: "Sending",
        badge: "info",
        description: "<strong>Sending...</strong> ({sendPercentage}% complete)",
        loadingDescription: "<strong>Sending...</strong>"
      },
      "active": {
        label: "Sending",
        badge: "info",
        description: "Last sent on {rssLastSendDate}<br/>Sends <strong>{rssScheduleString} at {rssScheduleHour}</strong> when new posts are added",
        unsentDescription: "This campaign has not sent yet.<br/>Sends <strong>{rssScheduleString} at {rssScheduleHour}</strong> when new posts are added"
      },
      "schedule": {
        label: "Scheduled",
        badge: "info",
        description: "Scheduled for <strong>{sendDate}</strong>",
        timewarpDescription: "Timewarp scheduled for <strong>{sendDate}</strong>"
      },
      "paused": {
        label: "Paused",
        badge: "warning",
        description: "Paused <strong>{lastUpdatedDate}</strong>"
      },
      "disconnected": {
        label: "Not connected",
        badge: "warning",
        description: "Your store is not connected."
      }
    },
    typeContent: {
      "regular": {
        label: "Regular"
      },
      "rss": {
        label: "RSS"
      },
      "variate": {
        label: "Multivariate"
      },
      "absplit": {
        label: "A/B Test"
      },
      "plaintext": {
        label: "Plain Text"
      },
      "transactional": {
        label: "Order Notification"
      },
      "automation": {
        label: "Automation"
      },
      "autoresponder": {
        label: "Autoresponder"
      },
      "reconfirm": {
        label: "Reconfirmation"
      },
      "inspection": {
        label: "Inspection"
      },
      "email-touchpoint": {
        label: "Campaign Manager"
      },
      "multivariate-email-touchpoint": {
        label: "Campaign Manager · Multivariate Test"
      },
      "ab-email-touchpoint": {
        label: "Campaign Manager · A/B Test"
      }
    },
    statsOrder: ["opens", "clicks", "revenue"],
    statsContent: {
      "subscribers": {
        label: "Subscribers"
      },
      "opens": {
        label: "Opens"
      },
      "clicks": {
        label: "Clicks"
      },
      "revenue": {
        label: "Revenue"
      }
    },
    actions: {
      "edit": {
        label: "Edit",
        onMouseUp: function () {
          trackBulkEmailEvent("campaign:engaged", {
            screen: "campaigns",
            object: "campaign",
            object_detail: "edit_campaign",
            action: "engaged",
            ui_object: "button",
            ui_object_detail: "edit",
            ui_action: "clicked",
            ui_access_point: "main_content_pane",
            campaign_id: Number(this.model.campaignId),
            editor_type: this.model.editorType === "New Builder" ? "nuni" : "nea"
          });
        }.bind(this)
      },
      "view": {
        label: "View email",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "replicate": {
        label: "Replicate",
        onMouseUp: function () {
          logger.googleAnalytics.trackEvent("all campaigns", "duplicate", "email");
        }
      },
      "social": {
        label: "Social Share"
      },
      "sent": {
        label: "Sent Campaigns"
      },
      "pause": {
        label: "Pause"
      },
      "conversations": {
        label: "New Conversations"
      },
      // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
      "report": {
        label: "View Report",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "pauseandedit": {
        label: "Pause and Edit",
        onClick: function () {
          campaigns.showPauseModal(this.model.campaignId, entities.encode(this.model.title), this.model.type);
        }
      },
      "rename": {
        label: "Rename",
        onClick: function () {
          var self = this;
          var trackModalEvent = function (parameters) {
            return function () {
              trackBulkEmailEvent("campaign:engaged", lang.mixin({
                screen: "campaigns",
                object: "campaign",
                object_detail: "rename_campaign",
                action: "engaged",
                ui_object: "button",
                ui_action: "clicked",
                ui_access_point: "modal",
                campaign_id: Number(self.model.campaignId),
                editor_type: self.model.editorType === "New Builder" ? "nuni" : "nea"
              }, parameters));
            };
          };
          new CampaignsRenameDialog({
            campaignUniqueId: this.model.uniqueId,
            campaignTitle: entities.encode(this.model.title),
            onCancel: trackModalEvent({
              ui_object_detail: "close"
            }),
            onClickPrimaryAction: trackModalEvent({
              ui_object_detail: "save"
            }),
            onClickSecondaryAction: trackModalEvent({
              ui_object_detail: "cancel"
            }),
            onHide: function () {
              this.destroyRecursive();
            }
          }).show();
        },
        onMouseUp: function () {
          EcsTracking.track(EcsEvents.beginRenameEvent);
        }
      },
      "unpause": {
        label: "Unpause"
      },
      "replicatetoautomation": {
        label: "Replicate to Automation"
      },
      "resolve": {
        label: "Resolve"
      },
      "sharereport": {
        label: "Share report",
        onClick: function () {
          this._launchShareOverlay();
        },
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "sharecampaign": {
        label: "Share campaign",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "download": {
        label: "Download",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "print": {
        label: "Print",
        onMouseUp: function (action, label, dimensions) {
          logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
        }
      },
      "manageemail": {
        label: "Manage email"
      }
    },
    statusContentTranslated: {},
    typeContentTranslated: {},
    statsContentTranslated: {},
    actionsTranslated: {},
    replicationRequestStatus: null,
    /**
     * @override Slat
     */
    postMixInProperties: function () {
      this.inherited(arguments);
      var rssAction = this.actions["sent"]; // Grab the RSS Campaign Action
      var campaignTypeIsRss = this.model.type === "rss"; // Check if the type is RSS
      var touchpointAction = this.actions["manageemail"];
      var touchpointTypeIsMultivariate = this.model.type === "multivariate-email-touchpoint" || this.model.type === "ab-email-touchpoint";

      // Check to see if we are on the reports page and change the label from Sent Campaigns ==> View Report for RSS campaigns
      if (this._isReportsPage() && campaignTypeIsRss) {
        rssAction.label = "View Report";
      }
      if (touchpointTypeIsMultivariate) {
        touchpointAction.label = "Manage test";
      } else {
        touchpointAction.label = "Manage email";
      }
      if (this.campaignListLocation && this.model.availableActions) {
        for (var i = 0; i < this.model.availableActions.length; i++) {
          if (this.model.availableActions[i].label === "replicate") {
            var availableActionHref = this.model.availableActions[i].href;
            availableActionHref += availableActionHref.indexOf("?") < 0 ? "?" : "&";
            availableActionHref += "co=" + this.campaignListLocation;

            // Temporary search param to be removed with aut-4547-campaign-replicate-flow-intercept
            availableActionHref += "&source=all-campaigns";
            this.model.availableActions[i].href = availableActionHref;
          }
        }
      }
    },
    /**
     * @override Slat
     */
    _buildTypeName: function () {
      var type = this.model.type;
      switch (type) {
        case "transactional":
        case "rss":
          var templateIcon = this.template_typeIcon.innerHTML;
          this.node_typeName.innerHTML = this.typeContent[type] ? templateIcon + this.typeContent[type].label : "";
          break;
        default:
          this.inherited(arguments);
      }
    },
    /**
     * @override Slat
     */
    _buildTypeNameTranslated: function () {
      var type = this.model.type;
      switch (type) {
        case "transactional":
        case "rss":
          var templateIcon = this.template_typeIcon.innerHTML;
          this.node_typeName.innerHTML = this.typeContentTranslated[type] ? templateIcon + this.typeContentTranslated[type].label : "";
          break;
        default:
          this.inherited(arguments);
      }
    },
    /**
     * When a user selects the Share Report action from the dropdown launch the
     * fullscreen Share Overlay. Method filters out the share report object from all
     * available report actions
     *
     * @private
     */
    _launchShareOverlay: function () {
      // Filter out the share report object from the available actions array
      var shareReportObject = this.model.availableReportActions.filter(function (action) {
        return action.label === "sharereport";
      });

      // Grab all the props from the share overlay object
      var shareOverlayProps = shareReportObject[0].share_overlay;
      var shareOverlay = new ShareOverlay({
        viewType: "report",
        assetId: shareOverlayProps.campaignId,
        title: shareOverlayProps.campaignTitle,
        sentTime: shareOverlayProps.sendTime,
        shareUrl: shareOverlayProps.shareUrl,
        enableEmailSharing: true,
        enablePassword: {
          required: true
        }
      });

      // Open the Share Overlay
      shareOverlay.openOverlay();
    },
    /**
     * override
     *
     * @param {object} translationObject i18n object
     */
    buildTranslations: function (translationObject) {
      this.typeContentTranslated = {
        "regular": {
          label: translationObject.campaigns_regular
        },
        "rss": {
          label: translationObject.campaigns_rss
        },
        "variate": {
          label: translationObject.campaigns_variate
        },
        "absplit": {
          label: translationObject.campaigns_absplit
        },
        "plaintext": {
          label: translationObject.campaigns_plain_text_label
        },
        "transactional": {
          label: translationObject.campaigns_transactional
        },
        "automation": {
          label: translationObject.campaigns_automation
        },
        "autoresponder": {
          label: translationObject.campaigns_autoresponder
        },
        "reconfirm": {
          label: translationObject.campaigns_reconfirm
        },
        "inspection": {
          label: translationObject.campaigns_inspection
        },
        "email-touchpoint": {
          label: translationObject.campaigns_email_touchpoint
        },
        "multivariate-email-touchpoint": {
          label: translationObject.campaigns_multivariate_email_touchpoint
        },
        "ab-email-touchpoint": {
          label: translationObject.campaigns_ab_email_touchpoint
        }
      };
      var sentDescription = I18nTranslation.translate("campaigns_sent_description_edited", ["{sendDate}", "{sends}", "{lastUpdatedBy}", "{lastUpdatedDate}", "{lastUpdatedBy}"]);
      var scheduledDescription = I18nTranslation.translate("campaigns_scheduled_description_with_edited", ["{sendDate}", "{lastUpdatedDate}", "{lastUpdatedBy}"]);
      var scheduledTimewarpDescription = I18nTranslation.translate("campaigns_scheduled_timewarp_description_with_edited", ["{sendDate}", "{lastUpdatedDate}", "{lastUpdatedBy}"]);
      var pausedDescription = I18nTranslation.translate("campaigns_paused_description_with_edited", ["{lastUpdatedDate}", "{lastUpdatedBy}"]);
      this.statusContentTranslated = {
        "sent": {
          label: translationObject.campaigns_sent,
          badge: "success",
          description: sentDescription,
          timewarpDescription: I18nTranslation.translate("campaigns_sent_timewarp_description", ["{sendDate}", "{lastDeliveryDate}"]),
          timewarpDescriptionWithoutDelivery: I18nTranslation.translate("campaigns_sent_timewarp_description_no_delivery", ["{sendDate}"]),
          timewarpDescriptionFuture: I18nTranslation.translate("campaigns_sent_timewarp_description_future", ["{sendDate}", "{lastDeliveryDate}"])
        },
        "canceled": {
          label: translationObject.campaigns_canceled,
          badge: "error",
          description: I18nTranslation.translate("campaigns_canceled_description", ["{canceledDate}"])
        },
        "save": {
          label: translationObject.campaigns_draft,
          badge: "",
          description: I18nTranslation.translate("campaigns_edited_by_you", ["{lastUpdatedDate}", "{lastUpdatedBy}"])
        },
        "sending": {
          label: translationObject.campaigns_sending,
          badge: "info",
          description: I18nTranslation.translate("campaigns_sending_description", ["{sendPercentage}"]),
          loadingDescription: translationObject.campaigns_sending_loading_description
        },
        "active": {
          label: translationObject.campaigns_sending,
          badge: "info",
          description: I18nTranslation.translate("campaigns_active_description", ["{rssLastSendDate}", "{rssScheduleString}", "{rssScheduleHour}"]),
          unsentDescription: I18nTranslation.translate("campaigns_unsent_description", ["{rssScheduleString}", "{rssScheduleHour}"])
        },
        "schedule": {
          label: translationObject.campaigns_scheduled,
          badge: "info",
          description: scheduledDescription,
          timewarpDescription: scheduledTimewarpDescription
        },
        "paused": {
          label: translationObject.campaigns_paused,
          badge: "warning",
          description: pausedDescription
        },
        "disconnected": {
          label: translationObject.campaigns_disconnected,
          badge: "warning",
          description: translationObject.campaigns_disconnected_description
        }
      };
      this.statsContentTranslated = {
        "subscribers": {
          label: translationObject.campaigns_subscribers
        },
        "opens": {
          label: translationObject.campaigns_opens
        },
        "clicks": {
          label: translationObject.campaigns_clicks
        },
        "revenue": {
          label: translationObject.campaigns_revenue
        }
      };
      this.actionsTranslated = {
        "edit": {
          label: translationObject.campaigns_actions_edit,
          onMouseUp: function () {
            var params = {
              screen: "campaigns",
              object: "campaign",
              object_detail: "edit_campaign",
              action: "engaged",
              ui_object: "button",
              ui_object_detail: "edit",
              ui_action: "clicked",
              ui_access_point: "main_content_pane",
              campaign_id: Number(this.model.campaignId),
              editor_type: this.model.editorType === "New Builder" ? "nuni" : "nea"
            };
            if (user.flagIsOn(flags.GEN_CAMPAIGN_CREATED_ENGAGED_GEN_TAB_TRACKING)) {
              var hasEligiblePlan = user.hasLegacyPaidPlan || user.hasStandardMonthlyPlan || user.hasPremiumMonthlyPlan;
              params.is_gentab_eligible = hasEligiblePlan && (!user.flagIsOn(flags.CONTENT_ENGINE_INLINE_REWRITE) || !user.canAccessContentGen);
              params.inline_eligible = user.canAccessContentGen;
            }
            this._trackEditCampaignEvent(params.campaign_id, params.editor_type, params.is_gentab_eligible || false);
          }.bind(this)
        },
        "view": {
          label: translationObject.campaigns_actions_view_email,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            EcsTracking.track(EcsEvents.AllCampaignsViewEmailEvent);
          }
        },
        "replicate": {
          label: translationObject.campaigns_actions_replicate,
          onMouseUp: function () {
            logger.googleAnalytics.trackEvent("all campaigns", "duplicate", "email");
          }
        },
        "social": {
          label: translationObject.campaigns_actions_social_share,
          onMouseUp: function () {
            campaigns.trackSlat("social_share", true, "Email");
          }
        },
        "sent": {
          label: translationObject.campaigns_actions_sent_campaigns
        },
        "pause": {
          label: translationObject.campaigns_actions_pause
        },
        "conversations": {
          label: translationObject.campaigns_actions_new_conversations
        },
        // onMouseUp is going to be used for all analytics calls in order to bypass how React is handling <a></a> tags inside of the dojo iframe.
        "report": {
          label: translationObject.campaigns_actions_view_report,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
            if (action === "view report") {
              campaigns.trackSlat("view_report", true, "Email");
            }
          }
        },
        "pauseandedit": {
          label: translationObject.campaigns_actions_pause_and_edit,
          onClick: function () {
            campaigns.showPauseModal(this.model.campaignId, entities.encode(this.model.title), this.model.type);
          },
          onMouseUp: function () {
            EcsTracking.track(EcsEvents.pauseCampaignEvent);
          }
        },
        "rename": {
          label: translationObject.campaigns_actions_rename,
          onClick: function () {
            var self = this;
            var trackModalEvent = function (parameters) {
              return function () {
                EcsTracking.track(EcsEvents.completeOrCancelRenameEvent, {
                  campaign_id: Number(self.model.campaignId),
                  editor_type: self.model.editorType === "New Builder" ? "nuni" : "nea"
                });
              };
            };
            new CampaignsRenameDialog({
              campaignUniqueId: this.model.uniqueId,
              campaignTitle: entities.encode(this.model.title),
              onCancel: trackModalEvent({
                ui_object_detail: "close"
              }),
              onClickPrimaryAction: trackModalEvent({
                ui_object_detail: "save"
              }),
              onClickSecondaryAction: trackModalEvent({
                ui_object_detail: "cancel"
              }),
              onHide: function () {
                this.destroyRecursive();
              }
            }).show();
          },
          onMouseUp: function () {
            EcsTracking.track(EcsEvents.beginRenameEvent, {
              campaign_id: Number(self.model.campaignId),
              editor_type: self.model.editorType === "New Builder" ? "nuni" : "nea"
            });
          }
        },
        "unpause": {
          label: translationObject.campaigns_actions_unpause
        },
        "replicatetoautomation": {
          label: translationObject.campaigns_actions_replicate_to_automation
        },
        "resolve": {
          label: translationObject.campaigns_actions_resolve
        },
        "sharereport": {
          label: translationObject.campaigns_actions_share_report,
          onClick: function () {
            this._launchShareOverlay();
          },
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
          }
        },
        "sharecampaign": {
          label: translationObject.campaigns_actions_share_campaigns,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
          }
        },
        "download": {
          label: translationObject.campaigns_actions_download,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
          }
        },
        "print": {
          label: translationObject.campaigns_actions_print,
          onMouseUp: function (action, label, dimensions) {
            logger.googleAnalytics.trackEvent("reports", action, label, dimensions);
          }
        },
        "manageemail": {
          label: translationObject.campaigns_actions_manage_email
        }
      };
      this.campaignReplicationFailureTranslated = translationObject.campaigns_replication_failure_error_message;
    },
    /**
     * override
     */
    buildAdditionalMetaData: function () {
      if (this.statusContent[this.model.status]) {
        var template = this.statusContent[this.model.status].description;
        if (this.model.isTimewarp && this.model.status === "sent") {
          var today = new Date();
          if (this.model.lastDeliveryDate) {
            template = this.model.lastDeliveryDate > today ? this.statusContent[this.model.status].timewarpDescriptionFuture : this.statusContent[this.model.status].timewarpDescription;
          } else {
            template = this.statusContent[this.model.status].timewarpDescriptionWithoutDelivery;
          }
        } else if (this.model.isTimewarp && this.statusContent[this.model.status].timewarpDescription) {
          template = this.statusContent[this.model.status].timewarpDescription;
        } else if (this.model.status === "active" && !this.model.rssLastSendDate) {
          template = this.statusContent[this.model.status].unsentDescription;
        } else if (this.model.status === "sending") {
          if (this.model.showSendPercentage) {
            // Show a placeholder template and fetch the sending percentage asynchronously
            template = this.statusContent.sending.loadingDescription;
            xhr.get(mUrl.toUrl("campaigns/get-delivery-status"), {
              handleAs: "json",
              query: {
                id: this.model.campaignId
              }
            }).then(function (response) {
              if (!this.node_additionalMetaData) {
                return;
              }
              if (response.success && response.data) {
                this.node_additionalMetaData.innerHTML = lang.replace(this.statusContent.sending.description, {
                  sendPercentage: response.data.percentComplete
                });
              }
            }.bind(this));
          } else {
            template = this.statusContent.save.description;
          }
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "sends":
              return this.model.stats && this.model.stats.subscribers ? utils.getThousandsString(this.model.stats.subscribers && this.model.stats.subscribers.value || 0) : "";
            case "lastUpdatedDate":
            case "sendDate":
            case "canceledDate":
            case "lastDeliveryDate":
            case "rssLastSendDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              //can't just check falseyness here because 0 is valid
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildAdditionalMetaDataTranslated: function () {
      if (this.statusContentTranslated[this.model.status]) {
        var template = this.statusContentTranslated[this.model.status].description;
        if (this.model.isTimewarp && this.model.status === "sent") {
          var today = new Date();
          if (this.model.lastDeliveryDate) {
            template = this.model.lastDeliveryDate > today ? this.statusContentTranslated[this.model.status].timewarpDescriptionFuture : this.statusContentTranslated[this.model.status].timewarpDescription;
          } else {
            template = this.statusContentTranslated[this.model.status].timewarpDescriptionWithoutDelivery;
          }
        } else if (this.model.isTimewarp && this.statusContentTranslated[this.model.status].timewarpDescription) {
          template = this.statusContentTranslated[this.model.status].timewarpDescription;
        } else if (this.model.status === "active" && !this.model.rssLastSendDate) {
          template = this.statusContentTranslated[this.model.status].unsentDescription;
        } else if (this.model.status === "sending") {
          if (this.model.showSendPercentage) {
            // Show a placeholder template and fetch the sending percentage asynchronously
            template = this.statusContentTranslated.sending.loadingDescription;
            xhr.get(mUrl.toUrl("campaigns/get-delivery-status"), {
              handleAs: "json",
              query: {
                id: this.model.campaignId
              }
            }).then(function (response) {
              if (!this.node_additionalMetaData) {
                return;
              }
              if (response.success && response.data) {
                this.node_additionalMetaData.innerHTML = lang.replace(this.statusContentTranslated.sending.description, {
                  sendPercentage: response.data.percentComplete
                });
              }
            }.bind(this));
          } else {
            template = this.statusContentTranslated.save.description;
          }
        }
        this.node_additionalMetaData.innerHTML = lang.replace(template || "", lang.hitch(this, function (_, key) {
          switch (key) {
            case "sends":
              return this.model.stats && this.model.stats.subscribers ? utils.getThousandsString(this.model.stats.subscribers && this.model.stats.subscribers.value || 0) : "";
            case "lastUpdatedDate":
            case "sendDate":
            case "canceledDate":
            case "lastDeliveryDate":
            case "rssLastSendDate":
              if (!this.model[key]) {
                return "";
              }
              // dates are now objects
              if (this.model[key] && this.model[key] instanceof Object && this.model[key].hasOwnProperty("jsDate")) {
                var jsDate = this.model[key].jsDate;
                return context.flagIsOn(flags.I18N_DATE_REPORTS) ? I18nTranslation.getLocalizedDate(jsDate, "full", true) : dateUtils.formatDateTime(jsDate, dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
              }
              return dateUtils.getDateString(this.model[key]);
            default:
              //can't just check falseyness here because 0 is valid
              return this.model[key] !== undefined && this.model[key] !== null ? this.model[key] : "";
          }
        }));
      }
    },
    /**
     * override
     */
    buildListName: function () {
      var listName = this.model.storeName || this.model.listName;
      var snapshotDate = this.model.snapshotDate;
      if (snapshotDate instanceof Object && snapshotDate.hasOwnProperty("jsDate")) {
        snapshotDate = dateUtils.formatDateTime(snapshotDate["jsDate"], dateUtils.DATE_STRING_FORMAT_NAMES.DAYNAME_MONTH_DAY_YEAR_TIME);
      }
      this.node_listName.appendChild(utils.compileTemplate(listName ? this.template_listName : "", {
        listName: listName,
        segmentName: this.model.hasSegment && this.model.segmentDisplay ? this.model.segmentDisplay : "",
        snapshotDate: this.model.snapshotDate ? snapshotDate : "",
        snapshotDateClass: this.model.snapshotDate ? "" : "hide"
      }));
    },
    /**
     * Prevent the checkbox from rendering for ACP email touchpoints
     *
     * @Override Slat
     */
    _buildCheckbox: function () {
      var checkbox = new SelectCheckBox({
        value: this.model.uniqueId
      });
      checkbox.placeAt(this.node_selectCheckBox, "replace");
      on(checkbox, "click", lang.hitch(this, function () {
        EcsTracking.track(EcsEvents.AllCampaignsBulkActionCheckBoxEvent, {
          touchpoint_type: "Email"
        });
      }));
    },
    _trackEditCampaignEvent: function (campaign_id, editor_type, is_gentab_eligible) {
      EcsTracking.track(EcsEvents.TrackEditCampaignEvent, {
        campaign_id: campaign_id,
        editor_type: editor_type,
        is_gentab_eligible: is_gentab_eligible
      });
    }
  });
});