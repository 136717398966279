/**
 * Event Tracking Widget
 * @see Confluence Page to break down tracking - https://confluence.mailchimp.com/display/AT/How+the+Automation+team+uses+Google+Analytics+Tracking
 *
 * Widget to track Google Analytics events on click of button or link. Also handles prevent duplicate request and clicks
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "dijit/_WidgetBase"], function (declare, lang, on, domClass, mUtils, _WidgetBase) {
  return declare([_WidgetBase], {
    // Path where the event was triggered, uses window.location.pathname by default.
    // Example: "list/members"
    eventCategory: "",
    // What the user actually did. *Required on implementation
    // Example: "Clicked To Setup 1cw Automation"
    eventAction: "",
    // More descriptive label for the track-able event. *Required on implementation
    // Example: "after creating audience"
    eventLabel: "",
    /**
     * Run this at widget creation time, making sure that we have all of the props we need.
     * Also gives the eventCategory a default value if not implemented
     */
    postMixInProperties: function () {
      this._validateRequiredProps();

      // If there isn't a custom eventCategory defined use the window location
      this.eventCategory = !this.eventCategory ? window.location.pathname : this.eventCategory;
    },
    postCreate: function () {
      // Uses dojo once to prevent multiple requests
      on.once(this, "click", lang.hitch(this, "_sendGATrackingInfo"));
    },
    /**
     * Sends Google Analytics data for tracking and also handles providing button feedback while
     * making the request or re-directing
     *
     * @param {Event} e - Mouse Event on click of button
     * @private
     */
    _sendGATrackingInfo: function (e) {
      // Check to see if link is a button style
      var isButtonLink = e.target.tagName === "A" && e.target.classList.contains("button");

      // Disable the UI for feedback
      domClass.add(e.target, "disabled-container");

      // Add a loading indicator if the element is a button link or button
      if (isButtonLink || e.target.tagName === "BUTTON") {
        domClass.add(e.target, "button-loading");
      }

      // Track the events in GA
      mUtils.trackEvent({
        eventCategory: this.eventCategory,
        eventAction: this.eventAction,
        eventLabel: this.eventLabel
      });
    },
    /**
     * There are properties that will need to be configured on the widget. Throws error if needs
     * aren't met.
     *
     * @private
     */
    _validateRequiredProps: function () {
      if (!this.eventAction) {
        throw new Error("Event Action unimplemented. It is required");
      }
      if (!this.eventLabel) {
        throw new Error("Event Label unimplemented. It is required");
      }
      if (typeof this.eventAction !== "string") {
        throw new Error("Event Action was implemented, but is a " + typeof this.eventAction + " and not a string.");
      }
      if (typeof this.eventLabel !== "string") {
        throw new Error("Event Label was implemented, but is a " + typeof this.eventLabel + " and not a string.");
      }
    }
  });
});