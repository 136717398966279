/**
 * Enable signup form submission to validate
 * password. If no errors exist, return null. Else, return error.
 * SMU-107: Do not refresh the sign up page when Sign Up is clicked
 *
 * @param {string} str password input field error value.
 */

define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags"], function (query, domClass, dom, context, flags) {
  return function validatePassword(str) {
    // At least one LOWERCASE character:
    var LOWER_CASE_REGEX = /^(?=.*[a-z]).+$/;
    // At least one UPPERCASE character:
    var UPPER_CASE_REGEX = /^(?=.*[A-Z]).+$/;
    // At least one NUMBER:
    var NUMBER_REGEX = /^(?=.*[\d]).+$/;
    // At least one SPECIAL character:
    var SPECIAL_CHARACTER_REGEX = /([-+=_!@#$%^&*.,;:'\"<>/?`~\[\]\(\)\{\}\\\|\s])/;
    // At least 8 characters in the screen:
    var CHARACTER_COUNT_REGEX = /^.{8,}/;
    // Flag to keep track if password is less than 50 characters:
    var MAX_LENGTH = 50;
    var passReq = query(".password-requirements")[0];
    var isValid = true;
    var usernamePattern = dom.byId("new_username").value;
    var usernameChar = query(".username-check")[0];
    var isUsernameInPassword = true;
    var createBtnEnabled = dom.byId("create-account-enabled");
    //Check if password is in username
    if (usernamePattern === null || usernamePattern === "") {
      if (createBtnEnabled) {
        domClass.replace(usernameChar, "completed", "not-completed");
      } else {
        domClass.add(usernameChar, "completed");
      }
      isUsernameInPassword = true;
    } else if (usernamePattern.toUpperCase().includes(str.toUpperCase()) || str.toUpperCase().includes(usernamePattern.toUpperCase())) {
      if (createBtnEnabled) {
        domClass.remove(passReq, "hide");
        domClass.add(passReq, "open");
        domClass.replace(usernameChar, "not-completed", "completed");
      } else {
        domClass.remove(passReq, "hide");
        domClass.remove(usernameChar, "completed");
      }
      isUsernameInPassword = false;
    } else {
      if (createBtnEnabled) {
        domClass.add(passReq, "hide");
        domClass.replace(usernameChar, "completed", "not-completed");
      } else {
        domClass.add(usernameChar, "completed");
      }
      isUsernameInPassword = true;
    }
    isValid = LOWER_CASE_REGEX.test(str) && UPPER_CASE_REGEX.test(str) && NUMBER_REGEX.test(str) && SPECIAL_CHARACTER_REGEX.test(str) && CHARACTER_COUNT_REGEX.test(str) && isUsernameInPassword && str.length < MAX_LENGTH;
    if (!isValid) {
      domClass.remove(passReq, "hide");
      domClass.add(passReq, "open");
      domClass.add(passReq, "show-errors");
      return new Error(" ");
    }
    domClass.remove(passReq, "open");
    return null;
  };
});