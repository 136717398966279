define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/dtl", "dojox/dtl/tag/logic", "dojox/dtl/Context", "mojo/utils/styles", "mojo/neapolitan/editors/blocks/templates/PageNavigation/outputWeb.html"], function (lang, declare, Stateful, query, domConstruct, array, dtl, dtlLogic, dtlContext, styles, tpl) {
  return declare([Stateful], {
    outputTplStr: tpl,
    html: "",
    links: [],
    linkVisibilityMap: {},
    layoutSettings: {
      orientation: "horizontal",
      position: "static"
    },
    containerStyles: "position: static; text-align: center;",
    logoStyles: "float: left;",
    navigationStyles: "float: right;",
    logo: null,
    enabled: false,
    useBrand: false,
    styles: [{
      "name": "Navigation Menu",
      "selector": ".mc-nav-container",
      "props": {
        "background-color": {
          "value": "#FFFFFF",
          "isOptional": false
        }
      }
    }, {
      "name": "Link Text",
      "selector": ".menu-item a",
      "props": {
        "color": {
          "value": "#282828"
        },
        "font-family": {
          "value": "Arial"
        },
        "font-size": {
          "value": "14px"
        },
        "font-weight": {
          "value": "normal"
        },
        "line-height": {
          "value": "150%"
        },
        "letter-spacing": {
          "value": "normal"
        }
      }
    }, {
      "name": "Mobile Menu Icon Color",
      "selector": ".mclp-wrapper .bar",
      "props": {
        "background-color": {
          "value": "#282828",
          "isOptional": false
        }
      }
    }],
    /**
     * Renders our navigation template with values
     * @param {bool} isPreview - If preview, a new array link objects are
     * returned with the live HREF values replaced with (#)
     * @return {string} html
     */
    getContent: function (isPreview) {
      var that = this;
      var links = !isPreview ? this.links : this.links.filter(function (link) {
        if (link.pageId) {
          return that.linkVisibilityMap[link.pageId] || link.isActive;
        }
        return true;
      }).map(function (link) {
        return {
          text: link.text,
          linkId: link.linkId,
          link: "#"
        };
      });
      if (!this.enabled) {
        return domConstruct.create("div");
      }
      if (!this.template) {
        this.template = new dtl.Template(this.outputTplStr);
      }
      var outputModel = new dtlContext({
        title: this.layoutSettings.orientation,
        links: links
      });
      var dom = domConstruct.toDom(lang.trim(this.template.render(outputModel)));
      return styles.inline(dom, this.styles);
    },
    getMarkup: function () {
      var contentDom = this.getContent();
      var container = domConstruct.create("div");
      domConstruct.place(contentDom, container);
      var htmlText = query(container).html();
      domConstruct.destroy();
      return htmlText;
    }
  });
});