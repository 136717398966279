/**
 * Constants used for ads-related widgets
 */
define(function () {
  return {
    "minAdCount": 100,
    "minAdCountMessage": "You can target your list with an ad once you have at least 100 contacts.",
    "minLookalikeCount": 500,
    "minLookalikeCountMessage": "You can build an audience similar to your list once you have at least 500 contacts."
  };
});