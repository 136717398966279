define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dijit/Dialog", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/InstagramConnect.html", "dojo/text!../templates/instagram-connect/DisconnectDialogBody.html", "dojo/text!../templates/instagram-connect/DisconnectDialogActionBar.html"], function (declare, lang, domClass, on, query, xhr, mUrl, Dialog, _Widget, _TemplatedMixin, _WidgetsInTemplateMixin, templateStr, disconnectDialogBodyStr, disconnectDialogActionBarStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: templateStr,
    // constructor
    appInstallUrl: null,
    _setAppInstallUrlAttr: {
      node: "connectButtonNode",
      type: "attribute",
      attribute: "href"
    },
    integrationInstallStatus: null,
    termsNode: null,
    disconnectDialog: null,
    // attach points - buttons
    connectButtonNode: null,
    disconnectButtonNode: null,
    facebookAPIDownNode: null,
    needsReauthNode: null,
    needsToConnectInstagramToFacebookNode: null,
    shouldBeUsingInstagramBusinessAccountsNode: null,
    circularLoaderNode: null,
    postCreate: function () {
      this.disconnectDialog = new Dialog({
        title: "Disconnect Facebook",
        content: disconnectDialogBodyStr,
        actionBarTemplate: disconnectDialogActionBarStr,
        onHide: lang.hitch(this, function () {
          this._removeWaitingStateFromButtonNode(this.disconnectButtonNode);
        })
      });

      // hide the terms copy for now, any states that need to show it should unhide it later
      domClass.add(this.termsNode, "display--none");
      if (this.integrationInstallStatus === true) {
        this._maybeShowBusinessAccountHelpText();
        this._prepareButtonsForConnectedState();
        this._prepareButtonsForDisconnectDialog();
      } else {
        this._determineNonConnectedIntegrationStateAndPrepareUI();
      }
    },
    /**
     * Instagram Connect is considered not connected when:
     * - Facebook is not connected
     * - Facebook is connected but no Instagram profiles are connected to any of their Facebook pages
     * - Facebook's API is down
     * - Facebook is connected but the API key has expired
     *
     * This function determines which state it's in and sets the UI up.
     */
    _determineNonConnectedIntegrationStateAndPrepareUI: function () {
      // show loading animation
      domClass.remove(this.circularLoaderNode, "hide");
      xhr.get(mUrl.toUrl("/account/connected-sites/internal-integration/get-facebook-connection-status"), {
        handleAs: "json"
      }).then(lang.hitch(this, function (data) {
        // hide loading animation
        domClass.add(this.circularLoaderNode, "hide");
        switch (data.status) {
          case "connected":
            domClass.remove(this.needsToConnectInstagramToFacebookNode, "hide");
            break;
          case "not_connected":
            domClass.remove(this.connectButtonNode, "hide");
            domClass.remove(this.termsNode, "display--none");
            break;
          case "needs_reauth":
            domClass.remove(this.needsReauthNode, "hide");
            break;
          case "facebook_unavailable":
            domClass.remove(this.facebookAPIDownNode, "hide");
            break;
          default:
            break;
        }
      }));
    },
    _prepareButtonsForConnectedState: function () {
      domClass.add(this.connectButtonNode, "hide");
      domClass.remove(this.disconnectButtonNode, "hide");
      domClass.add(this.termsNode, "display--none");

      // disconnect (open confirmation dialog)
      this.own(on(this.disconnectButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._setButtonNodeToWaiting(this.disconnectButtonNode);
        this.disconnectDialog.show();
      })));
    },
    _prepareButtonsForDisconnectDialog: function () {
      var self = this;
      query(".confirm-disconnect", this.disconnectDialog.domNode).on("click", function (e) {
        e.preventDefault();
        query(".confirm-disconnect").forEach(function (node) {
          self._setButtonNodeToWaiting(node);
        });
        xhr.post(mUrl.toUrl("/account/connected-sites/internal-integration/disconnect-instagram"), {
          handleAs: "json"
        }).then(function (data) {
          if (data && data.success === true) {
            window.location.reload();
          }
        });
      });
      query(".cancel", this.disconnectDialog.domNode).on("click", function (e) {
        e.preventDefault();
        self._removeWaitingStateFromButtonNode(self.disconnectButtonNode);
        self.disconnectDialog.hide();
      });
    },
    _setButtonNodeToWaiting: function (buttonNode) {
      domClass.add(buttonNode, "button-loading disabled");
    },
    _removeWaitingStateFromButtonNode: function (buttonNode) {
      domClass.remove(buttonNode, "button-loading disabled");
    },
    _maybeShowBusinessAccountHelpText: function () {
      xhr.get(mUrl.toUrl("/account/connected-sites/internal-integration/has-a-connected-instagram-business-account"), {
        handleAs: "json"
      }).then(lang.hitch(this, function (data) {
        if (data && !data.has_connected) {
          domClass.remove(this.shouldBeUsingInstagramBusinessAccountsNode, "hide");
        }
      }));
    }
  });
});