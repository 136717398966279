define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo, Deferred, darray) {
  // module:
  //		dojo/DeferredList

  dojo.DeferredList = function ( /*Array*/list, /*Boolean?*/fireOnOneCallback, /*Boolean?*/fireOnOneErrback, /*Boolean?*/consumeErrors, /*Function?*/canceller) {
    // summary:
    //		Deprecated, use dojo/promise/all instead.
    //		Provides event handling for a group of Deferred objects.
    // description:
    //		DeferredList takes an array of existing deferreds and returns a new deferred of its own
    //		this new deferred will typically have its callback fired when all of the deferreds in
    //		the given list have fired their own deferreds.  The parameters `fireOnOneCallback` and
    //		fireOnOneErrback, will fire before all the deferreds as appropriate
    // list:
    //		The list of deferreds to be synchronizied with this DeferredList
    // fireOnOneCallback:
    //		Will cause the DeferredLists callback to be fired as soon as any
    //		of the deferreds in its list have been fired instead of waiting until
    //		the entire list has finished
    // fireonOneErrback:
    //		Will cause the errback to fire upon any of the deferreds errback
    // canceller:
    //		A deferred canceller function, see dojo.Deferred
    var resultList = [];
    Deferred.call(this);
    var self = this;
    if (list.length === 0 && !fireOnOneCallback) {
      this.resolve([0, []]);
    }
    var finished = 0;
    darray.forEach(list, function (item, i) {
      item.then(function (result) {
        if (fireOnOneCallback) {
          self.resolve([i, result]);
        } else {
          addResult(true, result);
        }
      }, function (error) {
        if (fireOnOneErrback) {
          self.reject(error);
        } else {
          addResult(false, error);
        }
        if (consumeErrors) {
          return null;
        }
        throw error;
      });
      function addResult(succeeded, result) {
        resultList[i] = [succeeded, result];
        finished++;
        if (finished === list.length) {
          self.resolve(resultList);
        }
      }
    });
  };
  dojo.DeferredList.prototype = new Deferred();
  dojo.DeferredList.prototype.gatherResults = function (deferredList) {
    // summary:
    //		Gathers the results of the deferreds for packaging
    //		as the parameters to the Deferred Lists' callback
    // deferredList: dojo/DeferredList
    //		The deferred list from which this function gathers results.
    // returns: dojo/DeferredList
    //		The newly created deferred list which packs results as
    //		parameters to its callback.

    var d = new dojo.DeferredList(deferredList, false, true, false);
    d.addCallback(function (results) {
      var ret = [];
      darray.forEach(results, function (result) {
        ret.push(result[1]);
      });
      return ret;
    });
    return d;
  };
  return dojo.DeferredList;
});