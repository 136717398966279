/**
 * Mobile Ad Campaign Edit Module
 * @module /mojo/views/ads/edit-mobile.js
 * @see /mojo/views/ads/edit.js
 *
 * This module is included as part of the ViewScript Javascript and serves as a top-level JS file for all ad campaign
 * types inside the checklist editor. Code specific to any campaign type belongs elsewhere.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/request/notify", "mojo/url", "mojo/widgets/ads/mobile-checklist/EventRouter", "mojo/widgets/ads/mobile-checklist/AndroidSimulator",
// Slats
"mojo/widgets/ads/mobile-checklist/FacebookSlat", "mojo/widgets/ads/mobile-checklist/GoogleSlat",
// Device interfaces -- wannabe APIs
"mojo/widgets/ads/mobile-checklist/iOSDevice", "mojo/widgets/ads/mobile-checklist/AndroidDevice",
// Mixins
"mojo/views/_base"], function (declare, request, notify, mUrl, EventRouter, AndroidSimulator, fbSlat, googleSlat, iOSDevice, AndroidDevice, _base) {
  return declare([_base], {
    // Passed in from the controller
    ad: {},
    // Create the event router and let it start to control the page.
    onReady: function () {
      if (this.useSimulator()) {
        this.initSimulator();
      }
      this.initRouter();

      // Page level method to signal refresh
      this._notifyRouterExpiredSession();
    },
    /**
     * We use a simulator for easier testing of the connection between native and this web view.
     */
    initSimulator: function () {
      this.sim = new AndroidSimulator({
        interfaceContainer: this.$el.interfaceContainer
      });
      this.sim.placeAt(this.$el.simulatorContainer);
      this.sim.startup();
    },
    /**
     * The router is used as the communication hub between native and webview.
     */
    initRouter: function () {
      this.router = new EventRouter({
        campaignUniqueId: this.ad.uniqueId,
        slatInterface: this.getSlatInterface(),
        deviceInterface: this.getDeviceInterface(),
        useSimulator: this.useSimulator()
      });
      this.router.placeAt(this.$el.interfaceContainer);
      this.router.startup();
    },
    /**
     * Each campaign type has it's own interface. This creates a common method for controlling slats.
     * This should be one of the only places we check campaign type in our JS.
     *
     * @return {*}fbSlat||googleSlat
     */
    getSlatInterface: function () {
      var selectedSlatInterface = null;
      switch (this.ad.type) {
        case "facebook":
          selectedSlatInterface = fbSlat;
          break;
        case "google":
          selectedSlatInterface = googleSlat;
          break;
        default:
          throw new Error("Unknown slat interface type.");
      }
      return selectedSlatInterface;
    },
    /**
     * Each platform has it's own interface that allows customization. Pass the appropriate one to the
     * Event Router
     *
     * @return {*}iOSDevice||AndroidDevice - Reference to a widget that shares common mixin.
     */
    getDeviceInterface: function () {
      if (window.webkit && window.webkit.messageHandlers) {
        return iOSDevice;
      } else if (window.Android) {
        return AndroidDevice;
      }
      throw new Error("Unknown device interface type. Did you mean to use the Android simulator? Append 'simulator=true' as a query param");
    },
    /**
     * We use a simulator to Mock iOS/Android behaviors. Should only be used when we really want it
     * to. Append simulator=true as a query param in the URL to see the simulator.
     *
     * @return {boolean} Use sim or not.
     */
    useSimulator: function () {
      // In case we need to test for QA. Cheap test for query params.
      return window.location.search.indexOf("simulator=true") !== -1;
    },
    /**
     * Notify the Event Router that the current pages session has expired
     *
     * @private
     */
    _notifyRouterExpiredSession: function () {
      var self = this;
      notify("error", function (error) {
        switch (error.response.status) {
          case 403:
            self.router.handleExpiredSession();
            break;
          default:
            throw new Error("Unknown response status");
        }
      });
    }
  });
});