define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (declare, base, domAttr, on, mUrl, p2Utils, query, registry) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    onReady: function () {},
    openCreditDetails: function (id) {
      var self = this;
      var newPaneObj = {
        title: "Credit Details: " + id,
        href: mUrl.toUrl("/peaches2/users/billing", {
          _s: "credit-details",
          user_id: self.pageJs.userAttrs.userId,
          credit_id: id
        }),
        stub: "credit-details",
        closable: true
      };
      this.pageJs.tabContainer.addNewPane(newPaneObj, true, id);
    },
    openConfirmVoidDialog: function (credit_id) {
      query("[data-element='creditVoidId']")[0].value = credit_id;
      var confirmVoidDialog = registry.byId("confirm-void-dialog");
      confirmVoidDialog.show();
    }
  });
});