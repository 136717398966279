define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, xhr, mUrl) {
  var VerifiedDomains = declare(null, {
    constructor: function (properties) {
      this.domain = properties.domain;
    }
  });
  return {
    /**
     * @return {Promise} Resolves to an array of VerifiedDomains
     */
    list: function () {
      return xhr.get(mUrl.toUrl("channels/get-verified-domains"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return data.map(function (domain) {
          return new VerifiedDomains({
            domain: domain
          });
        });
      });
    }
  };
});