define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/InfoBlock", "mojo/user", "mojo/authz"], function (declare, InfoBlock, user, Authz) {
  var SETTING_ID = "eventsInfoBlockAcknowledgement";
  var EventsInfoBlock = declare([InfoBlock], {
    settingId: SETTING_ID,
    imageSrc: "https://cdn-images.mailchimp.com/product/brand_assets/illos/art-other-choice.png",
    headerText: "Learn how you can track user behavior in your app",
    linkText: "Read about events in our API Guide",
    linkHref: "https://mailchimp.com/developer/guides/create-custom-events/"
  });
  EventsInfoBlock.shouldShow = function () {
    return new Authz().userMay("regular_segmentation", "use category", {
      category: "contact_event"
    }).then(function (maySegment) {
      var hasAcknowledged = user.settings[SETTING_ID] === true;
      return hasAcknowledged === false && maySegment === false;
    });
  };
  return EventsInfoBlock;
});