define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/image.html", "mojo/neapolitan/MaxWidthRestriction", "mojo/neapolitan/widgets/ColorPicker"], function (declare, _Base, lang, win, domConstruct, tmpl, MaxWidthRestriction, ColorPicker) {
  return declare([_Base], {
    templateString: tmpl,
    postCreate: function () {
      this.sync('image', this.imageRow, "image");
      this.connect(this.imageRow, 'onChange', 'updateImage');
      MaxWidthRestriction.addTo(this.imageRow);

      // Set the defaults
      this.widthInput.value = this.mcedit.savedWidth;
      this.heightInput.value = this.mcedit.savedHeight;
      this.alignInput.set("value", this.mcedit.get("savedAlign"), false);
      this.borderWidthInput.set("value", this.mcedit.get("savedBorderWidth"), false);
      this.borderStyleInput.set("value", this.mcedit.get("savedBorderStyle"), false);
      this.borderColorInput.set("value", this.mcedit.get("savedBorderColor"), false);

      // Not using dijit/form/Input because their onChange event can get in some silly loop. Have to manually sync values.
      this.connect(this.widthInput, "change", "_adjustHeightProportions");
      this.connect(this.heightInput, "change", "_adjustWidthProportions");
      this.connect(this.alignInput, "onChange", function (value) {
        this._updateValue("savedAlign", value);
      });
      this.connect(this.borderWidthInput, "onChange", function (value) {
        this._updateValue("savedBorderWidth", value);
      });
      this.connect(this.borderStyleInput, "onChange", function (value) {
        this._updateValue("savedBorderStyle", value);
      });
      this.connect(this.borderColorInput, "onChange", function (value) {
        this._updateValue("savedBorderColor", value);
      });
      this.watch(lang.hitch(this, function (attr, old, value) {
        switch (attr) {
          case 'savedHeight':
            this.heightInput.value = value;
            break;
          case 'savedWidth':
            this.widthInput.value = value;
            break;
          case 'savedAlign':
            this.alignInput.set("value", value, false);
            break;
          case 'image':
            if (value == null) {
              this.editorContainer.toggleSection("content");
            }
            this._toggleSettingSection(value != null);
            break;
          case 'savedBorderWidth':
            this.borderWidthInput.set("value", value, false);
            break;
          case 'savedBorderStyle':
            this.borderStyleInput.set("value", value, false);
            break;
          case 'savedBorderColor':
            this.borderColorInput.set("value", value, false);
            break;
        }
      }));
      this._toggleSettingSection(this.mcedit.image != null);
      this.colorPicker = new ColorPicker();
      this.colorPicker.startup();
      domConstruct.place(this.colorPicker.domNode, win.body());
      this.colorPicker.addInput(this.borderColorInput);
    },
    _toggleSettingSection: function (show) {
      if (show) {
        this.editorContainer.enableSection("settings");
      } else {
        this.editorContainer.disableSection("settings");
      }
    },
    _updateValue: function (attr, value) {
      this.mcedit.set(attr, value);
      this.mcedit.save();
    },
    _adjustHeightProportions: function () {
      if (this.mcedit.image && this.proportionCheckbox.checked) {
        var proportion = this.mcedit.image.width / this.mcedit.image.height;
        var height = parseInt(1 / proportion * parseInt(this.widthInput.value));
        this.heightInput.value = height;
        this.mcedit.set("savedHeight", height);
      }
      this.mcedit.set("savedWidth", this.widthInput.value);
      this.mcedit.save();
    },
    _adjustWidthProportions: function () {
      if (this.mcedit.image && this.proportionCheckbox.checked) {
        var proportion = this.mcedit.image.width / this.mcedit.image.height;
        var width = parseInt(proportion * parseInt(this.heightInput.value));
        this.widthInput.value = width;
        this.mcedit.set("savedWidth", width);
      }
      this.mcedit.set("savedHeight", this.heightInput.value);
      this.mcedit.save();
    },
    onUploading: function (isUploading) {
      this.imageRow.toggleUploadMessage(isUploading);
    },
    updateImage: function (action) {
      if (action == "image") {
        this.mcedit.set("savedWidth", null);
        this.mcedit.set("savedHeight", null);
      }
      this.mcedit.set("image", this.imageRow.image);
      this.mcedit.save();
    },
    onClose: function () {
      this.destroy();
    }
  });
});