define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/widgets/ContactBillingModal", "mojo/widgets/_AdyenCardPayments", "mojo/api/postcard", "dijit/TooltipDialog", "dijit/popup", "dojo/text!./templates/postcard-billing-sidebar.html", "../UpsellPostcard"], function (declare, domClass, topic, Renderable, ContactBillingModal, _AdyenCardPayments, postcardApi, TooltipDialog, popup, tpl, UpsellPostcard) {
  var SIDEBAR_UPDATED_TOPIC = "mojo/widgets/checklist-editor/items/PostcardBillingSidebar/SIDEBAR_UPDATED";
  var PostcardBillingSidebar = declare([Renderable, _AdyenCardPayments], {
    templateString: tpl,
    parseContent: false,
    tooltips: {
      "recharge": {
        content: "When your balance falls too low to send a postcard, we'll automatically renew it so you don't miss out on any carts."
      },
      "deliveryEstimate": {
        content: "<strong>Printing/processing:</strong> 1 business day<br/><strong>Shipping:</strong> 4-8 business days<br><br>Postcards are only printed and shipped on business days."
      }
    },
    state: {
      content: null,
      isItemActive: false
    },
    constructor: function (props) {
      this.refreshSidebarContent(props.model.postcardId);
    },
    refreshSidebarContent: function (postcardId) {
      postcardApi.getBillingSidebarHTML(postcardId).then(function (HTMLContent) {
        this.setState({
          content: HTMLContent
        });
        topic.publish(SIDEBAR_UPDATED_TOPIC, this);
      }.bind(this));
    },
    toggleCVVError: function (shouldShowError) {
      var errorMessage = this.domNode.querySelector("[data-mc-el='ccSecurityError']");
      var CVVInput = this.domNode.querySelector("[data-mc-el='ccSecurity']");
      if (errorMessage && CVVInput) {
        domClass.toggle(errorMessage, "hide", !shouldShowError);
        domClass.toggle(CVVInput, "invalid", shouldShowError);
        if (shouldShowError) {
          CVVInput.focus();
        }
      }
    },
    _bindTooltipEventListeners: function () {
      this.domNode.querySelectorAll("[data-tooltip]").forEach(function (button) {
        var tooltipName = button.dataset["tooltip"];
        if (!this.tooltips[tooltipName]) {
          return;
        }

        // Create a tooltip dialog instance
        this.tooltips[tooltipName].widget = new TooltipDialog({
          content: "<ul class='help-tip-lines selfclear'>" + this.tooltips[tooltipName].content + "</ul>",
          baseClass: "dijitTooltipDialogHelp",
          "class": "help-tip-dialog dijitTooltipDialog",
          onBlur: function () {
            this.tooltips[tooltipName].isVisible = false;
            popup.hide(this.tooltips[tooltipName].widget);
          }.bind(this)
        });

        // Bind button to show tooltip dialog when clicked
        button.addEventListener("click", function (e) {
          this.tooltips[tooltipName].isVisible = true;
          popup.open({
            popup: this.tooltips[tooltipName].widget,
            around: button
          });
          // Place the focus on the tooltip dialog in order to close onBlur
          this.tooltips[tooltipName].widget.focus();
        }.bind(this));
      }, this);
    },
    _bindLinkEventListeners: function () {
      var paymentMethodLink = this.domNode.querySelector("[data-mc-el='editPaymentAnchor'], [data-mc-el='addPaymentAnchor']");
      var featuresLink = this.domNode.querySelector("[data-mc-el='toggleUpsell']");
      if (featuresLink) {
        featuresLink.addEventListener("click", function (e) {
          // TODO: Refactor sidebar to accept extra props instead of relying on window
          var accountUpgradeDetails = window._mc.account_upgrade_details;
          this.upsellPostcard = new UpsellPostcard(accountUpgradeDetails);
          this.upsellPostcard.showDialog();
        });
      }
      if (paymentMethodLink) {
        if (this.editContactBillingModal) {
          this.editContactBillingModal.destroy();
        }
        this.editContactBillingModal = new ContactBillingModal({
          step: "payment",
          workflowMode: true,
          onClose: this.refreshSidebarContent.bind(this, this.model.postcardId)
        }, paymentMethodLink);
      }
    },
    _bindAdyenCvvWidget: function () {
      if (this.domNode.querySelector("[data-mc-el='ccSecurityAdyen']")) {
        var production = this.domNode.querySelector('[data-mc-el="ccProd"]').value === "true";
        var originKey = this.domNode.querySelector('[data-mc-el="ccOriginKey"]').value;
        this.initializeCvcForm(production, originKey, this.domNode.querySelector("[data-mc-el='ccSecurityAdyen']"), {
          "onValid": function (valid) {
            this.domNode.querySelector('[data-mc-el="ccSecurity"]').value = valid ? this.domNode.querySelector('[name="encryptedSecurityCode"]').value : "";
          }.bind(this)
        });
      }
    },
    _disableSidebar: function () {
      var CVVInput = this.domNode.querySelector("[data-mc-el='ccSecurity']");
      if (CVVInput) {
        CVVInput.setAttribute("disabled", "disabled");
      }
      var editPaymentAnchor = this.domNode.querySelector("[data-mc-el='editPaymentAnchor']");
      if (editPaymentAnchor) {
        editPaymentAnchor.parentNode.removeChild(editPaymentAnchor);
      }
      var disclosure = this.domNode.querySelector(".disclosure-closed");
      if (disclosure) {
        disclosure.parentNode.removeChild(disclosure);
      }
      this.domNode.children[0].classList.add("c-disabledSlat");
    },
    /**
     * This method can parse through the DOM and determine user payment info.
     *
     * @returns {Object} payment info derived from DOM
     */
    getPaymentInfo: function () {
      var CVVInput = this.domNode.querySelector("[data-mc-el='ccSecurity']");
      var oneTimeFeeLabel = this.domNode.querySelector("[data-mc-el='oneTimeFeeLabel']");
      var amountDueInUSD = oneTimeFeeLabel ? Number(oneTimeFeeLabel.dataset.usd) : 0;
      var amountDueInUserCurrency = oneTimeFeeLabel ? Number(oneTimeFeeLabel.dataset["userCurrency"]) : 0;
      var amountDueStringInUserCurrency = oneTimeFeeLabel ? oneTimeFeeLabel.innerText : "";
      var pricingVersion = this.domNode.querySelector("[data-pricing-version]").getAttribute("data-pricing-version");
      return {
        hasPaymentMethod: !!this.domNode.querySelector("[data-has-payment-method='yes']"),
        isCVVRequired: !!this.domNode.querySelector("[data-mc-el='ccSecurity']"),
        pricingVersion: pricingVersion,
        CVV: CVVInput ? CVVInput.value : null,
        isPaymentRequired: amountDueInUSD > 0,
        amountDue: amountDueStringInUserCurrency,
        amountDueInUserCurrency: amountDueInUserCurrency
      };
    },
    render: function () {
      this.inherited(arguments);
      if (!this.state.content) {
        return;
      }
      if (this.state.isItemActive) {
        this._disableSidebar();
      } else {
        this._bindLinkEventListeners();
        this._bindTooltipEventListeners();
        this._bindAdyenCvvWidget();
      }
    }
  });
  PostcardBillingSidebar.SIDEBAR_UPDATED_TOPIC = SIDEBAR_UPDATED_TOPIC;
  return PostcardBillingSidebar;
});