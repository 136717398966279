define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/widgets/campaigns/CampaignArchiveUrl", "mojo/user", "mojo/widgets/Renderable", "mojo/lib/request-helpers", "mojo/validation/Error", "dojo/text!./templates/campaign-url-dialog.html", "mojo/context", "mojo/lib/flags"], function (declare, lang, domClass, xhr, url, CampaignArchiveUrl, user, Renderable, requestHelpers, ValidationError, tpl, context, flags) {
  return declare([Renderable], {
    templateString: tpl,
    urlExistsPath: "campaigns/campaign-archive-url-exists",
    constructor: function (props) {
      if (props.urlExistsPath) {
        this.urlExistsPath = props.urlExistsPath;
      }
      this.state = {
        errors: {},
        list: props.model.list,
        campaignId: props.model.campaignId,
        rootDomain: props.model.campaignUrl.rootDomain,
        campaignToken: props.model.campaignUrl.campaignToken,
        brandToken: props.model.campaignUrl.brandToken,
        isSaving: false,
        user: {
          hasVerifiedCustomDomain: user.hasVerifiedCustomDomain,
          isActivelyPaying: user.isActivelyPaying,
          hasPartnershipPlan: user.hasPartnershipPlan
        },
        brands: props.model.campaignUrl.brands
      };
      if (props.model.content) {
        this.state.subject = props.model.content.subject;
        this.state.isPage = false;
      } else {
        this.state.isPage = true;
      }
      this.campaignArchiveUrl = new CampaignArchiveUrl({
        urlExistsPath: this.urlExistsPath
      });
    },
    getData: function () {
      return {
        campaign_url: {
          brand_token: this.state.brandToken,
          campaign_token: this.campaignArchiveUrl.getCampaignTokenFromString(this.state.campaignToken)
        }
      };
    },
    setCampaignToken: function (str) {
      this.setState({
        campaignToken: str
      });
    },
    setValue: function (event) {
      var isCampaignTokenInput = event.target;
      if (isCampaignTokenInput) {
        var token = this.campaignArchiveUrl.getCampaignTokenFromString(event.target.value);
        if (token === "") {
          this.disableSaveButton();
        } else {
          this.enableSaveButton();
        }
        this.state.campaignToken = token;
      } else {
        this.setState({
          "brandToken": event
        });
      }
    },
    disableSaveButton: function () {
      domClass.add(this.campaignUrlSaveButton, "disabled");
    },
    enableSaveButton: function () {
      domClass.remove(this.campaignUrlSaveButton, "disabled");
    },
    save: function (event) {
      var data = this.getData();
      this.campaignUrlExists().then(this._successHandler.bind(this), this._errorHandler.bind(this));
      window.ga("send", "event", "campaign_checklist", "click", "campaign_link:save");
    },
    _errorHandler: function (error) {
      this.setState({
        errors: {
          campaignToken: error.message
        }
      });
    },
    _successHandler: function () {
      this.onSave(this.getData());
      this.closeDialog();
    },
    campaignUrlExists: function () {
      var cleanUrl = this.state.campaignToken ? this.state.campaignToken.replace(/^[_-]|[_-]$/g, "") : null; // replace underscores and dashes at beginning and end of string
      return xhr(url.toUrl(this.urlExistsPath), {
        query: {
          id: this.state.campaignId,
          brand_token: this.state.brandToken,
          campaign_token: cleanUrl
        },
        handleAs: "json"
      }).then(function (response) {
        if (response.error) {
          throw new ValidationError(response.error);
        }
        return response;
      }, requestHelpers.throwError);
    }
  });
});