define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo/html", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, domAttr, on, xhr, mUrl, html, dom, query) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    listId: null,
    onReady: function () {},
    peachesUrl: function (endpoint, params) {
      return mUrl.toUrl("/peaches2/users" + endpoint, params);
    },
    vipList: function (id) {
      this.listId = id;
      this.$widget.vipListDialog.show();
    },
    doVipList: function () {
      var self = this;
      var reason = this.$el.vipReason.value;
      if (!reason) {
        window.alert("You have to provide a reason");
        return;
      }
      this.$widget.vipListDialog.hide();
      window.location = this.peachesUrl("/lists/list-vip-add", {
        user_id: this.pageJs.userAttrs.userId,
        list_id: this.listId,
        reason: reason
      });
    },
    removeVipList: function (id) {
      this.$widget.removeVipListDialog.show();
      this.listId = id;
    },
    doRemoveVipList: function () {
      window.location = this.peachesUrl("/lists/list-vip-remove", {
        user_id: this.pageJs.userAttrs.userId,
        list_id: this.listId
      });
    },
    deleteList: function (id) {
      this.listId = id;
      this.$widget.deleteListDialog.show();
    },
    doListDeletion: function () {
      var note = this.$el.deleteListNote.value;
      if (!note) {
        window.alert("You have to provide a note");
        return;
      }
      window.location = this.peachesUrl("/lists/list-delete", {
        user_id: this.pageJs.userAttrs.userId,
        note: note,
        id: this.listId
      });
    },
    undeleteList: function (id) {
      this.listId = id;
      this.$widget.undeleteListDialog.show();
    },
    doListUndeletion: function () {
      var note = this.$el.undeleteListNote.value;
      if (!note) {
        window.alert("You have to provide a note");
        return;
      }
      window.location = this.peachesUrl("/lists/list-undelete", {
        user_id: this.pageJs.userAttrs.userId,
        id: this.listId,
        note: note
      });
    },
    showUnengaged: function (el, list_id) {
      var elem_id = domAttr.get(el, "id");
      if (list_id !== null) {
        xhr(this.peachesUrl("/lists//list-total-unengaged"), {
          query: {
            list_id: list_id,
            user_id: this.pageJs.userAttrs.userId
          },
          handleAs: "json"
        }).then(function (data) {
          if (data.status === "success") {
            html.set(dom.byId(elem_id + "-container"), "[ unengaged: " + data.total + " ]");
          } else {
            alert("Oops! Something happened :( Sorry.");
          }
        });
      }
      return false;
    },
    showForceMembershipCheckDialog: function (id) {
      this.listId = id;
      this.$widget.membershipcheckForceDialog.show();
    },
    doForceMembershipCheck: function () {
      var params = "action=" + query("input[type=radio][name=membershipcheck_action]:checked")[0].value + "&campaign_id=" + this.$el.membershipcheckCampaignId.value + "&first_sent=" + this.$el.membershipcheckFirstSent.checked + "&never_sent=" + this.$el.membershipcheckNeverSent.checked + "&prune=" + this.$el.membershipcheckPrune.checked + "&ecomm=" + this.$el.membershipcheckEcomm.checked + "&rating=" + this.$el.membershipcheckRating.value + "&no_open=" + this.$el.membershipcheckNoOpen.value + "&age=" + this.$el.membershipcheckAge.value + "&list_id=" + this.listId + "&user_id=" + this.pageJs.userAttrs.userId;
      window.location = this.peachesUrl("/lists/force-membership-check?" + params);
    },
    showRemoveMembershipCheckDialog: function (id) {
      this.listId = id;
      this.$widget.membershipcheckRemoveDialog.show();
    },
    doRemoveMembershipCheck: function () {
      window.location = this.peachesUrl("/lists/override-membership-check", {
        user_id: this.pageJs.userAttrs.userId,
        list_id: this.listId
      });
    }
  });
});