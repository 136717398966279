define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_AttachMixin", "dijit/_TemplatedMixin", "mojo/widgets/_Overlay", "dojo-proxy-loader?name=dojo/_base/connect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user"], function (declare, _WidgetBase, _AttachMixin, _TemplatedMixin, _Overlay, connect, domConstruct, domStyle, dojoWindow, domAttr, topic, user) {
  var instance = null;
  var timeout = null;
  var rememberMeDelay = 3000;
  var ReauthOverlay = declare([_WidgetBase, _TemplatedMixin, _AttachMixin, _Overlay], {
    templateString: '<div class="c-baseOverlay"></div>',
    postCreate: function () {
      this.reauthConfig = window.reauth ? window.reauth : null;
      // Adding a url param to track which poller is calling the reauth endpoint
      // for tracking.
      // 1 = Latest react version
      // 2 = Old react version
      // 3 = Dojo version
      this.reauthConfig.url = this.reauthConfig.url + "&reauthSource=3";
      this._placeDomNode();
    },
    onShow: function () {
      var contentFrame = domConstruct.create("iframe", {
        "frameBorder": 0,
        "src": "#",
        "width": "100%",
        "height": "100%"
      });
      domAttr.set(contentFrame, "src", this.reauthConfig.url);
      domConstruct.place(contentFrame, this.domNode);
    },
    onHide: function () {
      domConstruct.empty(this.domNode);
    },
    _placeDomNode: function () {
      domConstruct.place(this.domNode, dojoWindow.body());
    }
  });
  return {
    /**
     * Retrieve or create an instance of ReauthOverlay. This allows us to avoid creating multiple on the page.
     *
     * returns {*} Instance of ReauthOverlay
     * @private
     */
    _preShow: function () {
      if (!this._getInstance().reauthConfig.url) {
        throw new Error("Attempted to use ReauthOverlay without setting a url");
      }
      topic.publish("mojo/reauth/required");
      // Subscribe to the topic mojo/reauth/complete - once this topic is published, we use the newToken to update
      // CSRF and then call the 'hide' function in this object
      topic.subscribe("mojo/reauth/complete", function (newToken) {
        window.bugsnagClient.leaveBreadcrumb("mojo/reauth/complete event received");
        user.updateCSRF(newToken);
        this.hide();
      }.bind(this));

      // If user is remember me, delay dialog to attempt to relog them in the background.
      if (this._getInstance().reauthConfig.rememberMe) {
        timeout = setTimeout(function () {
          instance.show();
          timeout = null;
        }, rememberMeDelay);
      }
    },
    _getInstance: function () {
      if (!instance) {
        instance = new ReauthOverlay();
        instance.startup();
      }
      return instance;
    },
    show: function () {
      this._preShow();
      if (!this._getInstance().reauthConfig.rememberMe) {
        this._getInstance().show();
        domStyle.set(this._getInstance().domNode, "zIndex", 99999); //override zIndex set in _Overlay.js
      }
    },
    hide: function () {
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = null;
      }
      this._getInstance().hide();
    }
  };
});