/**
 * Add functions that keeps track of a filter map.
 * Uses postMixInProperties, so call this.inherited if your main widget overrides it.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful"], function (declare, lang, array, Stateful) {
  return declare(null, {
    saveState: true,
    postMixInProperties: function () {
      this.inherited(arguments);
      var params = {};
      if (this.saveState && this._persisted) {
        var hashes = this.getAllHashParams();
        for (var key in hashes) {
          if (key.indexOf("f_") === 0) {
            params[key.replace("f_", "")] = hashes[key];
          }
        }
      }
      this._filters = new Stateful(params);
    },
    hasFilters: function () {
      for (var key in this._filters) {
        if (this._validFilterKey(key)) {
          return true;
        }
      }
      return false;
    },
    hasFilter: function (name) {
      return this._filters.get(name);
    },
    getFilter: function (name) {
      return this._filters.get(name);
    },
    setFilter: function (name, value) {
      this._filters.set(name, value);
      this.set('page', 1);
      this.onFilterChange(name, value);
      if (this.saveState && this._persisted) {
        this.setHashParam("f_" + name, value);
      }
    },
    clearFilter: function (name) {
      this._filters.set(name, null);
      this.set('page', 1);
      this.onFilterChange(name, null);
      if (this.saveState && this._persisted) {
        this.unsetHashParam("f_" + name);
      }
    },
    clearAllFilters: function () {
      this.set("page", 1);
      for (var key in this._filters) {
        if (this._validFilterKey(key)) {
          this._filters.set(key, null);
          if (this.saveState && this._persisted) {
            this.unsetHashParam("f_" + key);
          }
        }
      }
    },
    watchFilter: function (name, callback) {
      return this._filters.watch(name, callback);
    },
    getFilterQuery: function () {
      var filterMap = {};
      for (var key in this._filters) {
        if (this._validFilterKey(key)) {
          filterMap['filters[' + key + ']'] = this._filters[key];
        }
      }
      return filterMap;
    },
    onFilterChange: function (name, value) {},
    _validFilterKey: function (value) {
      return this._filters.hasOwnProperty(value) && value.indexOf("_") != 0 && this._filters[value] != null;
    }
  });
});