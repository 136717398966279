/**
 * ExperimentLog - Simple widget to create a log when a front-end event is to be tracked for an Optimizely metric.
 */

define(["dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/logger"], function (xhr, mUrl, logger) {
  return {
    /**
     * Logs to back-end for Optimizely only - Ex: ExperimentLog.logOptimizely("create-form", data);
     *
     * @param metricName
     * @param data
     *
     * @return null
     */
    logOptimizely: function (metricName, data) {
      xhr.post(mUrl.toUrl("track/log-optimizely"), {
        "data": {
          "metricName": metricName,
          "data": data
        },
        "handleAs": "json"
      }).then(function (response) {
        logger.info(metricName, "log", {
          response: response
        });
      });
    },
    /**
     * Logs to Optimizely, Google Analytics, and Elasticsearch
     *
     * Ex: ExperimentLog.logToAllSources("create-form", "click", data);
     * @param category
     * @param action
     * @param data
     *
     * @return null
     */
    logToAllSources: function (category, action, data) {
      var metricName = category; // Variable name specific to Optimizely log usage
      this.logOptimizely(metricName, JSON.stringify(data));
      logger.googleAnalytics.trackEvent(category, action, JSON.stringify(data)); // Google Analytics
      logger.info(category, action, {
        data: data
      }); // Elasticsearch
    }
  };
});