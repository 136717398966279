define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/peaches2/tabContainerUtil", "mojo/url", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, base, tabContainerUtil, mUrl, FilterMenu, FilterMessage, dom, topic, lang) {
  return declare([base], {
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      tabContainer.container.watch("selectedChildWidget", function (name, oldValue, newValue) {
        window.history.replaceState({
          "href": newValue.params.href
        }, "", newValue.params.href);
      });
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "campaign-details" || mUrl.getHashParams().t === "campaign-details-section") {
        var campaignIdFromUrl = new URL(window.location.href).searchParams.get("campaign_id");
        var encodedCampaignNameFromUrl = new URL(window.location.href).searchParams.get("campaign_name");
        if (campaignIdFromUrl) {
          this.viewCampaignDetails(campaignIdFromUrl, encodedCampaignNameFromUrl);
          return false;
        }
      }
    },
    /**
     *  opens the campaign details tab for the campaign with the provided id
     *  @param {String} campaign_id the id for an ACP Campaign (Project)
     *  @param {String} campaign_name the base 64 encoded name for an ACP Campaign (Project) - it is encoded because the name could contain '_' and that is the char used for the string splitting in the tool dropdown
     */
    viewCampaignDetails: function (campaign_id, campaign_name) {
      var self = this;
      var newPaneObj = {
        title: "Campaign Details: " + atob(campaign_name),
        href: mUrl.toUrl("/peaches2/users/campaign-manager", {
          _s: "campaign-details",
          user_id: self.userAttrs.userId,
          campaign_id: campaign_id,
          campaign_name: campaign_name
        }),
        stub: "campaign-details",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, "details" + campaign_id);
      var topicHandle = topic.subscribe("mojo/analytics/List/campaign-touchpoints/render", lang.hitch(this, function (list) {
        this.ctx.loadFilter("campaign-details");
        this.ctx.topicHandle.remove();
      }.bind({
        ctx: this
      })));
      this.topicHandle = topicHandle;
    },
    openCampaignTool: function (e) {
      var value = e.attr("value");
      e.reset();
      value = value.split("_");
      var action = value[0];
      var campaignId = value[1];
      var encodedCampaignName = value[2];
      // other actions to go here in the future
      switch (action) {
        case "details":
          this.viewCampaignDetails(campaignId, encodedCampaignName);
          return;
        default:
          return;
      }
    },
    loadFilter: function (section) {
      var filter = null;
      var filterMessage = null;
      if (section === "campaign-details") {
        var campaignDetailsFilter = {
          "filters": [{
            "label": "Touchpoint Type",
            "key": "touchpointType",
            "type": "select",
            "options": {
              "email": "Email",
              "event": "Event",
              "task": "Task",
              "sms": "SMS Webhook",
              "webhook": "Webhook"
            }
          }]
        };
        campaignDetailsFilter.target = "campaign-touchpoints";
        filter = new FilterMenu(campaignDetailsFilter);
        dom.byId("touchpoint-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(campaignDetailsFilter);
        dom.byId("touchpoint-filters-message").appendChild(filterMessage.domNode);
      }
    }
  });
});