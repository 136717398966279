/**
 * Copy UID
 * @module mojo/widgets/UidCopy.js
 *
 * Widget used to quickly and easily copy a UID to a browser's clipboard
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/clipboard", "mojo/utils",
// Mixins
"dijit/_WidgetBase", "dijit/_AttachMixin"], function (declare, keys, clipboard, utils, _WidgetBase, _AttachMixin) {
  return declare([_WidgetBase, _AttachMixin], {
    // Hydrated via `data-dojo-props`
    uid: "",
    /**
     * Respond to click on a node.
     * Event added by `data-dojo-attach`
     *
     * @param {Event} event - click
     * @private
     */
    _onClick: function (event) {
      this._copyUid();
    },
    /**
     * Intercept keypress and potentially call method to copy UID to clipboard.
     * Event added by `data-dojo-attach`
     *
     * @param {Event} event - onkeydown
     * @private
     */
    _onKeyDown: function (event) {
      if (event.keyCode === keys.ENTER) {
        this._copyUid();
      }
    },
    /**
     * Copy the UID to the clipboard. Show a toaster as the notification.
     *
     * @private
     */
    _copyUid: function () {
      clipboard.copy(this.uid);
      utils.toast(this.uid + " is copied ✨🎉—paste when you're ready.");
    }
  });
});