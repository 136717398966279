/**
 * Module that holds shared state values used for tracking the genai feature.
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (lang) {
  var state = {
    campaign_id: null,
    campaign_type: "",
    block_type: ""
  };
  return {
    init: function (args) {
      lang.mixin(state, args);
    },
    getEventParams: function (extraParams) {
      // change content_type to block_type to comply with general Nea ECS params that are not related to inline ai feedback.
      if (state["content_type"]) {
        Object.defineProperty(state, "block_type", Object.getOwnPropertyDescriptor(state, "content_type"));
        delete state["content_type"];
      }
      if (extraParams) {
        return lang.mixin(lang.clone(state), extraParams);
      }
      return state;
    },
    getEventParamsNeaAIInlineFeedback: function (extraParams) {
      // change block_type to content_type to comply with Nea inline ai feedback ECS params that differ from others in nea.
      if (state["block_type"]) {
        Object.defineProperty(state, "content_type", Object.getOwnPropertyDescriptor(state, "block_type"));
        delete state["block_type"];
      }
      if (extraParams) {
        return lang.mixin(lang.clone(state), extraParams);
      }
      return state;
    }
  };
});