define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/checklist-editor/items/_PostcardPreview", "mojo/neapolitan/widgets/ImageRow", "mojo/widgets/FileManager", "mojo/ImageEditor", "mojo/url", "mojo/user", "mojo/lib/logger", "mojo/lib/flags", "mojo/api/postcard", "mojo/api/promoCode", "mojo/api/ecommerce-store", "mojo/api/brand-assets", "dojo/text!./templates/postcard-content.html"], function (declare, debounce, lang, all, domClass, Renderable, ItemButtons, PostcardPreview, ImageRow, FileManager, ImageEditor, mUrl, user, logger, flags, postcardApi, promoCodeApi, ecommerceStoreApi, brandAssetsApi, tpl) {
  var REQUIRED_IMAGE_WIDTH = 1875;
  var REQUIRED_IMAGE_HEIGHT = 1350;
  var PostcardContent = declare([Renderable], {
    NO_GIFS_MESSAGE: "Whoops. We can't mail a GIF.",
    REQUIRED_SIZE_MESSAGE: "This image will look blurry when printed. Make sure the image you choose is at least {dimensions}px.",
    TYPING_SPEED_DEBOUNCE: 300,
    BODY_TEXT_MAX_CHARACTERS: 500,
    connectYourStoreLink: mUrl.toUrl("/account/connected-sites/wizard/"),
    templateString: tpl,
    defaultPreviewContent: {
      backTitle: "Big enticing headline",
      backMessage: "Use this space to tell people about your newest product or an upcoming event. Or just send out a thank you to your most valuable customers. Your call!"
    },
    defaultPreviewContentAbandoned: {
      backTitle: "Come see us again",
      backMessage: "We’re always happy to have you. Because you’re a great customer, we thought we’d send a quick note to show you how much we care."
    },
    imageRows: {
      mainImage: null,
      logoImage: null
    },
    requiredImages: ["mainImage"],
    requiredDimensions: {
      mainImage: [REQUIRED_IMAGE_WIDTH, REQUIRED_IMAGE_HEIGHT]
    },
    state: {
      isFetchingStores: false,
      isFetchingPromoCodes: false,
      isPromoCodeVisible: false,
      stores: null,
      promoCodes: [],
      promoStoreId: null,
      promoCodeId: null,
      promoRuleId: null
    },
    _promoCodesCache: {},
    constructor: function (props) {
      var postcard = props.model;
      this.state.previewData = null;
      this.state.previewToggleSide = "front";
      this.state.promoStoreId = postcard.content.promoStoreId;
      this.state.promoRuleId = postcard.content.promoRuleId;
      this.state.promoCodeId = postcard.content.promoCodeId;
      this.state.headingText = postcard.content.backTitle;
      this.state.bodyText = postcard.content.backMessage;
      this.state.isPromoCodeVisible = !!this.state.promoStoreId;
      this.state.isAbandonedCart = postcard.automationTrigger.strategy === "abandoned_cart";
      this.state.mainImage = postcard.content.frontFileUrl ? {
        contentId: postcard.content.frontFileId,
        url: postcard.content.frontFileUrl,
        src: postcard.content.frontFileUrl
      } : null;
      this.state.logoImage = postcard.content.backFileUrl ? {
        contentId: postcard.content.backFileId,
        url: postcard.content.backFileUrl,
        src: postcard.content.backFileUrl
      } : null;

      // This postcard campaign might be attached to a store, and that can't be changed here
      this.automationTriggerStore = postcard.automationTriggerStore;
      this.fetchStores();
      this.fetchPreviewContent();
      this.fetchPreviewContentDebounced = debounce(this.fetchPreviewContent, this.TYPING_SPEED_DEBOUNCE);
      if (this.state.promoStoreId) {
        this.fetchPromoCodes(this.state.promoStoreId);
      }
      this.watch("state", function (key, prevState, nextState) {
        if (this.isAPreviewUpdateTheOnlyThingThatHappened(prevState, nextState)) {
          this.renderPreviews();
        }
        if (prevState.previewToggleSide !== nextState.previewToggleSide) {
          this.handleScrollingPreview();
        }
      }.bind(this));
      var scrollingContainer = document.getElementById("wrap");
      if (props.isActive) {
        this.fetchImageMetaData(Object.keys(this.imageRows));
        scrollingContainer.addEventListener("scroll", this.handleScrollingPreview, false);
      } else {
        scrollingContainer.removeEventListener("scroll", this.handleScrollingPreview, false);
      }
      this.populateForm();
    },
    populateForm: function () {
      if (this.state.isAbandonedCart) {
        this.state.headingText = this.state.headingText || this.defaultPreviewContentAbandoned.backTitle;
        this.state.bodyText = this.state.bodyText || this.defaultPreviewContentAbandoned.backMessage;
      }
    },
    handleScrollingPreview: function () {
      var scrollingContainer = document.getElementById("wrap");
      var preview = document.getElementById("postcard-preview-container");
      var max = preview.offsetParent.offsetHeight - preview.offsetHeight;
      var diff = scrollingContainer.scrollTop - preview.offsetParent.offsetTop;
      var offset = Math.min(Math.max(0, diff), max);
      preview.style.top = offset + "px";
    },
    isAPreviewUpdateTheOnlyThingThatHappened: function (prevState, nextState) {
      var prevStateString = JSON.stringify(lang.mixin({}, prevState, {
        previewData: null
      }));
      var nextStateString = JSON.stringify(lang.mixin({}, nextState, {
        previewData: null
      }));
      return prevStateString === nextStateString && prevState.previewData !== nextState.previewData;
    },
    isFrontOfCardValid: function () {
      // make sure the front has an image
      if (!this.state.mainImage) {
        return false;
      }

      // check image's file extension
      if (this.state.mainImage.fileExtension === "gif") {
        return false;
      }

      // check if image meets required dimensions
      if (this.state.mainImage.width < this.requiredDimensions.mainImage[0] || this.state.mainImage.height < this.requiredDimensions.mainImage[1]) {
        return false;
      }
      return true;
    },
    isBackOfCardValid: function () {
      if (this.state.logoImage && this.state.logoImage.fileExtension === "gif") {
        return false;
      }
      if (this.state.promoStoreId && (!this.state.promoCodeId || !this.state.promoRuleId)) {
        return false;
      }
      if (!this.state.previewData || this.isClipped) {
        return false;
      }
      return !!(this.state.headingText && this.state.bodyText && this.isBodyTextValid());
    },
    isBodyTextValid: function () {
      return this.state.bodyText.length <= this.BODY_TEXT_MAX_CHARACTERS;
    },
    togglePromoCode: function (isChecked) {
      if (isChecked) {
        this.setState({
          isPromoCodeVisible: true,
          promoStoreId: this.automationTriggerStore ? this.automationTriggerStore.storeId : null
        });
      } else {
        this.setState({
          isPromoCodeVisible: false,
          promoStoreId: null,
          promoCodeId: null,
          promoRuleId: null
        });
      }
    },
    handleStoreChange: function (promoStoreId) {
      if (promoStoreId === "") {
        promoStoreId = null;
      }
      this.setState({
        promoStoreId: promoStoreId
      });
    },
    handlePromoCodeChange: function (promoCodeId) {
      var selectedPromoCode = this.state.promoCodes.filter(function (promoCode) {
        // Some promo codes come back as strings, some are numbers so coerce them all to strings for consistency
        return String(promoCode.promoCodeId) === String(promoCodeId);
      })[0];
      if (selectedPromoCode) {
        this.setState({
          promoCodeId: selectedPromoCode.promoCodeId,
          promoRuleId: selectedPromoCode.promoRuleId
        });
      } else {
        this.setState({
          promoCodeId: null,
          promoRuleId: null
        });
      }
      this.fetchPreviewContentDebounced();
    },
    renderPreviews: function () {
      if (this.frontPreview) {
        this.frontPreview.destroy();
      }
      if (this.backPreview) {
        this.backPreview.destroy();
      }
      var previewData = this.state.previewData || {};
      this.frontPreview = new PostcardPreview({
        name: "Front",
        previewContent: this.isFrontOfCardValid() ? previewData.frontHtml : null,
        isLoading: !this.state.previewData
      });
      this.backPreview = new PostcardPreview({
        name: "Back",
        previewContent: previewData.backHtml,
        isLoading: !this.state.previewData,
        onReady: function (isClipped) {
          if (!this.isClipped && isClipped) {
            logger.info("postcard", "postcard content was clipped", {
              "postcardId": this.model.postcardId,
              "log_classification": "sensitive"
            });
          }
          this.isClipped = isClipped;
          if (this.itemButtonsContainer) {
            this.renderActionButtons();
          }
          if (this.clippedContentError) {
            domClass.toggle(this.clippedContentError, "hide", !isClipped);
          }
        }.bind(this)
      });
      this.frontPreview.placeAt(this.frontPreviewContainer);
      this.backPreview.placeAt(this.backPreviewContainer);
      if (this.isActive) {
        var isFrontPreviewSelected = this.state.previewToggleSide === "front";
        domClass.toggle(this.frontPreviewContainer, "hide-desktop", !isFrontPreviewSelected);
        domClass.toggle(this.backPreviewContainer, "hide-desktop", isFrontPreviewSelected);
        if (isFrontPreviewSelected) {
          this.frontToggleRadio.setAttribute("checked", true);
          this.backToggleRadio.removeAttribute("checked");
        } else {
          this.backToggleRadio.setAttribute("checked", true);
          this.frontToggleRadio.removeAttribute("checked");
        }
      }
    },
    renderActionButtons: function () {
      if (this.itemButtons) {
        this.itemButtons.destroy();
      }
      this.itemButtons = new ItemButtons({
        label: "Content",
        getData: this.getData.bind(this),
        onSave: this.onSave,
        cancel: this.cancel,
        activate: this.activate,
        isActive: this.isActive,
        isValid: this.isFrontOfCardValid() && this.isBackOfCardValid()
      });
      this.itemButtons.placeAt(this.itemButtonsContainer);
    },
    shouldUpdate: function (prevState, nextState) {
      return !this.isAPreviewUpdateTheOnlyThingThatHappened(prevState, nextState);
    },
    willUpdate: function (prevState, nextState) {
      if (nextState.promoStoreId !== null && prevState.promoStoreId !== nextState.promoStoreId) {
        this.fetchPromoCodes(nextState.promoStoreId);
      }
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        this.renderActionButtons();
        Object.keys(this.imageRows).forEach(function (imageName) {
          var currentImage = this.state[imageName];
          var imageWidget = this.imageRows[imageName];
          var dimensions = this.requiredDimensions[imageName];
          var hasLoadedImage = currentImage && (currentImage.name || currentImage.src === brandAssetsApi.MERGE_TAG);
          var imageIsRequired = this.requiredImages.indexOf(imageName) === -1;
          var subtitle = dimensions ? "Required size: " + dimensions.join(" &times; ") + " px" : null;
          if (this.state.isAbandonedCart && imageName === "mainImage") {
            subtitle += "<br /> Choose an image that shows off who you are and brings people back to your store.";
          }
          if (imageWidget) {
            imageWidget.destroy();
          }
          var availableActions = hasLoadedImage ? ["edit"] : ["browse"];
          // if image is optional, add option to remove image
          if (hasLoadedImage && imageIsRequired) {
            availableActions.push("remove");
          }
          var contextMessage = null;
          var allowedImageSizes = null;
          if (imageName === "mainImage") {
            contextMessage = {
              title: "Postcards require a high resolution image",
              message: "Images must be at least " + REQUIRED_IMAGE_WIDTH + " x " + REQUIRED_IMAGE_HEIGHT + " px and in one of the following formats: JPEG, PNG.",
              type: "info"
            };
            allowedImageSizes = {
              minWidth: REQUIRED_IMAGE_WIDTH,
              minHeight: REQUIRED_IMAGE_HEIGHT
            };
          }
          this.imageRows[imageName] = new ImageRow({
            renderingType: 'post',
            imageName: imageName,
            defaultSubtitle: subtitle,
            actions: availableActions,
            image: hasLoadedImage ? currentImage : null,
            onChange: this.onImageChange.bind(this, imageName),
            contextMessage: contextMessage,
            allowedFileTypes: ["jpg", "jpeg", "png"],
            allowedImageSizes: allowedImageSizes
          });
          if (currentImage && currentImage.url) {
            if (currentImage.fileExtension === "gif") {
              // Going to reach out to technical content for better messaging.
              this.imageRows[imageName].showRestrictionFeedback("browse", this.NO_GIFS_MESSAGE);
            } else if (dimensions && (currentImage.width < dimensions[0] || currentImage.height < dimensions[1])) {
              var message = lang.replace(this.REQUIRED_SIZE_MESSAGE, {
                dimensions: dimensions.join(" &times; ")
              });
              this.imageRows[imageName].showRestrictionFeedback("browse", message);
            }
          }
          this.imageRows[imageName].placeAt(this[imageName + "Container"]);
        }, this);
      }
      this.renderPreviews();
    },
    /**
     * Updates the state based on a text field update.
     *
     * @param {Event} e field change event
     */
    updateTextField: function (e) {
      var fieldName = e.target.name;
      var newFieldValue = e.target.value;

      // Mutate state directly to prevent re-renders
      this.state[fieldName] = newFieldValue;
      this.state.previewData = null;
      this.renderPreviews();
      this.renderActionButtons();
      this.toggleValidation(fieldName, newFieldValue);
      this.fetchPreviewContentDebounced(e);
    },
    toggleValidation: function (name, value) {
      switch (name) {
        case "bodyText":
          var isValid = this.isBodyTextValid();
          domClass.toggle(this.bodyTextInput, "invalid", !isValid);
          domClass.toggle(this.bodyTextInvalidErrorMessage, "hide", isValid);
          return;
        default:
          break;
      }
    },
    /**
     * Forces the back side of the preview to be shown on focus
     */
    focusTextField: function () {
      this.state.previewToggleSide = "back";
      this.renderPreviews();
    },
    /**
     * Updates which preview side to show
     *
     * @param {Event} e change event
     */
    togglePreviewSide: function (e) {
      this.setState({
        previewToggleSide: e.target.value
      });
    },
    /**
     * Stores an uploaded image to the state
     *
     * @param {string} name name of the image, corresponding to its state attribute name
     */
    onImageChange: function (name) {
      var nextState = {};
      var rowImage = this.imageRows[name].image;
      nextState[name] = rowImage ? lang.mixin({}, rowImage, {
        name: null,
        url: rowImage.originalUrl
      }) : null;
      nextState.previewToggleSide = name === "mainImage" ? "front" : "back";
      this.setState(nextState);
      this.fetchPreviewContent();
      if (rowImage) {
        this.fetchImageMetaData([name]);
      }
    },
    /**
     * Fetches the preview data for front/back of card from the server and sets state.
     */
    fetchPreviewContent: function () {
      var postcardContent = this.getData().content;
      if (this.state.isAbandonedCart) {
        postcardContent.backTitle = postcardContent.backTitle || this.defaultPreviewContentAbandoned.backTitle;
        postcardContent.backMessage = postcardContent.backMessage || this.defaultPreviewContentAbandoned.backMessage;
      } else {
        postcardContent.backTitle = postcardContent.backTitle || this.defaultPreviewContent.backTitle;
        postcardContent.backMessage = postcardContent.backMessage || this.defaultPreviewContent.backMessage;
      }
      postcardApi.getContentPreview(postcardContent).then(function (preview) {
        this.setState({
          previewData: preview
        });
      }.bind(this));
    },
    /**
     * Fetches meta data for a list of images
     *
     * @param {string[]} fileKeys a list of keys corresponding to files in this.state
     */
    fetchImageMetaData: function (fileKeys) {
      var existingFiles = fileKeys.filter(function (key) {
        return this.state[key];
      }, this);
      all(existingFiles.map(function (key) {
        var image = this.state[key];
        return image.src === brandAssetsApi.MERGE_TAG ? image : FileManager.getInfo(image.contentId);
      }, this)).then(function (fileMetas) {
        return all(fileMetas.map(function (meta, fileIndex) {
          if (meta.src === brandAssetsApi.MERGE_TAG) {
            return meta;
          }
          var currentImage = this.state[fileKeys[fileIndex]];
          return ImageEditor.getImageInfo(currentImage.url || meta.url).then(function (imageMeta) {
            return lang.mixin({}, meta, imageMeta, {
              src: imageMeta.url,
              url: imageMeta.originalUrl
            });
          });
        }, this));
      }.bind(this)).then(function (imageMetas) {
        var nextState = {};
        imageMetas.forEach(function (meta, fileIndex) {
          nextState[fileKeys[fileIndex]] = lang.mixin({}, meta);
        }, this);
        this.setState(nextState);
      }.bind(this));
    },
    fetchStores: function () {
      this.setState({
        isFetchingStores: true
      });
      return ecommerceStoreApi.list().then(function (stores) {
        this.setState({
          stores: stores,
          isFetchingStores: false
        });
      }.bind(this));
    },
    fetchPromoCodes: function (promoStoreId) {
      if (this._promoCodesCache[promoStoreId]) {
        this.setState({
          promoCodes: this._promoCodesCache[promoStoreId]
        });
        return;
      }
      this.setState({
        isFetchingPromoCodes: true
      });
      return promoCodeApi.fetchByStoreId(promoStoreId).then(function (promoCodes) {
        this._promoCodesCache[promoStoreId] = promoCodes;
        this.setState({
          isFetchingPromoCodes: false,
          promoCodes: promoCodes
        });
      }.bind(this));
    },
    getData: function () {
      var frontFileUrl = null;
      if (this.state.mainImage) {
        frontFileUrl = this.state.mainImage.src === brandAssetsApi.MERGE_TAG ? this.state.mainImage.src : this.state.mainImage.url;
      }
      var backFileUrl = null;
      if (this.state.logoImage) {
        backFileUrl = this.state.logoImage.src === brandAssetsApi.MERGE_TAG ? this.state.logoImage.src : this.state.logoImage.url;
      }
      return {
        content: {
          backTitle: this.state.headingText,
          backMessage: this.state.bodyText,
          frontFileId: this.state.mainImage && this.state.mainImage.src !== brandAssetsApi.MERGE_TAG ? this.state.mainImage.contentId : null,
          frontFileUrl: frontFileUrl,
          backFileId: this.state.logoImage && this.state.logoImage.src !== brandAssetsApi.MERGE_TAG ? this.state.logoImage.contentId : null,
          backFileUrl: backFileUrl,
          promoStoreId: this.state.promoStoreId,
          promoCodeId: this.state.promoCodeId,
          promoRuleId: this.state.promoRuleId
        }
      };
    }
  });
  PostcardContent.isComplete = function (model) {
    return model.content.backTitle && model.content.backMessage && model.content.frontFileId;
  };
  return PostcardContent;
});