define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/smart-mark/SmartMark", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/utils/I18nTranslation", "dojo/text!./templates/CustomerJourneyBannerCTA.html"], function (declare, on, SmartMark, _WidgetBase, _TemplatedMixin, I18nTranslation, templateString) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: templateString,
    ctaLink: null,
    zeroStateTitle: "Automate more than email—automate a Customer Journey",
    zeroStateDescription: "With Customer Journeys, you map an experience you want to give your customers and get a clear picture of all the paths they can take.",
    ctaText: "Try it out",
    _trackCtaLink: function (event) {
      event.preventDefault();
      window.ga("send", "event", "campaigns", "cta", "inline", {
        "dimension53": event.target.innerText,
        "dimension54": "/customer-journey",
        "dimension55": "campaigns list",
        "dimension56": "navigate"
      });
      window.location.href = "/customer-journey";
    },
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.zeroStateTitle = translations.campaigns_zero_cjy_title;
        this.zeroStateDescription = translations.campaigns_zero_cjy_description;
        this.ctaText = translations.campaigns_zero_cjy_banner_btn;
      }.bind(this));
    },
    postCreate: function () {
      var smartMarkInfoBlock = new SmartMark({
        tier: 2,
        condensed: true
      });
      smartMarkInfoBlock.placeAt(this.smartMarkInfoBlock, "only");
      smartMarkInfoBlock.triggerAnimation();
      on(this.ctaLink, "click", this._trackCtaLink);
    }
  });
});