define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/tabContainerUtil"], function (declare, base, domAttr, on, mUrl, topic, tabContainerUtil) {
  return declare([base], {
    /**
     * passed in from the backend
     */
    userId: null,
    onReady: function () {
      var self = this;
      var tabContainer = new tabContainerUtil();
      this.tabContainer = tabContainer;
      topic.subscribe("mojo/analytics/List/billing-orders-pager/render", function (list) {
        self.parse(self.elId);
        if (self.$group.viewOrderDetailsLink) {
          self.$group.viewOrderDetailsLink.forEach(function (el) {
            on(el, "click", function () {
              self.viewOrderDetails(domAttr.get(el, "data-order-id"));
            });
          });
        }
      });
    },
    /**
     * adds the order details content pane
     * @param {number} id - order id
     */
    viewOrderDetails: function (id) {
      var self = this;
      var newPaneObj = {
        title: "Order Details: " + id,
        href: mUrl.toUrl("/peaches2/tools/purged-accounts/order-details", {
          user_id: self.userId,
          order_id: id
        }),
        stub: "order-details",
        closable: true,
        onClose: function () {
          self.pageJs.tabContainer.orderDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, id, "order-details", "billing-tab");
    }
  });
});