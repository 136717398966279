define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dijit/_Templated", "dijit/_Widget", "dijit/form/RadioButton", "mojo/neapolitan/widgets/NavigationList", "mojo/utils", "mojo/context", "mojo/lib/flags", "./_EditorMixin", "./GlobalElementStyleEditor", "dojo/text!./templates/navigationEditor.html"], function (declare, lang, array, domClass, domConstruct, on, Stateful, _Templated, _Widget, RadioButton, NavigationList, utils, context, flags, _EditorMixin, StyleEditor, tpl) {
  var linkTypes = {
    INTERNAL: "internalLink",
    GENERIC: "genericLink",
    PAGE: "pageLink"
  };
  var createDropdownOptionsFromLinks = function (arr) {
    return array.map(arr, function (opt) {
      return {
        label: opt.text,
        value: opt.link
      };
    });
  };
  return declare([_Widget, _Templated, _EditorMixin], {
    templateString: tpl,
    widgetsInTemplate: true,
    draggableLinkItem: false,
    model: null,
    links: [],
    initialLinksSnapshot: null,
    initialStylesSnapshot: null,
    initialEnabledSnapshot: null,
    stylingProperties: {
      horizontal: {
        containerStyles: "position: static;",
        logoStyles: "float: left;",
        navigationStyles: "float: right;"
      },
      vertical: {
        containerStyles: "text-align: center;",
        logoStyles: "float: none; display: block;",
        navigationStyles: "float: none; display: block; padding-left: 0;"
      }
    },
    postCreate: function (params) {
      this.inherited(arguments);
      this.initialLinksSnapshot = this.model.get("links").slice();
      this.initialStylesSnapshot = this.model.get("styles").slice();

      // "Enabled" switch element
      this.navigationEnabledSwitch.set("value", this.model.get("enabled"));

      // Create navigation links in the editor
      this.linkList = this._createLinkList().placeAt(this.linkList);
      // Below listeners are driven by the ItemList module
      this.connect(this.linkList, "onChange", "_onAddNavigationLink");
      this.connect(this.linkList, "onContentChange", "_onUpdateItems");

      // Set up event listener for changing of the "Enabled" value on the model
      this.connect(this.navigationEnabledSwitch, "onChange", "_onEnabledToggle");
      this._toggleElements(this.enabled);
      this._setupStyleEditor();
    },
    _setupStyleEditor: function () {
      if (this.styleEditorContainer) {
        var styleEditor = this._getStyleEditor();
        styleEditor.startup();
        domConstruct.place(styleEditor.domNode, this.styleEditorContainer);
        this.watch("styles", lang.hitch(this, function () {
          styleEditor.updateInputs(this.model.styles);
        }));
      }
    },
    _getStyleEditor: function () {
      if (!this.styleEditor || this.styleEditor._destroyed) {
        var styles = lang.clone(this.model.styles);
        this.styleEditor = new StyleEditor({
          "styles": styles,
          "type": "Styles"
        });
        this.connect(this.styleEditor, "onChange", function (newStyle) {
          this.model.set("styles", newStyle);
        });
        this.connect(this.styleEditor, "onCancel", function (newStyle) {
          this.model.set("styles", newStyle);
          this._done();
        });
      }
      return this.styleEditor;
    },
    _onAddNavigationLink: function () {
      this.model.set("links", this.linkList.getOutputModel());
    },
    _createLinkList: function () {
      var itemTypeArray;
      itemTypeArray = [{
        value: linkTypes.PAGE,
        label: "Page"
      }, {
        value: linkTypes.GENERIC,
        label: "Link"
      }];
      var linkListOptions = {
        options: this.model.get("options"),
        showAddButton: true,
        linkMap: this.model.get("linkMap"),
        addButtonText: "Add Link",
        items: this.model.get("links"),
        draggableItems: true,
        doFilterAvailableItemTypes: false,
        availableItemTypes: itemTypeArray
      };
      return new NavigationList(linkListOptions);
    },
    _restoreInitialState: function () {
      this.model.set("links", this.initialLinksSnapshot);
      this.model.set("styles", this.initialStylesSnapshot);
    },
    _onLayoutChange: function (val) {
      var style = this.stylingProperties[val.currentTarget.value];
      this.model.set("containerStyles", style.containerStyles);
      this.model.set("logoStyles", style.logoStyles);
      this.model.set("navigationStyles", style.navigationStyles);
      this.model.set("layoutSettings", {
        orientation: val.currentTarget.value,
        position: "static"
      });
    },
    _toggleElements: function (isSelected) {
      if (isSelected) {
        domClass.add(this.navigationOverlay, "hide");
      } else {
        domClass.remove(this.navigationOverlay, "hide");
      }
    },
    _onEnabledToggle: function (val) {
      this.model.set("enabled", val);
      this._toggleElements(this.model.get("enabled"));
    },
    _setupInputs: function () {
      this.navigationEnabledSwitch.set("value", this.model.get("enabled"));
    },
    _onUpdateItems: function () {
      this.model.set("links", this.linkList.getOutputModel());
    },
    updateEnabled: function () {},
    _onDone: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.onSave(this.model, true);
      this.done();
    },
    _onCancel: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this._restoreInitialState();
      this.done();
    },
    onClose: function () {
      this.destroy();
    }
  });
});