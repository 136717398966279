define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dijit/registry", "dijit/Dialog", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/ClientKeyInfoModal.html", "mojo/utils"], function (declare, base, lang, domAttr, on, query, mUrl, registry, Dialog, xhr, clientKeyInfoModal, utils) {
  return declare([base], {
    /**
     * Shows client key info dialog and saves request key for later
     * @param {number} listID - The id of the list attached to the key.
     * @param {string} type - The type of dialog we should show.
     */
    showClientKeyInfo: function (listID, type) {
      var title = type === "merge" ? "View Merge Fields" : "View Marketing Permissions";
      var dialog_url = mUrl.toUrl("/account/client-key-info?type=" + type + "&list_id=" + listID);
      xhr.get(dialog_url, {
        handleAs: "json",
        content: {
          list_id: listID,
          type: type
        }
      }).then(function (response) {
        var clientModal = new Dialog({
          content: utils.compileTemplate(clientKeyInfoModal, {
            textToCopy: response.text_snippet,
            kotlinSnippet: response.kotlin_snippet,
            swiftSnippet: response.swift_snippet
          }),
          onCancel: function () {
            tabContainer.destroyRecursive();
          },
          title: title,
          style: "width: 600px; height: auto;"
        });
        //show modal
        clientModal.show();
        var tabContainer = registry.byId("copyCodeTabContainer");
        var copyTextBtn = clientModal.domNode.querySelector("[data-client-modal='copyTextBtn']");
        var doneBtn = clientModal.domNode.querySelector("[data-client-modal='doneBtn']");
        var _copyCode = function (copyBtn) {
          var selectedTab = dijit.byId('copyCodeTabContainer').selectedChildWidget.srcNodeRef;
          var selectedTextArea = selectedTab.querySelector('textarea');
          selectedTextArea.select();
          document.execCommand("copy");
          copyBtn.innerText = "Copied!";
        };
        var _resetButtonText = function (copyBtn) {
          copyBtn.innerText = "Copy to Clipboard";
        };
        var _done = function () {
          clientModal.hide();
          tabContainer.destroyRecursive();
        };
        on(copyTextBtn, "click", lang.hitch(this, _copyCode, copyTextBtn));

        // Watch the tabs and update the button
        tabContainer.watch("selectedChildWidget", function (name, oval, nval) {
          _resetButtonText(copyTextBtn);
        });
        on(doneBtn, "click", lang.hitch(this, _done));
      });
    },
    onReady: function () {
      this._initApiSdkBetaBannerButtons();
    },
    _initApiSdkBetaBannerButtons: function () {
      var dismissBetaBannerButton = query("[data-mc-el='dismissApiSdkBetaBanner']")[0];
      if (dismissBetaBannerButton) {
        on(dismissBetaBannerButton, "click", function () {
          xhr(mUrl.toUrl("/account/dismiss-sdk-beta-banner")).then(function () {
            var sdkBetaBannerElement = query("[data-mc-el='api-sdk-beta-banner']")[0];
            Velocity(sdkBetaBannerElement, {
              opacity: [0, [0.7, 0, 0.175, 1], 1],
              maxHeight: [0, 400]
            }, {
              duration: 500,
              display: "none"
            });
          });
        });
      }
    }
  });
});