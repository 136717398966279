define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Renderable
"mojo/widgets/Renderable",
// Mojo utils
"mojo/user", "mojo/lib/flags",
// Templates
"dojo/text!./templates/CampaignChecklistItemRec.html"], function (declare, lang, on, domConstruct, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Renderable, user, flags, recsTemplate) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Renderable], {
    templateString: recsTemplate,
    state: {
      recommendation: null,
      successful: false,
      hasSeenRecipientsRec: false,
      hasSeenSubjectRec: false,
      hasSeenPreviewRec: false,
      forceHide: null
    },
    /**
     * Constructor
     *
     * @param props
     */
    constructor: function (props) {
      /**
      *A user account that has less than 2 contacts in their audience will get
      an empty state on their audience tab
      *This also checks how many contacts a user has
      */
      this.state.forceHide = props.forceHide;

      // Which recommendation is being mounted and rendered | String
      this.state.recommendation = props.recommendation;

      // Expected to be true if the conditions of this recommendation are met | Boolean
      this.state.successful = props.successful;

      // The recipients recommendation has been seen | Boolean|Undefined|null
      this.state.hasSeenRecipientsRec = props.hasSeenRecipientsRec;

      // The subject line recommendations has been seen | Boolean|Undefined|null
      this.state.hasSeenSubjectRec = props.hasSeenSubjectRec;

      // The preview text recommendations has been seen | Boolean|Undefined|null
      this.state.hasSeenPreviewRec = props.hasSeenPreviewRec;

      // The recipient success state has previously been triggered | Boolean|Undefined|null
      this.state.hasSeenRecipientsSuccess = props.hasSeenRecipientsSuccess;

      // The subject line success state has previously been triggered | Boolean|Undefined|null
      this.state.hasSeenSubjectSuccess = props.hasSeenSubjectSuccess;

      // The preview text success state has previously been triggered | Boolean|Undefined|null
      this.state.hasSeenPreviewSuccess = props.hasSeenPreviewSuccess;

      // A bound function for passing persistent context into parents
      this.addPersistentExtraProps = props.addPersistentExtraProps;
    },
    /**
     * postCreat
     *
     * @link https://dojotoolkit.org/reference-guide/1.10/dijit/_WidgetBase.html
     */
    postCreate: function () {
      this.inherited(arguments);
      // Display alternative styling if this recommendation badge is shown 
      // alongside the Smart Mark
      domClass.add(this.blockContainer, 'c-recBlock--withSmartMark');

      // If the recommendation has been seen and this is now in a "successful"
      // state trigger success animations.
      if (this.state.successful && this.hasSeenRecommendation(this.state.recommendation) && !this.hasSeenSuccess(this.state.recommendation)) {
        setTimeout(function () {
          // Handle the icon switch
          domClass.remove(this.iconContainer, "recommendation");
          domClass.add(this.iconContainer, "success");

          // Handle the text switch
          domClass.remove(this.messageContainer, "recommendation");
          domClass.add(this.messageContainer, "success");
        }.bind(this), 1000);

        //trigger success fadeout
        setTimeout(function () {
          domClass.add(this.blockContainer, "recommendation-fade-out");
        }.bind(this), 5000);
        setTimeout(function () {
          domClass.add(this.blockContainer, "display--none");
        }.bind(this), 5210);
        this.setHasSeenSuccess(this.state.recommendation);
      }

      // If this component was mounted without successful state,
      // mark the recommendations as being seen.
      if (!this.state.successful) {
        this.setRecHasBeenSeen(this.state.recommendation);
      }

      /**
       * If a user has 0 contacts, or If a user also gets the empty state on their audience tab as well,
       * then the long recommendation will be hidden from below the Personalize to field
       */
      if (this.state.forceHide) {
        domClass.add(this.blockContainer, "hide");
      }
    },
    /**
     * A function for evaluating if the mounted recommendation has been seen previously
     *
     * @param recommendation
     * @return {boolean}
     */
    hasSeenRecommendation: function (recommendation) {
      switch (recommendation) {
        case "recipients":
          return this.state.hasSeenRecipientsRec;
        case "subject":
          return this.state.hasSeenSubjectRec;
        case "preview":
          return this.state.hasSeenPreviewRec;
        default:
          return false;
      }
    },
    /**
     * Uses parents persistent context to set context for having seen this particular recommendation
     *
     * @param recommendation
     * @return {null}
     */
    setRecHasBeenSeen: function (recommendation) {
      switch (recommendation) {
        case "recipients":
          this.addPersistentExtraProps({
            hasSeenRecipientsRec: true
          });
          break;
        case "subject":
          this.addPersistentExtraProps({
            hasSeenSubjectRec: true
          });
          break;
        case "preview":
          this.addPersistentExtraProps({
            hasSeenPreviewRec: true
          });
          break;
        default:
          return null;
      }
    },
    /**
     * Uses parents persistent context to set context for having seen this particular recommendations success state
     *
     * @param recommendation
     * @return {null}
     */
    setHasSeenSuccess: function (recommendation) {
      switch (recommendation) {
        case "recipients":
          this.addPersistentExtraProps({
            hasSeenRecipientsSuccess: true
          });
          break;
        case "subject":
          this.addPersistentExtraProps({
            hasSeenSubjectSuccess: true
          });
          break;
        case "preview":
          this.addPersistentExtraProps({
            hasSeenPreviewSuccess: true
          });
          break;
        default:
          return null;
      }
    },
    /**
     * A function for evaluating if the mounted recommendations success state has been seen previously
     *
     * @param recommendation
     * @return {boolean}
     */
    hasSeenSuccess: function (recommendation) {
      switch (recommendation) {
        case "recipients":
          return this.state.hasSeenRecipientsSuccess;
        case "subject":
          return this.state.hasSeenSubjectSuccess;
        case "preview":
          return this.state.hasSeenPreviewSuccess;
        default:
          return false;
      }
    }
  });
});