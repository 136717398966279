/**
 * Billing Slat
 *
 * The mobile version of the billing sidebar and entry-point to editing billing information. This should
 * be flexible enough to use on both Google and Facebook ads.
 *
 * "__methodName" denotes required implementation.
 *
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "mojo/api/billing", "mojo/lib/flags", "mojo/url", "mojo/user", "mojo/utils", "mojo/widgets/billing/PaymentMethod",
// Mixins
"./_EventAdapterMixin", "dijit/_TemplatedMixin", "dojo/text!./templates/BillingSlat.html"], function (declare, lang, dom, domAttr, domClass, domConstruct, all, query, request, billingApi, flags, mUrl, user, utils, PaymentMethod, _EventAdapterMixin, _TemplatedMixin, tplStr) {
  return declare([_EventAdapterMixin, _TemplatedMixin], {
    templateString: tplStr,
    postCreate: function () {
      this._initBillingSummary();
    },
    /**
     * Reach out to the backend to get the Billing Summary to display basic card information.
     * Currently this is broken out into two separate requests and spliced together with promise/all.
     *
     * TODO: Create controller endpoint capable of passing back itemized order in JSON.
     *
     * @private
     */
    _initBillingSummary: function () {
      var promises = {
        sidebar: this._getDesktopBillingSidebar(),
        paymentMethod: billingApi.getPaymentMethod()
      };
      all(promises).then(function (results) {
        this._handleSidebarResponse(results.sidebar);
        this._handlePaymentMethodResponse(results.paymentMethod);
      }.bind(this), function (error) {
        throw new Error("An error occurred retrieving the billing summary: " + error);
      });

      // Tell the bridge that the slat is ready to show.
      this.onLoaded();
    },
    /**
     * Hydrate the order breakdown with a piece of Avesta-rendered markup.
     *
     * Here be bad dom things. Be afraid.
     *
     * @param {String} sidebarMarkup - HTTP response from ads/billingSummaryAction
     *
     * @private
     */
    _handleSidebarResponse: function (sidebarMarkup) {
      var docFragment = domConstruct.toDom(sidebarMarkup);

      // Grab the actually useful part of the billing summary we get back from the backend.
      // This is dirty. Fortunately it's purely presentational.
      var summaryNode = query("[data-mc-el='itemizedSummary']", docFragment)[0];

      // Inject into the dom.
      domConstruct.place(summaryNode, this.itemizedOrderContainer, "only");
      domClass.remove(this.summaryContainer, "hide");
    },
    /**
     * Handle the response from the server
     *
     * @param {Object} billingData - data back from account/billing/paymentMethodAction
     *
     * @private
     */
    _handlePaymentMethodResponse: function (billingData) {
      this.billingData = billingData;
      this.summary = new PaymentMethod(this.billingData);
      this.summary.placeAt(this.paymentMethodContainer);
      this.summary.startup();
      this.set("paymentType", this.billingData.payment_type);
    },
    /**
     * Custom setter for this.paymentType
     *
     * @param {String} type - "none, credit_card, etc"
     *
     * @private
     */
    _setPaymentTypeAttr: function (type) {
      domClass.toggle(this.paymentMethodContainer, "hide", type === "none");
    },
    /**
     * Call out to the Ads controller and get some Avesta-rendered Markup. This is ideally
     * temporary until we can get the breakdown in JSON.
     *
     * @returns {*|Promise} Promise object representing the HTTP post response.
     * @deprecated - Look for other ways to get the billing breakdown.
     *
     * @private
     */
    _getDesktopBillingSidebar: function () {
      return request.get(mUrl.toUrl("/ads/billing-summary", {
        id: this.id
      }), {
        handleAs: "text"
      });
    },
    /**
     * Called by the container widget to tell this widget to save and close.
     *
     * TODO: Don't call `onDone()` until we're sure that they've saved/canceled any pending changes
     */
    __save: function () {
      this.onDone({});
    },
    // The Billing slat doesn't have the concept of Cancel or back. Do nothing with these required
    // methods.
    __cancel: function () {},
    __back: function () {}
  });
});