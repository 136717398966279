define(["dojo-proxy-loader?name=dojo/main!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry"], function (dojo, declare, base, on, query, domConstruct, domAttr, dijit) {
  return declare([base], {
    onReady: function () {
      var parentOption = this.$el.parentOption;
      var _mc = window._mc;
      // get all permission checkboxes
      var permissionCheckboxes = query(".permission");
      var profilePermissions = _mc.profile_permissions;
      var removeParentWarning = this.$el.removeParentWarning;
      if (_mc.profile) {
        // check current profile's permissions' checkboxes
        permissionCheckboxes.forEach(function (node) {
          var checkbox = dijit.getEnclosingWidget(node);
          if (profilePermissions.includes(checkbox.value)) {
            checkbox.set({
              checked: true
            });
          }
        });
        domAttr.set("profile_name", "value", _mc.profile.profile_name);
        domAttr.set("parent_profile_id", "value", _mc.profile.parent_profile_id);
        var index = dojo.byId("parent_profile_id").selectedIndex;
        if (index > -1) {
          var selectedParentProfileName = parentOption.options[index].text;
          disableParentPermissions(null, selectedParentProfileName);
        }
      }
      on(parentOption, "change", function () {
        disableParentPermissions(event, null);
        if (_mc.remove_parent_profile_flag) {
          showRemoveParentWarning();
        }
      });

      //disable default function of return key
      on(document, "keyup", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });
      var selectedParentProfile = "";
      function showRemoveParentWarning() {
        if (parentOption.value === "") {
          removeParentWarning.style.display = "block";
        } else {
          removeParentWarning.style.display = "none";
        }
      }
      function disableParentPermissions(event, parent_name) {
        if (parent_name) {
          domAttr.set("profile_name", "value", _mc.profile.profile_name);
          domAttr.set("parent_profile_id", "value", _mc.profile.parent_profile_id);
          var selectedIndex = dojo.byId("parent_profile_id").selectedIndex;
          selectedParentProfile = parentOption.options[selectedIndex].text;
        } else {
          selectedParentProfile = parentOption.options[event.target.selectedIndex].text;
        }

        // remove parent permission indicators
        var parentPermissionIndicators = dojo.query(".parent-permission-indicator");
        if (parentPermissionIndicators.length > 0) {
          dojo.query(".parent-permission-indicator").forEach(function (node) {
            dojo.destroy(node);
          });
        }

        // uncheck & enabled each parent profile permission checkbox
        permissionCheckboxes.forEach(function (node) {
          var checkbox = dijit.getEnclosingWidget(node);
          if (!profilePermissions.includes(checkbox.value)) {
            checkbox.set({
              checked: false,
              disabled: false
            });
          }
          var permissionProfiles = _mc.profile_hierarchy_permissions[selectedParentProfile];
          for (var permissionProfile in permissionProfiles) {
            if (permissionProfiles[permissionProfile].includes(checkbox.value)) {
              // disable permission checkboxes of that belong to parents
              // display the parent that already has the permission
              checkbox.set({
                disabled: true,
                checked: "checked"
              });
              var checkbox_node = dojo.byId(checkbox.value);
              domConstruct.place("<span class='parent-permission-indicator' style='font-style: italic;'><span class='freddicon arrow-left'></span> " + permissionProfile + "", checkbox_node, "after");
            }
          }
        });
      }
    }
  });
});