define(["dojo-proxy-loader?name=dojo!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/lib/logger", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/CharCount", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/pages/FaviconEditor", "dojo/text!./templates/page-title.html", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags"], function (dojo, declare, utils, logger, Renderable, CharCount, ItemButtons, FaviconEditor, tpl, I18nTranslation, context, flags) {
  var DEFAULT_TITLE = "Untitled Page";
  var LP_TRACKING_CATEGORY = "landing pages editor";
  var LP_TRACKING_ACTION = "page title and site icon";
  var MAX_CHARACTER_LENGTH = 150;
  var PageTitle = declare([Renderable], {
    templateString: tpl,
    TITLE_MAX_LENGTH: MAX_CHARACTER_LENGTH,
    titleErrorMsg: "Please enter a page title.",
    thisWillAppear: "This will appear in the browser's title bar.",
    constructor: function (props) {
      this.state = {
        errors: props.errors || {},
        title: props.model.title ? this.encodeTitle(props.model.title) : "",
        faviconUrl: props.model.faviconUrl || null,
        dirtyFields: {}
      };
      this.inherited(arguments);
      this.titleCharCount = new CharCount({
        maxCharacterLength: this.TITLE_MAX_LENGTH
      });
      this.onSave = props.onSave;
      this._initFaviconEditor();
    },
    encodeTitle: function (str) {
      return str.replace(/'/g, "&#039;").replace(/</g, "&lt;");
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.thisWillAppear = I18nTranslation.translate("landing_pages_this_will_appear");
      }
    },
    getData: function () {
      var nextState = {
        title: this.state.title,
        faviconUrl: this.state.faviconUrl
      };
      return nextState;
    },
    setValue: function (event) {
      // check brand token of url, set if empty
      var isTitle = event.target;
      var newTitle;
      var errors = {};
      if (isTitle) {
        this.preserveFocus(event);
        newTitle = event.target.value;
        var titleError = this.validate("title", newTitle);
        if (titleError) {
          errors.title = titleError;
        }
      } else {
        newTitle = event;
      }
      newTitle = this.encodeTitle(newTitle);
      var nextState = {
        errors: errors
      };
      nextState[event.target.name] = event.target.value;
      nextState.title = newTitle;
      this.setState(nextState);
    },
    /**
     * Method add tracking or perform any other actions when user leaves input field
     *
     * @param {event} e - Pointer Event
     */
    _handleTitleBlur: function (e) {
      var inputValue = e.target.value;
      logger.googleAnalytics.trackEvent(LP_TRACKING_CATEGORY, LP_TRACKING_ACTION, "Input value into title field", {
        dimension58: "title field value",
        dimension59: inputValue
      });
    },
    onSaveError: function (error) {},
    validate: function (field, value) {
      var error;
      if (field === "title" && value === "") {
        error = this.titleErrorMsg;
      }
      return error;
    },
    /**
     * After a user selects an image handle placing the image and checking for any errors
     *
     * @param {obj} selectedImage - The selected inserted image from Content Studio and data assigned to it
     * @private
     */
    _handleImageSelection: function (selectedImage) {
      this.faviconEditor.validateAndUpdatePreview(selectedImage);
      this.setState({
        faviconUrl: selectedImage.url
      });

      // Track when a user inserts an image for their site icon
      logger.googleAnalytics.trackEvent(LP_TRACKING_CATEGORY, LP_TRACKING_ACTION, "Set Image for custom favicon", {});
    },
    /**
     * Creates a new instance of the Favicon Editor on render
     *
     * @private
     */
    _initFaviconEditor: function () {
      this.faviconEditor = new FaviconEditor({
        faviconUrl: this.state.faviconUrl,
        onImageSelection: this._handleImageSelection.bind(this)
      });
    },
    render: function () {
      if (this.domNode.innerHTML === "") {
        this.inherited(arguments);
      }
      if (this.isActive) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.titleCharCount.placeAt(this.titleCharCountContainer);
        this.titleCharCount.setCount(this.TITLE_MAX_LENGTH - utils.getSymbolLength(this.state.title));
        if (this.faviconEditor) {
          this.faviconEditor.placeAt(this.faviconEditorContainer);
          this.faviconEditor.setPageTitle(this.state.title);
        }
        this.itemButtons = new ItemButtons({
          label: "Page Title",
          getData: this.getData.bind(this),
          onSaveError: this.onSaveError.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          activate: this.activate,
          deactivate: this.deactivate,
          isActive: this.isActive,
          isValid: Object.keys(this.state.errors).length === 0 && !this.faviconEditor.state.isFaviconImageInvalid
        });
        this.itemButtons.placeAt(this.itemButtonsContainer);
      }
    }
  });

  // Static methods used by the checklist item
  PageTitle.isComplete = function (model) {
    // Page title is set to Untitled Page if the internal campaign name is not set.
    return model.title && model.title !== DEFAULT_TITLE;
  };
  PageTitle.isValid = function (page) {
    return utils.getSymbolLength(page.title) <= MAX_CHARACTER_LENGTH;
  };
  return PageTitle;
});