define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dijit/registry", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, topic, base, mUrl, tabContainerUtil, FilterMenu, FilterMessage, registry, query, on) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.changeTab(window.location.href);
      var smsMenuItems = query("#sms-list a");
      on(smsMenuItems, "click", function () {
        self.changeTab(this.getAttribute("href"));
      });
      on(this.$el.createTransportButton, "click", function () {
        registry.byId("create-transport-dialog").show();
      });
      on(this.$el.createTransportCancelButton, "click", function () {
        registry.byId("create-transport-dialog").hide();
      });
      var createMessagingProgramLinks = query(".create-messaging-program-link");
      on(createMessagingProgramLinks, "click", function (e) {
        e.preventDefault();
        self.$el.createMessagingProgramDialogForm.reset();
        self.$el.transport_id.value = this.dataset.transportId;
        registry.byId("create-messaging-program-dialog").show();
      });
      on(this.$el.createMessagingProgramCancelButton, "click", function () {
        registry.byId("create-messaging-program-dialog").hide();
      });
      query("form").forEach(function (element) {
        element.removeAttribute("novalidate");
      });
    },
    changeTab: function (url) {
      var tabContainer = registry.byId("TabContainerId");
      var campaignDetailsPane = registry.byId("campaign-details-tabs");
      var brandInfoPane = registry.byId("brandInfoTab");
      var campaignsTab = registry.byId("campaignsTab");
      var transportsTab = registry.byId("transportsTab");
      if (campaignsTab && campaignsTab.selected) {
        // If the Campaigns tab is selected, select the Campaign Details pane
        tabContainer.selectChild(campaignDetailsPane);

        // And deselect the Brand Information pane
        if (brandInfoPane) {
          brandInfoPane.selected = false;
        }
      } else {
        if (url.indexOf("transports") > -1) {
          tabContainer.selectChild(transportsTab);
          return;
        }
        var urlParams = new URLSearchParams(url);
        if (urlParams.get("campaign_id")) {
          tabContainer.selectChild(campaignDetailsPane);
          if (urlParams.get("subtab")) {
            campaignDetailsPane.selectChild(registry.byId(urlParams.get("subtab")));
          }
          return;
        }
        if (urlParams.get("campaign_list")) {
          tabContainer.selectChild(campaignsTab);
          return;
        }
        // set first tab available selected
        tabContainer.selectChild(brandInfoPane || campaignsTab || transportsTab);
      }
    }
  });
});