/**
 * Widget that handles just the actions within the ads status bar
 * @module mojo/widgets/ads/checklist/status-bar/AdStatusBarActions
 * @see mojo/widgets/ads/checklist/status-bar/AdStatusBar
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/utils/string", "mojo/lib/flags", "mojo/context", "mojo/widgets/StatusBarActions", "dojo/text!./templates/AdStatusBarActions.html"], function (declare, domAttr, domClass, user, stringUtils, flags, context, _StatusBarActions, tplStr) {
  /**
   * Widget that is responsible for creating and updating actions of the ads status bar.
   */
  return declare([_StatusBarActions], {
    templateString: tplStr,
    // Passed in through widget declaration
    ad: null,
    isPlural: "",
    /**
     * Sets properties in template in time for first load.
     */
    postMixInProperties: function () {
      this.inherited(arguments);
      this.isPlural = stringUtils.toPlural("", this.ad.type === "google" ? 5 : 1);
    },
    canSubmitAd: function () {
      // for google ads, only allow publishing if the user Authz is enabled for the google ad feature.
      if (this.ad.needsUpgrade) {
        return false;
      }

      // a progress of less than 4 means that there are more steps to complete.
      if (this.ad.progress < 4) {
        return false;
      }

      // ad feedback must be resolved before submitting.
      if (this.ad.hasFeedback) {
        return false;
      }

      // only those with a manager role can submit.
      if (!user.hasRole("manager")) {
        return false;
      }

      // i think this one is self-explanatory.
      if (!user.canSend) {
        return false;
      }

      // for Facebook ads, if Facebook account has existed CBM and it's not on this MC account,
      // the only course of action is to manually delete the CBM
      if (this.ad.type === "facebook" && this.ad.hasExistingCBM) {
        return false;
      }
      if (this.ad.type === "facebook" && this.ad.hasPageConnectionError) {
        return false;
      }

      // if Facebook is unavailable we need to disable the submit ad button if the user can send
      // their ad until the network is available
      if (this.ad.type === "facebook" && this.ad.connectionStatus !== "ok") {
        return false;
      }
      return true;
    },
    canEndAd: function () {
      if (this.ad.type === "facebook" && this.ad.hasPageConnectionError) {
        return false;
      }
      return true;
    },
    /**
     * Sets properties before elements are added to dom.
     */
    postCreate: function () {
      this.inherited(arguments);
      var adString = " " + stringUtils.toPlural("Ad", this.ad.type === "google" ? 5 : 1);
      var submitString = this.status.name === "draft" ? "Submit" + adString : "Resubmit" + adString;
      var replicateString = "Replicate" + adString;
      var endString = "End" + adString;
      var resumeString = "Resume" + adString;

      // Link - Replicate ad/s
      this._setActionName(this.replicateAd, replicateString);

      // Button - Combo
      this._setActionName(this.comboEndAd, endString);
      this._setActionName(this.comboReplicateAd, replicateString);

      // Button - Submit/Resubmit ad/s
      this._setActionName(this.submitAd, submitString);
      if (!this.canSubmitAd()) {
        domAttr.set(this.submitAd, "disabled", "true");
      }
      if (this.status.name === "rejected") {
        domClass.remove(this.submitAd, "margin-left--auto !margin-right--lv0");
        domClass.add(this.submitAd, "margin--lv0@phone");
      }

      // Button - Resume ad/s
      this._setActionName(this.resumeAd, resumeString);

      // Button - End ad/s
      this._setActionName(this.endAd, endString);
      if (!this.canEndAd()) {
        domAttr.set(this.endAd, "disabled", "true");
      }

      // Button - View Report
      if (!this.ad.hasBeenPublished) {
        domClass.add(this.viewReport, "hide");
      }
    }
  });
});