/**
 * Button to display calendar modal. Then will change to display calendar from campaign view.
 *
 * @module mojo/widgets/campaigns/CalendarButton
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/ComingSoonModal.html", "dijit/Dialog", "dijit/_WidgetBase"], function (declare, on, domStyle, domClass, comingSoonModal, Dialog, _WidgetBase) {
  return declare([_WidgetBase], {
    postCreate: function () {
      this.own(on(this.domNode, "click", this._showCalendarModal.bind(this)));
    },
    _showCalendarModal: function () {
      var calendarModal = new Dialog({
        content: comingSoonModal,
        "onHide": function () {
          calendarModal.destroyRecursive();
        }
      });

      //show modal
      calendarModal.show();
      var calendarModalDomNode = calendarModal.domNode.querySelector("[data-dojo-attach-point=calendarModal]");
      domClass.add(calendarModalDomNode, "padding--lv4");

      //remove title background
      var titleBar = calendarModal.domNode.querySelector("[data-dojo-attach-point=titleBar]");
      domStyle.set(titleBar, "display", "none");

      //google analytics tracking
      window.ga("t0.send", "event", "Marketing Calendar", "Clicked ", "Marketing-Painted-Door");

      // close secondary hide button
      var dismissBtn = calendarModal.domNode.querySelector("[data-dojo-attach-point=dismissBtn]");
      on(dismissBtn, "click", function () {
        calendarModal.onHide();
      });
    }
  });
});