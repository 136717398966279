define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, base) {
  return declare([base], {
    onReady: function () {},
    /**
     * Trigger the dialog that presents options to change pay go credit expiration
     *
     * @param {number} id - the id of an pay go credit
     * @param {string} date - the date that the expiration should be changed to
     */
    changeExpirationDate: function (id, date) {
      this.$el.payGoCreditIdVal.value = id;
      this.$el.expirationDate.value = date;
      this.$widget.payGoCreditExpirationModifyWarningArea.show();
    },
    /**
     * Trigger the dialog that presents options to change created at
     *
     * @param {number} id - the id of an pay go credit
     * @param {string} date - the date that the created at date should be changed to
     */
    changeCreatedAtDate: function (id, date) {
      this.$el.payGoCreditId.value = id;
      this.$el.createdAtDate.value = date;
      this.$widget.payGoCreditCreatedAtModifyWarningArea.show();
    }
  });
});