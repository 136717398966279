define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dijit/form/Select"], function (lang, declare, request, dom, domClass, mUrl, Select) {
  return declare([Select], {
    stateField: null,
    postCreate: function () {
      this.inherited(arguments);
      this.stateField = dom.byId(this.stateField);
      if (this.stateField) {
        this.on("change", lang.hitch(this, "_toggleCountryInput"));
        this._toggleCountryInput();
      }
    },
    _toggleCountryInput: function () {
      this._fetchCountryStatus().then(lang.hitch(this, function (data) {
        var shouldHide = data === "N";
        domClass.toggle(this.stateField, "hide", shouldHide);
      }));
    },
    _fetchCountryStatus: function () {
      return request.get(mUrl.toUrl("/account/country-has-state", {
        "id": this.value
      }));
    }
  });
});