define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/api/campaign", "dojo/text!./templates/_notify-senders.html", "dijit/Dialog", "mojo/url"], function (declare, lang, Renderable, campaignApi, tpl, Dialog, mUrl) {
  return declare([Renderable], {
    templateString: tpl,
    notifyUrl: mUrl.toUrl("/campaigns/wizard/notify"),
    notifySendersModal: null,
    constructor: function (props) {
      this.notifySendersModal = new Dialog({
        title: "Notify Senders"
      });
      var campaign = props.model;
      this.defaultMessage = "I finished the \"" + campaign.name + "\" campaign, reviewed the pre-delivery checklist and it's ready to send.";
      this.state = {
        campaignId: campaign.campaignId,
        message: this.defaultMessage,
        admins: null,
        selectedAdmins: {},
        errorMessage: "",
        isSaving: false
      };
      campaignApi.getAdminsForUser().then(lang.hitch(this, function (admins) {
        this.setState({
          admins: admins,
          selectedAdmins: this.getDefaultSelectedAdmins(admins)
        });
      }));
    },
    getDefaultSelectedAdmins: function (admins) {
      return admins.reduce(lang.hitch(this, function (acc, admin) {
        acc[admin.id] = true;
        return acc;
      }), {});
    },
    render: function () {
      this.inherited(arguments);
      this.placeAt(this.notifySendersModal.containerNode);
    },
    cancel: function (event) {
      event.preventDefault();
      this.notifySendersModal.hide();
    },
    submit: function (event) {
      event.preventDefault();
      var numberOfSelectedAdmins = this.state.admins.filter(function (admin) {
        return this.state.selectedAdmins[admin.id];
      }, this).length;
      if (numberOfSelectedAdmins > 0) {
        event.target.submit();
      } else {
        this.setState({
          errorMessage: "You must choose at least one person to notify."
        });
      }
    },
    updateMessage: function (event) {
      //mutate state directly to prevent re-render
      this.state.message = event.target.value;
    },
    updateSelectedAdmins: function (id, newValue) {
      //mutate state directly to prevent re-render
      this.state.selectedAdmins[id] = newValue;
    },
    show: function () {
      this.setState({
        message: this.defaultMessage,
        selectedAdmins: this.getDefaultSelectedAdmins(this.state.admins || []),
        errorMessage: ""
      });
      this.notifySendersModal.show();
    }
  });
});