define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/AdaButton.html", "mojo/url"], function (declare, _WidgetBase, _TemplatedMixin, template, mUrl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: template,
    adaEndpoint: "/support/chat-ada",
    params: {},
    _onClick: function () {
      this.openAda();
    },
    openAda: function () {
      window.open(mUrl.toUrl(this.adaEndpoint, this.params), "_blank", "height=700, width=500");
    }
  });
});