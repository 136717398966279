define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/fx!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/fx/_base", "dojox/fx/_core", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils"], function (query, domStyle, domConstruct, array, lang, baseFx, fxExt, Line, domGeom, mUtils) {
  var utils = {
    /**
     * jQuery implementation of JQuery.extend. It's ported to dojo to allow deep mixin that dojo.mixin does not provide.
     */
    'extend': function () {
      var options,
        name,
        src,
        copy,
        copyIsArray,
        clone,
        target = arguments[0] || {},
        i = 1,
        length = arguments.length,
        deep = false;

      // Handle a deep copy situation
      if (typeof target === "boolean") {
        deep = target;
        target = arguments[1] || {};
        // skip the boolean and the target
        i = 2;
      }

      // Handle case when target is a string or something (possible in deep copy)
      if (typeof target !== "object" && !lang.isFunction(target)) {
        target = {};
      }

      // extend jQuery itself if only one argument is passed
      if (length === i) {
        target = this;
        --i;
      }
      for (; i < length; i++) {
        // Only deal with non-null/undefined values
        if ((options = arguments[i]) != null) {
          // Extend the base object
          for (name in options) {
            if (options.hasOwnProperty(name)) {
              src = target[name];
              copy = options[name];

              // Prevent never-ending loop
              if (target === copy) {
                continue;
              }

              // Recurse if we're merging plain objects or arrays
              if (deep && copy && ((copyIsArray = lang.isArray(copy)) || lang.isObject(copy))) {
                if (copyIsArray) {
                  copyIsArray = false;
                  clone = src && lang.isArray(src) ? src : [];
                } else {
                  clone = src && lang.isObject(src) ? src : {};
                }

                // Never move original objects, clone them
                target[name] = utils.extend(deep, clone, copy);

                // Don't bring in undefined values
              } else if (copy !== undefined) {
                target[name] = copy;
              }
            }
          }
        }
      }
      // Return the modified object
      return target;
    },
    dashToCamel: function (str) {
      return str.replace(/(\-[a-z])/g, function (match) {
        return match.toUpperCase().replace('-', '');
      });
    },
    /**
     * Creates a full clone of a given node. A regular clone using cloneNode or dojo/lang.clone can fully clone the dom
     * structure, but you will lose all the styles if you move it out of the container. This will clone the node and
     * traverse the structure to inline styles.
     *
     * @param node
     */
    cloneWithStyles: function (node) {
      var clonedNode = lang.clone(node);
      var _addStyles = function (real, clone) {
        var styles = domStyle.get(real);

        // Convert all the styles to a camel case styling for Firefox browsers.
        var camelStyles = {};
        for (var i = 0; i < styles.length; i++) {
          var prop = styles[i];
          var camel = utils.dashToCamel(prop);
          camelStyles[camel] = styles.getPropertyValue(prop);
        }
        domStyle.set(clone, camelStyles);
        var clonedChildren = query(clone).children();
        query(real).children().forEach(function (realChild, idx) {
          var clonedChild = clonedChildren[idx];
          _addStyles(realChild, clonedChild);
        });
      };
      _addStyles(node, clonedNode);
      return clonedNode;
    },
    smoothScroll: function ( /*object: node, win, target, duration*/args) {
      // Returns an animation that will scroll to a node
      // node: The node you want to scroll to
      // win: a node to scroll
      // duration: duration of the scroll animation
      args.target = domGeom.position(args.node);
      var delta = {
        x: args.target.x,
        y: args.target.y
      };
      var _anim = function (val) {
        args.win.scrollLeft = val[0];
        args.win.scrollTop = val[1];
      };
      var anim = new baseFx.Animation(lang.mixin({
        beforeBegin: function () {
          if (this.curve) {
            delete this.curve;
          }
          var current = {
            x: args.win.scrollLeft,
            y: args.win.scrollTop
          };
          anim.curve = new Line([current.x, current.y], [delta.x - 50, delta.y - 50]);
        },
        onAnimate: _anim
      }, args));

      // dojo/Animation
      return anim;
    },
    /**
     * This does what's necessary to create a style block in a frame so we can do some
     * live style editing to the frame. The way that it works is different per browser (IE vs. others),
     * so be careful when changing this and make sure to test in IE.
     *
     * @param {Document} doc - a document element to insert the styles
     * @returns {Object} - object with functions add/remove
     */
    createStyles: function (doc) {
      var styleBlock = doc.createElement("style");
      query("head", doc).append(styleBlock);
      var rules = [];
      var addFunc = function (ruleToAdd) {
        rules.push(ruleToAdd);

        // IE way
        if (styleBlock.styleSheet) {
          var ruleString = "";
          array.forEach(rules, function (rule) {
            ruleString += rule;
          });
          styleBlock.styleSheet.cssText = ruleString;
        } else {
          query(styleBlock).append(ruleToAdd);
        }
        mUtils.globalPublish("mojo/style-block/updated", [doc]);
      };
      var removeFunc = function () {
        if (styleBlock.styleSheet) {
          styleBlock.styleSheet.cssText = "";
        } else {
          domConstruct.destroy(styleBlock);
        }
        mUtils.globalPublish("mojo/style-block/updated", [doc]);
      };
      return {
        add: addFunc,
        remove: removeFunc
      };
    },
    /**
     * Helper function to inline styles to the given dom
     *
     * @param {HTMLElement} rootDom element to style
     * @param {Object} styles element block styles
     *
     * @returns {HTMLElement} styled element passed in
     *
     */
    inlineStyles: function (rootDom, styles) {
      if (styles) {
        styles.forEach(function (style) {
          for (var styleProperty in style.props) {
            if (style.props.hasOwnProperty(styleProperty)) {
              var value = style.props[styleProperty].value;
              if (value && value !== "") {
                var selector = style.props[styleProperty].selector || style.selector;
                if (selector === "mcBlockContainer") {
                  query(rootDom).style(utils.dashToCamel(styleProperty), value);
                } else {
                  query(selector, rootDom).style(utils.dashToCamel(styleProperty), value);
                }
              }
            }
          }
        });
      }
      return rootDom;
    },
    /**
     * Helper function to reset inline styles to the given dom
     *
     * @param {HTMLElement} rootDom element to style
     * @param {Object} styles element block styles
     *
     * @returns {HTMLElement} styled element passed in
     *
     */
    resetStyles: function (rootDom, styles) {
      if (styles) {
        styles.forEach(function (style) {
          for (var styleProperty in style.props) {
            if (style.props.hasOwnProperty(styleProperty)) {
              var selector = style.props[styleProperty].selector || style.selector;
              if (selector === "mcBlockContainer") {
                query(rootDom).style(utils.dashToCamel(styleProperty), null);
              } else {
                query(selector, rootDom).style(utils.dashToCamel(styleProperty), null);
              }
            }
          }
        });
      }
      return rootDom;
    },
    /**
     * Check url for unsafe chars and encode them
     *
     * @param {string} url - string to be encoded
     *
     * @returns {string} - safe url to insert into block
     * @private
     */
    encodeSafeUrl: function (url) {
      // Map of unsafe chars to prevent XSS
      var unsafeMap = {
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#039;"
      };
      return url.replace(/[<>"']/g, function (char) {
        return unsafeMap[char];
      });
    },
    /**
     * Helper function to get the platform type based on the window width
     *
     * @returns {null|string} - type of platform
     */
    getPlatformType: function () {
      var BREAKPOINTS = {
        PHONE_MAX_WIDTH: 600,
        TABLET_MAX_WIDTH: 960,
        DESKTOP_MAX_WIDTH: 1440
      };
      var platformWidth = window.innerWidth;
      if (platformWidth <= BREAKPOINTS.PHONE_MAX_WIDTH) {
        return "phone";
      } else if (platformWidth <= BREAKPOINTS.TABLET_MAX_WIDTH) {
        return "tablet";
      } else if (platformWidth <= BREAKPOINTS.DESKTOP_MAX_WIDTH) {
        return "desktop";
      } else if (!isNaN(platformWidth)) {
        return "max";
      }
      return null;
    },
    /**
     * Helper function to strip html tags from an HTML string
     *
     * @param {string} html - string containing html content
     * @returns {string} - plain text string
     */
    stripHTML: function (html) {
      if (!html) {
        return "";
      }
      var div = document.createElement("div");
      div.innerHTML = html;
      return div.innerText;
    },
    /**
     * Helper function to check whether the provided block contains default text
     * @param {number} blockId - The ID of the block we want to check
     * @param {string|null|undefined} defaultText - The default text we want to check for. Null if we want to check for the default text set in the block
     * @returns {boolean} - True if the block contains default text, otherwise false
     */
    blockHasDefaultText: function (blockId, defaultText) {
      var block = window.app.byId(blockId);
      if (defaultText && block) {
        return block._defaultText === defaultText;
      }
      return block && Array.isArray(block.content) && block.content[0] === block._defaultText;
    }
  };
  return utils;
});