define(["dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/aspect!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "dojox/mvc/sync", "mojo/utils", "mojo/neapolitan/editors", "mojo/neapolitan/Endpoint", "mojo/neapolitan/renderers", "mojo/neapolitan/utils", "mojo/neapolitan/HideTemplateSectionCoachmarks", "mojo/context", "mojo/lib/flags"], function (array, declare, lang, aspect, Deferred, Stateful, sync, utils, editors, Endpoint, renderers, neapUtils, sectionCoachmarks, context, flags) {
  return declare([Stateful], {
    id: null,
    type: null,
    variant: null,
    _containerId: null,
    /**
     * The display name used when displaying the block on the content pane in Neapolitan
     */
    _name: "Block",
    /**
     * (Optional) Show kb link in the top of the style tab.
     */
    _kbLink: null,
    /**
     * (Optional) label that blocks can specify that will be used to label the block avatar on the content section of
     * Neapolitan. If this isn't set, it defaults to the block name.
     */
    _label: null,
    /**
     * (Optional) Restrict the usage of this block to the specified campaign type.
     */
    _campaignType: null,
    /**
     *  (Optional) Whether this editor should appear in drag and drop block icon list
     */
    _visible: true,
    /**
     *  For use with this.endpoint.remove(), when deleted set _isDeleted, so next update-block calls is blocked
     */
    _isDeleted: false,
    /**
     * (Optional) Whether this is a block that's only for ecommerce users
     */
    _ecommOnly: false,
    /**
     * Which Neapolitan editors is this block allowed to show up in? (array of all by default)
     */
    _editingTypes: ["campaign", "page", "template"],
    /**
     * (Optional) Whether this is a block requires a particular platform type
     */
    _requiresPlatform: null,
    /**
     * Denote a block for paid users only. This is mainly for blocks that will break if the user is not paid
     */
    _paidOnly: false,
    _isSelected: false,
    /**
     * This is to denote that this block will be created as a default block. Default block gets deleted when
     * we switch templates
     */
    _default: false,
    /**
     * Allows changing how the image uploader looks. See ImageUploader for more info.
     */
    _imageUploaderType: "image",
    /**
     * Option that runs this block through a JS merge tag renderer.
     */
    _renderMergeTags: false,
    /**
     * Allows us to look at campaign type (web or email) when calculating container width
     */
    _campaignRenderingType: null,
    // override blockEditorControls for in specific models
    // TODO: we need to send this to mobile when we restrict block usage in the editor
    _blockEditorControls: ["sort", "edit", "clone", "delete"],
    // Use this boolean to easily watch if a block's dynamic content settings change
    hasDynamicContent: false,
    /**
     * Track if a user opened the Dynamic Content Settings pane but didn't actually use the feature
     *
     * Ideally we'd track this at the campaign level, but that would require a database migration,
     * and this is not intended to be tracked permanently. It will be used to trigger a Usabilla survey after the initial launch.
     */
    hasOpenedDynamicContentWithoutSaving: false,
    // This is where we'll keep all the information about the dynamic content rules configured for each block
    dynamicContentRules: null,
    constructor: function () {
      this._endpoint = new Endpoint(window.parent.neapolitan_content_block_root, window.parent.tpl_id, window.parent.variant);
    },
    postscript: function (params, blockOverride) {
      if (blockOverride) {
        neapUtils.extend(true, this, blockOverride);
      }

      // Find any 'props' that is setup as an array and convert them
      if (this.styles) {
        array.forEach(this.styles, function (style) {
          if (lang.isArray(style.props)) {
            var convertedProps = {};
            array.forEach(style.props, function (item) {
              convertedProps[item] = {
                "value": ""
              };
            });
            style.props = convertedProps;
          }
        });
      }

      // for future updates on deep changes in styles or reordering style objects
      // in the array we need to match existing object from the selector and use extend
      // to match the props from model and extend from the existing props with params.style

      if (params) {
        neapUtils.extend(true, this, params);
      }
    },
    /**
     * Core function that returns the Object that we want to persist as the block meta-data. It filters some specific
     * attributes and ignores anything that starts with _
     * @return {object} a plain object
     */
    data: function () {
      var plain = {};
      for (var s in this) {
        if (!(s in Stateful.prototype) && s.indexOf("_") !== 0 && array.indexOf(["id", "type", "editedBy", "editedById"], s) === -1 && !lang.isFunction(this[s])) {
          plain[s] = this[s];
        }
      }
      return plain;
    },
    /**
     * Saves the block. If a block is new, it's synchronous, but both return a dojo.Deferred, so you can treat
     * them the same way.
     *
     * @param {boolean} isSync - When true, this request to save the block, will be made synchronously
     * @return {Promise} A Promise that resolves when the request is finished (no guarantees about resolution value)
     */
    save: function (isSync) {
      this._previousStyles = null;
      if (this._isDeleted) {
        return new Deferred().resolve();
      }
      if (this.id) {
        return this._endpoint.update(this._containerId, this.id, {
          "html": this.getMarkup(),
          "data": this.data()
        }, isSync);
      }
      var create = this._endpoint.create(this._containerId, this.getMarkup(Preview.getContainer(this._containerId).containerWidth), this._index, this.type, this.data(), this._default);
      create.then(lang.hitch(this, function (data) {
        this.id = data.id;
      }));
      return create;
    },
    remove: function (skipSave) {
      if (this._editor) {
        this._editor.cancelAndHide();
      }
      // deleting block, from an native action or click or pusher (from another user);

      if (skipSave) {
        // deselecte the block
        if (this.get("_isSelected")) {
          utils.globalPublish("native/mobile/block/action/done");
        }
        var promise = new Deferred();
        promise.resolve();
        return promise;
      }
      var endpointPromise = this._endpoint.remove(this._containerId, this.id);
      return endpointPromise;
    },
    reorder: function (index, containerName) {
      var markup;
      if (containerName) {
        markup = this.getMarkup(Preview.getContainer(containerName).containerWidth);
      } else {
        markup = this.getMarkup();
      }
      var result = this._endpoint.update(this._containerId, this.id, {
        "html": markup,
        "data": this.data(),
        "index": index,
        "new_container": containerName
      });
      var self = this;
      result.then(lang.hitch(this, function () {
        if (containerName) {
          self.set("_containerId", containerName);
        }
        this.set("_index", index);
      }));
      return result;
    },
    applyStyles: function (styles) {
      if (!this._previousStyles) {
        this._previousStyles = this.styles;
      }
      this.set("styles", styles);
    },
    revertStyles: function () {
      this.set("styles", this._previousStyles);
      this._previousStyles = null;
    },
    getRendererModel: function (containerWidth) {
      if (!containerWidth) {
        containerWidth = Preview.getContainer(this._containerId).containerWidth;
      }
      return lang.mixin({
        containerWidth: containerWidth
      }, this.data());
    },
    // used only for LinkCheckerEditor currently
    getContent: function (containerWidth) {
      return renderers.getContent(this.type, this.getRendererModel(containerWidth), this._campaignRenderingType);
    },
    getMarkup: function (containerWidth) {
      return renderers.getMarkup(this.type, this.getRendererModel(containerWidth), this._campaignRenderingType);
    },
    getContainerWidth: function () {
      return Preview.getContainer(this._containerId).containerWidth;
    },
    openEditor: function () {
      sectionCoachmarks.stop();
      if (!this._editor || this._editor && !this._editorOpen) {
        this._editor = editors.create(this.type, this);
        this.set("_editorOpen", true);
        this._editor.show();
        aspect.after(this._editor, "destroy", lang.hitch(this, function () {
          this.set("_editorOpen", false);
        }));
      }
    },
    /**
     * This function is called whenever we need to update this block's data. The default implementation is to
     * override all the values. Each block can override this function and implement their own way of handling the merge
     */
    updateData: function (data) {
      this.set(data);
      if (this._editor && this._editorOpen) {
        this._editor.onDataUpdate(data);
      }
    },
    /**
     * This is what the link checker calls to let the block know about a change in a url.
     *
     * @param oldUrl - The old url
     * @param newUrl - The new url that this is changing to.
     * @param index - the index of this url in the context of this block
     */
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var promise = new Deferred();
      promise.reject("Oops! Looks like we unable to change this link. Contact support if the problem persist.");
      return promise;
    },
    /**
     * Inform LinkCheckerEditor whether or not it can work within this block.
     *
     * @return {boolean} True if LinkCheckerEditor should try to work on this block.
     */
    canLinkUpdate: function () {
      return true;
    },
    /**
     * Empty implementation, meant for subclasses to override.
     * Provides an opportunity for models to setup a listener to merge information changes, and trigger a re-render
     * of the mcBlockInstance.
     * Invoked on block placement.
     *
     * @param {mojo/preview/McBlock} mcBlockInstance McBlock that is rendering this model
     * @private
     */
    _setupMergeListeners: function (mcBlockInstance) {},
    /**
     * Empty implementation, meant for subclasses to override.
     * Clean up method for any listeners established in this._setupMergeListeners().
     * Invoked on block removal.
     *
     * @param {mojo/preview/McBlock} mcBlockInstance McBlock that is rendering this model
     * @private
     */
    _teardownMergeListeners: function (mcBlockInstance) {},
    /**
     * Apply brand styles if they exist for this block
     *
     * @param {Object} brandStyles Brand styles object, a key value store where the key is the block type and the
     *                             value is the css properties to apply to that block
     */
    applyBrandStyles: function (brandStyles) {
      var blockBrandStyles = brandStyles[this.type];
      if (blockBrandStyles) {
        this.styles = this.styles.map(function (style) {
          Object.keys(blockBrandStyles).forEach(function (property) {
            if (style.props.hasOwnProperty(property)) {
              style.props[property].value = blockBrandStyles[property];
            } else if (style.selector === property) {
              Object.keys(blockBrandStyles[property]).forEach(function (subproperty) {
                if (style.props.hasOwnProperty(subproperty)) {
                  style.props[subproperty].value = blockBrandStyles[property][subproperty];
                }
              });
            }
          });
          return style;
        });
      }
    }
  });
});