define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo/text!./templates/DeleteDialog.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/lib/flags", "mojo/context", "mojo/utils/I18nTranslation", "dojo/string"], function (declare, _Widget, _Templated, tpl, on, html, event, keys, lang, Deferred, Dialog, flags, context, I18nTranslation, string) {
  /**
   * A confirmation dialog for deletin' stuffs.
   */
  return declare([_Widget, _Templated], {
    templateString: tpl,
    dialog: null,
    deferred: null,
    confirmDeleteText: "",
    deleteButtonText: "Delete",
    cancelButtonText: "Cancel",
    dialogTitle: "Are you sure?",
    fileid: null,
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function () {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.dialogTitle = I18nTranslation.translate("are_you_sure_header");
        this.fileid = this.fileid.toString();
        this.confirmDeleteText = I18nTranslation.translate("content_studio_confirm_delete_file", {
          count: this.fileid.split(",").length === 1 ? 1 : 0
        });
        this.deleteButtonText = I18nTranslation.translate("content_studio_brand_delete_button");
        this.cancelButtonText = I18nTranslation.translate("content_studio_brand_cancel_button");
      }.bind(this));
    },
    postCreate: function () {
      this.deferred = new Deferred();
      var deleteSignal = on(this.deleteButton, "click", lang.hitch(this, function (e) {
        event.stop(e);
        deleteSignal.remove();
        this.resolve();
      }));
      var hideSignal = on(this.cancelButton, "click", lang.hitch(this, function (e) {
        event.stop(e);
        hideSignal.remove();
        this.cancel();
      }));
    },
    execute: function () {
      this.resolve();
    },
    resolve: function () {
      this.deferred.resolve();
      this.dialog.hide();
    },
    cancel: function () {
      this.deferred.cancel();
      this.dialog.hide();
    },
    show: function () {
      this.dialog = new Dialog({
        "title": this.dialogTitle,
        "content": this
      });
      this.dialog.startup();
      this.dialog.show();
    }
  });
});