define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/Pusher", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!../templates/Twitter.html"], function (declare, lang, xhr, domClass, on, domAttr, mUrl, mPusher, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    integrationButtonNode: null,
    fbPageSelector: null,
    integrationInstallStatus: null,
    appInstallUrl: null,
    //Only initiate the pages once, we don't want duplicates
    pagesInitiated: false,
    connectionData: {},
    connectedListsSet: false,
    postCreate: function () {
      this._populateTwitterLists();
      this.own(on(this.twitterLists, "change", lang.hitch(this, "_updateIntegrateLink")));
      this.own(on.once(this.disconnectButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._disconnectIntegration(14);
      })));
      this.own(on(this.testButton, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._testConnection();
      })));
      this._loadData();
    },
    _testConnection: function () {
      var test_url = "/account/integrations/twitter-test?id=" + this.twitterLists.value;
      var self = this;
      dojo.xhrPost({
        "url": mUrl.toUrl(test_url),
        "content": {},
        "handleAs": "text",
        "load": function (data) {
          var txtbox = self.testResults;
          var loading = self.integrationLoading;
          dojo.style(loading, "display", "block");
          dojo.style(loading, "display", "none");
          dojo.style(txtbox, "display", "block");
          txtbox.innerHTML = data;
        }
      });
    },
    _clearTestData: function () {
      this.testResults.innerHTML = "<em>No results to display yet</em>";
    },
    _loadData: function (id) {
      var info_url = "/account/connected-sites/internal-integration/get-twitter-connection-info";
      if (typeof id != "undefined") {
        info_url = info_url + "?id=" + id;
      }
      var self = this;
      dojo.xhrGet({
        "url": mUrl.toUrl(info_url),
        "handleAs": "json",
        "load": function (data) {
          self._setConnectionNodeValue(self.twitterUsername, data.screen_name, "Connected as: ");
          self._setConnectionNodeValue(self.verifiedTime, data.last_verified, "Verified: ");
          self._setConnectionNodeValue(self.lastVerifiedMessage, data.last_verified_msg, "Last message: ");
          if (data.connected_lists.length > 0 && self.connectedListsSet === false) {
            domClass.remove(self.attachedLists, "hide");
            data.connected_lists.forEach(function (list) {
              dojo.create("li", {
                innerHTML: list
              }, self.attachedTwitterLists);
            });
            self.connectedListsSet = true;
          }
        }
      });
    },
    _setConnectionNodeValue: function (node, data, statusText) {
      if (data === "") {
        return;
      }
      node.textContent = statusText + data;
      domClass.remove(node, "hide");
    },
    _populateTwitterLists: function () {
      var self = this;
      this._fetch("/account/connected-sites/internal-integration/get-lists").then(function (data) {
        var options = data.map(function (list) {
          return {
            value: list.list_id,
            label: list.name
          };
        });
        data.forEach(function (list) {
          self.connectionData[list.list_id] = list.connected;
        });
        self._setDefaultLink();
        self.twitterLists.addOption(options);
      });
    },
    _setDefaultLink: function () {
      if (this.connectionData[0] === true) {
        this._setupDisconnectButton();
      } else {
        this._setupConnectButton();
      }
      domAttr.set(this.integrationButtonNode, "href", this.appInstallUrl + 0);
    },
    _updateIntegrateLink: function () {
      if (this.connectionData[this.twitterLists.value] === true) {
        this._loadData(this.twitterLists.value);
        this._setupDisconnectButton();
      } else {
        this._setupConnectButton();
      }
      domAttr.set(this.integrationButtonNode, "href", this.appInstallUrl + this.twitterLists.value);
    },
    _setupDisconnectButton: function () {
      this._clearTestData();
      domClass.remove(this.integrationButtonNode, "p1 disabled button-loading");
      domClass.add(this.integrationButtonNode, "hide");
      this.integrationButtonNode.innerText = "Disconnect";
      this.own(on.once(this.integrationButtonNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this._disconnectIntegration();
      })));
      domClass.remove(this.connectionResults, "hide");
    },
    _setupConnectButton: function () {
      this._clearTestData();
      domClass.remove(this.integrationButtonNode, "disabled button-loading hide");
      domClass.add(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Connect";
      this.own(on.once(this.integrationButtonNode, "click", lang.hitch(this, function (e) {})));
      domClass.add(this.connectionResults, "hide");
    },
    _disconnectIntegration: function () {
      query = {
        'sys': 1,
        id: this.twitterLists.value
      };
      // eslint-disable-next-line no-undef
      lang.hitch(this, dojo.xhrPost({
        'url': mUrl.toUrl('/account/integrations/delete'),
        'content': query,
        'handleAs': 'json'
      }));
      window.ga('send', 'event', 'connected sites', 'platform disconnect', 'button', {
        'dimension53': 'Disconnect',
        'dimension54': '/account/integrations/delete',
        'dimension55': 'Disconnect link on integration detail screen',
        'dimension62': 'Twitter'
      });
      this._displayWaiting();
      location.reload();
    },
    _displayWaiting: function () {
      domClass.add(this.integrationButtonNode, "disabled");
      domClass.add(this.integrationButtonNode, "button-loading");
      domClass.remove(this.integrationButtonNode, "p1");
      this.integrationButtonNode.innerText = "Waiting...";
    },
    /**
     * Fetch and mUrl wrapper
     *
     * @param {String} url - url to fetch
     * @param {Object} query - object added as query params
     * @return {Promise} promise object
     */
    _fetch: function (url, query) {
      return xhr.get(mUrl.toUrl(url, query), {
        handleAs: "json"
      });
    }
  });
});