/**
 * This makes the assumption that all things included are editors.
 */
define(["./editors/blocks/TextEditor", "./editors/blocks/TextEditorMobile", "./editors/blocks/BoxedTextEditor", "./editors/blocks/BoxedTextEditorMobile", "./editors/blocks/DividerEditor", "./editors/blocks/DividerEditorMobile", "./editors/blocks/ImageEditor", "./editors/blocks/ImageEditorMobile", "./editors/blocks/ImageCardEditor", "./editors/blocks/ImageCardEditorMobile", "./editors/blocks/ImageGroupEditor", "./editors/blocks/ImageGroupEditorMobile", "./editors/blocks/ImageWithCaptionEditor", "./editors/blocks/ImageWithCaptionEditorMobile", "./editors/blocks/SocialShareEditor", "./editors/blocks/SocialShareEditorMobile", "./editors/blocks/SocialFollowEditor", "./editors/blocks/SocialFollowEditorMobile", "./editors/blocks/ButtonEditor", "./editors/blocks/ButtonEditorMobile", "./editors/blocks/FooterEditor", "./editors/blocks/FooterEditorMobile", "./editors/blocks/CodeEditor", "./editors/blocks/RssHeaderEditor", "./editors/blocks/RssHeaderEditorMobile", "./editors/blocks/RssItemsEditor", "./editors/blocks/RssItemsEditorMobile", "./editors/blocks/VideoEditor", "./editors/blocks/VideoEditorMobile", "./editors/blocks/ApiEditor", "./editors/blocks/ProductRecEditor", "./editors/blocks/ProductRecEditorMobile", "./editors/blocks/ProductEditor", "./editors/blocks/ProductEditorMobile", "./editors/blocks/PaymentEditor", "./editors/blocks/AbandonedCartEditor", "./editors/blocks/AbandonedCartEditorMobile", "./editors/blocks/AbandonedBrowseEditor", "./editors/blocks/PromoCodeEditor", "./editors/blocks/PromoCodeEditorMobile", "./editors/blocks/UnsupportedBlockMobile", "./editors/blocks/SignUpFormEditor", "./editors/blocks/WebVideoEditor"], function () {
  // incredmentally add mobile editors as needed;
  var mobileEditorMap = {
    "text": true,
    "boxedText": true,
    "divider": true,
    "button": true,
    "footer": true,
    "image": true,
    "imageCard": true,
    "imageGroup": true,
    "imageCaption": true,
    "video": true,
    "socialFollow": true,
    "socialShare": true,
    "rssHeader": true,
    "rssItems": true,
    "product": true,
    "productRec": true,
    "abandonedCart": true,
    "promoCode": true
  };
  var editorsByType = {};
  var i;
  for (i = 0; i < arguments.length; i++) {
    var editor = arguments[i];
    var typeRef = editor.prototype.type;
    if (typeRef) {
      var j = 0;
      while (editorsByType.hasOwnProperty(typeRef)) {
        typeRef = editor.prototype.type + "_" + j;
        j++;
      }
      editorsByType[typeRef] = editor;
    }
  }
  return {
    getAllByType: function () {
      return editorsByType;
    },
    create: function (type, block) {
      var hasNativeObject = window.app && window.app.nativeUtils && window.app.nativeUtils.getNativeObject();
      var typeLookup = type;
      if (hasNativeObject) {
        typeLookup = mobileEditorMap[type] ? type + "-mobile" : "unsupported-mobile";
      }
      return new editorsByType[typeLookup]({
        block: block
      });
    }
  };
});