define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url"], function (declare, base, xhr, utils, mUrl) {
  return declare([base], {
    onReady: function () {},
    /**
     * Trigger the dialog that presents options to change a subscription's next renewal date
     *
     * @param {number} id - the id of a subscription
     * @param {string} date - the date that the next renewal date should be changed to
     */
    changeNextRenewalDate: function (id, date) {
      this.$el.subscriptionId.value = id;
      this.$el.nextRenewalDate.value = date;
      this.$widget.subscriptionModifyWarningArea.show();
    }
  });
});