define(["dijit/Dialog", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils", "dojo/text!./templates/plainTextForm.html"], function (Dialog, declare, _WidgetBase, _TemplatedMixin, lang, on, domAttr, request, topic, mUrl, utils, tpl) {
  var dialog;
  var form;
  var successTopic = function (campaignId) {
    return "campaigns/plain-text/success/" + campaignId;
  };
  var PlainTextForm = new declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    campaignId: null,
    postCreate: function () {
      this.own(on(this.cancelBtn, "click", lang.hitch(this, "_onCancel")), on(this.regenBtn, "click", lang.hitch(this, "_onRegen")), on(this.saveBtn, "click", lang.hitch(this, "_onSave")));
    },
    setPlainText: function (text) {
      this.textInput.value = text;
    },
    getPlainTextFor: function (campaignId) {
      this.campaignId = campaignId;
      var req = request.get(mUrl.toUrl("campaigns/get-text", {
        "id": campaignId
      }), {
        "handleAs": "json"
      });
      req.then(lang.hitch(this, function (data) {
        form.setPlainText(data.content);
      }));
      return req;
    },
    _onSave: function () {
      utils.toggleButtonLoadingState(this.saveBtn);
      request.post(mUrl.toUrl("campaigns/text-update"), {
        "data": {
          "id": this.campaignId,
          "text": this.textInput.value
        },
        "handleAs": "json"
      }).then(lang.hitch(this, function (response) {
        if (response !== "error") {
          dialog.hide();
          topic.publish(successTopic(this.campaignId));
          if (this.onSave) {
            this.onSave();
          }
        }
        utils.toggleButtonLoadingState(this.saveBtn);
      }));
    },
    _onRegen: function () {
      utils.toggleButtonLoadingState(this.regenBtn);
      request.post(mUrl.toUrl("campaigns/regen-text"), {
        "data": {
          "id": this.campaignId
        },
        "handleAs": "json"
      }).then(lang.hitch(this, function (response) {
        switch (response.status) {
          case "error":
            utils.toggleButtonLoadingState(this.regenBtn);
            break;
          default:
            domAttr.set(this.textInput, "value", response.content);
            utils.toggleButtonLoadingState(this.regenBtn);
        }
      }));
    },
    _onCancel: function (e) {
      e.preventDefault();
      e.stopPropagation();
      dialog.hide();
    }
  });
  return {
    successTopic: successTopic,
    show: function (campaignId, onSave) {
      if (!dialog) {
        form = new PlainTextForm({
          onSave: onSave
        });
        form.startup();
        dialog = new Dialog({
          "class": "edit-plaintext-dialog",
          "style": "width:760px",
          "title": "Edit Plain-Text Version",
          "content": form.domNode
        });
      }
      form.getPlainTextFor(campaignId).then(function () {
        dialog.show();
      });
    },
    hide: function () {
      dialog.hide();
    }
  };
});