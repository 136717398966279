/**
 * @module mojo/widgets/AudienceHorizontalNav
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./AccessibleMenuBar", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking", "mojo/lib/flags"], function (declare,
// Mixin
AccessibleMenuBar,
// Dojo modules
domAttr, on, query,
// Mojo modules
context, EcsEvents, EcsTracking, flags) {
  return declare([AccessibleMenuBar], {
    postCreate: function () {
      // Effectively Dojo's super() command
      this.inherited(arguments);
      this.addEcsEvents();
    },
    addEcsEvents: function () {
      var self = this;
      this.menuParents.forEach(function (item) {
        var menuOption = domAttr.get(item, "data-analytics-tag");
        self._addMenuEvent(item, menuOption);
      });
    },
    // removes the href and adds a click event to the element with navigation
    _addNavigateEvent: function (element, ecsEventName, ecsCustomProps) {
      if (element) {
        var anchorElement = query("a", element)[0];
        if (!ecsCustomProps) {
          ecsCustomProps = {};
        }
        ecsCustomProps.screen = window.top.location.pathname;
        if (anchorElement) {
          var hrefValue = domAttr.get(anchorElement, "href");

          // using mousedown as a replacement for click envent as click is not always triggering before navigation
          on(anchorElement, "mousedown", function (event) {
            EcsTracking.track(ecsEventName, ecsCustomProps);
          });
          on(anchorElement, "touchstart", function (event) {
            EcsTracking.track(ecsEventName, ecsCustomProps);
          });
        }
      }
    },
    _addNavigateEventToChildren: function (element, event, menuOptionName) {
      var self = this;
      query(".c-menuItem", element).forEach(function (item) {
        var ecsCustomProps = {};
        if (menuOptionName) {
          var menuItemName = domAttr.get(item, "data-menu-option");
          ecsCustomProps[menuOptionName] = menuItemName;
        }
        self._addNavigateEvent(item, event, ecsCustomProps);
      });
    },
    _addMenuEvent: function (element, menuOption) {
      var menuOptionName = "";
      var self = this;
      switch (menuOption) {
        case "menu-bar-overview":
          // overview event
          self._addNavigateEvent(element, EcsEvents.AudienceOrganizationOverviewEvent);
          break;
        case "menu-bar-manage-subs":
          menuOptionName = "manage_contacts_audience_h_nav_option";
          self._addNavigateEventToChildren(element, EcsEvents.AudienceOrganizationManageContactsEvent, menuOptionName);
          // manage subs event
          break;
        case "menu-bar-add-subs":
          menuOptionName = "add_contacts_audience_h_nav_option";
          self._addNavigateEventToChildren(element, EcsEvents.AudienceOrganizationAddContactsEvent, menuOptionName);
          // add subs event
          break;
        case "menu-bar-pref-center":
          // pref center event
          self._addNavigateEvent(element, EcsEvents.AudienceOrganizationSubscriberPreferencesEvent);
          break;
        case "menu-bar-settings":
          // settings event
          menuOptionName = "settings_audience_h_nav_option";
          self._addNavigateEventToChildren(element, EcsEvents.AudienceOrganizationSettingsEvent, menuOptionName);
          break;
        case "menu-bar-add-subs":
          menuOptionName = "settings_audience_h_nav_option";
          self._addNavigateEventToChildren(element, EcsEvents.AudienceOrganizationSettingsEvent, menuOptionName);
          break;
        default:
          break;
      }
    }
  });
});