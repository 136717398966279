define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/tags/InlineTaggingWithBadges", "mojo/api/member-list", "mojo/url", "mojo/utils", "mojo/lib/logger", "mojo/user", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation", "dojo/text!./templates/list.html"], function (declare, lang, domClass, domConstruct, registry, Renderable, ItemButtons, InlineTaggingWithBadges, memberListApi, mUrl, utils, logger, user, context, flags, I18nTranslation, tpl) {
  var List = declare([Renderable], {
    templateString: tpl,
    taggingContainer: null,
    _originalListId: null,
    audienceSelectorDisabled: false,
    taggingEnabled: false,
    chooseAnAudience: "Choose an audience",
    segmentYourAudience: "You'll be able to segment your audience by signup source to target people who subscribe from your landing page.",
    youAreUnable: "You’re unable to switch audiences once your landing page is published.",
    audience: "Audience",
    contactsWhoSignUp: "Contacts who sign up will be tagged automatically",
    tags: "Tags",
    optional: "(optional)",
    chooseUpTo: "Choose Up to 5 Tags:",
    /**
     * Function optionally passed down from mojo/views/landing-pages/edit.js
     * to mojo/widgets/checklist-editor/Item.js and formats the
     * tag preview on the slat on save.
     * @param {Array}
     * @return {String} with HTML
     */
    formatTagPreview: null,
    state: {
      listOptions: [],
      listId: null,
      listName: "",
      changedList: false,
      signupTags: []
    },
    constructor: function (props) {
      this._originalListId = props.model.list.id;
      this.state.listId = props.model.list.id;
      this.state.listName = props.model.list.name;
      this.state.type = props.model.type;
      this.taggingEnabled = props.model.hasSignupForm && user.hasRole("manager");
      if (this.taggingEnabled) {
        this.state.signupTags = props.model.signup_tags;
      }
    },
    postMixInProperties: function () {
      if (!I18nTranslation.isTranslationObjectEmpty() && context.flagIsOn(flags.I18N_LANDING_PAGES)) {
        this.chooseAnAudience = I18nTranslation.translate("landing_pages_choose_an_audience");
        this.segmentYourAudience = I18nTranslation.translate("landing_pages_segment_your_audience");
        this.youAreUnable = I18nTranslation.translate("landing_pages_you_are_unable");
        this.audience = I18nTranslation.translate("landing_pages_audience");
        this.contactsWhoSignUp = I18nTranslation.translate("landing_pages_contacts_who_sign_up");
        this.tags = I18nTranslation.translate("landing_pages_tags");
        this.optional = I18nTranslation.translate("landing_pages_optional");
        this.chooseUpTo = I18nTranslation.translate("landing_pages_choose_up_to");
      }
    },
    postCreate: function () {
      memberListApi.list({
        merge_fields: "false"
      }).then(this.setListOptions.bind(this));
    },
    getData: function () {
      var signupTagIds = [];
      var tagPreview = null;
      if (this.taggingEnabled) {
        var signupTags = this.state.signupTags;
        signupTags.forEach(function (tag) {
          signupTagIds.push(tag.tag_id);
        });
        var analyticsCategory = "Tags on Landing Pages - ";
        var status = this.model.status === "published" ? "Published" : "Setup";
        analyticsCategory += status;
        if (signupTags.length > 0) {
          tagPreview = this.formatTagPreview(signupTags);
          logger.googleAnalytics.trackEvent(analyticsCategory, "Clicked", "Save Audience with Tags");
        } else {
          logger.googleAnalytics.trackEvent(analyticsCategory, "Clicked", "Save Audience without Tags");
        }
      }
      return {
        listId: this.state.listId,
        listName: this.state.listName,
        signup_tags: signupTagIds,
        tagPreview: tagPreview
      };
    },
    getOptionMeta: function (option) {
      var meta = option.totalSubscribers.toLocaleString() + " subscribed contacts";
      if (option.hasEcommerceStore) {
        meta += " &middot; Store connected";
      }
      return meta;
    },
    setListOptions: function (response) {
      this.setState({
        listOptions: response
      });
    },
    onListChange: function (listId) {
      var changedList = false;
      if (this._originalListId !== listId) {
        changedList = true;
      }
      this.setState({
        listId: Number(listId),
        isValid: !!listId,
        changedList: changedList,
        signupTags: []
      });
    },
    getListTagOptions: function () {
      var options = this.state.listOptions.map(lang.hitch(this, function (item) {
        var isSelected = this.state.listId && item.listId === this.state.listId ? true : false;
        return {
          value: item.listId,
          label: item.name,
          data: {
            meta: this.getOptionMeta(item)
          },
          selected: isSelected
        };
      }));
      options.unshift({
        label: this.chooseAnAudience
      });
      return options;
    },
    updateSignupTags: function (tags) {
      this.state.signupTags = tags;
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        if (this.itemButtons) {
          this.itemButtons.destroy();
        }
        this.itemButtons = new ItemButtons({
          label: this.audience,
          getData: this.getData.bind(this),
          onSave: this.onSave,
          cancel: this.cancel,
          activate: this.activate,
          isActive: this.isActive,
          isValid: this.state.listId ? true : this.state.isValid,
          showToastMessage: this.state.type === "page",
          successToastVerb: this.model.status === "published" ? "published" : "saved"
        });
        this.itemButtons.placeAt(this.itemButtonsContainer);
        if (this.taggingEnabled) {
          this.inlineTaggingWidget = new InlineTaggingWithBadges({
            name: "pageTaggingWidget",
            listID: this.state.listId,
            uiOptionHideHeader: true,
            currentSelectedTags: this.state.signupTags,
            updateSignupTags: this.updateSignupTags.bind(this),
            analyticsCategory: "Tags on Landing Pages",
            analyticsStatus: this.model.status === "published" ? "Published" : "Setup",
            tagSelectionCTAText: this.chooseUpTo,
            noSelectedTagsText: this.contactsWhoSignUp,
            hideManageTags: true,
            tagLimit: 5
          });
          domConstruct.place("<label class=\"display--inline padding-right--lv1\">" + this.tags + "</label><span class=\"small-meta\">" + this.optional + "</span>", this.inlineTaggingWidget.header, "replace");
          this.inlineTaggingWidget.placeAt(this.taggingContainer);
          var currentSelectedTagsListContainer = registry.findWidgets(this.taggingContainer)[0].currentSelectedTagsListContainer;
          domClass.add(currentSelectedTagsListContainer, "padding-top--lv1");
          domClass.remove(currentSelectedTagsListContainer, "padding-top--lv3");
          domClass.remove(registry.findWidgets(this.taggingContainer)[0].tagsContainer, "padding--lv3");
          domClass.remove(this.audienceLabel, "hide");
          if (this.model.status !== "draft") {
            this.audienceSelectorDisabled = true;
          }
        }
        if (this.state.type === "page" && this.taggingEnabled) {
          var audienceHelpText = "<span class='field-help'>" + this.youAreUnable + "</span>";
          domConstruct.place(audienceHelpText, this.segmentExplanationContainer);
        } else if (this.state.type === "page") {
          var segmentationExplanationText = "<span class='field-help'>" + this.segmentYourAudience + "</span>";
          domConstruct.place(segmentationExplanationText, this.segmentExplanationContainer);
        }
      }
    }
  });

  // Static methods used by the checklist item
  List.isComplete = function (model) {
    return !!model.listId;
  };
  List.isValid = List.isComplete;
  return List;
});