/**
 * @module mojo.utils.string
 *
 * String utility methods.
 * Looking to format another value/type as a string? See that type-specific utility, eg. `mojo.utils.number`.
 *
 * All methods' first argument should be a string.
 */
define([], function () {
  var exports = {
    /**
     * Take a string and capitalize the first letter
     *
     * @param {string} string - A string, like "abc"
     * @return {string} Value titlized, like "Abc"
     */
    capitalize: function (string) {
      var titlizedValue = string.charAt(0).toUpperCase() + string.slice(1);
      return titlizedValue;
    },
    /**
     * Make a string plural, depending on a quantity.
     *
     * @param {string} string - A string to maybe make plural
     * @param {Number} quantity - The quantity to determine if the string should be made plural
     * @return {string} Pluralized string
     */
    toPlural: function (string, quantity) {
      return parseInt(quantity, 10) === 1 ? string : string + "s";
    },
    /**
     * Validates if email address is valid.
     * RegEx from: http://regexlib.com/REDetails.aspx?regexp_id=5011
     *
     * @param {string} email - email address to be validated
     * @return {boolean} True if is a valid email
     */
    isValidEmailAddress: function (email) {
      var regEx = /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+$/i;
      return regEx.test(email);
    },
    /**
     * Validates if a passed-in value is a URL
     *
     * @param {string} string to test
     * @returns {boolean} describing if value is a URL
     */
    isUrl: function (string) {
      // before the URL API https://developer.mozilla.org/en-US/docs/Web/API/URL/URL, we used a regex to determine URL validity
      var passesRegex = /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?|\[|\])*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?|)*)?$/i.test(string);

      // now that the URL API is available in most browsers, we can use it to determine whether a URL looks good
      var passesBrowser;
      try {
        new URL(string);
        passesBrowser = true;
      } catch (e) {
        passesBrowser = false;
      }
      return passesRegex || passesBrowser;
    },
    /**
     * Takes a url and uses built in browser functions to parse the hostname
     *
     * @param {string} url - The URL To be parsed
     * @returns {string} The hostname from the URL
     */
    getUrlHostname: function (url) {
      //Create an element in memory, but does not add it to the dom.
      var tmp_url = document.createElement("a");
      tmp_url.href = url;
      return tmp_url.hostname;
    },
    /**
     * Takes a url and uses built in browser functions to parse the URL path
     *
     * @param {string} url - The URL to be parsed
     * @returns {string} The path from the URL
     */
    getUrlPath: function (url) {
      //Create an element in memory, but does not add it to the dom.
      var tmp_url = document.createElement("a");
      tmp_url.href = url;
      return tmp_url.pathname;
    },
    /**
     * Validates if a passed-in value is a merge field
     *
     * @param {string} string to test
     * @returns {boolean} describing if value is a merge field
     */
    isMergeField: function (string) {
      return /^\*\|\w+(?:\:[^\|]*)?\|\*$/.test(string);
    },
    /**
     * Get the type from merge field. Ex: *|WEBSITE|* would return WEBSITE
     *
     * @param {string} string to test
     * @returns {string} merge field type
     */
    toMergeToken: function (string) {
      if (!exports.isMergeField(string)) {
        return "";
      }
      return string.slice(2, -2);
    },
    /**
     * Get character length of string with symbols
     *
     * @param {string} string Subject to count length of
     * @return {int} length of string accounting for symbols
     */
    getSymbolLength: function (string) {
      // Count characters the way humans would, with emoji as a single character.
      // via https://mathiasbynens.be/notes/javascript-unicode
      var regexAstralSymbols = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
      if (string) {
        return string.replace(regexAstralSymbols, "_").length;
      }
      return 0;
    },
    /**
     * A regular expression used to identify emojis without capturing other 4 byte characters
     */
    emojiRegex: /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g,
    /**
     * Ideally this utility would use an allowlist pattern as opposed to a denylist.
     * However the whitelist would be significantly larger than the blacklist
     * when we consider characters like é and other commonly used characters in other languages
     * @param {string} testableString string to be stripped of emojis
     * @return {string} string without emojis
     */
    stripEmojis: function (testableString) {
      return testableString.replace(this.emojiRegex, "");
    },
    /**
     * Get number of emojis in the string
     *
     * @param {string} string to count emojis in
     * @return {int} number of emojis in this string
     */
    getEmojiCount: function (string) {
      var matches = (string || "").match(this.emojiRegex);
      return (matches || []).length;
    },
    /**
     * Get number of punctuation characters in the string
     *
     * @param {string} string to count punctuation in
     * @return {int} number of punctuation characters in this string
     */
    getPunctuationCount: function (string) {
      var punctuationRegex = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
      return ((string || "").match(punctuationRegex) || []).length;
    },
    /**
     * Get word count (where words are defined as sets of character separated by spaces)
     *
     * @param {string} string to count words in
     * @return {int} number of words in the string
     */
    getWordCount: function (string) {
      return string.split(" ").length;
    },
    /**
     * Truncate string to provided number of characters and optionally ellipse
     *
     * @param {string} string to truncate
     * @param {int} maxLength of string
     * @param {boolean} toEllipse should we append an ellipse to the truncated string
     * @return {string} truncated string
     */
    truncate: function (string, maxLength, toEllipse) {
      if (string && string.length > maxLength) {
        return toEllipse ? string.substring(0, maxLength) + "..." : string.substring(0, maxLength);
      }
      return string;
    },
    /**
     * List out an array of strings as one string in the format:
     * "A" (for one item)
     * "A and B" (for 2 items)
     * "A, B, and C" (for 3+ items)
     *
     * @param {Array} stringArray - A an array of strings
     * @return {string} A string listing out the values in a readable way
     */
    listify: function (stringArray) {
      if (stringArray.length === 1) {
        return stringArray[0];
      }
      if (stringArray.length === 2) {
        return stringArray[0] + " and " + stringArray[1];
      }
      return stringArray.map(function (val, i) {
        var isLast = i === stringArray.length - 1;
        var isSecondToLast = i === stringArray.length - 2;
        if (isLast) {
          return val;
        }
        if (isSecondToLast) {
          return val + ", and ";
        }
        return val + ", ";
      }).join("");
    }
  };
  return exports;
});