define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./Source"], function (declare, domClass, Source) {
  return declare("dojo.dnd.Target", Source, {
    // summary:
    //		a Target object, which can be used as a DnD target

    constructor: function /*===== node, params =====*/
    () {
      // summary:
      //		a constructor of the Target --- see the `dojo/dnd/Source` constructor for details
      this.isSource = false;
      domClass.remove(this.node, "dojoDndSource");
    }
  });
});