module.exports = {
  redLabel: "r",
  greenLabel: "g",
  blueLabel: "b",
  hueLabel: "h",
  saturationLabel: "s",
  valueLabel: "v",
  /* aka intensity or brightness */
  degLabel: "\u00B0",
  hexLabel: "hex",
  huePickerTitle: "Hue Selector",
  saturationPickerTitle: "Saturation Selector"
};