define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/promise/all", "mojo/url", "mojo/validation/Error", "mojo/lib/Poller", "mojo/lib/request-helpers"], function (declare, xhr, all, mUrl, ValidationError, Poller, requestHelpers) {
  var SegmentGroup = declare(null, {
    constructor: function (properties) {
      this.type = properties.type;
      this.segments = properties.segments;
    }
  });
  var Segment = declare(null, {
    constructor: function (properties) {
      this.segmentId = properties.id;
      this.name = properties.name;
      this.listId = properties.list_id;
      this.type = properties.type;
      this.typeDescription = properties.typeDescription;
      this.createdAt = properties.createdDate;
      this.updatedAt = properties.updatedDate;
      this.status = properties.status;
    }
  });
  var PastedSegment = declare(Segment, {
    constructor: function (properties) {
      this.originalPastedBlob = properties.pasted.emails;
      this.excludeOrInclude = properties.pasted.pasted_segment_options;
      this.malformedEmails = properties.pasted.invalid_data;
      this.notExistsEmails = properties.pasted.not_exists;
      this.unsubbedEmails = properties.pasted.unsubbed;
      this.count = properties.count;
    }
  });
  var EditorHtmlResponse = declare(null, {
    constructor: function (properties) {
      this.editorHtml = properties.editor_html;
    }
  });
  var CountResponse = declare(null, {
    constructor: function (properties) {
      this.count = properties.count;
    }
  });
  var SegmentApi = {
    /**
     * Build the html for the segment editor, relying on the backend for building out the form
     *
     * @param {integer} listId - List id to build the segment editor in context of
     * @param {Object} segmentConditions - Conditions for which to build the editor html
     *
     * @returns {Promise<EditorHtmlResponse>} A Promise resolving with the response for this endpoint
     */
    getSegmentEditorHtml: function (listId, segmentConditions) {
      var url = mUrl.toUrl("/campaigns/segments/get-editor", {
        list_id: listId,
        conditions: segmentConditions
      });
      return xhr.get(url, {
        handleAs: "json"
      }).then(function (response) {
        return new EditorHtmlResponse(response);
      }, requestHelpers.handleError);
    },
    /**
     * Build a static segment from pasted content, or get an error back about what was dropped
     *
     * @param {integer} listId - List id to build segment in context of
     * @param {string} emailsBlob - Big string of newline-, or comma-delimited email addresses
     * @param {string} excludeOrInclude - One of "exclude" or "include"
     *
     * @returns {Promise<PastedSegment>} A Promise resolving with the response for this endpoint
     */
    createPastedSegment: function (listId, emailsBlob, excludeOrInclude) {
      var url = mUrl.toUrl("/campaigns/segments/create-pasted", {
        list_id: listId
      });
      return xhr.post(url, {
        handleAs: "json",
        data: {
          emails: emailsBlob,
          pasted_segment_options: excludeOrInclude
        }
      }).then(function (response) {
        return new PastedSegment(response);
      }, requestHelpers.throwError);
    },
    /**
     * Get a pasted segment by its id.
     *
     * @param {integer} segmentId - Segment id to get
     *
     * @returns {Promise<Segment>} - A Promise resolving to a Segment instance
     */
    getSegment: function (segmentId) {
      var url = mUrl.toUrl("/campaigns/segments/get-segment", {
        segment_id: segmentId
      });
      return xhr.get(url, {
        handleAs: "json"
      }).then(function (response) {
        if ("pasted" in response) {
          return new PastedSegment(response);
        }
        return new Segment(response);
      }, requestHelpers.throwError);
    },
    /**
     * Get the count of this segment in the context of a list.
     *
     * @param {integer} listId List id in which to segment
     * @param {object} segment Segment to calculate count for
     *
     * @returns {Promise} Resolves to CountResponse, or rejects with error
     */
    getCount: function (listId, segment) {
      var pollableFunc = this._getPollableFunc(listId, segment);
      var poller = new Poller(pollableFunc);
      poller.begin();
      return poller.getPromise();
    },
    /**
     * Depending on the type of segment, return appropriate poll-able function with parameters already bound.
     *
     * @param {integer} listId List id in which to segment (doesn't apply to saved)
     * @param {object} segment Segment to calculate count for
     *
     * @returns {Function} A function which is compatible for use with Pollable
     *
     * @private
     */
    _getPollableFunc: function (listId, segment) {
      if (segment === null) {
        return SegmentApi._getCustomSegmentCount.bind(null, listId, {
          match: "any",
          conditions: []
        });
      }
      if ([SegmentApi.TYPE_SAVED, SegmentApi.TYPE_STATIC, SegmentApi.PANE_PASTED].indexOf(segment.type) >= 0) {
        return SegmentApi._getSavedSegmentCount.bind(null, segment.id);
      }
      if (segment.type === SegmentApi.TYPE_PREBUILT) {
        return SegmentApi._getPrebuiltSegmentCount.bind(null, listId, segment.id);
      }
      if (segment.type === SegmentApi.TYPE_CUSTOM) {
        return SegmentApi._getCustomSegmentCount.bind(null, listId, segment.value);
      }
      if (segment.type === SegmentApi.TYPE_SAVED_PRO) {
        return SegmentApi._getProSavedSegmentCount.bind(null, segment.id);
      }
      if (segment.type === SegmentApi.TYPE_CUSTOM_PRO) {
        return SegmentApi._getProCustomSegmentCount.bind(null, listId, segment.value);
      }
      throw new Error("No counting function for segment type '" + segment.type + "'");
    },
    _getSavedSegmentCount: function (savedSegmentId) {
      return SegmentApi._makePollingRequest(mUrl.toUrl("/segments/saved/get-recipients-count", {
        saved_segment_id: savedSegmentId
      }));
    },
    _getPrebuiltSegmentCount: function (listId, prebuiltSegmentId) {
      return SegmentApi._makePollingRequest(mUrl.toUrl("/segments/prebuilt/get-recipients-count", {
        list_id: listId,
        prebuilt_segment_id: prebuiltSegmentId
      }));
    },
    _getCustomSegmentCount: function (listId, segment) {
      return SegmentApi._makePollingRequest(mUrl.toUrl("/segments/custom/get-recipients-count", {
        list_id: listId,
        segment: JSON.stringify(segment)
      }));
    },
    _getProSavedSegmentCount: function (proSavedSegmentId) {
      return SegmentApi._makePollingRequest(mUrl.toUrl("/segments/pro-saved/get-recipients-count", {
        pro_saved_segment_id: proSavedSegmentId
      }));
    },
    _getProCustomSegmentCount: function (listId, ast) {
      return SegmentApi._makePollingRequest(mUrl.toUrl("/segments/pro-custom/get-recipients-count", {
        list_id: listId,
        ast: JSON.stringify(ast)
      }));
    },
    /**
     * Make a request to the given url, transforming the response to compatible with Poller
     *
     * @param {string} url - Url to request
     *
     * @returns {Promise<*>} A Promise resolving to either a CountResponse, Poller.RETRY, or rejecting.
     *
     * @private
     */
    _makePollingRequest: function (url) {
      return xhr.get(url, {
        handleAs: "json"
      }).then(function (response) {
        if (response.status === "waiting") {
          return Poller.RETRY;
        }
        return new CountResponse(response);
      }, requestHelpers.throwError);
    },
    /**
     * Get segment groups for a postcard list ID, pulling from cache is possible
     *
     * @param {integer} listId - List id for which to fetch segment groups
     *
     * @returns {Promise<SegmentGroup[]>} - A Promise resolving to an array of segment groups
     */
    getCachedPostcardSegmentGroups: function (listId) {
      return this._getCachedSegmentGroups(listId, "lists/segments/saved-postcards");
    },
    /**
     * Cache of all segment groups loaded from _getCachedSegmentGroups
     */
    _cachedSegmentGroups: {},
    /**
     * Get segment groups for a list ID, pulling from cache if possible
     *
     * @param {integer} listId - List id for which to fetch segment groups
     * @param {String} url - URL from which to fetch segments
     *
     * @returns {Promise<SegmentGroup[]>} - A Promise resolving to an array of segment groups
     */
    _getCachedSegmentGroups: function (listId, url) {
      var cachedSegmentGroups = this._cachedSegmentGroups[listId];
      if (cachedSegmentGroups) {
        return all().then(function () {
          return cachedSegmentGroups;
        });
      }
      return this.getSegmentGroups(listId, url).then(function (segmentGroups) {
        var segmentGroupOrder = ["contact-status", "prebuilt", "static", "saved"];
        var orderedSegmentGroups = segmentGroups.sort(function (a, b) {
          var aOrder = segmentGroupOrder.indexOf(a.type);
          var bOrder = segmentGroupOrder.indexOf(b.type);
          if (aOrder > bOrder) {
            return 1;
          }
          return bOrder === aOrder ? 0 : -1;
        });
        this._cachedSegmentGroups[listId] = orderedSegmentGroups;
        return orderedSegmentGroups;
      }.bind(this));
    },
    /**
     * Fetch recipient count for a given list/segment
     *
     * @param {Number} listId - List id
     * @param {string} segmentId - Segment id
     * @param {string} segmentType - Segment type
     * @return {Promise.<Number>} A Promise resolving to the number of recipients
     */
    fetchSegmentCount: function (listId, segmentId, segmentType) {
      return xhr.post(mUrl.toUrl("/lists/segments/saved-postcard-segment-count", {
        "id": listId,
        "segment_id": segmentId,
        "segment_type": segmentType
      }), {
        "handleAs": "json"
      }).then(function (responseJSON) {
        return responseJSON.count;
      });
    },
    /**
     * Get segment groups for a list ID
     *
     * @param {integer} listId - List id for which to fetch segment groups
     * @param {String} url - URL from which to fetch segments
     *
     * @returns {Promise<SegmentGroup[]>} - A Promise resolving to an array of segment groups
     */
    getSegmentGroups: function (listId, url) {
      return xhr.get(mUrl.toUrl(url), {
        handleAs: "json",
        query: {
          id: listId
        }
      }).then(function (segmentGroupsResponse) {
        return Object.keys(segmentGroupsResponse).map(function (segmentGroupKey) {
          var segmentGroupValue = segmentGroupsResponse[segmentGroupKey];
          var segments = Object.keys(segmentGroupValue).map(function (segmentId) {
            return new Segment({
              id: segmentId,
              name: segmentGroupValue[segmentId],
              list_id: listId
            });
          });
          return new SegmentGroup({
            type: segmentGroupKey,
            segments: segments
          });
        });
      });
    }
  };
  SegmentApi.TYPE_SAVED = "saved";
  SegmentApi.TYPE_CUSTOM = "custom";
  SegmentApi.TYPE_PREBUILT = "prebuilt";
  SegmentApi.TYPE_SAVED_PRO = "saved_pro";
  SegmentApi.TYPE_CUSTOM_PRO = "custom_pro";
  SegmentApi.TYPE_STATIC = "static";
  SegmentApi.TYPE_PASTED = "pasted";
  return SegmentApi;
});