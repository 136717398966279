define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/app/lists/segments",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "mojo/analytics/parts/_Part",
// Templates
"dojo/text!./templates/send-campaign-dropdown.html",
// Ads constants
"../ads/constants", "mojo/utils/I18nTranslation", "mojo/lib/flags", "mojo/context"], function (declare, lang, domStyle, query, on, mUrl, segments, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _Part, tpl, adsConstants, I18nTranslation, flags, context) {
  return declare([_Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    target: null,
    listId: null,
    adsConstants: {},
    sendCampaign: "Send Campaign",
    anEmailCampaign: "An email campaign",
    aSocialAd: "A Facebook / Instagram ad",
    aSocialAdToContacts: "A Facebook / Instagram ad to similar contacts",
    aPostcard: "A Postcard",
    constructor: function () {
      this.adsConstants = adsConstants;
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize();
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          this.sendCampaign = I18nTranslation.translate("audience_management_send_campaign");
          this.anEmailCampaign = I18nTranslation.translate("audience_management_email_campaign");
          this.aSocialAd = I18nTranslation.translate("audience_management_social_ad");
          this.aSocialAdToContacts = I18nTranslation.translate("audience_management_social_ad_contacts");
          this.aPostcard = I18nTranslation.translate("audience_management_postcard");
        }
      }
    },
    postCreate: function () {
      this.target.watch("total", lang.hitch(this, "checkAndDisableAds"));
      on(query(this.sendCampaign), "click", lang.hitch(this, function () {
        window.ga("send", {
          hitType: "event",
          eventCategory: "segments",
          eventAction: "cta",
          eventLabel: "button",
          dimension53: this.sendCampaignDropdownButton.innerText,
          dimension55: "send campaign menu",
          dimension56: "open menu"
        });
      }));
    },
    /**
     * Check for how many contacts are present in the target and react accordingly for ad campaigns
     */
    checkAndDisableAds: function () {
      // Disable ad send if not enough contacts
      var adsMeta = query(".js-ads-action-meta", this.adAction.domNode);
      var lookalikeMeta = query(".js-lookalike-action-meta", this.lookalikeAction.domNode);
      if (this.target.total < adsConstants.minAdCount) {
        this.adAction.set("disabled", true);
        adsMeta.removeClass("hide");
      } else {
        this.adAction.set("disabled", false);
        adsMeta.addClass("hide");
      }
      // Disable lookalike ad send if not enough contacts
      if (this.target.total < adsConstants.minLookalikeCount) {
        this.lookalikeAction.set("disabled", true);
        lookalikeMeta.removeClass("hide");
      } else {
        this.lookalikeAction.set("disabled", false);
        lookalikeMeta.addClass("hide");
      }
    },
    /**
     * Navigate to email campaign creation
     */
    onSendAction: function () {
      var segment = this.target.segment;

      // isSegment is true when it is an existing segment
      // isSegment is false when it is a segment preview
      var isSegment = segment && segment.hasOwnProperty("id");
      var params = {
        "list_id": this.listId,
        "__csrf_token": this.csrfToken
      };
      if (segment && segment.type === "advanced") {
        params["pro_segment_id"] = segment.id;
      } else if (isSegment) {
        params["segment_id"] = segment.id;
      } else {
        params["segment"] = 1;
      }
      if (this.campaignOrigin) {
        params["co"] = this.campaignOrigin;
      }
      window.ga("send", "event", "segments", "cta", "button", {
        cd53: "An email campaign",
        cd54: mUrl.toUrl("campaigns/create?type=regular", params),
        cd55: "send campaign menu",
        cd56: "redirect to campaign creator"
      });
      window.location = mUrl.toUrl("campaigns/create?type=regular", params);
    },
    onAdAction: function () {
      this._createAdFromSegment(this.target.segment, false);
    },
    onLookalikeAction: function () {
      this._createAdFromSegment(this.target.segment, true);
    },
    _createAdFromSegment: function (segment, lookAlike) {
      var listId = this.listId;

      // segments in limbo don't have an id or type.
      if (!segment.id || !segment.type) {
        segments.confirmSave(listId).then(lang.hitch(this, function (newSegment) {
          this._adsRedirect(newSegment, lookAlike);
        }));
      } else {
        this._adsRedirect(segment, lookAlike);
      }
    },
    _adsRedirect: function (segment, lookAlike) {
      var params = {
        list_id: this.listId,
        lookalike: lookAlike,
        source_id: segment.type.concat(":", segment.id),
        type: "facebook"
      };
      window.ga("send", "event", "segments", "cta", "button", {
        cd53: "A Facebook / Instagram ad",
        cd54: mUrl.toUrl("/ads/create", params),
        cd55: "Ads menu",
        cd56: "redirect to Ads creator"
      });
      window.location = mUrl.toUrl("/ads/create", params);
    },
    _onPostcardAction: function () {
      this._createPostcardFromSegment(this.target.segment);
    },
    _createPostcardFromSegment: function (segment) {
      var listId = this.listId;
      if (!segment.id || !segment.type) {
        segments.confirmSave(listId).then(lang.hitch(this, function (newSegment) {
          this._postcardsRedirect(newSegment);
        }));
      } else {
        this._postcardsRedirect(segment);
      }
    },
    _postcardsRedirect: function (segment) {
      var params = {
        list_id: this.listId,
        segment_id: segment.id,
        segment_type: segment.type
      };
      window.ga("send", "event", "segments", "cta", "button", {
        cd53: "A Postcard",
        cd54: mUrl.toUrl("/postcards/create", params),
        cd55: "Postcards menu",
        cd56: "redirect to Postcards creator"
      });
      window.location = mUrl.toUrl("/postcards/create", params);
    }
  });
});