define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/lib/clipboard", "mojo/app/peaches2/utils", "mojo/utils", "mojo/url", "mojo/context", "mojo/lib/flags"], function (declare, lang, dijit, base, domAttr, on, domStyle, domClass, xhr, query, clipboard, p2Utils, utils, mUrl, context, flags) {
  return declare([base], {
    /**
     * the current page.js object that is running. Will include any methods that should be
     * avaiable in this module as well as usersAttrs, the tabUtility, and pageTabs
     */
    pageJs: null,
    elId: null,
    packageLink: "",
    onReady: function () {
      var self = this;
      var planChangeReasonModal = self.$widget.planChangeReasonModal;
      var confirmationModal = self.$widget.confirmationModal;
      this.$group.submitDateForm.forEach(function (el) {
        on(el, "click", function (e) {
          e.stopPropagation();
          e.preventDefault();
          var form = this.parentElement;
          p2Utils.utils.sendFormViaAjax(form).then(function () {
            self.pageJs.tabContainer.billingSetupSection.setHref(mUrl.toUrl("peaches2/users/billing?user_id=" + self.pageJs.userAttrs.userId + "&_s=billing-setup"));
          });
        });
      });
      if (context.flagIsOn(flags.M10N_ADMIN_AGENTS_TO_ADD_MORE_THAN_ONE_DISCOUNT)) {
        var discountButtons = document.querySelectorAll('[data-mc-el^="discountExpirationInputEdit"]');
        discountButtons.forEach(function (button) {
          button.addEventListener("click", function () {
            var discountId = this.getAttribute("data-mc-el").replace("discountExpirationInputEdit", "");
            var discountInput = document.querySelector('[data-mc-el="discountExpirationInput' + discountId + '"]');
            var discountInputEdit = document.querySelector('[data-mc-el="discountExpirationInputEdit' + discountId + '"]');
            discountInput.classList.remove("hide");
            discountInputEdit.classList.add("hide");
          });
        });
      }
      if (this.$el.firstPaymentInputEdit) {
        on(this.$el.firstPaymentInputEdit, "click", function () {
          domClass.remove(self.$el.firstPaymentInput, "hide");
          domClass.add(self.$el.firstPaymentInputEdit, "hide");
        });
      }
      if (this.$el.lastPaymentInputEdit) {
        on(this.$el.lastPaymentInputEdit, "click", function () {
          domClass.remove(self.$el.lastPaymentInput, "hide");
          domClass.add(self.$el.lastPaymentInputEdit, "hide");
        });
      }
      if (this.$el.monthlyStartDateInputEdit) {
        on(this.$el.monthlyStartDateInputEdit, "click", function () {
          domClass.remove(self.$el.monthlyStartDateInput, "hide");
          domClass.add(self.$el.monthlyStartDateInputEdit, "hide");
        });
      }
      if (this.$el.discountExpirationInputEdit) {
        on(this.$el.discountExpirationInputEdit, "click", function () {
          domClass.remove(self.$el.discountExpirationInput, "hide");
          domClass.add(self.$el.discountExpirationInputEdit, "hide");
        });
      }
      if (this.$el.monthlyPaymentFailedInputEdit) {
        on(this.$el.monthlyPaymentFailedInputEdit, "click", function () {
          domClass.remove(self.$el.monthlyPaymentFailedInput, "hide");
          domClass.add(self.$el.monthlyPaymentFailedInputEdit, "hide");
        });
      }
      if (this.$el.twoFactorExpirationInputEdit) {
        on(this.$el.twoFactorExpirationInputEdit, "click", function () {
          domClass.remove(self.$el.twoFactorExpirationInput, "hide");
          domClass.add(self.$el.twoFactorExpirationInputEdit, "hide");
        });
      }
      if (this.$el.packageStartDateInputEdit) {
        on(this.$el.packageStartDateInputEdit, "click", function () {
          domClass.remove(self.$el.packageStartDateInput, "hide");
          domClass.add(self.$el.packageStartDateInputEdit, "hide");
        });
      }
      if (this.$el.packageEndDateInputEdit) {
        on(this.$el.packageEndDateInputEdit, "click", function () {
          domClass.remove(self.$el.packageEndDateInput, "hide");
          domClass.add(self.$el.packageEndDateInputEdit, "hide");
        });
      }
      if (this.$el.btResetToTomorrow) {
        on(this.$el.btResetToTomorrow, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to reset the customer's next plan update to tomorrow?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/reset-plan-update-date-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              new_package: self.$el.slChangePackage.value,
              s: "reset-plan-update-date-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                alert("Successfully updated plan update to tomorrow!");
                location.reload();
              } else {
                alert("An error occurred while trying to update your plan update: " + response.exception);
              }
            });
          }
        });
      }
      if (this.$el.monthlyPlanRadio.checked) {
        domClass.add(this.$el.paygoPlanSelect, "hide");
      }
      if (this.$el.paygoPlanRadio.checked) {
        domClass.add(this.$el.monthlyPlanSelect, "hide");
      }
      var btSubmitPurchaseIntentSetting = self.$el.btSubmitPurchaseIntentSetting;
      var inputPurchaseIntentSetting = self.$el.inputPurchaseIntentSetting;
      if (btSubmitPurchaseIntentSetting && inputPurchaseIntentSetting) {
        on(btSubmitPurchaseIntentSetting, "click", function () {
          xhr(mUrl.toUrl("/peaches2/users/billing/update-purchase-intent-setting", {
            user_id: self.pageJs.userAttrs.userId,
            purchase_intent: inputPurchaseIntentSetting.value
          }), {
            handleAs: "json"
          }).then(function (response) {
            if (response.successful === true) {
              utils.globalPublish("show-toast", [{
                "message": "Successfully updated purchase intent setting!",
                "type": "success"
              }]);
            } else {
              utils.globalPublish("show-toast", [{
                "message": "An error occurred while trying to update your purchase intent setting.",
                "type": "error"
              }]);
            }
          });
        });
      }
      var btSubmitPurchaseTaxIdSetting = self.$el.btSubmitPurchaseTaxIdSetting;
      var inputPurchaseTaxIdSetting = self.$el.inputPurchaseTaxIdSetting;
      if (btSubmitPurchaseTaxIdSetting && inputPurchaseTaxIdSetting) {
        on(btSubmitPurchaseTaxIdSetting, "click", function () {
          xhr(mUrl.toUrl("/peaches2/users/billing/update-purchase-tax-id-setting", {
            user_id: self.pageJs.userAttrs.userId,
            vat_id: inputPurchaseTaxIdSetting.value
          }), {
            handleAs: "json"
          }).then(function (response) {
            if (response.successful === true) {
              utils.globalPublish("show-toast", [{
                "message": "Successfully updated purchase Tax Id setting!",
                "type": "success"
              }]);
            } else {
              utils.globalPublish("show-toast", [{
                "message": "An error occurred while trying to update your purchase Tax Ids setting.",
                "type": "error"
              }]);
            }
          });
        });
      }
      var inputContractId = this.$el.inputContractId;
      var inputContractStartDate = this.$el.inputContractStartDate;
      var inputContractEndDate = this.$el.inputContractEndDate;
      var selectContractType = dijit.byId("selectContractType");
      var selectContractDiscountPercent = dijit.byId("selectContractDiscountPercent");
      if (this.$el.addContractBtn) {
        on(this.$el.addContractBtn, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to add a contract to this account?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/add-contract-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              s: "add-contract-for-user",
              contract_id: inputContractId.value,
              contract_type: selectContractType.getValue(),
              contract_discount_percent: selectContractDiscountPercent.getValue(),
              contract_start_date: inputContractStartDate.value,
              contract_end_date: inputContractEndDate.value
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully added contract!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "Error when adding contract: " + (response.exception ? response.exception : response.msg),
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      var voidedReason = this.$el.voidedReason;
      var contractAccountId = this.$el.contractAccountId;
      if (this.$el.voidContractBtn) {
        on(this.$el.voidContractBtn, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to void this contract?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/void-ies-contract", {
              user_id: self.pageJs.userAttrs.userId,
              s: "void-ies-contract",
              contract_account_id: contractAccountId.value,
              voided_reason: voidedReason.value
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully voided contract!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "Error when voiding contract: " + (response.exception ? response.exception : response.msg),
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      on(this.$el.monthlyPlanRadio, "change", function () {
        if (self.$el.monthlyPlanRadio.checked) {
          domClass.toggle(self.$el.monthlyPlanSelect, "hide");
          domClass.toggle(self.$el.paygoPlanSelect, "hide");
        }
      });
      on(this.$el.paygoPlanRadio, "change", function () {
        if (self.$el.paygoPlanRadio.checked) {
          domClass.toggle(self.$el.monthlyPlanSelect, "hide");
          domClass.toggle(self.$el.paygoPlanSelect, "hide");
        }
      });

      // Filters available tiers in the plan change tool based on country / package selection
      function filterTiers(tiers, country, packageName) {
        var isPartnershipPlan = null;
        if (packageName.startsWith("partnership_")) {
          packageName = packageName.slice("partnership_".length);
          isPartnershipPlan = true;
        }
        var isMultiAccountPlan = null;
        if (packageName.startsWith("multi_account_")) {
          packageName = packageName.slice("multi_account_".length);
          isMultiAccountPlan = true;
        }
        return Object.values(tiers).filter(function (tier) {
          if (isPartnershipPlan) {
            return tier.package === packageName && tier.is_partnership_plan === "true" && (tier.country.toLowerCase() === country.toLowerCase() || ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0", "paygo_plan_v1", "paygo_classic_plan_v1"].includes(packageName));
          }
          if (isMultiAccountPlan) {
            return tier.package === packageName && tier.is_multi_account_plan === "true" && (tier.country.toLowerCase() === country.toLowerCase() || ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0", "paygo_plan_v1", "paygo_classic_plan_v1"].includes(packageName));
          }
          if (!(tier.is_partnership_plan === "true" || tier.is_multi_account_plan === "true")) {
            return tier.package === packageName && (tier.country.toLowerCase() === country.toLowerCase() || ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0", "paygo_plan_v1", "paygo_classic_plan_v1"].includes(packageName));
          }
        });
      }

      // Sorts a list of tiers into sorted groups represented by an array of arrays containing a group tiers.
      // Algorithm will group tiers by common activation_timestamp values and common is_hidden statuses (published/unpublished plans).
      // if flag FINOPS_PLAN_CHANGE_TIER_REFACTOR is on then
      // Groups are sorted such that the most recently "activated" are first, with the is_hidden status as the tiebreaker; false value is ranked above true.
      // if flag FINOPS_PLAN_CHANGE_TIER_REFACTOR is off then
      // Groups are sorted such that is_hidden status value false are first, with the activation_timestamp as the tiebreaker; most recent activated tier is ranked above.
      function sortTiers(tiers) {
        var groupedTiers = [];

        //if flag is turned off, then first show all published plans and then show all unpublished plan
        if (!context.flagIsOn(flags.FINOPS_PLAN_CHANGE_TIER_REFACTOR)) {
          tiers.sort(function (tier1, tier2) {
            if (tier1.is_hidden !== tier2.is_hidden) {
              var istier1Unpublished = tier1.is_hidden === "true" ? true : false;
              var istier2Unpublished = tier2.is_hidden === "true" ? true : false;
              return istier1Unpublished - istier2Unpublished;
            }
            return tier2.activation_timestamp - tier1.activation_timestamp;
          });
        } else {
          tiers.sort(function (tier1, tier2) {
            if (tier1.activation_timestamp === tier2.activation_timestamp) {
              return tier1.is_hidden === tier2.is_hidden;
            }
            return tier2.activation_timestamp - tier1.activation_timestamp;
          });
        }
        var currentTimestamp = null;
        var currentHiddenStatus = null;
        var currentGroup = [];
        tiers.forEach(function (tier) {
          if (tier.activation_timestamp !== currentTimestamp || tier.is_hidden !== currentHiddenStatus) {
            if (currentGroup.length > 0) {
              groupedTiers.push(currentGroup);
            }
            currentTimestamp = tier.activation_timestamp;
            currentHiddenStatus = tier.is_hidden;
            currentGroup = [];
          }
          currentGroup.push(tier);
        });
        if (currentGroup.length > 0) {
          groupedTiers.push(currentGroup);
        }
        return groupedTiers;
      }

      // Clears a Dojo/Dijit Dropdown Options
      function clearOptions(selectControl) {
        selectControl.options.forEach(function (option) {
          selectControl.removeOption(option);
        });
        selectControl._setDisplay("");
      }

      // New Plan Change Tool (Est. 07/2020, Revised 02/2023)
      var slCountry = dijit.byId("slCountry");
      var slMarketingPlan = dijit.byId("slMarketingPlan");
      var slCommercePlan = dijit.byId("slCommercePlan");
      var slCRMPlan = dijit.byId("slCRMPlan");
      var slTier = dijit.byId("slTier");
      var btApplyPlanChange = self.$el.btApplyPlanChange; // btApplyPlanChange will load the planChangeReasonModal that contains btSubmitPlanChange
      var btSubmitPlanChange = self.$widget.btSubmitPlanChange; // btSubmitPlanChange will submit the plan change request
      var btToggleUnpublishedTiers = self.$widget.btToggleUnpublishedTiers;
      var currentCountry = "";
      var currentMarketingPlan = "";
      var currentCommercePlan = "";
      var currentCRMPlan = "";
      var currentTier = "";
      // Check to see if the plan change tool exists
      if (context.flagIsOn(flags.PLAN_CRM_WRAPPER)) {
        if (slCountry && slMarketingPlan && slCommercePlan && slCRMPlan && slTier) {
          currentCountry = slCountry.getValue(); // store the user's current country
          currentMarketingPlan = slMarketingPlan.getValue(); // store the user's current marketing plan
          currentCommercePlan = slCommercePlan.getValue(); // store the user's current commerce plan
          currentCRMPlan = slCRMPlan.getValue(); // store the user's current CRM plan
          currentTier = slTier.getValue(); // store the user's current tier
          handleInputChange(); // Refresh dropdowns to ensure proper state
          slTier.set("value", currentTier); // attempt to force the user's current tier to be preselected since handleInputChange() negates preselection
        }
      } else if (slCountry && slMarketingPlan && slCommercePlan && slTier) {
        currentCountry = slCountry.getValue(); // store the user's current country
        currentMarketingPlan = slMarketingPlan.getValue(); // store the user's current marketing plan
        currentCommercePlan = slCommercePlan.getValue(); // store the user's current commerce plan
        currentTier = slTier.getValue(); // store the user's current tier
        handleInputChange(); // Refresh dropdowns to ensure proper state
        slTier.set("value", currentTier); // attempt to force the user's current tier to be preselected since handleInputChange() negates preselection
      }

      // Attempts to toggle the state of feature flag "finops.plan_change_tier_refactor" for the current user
      if (btToggleUnpublishedTiers) {
        on(btToggleUnpublishedTiers, "click", function (e) {
          e.preventDefault();
          domClass.add(btToggleUnpublishedTiers, "disabled");
          xhr(mUrl.toUrl("/peaches2/users/billing/toggle-unpublished-plan-conversions", {
            user_id: self.pageJs.userAttrs.userId,
            s: "toggle-unpublished-plan-conversions"
          }), {
            handleAs: "json"
          }).then(function () {
            location.reload();
          });
        });
      }

      // Updates available plan tiers, enabled/disabled state of dropdowns depending on validity of current inputs
      function handleInputChange(countryOverride, packageNameOverride) {
        var country;
        var marketingPackageName;
        var allTiers;
        var noOption;
        var filteredTiers;
        var userCurrentTierNotVisibleOption;
        var groupedSortedTiers;
        if (context.flagIsOn(flags.PLAN_CRM_WRAPPER)) {
          if (slCountry && slMarketingPlan && slCommercePlan && slCRMPlan && slTier && btApplyPlanChange) {
            country = countryOverride || slCountry.get("value");
            marketingPackageName = packageNameOverride || slMarketingPlan.get("value");
            allTiers = JSON.parse(self.$el.allTiers.value);

            // Used to render a distinct option to clarify when there is no selection to be made
            noOption = {
              label: "N/A",
              value: "N/A"
            };
            if (["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0"].includes(marketingPackageName)) {
              slCountry.addOption(noOption);
              slCountry.set("value", "N/A");
              slCountry.set("disabled", true);
            } else {
              slCountry.removeOption(noOption);
              slCountry.set("disabled", false);
            }
            filteredTiers = filterTiers(allTiers, country, marketingPackageName);
            clearOptions(slTier);

            // Disable tier selection if there are no tiers for a given marketing package
            // Otherwise, let's group the tiers we have and label them accordingly in the dropdown
            if (filteredTiers.length === 0) {
              slTier.addOption(noOption);
              slTier.set("value", "N/A");
              slTier.set("disabled", true);
            } else {
              // Used to render a distinct option to clarify when the user's plan is not currently in the dropdown
              userCurrentTierNotVisibleOption = {
                label: "--Select One--",
                value: ""
              };
              slTier.addOption(userCurrentTierNotVisibleOption);
              slTier.set("value", "");
              // Begin sorting and grouping the tiers available for the selected plan configuration
              groupedSortedTiers = sortTiers(filteredTiers);
              groupedSortedTiers.forEach(function (tierGroup) {
                // When we create a new tier group, we should create a new "disabled" option as a title
                // We will use the data from the first entry in the tierGroup to inform our title's contents
                var optionGroupTitleLabel = (tierGroup[0].is_hidden === "true" ? "Unpublished" : "Published") + " " + (tierGroup.length > 1 ? "Plans" : "Plan") + " " + (tierGroup[0].activation_date_string === "null" ? "Activated before 2020" : "Activated on " + tierGroup[0].activation_date_string);
                var optionGroupTitleValue = (tierGroup[0].activation_timestamp || "null") + "-" + (tierGroup[0].is_hidden === "true" ? "unpublished" : "published") + "-option-group-title";
                var optionGroupTitle = {
                  label: "-------------(" + optionGroupTitleLabel + ")-------------",
                  value: optionGroupTitleValue
                };
                slTier.addOption(optionGroupTitle);
                // Title added, now we must disable it
                var slTierOptions = slTier.getOptions();
                for (var i = 0; i < slTierOptions.length; i++) {
                  if (slTierOptions[i].value === optionGroupTitleValue) {
                    slTierOptions[i].disabled = true;
                    slTier.updateOption(slTierOptions);
                    break;
                  }
                }
                // Add in the subsequent children of the title
                tierGroup.forEach(function (tier) {
                  var option = {
                    label: tier.name,
                    value: tier.value
                  };
                  //if flag is turned off, unpublished plans are disabled i.e. not selectable
                  if (!context.flagIsOn(flags.FINOPS_PLAN_CHANGE_TIER_REFACTOR) && tier.is_hidden === "true") {
                    option.disabled = true;
                  }
                  slTier.addOption(option);
                });
              });
              slTier.set("disabled", false);
            }
          }
        } else if (slCountry && slMarketingPlan && slCommercePlan && slTier && btApplyPlanChange) {
          country = countryOverride || slCountry.get("value");
          marketingPackageName = packageNameOverride || slMarketingPlan.get("value");
          allTiers = JSON.parse(self.$el.allTiers.value);

          // Used to render a distinct option to clarify when there is no selection to be made
          noOption = {
            label: "N/A",
            value: "N/A"
          };
          if (["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0"].includes(marketingPackageName)) {
            slCountry.addOption(noOption);
            slCountry.set("value", "N/A");
            slCountry.set("disabled", true);
          } else {
            slCountry.removeOption(noOption);
            slCountry.set("disabled", false);
          }
          filteredTiers = filterTiers(allTiers, country, marketingPackageName);
          clearOptions(slTier);

          // Disable tier selection if there are no tiers for a given marketing package
          // Otherwise, let's group the tiers we have and label them accordingly in the dropdown
          if (filteredTiers.length === 0) {
            slTier.addOption(noOption);
            slTier.set("value", "N/A");
            slTier.set("disabled", true);
          } else {
            // Used to render a distinct option to clarify when the user's plan is not currently in the dropdown
            userCurrentTierNotVisibleOption = {
              label: "--Select One--",
              value: ""
            };
            slTier.addOption(userCurrentTierNotVisibleOption);
            slTier.set("value", "");
            // Begin sorting and grouping the tiers available for the selected plan configuration
            groupedSortedTiers = sortTiers(filteredTiers);
            groupedSortedTiers.forEach(function (tierGroup) {
              // When we create a new tier group, we should create a new "disabled" option as a title
              // We will use the data from the first entry in the tierGroup to inform our title's contents
              var optionGroupTitleLabel = (tierGroup[0].is_hidden === "true" ? "Unpublished" : "Published") + " " + (tierGroup.length > 1 ? "Plans" : "Plan") + " " + (tierGroup[0].activation_date_string === "null" ? "Activated before 2020" : "Activated on " + tierGroup[0].activation_date_string);
              var optionGroupTitleValue = (tierGroup[0].activation_timestamp || "null") + "-" + (tierGroup[0].is_hidden === "true" ? "unpublished" : "published") + "-option-group-title";
              var optionGroupTitle = {
                label: "-------------(" + optionGroupTitleLabel + ")-------------",
                value: optionGroupTitleValue
              };
              slTier.addOption(optionGroupTitle);
              // Title added, now we must disable it
              var slTierOptions = slTier.getOptions();
              for (var i = 0; i < slTierOptions.length; i++) {
                if (slTierOptions[i].value === optionGroupTitleValue) {
                  slTierOptions[i].disabled = true;
                  slTier.updateOption(slTierOptions);
                  break;
                }
              }
              // Add in the subsequent children of the title
              tierGroup.forEach(function (tier) {
                var option = {
                  label: tier.name,
                  value: tier.value
                };
                //if flag is turned off, unpublished plans are disabled i.e. not selectable
                if (!context.flagIsOn(flags.FINOPS_PLAN_CHANGE_TIER_REFACTOR) && tier.is_hidden === "true") {
                  option.disabled = true;
                }
                slTier.addOption(option);
              });
            });
            slTier.set("disabled", false);
          }
        }
      }
      function setPlanChangeValidationMessage(msg, type) {
        var messageContainer = document.createElement("div");
        messageContainer.id = "plan-change-tool-msg-block";
        messageContainer.classList.add("c-flashBlock", "padding-right--lv2", "padding-bottom--lv4");
        document.getElementById("plan-change-tool-msg").appendChild(messageContainer);
        var messageType;
        switch (type) {
          case "success":
            messageType = "v-flashBlock--success";
            break;
          case "warning":
            messageType = "v-flashBlock--warning";
            break;
          default:
            messageType = "v-flashBlock--error";
            break;
        }
        var messageList = document.createElement("ul");
        messageList.classList.add("padding-left--lv5", "padding-right--lv3", "padding-top--lv3", "padding-bottom--lv3", messageType);
        messageList.id = "plan-change-tool-" + type;
        messageList.innerHTML = "<li>" + msg + "</li>";
        messageContainer.appendChild(messageList);
      }
      function validateSelectedPlanChange(isUnsupportedPlan, isPaygoPlan, isValidCountry, isValidTier) {
        var element = document.getElementById("plan-change-tool-msg");
        if (element) {
          element.innerHTML = "";
        }
        var isTierEnabled = !slTier.get("disabled"); // Changed for clarity

        if (isUnsupportedPlan) {
          setPlanChangeValidationMessage("Marketing plan " + slMarketingPlan.getValue() + " is unsupported. Please select another plan.", "error");
          return false;
        }
        if (!isValidCountry && !isPaygoPlan) {
          setPlanChangeValidationMessage("Please select a country", "warning");
          return false;
        }
        if (isTierEnabled && !isValidTier && !isPaygoPlan) {
          setPlanChangeValidationMessage("Please select a tier", "warning");
          return false;
        }
        setPlanChangeValidationMessage("Valid plan configuration", "success");
        return true;
      }
      var handleApplyButtonStatus = function () {
        domClass.add(self.$el.btApplyPlanChange, "disabled");
        var plansRequiringTiers = ["free_monthly_plan_v0", "essential_monthly_plan_v0", "standard_monthly_plan_v0", "premium_monthly_plan_v0", "ultimate_monthly_plan_v0"];
        var paygoPlans = ["paygo_classic_plan_v1", "paygo_plan_v1"];
        var nonLegacyPaygo = ["paygo_plan_v1"];
        var unconvertiblePlans = ["paygo_plan_v1", "paygo_classic_plan_v1", "universal_free_plan_v1", "essential_marketing_plan_v1", "standard_marketing_plan_v1", "premium_marketing_plan_v1", "ultimate_marketing_plan_v1", "transactional_monthly_plan_v1"];
        var legacyPlans = ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0"];
        var isUnsupportedPlan = unconvertiblePlans.includes(slMarketingPlan.getValue());
        var isMonthlyPlan = plansRequiringTiers.includes(slMarketingPlan.getValue());
        var isPaygoPlan = paygoPlans.includes(slMarketingPlan.getValue());
        var isNonLegacyPaygo = nonLegacyPaygo.includes(slMarketingPlan.get("value"));
        var isLegacyPlan = legacyPlans.includes(slMarketingPlan.getValue());
        var isPartnershipPlan = slMarketingPlan.getValue().startsWith("partnership_");
        var isMultiAccountPlan = slMarketingPlan.getValue().startsWith("multi_account_");
        var isValidCountry = isLegacyPlan || slCountry.getValue() !== "N/A" && slCountry.getValue() !== "";
        var isValidTier = !isMonthlyPlan && !(isPartnershipPlan || isMultiAccountPlan) || (isMonthlyPlan || isPartnershipPlan || isMultiAccountPlan) && slTier.getValue() !== "N/A" && slTier.getValue() !== "";
        var isCurrentPlan = currentMarketingPlan === slMarketingPlan.getValue() && currentCommercePlan === slCommercePlan.getValue() && currentTier === slTier.getValue() && (currentCRMPlan = slCRMPlan.getValue()) && currentCountry === slCountry.getValue();
        if (isNonLegacyPaygo) {
          //if flag is on, non legacy paygo plan is supported
          isUnsupportedPlan = false;
          // other fields i.e. country, tier, commerce plan are not relevant for paygo plan conversion
          domClass.remove(btApplyPlanChange, "disabled");
          //Show valid plan configuration message
          validateSelectedPlanChange(isUnsupportedPlan, isPaygoPlan, isValidCountry, isValidTier);
        } else {
          // Check if the selected plan, country and tier are valid
          if (!isUnsupportedPlan && !isCurrentPlan && !isPaygoPlan && isValidCountry && isValidTier) {
            // Remove the disabled class from the btApplyPlanChange button to re-enable it
            domClass.remove(btApplyPlanChange, "disabled");
          }
          validateSelectedPlanChange(isUnsupportedPlan, isPaygoPlan, isValidCountry, isValidTier);
        }
      };
      if (context.flagIsOn(flags.PLAN_CRM_WRAPPER)) {
        if (slCountry && slMarketingPlan && slCommercePlan && slCRMPlan && slTier && btSubmitPlanChange) {
          on(slCountry, "change", function (country) {
            handleInputChange(country);
            handleApplyButtonStatus();
          });
          on(slMarketingPlan, "change", function (marketingPlanName) {
            handleInputChange(null, marketingPlanName);
            handleApplyButtonStatus();
          });
          on(slCommercePlan, "change", function () {
            handleApplyButtonStatus();
          });
          on(slCRMPlan, "change", function () {
            handleApplyButtonStatus();
          });
          on(slTier, "change", function () {
            handleApplyButtonStatus();
          });
          on(btSubmitPlanChange, "click", function (e) {
            e.preventDefault();
            var reason = self.$el.planChangeReason.value;
            domClass.add(btSubmitPlanChange, "disabled");

            // Plans can either use a specific plan_id or the value of a tier as a pricing_id.
            // We need a pricing_id, so we must determine which value to use based on the requested plan.

            // Since legacy plans do not have pricing_ids, we need to determine if the user will be converted to legacy
            // If the user is being converted to legacy, we use the plan name from slMarketingPlan instead of
            // the tier value to complete the plan conversion.
            var legacyPlans = ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0", "paygo_classic_plan_v1"];
            var isConvertingToLegacy = legacyPlans.includes(slMarketingPlan.get("value"));

            // If the user is being converted to non-legacy Paygo, we use the plan name from slMarketingPlan instead of
            // the tier value to complete the plan conversion.
            var paygoPlans = ["paygo_plan_v1"];
            var isConvertingToNonLegacyPaygo = paygoPlans.includes(slMarketingPlan.get("value"));

            // We must also check for any plans we can't convert to. If so, we will also use the plan name from slMarketingPlan to generate an appropriate error message with logging.
            var unconvertiblePlans = ["universal_free_plan_v1", "essential_marketing_plan_v1", "standard_marketing_plan_v1", "premium_marketing_plan_v1", "ultimate_marketing_plan_v1", "transactional_monthly_plan_v1"];
            var cannotBeConvertedTo = unconvertiblePlans.includes(slMarketingPlan.get("value"));
            var marketing_plan_id = isConvertingToLegacy || isConvertingToNonLegacyPaygo || cannotBeConvertedTo ? slMarketingPlan.get("value") : slTier.get("value");
            xhr(mUrl.toUrl("/peaches2/users/billing/change-plan-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              marketing_pricing_id: marketing_plan_id,
              commerce_package_id: slCommercePlan.get("value"),
              crm_package_id: slCRMPlan.get("value"),
              reason: reason,
              s: "change-plan-for-user"
            }), {
              handleAs: "json"
            }).then(function () {
              btSubmitPlanChange.cancel();
              location.reload();
            });
          });
        }
      } else if (slCountry && slMarketingPlan && slCommercePlan && slTier && btSubmitPlanChange) {
        on(slCountry, "change", function (country) {
          handleInputChange(country);
          handleApplyButtonStatus();
        });
        on(slMarketingPlan, "change", function (marketingPlanName) {
          handleInputChange(null, marketingPlanName);
          handleApplyButtonStatus();
        });
        on(slCommercePlan, "change", function () {
          handleApplyButtonStatus();
        });
        on(slTier, "change", function () {
          handleApplyButtonStatus();
        });
        on(btSubmitPlanChange, "click", function (e) {
          e.preventDefault();
          var reason = self.$el.planChangeReason.value;
          domClass.add(btSubmitPlanChange, "disabled");

          // Plans can either use a specific plan_id or the value of a tier as a pricing_id.
          // We need a pricing_id, so we must determine which value to use based on the requested plan.

          // Since legacy plans do not have pricing_ids, we need to determine if the user will be converted to legacy
          // If the user is being converted to legacy, we use the plan name from slMarketingPlan instead of
          // the tier value to complete the plan conversion.
          var legacyPlans = ["legacy_free_monthly_plan_v0", "legacy_paid_monthly_plan_v0", "paygo_classic_plan_v1"];
          var isConvertingToLegacy = legacyPlans.includes(slMarketingPlan.get("value"));

          // If the user is being converted to non-legacy Paygo, we use the plan name from slMarketingPlan instead of
          // the tier value to complete the plan conversion.
          var paygoPlans = ["paygo_plan_v1"];
          var isConvertingToNonLegacyPaygo = paygoPlans.includes(slMarketingPlan.get("value"));

          // We must also check for any plans we can't convert to. If so, we will also use the plan name from slMarketingPlan to generate an appropriate error message with logging.
          var unconvertiblePlans = ["universal_free_plan_v1", "essential_marketing_plan_v1", "standard_marketing_plan_v1", "premium_marketing_plan_v1", "ultimate_marketing_plan_v1", "transactional_monthly_plan_v1"];
          var cannotBeConvertedTo = unconvertiblePlans.includes(slMarketingPlan.get("value"));
          var marketing_plan_id = isConvertingToLegacy || isConvertingToNonLegacyPaygo || cannotBeConvertedTo ? slMarketingPlan.get("value") : slTier.get("value");
          xhr(mUrl.toUrl("/peaches2/users/billing/change-plan-for-user", {
            user_id: self.pageJs.userAttrs.userId,
            marketing_pricing_id: marketing_plan_id,
            commerce_package_id: slCommercePlan.get("value"),
            reason: reason,
            s: "change-plan-for-user"
          }), {
            handleAs: "json"
          }).then(function () {
            btSubmitPlanChange.cancel();
            location.reload();
          });
        });
      }

      // Show the plan change reason why modal
      if (this.$el.btApplyPlanChange) {
        on(this.$el.btApplyPlanChange, "click", function (e) {
          e.preventDefault();
          planChangeReasonModal.show();
        });
      }

      // Close the plan change reason why modal
      if (this.$el.btCancelPlanChange) {
        on(this.$el.btCancelPlanChange, "click", function (e) {
          e.preventDefault();
          planChangeReasonModal.hide();
        });
      }
      var addOnSelector = dijit.byId("slAddOn");
      if (this.$el.btAddOn && addOnSelector) {
        on(this.$el.btAddOn, "click", function (e) {
          e.preventDefault();
          // This method will clear a users' current address validation status via AJAX
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to add this add-on package for this customer?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/add-add-on-package-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              addon_package: addOnSelector.value.toString(),
              s: "add-addon-package-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully added package!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to add your package: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      if (this.$el.btEditStartDate) {
        on(this.$el.btEditStartDate, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to edit this customer's package start date?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/change-package-date-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              package_start_date: self.$el.packageStartDateInputField.value,
              s: "change-package-date-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully updated package start date!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to change your package start date: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      if (this.$el.btEditEndDate) {
        on(this.$el.btEditEndDate, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to edit this customer's package end date?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/change-package-date-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              package_end_date: self.$el.packageEndDateInputField.value,
              s: "change-package-date-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully updated package end date!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to change your package end date: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      if (this.$widget.taxOverrideStatusSelect) {
        var toggleExpirationFields = function () {
          var value = self.$widget.taxOverrideStatusSelect.get("value");
          var allowExpirationDate = value && value !== "none";
          if (!allowExpirationDate) {
            self.$widget.taxOverrideExpiresNever.setAttribute("checked", true);
            self.$widget.taxOverrideExpirationDate.setAttribute("value", "");
          }
          self.$widget.taxOverrideExpiresNever.setAttribute("disabled", !allowExpirationDate);
          self.$widget.taxOverrideExpiresOnDate.setAttribute("disabled", !allowExpirationDate);
        };
        on(this.$widget.taxOverrideStatusSelect, "change", toggleExpirationFields);
        toggleExpirationFields();
        var toggleExpiresDate = function () {
          var selected = self.$widget.taxOverrideExpiresOnDate.get("checked");
          self.$widget.taxOverrideExpirationDate.setAttribute("disabled", !selected);
        };
        on(this.$widget.taxOverrideExpiresOnDate, "change", toggleExpiresDate);
        toggleExpiresDate();
      }
      if (this.$el.resetFreeMonthlySends) {
        on(this.$el.resetFreeMonthlySends, "click", function (e) {
          e.preventDefault();
          confirmationModal.show();
        });
      }
      if (this.$el.resetConfirm) {
        on(this.$el.resetConfirm, "click", function (e) {
          e.preventDefault();
          xhr(mUrl.toUrl("/peaches2/users/billing/reset-free-monthly-sends", {
            user_id: self.pageJs.userAttrs.userId,
            s: "reset-free-monthly-sends"
          }), {
            handleAs: "json"
          }).then(function (response) {
            confirmationModal.hide();
            if (response.status === "success") {
              utils.globalPublish("show-toast", [{
                "message": "Successfully reset the user's monthly sends!",
                "type": "success"
              }]);
              location.reload();
            } else {
              utils.globalPublish("show-toast", [{
                "message": "An error occurred while trying to reset monthly sends: " + response.exception,
                "type": "error"
              }]);
            }
          });
        });
      }
      if (this.$el.closeConfirm) {
        on(this.$el.closeConfirm, "click", function (e) {
          e.preventDefault();
          confirmationModal.hide();
        });
      }
      if (this.$el.pauseAccount) {
        on(this.$el.pauseAccount, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to pause this customer's account?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/pause-account-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              s: "pause-account-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully paused account!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to pause the account: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }
      if (this.$el.unpauseAccount) {
        on(this.$el.unpauseAccount, "click", function (e) {
          e.preventDefault();
          // eslint-disable-next-line no-alert
          if (confirm("Are you sure you want to unpause this customer's account?")) {
            xhr(mUrl.toUrl("/peaches2/users/billing/unpause-account-for-user", {
              user_id: self.pageJs.userAttrs.userId,
              s: "unpause-account-for-user"
            }), {
              handleAs: "json"
            }).then(function (response) {
              if (response.status === "success") {
                utils.globalPublish("show-toast", [{
                  "message": "Successfully unpaused account!",
                  "type": "success"
                }]);
                location.reload();
              } else {
                utils.globalPublish("show-toast", [{
                  "message": "An error occurred while trying to unpause the account: " + response.exception,
                  "type": "error"
                }]);
              }
            });
          }
        });
      }

      // ACB Discount handlers
      var acbDiscountReasonModal = self.$widget.acbDiscountReasonModal;
      // the initially unchecked checkbox
      if (this.$el.addAcbDiscount) {
        on(this.$el.addAcbDiscount, "change", function (e) {
          if (e.target.checked) {
            acbDiscountReasonModal.show();
          } else {
            self.$el.acbDiscountReason.setAttribute("value", "");
          }
        });
      }
      // the initially checked checkbox
      if (this.$el.removeAcbDiscount) {
        on(this.$el.removeAcbDiscount, "change", function (e) {
          if (e.target.checked) {
            acbDiscountReasonModal.show();
          } else {
            self.$el.acbDiscountReason.setAttribute("value", "");
          }
        });
      }
      // the ACB reason modal submit
      if (this.$el.btnAcbDiscountReasonSubmit) {
        on(this.$el.btnAcbDiscountReasonSubmit, "click", function () {
          // copy the modal value to the form
          self.$el.acbDiscountReason.setAttribute("value", self.$el.modalAcbDiscountReason.value);
        });
      }
      // the ACB reason modal cancel
      if (this.$el.btnAcbDiscountReasonCancel) {
        on(this.$el.btnAcbDiscountReasonCancel, "click", function () {
          // clear the form value
          self.$el.acbDiscountReason.setAttribute("value", "");
          acbDiscountReasonModal.hide();
        });
      }
      // End ACB Discount handlers
    },
    cancelEdit: function (elId, editId) {
      domClass.add(this.$el[elId], "hide");
      domClass.remove(this.$el[editId], "hide");
    },
    removePackage: function (packageName) {
      // eslint-disable-next-line no-alert
      if (confirm("Are you sure you want to remove package: " + packageName + " from this customer's plan?")) {
        xhr(mUrl.toUrl("/peaches2/users/billing/remove-add-on-package-for-user", {
          user_id: this.pageJs.userAttrs.userId,
          addon_package: packageName,
          s: "remove-add-on-package-for-user"
        }), {
          handleAs: "json"
        }).then(function (response) {
          if (response.status === "success") {
            utils.globalPublish("show-toast", [{
              "message": "Package removed successfully",
              "type": "success"
            }]);
            location.reload();
          } else {
            utils.globalPublish("show-toast", [{
              "message": "An error occurred while trying to remove the add-on package: " + response.exception,
              "type": "error"
            }]);
          }
        });
      }
    },
    getDipRange: function (packageName) {
      document.getElementById("dip-recommendation-intro").style.display = "none";
      document.getElementById("dip-recommendation-loading").style.display = "block";
      xhr(mUrl.toUrl("/peaches2/users/billing/get-dip-range", {
        user_id: this.pageJs.userAttrs.userId
      }), {
        handleAs: "json"
      }).then(function (response) {
        document.getElementById("dip-recommendation-loading").style.display = "none";
        var msg;
        if (response["error"]) {
          msg = document.createElement("p");
          msg.textContent = "There was an error retrieving the data: " + response["error"] + ". Please reach out to #delivery-help for assistance.";
          msg.classList.add("small-meta");
          document.getElementById("dip-recommendation-error").appendChild(msg);
          document.getElementById("dip-recommendation-error").style.display = "block";
        } else if (response["eligible"]) {
          var t;
          if (response["min"] === response["max"]) {
            t = response["min"] + " IP(s)";
          } else {
            t = response["min"] + " - " + response["max"] + " IPs";
          }
          msg = document.createTextNode(t);
          document.getElementById("dip-range").after(msg);
          document.getElementById("dip-recommendation-eligible").style.display = "block";
          document.getElementById("dip-badge-eligible").style.display = "inline";
        } else {
          msg = document.createElement("p");
          msg.textContent = response["reason"];
          msg.classList.add("small-meta");
          document.getElementById("dip-recommendation-ineligible").appendChild(msg);
          document.getElementById("dip-recommendation-ineligible").style.display = "block";
          document.getElementById("dip-recommendation-ineligible").style.display = "block";
          document.getElementById("dip-badge-ineligible").style.display = "inline";
        }
      });
    },
    createDipRequest: function () {
      var userId = this.pageJs.userAttrs.userId;
      window.open("https://jira.mailchimp.com/secure/CreateIssueDetails!init.jspa?pid=11500&issuetype=3&summary=Dedicated%20IP%20Request%20for%20UID%3A%20" + userId + "%20ZD%3A%20&description=UID%3A%20" + userId + "%20%0AType%3A%20Dedicated&labels=dedicated_ips&components=Support&priority=10202", "_blank");
    },
    deleteDiscount: function (discount_id) {
      if (context.flagIsOn(flags.M10N_ADMIN_AGENTS_TO_ADD_MORE_THAN_ONE_DISCOUNT)) {
        // eslint-disable-next-line no-alert
        if (confirm("Are you sure you want to remove discount with ID: " + discount_id + " from this customer's current discounts?")) {
          xhr(mUrl.toUrl("/peaches2/users/billing/delete-discount-for-user", {
            user_id: this.pageJs.userAttrs.userId,
            discount_id: discount_id,
            s: "delete-discount-for-user"
          }), {
            handleAs: "json"
          }).then(function (response) {
            if (response.status === "success") {
              utils.globalPublish("show-toast", [{
                "message": "Discount removed successfully",
                "type": "success"
              }]);
              location.reload();
            } else {
              utils.globalPublish("show-toast", [{
                "message": "An error occurred while trying to remove the discount: " + response.exception,
                "type": "error"
              }]);
            }
          });
        }
      }
    },
    saveDiscountExpirationDate: function (discount_id) {
      if (context.flagIsOn(flags.M10N_ADMIN_AGENTS_TO_ADD_MORE_THAN_ONE_DISCOUNT)) {
        var disInpId = "discount-expiration" + discount_id;
        if (document.getElementById(disInpId) === null || document.getElementById(disInpId) === undefined) {
          return "";
        }
        var discountEleVal = document.getElementById(disInpId).value;
        if (discountEleVal !== "N/A" && discountEleVal !== "" && discountEleVal !== null && discountEleVal !== undefined) {
          xhr(mUrl.toUrl("/peaches2/users/billing/save-discount-expiration-date", {
            user_id: this.pageJs.userAttrs.userId,
            discount_id: discount_id,
            discount_expiration: discountEleVal,
            s: "save-discount-expiration-date"
          }), {
            handleAs: "json"
          }).then(function (response) {
            if (response.status === "success") {
              utils.globalPublish("show-toast", [{
                "message": "Discount expiration date saved successfully",
                "type": "success"
              }]);
              location.reload();
            } else {
              utils.globalPublish("show-toast", [{
                "message": "An error occurred while trying to edit the discount expiration date: " + response.exception,
                "type": "error"
              }]);
            }
          });
        }
      }
    }
  });
});