define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/api/campaign/PresendCheck", "mojo/utils/string", "dojo/text!./templates/presend-warning-row.html"], function (declare, Renderable, PresendCheck, stringUtils, tpl) {
  var PresendWarningRow = declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      var presendCheck = props.presendCheck;
      this.state = {
        iconClasses: PresendWarningRow.getIconClassesForPresendCheck(props.presendCheck),
        plaintextMessage: PresendWarningRow.getMessageForPresendCheck(props.presendCheck),
        contextMessage: PresendWarningRow.getContextForPresendCheck(props.presendCheck),
        toPlural: stringUtils.toPlural,
        code: presendCheck.code
      };
    },
    postCreate: function () {
      // this was getting called every time a checklist automation loaded. added the pathname so that the tracking was not affected for the checklist builder
      if (window.location.pathname === "/campaigns/edit") {
        window.ga("send", "event", "campaign_checklist", "show", "placeholder_error");
        this.inherited(arguments);
      }
    }
  });
  PresendWarningRow.getIconClassesForPresendCheck = function (presendCheck) {
    if (presendCheck.type === "error") {
      return ["freddicon", "cross-circle-color"];
    }
    return ["warn-circle", "warn-circle-color"];
  };
  PresendWarningRow.getMessageForPresendCheck = function (presendCheck) {
    return presendCheck.details;
  };
  PresendWarningRow.getContextForPresendCheck = function (presendCheck) {
    return presendCheck.context;
  };
  return PresendWarningRow;
});