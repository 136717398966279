/**
 * Widget that overrides dijit's horizontal slider to add the concept of a potential value.
 *
 * This is for cases when you want to have a slider that has a max x ticks, but also programmatically
 * limit it to y ticks.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/HorizontalSlider", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, HorizontalSlider, domConstruct, dom) {
  return declare([HorizontalSlider], {
    potential: 0,
    potentialBarHtml: '<div role="presentation" class="absolute dijitSliderBar dijitSliderBarH dijitSliderPotentialBar dijitSliderPotentialBarH"></div>',
    fauxRemainingBarHtml: '<div role="presentation" class="absolute dijitSliderBar dijitSliderBarH fauxRemainingBar fauxRemainingBarH"></div>',
    sliderValueLabelHtml: '<p class="fwb slidervalue last"></p>',
    sliderValueLabel: null,
    potentialBar: null,
    postMixInProperties: function () {
      this.inherited(arguments);
      this.potential = this.maximum;
    },
    _setValueAttr: function ( /*Number*/value, /*Boolean?*/priorityChange) {
      if (this.potential >= value) {
        this.inherited(arguments);
      } else {
        this.inherited(arguments, [this.potential]);
      }
      this.updateLabel();
    },
    _setPotentialAttr: function (value) {
      this.potential = value;
      this.updatePotentialWidth();
    },
    _setTotalAttr: function (value) {
      this.updateCount(value);
    },
    _getPotentialBarWidth: function () {
      return (this.potential - this.minimum) / (this.maximum - this.minimum) * 100 + "%";
    },
    postCreate: function () {
      this.inherited(arguments);
      // Add the potential bar in
      this.potentialBar = domConstruct.place(this.potentialBarHtml, this.remainingBar, "after");
      this.potentialBar.style.width = "100%";
      this.progressBar.style.width = '0%';
      this.potentialBar.style.zIndex = 1;
      this.fauxRemainingBar = domConstruct.place(this.fauxRemainingBarHtml, this.potentialBar, "after");
      this.fauxRemainingBar.style.width = "100%";
      this.fauxRemainingBar.style.background = "#f2f2f2";
      dom.byId("variationCount").innerHTML = 1;
      this.sliderValueLabel = domConstruct.place(this.sliderValueLabelHtml, this.domNode, "last");
      this.progressBar.style.cursor = "pointer";
      this.remainingBar.style.cursor = "pointer";
      this.updateLabel();
    },
    updateLabel: function () {
      if (this.sliderValueLabel) {
        this.sliderValueLabel.innerHTML = this.value;
        this.remainingBar.style.width = this._getPotentialBarWidth();
      }
    },
    updatePotentialWidth: function () {
      if (this.potentialBar) {
        this.potentialBar.style.width = this._getPotentialBarWidth();
        this.remainingBar.style.width = this._getPotentialBarWidth();
      }
    },
    updateCount: function (value) {
      dom.byId("variationCount").innerHTML = value;
    }
  });
});