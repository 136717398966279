define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "./_WithLabelMixin", "dijit/_HasDropDown", "dijit/DropDownMenu", "dijit/MenuItem", "dojo/text!./templates/fontSize.html"], function (declare, array, lang, on, keys, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _WithLabelMixin, _HasDropDown, DropDownMenu, MenuItem, tpl) {
  var MAX_PIXEL_VALUE = 200;

  /**
   * Custom implementation of dijit/DropDownMenu to handle selecting the right value
   * if the text input matches a value inside the drop down
   */
  var _Menu = declare("mojo.widgets.input.FontSize._Menu", DropDownMenu, {
    baseClass: "mojoFontSizeInput_menu dijitMenu",
    autoFocus: true,
    focus: function () {
      var found = false;
      var val = this.parentWidget.value;
      if (val) {
        array.forEach(this.getChildren(), function (menuItem) {
          if (menuItem.label && val === menuItem.label) {
            found = true;
            this.focusChild(menuItem, false);
          }
        }, this);
      }
      if (!found) {
        this.inherited(arguments);
      }
    }
  });
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, _HasDropDown, _WithLabelMixin], {
    label: "Font Size",
    templateString: tpl,
    sizes: [9, 10, 11, 12, 13, 14, 16, 18, 24, 30, 36, 48, 60, 72],
    initialized: false,
    value: null,
    postCreate: function () {
      this.inherited(arguments);
      this._populateFontMenu();
      this._setInputValue(this.value);
      this.own(on(this.textInput, "change", lang.hitch(this, function () {
        this.set("value", this.textInput.value);
      })), on(this.textInput, "keypress", lang.hitch(this, function (e) {
        if (e.charOrCode === keys.ENTER) {
          this.set("value", this.textInput.displayedValue);
        }
      })));
      this.initialized = true;
    },
    /**
     * Build the drop down menu with suggested font sizes
     *
     * @private
     */
    _populateFontMenu: function () {
      var self = this;
      self.dropDown = new _Menu({
        parentWidget: this
      });
      array.forEach(this.sizes, function (size) {
        var item = new MenuItem({
          "label": size + "px",
          "onClick": function () {
            self.set("value", size);
          }
        });
        self.dropDown.addChild(item);
      });
    },
    /**
     * Updates the textInput based on the value given.
     *
     * @param {string} value - the value we want to display on the text input
     * @private
     */
    _setInputValue: function (value) {
      this.textInput.set("value", value);
    },
    /**
     * Cleans up the value of this input to only allow pixel values.
     *
     * @param {string|number} value - the value that the user input
     * @private
     */
    _setValueAttr: function (value) {
      if (value === null) {
        value = "";
      }

      // clear out everything but numbers
      value = value.toString().replace(/[^0-9]/g, "");
      if (value) {
        value = +value;
        if (value > MAX_PIXEL_VALUE) {
          value = MAX_PIXEL_VALUE;
        }
        value += "px";
      } else if (!this.isOptional) {
        // Force the smallest pixel if this input was set to be a non-optional field
        value = this.sizes[0] + "px";
      }
      this._set("value", value);
      if (this.initialized) {
        this._setInputValue(value);
        this.onChange(value);
      }
    },
    /**
     * Empty callback function
     * @param {string} value - the final transformed px value
     */
    onChange: function (value) {}
  });
});