define(["dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/window!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (Deferred, dojoWindow, domConstruct) {
  return function (src, tools, options) {
    tools = tools || [];
    options = options || {};
    var deferred = new Deferred();

    // track every time the editor is opened
    window.dataLayer.push({
      event: "e_photoeditor",
      photomethod: "open"
    });

    // Safely attempts to JSON.parse a string and returns an empty object in
    // the event of a failed parse. Because we are listening to all
    // "message" events, we cannot be sure that the sender correctly
    // formatted the JSON string.
    function safelyParseJSON(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return {};
      }
    }

    // The image editor requires the width/heigh of the image in some scenarios.
    // We could do the loading there, but the image is likely already loaded by the
    // browser in the current context, so better to fetch the dimensions now and
    // pass them through.
    var img = new Image();
    img.onload = function () {
      var url = "/image-editor/?url=" + src + "&width=" + img.naturalWidth + "&height=" + img.naturalHeight;
      if (tools.length) {
        url += "&tools=" + tools.join(",");
      }
      var optionsParams = Object.keys(options).map(function (key) {
        return key + "=" + options[key];
      });
      if (optionsParams.length) {
        url += "&" + optionsParams.join("&");
      }
      var frame = domConstruct.toDom('<iframe style="visibility:hidden;position:absolute;width:100%;height:100%;z-index:999;top:0;left:0;" src="' + url + '"></iframe>');
      domConstruct.place(frame, dojoWindow.body());
      window.addEventListener("message", receiveMessage);
      function receiveMessage(event) {
        var data = safelyParseJSON(event.data);
        if (data.event === "image_editor:show") {
          frame.style.visibility = "visible";
          return;
        }
        if (data.event === "image_editor:close") {
          deferred.resolve(null);
          teardown();
          return;
        }
        if (data.event === "image_editor:save") {
          deferred.resolve(data.image);
          window.dataLayer.push({
            event: "e_photoeditor",
            photomethod: "save",
            tools: data.tools || []
          });
          teardown();
          return;
        }
      }
      function teardown() {
        window.removeEventListener("message", receiveMessage);
        domConstruct.destroy(frame);
      }
    };
    img.src = src;
    return deferred.promise;
  };
});