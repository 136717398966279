define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/form/TextBox", "dojo/string", "./_WithLabelMixin"], function (declare, lang, domConstruct, _WidgetBase, TextBox, string, _WithLabelMixin) {
  return declare("mojo/widgets/input/Measurement", [_WidgetBase, _WithLabelMixin], {
    baseClass: 'mojoMeasurementInput',
    textboxOpts: {},
    /**
     * When value is changed, extract the px suffix and remove it from the input.
     */
    _setValueAttr: function ( /*Number*/value) {
      value = value.replace(/ /g, "");
      if (value.match("%") === null) {
        this.suffix = "px";
        if (value.match("px")) {
          value = string.trim(value.replace("px", ""));
        }
      } else {
        this.suffix = "";
      }
      if (this.input != null) {
        this.input.set('value', value, false);
      }
      this._updateSuffix();
      var previous = this.get('value');
      this._set('value', value + this.suffix);
      if (previous != value) {
        this.onChange(value + this.suffix);
      }
      this.inherited(arguments, [value]);
    },
    _setDisabledAttr: function (bool) {
      this.input.set("disabled", bool);
    },
    postCreate: function () {
      this.originalValue = this.get('value');
      var opts = {
        value: this.get("value")
      };
      lang.mixin(opts, this.textboxOpts);
      this.input = new TextBox(opts);
      this.input.startup();
      this.input.placeAt(this.domNode);
      this.set("value", this.get('value'));
      this.suffixDom = domConstruct.place('<span class="suffix">' + this.suffix + '</span>', this.domNode);
      this.connect(this.input, 'onChange', '_setValueAttr');
      this.inherited(arguments);
    },
    _updateSuffix: function () {
      if (this.suffixDom) {
        this.suffixDom.innerHTML = this.suffix;
      }
    },
    reset: function () {
      this.set('value', this.originalValue);
    },
    onChange: function (value) {}
  });
});