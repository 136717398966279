/**
 * @class mojo.report.widgets.BigStats
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/every", "mojo/widgets/Renderable", "dojo/text!./templates/BigStats.html", "../Stat"], function (declare, every, Renderable, tpl, Stat) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      var stats = props.stats;
      if (Array.isArray(stats) === false) {
        throw new Error("Must provide `stats` prop with type `array`");
      }
      var everyStatIsStat = every(stats, function (stat) {
        return stat instanceof Stat;
      });
      if (everyStatIsStat === false) {
        throw new Error("`stats` prop must be array of `mojo.report.Stat`");
      }
      this.state = {
        stats: stats
      };
    }
  });
});