/**
 * Mixin for sliding cards in account menu (and elsewhere).
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'velocity/velocity'], function (declare, domClass, dom, lang, Velocity) {
  return declare([], {
    slideCard: function (card, focusField) {
      Velocity(card, "stop");
      Velocity(card, {
        left: [0, [0.7, 0, 0.175, 1.06], "100%"]
      }, {
        duration: 250,
        complete: function () {
          if (focusField) {
            focusField.focus();
          }
        }
      });
    },
    closeCard: function (card) {
      Velocity(card, "stop");
      Velocity(card, {
        left: ["100%", [0.7, 0, 0.175, -0.2], 0]
      }, {
        duration: 250
      });
    }
  });
});