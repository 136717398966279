define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare) {
  var PresendCheck = declare(null, {
    constructor: function (properties) {
      this.type = properties.type;
      this.code = properties.code;
      this.heading = properties.heading;
      this.details = properties.details;
      this.context = properties.context;
    }
  });
  PresendCheck.TYPE_ERROR = "error";
  PresendCheck.TYPE_WARNING = "warning";
  PresendCheck.TYPE_SUCCESS = "success";
  PresendCheck.CODE_DEFAULT_TEXT_CONTENT = 105;
  PresendCheck.CODE_DEFAULT_IMAGE_CONTENT = 117;
  return PresendCheck;
});