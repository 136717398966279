define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "dojo/text!../editors/blocks/templates/ButtonEditor/output.html"], function (declare, _Renderer, tpl) {
  return declare("mojo.neapolitan.renderers.ButtonBlock", [_Renderer], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      return {
        totalPadding: this.outerPadding * 2,
        buttonText: meta.buttonText,
        align: meta.align,
        buttonWidth: meta.buttonWidth,
        target: meta.link && meta.link.attrs.target ? meta.link.attrs.target : '_blank',
        href: meta.link ? meta.link.href : '#',
        linkClasses: meta.link ? meta.link.attrs['class'] : '',
        title: meta.link && meta.link.attrs.title ? meta.link.attrs.title : meta.buttonText
      };
    }
  });
});