define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/ChoiceCardWithImage.html"], function (declare, domAttr, domClass, domConstruct, string, _WidgetBase, _TemplatedMixin, tpl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    titleTemplate: '<span class="small-meta margin-bottom--lv1">${title}</span>',
    descriptionTemplate: '<span class="small-meta line-height--lv3 font-weight--normal">${description}</span>',
    textBlockTemplate: '<div class="c-choiceCard_image-card--text"><p class="small-meta margin-bottom--lv1 font-weight--normal"><span class="font-weight--bold">${title}</span> in ${listName}</p><p class="small-meta line-height--lv3 font-weight--normal">${description}</p></div>',
    imageTemplate: '<img src="${imageUrl}" height="110" class="c-choiceCard_image-card--image"/>',
    _id: "",
    // we can't use id here--that's reserved for the widget's id, not this choice card's inner input node.
    name: "",
    value: "",
    badge: "",
    imageUrl: "",
    listName: null,
    title: null,
    description: null,
    checked: false,
    disabled: false,
    disabledReason: "",
    // The type can be 'checkbox' or 'radio', but we'll default to checkbox.
    type: "checkbox",
    constructor: function (props) {
      this.inherited(arguments);
      if (!props._id) {
        console.warn("In order to set the input id for this Choice Card, pass in an '_id' parameter.");
      }
    },
    postCreate: function () {
      // If we end up keeping this experiment in production, we can move these to the main template (tpl) and hide/remove nodes if props are missing.
      if (this.imageUrl) {
        var imageUrl = string.escape(this.imageUrl); // no html or scripts allowed here
        domConstruct.place(string.substitute(this.imageTemplate, {
          imageUrl: imageUrl
        }), this.imageContainerNode);
      }
      if (this.title && this.description && this.listName) {
        var title = string.escape(this.title);
        var listName = string.escape(this.listName);
        var description = string.escape(this.description); // no html or scripts allowed here
        domConstruct.place(string.substitute(this.textBlockTemplate, {
          title: title,
          listName: listName,
          description: description
        }), this.containerNode);
      } else {
        if (this.title) {
          var title = string.escape(this.title); // no html or scripts allowed here
          domConstruct.place(string.substitute(this.titleTemplate, {
            title: title
          }), this.containerNode);
        }
        if (this.description) {
          var description = string.escape(this.description); // no html or scripts allowed here
          domConstruct.place(string.substitute(this.descriptionTemplate, {
            description: description
          }), this.containerNode);
        }
      }
      if (this.content) {
        domConstruct.place(this.content, this.containerNode);
      }
      this.input.checked = this.checked;
      this.setDisabled(this.disabled, this.disabledReason);
      if (this.badge) {
        domClass.remove(this.badgeNode, "hide");
      }
    },
    setDisabled: function (disabled, disabledReason) {
      this.disabled = disabled;
      this.disabledReason = disabledReason;
      this.input.disabled = disabled;
      domClass.toggle(this.containerNode, "disabled-container", !!disabled);
      this.disabledText.innerText = this.disabledReason;
      domAttr.set(this.domNode, "title", disabledReason || "");
    },
    _onClick: function (event) {
      // deselecting the card will not remove focus, so the blue line surrounding
      // the card will remain unless we blur().
      if (!event.target.checked) {
        event.target.blur();
      }
      if (this.onClick) {
        this.onClick(event);
      }
    },
    // You can override this to do whatever you please.
    onClick: function (event) {},
    setChecked: function (isChecked) {
      this.checked = isChecked;
      this.input.checked = isChecked;
    }
  });
});