define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!../templates/activity-feed/activity-item-email.html"], function (declare, lang, domConstruct, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    campaignUrl: null,
    /**
     * Initialize data
     */
    postMixInProperties: function () {
      this.campaignUrl = mUrl.toUrl("/campaigns/show?id=" + this.data.campaign_id);
    },
    /**
     * If this is an email click event, show the clicked link
     */
    postCreate: function () {
      if (this.data.activity_type === "click") {
        var clickedLink = "<a href='" + this.data.link_clicked + "' target='_blank'>a link</a> in ";
        domConstruct.place(clickedLink, this.linkClickedContainer);
      }
      if (this.data.activity_type === "bounce" && this.data.bounce_has_open_activity === true) {
        var message = "This bounce is preceded or followed by open or click activity.  " + "Aggressive spam filters may open and click links in incoming mail before delivering.  " + "This can result in activity even if the email ends up bouncing when attempting to deliver.";
        var messageHtml = "<p class='dim-el small-meta margin-bottom--lv0'>" + message + "</p>";
        domConstruct.place(messageHtml, this.bounceActivityMessage);
      }
    }
  });
});