define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // dom.setSelectable
"dojo-proxy-loader?name=dojo/has!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../registry" // registry.byNode
], function (declare, dom, has, registry) {
  // module:
  //		dijit/form/_ButtonMixin

  var ButtonMixin = declare("dijit.form._ButtonMixin" + (0 ? "_NoBidi" : ""), null, {
    // summary:
    //		A mixin to add a thin standard API wrapper to a normal HTML button
    // description:
    //		A label should always be specified (through innerHTML) or the label attribute.
    //
    //		Attach points:
    //
    //		- focusNode (required): this node receives focus
    //		- valueNode (optional): this node's value gets submitted with FORM elements
    //		- containerNode (optional): this node gets the innerHTML assignment for label
    // example:
    // |	<button data-dojo-type="dijit/form/Button" onClick="...">Hello world</button>
    // example:
    // |	var button1 = new Button({label: "hello world", onClick: foo});
    // |	dojo.body().appendChild(button1.domNode);

    // label: HTML String
    //		Content to display in button.
    label: "",
    // type: [const] String
    //		Type of button (submit, reset, button, checkbox, radio)
    type: "button",
    __onClick: function ( /*Event*/e) {
      // summary:
      //		Internal function to divert the real click onto the hidden INPUT that has a native default action associated with it
      // type:
      //		private
      e.stopPropagation();
      e.preventDefault();
      if (!this.disabled) {
        // cannot use on.emit since button default actions won't occur
        this.valueNode.click(e);
      }
      return false;
    },
    _onClick: function ( /*Event*/e) {
      // summary:
      //		Internal function to handle click actions
      if (this.disabled) {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
      if (this.onClick(e) === false) {
        e.preventDefault();
      }
      var cancelled = e.defaultPrevented;

      // Signal Form/Dialog to submit/close.  For 2.0, consider removing this code and instead making the Form/Dialog
      // listen for bubbled click events where evt.target.type == "submit" && !evt.defaultPrevented.
      if (!cancelled && this.type == "submit" && !(this.valueNode || this.focusNode).form) {
        for (var node = this.domNode; node.parentNode; node = node.parentNode) {
          var widget = registry.byNode(node);
          if (widget && typeof widget._onSubmit == "function") {
            widget._onSubmit(e);
            e.preventDefault(); // action has already occurred
            cancelled = true;
            break;
          }
        }
      }
      return !cancelled;
    },
    postCreate: function () {
      this.inherited(arguments);
      dom.setSelectable(this.focusNode, false);
    },
    onClick: function /*Event*/ /*===== e =====*/
    () {
      // summary:
      //		Callback for when button is clicked.
      //		If type="submit", return true to perform submit, or false to cancel it.
      // type:
      //		callback
      return true; // Boolean
    },
    _setLabelAttr: function ( /*String*/content) {
      // summary:
      //		Hook for set('label', ...) to work.
      // description:
      //		Set the label (text) of the button; takes an HTML string.
      this._set("label", content);
      var labelNode = this.containerNode || this.focusNode;
      labelNode.innerHTML = content;
    }
  });
  if (0) {
    ButtonMixin = declare("dijit.form._ButtonMixin", ButtonMixin, {
      _setLabelAttr: function () {
        this.inherited(arguments);
        var labelNode = this.containerNode || this.focusNode;
        this.applyTextDir(labelNode);
      }
    });
  }
  return ButtonMixin;
});