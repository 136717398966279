define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/app/peaches2/tabContainerUtil", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/url", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, lang, base, tabContainerUtil, p2Utils, topic, registry, FilterMenu, FilterMessage, dom, domAttr, on, domClass, utils, mUrl, xhr) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    campaignId: null,
    sectionId: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        if (tab.stub === "deleted") {
          tab.preload = false;
        } else {
          tab.preload = true;
        }
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "social-details" || mUrl.getHashParams().t === "social-section") {
        var socialIdfromUrl = new URL(window.location.href).searchParams.get("social_post_id");
        if (socialIdfromUrl) {
          this.viewSocialDetails(socialIdfromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewSocialDetails(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "campaign-details" || mUrl.getHashParams().t === "campaign-details-section") {
        var campaignIdfromUrl = new URL(window.location.href).searchParams.get("cid");
        if (campaignIdfromUrl) {
          this.viewCampaignDetails(campaignIdfromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewCampaignDetails(mUrl.getHashParams().id);
        }
      }
      this.modules.autoresponder.set("campaignIndex", this);
      on(this.$el.deleteCampaignCancelButton, "click", lang.hitch(this, function () {
        this.cancelDelete();
      }));
    },
    /**
     * this is the call back function that is fired when a selecte tool is changed from the campaigns list
     * @param {object} e - event passed from the event listener
     * @return {boolean}
     */

    openEmailTool: function (e) {
      var url = e.attr("value");
      if (!url) {
        return false;
      }
      var popup = url.substring(0, 5) === "http:" || url.substring(0, 6) === "https:";
      if (popup) {
        window.open(url);
      } else {
        window.location = url;
      }
      return false;
    },
    /**
     * opens the delete campaign modal
     * @param {string} id - the campaign id that should be deleted
     * @param {string} type - campaign type
     * @param {string }sid - section that this is being called from
     */

    deleteCampaign: function (id, type, sid) {
      this.$el.campaignId.value = id;
      this.$el.sectionId.value = sid;
      this.$el.regularUserId.value = this.userAttrs.userId;
      if (type === "variate-child") {
        domClass.remove("deleteVariateText", "hide");
      } else {
        domClass.add("deleteVariateText", "hide");
      }
      this.$widget.deleteDialog.show();
    },
    /**
     * submits the deleteion of the campaign and checks to make sure a note is provided
     * @param {object} e - the event passed from the event handler.
     */
    submitDeletedCampaign: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$el.deleteNote.value.length === 0) {
        utils.addError(this.$el.deleteNote, "Please provide a note");
      } else if (this.$el.deleteNote.value.length < 5) {
        utils.addError(this.$el.deleteNote, "Please provide a more meaningful note");
      } else {
        this.$el.deleteDialogForm.submit();
      }
    },
    /**
     * fires when the cancel button is clicked from the delete dialog
     */
    cancelDelete: function () {
      this.$widget.deleteDialog.hide();
      utils.removeErrors(this.$el.deleteNote);
      this.$el.deleteNote.value = "";
    },
    /**
     *  opens manage links tab
     * @param {number} cid - the campaign id
     */
    manageLinks: function (e, cid) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var newPaneObj = {
        title: "Campaign Links: " + cid,
        stub: "campaign-links",
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-links",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        closable: true,
        onClose: function () {
          self.tabContainer.campaignLinksSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, cid);
    },
    /**
     * Opens Mcuribl Links tab
     * @param {object} e - the event passed from the event handler.
     * @param {number} cid - the campaign id
     */
    manageMcuriblLinks: function (e, cid) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var newPaneObj = {
        title: "Mcuribl Campaign Links: " + cid,
        stub: "mcuribl-campaign-links",
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "mcuribl-campaign-links",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        closable: true
      };
      this.tabContainer.addNewPane(newPaneObj, true, cid);
    },
    /**
     * opens the delete website modal
     * @param {string} website_id - the website id that should be deleted
     * @param {string} type
     * @param {string }sid - section that this is being called from
     */

    deleteWebsite: function (website_id, type, sid) {
      this.$el.websiteId.value = website_id;
      this.$el.websiteSectionId.value = sid;
      this.$el.regularWebsiteUserId.value = this.userAttrs.userId;
      this.$widget.websiteDeleteDialog.show();
    },
    /**
     * submits the deletion of the website and checks to make sure a note is provided
     * @param {object} e - the event passed from the event handler.
     */
    submitDeletedWebsite: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$el.deleteWebsiteNote.value.length === 0) {
        utils.addError(this.$el.deleteWebsiteNote, "Please provide a note");
      } else if (this.$el.deleteWebsiteNote.value.length < 5) {
        utils.addError(this.$el.deleteWebsiteNote, "Please provide a more meaningful note");
      } else {
        this.$el.websiteDeleteDialogForm.submit();
      }
    },
    /**
     * opens the unpublish survey modal
     * @param {string} survey_id - the website id that should be deleted
     * @param {string} type
     * @param {string }sid - section that this is being called from
     */
    unpublishSurvey: function (survey_id, type, sid) {
      this.$el.surveyId.value = survey_id;
      this.$el.surveySectionId.value = sid;
      this.$el.surveyUserId.value = this.userAttrs.userId;
      this.$widget.unpublishSurveyDialog.show();
    },
    /**
     * submits the deletion of the survey and checks to make sure a note is provided
     * @param {object} e - the event passed from the event handler.
     */
    submitUnpublishSurvey: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$el.unpublishSurveyNote.value.length === 0) {
        utils.addError(this.$el.unpublishSurveyNote, "Please provide a note");
      } else if (this.$el.unpublishSurveyNote.value.length < 5) {
        utils.addError(this.$el.unpublishSurveyNote, "Please provide a more meaningful note");
      } else {
        this.$el.unpublishSurveyDialogForm.submit();
      }
    },
    /**
     *  opens campaign details tab
     * @param {number} cid - the campaign id
     */
    viewCampaignDetails: function (cid) {
      var self = this;
      var newPaneObj = {
        title: "Campaign Details: " + cid,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-details",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        stub: "campaign-details",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, cid);
    },
    /**
     *  opens social details tab
     * @param {number} social_post_id - the social post id
     */
    viewSocialDetails: function (social_post_id) {
      var self = this;
      var newPaneObj = {
        title: "Social Campaign: " + social_post_id,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "social-details",
          user_id: self.userAttrs.userId,
          social_post_id: social_post_id
        }),
        stub: "social-details",
        closable: true,
        onClose: function () {
          self.tabContainer.socialDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, social_post_id);
    },
    /**
     *  opens manage bounces tab
     * @param {number} e - the click event
     * @param {number} cid - the campaign id
     */
    manageBounces: function (e, cid) {
      e.preventDefault();
      e.stopPropagation();
      var self = this;
      var newPaneObj = {
        title: "Bounces: " + cid,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "campaign-bounces",
          user_id: self.userAttrs.userId,
          cid: cid
        }),
        stub: "campaign-bounces",
        closable: true,
        onClose: function () {
          self.tabContainer.campaignBouncesSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    /**
     * resizes the height of the iframe to fit the whole page.
     */
    resizeIframe: function () {
      var campaignIframe = dom.byId("HtmlIframe");
      var frameheight = frames.campaignIframe.document.body.scrollHeight;
      campaignIframe.style.height = frameheight + "px";
    },
    /**
     * runs saveCampaignDetialsTab and resizeIfram
     */
    showCampaignContent: function () {
      this.saveCampaignDetailsTab("content");
      this.resizeIframe();
    },
    /**
     * saves the campaign details current state
     * @param {string} subsection_id - the section that this is being fired from.
     */
    saveCampaignDetailsTab: function (subsection_id) {
      xhr(mUrl.toUrl("/peaches2/users/campaigns/save-campaign-details-state", {
        user_id: this.userAttrs.userId,
        section: subsection_id
      }), {
        handleAs: "json"
      }).then(function (data) {}, function (err) {
        p2Utils.utils.setFlash(err, "error");
      });
    },
    /**
     * translate campaign archive
     * @param {string} cid - campaign id
     * @param {string} archiveUrl - campaign archive url
     */
    translateToggle: function (cid, archiveUrl) {
      // translate it to english using google translate
      var iframesrc = "http://translate.google.com/translate?tl=en&u=" + escape(archiveUrl);
      iframesrc += "&translate_to=en";
      window.open(iframesrc, cid, "height=500,width=700");
    },
    sendEventSearchForm: function (e, uid, cid, input_id) {
      e.preventDefault();
      var email = dom.byId(input_id).value;
      var widget = registry.byId("event-search-subsection");
      widget.setHref(mUrl.toUrl("/peaches2/users/campaigns/event-search", {
        user_id: uid,
        cid: cid,
        email: email
      }));
    },
    loadFilter: function (section) {
      var filter = null;
      var filterMessage = null;
      var filterSettings = {
        "filters": [{
          "label": "Status",
          "key": "status",
          "type": "select",
          "options": {
            "save": "Saved",
            "paused": "Paused",
            "schedule": "Scheduled",
            "sending": "sending",
            "sent": "Sent",
            "canceled": "Cancelled",
            "archived": "Archived"
          }
        }, {
          "label": "Created since...",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day ago",
            "2": "Two days ago",
            "7": "One week ago"
          }
        }, {
          "label": "List ID",
          "key": "list_id",
          "type": "text"
        }, {
          "label": "FROM Name",
          "key": "from_name",
          "type": "text"
        }, {
          "label": "FROM Email",
          "key": "from_email",
          "type": "text"
        }, {
          "label": "Title",
          "key": "title",
          "type": "text"
        }, {
          "label": "Subject",
          "key": "subject",
          "type": "text"
        }, {
          "label": "Parent Campaign",
          "key": "parent",
          "type": "text"
        }, {
          "label": "Include this parent",
          "key": "include_parent",
          "type": "checkbox"
        }]
      };
      if (section === "autoresponder") {
        filterSettings.target = "autoresponders-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("autoresponders-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("autoresponders-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "automation") {
        filterSettings.target = "automations-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("automations-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("automations-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "customer-journeys") {
        filterSettings.target = "customer-journeys-campaigns-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("customer-journeys-campaigns-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("customer-journeys-campaigns-filters-message").appendChild(filterMessage.domNode);
      }
      filterSettings.filters.unshift({
        "label": "Type",
        "key": "type",
        "type": "select",
        "options": {
          "regular": "Regular",
          "plaintext": "Plain text",
          "variate": "Multivariate",
          "variate-child": "Multivariate Child",
          "email-touchpoint": "Email Touchpoint",
          "absplit": "A/B Split",
          "rss": "RSS"
        }
      });
      if (section === "regular") {
        filterSettings.target = "regular-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("campaigns-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("campaigns-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "deleted") {
        filterSettings.target = "deleted-campaigns-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("deleted-campaigns-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("deleted-campaigns-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "deleted-automation") {
        filterSettings.target = "deleted-automations-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("deleted-automations-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("deleted-automations-filters-message").appendChild(filterMessage.domNode);
      }
      if (section === "websites") {
        filterSettings.target = "websites-list";
        filter = new FilterMenu(filterSettings);
        dom.byId("websites-filters").appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        dom.byId("websites-filters-message").appendChild(filterMessage.domNode);
      }
    },
    deliveryActionDialog: function (cid, dialog_id) {
      this.actionedCampaign = cid;
      this.$widget[dialog_id].show();
    },
    doDeliveryAction: function (note_id, dialog_id, action) {
      var note = this.$widget[note_id].value;
      var cid = this.actionedCampaign;
      this.actionedCampaign = null;
      if (!note || note === "") {
        this.$widget[dialog_id].hide();
        p2Utils.utils.setFlash("You have to provide a note.", "error");
        return;
      }
      window.location = mUrl.toUrl("/peaches2/users/campaigns/" + action, {
        user_id: this.userAttrs.userId,
        note: note,
        cid: cid,
        _p: this.activeModule
      });
    },
    deleteDomain: function (domain) {
      this.$el.purchasedDomainDialogForm.action = mUrl.toUrl("/peaches2/pager/purchased-domains/delete-domain-data");
      this.$el.purchasedDomainLabel.innerText = "Please enter a reason to delete this domain's data. Note: The domain's registration will not be revoked";
      this.$el.purchasedDomainReasonInput.name = "deleteReason";
      this.$el.purchasedDomainDomain.value = domain;
      this.$el.purchasedDomainUserId.value = this.userAttrs.userId;
      this.$widget.purchasedDomainDialog.show();
    },
    revokeDomain: function (domain) {
      this.$el.purchasedDomainDialogForm.action = mUrl.toUrl("/peaches2/pager/purchased-domains/revoke-domain");
      this.$el.purchasedDomainLabel.innerText = "Please enter a reason to revoke this domain's registration";
      this.$el.purchasedDomainReasonInput.name = "revokeReason";
      this.$el.purchasedDomainDomain.value = domain;
      this.$el.purchasedDomainUserId.value = this.userAttrs.userId;
      this.$widget.purchasedDomainDialog.show();
    },
    testDomainEvent: function (eventType, domain) {
      xhr.post(mUrl.toUrl("/branded-domains/opensrs-events/dispatch-test"), {
        handleAs: "json",
        data: JSON.stringify({
          eventType: eventType,
          data: {
            domain_name: domain
          },
          uid: this.userAttrs.userId
        })
      }).then(function () {
        window.location.href = window.location.href;
      });
    }
  });
});