define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "dojo/text!./templates/EmailHelpTip.html"], function (declare, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, domClass, on, user, flags, helpTipTpl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: helpTipTpl,
    label: "promotional emails",
    postCreate: function () {
      var tooltipDialog = this.tooltipDialog;
      on(tooltipDialog, "open", function () {
        // [NUJ-1791] Class added to shorten the height and prevent the bottom anchor
        domClass.add(tooltipDialog._popupWrapper, "c-signupMobileSizer");
      });
    }
  });
});