define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-form!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/ServiceProviderConnect.html", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/widgets/Dialog", "mojo/url", "mojo/widgets/account/connected-accounts/ConnectionSuccess"], function (declare, on, domClass, domAttr, domForm, registry, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, Template, xhr, user, Dialog, mUrl, ConnectionSuccess) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    ///////////////////////////////////////////////////////////////////
    // Attach points
    ///////////////////////////////////////////////////////////////////
    client: null,
    agency: null,
    form: null,
    checkbox: null,
    submitButton: null,
    disconnectSubmitButton: null,
    disableButton: null,
    disconnectOnlyContainer: null,
    legacyContainer: null,
    ///////////////////////////////////////////////////////////////////
    //Properties
    ///////////////////////////////////////////////////////////////////
    agencyClient: false,
    isAgency: false,
    ///////////////////////////////////////////////////////////////////
    // Setup
    ///////////////////////////////////////////////////////////////////
    templateString: Template,
    postCreate: function () {
      if (this.isAgency) {
        //only show something if the user is a partner already
        domClass.remove(this.disconnectOnlyContainer, "hide");
        on(this.form, "submit", this.onSubmit.bind(this));
      }
    },
    /**
     * Called when the form is submitted. This will make a request to the server to
     * record the selection.
     * @param {Object} event - the submit event from the from
     */
    onSubmit: function (event) {
      event.preventDefault();
      var values = domForm.toQuery(this.form);
      if (this.disableOnly) {
        registry.byId("disable-agency-dialog").show();
      } else {
        //this can be removed after mailchimp & co launch
        if (values.indexOf("is_agency=Y") === -1 && this.isAgency) {
          registry.byId("disable-agency-dialog").show();
        } else if (!this.isAgency) {
          xhr.post(mUrl.toUrl("/account/post-enable-agency"), {
            "handleAs": "json",
            "query": values
          }).then(this.onSuccess.bind(this), this.onError.bind(this));
        }
      }
    },
    /**
     * Called when the data is sent successfully.
     * @param {Object} data - the server response
     */
    onSuccess: function (data) {
      if (data.isAgency) {
        new ConnectionSuccess().show();
        this.isAgency = true;
      }
    },
    onError: function (err) {
      location.reload(); // with flash message
    }
  });
});