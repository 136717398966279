define(["dojo/request", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (request, lang, mUrl) {
  return {
    getCampaignPreviews: function (campaignId) {
      return this._getPreviews({
        "campaign_id": campaignId
      });
    },
    getTemplatePreviews: function (templateId) {
      return this._getPreviews({
        "template_id": templateId
      });
    },
    previewCampaign: function (campaignId, clients) {
      return this._previewRequest({
        "campaign_id": campaignId,
        "clients[]": clients
      });
    },
    previewTemplate: function (templateId, clients) {
      return this._previewRequest({
        "template_id": templateId,
        "clients[]": clients
      });
    },
    getCampaignClientsStats: function (campaignId) {
      return this._getClientsStats({
        "campaign_id": campaignId
      });
    },
    getTemplateClientsStats: function (templateId) {
      return this._getClientsStats({
        "template_id": templateId
      });
    },
    getCampaignClientHistory: function (campaignId, clientId) {
      return this._getClientHistory({
        "campaign_id": campaignId,
        "client_id": clientId
      });
    },
    getTemplateClientHistory: function (templateId, clientId) {
      return this._getClientHistory({
        "template_id": templateId,
        "client_id": clientId
      });
    },
    getTokens: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/tokens"), {
        "handleAs": "json",
        "query": params
      });
    },
    getTokensBlocks: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/tokens-blocks"), {
        "handleAs": "json",
        "query": params
      });
    },
    purchaseTokens: function (blockId) {
      return this._purchaseTokens({
        "block_id": blockId
      });
    },
    /**
     * @param {Number} previewId
     * @param {Object} params any of the following:
     *  - {Boolean} viewed - mark the preview as viewed.
     *  - {Boolean} approved - mark the preview as approved.
     *
     * @returns {dojo/Promise}
     */
    updatePreview: function (previewId, params) {
      return request.post(mUrl.toUrl('/inbox-preview/update'), {
        "handleAs": "json",
        "data": lang.mixin({
          "id": previewId
        }, params)
      });
    },
    _getPreviews: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/previews"), {
        "handleAs": "json",
        "query": params
      });
    },
    _previewRequest: function (params) {
      return request.post(mUrl.toUrl("/inbox-preview/run-preview"), {
        "handleAs": "json",
        "data": params
      });
    },
    _getClientHistory: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/client-history"), {
        "handleAs": "json",
        "query": params
      });
    },
    _getClientsStats: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/clients-stats"), {
        "handleAs": "json",
        "query": params
      });
    },
    _purchaseTokens: function (params) {
      return request.get(mUrl.toUrl("/inbox-preview/purchase-tokens"), {
        "handleAs": "json",
        "query": params
      });
    }
  };
});