define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/segment-overlay-line-item.html", "mojo/utils/I18nTranslation", "mojo/context", "mojo/lib/flags"], function (declare, lang, domClass, domConstruct, on, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, itemTplStr, I18nTranslation, context, flags) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: itemTplStr,
    segment: null,
    isAdvanced: false,
    dateLabel: null,
    createdOnLabel: null,
    advancedLabel: "Advanced",
    editLink: "Edit",
    constructor: function (properties) {
      this.dateLabel = properties.segmentCreatedDate;
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize();
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          this.createdOnLabel = I18nTranslation.translate("contacts_table_created_on", [this.dateLabel]);
          this.advancedLabel = I18nTranslation.translate("contacts_table_advanced_label");
          this.editLink = I18nTranslation.translate("contacts_table_edit_link");
        } else {
          this.createdOnLabel = "Created on " + this.dateLabel;
        }
      } else {
        this.createdOnLabel = "Created on " + this.dateLabel;
      }
    },
    postCreate: function () {
      if (this.segment && this.segment.hasOwnProperty("type") && this.segment.type === "advanced") {
        this.isAdvanced = true;
      }
      this.setupListeners();
      this.setupAdvanced();
    },
    /**
     * Set up event listeners
     */
    setupListeners: function () {
      on(this.segmentNameDate, "click", lang.hitch(this, "onSelectSegment"));
      on(this.editLink, "click", lang.hitch(this, "onEditSegment"));
      on(this.segmentLineItem, "mouseover", lang.hitch(this, "toggleEditVisibility"));
      on(this.segmentLineItem, "mouseout", lang.hitch(this, "toggleEditVisibility"));
    },
    /**
     * If this is an advanced segment, show the badge
     */
    setupAdvanced: function () {
      if (this.isAdvanced) {
        domClass.remove(this.advSegmentBadge, "hide");
      }
    },
    /**
     * Show and hide the edit link
     */
    toggleEditVisibility: function () {
      domClass.toggle(this.editLink, "hide");
      if (this.isAdvanced) {
        domClass.toggle(this.advSegmentBadge, "hide");
      }
    },
    /**
     * Event handle for segment selection
     */
    onSelectSegment: function () {},
    /**
     * Event handle for segment edit
     * @param {object} segment to edit
     */
    onEditSegment: function (segment) {}
  });
});