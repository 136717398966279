define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/ecs/EcsTracking", "mojo/ecs/EcsEvents", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/Dialog", "dojo/text!./templates/feedbackDialog.html", "../tracking-helper"], function (declare, lang, domClass, domAttr, on, EcsTracking, EcsEvents, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Dialog, tpl, TrackingHelper) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tpl,
    dialogTitleText: "Share your feedback",
    instructionText: "Please exclude personal details from your feedback.",
    submitButtonText: "Submit",
    closeButtonText: "Close",
    cancelText: "Cancel",
    submitSuccessMessage: "Thanks for submitting your feedback",
    maxLength: 1000,
    // data-dojo-attach-point in the template
    cancelLink: null,
    // data-dojo-attach-point in the template
    submitButton: null,
    // data-dojo-attach-point in the template
    closeButton: null,
    // data-dojo-attach-point in the template
    textarea: null,
    // data-dojo-attach-point in the template
    formContainer: null,
    // data-dojo-attach-point in the template
    successMessageContainer: null,
    // data-dojo-attach-point in the template
    charCounter: null,
    hasSubmitted: false,
    postCreate: function () {
      this.own(on(this.submitButton, "click", lang.hitch(this, "_handleSubmit")), on(this.cancelLink, "click", lang.hitch(this, "hide")), on(this.closeButton, "click", lang.hitch(this, "hide")), on(this.textarea, "input", lang.hitch(this, "_toggleSubmitButton")));
    },
    _handleSubmit: function () {
      this.onFeedbackSubmitted(this.textarea.value);
      this._toggleSuccessMessage(true);
      this.hasSubmitted = true;
    },
    /**
     * Disable submit button was on whether there's any text in the input box
     */
    _toggleSubmitButton: function () {
      if (this.textarea.value) {
        domAttr.remove(this.submitButton, "disabled");
      } else {
        domAttr.set(this.submitButton, "disabled", "disabled");
      }
    },
    _toggleSuccessMessage: function (show) {
      // Toggle the two primary containers
      domClass.toggle(this.formContainer, "hide", show);
      domClass.toggle(this.successMessageContainer, "hide", !show);
      // Toggle the buttons accordingly
      domClass.toggle(this.cancelLink, "hide", show);
      domClass.toggle(this.submitButton, "hide", show);
      domClass.toggle(this.closeButton, "hide", !show);
    },
    show: function () {
      if (!this.dialog) {
        this.dialog = new Dialog({
          "title": this.dialogTitleText,
          "content": this
        });
      }
      this._toggleSuccessMessage(false);

      // If it was previously submitted, let's clear the value on show()
      if (this.hasSubmitted) {
        this.hasSubmitted = false;
        this._resetTextInput();
      }
      this._toggleSubmitButton();
      EcsTracking.track(EcsEvents.ViewFeedbackModalEvent, TrackingHelper.getEventParams());
      this.dialog.show();
    },
    _resetTextInput: function () {
      this.textarea.value = "";
      this.charCounter._countRemainingCharacters(this.textarea);
    },
    hide: function () {
      if (this.dialog) {
        this.dialog.hide();
      }
    },
    /**
     * Dojo widget event for when a customer submits the feedback submits
     *
     * @param {string} feedback the feedback typed into the text area
     */
    onFeedbackSubmitted: function (feedback) {},
    destroy: function () {
      this.inherited(arguments);
      if (this.dialog) {
        this.dialog.destroy();
      }
    }
  });
});