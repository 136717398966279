define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "velocity/velocity.ui"], function (declare, base, query, domAttr, html, domClass, mUrl, lang, xhr, domStyle, Velocity) {
  return declare([base], {
    onReady: function () {
      // Template selection
      // Template names: onecolumn, twocolumnstacked, twocolumninline

      query(".template-select-button").on("click", function () {
        var current = this;

        // Toggle button text
        query(".template-select-button").html("Select").removeClass("p1");
        html.set(current, "Selected");
        domClass.add(current, "p1");

        // Highlight container
        query(".template-container").removeClass("v-bg-gray-lightest");
        var container = current.closest(".template-container");
        domClass.add(container, "v-bg-gray-lightest");
        var template = domAttr.get(current, "data-template");
        // Set the data attribute on Send to Mailchimp button
        query("#sendTemplate").attr("data-template-selected", template);
      });
      query("#sendTemplate").on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        var current = this;
        domClass.add(current, 'disabled');
        domClass.add(current, 'button-loading');
        var resource = domAttr.get(current, "data-resource");
        var id = domAttr.get(current, "data-resource-id");
        var template_id = domAttr.get(current, "data-template-selected");
        var shop = domAttr.get(current, "data-resource-shop");
        var url = mUrl.toUrl("shopify/build-campaign?resource=" + resource + "&id=" + id + "&template_id=" + template_id + "&shop=" + shop);
        xhr.get(url, {
          handleAs: "json",
          sync: false
        }).then(lang.hitch(this, function (data) {
          var redirect = mUrl.toUrl(data.url);
          window.location.href = redirect;
        }), function (err) {
          console.error('error!', err);
        });
      });
    }
  });
});