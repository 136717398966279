define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // lang.getObject
"dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// declare
"./_TabContainerBase", "./TabController", "./ScrollingTabController"], function (lang, declare, _TabContainerBase, TabController, ScrollingTabController) {
  // module:
  //		dijit/layout/TabContainer

  return declare("dijit.layout.TabContainer", _TabContainerBase, {
    // summary:
    //		A Container with tabs to select each child (only one of which is displayed at a time).
    // description:
    //		A TabContainer is a container that has multiple panes, but shows only
    //		one pane at a time.  There are a set of tabs corresponding to each pane,
    //		where each tab has the name (aka title) of the pane, and optionally a close button.
    //
    //		See `StackContainer.ChildWidgetProperties` for details on the properties that can be set on
    //		children of a `TabContainer`.

    // useMenu: [const] Boolean
    //		True if a menu should be used to select tabs when they are too
    //		wide to fit the TabContainer, false otherwise.
    useMenu: true,
    // useSlider: [const] Boolean
    //		True if a slider should be used to select tabs when they are too
    //		wide to fit the TabContainer, false otherwise.
    useSlider: true,
    // controllerWidget: Class
    //		An optional parameter to override the widget used to display the tab labels
    controllerWidget: "",
    _makeController: function ( /*DomNode*/srcNode) {
      // summary:
      //		Instantiate tablist controller widget and return reference to it.
      //		Callback from _TabContainerBase.postCreate().
      // tags:
      //		protected extension

      // "string" branch for back-compat, remove for 2.0
      var cls = this.baseClass + "-tabs" + (this.doLayout ? "" : " dijitTabNoLayout"),
        TabController = typeof this.controllerWidget == "string" ? lang.getObject(this.controllerWidget) : this.controllerWidget;
      return new TabController({
        id: this.id + "_tablist",
        ownerDocument: this.ownerDocument,
        dir: this.dir,
        lang: this.lang,
        textDir: this.textDir,
        tabPosition: this.tabPosition,
        doLayout: this.doLayout,
        containerId: this.id,
        "class": cls,
        nested: this.nested,
        useMenu: this.useMenu,
        useSlider: this.useSlider,
        tabStripClass: this.tabStrip ? this.baseClass + (this.tabStrip ? "" : "No") + "Strip" : null
      }, srcNode);
    },
    postMixInProperties: function () {
      this.inherited(arguments);

      // Scrolling controller only works for horizontal non-nested tabs
      if (!this.controllerWidget) {
        this.controllerWidget = (this.tabPosition == "top" || this.tabPosition == "bottom") && !this.nested ? ScrollingTabController : TabController;
      }
    }
  });
});