define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "vendor/lodash-amd/values", "vendor/lodash-amd/some", "mojo/lib/Poller", "mojo/lib/request-helpers", "mojo/lib/flags", "mojo/context", "mojo/api/segment", "mojo/user", "mojo/widgets/content-manager/Api", "mojo/url", "mojo/utils/date", "./campaign/PresendChecksCollection"], function (declare, lang, xhr, entities, values, some, Poller, requestHelpers, flags, context, segmentApi, user, contentManagerApi, mUrl, dateUtils, PresendChecksCollection) {
  var CampaignSegment = declare(null, {
    constructor: function (properties) {
      this.type = properties.type;
      this.id = properties.id;
      this.name = properties.name;
      this.value = properties.value;
    }
  });
  var ListComplianceStatus = declare(null, {
    constructor: function (properties) {
      this.mustBePruned = properties.must_be_pruned;
      this.mustUndoImports = properties.must_undo_imports;
      this.mustReconfirm = properties.must_reconfirm; // this is NOT blocking
      this.mustReconfirmExternal = properties.must_reconfirm_external;
      this.hasRecentReconfirmation = properties.has_recent_reconfirmation;
      this.reconfirmCount = properties.reconfirm_count;
      this.hasDraftReconfirmation = properties.has_draft_reconfirmation;
    },
    hasBlockingIssues: function () {
      return some([this.mustBePruned, this.mustUndoImports, this.mustReconfirmExternal]);
    }
  });
  var CampaignList = declare(null, {
    constructor: function (properties) {
      this.listId = properties.list_id;
      this.name = properties.name;
      // TODO remove these dates, unless used in a view
      this.createdAt = new Date(properties.created_at);
      this.isDeleted = properties.is_deleted === true;
      // TODO remove these dates, unless used in a view
      this.deletedAt = new Date(properties.deleted_at);
      this.isDisabled = properties.is_disabled === true;
      this.defaults = {
        fromName: properties.defaults.from_name,
        fromEmail: properties.defaults.from_email,
        fromEmailIsVerified: properties.defaults.from_email_verified,
        subject: properties.defaults.subject
      };
      this.mergeFields = properties.merge_fields;
      this.complianceStatus = new ListComplianceStatus(properties.compliance_status);
    }
  });
  var CampaignSegmentPasted = declare([CampaignSegment], {
    constructor: function (properties) {
      // get all parent's properties, plus pasted comes with count for free
      this.count = properties.count;
    }
  });
  CampaignSegment.createFromProperties = function (properties) {
    if (properties === null) {
      return null;
    }
    if (properties.type === "pasted") {
      return new CampaignSegmentPasted(properties);
    }
    return new CampaignSegment(properties);
  };
  var CampaignRecipients = declare(null, {
    constructor: function (properties) {
      this.count = null;
      this.recipientsAreReady = properties.recipients_are_ready;
      this.segment = CampaignSegment.createFromProperties(properties.segment);
      this.personalizeToTag = properties.content.to_email || "";
      if (properties.list) {
        this.count = "send_count" in properties && this.recipientsAreReady ? properties.send_count : null;
        this.listId = properties.list.list_id;
        this.listName = properties.list.name;
      }
    },
    needsRecipientCount: function () {
      return this.recipientsAreReady !== true;
    }
  });
  CampaignRecipients.requestRecipientCount = function (campaign) {
    // Use a statically cached countRequest so we don't repeatedly create the request loop
    if (!CampaignRecipients._countRequest) {
      CampaignRecipients._countRequest = campaign.getRecipientCountRequest();
      CampaignRecipients._countRequest.then(CampaignRecipients._clearCountRequest);
    }
    return CampaignRecipients._countRequest;
  };
  CampaignRecipients.cancelRecipientCount = function () {
    if (CampaignRecipients._countRequest && !CampaignRecipients._countRequest.isFulfilled()) {
      CampaignRecipients._countRequest.cancel();
      CampaignRecipients._clearCountRequest();
    }
  };
  CampaignRecipients._clearCountRequest = function () {
    CampaignRecipients._countRequest = null;
  };
  CampaignRecipients.getRecipientsPatchFromData = function (recipientsData) {
    var patch = {};
    if ("listId" in recipientsData) {
      patch = lang.mixin({}, patch, {
        "list_id": recipientsData.listId
      });
    }
    if ("segment" in recipientsData) {
      patch = lang.mixin({}, patch, {
        "segment": recipientsData.segment
      });
    }
    if ("personalizeToTag" in recipientsData) {
      patch = lang.mixin({}, patch, {
        content: {
          "to_email": recipientsData.personalizeToTag
        }
      });
    }
    return patch;
  };
  var CampaignSocialTwitter = declare(null, {
    constructor: function (properties) {
      this.shouldAutopost = properties.should_autopost;
      this.content = properties.content;
      this.fileData = properties.file_data ? properties.file_data.map(function (item) {
        return {
          selectedVersionUrl: item.selected_version_url,
          fileData: item.file_item ? new contentManagerApi.FileItem(item.file_item) : null
        };
      }) : null;
    }
  });
  var CampaignSocialInstagram = declare(null, {
    constructor: function (properties) {
      this.pageIds = properties.foreign_profile_id ? [properties.foreign_profile_id] : [];
      this.content = properties.content;
      this.fileData = properties.file_data ? properties.file_data.map(function (item) {
        return {
          selectedVersionUrl: item.selected_version_url,
          fileData: item.file_item ? new contentManagerApi.FileItem(item.file_item) : null
        };
      }) : null;
      this.shouldAutopost = properties.should_autopost;
    }
  });
  CampaignSocialInstagram.getPatchFromData = function (instagramData) {
    var patch = {};
    var socialPatch = {};
    socialPatch.platform = "instagram";
    if ("content" in instagramData) {
      socialPatch.message = instagramData.content;
    }
    if ("fileData" in instagramData) {
      socialPatch.file_data = instagramData.fileData.map(function (item) {
        return {
          id: item.id,
          url: item.selected_version_url
        };
      });
    }
    if ("pageIds" in instagramData) {
      socialPatch.foreign_profile_id = instagramData.pageIds[0];
    }
    if ("shouldAutopost" in instagramData) {
      socialPatch.should_autopost = instagramData.shouldAutopost;
    }
    patch = lang.mixin({}, patch, {
      social: lang.mixin({}, patch.social, socialPatch)
    });
    return patch;
  };
  CampaignSocialTwitter.getPatchFromData = function (twitterData) {
    var patch = {};
    if ("shouldAutopost" in twitterData) {
      patch = lang.mixin({}, patch, {
        content: {
          auto_tweet: twitterData.shouldAutopost
        }
      });
    }
    var socialPatch = {};
    if ("content" in twitterData) {
      socialPatch.auto_tweet_content = twitterData.content;
    }
    if ("fileData" in twitterData) {
      socialPatch.auto_tweet_file_data = twitterData.fileData;
    }
    patch = lang.mixin({}, patch, {
      social: lang.mixin({}, patch.social, socialPatch)
    });
    return patch;
  };
  var CampaignSocialFacebook = declare(null, {
    constructor: function (properties) {
      this.pageIds = properties.page_ids;
      this.content = properties.content;
      this.fileData = properties.file_data ? properties.file_data.map(function (item) {
        return {
          selectedVersionUrl: item.selected_version_url,
          fileData: item.file_item ? new contentManagerApi.FileItem(item.file_item) : null
        };
      }) : null;
      this.shouldAutopost = !!(this.pageIds && this.content);
    }
  });
  CampaignSocialFacebook.getPatchFromData = function (facebookData) {
    var patch = {};
    var socialPatch = {};
    socialPatch.platform = "facebook";
    socialPatch.content = {};
    if ("content" in facebookData) {
      socialPatch.content.message = facebookData.content;
    }
    if ("fileData" in facebookData) {
      socialPatch.content.file_data = facebookData.fileData.map(function (item) {
        return {
          id: item.id,
          url: item.selected_version_url
        };
      });
    }
    if ("pageIds" in facebookData && facebookData.pageIds.length > 0) {
      socialPatch.foreign_profile_id = facebookData.pageIds[0];
    }
    if ("shouldAutopost" in facebookData) {
      socialPatch.should_autopost = facebookData.shouldAutopost;
    }
    patch = lang.mixin({}, patch, {
      social: lang.mixin({}, patch.social, socialPatch)
    });
    return patch;
  };
  var CampaignSocial = declare(null, {
    constructor: function (properties) {
      this.twitter = new CampaignSocialTwitter(properties.social.twitter);
      this.facebook = new CampaignSocialFacebook(properties.social.facebook);
      this.instagram = new CampaignSocialInstagram(properties.social.instagram);
    }
  });
  CampaignSocial.getSocialPatchFromData = function (socialData) {
    var patch = {};
    if ("twitter" in socialData) {
      patch = lang.mixin({}, patch, CampaignSocialTwitter.getPatchFromData(socialData.twitter));
    }
    var facebookData = {};
    if ("facebook" in socialData) {
      facebookData = CampaignSocialFacebook.getPatchFromData(socialData.facebook);
      patch = lang.mixin({}, patch, facebookData);
    }
    var instagramData = {};
    if ("instagram" in socialData) {
      instagramData = CampaignSocialInstagram.getPatchFromData(socialData.instagram);
      patch = lang.mixin({}, patch, instagramData);
    }
    return patch;
  };
  var CampaignFrom = declare(null, {
    constructor: function (properties) {
      this.fromName = properties.content.from_name;
      this.fromEmail = properties.content.from_email;
      this.isVerifiedEmail = properties.content.is_verified_email;
    }
  });
  var CampaignContent = declare(null, {
    constructor: function (properties) {
      this.subject = properties.content.subject;
      this.previewText = properties.content.preview_text;
      this.shortUrlLength = properties.content.short_url.length;
    }
  });
  var SettingsAndTrackingContent = declare(null, {
    constructor: function (properties) {
      if (properties) {
        this.useInbox = properties.content.use_inbox;
        this.audienceHandlesRepliesWithInbox = properties.content.handle_replies_with_inbox;
        this.convertVideo = properties.content.convert_video;
        this.trackOpens = properties.content.track_opens;
        this.trackClicksHTML = properties.content.track_clicks_html;
        this.trackClicksText = properties.content.track_clicks_text;
        this.analytics = properties.content.analytics;
        this.analyticsTag = properties.content.analytics_tag;
        this.ecomm360 = properties.content.ecomm360;
        this.clicktale = properties.content.clicktale;
        this.clicktaleTag = properties.content.clicktale_tag;
        this.gooal = properties.content.gooal;
        this.googleEnabled = properties.google_enabled;
        this.isGoalAvailable = properties.is_goal_available;
        this.isGoalEnabled = properties.is_goal_enabled;
        this.convoNotifierCount = properties.convo_notifier_account;
        this.crmSfExists = properties.crm.salesforce;
        this.crmJavExists = properties.crm.capsule;
        if (properties.crm.salesforce) {
          this.crmSfCreateNotes = properties.crm.salesforce.create_notes;
          this.crmSfCreateCampaign = properties.crm.salesforce.create_campaign;
        }
        if (properties.crm.capsule) {
          this.crmJavCreateNotes = properties.crm.capsule.create_notes;
        }
      }
    }
  });
  var CampaignPromotion = declare(null, {
    constructor: function (properties) {
      if (properties.promotion) {
        this.promotion = properties.promotion;
        this.promotionIsEnabled = properties.promotion.is_enabled;
        this.promotionIsComplete = properties.promotion.is_complete;
      }
    }
  });
  var CampaignTestSend = declare(null, {
    constructor: function (properties) {
      this.nonaccountRecipients = properties ? properties.nonaccount_recipients : [];
      this.accountRecipients = properties ? properties.account_recipients : [];
      this.recipients = properties ? properties.recipients : [];
    },
    getDefaultTestEmails: function () {
      return this.nonaccountRecipients.join(", ");
    }
  });
  var CampaignUrl = declare(null, {
    constructor: function (properties) {
      this.rootDomain = properties.campaign_url.root_domain;
      this.campaignToken = properties.campaign_url.campaign_token;
      this.brandToken = properties.campaign_url.brand_token;
      this.mostRecentArchiveMapping = properties.campaign_url.mostRecentArchiveMapping;
      this.mostRecentArchiveMappingCanEdit = properties.campaign_url.mostRecentArchiveMappingCanEdit;
      this.canCustomizeArchiveUrl = properties.campaign_url.canCustomizeArchiveUrl;
      this.brands = properties.campaign_url.brands;
      this.url = properties.campaign_url.url;
      this.isDuplicateCampaignUrl = properties.campaign_url.is_duplicate;
    }
  });
  var CampaignComplianceStatus = declare(null, {
    constructor: function (properties) {
      var complianceStatus = properties.compliance_status;
      this.accountReviewIsRequired = complianceStatus.account_review_is_required;
      this.exceedsSingleCampaignLimit = complianceStatus.exceeds_single_campaign_limit;
      this.exceedsDailyCampaignLimit = complianceStatus.exceeds_daily_campaign_limit;
      this.exceedsAvailableCredits = complianceStatus.exceeds_available_credits;
      this.creditsLeft = complianceStatus.credits_left;
      this.minimumPackage = complianceStatus.minimum_package;
      this.egpPredictionWarning = properties.egp_prediction_warning;
    },
    hasBlockingIssues: function () {
      return some([this.exceedsSingleCampaignLimit, this.exceedsDailyCampaignLimit, this.exceedsAvailableCredits, this.egpPredictionWarning]);
    }
  });
  var CampaignAdmin = declare(null, {
    constructor: function (properties) {
      this.id = properties.id;
      this.name = properties.name;
    }
  });
  var PreviewTextGenerator = declare(null, {
    constructor: function (properties) {
      if (properties) {
        this.status = properties.status;
        this.value = properties.value;
        this.hasSeenPreviewText = properties.hasSeenPreviewText;
      }
    }
  });
  var api = {
    /**
     * Apply a patch of properties to the campaign, and get a full, updated, campaign back
     *
     * @param {string} campaignId - Campaign id to update
     * @param {Object} patch - Properties to update
     * @return {Promise.<Campaign>} A Promise resolving to a new updated campaign instance
     */
    patchById: function (campaignId, patch) {
      var url = mUrl.toUrl("/campaigns/api/patch", {
        "campaign_id": campaignId
      });
      var executePostRequest = xhr.post(url, {
        "handleAs": "json",
        "data": {
          "patch": JSON.stringify(patch) // dojo doesn't serialize json objects
        }
      });
      var campaignOrError = executePostRequest.then(this.createFromProperties.bind(this), requestHelpers.throwError);
      return campaignOrError;
    },
    /**
     * Instantiate a new Campaign from the given properties
     *
     * @param {Object} campaignProperties - Properties to build the Campaign instance from
     * @return {Campaign} A full instance
     */
    createFromProperties: function (campaignProperties) {
      return new Campaign(campaignProperties);
    },
    CampaignRecipients: CampaignRecipients,
    /**
     * Fetch all the admins that an author can notify about a finished campaign
     *
     * @param {string} userId - A user's id
     * @return {Promise.<CampaignAdmin[]>} A Promise resolving to an array of CampaignAdmins
     */
    getAdminsForUser: function (userId) {
      return xhr.post(mUrl.toUrl("/campaigns/users-to-notify"), {
        "handleAs": "json"
      }).then(function (data) {
        return data && data.map(function (admin) {
          return new CampaignAdmin(admin);
        });
      });
    },
    /**
     * Records when user has clicked preview text rec generation CTA
     *
     * @param {string} campaignId - Campaign id to update
     * @return {boolean} success
     */
    recordPreviewTextGenClicked: function (campaignId) {
      return xhr.post(mUrl.toUrl("/campaigns/preview-text-gen-clicked", {
        id: campaignId
      }), {
        "handleAs": "json"
      }).then(function (data) {
        return data;
      });
    },
    /**
     * Records when user has dismissed preview text rec
     *
     * @param {string} campaignId - Campaign id to update
     * @return {boolean} success
     */
    recordPreviewTextDismissal: function (campaignId) {
      return xhr.post(mUrl.toUrl("/campaigns/preview-text-dismissed", {
        id: campaignId
      }), {
        "handleAs": "json"
      }).then(function (data) {
        return data;
      });
    }
  };
  var getResolutionPermissionsInfo = function (hasAction, roleNeeded) {
    if (hasAction && !user.hasRole(roleNeeded)) {
      var message;
      if (roleNeeded === "admin") {
        message = "An admin must resolve this.";
      } else {
        message = "A manager or admin must resolve this.";
      }
      return {
        canResolve: false,
        message: message
      };
    }
    return {
      canResolve: true
    };
  };
  var getErrorState = function (campaign) {
    var resolutionInfo;
    var errorState = {
      scope: null,
      // "list", "account", "campaign" -- describes what is blocked, not what causes the blockage. thus a bad list that blocks a whole account from sending has scope "account";
      descriptionShort: null,
      // string that fits on one line.
      descriptionLong: null,
      // array of paragraphs
      title: null,
      //string
      resolution: {
        action: {
          // should be `null` if no action button/link is availale
          title: null,
          // button text
          attr: null // string containing action attribute, e.g. href or dojo-attach-event
        },
        roleNeeded: "author",
        // string constant "author", "manager", "admin";
        canResolve: null,
        // boolean
        cantResolveMessage: null // string or `null` if canResolve==true
      }
    };
    var list = campaign.list;
    if (campaign && campaign.egpPredictionWarning) {
      errorState.scope = "list";
      if (campaign.egpPrediction.actionRequired === "no_send") {
        errorState.descriptionShort = "Mailchimp's abuse prevention system has identified issues with the addresses on " + entities.encode(campaign.list.name) + ".";
        errorState.descriptionLong = ["Mailchimp's abuse prevention system, Omnivore, has identified some issues with the addresses on <strong>" + entities.encode(campaign.list.name) + "</strong> that have never been sent to through our system.", "Omnivore has indicated that these addresses may cause high <a href=\"https://mailchimp.com/help/about-abuse-complaints/\" target=\"_blank\">abuse complaints</a> or <a href=\"https://mailchimp.com/help/soft-vs-hard-bounces/\" target=\"_blank\">bounce rates</a>, and could contain <a href=\"https://mailchimp.com/help/about-spamtraps/\" target=\"_blank\">spamtrap addresses</a>. These are serious issues when it comes to email marketing and delivery.  The issues with these addresses will need to be resolved before the list can be sent to.", "Remember that all lists used in your Mailchimp account must be current and up-to-date, and comply with our <a href=\"http://mailchimp.com/legal/\" target=\"_blank\">Terms of Use and Acceptable Use Policies</a>. Our Knowledge Base has further information about <a href=\"https://mailchimp.com/help/examples-of-compliant-and-non-compliant-lists/\" target=\"_blank\">supported lists</a> and <a href=\"https://mailchimp.com/help/requirements-and-best-practices-for-lists/\" target=\"_blank\">best practices</a>."];
        errorState.resolution.action.attr = "data-dojo-attach-event='click: openReconfirmDialog'";
        errorState.resolution.action.title = "Resolve";
        errorState.resolution.roleNeeded = "manager";

        // get info about user's permissions to resolve
        resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
        errorState.resolution.canResolve = resolutionInfo.canResolve;
        errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
        return errorState;
      } // else {
      errorState.descriptionShort = "Mailchimp's abuse prevention system has identified issues with your recent import.";
      if (campaign.egpPrediction.initialReason === "public") {
        errorState.descriptionLong = ["Mailchimp's abuse prevention system, Omnivore, automatically scanned your recent import. Omnivore flagged the list for having a large number of addresses that may cause complaints, high bounce rates, or blocklistings. <a href=\"http://mailchimp.com/resources/research\" target=\"_blank\">Our research</a> shows that this can lead to increased abuse and complaint rates. This is known as a 'stale' list. Stale lists are often older lists, and may not have been sent to in some time, are collected through supported methods, and/or improperly managed for bounces and unsubscribe requests.", "Internet Service Providers and email services set limits on the number of emails that report abuse, send to spam traps, and generate high bounce rates. When those limits are exceeded, the sender of the emails can be blocked from further sending."];
      } else {
        errorState.descriptionLong = ["Mailchimp's abuse prevention system, Omnivore, automatically scanned your recent import. Omnivore flagged the list for having a large number of addresses that may cause complaints, high bounce rates, or blocklistings. <a href=\"http://mailchimp.com/resources/research\" target=\"_blank\">Our research</a> shows that this can lead to increased abuse and complaint rates. This is known as a 'stale' list. Stale lists are often older lists, and may not have been sent to in some time, are collected through supported methods, and/or improperly managed for bounces and unsubscribe requests.", "Internet Service Providers and email services set limits on the number of emails that report abuse, send to spam traps, and generate high bounce rates. When those limits are exceeded, the sender of the emails can be blocked from further sending."];
      }
      errorState.resolution.action.attr = "data-dojo-attach-event='click: openBounceWarningDialog'";
      errorState.resolution.action.title = "Resolve";
      errorState.resolution.roleNeeded = "manager";

      // get info about user's permissions to resolve
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (!user.canSend && user.whyNotSend) {
      // User can't send, e.g. because paused, chargeback, etc.
      errorState.title = [user.whyNotSendTitle];
      errorState.descriptionLong = [user.whyNotSend];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.scope = "account";
      if (user.isPaused) {
        errorState.resolution.roleNeeded = "admin";
        errorState.resolution.action = {
          attr: null,
          title: null
        };
      }
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (list && list.complianceStatus && list.complianceStatus.mustReconfirm && !campaign.isReconfirm) {
      var reconfirmCount = list.complianceStatus.reconfirmCount;
      errorState.scope = "list";
      errorState.title = "Reconfirmation status";
      errorState.descriptionLong = [reconfirmCount + " email " + (reconfirmCount === 1 ? "address has" : "addresses have") + " been removed for reconfirmation."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.action = {};
      var queryParams = {
        type: "reconfirm",
        list_id: list.listId,
        __csrf_token: user.getCsrfToken()
      };
      errorState.resolution.action.attr = "href=\"" + mUrl.toUrl("/campaigns/show-or-create", queryParams) + "\"";
      if (list.complianceStatus.hasDraftReconfirmation) {
        errorState.descriptionLong[1] = "A <a href=" + mUrl.toUrl("campaigns/show-or-create", queryParams) + ">reconfirmation campaign</a> for those addresses has been created but not sent yet.";
        errorState.resolution.action.title = "show reconfirmation campaign";
      } else {
        errorState.descriptionLong[1] = "You can send this campaign now or <a href=" + mUrl.toUrl("campaigns/show-or-create", queryParams) + ">create a reconfirmation campaign</a> to give the removed addresses a chance to opt-in before sending.";
        errorState.resolution.action.title = "create a reconfirmation campaign";
      }
      errorState.resolution.roleNeeded = "author";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (list && list.complianceStatus && list.complianceStatus.mustReconfirmExternal) {
      errorState.title = "Sending Disabled";
      errorState.descriptionLong = ["You must reconfirm this list before you can send to it."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "href=\"" + mUrl.toUrl("/lists/members/reconfirm-external", {
          id: list.listId
        }) + "\"",
        title: "export and remove addresses"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (list && list.complianceStatus && list.complianceStatus.mustBePruned) {
      errorState.title = "Sending Disabled";
      errorState.descriptionLong = ["You must prune unengaged subscribers from this list before you can send to it."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "href=\"" + mUrl.toUrl("/lists/members/prune", {
          id: campaign.recipients.listId
        }) + "\"",
        title: "resolve"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (list && list.isDisabled) {
      errorState.title = "Sending Disabled";
      errorState.descriptionLong = ["The list you are sending to is disabled."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "href=\"" + mUrl.toUrl("/account") + "\"",
        title: "resolve"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (list && list.complianceStatus && list.complianceStatus.mustUndoImports) {
      errorState.title = "Sending Disabled";
      errorState.descriptionLong = ["Before you can send to this list, you'll need to undo some recent list imports."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "href=\"" + mUrl.toUrl("/account") + "\"",
        title: "resolve"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (campaign && campaign.complianceStatus.exceedsAvailableCredits) {
      errorState.title = "Not Enough Credits";
      if (user.isPayAsYouGo) {
        errorState.descriptionLong = ["Your account does not have enough email credits to send this campaign. You would need to purchase <b>" + campaign.complianceStatus.creditsLeft + " credit" + (campaign.complianceStatus.creditsLeft - 1 ? "s" : "") + "</b> in order to send it."];
        errorState.descriptionShort = errorState.descriptionLong[0];
        errorState.resolution.roleNeeded = "manager";
        errorState.resolution.action = {
          attr: "href=\"" + mUrl.toUrl("/account/billing/plans/", {
            credits: campaign.complianceStatus.creditsLeft
          }) + "\"",
          title: "resolve"
        };
      } else {
        errorState.descriptionLong = ["This exceeds the sending limit for your free account."];
        errorState.descriptionShort = [errorState.descriptionLong[0]];
        errorState.resolution.roleNeeded = "manager";
        errorState.resolution.action = {
          attr: "data-dojo-attach-event='click: openPaywallWithSendTimeLimitGATracking'",
          title: "resolve"
        };
      }
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (campaign && campaign.complianceStatus && campaign.complianceStatus.exceedsSingleCampaignLimit) {
      errorState.title = "Free Account Limit";
      errorState.descriptionLong = ["Free accounts are not allowed to send to more than <b>" + user.freeLimit + "</b> recipients in a single campaign."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "href=\"" + mUrl.toUrl("/account/billing/plans") + "\"",
        title: "resolve"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (campaign && campaign.complianceStatus && campaign.complianceStatus.exceedsDailyCampaignLimit) {
      errorState.title = "Free Account Limit";
      errorState.descriptionLong = ["Free accounts are not allowed to send to more than <b>" + user.freeLimit + "</b> recipients in a single day.", "There have been <b>" + user.sentToday + "</b> sends in the past 24 hours. This send includes <b>" + campaign.recipients.count + "</b>, which would exceed the limit by <b>" + (user.sentToday + campaign.recipients.count - user.freeLimit) + "</b>.</p>"];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.resolution.roleNeeded = "manager";
      errorState.resolution.action = {
        attr: "data-dojo-attach-event='click: openPaywall'",
        title: "resolve"
      };
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }
    if (campaign && campaign.recipients.count === 0 /* && !isPending && isComplete */) {
      errorState.descriptionLong = ["To send, you need at least 1 subscribed contact in your audience."];
      errorState.descriptionShort = errorState.descriptionLong[0];
      errorState.scope = "list";
      resolutionInfo = getResolutionPermissionsInfo(errorState.resolution.action, errorState.resolution.roleNeeded);
      errorState.resolution.canResolve = resolutionInfo.canResolve;
      errorState.resolution.cantResolveMessage = resolutionInfo.canResolve ? null : resolutionInfo.message;
      return errorState;
    }

    // no errors
    return null;
  };
  var Campaign = declare(null, {
    /**
     * Takes a properties bundle, and unpacks it to front-end-friendly structure, delegating
     * to sub-models along the way
     *
     * @param {Object} properties - Properties from campaigns endpoints
     */
    constructor: function (properties) {
      this._timestamp = Date.now();
      this.campaignId = properties.campaign_id;
      this.createdAt = properties.created_at ? dateUtils.dateTransform(properties.created_at) : null;
      this.status = properties.status;
      this.name = properties.content.title || properties.content.subject;
      this.screenshotUrl = properties.screenshot_url;
      this.hasBrokenBlocks = properties.has_broken_blocks;
      this.hasDefaultBlockContent = properties.has_default_block_content;
      this.hasCreativeAssistantTemplate = properties.has_creative_assistant_template;
      this.showCreativeAssistantPaywallExperiment = properties.show_creative_assistant_paywall_experiment;
      this.presendChecks = new PresendChecksCollection(properties.presend_checks);
      this.renderedHtml = properties.rendered_html;
      this.isContentReady = properties.is_content_ready;
      this.useAutoFooter = properties.use_auto_footer;
      this.isHtmlNewerThanPlainText = properties.is_html_newer_than_plain_text;
      this.plainTextLastUpdatedAt = properties.plain_text_last_updated_at;
      this.hasRewards = properties.has_rewards;
      this.fromDomain = properties.from_domain;
      this.testsSent = properties.tests_sent;
      this.isCanceled = properties.is_canceled;
      this.canceledAt = properties.canceled_at ? dateUtils.dateTransform(properties.canceled_at) : null;
      this.sendTime = properties.send_time ? dateUtils.dateTransform(properties.send_time) : null;
      this.deliveryPercentage = properties.delivery_percentage;
      this.notificationRecipients = properties.notification_recipients;
      this.canTimewarp = properties.can_timewarp;
      // Account-wide problems
      this.egpPredictionWarning = properties.egp_prediction_warning;
      if (properties.egp_prediction_warning && properties.egp_prediction) {
        this.egpPrediction = {
          actionRequired: properties.egp_prediction.action_required,
          initialReason: properties.egp_prediction.initial_reason,
          userSignedName: properties.egp_prediction.user_signed_name
        };
      }
      this.complianceStatus = new CampaignComplianceStatus(properties);
      this.list = properties.list ? new CampaignList(properties.list) : null;
      this.social = new CampaignSocial(properties);
      this.recipients = new CampaignRecipients(properties);
      this.from = new CampaignFrom(properties);
      this.content = new CampaignContent(properties);
      this.promotion = new CampaignPromotion(properties);
      this.recentCampaigns = properties.recent_campaigns;
      this.lastTestSend = new CampaignTestSend(properties.last_test_send);
      this.campaignUrl = new CampaignUrl(properties);
      this.settingsAndTracking = new SettingsAndTrackingContent(properties);
      this.previewTextGenerator = new PreviewTextGenerator(properties.preview_text_generator);
      if (properties.list) {
        this.fromDefaultName = properties.list.defaults.from_name;
        this.fromDefaultEmail = properties.list.defaults.from_email;
        this.fromDefaultVerifiedEmail = properties.list.defaults.from_email_verified;
        this.fromDefaultSubject = properties.list.defaults.subject;
      }
      this.errorState = getErrorState(this);
    },
    /**
     * Repack the front-end-friendly structure into the format the backend originally
     * provided, and send a request to update the campaign
     *
     * @param {Object} data - js-friendly structure of updated data to patch up
     * @return {Promise.<Campaign>} A Promise resolving to a new updated campaign instance
     */
    patch: function (data) {
      if (!data) {
        data = {};
      }
      var patch = {};
      if (data.name) {
        patch.content = lang.mixin({}, patch.content, {
          title: data.name
        });
      }
      if (data.from) {
        patch.content = lang.mixin({}, patch.content, {
          from_name: data.from.fromName,
          from_email: data.from.fromEmail
        });
      }
      if (data.content && (data.content.subject || data.content.previewText)) {
        patch.content = lang.mixin({}, patch.content, {
          subject: data.content.subject,
          preview_text: data.content.previewText
        });
      }
      if (data.recipients) {
        var recipientsPatch = CampaignRecipients.getRecipientsPatchFromData(data.recipients);
        patch = lang.mixin({}, patch, recipientsPatch);
        if ("listId" in data.recipients) {
          patch = lang.mixin({}, patch, {
            "list_id": data.recipients.listId
          });
        }
        if ("segment" in data.recipients) {
          patch = lang.mixin({}, patch, {
            "segment": data.recipients.segment
          });
        }
        if ("personalizeToTag" in data.recipients) {
          patch = lang.mixin({}, patch, {
            content: {
              "to_email": data.recipients.personalizeToTag
            }
          });
        }
      }
      if (data.campaign_url && (data.campaign_url.campaign_token !== undefined || data.campaign_url.brand_token)) {
        patch = lang.mixin({}, patch, {
          campaign_token: data.campaign_url.campaign_token
        }, data.campaign_url.brand_token ? {
          list: {
            brand: {
              brand_token: data.campaign_url.brand_token
            }
          }
        } : {});
      }
      if (data.social) {
        var socialPatch = CampaignSocial.getSocialPatchFromData(data.social);
        patch = lang.mixin({}, patch, socialPatch);
      }
      if (data.settingsAndTracking) {
        patch.content = lang.mixin({}, patch.content, {
          use_conversation: data.settingsAndTracking.useInbox,
          use_inbox: data.settingsAndTracking.useInbox,
          convert_video: data.settingsAndTracking.convertVideo,
          track_opens: data.settingsAndTracking.trackOpens,
          track_clicks_html: data.settingsAndTracking.trackClicksHTML,
          track_clicks_text: data.settingsAndTracking.trackClicksText,
          analytics: data.settingsAndTracking.analytics,
          analytics_tag: data.settingsAndTracking.analyticsTag,
          ecomm360: data.settingsAndTracking.ecomm360,
          clicktale: data.settingsAndTracking.clicktale,
          clicktale_tag: data.settingsAndTracking.clicktaleTag,
          gooal: data.settingsAndTracking.gooal
        });
        patch.crm = lang.mixin({}, patch.crm, {
          salesforce: {
            "create_notes": data.settingsAndTracking.crmSfCreateNotes,
            "create_campaign": data.settingsAndTracking.crmSfCreateCampaign
          },
          capsule: {
            "create_notes": data.settingsAndTracking.crmJavCreateNotes
          }
        });
      }
      return api.patchById(this.campaignId, patch);
    },
    getRecipientCountRequest: function () {
      var self = this;
      var pollableFunc = function () {
        return self.patch({}).then(function (campaign) {
          if (campaign.recipients.needsRecipientCount()) {
            return Poller.RETRY;
          }
        });
      };
      var poller = new Poller(pollableFunc);
      poller.begin();
      return poller.getPromise();
    }
  });
  return api;
});