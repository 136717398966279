define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/_Part", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/CampaignBreadCrumb.html"], function (declare, _Part, _TemplatedMixin, lang, on, templateString) {
  return declare([_Part, _TemplatedMixin], {
    templateString: templateString,
    canDisplay: function () {
      return ["active", "paused", "needsAttention"].indexOf(this.target.getFilter("bucket")) > -1;
    },
    init: function () {
      on(this.domNode, "click", lang.hitch(this, function (e) {
        e.preventDefault();
        this.target.clearAllFilters();
        this.target.setFilter("bucket", "ongoing");
      }));

      //Listen for changes to bucket filter and toggle display
      this._watches.push(this.target.watchFilter("bucket", this.toggleDisplay.bind(this)));
    }
  });
});