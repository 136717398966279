define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./confirm", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "mojo/app/wizard"], function (declare, confirmJS, domAttr, query, on, utils, wizard) {
  return declare([confirmJS], {
    campaignId: null,
    // Passed from controller

    onReady: function () {
      utils.parseEmoji(document.body);
      wizard.repositionFlashBlock();
      wizard.moveFinalAction(this.$el.deliveryOptions);
      var self = this;

      // Social Cards per content campaign
      query('[data-social-share-el="button"]').on('click', function () {
        var campaignId = domAttr.get(this, "data-campaign-id");
        var title = domAttr.get(this, "data-campaign-title");
        self.openSocialShare(campaignId, title, 'circle-color');
      });
      this.initNotifyDialog();
      this.setupScheduleOptions();
      this.checkSendCountReady();
    }
  });
});