define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "mojo/widgets/checklist-editor/items/_PostcardPreview", "mojo/api/postcard", "dojo/text!./templates/FrontAndBackPreview.html"], function (declare, lang, Renderable, PostcardPreview, postcardApi, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.postcard = props.postcard;
    },
    postCreate: function () {
      postcardApi.getContentPreview(this.postcard.content).then(function (preview) {
        this.setState({
          preview: preview
        });
      }.bind(this));
    },
    render: function () {
      this.inherited(arguments);
      this._renderPreviews();
    },
    _renderPreviews: function () {
      var preview = this.state.preview;
      if (!preview) {
        return;
      }
      this.frontPreview = new PostcardPreview({
        name: "Front",
        previewContent: preview.frontHtml,
        isLoading: false
      }).placeAt(this.frontPreviewContainer);
      this.backPreview = new PostcardPreview({
        name: "Back",
        previewContent: preview.backHtml,
        isLoading: false
      }).placeAt(this.backPreviewContainer);
    }
  });
});