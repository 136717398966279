define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, topic, base, mUrl, tabContainerUtil, FilterMenu, FilterMessage) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        tab.preload = true;
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      topic.subscribe("mojo/analytics/List/facebook-deleted-list/render", function (list) {
        self.parse();
        tabContainer.facebookDeletedSection.set("title", "Deleted Facebook Ads (" + list.total + ")");
      });
      topic.subscribe("mojo/analytics/List/facebook-list/render", function (list) {
        self.parse();
        tabContainer.facebookSection.set("title", "Facebook Ads (" + list.total + ")");
      });
    },
    viewAdDetails: function (aid) {
      var self = this;
      var newPaneObj = {
        title: "Ad Details: " + aid,
        href: mUrl.toUrl("/peaches2/users/ads", {
          _s: "ad-details",
          user_id: self.userAttrs.userId,
          ad_id: aid
        }),
        stub: "ad-details",
        closable: true,
        onClose: function () {
          self.tabContainer.adDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true);
    },
    loadFilter: function (section) {
      var filter;
      var filterMessage;
      var filterSettings = {
        "filters": [{
          "label": "Status",
          "key": "status",
          "type": "select",
          "options": {
            "save": "Saved",
            "paused": "Paused",
            "schedule": "Scheduled",
            "sending": "sending",
            "sent": "Sent",
            "canceled": "Cancelled"
          }
        }, {
          "label": "Created since...",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day ago",
            "2": "Two days ago",
            "7": "One week ago"
          }
        }, {
          "label": "List ID",
          "key": "list_id",
          "type": "text"
        }, {
          "label": "FROM Name",
          "key": "from_name",
          "type": "text"
        }, {
          "label": "FROM Email",
          "key": "from_email",
          "type": "text"
        }, {
          "label": "Title",
          "key": "title",
          "type": "text"
        }, {
          "label": "Subject",
          "key": "subject",
          "type": "text"
        }, {
          "label": "Parent Campaign",
          "key": "parent",
          "type": "text"
        }, {
          "label": "Include this parent",
          "key": "include_parent",
          "type": "checkbox"
        }]
      };
      if (section === "facebook-deleted") {
        filterSettings.target = "facebook-deleted-list";
        filter = new FilterMenu(filterSettings);
        this.$el.facebookDeletedFilters.appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        this.$el.facebookDeletedFiltersMessage.appendChild(filterMessage.domNode);
      }
      if (section === "facebook") {
        filterSettings.target = "facebook-list";
        filter = new FilterMenu(filterSettings);
        this.$el.facebookFilters.appendChild(filter.domNode);
        filterMessage = new FilterMessage(filterSettings);
        this.$el.facebookFiltersMessage.appendChild(filterMessage.domNode);
      }
    }
  });
});