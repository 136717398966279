/**
 * Contains all the functions that are required for an arbitrary widget
 * to behave as an editor and be able to interact with mojo/neapolitan/EditorPane.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils"], function (declare, utils) {
  return declare(null, {
    /**
     * If specified, it will open in an EditorPane that has this particular paneId.
     */
    paneId: null,
    /**
     * Default behavior for show is to immediately load the widget.
     * You can override this function to allow a setup step by calling
     * publishSetup and firing the ready event afterwards.
     */
    show: function () {
      this.publishReady();
    },
    done: function () {
      this.publishDone();
    },
    /**
     * Tells the pane that you are ready to be shown
     */
    publishReady: function () {
      utils.globalPublish('mojo/editor/ready', [this]);
    },
    /**
     * Tells the pane that a setup step is required.
     */
    publishSetup: function () {
      utils.globalPublish('mojo/editor/setup', [this]);
    },
    /**
     * Tells the pane that you are done and ready to hide
     */
    publishDone: function () {
      utils.globalPublish('mojo/editor/done', [this]);
    },
    save: function (sync) {},
    /**
     * Callbacks that you can implement to do custom logic
     */
    onShow: function () {},
    onClose: function () {}
  });
});