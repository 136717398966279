define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/values", "vendor/lodash-amd/some", "mojo/url", "mojo/user", "mojo/lib/flags"], function (declare, lang, xhr, values, some, mUrl, user, flags) {
  var api = {
    /**
     * Apply a patch of properties to the page, and get a full, updated, page back
     *
     * @param {string} pageId - Page id to update
     * @param {Object} patch - Properties to update
     * @return {Promise.<Page>} A Promise resolving to a new updated page instance
     */
    patchById: function (pageId, patch) {
      return xhr.post(mUrl.toUrl("/landing-pages/patch", {
        id: pageId
      }), {
        handleAs: "json",
        data: {
          patch: JSON.stringify(patch) // dojo doesn't serialize json objects
        }
      }).then(this.createFromProperties.bind(this));
    },
    /**
     * Instantiate a new Page from the given properties
     *
     * @param {Object} pageProperties - Properties to build the Page instance from
     * @return {Page} A full instance
     */
    createFromProperties: function (pageProperties) {
      return new Page(pageProperties);
    },
    /**
     * Instantiate a new Page from the given properties
     *
     * @param {Object} pageProperties - Properties to build the Page instance from
     * @return {Page} A full instance
     */
    listPublishedSignupPages: function () {
      return xhr.get(mUrl.toUrl("channels/get-published-signup-pages"), {
        handleAs: "json",
        query: null
      }).then(function (data) {
        return data.map(function (page) {
          // returning option for select dropdown
          // with pageId and title as option value and label
          return {
            pageId: page.page_id,
            title: page.title
          };
        });
      });
    },
    acceptTerms: function () {
      return xhr.get(mUrl.toUrl("landing-pages/accept-terms"), {
        handleAs: "json"
      });
    }
  };

  // Values here will be found on the "campaignUrl" model property
  var PageUrl = declare(null, {
    constructor: function (properties) {
      if (!properties.page_url) {
        return;
      }
      this.rootDomain = properties.page_url.root_domain;
      this.campaignToken = properties.page_url.campaign_token;
      this.brandToken = properties.page_url.brand_token;
      this.domainType = properties.page_url.domain_type;
      this.mostRecentArchiveMapping = properties.page_url.mostRecentArchiveMapping;
      this.mostRecentArchiveMappingCanEdit = properties.page_url.mostRecentArchiveMappingCanEdit;
      this.canCustomizeArchiveUrl = properties.page_url.canCustomizeArchiveUrl;
      this.brands = properties.page_url.brands;
      this.url = properties.page_url.url;
    }
  });
  var BrandedDomains = declare(null, {
    constructor: function (props) {
      this.name = props.name;
      this.brandToken = props.brand_token;
    }
  });

  // Values here will be found directly on the model object.
  // Example (within the constructor method in a file such as PageUrl.js): this.state = { list: props.model.list }
  var Page = declare(null, {
    /**
     * Takes a properties bundle, and unpacks it to front-end-friendly structure, delegating
     * to sub-models along the way
     *
     * @param {Object} properties - Properties from pages endpoints
     */
    constructor: function (properties) {
      this._timestamp = Date.now();
      this.type = properties.type;
      this.pageId = properties.page_id;
      this.list = {
        id: properties.list_id,
        name: properties.list_name,
        captchaEnabled: properties.captcha_enabled,
        disabled: properties.list_disabled
      };
      this.store = {
        id: properties.store_id,
        name: properties.store_name
      };
      this.name = properties.name;
      this.title = properties.title;
      this.status = properties.status;
      this.createdAt = properties.created_at;
      this.updatedAt = properties.updated_at;
      this.publishedAt = properties.published_at;
      this.unpublishedAt = properties.unpublished_at;
      this.hasBrokenBlocks = properties.has_broken_blocks;
      this.hasDefaultBlockContent = properties.has_default_block_content;
      this.hasSignupForm = properties.page_has_signup_block;
      this.renderedHtml = properties.rendered_html;
      this.isContentReady = properties.is_content_ready;
      this.campaignUrl = new PageUrl(properties);
      this.canSend = properties.can_send;
      this.contentType = properties.content_type;
      this.screenshotUrl = properties.screenshot_url;
      this.tracking_settings = properties.tracking_settings;
      this.signup_tags = properties.signup_tags;
      this.parkedPageUrl = properties.parked_page_url;
      this.socialShareTitle = properties.og_title;
      this.socialShareDescription = properties.og_description;
      this.socialShareImage = properties.og_image;
      if (properties.branded_domains && properties.branded_domains.length > 0) {
        this.brandedDomains = properties.branded_domains.map(function (bd) {
          return new BrandedDomains(bd);
        });
      } else {
        this.brandedDomains = [];
      }
      var redirectSettingsKeys = ["successAction", "successRedirectUrl", "successPageRedirectUrl"];
      if (properties.signup_form_settings) {
        this.redirectSettings = redirectSettingsKeys.reduce(function (settings, settingKey) {
          settings[settingKey] = properties.signup_form_settings[settingKey];
          return settings;
        }, {});
      }
      this.screenshotUrl = properties.screenshot_url;
      this.hasTemplate = properties.has_template;
      this.faviconUrl = properties.favicon_url;
    },
    /**
     * Repack the front-end-friendly structure into the format the backend originally
     * provided, and send a request to update the campaign
     *
     * @param {Object} data - js-friendly structure of updated data to patch up
     * @return {Promise.<Page>} A Promise resolving to a new updated campaign instance
     */

    //  TODO: reconfig patch data to read from a config of the properties to update on patch.
    patch: function (data) {
      if (!data) {
        data = {};
      }
      var patch = {};
      if (data.name) {
        patch = lang.mixin({}, patch, {
          name: data.name
        });
      }
      if (data.listId) {
        patch = lang.mixin({}, patch, {
          list_id: data.listId
        });
      }
      if (data.storeId) {
        patch = lang.mixin({}, patch, {
          store_id: data.storeId
        });
      }
      if (data.title) {
        patch = lang.mixin({}, patch, {
          title: data.title
        });
      }
      if (data.faviconUrl) {
        patch = lang.mixin({}, patch, {
          favicon_url: data.faviconUrl
        });
      }
      if (data.socialShareTitle || data.socialShareTitle === "") {
        patch = lang.mixin({}, patch, {
          og_title: data.socialShareTitle
        });
      }
      if (data.socialShareDescription || data.socialShareDescription === "") {
        patch = lang.mixin({}, patch, {
          og_description: data.socialShareDescription
        });
      }
      if (data.socialShareImage || data.socialShareImage === null) {
        patch = lang.mixin({}, patch, {
          og_image: data.socialShareImage
        });
      }
      if (data.campaign_url && data.campaign_url.campaign_token) {
        patch = lang.mixin({}, patch, {
          campaign_token: data.campaign_url.campaign_token
        });
      }
      if (data.campaign_url && data.campaign_url.hasOwnProperty("brand_token")) {
        patch = lang.mixin({}, patch, {
          brand_token: data.campaign_url.brand_token
        });
      }
      if (data.tracking_settings) {
        patch = lang.mixin({}, patch, {
          tracking_settings: data.tracking_settings
        });
      }
      if (data.signup_tags) {
        patch = lang.mixin({}, patch, {
          signup_tags: data.signup_tags
        });
      }
      return api.patchById(this.pageId, patch);
    }
  });
  return api;
});