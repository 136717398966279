/**
 * Billing API
 *
 * Billing is tough. It's used throughout several parts within the app and that presents a problem for
 * maintainability and consistency. This file is intended as a centering point around communication mechanisms
 * to and from the backend.
 *
 */
define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/user", "mojo/lib/flags", "mojo/lib/logger"], function (lang, xhr, mUrl, user, flags, logger) {
  return {
    /**
     * Based on ~4 digit character, assess the credit card type.
     *
     * @param {Object} formData - Payload to attach to the POST request.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    getCardType: function (formData) {
      return this._get("/account/billing/card-type", formData);
    },
    /**
     * Grab info about the current payment from the backend.
     *
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    getPaymentMethod: function () {
      return this._get("/account/billing/payment-method", {
        id: user.userId
      });
    },
    /**
     * Submit a POST request to the backend to update a payment method for a given account. Append the CSRF token.
     *
     * @param {Object} formData - Payload to attach to the POST request. Usually payment method type and assoc. details
     * @returns {*|Promise} Promise object representing the HTTP post response.
     */
    postPaymentMethod: function (formData) {
      formData = lang.mixin({
        __csrf: user.__csrfToken
      }, formData);
      return this._post("/account/billing/payment-method-post", formData);
    },
    /**
     * Make a GET Request to a backend endpoint.
     *
     * @param {string} endpoint - backend path. Such as "/account/billing/"
     * @param {Object} formData - Payload to attach to the GET request.
     * @returns {*|Promise} Promise object representing the HTTP GET response.
     *
     * @private
     */
    _get: function (endpoint, formData) {
      return xhr.get(mUrl.toUrl(endpoint, formData), {
        "handleAs": "json"
      });
    },
    /**
     * Post the form to the backend. Should be flexible enough for most circumstances
     *
     * @param {string} endpoint - backend path. Such as "/account/billing/post-credit-card"
     * @param {Object} formData - subjective form data.
     * @returns {*|Promise} Promise object representing the HTTP post response.
     *
     * @private
     */
    _post: function (endpoint, formData) {
      return xhr.post(mUrl.toUrl(endpoint), {
        "handleAs": "json",
        "data": formData
      });
    }
  };
});