define(["dojo/request", "mojo/url", "mojo/utils"], function (request, mUrl, utils) {
  return {
    getAudienceUrl: "/audience/manage/get-audience",
    /**
     * Perform the POST request as indicated in the parameters
     * @param {string} requestUrl Url to hit
     * @param {object} postObject JSON object to post to the server
     * @param {function} callback Callback function
     * @returns {string} request Request response
     */
    postRequest: function (requestUrl, postObject, callback) {
      return request.post(requestUrl, {
        handleAs: "json",
        data: postObject
      }).then(function (response) {
        if (callback) {
          callback(response);
        }
      }, function (response) {
        utils.toast("Error: " + response.response.data.error);
      });
    },
    /**
     * Fetch an audience and its information related to deletion
     * @param {integer} listId the list id
     * @param {function} callback Callback function
     */
    getAudienceWithDeleteInfo: function (listId, callback) {
      var data = {
        "id": listId,
        withDeleteInfo: true
      };
      var requestUrl = mUrl.toUrl(this.getAudienceUrl);
      this.postRequest(requestUrl, data, callback);
    }
  };
});