define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"dijit/_WidgetBase", "dijit/_AttachMixin", // Dojo modules
"dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Custom modules
"mojo/url", "mojo/utils", "mojo/lib/logger",
// Vendor
"velocity/velocity", "velocity/velocity.ui"], function (declare, _WidgetBase, _AttachMixin, on, xhr, mUrl, utils, logger, Velocity) {
  return declare([_WidgetBase, _AttachMixin], {
    // Request Key that we'll pass to the backend.
    requestKey: null,
    /**
     * Part of Dojo's lifecycle. This'll run automatically once the widget is parsed.
     */
    postCreate: function () {
      this._initEventListeners();
    },
    /**
     * Start all of the click handlers we're going to need.
     *
     * @private
     */
    _initEventListeners: function () {
      if (this.resendBtn) {
        on(this.resendBtn, "click", this._handleResendBtnPress.bind(this));
      }
      if (this.reportAbuseBtn) {
        on(this.reportAbuseBtn, "click", this._handleReportAbuseBtnPress.bind(this));
      }
    },
    /**
     * Perform action based on button press.
     *
     * @private
     */
    _handleResendBtnPress: function () {
      this._toggleBtnLoadingState();
      // Post out to the server and async call functions based on how it went.
      var data = {
        "key": this.requestKey
      };
      var action = xhr.post(mUrl.toUrl("/partners/account-access-requests/resend-request-email"), {
        "handleAs": "json",
        "data": JSON.stringify(data)
      });
      action.then(this._onPostSuccessfulResponse.bind(this), this._onPostErrorResponse.bind(this));
    },
    _handleReportAbuseBtnPress: function () {
      // Post out to the server and async call functions based on how it went.
      var data = {
        "agency_request_key": this.requestKey,
        "agency_email": this.agencyEmail,
        "client_email": this.clientEmail
      };
      var action = xhr.post(mUrl.toUrl("/partners/contact-abuse/agency-abuse"), {
        "handleAs": "json",
        "data": JSON.stringify(data)
      });
      action.then(this._onPostSuccessfulReportAbuseResponse.bind(this), this._onPostErrorResponse.bind(this));
    },
    /**
     * We've successfully POSTed but need to handle the possible responses we get from the controller action.
     *
     * @private
     */
    _onPostSuccessfulResponse: function () {
      utils.toast("Success! Please check your email.");
      this._animateAndHide();
    },
    _onPostSuccessfulReportAbuseResponse: function () {
      utils.toast("Poof, the request is gone. We reported the abuse.");
      this._animateAndHide();
    },
    /**
     * Something when terribly wrong.
     *
     * @param {XMLHttpRequest} error Http
     * @private
     */
    _onPostErrorResponse: function (error) {
      utils.toast("Shoot! We failed on our end. Please try again");
      this._toggleBtnLoadingState();
      logger.info("agency-resend-email", "The resend button on the agency resend email feedback fails to resend", {
        "error": error
      });
    },
    /**
     * Something when terribly wrong.
     *
     * @param {XMLHttpRequest} error Http
     * @private
     */
    _onPostReportAbuseResponse: function (error) {
      utils.toast("Shoot! We failed on our end. Please try again");
      logger.info("agency-report-abuse", "The report abuse button on the agency resend email feedback fails to send report", {
        "error": error
      });
    },
    /**
     * Public method to toggle disabled state of the CTA
     *
     * @private
     */
    _toggleBtnLoadingState: function () {
      if (this._isButtonDisabled()) {
        this.resendBtn.classList.remove("button-loading");
        this.resendBtn.removeAttribute("disabled");
      } else {
        this.resendBtn.classList.add("button-loading");
        this.resendBtn.setAttribute("disabled", "disabled");
      }
    },
    /**
     * Fade out the action block. On complete of animation fire the callback if one is defined.
     *
     * @private
     */
    _animateAndHide: function () {
      Velocity(this.domNode, "fadeOut", {
        "easing": "easeInOut",
        "duration": 200
      });
    },
    /**
     * Do a quick check on the status of the button.
     *
     * @returns {boolean} Is the button currently "disabled"
     * @private
     */
    _isButtonDisabled: function () {
      return this.resendBtn.classList.contains("button-loading") && this.resendBtn.getAttribute("disabled") === "disabled";
    }
  });
});