/**
 * Google Mobile Slat Interface
 * @module /mojo/widgets/ads/mobile-checklist/GoogleSlat.js
 * @see /mojo/widgets/ads/mobile-checklist/_SlatMixin.js
 *
 * This module is intended as a "loader" of sorts for the different slats for this mobile interface
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"mojo/widgets/ads/mobile-checklist/_SlatMixin"], function (declare, _SlatMixin) {
  return declare([_SlatMixin], {
    campaignType: "google",
    enabled: false // Hopefully this doesn't have to stay disabled for long
  });
});