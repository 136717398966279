define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable", "dojo/text!./templates/_verify-domain.html", "dijit/registry", "mojo/url"], function (declare, lang, on, domClass, Renderable, tpl, registry, murl) {
  return declare([Renderable], {
    templateString: tpl,
    getData: function () {
      return {
        content: {}
      };
    },
    openVerifyModal: function () {
      this.verify = registry.byId(this.verifyDomainModal.id);
      var email = this.model.fromEmail;
      var campaignId = this.model.campaignId;
      var url = murl.toUrl("/account/verify-domain?step=add-new&domain=" + this.getDomain(email) + "&cid=" + campaignId + "&email=" + email);
      this.verify.setHref(url);
      this.verify.show();
    },
    getDomain: function (email) {
      var split = email.split("@");
      var domain = split[1];
      return domain;
    },
    closeVerifyModal: function () {
      this.verify.hide();
    },
    render: function () {
      this.inherited(arguments);
      /*
       *
       * to close the parent modal window -- following the functionality as in the closeAction function
       * in the VerifyDomainController
       *
       */
      window.onDomainVerifySuccess = this.closeVerifyModal.bind(this);
    }
  });
});