define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (lang) {
  // module:
  //		dojo/fx/easing

  var easingFuncs = {
    // summary:
    //		Collection of easing functions to use beyond the default
    //		`dojo._defaultEasing` function.
    // description:
    //		Easing functions are used to manipulate the iteration through
    //		an `dojo.Animation`s _Line. _Line being the properties of an Animation,
    //		and the easing function progresses through that Line determining
    //		how quickly (or slowly) it should go. Or more accurately: modify
    //		the value of the _Line based on the percentage of animation completed.
    //
    //		All functions follow a simple naming convention of "ease type" + "when".
    //		If the name of the function ends in Out, the easing described appears
    //		towards the end of the animation. "In" means during the beginning,
    //		and InOut means both ranges of the Animation will applied, both
    //		beginning and end.
    //
    //		One does not call the easing function directly, it must be passed to
    //		the `easing` property of an animation.
    // example:
    //	|	dojo.require("dojo.fx.easing");
    //	|	var anim = dojo.fadeOut({
    //	|		node: 'node',
    //	|		duration: 2000,
    //	|		//	note there is no ()
    //	|		easing: dojo.fx.easing.quadIn
    //	|	}).play();
    //

    linear: function ( /* Decimal? */n) {
      // summary:
      //		A linear easing function
      return n;
    },
    quadIn: function ( /* Decimal? */n) {
      return Math.pow(n, 2);
    },
    quadOut: function ( /* Decimal? */n) {
      return n * (n - 2) * -1;
    },
    quadInOut: function ( /* Decimal? */n) {
      n = n * 2;
      if (n < 1) {
        return Math.pow(n, 2) / 2;
      }
      return -1 * (--n * (n - 2) - 1) / 2;
    },
    cubicIn: function ( /* Decimal? */n) {
      return Math.pow(n, 3);
    },
    cubicOut: function ( /* Decimal? */n) {
      return Math.pow(n - 1, 3) + 1;
    },
    cubicInOut: function ( /* Decimal? */n) {
      n = n * 2;
      if (n < 1) {
        return Math.pow(n, 3) / 2;
      }
      n -= 2;
      return (Math.pow(n, 3) + 2) / 2;
    },
    quartIn: function ( /* Decimal? */n) {
      return Math.pow(n, 4);
    },
    quartOut: function ( /* Decimal? */n) {
      return -1 * (Math.pow(n - 1, 4) - 1);
    },
    quartInOut: function ( /* Decimal? */n) {
      n = n * 2;
      if (n < 1) {
        return Math.pow(n, 4) / 2;
      }
      n -= 2;
      return -1 / 2 * (Math.pow(n, 4) - 2);
    },
    quintIn: function ( /* Decimal? */n) {
      return Math.pow(n, 5);
    },
    quintOut: function ( /* Decimal? */n) {
      return Math.pow(n - 1, 5) + 1;
    },
    quintInOut: function ( /* Decimal? */n) {
      n = n * 2;
      if (n < 1) {
        return Math.pow(n, 5) / 2;
      }
      n -= 2;
      return (Math.pow(n, 5) + 2) / 2;
    },
    sineIn: function ( /* Decimal? */n) {
      return -1 * Math.cos(n * (Math.PI / 2)) + 1;
    },
    sineOut: function ( /* Decimal? */n) {
      return Math.sin(n * (Math.PI / 2));
    },
    sineInOut: function ( /* Decimal? */n) {
      return -1 * (Math.cos(Math.PI * n) - 1) / 2;
    },
    expoIn: function ( /* Decimal? */n) {
      return n == 0 ? 0 : Math.pow(2, 10 * (n - 1));
    },
    expoOut: function ( /* Decimal? */n) {
      return n == 1 ? 1 : -1 * Math.pow(2, -10 * n) + 1;
    },
    expoInOut: function ( /* Decimal? */n) {
      if (n == 0) {
        return 0;
      }
      if (n == 1) {
        return 1;
      }
      n = n * 2;
      if (n < 1) {
        return Math.pow(2, 10 * (n - 1)) / 2;
      }
      --n;
      return (-1 * Math.pow(2, -10 * n) + 2) / 2;
    },
    circIn: function ( /* Decimal? */n) {
      return -1 * (Math.sqrt(1 - Math.pow(n, 2)) - 1);
    },
    circOut: function ( /* Decimal? */n) {
      n = n - 1;
      return Math.sqrt(1 - Math.pow(n, 2));
    },
    circInOut: function ( /* Decimal? */n) {
      n = n * 2;
      if (n < 1) {
        return -1 / 2 * (Math.sqrt(1 - Math.pow(n, 2)) - 1);
      }
      n -= 2;
      return 1 / 2 * (Math.sqrt(1 - Math.pow(n, 2)) + 1);
    },
    backIn: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that starts away from the target,
      //		and quickly accelerates towards the end value.
      //
      //		Use caution when the easing will cause values to become
      //		negative as some properties cannot be set to negative values.
      var s = 1.70158;
      return Math.pow(n, 2) * ((s + 1) * n - s);
    },
    backOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that pops past the range briefly, and slowly comes back.
      // description:
      //		An easing function that pops past the range briefly, and slowly comes back.
      //
      //		Use caution when the easing will cause values to become negative as some
      //		properties cannot be set to negative values.

      n = n - 1;
      var s = 1.70158;
      return Math.pow(n, 2) * ((s + 1) * n + s) + 1;
    },
    backInOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function combining the effects of `backIn` and `backOut`
      // description:
      //		An easing function combining the effects of `backIn` and `backOut`.
      //		Use caution when the easing will cause values to become negative
      //		as some properties cannot be set to negative values.
      var s = 1.70158 * 1.525;
      n = n * 2;
      if (n < 1) {
        return Math.pow(n, 2) * ((s + 1) * n - s) / 2;
      }
      n -= 2;
      return (Math.pow(n, 2) * ((s + 1) * n + s) + 2) / 2;
    },
    elasticIn: function ( /* Decimal? */n) {
      // summary:
      //		An easing function the elastically snaps from the start value
      // description:
      //		An easing function the elastically snaps from the start value
      //
      //		Use caution when the elasticity will cause values to become negative
      //		as some properties cannot be set to negative values.
      if (n == 0 || n == 1) {
        return n;
      }
      var p = .3;
      var s = p / 4;
      n = n - 1;
      return -1 * Math.pow(2, 10 * n) * Math.sin((n - s) * (2 * Math.PI) / p);
    },
    elasticOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that elasticly snaps around the target value,
      //		near the end of the Animation
      // description:
      //		An easing function that elasticly snaps around the target value,
      //		near the end of the Animation
      //
      //		Use caution when the elasticity will cause values to become
      //		negative as some properties cannot be set to negative values.
      if (n == 0 || n == 1) {
        return n;
      }
      var p = .3;
      var s = p / 4;
      return Math.pow(2, -10 * n) * Math.sin((n - s) * (2 * Math.PI) / p) + 1;
    },
    elasticInOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that elasticly snaps around the value, near
      //		the beginning and end of the Animation.
      // description:
      //		An easing function that elasticly snaps around the value, near
      //		the beginning and end of the Animation.
      //
      //		Use caution when the elasticity will cause values to become
      //		negative as some properties cannot be set to negative values.
      if (n == 0) return 0;
      n = n * 2;
      if (n == 2) return 1;
      var p = .3 * 1.5;
      var s = p / 4;
      if (n < 1) {
        n -= 1;
        return -.5 * (Math.pow(2, 10 * n) * Math.sin((n - s) * (2 * Math.PI) / p));
      }
      n -= 1;
      return .5 * (Math.pow(2, -10 * n) * Math.sin((n - s) * (2 * Math.PI) / p)) + 1;
    },
    bounceIn: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that 'bounces' near the beginning of an Animation
      return 1 - easingFuncs.bounceOut(1 - n); // Decimal
    },
    bounceOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that 'bounces' near the end of an Animation
      var s = 7.5625;
      var p = 2.75;
      var l;
      if (n < 1 / p) {
        l = s * Math.pow(n, 2);
      } else if (n < 2 / p) {
        n -= 1.5 / p;
        l = s * Math.pow(n, 2) + .75;
      } else if (n < 2.5 / p) {
        n -= 2.25 / p;
        l = s * Math.pow(n, 2) + .9375;
      } else {
        n -= 2.625 / p;
        l = s * Math.pow(n, 2) + .984375;
      }
      return l;
    },
    bounceInOut: function ( /* Decimal? */n) {
      // summary:
      //		An easing function that 'bounces' at the beginning and end of the Animation
      if (n < 0.5) {
        return easingFuncs.bounceIn(n * 2) / 2;
      }
      return easingFuncs.bounceOut(n * 2 - 1) / 2 + 0.5; // Decimal
    }
  };
  lang.setObject("dojo.fx.easing", easingFuncs);
  return easingFuncs;
});