define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/content-manager/Api", "mojo/api/brand-assets", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/BrandLogoEmptyState.html"], function (declare, lang, on, api, brandAssetsApi, _WidgetBase, _TemplatedMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplStr,
    contentManager: null,
    postCreate: function () {
      this.uploadButton.innerText = this.translationTerms.content_studio_upload_logo_button;

      // Open local file browser on upload button click
      on(this.uploadButton, "click", lang.hitch(this, function () {
        this.logoFileBrowse.click();
      }));

      // Listen for changes to file browser so we can upload the file
      on(this.logoFileBrowse, "change", lang.hitch(this, function (e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files || files.length !== 1) {
          return;
        }
        this._uploadLogo(files[0]);
        this.logoFileBrowse.form.reset();
      }));
    },
    _uploadLogo: function (file) {
      this.contentManager.uploadFile(file, null, false).then(lang.hitch(this, function (response) {
        // Content manager handles error messaging
        if (response.error) {
          return;
        }

        // Promote successfully uploaded file to brand logo
        var contentOrigin = api.generateContentOriginFromContentItem(response);
        var displayUrl = response.getDisplayUrl();
        api.uploadFromUrl(api.SOURCE_BRAND, contentOrigin, displayUrl, api.CONFLICT_STRATEGY_OVERWRITE).then(lang.hitch(this, function (res) {
          brandAssetsApi.setBrandItem(res);
          // Force-refresh content pane to remove empty state
          this.contentManager.forceRenderFileList();
        }));
      }));
    }
  });
});