define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dijit/form/CheckBox", "dojo/text!./templates/Switch.html"], function (declare, debounce, CheckBox, tplStr) {
  return declare([CheckBox], {
    /* Widget Properties */
    templateString: tplStr,
    baseClass: "switchWrapper",
    transitionTime: 200,
    description: "",
    /**
     * Debounced function to call whenever change is triggered. Default to no-op, but bound to
     * onChangeAsync when provided
     */
    _onChangeAsync: function () {},
    onChange: function (isChecked) {
      this.inherited(arguments);
      this._onChangeAsync(isChecked);
    },
    postCreate: function () {
      this.inherited(arguments);
      if (this.onChangeAsync) {
        this._onChangeAsync = debounce(this.onChangeAsync, this.transitionTime);
      }
    }
  });
});