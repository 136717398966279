define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dojo/text!./templates/UrlImportDialog.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/event!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog"], function (declare, _Widget, _Templated, tpl, on, event, keys, lang, Deferred, Dialog) {
  return declare([_Widget, _Templated], {
    templateString: tpl,
    dialog: null,
    deferred: null,
    postCreate: function () {
      this.deferred = new Deferred();
      var importSignal = on(this.importButton, "click", lang.hitch(this, function (e) {
        event.stop(e);
        importSignal.remove();
        this.resolve();
      }));
      var enterSignal = on(this.importInput, "keyup", lang.hitch(this, function (e) {
        var charCode = e.charCode || e.keyCode;
        event.stop(e);
        if (charCode === keys.ENTER && this.importInput.value) {
          enterSignal.remove();
          this.resolve();
        }
        event.stop(e);
        if (charCode === keys.ESCAPE) {
          enterSignal.remove();
          this.cancel();
        }
      }));
      on(this.cancelLink, "click", lang.hitch(this, function (e) {
        event.stop(e);
        this.cancel();
      }));
    },
    execute: function () {
      this.resolve();
    },
    resolve: function () {
      var value = this.importInput.value;
      if (!value) {
        this.cancel();
        return;
      }
      this.deferred.resolve(value);
      this.dialog.hide();
    },
    cancel: function () {
      this.deferred.cancel();
      this.dialog.hide();
    },
    show: function () {
      this.dialog = new Dialog({
        "title": "Import URL",
        "content": this
      });
      this.dialog.startup();
      this.dialog.show();
      this.importInput.focus();
    }
  });
});