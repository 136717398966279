/**
 * A widget that represents a select input for our segmentation UI.
 * Uses a native select box, but adds additional functions to make it easier to use for segmentation.
 * Supports group which adds an optgroup to the select box.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_SegmentInputMixin", "mojo/user", "mojo/lib/flags", "mojo/lib/logger"], function (declare, _WidgetBase, domConstruct, domAttr, lang, array, _SegmentInputMixin, user, flags, logger) {
  return declare([_WidgetBase, _SegmentInputMixin], {
    value: null,
    options: null,
    buildRendering: function () {
      var value = this.value;
      this.domNode = domConstruct.create("select");
      this.replaceOptions(this.options);
      if (value) {
        this.domNode.value = value;
      }
      this.set("value", this.domNode.value);
      this.on("change", lang.hitch(this, "onValueChange"));
    },
    getDisplayHTML: function () {
      var selectedFieldOption = this.domNode.options[this.domNode.selectedIndex];
      if (selectedFieldOption) {
        return selectedFieldOption.innerHTML;
      }
      return "(Invalid value)";
    },
    getValue: function () {
      return this.value;
    },
    setValue: function (value) {
      this.set("value", value);
    },
    /**
     * Provide a means to replace all the options from the select box.
     * @param options
     */
    replaceOptions: function (options) {
      this.options = options;
      domConstruct.empty(this.domNode);
      var optGroups = {};
      array.forEach(this.options, lang.hitch(this, function (option) {
        var optionDom = domConstruct.create("option", {
          "value": option.value,
          "innerHTML": option.label
        });
        if (option.group) {
          if (!optGroups[option.group]) {
            optGroups[option.group] = domConstruct.create("optgroup", {
              "label": option.group
            });
          }
          domConstruct.place(optionDom, optGroups[option.group]);
        } else {
          domConstruct.place(optionDom, this.domNode);
        }
      }));
      for (var group in optGroups) {
        domConstruct.place(optGroups[group], this.domNode);
      }
      this.set("value", this.domNode.value);
    },
    // Publish a Segment Engaged event to the Events Pipeline
    _logToEventsPipeline: function () {
      var sharedEventProperties = {
        org: "sbseg",
        purpose: "prod",
        scope: "mc",
        initiative_name: "audience_organization",
        object: "segment",
        object_detail: "create_segment",
        action: "engaged",
        ui_object: "drop_down",
        ui_object_detail: "segment_conditions",
        ui_action: "clicked",
        ui_access_point: "form_segment",
        segmentation_method: "advanced",
        condition_field: null
      };

      // Add condition_field value for only the first dropdown ("field").
      // We don't want to track this value for "op", "value" or "extra".
      if (this.domNode.parentElement.dataset.dojoAttachPoint === "fieldSelectContainer") {
        sharedEventProperties.condition_field = this.getValue();
      }
      var contactTableProperties = Object.assign({
        scope_area: "contacts",
        screen: "lists/members"
      }, sharedEventProperties);
      var emailChecklistProperties = Object.assign({
        scope_area: "bulk_campaigns",
        screen: "campaigns/edit"
      }, sharedEventProperties);

      // If event is from the Email Checklist page
      if (window.location.pathname.startsWith("/i/campaigns/edit")) {
        // Grab campaign_id from url param (ie: "/campaigns/edit?id=5")
        var campaign_id = parseInt(new URLSearchParams(window.location.search).get("id"), 10);
        // Add campaign_id to email checklist properties
        emailChecklistProperties.campaign_id = campaign_id;
        logger.eventsPipeline.publish("segment:engaged", emailChecklistProperties);
        // If event is from the Contact Table page
      } else if (window.location.pathname.startsWith("/i/lists/members")) {
        logger.eventsPipeline.publish("segment:engaged", contactTableProperties);
      }
    },
    onValueChange: function () {
      this._updateValue();
      this._logToEventsPipeline();
    },
    _updateValue: function () {
      this.set("value", this.domNode.value);
    },
    _setOptionsAttr: function ( /*Array*/options) {
      this._set('options', options);
    }
  });
});