define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", // declare
"dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/touch", "./_ListBase"], function (declare, on, touch, _ListBase) {
  // module:
  //		dijit/form/_ListMouseMixin

  return declare("dijit.form._ListMouseMixin", _ListBase, {
    // summary:
    //		A mixin to handle mouse or touch events for a focus-less menu
    //		Abstract methods that must be defined externally:
    //
    //		- onClick: item was chosen (mousedown somewhere on the menu and mouseup somewhere on the menu)
    // tags:
    //		private

    postCreate: function () {
      this.inherited(arguments);

      // Add flag to use normalized click handling from dojo/touch
      this.domNode.dojoClick = true;
      this._listConnect("click", "_onClick");
      this._listConnect("mousedown", "_onMouseDown");
      this._listConnect("mouseup", "_onMouseUp");
      this._listConnect("mouseover", "_onMouseOver");
      this._listConnect("mouseout", "_onMouseOut");
    },
    _onClick: function ( /*Event*/evt, /*DomNode*/target) {
      this._setSelectedAttr(target, false);
      if (this._deferredClick) {
        this._deferredClick.remove();
      }
      this._deferredClick = this.defer(function () {
        this._deferredClick = null;
        this.onClick(target);
      });
    },
    _onMouseDown: function ( /*Event*/evt, /*DomNode*/target) {
      if (this._hoveredNode) {
        this.onUnhover(this._hoveredNode);
        this._hoveredNode = null;
      }
      this._isDragging = true;
      this._setSelectedAttr(target, false);
    },
    _onMouseUp: function ( /*Event*/evt, /*DomNode*/target) {
      this._isDragging = false;
      var selectedNode = this.selected;
      var hoveredNode = this._hoveredNode;
      if (selectedNode && target == selectedNode) {
        this.defer(function () {
          this._onClick(evt, selectedNode);
        });
      } else if (hoveredNode) {
        // drag to select
        this.defer(function () {
          this._onClick(evt, hoveredNode);
        });
      }
    },
    _onMouseOut: function ( /*Event*/evt, /*DomNode*/target) {
      if (this._hoveredNode) {
        this.onUnhover(this._hoveredNode);
        this._hoveredNode = null;
      }
      if (this._isDragging) {
        this._cancelDrag = new Date().getTime() + 1000; // cancel in 1 second if no _onMouseOver fires
      }
    },
    _onMouseOver: function ( /*Event*/evt, /*DomNode*/target) {
      if (this._cancelDrag) {
        var time = new Date().getTime();
        if (time > this._cancelDrag) {
          this._isDragging = false;
        }
        this._cancelDrag = null;
      }
      this._hoveredNode = target;
      this.onHover(target);
      if (this._isDragging) {
        this._setSelectedAttr(target, false);
      }
    }
  });
});