define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo/text!./templates/ConnectionSuccess.html", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user", "mojo/lib/flags", "mojo/widgets/Dialog", "mojo/url"], function (declare, on, domClass, _WidgetBase, _TemplatedMixin, MCJsTermsDialogTemplate, xhr, user, flags, Dialog, mUrl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    ///////////////////////////////////////////////////////////////////
    // Setup
    ///////////////////////////////////////////////////////////////////
    templateString: MCJsTermsDialogTemplate,
    dialog: null,
    /**
    * Pass in the template string and show the modal.
    **/
    show: function () {
      this.dialog = new Dialog({
        "closable": true,
        "class": "dijitDialog--fullScreen@phone",
        "content": this
      });

      //adds some classes to the modal to help it match the design
      domClass.add(this.dialog.titleBar, "!background--none");
      domClass.add(this.dialog.containerNode, "max-height-inherit");
      on(this.acceptButton, "click", this._accept.bind(this));
      this.dialog.startup();
      this.dialog.show();

      //enable the new badge on the clients tab explained in this modal.
      window.localStorage.setItem("newClientsTab", true);
    },
    /**
    * Called when the user clicks the ok button. This closes the modal.
    **/
    _accept: function () {
      this.dialog.hide();

      //reload the page so the new tab shows
      top.location = '/account/details/';
    }
  });
});