define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/url", "mojo/widgets/ContentManagerPane", "mojo/widgets/ecommerce/ProductPicker", "mojo/widgets/ecommerce/ProductManager", "mojo/widgets/pages/PaymentGalleryImages", "./_Block", "./_ButtonMixin", "./_ImageMixin"], function (declare, lang, Deferred, context, flags, mUrl, ContentManagerPane, ProductPicker, ProductManager, PaymentGalleryImages, _Block, _ButtonMixin, _ImageMixin) {
  var MAX_GALLERY_IMAGES = 5;
  var MAX_PRODUCTS = 4;
  var DEMO_PRODUCTS = [{
    id: 0,
    title: "T-shirt",
    price: "$1.00",
    description: "To get started, choose a product to sell from your store. Then, describe what makes your product unique or gift-worthy. We'll automatically add a payment form so visitors can buy directly from your website.",
    descriptionEnabled: true,
    image: {
      src: "https://cdn-images.mailchimp.com/product/blocks/empty-product.svg"
    }
  }];
  var Product = declare(null, {
    id: null,
    buttonLinkType: "product",
    title: "",
    imageUrl: "",
    isDemo: false,
    buttonEnabled: true,
    buttonText: "Buy Now",
    descriptionEnabled: false,
    description: "",
    image: {},
    constructor: function (params) {
      PaymentGalleryImages(); // no-op get the listeners, the define above already grabs the listeners
      lang.mixin(this, params);
      if (this.imageUrl) {
        this.imageUrl = mUrl.addProxy(this.imageUrl);
        this.image.src = this.imageUrl;
      }
      this.isDemo = this.id === 0;
    },
    getButtonLinkUrl: function () {
      var url;
      if (this.buttonLinkType && this.buttonLinkType === "cart") {
        url = this.addToCartUrl;
      }
      if (!url) {
        url = this.productUrl;
      }
      return url;
    },
    hasValidCartUrl: function () {
      return this.addToCartUrl && this.addToCartUrl !== this.productUrl;
    }
  });
  return declare([_Block, _ButtonMixin, _ImageMixin], {
    type: "payment",
    _name: "Payment",
    _editingTypes: ["page"],
    _blockEditorControls: ["sort", "edit", "delete"],
    _renderMergeTags: true,
    constructor: function (model) {
      this.styles = [{
        "name": "Product title",
        "selector": ".mcnProductTitle",
        "props": {
          "color": {
            "value": "#202020"
          },
          "font-family": {
            "value": "Lora"
          },
          "font-size": {
            "value": "30px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": "100%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }, {
        "name": "Product price",
        "selector": ".mcnProductPrice",
        "props": {
          "color": {
            "value": ""
          },
          "font-family": {
            "value": "Lora"
          },
          "font-size": {
            "value": "24px"
          },
          "font-style": {
            "value": "normal"
          },
          "font-weight": {
            "value": "bold"
          },
          "line-height": {
            "value": "200%"
          },
          "text-align": {
            "value": "left"
          }
        }
      }].concat(this._getImageStyles());
      this.styles = this.styles.concat(this._getButtonStyles(model._campaignRenderingType));

      // vertical || horizontal
      this.layout = "vertical";
      this.products = DEMO_PRODUCTS;

      // global settings for all buttons.
      this.buttonWidth = "100%";
      this.buttonAlign = "center";

      // Allow Gallery Images - initial values
      this.galleryImages = [];
      this.galleryImagesEnabled = false;
    },
    /**
     * Override the base behavior to convert the list of products into
     * the model defined at the top of this module.
     */
    postscript: function () {
      this.inherited(arguments);
      this.products = this.products.map(function (meta) {
        return new Product(meta);
      });

      // Allow Gallery Images - user returning to the saved version to edit
      this.setGalleryImages();
    },
    useDemoProduct: function () {
      this.products = DEMO_PRODUCTS.map(function (meta) {
        return new Product(meta);
      });
    },
    setGalleryImages: function (action) {
      // Reassign the main image as the first image in the gallery
      if (this.products.length && this.galleryImages.length <= 1 && this.products[0].image.src || action === "addProduct") {
        var product = this.products[0];
        this.galleryImages = [{
          url: product.image.src,
          contentId: product.id,
          alt: product.name || ""
        }];
      }
    },
    addProduct: function () {
      var self = this;
      return ProductPicker.chooseProduct().then(function (products) {
        products.forEach(function (product) {
          // Clear out the demo product if necessary
          if (self.products.length === 1 && self.products[0].id === 0) {
            self.products = [];
          }
          self.products.push(self._createNewProduct(product));
          // set here is used to fire change events
          self.updateProducts();
        });
        self.save();
      });
    },
    addProductFromStore: function (store, platform) {
      var self = this;
      return ProductManager.selectProduct({
        storeId: store,
        platform: platform
      }).then(function (product) {
        // only allow a single product to be added to the block
        this.products = [];
        this.products.push(this._createNewProductFromProductManager(product));
        // set here is used to fire change events
        this.updateProducts();
        // Allow Gallery Images
        this.setGalleryImages("addProduct");
        this.save();
        return this.products;
      }.bind(this), function () {
        // promise was rejected - which means modal was closed and products is staying the same
        // what to do on reject? return the existing products
        return this.products;
      }.bind(this));
    },
    /**
     * Replace the product in the given index. It resets all the attributes with the exception of
     * description and the button text.
     *
     * @param {number} index - the index of the product we want to change
     * @returns {dojo/Promise} - a promise that is successful when the user chosen a product
     */
    replaceProduct: function (index) {
      var self = this;
      return ProductPicker.chooseProduct().then(function (products) {
        // Replacement is always one product
        var meta = products[0];
        if (meta) {
          var oldProduct = self.products[index];
          var newProduct = self._createNewProduct(meta);
          if (oldProduct) {
            newProduct.description = oldProduct.description;
            newProduct.buttonText = oldProduct.buttonText;
          }
          self.products[index] = newProduct;
          self.updateProducts();
          self.save();
        }
      });
    },
    removeProductByIndex: function (index) {
      if (this.products[index]) {
        this.products.splice(index, 1);
        this.updateProducts();
        this.save();
      }
    },
    hasMaxGalleryImages: function () {
      return this.galleryImages.length >= MAX_GALLERY_IMAGES;
    },
    /**
     * Forces a refresh of products with itself to fire the onChange events
     */
    updateProducts: function () {
      this.set("products", this.products);
    },
    /**
     * Takes the product meta from the ProductPicker and correctly instantiate a Product defined in this module
     *
     * @param {Object} productMeta - a product meta that is returned by ProductPicker
     * @returns {Product} a new instance of Product
     * @private
     */
    _createNewProduct: function (productMeta) {
      return new Product({
        "id": productMeta.product_id,
        "title": productMeta.title,
        "price": productMeta.price,
        "image": {
          src: productMeta.image_url || ""
        },
        "description": productMeta.description || "",
        "tax": "0.0",
        "shipping": "0.00"
      });
    },
    _createNewProductFromProductManager: function (productMeta) {
      // TODO: description doesn't exist on teh content item from backend
      return new Product({
        "id": productMeta.id,
        "title": productMeta.title,
        "price": productMeta.price,
        "image": {
          src: productMeta.product_image_url || ""
        },
        "description": productMeta.description || "",
        "tax": "0.0",
        "shipping": "0.00"
      });
    }
  });
});