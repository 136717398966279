define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "mojo/utils/I18nTranslation", "dojo/text!./templates/AllCampaignsZeroStateFree.html"], function (declare, on, _WidgetBase, _TemplatedMixin, I18nTranslation, templateString) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: templateString,
    zeroStateHeading: "Reach your audience in minutes",
    zeroStateSubHeading: "Once you create an email campaign, it will show up here.",
    ctaTextCampaign: "Create a campaign",
    ctaLinkCampaign: null,
    _navigateCtaLink: function (event) {
      event.preventDefault();
      window.ga("send", "event", "campaigns", "cta", "button", {
        "dimension53": event.target.innerText,
        "dimension54": "#/create-campaign",
        "dimension55": "campaigns list",
        "dimension56": "navigate"
      });
      window.location.href = "#/create-campaign";
    },
    postMixInProperties: function () {
      I18nTranslation.initialize().then(function (translations) {
        if (I18nTranslation.isTranslationObjectEmpty()) {
          return;
        }
        this.zeroStateHeading = translations.campaigns_all_zero_state_header;
        this.zeroStateSubHeading = translations.campaigns_all_zero_description;
        this.ctaTextCampaign = translations.campaigns_all_zero_cta_button;
      }.bind(this));
    },
    postCreate: function () {
      on(this.ctaLinkCampaign, "click", this._navigateCtaLink);
    }
  });
});