define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Renderer", "./ButtonBlock", "mojo/neapolitan/models/Link", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/ProductEditor/vertical.html", "dojo/text!../editors/blocks/templates/ProductEditor/horizontal.html", "dojo/text!../editors/blocks/templates/ProductEditor/_product-content.html", "dojox/dtl", "dojox/dtl/Context", "dojox/dtl/tag/logic", "dojox/dtl/tag/loop"], function (declare, _Renderer, ButtonRenderer, Link, lang, domConstruct, verticalTpl, horizontalTpl, contentTpl, dtl, dtlContext) {
  return declare([_Renderer], {
    verticalTemplate: null,
    horizontalTemplate: null,
    // Template that puts all the product attributes in rows
    contentTemplate: null,
    // Keeps a reference to a button renderer which is the same renderer that handles a button block
    buttonRenderer: null,
    constructor: function () {
      this.inherited(arguments);
      this._loadTemplates();
      this.buttonRenderer = new ButtonRenderer();
    },
    /**
     * This block overrides the default getContent from the base to do its own rendering logic.
     *
     * @param {Object} tplModel - see _Renderer for more info
     *
     * @returns {*|HTMLElement} - a dom element of this block
     */
    getContent: function (tplModel) {
      var outputModel = this.getOutputModel(lang.clone(tplModel));
      outputModel = lang.mixin({
        outerPadding: this.outerPadding,
        innerPadding: this.innerPadding
      }, outputModel);
      var template = tplModel.layout === "vertical" ? this.verticalTemplate : this.horizontalTemplate;
      var dom = domConstruct.toDom(lang.trim(template.render(new dtlContext(outputModel))));
      return this._inlineStyles(dom, tplModel.styles);
    },
    /**
     * Loads up all the templates
     * @private
     */
    _loadTemplates: function () {
      this.verticalTemplate = new window.dojox.dtl.Template(verticalTpl);
      this.horizontalTemplate = new window.dojox.dtl.Template(horizontalTpl);
      this.contentTemplate = new window.dojox.dtl.Template(contentTpl);
    },
    /**
     * The column width changes based on number of products and which layout a user have chosen
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     *
     * @returns {Number} - the total width of a column
     * @private
     */
    _calculateColumnWidth: function (meta) {
      var columnWidth = meta.containerWidth;
      switch (meta.layout) {
        case "vertical":
          if (meta.products.length > 1) {
            columnWidth = columnWidth / meta.products.length;
          }
          break;
        case "horizontal":
          columnWidth = columnWidth / 2;
          break;
        default:
          throw new Error("No layout defined");
      }
      return columnWidth;
    },
    /**
     * Use Button block renderer to render the button itself.
     *
     * @param {Object} meta - the meta that is passed to every renderers' getOutputModel()
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @returns {string} html content of how a button should look
     * @private
     */
    _buttonMarkup: function (meta, product) {
      var buttonMeta = {
        "buttonText": product.buttonText,
        "buttonWidth": meta.buttonWidth,
        "align": meta.buttonAlign,
        "link": new Link(product.getButtonLinkUrl(), product.title)
      };
      return this.buttonRenderer.getMarkup(buttonMeta);
    },
    /**
     * Return the markup of the content of each product. This includes name, desc, price and button
     *
     * @param {Object} meta - ref to the output model's meta.
     * @param {Object} product - ref to model specified in the block. See mojo/neapolitan/models/blocks/Product
     *
     * @return {string} the markup for the inner content of the block.
     *
     * @private
     */
    _contentMarkup: function (meta, product) {
      var rows = [];
      if (product.title) {
        rows.push({
          "html": product.title + "<br />",
          "className": "mcnProductTitle",
          "styles": "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.price) {
        rows.push({
          "html": product.price + "<br />",
          "className": "mcnProductPrice",
          "styles": "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.descriptionEnabled && product.description) {
        rows.push({
          "html": product.description + "<br/>",
          "className": "mcnProductDescription",
          "styles": "padding-top:9px; padding-left:18px; padding-right:18px;"
        });
      }
      if (product.buttonEnabled) {
        rows.push({
          "html": this._buttonMarkup(meta, product),
          "className": "mcnProductButton",
          "styles": "padding-top:18px"
        });
      }
      return rows.length ? lang.trim(this.contentTemplate.render(new dtlContext({
        "rows": rows
      }))) : "";
    },
    getOutputModel: function (meta) {
      var columnWidth = this._calculateColumnWidth(meta);
      var imageWidth = columnWidth - 36;
      var self = this;
      var productsData = [];
      meta.products.forEach(function (product) {
        productsData.push({
          "product": product,
          "body": self._contentMarkup(meta, product)
        });
      });
      return {
        productsData: productsData,
        columnWidth: columnWidth,
        imageWidth: imageWidth,
        totalPadding: this.outerPadding * 2
      };
    }
  });
});