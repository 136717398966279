/**
 * Mimetype module for checking mimetype strings
 *
 * This module is a bag of static functions which perform basic string checks. To get a mimetype string, access the
 * `type` attribute on a File object from browser file upload
 */
define([], function () {
  var mimetype = {
    /**
     * Check if this mimetype is an image
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is an image
     */
    isImage: function (type) {
      return this._startsWith("image", type);
    },
    /**
     * Check if this mimetype is an audio
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is an audio
     */
    isAudio: function (type) {
      return this._startsWith("audio", type);
    },
    /**
     * Check if this mimetype is a video
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is a video
     */
    isVideo: function (type) {
      return this._startsWith("video", type);
    },
    /**
     * Check if this mimetype is plain text
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is plain text
     */
    isPlainText: function (type) {
      return this._startsWith("text", type);
    },
    /**
     * Check if this mimetype is a document
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is a document
     */
    isDocument: function (type) {
      var validTypes = ["application/msword", "application/rtf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.oasis.opendocument.text", "application/pdf"];
      return validTypes.indexOf(type) != -1;
    },
    /**
     * Check if this mimetype is a spreadsheet
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is a spreadsheet
     */
    isSpreadsheet: function (type) {
      var validTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.oasis.opendocument.spreadsheet"];
      return validTypes.indexOf(type) != -1;
    },
    /**
     * Check if this mimetype is a presentation
     *
     * @param {string} type - Mimetype to check
     * @return {boolean} True if the mimetype is a presentation
     */
    isPresentation: function (type) {
      var validTypes = ["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.oasis.opendocument.presentation"];
      return validTypes.indexOf(type) != -1;
    },
    /**
     * Returns true if the haystack begins with the needle
     *
     * @param {string} needle - String to check if its the beginning
     * @param {string} haystack - String to check the beginning of
     * @return {boolean} True if the haystack begins witht the needle
     */
    _startsWith: function (needle, haystack) {
      return haystack.substr(0, needle.length) == needle;
    }
  };
  return mimetype;
});