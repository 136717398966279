define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Part", "dijit/_Templated", "dojo/text!./templates/displayToggle.html", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/user"], function (declare, _Part, _Templated, tpl, on, lang, domClass, user) {
  return declare([_Part, _Templated], {
    templateString: tpl,
    displayGrid: user.settings.fileManagerShowGrid,
    init: function () {
      on(this.grid, "click", lang.hitch(this, function () {
        this._showGrid(true);
      }));
      on(this.list, "click", lang.hitch(this, function () {
        this._showGrid(false);
      }));
      this._showGrid(this.displayGrid);
    },
    /**
     * switchToGrid will toggle the grid/list view of a List
     * @param {Boolean} showGrid - true for grid, false for list
     */
    _showGrid: function (showGrid) {
      this.displayGrid = showGrid;
      user.saveSetting("filemanager_show_grid", showGrid);
      var parent = this.target.domNode.parentNode;
      this._toggleGridClasses(showGrid, parent);
    },
    /**
     *
     * @param {Boolean} showGrid decided to show grid(true) or list(false)
     * @param {Element} parent is the parent domElement to add/remove grid-active class
     */
    _toggleGridClasses: function (showGrid, parent) {
      if (showGrid) {
        domClass.add(parent, "grid-active");
        domClass.replace(this.grid, "v-buttonGroupBordered--active", "depressed");
        domClass.replace(this.list, "depressed", "v-buttonGroupBordered--active");
      } else {
        domClass.remove(parent, "grid-active");
        domClass.replace(this.list, "v-buttonGroupBordered--active", "depressed");
        domClass.replace(this.grid, "depressed", "v-buttonGroupBordered--active");
      }
    },
    onChange: function (value) {}
  });
});