define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_AttachMixin", "dijit/_FocusMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/keys!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/mouse!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _Widget, _AttachMixin, _FocusMixin, on, keys, mouse, domAttr, domClass, domStyle, domConstruct) {
  return declare([_Widget, _AttachMixin], {
    "enableMouseEvents": false,
    "tooltipArrow": "v-tooltipTarget--",
    "tooltipClass": "c-tooltip--",
    "tooltipType": "default",
    setupEscEvent: function handleEsc() {
      var self = this;
      on(this.domNode, "keyup", function (e) {
        e.stopPropagation();
        var charCode = e.charCode || e.keyCode;
        if (charCode === keys.ESCAPE) {
          self.domNode.blur();
        }
      });
    },
    setupMouseEvents: function handleMouse() {
      var self = this;
      on(this.domNode, mouse.enter, function () {
        self.domNode.focus();
      });
      on(this.domNode, mouse.leave, function () {
        self.domNode.blur();
      });
    },
    showTooltip: function () {
      domStyle.set(this.tooltip, "display", "block");
      domClass.add(this.domNode, this.tooltipArrow + this.tooltipType);
    },
    hideTooltip: function () {
      domStyle.set(this.tooltip, "display", "none");
      domClass.remove(this.domNode, this.tooltipArrow + this.tooltipType);
    },
    _onFocus: function () {
      this.showTooltip();
    },
    _onBlur: function () {
      this.hideTooltip();
    },
    postCreate: function () {
      this.tooltip.innerHTML = domAttr.get(this.domNode, "data-tooltip-text") || "";

      // Accessibility props
      domAttr.set(this.domNode, "tabindex", "0");
      domAttr.set(this.domNode, "aria-describedby", this.tooltip.innerHTML);

      // Set the appropriate classes for default/mini tooltips
      domClass.add(this.tooltip, this.tooltipClass + this.tooltipType);
      if (this.tooltipType === "default") {
        // Change the cursor to a question mark
        domStyle.set(this.domNode, "cursor", "help");

        // Replace trigger text with a span
        // to allow a dashed border only under the text
        domConstruct.place("<span data-dojo-attach-point='tooltipTriggerText'>" + this.tooltipTriggerText.innerHTML + "</span>", this.tooltipTriggerText, "replace");
      }

      // Set up event handlers
      this.setupEscEvent();
      if (this.enableMouseEvents) {
        this.setupMouseEvents();
      }
    }
  });
});