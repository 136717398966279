define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/views/_base", "mojo/user", "mojo/lib/flags", "mojo/widgets/FileManager", "mojo/widgets/reverse-bridges/InitialChoiceModalAlternateBridge"], function (declare, lang, domStyle, on, query, topic, registry, base, user, flags, FileManager, InitialChoiceModal) {
  return declare([base], {
    onReady: function () {
      if (this.$el.openFileManager) {
        on(this.$el.openFileManager, "click", lang.hitch(this, "_showFileManager"));
      }
      if (this.$el.createTemplate) {
        on(this.$el.createTemplate, "click", lang.hitch(this, "_showEditorSelectionModal"));
      }
    },
    _showEditorSelectionModal: function () {
      var showEditorSelectionModal = this.emailEditorPreference && this.emailEditorPreference.initial_choice_modal;
      if (showEditorSelectionModal) {
        var editorSelectionModalExperiment = new InitialChoiceModal({
          isTemplateEditor: true
        });
        editorSelectionModalExperiment.openModal();
      }
    },
    _showFileManager: function () {
      if (!FileManager.isShown()) {
        FileManager.show({
          showCreativeAssistant: true,
          actions: "copy, preview, edit, rename, delete"
        });
      }
    }
  });
});