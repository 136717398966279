define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "./_WithLabelMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, _WidgetBase, _TemplatedMixin, _WithLabelMixin, lang, domConstruct, query, on) {
  var INPUT_VALUES = {
    BOLD: "bold",
    ITALIC: "italic",
    UNDERLINE: "underline",
    STRIKETHROUGH: "strikethrough"
  };
  return declare([_WidgetBase, _TemplatedMixin, _WithLabelMixin], {
    label: "Font Style",
    templateString: "<div class=\"mojoFontStyle flex\"></div>",
    _checkboxTpl: "<input type=\"checkbox\" class=\"v-visuallyHidden v-buttonGroupControl\" id=\"{id}\" value=\"{value}\">" + "<label for=\"{id}\" class=\"v-buttonGroupLabel\"><span class=\"freddicon {icon}\"></span></label>",
    selector: null,
    fontStyle: null,
    fontWeight: null,
    textDecoration: null,
    properties: null,
    _inputs: null,
    postMixInProperties: function () {
      if (!this.properties) {
        this.properties = {};
      }
      this._inputs = {};
    },
    postCreate: function () {
      this.inherited(arguments);
      if (this.properties.hasOwnProperty("font-weight")) {
        this._addInput(INPUT_VALUES.BOLD, "text-bold");
      }
      if (this.properties.hasOwnProperty("font-style")) {
        this._addInput(INPUT_VALUES.ITALIC, "text-italics");
      }
      if (this.properties.hasOwnProperty("text-decoration")) {
        this._addInput(INPUT_VALUES.UNDERLINE, "text-underline");
        this._addInput(INPUT_VALUES.STRIKETHROUGH, "text-strikethrough");
      }

      // Add a reference to the checkboxes added to this widget
      query("[type=checkbox]", this.domNode).forEach(lang.hitch(this, function (checkbox) {
        this._inputs[checkbox.value] = checkbox;
      }));

      // Pre-populate all the inputs based on the passed in properties values
      for (var property in this.properties) {
        if (this.properties.hasOwnProperty(property)) {
          this.updateValue(property, this.properties[property]);
        }
      }
      this.own(on(this.domNode, "[type=checkbox]:change", lang.hitch(this, "_updateProperties")));

      // Watch for any changes done to the the widget's attribute that we're using to store the css value
      this.watch(lang.hitch(this, function (attr, oldVal, newValue) {
        var cssProp = null;
        switch (attr) {
          case "fontWeight":
            cssProp = "font-weight";
            break;
          case "fontStyle":
            cssProp = "font-style";
            break;
          case "textDecoration":
            cssProp = "text-decoration";
            break;
          default:
          // Do nothing for other attributes
        }
        if (cssProp) {
          this.onChange(cssProp, newValue);
        }
      }));
    },
    /**
     * Given a property and the value, correctly update the checkboxes
     *
     * @param {string} property - css property
     * @param {string} value - value of the css property
     */
    updateValue: function (property, value) {
      switch (property) {
        case "font-weight":
          this._inputs[INPUT_VALUES.BOLD].checked = value && value.toLowerCase() === "bold";
          break;
        case "font-style":
          this._inputs[INPUT_VALUES.ITALIC].checked = value && value.toLowerCase() === "italic";
          break;
        case "text-decoration":
          var lcVal = "";
          if (typeof value === "string") {
            lcVal = value.toLowerCase();
          }
          var hasStrikethrough = lcVal.indexOf("line-through") >= 0;
          var hasUnderline = lcVal.indexOf("underline") >= 0;
          this._inputs[INPUT_VALUES.STRIKETHROUGH].checked = hasStrikethrough;
          this._inputs[INPUT_VALUES.UNDERLINE].checked = hasUnderline;
          break;
        default:
        // For unknown attributes, do nothing.
      }
    },
    /**
     * Helper to add the checkbox input
     *
     * @param {string} value - the value to use on the input to identify the checkbox being clicked on
     * @param {string} iconClass - the corresponding icon class from freddicon
     * @private
     */
    _addInput: function (value, iconClass) {
      var boxId = this.id + "_" + value;
      domConstruct.place(lang.replace(this._checkboxTpl, {
        "id": boxId,
        "value": value,
        "icon": iconClass
      }), this.domNode);
    },
    _updateProperties: function () {
      var boldCheckbox = this._inputs[INPUT_VALUES.BOLD];
      var italicCheckbox = this._inputs[INPUT_VALUES.ITALIC];
      var underlineCheckbox = this._inputs[INPUT_VALUES.UNDERLINE];
      var strikethroughCheckbox = this._inputs[INPUT_VALUES.STRIKETHROUGH];
      if (boldCheckbox) {
        this.set("fontWeight", boldCheckbox.checked ? "bold" : "normal");
      }
      if (italicCheckbox) {
        this.set("fontStyle", italicCheckbox.checked ? "italic" : "normal");
      }
      if (underlineCheckbox && strikethroughCheckbox) {
        var decoration = [];
        if (underlineCheckbox.checked) {
          decoration.push("underline");
        }
        if (strikethroughCheckbox.checked) {
          decoration.push("line-through");
        }
        this.set("textDecoration", decoration.length ? decoration.join(" ") : "none");
      }
    },
    /**
     * Empty callback handler
     *
     * @param {string} property - css property
     * @param {string} value - value that the css property changed to
     */
    onChange: function (property, value) {}
  });
});