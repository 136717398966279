define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!../templates/activity-feed/activity-item-conversation.html"], function (declare, lang, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, activityTplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    campaignConversationUrl: null,
    /**
     * Initialize data
     */
    postMixInProperties: function () {
      this.campaignConversationUrl = mUrl.toUrl("/reports/conversations/view", {
        "id": this.data.campaign_id,
        "co_id": this.data.thread_id,
        "source": "profile"
      });
    }
  });
});