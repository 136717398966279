define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dijit/registry", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, base, registry, lang, mUrl) {
  return declare([base], {
    campaignId: null,
    // Passed from controller

    onInit: function () {},
    onReady: function () {
      var ordersList = dijit.byId("orders-list");
      var storeSelect = dijit.byId("ecommerce-stores");
      var self = this;
      storeSelect.on("change", function () {
        var data = this.get("value").split("-");
        ordersList.set("url", mUrl.toUrl('/reports/ecommerce/order-list', {
          id: self.campaignId,
          store_id: data[0],
          version: data[1]
        }));
      });
    }
  });
});