define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dijit/_WidgetBase", "dijit/form/Select", "./_WithLabelMixin"], function (declare, lang, domClass, domConstruct, on, string, _WidgetBase, Select, _WithLabelMixin) {
  return declare([_WidgetBase, _WithLabelMixin], {
    value: null,
    initialized: false,
    label: "Page Width",
    postCreate: function () {
      this.isOptional = true;
      this.inherited(arguments);
      this.originalValue = this.value;
      var pageWidth = this.value;
      var options = [{
        label: "780px",
        value: "780px"
      }, {
        label: "960px",
        value: "960px"
      }, {
        label: "1224px",
        value: "1224px"
      }, {
        label: "1332px",
        value: "1332px"
      }, {
        label: "100%",
        value: "100%"
      }];
      options.forEach(function (opt) {
        opt.label = "<span class='mojoFontSizeInput_menu'>" + opt.label + "</span>";
      });
      this.container = domConstruct.place("<div class='unit size1of2 !padding-left--lv0 full-width--xltablet'></div>", this.domNode);
      this.styleInput = new Select({
        options: options,
        maxHeight: -1,
        value: pageWidth,
        "class": "!margin-bottom--lv1"
      }).placeAt(this.container);
      this.own(on(this.styleInput, "change", lang.hitch(this, function () {
        this._onChange();
      })));
      this.initialized = true;
    },
    _setValueAttr: function (rawValue) {
      this.inherited(arguments);
      if (this.initialized) {
        this.setInputValues(rawValue);
        var value = this.get("value");
        this.onChange(value);
      }
    },
    _getValueAttr: function () {
      if (!this.initialized) {
        return this.inherited(arguments);
      }
      return this.styleInput.get("value");
    },
    setInputValues: function (rawValue) {
      if (this.initialized) {
        // var pageWidth = this._parseBorder(rawValue);
        this.styleInput.set("value", rawValue, false);
      }
    },
    onChange: function (value) {},
    _onChange: function () {
      var value = this.get("value");
      this.onChange(value);
    }
  });
});