define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/DisplayCurrencySwitcher.html", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (declare, on, domClass, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, template, xhr, lang, domConstruct, mUrl) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: template,
    initialSelection: null,
    postCreate: function () {
      var url = "account/get-possible-display-currencies";
      if (this.userId) {
        url = "/peaches2/users/account/get-possible-display-currencies";
        this.form.action = "/peaches2/users/account/set-display-currency";
        this.userIdField.value = this.userId;
      }
      //load the list of currencies
      xhr.get(mUrl.toUrl(url, {
        _s: "summary",
        user_id: this.userId
      }), {
        handleAs: "json"
      }).then(lang.hitch(this, function (response) {
        if (response.hasPendingChange) {
          domClass.remove(this.pending, "hide");
          domClass.add(this.formContent, "dim6");
          this.currencySelect.disabled = true;
        } else {
          domClass.add(this.pending, "hide");
          domClass.remove(this.formContent, "dim6");
          this.currencySelect.disabled = false;
        }

        //populate the dropdown
        var options = Object.entries(response.possibleDisplayCurrencies);
        this.currencySelect.innerHTML = options.map(function (option) {
          return '<option value="' + option[0] + '">' + option[1] + "</option>";
        });
        this.currencyText.innerHTML = response.pendingCurrency;
        this.currencySelect.value = response.displayCurrency;
        this.initialSelection = response.displayCurrency;
        this._onChange();
      }));

      //listen for selection change
      on(this.currencySelect, "change", this._onChange.bind(this));
    },
    //called when the dropdown is changed to toggle submitting
    _onChange: function () {
      if (this.currencySelect.value === this.initialSelection) {
        domClass.add(this.submitButton, "disabled");
      } else {
        domClass.remove(this.submitButton, "disabled");
      }
    }
  });
});