define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog"], function (declare, Dialog) {
  return declare(Dialog, {
    hide: function () {
      if (!this._alreadyInitialized) {
        return;
      }
      this.execute();
      this.inherited("hide", arguments);
    }
  });
});