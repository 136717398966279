define(["mojo/lib/flags", "mojo/lib/logger", "mojo/user"], function (flags, logger, user) {
  return function (eventAction, customProperties, customLoginTraits, customAccountTraits) {
    logger.eventsPipeline.publish("campaign:" + eventAction, Object.assign({
      org: "sbseg",
      purpose: "prod",
      scope: "mc",
      initiative_name: "bulk_email",
      object: "campaign"
    }, customProperties), {
      customLoginTraits: customLoginTraits,
      customAccountTraits: customAccountTraits
    });
  };
});