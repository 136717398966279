define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "dijit/registry", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/DropDownMenu", "dijit/MenuItem", "dijit/form/ComboButton", "mojo/analytics/parts/SelectCheckBox", "mojo/lib/clipboard", "mojo/url", "mojo/utils", "mojo/utils/date", "mojo/lib/flags", "mojo/user", "mojo/app/campaigns", "mojo/app/placards", "mojo/app/postcards", "mojo/views/automations/index", "dojo/text!./templates/Touchpoint.html"], function (declare, lang, domConstruct, domClass, domAttr, on, entities, registry, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, DropDownMenu, MenuItem, ComboButton, SelectCheckBox, clipboard, mUrl, utils, dateUtils, flags, user, campaigns, placards, postcards, automations, touchpointTpl) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: touchpointTpl,
    constructor: function (properties) {
      var touchpoint = this.touchpoint = properties.touchpoint;
      var indent = properties.indent;
      this.publishedAt = dateUtils.getDateString(new Date(touchpoint.published_at));
    },
    postCreate: function () {
      this.inherited(arguments);
      var touchpoint = this.touchpoint;
      new SelectCheckBox({
        value: this.touchpoint.outreach_unique_id
      }).placeAt(this.node_selectCheckBox, "replace");

      // Show an icon depending on the channel type
      domClass.add(this.node_channelIcon, "c-channelIcon--" + touchpoint.type);
      domClass.toggle(this.node_channelIcon, "indent", this.indent);

      // Set the description info
      this.description.innerHTML = touchpoint.description;
      var status = null;
      switch (touchpoint.status) {
        case "sent":
          status = {
            label: "Sent",
            badge: "success",
            show: false
          };
          break;
        case "sending":
          status = {
            label: "Sending",
            badge: "info",
            show: true
          };
          break;
        case "complete":
        case "completed":
        case "published":
        case "active":
          status = {
            label: "Published",
            badge: "success",
            show: false
          };
          break;
        case "pending":
          status = {
            label: "In Review",
            badge: "warning",
            show: true
          };
          break;
        case "processing":
          status = {
            label: "Processing",
            badge: "info",
            show: true
          };
          break;
        case "scheduled":
        case "schedule":
          status = {
            label: "Scheduled",
            badge: "warning",
            show: true
          };
          break;
        case "paused":
          status = {
            label: "Paused",
            badge: "warning",
            show: true
          };
          break;
        case "somepaused":
          status = {
            label: "Some Paused",
            badge: "warning",
            show: true
          };
          break;
        case "rejected":
        case "compliance":
          status = {
            label: "Rejected",
            badge: "error",
            show: true
          };
          break;
        case "partialRejected":
          status = {
            label: "Some Rejected",
            badge: "warning",
            show: true
          };
          break;
        case "canceled":
          status = {
            label: "Canceled",
            badge: "error",
            show: true
          };
          break;
        case "draft":
        case "save":
          status = {
            label: "Draft",
            badge: "",
            show: true
          };
          break;
        default:
          break;
      }
      if (status && status.show) {
        this.node_badge.innerHTML = status.label;
        domClass.add(this.node_badge, status.badge);
      } else {
        domClass.remove(this.node_badge, "badge");
      }
      var reportingUrl = touchpoint.report_url || "#";

      // Inflate the touchpoint stats
      var template = this.template_statBlock;
      var nodeStats = this.nodeStats;
      touchpoint.stats.forEach(function (stat) {
        var statBlock = utils.compileTemplate(template, {
          label: stat.label,
          value: stat.value,
          url: reportingUrl
        });
        nodeStats.append(statBlock);
      });

      // Populate the actions
      var actions = touchpoint.actions;
      if (actions && actions.length) {
        var dropDown;
        if (actions.length > 1) {
          dropDown = new DropDownMenu({
            "class": "mc-dropdown"
          });
          actions.slice(1).forEach(lang.hitch(this, function (action) {
            var item = new MenuItem({
              label: this._generateLinkHTML(action.label, action.href)
            });
            if (!action.href) {
              item.onClick = function () {
                this._onTouchpointClicked(action.action, touchpoint);
              }.bind(this);
            }
            dropDown.addChild(item);
          }));
        }
        var primaryAction = actions[0];
        if (primaryAction) {
          var button = new ComboButton({
            label: this._generateLinkHTML(primaryAction.label, primaryAction.href),
            dropDown: dropDown
          });

          // If the action doesn't have an explicit URL, it's likely handled by the frontend
          // (e.g. showing a rename dialog or copying a link to the clipboard)
          if (!primaryAction.href) {
            button.onClick = function () {
              this._onTouchpointClicked(primaryAction.action, touchpoint);
            }.bind(this);
          }
          domClass.add(button.domNode, "c-slatButton button-small");
          domClass.toggle(button.domNode, "c-campaignManager_slat_actions_single", !dropDown);
          button.placeAt(this.node_actions, "replace");

          // Also set the link title to the primary attribute.
          if (primaryAction.href) {
            domAttr.set(this.titleLink, "href", primaryAction.href);
          } else {
            on(this.titleLink, "click", function () {
              this._onTouchpointClicked(primaryAction.action, touchpoint);
            }.bind(this));
          }
        }
      }
    },
    _generateLinkHTML: function (linkText, hrefTemplate) {
      var labelLink = domConstruct.create("a", {
        innerHTML: linkText
      });
      if (hrefTemplate) {
        domAttr.set(labelLink, "href", hrefTemplate);
      }
      return labelLink.outerHTML;
    },
    _onTouchpointClicked: function (action, touchpoint) {
      switch (action) {
        case "rename":
          campaigns.showRenameModal(touchpoint.outreach_unique_id, entities.encode(touchpoint.name));
          break;
        case "copy_url":
          clipboard.copy(touchpoint.public_url);
          utils.toast('The "' + entities.encode(touchpoint.name) + '" URL was was copied to your clipboard. Go forth and share!');
          break;
        case "cancel":
          if (touchpoint.type === "postcard") {
            postcards.cancel(touchpoint.outreach_unique_id, entities.encode(touchpoint.name));
          }
          if (touchpoint.type === "ad") {
            placards.confirmCancel(touchpoint.outreach_unique_id).then(function () {
              registry.byId("campaigns-list").render();
            });
          }
          break;
        case "pause":
          if (touchpoint.type === "postcard") {
            postcards.pause(touchpoint.outreach_unique_id, touchpoint.status);
          }
          if (touchpoint.type === "ad") {
            placards.pauseAdWithConfirmDialogOnUpgrade(touchpoint.outreach_unique_id).then(function () {
              registry.byId("campaigns-list").render();
            });
          }
          break;
        case "unpause":
          if (touchpoint.type === "ad") {
            placards.unpauseAd(touchpoint.outreach_unique_id).then(function () {
              registry.byId("campaigns-list").render();
            }, function (errorResponse) {
              if (!errorResponse.errors || !errorResponse.errors.publish) {
                return;
              }
              utils.toast(errorResponse.errors.publish, "error");
            });
          }
          break;
        case "pauseandedit":
          if (touchpoint.type === "email") {
            campaigns.showPauseModal(touchpoint.outreach_unique_id, entities.encode(touchpoint.name));
          }
          break;
        case "replicate":
          if (touchpoint.type === "postcard") {
            postcards.replicate(touchpoint.outreach_unique_id);
          } else if (touchpoint.type === "automation") {
            new automations().showReplicationModal(touchpoint.outreach_unique_id);
          }
          break;
        case "archive":
          if (touchpoint.type === "automation") {
            new automations()._confirmArchiving(touchpoint.outreach_unique_id);
          }
          break;
        case "publish":
          if (touchpoint.type === "page") {
            // TODO move this into its own class
            document.location.href = mUrl.toUrl("/landing-pages/publish", {
              "id": touchpoint.outreach_unique_id,
              "__csrf_token": user.__csrfToken
            });
          }
          break;
        case "unpublish":
          if (touchpoint.type === "page") {
            // TODO move this from placards (which is for ads) to its own class
            placards.confirmUnpublishPage(touchpoint.outreach_unique_id, touchpoint.name).then(function () {
              registry.byId("campaigns-list").render();
            }, function () {
              utils.toast('Unable to unpublish "' + touchpoint.name + '". Please try again in a few minutes.', "error");
            });
          }
          break;
        default:
          return null;
      }
    }
  });
});