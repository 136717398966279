define(["dojo/window",
// windowUtils.get
"../main" // export symbol to dijit
], function (windowUtils, dijit) {
  // module:
  //		dijit/_base/window

  /*=====
  return {
  	// summary:
  	//		Back compatibility module, new code should use windowUtils directly instead of using this module.
  };
  =====*/

  dijit.getDocumentWindow = function (doc) {
    return windowUtils.get(doc);
  };
});