define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/app/peaches2/utils", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/analytics/parts/FilterMenu", "mojo/analytics/parts/FilterMessage"], function (declare, lang, base, domAttr, p2Utils, on, FilterMenu, FilterMessage) {
  return declare([base], {
    pageJs: null,
    elId: null,
    onReady: function () {
      var filterSettings1 = {
        "target": "list-1",
        "filters": [{
          "label": "Last used...",
          "key": "last_used",
          "type": "select",
          "options": {
            "1": "One day ago",
            "2": "Two days ago",
            "7": "One week ago",
            "30": "One month ago",
            "60": "Two months ago",
            "90": "Three months ago",
            "180": "Six months ago",
            "360": "One year ago"
          }
        }, {
          "label": "Created since...",
          "key": "age",
          "type": "select",
          "options": {
            "1": "One day ago",
            "2": "Two days ago",
            "7": "One week ago",
            "30": "One month ago",
            "60": "Two months ago",
            "90": "Three months ago",
            "180": "Six months ago",
            "360": "One year ago"
          }
        }, {
          "label": "Platform",
          "key": "platform",
          "type": "select",
          "options": {
            "android": "Android",
            "iphone": "iPhone"
          }
        }]
      };
      var filter1 = new FilterMenu(filterSettings1);
      this.$el.list1Filters.appendChild(filter1.domNode);
      var filterMessage1 = new FilterMessage(filterSettings1);
      this.$el.list1FiltersMessage.appendChild(filterMessage1.domNode);
      this.notificationOptionsListener();
    },
    /**
     * opens the delete device modal
     * @param {string} id - the device id that should be deleted
     */
    deleteDevice: function (id) {
      this.$el.deviceId.value = id;
      this.$widget.deleteDialog.show();
    },
    notificationOptionsListener: function () {
      on(this.$widget.notifOptions, "change", lang.hitch(this, function (selected) {
        this.notificationDescriptionDisplay(selected);
      }));
    },
    notificationDescriptionDisplay: function (selected) {
      this.$el.dosDescription.style.display = "none";
      this.$el.rstnoDescription.style.display = "none";
      this.$el.lppsDescription.style.display = "none";
      this.$el.aweDescription.style.display = "none";
      this.$el.ioDescription.style.display = "none";
      this.$el.mpDescription.style.display = "none";
      this.$el.osccsDescription.style.display = "none";
      this.$el.oscpsDescription.style.display = "none";
      this.$el.oscaaDescription.style.display = "none";
      this.$el.acDescription.style.display = "none";
      this.$el.prDescription.style.display = "none";
      this.$el.icDescription.style.display = "none";
      this.$el.nimDescription.style.display = "none";
      this.$el.esrDescription.style.display = "none";
      this.$el.adDescription.style.display = "none";
      this.$el.crmDescription.style.display = "none";
      if (selected === "daily-order") {
        this.$el.dosDescription.style.display = "block";
      }
      if (selected === "landing-page-perf") {
        this.$el.lppsDescription.style.display = "block";
      }
      if (selected === "resend-to-non-openers") {
        this.$el.rstnoDescription.style.display = "block";
      }
      if (selected === "welcome-email") {
        this.$el.aweDescription.style.display = "block";
      }
      if (selected === "instant-order") {
        this.$el.ioDescription.style.display = "block";
      }
      if (selected === "mobile-preview") {
        this.$el.mpDescription.style.display = "block";
      }
      if (selected === "outreach-status-change-campaign") {
        this.$el.osccsDescription.style.display = "block";
      }
      if (selected === "outreach-status-change-ad") {
        this.$el.oscaaDescription.style.display = "block";
      }
      if (selected === "import-complete") {
        this.$el.icDescription.style.display = "block";
      }
      if (selected === "new-inbox-message") {
        this.$el.nimDescription.style.display = "block";
      }
      if (selected === "email-survey-rec") {
        this.$el.esrDescription.style.display = "block";
      }
      if (selected === "anomaly-detection") {
        this.$el.adDescription.style.display = "block";
      }
      if (selected === "crm-tasks-due") {
        this.$el.crmDescription.style.display = "block";
      }
    }
  });
});