define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "velocity/velocity", "dojo/NodeList-traverse"], function (declare, query, domClass, on, domConstruct, lang, domAttr, string, Deferred, velocity) {
  return {
    // Returns false when invalid fields exist. Handles setting classes
    // and error messages
    validateForm: function (formDom, hideErrors) {
      // Loop through all inputs
      var valid = true;
      // Text first
      query("input[type=text], input[type=url]", formDom).forEach(lang.hitch(this, function (inputDom) {
        // Check for validation params
        var validate = domAttr.get(inputDom, "data-mcValidate");
        switch (validate) {
          case "required":
            if (!inputDom.value) {
              if (!hideErrors) this.invalidateField(inputDom, "This field is required, yo.");
              valid = false;
            }
            break;
          default:
            return;
        }
      }));

      // Then radios
      var validatedRadioGroups = [];
      query("input[type=radio]", formDom).forEach(lang.hitch(this, function (inputDom) {
        // Check for validation params
        var validate = domAttr.get(inputDom, "data-mcValidate");
        switch (validate) {
          case "required":
            var radioGroupName = domAttr.get(inputDom, "name");
            if (validatedRadioGroups.indexOf(radioGroupName) < 0) {
              validatedRadioGroups.push(radioGroupName);
              // Check all radios in this name group for a checked one
              var checkedRadioInputs = query('input[name=' + radioGroupName + ']::checked');
              if (!checkedRadioInputs.length) {
                // Add error message to last field
                var radioInputsInGroup = query('input[name=' + radioGroupName + ']');
                if (!hideErrors) this.invalidateField(radioInputsInGroup[radioInputsInGroup.length - 1], "This field is required, yo.");
                valid = false;
              }
            }
            break;
          default:
            return;
        }
      }));
      // Then Selects
      query("select", formDom).forEach(lang.hitch(this, function (inputDom) {
        var validate = domAttr.get(inputDom, "data-mcValidate");
        switch (validate) {
          case "required":
            if (!inputDom.value) {
              if (!hideErrors) this.invalidateField(inputDom, "This field is required, yo.");
              valid = false;
            }
            break;
          default:
            return;
        }
      }));
      // MISSING TEXTAREAS
      // Return
      return valid;
    },
    invalidateField: function (inputDom, message) {
      domClass.add(inputDom, "invalid");
      var inputType = domAttr.get(inputDom, 'type');

      // check for existing error message, and reuse if exists
      var errorMessageContainer = query(inputDom).next('.invalid-error')[0];
      if (inputType === "radio") {
        errorMessageContainer = query(inputDom).parents('.field-wrapper').next('.invalid-error')[0];
      }
      if (errorMessageContainer) {
        errorMessageContainer.textContent = message;
      } else {
        errorMessageContainer = domConstruct.create("div", {
          "class": "invalid-error",
          textContent: message
        });
        if (inputType === "radio") {
          domConstruct.place(errorMessageContainer, query(inputDom).parents('.field-wrapper')[0], "after");
        } else {
          domConstruct.place(errorMessageContainer, inputDom, "after");
        }
      }

      // Clean up error on change
      var eventToDetermineReset = "keydown";

      // Stupid tagName attribute is always in all caps...?
      switch (inputDom.tagName) {
        case "INPUT":
          if (inputType === "radio") {
            eventToDetermineReset = "change";
            break;
          } else {
            break;
          }
        case "SELECT":
          eventToDetermineReset = "change";
          break;
        default:
          eventToDetermineReset = "keydown";
      }
      // FML Radio buttons
      if (inputType === "radio") {
        // Get all radios for that group name
        var radioName = domAttr.get(inputDom, "name");
        // Attach change event for that group name
        var inputDomResetListener = query('input[name=' + radioName + ']').on("change", lang.hitch(this, function (e) {
          // On change, find last input for group name, reset it
          var radioInputsInGroup = query('input[name=' + radioName + ']');
          this.resetInvalidField(radioInputsInGroup[radioInputsInGroup.length - 1]);
          inputDomResetListener.remove();
        }));
      } else {
        var inputDomResetListener = on(inputDom, eventToDetermineReset, lang.hitch(this, function (e) {
          this.resetInvalidField(inputDom);
          inputDomResetListener.remove();
        }));
      }
    },
    resetInvalidField: function (inputDom) {
      var inputType = domAttr.get(inputDom, 'type');
      domClass.remove(inputDom, "invalid");
      if (inputType === "radio") {
        var errorMessage = query(inputDom).parents('.field-wrapper').next('.invalid-error')[0];
      } else {
        var errorMessage = query(inputDom).next('.invalid-error')[0];
      }
      if (errorMessage) domConstruct.destroy(errorMessage);
    },
    playDenyAnimation: function (shakeContainer) {
      var promise = new Deferred();
      var duration = 50;
      velocity(shakeContainer, {
        translateX: -11
      }, {
        duration: duration,
        complete: function () {
          velocity(shakeContainer, {
            translateX: 11
          }, {
            duration: duration,
            complete: function () {
              velocity(shakeContainer, {
                translateX: -11
              }, {
                duration: duration,
                complete: function () {
                  velocity(shakeContainer, {
                    translateX: 11
                  }, {
                    duration: duration,
                    complete: function () {
                      velocity(shakeContainer, {
                        translateX: -11
                      }, {
                        duration: duration,
                        complete: function () {
                          velocity(shakeContainer, {
                            translateX: 11
                          }, {
                            duration: duration,
                            complete: function () {
                              velocity(shakeContainer, {
                                translateX: 0
                              }, {
                                duration: duration
                              });
                              promise.resolve();
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
      return promise;
    }
  };
});