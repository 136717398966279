/**
 * Widget built to handle the selecting of automations to restart
 *
 * @module mojo/widgets/automation/RestartAutomationDialog
 * @see mojo/widgets/automation/RestartAutomation
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/user", "mojo/lib/flags", "mojo/widgets/Renderable", "dojo/text!./templates/RestartAutomationDialog.html"], function (declare, on, query, domAttr, domClass, registry, user, flags, Renderable, tplStr) {
  return declare([Renderable], {
    templateString: tplStr,
    // The amount of queued message to be sent
    totalQueueCount: 0,
    // Initial checked automation campaigns on launch
    checkedAutomations: 0,
    // Array of IDs to send to the backend on POST
    selectedAutomationsIds: [],
    postMixInProperties: function () {
      this.checkedAutomations = this.campaigns.length;
      this._getTotalQueueCount();
    },
    postCreate: function () {
      on(this.domNode, on.selector("[data-queue-count]", "change"), this._handleSelectedAutomations.bind(this));
    },
    /**
     * When a user checks or uncheck the automations they want to restart or not
     * update the total queue and checked items count
     *
     * @param {Event} e - Mouse Event on change of checkbox
     * @private
     */
    _handleSelectedAutomations: function (e) {
      var checkedBox = e.target;
      var singleQueueCount = parseInt(checkedBox.dataset.queueCount, 10);
      if (checkedBox.checked) {
        this.totalQueueCount += singleQueueCount;
        this.checkedAutomations += 1;
      } else {
        this.totalQueueCount -= singleQueueCount;
        this.checkedAutomations -= 1;
      }
      this._toggleRestartButton();

      // Update the value in the DOM to reflect how many messages will queue
      this.totalQueueAmount.innerText = this.totalQueueCount;
      this.totalCheckedItems.innerText = this.checkedAutomations;
    },
    _toggleRestartButton: function () {
      if (this.totalQueueCount === 0 && this.checkedAutomations === 0) {
        domAttr.set(this.restartButton, "disabled", true);
      } else {
        domAttr.set(this.restartButton, "disabled", false);
      }
    },
    /**
     * Get the total amount of queued messages from the data
     *
     * @private
     */
    _getTotalQueueCount: function () {
      this.campaigns.forEach(function (campaign) {
        this.totalQueueCount += campaign.queue_size;
      }.bind(this));
    },
    /**
     * Query the form to grab all the checked automations in order to
     * grab the IDs to be passed to the backend
     *
     * @returns {Array} Array of the checked automation/campaign ids
     * @private
     */
    _getSelectedAutomationsIds: function () {
      // Reset the array each time we grab the ids
      this.selectedAutomationsIds = [];

      // Grab all the selected checkboxes
      var selectedAutomations = query("[data-queue-count]:checked");

      // Loop through the array to grab the IDs and push them to the
      // selectedAutomationsId array
      selectedAutomations.forEach(function (campaign) {
        this.selectedAutomationsIds.push(parseInt(campaign.id, 10));
      }.bind(this));
      return this.selectedAutomationsIds;
    },
    /**
     * Event handler to cancel dialog on click
     * Cancel method passed down by the Dialog - see RestartAutomation.js
     *
     * @private
     */
    _cancelDialog: function () {
      this.onCancel();
    },
    /**
     * On click of the restart button grab the IDs and
     * send them back up - see RestartAutomation.js
     *
     * @param {Event} e - Mouse Event on click of restart confirmation
     * @private
     */
    _restartAutomations: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var confirmInput = registry.byId("confirmRestartInput");
      var passesValidation = confirmInput.validate();

      // Grab the selected Automation ids to be passed up
      var automationIds = this._getSelectedAutomationsIds();
      if (passesValidation) {
        // Give user feedback and disable button after submitting
        domClass.add(this.restartButton, "button-loading");
        domAttr.set(this.restartButton, "disabled", true);
        this.onRestart(automationIds);
      }
    },
    /**
     * If there is unsuccessful post of the automation ids then re-enable
     * the restart button and remove the loading indicator.
     *
     */
    onPostError: function () {
      domClass.remove(this.restartButton, "button-loading");
      domAttr.set(this.restartButton, "disabled", false);
    }
  });
});