define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./_Block"], function (declare, _Block) {
  return declare([_Block], {
    type: "divider",
    _name: "Divider",
    _kbLink: "https://mailchimp.com/help/use-divider-content-blocks/",
    constructor: function () {
      this.styles = [{
        "name": "Divider style",
        "selector": ".mcnDividerBlockInner",
        "props": {
          "padding-top": {
            "value": 18 + "px"
          },
          "padding-bottom": {
            "value": 18 + "px"
          },
          "border-top": {
            "value": "2px solid #EAEAEA",
            "selector": ".mcnDividerContent"
          },
          "background-color": {
            "selector": "mcBlockContainer"
          }
        }
      }];
    }
  });
});