define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/url",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/Dialog",
// Templates
"dojo/text!../templates/activity-feed/activity-item-note.html",
// Widgets
"mojo/widgets/contact-profile/AddNote", "../Api"], function (declare, lang, domConstruct, domClass, on, htmlEntities, mUrl, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, Dialog, activityTplStr, AddNote, api) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: activityTplStr,
    data: null,
    noteText: null,
    isReadOnly: false,
    /**
     * Decode html entities in the note text
     */
    postMixInProperties: function () {
      this.data.note_text = htmlEntities.decode(this.data.note_text);
    },
    /**
     * Initialize data
     */
    postCreate: function () {
      this.inherited(arguments);
      domClass.remove(this.noteActions, "hide");
      if (this.isReadOnly) {
        domClass.add(this.noteActions, "hide");
      }
    },
    /**
     * Create a new AddNote widget so that the user may edit the note text
     */
    showEditNoteUI: function () {
      var editNote = new AddNote({
        memberID: this.data.memberID,
        noteID: this.data.note_id,
        noteText: this.data.note_text,
        editSuccessCallback: lang.hitch(this, "editSuccessCallback"),
        showCancel: true,
        cancelFunction: lang.hitch(this, "hideNoteEdit")
      });
      domClass.add(this.noteItemContainer, "hide");
      editNote.placeAt(this.editNoteContainer);
    },
    /**
     * Destroy the editing UI on Save or Cancel
     */
    hideNoteEdit: function () {
      domClass.remove(this.noteItemContainer, "hide");
      domConstruct.empty(this.editNoteContainer);
    },
    /**
     * Once the note is saved, refresh the activity feed
     */
    editSuccessCallback: function () {
      this.hideNoteEdit();
      this.data.refreshFeed();
    },
    /**
     * Show dialog for confirming note deletion
     */
    showDeleteConfirmationUI: function () {
      domClass.add(this.noteItemContainer, "hide");
      domClass.remove(this.deleteNoteContainer, "hide");
    },
    /**
     * Hide dialog for confirming note deletion
     */
    hideDeleteConfirmationUI: function () {
      domClass.remove(this.noteItemContainer, "hide");
      domClass.add(this.deleteNoteContainer, "hide");
    },
    /**
     * Api call to delete a note
     */
    deleteNote: function () {
      api.deleteNote(this.data.memberID, this.data.note_id, this.data.refreshFeed);
    }
  });
});