define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable",
// Mixin
"./_ContentPreviewMixin",
// Templates
"dojo/text!./templates/FacebookPreviewDesktop.html"], function (declare, lang, domClass, domStyle, Renderable, _PreviewMixin, fbDesktoptplStr) {
  return declare([Renderable, _PreviewMixin], {
    templateString: fbDesktoptplStr,
    // Starting Position of Carousel
    startingAdPosition: 0,
    // Starting transition amount value
    transitionAmount: 0,
    // TODO: Get these values dynamically based on attachment width
    PARTIALTRANSITION: 218,
    FULLTRANSITION: 306,
    /**
     * When a user hovers over the next or previous button add
     * hover class to both buttons
     */
    _handleButtonMouseEnter: function () {
      domClass.add(this.previousButton, "c-fbAdCarousel_button--hover");
      domClass.add(this.nextButton, "c-fbAdCarousel_button--hover");
    },
    /**
     * When a user hovers over the next or previous button remove
     * hover class to both buttons
     */
    _handleButtonMouseLeave: function () {
      domClass.remove(this.previousButton, "c-fbAdCarousel_button--hover");
      domClass.remove(this.nextButton, "c-fbAdCarousel_button--hover");
    },
    /**
     * Shift the carousel ad container to show next attachment / ad item
     *
     * @param {domNode} carouselContainer - Dom Node / Container to shift
     * @param {int} shiftAmount - Amount to shift container by
     * @private
     */
    _shiftCarouselContainer: function (carouselContainer, shiftAmount) {
      domStyle.set(carouselContainer, "transform", "translateX(-" + shiftAmount + "px)");
    },
    /**
     * Adjust the size of the previous or next button depending if
     * the user has reached the end or beginning or the carousel
     *
     * @private
     */
    _adjustButtonSizes: function () {
      domClass.toggle(this.nextButton, "c-fbAdCarousel_button--large", this._isBeginningOfCarousel());
      domClass.toggle(this.previousButton, "c-fbAdCarousel_button--large", this._isEndOfCarousel());
    },
    /**
     * Conditional checks to see if carousel has reached the beginning or end
     *
     * @returns {boolean} - True or False
     * @private
     */
    _isBeginningOfCarousel: function () {
      return this.startingAdPosition === 0;
    },
    _isEndOfCarousel: function () {
      return this.startingAdPosition === this.numberOfAttachments;
    },
    /**
     * Click event when user presses the next button in carousel
     * increments the starting position and shows/hide next and previous button
     *
     * @private
     */
    _shiftCarouselForward: function () {
      this.startingAdPosition++;

      // Amount to shift the container depends where the user is in the carousel
      // if they are at the end or beginning shift container partially else shift full amount
      this.transitionAmount += this.startingAdPosition === 1 || this._isEndOfCarousel() ? this.PARTIALTRANSITION : this.FULLTRANSITION;

      // Shift container if user hasn't reached the end of carousel
      if (this.startingAdPosition <= this.numberOfAttachments) {
        this._shiftCarouselContainer(this.contentContainer, this.transitionAmount);
      }
      this._adjustButtonSizes();
      domClass.remove(this.previousButton, "hide");
      domClass.toggle(this.nextButton, "hide", this._isEndOfCarousel());
    },
    /**
     * Click event when user presses the previous button in carousel
     * decrements the starting position and shows/hide previous and next button
     *
     * @private
     */
    _shiftCarouselBack: function () {
      this.startingAdPosition--;

      // Amount to shift the container depends where the user is in the carousel
      // if they are at the end or beginning shift container partially else shift full amount
      this.transitionAmount -= this.startingAdPosition === 0 || this.startingAdPosition === this.numberOfAttachments - 1 ? this.PARTIALTRANSITION : this.FULLTRANSITION;

      // Shift container if user hasn't reached the beginning of carousel
      if (this.startingAdPosition >= 0) {
        this._shiftCarouselContainer(this.contentContainer, this.transitionAmount);
      }
      this._adjustButtonSizes();
      domClass.remove(this.nextButton, "hide");
      domClass.toggle(this.previousButton, "hide", this._isBeginningOfCarousel());
    }
  });
});