/**
 * Widget built to manage the extended budget slat.
 * @module mojo/widgets/ads/facebook/ExtendBudgetSlat
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date", "dojo/date/locale", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dijit/registry", "mojo/app/placards", "mojo/url", "mojo/user", "mojo/utils", "dojo/text!./edit-budget/templates/ExtendBudgetSlat.html"], function (declare, lang, date, locale, on, html, domClass, domConstruct, request, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, registry, placards, mUrl, user, utils, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    currentEndTime: 0,
    currencySymbol: user.currencySymbol,
    postCreate: function () {
      // Initialize the calendar widget to today, and don't allow the user to select anything before today.
      this.endDatePicker.set("value", new Date());
      this.endDatePicker.constraints.min = new Date();

      // populate the time dropdown
      var twoHoursFromNow = new Date().getHours() + 2; // by default, select 2 hours from now
      for (var i = 1; i <= 12; i++) {
        var option = domConstruct.create("option", {
          value: i,
          innerHTML: (i < 10 ? "0" : "") + i + ":00",
          // where is sprintf when you need it?
          selected: i === twoHoursFromNow % 12 || 12
        });
        domConstruct.place(option, this.endHour);
      }

      // do we default to am or pm?
      if (twoHoursFromNow <= 11) {
        this.endAM.checked = true;
      } else {
        this.endPM.checked = true;
      }

      // When we change the duration type, either show/hide the date picker or the projected end time
      on(this.duration, "change", lang.hitch(this, function (e) {
        // Clear any potential errors in the widget.
        utils.removeAllErrors(this.domNode);

        // Handle toggling of projected end time.
        var durationType = e.target.value;
        domClass.toggle(this.dateChooser, "hide", durationType !== "date");
        this._updateProjectedTime(durationType);
      }));

      // When we click continue, validate data show the confirm extend budget dialog.
      on(this.continue, "click", lang.hitch(this, "_handleContinue"));
    },
    /**
     * Setup the data for this slat.
     *
     * @param {obj} data - an object with keys for ad_id (the unique one, of course) and end_time.
     */
    setData: function (data) {
      this.ad_id = data.ad_id;
      this.currentEndTime = new Date(data.end_absolute_time * 1000);
    },
    _handleContinue: function () {
      var self = this;
      // Clear out any previous errors
      utils.removeAllErrors(this.domNode);

      // Make sure they've entered something.
      if (this.duration.value === "" && this.budget.value === "") {
        utils.addError(this.errorMessage, "Please make a selection");
        return;
      }

      // Submit request to backend to validate the info
      this._validateBudget().then(function (response) {
        switch (response.status) {
          case "success":
            self._loadConfirmationDialog();
            break;
          case "error":
            // TODO: Map to inputs. Backend doesn't know widget naming context.
            // Show the first returned error message in the error message container.
            utils.addError(self.errorMessage, response.errors[Object.keys(response.errors)[0]]);
            break;
          default:
            throw new Error("Unknown state " + response.status);
        }
      }, function (error) {
        throw new Error(error);
      });
    },
    _updateProjectedTime: function (durationType) {
      // only show this for relative dates
      domClass.toggle(this.projectedTime, "hide", durationType === "date" || durationType === "");
      html.set(this.projectedTime, this._getNewEndTime());
    },
    _validateBudget: function () {
      var formData = {
        id: this.ad_id,
        budget: Number(this.budget.value),
        end_time: this.duration.value ? this._getNewEndTime() : null
      };
      return request.post(mUrl.toUrl("/ads/post-validate-budget-extension"), {
        "handleAs": "json",
        "query": formData
      });
    },
    _loadConfirmationDialog: function () {
      var self = this;
      var budget = Number(this.budget.value);
      var end_time = this.duration.value ? this._getNewEndTime() : null;
      placards.confirmExtension(this.ad_id, budget, end_time).then(function () {
        // Success! Redirect to finished-sent page
        window.location = mUrl.toUrl("/ads/finished-sent", {
          id: self.ad_id
        });
      }, function (err) {
        // Clear out any previous errors and show feedback.
        utils.removeAllErrors(this.domNode);
        utils.addError(self.errorMessage, err.errors[Object.keys(err.errors)[0]]);
      });
    },
    _getNewEndTime: function () {
      function formatDate(d) {
        return locale.format(d, {
          datePattern: "MMM d, y",
          timePattern: "h:mm a"
        });
      }
      switch (this.duration.value) {
        case "one_week":
          return formatDate(date.add(this.currentEndTime, "week", 1));
        case "two_weeks":
          return formatDate(date.add(this.currentEndTime, "week", 2));
        case "one_month":
          return formatDate(date.add(this.currentEndTime, "month", 1));
        default:
          var endDate = this.endDatePicker.value;
          var endHour = +this.endHour.value;
          var offset = 0;
          if (endHour === 12) {
            offset = this.endPM.checked ? 0 : 12;
          } else {
            offset = this.endPM.checked ? 12 : 0;
          }
          var hour = (endHour + offset) % 24;
          var newDate = new Date(+endDate.getFullYear(), +endDate.getMonth(), +endDate.getDate(), hour);
          return formatDate(newDate);
      }
    }
  });
});