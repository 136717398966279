/**
 * Widget built to manage the edit state of the content Facebook slat.
 *
 * @module mojo/widgets/ads/facebook/EditConnectSlat
 * @see mojo/widgets/ads/_EditSlatMixin
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils", "./edit-content/Carousel", "./edit-content/InputWithLabel",
// Mixins
"mojo/widgets/ads/checklist/_EditSlatMixin",
// Templates
"dojo/text!./edit-content/templates/EditContentSlatWrapper.html", "dojo/text!./edit-content/templates/EditContentSlat.html"], function (declare, lang, utils, Carousel, InputWithLabel, _EditSlatMixin, wrapperTplStr, tplStr) {
  /**
   * Base widget reflecting the core pieces necessary to operate the content slat.
   */
  return declare([_EditSlatMixin], {
    templateString: wrapperTplStr,
    content: tplStr,
    slatId: "content",
    postEndpoint: "/ads/facebook/post-content",
    /**
     * Setup the data for this slat.
     *
     * @param {obj} data - an object with keys for all of the necessary data.
     */
    setData: function (data) {
      // Assign vars
      this.adId = data.ad_id;

      // Get this party started
      this._initMessage(data.message);
      this._initCarousel(data.ad_id, data.content, data.maxNumberOfAttachments, data.incompleteAttachmentIndex, data.canAddAttachments);
    },
    /**
     * Auto-fill the message input to what's in the backend.
     *
     * @param {string} messageContents - The value of the input from the backend
     * @private
     */
    _initMessage: function (messageContents) {
      this.messageTextArea = new InputWithLabel({
        type: "textarea",
        label: "Message",
        name: "message",
        required: false,
        content: {
          value: messageContents,
          placeholder: "Personalize your ad with a message"
        }
      }, this.messageContainer);
    },
    /**
     * There's an input associated with the overall campaign and not necessarily to a single carousel item.
     * Get the string value of the text area.
     *
     * Note: this is not a required field.
     *
     * @returns {string} - The contents of the TextArea.
     *
     * @private
     */
    _getMessageValue: function () {
      return this.messageTextArea.domNode.value;
    },
    /**
     * Take the data passed to us at load instantiate the carousel. It'll handle all of the creation of
     * different carousel items.
     *
     * @param {string} adId - unique ID of the ad campaign
     * @param {obj} content - Collection of objects that represent each carousel item.
     * @param {number} maxItems - the maximum number of items this carousel can show
     * @param {number} incompleteCarouselItemIndex - Index of the incomplete carousel item.
     * @param {boolean} canAddAttachments - User can add an item in the carousel if ad is not a gif.
     *
     * @private
     */
    _initCarousel: function (adId, content, maxItems, incompleteCarouselItemIndex, canAddAttachments) {
      this.carousel = new Carousel({
        adId: adId,
        items: content,
        maxItems: maxItems,
        canAddItems: canAddAttachments,
        messageTextArea: this.messageTextArea,
        // Pass reference since Carousel posts to backend.,
        incompleteCarouselItemIndex: incompleteCarouselItemIndex,
        onSlatReset: lang.hitch(this, "_handleSlatReset")
      });
      this.carousel.placeAt(this.carouselContainer);
      this.carousel.startup();
    },
    /**
     * The slat has been emptied of all content. Return to the preview state.
     *
     * @private
     */
    _handleSlatReset: function () {
      if (this.cancelButton) {
        // The cancel button has a listener attached that effectively loads the view mode. Simulate a click.
        this.cancelButton.click();
      }
    },
    /**
     * Called from within the template. Format the data that's going to the backend and POST out to the endpoint.
     *
     * Note: The backend is built to only accept a single carousel item. When someone saves, they are saving just
     * one item. Ideally we'll be able to fix that and handle cancel. 🤞
     */
    handleSave: function () {
      utils.removeAllErrors(this.domNode);
      if (!this._passesSimpleValidation()) {
        // Call into the same feedback given hook that happens with a real request.
        this.onPostErrorFeedbackGiven();
        return;
      }
      this.postForm(this.carousel.getFormData());
    },
    /**
     * If the backend returned errors, surface them in the interface.
     *
     * @param {obj} response - {status: "error", errors: {field: "There's something wrong."}}.
     */
    onPostError: function (response) {
      utils.showFormErrors(this.domNode, response.errors);
    },
    /**
     * The simple validation is handled at the carousel level so that it can be reused whenever a carousel
     * item is changed.
     *
     * @returns {boolean} hasFeedback - Should we post to the server yet?
     *
     * @private
     */
    _passesSimpleValidation: function () {
      return this.carousel.passesSimpleValidation();
    }
  });
});