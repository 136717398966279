define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string",
// Mixins
"dijit/_Widget"], function (declare, lang, dom, domAttr, domClass, query, on, has, stringUtils, _Widget) {
  /**
   * Widget for indicating the remaining characters on a limited size input field.
   *
   * @property {integer} characters - Maximum number of characters to count up to
   * @property {string} inputField - id of input element to track
   * @property {string} defaultText (optional) - Override the default "__ characters" text
   * @property {boolean} shouldCountOnBoot (optiona) - If true, calculate the character count in postCreate
   */
  var CountRemainingCharacters = declare([_Widget], {
    regexAstralSymbols: /[\uD800-\uDBFF][\uDC00-\uDFFF]/g,
    // Can also be set in data-dojo-props
    characters: 280,
    postCreate: function () {
      if (!this.defaultText) {
        this.domNode.innerHTML = this.characters + " characters";
      }
      if (!this.inputField) {
        console.warn("Undefined input field");
        return;
      }

      // If character count text is a span set in normal weight (not bold), cache that setting
      this.countTextNormal = domClass.contains(this.domNode, "font-weight--normal") ? true : false;

      // inputField set in data-dojo-props
      //
      // Using dom.byId will fail here if the desired input field hasn't been added to the document yet. This
      // can happen if you're dynamically loading and parsing DOM using parser.parse() or domConstruct to
      // inflate views.
      //
      // To work around that, we put the listener on window.document and use on.selector() to
      // listen for the desired event. It's important that when we do this, we use this.own() to remove the
      // listener when this widget's lifecycle is complete.
      var eventSelector = on.selector("#" + this.inputField, "keyup");
      this.own(on(window.document, eventSelector, this._handleKeyup.bind(this)));
      if (this.shouldCountOnBoot) {
        var targets = query("#" + this.inputField);
        if (targets.length === 0) {
          console.warn("Failed to find inputField '" + this.inputField + "'");
        } else {
          this._countRemainingCharacters(targets[0]);
        }
      }
    },
    _handleKeyup: function (e) {
      var field = e.target;
      this._countRemainingCharacters(field);
    },
    _countRemainingCharacters: function (field) {
      // Count emoji as one character
      // https://mathiasbynens.be/notes/javascript-unicode
      var count = field.value.replace(this.regexAstralSymbols, "_").length;

      // Count emojis as two characters on Chrome when maxlength attribute is present
      if (field.value.match(this.regexAstralSymbols) && domAttr.has(field, "maxlength") && has("chrome")) {
        count = field.value.length;
      }

      // Remaining character count
      this.remaining = this.characters - count;

      // Set "remaining" suffix based on field value length
      // If disabled, reset maxlength to character length
      var innerHTML = null;
      var hasNoCharacters = count <= 0;
      if (hasNoCharacters) {
        innerHTML = this.characters + " characters";
        domAttr.set(field, "maxlength", this.characters);
      } else {
        innerHTML = this.remaining + " " + stringUtils.toPlural("character", this.characters - count) + " remaining";
      }
      this.domNode.innerHTML = innerHTML;

      // Make character count text red when less than 10 characters remain
      domClass.toggle(this.domNode, "textcolor--error opaque", this.remaining < 10);

      // Make character count text bold when less than 10 characters remain
      if (this.countTextNormal) {
        domClass.toggle(this.domNode, "font-weight--normal", this.remaining >= 10);
      }
    }
  });
  return CountRemainingCharacters;
});