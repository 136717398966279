define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/Renderable"], function (declare, Renderable) {
  return declare([Renderable], {
    templateString: '<input type="hidden" name="<%- name %>" value="<%- value %>" />',
    constructor: function (props) {
      this.state = {
        name: props.name,
        value: props.value
      };
    },
    setValue: function (value) {
      this.setState({
        value: value
      });
    }
  });
});