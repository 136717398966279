/* eslint-disable no-undef */
define(["mojo/lib/flags", "mojo/url", "mojo/utils", "mojo/entri"], function (flags, mUrl, utils, entri) {
  var domainAuthentication = {
    /**
       * Handle authentication
       *
       * @param {string?} domain a domain name or email address
       * @param {*?} element a button element, optional
       */
    handleAuthenticateDomain: function (domain, element) {
      var accountDomainsUrl = mUrl.toUrl("/account/domains");
      if (domain) {
        if (element) {
          utils.toggleButtonLoadingState(element);
        }
        entri.showEntriIfSupported(domain).then(function () {
          if (element) {
            utils.toggleButtonLoadingState(element);
          }
        }, function () {
          window.location.href = accountDomainsUrl;
        });
      } else {
        window.location.href = accountDomainsUrl;
      }
    }
  };
  return domainAuthentication;
});