define(['./assign', './assignIn', './assignInWith', './assignWith', './at', './create', './defaults', './defaultsDeep', './entries', './entriesIn', './extend', './extendWith', './findKey', './findLastKey', './forIn', './forInRight', './forOwn', './forOwnRight', './functions', './functionsIn', './get', './has', './hasIn', './invert', './invertBy', './invoke', './keys', './keysIn', './mapKeys', './mapValues', './merge', './mergeWith', './omit', './omitBy', './pick', './pickBy', './result', './set', './setWith', './toPairs', './toPairsIn', './transform', './unset', './update', './updateWith', './values', './valuesIn'], function(assign, assignIn, assignInWith, assignWith, at, create, defaults, defaultsDeep, entries, entriesIn, extend, extendWith, findKey, findLastKey, forIn, forInRight, forOwn, forOwnRight, functions, functionsIn, get, has, hasIn, invert, invertBy, invoke, keys, keysIn, mapKeys, mapValues, merge, mergeWith, omit, omitBy, pick, pickBy, result, set, setWith, toPairs, toPairsIn, transform, unset, update, updateWith, values, valuesIn) {
  return {
    'assign': assign,
    'assignIn': assignIn,
    'assignInWith': assignInWith,
    'assignWith': assignWith,
    'at': at,
    'create': create,
    'defaults': defaults,
    'defaultsDeep': defaultsDeep,
    'entries': entries,
    'entriesIn': entriesIn,
    'extend': extend,
    'extendWith': extendWith,
    'findKey': findKey,
    'findLastKey': findLastKey,
    'forIn': forIn,
    'forInRight': forInRight,
    'forOwn': forOwn,
    'forOwnRight': forOwnRight,
    'functions': functions,
    'functionsIn': functionsIn,
    'get': get,
    'has': has,
    'hasIn': hasIn,
    'invert': invert,
    'invertBy': invertBy,
    'invoke': invoke,
    'keys': keys,
    'keysIn': keysIn,
    'mapKeys': mapKeys,
    'mapValues': mapValues,
    'merge': merge,
    'mergeWith': mergeWith,
    'omit': omit,
    'omitBy': omitBy,
    'pick': pick,
    'pickBy': pickBy,
    'result': result,
    'set': set,
    'setWith': setWith,
    'toPairs': toPairs,
    'toPairsIn': toPairsIn,
    'transform': transform,
    'unset': unset,
    'update': update,
    'updateWith': updateWith,
    'values': values,
    'valuesIn': valuesIn
  };
});
