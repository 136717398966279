define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base"], function (declare, lang, dom, domAttr, base) {
  return declare([base], {
    onReady: function () {
      // OktaSignIn is imported from peaches2/login/okta.html
      self.oktaSignIn = new OktaSignIn({
        // eslint-disable-line no-undef
        baseUrl: this.baseUrl,
        clientId: this.clientId,
        redirectUri: this.redirectUrl,
        logo: "https://cdn-images.mailchimp.com/product/mcadmin-logo-190121171144.jpg",
        authParams: {
          issuer: "default",
          scopes: ["email", "openid", "groups", "offline_access", "profile"],
          responseType: ["id_token"],
          display: "page"
        },
        i18n: {
          en: {
            "primaryauth.title": "Sign in with Okta"
          }
        },
        helpLinks: {
          help: "https://slack.com/app_redirect?channel=support-eng"
        }
      });
      if (self.oktaSignIn.token.hasTokensInUrl()) {
        window.location.hash = "";
      }
      self.oktaSignIn.session.get(this.handleSession);
    },
    handleSession: function (res) {
      if (res.status === "ACTIVE") {
        var sessionInput = dom.byId("okta-session-id");
        var sessionForm = dom.byId("okta-session-form");
        domAttr.set(sessionInput, "value", res.id);
        sessionForm.submit();
      } else {
        self.oktaSignIn.renderEl({
          el: "#okta-login-container"
        }, function success() {}, function error(err) {});
      }
    }
  });
});