define(["dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/utils", "mojo/views/_base", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url"], function (dom, Deferred, lang, declare, on, query, context, utils, base, xhr, mUrl) {
  return declare([base], {
    onReady: function () {
      var authForm = dom.byId("confirm-auth-form");
      if (null !== authForm) {
        var self = this;
        on(dom.byId("confirm-auth-form"), "submit", function (event) {
          event.preventDefault();
          self._confirmAuthorizationPost(event, self.client_id, self.client_name);
        });
      }
    },
    _confirmAuthorizationPost: function (event, client_id, client_name) {
      var p = this.$el.p;
      xhr.post(mUrl.toUrl("/oauth2/confirm-authorization-oneclick-async", {
        p: p.value
      }), {
        handleAs: "json"
      }).then(function (result) {
        if (result.success) {
          window.location.href = result.default_list_url;
        } else if (result.error) {
          console.error("Invalid data returned while authorizing in the oneclick flow", result.error_message ? result.error_message : "");
        }
      }, function (error) {
        if (error.response.status === 200 && error.response.getHeader("content-type") !== "application/json") {
          //Do nothing. This is likely html content being returned from login page as the oauth request has timed out.
        } else {
          console.error("Error authorizing OAuth user in oneclick flow", error);
        }
        dom.byId("submitButton").classList.remove("button-loading");
        return false;
      });
    }
  });
});