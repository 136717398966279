/**
 * @module mojo.utils.object
 *
 * Object utility methods.
 *
 * All methods' first argument should be an object.
 * Method names follow a prefix pattern which indicates their behavior:
 *  - toSomething: takes an object and formats it in a certain way, eg. toDiff
 */
define([], function () {
  var exports = {
    /**
     * Generates a new Object comparing the difference between the original object and the changed object
     *
     * @param {Object} origObj -- the object to set keys to iterate over
     * @param {Object} changedObj -- the object to compare agaist
     * @returns {Object} -- an object of changed properties
     */
    toDiff: function (origObj, changedObj) {
      var objKeys = Object.keys(origObj);
      return objKeys.reduce(function (newObj, curKey) {
        if (changedObj[curKey] || changedObj[curKey] === "" || changedObj[curKey] === false) {
          // compare origObj[key] and changedObj[curKey]
          if (origObj[curKey] !== changedObj[curKey]) {
            newObj[curKey] = changedObj[curKey];
          }
        }
        return newObj;
      }, {});
    }
  };
  return exports;
});