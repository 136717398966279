define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/registry", "mojo/widgets/Renderable", "mojo/widgets/Previewer", "mojo/widgets/campaigns/PlainTextDialog", "mojo/neapolitan/ShareSettings", "mojo/app/wizard", "mojo/utils", "mojo/widgets/Dialog", "mojo/context", "mojo/lib/flags", "mojo/user", "dojo/text!./templates/_send-a-test.html"], function (declare, lang, on, dom, domClass, query, topic, registry, Renderable, Previewer, PlainTextDialog, ShareSettings, wizard, utils, Dialog, context, flags, user, tpl) {
  return declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      this.state = {
        campaign: props.model,
        userLoginsInfo: [],
        mode: "form",
        // one of "form", "success", or "error",
        fieldhelp: "Use commas to separate multiple emails."
      };
      this._setAvailableUserLoginsInfo(props.userLogins);
      if (this._showIsFreeMessage()) {
        this._setFieldHelper();
      }
    },
    _showIsFreeMessage: function () {
      return user.isFree;
    },
    _setFieldHelper: function () {
      this.setState({
        fieldhelp: "Use commas to separate multiple emails. For users on a free plan, links will expire after 72 hours."
      });
    },
    _createLoginLastTest: function (recipients, email) {
      return recipients && recipients.indexOf(email) > -1;
    },
    _createInNotificationRecipients: function (recipients, id) {
      return recipients && recipients.indexOf(id.toString()) > -1;
    },
    _shouldShowLoginsAsOption: function () {
      return this.state.campaign.lastTestSend.accountRecipients.length > 0;
    },
    getData: function () {
      return {
        content: {}
      };
    },
    _setAvailableUserLoginsInfo: function (userLogins) {
      var campaign = this.state.campaign;
      var newUserLoginsInfo = [];
      for (var i = 0; i < userLogins.length; i++) {
        newUserLoginsInfo.push({
          login_email: userLogins[i].login_email,
          login_id: userLogins[i].login_id,
          login_name: userLogins[i].login_name,
          login_last_test: this._createLoginLastTest(campaign.lastTestSend.recipients, userLogins[i].login_email),
          in_notification_recipients: this._createInNotificationRecipients(campaign.notificationRecipients, userLogins[i].login_id)
        });
      }
      this.state.userLoginsInfo = newUserLoginsInfo;
    },
    /**
     * Send a test of a campaign, and capture error messages and success
     */
    _sendTest: function () {
      wizard.sendTest("null", "campaign", this.model.campaignId, this.sendTestInput).then(this._handleSendTestSuccess.bind(this), this._handleSendTestError.bind(this));
      // this function is used in the campaign checklist builder and in the automations checklist builder. updated the properties of model to include check for single welcome
      if (this.model.isSingleWelcome) {
        window.ga("send", "event", "one_click_builder", "click", "send_a_test_email:send_test");
      } else {
        window.ga("send", "event", "campaign_builder", "click", "send_a_test_email:send_test");
      }
    },
    _handleSendTestSuccess: function () {
      this.setState({
        mode: "success",
        errorMessage: null
      });
    },
    _handleSendTestError: function (message) {
      this.setState({
        mode: "error",
        errorMessage: message
      });
    },
    _handleSendTestKeyPress: function (e) {
      if (e.keyCode === 13) {
        query(".send-test-button")[0].click();
      }
    },
    _hasFreeEmail: function () {
      var yahoo = "yahoo.com";
      var gmail = "gmail.com";
      var hasFreeEmail = false;
      // Check the "from" domain on the campaign if the from section is completed
      var domain = this.state.campaign.fromDomain;
      if (domain) {
        domain = domain.toLowerCase();
        return domain === yahoo || domain === gmail;
      }
      // If no "from email" check default from email
      var email = this.state.campaign.fromDefaultEmail;
      if (email) {
        email = email.toLowerCase();
        return email.includes("@" + yahoo) || email.includes("@" + gmail);
      }
      return hasFreeEmail;
    },
    /**
     * Toggle show/hide of a given element, with the disclosure classes added and removed from the target element
     */
    disclosureElementA: function () {
      utils.disclosureElement("sendtest-users", this.disclosureElementLabelA, true);
    },
    disclosureElementB: function () {
      utils.disclosureElement("top-bar", this.disclosureElementLabelB, true);
    },
    disclosureElementC: function () {
      utils.disclosureElement("sendtest-notifications", this.disclosureElementLabelC, true);
    },
    resetSendTestDialog: function () {
      this.setState({
        mode: "form",
        errorMessage: null
      });
    },
    show: function () {
      this.dialog = new Dialog({
        "title": "Send a Test Email",
        "content": this
      });
      this.dialog.startup();
      this.dialog.show();
    },
    hide: function () {
      var promise = this.dialog.hide();
      if (promise) {
        promise.then(function () {
          this.dialog.destroy();
          this.dialog = null;
        }.bind(this));
      }
      return false;
    }
  });
});