define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/utils/string", "mojo/context", "mojo/lib/flags", "./_Block", "./_ImageMixin"], function (declare, domAttr, query, domConstruct, array, stringUtils, context, flags, _Block, _ImageMixin) {
  return declare([_Block, _ImageMixin], {
    type: "imageCaption",
    _name: "Image + Text",
    _kbLink: "https://mailchimp.com/help/use-image-caption-content-blocks/",
    _label: "Image + Text",
    _defaultCaption: "Input caption text here. Use the block\'s Settings tab to change the caption position and set other styles.",
    constructor: function () {
      this.captions = [{
        "text": this._defaultCaption,
        "image": null
      }];
      this.selectedCaption = 0;
      this.captionPosition = "bottom"; // [top,left,right,bottom]
      this.captionWidth = "half"; //[half, one-third, two-third, three-quarter]
      this.numberOfCaptions = "1";
      this.align = "center";
      this.styles = [{
        "name": "Text style",
        'selector': '.mcnTextContent, .mcnTextContent p',
        'props': {
          "color": {
            "value": ""
          },
          "font-family": {
            "value": ""
          },
          "font-size": {
            "value": ""
          },
          "font-style": {
            "value": ""
          },
          "font-weight": {
            "value": ""
          },
          "line-height": {
            "value": ""
          },
          "text-align": {
            "value": ""
          }
        }
      }];
      this.styles = this.styles.concat(this._getImageStyles());
      var isMobile = window.app && window.app.nativeUtils && window.app.nativeUtils.getNativeObject();
      if (!isMobile) {
        //Previously we saved caption position/width instead of image position/width.
        //Because trying alter all Neapolitan templates and saved user campaigns to reflect
        //a UI change from caption to image would be difficult, these objects are being used instead.
        this.captionToImagePositionMap = {
          left: "right",
          right: "left",
          top: "bottom",
          bottom: "top"
        };
        this.captionToImageWidthMap = {
          "half": "1/2",
          "one-third": "2/3",
          "two-third": "1/3",
          "three-quarter": "1/4"
        };
        this.imageToCaptionWidthMap = {
          "1/2": "half",
          "2/3": "one-third",
          "1/3": "two-third",
          "1/4": "three-quarter"
        };
      }
    },
    getSelectedCaption: function () {
      return this._getCaption(this.selectedCaption);
    },
    setSelectedCaption: function (caption) {
      this._setCaption(this.selectedCaption, caption);
    },
    setUpCaptions: function () {
      // if this.captions length is greater than numberOfCaption, remove captions
      var numberOfCaptions = Number(this.numberOfCaptions);
      // remove the captions that are not in the count.
      if (numberOfCaptions < this.captions.length) {
        for (var c = this.captions.length; c > numberOfCaptions; c--) {
          delete this.captions[c - 1];
        }
      }
      for (var i = 0; i < numberOfCaptions; i++) {
        var caption = this.captions[i];
        if (!caption) {
          caption = {
            image: null,
            text: this._defaultCaption
          };
          this.captions[i] = caption;
        }
      }
    },
    _getCaption: function (index) {
      var captions = this.captions;
      if (!captions[index]) {
        captions[index] = {
          text: this._defaultCaption,
          image: null
        };
        this.set("captions", captions);
      }
      return captions[index];
    },
    _setCaption: function (index, caption) {
      var captions = this.captions;
      captions[index] = caption;
      this.set("captions", captions);
    },
    onImageUpload: function (image, index) {
      var caption = this._getCaption(index);
      caption.image = image;
      this._setCaption(index, caption);
      this.save();
    },
    onLinkUpdate: function (oldUrl, newUrl, index) {
      var content = this.getContent();
      var link = query('a', content)[index];
      if (link && domAttr.get(link, 'href') == oldUrl) {
        var result;
        if (this.captionPosition == 'left' || this.captionPosition == 'right') {
          result = this._handleHorizontalLinkUpdate(oldUrl, newUrl, link, content);
        } else {
          result = this._handleVerticalLinkUpdate(oldUrl, newUrl, link, content);
        }
        if (result) {
          domConstruct.destroy(content);
          return this.save();
        }
      }
      domConstruct.destroy(content);
      return this.inherited(arguments);
    },
    /**
     * Handles tweaking links for captions that uses left/right positioning
     */
    _handleHorizontalLinkUpdate: function (oldUrl, newUrl, link, content) {
      var textClass = ".mcnCaption" + stringUtils.capitalize(this.captionPosition) + "TextContentContainer";
      var imageClass = ".mcnCaption" + stringUtils.capitalize(this.captionPosition) + "ImageContentContainer";
      var imageContainers = query(imageClass, content);
      var textContainers = query(textClass, content);
      var imageParentContainer = query(link).parents(imageClass)[0];
      var captionIndex;
      // The case that it's an image link which has only one way of handling it.
      if (imageParentContainer) {
        captionIndex = array.indexOf(imageContainers, imageParentContainer);
        return this._updateImageUrl(captionIndex, oldUrl, newUrl);
      } else {
        // Have to handle arbitrary link on the HTML of the caption.
        var contentParentContainer = query(link).parents(textClass)[0];
        // Find the positioning of the index in the context of the container that holds text...
        var linkIndex = array.indexOf(query('a', contentParentContainer), link);
        captionIndex = array.indexOf(textContainers, contentParentContainer);
        return this._updateContentUrl(captionIndex, linkIndex, oldUrl, newUrl);
      }
    },
    _handleVerticalLinkUpdate: function (oldUrl, newUrl, link, content) {
      var captionContainerClass = ".mcnCaption" + stringUtils.capitalize(this.captionPosition) + "Content";
      var textClass = '.mcnTextContent';
      var imageClass = ".mcnCaption" + stringUtils.capitalize(this.captionPosition) + "ImageContent";
      var captionContainers = query(captionContainerClass, content);
      var targetContainer = query(link).parents(captionContainerClass)[0];
      var captionIndex = array.indexOf(captionContainers, targetContainer);
      var imageContainer = query(link).parent(imageClass)[0];
      if (imageContainer) {
        return this._updateImageUrl(captionIndex, oldUrl, newUrl);
      } else {
        // Have to handle arbitrary link on the HTML of the caption.
        var textContainer = query(link).parents(textClass)[0];
        // Find the positioning of the index in the context of the container that holds text...
        var linkIndex = array.indexOf(query('a', textContainer), link);
        return this._updateContentUrl(captionIndex, linkIndex, oldUrl, newUrl);
      }
    },
    /**
     * Helper function to change the url for a particular caption's image.
     */
    _updateImageUrl: function (captionIndex, oldUrl, newUrl) {
      var caption = this.captions[captionIndex];
      if (caption && caption.image && caption.image.link && caption.image.link.href == oldUrl) {
        caption.image.link.href = newUrl;
        if (caption.image.link.text == oldUrl) {
          caption.image.link.text = newUrl;
        }
        return true;
      }
      return false;
    },
    /**
     * Helper function to change the url for a particular link in the content.
     */
    _updateContentUrl: function (captionIndex, linkIndex, oldUrl, newUrl) {
      var caption = this.captions[captionIndex];
      var captionWrap = domConstruct.create('div');
      var captionDom = domConstruct.toDom(caption.text);
      domConstruct.place(captionDom, captionWrap);
      domAttr.set(query('a', captionWrap)[linkIndex], 'href', newUrl);
      caption.text = query(captionWrap).html();
      domConstruct.destroy(captionWrap);
      return true;
    }
  });
});