/**
 * Users are able to set specific start and end dates and times for their campaign. The start and end
 * times should behave similarly, so they've been abstracted to a widget.
 *
 * @module mojo/widgets/ads/facebook/edit-budget/DateTimeInput
 * @see mojo/widgets/ads/facebook/EditBudgetSlat
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./DateTimeInput.html"], function (declare, locale, domConstruct, on, Select, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    dateTime: null,
    /**
     * Set some defaults at widget load time.
     */
    postCreate: function () {
      // Set some defaults for the input.
      var now = new Date();
      var inoneYear = now.getUTCFullYear() + 1;
      var maxdateTime = new Date().setFullYear(inoneYear);
      var maxDate = new Date(maxdateTime);
      // Require a current date
      this.dateInput.constraints.min = now;
      // Require a maximum date
      this.dateInput.constraints.max = maxDate;
      // Auto fill the date with either the current date or what the user passed in.
      this.dateInput.set("value", this._isExpiredDate() ? now : this.dateTime);
      this._populateHours();
      // Fix to prevent double event error on Android devices
      on(this.dateInput, "click", function (e) {
        e.stopPropagation();
      });
    },
    /**
     * Validate the existence of the one property we need. Continues on with default postMixinProperties behavior.
     */
    postMixInProperties: function () {
      if (!this.dateTime) {
        throw new Error("Valid date required");
      }
      this.inherited(arguments);
    },
    /**
     * Format the date for consumption by the backend.
     *
     * @return {string} Date in 2017-12-25 format
     */
    getDate: function () {
      return locale.format(this.dateInput.value, {
        datePattern: "y-M-d",
        selector: "date"
      });
    },
    /**
     * @return {string} String representation of hour of the day
     */
    getTime: function () {
      return this.hourInput.getValue();
    },
    /**
     * Get the value of am or pm.
     *
     * @return {string} - either am or pm
     */
    getTimeMeridian: function () {
      return this.amInput.checked ? this.amInput.value : this.pmInput.value;
    },
    /**
     * Auto-fill the hours of the day into a select box and automatically select a time.
     *
     * @private
     */
    _populateHours: function () {
      this.selectedHour = this._isExpiredDate() ? new Date().getHours() + 2 // Select 2 hours from now if their selected date has passed.
      : this.dateTime.getHours(); // Go with what they selected

      this.hourInput = new Select({
        options: this._getOptions(),
        name: this.id + "_hour",
        id: this.id + "_hour",
        maxHeight: -1,
        "class": "select-small margin--lv3 !margin-top-bottom--lv0 margin-left--lv0"
      }).placeAt(this.hourInputContainer);

      // Set AM/PM based on the next 2 hours.
      if (this.selectedHour <= 11) {
        this.amInput.checked = true;
      } else {
        this.pmInput.checked = true;
      }
    },
    /**
     * Simple check to see if the Date/Time stamp is still valid
     *
     * @return {boolean} - Is the currently selected time older than now?
     * @private
     */
    _isExpiredDate: function () {
      return new Date() > this.dateTime;
    },
    /**
     * Generate all of the appropriate options for the hour dropdown and handle selection.
     *
     * @return {Array} options - Formatted as a collection for consumption by a Select widget.
     */
    _getOptions: function () {
      var options = [];

      // Loop through available hours and generate some select options
      for (var hour = 1; hour <= 12; hour++) {
        options.push({
          value: hour,
          // Add a "0" for 1-9 and `:00` minutes indicator to the hour
          label: (hour < 10 ? "0" : "") + hour + ":00",
          // There's a potential for folks to have 2 hours into the future. Use remainder to select 1 or 2 pm
          // if the selectedHour is the 12:00 block.
          selected: hour === (this.selectedHour <= 12 ? this.selectedHour : this.selectedHour % 12)
        });
      }
      return options;
    }
  });
});