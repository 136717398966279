define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "mojo/url", "mojo/utils", "mojo/user", "mojo/lib/flags"], function (declare, lang, xhr, Deferred, Stateful, mUrl, utils, user, flags) {
  var PRIVACY_BADGE_SAVE_URL = "/landing-pages/save-privacy-badge-settings";

  // set default state of Privacy Settings
  var PrivacySettings = declare([Stateful], {
    enabled: true,
    theme: "light",
    pageId: null,
    _kbLink: "https://mailchimp.com/help/landing-page-privacy/",
    _webKbLink: "https://mailchimp.com/help/about-website-beta/",
    constructor: function (params) {
      if (params.isWebsitePage) {
        this.set("_kbLink", this._webKbLink);
      }
    }
  });
  var api = {
    /**
     * Save config settings for Privacy Badge
     * @param {Object} model - Properties to update
     * @return {Promise} promise from save request
     */
    save: function (model) {
      var url = mUrl.toUrl(PRIVACY_BADGE_SAVE_URL, {
        id: model.pageId
      });
      var setting = {
        enabled: model.get("enabled"),
        theme: model.get("theme")
      };
      if (window.app.isWebsitePage === true) {
        window.ga("send", "event", "Website Builder", "Edit Website Design-Content-Design-Privacy-Badge", "Clicked / Save");
      }
      return xhr.post(url, {
        "handleAs": "json",
        "data": {
          "setting": JSON.stringify(setting) // dojo doesn't serialize json objects
        }
      });
    },
    /**
     * Instantiate a new Campaign from the given properties
     * @param {Object} privacyConfig - Properties to build the PrivacyBadgeSettings instance from
     * @return {Campaign} A full instance
     */
    createFromProperties: function (privacyConfig) {
      return new PrivacySettings(privacyConfig);
    }
  };
  return api;
});