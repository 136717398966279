define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./ImageCardEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/ImageCardEditor/editorMobile.html", "mojo/neapolitan/editors/_DynamicContentMixin"], function (declare, ImageCardEditor, _MobileBlockMixin, tmpl, _DynamicContentMixin) {
  return declare([ImageCardEditor, _MobileBlockMixin, _DynamicContentMixin], {
    type: "imageCard-mobile",
    templateString: tmpl,
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["sectionHeader", "switchContentBtn", "imageSet", "recoverContentWarning"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});