define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "../email-renderers/ImageGroupBlock", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!../editors/blocks/templates/ImageGroupEditor/outputWeb.html"], function (declare, ImageGroupBlock, array, lang, tpl) {
  return declare("mojo.neapolitan.email-renderers.ImageGroupBlock", [ImageGroupBlock], {
    outputTplStr: tpl,
    getOutputModel: function (meta) {
      return {
        images: this._getImageGroupModel(meta)
      };
    },
    _getImageGroupModel: function (meta) {
      var model = [];
      var imageIndex = 0;
      array.forEach(meta.selectedLayout, lang.hitch(this, function (numPerRow) {
        var row = [];
        var flexStyle;
        var imageContainerWidth = this._getCalculatedContainerWidth(numPerRow, meta.containerWidth);
        for (var i = 0; i < numPerRow; i++) {
          if (numPerRow === 2) {
            flexStyle = "0 0 50%";
          } else {
            flexStyle = "1";
          }
          if (meta.images[imageIndex] && meta.images[imageIndex].hasOwnProperty("src")) {
            var imageData = lang.mixin({}, meta.images[imageIndex]);
            imageData.calculatedWidth = imageContainerWidth;
            // Only limit the calculated width of an image if it's wider than it's container
            if (imageData.width < imageContainerWidth) {
              imageData.calculatedWidth = imageData.width;
            } else {
              imageData.calculatedWidth = imageContainerWidth;
            }
            imageData.flexStyle = flexStyle;
            row.push(imageData);
          } else {
            flexStyle = "auto";
            row.push(lang.mixin({
              src: null,
              calculatedWidth: imageContainerWidth,
              index: imageIndex,
              flexStyle: flexStyle
            }));
          }
          imageIndex++;
        }
        model.push(row);
      }));
      return model;
    },
    _getCalculatedContainerWidth: function (numPerRow, containerWidth) {
      if (numPerRow === 2) {
        return Math.floor(containerWidth / 2 - this.outerPadding * 4);
      }
      return Math.floor(containerWidth - this.outerPadding * 4);
    }
  });
});