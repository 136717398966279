/**
 * Content Studio (Formerly Content Manager) extends TopSliderPane (see mojo/widgets/TopSliderPane.js)
 * Purpose: show users uploaded and sync'd content (i.e. images) from varying sources
 * Sources are created in "content-manager/Api" (see mojo/widgets/content-manager/Api.js)
 * Sources include files, products, Instagram images, Giphy, My Logos
 *
 * Lifecycle of Content Studio
 *  - show() - instance of ContentManager (sometimes called "FileManager") with passed params:
 *      before calling "inherited", set instance properties from params:
 *          - allowedFileTypes
 *          - actions
 *          - what else?: allowedImageSizes, onSelectContentItem, contextMessage, filter
 *      - call "inherited" show for TopSliderPane
 *      - with TopSliderPane, _paneDidShow is called after an animation is complete
 *  - _paneDidShow()
 *      - this is local to Content Studio, don't pass in as a param
 *      - fires after this.show() completes slideIn animation
 *  - _paneDidHide()
 *      - this is local to Content Studio, don't pass in as a param
 *      - fires after this.hide();
 * Usage:
 *  ContentManager.show({ allowedFileTypes: [], actions: []})
 *
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "vendor/lodash-amd/find", "vendor/lodash-amd/isEqual", "vendor/lodash-amd/values", "vendor/lodash-amd/forEach", "dijit/_Templated", "dijit/_Widget", "dojo/text!./templates/ContentManagerContainer.html", "dojo/text!./templates/ContentManagerPane.html", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation", "./TopSliderPane", "./content-manager/ContentManager", "./content-manager/Api"], function (declare, lang, on, topic, domClass, _find, _isEqual, _values, _forEach, _Templated, _Widget, templateWithoutTopSlider, template, context, flags, I18nTranslation, TopSliderPane, ContentManager, api) {
  var ContentManagerPane = declare([TopSliderPane], {
    templateString: template,
    content: "<div data-dojo-attach-point='contentManager__node' class='full-height'></div>",
    titleText: "Content Studio",
    isOverlay: true,
    mobileCloseLabel: "Close",
    desktopCloseLabel: "Close",
    constructor: function (isOverlay) {
      this.isOverlay = isOverlay;
      if (!this.isOverlay) {
        this.templateString = templateWithoutTopSlider;
      }
      this.inherited(arguments);
    },
    postMixInProperties: function () {
      if (context.flagIsOn(flags.EEE_BACKFILL_STRINGS_NUNI)) {
        I18nTranslation.initialize().then(function (translations) {
          this.mobileCloseLabel = translations.content_manager_pane_close_label;
          this.desktopCloseLabel = translations.content_manager_pane_close_label;
        }.bind(this));
      }
      this.inherited(arguments);
    },
    postCreate: function () {
      if (this.closeLinkMobile) {
        on(this.closeLinkMobile, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          this.hide();
        }));
      }
      if (this.closeLink) {
        on(this.closeLink, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          this.hide();
        }));
      }
      domClass.toggle(this.domNode, "kioskMode", this.isKioskMode);
      this.CM = new ContentManager({
        onComplete: this.hide.bind(this),
        isOverlay: true
      });
      this.CM.placeAt(this.contentManager__node);
    },
    startup: function () {
      this.CM.startup();
    },
    /**
     * Callback invoked each time the overlay is brought to the foreground, but after animation is completed.
     * @description _paneDidShow will setup module event listeners for fileDrag and window resize
     * @override
     * @private
     */
    _paneDidShow: function () {
      this.CM._setUpFileDrag();
      this.CM._setupWindowResize();
    },
    /**
     * _paneWillShow fires when pane content is hidden
     * @description make changed to the ui, calling setState, render,
     * @override
     * @private
     */
    _paneWillShow: function () {
      this.CM._refresh();
    },
    /**
     * Callback invoked each time the overlay is hidden, only after animation is complete.
     * remove resize and orientationChange event listeners
     * detach drag and drop listener for files
     * publish event FileManager is hidden
     * @override
     * @private
     */
    _paneDidHide: function () {
      this.CM._cleanUpEvents();
      if (this.onHide) {
        this.onHide();
      }
    },
    /**
     * Overrides show, to fix up parameters and wipe state clean, before actually calling show.
     *
     * @override TopSliderPane.show()
     */
    show: function (params) {
      this.CM._handleShow(params);
      this.onHide = params.onHide;
      return this.inherited(arguments);
    },
    hide: function () {
      this.CM._handleHide();
      return this.inherited(arguments);
    },
    importFromUrl: function (url, skipUploadPane, contentId) {
      // returns a promise
      return this.CM.importFromUrl(url, skipUploadPane, contentId);
    },
    uploadFile: function (file, folder, skipUploadPane) {
      // returns a promise
      return this.CM.uploadFile(file, null, true);
    }
  });

  /**
   * This stores the singleton instance of the ContentManager class.
   * It is lazy-loaded by module._getContentManager().
   *
   * @type {ContentManager|null}
   */
  var contentManagerPane = null;
  var module = {
    _getContentManager: function (isOverlay) {
      isOverlay = isOverlay === undefined ? true : isOverlay;
      if (!contentManagerPane) {
        isOverlayRender = isOverlay;
        contentManagerPane = new ContentManagerPane(isOverlayRender);
        contentManagerPane.startup();
      } else if (contentManagerPane && isOverlayRender !== isOverlay) {
        contentManagerPane.destroyRecursive();
        isOverlayRender = isOverlay;
        contentManagerPane = new ContentManagerPane(isOverlayRender);
        contentManagerPane.startup();
      }
      return contentManagerPane;
    },
    show: function (params) {
      // returns a promise that doesn't resolve
      return this._getContentManager(params.isOverlay).show(params);
    },
    hide: function (params) {
      // returns a promise that does resolve
      var overlay = true;
      if (params && params.hasOwnProperty("isOverlay")) {
        overlay = params.isOverlay;
      }
      return this._getContentManager(overlay).hide();
    },
    isShown: function () {
      // returns a boolean
      return contentManagerPane && contentManagerPane.displayActive === true;
    },
    importFromUrl: function (url, skipUploadPane, contentId) {
      // returns a promise
      return this._getContentManager().importFromUrl(url, skipUploadPane, contentId);
    },
    uploadFile: function (file, folder, skipUploadPane) {
      // returns a promise
      return this._getContentManager().uploadFile(file, null, true);
    },
    getInfo: function (contentId, sourceId) {
      // returns a promise
      return api.getInfo(contentId, sourceId);
    }
  };
  return module;
});