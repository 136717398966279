define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/number", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojox/html/entities", "mojo/url", "mojo/utils", "mojo/user", "mojo/lib/flags", "mojo/app/lists/members",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/segment-bar.html",
// Widgets
"mojo/analytics/parts/_Part", "dijit/form/DropDownButton", "../tags/TagOverlay", "./SendCampaignDropdown",
// API
"../tags/Api", "mojo/utils/I18nTranslation", "mojo/context"], function (declare, lang, domAttr, domClass, domConstruct, ioQuery, number, on, htmlentities, mUrl, utils, user, flags, listMembers, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, barTplStr, _Part, DropDownButton, TagOverlay, SendCampaignDropdown, tagApi, I18nTranslation, context) {
  return declare([_Part, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: barTplStr,
    target: null,
    listId: null,
    segmentButton: null,
    segmentOverlay: null,
    editSegment: null,
    tagSegmentOverlay: null,
    mode: null,
    sendCampaignDropdown: null,
    defaultDropdownLabel: "New Segment",
    previewName: null,
    editSegmentLabel: "Edit Segment",
    createSegment: "Create a segment",
    editingSegment: "Editing segment: ",
    viewingSegment: "Viewing segment: ",
    rerunLabel: "Rerun",
    saveSegmentLabel: "Save Segment",
    constructor: function () {
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        I18nTranslation.initialize();
        if (!I18nTranslation.isTranslationObjectEmpty()) {
          this.editSegmentLabel = I18nTranslation.translate("audience_management_edit_segment_label");
          this.createSegment = I18nTranslation.translate("audience_management_create_segment");
          this.editingSegment = I18nTranslation.translate("audience_management_editing_segment");
          this.viewingSegment = I18nTranslation.translate("audience_management_viewing_segment");
          this.rerunLabel = I18nTranslation.translate("audience_management_rerun_label");
          this.saveSegmentLabel = I18nTranslation.translate("audience_management_save_segment");
        }
      }
    },
    init: function () {
      this.setupSegmentDropdown();
      this.setupEventHandlers();
      this.setupSendCampaign();
      if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
        this.updateContactCount();
      } else {
        this.updateContactCountUntranslated();
      }
      this.updateAdvancedSegment(this.target.segment);
    },
    /**
     * Get the query string for the current page as an object
     * @returns {object} query converted to object
     */
    getQuery: function () {
      var queryString = window.location.search && window.location.search.slice(1);
      queryString = ioQuery.queryToObject(queryString);
      return queryString;
    },
    /**
     * Set up UI for segment selection from dropdown
     */
    setupSegmentDropdown: function () {
      this.segmentButton = new DropDownButton({
        "label": this.target.segment && this.target.segment.name ? this.target.segment.name : this.defaultDropdownLabel,
        "dropDown": this.segmentOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.viewSegmentDropdownButton);
      this.segmentButton.startup();
    },
    /**
     * Create the dropdown button and append the overlay
     */
    setupTagSegmentDropdown: function () {
      this.tagSegmentButton = new DropDownButton({
        "label": "Tag All",
        "dropDown": this.tagSegmentOverlay,
        "dropDownPosition": ["below", "above"]
      }, this.tagSegmentDropdownButton);
      this.tagSegmentButton.startup();
    },
    /**
     * Set up segment bar events
     */
    setupEventHandlers: function () {
      on(this.closeButton, "click", lang.hitch(this, "onClose"));
      on(this.editLink, "click", lang.hitch(this, function () {
        window.ga("send", "event", "segments", "cta", "inline", {
          cd53: "Edit segment",
          cd56: "open segment editor"
        });
        this.onEditSegment(null);
      }));
      on(this.saveSegmentButton, "click", lang.hitch(this, "onSaveNewSegment"));
      on(this.rerunAdvancedButton, "click", lang.hitch(this, "onRerunAdvanced"));
      this.target.watch("refreshing", lang.hitch(this, function () {
        if (context.flagIsOn(flags.I18N_AUDIENCE_MANAGEMENT)) {
          this.updateContactCount();
        } else {
          this.updateContactCountUntranslated();
        }
      }));
      this.target.watch("segment", lang.hitch(this, function () {
        this.updateSelectedSegment(this.target.segment);
      }));
      on(this.tagSegmentOverlay, "applyTag", lang.hitch(this, "addTagToSegment"));
      on(this.tagSegmentOverlay, "createTag", lang.hitch(this, "createAndAddTagToSegment"));
    },
    /**
     * Append the passed-in campaign send dropdown
     */
    setupSendCampaign: function () {
      this.sendCampaignDropdown = new SendCampaignDropdown({
        target: this.target,
        listId: this.listId,
        campaignOrigin: "seg-bar-top",
        csrfToken: user.getCsrfToken()
      }, this.sendCampaignButton);
      this.sendCampaignDropdown.startup();
    },
    /**
     * Update the text displayed for the segment contact count
     */
    updateContactCount: function () {
      var segment = this.target.segment;
      if (segment) {
        var message = "";
        var name = segment.name ? segment.name : I18nTranslation.translate("contacts_table_segment_label");
        switch (this.target.segment.status) {
          case "started":
            message = I18nTranslation.translate("contacts_table_is_generating", [name]);
            break;
          case "errored":
            message = I18nTranslation.translate("contacts_table_failed_to_generate", [name]);
            break;
          default:
            message = this.selectSegmentBarMessage(this.target.total);
        }
        var encodedMessage = htmlentities.encode(message);
        if (segment.lastRunTimeAgo) {
          var encodedLastRunMessage = htmlentities.encode(segment.lastRunTimeAgo);
          this.segmentContactCount.innerHTML = encodedMessage + " &bull; " + encodedLastRunMessage;
        } else {
          this.segmentContactCount.innerHTML = encodedMessage;
        }
      }
    },
    selectSegmentBarMessage: function (total) {
      var message = "";
      if (this.target.segment.type === "saved") {
        message = I18nTranslation.translate("contacts_match_conditions", {
          "count": total
        });
      } else {
        this.previewSegmentLabel.textContent = "";
        var source = this.getQuery().source;
        switch (source) {
          case "EME":
            message = I18nTranslation.translate("contacts_match_conditions_eme", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          case "Tags":
            message = I18nTranslation.translate("contacts_match_conditions_tags", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          case "CLV":
            message = I18nTranslation.translate("contacts_match_conditions_clv", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          case "CLS":
            message = I18nTranslation.translate("contacts_match_conditions_cls", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          case "TopLocations":
            message = I18nTranslation.translate("contacts_match_conditions_top_locations", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          case "RecentGrowth":
            message = I18nTranslation.translate("contacts_match_conditions_recent_growth", {
              "count": total,
              "preview_name": this.previewName
            });
            break;
          default:
            message = I18nTranslation.translate("contacts_match_conditions", {
              "count": total
            });
        }
      }
      return message;
    },
    /**
     * Update the text displayed for the segment contact count - pre-translated version remains for flag-off state
     */
    updateContactCountUntranslated: function () {
      var segment = this.target.segment;
      if (segment) {
        var message = "";
        var name = segment.name ? segment.name : "Segment";
        switch (this.target.segment.status) {
          case "started":
            message = name + " is generating.";
            break;
          case "errored":
            message = name + " failed to generate.";
            break;
          default:
            message = number.format(this.target.total) + " contact";
            if (this.target.total !== 1) {
              message += "s match";
            } else {
              message += " matches";
            }
            message = this.selectSegmentBarMessageUntranslated(message);
        }
        var encodedMessage = htmlentities.encode(message);
        if (segment.lastRunTimeAgo) {
          var lastRunMessage = "Last ran " + segment.lastRunTimeAgo;
          var encodedLastRunMessage = htmlentities.encode(lastRunMessage);
          this.segmentContactCount.innerHTML = encodedMessage + " &bull; " + encodedLastRunMessage;
        } else {
          this.segmentContactCount.innerHTML = encodedMessage;
        }
      }
    },
    selectSegmentBarMessageUntranslated: function (message) {
      if (this.target.segment.type === "saved") {
        message += " your conditions";
      } else {
        this.previewSegmentLabel.textContent = "";
        var source = this.getQuery().source;
        switch (source) {
          case "EME":
            message += " the condition Email Marketing Engagement is " + this.previewName;
            break;
          case "Tags":
            message += " for condition Tag is " + this.previewName;
            break;
          case "CLV":
            message += " the condition Customer Lifetime Value is " + this.previewName;
            break;
          case "CLS":
            message += " the condition Likelihood to Purchase Again is " + this.previewName;
            break;
          case "TopLocations":
            message += " the condition Location is in " + this.previewName;
            break;
          case "RecentGrowth":
            message += " the condition Sourced from " + this.previewName;
            break;
          default:
            message += " your conditions";
        }
      }
      return message;
    },
    /**
     * Update the label attr on the dropdownbutton to reflect the current segment
     * Show toaster message on selection of new segment
     *
     * @param {object} segment object
     */
    updateSelectedSegment: function (segment) {
      if (segment && segment.name) {
        if (segment.name !== this.segmentButton.label) {
          utils.toast(this.viewingSegment + segment.name);
          domAttr.set(this.segmentButton, "label", segment.name);
          this.updateAdvancedSegment(segment);
        }
        this.segmentButton.closeDropDown();
      }
    },
    /**
     * Set up rerun button for advanced segments
     *
     * @param {object} segment object
     */
    updateAdvancedSegment: function (segment) {
      if (segment && segment.hasOwnProperty("type") && segment.type === "advanced") {
        domClass.remove(this.rerunAdvancedButton, "hide");
      } else {
        domClass.add(this.rerunAdvancedButton, "hide");
      }
    },
    /**
     * Apply tag to current segment or segment preview
     * @param {object} tag to apply
     */
    addTagToSegment: function (tag) {
      tagApi.tagSegmentOrPreview(this.listId, tag.tag_id, lang.hitch(this, "addTagToSegmentCallback"));
    },
    /**
     * Create a new tag and apply it to segment or segment preview
     */
    createAndAddTagToSegment: function () {
      tagApi.createTagSegmentPreview(this.listId, this.tagSegmentOverlay.getInputValue(), lang.hitch(this, "addTagToSegmentCallback"));
    },
    /**
     * Success callback once tags are applied to segment or segment preview
     */
    addTagToSegmentCallback: function () {
      var message = "Applying tag to ";
      if (this.mode === "preview") {
        message += "segment preview.";
      } else {
        message += "segment.";
      }
      utils.toast(message);
    },
    /**
     * Set the mode of the segment bar and re-render
     * @param {string} mode to show the bar in
     */
    setMode: function (mode) {
      this.mode = mode;
      this.renderCurrentMode();
    },
    /**
     * Render the correct segment bar UI based on the current mode
     */
    renderCurrentMode: function () {
      switch (this.mode) {
        case "view":
          this.showViewPane();
          break;
        case "preview":
          this.showPreviewPane();
          break;
        case "create":
        case "edit":
          this.showCreatePane();
          break;
        default:
          break;
      }
    },
    /**
     * Show UI for viewing the members of a segment
     */
    showViewPane: function () {
      this.hideAllPanes();
      domClass.remove(this.segmentViewPreviewContainer, "hide");
      domClass.remove(this.viewSegmentDropdownButtonContainer, "hide");
    },
    /**
     * Show UI for previewing the members of a segment
     */
    showPreviewPane: function () {
      this.hideAllPanes();
      this.previewSegmentLabel.textContent = this.previewName || this.defaultDropdownLabel;
      domClass.remove(this.segmentViewPreviewContainer, "hide");
      domClass.remove(this.previewSegmentLabel, "hide");
      domClass.remove(this.saveSegmentButton, "hide");
    },
    /**
     * Show UI for the segment builder
     */
    showCreatePane: function () {
      this.hideAllPanes();
      if (this.mode === "edit" && this.target.segment && this.target.segment.name) {
        this.segmentCreatorLabel.innerText = this.editingSegment + this.target.segment.name;
      } else {
        this.segmentCreatorLabel.innerText = this.createSegment;
      }
      domClass.remove(this.createSegmentContainer, "hide");
    },
    /**
     * Hide all segmentation bar panes (view, preview, and create)
     */
    hideAllPanes: function () {
      domClass.add(this.segmentViewPreviewContainer, "hide");
      domClass.add(this.createSegmentContainer, "hide");
      domClass.add(this.previewSegmentLabel, "hide");
      domClass.add(this.saveSegmentButton, "hide");
      domClass.add(this.viewSegmentDropdownButtonContainer, "hide");
    },
    /**
     * Override of _Part canDisplay function
     * @returns {boolean} can display value
     */
    canDisplay: function () {
      return true;
    },
    /**
     * Event hooks
     */
    onClose: function () {
      listMembers.clearContactTableReferrer();
      this.previewName = null;
      this.hideAllPanes();
    },
    onEditSegment: function () {},
    onSaveNewSegment: function () {
      window.ga("send", "event", "segments", "open naming modal");
    },
    onRerunAdvanced: function () {
      window.ga("send", "event", "segments", "rerun", "advanced");
    },
    onSendAction: function () {},
    onAdAction: function () {},
    onLookalikeAction: function () {}
  });
});