define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/debounce", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/form/Select", "velocity/velocity", "mojo/url", "mojo/utils", "mojo/lib/clipboard", "mojo/widgets/FeedbackBlock", "dijit/_Widget", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/ManualConnection.html"], function (declare, lang, Deferred, debounce, xhr, domConstruct, domClass, on, Select, Velocity, mUrl, mUtils, mClipboard, FeedbackBlock, _Widget, _TemplatedMixin, _WidgetInTemplateMixin, templateStr) {
  return declare([_Widget, _TemplatedMixin, _WidgetInTemplateMixin], {
    templateString: templateStr,
    // constructor
    platform: null,
    // data-dojo-attach-point(s)
    checkConnectionNode: null,
    codeSectionNode: null,
    copyCodeNode: null,
    createListNode: null,
    displayCodeNode: null,
    errorMessageNode: null,
    getCodeFormNode: null,
    getCodeNode: null,
    listSelectNode: null,
    urlFeedbackNode: null,
    urlInputNode: null,
    defaultUrl: null,
    /** custom setters **/

    // update the list select dropdown options when this.lists is changed
    lists: null,
    _setListsAttr: function (lists) {
      if (lists.length === 0) {
        // user account does not have any lists
        this.listSelectWidget.set("options", [{
          label: "No audiences",
          value: ""
        }]);
        this.listSelectWidget.set("disabled", true);
        domClass.add(this.getCodeNode, "disabled");
        domClass.remove(this.createListNode, "display--none");
      } else {
        this.listSelectWidget.set("options", this._formatListsAsOptions(lists));
        this.listSelectWidget.set("disabled", false);
      }
      this.listSelectWidget.reset();
      this._set("lists", lists);
    },
    // required by requestManualCode
    selectedList: null,
    _setSelectedListAttr: function (listData) {
      var listObject = {
        name: listData.label,
        id: listData.value
      };
      this._set("selectedList", listObject);
    },
    // code that users will paste to their site
    mcScript: null,
    _setMcScriptAttr: {
      node: "codeSectionNode",
      type: "innerText"
    },
    // set the error feedback block
    errorMessage: null,
    _setErrorMessageAttr: function (error) {
      domConstruct.empty(this.errorMessageNode);
      // clear error by setting to null
      if (error !== null) {
        new FeedbackBlock({
          type: "error",
          title: error.title,
          message: error.message
        }).placeAt(this.errorMessageNode);
      }
      this._set("errorMessage", error);
    },
    constructor: function () {
      // initialize with loading state
      this.listSelectWidget = new Select({
        options: [{
          label: "Loading...",
          value: "-1"
        }],
        disabled: true
      });
    },
    postCreate: function () {
      // place the list selection
      this.listSelectWidget.placeAt(this.listSelectNode);
      if (this.defaultUrl) {
        //if we are passed a default url show
        this.urlInputNode.value = this.defaultUrl;
      }
      this.own(
      // user types in store URL input
      on(this.urlInputNode, "keyup", this._handleUrlChange.bind(this)),
      // user selects a new list from dropdown
      on(this.listSelectWidget, "change", this._handleListSelect.bind(this)),
      // submit form to request code
      on(this.getCodeNode, "click", this._handleGetCode.bind(this)),
      // copy the displayed code to clipboard
      on(this.copyCodeNode, "click", this._handleCopyCode.bind(this)),
      // check for a successful script connection
      on(this.checkConnectionNode, "click", this._handleCheckConnection.bind(this)));

      // fetch user lists from server
      // repopulates this.listSelectWidget with formatted list options
      this._fetchUserLists().then(this.set.bind(this, "lists"));
    },
    /** event handlers **/

    _handleUrlChange: function (event) {
      var value = event.target.value;
      // text value did not change
      if (value === this.siteUrl) {
        return;
      }
      this._hideUrlError();
      this.siteUrl = value;
    },
    _handleListSelect: function (listId) {
      var listOptionObject = this.listSelectWidget._getSelectedOptionsAttr();
      this.set("selectedList", listOptionObject);
    },
    _handleGetCode: function (event) {
      event.preventDefault();
      var siteUrl = this.urlInputNode.value;
      this._showButtonLoading(this.getCodeNode);
      this.set("errorMessage", null);
      window.ga("send", "event", "connected sites", "platform connect", "button", {
        "dimension53": "Get Code",
        "dimension54": "/account/connected-sites/generate-manual-site",
        "dimension62": "Squarespace"
      });
      this._validateSiteUrl(siteUrl).then(this._handleValidationResponse.bind(this)).then(this._requestManualCode.bind(this, siteUrl, this.selectedList, this.platform)).then(this._displayCodeSection.bind(this), function (error) {
        // catch error
      });
    },
    _handleCopyCode: function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.mcScript) {
        try {
          mClipboard.copy(this.mcScript);
          mUtils.toast("Code copied to clipboard!");
        } catch (error) {
          mUtils.toast("Error. Please copy using your mouse and keyboard.", "error");
        }
      }
    },
    _handleCheckConnection: function (event) {
      event.preventDefault();
      event.stopPropagation();
      this._showButtonLoading(this.checkConnectionNode);
      this.set("errorMessage", null);
      this._verifySiteConnection(this.siteId).then(lang.hitch(this, function (response) {
        if (response.verified) {
          // accept terms of service
          xhr(mUrl.toUrl("/account/connected-sites/accept-terms"));
          // Send site connected transactional email
          xhr(mUrl.toUrl("/account/connected-sites/send-site-connected-email"));
          // redirect to site detail page with success banner
          window.location = mUrl.toUrl("/account/connected-sites/connection-success");
        } else {
          var errorMessage = response.message ? response.message : "Uh oh, we couldn't connect your site.  Check your code and try again.";
          this._ajaxError({
            title: "",
            message: errorMessage,
            button: this.checkConnectionNode
          });
        }
      }));
    },
    _handleValidationResponse: function (response) {
      // return deferred for .then chaining
      var deferred = new Deferred();
      if (response.isValidUrl === "false") {
        this._showUrlError(response.message);
        this._removeButtonLoading(this.getCodeNode);
        deferred.cancel("Invalid site URL.");
        return deferred;
      }
      // show message if there is not a valid list selected
      if (!this.selectedList) {
        this._ajaxError({
          title: "No audience",
          message: "Uh oh! Looks like you haven't selected an audience to connect.",
          button: this.getCodeNode
        });
        deferred.cancel("No audience selected.");
        return deferred;
      }
      deferred.resolve();
      return deferred;
    },
    /** dom modifiers **/

    _showUrlError: function (message) {
      domClass.add(this.urlFeedbackNode, "invalid-error");
      this.urlFeedbackNode.innerHTML = message;
    },
    _hideUrlError: function () {
      domClass.remove(this.urlFeedbackNode, "invalid-error");
      this.urlFeedbackNode.innerText = "";
    },
    _showButtonLoading: function (button) {
      domClass.add(button, "button-loading");
      domClass.add(button, "disabled");
    },
    _removeButtonLoading: function (button) {
      domClass.remove(button, "button-loading");
      domClass.remove(button, "disabled");
    },
    _displayCodeSection: function (response) {
      this._removeButtonLoading(this.getCodeNode);
      this.set("mcScript", response.code);
      this.set("siteId", response.siteId);
      Velocity(this.getCodeFormNode, "transition.slideUpOut", {
        duration: 250,
        complete: function () {
          Velocity(this.displayCodeNode, "transition.slideDownIn", {
            duration: 250
          });
        }.bind(this)
      });
    },
    /** AJAX calls **/

    _verifySiteConnection: function (siteId) {
      var deferred = new Deferred();
      xhr.get(mUrl.toUrl("/account/connected-sites/check-connection?site_id=" + siteId), {
        handleAs: "json"
      }).then(function (response) {
        deferred.resolve(response);
      }, lang.hitch(this, function (error) {
        this._ajaxError({
          title: "Verification failed.",
          message: error.response.data.error,
          button: this.checkConnectionNode
        });
        deferred.reject(error);
      }));
      return deferred;
    },
    _requestManualCode: function (siteUrl, list, platform) {
      var deferred = new Deferred();
      xhr.get(mUrl.toUrl("/account/connected-sites/generate-manual-site" + "?site_url=" + siteUrl + "&platform=" + platform + "&list_id=" + list.id + "&list_name=" + list.name), {
        handleAs: "json"
      }).then(function (response) {
        deferred.resolve(response);
      }, lang.hitch(this, function (error) {
        this._ajaxError({
          title: "Unable to retrieve code.",
          message: error.response.data.error,
          button: this.getCodeNode
        });
        deferred.reject(error);
      }));
      return deferred;
    },
    _validateSiteUrl: function (url) {
      var deferred = new Deferred();
      xhr.get(mUrl.toUrl("/account/connected-sites/is-valid-url?platform=other&site-url=" + url), {
        handleAs: "json"
      }).then(function (response) {
        deferred.resolve(response);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred;
    },
    _fetchUserLists: function () {
      var deferred = new Deferred();
      xhr.get(mUrl.toUrl("/account/connected-sites/get-lists", {}), {
        handleAs: "json"
      }).then(function (responseLists) {
        deferred.resolve(responseLists);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred;
    },
    /** utility helpers **/

    _formatListsAsOptions: function (listsData) {
      return listsData.map(function (list) {
        return {
          label: list["list_name"],
          value: list["list_id"]
        };
      });
    },
    _ajaxError: function (error) {
      this.set("errorMessage", {
        title: error.title,
        message: error.message
      });
      if (error.button) {
        this._removeButtonLoading(error.button);
      }
    }
  });
});