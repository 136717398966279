/**
 * Widget built for the "Sends to" Row in the 1-Click Single Welcome Automation Checklist
 *
 * @module mojo/widgets/checklist-editor/items/AutomationRecipients.js
 * @see mojo/views/automations/single-email-welcome/edit.js
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/widgets/checklist-editor/items/ItemButtons", "mojo/widgets/Renderable", "mojo/user", "mojo/lib/flags", "dijit/form/Select", "dijit/form/CheckBox", "dojo/text!./templates/automation-recipients.html"], function (declare, lang, ItemButtons, Renderable, user, flags, Select, Checkbox, tpl) {
  var AutomationRecipients = declare([Renderable], {
    templateString: tpl,
    constructor: function (props) {
      var recipients = props.model.recipients;
      this.state = {
        listName: props.model.list_name,
        delayString: recipients.delayString,
        delayValue: recipients.delayValue,
        delayDirection: recipients.delayDirection === "-" ? "before" : "after",
        landingPageName: recipients.landingPageName,
        hasSegmentCondition: recipients.hasSegmentCondition,
        includesImportedContacts: recipients.includesImportedContacts
      };
      this.userCanIncludeImportedContacts = props.userCanIncludeImportedContacts;
      this.importedContactsString = this._getImportedContactsString(this.state.includesImportedContacts);

      // TODO: When we get more segment conditions will probably need a method to switch between the type
      this.segmentCondition = this.state.hasSegmentCondition ? ", from " + this.state.landingPageName : "";
    },
    /**
     * Method to return a string for if a user is including imported contacts to the recipients.
     * Checks the value of the checkbox to toggle what is returned
     *
     * @param {boolean} includesImportedContacts - Checks to see if imported contacts are included in order to update the string.
     * @returns {String}
     * @private
     */
    _getImportedContactsString: function (includesImportedContacts) {
      return includesImportedContacts ? "or are imported to" : "";
    },
    getData: function () {
      return {
        trigger: {
          delayValue: this.state.delayValue,
          includesImportedContacts: this.state.includesImportedContacts
        }
      };
    },
    /**
     * Creates the delay trigger dropdown when a user edits the trigger
     *
     * @private
     */
    _renderDelayDropdown: function () {
      var delayOptions = [{
        label: "Immediately",
        value: "now"
      }, {
        label: "1 Hour",
        value: "+1 hour"
      }, {
        label: "6 Hours",
        value: "+6 hour"
      }, {
        label: "1 day",
        value: "+1 day"
      }];
      this.delayDropdown = new Select({
        options: delayOptions,
        value: this.state.delayValue,
        "class": "!margin--lv0"
      });
      this.delayDropdown.placeAt(this.delayDropdownContainer);
      this.delayDropdown.watch("value", this._setDelayValue.bind(this));
    },
    /**
     * On change of the trigger delay dropdown, update the delay value.
     *
     * @private
     */
    _setDelayValue: function () {
      this.setState({
        delayValue: this.delayDropdown.value
      });
    },
    /**
     * Creates the checkbox for importing subscribed contacts
     *
     * @private
     */
    _renderImportCheck: function () {
      this.importBox = new Checkbox({
        checked: this.state.includesImportedContacts,
        onChange: this._setImportConditionString.bind(this)
      });
      this.importBox.placeAt(this.importContactsContainer);
    },
    /**
     * Method to toggle the recipients string to append "or are imported to" based on
     * the checkbox's checked value.
     *
     * @param {boolean} isChecked - Gets the value of the checkbox of whether its checked or not
     * @private
     */
    _setImportConditionString: function (isChecked) {
      this.importedContactsString = this._getImportedContactsString(isChecked);
      this.setState({
        includesImportedContacts: isChecked
      });
    },
    /**
     * Creates the save and cancel button for the checklist active state (edit mode)
     *
     * @private
     */
    _renderItemButtons: function () {
      if (this.itemButtons) {
        this.itemButtons.destroy();
      }
      this.itemButtons = new ItemButtons({
        label: "Recipients",
        getData: this.getData.bind(this),
        activate: this.activate,
        deactivate: this.deactivate,
        isActive: this.isActive,
        cancel: this.cancel,
        onSave: this.onSave,
        isValid: true
      });
      this.itemButtons.placeAt(this.recipientsItemButtonsContainer);
    },
    render: function () {
      this.inherited(arguments);
      if (this.isActive) {
        this._renderItemButtons();
        this._renderDelayDropdown();

        // Only render the checkbox if the flag is on
        if (this.userCanIncludeImportedContacts) {
          this._renderImportCheck();
        }
      }
    }
  });
  AutomationRecipients.isComplete = function (recipients) {
    return recipients.delay !== null && recipients.delayValue !== null;
  };
  AutomationRecipients.isValid = AutomationRecipients.isComplete;
  return AutomationRecipients;
});