define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module",
// Mixins
"./EditContentSlat", "../mobile-checklist/_EventAdapterMixin"], function (declare, EditContentSlat, _EventAdapterMixin) {
  return declare([EditContentSlat, _EventAdapterMixin], {
    actionBarEnabled: false,
    // Map events to parent widget listeners
    onPostSuccess: function (payload) {
      this._updateItemData();
      this.onDone(payload);
    },
    onPostErrorFeedbackGiven: function () {
      this.onDoneWithError();
    },
    /**
     * Looks into carousel items to determine if the ad has content.
     * Enough content in order to create a preview (title, image, and link)
     *
     * @return {boolean} - Is there saved and valid content within the ad?
     */
    hasContent: function () {
      return this.carousel.hasValidSavedContent();
    },
    /**
     * Update the carousel content in order to keep the data
     * in sync for comparison
     *
     * @private
     */
    _updateItemData: function () {
      var currentCarouselItemIndex = this.carousel._getCurrentCarouselItemIndex();
      this.carousel.updateItemData(currentCarouselItemIndex,
      // Update current carousel item
      false // No need to reset buttons
      );
    },
    // Handle actions being sent to the widget
    __save: function () {
      this.handleSave();
    },
    /**
     * TODO: Handle Cancel and back when the rest of the plumbing is in place.
     */
    __cancel: function () {
      this.onExit();
    },
    __back: function () {}
  });
});