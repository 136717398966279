define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil"], function (declare, base, mUrl, tabContainerUtil) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      if (this.activeSection === "order-details" || mUrl.getHashParams().t === "order-details-section") {
        var orderDetailsUrl = new URL(window.location.href).searchParams.get("order_id");
        if (orderDetailsUrl) {
          this.modules.orders.viewOrderDetails(orderDetailsUrl);
        }
        if (mUrl.getHashParams().id) {
          this.modules.orders.viewOrderDetails(mUrl.getHashParams().id);
        }
      }
      if (this.activeSection === "credit-details" || mUrl.getHashParams().t === "credit-details-section") {
        var creditDetailsUrl = new URL(window.location.href).searchParams.get("credit_id");
        if (creditDetailsUrl) {
          this.modules.creditLedger.openCreditDetails(creditDetailsUrl);
        }
        if (mUrl.getHashParams().id) {
          this.modules.creditLedger.openCreditDetails(mUrl.getHashParams().id);
        }
      }
    }
  });
});