define(["dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/date/locale", "dojo/text!./templates/automation-status.html", "mojo/widgets/Renderable", "mojo/user", "mojo/url", "mojo/utils", "mojo/api/automation", "../ConfirmAutomation", "../PauseAutomation"], function (lang, declare, locale, tpl, Renderable, user, mUrl, utils, automationApi, ConfirmAutomation, PauseAutomation) {
  return declare([Renderable], {
    templateString: tpl,
    /**
     * Constant url for Finish later link
     */
    finishLaterUrl: mUrl.toUrl("/campaigns"),
    enableButtonWhenUserIsPaused: null,
    state: {
      isItemActive: false
    },
    constructor: function (props) {
      this.enableButtonWhenUserIsPaused = user.isPaused;
      this.state.model = props.model;
      this.state.completeItems = props.completeItems;
      this.state.remainingItems = props.remainingItems;

      // this field is true when all checklist slats are complete and valid
      this.state.isReady = props.isReady;
      this.state.hasPro = user.pro;
      this.state.isManager = user.hasRole("manager");
      this.state.heading = "Create your automation!";

      // this is the default button state, it will appear when the status == "draft"
      this.state.buttons = [{
        classes: "c-slatButton button p1",
        otherAttrs: this.enableButtonWhenUserIsPaused ? "data-dojo-attach-event='click:openConfirmAutomationModal'" : "disabled='disabled'",
        value: "Start Sending",
        enabledAction: "openConfirmAutomationModal"
      }];
      this.state.shouldShowFinishLaterLink = true;
      this.state.icon = {
        classes: "c-channelIcon c-channelIcon--email"
      };
      this.finishLaterUrl = mUrl.toUrl("/customer-journey");

      // this field should be true when the campaign is ready to send
      // that is, it has passed validation from front-end and back-end
      var isReadyToSend = this.state.isReady;
      this.statusBadge = {
        "sending": {
          label: "Sending",
          badge: "info"
        },
        "somepaused": {
          label: "Some Paused",
          badge: "warning"
        },
        "save": {
          label: "Draft",
          badge: ""
        },
        "draft": {
          label: "Draft",
          badge: ""
        },
        "active": {
          label: "Active",
          badge: "info"
        },
        "paused": {
          label: "Paused",
          badge: "warning"
        }
      };
      this.campaignType = "automation";
      if (props.model.errorState && props.model.errorState.scope === "account") {
        this.state.heading = props.model.errorState.title;
        this.state.subHeading = props.model.errorState.descriptionShort;
        this.state.buttons[0].classes = "c-slatButton button p2" + props.model.errorState.resolution.canResolve ? "" : " disabled";
        if (props.model.errorState.resolution.action && props.model.errorState.resolution.action.attr) {
          this.state.buttons[0].value = props.model.errorState.resolution.action.title;
          this.state.buttons[0].otherAttrs = props.model.errorState.resolution.action.attr;
        }
      } else if (this.state.model.status === automationApi.AUTOMATION_STATUS_SENDING) {
        this.state.heading = "Your automation is live!";
        if (!this.state.model.authorizedEmail) {
          this.state.heading = "Your email delivery is at risk unless you authenticate your domain.";
          this.state.icon = {
            classes: "c-slatInfoIcon freddicon warn-circle-large deprecated-textColorGray"
          };
        }
        this.state.showStatusBadge = this.state.model.authorizedEmail;
        this.state.buttons = this.state.model.authorizedEmail ? [{
          classes: "c-slatButton button p0",
          value: "View Report",
          otherAttrs: "data-dojo-attach-event='click: viewReport'"
        }, {
          classes: "c-slatButton button",
          value: "Pause and Edit",
          otherAttrs: "data-dojo-attach-event='click: openPauseAutomationModal'"
        }] : [{
          classes: "c-slatButton button-link",
          value: "View Report",
          otherAttrs: "data-dojo-attach-event='click: viewReport'"
        }, {
          classes: "c-slatButton button",
          value: "Pause and Edit",
          otherAttrs: "data-dojo-attach-event='click: openPauseAutomationModal'"
        }, {
          classes: "c-slatButton button p1",
          value: "Authenticate your domain",
          otherAttrs: "data-dojo-attach-event='click: authenticateDomain'"
        }];
        this.state.shouldShowFinishLaterLink = false;
      } else if (this.state.model.status === automationApi.AUTOMATION_STATUS_PAUSED) {
        this.state.heading = "Your automation is paused.";
        this.state.buttons = [{
          classes: "c-slatButton button",
          value: "Resume Sending",
          otherAttrs: "data-dojo-attach-event='click: openConfirmAutomationModal'"
        }];
      } else if (isReadyToSend) {
        this.state.heading = "Edit your draft, or start sending.";
        this.state.buttons = this.state.buttons.map(function (button) {
          return lang.mixin({}, button, {
            otherAttrs: "data-dojo-attach-event='click: " + button.enabledAction + "'"
          });
        });
      } else if (this.state.remainingItems >= 4) {
        this.state.heading = "Let's get started!";
      } else if (this.state.remainingItems >= 2) {
        this.state.heading = "Keep it up!";
      } else if (this.state.remainingItems === 1) {
        this.state.heading = "You're almost finished!";
      } else if (this.state.remainingItems === 0) {
        // this case isn't relevant if some validation comes from back-end, isn't captured by a checklist item,
        // but we still want to disable the checklist.
        this.state.heading = "Error";
        this.state.subHeading = "We've made a huge mistake.";
        throw new Error("Checklist thinks it is complete, but is still not in `isReady` state");
      }
    },
    openConfirmAutomationModal: function () {
      if (this.enableButtonWhenUserIsPaused) {
        return window.parent.postMessage({
          status: "paused",
          action: "wiggle"
        });
      }
      var confirmAutomationModal = new ConfirmAutomation({
        model: this.model,
        automationType: "singleWelcome",
        status: this.state.model.status
      });
      confirmAutomationModal.showConfirmAutomationDialog();
    },
    viewReport: function () {
      document.location.href = mUrl.toUrl("/reports/summary", {
        "id": this.model.parentId
      });
    },
    openPauseAutomationModal: function () {
      var pauseAutomationModal = new PauseAutomation({
        model: this.model,
        automationType: "singleWelcome"
      });
      pauseAutomationModal.showPauseAutomationDialog();
    },
    authenticateDomain: function () {
      document.location.href = mUrl.toUrl("/account/domains");
    }
  });
});