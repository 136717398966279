define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/Stateful", "mojo/url", "vendor/lodash-amd/filter", "vendor/lodash-amd/forEach", "vendor/lodash-amd/reject", "vendor/lodash-amd/differenceBy", "vendor/lodash-amd/find", "vendor/lodash-amd/map", "vendor/lodash-amd/sortBy", "vendor/lodash-amd/pull", "vendor/lodash-amd/snakeCase", "mojo/lib/flags", "mojo/context"], function (declare, lang, xhr, Stateful, mUrl, _filter, _forEach, _reject, _differenceBy, _find, _map, _sortBy, _pull, _snakeCase, flags, context) {
  var PagesFormConfig = declare([Stateful], {
    pageId: null,
    _formConfigEndpoint: "landing-pages/save-signup-form-settings",
    formConfig: null,
    fieldsConfig: null,
    _allFieldTypes: ["merge", "group", "marketing_preference"],
    gdprEnabled: false,
    // maintain a full set of fields with selected flags, field type, and POST key IDs
    allKeyedFields: null,
    listSelectedIds: [],
    gdprSelectedIds: [],
    _defaultEmailField: {
      "label": "Email Address",
      "name": "EMAIL",
      "type": "email",
      "required": true
    },
    // Remove emailLabel from all files when ELC is fully rolled out
    setupFormConfig: function (emailLabel, allFields, config) {
      this._defaultEmailField.label = emailLabel;
      this.set("formConfig", new Stateful(config));
      this.setupFields(emailLabel, allFields, this.get("formConfig").get("fields"));
      return this.get("formConfig");
    },
    updateFormConfig: function (config) {
      // when the config returns, update all the keys
      // then update the selected items,
      // get keys and set each one
      _forEach(config, lang.hitch(this, function (item, key) {
        this.formConfig.set(key, item);
      }));
      return this.get("formConfig");
    },
    /**
     * updateSelectedFields will be called from an editor to update the selected fields
     * @param {Object} field is the model to set
     * @param {Boolean} isSelected for whether or not the field is selected
     * @return {Object} fieldsConfig after setting the new selected and available lists
     */
    updateFieldSelection: function (field, isSelected) {
      // set field to be selected or deselected
      field._isSelected = isSelected;
      if (isSelected) {
        this._addIdToSorted(field._postKeyId, field._fieldType);
      } else {
        this._removeIdFromSorted(field._postKeyId, field._fieldType);
      }

      // run update for formConfig.fields for all fields;
      this.updateRenderedFields();

      // lookup field in allFields, change selected, process fields, return fields
      return this.fieldsConfig.set("fieldSets", this._getAvailableFields(this.allKeyedFields));
    },
    /**
     * updateGdprRequired updates the requireGdprSelection value
     * @param {Boolean} isRequired new value
     * @return {Object} fieldsConfig after setting new value
     */
    updateGdprRequired: function (isRequired) {
      return this.fieldsConfig.set("requireGdprSelection", isRequired);
    },
    /**
     * updateRequiredFields will be called from an editor to update the required state of selected fields
     * @param {Object} fieldId is the id of the field being updated
     * @param {Boolean} isRequired comes from the checked value of a checkbox
     */
    updateRequiredFields: function (fieldId, isRequired) {
      _find(this.allKeyedFields, ["_postKeyId", fieldId]).required = isRequired;
      this.updateRenderedFields();
      // what to return
      // do anything after?
    },
    /**
     * updateSortedFields will be called from an editor to update the sort order fields
     * @param {Array} orderedFieldIds will have selected listFields and selected gdprFields
     * @param {String} fieldType is type of field to be sorted
     * @returns {Object} current fieldSets are returned after setting available
     */
    updateSortedFields: function (orderedFieldIds, fieldType) {
      // what to return, nothing
      // sort by list type
      var sortIdsRef;
      switch (fieldType) {
        case "marketing_preference":
          sortIdsRef = this.gdprSelectedIds;
          break;
        default:
          sortIdsRef = this.listSelectedIds;
          break;
      }
      _forEach(orderedFieldIds, function (fieldId, idx) {
        sortIdsRef[idx] = fieldId;
      });
      // run update for formConfig.fields for all fields;
      this.updateRenderedFields();
      return this.fieldsConfig.set("fieldSets", this._getAvailableFields(this.allKeyedFields));
    },
    // add a value to sorted list by type
    // user for adding fields
    _addIdToSorted: function (fieldId, fieldType) {
      switch (fieldType) {
        case "marketing_preference":
          this.gdprSelectedIds.push(fieldId);
          break;
        default:
          this.listSelectedIds.push(fieldId);
          break;
      }
    },
    // add a value to sorted list by type
    // user for adding fields
    _removeIdFromSorted: function (fieldId, fieldType) {
      switch (fieldType) {
        case "marketing_preference":
          this.gdprSelectedIds = _pull(this.gdprSelectedIds, fieldId);
          break;
        default:
          this.listSelectedIds = _pull(this.listSelectedIds, fieldId);
          break;
      }
    },
    /**
     * setupFields is for an initial call to set the state of all fields as collection and selected fields
     * @param {String} emailLabel is the label for the default email field
     * @param {Object} allFields is a hash keyed by field type and field id
     * @param {Array} selectedFields is the array of fields already selected, including the default email fields
     * @return {Object} the function returns a call to the currect fieldsConfig which is an object containing available and selected fields
     */
    setupFields: function (emailLabel, allFields, selectedFields) {
      // put all allFields into one object with postKeyId (value to post on save)
      this.allKeyedFields = _map(allFields, lang.hitch(this, function (field, key) {
        field._postKeyId = key;
        field._isSelected = false;
        field.isGroup = false;
        // marketing_preference is gdpr
        field.isMarketingPreference = this._getSelectedType(this._allFieldTypes, field) === "marketing_preference";
        field._fieldType = this._getSelectedType(this._allFieldTypes, field);
        return field;
      }));
      // get initial selected fields flagged in allFields
      _forEach(selectedFields, lang.hitch(this, function (field, idx) {
        // for each selected field, find the corresponding field in allKeyedFields
        var selectedKey = this._getSelectedKey(this._allFieldTypes, field);
        var keyedField = _find(this.allKeyedFields, ["_postKeyId", selectedKey.key]);
        if (keyedField) {
          // add a selected flag
          keyedField._isSelected = true;

          // add a required flag
          keyedField.required = field.required;

          // add type attributes (isGroup, isMarketingPreference)
          if (selectedKey.type === "group") {
            keyedField.isGroup = true;
          }
          if (selectedKey.type === "marketing_preference") {
            keyedField.isMarketingPreference = true;
          }
          this._addIdToSorted(selectedKey.key, selectedKey.type);
        }
      }));
      if (window.elcIsEnabled) {
        this.set("fieldsConfig", new Stateful({
          hasGdpr: this.get("gdprEnabled"),
          requireGdprSelection: this.get("formConfig").get("requireOneField"),
          fieldSets: this._getAvailableFields(this.allKeyedFields)
        }));
      } else {
        this.set("fieldsConfig", new Stateful({
          hasGdpr: this.get("gdprEnabled"),
          requireGdprSelection: this.get("formConfig").get("requireOneField"),
          emailLabel: emailLabel,
          fieldSets: this._getAvailableFields(this.allKeyedFields)
        }));
      }
      return this.get("fieldsConfig");
    },
    /**
     * _getAvailableFields returns an object of available and selected fields for list type and marketing_preference (gdpr) type fields
     * @param {Array} allKeyedFields is the array of all local set of fields with all the properties needed to parse them into field sets for display
     * @returns {Object} fieldsConfig key by available and selected field types
     */
    _getAvailableFields: function (allKeyedFields) {
      var fieldsConfig = {};
      // check for GDPR fields, or has GDPR,
      if (this.get("gdprEnabled")) {
        // filter by gdprFields for available and selected
        fieldsConfig.availableGdprFields = _filter(allKeyedFields, {
          "isMarketingPreference": true,
          "_isSelected": false
        });

        // sort selected Fields for listing
        var gdprSelectedFields = _filter(allKeyedFields, {
          "isMarketingPreference": true,
          "_isSelected": true
        });
        fieldsConfig.selectedGdprFields = _map(this.gdprSelectedIds, function (fieldId) {
          return _find(gdprSelectedFields, ["_postKeyId", fieldId]);
        });
      }
      // filter by listFields for available and selected
      fieldsConfig.availableListFields = _filter(allKeyedFields, {
        "isMarketingPreference": false,
        "_isSelected": false
      });

      // sort selected Fields for listing
      var listSelectedFields = _filter(allKeyedFields, {
        "isMarketingPreference": false,
        "_isSelected": true
      });
      fieldsConfig.selectedListFields = _map(this.listSelectedIds, function (fieldId) {
        return _find(listSelectedFields, ["_postKeyId", fieldId]);
      });
      return fieldsConfig;
    },
    /**
     * updateRenderedFields will update the formConfig.fields array which controls rendering signup form in the editor window
     */
    updateRenderedFields: function () {
      // start with default email field
      // create new fields array with concat of all
      var combinedFields;
      if (window.elcIsEnabled) {
        combinedFields = [];
      } else {
        combinedFields = [this._defaultEmailField];
      }
      // get two lists to merge
      var listFields = _filter(this.allKeyedFields, {
        "isMarketingPreference": false,
        "_isSelected": true
      });
      // order from listSelectedIds
      // map listSelectedIds, find in listFields, return field
      var orderedListFields = _map(this.listSelectedIds, function (fieldId) {
        return _find(listFields, ["_postKeyId", fieldId]);
      });

      // combine with list fields
      combinedFields = combinedFields.concat(orderedListFields);
      if (this.get("gdprEnabled")) {
        // filter by gdprFields for available and selected
        var gdprFields = _filter(this.allKeyedFields, {
          "isMarketingPreference": true,
          "_isSelected": true
        });

        // order from gdprSelectedIds
        // map gdprSelectedIds, find in gdprFields, return field
        var orderedGdprFields = _map(this.gdprSelectedIds, function (fieldId) {
          return _find(gdprFields, ["_postKeyId", fieldId]);
        });
        // combine
        combinedFields = combinedFields.concat(orderedGdprFields);
      }
      this.formConfig.set("fields", combinedFields);
    },
    /**
     * _addDefaultEmailField takes an array of fields, and returns an array with the default fields added (email is only default field currently)
     * @param {Array} passedArray is array to add fields
     * @returns {Array} passedArray will have added default field added
     */
    _addDefaultEmailField: function (passedArray) {
      return passedArray.unshift(this._defaultEmailField);
    },
    /**
     * _createPostKey creates field key for returning fields sets
     * @param {Array} fieldTypes are the types of fields displayed in the form
     * @param {Object} selectedField is the object to process the type and key
     * @return {String} postKey is key to send to server
     */
    _createPostKey: function (fieldTypes, selectedField) {
      var postKey;
      // test selected field for field types,
      _forEach(fieldTypes, function (type) {
        if (selectedField.hasOwnProperty(type + "_id")) {
          var id = selectedField[type + "_id"];
          postKey = type + "_" + id;
        }
      });
      // return key for lookup
      return postKey;
    },
    /**
     * _getSelectedKey looks up each field type and returns an object with new key to be used as a lookup in allfields
     * this method is used for incoming selected fields which are not keyed coming from the controller
     * @param {Array} fieldTypes are the types of fields displayed in the form
     * @param {Object} selectedField is the object to process the type and key
     * @return {Object} lookupKey is the data needed to be added to the field for lookup
     */
    _getSelectedKey: function (fieldTypes, selectedField) {
      var lookupKey = {};
      // test selected field for field types,
      _forEach(fieldTypes, function (type) {
        if (selectedField.hasOwnProperty(type + "_id")) {
          lookupKey.type = type;
          lookupKey.key = type + "_" + selectedField[type + "_id"];
        }
      });
      // return key for lookup
      return lookupKey;
    },
    /**
     * _getSelectedType looks up each field type and compare them against the field's postKeyId, then returns a string of the field type
     * this method is used for incoming all fields which do not have a field type
     * @param {Array} fieldTypes are the types of fields displayed in the form
     * @param {Object} selectedField is the object to process the type and key
     * @return {String} fieldType is the data needed to be added to the field for grouping field types
     */
    _getSelectedType: function (fieldTypes, selectedField) {
      var fieldType;
      _forEach(fieldTypes, function (type) {
        if (selectedField._postKeyId.indexOf(type)) {
          fieldType = selectedField._postKeyId.substring(0, selectedField._postKeyId.lastIndexOf("_"));
        }
      });
      // return field type
      return fieldType;
    },
    /**
     * _serializeFields is the method to set create the array of field ids sent when saving the form config data
     * @param {Array} fields is the current list of selected fields as a single array
     * @returns {Array} it returns a mapped array of postKeyIds, excluding the default "EMAIL" field
     */
    _serializeFields: function (fields) {
      if (window.elcIsEnabled) {
        return fields.map(lang.hitch(this, function (fieldObj) {
          if (fieldObj.hasOwnProperty("_postKeyId")) {
            return fieldObj._postKeyId;
          }
          // we need this once we have saved from the editor and then saving from "save and continue" or "save and publish"
          return this._createPostKey(this._allFieldTypes, fieldObj);
        }));
      }
      return _reject(fields, {
        name: "EMAIL"
      }).map(lang.hitch(this, function (fieldObj) {
        if (fieldObj.hasOwnProperty("_postKeyId")) {
          return fieldObj._postKeyId;
        }
        // we need this once we have saved from the editor and then saving from "save and continue" or "save and publish"
        return this._createPostKey(this._allFieldTypes, fieldObj);
      }));
    },
    /**
     * TODO: add pusher channel for formConfig
     * Listen for pusher notifications for refreshing formConfig
     */
    // _initializePusher: function () {
    //     var self = this;
    //     // Don't try to re-subscribe if already active
    //     if (self.channel) {return false;}
    //     mPusher.load().then(function (pusher) {
    //         // TODO: setup pusher channel for updating formConfig
    //     });
    // },
    save: function () {
      var settingsKeys = ["successMessage", "buttonLabel", "successAction", "successRedirectUrl", "successPageUniqueId"];

      // Settings of the form config object, whose fields are defined above, with
      // snake-cased key names.
      var settingsSnakeCased = Object.keys(this.formConfig).reduce(function (config, nextKey) {
        if (settingsKeys.indexOf(nextKey) === -1) {
          return config;
        }
        config[_snakeCase(nextKey)] = this.formConfig[nextKey];
        return config;
      }.bind(this), {});
      var dataToSend = {
        "styles": JSON.stringify(this.formConfig.styles),
        "setting": JSON.stringify(settingsSnakeCased)
      };
      // deal with parsing all the fields with _serializeFields
      dataToSend.fields = JSON.stringify(this._serializeFields(this.formConfig.get("fields")));
      // deal with parsing all the required ids
      var requiredFields = {};
      _filter(this.formConfig.fields, {
        required: true
      }).forEach(lang.hitch(this, function (fieldObj) {
        if (window.elcIsEnabled) {
          // deal with cases where the _postKeyId is not set
          if (!fieldObj._postKeyId) {
            fieldObj._postKeyId = this._createPostKey(this._allFieldTypes, fieldObj);
          }
          requiredFields[fieldObj._postKeyId] = "on";
        } else if (fieldObj.name !== "EMAIL") {
          // deal with cases where the _postKeyId is not set
          if (!fieldObj._postKeyId) {
            fieldObj._postKeyId = this._createPostKey(this._allFieldTypes, fieldObj);
          }
          requiredFields[fieldObj._postKeyId] = "on";
        }
      }));
      dataToSend.required_fields = JSON.stringify(requiredFields);
      dataToSend.require_one_field = JSON.stringify(this.get("fieldsConfig").get("requireGdprSelection") ? "on" : "off");
      return xhr.post(mUrl.toUrl(this._formConfigEndpoint, {
        id: this.formConfig.pageId
      }), {
        "data": dataToSend,
        "handleAs": "json"
      }).then(lang.hitch(this, function (data) {
        // set all properties on formConfig, not new stateful
        return this.updateFormConfig(data);
      }));
    }
  });

  // create a single instance of the PagesFormConfig class,
  // and expose just the necessary functions

  var pageFormConfig = null;
  var module = {
    _getPagesFormConfig: function () {
      if (!pageFormConfig) {
        pageFormConfig = new PagesFormConfig();
      }
      return pageFormConfig;
    },
    setupFormConfig: function (pageId, emailLabel, allFields, config) {
      var pagesFormConfig = this._getPagesFormConfig();
      pagesFormConfig.set("pageId", pageId);
      return pagesFormConfig.setupFormConfig(emailLabel, allFields, config);
    },
    getStateful: function () {
      return this._getPagesFormConfig().get("formConfig");
    },
    getFieldsConfig: function () {
      return this._getPagesFormConfig().get("fieldsConfig");
    },
    updateFieldSelection: function (field, isSelected) {
      return this._getPagesFormConfig().updateFieldSelection(field, isSelected);
    },
    updateGdprRequired: function (isRequired) {
      return this._getPagesFormConfig().updateGdprRequired(isRequired);
    },
    updateRequiredFields: function (fieldId, isRequired) {
      return this._getPagesFormConfig().updateRequiredFields(fieldId, isRequired);
    },
    updateSortedFields: function (sortedFieldIds, fieldsTypes) {
      return this._getPagesFormConfig().updateSortedFields(sortedFieldIds, fieldsTypes);
    },
    setGDPREnabled: function (gdprEnabled) {
      this._getPagesFormConfig().set("gdprEnabled", gdprEnabled);
    },
    save: function () {
      return this._getPagesFormConfig().save();
    }
  };
  return module;
});