/**
 * PaidFeatureList
 *
 * @module mojo/widgets/ExperimentList.js
 * @see mojo/widgets/NoDataBlock.js
 * @see mojo/widgets/FilteredSearch.js
 *
 * Simple widget to create a better experience searching for enabled/disabled status of a users paid features.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/Dialog", "mojo/widgets/FlagList", "dojo-proxy-loader?name=dojo/ready!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/context", "mojo/lib/flags", "mojo/utils/I18nTranslation",
// Templates
"dojo/text!./templates/PaidFeatureList.html"], function (declare, on, query, Dialog, FlagList, ready, context, flags, I18nTranslation, tplStr) {
  return declare([FlagList], {
    templateString: tplStr,
    paid_feature_name: "Paid Feature Name",
    check_spelling: "Check the spelling?",
    no_paid_feature_found: "No Paid Feature Found \:(",
    postMixInProperties: function () {
      if (context.flagIsOn(flags.I18N_REPORTING_TRANSLATIONS_V2)) {
        I18nTranslation.initialize().then(function (translations) {
          this.paid_feature_name = translations.reporting_paid_feature_paid_feature_name;
          this.check_spelling = translations.reporting_paid_feature_check_spelling;
          this.no_paid_feature_found = translations.reporting_paid_feature_no_paid_feature_found;
        }.bind(this));
      }
    },
    startup: function () {
      if (query("[data-action='showAllPaidFeatures']")[0]) {
        on(query("[data-action='showAllPaidFeatures']")[0], "click", this._openPaidFeaturesDialog.bind(this));
      }
    },
    /**
     * Instantiate and show a dialog
     *
     * @param {Object} event - standard browser click event.
     * @private
     */
    _openPaidFeaturesDialog: function (event) {
      event.preventDefault();
      this.flagListDialog = new Dialog({
        title: "All Paid User Features",
        content: this._getDialogContent()
      });
      this.flagListDialog.startup();
      this.flagListDialog.show();
    }
  });
});