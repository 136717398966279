define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/request", "dojo/hash", "dojox/html/entities", "dojo-proxy-loader?name=dojo/io-query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/utils"], function (declare, lang, request, hash, htmlentities, ioQuery, mUrl, utils) {
  var tags = {
    "tagsList": {},
    "tagsCallback": {},
    "tagsInitialized": {}
  };
  return {
    getContactTagStateUrl: "/lists/tags/get-contact-tag-state",
    getListTagsUrl: "/lists/tags/get-tags-json",
    applyTagsUrl: "/lists/tags/tag-contacts",
    removeTagsUrl: "/lists/tags/untag-contacts",
    getSelectedContactsTagsUrl: "/lists/tags/get-multi-contacts-tags",
    filterByTagUrl: "/lists/segments/members/tags-filter",
    tagSegmentOrPreviewUrl: "/lists/tags/tag-segment-preview",
    bulkUntagAudienceUrl: "/lists/tags/untag-contacts-new",
    bulkUntagSegmentUrl: "/lists/tags/untag-segment-preview",
    /**
     * Hook to watch the tags list and provide callback for onchange
     * @param {function} callback to run when tags change
     * @param {string} listID to index
     */
    watchTags: function (callback, listID) {
      //If this listID has not been initialized, go get the tags list
      if (!tags.tagsCallback[listID]) {
        tags.tagsCallback[listID] = [];
        if (!tags.tagsInitialized.hasOwnProperty(listID)) {
          tags.tagsInitialized[listID] = false;
          this.getListTags(listID, null);
        }
      }

      //Append the passed-in callback
      tags.tagsCallback[listID].push(callback);

      //Call the callback if this listID has been initialized
      if (tags.tagsInitialized[listID]) {
        callback(tags.tagsList[listID]);
      }
    },
    /**
     * Run the list of callbacks associated with the provided list ID
     * @param {string} listID associated with callbacks
     */
    runCallbacks: function (listID) {
      if (tags.tagsCallback[listID]) {
        tags.tagsCallback[listID].forEach(function (callback) {
          callback(tags.tagsList[listID]);
        });
      }
    },
    /**
     * Perform the GET request as indicated in the parameters
     * @param {string} requestUrl Url to hit
     * @param {function} callback Callback function
     * @returns {string} request Request response
     */
    getRequest: function (requestUrl, callback) {
      var req = request.get(requestUrl, {
        handleAs: "json"
      });
      req.then(function (response) {
        if (callback) {
          callback(response);
        }
      }, function (response) {
        utils.toast("Error: " + response.response.data.error);
      });
      return req;
    },
    /**
     * Perform the POST request as indicated in the parameters
     * @param {string} requestUrl Url to hit
     * @param {object} postObject JSON object to post to the server
     * @param {function} callback Callback function
     * @returns {string} request Request response
     */
    postRequest: function (requestUrl, postObject, callback) {
      return request.post(requestUrl, {
        handleAs: "json",
        data: postObject
      }).then(function (response) {
        if (callback) {
          callback(response);
        }
      }, function (response) {
        utils.toast("Error: " + response.response.data.error);
      });
    },
    /**
     * Get the list of tags associated with the contact and the list of tags associated with the list
     * @param {string} listID List ID
     * @param {string} memberID List member ID
     * @param {function} callback Callback function
     */
    getContactTags: function (listID, memberID, callback) {
      var requestUrl = mUrl.toUrl(this.getContactTagStateUrl, {
        "id": listID,
        "list_email_id": memberID
      });
      this.getRequest(requestUrl, callback);
    },
    /**
     * Get the list of tags associated with the provided listID
     * @param {string} listID List ID
     * @param {function} callback Callback function
     */
    getListTags: function (listID, callback) {
      var requestUrl = mUrl.toUrl(this.getListTagsUrl, {
        "id": listID
      });
      this.getRequest(requestUrl, callback).then(lang.hitch(this, function (tagList) {
        tags.tagsList[listID] = tagList.list_tags;
        tags.tagsInitialized[listID] = true;
        this.runCallbacks(listID);
      }));
    },
    /**
     * Apply an existing tag to an array of contacts
     * Response contains the current list of tags associated with the contact
     * @param {string} listID List ID
     * @param {array} memberIDs List member IDs
     * @param {string} tagID List of tags to add
     * @param {function} callback Callback function
     */
    applyTagToContacts: function (listID, memberIDs, tagID, callback) {
      var data = {
        "id": listID,
        "list_email_ids": JSON.stringify(memberIDs),
        "tag_ids": JSON.stringify([tagID])
      };
      var requestUrl = mUrl.toUrl(this.applyTagsUrl);
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Delete the specified tag from an array of contacts
     * @param {string} listID List ID
     * @param {array} memberIDs List member IDs
     * @param {string} tagID ID of tag to remove
     * @param {function} callback Callback function
     */
    removeTagFromContacts: function (listID, memberIDs, tagID, callback) {
      var data = {
        "id": listID,
        "list_email_ids": JSON.stringify(memberIDs),
        "tag_ids": JSON.stringify([tagID])
      };
      var requestUrl = mUrl.toUrl(this.removeTagsUrl);
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Create a new tag and apply it to an array of contacts
     * @param {string} listID List ID
     * @param {string} tagName Name of tag to create
     * @param {array} memberIDs List member IDs
     * @param {function} callback Callback function
     */
    createTagApplyToContacts: function (listID, tagName, memberIDs, callback) {
      var data = {
        "list_email_ids": JSON.stringify(memberIDs),
        "tag_name": tagName,
        "id": listID
      };
      var requestUrl = mUrl.toUrl(this.applyTagsUrl);
      this.postRequest(requestUrl, data, lang.hitch(this, function (response) {
        callback(response);
        this.getListTags(listID, null);
      }));
    },
    /**
     * Apply an existing tag to all contacts in an audience
     * @param {string} listID List ID
     * @param {string} tagID ID of tag to apply
     * @param {function} callback Callback function
     */
    applyTagToAudience: function (listID, tagID, callback) {
      var data = {
        "tag_audience": true,
        "id": listID,
        "tag_ids": JSON.stringify([tagID])
      };
      var requestUrl = mUrl.toUrl(this.applyTagsUrl);
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Create a new tag and apply it to all contacts in an audience
     * @param {string} listID List ID
     * @param {string} tagName Name of tag to create and apply
     * @param {function} callback Callback function
     */
    createTagApplyToAudience: function (listID, tagName, callback) {
      var data = {
        "tag_audience": true,
        "tag_name": tagName,
        "id": listID
      };
      var requestUrl = mUrl.toUrl(this.applyTagsUrl);
      this.postRequest(requestUrl, data, lang.hitch(this, function (response) {
        callback(response);
        this.getListTags(listID, null);
      }));
    },
    /**
     * Remove an existing tag from all contacts in an audience or segment
     * @param {boolean} untagAudience should entire audience be untagged
     * @param {string} listID List ID
     * @param {string} tagID ID of tag to apply
     * @param {function} callback Callback function
     */
    untagAudienceOrSegment: function (untagAudience, listID, tagName, tagID, callback) {
      var data = {
        "untag_audience": untagAudience,
        "id": listID,
        "tag_name": tagName,
        "tag_id": tagID,
        "tag_ids": JSON.stringify([tagID])
      };
      var requestUrl = mUrl.toUrl(this.bulkUntagSegmentUrl);
      if (untagAudience) {
        requestUrl = mUrl.toUrl(this.bulkUntagAudienceUrl);
      }
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Get the tags associated with each passed-in memberID
     * @param {integer} listId the list id
     * @param {array} memberIDs List member IDs
     * @param {function} callback Callback function
     */
    getSelectedContactsTags: function (listId, memberIDs, callback) {
      var data = {
        "list_email_ids": JSON.stringify(memberIDs),
        "id": listId
      };
      var requestUrl = mUrl.toUrl(this.getSelectedContactsTagsUrl);
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Filter the contact table by up to 5 tags
     * @param {string} listID for contacts in the table
     * @param {array} tagsToFilterBy list of tags to filter the contacts by
     * @param {function} callback Callback function
     */
    updateTagFilter: function (listID, tagsToFilterBy, callback) {
      var requestUrl = mUrl.toUrl(this.filterByTagUrl);
      requestUrl = requestUrl + "?list_id=" + listID + "&as_json=true";
      if (tagsToFilterBy && tagsToFilterBy.length > 0) {
        tagsToFilterBy.forEach(function (tag) {
          requestUrl = requestUrl + "&tag_ids[]=" + tag.tag_id;
        });
      }
      this.getRequest(requestUrl, callback);
    },
    /**
     * Bulk apply a tag to a segment or segment preview
     *
     * @param {string} listId List Id
     * @param {string} tagId to apply
     * @param {function} callback Callback function
     */
    tagSegmentOrPreview: function (listId, tagId, callback) {
      var requestUrl = mUrl.toUrl(this.tagSegmentOrPreviewUrl);
      var data = {
        "id": listId,
        "tag_id": tagId
      };
      this.postRequest(requestUrl, data, callback);
    },
    /**
     * Create a tag and apply it to the session segment or segment preview
     * @param {string} listId List Id
     * @param {string} tagName to create
     * @param {function} callback Callback function
     */
    createTagSegmentPreview: function (listId, tagName, callback) {
      var data = {
        "id": listId,
        "tag_name": tagName
      };
      var requestUrl = mUrl.toUrl(this.tagSegmentOrPreviewUrl);
      this.postRequest(requestUrl, data, lang.hitch(this, function () {
        callback();
        this.getListTags(listId, null);
      }));
    }
  };
});