define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./RssItemsEditorMobile", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/RssHeaderEditor/basicContent.html", "dojo/text!./templates/RssHeaderEditor/intermediateContent.html", "dojo/text!./templates/RssHeaderEditor/advancedContent.html", "dojo/html", "dijit/form/Select", "mojo/neapolitan/widgets/EditorContainer"], function (declare, RssItemsEditorMobile, _MobileBlockMixin, basicContent, intermediateContent, advancedContent) {
  return declare([RssItemsEditorMobile, _MobileBlockMixin], {
    type: "rssHeader-mobile",
    presetsLabel: "RSS Header Style",
    constructor: function () {
      this.contentPresets = {
        basic: {
          label: "Basic",
          content: basicContent
        },
        intermediate: {
          label: "Intermediate",
          content: intermediateContent
        },
        advanced: {
          label: "Advanced",
          content: advancedContent
        },
        custom: {
          label: "Custom",
          content: advancedContent
        }
      };
    },
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});