define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "./FooterEditor", "mojo/neapolitan/editors/_MobileBlockMixin", "dojo/text!./templates/FooterEditor/editorMobile.html"], function (declare, FooterEditor, MobileBlockMixin, editorTpl) {
  return declare([FooterEditor, MobileBlockMixin], {
    type: "footer-mobile",
    templateString: editorTpl,
    // for mobile mixin, and hiding elements for CKEditor focus
    domElementHiddenOnFocus: ["columnSelector", "recoverContentWarning"],
    destroy: function () {
      this._teardownNativeMobileHandlers();
      this.inherited(arguments);
    },
    postCreate: function () {
      this.inherited(arguments);
      this._setupNativeMobileHandlers();
    }
  });
});