define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/Dialog", "mojo/utils/string", "mojo/context", "mojo/lib/flags", "mojo/utils", "dojo/text!./templates/CampaignPlaceholderContentWarning.html"], function (declare, lang, domConstruct, on, _WidgetBase, _TemplatedMixin, Dialog, stringUtils, context, flags, utils, tpl) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    title: "Review for placeholder content",
    type: "onLoad",
    contentType: "text",
    onContinueCallback: null,
    onDismissCallback: null,
    presendChecks: null,
    trackingLocation: null,
    dialog: null,
    postMixInProperties: function () {
      // If there are placeholder images use 'content' instead of 'text'
      if (this.presendChecks.context[1] > 0) {
        this.contentType = "content";
      }
    },
    postCreate: function () {
      this.formatPresendContent();
      if (this.type === "onSave") {
        this.title = "Hang on! There’s still placeholder " + this.contentType + ".";
      }
      this.dialog = new Dialog({
        title: this.title,
        content: this.type === "onLoad" ? this.onLoad : this.onSave,
        style: "overflow: auto; width: 500px",
        "onHide": lang.hitch(this, function () {
          if (this.onDismissCallback) {
            this.onDismissCallback();
          }
          this.destroyRecursive();
          this.trackEvent(this.trackingLocation, "click", "placeholder_content:close");
        })
      });
      on(this.continueButton, "click", lang.hitch(this, function () {
        if (this.onDismissCallback) {
          this.onDismissCallback();
        }
        this.dialog.destroyRecursive();
        this.trackEvent(this.trackingLocation, "click", "placeholder_content:got_it");
      }));
      on(this.exitButton, "click", lang.hitch(this, function () {
        this.onContinueCallback();
        this.dialog.destroyRecursive();
        this.trackEvent(this.trackingLocation, "click", "placeholder_content:exit_anyway");
      }));
      on(this.returnButton, "click", lang.hitch(this, function () {
        if (this.onDismissCallback) {
          this.onDismissCallback();
        }
        this.dialog.destroyRecursive();
        this.trackEvent(this.trackingLocation, "click", "placeholder_content:keep_editing");
      }));
      this.dialog.show();
      this.trackEvent(this.trackingLocation, "show", "placeholder_content");
    },
    formatPresendContent: function () {
      var textContent = this.presendChecks.context[0];
      var imageContent = this.presendChecks.context[1];
      var maxTextContentToDisplay = 2;

      // List default text content
      for (var i = 0; i < maxTextContentToDisplay; i++) {
        if (textContent[i]) {
          var textTemplate = "<li>" + textContent[i] + "</li>";
          this.updateContentList(textTemplate);
        }
      }

      // List number of default images if there are any
      if (imageContent !== 0) {
        var imageTemplate = "<li>" + imageContent + stringUtils.toPlural(" image", imageContent) + "</li>";
        this.updateContentList(imageTemplate);
      }

      // List any additional default text content
      if (textContent.length > maxTextContentToDisplay) {
        var moreTemplate = "<li> + " + (textContent.length - maxTextContentToDisplay) + " more.</li>";
        this.updateContentList(moreTemplate);
      }
    },
    updateContentList: function (template) {
      var contentList = this.type === "onLoad" ? this.contentListOnLoad : this.contentListOnSave;
      var element = domConstruct.toDom(template);
      domConstruct.place(element, contentList);
    },
    trackEvent: function (category, action, label) {
      if (category && action && label) {
        window.ga("send", "event", category, action, label);
      }
    }
  });
});