/**
 * Utils related to postMessage events used by the single-page application
 */
define([], function () {
  var isInFallbackIframe = window.top !== window && window.location.pathname.substr(0, 2) === '/i';
  return {
    enableFullscreen: function (payload) {
      // Used in `web/js/src/Main/core/Frame/index.js`
      if (isInFallbackIframe) {
        window.top.postMessage({
          channel: "fallback/fullscreen",
          payload: payload
        }, window.location.origin);
      }
    },
    enableGlobalSearch: function (payload) {
      // Used in `web/js/src/@mc/components/GlobalSearch/index.js`
      if (isInFallbackIframe) {
        window.top.postMessage({
          channel: "fallback/global-search",
          payload: payload
        }, window.location.origin);
      }
    },
    prefixPath: function (path) {
      if (isInFallbackIframe) {
        return "/i" + path;
      }
      return path;
    },
    isInFallbackIframe: function () {
      return isInFallbackIframe;
    }
  };
});