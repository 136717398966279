define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_Widget", "dijit/_Templated", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin", "dojo/text!./templates/confirmationMessageEditor.html", "mojo/neapolitan/editors/_CKMixin", "mojo/signup-forms/PagesFormConfig", "mojo/url", "mojo/utils", "./_EditorMixin"], function (declare, lang, on, _Widget, _Templated, _TemplatedMixin, _WidgetsInTemplateMixin, tplString, _CKMixin, PagesFormConfig, mUrl, utils, _EditorMixin) {
  return declare([_Widget, _Templated, _TemplatedMixin, _WidgetsInTemplateMixin, _CKMixin, _EditorMixin], {
    templateString: tplString,
    widgetsInTemplate: true,
    showingPreview: false,
    constructor: function () {
      this.formConfig = PagesFormConfig.getStateful();
      if (window.app.list.id) {
        this.listSettingsUrl = mUrl.toUrl("lists/settings/defaults", {
          id: window.app.list.id
        });
        this.formBuilderUrl = mUrl.toUrl("lists/designer", {
          id: window.app.list.id
        });
      }
    },
    startup: function () {
      // Do not show CK if double opt-in list settings are turned on
      if (!this.formConfig.doubleOptin) {
        this.showCKEditor(this.ckeditorContainer, this.timestampContainer, this.formConfig.successMessage, function () {},
        // No-op for onReady callback
        {
          startupFocus: false
        });
      }
    },
    postCreate: function () {
      this.formConfig.watch("successMessage", this._updateFormConfig.bind(this));
      this.formConfig.watch("showConfirmation", this._updateFormConfig.bind(this));
      if (this.formConfig.doubleOptin) {
        this.confirmationMessage.style.display = "none";
        if (this.formConfig.canEditDoubleOptin) {
          this.lockedDoubleOptinNotice.style.display = "none";
        } else {
          this.canEditDoubleOptinNotice.style.display = "none";
        }
      } else {
        this.canEditDoubleOptinNotice.style.display = "none";
        this.lockedDoubleOptinNotice.style.display = "none";
      }
      this.own(
      // watch preview confirmation message toggle
      on(this.messagePreviewToggle, "click", this._handlePreviewToggle.bind(this)));
    },
    onCKChange: function () {
      var content = this.formConfig.successMessage;
      content = this.editor.getData();
      // render alert message in merge template
      this.formConfig.set("successMessage", content);
    },
    _updateFormConfig: function () {
      utils.globalPublish("neapolitan/preview/pages/form/config/update");
    },
    _handlePreviewToggle: function () {
      this.showingPreview = !this.showingPreview;
      this.formConfig.set("showConfirmation", this.showingPreview);
      if (!this.showingPreview) {
        this.messagePreviewToggle.innerText = "Preview confirmation message";
      } else {
        this.messagePreviewToggle.innerText = "Exit preview";
      }
    }
  });
});