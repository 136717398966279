define(["dijit/Dialog", "dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/request/xhr!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/flags", "mojo/user", "mojo/ecs/EcsEvents", "mojo/ecs/EcsTracking", "dojo/text!./templates/toggle.html", "mojo/context", "mojo/utils/I18nTranslation"], function (Dialog, declare, lang, domClass, domConstruct, _WidgetBase, _TemplatedMixin, on, xhr, mUrl, flags, user, EcsEvents, EcsTracking, tpl, context, I18nTranslation) {
  return declare([_WidgetBase, _TemplatedMixin], {
    templateString: tpl,
    emailEditorPreference: null,
    isMCAdminUser: false,
    toggleHeader: "Default Email Builder",
    toggleSubHeading: "Choose which builder to use by default when creating a new campaign.",
    classicBuilderHeading: "Classic Builder",
    classicBuilderFeatureOne: "Our traditional builder you may know and love",
    classicBuilderFeatureTwo: "Create custom templates and showcase your e-commerce products",
    newBuilderHeading: "New Builder",
    newBuilderFeatureOne: "A sleek, updated user interface to add and edit content inside the email itself",
    newBuilderFeatureTwo: "Wishlist features our users have been asking for, like Undo/Redo",
    internalWarningHeading: "Internal access user warning",
    internalWarningDescription: "You are logged in via an internal tool, which does not allow you to set a default email builder. Please log in with your username and password to set your default builder.",
    postCreate: function () {
      if (this.emailEditorPreference === "inline") {
        this.inlineRadio.checked = true;
        this.dragAndDropRadio.checked = false;
      } else if (this.emailEditorPreference === "dnd") {
        this.inlineRadio.checked = false;
        this.dragAndDropRadio.checked = true;
      } else {
        this.inlineRadio.checked = false;
        this.dragAndDropRadio.checked = false;
      }
    },
    postMixInProperties: function () {
      if (context.flagIsOn(flags.I18N_ACCOUNT_MANAGEMENT_M2)) {
        I18nTranslation.initialize().then(function (translations) {
          this.toggleHeader = translations["email_editor_toggle_heading"];
          this.toggleSubHeading = translations["email_editor_toggle_subheading"];
          this.classicBuilderHeading = translations["email_editor_toggle_classic_builder_heading"];
          this.classicBuilderFeatureOne = translations["email_editor_toggle_classic_builder_feat_1"];
          this.classicBuilderFeatureTwo = translations["email_editor_toggle_classic_builder_feat_2"];
          this.newBuilderHeading = translations["email_editor_toggle_new_builder_heading"];
          this.newBuilderFeatureOne = translations["email_editor_toggle_new_builder_feat_1"];
          this.newBuilderFeatureTwo = translations["email_editor_toggle_new_builder_feat_2"];
          this.internalWarningHeading = translations["email_editor_toggle_internal_warning_heading"];
          this.internalWarningDescription = translations["email_editor_toggle_internal_warning_description"];
        }.bind(this));
      }
    },
    disableRadios: function () {
      this.inlineRadio.disabled = true;
      this.dragAndDropRadio.disabled = true;
    },
    enableRadios: function () {
      this.inlineRadio.disabled = false;
      this.dragAndDropRadio.disabled = false;
    },
    handleEditorSelectionECSTracking: function (editorType) {
      EcsTracking.track(EcsEvents.DefaultEditor, {
        editor_type: editorType
      });
    },
    handleEditorSelection: function (e) {
      e.preventDefault();
      this.disableRadios();
      var inlineEditor = e.target && e.target.value === "true";
      var label = inlineEditor ? "nuni select" : "nea select";

      // handle ECS
      if (context.flagIsOn(flags.EM2_NEA_NUNI_EDITOR_SETTINGS_ECS)) {
        this.handleEditorSelectionECSTracking(inlineEditor ? "nuni" : "nea");
      }
      var form = document.createElement("form");
      form.action = "/account/handle-editor-selection";
      form.method = "POST";
      var csrf = document.createElement("input");
      csrf.type = "hidden";
      csrf.name = "__csrf_token";
      csrf.value = user.getCsrfToken();
      form.appendChild(csrf);
      xhr.post(mUrl.toUrl("/account/handle-editor-selection"), {
        "handleAs": "json",
        "data": {
          "inlineEditor": inlineEditor
        }
      }).then(lang.hitch(this, function (data) {
        if (data.status === "success") {
          if (inlineEditor) {
            this.inlineRadio.checked = true;
            this.dragAndDropRadio.checked = false;
          } else {
            this.inlineRadio.checked = false;
            this.dragAndDropRadio.checked = true;
          }
          this.enableRadios();
        } else {
          //TODO: error state
        }
      }));
    }
  });
});