define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/topic!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/views/_base", "mojo/url", "mojo/app/peaches2/tabContainerUtil"], function (declare, topic, base, mUrl, tabContainerUtil) {
  return declare([base], {
    /**
     * this will be returned from the backend and will be an array
     * of objects that contains data to create the content tabs
     */
    pageTabs: null,
    activeSection: null,
    /**
     * attributes about the user and mcagent that should be passed to the content pane js
     */
    userAttrs: null,
    modules: null,
    onReady: function () {
      var self = this;
      this.pageTabs.forEach(function (tab) {
        tab.preload = true;
      });
      var tabContainer = new tabContainerUtil();
      tabContainer.createContentPanes(this.$el.informationTabs, this, window._mcaModules);
      this.tabContainer = tabContainer;
      this.modules = this.tabContainer.viewModule;
      topic.subscribe("mojo/analytics/List/postcards-list/render", function (list) {
        self.parse();
        tabContainer.postcardsSection.set("title", "Postcards (" + list.total + ")");
      });
      if (this.activeSection === "postcards-details" || mUrl.getHashParams().t === "postcards-details-section") {
        var postcardIdFromUrl = new URL(window.location.href).searchParams.get("postcard_id");
        if (postcardIdFromUrl) {
          this.viewPostcardDetails(postcardIdFromUrl);
          return false;
        }
        if (mUrl.getHashParams().id) {
          this.viewPostcardDetails(mUrl.getHashParams().id);
        }
      }
    },
    viewPostcardDetails: function (postcard_id) {
      var self = this;
      var newPaneObj = {
        title: "Postcard: " + postcard_id,
        href: mUrl.toUrl("/peaches2/users/campaigns", {
          _s: "postcards-details",
          user_id: self.userAttrs.userId,
          postcard_id: postcard_id
        }),
        stub: "postcards-details",
        closable: true,
        onClose: function () {
          self.tabContainer.postcardsDetailsSection = null;
          return true;
        }
      };
      this.tabContainer.addNewPane(newPaneObj, true, postcard_id);
    },
    loadFilter: function (section) {}
  });
});