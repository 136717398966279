define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, Deferred) {
  /**
   * Class for hiding a polling-style function behind a simpler single-promise interface.
   *
   * The pollableFunc should return a Promise. The promise should either:
   * 1. Resolves to a value, which indicates the long-running operation is finished, and the value can be passed along
   * 2. Resolves to Poller.RETRY to indicate the pollableFunc should be tried again later
   * 3. Reject, which indicates the long-running operation is finished, and the error can be passed along
   *
   * The delayStrategy should take one argument and return a floating value for the number of milliseconds to wait
   * before polling again. Out of the box, Poller defaults to an small exponential backoff strategy.
   */
  var Poller = declare(null, {
    /**
     * Construct a Poller instance
     * @param {function} pollableFunc Function to poll. Should resolve to value|"retry", or reject to
     * @param {function} delayStrategy Function to calculate how long to wait before polling again.
     */
    constructor: function (pollableFunc, delayStrategy) {
      this._pollableFunc = pollableFunc;
      this._delayStrategy = delayStrategy || Poller.EXPONENTIAL_BACKOFF_DELAY_STRATEGY;
      this._previousAttempts = 0;
      this._pollable_promise = null;
      this._timeout_id = null;
      this._deferred = new Deferred(this._handleCancel.bind(this));
    },
    /**
     * Get a single promise which will resolve and reject when pollableFunc is finished doing work.
     *
     * @returns {Promise} Resolves when the pollableFunc resolves with a value, or rejects with pollableFunc's error
     */
    getPromise: function () {
      return this._deferred.promise;
    },
    /**
     * Kick off the first attempt. This function can be called multiple times. The polling process will only
     * begin once.
     */
    begin: function () {
      if (this._previousAttempts === 0) {
        this._makeAttempt();
      }
    },
    _makeAttempt: function () {
      this._previousAttempts++;
      this._pollable_promise = this._pollableFunc();
      this._pollable_promise.then(this._handlePollableSuccess.bind(this), this._handlePollableError.bind(this));
    },
    /**
     * Handle the resolved response of the pollable func's returned promise.
     *
     * @param {object} response - The value the promise is resolved with
     *
     * @private
     */
    _handlePollableSuccess: function (response) {
      if (response !== Poller.RETRY) {
        this._deferred.resolve(response);
        return;
      }
      var delayInMs = this._delayStrategy(this._previousAttempts);
      this._timeout_id = setTimeout(this._makeAttempt.bind(this), delayInMs);
    },
    /**
     * Handle the rejected response of the pollable func's returned promise.
     *
     * @param {object} error - The value the promise is rejected with
     *
     * @private
     */
    _handlePollableError: function (error) {
      this._deferred.reject(error);
    },
    /**
     * Handle when cancel() is called on the promise Poller provides in its public interface via the getPromise().
     *
     * @private
     */
    _handleCancel: function () {
      if (this._timeout_id) {
        clearTimeout(this._timeout_id);
      }
      if (this._pollable_promise) {
        this._pollable_promise.cancel();
      }
    }
  });

  /**
   * A strategy for calculating the polling delay for each subsequent pollableFunc invocation, after the first time.
   * That is, numPreviousAttempts should always be greater than or equal to 1, because Poller doesn't ask for a delay
   * before to invoking pollableFunc for the first time.
   *
   * @param {integer} numPreviousAttempts The number of times we've polled before
   *
   * @returns {number} Number of milliseconds to wait before polling again
   */
  Poller.EXPONENTIAL_BACKOFF_DELAY_STRATEGY = function (numPreviousAttempts) {
    return Math.pow(1.15, numPreviousAttempts) * 1000;
  };

  /**
   * Sentinel value for a pollableFunc's Promise to resolve to, indicating that the pollableFunc should be called
   * again at a later point in time
   *
   * @type {string}
   */
  Poller.RETRY = "retry";
  return Poller;
});