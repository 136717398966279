/**
 * constants of style properties for neapolitan and their inputs to re-use
 */
define([], function () {
  var keys = {
    FONT_FAMILY: "font-family",
    FONT_SIZE: "font-size",
    FONT_WEIGHT: "font-weight",
    FONT_STYLE: "font-style",
    COLOR: "color",
    TEXT_DECORATION: "text-decoration",
    TEXT_ALIGN: "text-align",
    LINE_HEIGHT: "line-height",
    LETTER_SPACING: "letter-spacing",
    BG_COLOR: "background-color",
    BG_IMAGE: "background-image",
    BG_SIZE: "background-size",
    BG_POSITION: "background-position",
    BG_REPEAT: "background-repeat",
    FLEX: "flex",
    PADDING_TOP: "padding-top",
    PADDING_RIGHT: "padding-right",
    PADDING_BOTTOM: "padding-bottom",
    PADDING_LEFT: "padding-left"
  };
  keys["FONT_STYLES_PROPS"] = [keys.FONT_WEIGHT, keys.FONT_STYLE, keys.TEXT_DECORATION];
  keys["FONT_PROPS"] = [keys.FONT_FAMILY, keys.COLOR, keys.FONT_SIZE, keys.LINE_HEIGHT, keys.TEXT_ALIGN, keys.LETTER_SPACING, keys.FONT_WEIGHT, keys.FONT_STYLE, keys.TEXT_DECORATION];
  keys["BG_PROPS"] = [keys.BG_COLOR, keys.BG_IMAGE, keys.BG_SIZE, keys.BG_POSITION, keys.BG_REPEAT];
  keys["PADDING_PROPS"] = [keys.PADDING_TOP, keys.PADDING_RIGHT, keys.PADDING_BOTTOM, keys.PADDING_LEFT];
  return keys;
});