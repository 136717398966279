define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/string", "dojox/widget/ColorPicker", "dijit/focus", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/Color!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate", "dojo/NodeList-traverse"], function (declare, lang, dom, query, domConstruct, domGeom, domStyle, string, ColorPicker, FocusManager, on, Color) {
  return declare(ColorPicker, {
    animatePoint: false,
    customColors: {},
    attachInput: null,
    inputBlurHandler: null,
    customColorTemplate: "<li class='mojoCustomColor' style='background:${color}'>&nbsp</li>",
    customColorContainerTemplate: "<td colspan='3'><div class='mojoCustomColorContainer'><label class='above0 mojoCustomColorLabel'>${label}</label><ul class='mojoCustomColors'></ul><div class='clear'/></div></td>",
    _createColor: function (container, color) {
      var colorHtml = string.substitute(this.customColorTemplate, {
        color: color
      });
      var colorDom = domConstruct.place(colorHtml, query('ul.mojoCustomColors', container)[0]);
      this.connect(colorDom, 'onclick', function () {
        this.setColor(domStyle.get(colorDom, 'backgroundColor'), true);
      });
    },
    /** Overriding dojox implementation because their hue cursor is not changing colors on drag **/
    _setTimer: function ( /* d.dnd.Mover */mover) {
      if (mover.node != this.cursorNode && mover.node != this.hueCursorNode) {
        return;
      }
      FocusManager.focus(mover.node);
      dom.setSelectable(this.domNode, false);
      this._timer = setInterval(lang.hitch(this, "_updateColor"), 45);
    },
    /** Overriding default implementation because it uses layerX and layerY and that causes problems
     * in IE/Opera where the color selector goes off the screen
     **/
    _setHuePoint: function ( /* Event */evt) {
      var selCenter = this.PICKER_HUE_SELECTOR_H / 2;
      var pos = domGeom.position(this.hueNode, true);
      var ypos = evt.pageY - pos.y - selCenter;
      if (this.animatePoint) {
        fx.slideTo({
          node: this.hueCursorNode,
          duration: this.slideDuration,
          top: ypos,
          left: 0,
          onEnd: lang.hitch(this, function () {
            this._updateColor(true);
            FocusManager.focus(this.hueCursorNode);
          })
        }).play();
      } else {
        domStyle.set(this.hueCursorNode, "top", ypos + "px");
        this._updateColor(false);
      }
    },
    /** Overriding default implementation because it uses layerX and layerY and that causes problems
     * in IE/Opera where the color selector goes off the screen
     **/
    _setPoint: function ( /* Event */evt) {
      var satSelCenterH = this.PICKER_SAT_SELECTOR_H / 2;
      var satSelCenterW = this.PICKER_SAT_SELECTOR_W / 2;
      var pos = domGeom.position(this.colorUnderlay, true);
      var newTop = evt.pageY - pos.y - satSelCenterH;
      var newLeft = evt.pageX - pos.x - satSelCenterW;
      if (evt) {
        FocusManager.focus(evt.target);
      }
      if (this.animatePoint) {
        fx.slideTo({
          node: this.cursorNode,
          duration: this.slideDuration,
          top: newTop,
          left: newLeft,
          onEnd: lang.hitch(this, function () {
            this._updateColor(true);
            FocusManager.focus(this.cursorNode);
          })
        }).play();
      } else {
        domStyle.set(this.cursorNode, {
          left: newLeft + "px",
          top: newTop + "px"
        });
        this._updateColor(false);
      }
    },
    _updateColor: function () {
      this.inherited(arguments, [true]);
    },
    postCreate: function () {
      this.inherited(arguments);
      for (var label in this.customColors) {
        if (this.customColors[label].length) {
          this.customColorsNode = domConstruct.create('tr');
          query('> tbody', this.domNode).append(this.customColorsNode);
          var containerHtml = string.substitute(this.customColorContainerTemplate, {
            label: label
          });
          var containerDom = domConstruct.place(containerHtml, this.customColorsNode);
          for (var col = 0; col < this.customColors[label].length; col++) {
            var color = this.customColors[label][col];
            this._createColor(containerDom, color);
          }
        }
      }
      domStyle.set(this.domNode, {
        position: 'absolute',
        display: 'none'
      });
    },
    attachTo: function (input) {
      if (this.attachInput !== input) {
        this.attachInput = input;
        if (Color.fromString(input.value) !== null) {
          this.setColor(input.value);
        }
      }
      return this;
    },
    show: function (styles) {
      var that = this;
      var handle = on(document, 'click', function (evt) {
        if (!query(evt.target).parents('.dojoxColorPicker').length && evt.target !== that.attachInput) {
          handle.remove();
          that.hide();
        }
      });
      this.startup();
      if (!styles) {
        var pos = domGeom.position(this.attachInput, true);
        styles = {
          top: pos.y + pos.h + 'px',
          left: pos.x + 'px'
        };
      }
      styles.display = 'block';
      domStyle.set(this.domNode, styles);
    },
    hide: function () {
      domStyle.set(this.domNode, 'display', 'none');
    }
  });
});