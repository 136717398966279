/**
 * Color Swatches
 * @module mojo/widgets/pattern-library/ColorSwatches.js
 *
 * Widget used to represent UI for Pattern Library color swatches section.
 */

define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/colors", "mojo/widgets/pattern-library/Swatch", "mojo/widgets/pattern-library/SwatchWithContrast",
// Vendor
"vendor/ColorContrastChecker/color-contrast-checker",
// Mixins
"dijit/_WidgetBase", "dijit/_TemplatedMixin", "dijit/_WidgetsInTemplateMixin",
// Templates
"dojo/text!./templates/ColorSwatches.html"], function (declare, domConstruct, mColors, Swatch, SwatchWithContrast, ccc, _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin, tplStr) {
  var brandColors = mColors.brandColors;
  return declare([_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {
    templateString: tplStr,
    // Set the default, override on change of top-level toggler.
    colorFormat: "hex",
    // Control what colors show as part of what grouping.
    groupBrand: [brandColors.cavendish],
    groupFunctional: [brandColors.peppercorn, brandColors.oyster, brandColors.tuna, brandColors.portobello, brandColors.basmati, brandColors.parsnip, brandColors.jasmine, brandColors.coconut, brandColors.kale],
    groupFeedback: [brandColors.yam, brandColors.cornflower, brandColors.cucumber, brandColors.squash, brandColors.radish],
    groupNeutral1: [brandColors.peppercorn, brandColors.peppercorn65, brandColors.peppercorn30, brandColors.peppercorn15],
    groupNeutral2: [brandColors.coconut, brandColors.coconut50, brandColors.coconut20, brandColors.coconut10],
    groupAccent: [brandColors.dragonfruit, brandColors.plum, brandColors.apricot, brandColors.apple, brandColors.pumpkin],
    /**
     * Workhorse of the widget. Part of Dojo's lifecycle.
     */
    postCreate: function () {
      this._initSwatches();
    },
    /**
     * Create the rows for the swatches based on whats configured earlier.
     *
     * @private
     */
    _initSwatches: function () {
      this._resetSwatches();

      // Startup each row
      this._initSwatchGroup(this.groupBrand, this.groupBrandContainer);
      this._initSwatchGroup(this.groupFunctional, this.groupFunctionalContainer);
      this._initSwatchGroup(this.groupFeedback, this.groupFeedbackContainer);
      this._initSwatchGroup(this.groupNeutral1, this.groupNeutral1Container);
      this._initSwatchGroup(this.groupNeutral2, this.groupNeutral2Container);
      this._initSwatchGroup(this.groupAccent, this.groupAccentContainer);
    },
    /**
     *
     * @param {array} groupData - The collection of color objects for a given grouping.
     * @param {domNode} destinationNode - Reference to where to place each new widget.
     * @private
     */
    _initSwatchGroup: function (groupData, destinationNode) {
      var hasTransparentColor = groupData.some(function (c) {
        return ccc.isValidColorCode(c.hex) === false;
      });
      var subGroup = domConstruct.create("div", {
        "class": "c-groupDataContainer flex flex-wrap--wrap"
      });

      // Loop through the group and startup the swatches
      groupData.forEach(function (colorObj) {
        this._initSwatch(ccc.isValidColorCode(colorObj.hex) ? SwatchWithContrast : Swatch, colorObj, ccc.isValidColorCode(colorObj.hex) ? destinationNode : subGroup);
      }.bind(this));
      if (hasTransparentColor) {
        domConstruct.place(subGroup, destinationNode, "last");
      }
    },
    _initSwatch: function (swatchType, colorObj, destinationNode) {
      var swatch = new swatchType({
        color: colorObj,
        colorFormat: this.colorFormat
      });
      swatch.placeAt(destinationNode);
      swatch.startup();
    },
    /**
     * Respond to changes in the toggler. Save the users setting and regenerate all of the swatches.
     *
     * @param {string} newFormat - New format to display. hex/rgb/less
     * @private
     */
    _handleTogglerChange: function (newFormat) {
      this.colorFormat = newFormat;
      this._initSwatches();
    },
    /**
     * Clear out the domNodes so we don't end up with duplicate swatches. Ideally this would destroy each
     * of the widgets. If performance becomes a challenge, this would be a necessary upgrade.
     *
     * @private
     */
    _resetSwatches: function () {
      domConstruct.empty(this.groupBrandContainer);
      domConstruct.empty(this.groupFunctionalContainer);
      domConstruct.empty(this.groupFeedbackContainer);
      domConstruct.empty(this.groupNeutral1Container);
      domConstruct.empty(this.groupNeutral2Container);
      domConstruct.empty(this.groupAccentContainer);
    }
  });
});