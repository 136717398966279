define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", 'codemirror4/lib/codemirror', "./_Base", "dojo/text!./templates/CodeEditor/editor.html", "mojo/neapolitan/editors/_DynamicContentMixin", "mojo/neapolitan/widgets/EditorContainer", 'codemirror4/mode/htmlmixed/htmlmixed'], function (declare, lang, domConstruct, CodeMirror, _Base, tpl, _DynamicContentMixin, EditorContainer, code_mirror_htmlmixed) {
  return declare([_Base, _DynamicContentMixin], {
    type: 'code',
    templateString: tpl,
    postCreate: function () {
      this.inherited(arguments);
      if (this.isPageTemplate) {
        this.emailHTMLCodeWarning.remove();
      }
      this.codeEditor = CodeMirror(this.editorContainer, {
        value: this.block.html,
        mode: "htmlmixed",
        autofocus: true,
        tabMode: "indent",
        indentUnit: 2,
        smartIndent: false,
        autoClearEmptyLines: true,
        lineWrapping: true,
        lineNumbers: true
      });
      this.codeEditor.on("change", lang.hitch(this, function () {
        this.block.set("html", this.codeEditor.getValue());
      }));
    },
    onShow: function () {
      this.inherited(arguments);
      this.codeEditor.refresh();
      this.codeEditor.focus();
    }
  });
});